import { FunctionComponent, ReactElement } from 'react'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'

import './styles.scss'

const LoginLayout: FunctionComponent<any> = (props: any): ReactElement<any> => {
  const { t } = useTranslation()

  return (
    <Grid
      container={true}
      justifyContent="center"
      alignItems="center"
      classes={{
        container: 'login-page mui-override',
      }}
    >
      <Paper
        classes={{
          root: 'login-page__dialog mui-override',
        }}
      >
        <Typography
          variant="h5"
          classes={{
            root: 'login-page__title mui-override',
          }}
        >
          {t('forgot_password.layout.title', 'Login')}
        </Typography>
        <Grid
          item={true}
          container={true}
          direction="column"
          classes={{
            container: 'login-page__input-container mui-override',
          }}
        >
          {props.emailField}
          <Typography
            classes={{
              root: 'login-page__description mui-override',
            }}
          >
            {t(
              'forgot_password.layout.message',
              'We will send you an email to reset your password.'
            )}
          </Typography>
        </Grid>
        <Grid container={true} justifyContent="space-between">
          <Grid
            container={true}
            className="login-links mui-override"
            justifyContent="flex-end"
            flexDirection="column"
          >
            {props.back}
          </Grid>
          {props.button}
        </Grid>
      </Paper>
    </Grid>
  )
}

LoginLayout.propTypes = {
  emailField: PropTypes.node,
  passwordField: PropTypes.node,
  button: PropTypes.node,
}
LoginLayout.defaultProps = {
  emailField: '',
  passwordField: '',
  button: '',
}

export default LoginLayout

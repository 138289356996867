const grey = {
  50: '#F2F2F3',
  100: '#EBEBEC',
  200: '#E0E0E2',
  300: '#C0BFC3',
  400: '#9A99A0',
  500: '#807F87',
  600: '#686670',
  700: '#4F4D58',
  800: '#353340',
  900: '#1C1A29',
  A100: '#EDEDED',
  A200: '#9C9C9C',
  A400: '#49445F',
  A700: '#414141',
}

export default grey

import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/purchaseOrders'
import * as actionCreators from '../../actionCreators/purchaseOrders'
import {
  simpleGetAndResolve,
  simplePostAndResolve,
  simplePutAndResolve,
  simplePatchAndResolve,
  simpleDeleteAndResolve,
} from '../factories'
import uploadPDFDocument from './uploadPDF'

const getPurchaseOrders = simpleGetAndResolve(
  () => 'api/v1/purchase_orders',
  actionCreators.purchaseOrdersGetDataSuccess,
  (response) => response.data
)

const getPurchaseOrdersMeta = simpleGetAndResolve(
  () => 'api/v1/purchase_orders/meta',
  actionCreators.purchaseOrdersGetMetaSuccess,
  (response) => response.data
)

const getPurchaseOrdersFiltersData = simpleGetAndResolve(
  (action) => `/api/v1/purchase_orders/filters_data`,
  actionCreators.purchaseOrdersGetFiltersDataSuccess,
  (response) => response.data
)

const purchaseOrderCreate = simplePostAndResolve(
  () => 'api/v1/purchase_orders',
  actionCreators.purchaseOrderCreateSuccess,
  (response) => response.data
)

const checkUniqueOrderNumber = simpleGetAndResolve(
  () => 'api/v1/purchase_orders/unique_purchase_order_number',
  actionCreators.purchaseOrdersCheckUniqueSuccess,
  (response) => response.data
)

const updatePurchaseOrder = simplePutAndResolve(
  (action) => `/api/v1/purchase_orders/${action.id}`,
  actionCreators.updatePurchaseOrderSuccess,
  (response) => response.data
)

const purchaseOrdersRemoveFromShipment = simplePostAndResolve(
  (action) => 'api/v1/purchase_orders/remove_po_lines_from_shipment',
  (response) => response.data
)

const purchaseOrdersAssignToShipment = simplePostAndResolve(
  (action) => 'api/v1/purchase_orders/assign_po_lines_to_shipment',
  (response) => response.data
)

const deletePurchaseOrder = simpleDeleteAndResolve(
  (action) => `/api/v1/purchase_orders/${action.id}`,
  actionCreators.deletePurchaseOrderSuccess,
  (response) => response
)

const dismissPurchaseOrderStatuses = simplePutAndResolve(
  (action) =>
    `/api/v1/purchase_orders/dismiss_upload_statuses?status=${action.status}`,
  (response) => response.data
)

const purchaseOrderShareEmail = simplePostAndResolve(
  (action) => `api/v1/purchase_orders/${action.id}/supplier_email`,
  (response) => response.data
)

const assignSellerOrganization = simplePostAndResolve(
  (action) => `api/v1/purchase_orders/${action.id}/assign_seller_organization`,
  (response) => response.data
)

const unmarkException = simplePatchAndResolve(
  (action) => `api/v1/purchase_orders/${action.id}/unmark_event_as_exception`,
  (response) => response.data
)

const resetInfoRequests = simpleDeleteAndResolve(
  (action) => `api/v1/purchase_orders/${action.id}/reset_sla_checkpoint`,
  actionCreators.resetInfoRequestsSuccess,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.UPLOAD_PDF_DOCUMENT, uploadPDFDocument)
  yield takeEvery(actions.UPDATE_PURCHASE_ORDER, updatePurchaseOrder)
  yield takeEvery(actions.PURCHASE_ORDERS_GET_DATA, getPurchaseOrders)
  yield takeEvery(actions.PURCHASE_ORDER_SHARE_EMAIL, purchaseOrderShareEmail)
  yield takeEvery(actions.PURCHASE_ORDERS_GET_META, getPurchaseOrdersMeta)
  yield takeEvery(
    actions.PURCHASE_ORDERS_GET_FILTERS_DATA,
    getPurchaseOrdersFiltersData
  )
  yield takeEvery(actions.PURCHASE_ORDERS_CREATE, purchaseOrderCreate)
  yield takeEvery(actions.PURCHASE_ORDERS_CHECK_UNIQUE, checkUniqueOrderNumber)
  yield takeEvery(
    actions.REMOVE_PURCHASE_ORDERS_FROM_SHIPMENT,
    purchaseOrdersRemoveFromShipment
  )
  yield takeEvery(
    actions.ASSIGN_PURCHASE_ORDERS_TO_SHIPMENT,
    purchaseOrdersAssignToShipment
  )

  yield takeEvery(actions.DELETE_PURCHASE_ORDER, deletePurchaseOrder)
  yield takeEvery(
    actions.PURCHASE_ORDER_DISMISS_STATUSES,
    dismissPurchaseOrderStatuses
  )
  yield takeEvery(actions.PURCHASE_ORDER_UNMARK_EXCEPTION, unmarkException)
  yield takeEvery(actions.ASSIGN_SELLER_ORGANIZATION, assignSellerOrganization)
  yield takeEvery(actions.RESET_INFO_REQUESTS, resetInfoRequests)
}

import { useController } from 'react-hook-form'
import { toNumber } from 'lodash'
import Grid from '@mui/material/Grid'
import Input, { NumberFormatCustom } from 'src/components/Common/Input/MuiInput'
import { ItemProps } from '../GoodsLoadTypeLCL.props'
import { countVolume } from './helpers'

export const QuantityInput = (props: ItemProps) => {
  const { cargo, onChange } = props
  const { field, fieldState } = useController(props)

  const handleChange = (value: string) => {
    const length = cargo.length
    const width = cargo.width
    const height = cargo.height
    const volume = countVolume(width, height, length, toNumber(value))

    onChange(cargo.id, 'quantity', Number(value))
    onChange(cargo.id, 'total_volume_cbm', volume)
  }

  return (
    <Grid item xs={1} pr={1.5} pl={1.5}>
      <Input
        {...field}
        value={cargo.quantity}
        inputComponent={NumberFormatCustom as any}
        onChange={(value) => {
          field.onChange(value)
          handleChange(value)
        }}
        ref={null}
        error={!props.isClearError && Boolean(fieldState.error)}
        data-testid="quantity-field"
      />
    </Grid>
  )
}

import Box from '@mui/material/Box'
import IconField from '../components/IconField'
import ShipmentField from '../components/ShipmentField'
import PortField from '../components/PortField'
import StatusField from '../components/StatusField'
import GenericDateField from '../components/GenericDateField'
import CommentField from '../components/CommentField'
import ColoredField from '../components/ColoredField'
import ColoredFieldWithMappings from '../components/ColoredFieldWithMappings'

export const scheduleTbaSchema = {
  icon: {
    field: (shipment: IDashboardScheduleTbaShipment) => (
      <IconField shipment={shipment} />
    ),
    name: '',
    sortable: false,
    sortValue: '',
    customClassName: 'short-50',
  },
  shipment: {
    field: (shipment: IDashboardScheduleTbaShipment) => (
      <ShipmentField shipment={shipment} />
    ),
    name: 'SHIPMENT',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  status: {
    field: (shipment: IDashboardScheduleTbaShipment) => (
      <StatusField shipment={shipment} />
    ),
    name: 'STATUS',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned x-medium',
  },
  from: {
    field: (shipment: IDashboardScheduleTbaShipment) => (
      <PortField port={shipment.loading_port} date={null}>
        TBA
      </PortField>
    ),
    name: 'POL',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  to: {
    field: (shipment: IDashboardScheduleTbaShipment) => (
      <PortField port={shipment.discharge_port} date={null}>
        TBA
      </PortField>
    ),
    name: 'POD',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  volume: {
    field: (shipment: IDashboardScheduleTbaShipment) => (
      <>
        <Box className="text-wrapper">{shipment.carrier?.name}</Box>
        <Box>{shipment.volume}</Box>
      </>
    ),
    name: 'CARRIER / VOLUME',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned s-medium',
  },
  comment: {
    field: (shipment: IDashboardScheduleTbaShipment) => (
      <CommentField comment={shipment.comment} />
    ),
    name: 'COMMENT',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  cargoReady: {
    field: (shipment: IDashboardScheduleTbaShipment) => (
      <GenericDateField date={shipment.cargo_ready_date} />
    ),
    name: 'CARGO READY',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned s-medium',
  },
  finance: {
    field: (shipment: IDashboardScheduleTbaShipment) =>
      shipment.finance_task ? (
        <ColoredFieldWithMappings
          type="task"
          mapping="finance_etd_check"
          value={shipment.finance_task.task_status_id}
        />
      ) : (
        <ColoredField value="" icon="remove" color="white" />
      ),
    name: 'FINANCE',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned s-medium',
  },
  created: {
    field: (shipment: IDashboardScheduleTbaShipment) => (
      <GenericDateField date={shipment.created_at} />
    ),
    name: 'CREATED',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned s-medium',
  },
}

import { AnyAction } from 'redux'
import * as actions from '../actions/bookings'

export const clearBookingsState = (): AnyAction => ({
  type: actions.CLEAR_BOOKINGS_STATE,
})

export const bookingsGetData = (id: number): AnyAction => ({
  type: actions.BOOKINGS_GET_DATA,
  id,
})

export const bookingsGetDataSuccess = (payload: any): AnyAction => ({
  type: actions.BOOKINGS_GET_DATA_SUCCESS,
  payload,
})

export const incotermsGetData = (): AnyAction => ({
  type: actions.INCOTERMS_GET_DATA,
})

export const incotermsGetDataSuccess = (payload: any): AnyAction => ({
  type: actions.INCOTERMS_GET_DATA_SUCCESS,
  payload,
})

export const updateBooking = (id: string, payload: any): AnyAction => ({
  type: actions.UPDATE_BOOKING,
  id,
  payload,
})

export const updateBookingSuccess = (payload: any): AnyAction => ({
  type: actions.UPDATE_BOOKING_SUCCESS,
  payload,
})

export const createBooking = (payload: any): AnyAction => ({
  type: actions.CREATE_BOOKING,
  payload,
})

export const createBookingSuccess = (payload: any): AnyAction => ({
  type: actions.CREATE_BOOKING_SUCCESS,
  payload,
})

export const bookingsCarrierBookingGetData = (id: number): AnyAction => ({
  type: actions.BOOKINGS_CARRIER_BOOKING_GET_DATA,
  id,
})

export const bookingsCarrierBookingGetDataSuccess = (
  payload: any
): AnyAction => ({
  type: actions.BOOKINGS_CARRIER_BOOKING_GET_DATA_SUCCESS,
  payload,
})

export const assignAddress = (payload: any, id: number): AnyAction => ({
  type: actions.BOOKINGS_ASSIGN_ADDRESS,
  payload,
  id,
})

export const assignContact = (payload: any, id: number): AnyAction => ({
  type: actions.BOOKINGS_ASSIGN_CONTACT,
  payload,
  id,
})

export const bookingAssignSuccess = (payload: any): AnyAction => ({
  type: actions.BOOKINGS_ASSIGN_SUCCESS,
  payload,
})

export const updateNotifyPartyAsConsignee = (
  payload: any,
  id: number
): AnyAction => ({
  type: actions.BOOKINGS_UPDATE_NOTIFY_AS_CONSIGNEE,
  payload,
  id,
})

export const assignShipperAsCollaborator = (id: number): AnyAction => ({
  type: actions.ASSIGN_SHIPPER_AS_COLLABORATOR,
  id,
})

import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import Skeleton from '@mui/material/Skeleton'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import Box from '@mui/material/Box'
import { TableSkeletonProps } from './TableSkeleton.props'

export const TableSkeleton: React.FC<TableSkeletonProps> = ({
  sx = {},
  rows = 10,
  columns = 8,
  className = '',
  component = Box,
}) => {
  return (
    <TableContainer
      sx={sx}
      component={component}
      data-testid="table-skeleton-loader"
    >
      <Table className={className}>
        <TableHead>
          <TableRow>
            {Array.from({ length: columns }).map((_, index) => (
              <TableCell key={index}>
                <Skeleton animation="wave" height="30px" />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: rows }).map((_rowItem, rowIndex) => (
            <TableRow key={rowIndex}>
              {Array.from({ length: columns }).map((_colItem, columnIndex) => (
                <TableCell key={columnIndex} sx={{ bgcolor: 'common.white' }}>
                  <Skeleton animation="wave" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableSkeleton

import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import Typography from '@mui/material/Typography'
import './styles.scss'

interface IDateBoxProps {
  date?: string | null
}

interface IDateBoxItemProps extends IDateBoxProps {
  format: string
}

const DateBoxItem: React.FC<IDateBoxItemProps> = (props: IDateBoxItemProps) => {
  const { date } = props
  const dateText = date ? DateTime.fromISO(date) : ''
  return (
    <Typography component="div" variant="subtitle1" className="date-box__field">
      {dateText ? dateText.toFormat(props.format) : 'N/A'}
    </Typography>
  )
}

const DateBox: React.FC<IDateBoxProps> = (props: IDateBoxProps) => {
  return (
    <div className="date-box">
      <DateBoxItem {...{ date: props.date, format: 'MMM' }} />
      <DateBoxItem {...{ date: props.date, format: 'd' }} />
    </div>
  )
}

DateBox.propTypes = {
  date: PropTypes.string,
}

DateBox.defaultProps = {
  date: '',
}

export default DateBox

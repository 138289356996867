import { includes, find } from 'lodash'
import { THC } from 'src/config/constants'

export const getDimensions = (item: IContainerShort): string => {
  if (item.length_mm && item.width_mm && item.height_mm) {
    return `${item.length_mm / 10} x ${item.width_mm / 10} x ${
      item.height_mm / 10
    } cm`
  }
  return '-'
}

export const getServiceName = (estimated_prices, name: string): string => {
  switch (name) {
    case 'insurance':
      return 'Cargo insurance'
    case 'freight':
      return estimated_prices
        ? 'Sea freight calculated estimation'
        : 'Sea freight'
    default:
      return name
  }
}

const includedTHC = ['DEST_THC', 'ORIG_THC']

export const getGeneralTHC = (quote, item): string => {
  if (!quote) {
    return ''
  }
  const includedServices = quote.price_details.included_services
  const isOceanFreight: boolean = item.freight
  const destination: string = includedTHC[0]
  const origin: string = includedTHC[1]
  let status = ''

  if (includedServices && includedServices.length && isOceanFreight) {
    const originServices = includes(includedServices, origin)
    const destinationServices = includes(includedServices, destination)
    switch (true) {
      case originServices && destinationServices:
        status = THC.bothSAB
        break
      case originServices && !destinationServices:
        status = THC.origin
        break
      case !originServices && destinationServices:
        status = THC.destination
        break
    }
  }
  return status
}

export const checkOnRequest = (
  name: string,
  estimatedPrices: boolean,
  services?: IPriceServices[]
): boolean => {
  const _services = services ?? []
  const service: IPriceServices | undefined = find(_services, {
    service_name: name,
  })
  if (name === 'freight') return !estimatedPrices

  return service ? !service.price_on_request : false
}

export const isRolesSelected = (collaborators): boolean => {
  return collaborators.every((collaborator) => collaborator.roles.length > 0)
}

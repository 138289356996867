import { Fragment } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { useTranslation } from 'react-i18next'
import { InfoTooltip } from 'src/stories/MUI/Tooltip/InfoTooltip'
import QuickFiltersManageDrawerEmptyState from './QuickFiltersManageDrawerEmptyState'
import QuickFiltersManageDrawerListHeader from './QuickFiltersManageDrawerListHeader'
import { QuickFiltersManageDrawerFormProps } from './QuickFiltersManageDrawer.props'

const QuickFiltersManageDrawerContent: React.FC<QuickFiltersManageDrawerFormProps> = ({
  savedFilters,
  onSubmit,
  onDelete,
}) => {
  const { t } = useTranslation()
  const mappedQuickFilters = savedFilters.map(
    (savedFilter: PickUpAndDeliveryQuickFilter | ShipmentQuickFilter) => ({
      key: `${savedFilter.id}`,
      name: savedFilter.name,
    })
  )

  const { register, control, handleSubmit, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      qf: mappedQuickFilters,
    },
  })

  const { fields, remove } = useFieldArray({
    control,
    name: 'qf',
  })

  return (
    <Box mt={6}>
      {savedFilters.length === 0 && <QuickFiltersManageDrawerEmptyState />}
      {savedFilters.length > 0 && (
        <FormControl
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <List
            sx={{
              width: '100%',
              flexGrow: 1,
            }}
            subheader={<QuickFiltersManageDrawerListHeader />}
          >
            {fields.map((savedFilter, index: number) => {
              return (
                <Fragment key={savedFilter.key}>
                  <ListItem disableGutters sx={{ height: 72 }}>
                    <ListItemText
                      primary={
                        <TextField
                          fullWidth
                          {...register(`qf.${index}.name`, {
                            required: true,
                          })}
                        />
                      }
                    />
                    <Button
                      sx={{ ml: 2 }}
                      children={t('common.buttons.delete', 'Delete')}
                      size="large"
                      onClick={() => {
                        onDelete({
                          id: savedFilter.key,
                          name: savedFilter.name,
                        })
                        remove(index)
                      }}
                      startIcon={<DeleteIcon />}
                      aria-label="Delete filter"
                    />
                  </ListItem>
                  <Divider component="li" sx={{ borderColor: 'grey.200' }} />
                </Fragment>
              )
            })}
          </List>
          <Stack
            mt={2}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography color="grey.600" variant="body1">
              {t(
                'filters.manage_quick_filters.helper_text.title',
                'Add new quick filter'
              )}
              <InfoTooltip
                title={t(
                  'filters.manage_quick_filters.helper_text.description',
                  'You can add a new quick filter by configuring filters on the left and use ‘save as new quick filter’'
                )}
              />
            </Typography>

            <Button
              variant="contained"
              type="submit"
              size="large"
              onClick={handleSubmit(onSubmit)}
              disabled={!formState.isValid || !formState.isDirty}
              children={t('common.buttons.save', 'Save')}
            />
          </Stack>
        </FormControl>
      )}
    </Box>
  )
}

export default QuickFiltersManageDrawerContent

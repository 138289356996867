import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import TextField from '@mui/material/TextField'
import { useDispatch } from 'react-redux'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { addAgreementEmail } from './DemurrageAndDetentionContent.constants'

const DemurrageAndDetentionContentAddAgreementCopyEmail = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleClickOnCopyButton = () => {
    if (!navigator.clipboard) {
      return dispatch(
        showNotification({
          message: t(
            'demurrage_and_detention.add_agreement.notifications.copy_to_clipboard_error',
            "Your browser doesn't support copy to clipboard"
          ),
          severity: 'error',
        })
      )
    }
    navigator.clipboard.writeText(addAgreementEmail).then(() => {
      dispatch(
        showNotification({
          message: t(
            'demurrage_and_detention.add_agreement.notifications.copy_to_clipboard_success',
            'Email address copied'
          ),
          severity: 'success',
        })
      )
    })
  }

  return (
    <Stack direction="row" spacing={1.5}>
      <TextField
        fullWidth
        disabled={true}
        variant="outlined"
        defaultValue={addAgreementEmail}
      />
      <Button
        children={t('common.buttons.copy', 'Copy')}
        variant="contained"
        onClick={handleClickOnCopyButton}
        data-testid="copy-agreement-email-button"
      />
    </Stack>
  )
}

export default DemurrageAndDetentionContentAddAgreementCopyEmail

import { FunctionComponent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { stringify } from 'query-string'
import { keys, pickBy, isEmpty } from 'lodash'
import { Dialog, DialogContent } from 'src/stories/Dialogs'
import {
  Typography,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormGroup,
  Tooltip,
} from '@mui/material'
import { setVisibilityShipment } from 'src/stores/actionCreators'
import useLocalStorage from 'src/hooks/useLocalStorage'
import { useTranslation } from 'react-i18next'

const serviceItemKeys = [
  'pickup',
  'export_customs',
  'origin_port',
  'freight',
  'destination_port',
  'import_customs',
  'delivery',
  'insurance',
]

const ShipmentServicesWindow: FunctionComponent<any> = ({
  open,
  onClose,
  shipmentId,
  defaultServices,
  title,
  header,
  showSwitch,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [additionalServices, setAdditionalServices] = useState<{
    [key: string]: boolean
  }>({
    pickup: false,
    export_customs: false,
    origin_port: false,
    freight: false,
    destination_port: false,
    import_customs: false,
    delivery: false,
    insurance: false,
  })
  const [showServiceWindow, setShowServiceWindow] = useLocalStorage<boolean>(
    'hideServiceWindow',
    JSON.parse(localStorage.getItem('hideServiceWindow') ?? 'false')
  )

  const serviceItems = serviceItemKeys.map((value) => ({
    label: t(`common.service_item.${value}.title`),
    value,
  }))

  const onSelectService = (e) => {
    setAdditionalServices({
      ...additionalServices,
      ...{ [e.target.value]: e.target.checked },
    })
  }

  const onCloseWindow = () => {
    onClose()
    if (showSwitch) {
      dispatch(setVisibilityShipment(null))
    }
  }

  const sendServicesRequest = () => {
    onCloseWindow()
    history.push({
      pathname: `/shipments/${shipmentId}/chats`,
      search: stringify({ services: keys(pickBy(additionalServices)) }),
    })
  }

  const showWindowToggle = () => {
    setShowServiceWindow(!showServiceWindow)
  }

  return (
    <Dialog
      sx={{
        '.MuiDialog-paper': {
          width: '100%',
        },
        '.MuiDialog-paper .MuiDialogTitle-root': {
          paddingBottom: 1,
        },
        '.MuiDialogTitle-root': {
          fontWeight: 600,
        },
      }}
      maxWidth="md"
      open={open}
      onClose={onCloseWindow}
      title={title}
      actions={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box>
            {showSwitch && (
              <FormControlLabel
                checked={showServiceWindow}
                name="ask-me"
                disabled={false}
                control={<Checkbox color="primary" />}
                label={<Box>{t('add_shipment_services.dismiss')}</Box>}
                onChange={showWindowToggle}
                data-testid="toggle-window"
              />
            )}
          </Box>
          <Box>
            <Button
              variant="text"
              onClick={onCloseWindow}
              size="large"
              data-testid="cancel-button"
            >
              {t('common.buttons.cancel')}
            </Button>
            <Button
              data-testid="send-button"
              variant="contained"
              onClick={sendServicesRequest}
              disabled={isEmpty(pickBy(additionalServices))}
              size="large"
            >
              {t('common.buttons.send_quote_request')}
            </Button>
          </Box>
        </Box>
      }
    >
      <DialogContent>
        <Typography sx={{ mt: 2 }} variant="body1" children={header} />
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
          <FormControl component="fieldset" onChange={onSelectService}>
            <FormGroup aria-label="position">
              {serviceItems.map((configuration: any) => {
                return (
                  <Tooltip
                    title={
                      defaultServices[configuration.value]
                        ? t('add_shipment_service_already_included')
                        : ''
                    }
                    placement="left"
                    key={configuration.value}
                  >
                    <FormControlLabel
                      key={configuration.label}
                      value={configuration.value}
                      checked={
                        defaultServices[configuration.value] ||
                        additionalServices[configuration.value]
                      }
                      name={configuration.label}
                      disabled={defaultServices[configuration.value]}
                      control={<Checkbox color="primary" />}
                      label={<Box>{configuration.label}</Box>}
                    />
                  </Tooltip>
                )
              })}
            </FormGroup>
          </FormControl>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
export default ShipmentServicesWindow

import { FunctionComponent } from 'react'
import { MentionsInput, Mention } from 'react-mentions'
import { Avatar } from 'src/stories/Avatar'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import { includes } from 'lodash'

interface IMentionAreaProps {
  value: string
  currentUserId: number
  followers: number[]
  mentionables: {
    admin_users: IChatUser[]
    users: IChatUser[]
  }
  onBlur: (content: string, event: React.FormEvent<HTMLElement>) => void
  onFocus: (event: React.FormEvent<HTMLElement>) => void
  submit: (content: string, event: React.FormEvent<HTMLElement>) => void
  onAddMention: (
    isWatcherAlert: boolean,
    mentionedUserOutOfOffice: string | null,
    mentionedUserFullName: string
  ) => void
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const transformMention = (
  id: number,
  display: string,
  type: string
): string => {
  return `@${display}`
}

const markup: string =
  "<span class='atwho-custom' data-atwho-id='__id__' data-atwho-type='__type__'>@__display__</span>"

const MentionArea: FunctionComponent<IMentionAreaProps> = (props) => {
  const mentionablesArray = (): IChatUser[] => {
    return props.mentionables.users
      .filter((user: IChatUser) => props.currentUserId !== user.id)
      .map((user: IChatUser) => ({
        ...user,
        display: `${user.full_name} | ${user.organization.name}`,
        full_name: `${user.full_name}`,
      }))
  }

  const onEnterPress = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (['Enter', 'NumpadEnter'].includes(event.code) && !event.shiftKey) {
      event.preventDefault()
      props.submit(props.value, event)
    }
  }

  const customMentions = (entry: IChatUser): React.ReactNode => {
    const isFollower = includes(props.followers, entry.id)
    return (
      <div
        className="mention-item--block"
        data-testid={`match-user-${entry.id}`}
      >
        <div className="mention-item--wrapper">
          <div className="mention-item--avatar">
            <Avatar
              userInfo={entry}
              size="medium"
              src={entry.avatar_thumb || 'no-logo'}
              alt={`${entry.first_name[0]}${entry.last_name[0]}`}
              doubleInitials={true}
              className={entry.organization.role_code}
            />
          </div>
          <div className="mention-item--display">
            <div className="mention-item--display__name">{entry.full_name}</div>
            <div className="mention-item--display__sep">|</div>
            <div className="mention-item--display__org">
              {entry.organization.name}
            </div>
          </div>
        </div>
        <div className="mention-item--wrapper">
          <div className="mention-item--display__status">
            {isFollower ? (
              <VisibilityIcon color="primary" />
            ) : (
              <VisibilityOffOutlinedIcon color="primary" />
            )}
          </div>
        </div>
      </div>
    )
  }
  return (
    <MentionsInput
      onKeyDown={onEnterPress}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      value={props.value}
      markup={markup}
      rows={4}
      placeholder="Type your message - use @name to add someone to the conversation"
      className="comments__form__inputs__base__container--input"
      data-testid="mention-input"
      displayTransform={transformMention}
    >
      <Mention
        trigger="@"
        appendSpaceOnAdd={true}
        onAdd={(id: number) => {
          props.onAddMention(
            !includes(props.followers, id),
            mentionablesArray().filter((e) => id === e.id)[0]
              .out_of_office_till,
            mentionablesArray().filter((e) => id === e.id)[0].full_name
          )
        }}
        data={mentionablesArray()}
        renderSuggestion={customMentions}
        type="User"
      />
    </MentionsInput>
  )
}

export default MentionArea

import { createReducer, Reducer } from 'redux-create-reducer'
import { AnyAction } from 'redux'
import { has, isEqual, omit } from 'lodash'
import { ModalityEnum, LoadTypeEnum } from 'src/config/constants'

declare global {
  interface IShipmentTag {
    name: string
    color: string
    code: string
  }

  interface IReportsShipmentTask {
    id: number
    shipment_id: number
    container_id: number
    due_date: null | string
    task_type_id: number
    task_status: TaskStatus
    task_status_id: number
    created_at: string
    updated_at: string
    resolved_at: string
  }

  interface IReportsShipment {
    id: number
    created_at: string
    loading_port: {
      name: string
    }
    discharge_port: {
      name: string
    }
    load_type: LoadTypeEnum
    modality: ModalityEnum
    our_reference: string | null
    status: string
    status_code: string
    owner_organization: IOrganization
    cortana_shipment_url: string
    initialized_by: string | null
    estimated_departure: string | null
    estimated_arrival: string | null
    follow_up?: string | null
    comment: IComment | null
    volume: string | null
    operation_type: string
    unique_id: number
    status_sequence: number
    margin_negative: boolean
    fixed_rates_enabled?: boolean
    service_department: {
      id: number
      code: string
      name: string
    }
    cargos: {
      summary: {
        count: number
        dangerous_goods: boolean
        stackable: boolean
      }
    }

    // those properties here listed for compatibility when fuifilling
    // them from update_status endpoint.
    finance_task?: IReportsShipmentTask | null
    finance_delivery_task?: IReportsShipmentTask | null
    destination_customs_clearance_task?: IReportsShipmentTask | null
    perform_customs_docs_check_task?: IReportsShipmentTask | null
    obtain_customs_documents_task?: IReportsShipmentTask | null
    obtain_hbl_task?: IReportsShipmentTask | null
    obtain_mbl_task?: IReportsShipmentTask | null
    release_task?: IReportsShipmentTask | null
    trucking_task?: IReportsShipmentTask | null
    communication_task?: IReportsShipmentTask | null
    inland_transport_status?: string
    inland_transport_id?: number
    track_trace_status?: string
  }

  type RollingStatus = 'to_be_checked' | 'confirmed' | 'declined'
  type DeliveryTaskType =
    | 'align_delivery_with_transporter'
    | 'finance_etd_check'
    | 'finance_delivery_check'
    | 'obtain_carrier/coloader_release'
    | 'obtain_delivery_date_from_consignee'
    | 'obtain_destination_customs_clearance'
    | 'perform_customs_documents_check'
    | 'obtain_customs_documents'
    | 'obtain_released_hbl'
    | 'obtain_released_mbl'
    | 'delivery'

  type TaskField =
    | 'finance_task'
    | 'finance_delivery_task'
    | 'destination_customs_clearance_task'
    | 'perform_customs_docs_check_task'
    | 'obtain_customs_documents_task'
    | 'obtain_hbl_task'
    | 'obtain_mbl_task'
    | 'release_task'
    | 'trucking_task'
    | 'communication_task'

  interface IDashboardBookingsPlacedShipment extends IReportsShipment {
    cargo_ready_date: string | null
  }

  interface IDashboardBookingConfirmedShipment extends IReportsShipment {
    hbl_status: string | null
    mbl_status: string | null
    tnt_status: string | null
    finance_task: IReportsShipmentTask | null
    status_sequence: number
    margin_negative: boolean
  }

  interface IDashboardInactiveTNTShipment extends IReportsShipment {
    status_sequence: number
    track_trace_status: string
  }

  interface IDashboardRecentDeliveriesShipment extends IReportsShipment {
    finance_delivery_task: IReportsShipmentTask | null
  }

  interface IDashboardOnHoldShipment extends IReportsShipment {
    carrier: any
    follow_up: string | null
    cargo_ready_date: string | null
  }

  interface IDashboardScheduleAnnouncedShipment extends IReportsShipment {
    carrier: any
    vessel: string | null
    finance_task: IReportsShipmentTask | null
    tags: IShipmentTag[]
  }

  interface IDashboardScheduleTbaShipment extends IReportsShipment {
    carrier: any
    finance_task: IReportsShipmentTask | null
    cargo_ready_date: string | null
  }

  interface IRollingsShipment extends IReportsShipment {
    rolling_status: RollingStatus
    rolling_reason: string
    current_vessel: string
    previous_vessel: string
    rolling_current_eta: string
    rolling_previous_eta: string
    rolling_updated_at: string
    author: IAuthor
    delay_days: string
  }

  interface IContainerEvents {
    actual_empty_gate_in: string | null
    actual_full_gate_out: string | null
    actual_first_day_of_demurrage: string | null
  }

  interface IHandoverShipment extends IReportsShipment {
    container_id: number
    container_number: string | null
    bl_number: string | null
    vessel: string | null
    pickup_delivery_time: string | null
    cortana_container_url: string
    destination_customs_clearance_task: IReportsShipmentTask | null
    perform_customs_docs_check_task: IReportsShipmentTask | null
    obtain_customs_documents_task: IReportsShipmentTask | null
    obtain_hbl_task: IReportsShipmentTask | null
    obtain_mbl_task: IReportsShipmentTask | null
    release_task: IReportsShipmentTask | null
    container_events: IContainerEvents
  }

  interface IDeliveriesShipment extends IHandoverShipment {
    delivery_date: string
    devanning_at: null | string
    trucking_task: IReportsShipmentTask | null
    communication_task: IReportsShipmentTask | null
    transport_window: number | null
    destination_demurrage_starting_from: string | null
  }

  interface IReportsDashboardFilters {
    page: number
    per_page: number
    search: string
    order_by: ISortOption
    load_type: string[]
    cluster: string[]
    modality: string[]
    pol: string[]
    pod: string[]
    organization: string[]
    service_department: string[]
    rolling_status: string[]
  }

  type ReportsDashboardTaskTypeStatuses = {
    [key in DeliveryTaskType]: TaskStatus[]
  }

  interface IReportsDashboardFilterOptions {
    load_types: string[][]
    modalities: string[][]
    service_departments: string[][]
    clusters: Array<Array<string | number>>
    task_type_statuses: ReportsDashboardTaskTypeStatuses
    rolling_statuses: string[][]
  }

  interface ICommentEditModal {
    open: boolean
    shipment: AnyReportsShipment | null
  }

  type AnyReportsShipment =
    | IDashboardBookingsPlacedShipment
    | IDashboardBookingConfirmedShipment
    | IDashboardOnHoldShipment
    | IDashboardScheduleAnnouncedShipment
    | IDashboardScheduleTbaShipment

  interface IReportsDashboardState {
    shipments: AnyReportsShipment[]
    totalShipmentsCount: number
    isLoading: boolean
    commentEditModal: ICommentEditModal
    scope: null | IReportsDashboardScope
    filters: IReportsDashboardFilters
    filterOptions: IReportsDashboardFilterOptions
    fetchingFilterOptions: boolean
    asyncFilterOptions: {
      polsWithNames: string[][]
      podsWithNames: string[][]
      organizationsWithNames: string[][]
    }
  }

  type ISortOption =
    | 'newest_first'
    | 'eta_asc'
    | 'eta_desc'
    | 'etd_asc'
    | 'etd_desc'
    | 'delivery_asc'
    | 'delivery_desc'
    | 'crd_asc'
    | 'crd_desc'

  type IReportsDashboardScope =
    | 'bookings_placed'
    | 'schedule_tba'
    | 'on_hold'
    | 'schedule_announced'
    | 'booking_confirmed'
    | 'rollings'
    | 'handover'
    | 'deliveries_lcl'
    | 'deliveries_fcl'
    | 'inactive_t_n_t'
    | 'recent_deliveries'

  type TaskStatus = {
    id: number
    name: string
    color?: 'orange' | 'green' | 'blue' | 'red' | 'grey' | 'white'
    icon?: string
    shortcut?: string
  }
}

const PER_PAGE = 50

const TASK_TYPES: TaskField[] = [
  'finance_task',
  'finance_delivery_task',
  'destination_customs_clearance_task',
  'perform_customs_docs_check_task',
  'obtain_customs_documents_task',
  'obtain_hbl_task',
  'obtain_mbl_task',
  'release_task',
  'trucking_task',
  'communication_task',
]

const filtersInitialState = {
  page: 1,
  per_page: PER_PAGE,
  order_by: 'newest_first' as ISortOption,
  search: '',
  load_type: [],
  cluster: [],
  modality: [],
  pol: [],
  pod: [],
  organization: [],
  service_department: [],
  rolling_status: [],
}

const initialAsyncFilterOptions = {
  polsWithNames: [],
  podsWithNames: [],
  organizationsWithNames: [],
}

export const initialCommentEditModalState = {
  open: false,
  shipment: null,
}

export const initialState: IReportsDashboardState = {
  shipments: [],
  totalShipmentsCount: 0,
  isLoading: true,
  commentEditModal: initialCommentEditModalState,
  scope: null,
  filters: filtersInitialState,
  filterOptions: {
    load_types: [],
    modalities: [],
    clusters: [],
    service_departments: [],
    task_type_statuses: {
      finance_etd_check: [],
      finance_delivery_check: [],
      align_delivery_with_transporter: [],
      obtain_delivery_date_from_consignee: [],
      'obtain_carrier/coloader_release': [],
      obtain_destination_customs_clearance: [],
      perform_customs_documents_check: [],
      obtain_customs_documents: [],
      obtain_released_hbl: [],
      obtain_released_mbl: [],
      delivery: [],
    },
    rolling_statuses: [],
  },
  fetchingFilterOptions: false,
  asyncFilterOptions: initialAsyncFilterOptions,
}

const receiveShipments: Reducer<IReportsDashboardState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  shipments: action.payload.shipments,
  totalShipmentsCount: action.payload.total_count,
  isLoading: false,
})

const updateScope: Reducer<IReportsDashboardState, AnyAction> = (
  state,
  action
) => {
  const switchTabConditions: any = { page: 1 }
  const deliverySortingMisplaced: boolean =
    !['handover', 'deliveries_lcl', 'deliveries_fcl'].includes(
      action.payload
    ) && ['delivery_desc', 'delivery_asc'].includes(state.filters.order_by)
  const crdSortingMisplaced: boolean =
    !['schedule_tba'].includes(action.payload) &&
    ['crd_desc', 'crd_asc'].includes(state.filters.order_by)

  const devanningAtSortingMisplaced: boolean =
    action.payload !== 'deliveries_lcl' &&
    ['devanning_asc', 'devanning_desc'].includes(state.filters.order_by)

  if (
    action.payload &&
    (deliverySortingMisplaced ||
      crdSortingMisplaced ||
      devanningAtSortingMisplaced)
  ) {
    switchTabConditions.order_by = 'newest_first'
  }

  return {
    ...state,
    scope: action.payload,
    filters: {
      ...state.filters,
      ...switchTabConditions,
    },
    isLoading: action.payload !== state.scope,
  }
}

const updateFilters: Reducer<IReportsDashboardState, AnyAction> = (
  state,
  action
) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      ...action.payload,
    },
  }
}

const updateAsyncFilters: Reducer<IReportsDashboardState, AnyAction> = (
  state,
  action
) => {
  const stateCopy = { ...state }
  stateCopy.asyncFilterOptions = {
    ...stateCopy.asyncFilterOptions,
    ...action.payload,
  }

  if (has(action.payload, 'polsWithNames')) {
    // Prevent re-rendering if equal: ['1'] != ['1'] in JS.
    const newPolIds = action.payload.polsWithNames.map(([name, id]) => id)
    if (!isEqual(newPolIds, stateCopy.filters.pol)) {
      stateCopy.filters = {
        ...state.filters,
        pol: newPolIds,
      }
    }
  }

  if (has(action.payload, 'podsWithNames')) {
    const newPodIds = action.payload.podsWithNames.map(([name, id]) => id)
    if (!isEqual(newPodIds, stateCopy.filters.pod)) {
      stateCopy.filters = {
        ...state.filters,
        pod: newPodIds,
      }
    }
  }

  if (has(action.payload, 'organizationsWithNames')) {
    const newOrganizationIds = action.payload.organizationsWithNames.map(
      ([name, id]) => id
    )

    if (!isEqual(newOrganizationIds, stateCopy.filters.organization)) {
      stateCopy.filters = {
        ...state.filters,
        organization: newOrganizationIds,
      }
    }
  }

  return stateCopy
}

const requestShipments: Reducer<IReportsDashboardState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  isLoading: !action.isBackgroundReload,
})

const requestFilterOptions: Reducer<IReportsDashboardState, AnyAction> = (
  state
) => ({
  ...state,
  fetchingFilterOptions: true,
})

const receiveFilterOptions: Reducer<IReportsDashboardState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  filterOptions: {
    ...state.filterOptions,
    ...action.payload,
  },
  fetchingFilterOptions: false,
})

const clearFilters: Reducer<IReportsDashboardState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  asyncFilterOptions: initialAsyncFilterOptions,
  filters: {
    ...state.filters,
    ...omit(filtersInitialState, ['scope', 'page', 'order_by']),
  },
})

const setEditCommentsModalInformation: Reducer<
  IReportsDashboardState,
  AnyAction
> = (state, action) => ({
  ...state,
  commentEditModal: {
    ...state.commentEditModal,
    ...action.payload,
  },
})

const updateShipmentComment: Reducer<IReportsDashboardState, AnyAction> = (
  state,
  action
) => {
  const shipments = [...state.shipments].map((item) => {
    if (action.payload.shipment_id === item.id) {
      item.comment = action.payload
    }
    return item
  })
  return {
    ...state,
    shipments: shipments,
  }
}

const deletedLastComment: Reducer<IReportsDashboardState, AnyAction> = (
  state,
  action
) => {
  const shipments = [...state.shipments].map((item) => {
    if (action.payload.shipmentId === item.id) {
      item.comment = action.payload.lastComment
    }
    return item
  })
  return {
    ...state,
    shipments,
  }
}

const updatedDeliveryStatus: Reducer<IReportsDashboardState, AnyAction> = (
  state,
  action
) => {
  const shipments = [...state.shipments].map((item) => {
    if (action.payload.id === item.inland_transport_id) {
      item.inland_transport_status = action.payload.status
    }
    return item
  })

  const commentEditModal = { ...state.commentEditModal }

  if (commentEditModal.shipment) {
    commentEditModal.shipment.inland_transport_status = action.payload.status
  }

  return {
    ...state,
    shipments,
    commentEditModal,
  }
}

const updatedTaskStatus: Reducer<IReportsDashboardState, AnyAction> = (
  state,
  action
) => {
  const shipments = [...state.shipments].map((item) => {
    if (action.payload.shipment_id === item.id) {
      TASK_TYPES.forEach((taskField: TaskField) => {
        if (
          item[taskField] &&
          (item[taskField] as IReportsShipmentTask).id === action.payload.id
        ) {
          ;(item[taskField] as IReportsShipmentTask).task_status_id =
            action.payload.task_status_id
        }
      })
    }
    return item
  })

  const commentEditModal = { ...state.commentEditModal }

  if (commentEditModal.shipment) {
    TASK_TYPES.forEach((taskField: TaskField) => {
      const shipment = commentEditModal.shipment as AnyReportsShipment
      if (
        shipment[taskField] &&
        (shipment[taskField] as IReportsShipmentTask).id === action.payload.id
      ) {
        ;(shipment[taskField] as IReportsShipmentTask).task_status_id =
          action.payload.task_status_id
      }
    })
  }

  return {
    ...state,
    shipments,
    commentEditModal,
  }
}

export default createReducer(initialState, {
  REPORTS_DASHBOARD_FETCH_DATA_SUCCESS: receiveShipments,
  REPORTS_DASHBOARD_FETCH_DATA: requestShipments,
  REPORTS_DASHBOARD_UPDATE_FILTERS: updateFilters,
  REPORTS_DASHBOARD_UPDATE_SCOPE: updateScope,
  REPORTS_DASHBOARD_UPDATE_ASYNC_FILTERS: updateAsyncFilters,
  REPORTS_DASHBOARD_FETCH_FILTER_OPTIONS_SUCCESS: receiveFilterOptions,
  REPORTS_DASHBOARD_FETCH_FILTER_OPTIONS: requestFilterOptions,
  REPORTS_DASHBOARD_CLEAR_FILTERS: clearFilters,
  REPORTS_DASHBOARD_SET_EDIT_COMMENT_INFO: setEditCommentsModalInformation,
  SHIPMENT_COMMENTS_CREATE_SUCCESS: updateShipmentComment,
  REPORTS_DASHBOARD_DELETED_LAST_COMMENT: deletedLastComment,
  REPORTS_DASHBOARD_UPDATE_TASK_STATUS_SUCCESS: updatedTaskStatus,
  UPDATE_CURRENT_MODALITY_SUCCESS: updatedDeliveryStatus,
})

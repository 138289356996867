import MuiDrawer, { DrawerProps } from '@mui/material/Drawer'
import Typography from '@mui/material/Typography'
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import IconButton from '@mui/material/IconButton'
import { Box } from '@mui/material'

export interface DrawerBarProps extends DrawerProps {
  onClose: () => void
  heading: JSX.Element | string
}

const Drawer: React.FC<DrawerBarProps> = (props) => {
  const {
    anchor = 'right',
    open,
    onClose,
    children,
    heading,
    ...otherProps
  } = props
  return (
    <MuiDrawer
      {...otherProps}
      anchor={anchor}
      open={open}
      onClose={onClose}
      hideBackdrop={true}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Box
          sx={{
            p: 3,
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h3">{heading}</Typography>
            <IconButton
              aria-label="close"
              onClick={onClose}
              size="large"
              data-testid="drawer-close"
            >
              <KeyboardTabIcon />
            </IconButton>
          </Box>
          {children}
        </Box>
      </ClickAwayListener>
    </MuiDrawer>
  )
}

export default Drawer

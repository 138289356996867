import { TableCell, Typography } from '@mui/material'
import { FC } from 'react'

interface IDisplayPriceTableProps {
  item: string
}

const priceLabelMap = {
  pickup: 'Pickup',
  delivery: 'Delivery',
  origin_port: 'Origin port',
  destination_port: 'Destination port',
  export_customs: 'Export declaration',
  import_customs: 'Import declaration',
  insurance: 'Cargo insurance',
  freight: 'Freight',
}

export const DisplayPriceTable: FC<IDisplayPriceTableProps> = ({ item }) => {
  const key = item.toLowerCase().split(' ').join('_')
  let text: string = priceLabelMap[key] ?? item

  return (
    <TableCell>
      <Typography variant="body1Strong" className="pl-16">
        {text}
      </Typography>
    </TableCell>
  )
}

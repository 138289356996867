import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import CheckIcon from '@mui/icons-material/Check'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import MuiLink from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { makeStyles } from '@mui/styles'
import { Typography, TypographyProps } from '@mui/material'
import MapExceptions from 'src/pages/MapOverviews/MapExceptions'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import MapOverviews from 'src/pages/MapOverviews'
import { useGetTaskManager } from 'src/services/Api/dashboard'
import { useGetOnlyWatchedShipmentsTrack } from 'src/services/Api/maps'
import { initialDashboardState } from 'src/stores/reducers/dashboard'
import { Skeleton } from 'src/stories/Skeleton'
import { Trans, useTranslation } from 'react-i18next'

interface DashboardTaskManagerProps {
  withMap: boolean
}

interface NumberBadgeProps {
  px: number
  count: number
  color: string
  iconSize: number
  typography?: TypographyProps['variant']
  avatarSize: number
}

interface DetailsTextProps {
  sx: object
  text: string
}

const smallScreen = {
  display: 'flex',
  justifyContent: 'space-between',
  margin: `0 0 20px 0`,
  width: '100%',
  paddingLeft: '0px',
}

const useStyles = makeStyles((theme) => ({
  stats: {
    flexDirection: 'row',
    [theme.breakpoints.between('sm', 'xl')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
    },
  },
  statsText: {
    [theme.breakpoints.between('sm', 'xl')]: {
      marginTop: theme.spacing(3),
    },
  },
  row: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridColumnGap: '20px',
    marginLeft: 0,
    [theme.breakpoints.down('lg')]: {
      ...smallScreen,
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    width: '100%',
    padding: 0,
    [theme.breakpoints.down('lg')]: {
      ...smallScreen,
    },
  },
}))

const NumberBadge: React.FC<NumberBadgeProps> = ({
  px,
  count,
  color,
  iconSize,
  avatarSize,
  typography = 'h2',
}) => {
  return (
    <Avatar
      sx={{
        width: 'auto',
        height: avatarSize,
        minWidth: avatarSize,
        color: `${color}.dark`,
        borderRadius: avatarSize / 2,
        backgroundColor: count > 0 ? `${color}.light` : 'success.light',
      }}
    >
      {count > 0 ? (
        <Typography variant={typography} px={px} color="inherit">
          {count}
        </Typography>
      ) : (
        <CheckIcon sx={{ fontSize: iconSize, color: 'success.dark' }} />
      )}
    </Avatar>
  )
}

const DetailText: React.FC<DetailsTextProps> = ({ sx, text }) => {
  return (
    <Typography sx={sx} variant="body1">
      {text}
    </Typography>
  )
}

const DashboardTaskManager: React.FC<DashboardTaskManagerProps> = ({
  withMap,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { onlyWatchedShipments, visibilityTrial } = useSelector(
    (state: IGlobalState) => ({
      onlyWatchedShipments: state.shipmentsWatchedBy.onlyWatchedShipments,
      visibilityTrial: state.user.organization_visibility_trial,
    })
  )

  const { data: shipmentsTrack } = useGetOnlyWatchedShipmentsTrack()
  const { data: watchedShipments, isLoading } = useGetTaskManager({
    only_watched_shipments: onlyWatchedShipments,
  })

  const total = shipmentsTrack?.data.total
  const isNoTotal: boolean = useMemo(() => total === 0, [total])

  const counters =
    watchedShipments?.data || initialDashboardState.taskManagerCounters
  const {
    mentions,
    approvals,
    proposed_quotes,
    unread_messages,
    incomplete_info,
  } = counters
  const overdue = counters?.unreached_milestones_count?.overdue_milestones_count

  const items = [
    {
      key: 'unread_messages',
      content: unread_messages
        ? t('dashboard.unread_messages.unread_messages', 'Unread messages')
        : t(
            'dashboard.unread_messages.no_unread_messages',
            'No unread messages'
          ),
      count: unread_messages,
      details: [
        {
          key: 'approvals',
          count: approvals,
          text: t(
            'dashboard.unread_messages.pending_approvals',
            'pending approvals'
          ),
        },
        {
          key: 'mentions',
          count: mentions,
          text: t('dashboard.unread_messages.mentions', 'mentions'),
        },
      ].filter(({ count }) => count && count > 0),
      color: 'secondary',
      detailsColor: 'secondary',
      detailsEmptyState:
        unread_messages > 0
          ? t(
              'dashboard.unread_messages.empty_state.uread_messages_other',
              'Better get cracking!',
              { count: 1 }
            )
          : t(
              'dashboard.unread_messages.empty_state.uread_messages_zero',
              "Don't worry, we still love you!",
              { count: 0 }
            ),
      actionText: unread_messages
        ? t('dashboard.common.respond_now', 'Respond now')
        : t('dashboard.common.go_to_message_center', 'Go to message center'),
      actionLink: '/message_centre',
    },
    {
      key: 'proposed_quotes',
      content: proposed_quotes
        ? t(
            'dashboard.proposed_quotes.outstanding_quotes',
            'Outstanding quotes'
          )
        : t(
            'dashboard.proposed_quotes.no_outstanding_quotes',
            'No outstanding quotes'
          ),
      count: proposed_quotes,
      details: [],
      color: 'info',
      detailsColor: 'error',
      detailsEmptyState:
        proposed_quotes > 0
          ? ''
          : t(
              'dashboard.proposed_quotes.empty_state.proposed_quotes_zero',
              'If you need us, you know where to find us.',
              { count: 0 }
            ),
      actionText: proposed_quotes
        ? t('dashboard.common.respond_now', 'Respond now')
        : t('dashboard.common.request_quote', 'Request a quote'),
      actionLink: '/quotes',
    },
    {
      key: 'incomplete_info',
      content: incomplete_info
        ? t('dashboard.incomplete_info.pending_tasks', 'Pending tasks')
        : t('dashboard.incomplete_info.no_pending_tasks', 'No pending tasks'),
      count: incomplete_info,
      details: overdue
        ? [
            {
              key: 'overdue',
              count: overdue,
              text: t('dashboard.incomplete_info.overdue', 'are overdue'),
            },
          ]
        : [],
      color: 'warning',
      detailsColor: 'error',
      detailsEmptyState:
        incomplete_info > 0
          ? t(
              'dashboard.incomplete_info.empty_state.incomplete_info_other',
              'Nothing is overdue yet!',
              { count: 1 }
            )
          : t(
              'dashboard.incomplete_info.empty_state.incomplete_info_zero',
              'Well done! Everything is up to date.',
              { count: 0 }
            ),
      actionText: incomplete_info
        ? t('dashboard.common.respond_now', 'Respond now')
        : t('dashboard.common.go_to_shipments', 'Go to shipments'),
      actionLink: incomplete_info
        ? '/shipments?all_missing_information=true'
        : '/shipments',
    },
  ]

  const isMapVisible = withMap

  const avatarSize = 70

  const emptyState = visibilityTrial ? (
    <div className="map-overview--exceptions">
      <Trans
        i18nKey={'dashboard.map.empty_state.add_shipment'}
        defaults="<shipmentsLink>Add a shipment</shipmentsLink>, create a new <searchLink>booking request</searchLink> or have a cup of tea."
        components={{
          shipmentsLink: <MuiLink component={Link} to="/shipments" />,
          searchLink: (
            <MuiLink sx={{ ml: 0.5, mr: 0.5 }} component={Link} to="/search" />
          ),
        }}
      />
    </div>
  ) : (
    <div className="map-overview--exceptions">
      <Trans
        i18nKey={'dashboard.map.empty_state.review_quotes'}
        defaults="Review your <quotesLink>quotes</quotesLink>, create a new <searchLink>booking request</searchLink> or have a cup of tea."
        components={{
          quotesLink: (
            <MuiLink sx={{ ml: 0.5 }} component={Link} to="/quotes" />
          ),
          searchLink: (
            <MuiLink sx={{ ml: 0.5, mr: 0.5 }} component={Link} to="/search" />
          ),
        }}
      />
    </div>
  )

  const mainInfoHeader = (
    <Typography variant="h3">
      {isNoTotal &&
        t(
          'dashboard.main_header.no_shipments',
          'You have no shipments in transit'
        )}
      {!isNoTotal &&
        t(
          'dashboard.main_header.total_shipments',
          'You have {{total}} tracked shipments',
          { total }
        )}
    </Typography>
  )

  const infoSubHeader = isNoTotal ? emptyState : <MapExceptions />

  return (
    <Grid container spacing={2.5} data-testid="dashboard-task-manager">
      <Grid item sx={{ flexGrow: 1 }} sm={12} md={isMapVisible ? 4 : 12}>
        <Grid
          container
          columnSpacing={{ sm: 0 }}
          rowSpacing={2.5}
          color={'primary.main'}
          className={!isMapVisible ? classes.row : classes.column}
        >
          {items.map((item, index) => {
            const { count, color } = item
            return (
              <Grid
                data-testid={`${item.key}-component`}
                item
                sx={{
                  display: 'flex',
                  flex: 1,
                  minWidth: '100%',
                }}
                md={4}
                xs={12}
                key={item.key}
                sm={index === items.length - 1 ? 12 : 6}
              >
                <Paper
                  sx={{
                    p: 3,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                  }}
                >
                  <Paper
                    elevation={0}
                    classes={{
                      root: classes.stats,
                    }}
                    sx={{
                      mb: 2,
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      mr={2}
                      variant="h3"
                      sx={{ flexGrow: 1, width: '100%' }}
                      classes={{
                        root: classes.statsText,
                      }}
                    >
                      {isLoading ? <Skeleton /> : item.content}
                    </Typography>
                    <Box sx={{ flexGrow: 0 }}>
                      {isLoading ? (
                        <Skeleton
                          animation="wave"
                          variant="circular"
                          width={avatarSize}
                          height={avatarSize}
                        />
                      ) : (
                        <NumberBadge
                          px={2}
                          count={count}
                          color={color}
                          iconSize={32}
                          typography="h1"
                          avatarSize={avatarSize}
                        />
                      )}
                    </Box>
                  </Paper>
                  {item.details.length > 0 ? (
                    <Stack mb={2} spacing={3} direction="row">
                      {item.details.map((detail) => (
                        <Box
                          key={detail.key}
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {detail.count > 0 && (
                            <NumberBadge
                              px={1}
                              iconSize={15}
                              avatarSize={32}
                              typography="body1"
                              count={detail.count}
                              color={item.detailsColor}
                            />
                          )}
                          <DetailText sx={{ ml: 1 }} text={detail.text} />
                        </Box>
                      ))}
                    </Stack>
                  ) : (
                    <Box sx={{ height: '40px', width: '100%' }}>
                      {isLoading ? (
                        <Skeleton
                          animation="wave"
                          sx={{ width: '70%', height: '50%' }}
                        />
                      ) : (
                        <DetailText
                          sx={{ mb: 3 }}
                          text={item.detailsEmptyState}
                        />
                      )}
                    </Box>
                  )}
                  <Box sx={{ width: '100%' }}>
                    {isLoading ? (
                      <Skeleton
                        width="40%"
                        height={36}
                        animation="wave"
                        variant="rectangular"
                        sx={{ borderRadius: 1 }}
                      />
                    ) : (
                      <Button
                        component={Link}
                        variant="outlined"
                        to={item.actionLink}
                        endIcon={<ArrowForwardRoundedIcon />}
                        size="large"
                      >
                        {item.actionText}
                      </Button>
                    )}
                  </Box>
                </Paper>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
      {isMapVisible && (
        <Grid
          item
          sm={12}
          md={8}
          sx={{ flex: 1, display: 'flex' }}
          data-testid="map-component"
        >
          <Paper sx={{ p: 4, flex: 1 }}>
            <Box pb={2} pt={1}>
              {isLoading ? (
                <Skeleton width="50%" height={30} />
              ) : (
                mainInfoHeader
              )}
            </Box>
            <Box pb={2} pt={1}>
              {isLoading ? <Skeleton width="70%" height={27} /> : infoSubHeader}
            </Box>
            <MapOverviews />
          </Paper>
        </Grid>
      )}
    </Grid>
  )
}
export default DashboardTaskManager

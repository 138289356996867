import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import * as actions from '../actions/shipmentPrice'

declare global {
  interface IShipmentOrganization {
    id: number
    logo: string | null
    manageable: boolean
    name: string
    role: string
    role_code: string
    result_type?: string
  }

  interface ITransactionItem {
    buyer_organization: IShipmentOrganization
    currency: string
    description: string
    id: number
    included: boolean
    on_request: boolean
    quantity: string
    seller_organization: IShipmentOrganization
    service_item: IServiceItem
    shipment: {
      id: number
      our_reference: string
      reference: string | null
      url: string
    }
    total_eur: string
    total_usd: string
    unit_price: string
  }

  interface IShipmentPriceState {
    transactionItems: ITransactionItem[]
    showOnlyOriginalCurrency: boolean
  }
}
export const initialShipmentPriceState = {
  transactionItems: [],
  showOnlyOriginalCurrency: false,
}

const receiveTransactionItems: Reducer<IShipmentPriceState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  transactionItems: action.payload.data,
  showOnlyOriginalCurrency: action.payload.meta.show_only_original_currency,
})

export default createReducer(initialShipmentPriceState, {
  [actions.SHIPMENT_TRANSACTION_ITEMS_GET_DATA_SUCCESS]: receiveTransactionItems,
})

import { AnyAction } from 'redux'
import * as actions from '../actions/purchaseOrders'

export const uploadPDFDocument = (payload: any): AnyAction => ({
  type: actions.UPLOAD_PDF_DOCUMENT,
  payload,
})

export const purchaseOrdersGetData = (payload?: any): AnyAction => ({
  type: actions.PURCHASE_ORDERS_GET_DATA,
  payload,
})

export const purchaseOrdersGetDataSuccess = (payload: any): AnyAction => ({
  type: actions.PURCHASE_ORDERS_GET_DATA_SUCCESS,
  payload,
})

export const purchaseOrdersGetMeta = (payload?: any): AnyAction => ({
  type: actions.PURCHASE_ORDERS_GET_META,
  payload,
})

export const purchaseOrdersGetMetaSuccess = (payload: any): AnyAction => ({
  type: actions.PURCHASE_ORDERS_GET_META_SUCCESS,
  payload,
})

export const purchaseOrdersGetFiltersData = (payload?: any): AnyAction => ({
  type: actions.PURCHASE_ORDERS_GET_FILTERS_DATA,
  payload,
})

export const purchaseOrdersGetFiltersDataSuccess = (
  payload: any
): AnyAction => ({
  type: actions.PURCHASE_ORDERS_GET_FILTERS_DATA_SUCCESS,
  payload,
})

export const purchaseOrdersOpenItem = (payload: number | null): AnyAction => ({
  type: actions.PURCHASE_ORDERS_OPEN_ITEM,
  payload,
})

export const purchaseOrderCreate = (payload: any): AnyAction => ({
  type: actions.PURCHASE_ORDERS_CREATE,
  payload,
})

export const purchaseOrderCreateSuccess = (payload: any): AnyAction => ({
  type: actions.PURCHASE_ORDERS_CREATE_SUCCESS,
  payload,
})

export const purchaseOrdersClear = (): AnyAction => ({
  type: actions.PURCHASE_ORDERS_CLEAR,
})

export const purchaseOrdersSetAssignableStatuses = (payload): AnyAction => ({
  type: actions.PURCHASE_ORDERS_SET_ASSIGNABLE_STATUSES,
  payload,
})

export const purchaseOrdersCheckUnique = (payload: any): AnyAction => ({
  type: actions.PURCHASE_ORDERS_CHECK_UNIQUE,
  payload,
})

export const purchaseOrdersCheckUniqueSuccess = (payload: any): AnyAction => ({
  type: actions.PURCHASE_ORDERS_CHECK_UNIQUE_SUCCESS,
  payload,
})

export const updatePurchaseOrder = (id: string, payload: any): AnyAction => ({
  type: actions.UPDATE_PURCHASE_ORDER,
  id,
  payload,
})

export const updatePurchaseOrderSuccess = (payload: any): AnyAction => ({
  type: actions.UPDATE_PURCHASE_ORDER_SUCCESS,
  payload,
})

export const assignSellerOrganization = (
  id: string,
  payload: any
): AnyAction => ({
  type: actions.ASSIGN_SELLER_ORGANIZATION,
  id,
  payload,
})

export const removePurchaseOrdersFromShipment = (payload: any): AnyAction => ({
  type: actions.REMOVE_PURCHASE_ORDERS_FROM_SHIPMENT,
  payload,
})

export const assignPurchaseOrdersToShipment = (payload: any): AnyAction => ({
  type: actions.ASSIGN_PURCHASE_ORDERS_TO_SHIPMENT,
  payload,
})

export const deletePurchaseOrder = (id: number): AnyAction => ({
  type: actions.DELETE_PURCHASE_ORDER,
  id,
})

export const deletePurchaseOrderSuccess = (payload: any): AnyAction => ({
  type: actions.DELETE_PURCHASE_ORDER_SUCCESS,
  payload,
})

export const triggerFetchingOrders = (): AnyAction => ({
  type: actions.TRIGGER_FETCHING_ORDERS,
})

export const triggerAddOrderModal = (): AnyAction => ({
  type: actions.TRIGGER_OPEN_ADD_MODAL,
})

export const clearAddModalCount = (): AnyAction => ({
  type: actions.CLEAR_OPEN_ADD_MODAL_COUNT,
})

export const purchaseOrderDismissStatuses = (status: string): AnyAction => ({
  type: actions.PURCHASE_ORDER_DISMISS_STATUSES,
  status,
})

export const purchaseOrderUnmarkException = (
  id: number,
  payload: any
): AnyAction => ({
  type: actions.PURCHASE_ORDER_UNMARK_EXCEPTION,
  id,
  payload,
})

export const purchaseOrderShareEmail = (
  id: string,
  payload: any
): AnyAction => ({
  type: actions.PURCHASE_ORDER_SHARE_EMAIL,
  id,
  payload,
})

export const resetInfoRequests = (id: number, payload: any): AnyAction => ({
  type: actions.RESET_INFO_REQUESTS,
  id,
  payload,
})

export const resetInfoRequestsSuccess = (payload: any): AnyAction => ({
  type: actions.RESET_INFO_REQUESTS_SUCCESS,
  payload,
})

export const togglePOSelect = (payload: number): AnyAction => ({
  type: actions.TOGGLE_SELECTED,
  payload,
})

export const setPOSelect = (payload: number[]): AnyAction => ({
  type: actions.SET_SELECTED,
  payload,
})

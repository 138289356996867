import { FunctionComponent, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import Input from 'src/components/Common/Input/MuiInput'
import {
  trackAndTraceGetData,
  trackTraceSubscriptionCreate,
  shipmentOverviewGetData,
} from 'src/stores/actionCreators'
import { promisifyAction } from 'src/utils'
import { keyTypeOptions, tableColumns } from '../../constants'
import TrackAndTraceSubscriptionRow from './TrackAndTraceSubscriptionRow'

const initialFormData = {
  tracking_key: '',
  tracking_number: '',
}

const TrackAndTraceSubscriptionsTable: FunctionComponent = () => {
  const dispatch = useDispatch()
  const [formOpen, setFormOpen] = useState(false)
  const [formData, setFormData] = useState(initialFormData)

  const {
    shipmentId,
    shipmentModality,
    track_trace_subscriptions,
  } = useSelector((state: IGlobalState) => ({
    shipmentId: state.shipmentOverview.id,
    shipmentModality: state.shipmentOverview.modality,
    track_trace_subscriptions: state.shipmentOverview.track_trace_subscriptions,
  }))

  const trackAndTraceGetDataAsync = promisifyAction(
    dispatch,
    trackAndTraceGetData
  )
  const trackTraceSubscriptionCreateAsync = promisifyAction(
    dispatch,
    trackTraceSubscriptionCreate
  )

  useEffect(() => {
    if (shipmentId) {
      const getData = async () => {
        await trackAndTraceGetDataAsync(shipmentId)
      }
      getData()
    }
  }, [shipmentId])

  const onKeyTypeSelect = (event: SelectChangeEvent<unknown>) => {
    setFormData({ ...formData, tracking_key: event.target.value as string })
  }

  const onKeyNumberChange = (value) => {
    if (shipmentModality === 'air') {
      setFormData({
        ...formData,
        tracking_key: 'air_waybill',
        tracking_number: value,
      })
    } else {
      setFormData({ ...formData, tracking_number: value })
    }
  }

  const onFormSubmit = async () => {
    try {
      await trackTraceSubscriptionCreateAsync(shipmentId, formData)
      await trackAndTraceGetDataAsync(shipmentId)
      dispatch(shipmentOverviewGetData(shipmentId))
      dispatch(
        showNotification({
          message: 'Track and trace subscription has been created.',
          severity: 'success',
        })
      )
    } catch (error) {
      const err = (error as any).response.data
      dispatch(
        showNotification({
          message: err?.error || err,
          severity: 'error',
        })
      )
    }

    setFormOpen(false)
    setFormData(initialFormData)
  }

  const newSubscriptionForm = () => {
    return (
      <Box
        className="new-subscription-form"
        sx={{
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'primary.100',
          borderRadius: 1,
          p: 1.5,
          mb: 1,
          minHeight: 44,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor: 'white',
          cursor: 'pointer',
          marginTop: 2,
          width: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Box width={200}>
              <InputLabel>Key type</InputLabel>
              <SingleSelect
                placeholder="Key type"
                onChange={onKeyTypeSelect}
                value={formData.tracking_key}
                MenuProps={{ disablePortal: true }}
                disabled={shipmentModality === 'air'}
                options={keyTypeOptions[shipmentModality]}
                data-testid="track-and-trace-sub-table-key-select"
                inputProps={{
                  'data-testid': 'track-and-trace-sub-table-key-select-input',
                }}
              />
            </Box>
            <Box sx={{ ml: 2, width: '240px' }}>
              <Input
                label="Tracking key"
                name="tracking_key"
                placeholder="MRCK1234567"
                className="input-field"
                value={formData.tracking_number}
                onChange={onKeyNumberChange}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', pt: 3.5 }}>
            <Button
              variant="text"
              size="large"
              sx={{ marginRight: 1 }}
              onClick={() => {
                setFormOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              size="large"
              variant="contained"
              onClick={onFormSubmit}
              disabled={!formData.tracking_key || !formData.tracking_number}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    )
  }

  const renderTable = () => {
    return (
      <TableContainer
        sx={{ mb: 2 }}
        component={(props) => (
          <Paper
            {...props}
            square
            elevation={0}
            sx={{ maxHeight: '100%', width: '50vw' }}
          />
        )}
      >
        <Table sx={{ tableLayout: 'auto' }}>
          <TableHead>
            <TableRow>
              {tableColumns.map((column, idx) => (
                <TableCell
                  key={`${column}-header`}
                  className={tableColumns.length - 1 === idx ? 'short' : ''}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {track_trace_subscriptions?.length
              ? track_trace_subscriptions.map((row, i) => (
                  <TrackAndTraceSubscriptionRow
                    key={`${row.id}-${i}`}
                    row={row}
                    shipmentId={shipmentId}
                  />
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <Box mt={3} data-testid="t-and-t-and-trace-subscription-table">
      <Box mb={1}>
        <Typography variant="h2">Track and trace subscription</Typography>
      </Box>
      {renderTable()}
      {formOpen ? (
        newSubscriptionForm()
      ) : (
        <Button
          variant="text"
          color="primary"
          size="large"
          onClick={() => {
            setFormOpen(true)
          }}
          startIcon={<AddRoundedIcon />}
        >
          Add subscription
        </Button>
      )}
    </Box>
  )
}

export default TrackAndTraceSubscriptionsTable

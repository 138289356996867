import { FunctionComponent, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { createShipmentComment } from 'src/stores/actionCreators'
import Textarea from 'src/components/Common/Textarea'
import { Button } from '@mui/material'

interface IProps {
  shipmentId: number
}

const EditShipmentField: FunctionComponent<IProps> = ({ shipmentId }) => {
  const dispatch = useDispatch()

  const [text, setText] = useState<string>('')
  const [focused, setFocused] = useState<boolean>(false)
  const { t } = useTranslation()
  const onSubmit = useCallback(async () => {
    if (shipmentId) {
      dispatch(createShipmentComment(shipmentId, { body: text }))
      setText('')
    }
  }, [shipmentId, text])

  return (
    <div className={`${focused ? 'focused' : ''} edit-comment__input`}>
      <Textarea
        value={text}
        onChange={setText}
        placeholder={t(
          'transports.drawer.comment_description',
          'Add a comment or reply here...'
        )}
        className="edit-comment__input-textarea"
        autofocus={false}
        onFocus={setFocused.bind(null, true)}
        onBlur={setFocused.bind(null, false)}
        rows={2}
        onKeyDown={(e) => {
          if (['Enter', 'NumpadEnter'].includes(e.code)) {
            e.preventDefault()
            onSubmit()
          } else return
        }}
      />
      <Button variant="contained" onClick={onSubmit} disabled={!text}>
        {t('transports.drawer.comment_send', 'Send')}
      </Button>
    </div>
  )
}
export default EditShipmentField

import { AnyAction } from 'redux'
import * as actions from '../actions/reports'

export const reportsGetShipmentsCounts = (payload: any): AnyAction => ({
  type: actions.REPORTS_GET_COUNTS_OF_SHIPMENTS,
  payload,
})

export const reportsGetShipmentsCountsSuccess = (payload: any): AnyAction => ({
  type: actions.REPORTS_GET_COUNTS_OF_SHIPMENTS_SUCCESS,
  payload,
})

export const reportsGetShipmentsCosts = (payload: any): AnyAction => ({
  type: actions.REPORTS_GET_COSTS_OF_SHIPMENTS,
  payload,
})

export const reportsGetShipmentsCostsSuccess = (payload: any): AnyAction => ({
  type: actions.REPORTS_GET_COSTS_OF_SHIPMENTS_SUCCESS,
  payload,
})

import { AnyAction } from 'redux'
import * as actions from '../actions/searches'

export const searchQuotes = (payload: any): AnyAction => ({
  type: actions.SEARCH_QUOTES,
  payload,
})
export const searchQuotesReset = (): AnyAction => ({
  type: actions.SEARCH_QUOTES_RESET,
})

export const searchQuotesSuccess = (payload: any): AnyAction => ({
  type: actions.SEARCH_QUOTES_SUCCESS,
  payload,
})
export const searchQuotesError = (): AnyAction => ({
  type: actions.SEARCH_QUOTES_ERROR,
})
export const searchQuotesSort = (payload: any): AnyAction => ({
  type: actions.SEARCH_QUOTES_SORT,
  payload,
})

export const getLinkedCustomers = (payload: IOrganization[]): AnyAction => ({
  type: actions.LINKED_CUSTOMERS,
  payload,
})
export const getLinkedCustomersSuccess = (
  payload: IOrganization[]
): AnyAction => ({
  type: actions.LINKED_CUSTOMERS_SUCCESS,
  payload,
})
export const getLinkedCustomersError = (): AnyAction => ({
  type: actions.LINKED_CUSTOMERS_ERROR,
})

export const getCarriers = (payload: any): AnyAction => ({
  type: actions.GET_CARRIERS,
  payload,
})
export const getCarriersSuccess = (payload: any): AnyAction => ({
  type: actions.GET_CARRIERS_SUCCESS,
  payload,
})

export const getVessels = (payload: any): AnyAction => ({
  type: actions.GET_VESSELS,
  payload,
})
export const getVesselsSuccess = (payload: any): AnyAction => ({
  type: actions.GET_VESSELS_SUCCESS,
  payload,
})

export const getCarrierOrganizations = (payload: any): AnyAction => ({
  type: actions.GET_CARRIER_ORGANIZATIONS,
  payload,
})

export const getCarrierOrganizationsSuccess = (payload: any): AnyAction => ({
  type: actions.GET_CARRIER_ORGANIZATIONS_SUCCESS,
  payload,
})

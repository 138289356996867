import { useMemo, FunctionComponent, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { parse } from 'query-string'
import { Box } from '@mui/material'
import RoomServiceIcon from '@mui/icons-material/RoomService'
import { EmptyState } from 'src/stories'
import ShipmentCostsRequestServiceModal from '../ShipmentCostsRequestServiceModal'

const ShipmentCostsVisibilityEmptyState: FunctionComponent<{}> = () => {
  const location = useLocation()
  const { shipmentId, collaborators } = useSelector((state: IGlobalState) => ({
    shipmentId: state.shipmentOverview.id,
    collaborators: state.shipmentOverview.collaborators,
  }))
  const params = parse(location.search)
  const [isOpen, setOpen] = useState<boolean>(params.modalOpen === 'true')

  useEffect(() => {
    if (params.modalOpen !== `${isOpen}`) {
      setOpen(params.modalOpen === 'true')
    }
  }, [location.search, isOpen])

  const visibilityProviderName: string = useMemo(() => {
    return (
      collaborators?.find((i) =>
        i.roles.find((role) => role.role === 'Visibility provider')
      )?.name ?? 'Shypple'
    )
  }, [collaborators])

  return (
    <Box data-testid="visibility-empty-state" sx={{ background: 'white' }}>
      <EmptyState
        badgeColor="info"
        badgeIcon={RoomServiceIcon}
        title="We're at your service"
        button={{
          text: 'Request services',
          link: `/shipments/${shipmentId}/price-structure?modalOpen=true`,
        }}
        description={`${visibilityProviderName} can help you with local handling, customs declarations, inland transport and more.`}
      />
      <ShipmentCostsRequestServiceModal
        isOpen={isOpen}
        shipmentId={shipmentId}
      />
    </Box>
  )
}

export default ShipmentCostsVisibilityEmptyState

// https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table

const timeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

const mapPatternsToIntlOptions = {
  yy: { year: '2-digit' },
  yyyy: { year: 'numeric' },
  MM: { month: '2-digit' },
  MMM: { month: 'short' },
  MMMM: { month: 'long' },
  dd: { day: '2-digit' },
}

// ignoring literals from the locale completely, which is not good, but:
// 1. Intl is not allowing (?) to have different language and diferent culture code
// 2. Shypple is not following propper localization, so date time formats could be different every time
const format = (date: Date, pattern: string, locale: string = 'en-US') => {
  const parts = pattern.match(/(.)\1*/g)
  if (!parts) return ''
  //['day', 'literal', 'month']
  const order = parts.map((part) =>
    Object.keys(mapPatternsToIntlOptions).includes(part)
      ? Object.keys(mapPatternsToIntlOptions[part])[0]
      : part
  )
  //   {
  //     day: '2-digit',
  //     month: 'short',
  //   }
  const options = parts.reduce((result, part) => {
    return { ...result, ...mapPatternsToIntlOptions[part] }
  }, {})
  const formatter = new Intl.DateTimeFormat(locale, options)
  return formatter
    .formatToParts(date)
    .reduce((result, part) => {
      const index = result.indexOf(part.type)
      result[index] = part.value
      return result
    }, order)
    .join('')
}

const addMonth = (date: Date, month: number) => {
  return new Date(date.setMonth(date.getMonth() + month))
}

const toISODateString = (date: Date) => {
  return date.toISOString().split('T')[0]
}

export default {
  format,
  addMonth,
  timeZone,
  toISODateString,
}

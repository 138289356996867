import { makeStyles } from '@mui/styles'

export const CargoRow = makeStyles((theme) => ({
  dimensions: {
    alignItems: 'center',
    borderRadius: '5px',
    border: `1px solid`,
    textIndent: '5px',
    padding: '1px',
    display: 'flex',
    width: '100%',
    height: 38,
    borderColor: (props: any) =>
      props.error ? theme.palette.error.main : theme.palette.grey[400],

    '&:focus-within': {
      border: `1px solid ${theme.palette.primary.main}`,
    },

    '& > *': {
      color: theme.palette.primary.main,
      textAlign: 'center',
      boxSizing: 'border-box',
      padding: 0,
    },
    '& .MuiInputBase-root': {
      border: 'none',
      margin: '0 4px',

      '& .MuiInputBase-input': {
        padding: '0',
      },
    },

    '& .unit': {
      marginRight: '5px',
    },
  },
  input: {
    textAlign: 'center',
  },
  error: {
    color: theme.palette.error.main,
    fontSize: 13,
  },
}))

export const SABStyles = makeStyles((theme) => ({
  paper: {
    padding: '1rem',
    maxWidth: '1150px',
    marginLeft: '2rem',
    '& .x-long': {
      width: '175px',
    },
    '& .x-short': {
      width: '41px',
    },
    '& .x-medium': {
      width: '80px',
    },
    '& .short': {
      width: '70px',
    },
    '& .MuiTableCell-sizeMedium': {
      paddingTop: 8,
      paddingBottom: 8,
      height: 40,
      verticalAlign: 'top',

      '& p, & h6': {
        whiteSpace: 'normal',
      },
    },
  },
}))

export const WATCH_SHIPMENT_RULE_CREATE = 'WATCH_SHIPMENT_RULE_CREATE'
export const WATCH_SHIPMENT_RULE_UPDATE = 'WATCH_SHIPMENT_RULE_UPDATE'
export const WATCH_SHIPMENT_RULE_DELETE = 'WATCH_SHIPMENT_RULE_DELETE'
export const WATCH_SHIPMENT_RULE_SYNC = 'WATCH_SHIPMENT_RULE_SYNC'
export const WATCH_SHIPMENT_RULE_GET_SHIPPERS =
  'WATCH_SHIPMENT_RULE_GET_SHIPPERS'
export const WATCH_SHIPMENT_RULE_GET_SHIPPERS_SUCCESS =
  'WATCH_SHIPMENT_RULE_GET_SHIPPERS_SUCCESS'
export const WATCH_SHIPMENT_RULE_TOGGLE_MODAL =
  'WATCH_SHIPMENT_RULE_TOGGLE_MODAL'
export const WATCH_SHIPMENT_RULE_SET_CURRENT_RULE =
  'WATCH_SHIPMENT_RULE_SET_CURRENT_RULE'

import { SetStateAction, useState } from 'react'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Menu from '@mui/material/Menu'
import { makeStyles } from '@mui/styles'
import MenuItem from '@mui/material/MenuItem'
import ListSubheader from '@mui/material/ListSubheader'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import groupBy from 'lodash/groupBy'
import {
  palette,
  getShipmentStatusChipColorBasedOnShipmentPhase,
} from '../../../constants'
import { StatusProps, ShipmentMenuStatusProps } from '../shipmentContentProps'

const useStyles = makeStyles((theme) => {
  return {
    arrow: {
      '& svg': {
        transition: theme.transitions.create(['transform'], {
          duration: theme.transitions.duration.complex,
        }),
      },
    },
    chipIconOpened: {
      '& svg': {
        transform: 'rotate(180deg)',
      },
    },
    chipIconClosed: {
      '& svg': {
        transform: 'rotate(0)',
      },
    },
    chipBox: {
      ...palette.reduce((res, color) => {
        const chipColors = {
          color: theme.palette[color].dark,
          borderColor: theme.palette[color].dark,
          backgroundColor: theme.palette[color].light,
        }
        return {
          ...res,
          [`&.${color}`]: {
            '& .MuiChip-root': {
              ...chipColors,
              '& .MuiChip-label': {
                color: theme.palette[color].dark,
              },
              '&:focus': {
                ...chipColors,
              },
              '&:hover': {
                backgroundColor: theme.palette[color][100],
              },
            },
          },
        }
      }, {}),
    },
  }
})

export const ShipmentStatusMenu = ({
  onStatusChange,
  updating,
  statuses,
  status,
}: ShipmentMenuStatusProps) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: {
    currentTarget: SetStateAction<HTMLElement | null>
  }) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const groups = groupBy(
    statuses,
    (shipmentStatusItem) => shipmentStatusItem.group
  )

  const renderSelectGroup = (statusGroup: string) => {
    const items = groups[statusGroup].map((item: StatusProps) => {
      const { id } = item
      const isStatusSelected = `${status.id}` === `${id}`
      return (
        <MenuItem
          key={id}
          value={item.name}
          selected={isStatusSelected}
          onClick={() => {
            handleClose()
            if (isStatusSelected) {
              return
            }
            if (onStatusChange) {
              onStatusChange(item)
            }
          }}
        >
          {item.name}
        </MenuItem>
      )
    })
    return [<ListSubheader>{statusGroup}</ListSubheader>, items]
  }

  return (
    <>
      <Box
        className={`${getShipmentStatusChipColorBasedOnShipmentPhase(
          status.phase
        )} ${classes.chipBox}`}
      >
        <Chip
          size="small"
          disabled={updating}
          onClick={handleClick}
          label={
            <Box
              display="flex"
              alignItems="center"
              className={`${classes.arrow} ${
                open ? classes.chipIconOpened : classes.chipIconClosed
              }`}
            >
              {status.name}{' '}
              <KeyboardArrowDownIcon sx={{ ml: 0.5, fontSize: '1rem' }} />
            </Box>
          }
        />
      </Box>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{ style: { maxHeight: 300 } }}
      >
        {Object.keys(groups).map((group) => renderSelectGroup(group))}
      </Menu>
    </>
  )
}

import { ModalityEnum } from 'src/config/constants'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

interface TimelineFlightNumberProps {
  modality: ModalityEnum
  flightNumber: ShipmentLegFlightNumber
}

export const TimelineFlightNumber: React.FC<TimelineFlightNumberProps> = ({
  modality,
  flightNumber,
}) => {
  const { t } = useTranslation()
  if (modality !== ModalityEnum.Air) {
    return null
  }
  const text = flightNumber
    ? t('shipment.timeline.flight_number', {
        defaultValue: 'Flight: {{flightNumber}}',
        flightNumber,
      })
    : t('shipment.timeline.flight_number_not_available', {
        defaultValue: 'Flight: To be announced',
      })
  return (
    <Typography variant="subtitle2" color="text.secondary" children={text} />
  )
}

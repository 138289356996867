import DateTime from 'src/utils/DateTime'

export function getStyles(month: string) {
  const toHighlight = DateTime.format(new Date(), 'MMMM yyyy') === month
  const fillValue = toHighlight ? '#000' : '#98a0a5'
  const fontWeight = toHighlight ? 'bold' : 'normal'

  return { fillValue, fontWeight }
}

export function extractLabelFromPayload(value: string = '') {
  return value.substring(0, 3) || ''
}

import { FunctionComponent, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import Map from 'src/components/Map'
import CancelShipmentModal from 'src/components/CancelShipmentModal'
import useGoBack from '../../hooks/useGoBack'
import { mapModalDisplayChange } from '../../stores/actionCreators'
import ShipmentOverviewMapModal from './ShipmentOverviewMapModal'

const ShipmentOverviewMap: FunctionComponent<{}> = () => {
  const { markers, shipment, mapOpen } = useSelector((state: IGlobalState) => ({
    markers: state.shipmentTrack.list || [],
    mapOpen: state.shipmentTrack.displayMapModal,
    shipment: state.shipmentOverview,
  }))

  const dispatch: Dispatch = useDispatch()

  const goBack = useGoBack()

  const [cancelDialogOpen, setCancelDialogOpen] = useState<boolean>(false)

  const closeCancelModal = useCallback(() => {
    setCancelDialogOpen(false)
  }, [setCancelDialogOpen])

  const onCloseMapModal = useCallback(() => {
    dispatch(mapModalDisplayChange(false))
  }, [mapOpen])

  const openMapModal = useCallback(() => {
    dispatch(mapModalDisplayChange(true))
  }, [mapOpen])

  return (
    <div className="shipment-overview-top__map-container">
      <div className="map-wrapper">
        <div className="open-icon-container" onClick={openMapModal}>
          <i className="icon enlarge2 open-map-modal" />
        </div>
        <Map markers={markers} disableLine={true} />
      </div>
      {mapOpen && (
        <ShipmentOverviewMapModal onClose={onCloseMapModal} markers={markers} />
      )}

      <CancelShipmentModal
        open={cancelDialogOpen}
        close={closeCancelModal}
        afterCancel={goBack}
        shipmentId={shipment.id}
      />
    </div>
  )
}

export default ShipmentOverviewMap

import { useSelector, shallowEqual } from 'react-redux'
import useGoBack from 'src/hooks/useGoBack'
import { ShipmentCostsContent } from './ShipmentCostsContent'
import { ShipmentQuotationsContainerProps } from './ShipmentCosts.props'

const ShipmentCosts = () => {
  const afterCancelHandler = useGoBack()
  const { shipment, quotations } = useSelector(
    (state: IGlobalState): ShipmentQuotationsContainerProps => ({
      shipment: state.shipmentOverview,
      quotations: state.shipmentQuotations.items,
    }),
    shallowEqual
  )

  return (
    <ShipmentCostsContent {...{ shipment, quotations, afterCancelHandler }} />
  )
}

export default ShipmentCosts

import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { Link as MuiLink, Tooltip, TableCell, TableRow } from '@mui/material'

import {
  totalVolumeFormatted,
  totalWeightFormatted,
} from '../PurchaseOrderLine/helpers'

import './styles.scss'

interface IProps {
  item: IPurchaseOrderLine
  purchaseOrderNumber: string
  purchaseOrderId: number
}

const BookingPurchaseOrderLine: FunctionComponent<IProps> = (props) => {
  const { item, purchaseOrderNumber } = props

  return (
    <TableRow key={item.id}>
      <TableCell className="left-aligned s-medium">
        <MuiLink
          variant="body1"
          component={Link}
          to={`/order-management?open_item=${props.purchaseOrderId}&scope=all`}
        >
          {purchaseOrderNumber}
        </MuiLink>
      </TableCell>
      <TableCell className="left-aligned short-50">
        {item.units_ordered} x
      </TableCell>
      <TableCell className="left-aligned wide">
        <Tooltip title={item.product_name} placement="top">
          <div className="text-wrapper paragraph__medium strong">
            {item.product_name}
          </div>
        </Tooltip>
      </TableCell>
      <TableCell className="left-aligned s-medium">
        {item.product_code}
      </TableCell>
      <TableCell className="left-aligned s-medium">
        {totalVolumeFormatted(item) || '-'}
      </TableCell>
      <TableCell className="left-aligned s-medium">
        {totalWeightFormatted(item) || '-'}
      </TableCell>
      <TableCell className="left-aligned s-medium" />
    </TableRow>
  )
}

export default BookingPurchaseOrderLine

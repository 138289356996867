import Box from '@mui/material/Box'
import TableRow from '@mui/material/TableRow'
import Tab from '@mui/material/Tab'
import { styled } from '@mui/styles'

const StyledBox = styled(Box)(({ theme }) => ({
  background: theme.palette.grey[50],
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey[200]}`,
  overflowX: 'auto',
}))

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': { border: 0 },
}))

const StyledTab = styled(Tab)(() => ({
  minHeight: 48,
}))

export { StyledTab, StyledBox, StyledTableRow }

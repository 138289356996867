import { Grid, Stack, Box, Typography } from '@mui/material'
import { LoadTypeEnum, ModalityEnum } from 'src/config/constants'
import { useTranslation } from 'react-i18next'
import { useFormContext } from '../../FormContext'
import Skeleton from '../Skeleton'
import Container from '../Parts/Container/Container'
import Cargo from '../Parts/Cargo/Cargo'
import HsCode from '../Parts/HsCode'
import DangerousGoods from '../Parts/DangerousGoods'
import TemperatureSettings from '../Parts/TemperatureSettings'
import SizedBox from '../Parts/Box'
import { TemplateFormContextProps } from '../../Form.props'

const TemplateFormLoadDetails = () => {
  const {
    mode,
    isLoading,
    formState,
    cargoDetailsRequiredFields,
    selectedContainerType,
  } = useFormContext() as TemplateFormContextProps
  const { modality, loadType } = formState
  const { t } = useTranslation()
  return (
    <>
      <Grid container spacing={2} mt={2}>
        <Skeleton isLoading={isLoading}>
          <SizedBox columnSize={12}>
            {loadType === LoadTypeEnum.fcl && <Container />}
          </SizedBox>
          <SizedBox columnSize={12}>
            <Cargo />
          </SizedBox>
          <SizedBox columnSize={8}>
            <Stack direction="row" alignItems="end" spacing={2}>
              <Box width={500}>
                <HsCode
                  fieldPath="hsCode.hsCode"
                  label={t('templates.cargo_details.hs_code', 'HS Code')}
                />
              </Box>
              {modality === ModalityEnum.Air && (
                <TemperatureSettings
                  label={t(
                    'templates.cargo_details.temperature',
                    'Temperature'
                  )}
                  required={cargoDetailsRequiredFields.includes(
                    'temperatureSetting'
                  )}
                />
              )}
              <DangerousGoods
                label={t(
                  'templates.cargo_details.dangerous_goods',
                  'Dangerous goods'
                )}
                fieldPath="tags.dangerousGoods"
              />
            </Stack>
            {selectedContainerType?.equipment_controlled_atmosphere &&
              mode === 'rebook' && (
                <Typography
                  mt={4}
                  variant="body1"
                  color="text.primary"
                  children="Click Confirm to accept all settings and details above and continue"
                />
              )}
          </SizedBox>
        </Skeleton>
      </Grid>
    </>
  )
}

export default TemplateFormLoadDetails

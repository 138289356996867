import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import { useDispatch } from 'react-redux'
import { routes } from 'src/pages/AddressBook'
import { history } from 'src/shyppleStore'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { personalDetailsPutPersonalDetail } from '../../../stores/actionCreators'
import { promisifyAction } from '../../../utils'

const UpdateContactButton = ({
  contactId,
  contact,
  disabled,
}: {
  contactId: number
  contact: IPersonalDetail
  disabled?: boolean
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const updateContact = promisifyAction(
    dispatch,
    personalDetailsPutPersonalDetail
  )
  const update = useCallback(async () => {
    await updateContact(contactId, contact)
    history.push(routes.persons)
    dispatch(
      showNotification({
        message: `Contact "${contact.name}" updated.`,
        severity: 'success',
      })
    )
  }, [contactId, contact])

  return (
    <Button
      disabled={disabled}
      variant="contained"
      color="primary"
      disableElevation
      onClick={update}
    >
      {t('address_book.connections.update_contact')}
    </Button>
  )
}

export default UpdateContactButton

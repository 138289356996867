import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'

const ShipmentTemplateDialogSkeleton = () => {
  return (
    <Box data-testid="save-template-from-shipment-skeleton">
      <Skeleton width="30%" variant="text" animation="wave" sx={{ mb: 0.5 }} />
      <Skeleton
        height={40}
        width="100%"
        animation="wave"
        variant="rectangular"
        sx={{ borderRadius: 1 }}
      />
    </Box>
  )
}

export default ShipmentTemplateDialogSkeleton

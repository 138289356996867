import { FunctionComponent, useState, useMemo } from 'react'
import { orderBy } from 'lodash'
import {
  Box,
  Link,
  Theme,
  Tooltip,
  Table as MuiTable,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import './styles.scss'

import { Table } from 'src/stories'
import { SxProps } from '@mui/system'
import { permissionTo } from 'src/utils'
import { ModalityEnum, OrganizationRoleEnum } from 'src/config/constants'
import { useSelector } from 'react-redux'
import { ShipmentStatusSequence } from 'src/config/statusSequence'
import { CargoWindow } from '../CargoWindow'

import { formatNumberToFixed } from './helpers'

interface IProps {
  cargo: ICargo[]
  containerId: number
  fetchContainerData: () => Promise<void> | void
  shipmentModality: ModalityEnum
  totalWeightKg?: string
  totalVolumeCBM?: string
  isEditable?: boolean
}

type HeaderComponentProps = {
  row: ICargo
}

const trimText: SxProps<Theme> | undefined = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}

const getTableHeaders = (t: TFunction<'translation', undefined>) => {
  const StackableValues = {
    true: t('common.yes', 'Yes'),
    false: t('common.no', 'No'),
  }
  return {
    empty: {
      name: '',
      component: () => <i className="icon box" />,
      className: 'short',
    },
    '#': {
      name: '#',
      component: (props: HeaderComponentProps) => (
        <div data-testid="cargo-details-quantity">{props.row.quantity}</div>
      ),
    },
    packageType: {
      name: t('shipment_containers.cargo_window.package_type', 'Package type'),
      component: (props: HeaderComponentProps) => (
        <div data-testid="cargo-details-package-type">
          {props.row.package_type_name}
        </div>
      ),
    },
    goodsDescription: {
      name: t(
        'shipment_containers.cargo_window.goods_description',
        'Goods description'
      ),
      component: (props: HeaderComponentProps) => (
        <Tooltip title={props.row.goods_description ?? ''}>
          <Box data-testid="cargo-details-description" sx={trimText}>
            {props.row.goods_description ?? '-'}
          </Box>
        </Tooltip>
      ),
    },
    dimensions: {
      name: t('shipment_containers.cargo_window.dimensions', 'Dimensions'),
      component: (props: HeaderComponentProps) => (
        <Tooltip title={getDimensions(props.row)}>
          <Box data-testid="cargo-details-dimensions" sx={trimText}>
            {getDimensions(props.row)}
          </Box>
        </Tooltip>
      ),
    },
    volume: {
      name: t('shipment_containers.cargo_window.volume', 'Volume'),
      component: (props: HeaderComponentProps) => (
        <div data-testid="cargo-details-volume">
          {props.row.volume_cbm + ' m3'}
        </div>
      ),
    },
    weight: {
      name: t('shipment_containers.cargo_window.weight', 'Weight'),
      component: (props: HeaderComponentProps) => (
        <div data-testid="cargo-details-weight">
          {formatNumberToFixed(props.row.total_weight_kg, 2) + ' kg'}
        </div>
      ),
    },
    stackable: {
      name: t('shipment_containers.cargo_window.stackable', 'Stackable'),
      component: (props: HeaderComponentProps) => (
        <div data-testid="cargo-details-stackable">
          {StackableValues[props.row.stackable.toString()]}
        </div>
      ),
    },
  }
}

export const getDimensions = (item: Partial<ICargo | ICargoItem>) => {
  if (item.volume_cbm && item.height_mm && item.width_mm && item.length_mm) {
    return `${Math.round(item.length_mm / 10)} x ${Math.round(
      item.width_mm / 10
    )} x ${Math.round(item.height_mm / 10)} cm`
  }

  return '-'
}

export const CargoBlock: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()

  const { cargo } = props

  const [displayCargoModal, setDisplayCargoModal] = useState<boolean>(false)
  const { organizationRole, statusSequence } = useSelector(
    (state: IGlobalState) => ({
      organizationRole: state.user.organizationRole,
      statusSequence: state.shipmentOverview.status_sequence,
    })
  )

  const isCustomer = organizationRole === OrganizationRoleEnum.Customer

  const shouldShowWarningMessage = useMemo(
    () =>
      (statusSequence >= ShipmentStatusSequence.ProposedQuote ||
        statusSequence <= ShipmentStatusSequence.ScheduleToBeAnnounced) &&
      isCustomer &&
      props.shipmentModality === ModalityEnum.Air,
    [props.shipmentModality]
  )

  const cargoLinesView: boolean = permissionTo(
    'shipments.containers_|_cargo.cargo_lines.view'
  )
  const canManageCargoLines: boolean = !!(
    permissionTo('shipments.containers_|_cargo.cargo_lines.manage') &&
    props.isEditable
  )

  const totalCargoWeight: string = useMemo(
    () =>
      props.totalWeightKg
        ? formatNumberToFixed(props.totalWeightKg, 2) + ' kg'
        : '0 kg',
    [props.totalWeightKg]
  )

  const totalVolumeCBM: string = useMemo(
    () =>
      props.totalVolumeCBM
        ? formatNumberToFixed(props.totalVolumeCBM, 2) + ' m3'
        : '0 m3',
    [props.totalVolumeCBM]
  )

  const onClose = () => {
    setDisplayCargoModal(false)
  }

  const openCargoDetails = (): void => {
    setDisplayCargoModal(true)
  }

  if (!cargoLinesView || !cargo) {
    return null
  }

  const isCargoDisabled = false

  const orderedCargo: ICargo[] = orderBy(cargo, ['id'], ['asc']).map(
    (item) => ({
      ...item,
      isCargoDisabled,
    })
  )

  return (
    <>
      <div data-testid="cargo-details-table">
        <Table
          loading={false}
          columns={getTableHeaders(t)}
          rows={orderedCargo}
        />
      </div>
      <TableContainer>
        <MuiTable>
          <TableBody>
            <TableRow>
              <TableCell className="short"></TableCell>
              <TableCell
                sx={{
                  whiteSpace: 'unset',
                }}
              >
                {canManageCargoLines && (
                  <Link
                    component="button"
                    variant="body1"
                    className="left-aligned"
                    onClick={openCargoDetails}
                    data-testid="cargo-block-add-cargo-button"
                  >
                    <AddRoundedIcon />
                    {orderedCargo.length
                      ? t(
                          'shipment_containers.cargo_window.add_or_edit_goods',
                          'Add / Edit goods'
                        )
                      : t(
                          'shipment_containers.cargo_window.add_more_goods',
                          'Add more goods'
                        )}
                  </Link>
                )}
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>{totalVolumeCBM}</TableCell>
              <TableCell>{totalCargoWeight}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </MuiTable>
      </TableContainer>
      <CargoWindow
        open={displayCargoModal}
        close={onClose}
        cargoItems={orderedCargo}
        containerId={props.containerId}
        fetchContainerData={props.fetchContainerData}
        isCargoDisabled={isCargoDisabled}
        shouldShowWarningMessage={shouldShowWarningMessage}
        shipmentModality={props.shipmentModality}
      />
    </>
  )
}

import { FunctionComponent, useCallback } from 'react'
import { noop } from 'lodash'
import { Tooltip } from '@mui/material'
import Radio from 'src/components/Common/Radio'

import { convertDateToUniversalFormat } from '../../../../utils/helpers'
import './styles.scss'

interface IProps {
  shipment: IDetailedShipment
  selectedShipment: IDetailedShipment | null
  onSelectShipment: (shipment: IDetailedShipment) => void
}

const SelectableShipmentLine: FunctionComponent<IProps> = (props) => {
  const { shipment, selectedShipment } = props

  const onChange = useCallback(() => {
    props.onSelectShipment(shipment)
  }, [])

  return (
    <div className="selectable-shipment-line" onClick={onChange}>
      <div className="selectable-shipment-line__selectable">
        <Radio
          checked={!!selectedShipment && selectedShipment.id === shipment.id}
          onChange={noop}
          id={shipment.id}
          value={true}
          label={shipment.title}
        />
      </div>
      <div className="selectable-shipment-line__name text-wrapper">
        <Tooltip
          title={`${shipment.title} | ${shipment.reference_number || ''}`}
          placement="top"
        >
          <div>
            {shipment.title} | {shipment.reference_number}
          </div>
        </Tooltip>
      </div>
      <div className="selectable-shipment-line__departure text-wrapper">
        <span className="date">
          {convertDateToUniversalFormat(shipment.estimated_departure) || 'TBA'}
        </span>
        {shipment.loading_port}
      </div>
      <div className="selectable-shipment-line__arrival text-wrapper">
        <span className="date">
          {convertDateToUniversalFormat(shipment.estimated_departure) || 'TBA'}
        </span>
        {shipment.discharge_port}
      </div>
    </div>
  )
}

export default SelectableShipmentLine

import { createReducer, Handlers, Reducer } from 'redux-create-reducer'
import { AnyAction } from 'redux'
import * as actions from '../actions/dashboard'

declare global {
  type ShipmentsLoadTypes = 'fcl' | 'lcl' | 'air' | 'rail'
  type StatusGroupType =
    | 'pre_booked'
    | 'booking_confirmed'
    | 'origin_port'
    | 'in_transit'
    | 'destination_port'
    | 'delivered'

  interface IPickupAndDeliveryItem {
    id: number
    cargo_weight_kg: string
    carrier: string
    closing_time: string
    container: IShipmentContainerShort
    shipment: IShipmentShort
    shipment_details: {
      carrier: string
      eta: string
      load_type: string
      vessel: string | null
    }
    inland_transport_addresses: IInlandTransportAddress[]
    modality: string
    pickup_delivery_time: string
    pickup_delivery_time_editable: boolean
    service: string
    status: string
    transporter_status: string
    url: string
  }

  interface ISupplyChainOverview {
    booking_confirmed: ISupplyChainOverviewItem
    delivered: ISupplyChainOverviewItem
    destination_port: ISupplyChainOverviewItem
    in_transit: ISupplyChainOverviewItem
    origin_port: ISupplyChainOverviewItem
    pre_booked: ISupplyChainOverviewItem
  }

  interface ISupplyChainOverviewItem {
    air_count: number
    rail_count: number
    count: number
    fcl_count: number
    lcl_count: number
    per_load_type: ISupplyChainPerLoadTypeObject
  }

  interface ISupplyChainPerLoadTypeObject {
    air: ISupplyChainPerLoadTypeItem[]
    lcl: ISupplyChainPerLoadTypeItem[]
    fcl: ISupplyChainPerLoadTypeItem[]
    rail: ISupplyChainPerLoadTypeItem[]
  }

  interface ISupplyChainPerLoadTypeItem {
    count: number
    loading_port?: string
    discharge_port?: string
    month?: string
  }

  interface IShipment {
    id: number
    title?: string
    collaborators?: ICollaborator[]
    reference_number?: string
    estimated_departure?: string
    loading_port?: string
    loading_port_country?: string
    estimated_arrival?: string
    discharge_port?: string
    discharge_port_country?: string
    progress_percent?: number
    valid_information?: boolean
    shipment_type?: string
    our_reference?: string
    reference?: string
    status_group: string
    status?: string
    flagged: boolean
    booked_at?: string
    booked_by?: string
    booked_by_organization: IChatOrganization
    delivered_at?: string
    delivery_requested: boolean
    delivery_address_name?: string
    pickup_requested: boolean
    pickup_address_name?: string
  }

  interface ITaskManagerCounters {
    approvals: number
    incomplete_info: number
    mentions: number
    new_user: boolean
    proposed_quotes: number
    shipments_count: number
    unread_messages: number
    unreached_milestones_count: IUnreachedMilestonesCount
  }

  interface IUnreachedMilestonesCount {
    open_milestones_count: number
    overdue_milestones_count: number
  }

  interface IDashboard {
    taskManagerCounters: ITaskManagerCounters
    updatedByYou: {
      records: IDashboardShipmentWithComment[]
      total_count: number
      per_page: number
      has_more: boolean
      page: number
    }
    updatedByOthers: {
      records: IDashboardShipmentWithComment[]
      total_count: number
      per_page: number
      has_more: boolean
      page: number
    }
    arriving: {
      records: IDashboardShipment[]
    }
    departing: {
      records: IDashboardShipment[]
    }
    recentlyBooked: {
      records: IDashboardShipment[]
    }
    supplyChain: any
    supplyChainShipments: IShipment[]
    tasks: IMilestoneTask[]
    pickupAndDelivery: {
      inland_transports: IPickupAndDeliveryItem[]
      total: number
    }
  }

  interface IDashboardShipment {
    id: number
    title: string
    reference_number?: string
    estimated_departure: string
    estimated_arrival: string
    loading_port: string
    discharge_port: string
    progress_percent: number
    shipment_type: string
    status_group: string
    status?: string
    flagged: boolean
    booked_at?: string
    booked_by?: string
    booked_by_organization: IChatOrganization
    delivered_at?: string
    delivery_requested: boolean
    delivery_address_name?: string
    pickup_requested: boolean
    pickup_address_name?: string
  }

  interface IDashboardShipmentWithComment {
    id: number
    title: string
    reference_number?: string
    estimated_departure: string
    estimated_arrival: string
    loading_port: string
    discharge_port: string
    progress_percent: number
    shipment_type: string
    status_group: string
    flagged: boolean
    chat_comment_id: number
    chat_comment_message: string
    chat_comment_author: string
    author_organization: IChatOrganization
    chat_comment_created_at: string

    shared_reference?: string | null
    load_type?: string
    modality?: string
    visibility_only?: boolean
  }
}

export const initialDashboardState: IDashboard = {
  taskManagerCounters: {
    approvals: 0,
    incomplete_info: 0,
    mentions: 0,
    new_user: false,
    proposed_quotes: 0,
    shipments_count: 0,
    unread_messages: 0,
    unreached_milestones_count: {
      open_milestones_count: 0,
      overdue_milestones_count: 0,
    },
  },
  updatedByYou: {
    records: [],
    total_count: 0,
    per_page: 0,
    has_more: false,
    page: 0,
  },
  updatedByOthers: {
    records: [],
    total_count: 0,
    per_page: 0,
    has_more: false,
    page: 0,
  },
  arriving: {
    records: [],
  },
  departing: {
    records: [],
  },
  recentlyBooked: {
    records: [],
  },
  supplyChain: {},
  supplyChainShipments: [],
  tasks: [],
  pickupAndDelivery: {
    total: 0,
    inland_transports: [],
  },
}

const saveReceivedTaskManagerData: Reducer<IDashboard, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  taskManagerCounters: payload,
})

const saveReceivedUpdatedByYouData: Reducer<any, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  updatedByYou: payload,
})

const saveReceivedUpdatedByOthersData: Reducer<any, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  updatedByOthers: payload,
})

const saveReceivedArrivingData: Reducer<any, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  arriving: payload,
})

const saveReceivedDepartingData: Reducer<any, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  departing: payload,
})

const saveReceivedRecentlyBookedData: Reducer<any, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  recentlyBooked: payload,
})

const saveReceivedSupplyChainData: Reducer<any, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  supplyChain: payload,
})

const saveReceivedSupplyChainShipmentsData: Reducer<any, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  supplyChainShipments: payload.shipments,
})

const saveReceivedTasksData: Reducer<IDashboard, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  tasks: payload,
})

const saveReceivedPickupAndDeliveryData: Reducer<IDashboard, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  pickupAndDelivery: payload,
})

const actionHandlers: Handlers<IDashboard> = {
  [actions.FETCH_DASHBOARD_TASK_MANAGER_SUCCESS]: saveReceivedTaskManagerData,
  [actions.FETCH_DASHBOARD_TASKS_SUCCESS]: saveReceivedPickupAndDeliveryData,
  [actions.FETCH_DASHBOARD_PICKUP_AND_DELIVERY_SUCCESS]: saveReceivedTasksData,
  [actions.FETCH_DASHBOARD_UPDATED_BY_YOU_SUCCESS]: saveReceivedUpdatedByYouData,
  [actions.FETCH_DASHBOARD_UPDATED_BY_OTHERS_SUCCESS]: saveReceivedUpdatedByOthersData,
  [actions.FETCH_DASHBOARD_ARRIVING_SUCCESS]: saveReceivedArrivingData,
  [actions.FETCH_DASHBOARD_DEPARTING_SUCCESS]: saveReceivedDepartingData,
  [actions.FETCH_DASHBOARD_RECENTLY_BOOKED_SUCCESS]: saveReceivedRecentlyBookedData,
  [actions.FETCH_DASHBOARD_SUPPLY_CHAIN_SUCCESS]: saveReceivedSupplyChainData,
  [actions.FETCH_DASHBOARD_SUPPLY_CHAIN_SHIPMENTS_SUCCESS]: saveReceivedSupplyChainShipmentsData,
}

export default createReducer(initialDashboardState, actionHandlers)

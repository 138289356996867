import { ModalityEnum } from 'src/config/constants'
import i18n from 'i18next'

export const getTranslationForModality = (
  modality: ModalityEnum,
  field: string
) => {
  return i18n.exists(`common.modality_${modality}.${field}`)
    ? i18n.t(`common.modality_${modality}.${field}`, field)
    : i18n.t(`common.modality_sea.${field}`, field)
}

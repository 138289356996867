import { SvgIconProps } from '@mui/material/SvgIcon'

const Container20FlatRack = (props: SvgIconProps) => {
  return (
    <svg viewBox="0 0 48 36" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1076 26.4634C14.9658 26.7796 14.6476 27 14.2778 27H11.9086C11.4068 27 11 26.5942 11 26.0935V8.9065C11 8.40586 11.4068 8 11.9086 8H14.2778C14.7797 8 15.1864 8.40586 15.1864 8.9065V22.1708H32.3006V8.9065C32.3006 8.40586 32.7087 8 33.212 8H35.5883C36.0916 8 36.4996 8.40586 36.4996 8.9065V26.0935C36.4996 26.5942 36.0916 27 35.5883 27H33.212C32.8411 27 32.522 26.7796 32.3797 26.4634H15.1076ZM32.3006 25.2633V23.3708H15.1864V25.2633H32.3006ZM13.9849 9.19879V25.8012H12.2016V9.19879H13.9849ZM35.2944 9.19879V25.8012H33.5058V9.19879H35.2944Z"
        fill="#1C1053"
      />
    </svg>
  )
}

const Container40FlatRack = (props: SvgIconProps) => {
  return (
    <svg viewBox="0 0 48 36" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.10761 26.4634C7.96577 26.7796 7.6476 27 7.27784 27H4.9086C4.4068 27 4 26.5942 4 26.0935V8.9065C4 8.40586 4.40679 8 4.9086 8H7.27784C7.77965 8 8.18644 8.40586 8.18644 8.9065V22.1708H39.6632V8.9065C39.6632 8.40586 40.07 8 40.5718 8H42.941C43.4428 8 43.8496 8.40586 43.8496 8.9065V26.0935C43.8496 26.5942 43.4428 27 42.941 27H40.5718C40.202 27 39.8838 26.7796 39.742 26.4634H8.10761ZM39.6632 25.2633V23.3708H8.18644V25.2633H39.6632ZM6.98487 9.19879V25.8012H5.20157V9.19879H6.98487ZM42.648 9.19879V25.8012H40.8647V9.19879H42.648Z"
        fill="#1C1053"
      />
    </svg>
  )
}
const Container20Tank = (props: SvgIconProps) => {
  return (
    <svg viewBox="0 0 48 36" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.75 17.6C16.75 14.2311 19.4811 11.5 22.85 11.5H24.65C28.0189 11.5 30.75 14.2311 30.75 17.6C30.75 20.9689 28.0189 23.7 24.65 23.7H22.85C19.4811 23.7 16.75 20.9689 16.75 17.6ZM22.85 12.7H24.65C27.3562 12.7 29.55 14.8938 29.55 17.6C29.55 20.3062 27.3562 22.5 24.65 22.5H22.85C20.1438 22.5 17.95 20.3062 17.95 17.6C17.95 14.8938 20.1438 12.7 22.85 12.7Z"
        fill="#1C1053"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9074 8C11.4063 8 11 8.40625 11 8.90741V26.1118C11 26.6129 11.4063 27.0192 11.9074 27.0192H14.2736C14.6465 27.0192 14.9669 26.7942 15.1064 26.4726H32.4222C32.5617 26.7942 32.8821 27.0192 33.2551 27.0192H35.6212C36.1224 27.0192 36.5286 26.6129 36.5286 26.1118V8.90741C36.5286 8.40625 36.1224 8 35.6212 8H33.2551C32.8821 8 32.5617 8.22503 32.4222 8.54672H15.1065C14.967 8.22502 14.6465 8 14.2736 8H11.9074ZM12.2 25.8192V9.2H13.981V25.8192H12.2ZM32.3477 25.2726H15.181V9.74672H32.3477V25.2726ZM33.5477 25.8192V9.2H35.3286V25.8192H33.5477Z"
        fill="#1C1053"
      />
    </svg>
  )
}

const Container40Tank = (props: SvgIconProps) => {
  return (
    <svg viewBox="0 0 48 36" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.8387 8.90652C43.8387 8.40588 43.4394 8.00002 42.9467 8.00002H40.6209C40.2543 8.00002 39.9393 8.22481 39.8022 8.54616H8.03656C7.89943 8.2248 7.58447 8 7.21784 8H4.89197C4.39934 8 4 8.40586 4 8.90649V26.0935C4 26.5941 4.39934 27 4.89197 27H7.21784C7.58444 27 7.89938 26.7752 8.03653 26.4539H39.8022C39.9393 26.7752 40.2543 27 40.6209 27H42.9467C43.4394 27 43.8387 26.5941 43.8387 26.0935V8.90652ZM42.6591 25.8012H40.9085V9.19881H42.6591V25.8012ZM8.10981 9.74494H39.7289V25.2551H8.10981V9.74494ZM5.17958 9.19879H6.93023V25.8012H5.17958V9.19879ZM16 12.7H32C34.651 12.7 36.8 14.849 36.8 17.5V17.7C36.8 20.351 34.651 22.5 32 22.5H16C13.349 22.5 11.2 20.351 11.2 17.7V17.5C11.2 14.849 13.349 12.7 16 12.7ZM10 17.5C10 14.1863 12.6863 11.5 16 11.5H32C35.3137 11.5 38 14.1863 38 17.5V17.7C38 21.0137 35.3137 23.7 32 23.7H16C12.6863 23.7 10 21.0137 10 17.7V17.5Z"
        fill="#1C1053"
      />
    </svg>
  )
}

export {
  Container20FlatRack,
  Container40FlatRack,
  Container20Tank,
  Container40Tank,
}

import { FunctionComponent, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { times } from 'lodash'
import SelectableShipmentLine from '../SelectableShipmentLine'
import BlankSelectableShipmentLine from '../BlankSelectableShipmentLine'
import ShipmentsEmptyState from '../ShipmentsEmptyState'
import ShipmentFilters from '../ShipmentFilters'
import './styles.scss'

interface IProps {
  selectedShipment: IDetailedShipment | null
  onSelectShipment: (shipment: IDetailedShipment) => void
}

const SelectShipmentStep: FunctionComponent<IProps> = (props) => {
  const { shipments, isFetching } = useSelector((state: IGlobalState) => ({
    shipments: state.shipments.shipmentsToAssignPO,
    isFetching: state.shipments.isFetchingShipmentsToAssign,
  }))

  const onSelectShipment = useCallback(
    (shipment: IDetailedShipment) => {
      props.onSelectShipment(shipment)
    },
    [props.onSelectShipment]
  )
  return (
    <div className="select-shipments-step">
      <ShipmentFilters />
      <div className="select-shipments-step__shipments">
        {isFetching &&
          times(10, (index) => <BlankSelectableShipmentLine key={index} />)}

        {!isFetching && !!shipments && !shipments.length && (
          <ShipmentsEmptyState />
        )}

        {!isFetching &&
          !!shipments &&
          !!shipments.length &&
          shipments.map((shipment) => (
            <SelectableShipmentLine
              key={shipment.id}
              shipment={shipment}
              selectedShipment={props.selectedShipment}
              onSelectShipment={onSelectShipment}
            />
          ))}
      </div>
    </div>
  )
}

export default SelectShipmentStep

const services = {
  Intercom: ['Asia/Shanghai', 'Asia/Urumqi'],
}
// Some services like google maps are blocked in China
// we don't want to load this services because they will impact performance of the app
// should we consider using local services?
export default (service: keyof typeof services) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return services[service].includes(timeZone)
}

import IconField from '../components/IconField'
import ShipmentField from '../components/ShipmentField'
import PortField from '../components/PortField'
import AlertField from '../components/AlertField'
import StatusField from '../components/StatusField'
import GenericDateField from '../components/GenericDateField'
import TrackTraceStatusField from '../components/TrackTraceStatusField'

export const inactiveTNTSchema = {
  icon: {
    field: (shipment: IDashboardInactiveTNTShipment) => (
      <IconField shipment={shipment} />
    ),
    name: '',
    sortable: false,
    sortValue: '',
    customClassName: 'short-50 two-lines',
  },
  shipment: {
    field: (shipment: IDashboardInactiveTNTShipment) => (
      <ShipmentField
        shipment={shipment}
        showNonStackable={false}
        showSpot={false}
      />
    ),
    name: 'SHIPMENT',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned two-lines',
  },
  status: {
    field: (shipment: IDashboardInactiveTNTShipment) => (
      <StatusField shipment={shipment} />
    ),
    name: 'STATUS',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned x-medium',
  },
  from: {
    field: (shipment: IDashboardInactiveTNTShipment) => (
      <PortField
        port={shipment.loading_port}
        date={shipment.estimated_departure}
      >
        <AlertField shipment={shipment} type="etd" />
      </PortField>
    ),
    name: 'POL',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned two-lines',
  },
  to: {
    field: (shipment: IDashboardInactiveTNTShipment) => (
      <PortField
        port={shipment.discharge_port}
        date={shipment.estimated_arrival}
      ></PortField>
    ),
    name: 'POD',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned two-lines',
  },

  tnt: {
    field: (shipment: IDashboardInactiveTNTShipment) => (
      <TrackTraceStatusField trackTraceStatus={shipment.track_trace_status} />
    ),
    name: 'T&T Status',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  created: {
    field: (shipment: IDashboardInactiveTNTShipment) => (
      <GenericDateField date={shipment.created_at} />
    ),
    name: 'Created at',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
}

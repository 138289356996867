import { FunctionComponent } from 'react'
import ColoredFieldWithMappings from '../ColoredFieldWithMappings'
import ColoredField from '../ColoredField'

const BLANK_DESTINATION_CUSTOMS_CLEARANCE_ID = 135
const BLANK_PERFORM_CUSTOMS_DOCS_CHECK_ID = 94
const BLANK_OBTAIN_CUSTOMS_DOCS_ID = 89

interface IProps {
  shipment: IReportsShipment
}

const CustomsTask: FunctionComponent<IProps> = ({ shipment }) => {
  let mapping: string = ''
  let currentStatusId: number | null = null

  if (
    shipment.destination_customs_clearance_task &&
    shipment.destination_customs_clearance_task.task_status_id !==
      BLANK_DESTINATION_CUSTOMS_CLEARANCE_ID
  ) {
    mapping = 'obtain_destination_customs_clearance'
    currentStatusId = shipment.destination_customs_clearance_task.task_status_id
  } else if (
    shipment.perform_customs_docs_check_task &&
    shipment.perform_customs_docs_check_task.task_status_id !==
      BLANK_PERFORM_CUSTOMS_DOCS_CHECK_ID
  ) {
    mapping = 'perform_customs_documents_check'
    currentStatusId = shipment.perform_customs_docs_check_task.task_status_id
  } else if (
    shipment.obtain_customs_documents_task &&
    shipment.obtain_customs_documents_task.task_status_id !==
      BLANK_OBTAIN_CUSTOMS_DOCS_ID
  ) {
    mapping = 'obtain_customs_documents'
    currentStatusId = shipment.obtain_customs_documents_task.task_status_id
  }

  if (mapping && currentStatusId) {
    return (
      <ColoredFieldWithMappings
        type="task"
        mapping={mapping}
        value={currentStatusId}
      />
    )
  }

  return <ColoredField value="" icon="remove" color="white" />
}

export default CustomsTask

import { DemurrageAndDetentionPrice } from 'src/@types/endpoints/demurrageAndDetention'

export const getItemForSumOfPrices = (prices: DemurrageAndDetentionPrice[]) => {
  const itemWithOnRequestPrice = prices.find(
    (price) => price.note === 'on_request'
  )

  if (itemWithOnRequestPrice) {
    return itemWithOnRequestPrice
  }

  const firstPriceItem = prices[0]

  if (firstPriceItem) {
    return {
      ...firstPriceItem,
      value: prices.reduce((result, price) => {
        return result + Number(price.value)
      }, 0),
    }
  }

  return null
}

export const getOverdueDays = (
  usedDays: number | null,
  totalDays: number | null
) => {
  if (!usedDays || !totalDays) {
    return null
  }
  return usedDays - totalDays
}

export const getProgressPercentage = (
  usedDays: number | null,
  totalDays: number | null
) => {
  if (!usedDays || !totalDays) {
    return 0
  }
  if (usedDays > totalDays) {
    return 100
  }
  return (usedDays * 100) / totalDays
}

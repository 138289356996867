import { FunctionComponent, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LinearProgress } from 'src/stories'
import LoadableContainer from 'src/components/LoadableContainer'
import MessageCenterSingleApproval from 'src/components/MessageCenter/MessageCenterSingleApproval'
import { useTranslation } from 'react-i18next'
import { promisifyAction } from '../../../utils'
import { approvalsGetData } from '../../../stores/actionCreators'
import './styles.scss'

const MessageCenterApprovals: FunctionComponent<{}> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [busy, setBusy] = useState<boolean>(true)

  const { comments } = useSelector((state: IGlobalState) => ({
    comments: state.messages.approvals.comments || [],
  }))

  const approvalsGetDataAsync = promisifyAction(dispatch, approvalsGetData)

  useEffect(() => {
    const fetchDataAsync = async () => {
      setBusy(true)
      await approvalsGetDataAsync()
      setBusy(false)
    }
    fetchDataAsync()
  }, [])

  return (
    <LoadableContainer loading={busy} placeholder={<LinearProgress />}>
      <div className="message-center-approvals">
        {comments.length ? (
          comments.map((comment: IComment) => (
            <MessageCenterSingleApproval comment={comment} key={comment.id} />
          ))
        ) : (
          <div className="message-center-emptyTab">
            <i className="icon big-check" />
            {t(
              'message_center.approvals.empty_placeholder',
              'You have no pending approvals'
            )}
          </div>
        )}
      </div>
    </LoadableContainer>
  )
}

export default MessageCenterApprovals

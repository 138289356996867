import { FunctionComponent } from 'react'
import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Table from 'src/components/Common/Table'
import { orderHeaders } from '../tableHeaders'
import { convertDatePickerToUniversalFormat } from '../../../utils/helpers'

interface IProps {
  purchaseOrder: IPurchaseOrder
}

const PublicPOTable: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const purchaseOrder = props.purchaseOrder

  const _renderCell = (cellValue: any, className = 'left-aligned') => {
    return (
      <Table.StripedTableCell className={className}>
        {cellValue}
      </Table.StripedTableCell>
    )
  }

  return (
    <Table.StripedTable theme="rates">
      <Table.StripedTableHeader cells={orderHeaders(t)} />
      <Table.StripedTableBody>
        <Table.StripedTableRow index={0}>
          {_renderCell(
            <Tooltip
              title={purchaseOrder.purchase_order_number}
              placement="top"
            >
              <div className="text-wrapper paragraph__medium strong ">
                {purchaseOrder.purchase_order_number}
              </div>
            </Tooltip>,
            'left-aligned x-medium'
          )}
          <Table.StripedTableCell className="left-aligned wide">
            <Tooltip
              title={
                (purchaseOrder.seller.address &&
                  purchaseOrder.seller.address.name) ||
                ''
              }
              placement="top"
            >
              <div className="text-wrapper paragraph__medium strong ">
                {purchaseOrder.seller.address &&
                  purchaseOrder.seller.address.name}
              </div>
            </Tooltip>
          </Table.StripedTableCell>
          <Table.StripedTableCell className="left-aligned">
            {(purchaseOrder.incoterm && purchaseOrder.incoterm.code) || '-'}
          </Table.StripedTableCell>
          <Table.StripedTableCell className="left-aligned">
            {(purchaseOrder.loading_port && purchaseOrder.loading_port.name) ||
              '-'}
          </Table.StripedTableCell>
          <Table.StripedTableCell className="left-aligned">
            {convertDatePickerToUniversalFormat(purchaseOrder.order_date) ||
              '-'}
          </Table.StripedTableCell>
          <Table.StripedTableCell className="left-aligned">
            {convertDatePickerToUniversalFormat(
              purchaseOrder.ex_factory_date
            ) || '-'}
          </Table.StripedTableCell>
          <Table.StripedTableCell className="left-aligned">
            {convertDatePickerToUniversalFormat(
              purchaseOrder.cargo_ready_date
            ) || '-'}
          </Table.StripedTableCell>
          <Table.StripedTableCell className="left-aligned">
            {!!purchaseOrder.total_volume_cbm
              ? `${purchaseOrder.total_volume_cbm}m3`
              : '-'}
          </Table.StripedTableCell>
          <Table.StripedTableCell className="left-aligned">
            {!!purchaseOrder.total_weight_kg
              ? `${purchaseOrder.total_weight_kg}kg`
              : '-'}
          </Table.StripedTableCell>
        </Table.StripedTableRow>
      </Table.StripedTableBody>
    </Table.StripedTable>
  )
}
export default PublicPOTable

import { Button, Link, Typography } from '@mui/material'
import { CheckRounded as CheckRoundedIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import {
  EstimatedPickupOrDeliveryStatusEnum,
  getDateTimeRange,
  getIsEstimated,
} from './constants'

interface IEstimatedPickupOrDeliveryProps {
  title: string
  isSailingTBA: boolean
  dateTimeBlock: (transport: IInlandTransport) => React.ReactNode
  transport?: IInlandTransport
  canManage: boolean
  requestPickupOrDeliveryTime: () => void
  acceptPickupOrDeliveryDateAndTime: () => Promise<any>
  deliveryInspectionDatetime: string | null
  transportType?: string
}

export const EstimatedPickupOrDeliveryDate: React.FC<IEstimatedPickupOrDeliveryProps> = (
  props
) => {
  const {
    isSailingTBA,
    transport,
    canManage,
    requestPickupOrDeliveryTime,
    dateTimeBlock,
    acceptPickupOrDeliveryDateAndTime,
    title,
    transportType,
  } = props

  const { t } = useTranslation()

  const isContainerDeliveryEstimated = transport && getIsEstimated(transport)

  const estimatedOn = t('shipment_containers.estimated_on', {
    defaultValue: 'Estimated on {{date, DATE_MED}}',
    date: transport?.pickup_delivery_time,
  })

  return (
    <div className="date-time-info">
      <div className="date-time-info--title">
        <Typography variant="body1Strong" children={title} />
      </div>
      {isSailingTBA ? (
        <Typography
          variant="body1"
          children={t('shipment_containers.to_be_announced', 'To be announced')}
        />
      ) : (
        isContainerDeliveryEstimated &&
        !transport.pickup_delivery_time_editable && (
          <Typography
            variant="body1"
            data-testid="container-service-estimated-on"
          >
            {estimatedOn.length > 0 ? estimatedOn : '-'}
          </Typography>
        )
      )}
      {isContainerDeliveryEstimated &&
        transport?.pickup_delivery_time_editable && (
          <div className={`${canManage ? 'blue-text' : ''} manageable`}>
            {canManage ? (
              <Link
                variant="body1"
                component="button"
                onClick={requestPickupOrDeliveryTime}
              >
                {transportType === 'pickup'
                  ? t('shipment_containers.plan_pickup', 'Plan pick-up')
                  : t('shipment_containers.plan_deliver', 'Plan delivery')}
              </Link>
            ) : (
              '-'
            )}
          </div>
        )}
      {transport?.status === EstimatedPickupOrDeliveryStatusEnum.Requested && (
        <div>
          {dateTimeBlock(transport)}
          <Typography variant="body1">
            {t(
              'shipment_containers.to_be_confirmed_by_forwarder',
              'To be confirmed by the forwarder'
            )}
          </Typography>
        </div>
      )}
      {transport?.status === EstimatedPickupOrDeliveryStatusEnum.Confirmed && (
        <div>
          <div>{getDateTimeRange(transport)}</div>
          <Typography variant="body1">
            {t('shipment_containers.confirmed', 'Confirmed')}
          </Typography>
        </div>
      )}
      {transport?.status === EstimatedPickupOrDeliveryStatusEnum.Proposed && (
        <div>
          <div>
            <Typography variant="body1">
              {getDateTimeRange(transport)}
            </Typography>
          </div>
          {canManage && (
            <div className="button-block">
              <Button
                startIcon={<CheckRoundedIcon />}
                children={t('common.buttons.accept', 'Accept')}
                onClick={acceptPickupOrDeliveryDateAndTime}
              />
              <Button variant="contained" onClick={requestPickupOrDeliveryTime}>
                {t('common.buttons.change', 'Change')}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/account'
import * as actionCreators from '../../actionCreators/account'
import {
  simpleGetAndResolve,
  simpleDeleteAndResolve,
  simplePostAndResolve,
} from '../factories'

const accessTokenURL = () => 'api/v1/account/api_token'

const getAccessToken = simpleGetAndResolve(
  accessTokenURL,
  actionCreators.getAccessTokenSuccess,
  (response) => response.data.data
)

const resetAccessToken = simplePostAndResolve(
  accessTokenURL,
  actionCreators.resetAccessTokenSuccess,
  (response) => response.data.data
)

const deleteAccessToken = simpleDeleteAndResolve(
  accessTokenURL,
  actionCreators.deleteAccessTokenSuccess,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.GET_ACCESS_TOKEN, getAccessToken)
  yield takeEvery(actions.RESET_ACCESS_TOKEN, resetAccessToken)
  yield takeEvery(actions.DELETE_ACCESS_TOKEN, deleteAccessToken)
}

import { AnyAction } from 'redux'
import * as actions from '../actions/shipmentLayout'

export const shipmentLayoutCancelShipment = (
  id: number,
  payload: any
): AnyAction => ({
  type: actions.SHIPMENT_LAYOUT_CANCEL_SHIPMENT,
  id,
  payload,
})

export const shipmentLayoutCancelShipmentSuccess = (
  payload: any
): AnyAction => ({
  type: actions.SHIPMENT_LAYOUT_CANCEL_SHIPMENT_SUCCESS,
  payload,
})

export const shipmentLayoutAcceptQuote = (id: number): AnyAction => ({
  type: actions.SHIPMENT_LAYOUT_ACCEPT_QUOTE,
  id,
})

export const shipmentLayoutAcceptQuoteSuccess = (payload: any): AnyAction => ({
  type: actions.SHIPMENT_LAYOUT_ACCEPT_QUOTE_SUCCESS,
  payload,
})

export const shipmentLayoutGetRemarks = (): AnyAction => ({
  type: actions.SHIPMENT_LAYOUT_GET_REMARKS,
})

export const shipmentLayoutGetRemarksSuccess = (payload: any): AnyAction => ({
  type: actions.SHIPMENT_LAYOUT_GET_REMARKS_SUCCESS,
  payload,
})

export const shipmentLayoutUpdateBooking = (
  id: number,
  status: string
): AnyAction => ({
  type: actions.SHIPMENT_LAYOUT_UPDATE_BOOKING,
  id,
  status,
})

export const shipmentLayoutUpdateBookingSuccess = (
  payload: any
): AnyAction => ({
  type: actions.SHIPMENT_LAYOUT_UPDATE_BOOKING_SUCCESS,
  payload,
})

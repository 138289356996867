import Typography from '@mui/material/Typography'
import { ShipmentPortsProps } from '../shipmentContentProps'
import { AlignedStack, AlignedStackItem } from './ShipmentAlignedStack'

type PortProps = {
  text: string
}

export const ShipmentPortsPort: React.FC<PortProps> = ({ text }) => {
  return <Typography variant="body1Strong" noWrap={true} children={text} />
}

export const ShipmentPorts: React.FC<ShipmentPortsProps> = (props) => {
  const { align, loadingPort, dischargePort } = props
  return (
    <AlignedStack justifyContent={align} direction="row" spacing={0}>
      <AlignedStackItem maxWidth="50%">
        <ShipmentPortsPort text={loadingPort} />
      </AlignedStackItem>
      <AlignedStackItem width={16} px={0.5} textAlign="center">
        <ShipmentPortsPort text="-" />
      </AlignedStackItem>
      <AlignedStackItem maxWidth="50%">
        <ShipmentPortsPort text={dischargePort} />
      </AlignedStackItem>
    </AlignedStack>
  )
}

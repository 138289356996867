import { FunctionComponent } from 'react'

import SendTransportOrderEmailBlock from './InlandTransport/SendTransportOrderEmailBlock'
import InlandTransportForm from './InlandTransport/InlandTransportForm'

type IProps = {
  inlandTransport: ISingleInlandTransport
}

const InlandTransport: FunctionComponent<IProps> = ({ inlandTransport }) => {
  return (
    <>
      <InlandTransportForm inlandTransport={inlandTransport} />
      <SendTransportOrderEmailBlock inlandTransport={inlandTransport} />
    </>
  )
}

export default InlandTransport

import { orderBy } from 'lodash'
import { useState } from 'react'
import Popover from '@mui/material/Popover'
import {
  Box,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from '@mui/material'

import { Avatar } from 'src/stories/Avatar'
import { AvatarGroup } from 'src/stories/AvatarGroup'
import { useTranslation } from 'react-i18next'

const DocumentsTableCellViewableBy = ({ row }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const { t } = useTranslation()

  const onMouseEnter = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const onMouseLeave = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'documents-popover' : undefined
  const documentOwner = row.original.uploaded_by
  const avatarsGroup = orderBy(row.original?.viewable_by ?? [], ['name']).map(
    (org: IDocumentViewableBy) => {
      return {
        id: `${org.id}`,
        alt: org.name,
        className: org.role_code,
        src: org.logo ?? 'no-logo',
        title: '',
      }
    }
  )
  return (
    <>
      <Popover
        id={id}
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={onMouseLeave}
        disableRestoreFocus
        data-testid="documents-popover"
      >
        <Box maxWidth={600} data-testid="document-viewable-by-popover">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="small"></TableCell>
                <TableCell className="wide">
                  {t(
                    'documents.table.cells.vieviewable_by.columns.organization',
                    'organization'
                  )}
                </TableCell>
                <TableCell className="medium">
                  {t(
                    'documents.table.cells.vieviewable_by.columns.uploaded_by',
                    'uploaded by'
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {avatarsGroup.map((organization) => (
                <TableRow key={organization.id}>
                  <TableCell className="small">
                    <Avatar
                      size="medium"
                      {...organization}
                      doubleInitials={false}
                    />
                  </TableCell>
                  <TableCell className="wide">{organization.alt}</TableCell>
                  <TableCell className="medium">
                    {organization.alt === documentOwner?.organization_name
                      ? `${documentOwner.full_name}`
                      : ''}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Popover>
      <AvatarGroup
        max={3}
        size="small"
        data-testid="document-viewable-by"
        disableTooltips={true}
        avatars={avatarsGroup}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        sx={{ flexDirection: 'row', cursor: 'pointer' }}
      />
    </>
  )
}

export default DocumentsTableCellViewableBy

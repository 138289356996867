import { takeEvery } from 'redux-saga/effects'
import * as actionCreators from '../../actionCreators/addresses'
import {
  simpleGetAndResolve,
  simplePutAndResolve,
  simplePostAndResolve,
  simpleDeleteAndResolve,
} from '../factories'

import * as actions from '../../actions/addresses'
import {
  getAddressesURL,
  getDefaultAddressUrl,
  getOneAddressURL,
  getOrganizationAddressesURL,
  responseData,
} from './constants'

export const getAddresses = simpleGetAndResolve(
  getAddressesURL,
  actionCreators.addressesGetDataSuccess,
  responseData
)

export const getOrganizationAddresses = simpleGetAndResolve(
  getOrganizationAddressesURL,
  actionCreators.getOrganizationAddressesSuccess,
  responseData
)

export const getOneAddress = simpleGetAndResolve(
  getOneAddressURL,
  actionCreators.addressesGetOneAddressSuccess,
  ({ data }: { data: IAddress }): IAddress => ({
    ...data,
    country_id: data.country.id,
  })
)

export const putAddress = simplePutAndResolve(
  getOneAddressURL,
  actionCreators.addressesPutAddressSuccess,
  responseData
)

export const addAddress = simplePostAndResolve(
  getAddressesURL,
  actionCreators.addressesAddAddressSuccess,
  responseData,
  false,
  true
)

export const deleteAddress = simpleDeleteAndResolve(
  getOneAddressURL,
  actionCreators.addressesDeleteAddressSuccess,
  responseData,
  false,
  true
)

export const setDefaultAddress = simplePostAndResolve(
  getDefaultAddressUrl,
  actionCreators.setDefaultAddressSuccess
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.ADDRESSES_GET_DATA, getAddresses)
  yield takeEvery(actions.GET_ORGANIZATION_ADDRESSES, getOrganizationAddresses)
  yield takeEvery(actions.ADDRESSES_GET_ONE_ADDRESS, getOneAddress)
  yield takeEvery(actions.ADDRESSES_PUT_ADDRESS, putAddress)
  yield takeEvery(actions.ADDRESSES_ADD_ADDRESS, addAddress)
  yield takeEvery(actions.ADDRESSES_DELETE_ADDRESS, deleteAddress)
  yield takeEvery(actions.SET_DEFAULT_ADDRESS, setDefaultAddress)
}

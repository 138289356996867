import { AnyAction } from 'redux'
import * as actions from '../actions/shipmentOverview'

export const clearShipmentOverviewState = (): AnyAction => ({
  type: actions.CLEAR_SHIPMENT_OVERVIEW_STATE,
})

export const shipmentOverviewGetData = (id: string | number): AnyAction => ({
  type: actions.SHIPMENT_OVERVIEW_GET_DATA,
  id,
})

export const shipmentOverviewGetDataSuccess = (payload: any): AnyAction => ({
  type: actions.SHIPMENT_OVERVIEW_GET_DATA_SUCCESS,
  payload,
})

export const carrierBookingCreateData = (
  id: number,
  payload: any
): AnyAction => ({
  type: actions.CARRIER_BOOKING_CREATE_DATA,
  id,
  payload,
})

export const carrierBookingCreateDataSuccess = (payload: any): AnyAction => ({
  type: actions.CARRIER_BOOKING_CREATE_DATA_SUCCESS,
  payload,
})

export const carrierBookingRequestData = (
  id: number,
  payload: any
): AnyAction => ({
  type: actions.CARRIER_BOOKING_REQUEST_DATA,
  id,
  payload,
})

export const carrierBookingRequestDataSuccess = (payload: any): AnyAction => ({
  type: actions.CARRIER_BOOKING_REQUEST_DATA_SUCCESS,
  payload,
})

export const carrierBookingGetData = (id: number, payload: any): AnyAction => ({
  type: actions.CARRIER_BOOKING_GET_DATA,
  id,
  payload,
})

export const carrierBookingGetDataSuccess = (payload: any): AnyAction => ({
  type: actions.CARRIER_BOOKING_GET_DATA_SUCCESS,
  payload,
})

export const carrierBookingUpdateData = (
  shipmentId: number,
  bookingId: number,
  payload: any
): AnyAction => ({
  type: actions.CARRIER_BOOKING_UPDATE_DATA,
  shipmentId,
  bookingId,
  payload,
})

export const updateShipmentReference = (
  id: number,
  payload: any
): AnyAction => ({
  type: actions.UPDATE_SHIPMENT_REFERENCE,
  id,
  payload,
})

export const updateShipmentReferenceSuccess = (payload: any): AnyAction => ({
  type: actions.UPDATE_SHIPMENT_REFERENCE_SUCCESS,
  payload,
})

export const shipmentOverviewUnpinMessage = (
  chatId: number,
  messageId: number
): AnyAction => ({
  type: actions.SHIPMENT_OVERVIEW_UNPIN_MESSAGE,
  chatId,
  messageId,
})

export const shipmentOverviewUnpinMessageSuccess = (
  payload: any
): AnyAction => ({
  type: actions.SHIPMENT_OVERVIEW_UNPIN_MESSAGE_SUCCESS,
  payload,
})

export const toggleFlag = (id: string, payload: any): AnyAction => ({
  type: actions.SHIPMENTS_TOGGLE_FLAG,
  id,
  payload,
})

export const toggleFlagSuccess = (payload: any): AnyAction => ({
  type: actions.SHIPMENTS_TOGGLE_FLAG_SUCCESS,
  payload,
})

export const toggleWatch = (id: string): AnyAction => ({
  type: actions.SHIPMENTS_TOGGLE_WATCH,
  id,
})

export const toggleWatchSuccess = (payload: any): AnyAction => ({
  type: actions.SHIPMENTS_TOGGLE_WATCH_SUCCESS,
  payload,
})

export const shipmentsOverviewGetPdfFiles = (id: string): AnyAction => ({
  type: actions.SHIPMENTS_OVERVIEW_GET_PDF_FILES,
  id,
})

export const shipmentsOverviewGetPdfFilesSuccess = (
  payload: any
): AnyAction => ({
  type: actions.SHIPMENTS_OVERVIEW_GET_PDF_FILES_SUCCESS,
  payload,
})

export const shipmentsOverviewSetDrawer = (payload: boolean): AnyAction => ({
  type: actions.SHIPMENT_OVERVIEW_SET_DRAWER,
  payload,
})

export const shipmentOverviewReQuote = (id: string): AnyAction => ({
  type: actions.SHIPMENT_RE_QUOTE,
  id,
})

export const setShipmentEditModalOpen = (payload: boolean): AnyAction => ({
  type: actions.SET_EDIT_SHIPMENT_MODAL_OPEN,
  payload,
})

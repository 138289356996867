import Tooltip from '@mui/material/Tooltip'
import { useState } from 'react'
import MuiAvatar, { AvatarProps } from '@mui/material/Avatar'
import { Badge, PopperPlacementType } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import UserCard, { IUserCardInfo } from './UserCard'

export type AvatarSize =
  | 'small'
  | 'medium'
  | 'large'
  | 'extra-large'
  | 'super-size'

const sizeMapper = (theme) => {
  return {
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      dotHeight: theme.spacing(1),
      dotWidth: theme.spacing(1),
    },
    medium: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      dotHeight: theme.spacing(1),
      dotWidth: theme.spacing(1),
    },
    large: {
      width: theme.spacing(7.5),
      height: theme.spacing(7.5),
      dotHeight: theme.spacing(1.25),
      dotWidth: theme.spacing(1.25),
    },
    'extra-large': {
      width: theme.spacing(12.5),
      height: theme.spacing(12.5),
      dotHeight: theme.spacing(1.5),
      dotWidth: theme.spacing(1.5),
    },

    'super-size': {
      width: theme.spacing(20),
      height: theme.spacing(20),
      dotHeight: theme.spacing(2),
      dotWidth: theme.spacing(2),
    },
  }
}

const BadgeSizeMapper = (size) => {
  const theme = useTheme()
  const mapper = sizeMapper(theme)
  let sizes = {
    maxWidth: mapper[size].width,
    maxHeight: mapper[size].height,
    dotHeight: mapper[size].dotHeight,
    dotWidth: mapper[size].dotWidth,
    bottom: '10%',
    right: '10%',
    outline: '2px solid white',
  }

  if (size === 'super-size') {
    sizes.outline = '3px solid white'
    delete (sizes as any).bottom
    delete (sizes as any).right
  }

  return sizes
}

const useStyles = makeStyles((theme) => ({
  small: {
    width: sizeMapper(theme)['small'].width,
    height: sizeMapper(theme)['small'].height,
    fontSize: 14,
  },
  medium: {
    width: sizeMapper(theme)['medium'].width,
    height: sizeMapper(theme)['medium'].height,
  },
  large: {
    width: sizeMapper(theme)['large'].width,
    height: sizeMapper(theme)['large'].height,
  },
  'extra-large': {
    width: sizeMapper(theme)['extra-large'].width,
    height: sizeMapper(theme)['extra-large'].height,
  },
  'super-size': {
    width: sizeMapper(theme)['super-size'].width,
    height: sizeMapper(theme)['super-size'].height,
  },
  badgeOutline: { outline: '1px solid white' },
}))

export interface ShyppleAvatarProps extends AvatarProps {
  doubleInitials?: boolean
  userCard?: boolean
  userInfo?: IUserCardInfo
  disablePopper?: boolean
  size?: AvatarSize
  placement?: PopperPlacementType
  /**
   * We use className to show certain color if `src` is not present.
   */
  className?: OrganizationRole | string
}
/**
 * Displaying Avatar of User/Organization/etc.
 *
 * Note: to force the behaviour when no `src` provided and to show only first letter
 * of the `alt` text, please add a non-empty string as a fallback for `src`, i.e.
 * `src={party.logo || 'no-logo'}`
 */
export const Avatar: React.FC<ShyppleAvatarProps> = ({
  size = 'medium',
  title = '',
  doubleInitials,
  userCard,
  userInfo,
  ...props
}) => {
  const classes = useStyles()
  const [show, setShow] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const onMouseEnter = (event): void => {
    setAnchorEl(event.currentTarget)
    setShow(true)
  }

  const onMouseLeave = (): void => setShow(false)

  const avatarComponent: JSX.Element = (
    <Badge
      invisible={!userInfo?.out_of_office_till}
      badgeContent="" // to display no-"variant=dot" badge with no text
      color="warning"
      sx={{
        maxHeight: BadgeSizeMapper(size).maxHeight,
        maxWidth: BadgeSizeMapper(size).maxWidth,
        '& .MuiBadge-badge': {
          outline: BadgeSizeMapper(size).outline,
          bottom: BadgeSizeMapper(size).bottom,
          right: BadgeSizeMapper(size).right,
          height: BadgeSizeMapper(size).dotHeight,
          width: BadgeSizeMapper(size).dotWidth,
          minWidth: '6px',
          padding: '0',
        },
      }}
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <MuiAvatar
        {...props}
        className={`${props.className ? props.className : ''} ${classes[size]}`}
        aria-describedby={props.src || props.alt}
        data-testid="avatar"
      >
        {doubleInitials ? props.alt : undefined}
      </MuiAvatar>
    </Badge>
  )

  return (
    <>
      {userCard ? (
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          {avatarComponent}
          <UserCard
            show={show}
            anchorEl={anchorEl}
            userInfo={userInfo}
            src={props.src}
            alt={props.alt}
            role={props.className}
            placement={props.placement}
            disablePopper={props.disablePopper}
            doubleInitials={doubleInitials}
          />
        </div>
      ) : (
        <Tooltip title={title} disableHoverListener={!title}>
          {avatarComponent}
        </Tooltip>
      )}
    </>
  )
}

import { useState, useCallback, SyntheticEvent } from 'react'
import i18n from 'i18next'
import {
  Paper,
  InputLabel,
  TextField,
  PaperProps,
  Autocomplete,
  MenuItemProps,
} from '@mui/material'
import { debounce } from 'lodash'
import BusinessIcon from '@mui/icons-material/Business'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { MenuItemWithIcon } from 'src/stories/Lab/Menu/components/MenuItemWithIcon'
import { selectOptionTypeIconMap } from '../../Form.constants'

const CustomPaper = (props: PaperProps) => {
  return <Paper elevation={8} {...props} />
}

const getRenderedOption = (props: MenuItemProps, option: any, { selected }) => {
  const { className, ...otherProps } = props
  const icon = selectOptionTypeIconMap?.[option.type] ?? BusinessIcon

  return (
    <MenuItemWithIcon
      icon={icon}
      {...otherProps}
      selected={selected}
      text={option.label as string}
    />
  )
}

const ShipmentTemplateFormAsyncAutocomplete = ({
  label = null,
  required,
  selected,
  options,
  onChange,
  isFetched,
  isFetching,
  onInputChange,
  disabled = false,
  inputPlaceholder = i18n.t('common.select', 'Select'),
  ...otherProps
}) => {
  const [inputValue, setInputValue] = useState('')

  const noOptionsText =
    isFetched && inputValue.length > 0
      ? i18n.t('common.no_options', 'No options')
      : i18n.t('common.start_typing', 'Start typing')

  const handleOnChange = useCallback(
    (_event: SyntheticEvent<Element, Event>, newValue) => {
      onChange(newValue)
    },
    [onChange]
  )

  const handleDebounceFn = (search: string) => {
    onInputChange(search)
    setInputValue(search)
  }

  const handleInputChangeWithDebounce = useCallback(
    debounce(handleDebounceFn, 800),
    [onChange]
  )

  const handleOnInputChange = (
    _event: SyntheticEvent<Element, Event>,
    search: string
  ) => {
    handleInputChangeWithDebounce(search)
  }

  return (
    <>
      {label && <InputLabel required={required}>{label}</InputLabel>}
      <Autocomplete
        fullWidth
        value={selected}
        openOnFocus={true}
        disabled={disabled}
        options={options}
        loading={isFetching}
        autoSelect={true}
        filterOptions={(x) => x}
        onChange={handleOnChange}
        PaperComponent={CustomPaper}
        noOptionsText={noOptionsText}
        renderOption={getRenderedOption}
        onInputChange={handleOnInputChange}
        popupIcon={<KeyboardArrowDownIcon />}
        isOptionEqualToValue={(option, selectedOption) => {
          return option.value === selectedOption.value
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
            }}
            placeholder={inputPlaceholder}
          />
        )}
        {...otherProps}
      />
    </>
  )
}

export default ShipmentTemplateFormAsyncAutocomplete

import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import {
  Box,
  TableContainer,
  Typography,
  Button,
  Link as MuiLink,
} from '@mui/material'
import ShipmentReferencesModal, {
  getInternalReference,
} from 'src/components/ShipmentReferencesModal'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { promisifyAction } from 'src/utils'
import { AvatarGroup } from 'src/stories/AvatarGroup'
import { shipmentOverviewGetData } from '../../../stores/actionCreators'

interface Props {
  id: number
  title: string
}

const EditReferencesBlock = (props: Props) => {
  const [open, setOpen] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const dispatch = useDispatch()

  const { shipmentOverview, currentUser } = useSelector(
    (globalState: IGlobalState) => ({
      shipmentOverview: globalState.shipmentOverview,
      currentUser: globalState.user,
    }),
    shallowEqual
  )

  useEffect(() => {
    shipmentOverviewGetDataAsync(props.id.toString()).then(() =>
      setLoading(false)
    )
  }, [])

  const shipmentOverviewGetDataAsync = promisifyAction(
    dispatch,
    shipmentOverviewGetData
  )

  const collaboratorsAvatars = (shipmentOverview.collaborators || []).map(
    (party) => ({
      alt: party.name,
      src: party.logo || 'no-logo',
      className: party.organization_role_code || party.role_code,
    })
  )

  const userAvatars = [
    {
      alt: currentUser.organizationName,
      src: currentUser.organizationLogo || 'no-logo',
      className: currentUser.organizationRole as any,
    },
  ]

  const showModal = () => setOpen(true)

  const hideModal = () => setOpen(false)

  const { t } = useTranslation()

  return (
    <div style={{ display: 'flex' }}>
      <Paper className="search-book__info" style={{ flex: '1.3 1' }}>
        <div className="shipment-organizations--block">
          <div className="shipment-organizations--header">
            <Typography
              variant="h3"
              children={t(
                'search_and_book.shipment_references',
                'Shipment references'
              )}
            />
          </div>
        </div>
        <TableContainer component={TableContainerComponent}>
          <Table style={{ tableLayout: 'auto' }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  {t('search_and_book.description', 'Description')}
                </TableCell>
                <TableCell>
                  {t('search_and_book.reference', 'Reference')}
                </TableCell>
                <TableCell>
                  {t('search_and_book.visible_to', 'Visible to')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <>
                  {Array.from({ length: 3 }).map((_, i) => (
                    <TableRow key={i}>
                      {Array.from({ length: 3 }).map((_, j) => (
                        <TableCell key={j}>
                          <Box
                            height={24}
                            width="100%"
                            className="text-placeholder light-grey"
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              )}
              {!isLoading &&
                [
                  {
                    description: t(
                      'common.forms.fields.shipment_shared_reference.label',
                      'Shared reference'
                    ),
                    reference: shipmentOverview.shared_reference,
                    visible_to: (
                      <AvatarGroup
                        size="medium"
                        max={5}
                        avatars={collaboratorsAvatars}
                      />
                    ),
                  },
                  {
                    description: t(
                      'common.forms.fields.shipment_internal_reference.label',
                      'Internal reference'
                    ),
                    reference: getInternalReference(
                      shipmentOverview,
                      currentUser
                    ),
                    visible_to: (
                      <AvatarGroup
                        size="medium"
                        max={5}
                        avatars={userAvatars}
                      />
                    ),
                  },
                  {
                    description: t(
                      'common.forms.fields.shipment_number.label',
                      'Shipment number'
                    ),
                    reference: props.title,
                    visible_to: (
                      <AvatarGroup
                        size="medium"
                        max={5}
                        avatars={collaboratorsAvatars}
                      />
                    ),
                  },
                ].map((item) => (
                  <TableRow key={item.description}>
                    <TableCell>
                      <b>{item.description}</b>
                    </TableCell>
                    <TableCell>
                      <Box
                        overflow="hidden"
                        whiteSpace="normal"
                        textOverflow="ellipsis"
                        maxWidth="280px"
                        title={item.reference || ''}
                      >
                        {!item.reference && (
                          <MuiLink
                            variant="body1"
                            component="button"
                            onClick={showModal}
                          >
                            {t('search_and_book.add', 'Add')}
                          </MuiLink>
                        )}
                        {item.reference}
                      </Box>
                    </TableCell>
                    <TableCell>{item.visible_to}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box m={2} textAlign="right">
          <Button
            variant="outlined"
            onClick={showModal}
            children={t('search_and_book.edit_references', 'Edit references')}
          />
        </Box>
      </Paper>
      {open && (
        <ShipmentReferencesModal
          open
          shipmentTitle={props.title}
          shipmentId={props.id}
          onClose={hideModal}
        />
      )}
    </div>
  )
}

const TableContainerComponent = (props) => (
  <Paper {...props} square elevation={0} />
)

export default EditReferencesBlock

import {
  useQuery,
  UseQueryOptions,
  useMutation,
  UseMutationOptions,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  ICreateShipmentRequest,
  ICreateShipmentResponse,
  IIsUniqueTrackingKeyRequest,
  IIsUniqueTrackingKeyResponse,
  IShipmentFiltersResponse,
  IShipmentsMetaResponse,
  IShipmentsResponse,
  IUpdateShipmentTaskRequest,
  IUpdateShipmentTaskResponse,
  IShipmentOverviewResponse,
  IShipmentBookingsResponse,
  IShipmentContainersResponse,
  IVisibilityShipmentCarriersResponse,
} from 'src/@types/endpoints/shipments'
import endpoints, { queryKeys } from '../endpoints'
import { useQueryAsync } from '../hooks'
import { apiClient } from '../http-common'
import { createQueryString } from '../utils'

export const useUpdateShipmentTask = (
  options?: UseMutationOptions<
    IUpdateShipmentTaskResponse,
    AxiosError,
    IUpdateShipmentTaskRequest
  >
) => {
  const updateShipmentTask = (data: IUpdateShipmentTaskRequest) => {
    const { task_id, ...payload } = data
    const url = `${endpoints.shipments}/${data.shipment_id}/tasks/${task_id}`

    return apiClient.put<any, IUpdateShipmentTaskResponse>(
      url,
      JSON.stringify(payload)
    )
  }

  return useMutation(updateShipmentTask, options)
}

export const useGetShipmentOverview = (
  payload: { shipmentId: string },
  options?: UseQueryOptions<
    IShipmentOverviewResponse,
    AxiosError,
    IShipmentOverviewResponse
  >
) => {
  const url = `${endpoints.shipments}/${payload.shipmentId}/overview`
  const { shipmentOverview: key } = queryKeys

  const getShipmentOverview = () =>
    apiClient.get<any, IShipmentOverviewResponse>(url)

  return useQuery<IShipmentOverviewResponse, AxiosError>(
    [key, payload.shipmentId],
    getShipmentOverview,
    options
  )
}

export const useGetShipmentBookings = (
  payload: { shipmentId: string },
  options?: UseQueryOptions<
    IShipmentBookingsResponse,
    AxiosError,
    IShipmentBookingsResponse
  >
) => {
  const url = `${endpoints.shipments}/${payload.shipmentId}/bookings`
  const { shipmentBookings: key } = queryKeys

  const getShipmentBookings = () =>
    apiClient.get<any, IShipmentBookingsResponse>(url)

  return useQuery<IShipmentBookingsResponse, AxiosError>(
    [key, payload.shipmentId],
    getShipmentBookings,
    options
  )
}

export const useGetShipmentContainers = (
  payload: { shipmentId: string },
  options?: UseQueryOptions<
    IShipmentContainersResponse,
    AxiosError,
    IShipmentContainersResponse
  >
) => {
  const url = `${endpoints.shipments}/${payload.shipmentId}/containers`
  const { shipmentContainers: key } = queryKeys

  const getShipmentContainers = () =>
    apiClient.get<any, IShipmentContainersResponse>(url)

  return useQuery<IShipmentContainersResponse, AxiosError>(
    [key, payload.shipmentId],
    getShipmentContainers,
    options
  )
}
export const useGetShipmentsAsync = (
  payload = {},
  options?: UseQueryOptions<IShipmentsResponse, AxiosError, IShipmentsResponse>
) => {
  let queryString = createQueryString(payload)
  const { shipments: key } = queryKeys

  const getShipments = (payload: object = {}) => {
    const url = endpoints.shipments
    queryString = Object.keys(payload).length
      ? createQueryString(payload)
      : queryString

    return apiClient.get<any, IShipmentsResponse>(url + queryString)
  }

  return useQueryAsync<IShipmentsResponse, AxiosError>(
    [key, queryString],
    getShipments,
    options
  )
}

export const useGetShipments = (
  payload = {},
  options?: UseQueryOptions<IShipmentsResponse, AxiosError, IShipmentsResponse>
) => {
  return useGetShipmentsAsync(payload, { enabled: true, ...options })
}

export const useCreateShipment = (
  options?: UseMutationOptions<
    ICreateShipmentResponse,
    AxiosError,
    ICreateShipmentRequest
  >
) => {
  const createShipment = (payload: ICreateShipmentRequest) => {
    const url = endpoints.shipments

    return apiClient.post<any, ICreateShipmentResponse>(
      url,
      JSON.stringify(payload)
    )
  }

  return useMutation(createShipment, options)
}

export const useGetShipmentFilters = (
  options?: UseQueryOptions<
    IShipmentFiltersResponse,
    AxiosError,
    IShipmentFiltersResponse
  >
) => {
  const url = endpoints.shipmentsFiltersData
  const { shipmentsFiltersData: key } = queryKeys

  const getShipmentFilters = () =>
    apiClient.get<any, IShipmentFiltersResponse>(url)

  return useQuery<IShipmentFiltersResponse, AxiosError>(
    [key],
    getShipmentFilters,
    {
      select: (data) => {
        data.statuses = data.scope_statuses.all.map((x) => [x.name, x.id])
        return data
      },
      ...options,
    }
  )
}

export const useGetShipmentsMetaAsync = (
  payload = {},
  options?: UseQueryOptions<
    IShipmentsMetaResponse,
    AxiosError,
    IShipmentsMetaResponse
  >
) => {
  let queryString = createQueryString(payload)
  const { shipmentsMeta: key } = queryKeys

  const getShipmentsMeta = (payload: any = {}) => {
    const url = endpoints.shipmentsMeta
    queryString = Object.keys(payload).length
      ? createQueryString(payload)
      : queryString

    return apiClient.get<any, IShipmentsMetaResponse>(url + queryString)
  }

  return useQueryAsync<IShipmentsMetaResponse, AxiosError>(
    [key, queryString],
    getShipmentsMeta,
    options
  )
}

export const useGetShipmentsMeta = (
  payload = {},
  options?: UseQueryOptions<
    IShipmentsMetaResponse,
    AxiosError,
    IShipmentsMetaResponse
  >
) => {
  return useGetShipmentsMetaAsync(payload, { enabled: true, ...options })
}

export const useGetVisibilityShipmentCarriers = (
  payload = {},
  options?: UseQueryOptions<
    IVisibilityShipmentCarriersResponse,
    AxiosError,
    IVisibilityShipmentCarriersResponse
  >
) => {
  const queryString = createQueryString(payload)
  const url = endpoints.shipmentsVisibilityShipmentCarriers + queryString
  const { shipmentsVisibilityShipmentCarriers: key } = queryKeys

  const getVisibilityShipmentCarriers = () =>
    apiClient.get<any, IVisibilityShipmentCarriersResponse>(url)
  return useQuery<IVisibilityShipmentCarriersResponse, AxiosError>(
    [key, queryString],
    getVisibilityShipmentCarriers,
    options
  )
}

export const useGetIsUniqueTrackingKeyAsync = (
  options?: UseQueryOptions<
    IIsUniqueTrackingKeyResponse,
    AxiosError,
    IIsUniqueTrackingKeyResponse
  >
) => {
  const { shipmentsUniqueTrackingKey: key } = queryKeys

  const getIsUniqueTrackingKey = (payload: IIsUniqueTrackingKeyRequest) => {
    const queryString = createQueryString(payload)
    const url = endpoints.shipmentsUniqueTrackingKey + queryString

    return apiClient.get<any, IIsUniqueTrackingKeyResponse>(url)
  }

  return useQueryAsync([key], getIsUniqueTrackingKey, options)
}

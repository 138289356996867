import { ReactNode } from 'react'
import Grid from '@mui/material/Grid'
import Input from 'src/components/Common/Input/MuiInput'
import { convertDateToFullWithTime } from 'src/utils/helpers'

const onChange = () => {}

interface InputData {
  name: string
  label: string
  value: string
  disabled?: boolean
}

const BankDetails = ({ details }: { details: IBankDetails | null }) => {
  if (!details) return null
  const inputsData: InputData[] = [
    {
      name: 'bank_account_number',
      label: 'Bank account number',
      value: details.bank_account_number,
    },
    {
      name: 'bic_number',
      label: 'BIC number (SWIFT code)',
      value: details.bic_number,
    },
    {
      name: 'bank_name',
      label: 'Bank name',
      value: details.bank_name,
    },
    {
      name: 'bank_city',
      label: 'City (bank location)',
      value: details.bank_city,
    },
    {
      name: 'requested_by.full_name',
      label: 'Requested by & on',
      value: details.requested_by
        ? `${details.requested_by.full_name} ${convertDateToFullWithTime(
            details.requested_at
          )}`
        : '',
    },
    {
      name: 'approved_by.full_name',
      label: 'Approved by & on',
      value: details.approved_by
        ? `${details.approved_by.full_name} ${convertDateToFullWithTime(
            details.approved_at
          )}`
        : '',
    },
  ]

  const renderInputs = (array: InputData[]): ReactNode[] => {
    return array.map((item) => (
      <Grid item mb={3}>
        <Input
          name={item.name}
          label={item.label}
          value={item.value}
          onChange={onChange}
          disabled={true}
        />
      </Grid>
    ))
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4} mb={3}>
        {renderInputs(inputsData.slice(0, 3))}
      </Grid>
      <Grid item xs={12} sm={4} mb={3}>
        {renderInputs(inputsData.slice(3, inputsData.length))}
      </Grid>
    </Grid>
  )
}

export default BankDetails

import { isArray } from 'lodash'
import { MenuItemWithCheckbox } from '../Menu/components/MenuItemWithCheckbox'

export const MultiSelectSelectAll = ({ selected, options, onClick }) => {
  const isAllSelected =
    isArray(selected) &&
    options.length > 0 &&
    selected.length === options.length

  const isIndeterminate =
    isArray(selected) && selected.length > 0 && selected.length < options.length

  return (
    <MenuItemWithCheckbox
      value="all"
      sx={{
        borderStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: 'grey.200',
      }}
      data-testid="multi-select-all"
      text={`${isIndeterminate || isAllSelected ? 'Deselect' : 'Select'} all`}
      selected={isAllSelected}
      onClick={onClick}
      checkboxProps={{
        indeterminate: isIndeterminate,
      }}
    />
  )
}

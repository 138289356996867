export const prepareForRequest = (state): any => ({
  name: state.name || '',
  email: state.email || '',
  phone: state.phone || '',
  comment: state.comment || '',
  contact_id: state.contact_id || '',
})

export const handleAfterSelect = (contact): any => ({
  name: contact.name || '',
  email: contact.email || '',
  phone: contact.phone || '',
  comment: contact.comment || '',
  contact_id: contact.id || '',
})

export const defaultAddress = {
  city: '',
  comment: '',
  company_name: '',
  eori_number: '',
  postal_code: '',
  address: '',
  vat_number: '',
}

export const initialState = {
  contacts: [],
  contact_id: null,
  name: '',
  email: '',
  phone: '',
  comment: '',
  validators: [],
  baseState: {},
  errorNotes: {},
  openForNew: false,
  checkingEmail: false,
}

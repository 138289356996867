import { QueryClient } from '@tanstack/react-query'
import Axios from 'axios'
import { onRejected } from './utils'
import { getCredentials } from './utils/http-credentials'

const axios = Axios.create({
  timeout: 90000,
  baseURL: window.shyppleConfig.apiUrl + '/api/v1',
  headers: {
    'Content-type': 'application/json',
    'Cache-Control': 'no-cache',
    ...getCredentials(),
  },
})
axios.interceptors.response.use((res) => res.data, onRejected)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

export { axios as apiClient, queryClient }

import IconButton from '@mui/material/IconButton'
import { ButtonProps, IconProps, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

interface TopNavigationKnowledgedBaseLinkProps {
  iconColor?: string
  iconSize?: IconProps['fontSize']
  buttonSize?: ButtonProps['size']
}

const TopNavigationKnowledgedBaseLink: React.FC<TopNavigationKnowledgedBaseLinkProps> = ({
  iconSize = 'medium',
  buttonSize = 'medium',
  iconColor = 'common.white',
}) => {
  const { t } = useTranslation()
  return (
    <Tooltip
      title={t('top_navigation.knowledge_base.tooltip', 'Knowledge base')}
    >
      <IconButton
        href={t(
          'top_navigation.knowledge_base.link',
          'https://help.shypple.com/en/?utm_source=platform&utm_medium=navigation-button&utm_campaign=knowledge-base-promotion'
        )}
        target="_blank"
        size={buttonSize}
      >
        <HelpOutlineIcon sx={{ color: iconColor }} fontSize={iconSize} />
      </IconButton>
    </Tooltip>
  )
}

export default TopNavigationKnowledgedBaseLink

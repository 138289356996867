import { FunctionComponent, useState, useEffect, memo } from 'react'
import { times, toNumber, includes } from 'lodash'
import './styles.scss'

export interface PaginationProps {
  theme?: string
  onChangePage: (value: number) => void
  currentPage?: number
  totalItemsNumber: number
  itemsPerPage: number
}

const Pagination: FunctionComponent<PaginationProps> = memo((props) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const totalPagesNumber: number = Math.ceil(
    props.totalItemsNumber / props.itemsPerPage
  )

  useEffect(() => {
    if (props.currentPage) {
      setCurrentPage(props.currentPage)
    }
  }, [props])

  const isFirstPage: boolean = currentPage === 1
  const isLastPage: boolean = currentPage === (totalPagesNumber || 1)

  const onChangePage = (event: React.MouseEvent): void => {
    const pageNumber: number = toNumber(event.currentTarget.id)
    if (currentPage !== pageNumber) {
      props.onChangePage(pageNumber)
      setCurrentPage(pageNumber)
    }
  }

  const previousPage = (): void => {
    if (!isFirstPage) {
      props.onChangePage(currentPage - 1)
      setCurrentPage(currentPage - 1)
    }
  }

  const nextPage = (): void => {
    if (!isLastPage) {
      props.onChangePage(currentPage + 1)
      setCurrentPage(currentPage + 1)
    }
  }

  const showPage = (index: number): boolean => {
    const pageNumber: number = props.currentPage || 1
    const pagesArr: number[] = [
      1,
      pageNumber - 1,
      pageNumber,
      pageNumber + 1,
      totalPagesNumber,
    ]
    if (pageNumber === 1 || (pageNumber === 2 && totalPagesNumber > 5)) {
      pagesArr.push(3)
    }
    return totalPagesNumber <= 5 || includes(pagesArr, index)
  }

  const showDots = (index: number, showAtBeginning: boolean): boolean => {
    if (totalPagesNumber > 5) {
      if (showAtBeginning) {
        return (
          currentPage - 1 === index &&
          currentPage - 1 !== 1 &&
          currentPage - 2 !== 1
        )
      } else {
        return (
          currentPage + (currentPage === 1 ? 2 : 1) === index &&
          currentPage + 1 !== totalPagesNumber &&
          currentPage + 2 !== totalPagesNumber
        )
      }
    }
    return false
  }

  const pagesRow = (): React.ReactNode => {
    return (
      <>
        {times(totalPagesNumber || 1, (index: number) => {
          return (
            <div className="flex" key={`${index + 1}`}>
              {showPage(index + 1) && showDots(index + 1, true) && (
                <div>...</div>
              )}
              {showPage(index + 1) && (
                <div
                  id={`${index + 1}`}
                  data-testid={`page-${index + 1}`}
                  className={`common__pagination--page-number ${
                    currentPage === index + 1 ? 'selected' : ''
                  }`}
                  onClick={onChangePage}
                >
                  {index + 1}
                </div>
              )}
              {showPage(index + 1) && showDots(index + 1, false) && (
                <div>...</div>
              )}
            </div>
          )
        })}
      </>
    )
  }

  const itemsRange = (): string => {
    switch (props.currentPage) {
      case 1:
        return `${props.totalItemsNumber ? 1 : 0}-${
          props.itemsPerPage > props.totalItemsNumber
            ? props.totalItemsNumber
            : props.itemsPerPage
        }`
      case totalPagesNumber:
        return `${(totalPagesNumber - 1) * props.itemsPerPage + 1}-${
          props.totalItemsNumber
        }`
      default:
        return `${((props.currentPage || 1) - 1) * props.itemsPerPage + 1}-${
          (props.currentPage || 1) * props.itemsPerPage
        }`
    }
  }

  return (
    <div className={`common__pagination ${props.theme || ''}`}>
      <i
        className={`icon chevron back ${isFirstPage ? 'disabled' : ''}`}
        onClick={previousPage}
      />
      <div className="common__pagination--pages-row">{pagesRow()}</div>
      <i
        className={`icon chevron next ${isLastPage ? 'disabled' : ''}`}
        onClick={nextPage}
      />
      <div
        className="common__pagination--counters"
        data-testid="pagination-totals"
      >
        {`(${itemsRange()} of ${props.totalItemsNumber})`}
      </div>
    </div>
  )
})

export default Pagination

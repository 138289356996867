import { Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogContent } from 'src/stories'

interface IProps {
  open: boolean
  onClose: () => void
  onSave: () => void
}
export const CargoWindowWarningMessageModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  return (
    <Dialog
      title={t(
        'shipment_containers.cost_of_shipment_change.title',
        'Cost of shipment might change'
      )}
      onClose={props.onClose}
      maxWidth="sm"
      open={props.open}
      actions={
        <>
          <Button
            variant="text"
            color="primary"
            data-testid="warning-cancel-button"
            size="large"
            onClick={props.onClose}
          >
            {t('common.buttons.cancel', 'Cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={props.onSave}
          >
            {t(
              'shipment_containers.cost_of_shipment_change.confirm',
              'OK, I understand'
            )}
          </Button>
        </>
      }
    >
      <DialogContent>
        <Typography
          variant="body1"
          sx={{ whiteSpace: 'pre-line' }}
          data-testid="cost-of-shipment-change-description"
        >
          {t(
            'shipment_containers.cost_of_shipment_change.text',
            'You are about to change the volume and/or weight of your cargo. This might impact the cost of your shipment. We will review the details and send you an update shortly.'
          )}
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

import Tooltip from '@mui/material/Tooltip'
import WarningIcon from '@mui/icons-material/Warning'

const WarningIconWithTooltip = ({ title, color }) => (
  <Tooltip title={title} placement="right">
    <WarningIcon
      fontSize="small"
      sx={{ mx: 0.5, color, cursor: 'pointer' }}
      data-testid="deliveries-fcl-warning-icon"
    />
  </Tooltip>
)

export default WarningIconWithTooltip

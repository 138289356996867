import { FunctionComponent, memo, useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Table from '@mui/material/Table'
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Skeleton from '@mui/material/Skeleton'
import { times } from 'lodash'
import PickupsDeliveryTableHeader from '../PickupsDeliveryTableHeader'

const BlankTable: FunctionComponent<{}> = () => {
  const theme = useTheme()
  const isScreenSmall = useMediaQuery(theme.breakpoints.down('md'))

  const { tableColumns } = useSelector(
    (state: IGlobalState) => ({
      tableColumns: state.pickUpsAndDeliveries.tableColumns,
    }),
    shallowEqual
  )

  const columnLength = useMemo(() => {
    let length = tableColumns.length

    tableColumns.forEach((item: ITableConfiguration) => {
      if (item.name.includes('Task') && item.task_type_group !== null)
        length += item.task_type_group.task_types.length - 1
    })

    return length + 1
  }, [tableColumns])

  return (
    <TableContainer
      className="no-scroll"
      component={(props) => <Paper {...props} square elevation={0} />}
    >
      <Table size={isScreenSmall ? 'small' : 'medium'}>
        <PickupsDeliveryTableHeader />
        <TableBody>
          {times(10, (i) => {
            return (
              <TableRow key={`${i}-empty-row`}>
                {times(columnLength, (j) => {
                  return (
                    <TableCell key={`${j}-empty-cell`}>
                      <Skeleton animation="wave" />
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default memo(BlankTable)

import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { StyledStepBox } from './SABSearchItem.styles'
import SABSearchItemTimelineTransitChip from './SABSearchItemTimelineTransitChip'
import { SABSearchItemTimelineCarrierAndTransitProps } from './SABSearchItem.props'

const SABSearchItemTimelineCarrierAndTransit: React.FC<SABSearchItemTimelineCarrierAndTransitProps> = ({
  carrier,
  showCarrier,
  transitTime,
}) => {
  const { t } = useTranslation()
  return (
    <Box
      textAlign="center"
      flexDirection="column"
      data-testid="search-and-book-item-carrier-and-transit"
    >
      <StyledStepBox>
        <SABSearchItemTimelineTransitChip transitTime={transitTime} />
      </StyledStepBox>
      {showCarrier && (
        <Box sx={{ pt: 0.5 }}>
          <Typography
            variant="body1Strong"
            children={t('common.modality_sea.carrier', 'Carrier')}
            mt={1}
          />
          <Typography children={carrier} />
        </Box>
      )}
    </Box>
  )
}

export default SABSearchItemTimelineCarrierAndTransit

import { find, get } from 'lodash'
import * as tabs from './tabRoutes'

type TabMap = [string, RegExp]
const tabMap: TabMap[] = [
  [tabs.bookings, /\/shipments\/.+?\/bookings\/?/],
  [tabs.instructions, /\/shipments\/.+?\/instructions\/?/],
  [tabs.documentation, /\/shipments\/.+?\/documentation\/?/],
  [tabs.purchaseOrders, /\/shipments\/.+?\/orders\/?/],
  [tabs.track, /\/shipments\/.+?\/track\/?/],
  [tabs.costs, /\/shipments\/.+?\/price-structure\/?/],
  [tabs.containers, /\/shipments\/.+?\/containers\/?/],
  [tabs.chats, /\/shipments\/.+?\/chats\/?.*/],
  [tabs.agentsChat, /\/shipments\/.+?\/agents-chat\/?.*/],
  [tabs.orders, /\/shipments\/.+?\/orders\/?.*/],
  [tabs.tasks, /\/shipments\/.+?\/tasks\/?.*/],
]

const isMatch = (route: string, [field, regex]: any[]): boolean =>
  regex.test(route)

export default (route: string): string =>
  get(find(tabMap, isMatch.bind(null, route)), 0, tabs.bookings)

// @ts-nocheck
// FIXME

import { FunctionComponent, useState, useRef, useEffect } from 'react'
import { includes } from 'lodash'
import MenuItem from '@mui/material/MenuItem'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'

import './styles.scss'

interface IProps {
  className?: string
  placeholder?: string
  disabled?: boolean
  fieldName?: string
  title?: string
  allOptionTitle?: string
  selectedTitle?: string
  values?: (string | number)[]
  theme?: string
  onChange: (option: string, fieldName?: string, item?: string[]) => void
  onSelectAllOptions?: (checked: boolean, fieldName: string) => void
  onCancel: (fieldName: string) => void
  onSearch?: (query: string) => void
  options?: Array<Array<string | number>>
  disabledOptions?: string[]
  searchable?: boolean
  selectableAll?: boolean
  emptySearchMessage?: string
  size?: string
  resultsLimit?: number
  disableSorting?: boolean
  error?: boolean
}

const OptionsPicker: FunctionComponent<IProps> = (props) => {
  const [search, setSearch] = useState<string>('')
  const [isAllOptionsPicked, setIsAllOptionsPicked] = useState<boolean>(false)
  const isActive = (props.values || []).length > 0
  const searchBarRef = useRef<HTMLInputElement>(null)
  const selectRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (props.selectableAll && props.values && props.options) {
      setIsAllOptionsPicked(props.values.length === props.options.length)
    }
  }, [props.values, props.options])

  const handlePickOption = (
    event: React.ChangeEvent<any>,
    option: string[]
  ): void => {
    props.onChange(event.target.id, props.fieldName || '', option)
  }

  const handlePickAllOptions = (): void => {
    setIsAllOptionsPicked(!isAllOptionsPicked)
    const isNotAllSelected: boolean =
      !!(props.values || []).length &&
      (props.values || []).length < (props.options || []).length
    props.onSelectAllOptions &&
      props.onSelectAllOptions(
        isAllOptionsPicked || isNotAllSelected,
        props.fieldName || ''
      )
  }

  const renderValue = (values): React.ReactNode => {
    return (
      <Box
        sx={{
          color: values.length ? 'primary.main' : 'grey.400',
        }}
        data-testid="multiselect-value"
      >
        {values.length ? (
          `${props.selectedTitle || props.fieldName} (${values.length})`
        ) : (
          <span>{props.title}</span>
        )}
      </Box>
    )
  }

  const arrowIcon = () => {
    return <HighlightOffIcon onClick={clearOptions} />
  }

  const handleChangeSearch = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setSearch(event.target.value)
    if (props.onSearch) {
      props.onSearch(event.target.value)
    }
  }

  const matchSearch = (option: string[]): boolean => {
    return (
      !includes(
        (props.values || []).map((x) => `${x}`),
        `${option[1]}`
      ) &&
      (!search || option[0].toLowerCase().includes(search.toLowerCase()))
    )
  }

  const close = (): void => {
    if (selectRef && selectRef.current) {
      selectRef.current.classList.remove(
        'shypple-options-picker__root--focused',
        'Mui-focused'
      )
    }
    setSearch('')
  }

  const clearOptions = () => {
    props.onCancel(props.fieldName || '')
  }

  const optionName = (option: string): string => {
    return option.split('- ')[1] || ''
  }

  const pickedOptionIds: string[] = (props.values || []).map((x) => `${x}`)

  const limitedOptions: string[][] = (props.options || [])
    .filter(matchSearch)
    .sort((a: string[], b: string[]): number =>
      props.disableSorting ? 0 : a[0] > b[0] ? 1 : -1
    )
    .slice(0, (props.resultsLimit || 50) - pickedOptionIds.length)
    .map((x: string[]) => [`${x[0]}`, `${x[1]}`])

  const selectedOptions: string[][] = (props.options || [])
    .filter((x: string[]) => includes(pickedOptionIds, `${x[1]}`))
    .map((x: string[]) => [`${x[0]}`, `${x[1]}`])

  const options: string[][] = selectedOptions.concat(limitedOptions)

  const isAllOptionsSelected: boolean =
    (props.values || []).length === options.length
  const isAllOptionsDisabled: boolean =
    isActive && (props.values || []).length < options.length

  return (
    <Select
      id="shypple-options-picker"
      classes={{
        root: 'shypple-options-picker',
      }}
      inputProps={{ 'data-testid': 'multiselect' }}
      multiple={true}
      className={props.className}
      disabled={props.disabled}
      displayEmpty={true}
      value={props.values || []}
      onClose={close}
      ref={selectRef}
      MenuProps={{
        classes: {
          paper: `shypple-options-picker__menu ${props.size || ''}`,
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      IconComponent={props.values?.length ? arrowIcon : ExpandMoreIcon}
      renderValue={renderValue}
    >
      <div className="shypple-options-picker__search-container">
        {props.searchable && (
          <input
            ref={searchBarRef}
            className="shypple-options-picker__search"
            data-testid="multiselect-search"
            placeholder=""
            value={search}
            onChange={handleChangeSearch}
          />
        )}
      </div>
      {!options.length && (
        <div
          className={`shypple-options-picker__empty-results ${
            !props.emptySearchMessage ? 'grey' : ''
          }`}
        >
          {props.emptySearchMessage || 'No results'}
        </div>
      )}
      {props.selectableAll && options.length && (
        <div
          id="all-option"
          onClick={handlePickAllOptions}
          className={'shypple-options-picker__search-switch'}
        >
          <Checkbox
            name="all-option"
            checked={isAllOptionsSelected}
            onChange={handlePickAllOptions}
            indeterminate={isAllOptionsDisabled}
          />
          <div
            id="all-option"
            className="shypple-options-picker__option-name"
            onClick={handlePickAllOptions}
          >
            All {props.allOptionTitle}
          </div>
        </div>
      )}
      {(options || []).map((option) => (
        <div key={option[1]}>
          <MenuItem
            sx={{ padding: `4px 16px 0px 8px` }}
            id={option[1]}
            value={option[1]}
            onClick={(event) => handlePickOption(event, option)}
            classes={{
              root: `shypple-options-picker__search-item ${
                includes(props.disabledOptions || [], option[1])
                  ? 'disabled'
                  : ''
              }`,
              selected: 'shypple-options-picker__search-item--selected',
            }}
            disabled={props.disabled}
          >
            <Checkbox
              id={option[1]}
              name={option[1]}
              className="shypple-options-picker__option-checkbox"
              checked={includes(props.values || [], option[1])}
              disabled={includes(props.disabledOptions || [], option[1])}
            />
            <Typography noWrap id={option[1]}>
              {includes(['pols', 'pods'], props.fieldName)
                ? optionName(option[0])
                : option[0]}
            </Typography>
          </MenuItem>
        </div>
      ))}
    </Select>
  )
}

export default OptionsPicker

import { FunctionComponent } from 'react'
import MuiTabs, { TabsProps } from '@mui/material/Tabs'
import MuiTab, { TabProps } from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'

type TabIconDirection = 'vertical' | 'horizontal'

export interface TabComponentProps extends TabProps {
  iconDirection?: TabIconDirection
}

const tabClasses = makeStyles({
  root: {
    '& .MuiTab-wrapper': {
      flexDirection: ({ iconDirection }: { iconDirection: TabIconDirection }) =>
        iconDirection === 'vertical' ? 'column' : 'row',
    },
  },
})

const Tab = (tabProps: TabComponentProps) => {
  const { iconDirection, ...restProps } = tabProps
  const classes = tabClasses({
    iconDirection: tabProps.iconDirection || 'vertical',
  })
  return (
    <MuiTab
      {...restProps}
      className={`${classes.root} ${restProps.className}`}
      data-testid={tabProps.value}
    />
  )
}

interface TabsComponentProps extends TabsProps {
  /**
   * Array of tab objects that supports MUI Tab props
   */
  tabs: TabComponentProps[]
  onChange: (value: TabsProps['value']) => void
}

const tabsClasses = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
})

const TabsComponent: FunctionComponent<TabsComponentProps> = ({
  tabs,
  onChange,
  ...tabsProps
}) => {
  const classes = tabsClasses()
  const onTabChanged = (
    _event: React.ChangeEvent<{}>,
    value: TabsProps['value']
  ) => {
    onChange(value)
  }

  return (
    <MuiTabs
      {...tabsProps}
      onChange={onTabChanged}
      className={`${classes.root} ${tabsProps.className}`}
    >
      {tabs.map((tabProps, index) => (
        <Tab key={index} {...tabProps} />
      ))}
    </MuiTabs>
  )
}

export interface Props extends TabsComponentProps {
  /**
   * A slot on the right side from the tabs to put custom react node. E.g. sorting
   */
  children?: React.ReactNode
}

const Tabs: FunctionComponent<Props> = ({
  tabs,
  onChange,
  children,
  ...tabsProps
}) => {
  const theme = useTheme()
  const borderStyles =
    tabsProps.orientation === 'vertical'
      ? undefined
      : `1px solid ${theme.palette.action.disabledBackground}`
  return (
    <Box
      borderBottom={borderStyles}
      display="flex"
      alignItems="center"
      data-testid="tabs-container"
    >
      <TabsComponent tabs={tabs} onChange={onChange} {...tabsProps} />
      {children && children}
    </Box>
  )
}

export default Tabs

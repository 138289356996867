import { FunctionComponent, memo, useMemo } from 'react'
import { Badge, Tooltip, Box } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import { ShipmentModalityIcon } from 'src/stories'
import { useTranslation } from 'react-i18next'
import { compact } from 'lodash'
import {
  badgeColorsBasedOnTnTStatus,
  iconColorsBasedOnTnTStatus,
  shyppleShipmentsTooltipText,
  visibilityShipmentsTooltipText,
  exceptionsCounter,
  warningBadge,
  BadgeColor,
} from '../../constants'

const ShipmentIndicator: FunctionComponent<{
  status: string | null
  visibility_only: boolean
  open_milestones: any
  shipment_rolled: boolean
  type: string
}> = memo(
  ({ status, visibility_only, open_milestones, shipment_rolled, type }) => {
    const { t } = useTranslation()
    const color: BadgeColor = status
      ? badgeColorsBasedOnTnTStatus[status]
      : 'default'

    const iconColor = status ? iconColorsBasedOnTnTStatus[status] : 'default'

    const isShyppleIconShown = (): boolean => {
      return !!status && (status === 'active' || status === 'completed')
    }

    const exceptionArray = useMemo(() => {
      return [
        shipment_rolled,
        !!status && status === 'error',
        open_milestones?.open_milestones_count +
          open_milestones?.overdue_milestones_count,
      ]
    }, [shipment_rolled, status, open_milestones])

    const isAnyException = useMemo(() => {
      return compact(exceptionArray).length > 0
    }, [exceptionArray])

    const badgeVariant = (): 'dot' | undefined => {
      return !isAnyException && isShyppleIconShown() ? 'dot' : undefined
    }

    const showWarningBadge = () => {
      return !isShyppleIconShown() ? warningBadge(color) : null
    }

    const badgeIndicator: JSX.Element = (
      <Badge
        sx={{ width: 20, marginRight: 2 }}
        badgeContent={
          isAnyException
            ? exceptionsCounter(compact(exceptionArray).length)
            : showWarningBadge()
        }
        variant={badgeVariant()}
        className={`${color} modality-badge`}
        color={color}
      >
        <ShipmentModalityIcon modality={type} />
      </Badge>
    )

    const tooltipTitle = (): string => {
      if (!status) {
        return t(
          'shipment_card.shipment_visibility_tooltip.no_visibility_subscription'
        )
      } else {
        if (visibility_only) {
          return t(visibilityShipmentsTooltipText[status])
        } else {
          return t(shyppleShipmentsTooltipText[status])
        }
      }
    }

    const tasksCount = (open, overdue) => {
      let type = ''
      switch (true) {
        case open && !overdue:
          type =
            'shipment_card.shipment_exceptions_open_and_overdue_tasksWithCount'
          break
        case overdue && !open:
          type = 'shipment_card.shipment_exceptions_overdue_tasksWithCount'
          break
        default:
          type = 'shipment_card.shipment_exceptions_open_tasksWithCount'
      }

      return t(type, { count: open + overdue })
    }

    const renderIcon = (status) => {
      return <CircleIcon sx={{ fontSize: '8px' }} color={status} />
    }

    const exceptionTitle = () => {
      return (
        <Box>
          {shipment_rolled && (
            <Box>
              {renderIcon('error')}{' '}
              {t('shipment_card.shipment_exceptions_shipment_rolling')}
            </Box>
          )}
          {open_milestones?.open_milestones_count +
            open_milestones?.overdue_milestones_count >
            0 && (
            <Box>
              {renderIcon('error')}{' '}
              {tasksCount(
                open_milestones?.open_milestones_count,
                open_milestones?.overdue_milestones_count
              )}
            </Box>
          )}
          {!!status && status === 'error' ? (
            <Box>
              {renderIcon('error')}{' '}
              {t('shipment_card.shipment_exceptions_track_and_trace_error')}
            </Box>
          ) : (
            warningTitle()
          )}
        </Box>
      )
    }

    const warningTitle = () => {
      return (
        <Box>
          <Box sx={{ display: 'inline' }}>{renderIcon(iconColor)}</Box>{' '}
          {tooltipTitle()}
        </Box>
      )
    }

    return (
      <Tooltip
        data-testid="tooltip-indicator"
        placement="right"
        title={
          isAnyException
            ? exceptionTitle()
            : isShyppleIconShown()
            ? tooltipTitle()
            : warningTitle()
        }
      >
        {badgeIndicator}
      </Tooltip>
    )
  }
)

export default ShipmentIndicator

import { isEqual } from 'lodash'
import { DateTime } from 'luxon'
import { useEffect, useRef, useState } from 'react'
import Picker from 'react-month-picker'
import MenuButton from 'src/components/MenuButton'
import './styles.scss'
import { monthYearToString, pickerWords } from './utils'

interface IMonthPickerProps<T> {
  onChange?: (dateRange: T) => void
  className?: string
  buttonColor?: string
  buttonColorActive?: string
  startDate: string
  endDate: string
  testId: string
}

interface IMonthPickerState {
  monthRange: {
    from: {
      year: number
      month: number
    }
    to: {
      year: number
      month: number
    }
  }
}

interface IListChanges {
  firstListChanged: boolean
  secondListChanged: boolean
}

const rightNow = new Date()

const initialState = {
  monthRange: {
    from: { year: rightNow.getFullYear() - 1, month: rightNow.getMonth() + 1 },
    to: { year: rightNow.getFullYear(), month: rightNow.getMonth() + 1 },
  },
}

const initialListChanges = {
  firstListChanged: false,
  secondListChanged: false,
}

function MonthPicker<T>(props: IMonthPickerProps<T>) {
  const listChanges = useRef<IListChanges>({ ...initialListChanges })
  const pickerElement = useRef(null)
  const [state, setState] = useState<IMonthPickerState>({ ...initialState })

  useEffect(() => {
    if (props.startDate) {
      const [month, year] = props.startDate.split('/').map(Number)
      initialState.monthRange.from = { month, year }
    }

    if (props.endDate) {
      const [month, year] = props.endDate.split('/').map(Number)
      initialState.monthRange.to = { month, year }
    }

    setState({ ...initialState })
  }, [])

  function buttonText(): string {
    const { from, to } = state.monthRange
    return `${monthYearToString(from)} ~ ${monthYearToString(to)}`
  }

  function rangeToOuterFormat(rangeObject: any): any {
    const { from, to } = rangeObject
    if (from.month && from.year && to.month && to.year) {
      return {
        from: `${from.month}/${from.year}`,
        to: `${to.month}/${to.year}`,
      }
    } else {
      return {
        from: '',
        to: '',
      }
    }
  }

  function openPicker(): void {
    ;(pickerElement.current as any).show()
  }

  function handleRangeChange(
    value: number,
    text: number,
    listIndex: number
  ): void {
    let { firstListChanged, secondListChanged } = listChanges.current
    if (listIndex === 0) {
      firstListChanged = true
    }

    if (listIndex === 1) {
      secondListChanged = true
    }
    if (firstListChanged && secondListChanged) {
      ;(pickerElement.current as any).dismiss()
      listChanges.current.firstListChanged = false
      listChanges.current.secondListChanged = false
    } else {
      listChanges.current.firstListChanged = firstListChanged
      listChanges.current.secondListChanged = secondListChanged
    }
  }

  function onCancel(): void {
    setState({ monthRange: initialState.monthRange })
    if (props.onChange) {
      props.onChange(rangeToOuterFormat(initialState.monthRange))
    }
  }

  function onDismissCallback(value: any): void {
    setState({ monthRange: value })
    if (props.onChange) {
      props.onChange(rangeToOuterFormat(value))
    }
  }

  const isActive = !isEqual(state.monthRange, initialState.monthRange)
  const now = DateTime.utc().plus({ months: 3 })

  return (
    <div className={props.className}>
      <MenuButton
        buttonColor={props.buttonColor || 'grey-outline'}
        buttonColorActive={props.buttonColorActive || 'blue-active'}
        className="month-picker-button"
        isActive={isActive}
        title={buttonText()}
        onClick={openPicker}
        onCancel={onCancel}
      />
      <Picker
        ref={pickerElement}
        years={{
          min: { year: 2017, month: 6 },
          max: { year: now.year, month: now.month + 1 },
        }}
        range={state.monthRange}
        lang={pickerWords}
        onChange={handleRangeChange}
        data-testId={props.testId}
        onDismiss={onDismissCallback}
      />
    </div>
  )
}

export default MonthPicker

import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { temperatureOptions } from 'src/pages/SearchAndBook/constants'
import SingleSelect from '../FormElements/SingleSelect'

const TemperatureSettings = ({ label, required }) => {
  const { t } = useTranslation()
  const options = temperatureOptions.map((option) => ({
    ...option,
    label: t(option.label),
  }))

  return (
    <Box width={240}>
      <SingleSelect
        label={label}
        required={required}
        fieldPath="temperatureSetting"
        options={options}
        SelectDisplayProps={{
          'data-testid': 'shipment-template-temperature-setting',
        }}
      />
    </Box>
  )
}

export default TemperatureSettings

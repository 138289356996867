// @ts-nocheck
// FIXME

import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import * as actions from '../actions/reports'

declare global {
  interface IReportsShipmentsCosts {
    reports: any
  }
  interface IReportsShipmentsCostsState {
    dataset: any
    filtrationData: {
      pols: Array<Array<string | number>>
      pods: Array<Array<string | number>>
      carriers: Array<Array<string | number>>
      consignees: string[]
      dates: string[]
      types: string[]
      statuses: Array<Array<string | number>>
      services: Array<Array<string | number>>
      currencies: Array<Array<string | number>>
      shippers: string[]
    }
  }
}

export const initialReportsShipmentsCostsState = {}

const receiveReportShipmentsCostsData: Reducer<
  IReportsShipmentsCostsState,
  AnyAction
> = (state, action) => ({
  ...state,
  dataset: action.payload.data.dataset,
  filtrationData: action.payload.data.filtration_data,
})

export default createReducer(initialReportsShipmentsCostsState, {
  [actions.REPORTS_GET_COSTS_OF_SHIPMENTS_SUCCESS]: receiveReportShipmentsCostsData,
})

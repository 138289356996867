// @ts-nocheck
// FIXME

import { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Link as MuiLink, Tooltip } from '@mui/material'
import Table from 'src/components/Common/Table'
import CustomSwitchButton from 'src/components/CustomSwitchButton'
import { updateSLAAlerts } from '../../../../stores/actionCreators'
import { promisifyAction } from '../../../../utils'

import './styles.scss'

interface IProps {
  slaCheckpoint: ISLA
  editItem: () => void
  deleteItem: () => void
  fetchData: () => void
}

const tableHeaders: any[] = [
  {
    name: 'ORGANIZATION',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned wide',
  },
  {
    name: 'EMAIL',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned wide',
  },
  {
    name: 'SEND REQUEST',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned wide',
  },
]

const ServiceLevelAgreementsRowDetails: FunctionComponent<IProps> = (props) => {
  const dispatch = useDispatch()

  const updateSLAAlertsAsync = promisifyAction(dispatch, updateSLAAlerts)

  const handleFieldChange = async (id: number, name: string): Promise<any> => {
    await updateSLAAlertsAsync(props.slaCheckpoint.id, id)
    await props.fetchData()
  }

  return (
    <div className="sla-checkpoint__details">
      <div className="sla-checkpoint__details--header-action-edit paragraph__medium lightblue">
        <Tooltip title="Edit SLA checkpoint" placement="bottom">
          <i className="icon pencil" onClick={props.editItem} />
        </Tooltip>
      </div>
      <div className="sla-checkpoint__details--header-action-delete paragraph__medium lightblue">
        <Tooltip title="Delete SLA checkpoint" placement="bottom">
          <i className="icon trash" onClick={props.deleteItem} />
        </Tooltip>
      </div>
      <Table.StripedTable theme="organizationSettings">
        <Table.StripedTableHeader cells={tableHeaders} />
        <Table.StripedTableBody>
          {props.slaCheckpoint &&
            props.slaCheckpoint.sla_checkpoint_addresses.map(
              (item: ISLAAddress, index: number) => (
                <Table.StripedTableRow index={index}>
                  <Table.StripedTableCell className="left-aligned wide paragraph__medium black">
                    <span className="text-wrapper">
                      {item.address.name ? (
                        <MuiLink
                          variant="body1"
                          component={Link}
                          to={`/addresses/${item.address.id}/edit`}
                        >
                          {item.address.name}
                        </MuiLink>
                      ) : (
                        '-'
                      )}
                    </span>
                  </Table.StripedTableCell>
                  <Table.StripedTableCell className="left-aligned wide paragraph__medium black">
                    <span className="text-wrapper">
                      {item.address.email || '-'}
                    </span>
                  </Table.StripedTableCell>
                  <Table.StripedTableCell className="left-aligned wide paragraph__medium black">
                    {item.automated_alert}
                    <CustomSwitchButton
                      field={'automated_alert'}
                      value={item.automated_alert}
                      onChange={handleFieldChange.bind(null, item.id)}
                    />
                  </Table.StripedTableCell>
                </Table.StripedTableRow>
              )
            )}
        </Table.StripedTableBody>
      </Table.StripedTable>
    </div>
  )
}

export default ServiceLevelAgreementsRowDetails

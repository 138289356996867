import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { permissionTo } from 'src/utils'

interface BookingEventTemplateDocumentProps {
  userOrganizationId: number
  templateVariables: IBookingTemplateVariables
}

const BookingEventTemplateDocument: React.FC<BookingEventTemplateDocumentProps> = ({
  templateVariables,
  userOrganizationId,
}) => {
  const {
    file_url: url,
    updated_value: fileName = '',
    original_filename: originalFileName,
    organization_ids: organizationIds = [],
  } = templateVariables

  const isDownloadable =
    !!url &&
    !!fileName &&
    organizationIds.includes(userOrganizationId) &&
    permissionTo('shipments.bookings.documents.manage')

  if (isDownloadable) {
    return (
      <Link
        href={url}
        variant="body1"
        target="_blank"
        download={originalFileName}
        className="uploaded-document uploaded-document--link"
      >
        {fileName}
      </Link>
    )
  }
  return (
    <Typography
      variant="body1"
      component="span"
      children={fileName}
      className="uploaded-document uploaded-document--text"
    />
  )
}

export default BookingEventTemplateDocument

import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/packageTypes'
import * as actionCreators from '../../actionCreators/packageTypes'
import { simpleGetAndResolve } from '../factories'

const getPackageTypes = simpleGetAndResolve(
  () => 'api/v1/common/package_types',
  actionCreators.packageTypesGetDataSuccess,
  (response) => response.data
)

const getContainerTypes = simpleGetAndResolve(
  () => 'api/v1/container_types',
  actionCreators.containerTypesGetDataSuccess,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.PACKAGE_TYPES_GET_DATA, getPackageTypes)
  yield takeEvery(actions.CONTAINER_TYPES_GET_DATA, getContainerTypes)
}

import Checkbox from '@mui/material/Checkbox'
import { MenuItemProps } from '../Menu.props'
import { MenuItemText } from './MenuItemText'
import { StyledMenuItem } from './MenuItem.utils'

export const MenuItemWithCheckbox: React.FC<MenuItemProps> = (props) => {
  const {
    selected,
    text,
    checkboxProps,
    menuItemTextProps,
    ...otherProps
  } = props
  return (
    <StyledMenuItem
      className="with-checkbox"
      selected={selected}
      {...otherProps}
    >
      <Checkbox sx={{ p: 0, mr: 1 }} {...checkboxProps} checked={selected} />
      <MenuItemText text={text} {...menuItemTextProps} />
    </StyledMenuItem>
  )
}

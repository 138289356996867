import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { DateTime } from 'luxon'
import { FunctionComponent, useState } from 'react'
import { useUpdateShipmentTask } from 'src/services/Api/shipments'
import DatePicker from 'src/stories/DatePicker'
import { permissionTo } from 'src/utils'

interface Props {
  row: IMilestoneTask
  onChange?: () => void
}

const useStyles = makeStyles(() => ({
  inputBase: {
    cursor: 'pointer !important',
  },
  typography: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const DueDateComponent: FunctionComponent<Props> = ({ row, onChange }) => {
  const [open, setOpen] = useState(false)
  const [date, setDate] = useState<DateTime | null>(
    row.due_date ? DateTime.fromISO(row.due_date) : null
  )

  const classes = useStyles()
  const { mutate: updateTask } = useUpdateShipmentTask({ onSuccess: onChange })

  const handleChange = (date: DateTime | null) => {
    setDate(date)
    const dueDate = date?.toISO()
    if (!dueDate) return
    const shipmentId = row.shipment.id
    const taskId = row.id
    updateTask({ shipment_id: shipmentId, task_id: taskId, due_date: dueDate })
  }

  const createTypographyClass = () => {
    const canEdit = permissionTo('tasks.update')
    const today = DateTime.now().startOf('day')
    const dueDate = date?.startOf('day')
    return dueDate && dueDate <= today
      ? { color: 'error.main', cursor: canEdit ? 'pointer' : 'auto' }
      : { cursor: canEdit ? 'pointer' : 'auto' }
  }

  const onClick = () => {
    if (!permissionTo('tasks.update')) return
    setOpen(true)
  }

  return (
    <>
      {!open && (
        <Typography
          className={classes.typography}
          sx={createTypographyClass()}
          onClick={onClick}
          data-testid="shipment-task-due-date"
        >
          {date ? date.toFormat('d MMM') : '-'}
        </Typography>
      )}
      {open && (
        <DatePicker
          open
          value={date?.toISO() || null}
          onChange={handleChange}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  )
}

export default DueDateComponent

import { FunctionComponent, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { updateUrl } from 'src/components/Filters/utils'
import { cloneDeep } from 'lodash'
import Input from 'src/components/Common/Input'
import { useGetShipmentsMeta } from 'src/services/Api/shipments'
import { shipmentsUpdateFilters } from 'src/stores/actionCreators'
import Tabs from 'src/stories/Tabs'
import { useTranslation } from 'react-i18next'
import { formatFiltersToParams } from '../helpers'
import { sortingOptions, tabList } from './constants'

const ShipmentsTabs: FunctionComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const translatableSortingOptions = useMemo(() => {
    return sortingOptions.map((option) => ({
      ...option,
      name: t(`shipments.sorting_options.${option.value}`, option.value),
    }))
  }, [sortingOptions])

  const { filters } = useSelector(
    (state: IGlobalState) => ({ filters: state.shipments.filters }),
    shallowEqual
  )

  const { data: shipmentsMeta } = useGetShipmentsMeta(
    formatFiltersToParams({ ...filters }),
    { enabled: !filters.initial }
  )
  const countsPerScope = shipmentsMeta?.scope_counts

  const setScope = (scope: string) => {
    dispatch(shipmentsUpdateFilters({ ...filters, page: 1, scope }))
    updateUrl('shipments', { ...filters, page: 1, scope })
  }
  const setSorting = (sortBy: string) => {
    dispatch(shipmentsUpdateFilters({ ...filters, sortBy }))
    updateUrl('shipments', { ...filters, sortBy })
  }

  const tabs = useMemo(() => {
    if (!countsPerScope) return []

    return cloneDeep(tabList).map((tab: ITabProps) => {
      tab.label = `${t(`shipments.tabs.${tab.value}`, tab.value)} (${
        countsPerScope[tab.value]
      })`
      return tab
    })
  }, [countsPerScope])

  return (
    <Tabs
      value={filters.scope}
      onChange={setScope}
      tabs={tabs}
      variant="scrollable"
    >
      <Input.DropDown
        theme="shipmentsPage"
        value={filters.sortBy}
        onChange={setSorting}
        options={translatableSortingOptions}
        className="shipments-sorting"
      />
    </Tabs>
  )
}

export default ShipmentsTabs

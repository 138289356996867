import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { DemurrageAndDetentionPrice } from 'src/@types/endpoints/demurrageAndDetention'
import ContainerDemurrageAndDetentionItemPrice from './ContainerDemurrageAndDetentionItemPrice'

interface ContainerDemurrageAndDetentionItemDetailsProps {
  usedDays: number | null
  totalDays: number | null
  overdueDays: number | null
  overduePrices: DemurrageAndDetentionPrice
}

const ContainerDemurrageAndDetentionItemDetails: React.FC<ContainerDemurrageAndDetentionItemDetailsProps> = ({
  usedDays,
  totalDays,
  overdueDays,
  overduePrices,
}) => {
  const { t } = useTranslation()
  const hasOverdueDays = !!overdueDays && overdueDays > 0
  const textColor = hasOverdueDays ? 'error.900' : 'primary.main'

  return (
    <Stack
      spacing={1}
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
    >
      <Box
        whiteSpace="pre"
        data-testid="container-demurrage-and-detention-item-days"
      >
        <Typography component="span" color={textColor} children={usedDays} />
        <Typography component="span" children={` / ${totalDays}`} />
      </Box>
      {!!usedDays && !!totalDays && (
        <>
          <Typography
            component="div"
            color={textColor}
            data-testid="container-demurrage-and-detention-item-overdue"
            children={
              hasOverdueDays
                ? `+${overdueDays}`
                : t(
                    'shipment_containers.demurrage_and_detention.details.within_free_period',
                    'Within free period'
                  )
            }
          />
        </>
      )}
      {hasOverdueDays && (
        <Typography
          component="div"
          children={
            <ContainerDemurrageAndDetentionItemPrice
              price={overduePrices}
              linkText={t(
                'shipment_containers.demurrage_and_detention.details.cost',
                'Cost '
              )}
              addSuffix={false}
            />
          }
        />
      )}
    </Stack>
  )
}

export default ContainerDemurrageAndDetentionItemDetails

import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/shipmentPrice'
import * as actionCreators from '../../actionCreators/shipmentPrice'
import { simpleGetAndResolve } from '../factories'

const getTransactionItems = simpleGetAndResolve(
  (action) => `/api/v1/shipments/${action.id}/transaction_items`,
  actionCreators.shipmentTransactionItemsGetDataSuccess,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeEvery(
    actions.SHIPMENT_TRANSACTION_ITEMS_GET_DATA,
    getTransactionItems
  )
}

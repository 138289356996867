import { useCallback, useState } from 'react'
import { TFunction } from 'i18next'
import { set, debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import { Avatar } from 'src/stories/Avatar'
import { KeyboardArrowDown } from '@mui/icons-material'
import { useGetOrganizationsAsync } from 'src/services/Api/organizations'
import {
  useGetShipmentRolesSuggestion,
  useGetOrganizationShipmentRoles,
} from 'src/services/Api/common'
import { CollaboratorOptionProps } from '../../../Form.props'
import { useFormContext } from '../../../FormContext'
import { getCollaboratorOptions } from '../../../Form.utils'

const getNoOptionsText = (
  t: TFunction<'translation', undefined>,
  inputValue: string,
  isFetching: boolean,
  isFetched: boolean
) => {
  if (isFetching) {
    return t('common.loading', 'Loading...')
  }
  return isFetched && inputValue.length > 0
    ? t('common.no_options', 'No options')
    : t('common.start_typing', 'Start typing')
}

const CollaboratorsSelect = ({ forwarderRoles, bookingPartyRoles }) => {
  const {
    fetchAsync: getShipmentRolesSuggestion,
  } = useGetShipmentRolesSuggestion()

  const { t } = useTranslation()

  const { onChange, formState } = useFormContext()
  const { collaborators: collaboratorsFormState } = formState

  const [value, setValue] = useState<CollaboratorOptionProps | null>(null)
  const [inputValue, setInputValue] = useState<string>('')

  const [collaboratorOptions, setCollaboratorOptions] = useState<
    CollaboratorOptionProps[]
  >([])

  const { loadingPort, dischargePort } = formState

  const { data: organizationShipmentRoles } = useGetOrganizationShipmentRoles({
    refetchOnMount: false,
  })

  const {
    fetchAsync: getOrganizations,
    isFetched,
    isFetching,
  } = useGetOrganizationsAsync()

  const noOptionsText = getNoOptionsText(t, inputValue, isFetching, isFetched)

  const hasPortErrors = !loadingPort || !dischargePort

  const updateCollaboratorShipmentRoleOptions = (res, newValue) => {
    const roles = res?.roles ?? []
    const suggestedShipmentRoles = [
      ...newValue.shipmentRoleOptions.filter((role) => roles.includes(role.id)),
      ...newValue.shipmentRoleOptions.filter(
        (role) => !forwarderRoles.includes(role.id)
      ),
    ]
    onChange(
      set(formState, 'collaborators', [
        ...formState.collaborators,
        { ...newValue, shipmentRoleOptions: suggestedShipmentRoles },
      ])
    )
  }

  const onAutocompleteChange = (_event, newValue) => {
    const newValueHasForwarderRole = newValue.shipmentRoleOptions
      .map((role) => role.id)
      .some((role) => forwarderRoles.includes(role))

    if (newValueHasForwarderRole && !hasPortErrors) {
      getShipmentRolesSuggestion({
        organization_id: newValue.id,
        loading_port_id: loadingPort.value,
        discharge_port_id: dischargePort.value,
      }).then((res) => {
        updateCollaboratorShipmentRoleOptions(res, newValue)
      })
    } else {
      onChange(
        set(formState, 'collaborators', [...formState.collaborators, newValue])
      )
    }

    setValue(null)
  }

  const onAutocompleteClose = () => {
    setValue(null)
    setInputValue('')
    setCollaboratorOptions([])
  }

  const onAutocompleteInputChange = async (search) => {
    setInputValue(search)
    if (search.length === 0) {
      return
    }
    const fetchedOrganizations = await getOrganizations({ search })

    if (fetchedOrganizations) {
      const filteredOptions = getCollaboratorOptions({
        organizations: fetchedOrganizations,
        collaborators: collaboratorsFormState,
        bookingPartyRoles,
        organizationShipmentRoles,
      })
      setCollaboratorOptions(filteredOptions)
    }
  }

  const handleInputChangeWithDebounce = useCallback(
    debounce(onAutocompleteInputChange, 300),
    [collaboratorsFormState]
  )

  const handleOnInputChange = (
    _event: React.SyntheticEvent<Element, Event>,
    search: string
  ) => {
    handleInputChangeWithDebounce(search)
  }

  return (
    <Box mb={2}>
      <InputLabel
        children={t('templates.collaborators.collaborators', 'Collaborators')}
      />
      <Autocomplete
        fullWidth
        value={value as CollaboratorOptionProps}
        blurOnSelect={true}
        disabled={hasPortErrors}
        onClose={onAutocompleteClose}
        id="collaborators-select"
        disableClearable={true}
        filterOptions={(x) => x}
        noOptionsText={noOptionsText}
        options={collaboratorOptions}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) =>
          value && option.id === value.id
        }
        popupIcon={<KeyboardArrowDown />}
        onChange={onAutocompleteChange}
        data-testid="shipment-template-collaborators-select"
        onInputChange={handleOnInputChange}
        renderOption={(props, option) => (
          <ListItem {...props}>
            <ListItemAvatar>
              <Avatar
                alt={option.name}
                src="no-logo"
                className={option.roleCode}
              />
            </ListItemAvatar>
            <ListItemText primary={option.name} secondary={option.roleName} />
          </ListItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            error={hasPortErrors}
            placeholder={t('common.select', 'Select')}
            inputProps={{
              ...params.inputProps,
              'data-testid': 'collaborators-select-input',
            }}
            helperText={
              hasPortErrors ? 'Please select the port pair first' : ''
            }
          />
        )}
      />
    </Box>
  )
}

export default CollaboratorsSelect

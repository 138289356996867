import { FunctionComponent, useState, useCallback, useEffect } from 'react'
import Collapse from '@mui/material/Collapse'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import './styles.scss'

export interface ContentDropDownProps {
  /**
   * Custom class name
   */
  className?: string
  /**
   * Expanded Content Drop Down Header compoment
   */
  header: React.ReactNode
  /**
   * Collapsed Content Drop Down Header compoment
   */
  headerCollapsed: React.ReactNode
  /**
   * Drop Down body content
   */
  body: React.ReactNode
  /**
   * Force to open dropdown after mount
   */
  forcedOpen?: boolean
  /**
   * Make Drop Down always expanded
   */
  disableCollapse: boolean
  /**
   * ID of nested item
   */
  itemId?: number | null
  /**
   * ID of Drop Down element
   */
  id?: number | null
  /**
   * Select nested item event
   */
  setItemIdSelected?: (id: number | null) => void
  /**
   * Click on Drop Down line event
   */
  onToggle?: (id: number, isOpen: boolean) => void
  // Presence of this prop marks that this dropdown is actually a part of a table.
  // To display the width of the dropdown body correctly, we need to set property colspan to the
  // element so we need to know number of the table columns.
  tableCellsCount?: number
  tableCellClassName?: string
  testId?: string
}

const ContentDropDown: FunctionComponent<ContentDropDownProps> = (props) => {
  const [opened, setOpened] = useState<boolean>(false)
  const [firstOpen, setFirstOpen] = useState<boolean>(true)

  useEffect(() => {
    setOpened(!!props.forcedOpen)
  }, [props.forcedOpen])

  const toggle = useCallback(
    (event): void => {
      const elementClassList = event.target.classList
      if (
        elementClassList.contains('blocked-open') ||
        elementClassList.contains('Select-placeholder') ||
        elementClassList.contains('MuiBackdrop-root')
      ) {
        return
      }
      if (props.itemId && props.setItemIdSelected) {
        props.setItemIdSelected(!opened ? props.itemId : null)
      }
      if (props.onToggle && props.id) {
        props.onToggle(props.id, !opened)
      }
      if (!props.disableCollapse) {
        setOpened(!opened)
        setFirstOpen(false)
      }
    },
    [opened]
  )

  const defaultBlock = () => (
    <div
      data-testid={props.testId}
      className={`content-dropdown--block ${props.className} ${
        opened ? 'opened' : ''
      } ${props.disableCollapse ? 'disable-collapse' : ''}`}
    >
      <div
        className="content-dropdown--header"
        onClick={toggle}
        data-testid="shipment-container-row"
      >
        {opened ? props.header : props.headerCollapsed}
      </div>
      <Collapse in={opened} timeout={firstOpen ? 0 : 'auto'} unmountOnExit>
        {props.body}
      </Collapse>
    </div>
  )

  if (props.tableCellsCount) {
    return (
      <>
        <TableRow className="bordered-table-row-element" onClick={toggle}>
          {opened ? props.header : props.headerCollapsed}
        </TableRow>
        {opened && (
          <TableRow>
            <TableCell
              className={`no-padding-and-border ${!opened ? 'collapsed' : ''}`}
              colSpan={props.tableCellsCount}
            >
              <Collapse
                in={opened}
                timeout={firstOpen ? 0 : 'auto'}
                unmountOnExit
              >
                {props.body}
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </>
    )
  }

  return defaultBlock()
}

export default ContentDropDown

import { ReactNode, FunctionComponent } from 'react'
import './styles.scss'

interface IProps {
  children: ReactNode
  className?: string
}

const Column: FunctionComponent<IProps> = (props) => (
  <div className={`form-column ${props.className}`}>{props.children}</div>
)

Column.defaultProps = {
  className: '',
}

export default Column

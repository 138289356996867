import { AnyAction } from 'redux'
import * as actions from '../actions/ports'

export const getPorts = (payload): AnyAction => ({
  type: actions.PORTS_GET_DATA,
  payload,
})

export const getPortsSuccess = (payload: IPort[]): AnyAction => ({
  type: actions.PORTS_GET_DATA_SUCCESS,
  payload,
})

export const preloadPols = (payload): AnyAction => ({
  type: actions.PORTS_PRELOAD_POLS,
  payload,
})

export const preloadPolsSuccess = (payload: IPort[]): AnyAction => ({
  type: actions.PORTS_PRELOAD_POLS_SUCCESS,
  payload,
})

export const preloadPods = (payload): AnyAction => ({
  type: actions.PORTS_PRELOAD_PODS,
  payload,
})

export const preloadPodsSuccess = (payload: IPort[]): AnyAction => ({
  type: actions.PORTS_PRELOAD_PODS_SUCCESS,
  payload,
})

export const clearPorts = (storageType?: PortStorageType): AnyAction => ({
  type: actions.PORTS_CLEAR_DATA,
  storageType,
})

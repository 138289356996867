import Input from '../../FormElements/Input'

const TemplateFormCargoDimensionsInput = ({ field, disabled }) => {
  return (
    <Input
      fieldPath={`cargo.${field}`}
      numberInput
      placeholder="0"
      disabled={disabled}
      inputProps={{
        'data-testid': `shipment-template-reefer-cargo-dimensions-${field}`,
      }}
      sx={{
        '& .MuiInputBase-root': {
          minHeight: 38,
        },
        '& fieldset': {
          border: 'none',
        },
        '& input': {
          padding: 1,
          textAlign: 'center',
        },
      }}
    />
  )
}

export default TemplateFormCargoDimensionsInput

import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/personalDetails'
import * as actionCreators from '../../actionCreators/personalDetails'
import {
  simpleGetAndResolve,
  simplePutAndResolve,
  simplePostAndResolve,
  simpleDeleteAndResolve,
} from '../factories'

const getPersonalDetailsURL = () => 'api/v1/contacts/'
const getOnePersonalDetailURL = (action) => `api/v1/contacts/${action.id}`

const getPersonalDetails = simpleGetAndResolve(
  getPersonalDetailsURL,
  actionCreators.personalDetailsGetDataSuccess,
  (response) => response.data
)

const getOnePersonalDetail = simpleGetAndResolve(
  getOnePersonalDetailURL,
  actionCreators.personalDetailsGetOnePersonalDetailSuccess,
  (response) => response.data
)

const putPersonalDetail = simplePutAndResolve(
  getOnePersonalDetailURL,
  actionCreators.personalDetailsDeletePersonalDetailSuccess
)

const addPersonalDetail = simplePostAndResolve(
  getPersonalDetailsURL,
  actionCreators.personalDetailsAddPersonalDetailSuccess,
  (response) => response.data
)

const deletePersonalDetail = simpleDeleteAndResolve(
  getOnePersonalDetailURL,
  actionCreators.personalDetailsDeletePersonalDetailSuccess
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.PERSONAL_DETAILS_GET_DATA, getPersonalDetails)
  yield takeEvery(
    actions.PERSONAL_DETAILS_GET_ONE_PERSONAL_DETAIL,
    getOnePersonalDetail
  )
  yield takeEvery(
    actions.PERSONAL_DETAILS_PUT_PERSONAL_DETAIL,
    putPersonalDetail
  )
  yield takeEvery(
    actions.PERSONAL_DETAILS_ADD_PERSONAL_DETAIL,
    addPersonalDetail
  )
  yield takeEvery(
    actions.PERSONAL_DETAILS_DELETE_PERSONAL_DETAIL,
    deletePersonalDetail
  )
}

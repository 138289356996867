import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import { concat } from 'lodash' // using concat since state is immutable
import * as actions from '../actions/webSocketActions'

declare global {
  interface ISocketMessage {
    message_type: string
    shipment_id?: string
    chat_id?: string
    message: any
  }

  interface ISocketCommand {
    name: string
    params: any
  }

  interface ISocketMessagesState {
    userChannelMessages: ISocketMessage[]
    userChannelCommands: ISocketCommand[]
    chatChannelCommands: ISocketCommand[]
  }
}
export const initialSocketsState = {
  userChannelMessages: [],
  userChannelCommands: [],
  chatChannelCommands: [],
}

const receiveUserChannelMessage: Reducer<ISocketMessagesState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  userChannelMessages: concat(state.userChannelMessages, [action.payload]),
})

const clearUserChannelMessages: Reducer<ISocketMessagesState, AnyAction> = (
  state
) => ({
  ...state,
  userChannelMessages: [],
})

const triggerUserChannelCommand: Reducer<ISocketMessagesState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  userChannelCommands: concat(state.userChannelCommands, [action.payload]),
})

const triggerChatChannelCommand: Reducer<ISocketMessagesState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  chatChannelCommands: concat(state.chatChannelCommands, [action.payload]),
})

export default createReducer(initialSocketsState, {
  [actions.USER_CHANNEL_RECEIVED]: receiveUserChannelMessage,
  [actions.USER_CHANNEL_CLEAR]: clearUserChannelMessages,
  [actions.USER_CHANNEL_PERFORM]: triggerUserChannelCommand,
  [actions.CHAT_CHANNEL_PERFORM]: triggerChatChannelCommand,
})

import {
  FunctionComponent,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react'
import { Button, Box } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Input from '../Common/Input/MuiInput'

import { userSubmitUserData } from '../../stores/actionCreators'
import {
  validationErrorsFor,
  cannotBeBlank,
} from '../../pages/AddressBookEdit/utils'
import { Dialog, DialogContent } from '../../stories'
import './styles.scss'

interface IProps {
  open: boolean
  user: IUserState
  handleClose: () => void
}

interface IUserFields {
  first_name: string
  last_name: string
  phone: string
  email: string
}

const validations = {
  first_name: [cannotBeBlank],
  last_name: [cannotBeBlank],
  phone: [cannotBeBlank],
}

const setUserFields = (user: IUserState): IUserFields => {
  return {
    first_name: user.firstName || '',
    last_name: user.lastName || '',
    email: user.email,
    phone: user.phone || '',
  }
}

const AccountInfoModal: FunctionComponent<IProps> = ({
  user,
  handleClose,
  open,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [formData, setFormData] = useState<IUserFields>(setUserFields(user))
  const [validationErrors, setValidationErrors] = useState({})

  useEffect(() => {
    setFormData(setUserFields(user))
  }, [open])

  const isFormValid: boolean = useMemo(
    () => !Object.keys(validationErrors).length,
    [validationErrors]
  )

  const onChange = (value: string | number, field: string) => {
    setFormData({ ...formData, [field]: value })
  }

  const onSave = useCallback(async () => {
    await dispatch(userSubmitUserData(formData))

    handleClose()
  }, [formData])

  useEffect(() => {
    setValidationErrors(validationErrorsFor(formData, validations))
  }, [formData])

  const renderInput = useCallback(
    (
      label: string,
      field: string,
      disabled: boolean = false
    ): React.ReactNode => {
      return (
        <Input
          name={field}
          required
          label={label}
          disabled={disabled}
          value={formData[field] || ''}
          onChange={onChange}
          error={!!validationErrors[field]}
          helperText={validationErrors[field]}
        />
      )
    },
    [formData, validationErrors]
  )

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title={t(
        'account.side_bar.edit_account_modal.title',
        'Edit Account Information'
      )}
      actions={
        <>
          <Button variant="text" onClick={handleClose}>
            {t('account.side_bar.edit_account_modal.cancel_button', 'Cancel')}
          </Button>
          <Button variant="contained" disabled={!isFormValid} onClick={onSave}>
            {t('account.side_bar.edit_account_modal.save_button', 'Save')}
          </Button>
        </>
      }
    >
      <DialogContent>
        <Box minWidth={400}>
          <div className="organization-address-form">
            <div className="organization-address-form__side">
              <div className="mb-16">
                {renderInput(
                  t(
                    'account.side_bar.edit_account_modal.first_name_label',
                    'First Name'
                  ),
                  'first_name'
                )}
              </div>
              <div>
                {renderInput(
                  t('account.side_bar.edit_account_modal.email_label', 'Email'),
                  'email',
                  true
                )}
              </div>
            </div>
            <div className="organization-address-form__side">
              <div className="mb-16">
                {renderInput(
                  t(
                    'account.side_bar.edit_account_modal.last_name_label',
                    'Last Name'
                  ),
                  'last_name'
                )}
              </div>
              <div>
                {renderInput(
                  t(
                    'account.side_bar.edit_account_modal.phone_number_label',
                    'Phone number'
                  ),
                  'phone'
                )}
              </div>
            </div>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
export default AccountInfoModal

import { FunctionComponent } from 'react'
import Box from '@mui/material/Box'

import './styles.scss'

interface IProps {
  comment: IComment | null
}

const CommentField: FunctionComponent<IProps> = (props) => {
  return (
    <Box className="comment-field">
      {props.comment ? props.comment.body : ''}
    </Box>
  )
}

export default CommentField

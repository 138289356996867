import { FunctionComponent, Fragment } from 'react'
import { Dispatch } from 'redux'
import { filter } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from '@mui/material'
import { permissionTo } from '../../utils'
import { shipmentOverviewUnpinMessage } from '../../stores/actionCreators'
import promisifyAction from '../../utils/promisifyAction'

import './styles.scss'

interface IProps {
  messages?: IComment[]
  chatId: number
}

const PinnedMessages: FunctionComponent<IProps> = (props) => {
  const { chatId } = props
  const dispatch: Dispatch = useDispatch()
  const { messages } = useSelector((state: IGlobalState) => ({
    messages: state.chat.comments,
  }))
  const unpinMessage = promisifyAction(dispatch, shipmentOverviewUnpinMessage)

  if (!messages || !messages.length) {
    return null
  }

  const scrollToMessage = (messageId: number) => {
    const commentsContainer: HTMLElement | null = document.getElementById(
      'messages-container'
    )
    const messageElement: HTMLElement | null = document.getElementById(
      `chat-comment-${messageId}`
    )

    if (!commentsContainer || !messageElement) {
      return
    }

    const top: number = messageElement.offsetTop

    if (commentsContainer.scrollTo) {
      commentsContainer.scrollTo({ top, behavior: 'smooth' })
    } else {
      commentsContainer.scrollTop = top
    }
  }

  const pinnedMessages: IComment[] = filter(messages, 'pinned')

  return (
    <Fragment>
      <div className="pinned-messages">
        <div className="">
          {pinnedMessages.map((message: IComment) => (
            <div
              key={message.id}
              className="pinned-messages__message-container"
            >
              {permissionTo('chats.comments.pin') && (
                <div className="pinned-messages__unpin">
                  <i
                    className="icon pin"
                    onClick={unpinMessage.bind(undefined, chatId, message.id)}
                  />
                </div>
              )}
              <div>
                {message.content != null && message.content !== '' && (
                  <Fragment>
                    <div className="pinned-messages__message">
                      <span
                        className="pinned-messages__message-author"
                        style={{ color: message.author.organization.color }}
                      >
                        {message.author.full_name} |{' '}
                        <b> {message.author.organization.name} </b>
                      </span>{' '}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `${
                            message.approval
                              ? message.approval.approval_content
                              : message.content
                          }`,
                        }}
                      ></span>
                    </div>
                    <span className="pinned-messages__message-separator">
                      -
                    </span>
                  </Fragment>
                )}
                <span
                  className="pinned-messages__show-message-link"
                  onClick={scrollToMessage.bind(undefined, message.id)}
                >
                  show message
                </span>
                {message.comment_attachments.map((commentAttachment, index) => (
                  <Link
                    key={index}
                    variant="body1"
                    href={commentAttachment.file_url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <i className="icon paperclip" />
                    {commentAttachment.original_filename}
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  )
}
export default PinnedMessages

import { FunctionComponent, useState } from 'react'
import {
  Box,
  Link,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

interface IProps {
  onSave: () => void
  preferredForwarder: any
  seller: any
  loading?: boolean
}

type DocTypeNames =
  | 'Forwarding Conditions'
  | 'Terms and conditions'
  | 'Visibility terms and conditions'
  | 'Warehousing Condition'

const noDocTypeText = {
  'Forwarding Conditions': 'Dutch Forwarding Conditions by FENEX',
  'Visibility terms and conditions': 'General Terms and Conditions',
  'Terms and conditions': 'General Terms & Conditions',
  'Warehousing Condition': '',
}

const ShipmentTemplateAcceptTerm: FunctionComponent<IProps> = ({
  onSave,
  preferredForwarder,
  seller,
  loading = false,
}) => {
  const [termsChecked, setTermsChecked] = useState<boolean>(false)

  const { t } = useTranslation()

  const renderPublicDocument = (type: DocTypeNames): React.ReactNode => {
    const doc = seller?.public_documents.find(
      (item) => item.document_type.name === type
    )

    if (!doc) {
      return <>{noDocTypeText[type]}</>
    }

    if (type === 'Warehousing Condition') {
      return (
        <>
          <span> The </span>
          <Link href={doc.file_url} rel="noopener noreferrer" target="_blank">
            <Typography
              display="inline"
              variant="body1"
              className="hyperlink bold"
            >
              Dutch Warehousing Conditions
            </Typography>
          </Link>
          <span>
            , latest version, are applicable to all orders which exclusively
            concern the warehousing of property.
          </span>
        </>
      )
    }

    return (
      <Link href={doc.file_url} rel="noopener noreferrer" target="_blank">
        <Typography display="inline" variant="body1" className="hyperlink bold">
          {doc.name}
        </Typography>
      </Link>
    )
  }

  return (
    <Box data-testid="rebook-template" sx={{ position: 'relative' }}>
      <Box data-testid="rebook-text">
        I hereby acknowledge and accept that{' '}
        <>{preferredForwarder?.name || ''}</> is acting as a digital freight
        forwarder and that for all freight forwarding services provided by{' '}
        <>{preferredForwarder?.name || ''}</>,{' '}
        <>{renderPublicDocument('Forwarding Conditions')}</>, latest version,
        and the <>{renderPublicDocument('Terms and conditions')}</> shall
        exclusively apply.<>{renderPublicDocument('Warehousing Condition')}</>{' '}
        All subcontractors and carriers appointed by Shypple B.V. have limited
        liability. Shypple B.V. recommends that goods are insured.
      </Box>
      <Box sx={{ position: 'absolute', right: '0', bottom: -61 }}>
        <FormControlLabel
          className="outline"
          control={
            <Checkbox
              checked={termsChecked}
              onChange={setTermsChecked.bind(null, (prev) => !prev)}
              name="accept-terms"
              data-testid="accept-rebook"
              color="primary"
            />
          }
          label={
            <Typography variant="body1Strong" className="pr-20">
              {t('templates.booking_request.accept_terms', 'Accept terms')}
            </Typography>
          }
        />
        <Button
          size="large"
          color="primary"
          variant="contained"
          className="ml-16"
          onClick={onSave}
          disabled={loading || !termsChecked}
          data-testid="rebook-action"
        >
          {loading
            ? t('templates.booking_request.button_next_loading', 'Loading...')
            : t('templates.booking_request.button_next', 'Next')}
        </Button>
      </Box>
    </Box>
  )
}

export default ShipmentTemplateAcceptTerm

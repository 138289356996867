import OverviewTableText from './OverviewTableText'

interface OverviewTableShipmentBookingPartiesProps {
  shipmentOrganizations: {
    id: number
    organization_id: number
    organization_name: string
    roles: {
      id: number
      role: string
      role_code: string
    }[]
  }[]
  role: 'shipper' | 'consignee'
}

const OverviewTableShipmentBookingParties = ({
  shipmentOrganizations,
  role,
}: OverviewTableShipmentBookingPartiesProps) => {
  const bookingParty =
    shipmentOrganizations.filter((organization) => {
      return organization.roles.map((role) => role.role_code).includes(role)
    }) ?? []

  const text = bookingParty
    .map((organization) => organization.organization_name)
    .join(', ')

  return <OverviewTableText text={text} />
}

export default OverviewTableShipmentBookingParties

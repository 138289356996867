import { FunctionComponent, useCallback, useState, useEffect } from 'react'
import { useDispatch, useStore, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { Link } from '@mui/material'
import { useTranslation } from 'react-i18next'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import Input from 'src/components/Common/Input'
import Select from 'src/components/Common/Select'

import HsCodeOptionComponent from '../HsCodeOptionComponent'
import { searchHsCodes, cacheHsCode } from '../../../stores/actionCreators'
import { promisifyAction } from '../../../utils'
import { presentGoodsCode } from '../../../utils/helpers'

import './styles.scss'

interface IProps {
  newHsCode: IHsCodeToSubmit
  onChange: (index: number, updatedHsCode: IHsCodeToSubmit) => void
  index: number
  onRemove: (index: number) => void
}

const NewHsCodeLine: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const store = useStore()

  const searchHsCodesAsync = promisifyAction(dispatch, searchHsCodes)
  const cacheHsCodeAsync = promisifyAction(dispatch, cacheHsCode)

  const { cachedHsCodes } = useSelector((state: IGlobalState) => ({
    cachedHsCodes: state.hsCodes.cachedHsCodes,
  }))

  const [newHsCode, setNewHsCode] = useState<IHsCodeToSubmit>(props.newHsCode)

  const initialHsCode =
    cachedHsCodes.filter((x) => x.id === props.newHsCode.hs_code_id)[0] || null
  const [hsCode, setHsCode] = useState<IHsCode | null>(initialHsCode)

  const onNotesEdited = useCallback(
    (fieldName, value) => {
      setNewHsCode({ ...newHsCode, notes: value })
    },
    [newHsCode]
  )

  const updateHsCode = useCallback(
    (selectedCode: IHsCode) => {
      setHsCode(selectedCode)
      let selectedCodeId: number = 0
      if (selectedCode) {
        cacheHsCodeAsync(selectedCode)
        selectedCodeId = selectedCode.id
      }
      setNewHsCode({ ...newHsCode, hs_code_id: selectedCodeId })
    },
    [newHsCode]
  )

  useEffect(() => {
    props.onChange(props.index, newHsCode)
  }, [newHsCode, props.index])

  const fetchHsCodes = useCallback(
    async (searchInput: string) => {
      await searchHsCodesAsync({ search: searchInput.toLowerCase() })
      const codes: IHsCode[] = store.getState().hsCodes.hsCodesForSearch
      return { options: codes }
    },
    [searchHsCodesAsync, store]
  )

  // MUST be passed if we're going along with a custom OptionComponent since HsCode doesn't have a prop 'name'
  const valueRenderer = (hsCode: IHsCode) => {
    return `${presentGoodsCode(hsCode.goods_code)} - ${hsCode.description}`
  }

  const onRemove = useCallback(() => {
    props.onRemove(props.index)
  }, [props.index])

  const blankFilterOptions = (options, filterString, values) => options

  const arrowRenderer = () => {
    return <i className="icon search" />
  }

  const stripDigits = (input: string) => {
    return input.replace(/[^0-9]/g, '')
  }

  return (
    <div className="new-hs-code-line">
      <div className="new-hs-code-line__title">
        {t(
          'shipment_containers.hs_code_tabs.select_hs_code',
          'Please select HS code'
        )}

        {!!props.index && (
          <span className="new-hs-code-line__title-remove" onClick={onRemove}>
            <i className="icon trash" />
          </span>
        )}
        {!props.index && (
          <Link
            variant="body1"
            target="_blank"
            rel="noopener noreferrer"
            href="https://ec.europa.eu/taxation_customs/dds2/taric/taric_consultation.jsp"
          >
            <InfoRoundedIcon />
            {t(
              'shipment_containers.hs_code_tabs.lookup_hs_code',
              'Lookup HS codes'
            )}
          </Link>
        )}
      </div>
      <div className="new-hs-code-line__input">
        <Select.SingleAsync
          onChange={updateHsCode}
          value={hsCode}
          theme="common"
          placeholder={t(
            'shipment_containers.hs_code_tabs.search_for_hs_code',
            'Search for HS codes'
          )}
          loadOptions={fetchHsCodes}
          onInputChange={stripDigits}
          filterOptions={blankFilterOptions}
          clearable={true}
          arrowRenderer={arrowRenderer}
          optionComponent={HsCodeOptionComponent}
          valueRenderer={valueRenderer}
        />
      </div>
      <div className="new-hs-code-line__notes">
        <Input.TextBordered
          placeholder={t(
            'shipment_containers.hs_code_tabs.add_notes',
            'Add notes'
          )}
          className="full"
          name="note"
          value={newHsCode.notes}
          onChange={onNotesEdited}
        />
      </div>
    </div>
  )
}

export default NewHsCodeLine

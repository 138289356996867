import { SelectChangeEvent } from '@mui/material'
import { times } from 'lodash'
import { useTranslation } from 'react-i18next'
import { FunctionComponent, useState } from 'react'
import { IShipmentsMapResponse } from 'src/@types/endpoints/maps'
import { useGetShipmentsTrack } from 'src/services/Api/maps'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'

type MapPaginationOption = {
  id: number
  label: string
}

type PropTypes = {
  selectedPage: number
  setSelectedPage: Function
}

const MapPagination: FunctionComponent<PropTypes> = ({
  selectedPage,
  setSelectedPage,
}) => {
  const [paginationOptions, setPaginationOptions] = useState<
    MapPaginationOption[]
  >([])

  const createPaginationOptions = (data: IShipmentsMapResponse) => {
    const { per_page: perPage = 0, total = 0 } = data?.data || {}
    const totalPages = Math.ceil(total / perPage)

    const options: MapPaginationOption[] = times(totalPages, (i) => {
      const isTheLastPage = i === totalPages - 1
      const startingIndex = i * perPage
      const endingIndex = isTheLastPage ? total : (i + 1) * perPage - 1

      const option = {
        id: i + 1,
        label: `${startingIndex} - ${endingIndex}`,
      }

      return option
    })

    setPaginationOptions(options)
  }

  useGetShipmentsTrack(
    { page: selectedPage - 1 },
    { onSuccess: createPaginationOptions }
  )

  const onSelectPage = (event: SelectChangeEvent<unknown>) => {
    setSelectedPage(event.target.value)
  }

  const { t } = useTranslation()

  if (paginationOptions.length <= 1) {
    return null
  }

  return (
    <div className="pagination">
      <SingleSelect
        value={selectedPage}
        onChange={onSelectPage}
        label={t('shipments_map.display_shipments')}
        options={paginationOptions}
        data-testid="track-and-trace-map-pagination-select"
      />
    </div>
  )
}

export default MapPagination

export const APPROVALS_GET_DATA = 'APPROVALS_GET_DATA'
export const APPROVALS_GET_DATA_SUCCESS = 'APPROVALS_GET_DATA_SUCCESS'
export const MENTIONS_GET_DATA = 'MENTIONS_GET_DATA'
export const MENTIONS_GET_DATA_SUCCESS = 'MENTIONS_GET_DATA_SUCCESS'
export const GET_SAVED_MESSAGES = 'GET_SAVED_MESSAGES'
export const GET_SAVED_MESSAGES_SUCCESS = 'GET_SAVED_MESSAGES_SUCCESS'
export const UNREAD_GET_DATA = 'UNREAD_GET_DATA'
export const UNREAD_GET_DATA_SUCCESS = 'UNREAD_GET_DATA_SUCCESS'
export const COUNTERS_GET_DATA = 'COUNTERS_GET_DATA'
export const COUNTERS_GET_DATA_SUCCESS = 'COUNTERS_GET_DATA_SUCCESS'
export const SET_MESSAGES_COUNTERS = 'SET_MESSAGES_COUNTERS'

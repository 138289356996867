import Box from '@mui/material/Box'
import CopyWrapper from 'src/stories/CopyWrapper'
import IconField from '../components/IconField'
import ShipmentField from '../components/ShipmentField'
import PortField from '../components/PortField'
import CommentField from '../components/CommentField'
import TagsField from '../components/TagsField'
import ColoredField from '../components/ColoredField'
import ColoredFieldWithMappings from '../components/ColoredFieldWithMappings'

export const scheduleAnnouncedSchema = {
  icon: {
    field: (shipment: IDashboardScheduleAnnouncedShipment) => (
      <IconField shipment={shipment} />
    ),
    name: '',
    sortable: false,
    sortValue: '',
    customClassName: 'short-50',
  },
  shipment: {
    field: (shipment: IDashboardScheduleAnnouncedShipment) => (
      <ShipmentField shipment={shipment} />
    ),
    name: 'SHIPMENT',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  carrier: {
    field: (shipment: IDashboardScheduleAnnouncedShipment) => (
      <>
        <Box className="text-wrapper">{shipment.carrier?.name}</Box>
        <Box className="text-wrapper">
          {shipment.vessel ? <CopyWrapper>{shipment.vessel}</CopyWrapper> : ''}
        </Box>
      </>
    ),
    name: 'CARRIER',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned x-medium',
  },
  from: {
    field: (shipment: IDashboardScheduleAnnouncedShipment) => (
      <PortField
        port={shipment.loading_port}
        date={shipment.estimated_departure}
      />
    ),
    name: 'POL',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  to: {
    field: (shipment: IDashboardScheduleAnnouncedShipment) => (
      <PortField
        port={shipment.discharge_port}
        date={shipment.estimated_arrival}
      />
    ),
    name: 'POD',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  volume: {
    field: (shipment: IDashboardScheduleAnnouncedShipment) => (
      <Box>{shipment.volume}</Box>
    ),
    name: 'VOLUME',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned s-medium',
  },
  comment: {
    field: (shipment: IDashboardScheduleAnnouncedShipment) => (
      <CommentField comment={shipment.comment} />
    ),
    name: 'COMMENT',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  finance: {
    field: (shipment: IDashboardScheduleAnnouncedShipment) =>
      shipment.finance_task ? (
        <ColoredFieldWithMappings
          type="task"
          mapping="finance_etd_check"
          value={shipment.finance_task.task_status_id}
        />
      ) : (
        <ColoredField value="" icon="remove" color="white" />
      ),
    name: 'FINANCE',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned s-medium',
  },
  tags: {
    field: (shipment: IDashboardScheduleAnnouncedShipment) => (
      <TagsField tags={shipment.tags} />
    ),
    name: 'TAGS',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned wide',
  },
}

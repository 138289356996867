const secondary = {
  50: '#E2F2FB',
  100: '#B9DEF7',
  200: '#8DC9F3',
  300: '#60B4EE',
  400: '#3BA4EC',
  500: '#0596E9',
  600: '#0088DC',
  700: '#0076C9',
  800: '#0065B8',
  900: '#004899',
}

export default secondary

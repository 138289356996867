import { FunctionComponent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { compact } from 'lodash'

import AuthorAvatar from 'src/components/AuthorAvatar'
import PurchaseOrderEventTemplate from '../PurchaseOrderEvent/PurchaseOrderEventTemplate'
import { convertDateToDateTime } from '../../../../utils/helpers'

import './styles.scss'

interface IProps {
  onUnmark?: (eventId: number) => void
  event: IPurchaseOrderEvent
  publicPage: boolean
}

const PurchaseOrderEvent: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const { currentUserId, canBuy } = useSelector((state: IGlobalState) => ({
    currentUserId: state.user.id,
    canBuy: state.user.can_buy,
  }))

  const authorNameWithOrganization: React.ReactNode = useMemo(() => {
    const author: IAuthor = props.event.user
    let authorName: string = ''
    if (props.event.user.id === currentUserId) {
      authorName = 'You'
    } else {
      if (author.organization.id === 1) {
        authorName = compact([
          author.first_name,
          author.department,
          author.phone,
        ]).join(' | ')
      } else {
        authorName = author.full_name
      }
      authorName += ` | ${author.organization.name}`
    }

    return (
      <span className="booking-event__content-bottom--author">
        {authorName}
      </span>
    )
  }, [props.event])

  const onUnmarkClick = async (e) => {
    props.onUnmark && props.onUnmark(props.event.id)
  }

  return (
    <div className="purchase-order-event">
      <AuthorAvatar author={props.event.user} size="medium" />
      <div className="purchase-order-event__content">
        <PurchaseOrderEventTemplate
          exceptional={props.event.exceptional}
          templateName={props.event.template_name}
          templateVariables={props.event.template_variables}
        />
        <div className="purchase-order-event__content-bottom">
          {convertDateToDateTime(props.event.created_at)} -{' '}
          {authorNameWithOrganization}
        </div>
      </div>
      <div className="purchase-order-event__actions">
        {!props.publicPage && canBuy && props.event.exceptional && (
          <div
            className="purchase-order-event__actions__item"
            onClick={onUnmarkClick}
          >
            {t(
              'purchase_orders.table.tabs.events.unmark_exception',
              'Unmark exception'
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default PurchaseOrderEvent

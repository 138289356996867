import { FunctionComponent } from 'react'
import Box from '@mui/material/Box'
import { DateTime } from 'luxon'

import {
  convertToStandartDateFormat,
  convertToStandartDateTimeFormat,
  convertToStandartDateTimeInterval,
  fromISOwithZone,
} from 'src/utils/helpers/shipmentDate'

const GenericDateField: FunctionComponent<{
  date: string | null
  withTime?: boolean
  interval?: number | null
}> = ({ date, withTime, interval }) => {
  if (!date) return <Box>-</Box>
  const convertedDate: DateTime = fromISOwithZone(date)
  if (interval) {
    return (
      <Box>{convertToStandartDateTimeInterval(convertedDate, interval)}</Box>
    )
  }
  if (withTime) {
    return <Box>{convertToStandartDateTimeFormat(convertedDate)}</Box>
  }
  return <Box>{convertToStandartDateFormat(convertedDate)}</Box>
}

export default GenericDateField

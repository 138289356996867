import { FunctionComponent, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { orderBy } from 'lodash'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { promisifyAction } from '../../../../utils'
import { createSLA, updateSLA } from '../../../../stores/actionCreators'
import { dummySLA } from '../../../../stores/reducers/organizationSettings'
import { Dialog, DialogContent } from '../../../../stories'
import SettingsBlock from './SettingsBlock'
import PartiesBlock from './PartiesBlock'
import { addressesValidation } from './validations'
import './styles.scss'

interface IProps {
  open: boolean
  close: () => void
  fetchData: () => void
  slaCheckpoint: ISLA
}

const PurchaseModalWindow: FunctionComponent<IProps> = (props) => {
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [loadingButton, setLoadingButton] = useState<boolean>(false)
  const [slaCheckpoint, setSlaCheckpoint] = useState<ISLA>(dummySLA)
  const dispatch = useDispatch()

  const createSLAAsync = promisifyAction(dispatch, createSLA)
  const updateSLAAsync = promisifyAction(dispatch, updateSLA)

  useEffect(() => {
    if (props.slaCheckpoint && props.slaCheckpoint.id) {
      setSlaCheckpoint(props.slaCheckpoint)
      setIsEdit(true)
    } else {
      setIsEdit(false)
    }
  }, [props.slaCheckpoint])

  const onUpdateAndClose = useCallback(async () => {
    await setLoadingButton(true)
    if (!disabledCondition() && !loadingButton) {
      await onSave()
      onClose()
    } else {
      setLoadingButton(false)
    }
  }, [slaCheckpoint])

  const onSave = async (): Promise<any> => {
    let slaCheckpointData = slaCheckpoint
    if (slaCheckpointData.dt_days_id === 1) {
      slaCheckpointData.dt_days = slaCheckpointData.dt_days * -1
    }
    slaCheckpointData.sla_checkpoint_addresses_attributes =
      slaCheckpointData.sla_checkpoint_addresses
    try {
      if (isEdit) {
        await updateSLAAsync(slaCheckpointData.id, slaCheckpointData)
      } else {
        await createSLAAsync(slaCheckpointData)
      }
    } catch {
      dispatch(
        showNotification({
          message: `SLA checkpoint ${isEdit ? 'editing' : 'creating'} error.`,
          severity: 'error',
        })
      )
    }
    await props.fetchData()
    setLoadingButton(false)
  }

  const { t } = useTranslation()

  const onClose = (): void => {
    setIsEdit(false)
    setSlaCheckpoint({
      ...dummySLA,
      ...{
        sla_checkpoint_addresses: [
          {
            address_id: 0,
            address: null,
            automated_alert: false,
          },
        ],
      },
    })
    props.close()
  }

  const handleChangeSLA = (value: number | string, type: string): void => {
    setSlaCheckpoint({ ...slaCheckpoint, ...{ [type]: value } })
  }

  const disabledCondition = (): boolean => {
    return (
      loadingButton ||
      !slaCheckpoint.name ||
      !slaCheckpoint.dt_days ||
      !addressesValidation(slaCheckpoint)
    )
  }

  const addNewAddress = (): void => {
    const updatedSLACheckpointsArr = [
      ...slaCheckpoint.sla_checkpoint_addresses,
      {
        address_id: 0,
        address: null,
        automated_alert: false,
      },
    ]

    setSlaCheckpoint({
      ...slaCheckpoint,
      ...{ sla_checkpoint_addresses: [...updatedSLACheckpointsArr] },
    })
  }

  const removeAddress = (checkpointIndex: number): void => {
    const updatedSLACheckpointsArr = [
      ...orderBy(slaCheckpoint.sla_checkpoint_addresses).map(
        (item, index: number) => {
          if (index === checkpointIndex) {
            item._destroy = true
            return item
          }
          return item
        }
      ),
    ]

    setSlaCheckpoint({
      ...slaCheckpoint,
      ...{ sla_checkpoint_addresses: [...updatedSLACheckpointsArr] },
    })
  }

  const onChangeAddressItem = useCallback(
    (itemIndex: number, name: string, value: string | number): void => {
      const updatedSLACheckpointsArr = orderBy(
        slaCheckpoint.sla_checkpoint_addresses
      ).map((item, index: number) => {
        if (index === itemIndex) {
          if (name === 'email') {
            item.address_attributes = {
              id: item.address_id || (item.address ? item.address.id : 0),
              email: value as string,
            }
          } else {
            item[name] = value
          }
        }
        return item
      })

      setSlaCheckpoint({
        ...slaCheckpoint,
        ...{ sla_checkpoint_addresses: [...updatedSLACheckpointsArr] },
      })
    },
    [slaCheckpoint]
  )

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      title={`${
        isEdit
          ? t('common.buttons.edit', 'Edit')
          : t('address_book.add_address.add_new', 'Add new')
      } ${t('address_book.connections.sla', 'SLA')}`}
      maxWidth="lg"
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>
            {t('account.side_bar.edit_account_modal.cancel_button', 'Cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            onClick={onUpdateAndClose}
            loading={loadingButton}
            disabled={disabledCondition()}
          >
            {t('common.buttons.update', 'Update')}
          </LoadingButton>
        </>
      }
    >
      <DialogContent className="mt-16">
        <SettingsBlock
          slaCheckpoint={slaCheckpoint}
          isEdit={isEdit}
          handleChangeSLA={handleChangeSLA}
        />
        <PartiesBlock
          slaCheckpoint={slaCheckpoint}
          addNewAddress={addNewAddress}
          destroyAddress={removeAddress}
          onChangeAddressItem={onChangeAddressItem}
        />
      </DialogContent>
    </Dialog>
  )
}

export default PurchaseModalWindow

import { createReducer, Handlers, Reducer } from 'redux-create-reducer'
import { AnyAction } from 'redux'
import * as actions from '../actions/searches'

declare global {
  interface ICarriersResult {
    list: ICarrier[]
  }
}

export const initialCarriersState: ICarriersResult = {
  list: [],
}

const getCarriers: Reducer<ICarriersResult, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  list: payload,
})

const actionHandlers: Handlers<ICarriersResult> = {
  [actions.GET_CARRIERS_SUCCESS]: getCarriers,
}

export default createReducer(initialCarriersState, actionHandlers)

import './counter.scss'

export interface CounterProps {
  /**
   * Counter background color
   */
  className: 'green' | 'red'
  /**
   * Counter value
   */
  value: number | string
}

export const Counter: React.FC<CounterProps> = ({ className, value = 1 }) => (
  <div className={`counter ${className} ${value > 99 ? 'wide' : ''}`}>
    {value < 100 ? value : '99+'}
  </div>
)

import { FunctionComponent, useState } from 'react'
import { useDispatch, useSelector, shallowEqual, useStore } from 'react-redux'
import { Box, IconButton, SelectChangeEvent } from '@mui/material'
import { modalityOptions } from 'src/config/constants'
import { compact, uniq, uniqBy } from 'lodash'
import { AutoComplete } from 'src/stories'
import Delete from '@mui/icons-material/Delete'

import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import Input from 'src/components/Common/Input'
import ModalitySelect from 'src/components/Common/ModalitySelect'
import FormLabel from 'src/stories/Lab/FormLabel'
import {
  addressSuggestions,
  watchShipmentRulesGetShipper,
} from '../../../stores/actionCreators'

import { promisifyAction } from '../../../utils'

import { conditionDirectionOptions } from './constants'

interface IProps {
  ruleCondition: IWatchShipmentRuleCondition
  index: number
  onDeleteConditionClick: (i: number) => void
  conditionOptions: any[]
  deletable: boolean
  onConditionChange: (i: number, condition: IWatchShipmentRuleCondition) => void
}

const WatchShipmentRuleCondition: FunctionComponent<IProps> = ({
  ruleCondition,
  index,
  onDeleteConditionClick,
  onConditionChange,
  conditionOptions,
  deletable,
}) => {
  const dispatch = useDispatch()
  const store = useStore()

  const { countriesOptions } = useSelector(
    (state: IGlobalState) => ({
      countriesOptions: state.countries.list,
    }),
    shallowEqual
  )

  const [autocompleteOptions, setAutocompleteOptions] = useState([])

  const getPortsAsync = promisifyAction(dispatch, addressSuggestions)
  const addressesGetDataAsync = promisifyAction(
    dispatch,
    watchShipmentRulesGetShipper
  )

  const onModalitySelectChange = (newValue) => {
    const selectedModality = modalityOptions.find(
      (option) => option.id === newValue
    )
    ruleCondition.value = selectedModality?.id || ''
    ruleCondition.value_label = selectedModality?.label || ''

    onConditionChange(index, ruleCondition)
  }

  const onRuleConditionChange = (event: SelectChangeEvent<unknown>) => {
    const selectedConditionType = conditionOptions.find(
      (option) => option.id === event.target.value
    )
    ruleCondition.condition = selectedConditionType.id
    ruleCondition.value = ''
    ruleCondition.value_label = ''
    onConditionChange(index, ruleCondition)
    setAutocompleteOptions([])
  }

  const onRuleDirectionChange = (event: SelectChangeEvent<unknown>) => {
    ruleCondition.condition_direction = event.target.value as string
    onConditionChange(index, ruleCondition)
  }

  const onAutocompleteChange = (option) => {
    const value = option.map((opt) => opt.value || opt).join('|')
    const label = option.map((opt) => opt.label).join(' OR ')
    ruleCondition.value = value
    ruleCondition.value_label = label
    onConditionChange(index, ruleCondition)
  }

  const onMultiselectChange = (value) => {
    const selectedIds = uniq(compact(ruleCondition.value.toString().split('|')))
    if (selectedIds.includes(value)) {
      selectedIds.splice(selectedIds.indexOf(value), 1)
    } else {
      selectedIds.push(value)
    }
    ruleCondition.value = selectedIds.join('|')
    ruleCondition.value_label = countriesOptions
      .filter((x) => selectedIds.includes(`${x[1]}`))
      .map((x) => x[0])
      .join(' OR ')
    onConditionChange(index, ruleCondition)
  }

  const clearMultiselect = (_name) => {
    ruleCondition.value = ''
    ruleCondition.value_label = ''
    onConditionChange(index, ruleCondition)
  }

  const getData = (recordsType: 'ports' | 'addresses', searchInput: string) => {
    const fetchOptionsAsync = async (input: string): Promise<any[]> => {
      let options = []

      if (!!input) {
        switch (recordsType) {
          case 'ports':
            await getPortsAsync({ input: input, only_ports: true })
            options = store.getState().searchBooking.suggestions.map((x) => {
              return { id: `${x.port_id}`, label: x.main_text }
            })
            break
          case 'addresses':
            await addressesGetDataAsync({ search: input })
            options = store.getState().watchShipmentRules.shippers.map((x) => {
              return { id: `${x.id}`, label: x.name }
            })
            break
        }
      }
      setAutocompleteOptions(autocompleteOptions.concat(options))
      return options
    }
    return fetchOptionsAsync(searchInput)
  }

  const renderAutocomplete = (
    recordsType: 'ports' | 'addresses',
    fieldName
  ) => {
    const selectedIds = uniq(compact(ruleCondition.value.toString().split('|')))
    const selectedLabels = uniq(
      compact(ruleCondition.value_label.toString().split(' OR '))
    )
    const optionsToselector = autocompleteOptions.map((opt: any) => {
      return { value: opt.id, label: opt.label, data: opt }
    })
    const selectedOptions = selectedIds.map((id, index) => {
      return { value: id, label: selectedLabels[index], data: {} }
    })

    return (
      <>
        <FormLabel label={fieldName} />
        <AutoComplete
          multiple={true}
          name={
            conditionOptions.find(
              (opt) => opt.value === ruleCondition.condition
            )?.label
          }
          className="rule-condition-value"
          key={ruleCondition.condition}
          onSearch={(searchInput) => {
            return getData(
              recordsType,
              searchInput || ruleCondition.value_label.replace('Port of ', '')
            )
          }}
          onChange={onAutocompleteChange}
          placeholder="Type to search"
          noOptionsText="No options"
          disableChip={true}
          checkbox={true}
          value={selectedOptions}
          options={uniqBy(optionsToselector.concat(selectedOptions), 'value')}
          data-testid="rule-condition-multiselect"
        />
      </>
    )
  }

  const renderValueSelect = () => {
    switch (ruleCondition.condition) {
      case 'departing_from_port':
        return renderAutocomplete('ports', 'Port of loading (POL)')
      case 'arriving_to_port':
        return renderAutocomplete('ports', 'Port of discharge (POD)')
      case 'departing_from_country':
      case 'arriving_in_country':
        return (
          <Box>
            <FormLabel label="Countries" />
            <Input.OptionsPicker
              className="watch-rule-condition--multiselect"
              options={countriesOptions}
              title="Select countries"
              searchable={true}
              selectedTitle="Select countries"
              fieldName={ruleCondition.condition}
              onChange={onMultiselectChange}
              onCancel={clearMultiselect}
              values={compact(ruleCondition.value.toString().split('|'))}
              size="long"
            />
          </Box>
        )
      case 'consignee_is':
        return renderAutocomplete('addresses', 'Consignee')
      case 'shipper_is':
        return renderAutocomplete('addresses', 'Shipper')
      case 'modality_is':
        return (
          <Box>
            <ModalitySelect
              inlandOnly={false}
              defaultValue={
                modalityOptions.find((opt) => opt.id === ruleCondition.value)
                  ?.id || ''
              }
              onChangeModality={onModalitySelectChange}
            />
          </Box>
        )
      default:
        return (
          <Box sx={{ pt: 3 }}>
            <SingleSelect
              name="value"
              value=""
              options={[]}
              disabled={true}
              onChange={() => {}}
            />
          </Box>
        )
    }
  }

  return (
    <Box mt={2} data-testid="watch-rule-condition">
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ width: '40%' }}>
          <FormLabel label={index ? 'and' : 'Watch a shipment if'} />
          <SingleSelect
            value={
              conditionOptions.find((opt) => opt.id === ruleCondition.condition)
                ?.id || ''
            }
            options={conditionOptions}
            onChange={onRuleConditionChange}
            placeholder="Select"
            data-testid="rule-condition-select"
          />
        </Box>

        <Box sx={{ width: '10%', pt: 3 }}>
          <SingleSelect
            value={
              conditionDirectionOptions.find(
                (opt) => opt.id === ruleCondition.condition_direction
              )?.id || ''
            }
            options={conditionDirectionOptions}
            onChange={onRuleDirectionChange}
            data-testid="rule-condition-value"
          />
        </Box>
        <Box sx={{ width: '40%' }}>{renderValueSelect()}</Box>
        <Box
          sx={{
            width: '5%',
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {deletable && (
            <IconButton
              data-testid="watch-rule-condition-delete"
              onClick={() => {
                onDeleteConditionClick(index)
              }}
              size="medium"
            >
              <Delete color="action" />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default WatchShipmentRuleCondition

import { FunctionComponent, useState, useEffect } from 'react'
import './styles.scss'

interface IProps {
  className?: string
  placeholder?: string
  disabled?: boolean
  label?: string
  value: string
  name?: string
  rows?: number
  showError?: boolean
  errorMessage?: string | null
  onChange: (value: string) => void
  onFocus?: () => void
  onBlur?: () => void
  onKeyDown?: (event) => void
  autofocus?: boolean
  maxLength?: number
}

const Textarea: FunctionComponent<IProps> = (props) => {
  const [showError, setShowError] = useState<boolean>(true)

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    if (props.onChange) {
      props.onChange(event.target.value)
    }
  }

  const handleFocus = (): void => {
    props.onFocus && props.onFocus()
    setShowError(false)
  }

  const handleBlur = (): void => {
    props.onBlur && props.onBlur()
    setShowError(true)
  }

  useEffect(() => {
    setShowError(true)
  }, [props.value])

  return (
    <div
      className={`textarea-form ${props.className} ${
        props.disabled ? 'disabled' : ''
      } ${props.errorMessage && showError ? 'invalid' : ''}`}
    >
      <div className="input-group">
        {!!props.label && (
          <label className="textarea-form-label">{props.label}</label>
        )}
        <textarea
          id="textarea-field"
          className="textarea-field"
          name={props.name}
          onChange={handleChange}
          disabled={props.disabled}
          rows={props.rows}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={props.placeholder}
          maxLength={props.maxLength}
          value={props.value}
          required={true}
          autoFocus={props.autofocus}
          onKeyDown={props.onKeyDown}
        />
      </div>
      <div className="textarea-form-error">
        {showError && props.errorMessage}
      </div>
    </div>
  )
}

Textarea.defaultProps = {
  disabled: false,
  showError: false,
  className: '',
  rows: 4,
  onChange: (e) => undefined,
}

export default Textarea

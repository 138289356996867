import { ReactNode, FunctionComponent } from 'react'
import ContentDropDown from 'src/components/Common/ContentDropDown'

interface IProps {
  index: number | null
  children: ReactNode
  className?: string
}

const DropDownRow: FunctionComponent<IProps> = (props) => {
  return (
    <div className={`striped-table--row ${props.className || ''}`}>
      <ContentDropDown
        className="container-overview-block"
        header={<div>Header</div>}
        headerCollapsed={<div>Header2</div>}
        body={<div>Body</div>}
        disableCollapse={false}
      />
    </div>
  )
}

export default DropDownRow

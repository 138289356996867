import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Paper, Typography } from '@mui/material'
import './styles.scss'
import Row from './Row'

interface ISearchPreviousProps {
  previousSearches: ISearchPrevious[]
}

const SABSearchPrevious: FunctionComponent<ISearchPreviousProps> = (props) => {
  const { t } = useTranslation()
  if (!props.previousSearches.length) {
    return null
  }

  return (
    <div className="search-previous" data-testid="search-previous">
      <div className="search-previous__title">
        <Typography
          variant="body1Strong"
          children={t('search_and_book.previous_searches', 'Previous searches')}
        />
      </div>
      <Paper>
        {props.previousSearches.map((search, index) => (
          <Row key={index} search={search} />
        ))}
      </Paper>
    </div>
  )
}

export default SABSearchPrevious

import { useState, FunctionComponent } from 'react'
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
} from '@mui/material'
import SelectableChip from 'src/components/SelectableChip'
import { capitalize } from 'lodash'
import { DateTime } from 'luxon'
import {
  convertToStandartDateFormat,
  getTimeIntervalUpToNow,
} from 'src/utils/helpers'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useDispatch } from 'react-redux'
import {
  shipmentOverviewGetData,
  trackAndTraceCancel,
  trackAndTraceGetData,
  trackAndTracePause,
  trackAndTraceResume,
} from 'src/stores/actionCreators'
import { promisifyAction } from 'src/utils'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { tableColumnKeys, statuses, trackingKey } from '../../../constants'

const TrackAndTraceSubscriptionRow: FunctionComponent<{
  row: ITrackTraceSubscription
  shipmentId: number
}> = ({ row, shipmentId }) => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleMoreActionsClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMoreActionsClose = () => {
    setAnchorEl(null)
  }

  const trackAndTraceResumeAsync = promisifyAction(
    dispatch,
    trackAndTraceResume
  )
  const trackAndTracePauseAsync = promisifyAction(dispatch, trackAndTracePause)
  const trackAndTraceGetDataAsync = promisifyAction(
    dispatch,
    trackAndTraceGetData
  )
  const trackAndTraceCancelAsync = promisifyAction(
    dispatch,
    trackAndTraceCancel
  )
  const shipmentOverviewGetDataAsync = promisifyAction(
    dispatch,
    shipmentOverviewGetData
  )

  const getValue = (key: string) => {
    switch (key) {
      case 'status':
        return row.status ? (
          <SelectableChip
            disabled={true}
            variant="filled"
            value={{
              name:
                row.status === 'paused_eta_updates'
                  ? 'Active'
                  : capitalize(row.status),
              color: statuses[row.status] ?? 'default',
            }}
          />
        ) : (
          '-'
        )
      case 'tracking_key':
        return row.tracking_key ? trackingKey[row.tracking_key] : '-'
      case 'carrier_name':
        return row.carrier.name || '-'
      case 'last_provider_update':
        return row['last_provider_update']
          ? getTimeIntervalUpToNow(
              DateTime.fromISO(row['last_provider_update'])
            )
          : '-'
      case 'created_at':
        return row['created_at']
          ? convertToStandartDateFormat(DateTime.fromISO(row['created_at']))
          : '-'
      default:
        return row[key] || '-'
    }
  }

  const isPaused = ['paused', 'paused_eta_updates'].includes(row.status)

  const noActions = ['archived', 'cancelled'].includes(row.status)

  const handleSubscriptionStatus = async (): Promise<void> => {
    if (isPaused) {
      await trackAndTraceResumeAsync(shipmentId, {
        trackId: row.id,
      })
    } else {
      await trackAndTracePauseAsync(shipmentId, {
        trackId: row.id,
      })
    }

    await trackAndTraceGetDataAsync(shipmentId)
    shipmentOverviewGetDataAsync(shipmentId)
  }

  const handleCancelSubscriptionStatus = async () => {
    try {
      await trackAndTraceCancelAsync(shipmentId, {
        trackId: row.id,
      }).then((_) =>
        dispatch(
          showNotification({
            message: 'Track and trace subscription has been archived.',
            severity: 'success',
          })
        )
      )
    } catch (error) {
      dispatch(
        showNotification({
          message: (error as any).response.data,
          severity: 'error',
        })
      )
    }

    await trackAndTraceGetDataAsync(shipmentId)
    shipmentOverviewGetDataAsync(shipmentId)
  }

  return (
    <TableRow data-testid="t-n-t-table-row">
      {tableColumnKeys.map((key) => (
        <TableCell key={key} data-testid={key}>
          {getValue(key)}
        </TableCell>
      ))}
      <TableCell className="right-aligned short">
        <Box>
          {!noActions && (
            <>
              <IconButton
                aria-haspopup="true"
                id="more-actions-toggler"
                aria-expanded={open ? 'true' : undefined}
                aria-controls={open ? 'more-actions-menu' : undefined}
                onClick={handleMoreActionsClick}
                size="large"
              >
                <MoreVertIcon color="action" />
              </IconButton>

              <Menu
                open={open}
                anchorEl={anchorEl}
                id="more-actions-menu"
                onClose={handleMoreActionsClose}
                MenuListProps={{
                  'aria-labelledby': 'more-actions-toggler',
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                {isPaused && (
                  <MenuItem key="resume" onClick={handleSubscriptionStatus}>
                    Resume
                  </MenuItem>
                )}
                {row.status === 'active' && (
                  <MenuItem key="pause" onClick={handleSubscriptionStatus}>
                    Pause
                  </MenuItem>
                )}
                <MenuItem
                  key="archive"
                  onClick={handleCancelSubscriptionStatus}
                >
                  Archive
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default TrackAndTraceSubscriptionRow

import { LoadTypeEnum, ModalityEnum } from 'src/config/constants'
import { FilterOptionValue } from './types'

export const searchParams = {
  pickupTime: 'pickup_time',
  deliveryTime: 'delivery_time',
  pol: 'pol_ids',
  pod: 'pod_ids',
  departure: 'departure_date',
  arrival: 'arrival_date',
  modality: 'modality',
  loadType: 'load_type',
  shipmentStatus: 'status_ids',
  pickupStatus: 'pickup_status',
  deliveryStatus: 'delivery_status',
  consignee: 'consignee_id',
}

export const modalityOptions: FilterOptionValue[] = [
  { value: ModalityEnum.Sea, label: 'Sea' },
  { value: ModalityEnum.Air, label: 'Air' },
  { value: ModalityEnum.Road, label: 'Road' },
  { value: ModalityEnum.Rail, label: 'Rail' },
  { value: ModalityEnum.Barge, label: 'Barge' },
]

export const loadTypeOptions: FilterOptionValue[] = [
  { value: LoadTypeEnum.fcl, label: 'FCL' },
  { value: LoadTypeEnum.lcl, label: 'LCL' },
]

export const pickupOrDeliveryStatusOptions: FilterOptionValue[] = [
  { value: 'estimated', label: 'Estimated' },
  { value: 'requested', label: 'Requested' },
  { value: 'proposed', label: 'Proposed' },
  { value: 'confirmed', label: 'Confirmed' },
  { value: 'completed', label: 'Completed' },
]

export const pickupOrDeliveryStatusColors = {
  requested: 'warning',
  estimated: 'primary',
  proposed: 'secondary',
  confirmed: 'success',
  completed: 'success',
}

export const overviewFilterItemsStorageKey = 'overviewStateFilterItems'

export const datePickerDefaultValue = {
  start: null,
  end: null,
}

export const savedFilterKey = 'overviewFilters'
export const savedFilterParamsKey = 'overviewFiltersParams'

export const searchFilterGroups = {
  recent_searches: 'Recent searches/filters',
  filters: 'Filters',
}

export const customLabelsForWeekOptions = [
  {
    i18nKey: 'current_week',
    defaultValue: 'Current week (week {{weekNumber}})',
  },
  {
    i18nKey: 'next_week',
    defaultValue: 'Next week (week {{weekNumber}})',
  },
  {
    i18nKey: 'week_with_number',
    defaultValue: 'Week {{weekNumber}}',
  },
]

import { useState } from 'react'
import { Box, Typography } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import { useTranslation } from 'react-i18next'
import Input from '../Common/Input/MuiInput'
import validations, { PasswordModalState } from './validations'
import PasswordRequirementItem from './PasswordRequirementItem'

const PasswordContent = ({
  onChange,
  formData,
  currentPasswordRequired = true,
}: {
  currentPasswordRequired?: boolean
  onChange: (value: string | number, field: string) => void
  formData: PasswordModalState
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const { t } = useTranslation()

  const endAdornment = (
    <IconButton onClick={() => setShowPassword(!showPassword)} size="large">
      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
    </IconButton>
  )

  return (
    <Box sx={{ display: 'flex' }}>
      <Box mr={4}>
        {currentPasswordRequired && (
          <Box mb={2}>
            <Input
              data-testid="input-current-password"
              type={showPassword ? 'text' : 'password'}
              name="current"
              required
              label={t(
                'change_password.fields.current_password.label',
                'Current Password'
              )}
              value={formData.current || ''}
              onChange={onChange}
              autoComplete="new-password"
              placeholder={t(
                'change_password.fields.current_password.placeholder',
                'Current Password'
              )}
              endAdornment={endAdornment}
            />
          </Box>
        )}
        <Box mb={2}>
          <Input
            data-testid="input-new-password"
            type={showPassword ? 'text' : 'password'}
            name="password"
            required
            label={t(
              'change_password.fields.new_password.label',
              'New Password'
            )}
            value={formData.password || ''}
            onChange={onChange}
            autoComplete="new-password"
            placeholder={t(
              'change_password.fields.new_password.placeholder',
              'New Password'
            )}
            endAdornment={endAdornment}
          />
        </Box>
        <Input
          data-testid="input-confirm-password"
          type={showPassword ? 'text' : 'password'}
          name="confirm"
          required
          label={t(
            'change_password.fields.confirm_password.label',
            'Confirm Password'
          )}
          value={formData.confirm || ''}
          onChange={onChange}
          autoComplete="new-password"
          placeholder={t(
            'change_password.fields.confirm_password.placeholder',
            'Confirm Password'
          )}
          endAdornment={endAdornment}
        />
      </Box>
      <Box sx={{ alignItems: 'center', display: 'flex' }}>
        <Box>
          <Typography variant="subtitle2">Password requirements:</Typography>
          <Box mt={1}>
            <PasswordRequirementItem
              text={t(
                'change_password.validations.password_length',
                'At least 10 characters'
              )}
              isChecked={validations.correctLength(formData.password)}
            />
            <PasswordRequirementItem
              text={t(
                'change_password.validations.uppercase',
                'At least 1 uppercase letter (A-Z)'
              )}
              isChecked={validations.hasUppercaseCharacters(formData.password)}
            />
            <PasswordRequirementItem
              text={t(
                'change_password.validations.lowercasae',
                'At least 1 lowercase letter (a-z)'
              )}
              isChecked={validations.hasLowercaseCharacters(formData.password)}
            />
            <PasswordRequirementItem
              text={t(
                'change_password.validations.number',
                'At least 1 number (0-9)'
              )}
              isChecked={validations.hasNumbers(formData.password)}
            />
            <PasswordRequirementItem
              text={t(
                'change_password.validations.special_character',
                'At least 1 special character (!@#$%^&*)'
              )}
              isChecked={validations.hasSpecialCharacters(formData.password)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default PasswordContent

import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Box, Typography } from '@mui/material'

interface IProps {
  productsState: string
  changeProductsState: (productsState: string) => void
  disableProductsState: boolean
}

const ProductsBlock: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    props.changeProductsState((event.target as HTMLInputElement).value)
  }

  return (
    <>
      <Box mb={2} mt={2}>
        <Typography variant="h5">
          {t(
            'purchase_orders.modals.purchase_modal.products.title',
            'Products'
          )}
        </Typography>
      </Box>
      <div className="purchase-orders-window--block-content">
        <Typography variant="body2">
          {t(
            'purchase_orders.modals.purchase_modal.products.description',
            'How do you want to specify your products'
          )}
        </Typography>

        <RadioGroup value={props.productsState} onChange={handleChange}>
          <div className="flex">
            <FormControlLabel
              value="unknown"
              control={
                <Radio color="primary" disabled={props.disableProductsState} />
              }
              label={t(
                'purchase_orders.modals.purchase_modal.products.unknown_measurments.label',
                'Unknown measurements'
              )}
            />

            <FormControlLabel
              value="total"
              control={
                <Radio color="primary" disabled={props.disableProductsState} />
              }
              label={t(
                'purchase_orders.modals.purchase_modal.products.volume_and_weight.label',
                'Total volume and weight'
              )}
            />
            <FormControlLabel
              value="add"
              control={<Radio color="primary" />}
              label={t(
                'purchase_orders.modals.purchase_modal.products.add_products.label',
                'Add products'
              )}
            />
          </div>
        </RadioGroup>
      </div>
    </>
  )
}

export default ProductsBlock

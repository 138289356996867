import { takeLatest, put, select } from 'redux-saga/effects'
import { showNotification } from 'src/stores/actionCreators/notifications'
import * as actions from '../../actions/shipmentQuotations'
import * as actionCreators from '../../actionCreators/shipmentQuotations'
import { shipmentOverviewGetData } from '../../actionCreators/shipmentOverview'
import { simpleGetAndResolve, simplePostAndResolve } from '../factories'

const getShipmentQuotations = simpleGetAndResolve(
  ({ payload }) =>
    `/api/v1/shipments/${payload.shipment.id}/shipment_quotations/all`,
  actionCreators.shipmentQuotationsReceived,
  (request) => request.data.data.shipment_quotations
)

const bookShipmentQuotations = simplePostAndResolve(
  ({ payload }) => `/api/v1/shipment_quotations/${payload.quotation.id}/book`,
  actionCreators.shipmentQuotationBooked,
  (request) => request
)

function* shipmentQuotationBooked() {
  const shipment: IOverviewShipment = yield select(
    (state: IGlobalState) => state.shipmentOverview
  )
  yield put(
    showNotification({
      message: 'Quote has been accepted',
      severity: 'success',
    })
  )
  yield put(shipmentOverviewGetData(shipment.id.toString()))
}

export default function* (): Iterator<any> {
  yield takeLatest(actions.REQUESTED_SHIPMENT_QUOTATION, getShipmentQuotations)
  yield takeLatest(actions.BOOK_SHIPMENT_QUOTATION, bookShipmentQuotations)
  yield takeLatest(actions.BOOKED_SHIPMENT_QUOTATION, shipmentQuotationBooked)
}

import { AnyAction } from 'redux'
import * as actions from '../actions/csat'

export const saveRating = (payload: any): AnyAction => ({
  type: actions.CSAT_SAVE_RATING,
  payload,
})

export const saveRatingSuccess = (payload: any): AnyAction => ({
  type: actions.CSAT_SAVE_RATING_SUCCESS,
  payload,
})

export const saveComment = (payload: any): AnyAction => ({
  type: actions.CSAT_SAVE_COMMENT,
  payload,
})

export const saveCommentSuccess = (payload: any): AnyAction => ({
  type: actions.CSAT_SAVE_COMMENT_SUCCESS,
  payload,
})

export const saveCommentByToken = (payload: any): AnyAction => ({
  type: actions.CSAT_SAVE_COMMENT_BY_TOKEN,
  payload,
})

export const saveCommentByTokenSuccess = (payload: any): AnyAction => ({
  type: actions.CSAT_SAVE_COMMENT_BY_TOKEN_SUCCESS,
  payload,
})

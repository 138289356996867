import { Box } from '@mui/material'
import MuiPagination from '@mui/material/Pagination'
import { PaginationProps } from './paginationProps'
import './styles.scss'

export const Pagination: React.FC<PaginationProps> = (props) => {
  const {
    sx = {},
    page = 1,
    totalItems,
    showRange = false,
    itemsPerPage = 20,
    ...muiPaginationProps
  } = props

  const count = Math.ceil(totalItems / itemsPerPage)

  const getRangeFrom = () => {
    return page === 1 ? page : (page - 1) * itemsPerPage + 1
  }

  const getRangeTo = () => {
    if (page === 1) {
      return Math.min(totalItems, itemsPerPage)
    }

    if (page === count) {
      return totalItems
    }

    return page * itemsPerPage
  }

  const getRangeCounterText = () => {
    if (totalItems === 0) {
      return '0-0'
    }

    return `${getRangeFrom()}-${getRangeTo()}`
  }

  return (
    <Box
      sx={{
        ...sx,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <MuiPagination count={count} page={+page} {...muiPaginationProps} />
      {showRange && (
        <Box sx={{ typography: 'body2', mx: 2 }}>
          {`(${getRangeCounterText()} of ${totalItems})`}
        </Box>
      )}
    </Box>
  )
}

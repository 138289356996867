import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { SABSearchItemTimelineAddressProps } from './SABSearchItem.props'
import SABSearchItemTimelineLocationIcon from './SABSearchItemTimelineLocationIcon'

const SABSearchItemTimelineAddress: React.FC<SABSearchItemTimelineAddressProps> = ({
  port,
  title,
  address,
  textAlign,
}) => {
  const text = address === port.name ? 'Unknown' : address
  return (
    <Box textAlign={textAlign} data-testid="search-and-book-item-address">
      <SABSearchItemTimelineLocationIcon />
      <Box px={1}>
        <Typography variant="body1Strong" children={title} />
        <Typography children={text} />
      </Box>
    </Box>
  )
}

export default SABSearchItemTimelineAddress

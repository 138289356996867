import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

const QuickFiltersManageDrawerListHeader = () => {
  const { t } = useTranslation()
  return (
    <>
      <Typography
        mb={2}
        variant="overline"
        children={t('filters.manage_quick_filters.name', 'name')}
      />
      <Divider sx={{ borderColor: 'grey.200' }} />
    </>
  )
}

export default QuickFiltersManageDrawerListHeader

import { EmptyState } from 'src/stories'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import { useTranslation } from 'react-i18next'

const NotificationsContentEmptyState = () => {
  const { t } = useTranslation()
  return (
    <EmptyState
      button={{
        link: '/account/notifications',
        text: t(
          'top_navigation.notifications.empty_state.settings_button.text',
          'Go to notification settings'
        ),
      }}
      badgeColor="primary"
      badgeIcon={DoneAllIcon}
      title={t(
        'top_navigation.notifications.empty_state.title',
        'No unread notifications'
      )}
      description={t(
        'top_navigation.notifications.empty_state.description',
        'You can let us know what type of notifications you would like to receive by editing your preferences in your account settings.'
      )}
    />
  )
}

export default NotificationsContentEmptyState

/**
 * Helper functions for determining the earliest possible shipment date
 */
import { DateTime, Duration, DurationUnit } from 'luxon'
import { ModalityEnum } from 'src/config/constants'

// All shipment-related timestamps come from the backend with timezone offsets.
// By default, these timestamps will be converted into the client's timezone.
// We don't want this for times related to shipments. We need to show
// the "local" time i.e. the time at port of loading/discharge.
//
// Setting setZone: true will keep the time in the original timezone.
export const FORMAT_LOCAL = "yyyy-MM-dd'T'HH:mm:ss"
export const FORMAT_SHORT_DATE = 'dd MMM'
export const FORMAT_DATE = 'dd MMM yyyy'
export const FORMAT_DATE_TIME = 'dd MMM yyyy, HH:mm'
export const FORMAT_TIME = 'HH:mm'
export const FORMAT_YEAR = 'yyyy'
export const FORMAT_ORDER = 'd/MM/yy'
export const DATE_STRING_FORMAT = 'yyyy-MM-dd'

const TIME_UNITS: Intl.RelativeTimeFormatUnit[] = [
  'day',
  'hour',
  'minute',
  'second',
]

const defaultOptions = { setZone: true }

function getDateIsExpired(date: string): boolean {
  return new Date(date).getTime() <= new Date().getTime()
}

function fromSQLwithZone(date: string, options = {}): DateTime {
  return DateTime.fromSQL(date, { ...defaultOptions, ...options })
}

function convertToStandartDateTimeFormat(date: DateTime): string {
  return date.toFormat(`${FORMAT_DATE} - ${FORMAT_TIME}`)
}

function convertToStandartDateTimeInterval(date: DateTime, interval: number) {
  const closingDate: DateTime = date.plus({ seconds: interval })
  const dateString = convertToStandartDateFormat(date)
  const openingTimeString: string = date.toFormat(FORMAT_TIME)
  const closingTimeString: string = closingDate.toFormat(FORMAT_TIME)

  return `${dateString} ${openingTimeString} - ${closingTimeString}`
}

function convertToStandartDateFormat(date: DateTime): string {
  return date.toFormat(FORMAT_DATE)
}

function fromISOwithZone(date: string, options = {}): DateTime {
  return DateTime.fromISO(date, { ...defaultOptions, ...options })
}

function fromJSwithZone(date: Date, options = {}): DateTime {
  return DateTime.fromJSDate(date)
}

function getCurrentDate(): DateTime {
  return DateTime.local()
}

function getCurrentDateString(): string {
  return DateTime.local().toString()
}

function increaseDate(date: string, type: string, count: number): string {
  return DateTime.fromISO(date)
    .plus({ [type]: count })
    .toString()
}

function decreaseDate(date: string, type: string, count: number): string {
  return DateTime.fromISO(date)
    .minus({ [type]: count })
    .toString()
}

function shippingDate(modality: ModalityEnum): string {
  let date = DateTime.local()
  if (modality === ModalityEnum.Air) {
    return date.toString()
  }
  if (date.weekday > 5) {
    date = addBusinessDaysNew(date, 1)
  }
  return addBusinessDaysNew(date, 3).toString()
}

const formatDesktopDate = (
  dateString: OptionalString,
  format: string
): string =>
  dateString
    ? fromISOwithZone(dateString).toFormat(format ? format : 'dd/MMM')
    : 'TBA'

const formatDate = (date: OptionalString): string =>
  formatDesktopDate(date, FORMAT_DATE)

const formatDateTime = (date: OptionalString): string =>
  formatDesktopDate(date, FORMAT_DATE_TIME)

function parseAnyFormatDate(date: string): string {
  return convertDateForComparison(date)
}

function minimumShippingDate(modality: ModalityEnum, date: string): string {
  const earliest = shippingDate(modality)
  if (convertDateForComparison(date) < earliest) {
    return earliest
  }
  return convertDateForComparison(date)
}

function addBusinessDaysNew(date: DateTime, days: number): DateTime {
  let i = 0
  let startDate = date
  while (i < days) {
    startDate = startDate.plus({ days: 1 })
    if (startDate.weekday > 1 && startDate.weekday < 7) {
      i++
    }
  }
  return startDate
}

function convertEstimatedDate(date: string): string {
  return fromISOwithZone(date).toFormat(FORMAT_SHORT_DATE) || ''
}

// when sending a local timestamp but you don't know the timezone/offset
function convertDateToLocalFormat(date: string): string {
  return fromISOwithZone(date).toFormat(FORMAT_LOCAL)
}

function convertDateToUniversalFormat(date: OptionalString): string {
  if (!date) {
    return ''
  }
  return fromISOwithZone(date).toFormat(FORMAT_SHORT_DATE)
}

function convertDatePickerToUniversalFormat(date: string | null): string {
  if (!date) {
    return ''
  }
  return DateTime.fromISO(date).toFormat(FORMAT_DATE) || ''
}

function convertDateToOrderFormat(
  date: string | null,
  format?: string
): string {
  if (!date) {
    return ''
  }

  switch (true) {
    case DateTime.fromFormat(date, 'yyyy-MM-d').isValid:
      return DateTime.fromFormat(date, 'yyyy-MM-d').toFormat(
        format || FORMAT_ORDER
      )
    case DateTime.fromFormat(date, 'd-MM-yyyy').isValid:
      return DateTime.fromFormat(date, 'd-MM-yyyy').toFormat(
        format || FORMAT_ORDER
      )
    case DateTime.fromFormat(date, 'd/MM/yyyy').isValid:
      return DateTime.fromFormat(date, 'd/MM/yyyy').toFormat(
        format || FORMAT_ORDER
      )
    case DateTime.fromISO(date).isValid:
      return DateTime.fromISO(date).toFormat(format || FORMAT_ORDER)
    default:
      return ''
  }
}

function convertDateForComparison(date: string | null): string {
  if (!date) {
    return ''
  }
  return fromISOwithZone(date).toISODate() || ''
}

function convertDateFromPicker(date: string | null): string {
  if (!date) {
    return ''
  }
  return DateTime.fromFormat(date, 'MM/dd/yyyy').toString()
}

function formattedDateNow(): string {
  return DateTime.local().toISODate() || ''
}

function convertToDate(date: string): string {
  return fromISOwithZone(date).toFormat('MMM dd, yyyy')
}

function convertDateToDateTime(date: string): string {
  return fromISOwithZone(date).toFormat('dd MMM yyyy - HH:mm')
}

function convertDateToDateWithoutYear(date: string): string {
  return fromISOwithZone(date).toFormat(FORMAT_SHORT_DATE)
}

function convertDateToDateTimeWithoutYear(date: string): string {
  return fromISOwithZone(date).toFormat('dd MMM HH:mm')
}
function convertDateToLocalDateTimeWithoutYear(date: string): string {
  return fromISOwithZone(date).toLocal().toFormat('dd MMM HH:mm')
}

function convertDateToFullWithTime(date: string | null): string {
  if (!date) {
    return ''
  }
  return `- ${fromISOwithZone(date).toFormat(FORMAT_DATE_TIME)}`
}

function convertDateToFullWithTimePoint(date: string | null): string {
  if (!date) {
    return ''
  }
  return `${fromISOwithZone(date).toFormat(FORMAT_DATE)} at ${fromISOwithZone(
    date
  ).toFormat(FORMAT_TIME)}`
}

function convertDateToDDMMWithTimePoint(date: string | null): string {
  if (!date) {
    return ''
  }
  return `${fromISOwithZone(date).toFormat(
    FORMAT_SHORT_DATE
  )} at ${fromISOwithZone(date).toFormat(FORMAT_TIME)}`
}

function convertDateToTime(date: string | null): string {
  if (!date) {
    return ''
  }
  return fromISOwithZone(date, { locale: 'en' }).toFormat(FORMAT_TIME) || ''
}

function convertDateToTimeRange(
  dateFrom: OptionalString,
  dateTo: OptionalString
): string {
  if (!dateFrom || !dateTo) {
    return ''
  }
  const timeFrom = fromISOwithZone(dateFrom).toFormat(FORMAT_TIME)
  const timeTo = fromISOwithZone(dateTo).toFormat(FORMAT_TIME)
  return (
    fromISOwithZone(dateFrom).toFormat('dd MMM yyyy HH:mm') +
    (timeFrom !== timeTo ? ` - ${timeTo}` : '')
  )
}

function convertDateToRelative(date: string): string | null {
  if (!date) {
    return ''
  }
  return DateTime.fromISO(date).toRelative({ locale: 'en' })
}

function extractHours(date: string): number {
  return fromISOwithZone(date).hour
}

function extractMinutes(date: string): number {
  return fromISOwithZone(date).minute
}

function convertToDay(date: string): string {
  return fromISOwithZone(date).toFormat('dd')
}

function convertToMonth(date: string): string {
  return fromISOwithZone(date).toFormat('MMM')
}

function convertToUnixDate(date: string): string {
  return DateTime.fromISO(date).toFormat('X')
}

function isValidDate(date: string): boolean {
  return DateTime.fromISO(date).isValid
}

function startOfDay(date: string): string {
  const dateValue = fromISOwithZone(date)
  return dateValue?.startOf('day')?.toISODate() || ''
}

function datesDifferenceInDays(start: string, end: string): number {
  return fromISOwithZone(start).diff(fromISOwithZone(end), 'days').days
}

function datesDifference(start: string, end: string): number {
  return fromISOwithZone(start).diff(fromISOwithZone(end), 'days').as('days')
}

function convertToJSDate(date: string): Date {
  return DateTime.fromISO(date).toJSDate()
}

function convertToDateStringFormat(date: Date): string {
  return DateTime.fromISO(date.toISOString()).toFormat(DATE_STRING_FORMAT)
}

function getTimeIntervalUpToNow(dateTime: DateTime) {
  const duration: Duration = dateTime.diffNow().shiftTo(...TIME_UNITS)

  const unit = TIME_UNITS.find((unit) => duration.get(unit))

  const relativeTimeFormat = new Intl.RelativeTimeFormat('en')
  return relativeTimeFormat.format(
    Math.round(duration.as(unit as DurationUnit)),
    unit as Intl.RelativeTimeFormatUnit
  )
}

export {
  getDateIsExpired,
  convertToStandartDateFormat,
  fromISOwithZone,
  fromSQLwithZone,
  convertToStandartDateTimeFormat,
  convertToStandartDateTimeInterval,
  getCurrentDate,
  getCurrentDateString,
  increaseDate,
  decreaseDate,
  shippingDate,
  minimumShippingDate,
  parseAnyFormatDate,
  convertEstimatedDate,
  convertDateToLocalFormat,
  convertDateToUniversalFormat,
  convertDateToDateTime,
  convertDatePickerToUniversalFormat,
  formatDate,
  formatDateTime,
  formatDesktopDate,
  convertDateToFullWithTime,
  convertDateToFullWithTimePoint,
  convertDateToDDMMWithTimePoint,
  convertToDate,
  convertDateToDateWithoutYear,
  convertDateToDateTimeWithoutYear,
  convertDateToLocalDateTimeWithoutYear,
  convertDateToTimeRange,
  extractHours,
  extractMinutes,
  convertDateForComparison,
  formattedDateNow,
  convertDateFromPicker,
  convertToUnixDate,
  convertToDay,
  convertToMonth,
  isValidDate,
  startOfDay,
  datesDifferenceInDays,
  datesDifference,
  convertDateToOrderFormat,
  convertDateToTime,
  convertDateToRelative,
  convertToJSDate,
  convertToDateStringFormat,
  getTimeIntervalUpToNow,
  fromJSwithZone,
}

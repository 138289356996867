import { FunctionComponent, useMemo } from 'react'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import { Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface IProps {
  container: IShipmentContainer
}

interface ISpecialRequirement {
  label: string
  value: string
}

const SpecialRequirements: FunctionComponent<IProps> = ({ container }) => {
  const { t } = useTranslation()
  const specialRequirements: ISpecialRequirement[] = [
    {
      label: t(
        'common.forms.fields.special_requirements.options.food_grade',
        'Food grade'
      ),
      value: 'food_grade',
    },
    {
      label: t(
        'common.forms.fields.special_requirements.options.heavy_tested',
        'Heavy tested'
      ),
      value: 'heavy_tested',
    },
    {
      label: t(
        'common.forms.fields.special_requirements.options.shippers_owned',
        'Shippers owned'
      ),
      value: 'shippers_owned',
    },
    {
      label: t(
        'common.forms.fields.non_operating_reefer.label',
        'Non operating reefer'
      ),
      value: 'non_operating_reefer',
    },
  ]
  const requirements: ISpecialRequirement[] = useMemo(() => {
    return specialRequirements.filter(
      (x) =>
        container[x.value] &&
        (x.value !== 'non_operating_reefer' ||
          (x.value === 'non_operating_reefer' &&
            container.equipment_controlled_atmosphere))
    )
  }, [container])

  return requirements.length ? (
    <div className="special-requirements">
      <div className="special-requirements--title">
        <Typography
          variant="h5"
          children={t(
            'common.forms.fields.special_requirements.label',
            'Special requirements'
          )}
        />
      </div>
      <div className="special-requirements--block">
        {requirements.map((requirement: ISpecialRequirement) => {
          return (
            <div className="special-requirements--block--item">
              <Chip
                label={requirement.label}
                color="info"
                key={requirement.label}
              />
            </div>
          )
        })}
      </div>
      <Divider />
    </div>
  ) : null
}

export default SpecialRequirements

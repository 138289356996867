import { makeStyles } from '@mui/styles'
import Box from '@mui/material/Box'

const useStyles = makeStyles((theme) => ({
  defaultLabelWrapper: {
    float: 'left',
    marginRight: theme.spacing(1),
  },
  defaultLabel: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey[600],
    padding: `0 ${theme.spacing(1)}`,
    border: '1px solid',
    borderRadius: 3,
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  defaultLabelIcon: {
    width: 12,
  },
}))

const DefaultLabel = ({ text }: { text: string }) => {
  const classes = useStyles()
  return (
    <Box className={classes.defaultLabelWrapper}>
      <Box className={classes.defaultLabel} height="20px">
        <Box>{text}</Box>
      </Box>
    </Box>
  )
}

export default DefaultLabel

import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  groupedRows: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  row: {
    '& td:first-child': {
      paddingLeft: (props: any) => (props.grouped ? theme.spacing(4) : '12px'),
    },
    '& td:last-child': {
      paddingRight: '100px',
    },
    '&.active:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.action.hover,
    },
  },
  tablePaperWrapper: {
    boxShadow: 'none',
    borderBottomLeftRadius: 'unset',
    borderBottomRightRadius: 'unset',
  },
}))

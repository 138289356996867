import { takeEvery } from 'redux-saga/effects'
import * as actionCreators from '../../actionCreators/searches'
import { simpleGetAndResolve } from '../factories'
import * as actions from '../../actions/searches'
import searchQuotes from './searchQuotes'
import { getLinkedCustomers } from './linkedCustomers'

export const getCarriers = simpleGetAndResolve(
  () => 'api/v1/common/carriers',
  actionCreators.getCarriersSuccess,
  (response) => response.data
)

export const getVessels = simpleGetAndResolve(
  () => 'api/v1/common/vessels',
  actionCreators.getVesselsSuccess,
  (response) => response.data
)

export const getCarrierOrganizations = simpleGetAndResolve(
  () => 'api/v1/common/carrier_organizations',
  actionCreators.getCarrierOrganizationsSuccess,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.GET_CARRIER_ORGANIZATIONS, getCarrierOrganizations)
  yield takeEvery(actions.SEARCH_QUOTES, searchQuotes)
  yield takeEvery(actions.LINKED_CUSTOMERS, getLinkedCustomers)
  yield takeEvery(actions.GET_CARRIERS, getCarriers)
  yield takeEvery(actions.GET_VESSELS, getVessels)
}

import { FunctionComponent, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { v4 as getID } from 'uuid'
import { round, sumBy } from 'lodash'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import { Button } from '@mui/material'
import Popper from '@mui/material/Popper'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { GoodsLoadTypeLCL } from 'src/components/GoodsLoadType/GoodsLoadTypeLCL'
import { initialCargo } from 'src/components/GoodsLoadType/GoodsLoadTypeLCL/constants'
import CustomInput from '../../Common/Input/MuiInput'
import { ISABGoodsSelectProps } from './index.props'

import './styles.scss'

const SABGoodsSelect: FunctionComponent<ISABGoodsSelectProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [clickedAway, setClickedAway] = useState<boolean | null>(false)

  useEffect(() => {
    if (props.modality === 'air') {
      handleChangeAllCargoByLoadType()
    }
  }, [props.modality])

  const { packageTypes, isLCLFormValid } = useSelector(
    (state: IGlobalState) => ({
      packageTypes: state.packageTypes.list,
      isLCLFormValid: state.lclForm.isValid,
    })
  )

  const isActive = Boolean(anchorEl)
  const elementId = `Selector_${getID()}`
  const showError = clickedAway && !isLCLFormValid
  const shoeErrorInput = props.isSubmited && !isLCLFormValid

  const onClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const onCancel = (): void => {
    if (isLCLFormValid) {
      setClickedAway(false)
      setAnchorEl(null)
    } else {
      setClickedAway(true)
      props.formRef?.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      )
    }
  }

  const addNewCargo = (): void => {
    setClickedAway(null)
    props.changeState(props.name, [...props.cargoArr, initialCargo()])
  }

  const handleChangeAllCargoByLoadType = (): void => {
    const updatedCargoArr: ICargoItem[] = props.cargoArr.map((cargo) => {
      cargo.total_volume_cbm = round(
        (cargo.quantity * cargo.width * cargo.height * cargo.length) / 1000000,
        2
      )
      return cargo
    })
    props.changeState(props.name, [...updatedCargoArr])
  }

  const removeCargo = (id: string): void => {
    props.changeState(props.name, [
      ...props.cargoArr.filter((item: ILCLObject) => item.id !== id),
    ])
  }

  const handleChangeCargo = ({ id, name, value }): void => {
    const updatedCargoArr: ICargoItem[] = props.cargoArr.map((cargo) => {
      if (cargo.id === id) {
        cargo[name] = value
      }

      return cargo
    })

    setClickedAway(isLCLFormValid)
    props.changeState(props.name, [...updatedCargoArr])
  }

  const clearCargo = (): void => {
    props.cargoArr[0] = initialCargo()

    props.changeState(props.name, [...props.cargoArr])
  }
  const { t } = useTranslation()
  const inputValue = () => {
    // Calculate cargo values
    const totalPieces = sumBy(props.cargoArr, 'quantity')
    const totalVolume = sumBy(props.cargoArr, 'total_volume_cbm').toFixed(2)
    const totalWeight = sumBy(props.cargoArr, 'weight')

    // Retrieve translated strings
    const piecesTranslation = t('common.pieces', 'Pieces')
    const totalTranslation = t('shipment_costs.table.total', 'Total')

    // Construct the translated string
    return `${totalPieces} ${piecesTranslation} | ${totalTranslation}: ${totalVolume} m3, ${totalWeight} kg`
  }

  const goodsTable = () => (
    <Grid>
      <GoodsLoadTypeLCL
        cargos={props.cargoArr}
        onRemove={removeCargo}
        onChange={handleChangeCargo}
        packageTypes={packageTypes}
        modality={props.modality}
        onClear={clearCargo}
        isShowRefresh={true}
        onSubmit={props.onSubmit}
        formRef={props.formRef}
        isShowAterisk={true}
      />
      {showError && (
        <Typography variant="body1" color="error" pl={2} pb={1}>
          {t(
            'search_and_book.goods_select.error_message',
            'Please complete all required fields to continue.'
          )}
        </Typography>
      )}
      {!props.isEdit && (
        <div className="goods-select--add-line">
          <Button
            variant="text"
            color="primary"
            size="medium"
            onClick={addNewCargo}
            startIcon={<AddRoundedIcon />}
            data-testid={'add-more-goods'}
          >
            {t('search_and_book.goods_select.add_more_goods', 'Add more goods')}
          </Button>
        </div>
      )}
    </Grid>
  )

  if (props.onlyTable) {
    return goodsTable()
  }

  return (
    <div className="goods-select">
      <CustomInput
        onClick={onClick}
        label={t('load_requirements_component.label', 'Load requirements')}
        placeholder="Specify load requirements"
        value={inputValue()}
        readOnly={true}
        error={shoeErrorInput}
        endAdornment={isActive ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
        data-testid={props.dataTestId || 'custome-input'}
      />
      <Popper
        id={elementId}
        open={isActive}
        anchorEl={anchorEl}
        placement="bottom"
        className="goods-select--popper"
      >
        <ClickAwayListener mouseEvent="onMouseUp" onClickAway={onCancel}>
          {goodsTable()}
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

export default SABGoodsSelect

import { FunctionComponent, useState, useCallback, useEffect } from 'react'
import { find } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TextArea } from 'src/stories/TextArea/TextArea'
import { AvatarGroup, ShyppleAvatarGroupProps } from 'src/stories/AvatarGroup'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CopyButton from 'src/stories/CopyButton'
import { Button } from '@mui/material'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { promisifyAction } from '../../utils'
import {
  shipmentOverviewGetData,
  updateShipmentReference,
} from '../../stores/actionCreators'
import { Dialog, DialogContent } from '../../stories'

const maxReferenceLength = 500

interface IProps {
  shipmentId: number
  shipmentTitle: string
  open: boolean
  onClose: () => void
}

interface IReferenceTextAreaProps {
  value: string
  name: string
  label: string
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  subLabel?: string
  placeholder?: string
  collaborators: ShyppleAvatarGroupProps['avatars']
  isLoading: boolean
  error?: string
}

export const getInternalReference = (
  shipment: IOverviewShipment,
  user: IUserState
): string => {
  const reference = find(shipment.reference_numbers, {
    organization_id: user.organizationId,
  })
  if (reference && reference.reference_number) {
    return reference.reference_number
  }
  return ''
}

export const ReferenceTextArea = (props: IReferenceTextAreaProps) => {
  const { t } = useTranslation()
  return (
    <TextArea
      isLoading={props.isLoading}
      id={`${props.name}-reference`}
      maxLength={501}
      error={props.error}
      name={props.name}
      onChange={props.onChange}
      value={props.value}
      placeholder={props.placeholder}
      label={
        <>
          <Typography className="medium bold">{props.label}</Typography>
          <Box ml={1} />
          <Typography className="small normal">{props.subLabel}</Typography>
        </>
      }
      afterLabel={
        <AvatarGroup size="medium" max={5} avatars={props.collaborators} />
      }
      endAdornment={(ref) =>
        props.value !== '' ? (
          <CopyButton
            children={t('common.buttons.copy', 'Copy')}
            inputRef={ref}
            successMessage={t(
              'common.notifications.reference_copied_to_clipboard'
            )}
          />
        ) : null
      }
      data-testid={props.name}
    />
  )
}

const ShipmentReferencesModal: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [references, setReferences] = useState<{
    private: string
    shared: string
  }>({
    private: '',
    shared: '',
  })
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState({
    private: '',
    shared: '',
    shipment: '',
  })

  const { shipment, currentUser } = useSelector((state: IGlobalState) => ({
    shipment: state.shipmentOverview,
    currentUser: state.user,
  }))

  const referencesBeforeUpdate = {
    private: getInternalReference(shipment, currentUser),
    shared: shipment.shared_reference,
  }

  useEffect(() => {
    setLoading(true)
    shipmentOverviewGetDataAsync(props.shipmentId.toString()).then(
      (newShipment) => {
        setReferences({
          shared: newShipment.shared_reference || '',
          private: getInternalReference(newShipment, currentUser),
        })
        setLoading(false)
      }
    )
  }, [])

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { name, value } = event.target
      setReferences((prev) => ({ ...prev, [name]: value }))
      if (value.length > maxReferenceLength) {
        setError((prev) => ({
          ...prev,
          [name]: t(
            'common.forms.fields.shipment_shared_reference.validation_error.max_characters',
            {
              maxCharacters: maxReferenceLength,
            }
          ),
        }))
      } else {
        setError((prev) => ({ ...prev, [name]: '' }))
      }
    },
    []
  )

  const shipmentOverviewGetDataAsync = promisifyAction(
    dispatch,
    shipmentOverviewGetData
  )

  const updateReferenceAsync = promisifyAction(
    dispatch,
    updateShipmentReference
  )

  const onSave = async (): Promise<void> => {
    updateReferenceAsync(props.shipmentId, {
      reference_number: references.private,
      shared_reference: references.shared,
    }).then(() => {
      dispatch(
        showNotification({
          message: t('shipment_reference_edit.success_message'),
          severity: 'success',
        })
      )
    })
  }

  const onClose = useCallback(() => {
    props.onClose()
  }, [props.onClose])

  const onSaveAndClose = useCallback(async () => {
    await onSave()
    props.onClose()
  }, [props.onClose, props.shipmentId, references])

  const collaboratorsAvatars = (shipment.collaborators || []).map((party) => ({
    alt: party.name,
    src: party.logo || 'no-logo',
    className: party.organization_role_code || party.role_code,
  }))

  const referencesChanged =
    referencesBeforeUpdate.shared !== references.shared ||
    referencesBeforeUpdate.private !== references.private

  const isInvalid = !!(error.private || error.shared || error.shipment)
  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      title={t('shipment_reference_edit.title', {
        shipmentNumber: props.shipmentTitle,
        defaultValue: 'Shipment references for {{shipmentNumber}}',
      })}
      actions={
        <>
          <Button
            onClick={onClose}
            children={t('common.buttons.cancel', 'Cancel')}
            variant="text"
          />
          <Button
            onClick={onSaveAndClose}
            disabled={isLoading || !referencesChanged || isInvalid}
            children={t('common.buttons.save', 'Save')}
            variant="contained"
          />
        </>
      }
    >
      <DialogContent>
        <ReferenceTextArea
          isLoading={isLoading}
          name="shared"
          value={references.shared}
          onChange={onChange}
          placeholder={t(
            'common.forms.fields.shipment_shared_reference.placeholder',
            'Enter shared reference'
          )}
          label={t(
            'common.forms.fields.shipment_shared_reference.label',
            'Shared reference'
          )}
          subLabel={t(
            'common.forms.fields.shipment_shared_reference.helper_text',
            'Visible to and editable by all collaborators'
          )}
          collaborators={collaboratorsAvatars}
          error={error.shared}
        />
        <Box mb={1} />
        <ReferenceTextArea
          isLoading={isLoading}
          name="private"
          value={references.private}
          onChange={onChange}
          placeholder={t(
            'common.forms.fields.shipment_internal_reference.placeholder',
            'Enter internal reference'
          )}
          label={t(
            'common.forms.fields.shipment_internal_reference.label',
            'Internal reference'
          )}
          subLabel={t(
            'common.forms.fields.shipment_internal_reference.helper_text',
            'Optional and only visible to your organization'
          )}
          collaborators={[
            {
              alt: currentUser.organizationName,
              src: currentUser.organizationLogo || 'no-logo',
              className: currentUser.organizationRole as any,
            },
          ]}
          error={error.private}
        />
        <Box mb={1} />
        <ReferenceTextArea
          isLoading={isLoading}
          name="shipment"
          value={props.shipmentTitle}
          onChange={() => {}}
          placeholder={t(
            'common.forms.fields.shipment_number.placeholder',
            'Shipment number'
          )}
          label={t(
            'common.forms.fields.shipment_number.label',
            'Shipment number'
          )}
          subLabel={t(
            'common.forms.fields.shipment_number.helper_text',
            'Not editable and visible to all collaborators'
          )}
          collaborators={collaboratorsAvatars}
          error={error.shipment}
        />
      </DialogContent>
    </Dialog>
  )
}

export default ShipmentReferencesModal

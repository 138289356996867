import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'
import { Chip, Typography } from '@mui/material'

const ChipLabel = ({ partOne, partTwo }) => {
  return (
    <>
      <Typography
        component="span"
        mr={0.5}
        data-testid="dropdown-trigger-category"
      >
        {partOne}:
      </Typography>
      <Typography
        component="span"
        variant="body1Strong"
        data-testid="dropdown-trigger-value"
        sx={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '50vw',
          verticalAlign: 'middle',
        }}
      >
        {partTwo}
      </Typography>
    </>
  )
}

const DropdownTrigger = ({
  partOne,
  anchorRef,
  partTwo,
  onClick,
  onDelete,
  open,
}) => {
  return (
    <Chip
      label={<ChipLabel partOne={partOne} partTwo={partTwo} />}
      title={partTwo}
      color={partOne === 'Search' ? 'secondary' : 'primary'}
      variant="filled"
      ref={anchorRef}
      onClick={onClick}
      onDelete={onDelete}
      icon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      data-testid="dropdown-trigger"
    />
  )
}

export default DropdownTrigger

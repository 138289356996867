import Box from '@mui/material/Box'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import { TableCustom, TableCustomCell } from './RatesLineDetails.styles'
import RatesLineDetailsChargesTablePriceBlock from './RatesLineDetailsChargesTablePriceBlock'
import RatesLineDetailsChargesTableCalculationMethod from './RatesLineDetailsChargesTableCalculationMethod'

const RatesLineDetailsChargesTable: React.FC<{
  prices: IRatesPriceItem[]
}> = ({ prices }) => {
  return (
    <TableCustom size="small">
      <TableBody>
        {prices.map((price: IRatesPriceItem) => (
          <TableRow key={price.id}>
            <TableCustomCell>{price.service_item.name}</TableCustomCell>
            <TableCustomCell>
              <Box display="flex">
                <RatesLineDetailsChargesTablePriceBlock price={price} />
                <RatesLineDetailsChargesTableCalculationMethod price={price} />
              </Box>
            </TableCustomCell>
          </TableRow>
        ))}
      </TableBody>
    </TableCustom>
  )
}

export default RatesLineDetailsChargesTable

import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { Box, Button } from '@mui/material'
import EmptyResults from 'src/components/Common/EmptyResults'
import AddCostsRow from '../AddCostsRow'
import ShipmentCostsVisibilityEmptyState from '../ShipmentCostsVisibilityEmptyState'

interface IShipmentCostsEmptyStateProps {
  isQuote: boolean
  canManageCosts: boolean
  canManageQuote: boolean
  handleCostsDialog: (
    buyer: IShipmentOrganization | IShipmentParty,
    items: ITransactionItem[]
  ) => void
  shipmentEmpty: React.ReactNode
  shipmentId: string
  openCancelModal: () => void
}

const ShipmentCostsEmptyState: FunctionComponent<IShipmentCostsEmptyStateProps> = (
  props
) => {
  const { t } = useTranslation()
  const {
    isQuote,
    canManageCosts,
    canManageQuote,
    handleCostsDialog,
    shipmentEmpty,
    shipmentId,
    openCancelModal,
  } = props

  const { visibilityOnly } = useSelector((state: IGlobalState) => ({
    visibilityOnly: state.shipmentOverview.visibility_only,
  }))

  const renderEmptyQoute = (): React.ReactNode => (
    <EmptyResults>
      <b>
        {t(
          'shipment_costs.empty_state.title',
          'Prices for this shipment are not known yet.'
        )}
      </b>
      <b>
        {t(
          'shipment_costs.empty_state.description',
          'We will inform you when our offer is complete.'
        )}
      </b>
      {canManageQuote && (
        <Box mt={1} mb={1}>
          <Button onClick={openCancelModal}>
            {t('common.buttons.cancel_quote', 'Cancel quote')}
          </Button>
        </Box>
      )}

      <Box mt={1}>
        <Trans
          i18nKey="shipment_costs.empty_state.helper_text"
          defaults="If there are any questions, contact us <0>via chat</0>."
          components={[<Link to={`/shipments/${shipmentId}/private_chat`} />]}
        />
      </Box>
    </EmptyResults>
  )

  const showEmptyStates = () => {
    if (isQuote && !canManageCosts) {
      return renderEmptyQoute()
    }
    if (canManageCosts) {
      return <AddCostsRow onClick={handleCostsDialog} />
    }
    if (!canManageCosts && visibilityOnly) {
      return <ShipmentCostsVisibilityEmptyState />
    } else {
      return shipmentEmpty
    }
  }

  return <Box data-testid="shipment-empty-costs">{showEmptyStates()}</Box>
}

export default ShipmentCostsEmptyState

import AddressBook from './AddressBook'
import AddressBookHeader from './AddressBookHeader'

export const routes = {
  connections: '/contacts/connections',
  connectionsView: '/contacts/connections/:id',
  companies: '/contacts/addresses',
  companiesNew: '/addresses/new',
  companiesEdit: '/addresses/:id/edit',
  persons: '/contacts/persons',
  personsNew: '/contacts/new',
  personsEdit: '/contacts/:id/edit',
}

const AddressBookContainer = ({
  location,
}: {
  location: { pathname: string }
}) => {
  return (
    <>
      <AddressBookHeader />
      <AddressBook pathname={location.pathname} />
    </>
  )
}

export default AddressBookContainer

import { FunctionComponent } from 'react'
import { Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ContentDropDown from 'src/components/Common/ContentDropDown'
import MilestonesProgressBar from '../MilestonesProgressBar'

interface IProps {
  shipment: IOverviewShipment
}

const TimelineQuotation: FunctionComponent<IProps> = ({ shipment }) => {
  const { t } = useTranslation()
  const fakeQuotationMilestones: IMilestone[] = [
    {
      name: t(
        'shipment.timeline.quotation.accept_or_decline',
        'Accept/Decline quote'
      ),
      reached: false,
      tasks: [],
      booking_level: false,
      container_level: false,
      code: '',
      due_date: null,
      id: 0,
      start_date: null,
      status: '',
    },
  ]
  const isMultipleQuote = shipment.shipment_quotations > 1

  const headerContent = (): React.ReactNode => (
    <div>
      {isMultipleQuote
        ? t('shipment.timeline.quotation.multiple_quotes', {
            defaultValue: '{{count}} proposed quotes',
            count: shipment.shipment_quotations,
          })
        : t('shipment.timeline.quotation.valid_until', {
            defaultValue: 'Valid until: {{val, DATE_MED}}',
            val: shipment.quote_expiration_at,
          })}
    </div>
  )

  const bodyContent = (): React.ReactNode => {
    if (isMultipleQuote) {
      return null
    }

    return (
      <>
        <MilestonesProgressBar
          milestones={fakeQuotationMilestones}
          shipmentId={shipment.id}
          forQuote={true}
        />
      </>
    )
  }

  return (
    <>
      <div className="shipment-timeline--phases-item-title heading__2 blue bold">
        {t('shipment.timeline.quotation.title', 'Quotation')}
      </div>
      <div className="shipment-timeline--phases-item-wrapper">
        <Paper variant="elevation">
          <ContentDropDown
            className="shipment-timeline--phases-item-dropdown"
            header={headerContent()}
            headerCollapsed={headerContent()}
            body={bodyContent()}
            disableCollapse={true}
            forcedOpen={!isMultipleQuote}
          />
        </Paper>
      </div>
    </>
  )
}

export default TimelineQuotation

import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import * as actions from '../actions/tasks'

declare global {
  interface ITask {
    booking_level: boolean
    code: string
    container_level: boolean
    due_date: string
    id: number
    name: string
    shipment_id: number
    status: string
    title: string
  }

  interface ITaskStatus {
    id: number
    name: string
    task_status_id: number
    shipment_id: number
    task_status: string
    task_type_id: 18
    resolved_at: string
  }

  interface ITaskStatusType {
    code: string
    id: number
    name: string
  }

  interface ITasksState {
    yourOpenTasks: ITask[] | null
    allOpenTasks: ITask[] | null
    fetchingYourOpenTasks: boolean
    fetchingAllOpenTasks: boolean
    tasksStatusTypes: ITaskStatusType[]
  }
}

export const initialTasksState = {
  yourOpenTasks: null,
  allOpenTasks: null,
  fetchingYourOpenTasks: false,
  fetchingAllOpenTasks: false,
  tasksStatusTypes: [],
}

const receiveYourOpenTasks: Reducer<ITasksState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  yourOpenTasks: action.payload,
  fetchingYourOpenTasks: false,
})

const receiveAllOpenTasks: Reducer<ITasksState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  allOpenTasks: action.payload,
  fetchingAllOpenTasks: false,
})

const fetchingYourTasks: Reducer<ITasksState, AnyAction> = (state) => ({
  ...state,
  fetchingYourOpenTasks: true,
})

const fetchingAllTasks: Reducer<ITasksState, AnyAction> = (state) => ({
  ...state,
  fetchingAllOpenTasks: true,
})

const fetchingTasksStatusTypes: Reducer<ITasksState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  tasksStatusTypes: action.payload,
})

const cleanState: Reducer<ITasksState, AnyAction> = () => initialTasksState

export default createReducer(initialTasksState, {
  [actions.GET_YOUR_OPEN_TASKS_SUCCESS]: receiveYourOpenTasks,
  [actions.GET_ALL_OPEN_TASKS_SUCCESS]: receiveAllOpenTasks,
  [actions.GET_TASKS_STATUS_TYPES_SUCCESS]: fetchingTasksStatusTypes,
  [actions.GET_YOUR_OPEN_TASKS]: fetchingYourTasks,
  [actions.GET_ALL_OPEN_TASKS]: fetchingAllTasks,
  [actions.CLEAN_TASKS_STATE]: cleanState,
})

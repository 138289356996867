// @ts-nocheck
// FIXME

import {
  FunctionComponent,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react'
import { useSelector, useDispatch, useStore } from 'react-redux'
import { keys, includes, find, forEach, each } from 'lodash'

import { promisifyAction } from 'src/utils'
import Modal from 'src/components/Common/Modal'
import Select from 'src/components/Common/Select'
import Form from 'src/components/Common/Form'
import Input from 'src/components/Common/Input'
import FormFieldSelect from 'src/components/FormFieldSelect'
import FormFieldTextBasic from 'src/components/FormFieldTextBasic'

import { useTranslation } from 'react-i18next'
import { ModalityEnum } from 'src/config/constants'
import {
  shipmentOverviewGetData,
  addressesAddAddress,
  addressesPutAddress,
  assignAddressToContainer,
  assignReferenceToContainer,
} from 'src/stores/actionCreators'
import { Button } from '@mui/material'
import addressValidators from './addressValidators'
import { IFieldValidator } from './interfaces'
import './styles.scss'

interface IValidationErrors {
  [x: string]: string | null | undefined
}

interface IAddressDetails {
  name: string
  address: string
  postal_code: string
  comment: string
  city: string
  country_id: number | null
  address_id: number | null
  preferred_modalities: ModalityEnum[]
  address_opening_hours: any[]
}

const constructAddress = (address): IAddressDetails => ({
  name: address.name,
  address: address.address,
  postal_code: address.postal_code,
  city: address.city,
  country_id: address.country.id,
  address_id: address.id,
  comment: address.comment || '',
  preferred_modalities: address.preferred_modalities,
  address_opening_hours: address.address_opening_hours || [],
})

const initialAddressDetails: IAddressDetails = {
  name: '',
  address: '',
  postal_code: '',
  comment: '',
  city: '',
  country_id: 0,
  address_id: null,
  preferred_modalities: [],
  address_opening_hours: [],
}

interface IProps {
  open: boolean
  close: () => void
  afterSave: () => void
  containerId?: number
  containerNumber?: string
  address: IInlandTransportAddress | null
  serviceType: 'pickup' | 'delivery'
  containerData: any[]
}

const AddressWindow: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()

  const companyNameUniqValidationMessage: string = t(
    'shipment_containers.validations.company_not_unique',
    'Company already exists'
  )

  const addressForm = {
    name: t('shipment_containers.addresses.company_name', 'Company name*'),
    address: t('shipment_containers.addresses.address', 'Address*'),
    postal_code: t('shipment_containers.addresses.postal_code', 'Postal code'),
    city: t('shipment_containers.addresses.city', 'City*'),
  }

  const addressNote = {
    comment: t(
      'shipment_containers.addresses.comment',
      'Additional Information'
    ),
  }
  const [addressDetails, setAddressDetails] = useState<IAddressDetails>(
    initialAddressDetails
  )
  const [reference, setReference] = useState<string | null>(null)
  const [isAddressUsed, setIsAddressUsed] = useState<boolean>(false)
  const [addressDetailsErrors, setAddressDetailsErrors] = useState<
    IValidationErrors
  >({})
  const dispatch = useDispatch()
  const store = useStore()

  const createAddressAsync = promisifyAction(dispatch, addressesAddAddress)
  const fetchOverview = promisifyAction(dispatch, shipmentOverviewGetData)
  const updateAddressAsync = promisifyAction(dispatch, addressesPutAddress)
  const assignAddressToContainerAsync = promisifyAction(
    dispatch,
    assignAddressToContainer
  )
  const assignReferenceToContainerAsync = promisifyAction(
    dispatch,
    assignReferenceToContainer
  )

  const { countries, addresses } = useSelector((globalState: IGlobalState) => ({
    countries: globalState.countries.list,
    addresses: globalState.addresses.list,
  }))

  const isCompanyNameUniq = useMemo((): boolean => {
    const companyNames: string[] = addresses.map((x) => {
      return addressDetails.address_id === x.id ? '' : x.name
    })
    return !includes(companyNames, addressDetails.name)
  }, [addresses, addressDetails.name])

  useEffect(() => {
    setAddressDetailsErrors({
      ...addressDetailsErrors,
      name: isCompanyNameUniq ? null : companyNameUniqValidationMessage,
    })
  }, [isCompanyNameUniq])

  useEffect(() => {
    if (props.address?.address_id) {
      setReference(props.address.reference)
      const currentAddress = find(addresses, {
        id: props.address.address_id,
      })
      setAddressDetails(
        currentAddress
          ? constructAddress(currentAddress)
          : initialAddressDetails
      )
      if (currentAddress) {
        setIsAddressUsed(true)
      }
    }
  }, [props.address])

  // unmount component
  useEffect(() => {
    if (!props.open) {
      setAddressDetails(initialAddressDetails)
      setAddressDetailsErrors({})
      setReference(null)
      setIsAddressUsed(false)
    }
  }, [props.open])

  const renderAddressInputs = (): React.ReactNode => {
    if (!isAddressUsed) {
      return null
    }
    return (
      <>
        {(keys(addressForm) || []).map((field: string) => (
          <Input.Text
            key={field}
            children={''}
            disabled={!!(field === 'name' && addressDetails.address_id)}
            label={addressForm[field]}
            errorMessage={addressDetailsErrors[field]}
            name={field}
            value={addressDetails[field]}
            onChange={handleAddressChange}
          />
        ))}
        {renderCountrySelect(
          t('shipment_containers.addresses.country', 'Country*'),
          'country_id',
          addressDetails.country_id,
          addressDetailsErrors.country_id,
          handleAddressChange
        )}
        {(keys(addressNote) || []).map((field: string) => (
          <Input.Text
            key={field}
            children={''}
            disabled={false}
            label={addressNote[field]}
            errorMessage={addressDetailsErrors[field]}
            name={field}
            value={addressDetails[field]}
            onChange={handleAddressChange}
          />
        ))}
      </>
    )
  }

  const renderCountrySelect = (
    label,
    field,
    value,
    errors,
    onChange
  ): React.ReactNode => {
    return (
      <FormFieldSelect
        label={label}
        fieldName={field}
        hasLimitedHeight={true}
        isSearchable={true}
        isRequired={true}
        fallbackValue={value}
        className="address-window--select-input"
        popperClassName="address-window--modal-popper"
        value={value}
        errorNotes={errors}
        onChange={onChange}
        options={countries}
        disabled={false}
      />
    )
  }

  const handleAddressChange = useCallback(
    (fieldName: string, value: string | string[] | number[]): void => {
      setReference(null)
      setAddressDetails({ ...addressDetails, [fieldName]: value })
      setAddressDetailsErrors({ ...addressDetailsErrors, [fieldName]: null })
    },
    [addressDetails]
  )
  const handleReferenceChange = useCallback(
    (fieldName: string, value: string): void => {
      setReference(value)
    },
    [addressDetails]
  )

  const handleAddressSelect = (address: IAddress | null): void => {
    if (address?.id) {
      setAddressDetails(constructAddress(address))
    } else {
      setAddressDetails(initialAddressDetails)
    }
    setAddressDetailsErrors({})
    setIsAddressUsed(true)
  }

  const checkValidations = (): boolean => {
    let isValid: boolean = true
    const addressErrorsObject: { [key: string]: string } = {}

    // Presence validations
    each(addressValidators, ({ field, validate }: IFieldValidator): void => {
      const errorMessage: string = validate(addressDetails[field])
      if (errorMessage) {
        addressErrorsObject[field] = errorMessage
        isValid = false
      }
    })

    // Uniqueness validation on company name
    if (!isCompanyNameUniq) {
      addressErrorsObject.name = companyNameUniqValidationMessage
      isValid = false
    }

    setAddressDetailsErrors(addressErrorsObject)

    return isValid
  }

  const saveAddress = async (): Promise<any> => {
    const isValid: boolean = checkValidations()
    if (!isValid) {
      return
    }
    const assignAddressObj = {
      address_id: addressDetails.address_id,
      service_type: props.serviceType,
    }

    const assignReferenceObj = {
      reference,
      service_type: props.serviceType,
    }

    if (addressDetails.address_id) {
      await updateAddressAsync(addressDetails.address_id, addressDetails)
    } else {
      await createAddressAsync(addressDetails)
      const singleAddress = store.getState().addresses.singleAddress
      assignAddressObj.address_id = singleAddress.id
    }

    forEach(
      props.containerData,
      async (container, index: number): Promise<any> => {
        await Promise.all([
          assignAddressToContainerAsync(container.id, assignAddressObj),
          assignReferenceToContainerAsync(container.id, assignReferenceObj),
        ])
        fetchOverview(container.shipment.id)

        // TODO: Vlad should fix it
        if (index === props.containerData.length - 1) {
          await props.afterSave()
        }
      }
    )
  }

  const closeWindow = () => {
    setAddressDetails(initialAddressDetails)
    props.close()
  }

  const contacts = useMemo((): any[] => {
    const list: any[] = addresses.slice() || []
    list.unshift({
      id: null,
      name: t(
        'shipment_containers.addresses.add_company_name',
        'Add new company'
      ),
    })
    return list
  }, [addresses])

  const modalTitle =
    props.serviceType === 'pickup'
      ? t(
          'shipment_containers.addresses.pickup_title',
          'Pickup information for container'
        )
      : t(
          'shipment_containers.addresses.delivery_title',
          'Delivery information for container'
        )

  return (
    <Modal.Window open={props.open} onClose={closeWindow}>
      <Modal.Title
        children={`${modalTitle} ${
          props.containerData.length ? props.containerData[0].number || '' : ''
        }`}
        onClose={closeWindow}
      />
      <Modal.Content>
        <div className="address-window">
          <Form.Column className="address-block">
            <div className="address-window--select-header">
              {props.serviceType === 'pickup'
                ? t(
                    'shipment_containers.addresses.pickup_address',
                    'Pickup address'
                  )
                : t(
                    'shipment_containers.addresses.delivery_address',
                    'Delivery address'
                  )}
            </div>
            <div className="address-window--reference">
              <div className="address-window--reference--notes">
                {props.serviceType === 'pickup'
                  ? t(
                      'shipment_containers.addresses.enter_pickup_address',
                      'Please enter your pickup address'
                    )
                  : t(
                      'shipment_containers.addresses.enter_delivery_address',
                      'Please enter your delivery address'
                    )}
              </div>
            </div>
            <Select.Single
              className={`company__select ${
                !addressDetails.address_id && isAddressUsed
                  ? 'open-and-empty'
                  : ''
              }`}
              placeholder={
                !addressDetails.address_id && isAddressUsed
                  ? t(
                      'shipment_containers.addresses.new_company',
                      'New company'
                    )
                  : t(
                      'shipment_containers.addresses.select_company',
                      'Select company'
                    )
              }
              value={addressDetails.address_id}
              onChange={handleAddressSelect}
              options={contacts}
              theme="common"
            />
            {renderAddressInputs()}
          </Form.Column>

          <Form.Column>
            <div
              className={`address-window--select-header ${
                !isAddressUsed ? 'disabled' : ''
              }`}
            >
              {props.serviceType === 'pickup'
                ? t(
                    'shipment_containers.addresses.pickup_reference',
                    'Pickup reference'
                  )
                : t(
                    'shipment_containers.addresses.delivery_reference',
                    'Delivery reference'
                  )}
            </div>
            <div className="address-window--reference">
              <div
                className={`address-window--reference--notes ${
                  !isAddressUsed ? 'disabled' : ''
                }`}
              >
                {props.serviceType === 'pickup'
                  ? t(
                      'shipment_containers.addresses.enter_pickup_reference',
                      'If applicable, please enter your pickup reference.'
                    )
                  : t(
                      'shipment_containers.addresses.enter_delivery_reference',
                      'If applicable, please enter your delivery reference.'
                    )}
              </div>
              <FormFieldTextBasic
                label={
                  props.serviceType === 'pickup'
                    ? t(
                        'shipment_containers.addresses.pickup_reference',
                        'Pickup reference'
                      )
                    : t(
                        'shipment_containers.addresses.delivery_reference',
                        'Delivery reference'
                      )
                }
                name={'reference'}
                value={reference || ''}
                disabled={!isAddressUsed}
                onChange={handleReferenceChange}
              />
            </div>
          </Form.Column>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="outlined" onClick={closeWindow}>
          {t('common.buttons.cancel', 'Cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={saveAddress}
          disabled={!isAddressUsed}
        >
          {t('common.buttons.save', 'Save')}
        </Button>
      </Modal.Actions>
    </Modal.Window>
  )
}

export default AddressWindow

import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { omit, includes, values, isNumber, omitBy, isNull } from 'lodash'
import { parse, stringify } from 'query-string'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ShipmentShareModal from 'src/components/ShipmentShareModal'
import { ModalityEnum } from 'src/config/constants'
import { OrganizationWithAvatar } from 'src/stories/OrganizationWithAvatar'
import getClassName from 'src/utils/styling'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import { InfoTooltip } from 'src/stories'
import { ISchedule } from 'src/stores/reducers/schedule'
import {
  searchQuotesBooking,
  searchQuotesBookingReset,
  searchQuotesOverview,
} from 'src/stores/actionCreators'
import PurchaseOrdersNotification from 'src/pages/SearchAndBook/PurchaseOrdersNotification'
import SABQuoteBooked from '../SABQuoteBooked'
import SABSearchItemTimeline from '../SABSearchItem/components/SABSearchItemTimeline'
import SABTermsConditions from '../SABTermsConditions'
import { QuoteTable } from './QuoteTable'

import {
  checkOnRequest,
  getGeneralTHC,
  getServiceName,
  isRolesSelected,
} from './helpers'
import { DisplayPriceTable } from './DisplayPriceTable'
import { ShipmentContainers } from './ShipmentContainers'
import './styles.scss'

const classesTooltip = {
  tooltip: 'search-book__tooltip',
  popper: 'search-book__popper',
}

const freightInfoText =
  'This is an estimated price based on historic sea freight rates. To receive the actual rate, please select "Request quote"'

const TableContainerComponent = (props) => (
  <Paper {...props} square elevation={0} />
)

const SABQuote = () => {
  const { t } = useTranslation()
  const [services, setServices] = useState({
    openShareModal: false,
    screenWidth: window.innerWidth,
    collaborators: [],
  })

  const dispatch = useDispatch()

  useEffect(() => {
    const updateWindowDimensions = () => {
      setServices((prevState) => ({
        ...prevState,
        screenWidth: window.innerWidth,
      }))
    }
    dispatch(searchQuotesBookingReset())
    const params = parse(window.location.search, {
      arrayFormat: 'bracket',
    }) as {
      [key: string]: string | []
    }

    dispatch(searchQuotesOverview(params))

    window.addEventListener('resize', updateWindowDimensions)
    return () => {
      dispatch(searchQuotesBookingReset())
      window.addEventListener('resize', updateWindowDimensions)
    }
  }, [window.location.search, window.innerWidth])

  const {
    shipmentModality,
    shipment_organizations,
    shipment_type,
    containers,
    quote,
    booking,
    loading,
    currentOrganizationId,
    currentUserId,
  } = useSelector((state: IGlobalState) => ({
    shipmentModality: state.searchOverview.modality,
    shipment_type: state.searchOverview.shipment_type,
    containers: state.searchOverview.containers,
    quote: state.searchOverview.quote,
    shipment_organizations: state.searchOverview.shipment_organizations,
    booking: state.searchBooking,
    loading: state.searchOverview.loading || state.searchBooking.loading,
    currentUserId: state.user.id,
    currentOrganizationId: state.user.organizationId,
  }))

  const bookingSubmit = () => {
    const search = window.location.search
    const query = parse(search, { arrayFormat: 'bracket' })
    const clearQuery = omitBy(query, isNull) as any
    const currentCollaborators = services.collaborators.length
      ? services.collaborators
      : shipment_organizations

    const shipmentOrganizations = (currentCollaborators || []).map(
      (collaborator: IShipmentParty) => {
        return {
          organization_id: collaborator.organization_id,
          role_ids: (collaborator.roles || []).map((role) => role.id),
        }
      }
    )
    clearQuery.shipment_organizations = shipmentOrganizations

    dispatch(searchQuotesBooking(clearQuery))
    return {}
  }

  const isLoading = loading || !quote
  if (isLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={50} style={{ marginTop: '160px' }} />
      </Box>
    )
  }
  const search = window.location.search
  const query = parse(search, { arrayFormat: 'bracket' })
  const backQuery = omit(query, ['schedule_id', 'is_cheapest', 'is_fastest'])
  const backQueryString = stringify(backQuery, { arrayFormat: 'bracket' })
  const sortOrder = {
    pickup: 1,
    origin_port: 2,
    export_customs: 3,
    freight: 4,
    import_customs: 5,
    destination_port: 6,
    delivery: 7,
    insurance: 8,
  }

  const sortedPrices = quote.price_details.services.sort(
    (x, y) => sortOrder[x.service_name] - sortOrder[y.service_name]
  )
  const isEstimated: boolean = quote.estimated_prices

  const validTo: boolean = !!quote.price_details.valid_to

  const allPriceUnknown: boolean = quote.estimated_prices
  const allPriceIncomplete: boolean = quote.incomplete_prices

  const currentCollaborators = services.collaborators.length
    ? services.collaborators
    : shipment_organizations

  const isModalityAir = shipmentModality === ModalityEnum.Air
  const schedule = quote?.schedule

  const expandRow = (item: string) => {
    const checkRequestDetails = checkOnRequest(
      item,
      !!quote?.estimated_prices,
      quote?.price_details.services
    )
    if (checkRequestDetails) {
      return () => {
        setServices((state) => ({ ...state, [item]: !state[item] }))
      }
    }
    return
  }

  const openShareWindow = () => {
    setServices((prev) => ({ ...prev, openShareModal: true }))
    return {}
  }

  const onSelectCollaborators = (collaborators) => {
    setServices((prev) => ({
      ...prev,
      openShareModal: false,
      collaborators: collaborators,
    }))
  }

  if (!booking.id) {
    return (
      <div className="search-book__quote" data-testid="search-book-quote">
        <Button
          component={Link}
          to={`/search?${backQueryString}`}
          variant="outlined"
          size="small"
          startIcon={<ArrowBackIosRoundedIcon />}
          data-testid="search-book-back-to-result-button"
        >
          {t(' search_and_book.back_to_results', 'Back to search results')}
        </Button>
        <Box mb={2} />
        <PurchaseOrdersNotification />
        <div className="search-book-timeline">
          <Paper className="search-book__info">
            <div className="search-book__info--block">
              <div className="info__left-part">
                {shipmentModality && (
                  <SABSearchItemTimeline
                    showAlert={true}
                    showCarrier={true}
                    schedule={schedule as ISchedule}
                    modality={shipmentModality}
                    pickupAddress={quote?.pickup_address}
                    deliveryAddress={quote?.delivery_address}
                    cargoClosingDate={quote.cargo_closing_date}
                  />
                )}
                <div className="info-company">
                  {validTo && !isModalityAir && (
                    <div className="button-container">
                      {query.is_cheapest && (
                        <div className="button-container__button button-container__button--orange">
                          {t(' search_and_book.cheapest', 'Cheapest')}
                        </div>
                      )}
                      {query.is_fastest && (
                        <div className="button-container__button">
                          {t(' search_and_book.fastest', 'Fastest')}
                        </div>
                      )}
                      {quote.schedule.direct_route && (
                        <>
                          <div className="button-container__button button-container__button--direct">
                            {t(
                              ' search_and_book.direct_sailing',
                              'Direct sailing'
                            )}
                          </div>
                        </>
                      )}
                      {(allPriceUnknown || allPriceIncomplete) &&
                        !!quote.price_details.services.length && (
                          <div className="button-container__button button-container__button--disabled">
                            {t(
                              ' search_and_book.price_estimation_available',
                              'Price estimation available'
                            )}
                          </div>
                        )}
                      {quote.schedule.predicted_schedule &&
                        !!quote.schedule.id &&
                        !quote.schedule.direct_route && (
                          <>
                            <div className="button-container__button button-container__button--disabled">
                              {t(
                                ' search_and_book.by_predicted_schedule',
                                'By predicted schedule'
                              )}
                            </div>
                            <div className="button-container__text">
                              {t(
                                ' search_and_book.sailing_not_known',
                                'The exact sailing is not known yet. These dates are an estimate. We will confirm the ETD ETA as soon as possible'
                              )}
                            </div>
                          </>
                        )}
                    </div>
                  )}
                  <img className="info-company__logo" alt="" />
                </div>
              </div>
            </div>
            {includes(['air', 'lcl'], shipment_type) && (
              <QuoteTable containers={containers} />
            )}
          </Paper>
          <Paper className="search-book__info shipment-organizations">
            <div className="shipment-organizations--block">
              <div className="shipment-organizations--header">
                <Typography
                  variant="h5"
                  children={t(
                    'search_and_book.collaborators.heading',
                    'All collaborators on this shipment'
                  )}
                />
              </div>
              <TableContainer component={TableContainerComponent}>
                <Table style={{ tableLayout: 'auto' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {t(
                          'shipment_documents.table.organization',
                          'Organization'
                        )}
                      </TableCell>
                      <TableCell>
                        {t('templates.shipment_role', 'Shipment role')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(currentCollaborators || []).map((org: any) => (
                      <TableRow key={org.organization_id}>
                        <TableCell>
                          <OrganizationWithAvatar
                            organization={org}
                            bold={true}
                          />
                        </TableCell>
                        <TableCell>
                          <Box display="flex" alignItems="center" height={32}>
                            {!!org.roles.length ? (
                              (org.roles || [])
                                .map((role) => {
                                  return role.role || role.name
                                })
                                .join(', ')
                            ) : (
                              <Typography children="Indicate shipment role" />
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Box m={2} textAlign="right">
              <Button variant="outlined" onClick={openShareWindow}>
                {t(
                  'search_and_book.collaborators.add_manage_collaborators',
                  'Add/manage collaborators'
                )}
              </Button>
            </Box>
          </Paper>
        </div>

        {sortedPrices.length !== 0 && (
          <Paper className="search-book__pricing mb-20">
            <Table>
              <TableHead style={{ minHeight: 81 }}>
                <TableRow>
                  <TableCell>
                    {' '}
                    {t('common.modality_sea.price', 'Price')}
                  </TableCell>
                  <TableCell>
                    {values(services).includes(true) ? 'Unit Price' : ''}
                  </TableCell>
                  <TableCell>{t('common.dollar', 'Dollar')}</TableCell>
                  <TableCell>{t('common.euro', 'Euro')}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {(sortedPrices || []).map((item: IPriceServices) => {
                  const totalUsd =
                    item.subtotal_usd && item.subtotal_usd !== '0.00'
                      ? item.subtotal_usd
                      : '-'
                  const totalEur = item.subtotal_eur
                    ? item.subtotal_eur !== '0.00'
                      ? item.subtotal_eur
                      : 'Included'
                    : 'to be requested'
                  let ready = true
                  item.items.forEach((price: IPriceItem) => {
                    if (price.price_on_request) {
                      ready = false
                    }
                  })
                  return (
                    <Fragment key={'tr-' + item.service_name}>
                      <TableRow
                        className="pricing-tr--hoverable"
                        onClick={expandRow(item.service_name)}
                      >
                        <TableCell
                          className={getClassName(
                            'icon',
                            {
                              'delivery-truck': includes(
                                ['pickup', 'delivery'],
                                item.service_name
                              ),
                              crane: includes(
                                ['origin_port', 'destination_port'],
                                item.service_name
                              ),
                              stamp: includes(
                                ['export_customs', 'import_customs'],
                                item.service_name
                              ),
                              'secure-shield':
                                item.service_name === 'insurance',
                            },
                            { orange: isEstimated && item.freight }
                          )}
                        >
                          <Typography variant="body1Strong">
                            {getServiceName(
                              quote?.estimated_prices,
                              item.service_name
                            )}
                          </Typography>
                          <span className="thc-status">
                            {getGeneralTHC(quote, item)}
                          </span>
                          {item.freight && isEstimated && (
                            <InfoTooltip
                              placement="bottom"
                              title={freightInfoText}
                              classes={classesTooltip}
                            />
                          )}
                        </TableCell>
                        <TableCell />
                        <TableCell
                          className={getClassName({
                            'dollar-sign':
                              ready &&
                              !services[item.service_name] &&
                              isNumber(totalUsd),
                            orange: isEstimated && item.freight,
                          })}
                        >
                          {!services[item.service_name] && totalUsd}
                        </TableCell>
                        <TableCell
                          className={getClassName({
                            'euro-sign':
                              !services[item.service_name] &&
                              isNumber(totalEur),
                            'price__on-request':
                              !services[item.service_name] &&
                              !isNumber(totalEur),
                            orange: isEstimated && item.freight,
                          })}
                        >
                          {!services[item.service_name] && totalEur}
                        </TableCell>
                        <TableCell>
                          {checkOnRequest(
                            item.service_name,
                            !!quote?.estimated_prices,
                            quote?.price_details.services
                          ) && (
                            <div
                              className="icon chevron"
                              style={
                                services[item.service_name]
                                  ? { transform: 'rotate(-90deg)', width: 10 }
                                  : { transform: 'rotate(90deg)', width: 10 }
                              }
                            />
                          )}
                        </TableCell>
                      </TableRow>
                      {services[item.service_name] &&
                        (item.items || []).map((subitem: IPriceItem) => (
                          <TableRow
                            key={
                              'subtr-' +
                              item.service_name +
                              '-' +
                              subitem.description
                            }
                          >
                            <TableCell style={{ paddingLeft: 52 }}>{`${
                              shipment_type === 'lcl'
                                ? subitem.quantity
                                : parseInt(subitem.quantity, 10)
                            } x ${
                              item.service_name === 'insurance'
                                ? 'Insurance'
                                : subitem.description
                            }`}</TableCell>
                            {item.service_name === 'insurance' ? (
                              <TableCell>
                                Value of goods €{query.cif_value}
                              </TableCell>
                            ) : (
                              <TableCell
                                className={getClassName('', {
                                  [subitem.currency +
                                  '-sign']: subitem.unit_price,
                                  hidden: !values(services).includes(true),
                                })}
                              >
                                {subitem.unit_price || '-'}
                              </TableCell>
                            )}
                            <TableCell
                              className={subitem.price_usd ? 'dollar-sign' : ''}
                            >
                              {subitem.price_usd || '-'}
                            </TableCell>
                            <TableCell
                              className={getClassName({
                                'euro-sign': subitem.price_eur,
                                'subprice__on-request':
                                  subitem.price_on_request,
                                'price__on-request': subitem.included,
                              })}
                            >
                              {subitem.price_eur || ''}
                              {subitem.included ? 'Included' : ''}
                              {subitem.price_on_request ? 'On Request' : ''}
                            </TableCell>
                            <TableCell />
                          </TableRow>
                        ))}
                    </Fragment>
                  )
                })}
              </TableBody>
              <TableFooter style={{ minHeight: 78 }}>
                <TableRow>
                  <TableCell colSpan={values(services).includes(true) ? 2 : 1}>
                    Total (excl. VAT
                    {!allPriceUnknown && !allPriceIncomplete
                      ? ' and pending requests'
                      : ''}
                    )
                  </TableCell>

                  <TableCell
                    className={getClassName({
                      'dollar-sign': !allPriceUnknown && !allPriceIncomplete,
                    })}
                  >
                    {!allPriceUnknown && !allPriceIncomplete
                      ? quote.total_price_usd
                      : '-'}
                  </TableCell>
                  <TableCell
                    className={getClassName({
                      'euro-sign': !allPriceUnknown && !allPriceIncomplete,
                    })}
                  >
                    {!allPriceUnknown && !allPriceIncomplete
                      ? quote.total_price_eur
                      : 'to be calculated'}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableFooter>
            </Table>
          </Paper>
        )}
        {sortedPrices.length === 0 && (
          <Paper className="search-book__pricing mb-20">
            <Table>
              <TableHead style={{ minHeight: 81 }}>
                <TableRow>
                  <TableCell>
                    {' '}
                    {t('shipment_costs.edit_costs.fields.unit_price', 'Price')}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {(quote.price_details.requested_services || []).map(
                  (item, index) => (
                    <TableRow className="pricing-tr--no-price" key={index}>
                      <DisplayPriceTable item={item} />
                      <TableCell className="price__on-request">
                        {t(
                          'search_and_book.collaborators.to_be_requested',
                          'To be requested'
                        )}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </Paper>
        )}
        <SABTermsConditions
          quote={quote as ISearchQuote}
          currentUserId={currentUserId as number}
          collaboratorsRolesSet={isRolesSelected(currentCollaborators)}
          collaboratorsWarningAction={openShareWindow}
          bookingSubmit={bookingSubmit}
        />
        <ShipmentShareModal
          collaborators={currentCollaborators}
          showPublicLink={false}
          open={services.openShareModal}
          onClose={() => {
            setServices((prev) => ({ ...prev, openShareModal: false }))
          }}
          onSaveAndFetch={onSelectCollaborators}
          buttonLoading={false}
          token={''}
          allowManageable={true}
          headerText={t(
            'search_and_book.collaborators.add_manage_collaborators',
            'Add/manage collaborators'
          )}
          isScheduleExist={!!quote.schedule.id}
          sellerOrganization={quote.seller_organization}
        />
      </div>
    )
  } else {
    return (
      <SABQuoteBooked
        modality={shipmentModality as ModalityEnum}
        shipment_type={shipment_type}
        containers={
          <ShipmentContainers
            containers={containers}
            shipmentType={shipment_type}
          />
        }
        containersArray={containers}
        booking={booking}
        quote={quote}
        search={search}
        currentUserId={currentUserId as number}
        currentOrganizationId={currentOrganizationId}
      />
    )
  }
}

export default SABQuote

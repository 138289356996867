import { EmptyState } from 'src/stories'
import WavesIcon from '@mui/icons-material/Waves'

const DashboardEmptyLine: React.FC = () => (
  <EmptyState
    badgeColor="primary"
    badgeIcon={WavesIcon}
    title="The horizon is clear"
    button={{ text: 'Search & book', link: '/search' }}
    description="There are no shipments to show. You can create one in the Search & book page or enjoy the silence!"
  />
)

export default DashboardEmptyLine

import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
  navItems,
  createMenuLinkItems,
  userMenuLinkItems,
} from 'src/components/TopNavigation/TopNavigation.constants'
import { MenuItemLinkProps } from 'src/components/TopNavigation/TopNavigation.props'

export default (t) => {
  const location = useLocation()

  const linksTitles = [
    ...navItems(t),
    ...userMenuLinkItems(t),
    ...createMenuLinkItems(t),
  ].reduce((filtered: MenuItemLinkProps[], item: MenuItemLinkProps) => {
    if (!item.children) {
      filtered.push(item)
    }
    if (item.children) {
      filtered = [...filtered, ...item.children]
    }
    return filtered
  }, [])

  useEffect(() => {
    const current = linksTitles.find((nav) =>
      location.pathname.includes(nav?.to ?? '')
    )
    document.title = current?.label ?? 'Shypple'
  }, [location.pathname])
}

import { Component } from 'react'
import { ActionCableConsumer } from 'react-actioncable-provider'

interface IProps {
  onReceived: (receivedMessage: any) => void
  forwardRef: (elem: ActionCableConsumer) => void
}

class UserChannelConsumerWrapper extends Component<IProps, {}> {
  public shouldComponentUpdate() {
    return false
  }

  public render() {
    return (
      <ActionCableConsumer
        ref={this.props.forwardRef}
        channel={{ channel: 'UserChannel' }}
        onReceived={this.props.onReceived}
      />
    )
  }
}

export default UserChannelConsumerWrapper

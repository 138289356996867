import { get, capitalize } from 'lodash'
import { Grid, InputLabel } from '@mui/material'
import { useSelector } from 'react-redux'
import { ShipmentLoadTypeIcon } from 'src/stories/MUI/ShipmentContent/components/ShipmentLoadTypeIcon'
import { ShipmentModalityIcon } from 'src/stories/MUI/ShipmentContent/components/ShipmentModalityIcon'
import { OrganizationRoleEnum } from 'src/config/constants'
import { useTranslation } from 'react-i18next'
import { useFormContext } from '../../FormContext'
import Input from '../FormElements/Input'
import Skeleton from '../Skeleton'
import BookingParties from '../Parts/BookingParties/BookingParties'
import ShipmentRole from '../Parts/ShipmentRole'
import SizedBox from '../Parts/Box'
import FieldValueText from '../Parts/FieldValueText'

const TemplatesFormInitialDetailsView = () => {
  const { isLoading, formState } = useFormContext()
  const modality = get(formState, 'modality', '')
  const loadType = get(formState, 'loadType', '')
  const customer = get(formState, 'customer', null)
  const { t } = useTranslation()

  const { user } = useSelector((state: IGlobalState) => ({
    user: state.user,
  }))

  const isUserOrganizationCustomer =
    user.organizationRole === OrganizationRoleEnum.Customer

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Skeleton isLoading={isLoading}>
        <Input
          fieldPath="name"
          required={true}
          label={t('templates.name', 'Name')}
          columnSize={2.5}
        />
        <ShipmentRole
          fieldPath="shipmentRole"
          label={t('templates.shipment_role', 'Shipment role')}
          columnSize={1.5}
          editable={false}
        />
        {!isUserOrganizationCustomer && (
          <SizedBox columnSize={2}>
            <InputLabel>{t('templates.customer', 'Customer')}</InputLabel>
            <FieldValueText text={customer ? customer.label : '-'} />
          </SizedBox>
        )}
        <SizedBox columnSize={1.5}>
          <InputLabel>
            {t('templates.shipment_details.modality', 'Modality')}
          </InputLabel>
          <FieldValueText
            icon={<ShipmentModalityIcon modality={modality} />}
            text={capitalize(modality)}
          />
        </SizedBox>
        <SizedBox columnSize={1.5}>
          <InputLabel>
            {t('templates.shipment_details.load_type', 'Load type')}
          </InputLabel>
          <FieldValueText
            icon={<ShipmentLoadTypeIcon loadType={loadType} />}
            text={loadType.toUpperCase()}
          />
        </SizedBox>
        <BookingParties columnSize={3} editable={false} />
      </Skeleton>
    </Grid>
  )
}

export default TemplatesFormInitialDetailsView

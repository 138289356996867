import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { useForm, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { stringify } from 'query-string'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import FormLabel from 'src/stories/Lab/FormLabel'
import { useTranslation } from 'react-i18next'
import DatePicker from 'src/stories/DatePicker'
import { ShipmentCostsQuoteRequestRateMatchDialogContentProps } from './ShipmentCostsQuoteRequestRateMatch.props'
import { currencies } from './ShipmentCostsQuoteRequestRateMatch.constants'

const defaultValues: ShipmentCostsQuoteRequestRateMatchDialogContentProps = {
  rate: null,
  carrier: '',
  validity: '',
  forwarder: '',
  currency: currencies[0],
}

const ShipmentCostsQuoteRequestRateMatchDialogContent = ({
  onClose,
  shipmentId,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues,
  })

  const onSubmit = (
    data: ShipmentCostsQuoteRequestRateMatchDialogContentProps
  ) => {
    history.push({
      pathname: `/shipments/${shipmentId}/chats`,
      search: stringify(data),
    })
  }

  const handleDialogClose = () => {
    reset()
    onClose()
  }

  return (
    <>
      <DialogTitle>
        {t('shipment_costs.request_rate_match.title', 'Request rate match')}
      </DialogTitle>
      <DialogContent data-testid="quote-request-match-rate-dialog-content">
        <DialogContentText>
          {t(
            'shipment_costs.request_rate_match.description',
            'Did you receive a better rate elsewhere? We would like to think along with you! Please enter the information below and we will get back to you shortly!'
          )}
        </DialogContentText>
        <Grid container spacing={2} my={2}>
          <Grid item xs={3}>
            <FormLabel
              label={t('common.forms.fields.carrier.label', 'Carrier')}
            />
            <TextField
              fullWidth
              autoFocus
              inputProps={{
                'data-testid': 'quote-request-match-rate-carrier',
              }}
              {...register('carrier', { required: true })}
            />
          </Grid>
          <Grid item xs={3}>
            <FormLabel
              label={t('common.forms.fields.forwarder.label', 'Forwarder')}
            />
            <TextField
              fullWidth
              inputProps={{
                'data-testid': 'quote-request-match-rate-forwarder',
              }}
              {...register('forwarder', { required: true })}
            />
          </Grid>
          <Grid item xs={2}>
            <FormLabel
              label={t('common.forms.fields.target_rate.label', 'Target rate')}
            />
            <TextField
              fullWidth
              type="number"
              inputProps={{
                'data-testid': 'quote-request-match-rate-target-rate',
              }}
              {...register('rate', { required: true })}
            />
          </Grid>
          <Grid item xs={2}>
            <FormLabel
              label={t('common.forms.fields.currency.label', 'Currency')}
            />
            <Controller
              name="currency"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange } }) => {
                return (
                  <SingleSelect
                    fullWidth
                    mandatory={true}
                    autoWidth={false}
                    defaultValue={defaultValues.currency}
                    data-testid="quote-request-match-rate-currency"
                    onChange={(e) => {
                      onChange(e.target.value as string)
                    }}
                    options={currencies.map((currency: string) => {
                      return {
                        id: currency,
                        label: currency.toUpperCase(),
                      }
                    })}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <FormLabel
              label={t('common.forms.fields.valid_until.label', 'Valid till')}
            />
            <Controller
              name="validity"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => {
                return (
                  <DatePicker
                    data-testid="datepicker-input"
                    value={value}
                    onChange={(value) => onChange(value?.toISODate())}
                    clearable={false}
                  />
                )
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          onClick={handleDialogClose}
          data-testid="quote-request-match-rate-dialog-cancel-button"
        >
          {t('common.buttons.close', 'Close')}
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={!isDirty || !isValid}
          data-testid="quote-request-match-rate-dialog-submit-button"
        >
          {t('common.buttons.submit', 'Submit')}
        </Button>
      </DialogActions>
    </>
  )
}

export default ShipmentCostsQuoteRequestRateMatchDialogContent

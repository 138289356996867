import { Box, Typography } from '@mui/material'
import { compact } from 'lodash'
import { TruncatedTextWithTooltip } from 'src/stories'
import ProgressWithDays from 'src/components/ProgressWithDays'
import DateTime from 'src/utils/DateTime'

const progressColors = {
  in_transit: ['dblue', 'lblue'],
  delivered: ['green', 'green'],
  confirmed_bookings: ['lblue', 'lblue'],
  not_confirmed_bookings: ['grey', 'grey'],
  supplier_initiated_bookings: ['grey', 'grey'],
}

export const dashboardLine = (
  shipment,
  subtitle?: string | JSX.Element,
  info?: JSX.Element
) => {
  const dateEnd = shipment.estimated_arrival
    ? DateTime.format(new Date(shipment.estimated_arrival), 'dd MMM')
    : ''
  const dateStart = shipment.estimated_departure
    ? DateTime.format(new Date(shipment.estimated_departure), 'dd MMM')
    : ''
  const group = progressColors[shipment.status_group] || ['grey', 'grey']

  const departureIcon = () => {
    if (shipment.modality === 'air') {
      return 'arrive '
    } else if (shipment.modality === 'rail') {
      return shipment.modality
    } else {
      return 'loading '
    }
  }

  const destinationIcon = () => {
    if (shipment.modality === 'air') {
      return 'depart '
    } else if (shipment.modality === 'rail') {
      return shipment.modality
    } else {
      return 'loading '
    }
  }

  return {
    id: shipment.id,
    number: (
      <Box
        sx={{
          display: 'flex',
          width: 'fit-content',
          '@media (min-width: 600px) and (max-width: 1600px)': {
            paddingTop: 1,
            flexDirection: 'column',
          },
        }}
      >
        <Box
          className="dashboard-page__shipment-line__info__main"
          sx={{
            display: 'flex',
            '@media (min-width: 600px) and (max-width: 1200px)': {
              flexDirection: 'column',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              '@media (min-width: 600px) and (max-width: 900px)': {
                flexDirection: 'column',
              },
            }}
            className="dashboard-page__shipment-line__info__main--title"
          >
            <Typography
              variant="body1Strong"
              children={compact([
                shipment.reference_number,
                shipment.shared_reference,
              ]).join(' | ')}
            />
          </Box>
          <div>
            <Typography variant="body1" children={shipment.title} />
          </div>
        </Box>
      </Box>
    ),
    timeline: (
      <div className="dashboard-page__shipment-line--ports">
        <div className="dashboard-page__shipment-line--ports--start">
          <div>
            <Typography variant="body1Strong" children={dateStart} />
          </div>
          <div className="dashboard-page__shipment-line--ports-port">
            <TruncatedTextWithTooltip text={shipment.loading_port} />
          </div>
        </div>
        <div className="dashboard-page__shipment-line--ports-progress">
          <ProgressWithDays
            departureIcon={departureIcon()}
            destinationIcon={destinationIcon()}
            primaryColor={group[0]}
            secondaryColor={group[1]}
            progress={shipment.progress_percent}
          />
        </div>
        <div className="dashboard-page__shipment-line--ports--end">
          <div>
            <Typography variant="body1Strong" children={dateEnd} />
          </div>
          <div className="dashboard-page__shipment-line--ports-port">
            <TruncatedTextWithTooltip text={shipment.discharge_port} />
          </div>
        </div>
      </div>
    ),

    end: (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="dashboard-page__shipment-line--divider">
          <div className="dashboard-page__shipment-line--divider-line" />
          <div className="dashboard-page__shipment-line--divider-icon">
            <i className="icon info" />
          </div>
          <div className="dashboard-page__shipment-line--divider-line" />
        </div>
        <div className="dashboard-page__shipment-line--extras">
          <Typography
            variant="body1"
            component="div"
            children={
              <div>
                {info && info}
                <div className="subtitle">{subtitle}</div>
              </div>
            }
          />
        </div>
        <div className="dashboard-page__shipment-line--icon-container mr-15">
          {shipment.flagged ? <i className="icon flag red" /> : ''}
        </div>
      </div>
    ),
  }
}

import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { saveRating, saveComment } from 'src/stores/actionCreators/csat'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ButtonBase from '@mui/material/ButtonBase'
import { makeStyles, withStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import InputBase from '@mui/material/InputBase'

import redImageUrl from 'src/assets/images/csat/red.svg'
import orangeImageUrl from 'src/assets/images/csat/orange.svg'
import yellowImageUrl from 'src/assets/images/csat/yellow.svg'
import greenImageUrl from 'src/assets/images/csat/green.svg'
import blueImageUrl from 'src/assets/images/csat/blue.svg'

const CustomInputBase = withStyles((theme) => ({
  disabled: {
    pointerEvents: 'none',
  },
  input: {
    borderRadius: 3,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[300]}`,
    fontSize: 16,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'background-color']),
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover': {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.light,
    },
  },
}))(InputBase)

const emojiStyles = makeStyles((theme) => ({
  emoji: ({
    selected,
    active,
  }: {
    selected: string | null
    active: boolean
  }) => ({
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    borderRadius: '3px',
    border: `1px solid ${theme.palette.secondary.main}`,
    opacity: selected === null ? 1 : active ? 1 : 0.6,
    height: 24,
    boxShadow: active ? `0px 0px 4px ${theme.palette.secondary.main}` : 'none',
  }),
}))

const useStyles = makeStyles((theme) => ({
  csatComment: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 3,
    marginLeft: '60px',
    display: 'inline-block',
  },
}))

const mapRatingToImage = {
  1: redImageUrl,
  2: orangeImageUrl,
  3: yellowImageUrl,
  4: greenImageUrl,
  5: blueImageUrl,
}

const Emoji = ({ rating, selected, active, onClick }) => {
  const classes = emojiStyles({ active, selected })

  return (
    <ButtonBase onClick={onClick}>
      <Box className={classes.emoji}>
        <img
          width="24px"
          height="24px"
          src={mapRatingToImage[rating]}
          alt={`Rate ${rating}`}
        />
      </Box>
    </ButtonBase>
  )
}

const CSAT = (props: ICsat & { shipmentId: number; userId: number }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [comment, setComment] = useState<string | null>(props.comment || '')
  const [selectedRating, setSelectedRating] = useState<number | null>(
    props.score
  )

  const saveCsatRating = (rating: number) => {
    setSelectedRating(rating)
    dispatch(
      saveRating({
        shipment_id: props.shipmentId,
        user_id: props.userId,
        score: rating,
      })
    )
  }

  const saveCsatComment = () => {
    dispatch(saveComment({ id: props.id, comment }))
  }

  return (
    <Box display="inline-flex" flexDirection="column">
      <Box className={classes.csatComment}>
        <Typography gutterBottom>
          <b>How did you experience your booking with Shypple?</b>
        </Typography>
        <Typography gutterBottom>
          We are really curious to know your opinion and see where we can
          improve our services.
        </Typography>
        <Box display="flex" mt={2}>
          {Object.keys(mapRatingToImage).map((rating) => {
            return (
              <Box
                mr={1}
                key={rating}
                style={{ pointerEvents: selectedRating ? 'none' : 'inherit' }}
              >
                <Emoji
                  rating={rating}
                  selected={selectedRating}
                  active={parseInt(rating) === selectedRating}
                  onClick={() => saveCsatRating(parseInt(rating))}
                />
              </Box>
            )
          })}
        </Box>
      </Box>
      {selectedRating && (
        <Box mt={2} className={classes.csatComment}>
          <Box mb={2}>
            <Typography>
              Thanks for your feedback! If you have additional notes, please
              shoot us a message.
            </Typography>
          </Box>
          <Box display="flex">
            <CustomInputBase
              fullWidth
              placeholder="Enter your additional message"
              value={comment}
              onChange={(event) => setComment(event.target.value)}
              disabled={props.comment !== null}
            />
            <Box mr={1} />
            <Button
              disabled={props.comment !== null}
              disableElevation
              variant="contained"
              color="primary"
              onClick={saveCsatComment}
            >
              Send
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default CSAT

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Pill from 'src/stories/MUI/Pill'
import { DemurrageAndDetentionAddAgreementSectionProps } from './DemurrageAndDetentionContent.props'

const DemurrageAndDetentionContentAddAgreementSection: React.FC<DemurrageAndDetentionAddAgreementSectionProps> = ({
  step,
  title,
  renderFooter,
  description,
}) => {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={4}
      lg={4}
      xl={4}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Pill children={<Typography variant="h1" children={step} />} />
        <Typography variant="h3" children={title} mt={3} />
        <Typography
          mt={0.5}
          variant="body1"
          children={description}
          sx={{ whiteSpace: 'pre-line' }}
        />
      </Box>
      {renderFooter && <Box mt={3}>{renderFooter()}</Box>}
    </Grid>
  )
}

export default DemurrageAndDetentionContentAddAgreementSection

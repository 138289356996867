import { useMemo, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import TasksContent from 'src/components/Tasks/TasksContent'
import NotificationsContent from 'src/components/Notifications/NotificationsContent'
import { StyledDrawer } from 'src/components/TopNavigation/TopNavigation.styles'
import { MenuItemLinkProps } from 'src/components/TopNavigation/TopNavigation.props'
import { drawerWidth } from 'src/components/TopNavigation/TopNavigation.constants'
import TopNavigationMenuItem from 'src/components/TopNavigation/components/TopNavigationMenuItem'
import TopNavigationDrawerSearch from './TopNavigationDrawerSearch'

interface TopNavigationDrawerProps {
  open: boolean
  onClick: () => void
  navItems: MenuItemLinkProps[]
}

const TopNavigationDrawer: React.FC<TopNavigationDrawerProps> = ({
  open,
  onClick,
  navItems,
}) => {
  const { notificationList } = useSelector(
    (state: IGlobalState) => ({
      notificationList: state.navigationNotifications.list,
    }),
    shallowEqual
  )

  const [tasksOpen, setTasksOpen] = useState<boolean>(false)
  const [notificationsOpen, setNotificationsOpen] = useState<boolean>(false)

  const onTasksClick = () => {
    setTasksOpen((prevState) => !prevState)
    if (notificationsOpen) {
      setNotificationsOpen(false)
    }
  }

  const onNotificationsClick = () => {
    setNotificationsOpen((prevState) => !prevState)
    if (tasksOpen) {
      setTasksOpen(false)
    }
  }

  const onDrawerClose = () => {
    onClick()
    setTasksOpen(false)
    setNotificationsOpen(false)
  }

  const drawerLinkItems: MenuItemLinkProps[] = useMemo(
    () =>
      navItems.reduce(
        (filtered: MenuItemLinkProps[], item: MenuItemLinkProps) => {
          if (!item.children) {
            filtered.push(item)
          }
          if (item.children) {
            filtered = [...filtered, ...item.children]
          }
          return filtered
        },
        []
      ),
    [navItems]
  )

  return (
    <>
      <Box component="nav">
        <StyledDrawer
          open={open}
          variant="temporary"
          onClose={onDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{ sx: { overflow: 'hidden' } }}
        >
          <TopNavigationDrawerSearch
            onSearchDone={onClick}
            tasksOpen={tasksOpen}
            notificationsOpen={notificationsOpen}
            onTasksClick={onTasksClick}
            onNotificationsClick={onNotificationsClick}
          />
          <Divider sx={{ mt: 1.5 }} />
          {tasksOpen && (
            <TasksContent
              isOpen={tasksOpen}
              contentWidth={drawerWidth}
              onClickTask={onDrawerClose}
            />
          )}
          {notificationsOpen && (
            <Box overflow="auto">
              <NotificationsContent
                contentHeigh="auto"
                list={notificationList}
                contentWidth={drawerWidth}
                onClickNotification={onDrawerClose}
              />
            </Box>
          )}
          {!tasksOpen && !notificationsOpen && (
            <List sx={{ overflow: 'auto' }}>
              {drawerLinkItems.map((item) => (
                <TopNavigationMenuItem
                  item={item}
                  key={item.key}
                  onClick={onDrawerClose}
                  sx={{ mb: 1, px: 1.75 }}
                />
              ))}
            </List>
          )}
        </StyledDrawer>
      </Box>
    </>
  )
}

export default TopNavigationDrawer

import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { promisifyAction } from 'src/utils'
import { capitalize, Box } from '@mui/material'
import { useGetAddressesAsync } from 'src/services/Api/common'
import { IAddressesResponse } from 'src/@types/endpoints/common'
import { updatePickupsAndDeliveriesItem } from 'src/stores/actionCreators'
import { showNotification } from 'src/stores/actionCreators/notifications'
import InlandTransportRowTransportSearch from './InlandTransportRowTransportSearch'
import {
  AutocompleteOptionPropsOptionProps,
  AutocompleteOptionPropsPayloadProps,
  InlandTransportRowTransportAddressProps,
} from './InlandTransportRow.props'
import {
  getInlandTransportAddresses,
  getAutocompleteSelectOptions,
} from './InlandTransportRow.utils'

const InlandTransportRowTransportAddress: React.FC<InlandTransportRowTransportAddressProps> = ({
  rowId,
  service,
  addresses,
  updatable,
  collaborators = [],
  inline,
  onSelectValue,
  inputWidth,
  disabled,
}) => {
  const dispatch = useDispatch()
  const transportAddress = (addresses || []).find((x) => x.pickup_delivery)
  const hasTransportAddress = transportAddress && transportAddress.name
  const defaultValue:
    | AutocompleteOptionPropsOptionProps
    | undefined = hasTransportAddress
    ? { value: transportAddress.id, label: transportAddress.name }
    : undefined

  const [defaultOptions, setDefaultOptions] = useState(
    defaultValue ? [defaultValue] : []
  )

  const [selected, setSelected] = useState(defaultValue)
  const [options, setOptions] = useState(defaultOptions)

  useEffect(() => {
    if (transportAddress && !!transportAddress.name) {
      setSelected({ value: transportAddress.id, label: transportAddress.name })
    }
  }, [transportAddress])

  const {
    fetchAsync: getAddresses,
    isFetching,
    isFetched,
  } = useGetAddressesAsync()

  const updateTransportAddressAsync = promisifyAction(
    dispatch,
    updatePickupsAndDeliveriesItem
  )

  const handleGetAddressesSuccess = (res: IAddressesResponse | undefined) => {
    const fetchedOptions = res
      ? res.data.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      : null

    const options = getAutocompleteSelectOptions(
      selected,
      defaultOptions,
      fetchedOptions
    )

    if (options) {
      setOptions(options)
    }
  }

  const getAddressesAsync = async (search: string) => {
    const hasOption = options.find((option) => option.label === search)
    if (search.length === 0 || !!hasOption) {
      return
    }
    const organizationId =
      collaborators.find(
        (collaborator: ICollaborator) =>
          collaborator.organization_role_code === 'customer'
      )?.organization_id ?? null

    getAddresses({
      search,
      page_size: 1000,
      organization_id: organizationId,
    }).then(handleGetAddressesSuccess)
  }

  const updateTransportAddress = (
    inlandTransportAddresses: AutocompleteOptionPropsPayloadProps[],
    tempSelection: AutocompleteOptionPropsOptionProps | undefined
  ) => {
    updateTransportAddressAsync(rowId, {
      inland_transport_addresses_attributes: inlandTransportAddresses,
    })
      .then(() => {
        dispatch(
          showNotification({
            severity: 'success',
            message: `${capitalize(service)} address updated`,
          })
        )
      })
      .catch(() => {
        setSelected(tempSelection)
      })
  }

  const handleSelectChange = (
    newSelection: AutocompleteOptionPropsOptionProps | null
  ) => {
    if (!updatable) {
      return
    }
    const tempSelection = selected
    setSelected(newSelection === null ? undefined : newSelection)
    setDefaultOptions(newSelection === null ? [] : [newSelection])

    const inlandTransportAddresses: AutocompleteOptionPropsPayloadProps[] = getInlandTransportAddresses(
      addresses,
      newSelection,
      service
    )

    if (inline && onSelectValue) {
      onSelectValue(newSelection === null ? undefined : newSelection)
    } else {
      updateTransportAddress(inlandTransportAddresses, tempSelection)
    }
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      height="100%"
      data-testid="inland-transport-address"
    >
      {updatable && (
        <InlandTransportRowTransportSearch
          {...{
            options,
            selected,
            isFetched,
            isFetching,
            onChange: handleSelectChange,
            onInputChange: getAddressesAsync,
            inputPlaceholder: 'Search addresses',
            inputWidth: inputWidth,
            inline: inline,
            disabled: disabled,
          }}
        />
      )}
      {!updatable && <Box>{selected?.label ?? '-'}</Box>}
    </Box>
  )
}

export default InlandTransportRowTransportAddress

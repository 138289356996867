import { FunctionComponent } from 'react'
import MenuItem from '@mui/material/MenuItem'
import Input from '@mui/material/Input'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Chip from '@mui/material/Chip'

import { find } from 'lodash'

import './styles.scss'

interface IOption {
  label: string
  value: any
}

interface IProps {
  className?: string
  placeholder?: string
  disabled?: boolean
  label?: string
  value?: string
  name?: string
  theme?: string
  showError?: boolean
  errorMessage?: string | null
  onChange?: (fieldName: string | undefined, value: string[] | number[]) => void
  options: IOption[]
  values: any[]
}

const Multiselect: FunctionComponent<IProps> = (props) => {
  const handleChange = (event: SelectChangeEvent<any[]>): void => {
    if (props.onChange) {
      props.onChange(props.name, event.target.value as string[])
    }
  }

  const renderValues = (values): React.ReactNode => (
    <div>
      {(values as string[]).map((value) => (
        <Chip key={value} label={labelOf(value)} />
      ))}
    </div>
  )

  const labelOf = (value: string): string => {
    const option: IOption | undefined = find(
      props.options,
      (x) => x.value === value
    )
    return option ? option.label : ''
  }

  return (
    <div className={props.theme}>
      <Select
        id="shypple-multiselect"
        classes={{ select: 'shypple-multiselect' }}
        multiple={true}
        value={props.values}
        onChange={handleChange}
        input={
          <Input
            id="shypple-multiselect-chip"
            classes={{
              underline: 'shypple-multiselect__underline',
              root: 'shypple-multiselect__root',
            }}
          />
        }
        MenuProps={{ classes: { paper: 'shypple-multiselect__paper' } }}
        inputProps={{ className: 'shypple-multiselec__input' }}
        renderValue={renderValues}
      >
        {props.options.map((options) => (
          <MenuItem key={options.value} value={options.value}>
            {options.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

Multiselect.defaultProps = {
  disabled: false,
  showError: false,
  className: '',
  onChange: (e) => undefined,
  options: [],
  values: [],
}

export default Multiselect

import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/addressBankDetails'
import * as actionCreators from '../../actionCreators/addressBankDetails'
import {
  simpleGetAndResolve,
  simplePutAndResolve,
  simplePostAndResolve,
} from '../factories'

const createBankDetailsURL = () => 'api/v1/address_bank_details/'
const singleBankDetailsURL = (action) =>
  `api/v1/address_bank_details/${action.id}`

const getSingleBankDetails = simpleGetAndResolve(
  singleBankDetailsURL,
  actionCreators.getBankDetailsSuccess,
  (response) => response.data.address_bank_detail
)

const updateBankDetails = simplePutAndResolve(
  singleBankDetailsURL,
  actionCreators.updateBankDetailsSuccess,
  (response) => response.data.address_bank_detail
)

const createBankDetails = simplePostAndResolve(
  createBankDetailsURL,
  actionCreators.createBankDetailsSuccess,
  (response) => response.data.address_bank_detail
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.GET_BANK_DETAIL, getSingleBankDetails)
  yield takeEvery(actions.CREATE_BANK_DETAIL, createBankDetails)
  yield takeEvery(actions.UPDATE_BANK_DETAIL, updateBankDetails)
}

import Typography, { TypographyProps } from '@mui/material/Typography'

interface IProps extends TypographyProps {
  text: string
}

export const MenuItemText: React.FC<IProps> = (props) => {
  const { text, ...otherProps } = props
  return (
    <Typography
      noWrap
      color="inherit"
      component="span"
      variant="body1"
      {...otherProps}
      children={text}
    />
  )
}

import { AutocompleteOptionProps } from 'src/stories/Lab/Autocomplete/Autocomplete.props'
import AutocompleteCommon from 'src/stories/Lab/Autocomplete/AutocompleteCommon'
import FormLabel from 'src/stories/Lab/FormLabel'
import { useFilter } from '../hooks'
import { FilterContext, FilterInputsCommonPropsType } from '../types'
import { extractAnOptionFromFilterOptions } from '../utils'

type FormElementProps = Omit<FilterInputsCommonPropsType, 'placeholder'> & {
  options?: AutocompleteOptionProps[]
  placeholder?: FilterInputsCommonPropsType['placeholder']
  optionsExtractor?: (
    filterOptions: FilterContext['filterOptions'],
    name: string
  ) => AutocompleteOptionProps[]
}

export const FilterMultipleAutoComplete = ({
  name,
  label,
  placeholder,
  optionsExtractor = extractAnOptionFromFilterOptions,
  filterOptionKey = '',
  ...props
}: FormElementProps) => {
  const { filterOptions, onInputChange, filters } = useFilter()

  const options: AutocompleteOptionProps[] =
    props.options || optionsExtractor?.(filterOptions, filterOptionKey) || []

  const selectedOptions = options.filter(({ id }) =>
    filters[name]?.includes(id)
  )

  const onReset = () => onInputChange(name, [])

  const onChange = (updatedValue: AutocompleteOptionProps[]) =>
    onInputChange(
      name,
      updatedValue.map(({ id }) => id)
    )

  return (
    <>
      <FormLabel label={label} count={selectedOptions.length} />
      <AutocompleteCommon
        options={options}
        disablePortal={true}
        value={selectedOptions}
        onReset={onReset}
        onChange={onChange}
        placeholder={placeholder}
        data-testid={props['data-testid']}
      />
    </>
  )
}

import { useContext } from 'react'
import { FilterContext } from './types'
import { filterIsUnchanged } from './utils'
import { filterContext } from '.'

export const useFilter = (): FilterContext => {
  const context = useContext(filterContext)
  if (!context) {
    throw new Error('This component must be used within <Filters> component.')
  }
  return context
}

export const useActionButtonIsActive = (
  buttonName: 'clear' | 'update' | 'save'
) => {
  const {
    filters,
    quickFilter,
    initialFilters,
    filterId,
    defaultFiltersKeys,
  } = useFilter()
  const quickFilterIsActive = !!filterId

  const {
    filterIsSameAsInitialFilter,
    filterIsSameAsQuickFilter,
  } = filterIsUnchanged(
    filters,
    quickFilter,
    initialFilters,
    defaultFiltersKeys
  )

  if (quickFilterIsActive && (buttonName === 'update' || buttonName === 'save'))
    return !filterIsSameAsQuickFilter && !filterIsSameAsInitialFilter

  if (!quickFilterIsActive && buttonName === 'update') return false

  return !filterIsSameAsInitialFilter
}

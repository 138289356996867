import { shipmentsOverviewGetPdfFiles } from 'src/stores/actionCreators'
import { FC, useMemo } from 'react'
import { permissionTo, promisifyAction } from 'src/utils'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material'
import { getInternalReference } from 'src/components/ShipmentReferencesModal'
import DateTime from 'src/utils/DateTime'
import { useTranslation } from 'react-i18next'

interface IProps {
  shipmentId: string
}
export const CreateBookingOverviewButton: FC<IProps> = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const getDownloadFile = promisifyAction(
    dispatch,
    shipmentsOverviewGetPdfFiles
  )
  const { shipment, currentUser } = useSelector((state: IGlobalState) => ({
    shipment: state.shipmentOverview,
    currentUser: state.user,
  }))

  const internalReference: string = useMemo(
    () => getInternalReference(shipment, currentUser),
    [shipment]
  )

  const handleClick = async () => {
    const link = document.createElement('a')
    const file = await getDownloadFile(props.shipmentId)
    const url = URL.createObjectURL(
      new Blob([file], { type: 'application/pdf' })
    )
    link.href = url
    link.setAttribute(
      'download',
      `booking_overview_${internalReference}_${DateTime.format(
        new Date(),
        'dd-MM-yyyy'
      )}.pdf`
    )
    document.body.appendChild(link)
    link.click()
    link.parentElement?.removeChild(link)
    URL.revokeObjectURL(url)
  }

  return (
    <Button
      variant="outlined"
      onClick={handleClick}
      disabled={!permissionTo('shipments.bookings.documents.manage')}
      className="mb-8"
    >
      {t('common.buttons.create_draft_bl', 'Create draft BL')}
    </Button>
  )
}

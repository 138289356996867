import { FunctionComponent } from 'react'
import Box from '@mui/material/Box'
import { Theme } from '@mui/material/styles'
import { makeStyles, createStyles } from '@mui/styles'
import Chip from '@mui/material/Chip'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'left',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  })
)

const TagsField: FunctionComponent<{
  tags: IShipmentTag[]
}> = ({ tags }) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      {tags.map((tag: IShipmentTag) => (
        <Chip
          label={tag.name}
          variant="outlined"
          color="primary"
          key={tag.name}
        />
      ))}
    </Box>
  )
}

export default TagsField

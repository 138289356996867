import { ISelectChipColor } from 'src/components/SelectableChip'

export const tableColumns = [
  'status',
  'tracking key',
  'tracking number',
  'carrier',
  'created',
  '',
]

export const statuses: { [key: string]: ISelectChipColor } = {
  pending: 'secondary',
  requested: 'secondary',
  active: 'success',
  paused_eta_updates: 'success',
  archived: 'default',
  cancelled: 'default',
  completed: 'success',
  error: 'error',
  invalid: 'error',
  paused: 'warning',
}

export const trackingKey = {
  container_number: 'Container number',
  bl_number: 'BL number',
  booking_number: 'Booking number',
  air_waybill: 'Air waybill',
}

export const tableColumnKeys = [
  'status',
  'tracking_key',
  'tracking_number',
  'carrier_name',
  'created_at',
]

export const keyTypeOptions = {
  sea: [
    { label: 'BL number', id: 'bl_number' },
    { label: 'Booking number', id: 'booking_number' },
    { label: 'Container number', id: 'container_number' },
  ],
  air: [{ label: 'Air waybill', id: 'air_waybill' }],
}

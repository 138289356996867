import { AnyAction } from 'redux'
import * as actions from '../actions/chat'

export const clearChatState = (): AnyAction => ({
  type: actions.CLEAR_CHAT_STATE,
})

export const chatsMarkAllRead = (): AnyAction => ({
  type: actions.CHATS_MARK_ALL_READ,
})

export const chatsMarkAllReadSuccess = (): AnyAction => ({
  type: actions.CHATS_MARK_ALL_READ_SUCCESS,
})

export const shipmentGetChats = (shipmentId: number): AnyAction => ({
  type: actions.SHIPMENT_GET_CHATS,
  shipmentId,
})

export const shipmentGetChatsSuccess = (payload: any): AnyAction => ({
  type: actions.SHIPMENT_GET_CHATS_SUCCESS,
  payload,
})

export const chatGetData = (chatId: number, page: number): AnyAction => ({
  type: actions.CHAT_GET_INITIAL_DATA,
  payload: { page },
  chatId,
})

export const chatGetDataSuccess = (payload: any): AnyAction => ({
  type: actions.CHAT_GET_DATA_SUCCESS,
  payload,
})

export const chatGetInitialDataSuccess = (payload: any): AnyAction => ({
  type: actions.CHAT_GET_INITIAL_DATA_SUCCESS,
  payload,
})

export const chatGetUsers = (chatId: number): AnyAction => ({
  type: actions.CHAT_GET_USERS,
  chatId,
})

export const chatGetUsersSuccess = (payload: any): AnyAction => ({
  type: actions.CHAT_GET_USERS_SUCCESS,
  payload,
})

export const attachFileToShipment = (
  chatId: number,
  attachmentId: number
): AnyAction => ({
  type: actions.CHAT_ATTACH_FILE,
  chatId,
  attachmentId,
})

export const chatAttachFileSuccess = (payload: any): AnyAction => ({
  type: actions.CHAT_ATTACH_FILE_SUCCESS,
  payload,
})

export const chatPinComment = (
  chatId: number,
  commentId: number
): AnyAction => ({
  type: actions.CHAT_PIN_COMMENT,
  chatId,
  commentId,
})

export const chatPinCommentSuccess = (payload: any): AnyAction => ({
  type: actions.CHAT_PIN_COMMENT_SUCCESS,
  payload,
})

export const chatSaveMessage = (
  chatId: number,
  commentId: number
): AnyAction => ({
  type: actions.CHAT_SAVE_MESSAGE,
  chatId,
  commentId,
})

export const chatSaveMessageSuccess = (payload: any): AnyAction => ({
  type: actions.CHAT_SAVE_MESSAGE_SUCCESS,
  payload,
})

export const submitChatComment = (payload: any, chatId: number): AnyAction => ({
  type: actions.CHAT_SUBMIT_COMMENT,
  payload,
  chatId,
})

export const submitChatCommentSuccess = (payload: any): AnyAction => ({
  type: actions.CHAT_SUBMIT_COMMENT_SUCCESS,
  payload,
})

export const toggleChatFollow = (
  chatId: number,
  userId: number
): AnyAction => ({
  type: actions.CHAT_TOGGLE_FOLLOW,
  chatId,
  userId,
})

export const toggleChatFollowSuccess = (payload: any): AnyAction => ({
  type: actions.CHAT_TOGGLE_FOLLOW_SUCCESS,
  payload,
})

export const acceptApproval = (id: number): AnyAction => ({
  type: actions.CHAT_ACCEPT_APPROVAL,
  id,
})

export const acceptApprovalSuccess = (payload: any): AnyAction => ({
  type: actions.CHAT_ACCEPT_APPROVAL_SUCCESS,
  payload,
})

export const rejectApproval = (id: number): AnyAction => ({
  type: actions.CHAT_REJECT_APPROVAL,
  id,
})

export const rejectApprovalSuccess = (payload: any): AnyAction => ({
  type: actions.CHAT_REJECT_APPROVAL_SUCCESS,
  payload,
})

import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import muiTheme from 'src/muiTheme'
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles'
import MapTooltipFooter from './MapTooltipFooter'
import MapTooltipContent from './MapTooltipContent'

import { TooltipProps } from './MapTooltip.props'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface MapTooltipProps {
  shipments: TooltipProps[]
}

const MapTooltip: React.FC<MapTooltipProps> = ({ shipments }) => {
  const shipment = shipments[0]
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <Stack
          spacing={2}
          width={255}
          divider={<Divider orientation="horizontal" flexItem />}
        >
          <MapTooltipContent shipments={shipments} />
          <MapTooltipFooter shipment={shipment} />
        </Stack>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default MapTooltip

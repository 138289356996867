import { AnyAction } from 'redux'
import * as actions from '../actions/trackAndTrace'

export const trackAndTraceGetData = (shipment_id: number): AnyAction => ({
  shipment_id,
  type: actions.TRACK_AND_TRACE_GET_DATA,
})

export const trackAndTraceGetDataSuccess = (payload: any): AnyAction => ({
  type: actions.TRACK_AND_TRACE_GET_DATA_SUCCESS,
  payload,
})

export const trackAndTraceResume = (
  shipment_id: number,
  payload: any
): AnyAction => ({
  shipment_id,
  type: actions.TRACK_AND_TRACE_RESUME,
  payload,
})

export const trackAndTracePause = (
  shipment_id: number,
  payload: any
): AnyAction => ({
  shipment_id,
  type: actions.TRACK_AND_TRACE_PAUSED,
  payload,
})

export const trackAndTraceUsePortPair = (
  shipment_id: number,
  payload: any
): AnyAction => ({
  shipment_id,
  type: actions.TRACK_AND_TRACE_USE_PORT_PAIR,
  payload,
})

export const trackAndTraceCancel = (
  shipment_id: number,
  payload: any
): AnyAction => ({
  shipment_id,
  type: actions.TRACK_AND_TRACE_CANCEL_AND_ARCHIVE,
  payload,
})

export const updateTrackAndTraceSuccess = (payload: any): AnyAction => ({
  type: actions.TRACK_AND_TRACE_UPDATE_SUCCESS,
  payload,
})

export const trackTraceSubscriptionCreate = (
  shipment_id,
  payload
): AnyAction => ({
  type: actions.TRACK_AND_TRACE_SUBSCRIPTION_CREATE,
  shipment_id,
  payload,
})

export const trackTraceSubscriptionCreateSuccess = (
  payload: ITrackTraceSubscription
): AnyAction => ({
  type: actions.TRACK_AND_TRACE_SUBSCRIPTION_CREATE_SUCCESS,
  payload,
})

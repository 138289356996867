import { makeStyles } from '@mui/styles'

export const useStyle = makeStyles((theme) => ({
  dimensions: {
    alignItems: 'center',
    borderRadius: '5px',
    border: `1px solid`,
    textIndent: '5px',
    padding: '1px',
    display: 'flex',
    width: '100%',
    height: 36,
    borderColor: theme.palette.grey[400],

    '&:focus-within': {
      border: (props: any) =>
        props.isDimensionsDisabled
          ? `1px solid ${theme.palette.grey[300]}`
          : `1px solid ${theme.palette.primary.main}`,
    },

    '& > *': {
      color: theme.palette.primary.main,
      textAlign: 'center',
      boxSizing: 'border-box',
      padding: 0,
    },

    '& .MuiInputBase-root': {
      border: 'none',

      '& .MuiInputBase-input': {
        padding: '0',
      },
    },

    '& .unit': {
      marginRight: '5px',
    },
  },
  input: {
    textAlign: 'center',
    fontSize: 13,
  },
  error: {
    borderColor: theme.palette.error.main,
  },
  disabled: {
    borderColor: theme.palette.grey[300],
    pointerEvents: 'none',
    '& > *': {
      color: theme.palette.grey[300],
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginTop: 5,
    marginBottom: 5,
    fontSize: 12,
  },
}))

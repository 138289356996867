import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import * as actions from '../actions/documents'

declare global {
  interface IDocuments {
    list: IDashboardShipmentDocument[]
    total_count: number
    filters_data: IDocumentsFiltersData
    initial: boolean
    filters: {
      search: string
      document_types_ids: string[]
      uploaded_by_ids: number[] | string[]
    }
  }

  interface IDashboardShipmentDocument {
    id: number
    original_filename: string
    download_url: string
    document_types: IDocumentTypeShort[]
    created_at: string
    containers: IContainerShort[]
    booking: {
      booking_index: string
      booking_reference: string
      id: number
      shipper_name: string
      url: string
    }
    shipment: {
      id: number
      our_reference: string
      reference: string
      url: string
    }
    uploaded_by: IDocumentUploadedBy
    viewable_by: IDocumentViewableBy[]
  }

  interface IDocumentTypeShort {
    id: number
    name: string
  }

  interface IFileData {
    file?: File
    name: string
    types: string[]
    containers: string[]
    booking?: string
    viewable_by: string[]
  }

  interface IDocumentViewableBy {
    id: number
    logo: string | null
    name: string
    role_code: string
  }

  interface IDocumentUploadedBy {
    avatar: string | null
    full_name: string
    id: number
    organization_name: string
  }

  interface IDocumentsFiltersData {
    document_types: any[]
    uploaded_by: any[]
  }
}

export const initialFilters = {
  search: '',
  document_types_ids: [],
  uploaded_by_ids: [],
}

export const initialDocumentsState: IDocuments = {
  list: [],
  total_count: 0,
  filters_data: {
    document_types: [],
    uploaded_by: [],
  },
  filters: initialFilters,
  initial: true,
}

const receiveList: Reducer<IDocuments, AnyAction> = (state, action) => ({
  ...state,
  list: action.payload.shipment_documents,
  total_count: action.payload.total_count,
  initial: false,
})

const receiveFilters: Reducer<IDocuments, AnyAction> = (state, action) => ({
  ...state,
  filters_data: action.payload,
})

const updateFilters: Reducer<IDocuments, AnyAction> = (state, action) => ({
  ...state,
  filters: { ...state.filters, ...action.payload },
  initial: false,
})

export default createReducer(initialDocumentsState, {
  [actions.DOCUMENTS_GET_DATA_SUCCESS]: receiveList,
  [actions.DOCUMENTS_FILTERS_GET_DATA_SUCCESS]: receiveFilters,
  [actions.DOCUMENTS_UPDATE_FILTERS]: updateFilters,
})

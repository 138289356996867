import { parse, stringify } from 'query-string'
import { reduce } from 'lodash'
import {
  shippingDate,
  parseAnyFormatDate,
  convertDateForComparison,
} from 'src/utils/helpers'
import { ModalityEnum, LoadTypeEnum } from 'src/config/constants'
import { SpotRateProps } from './SABSearchResultAirSpotRates.props'

export const getSearchUrl = (path: string, options, searchQuery): string => {
  const updatedQuery = { ...searchQuery }
  const date = convertDateForComparison(shippingDate(updatedQuery.modality))
  const passedDate = parseAnyFormatDate(updatedQuery.departure_date)
  updatedQuery.departure_date = passedDate < date ? date : passedDate
  if (
    updatedQuery.modality === ModalityEnum.Air ||
    updatedQuery.load_type === LoadTypeEnum.lcl
  ) {
    updatedQuery.container_types = []
  }

  const query = stringify(
    { ...updatedQuery, ...options },
    { arrayFormat: 'bracket' }
  )
  return `${path}?${query}`
}

export const getSportRatesSearchUrl = (spotRates: SpotRateProps[]): string => {
  const selectedSpotRates = reduce(
    spotRates,
    (result, rate) => {
      if (!!rate.checked) {
        result.push(rate.name)
      }
      return result
    },
    [] as string[]
  )

  return getSearchUrl(
    '/search/quote',
    { requested_air_tt: selectedSpotRates },
    parse(window.location.search, { arrayFormat: 'bracket' })
  )
}

// @ts-nocheck
// FIXME

import { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { find } from 'lodash'
import Box from '@mui/material/Box'

import Tabs from 'src/stories/Tabs'
import * as routes from 'src/pages/ShipmentLayout/tabRoutes.ts'
import { shipmentGetChats, clearChatState } from 'src/stores/actionCreators'
import UserChannelClient from 'src/components/SocketHandlers/UserChannelClient'
import InlineNotification from 'src/components/Common/InlineNotification'

import { permissionTo } from '../../utils'

import PublicChat from '../PublicChat'
import AgentsChat from '../AgentsChat'
import PrivateChat from './index'

interface IPrivateChatProps {
  match: IMatch | null
  shipmentId?: number
  getChats: IActionPromiseFactory
}

const PRIVATE_CHAT = 'private_chat'
const PUBLIC_CHAT = 'public_chat'
const AGENTS_CHAT = 'agents_chat'

const initialCounters = {
  privateChat: 0,
  publicChat: 0,
  agentsChat: 0,
}

const scrollTo = (id: string) => {
  const anchor = document.querySelector(`#${id}`)
  anchor &&
    anchor.scrollIntoView({
      behavior: 'smooth',
    })
}

const Chats = (props: IPrivateChatProps) => {
  const dispatch = useDispatch()
  const [selectValue, setSelectValue] = useState<TTabType>(PRIVATE_CHAT)
  const [counters, setCounters] = useState(initialCounters)
  const location = useLocation()
  const history = useHistory()
  const params = useParams()
  const { id: shipmentId } = params

  const { chats } = useSelector((state: IGlobalState) => ({
    chats: state.chat.chats,
  }))

  const renderTabLabel = (label: string, counter: number) => {
    return (
      <>
        <div className="flex-center">
          {label}{' '}
          {counter > 0 && <Box className="shipment-layout__opentasks__badge" />}
        </div>
      </>
    )
  }

  const tabs = useMemo(() => {
    return [
      {
        label: renderTabLabel('Private chat', counters.privateChat),
        value: PRIVATE_CHAT,
        iconDirection: 'vertical',
        icon: (
          <i
            className="icon private-chat"
            style={{ height: 20, fontSize: 18 }}
          />
        ),
        permissions: ['shipments.private_chat.view'],
      },
      {
        label: renderTabLabel('Agents chat', counters.agentsChat),
        value: AGENTS_CHAT,
        iconDirection: 'vertical',
        icon: (
          <i className="icon agent-icon" style={{ height: 20, fontSize: 18 }} />
        ),
        permissions: ['shipments.agents_chat.view'],
      },
      {
        label: renderTabLabel('Public chat', counters.publicChat),
        value: PUBLIC_CHAT,
        iconDirection: 'vertical',
        icon: <i className="icon users" style={{ height: 20, fontSize: 18 }} />,
        permissions: ['shipments.public_chat.view'],
      },
    ]
  }, [counters])

  const [
    isPrivateChatEnabled,
    isPublicChatEnabled,
    isAgentsChatEnabled,
  ] = useMemo(() => {
    return [
      !!find(chats, { chat_type: 'private_chat' }),
      !!find(chats, { chat_type: 'public_chat' }),
      !!find(chats, { chat_type: 'agents_chat' }),
    ]
  }, [chats])

  const filteredTabs = useMemo(() => {
    return tabs.filter((tab) => {
      if (tab.value === PRIVATE_CHAT && !isPrivateChatEnabled) return false
      if (tab.value === PUBLIC_CHAT && !isPublicChatEnabled) return false
      if (tab.value === AGENTS_CHAT && !isAgentsChatEnabled) return false
      return tab.permissions.length ? permissionTo(tab.permissions) : true
    })
  }, [tabs, isPublicChatEnabled, isAgentsChatEnabled])

  useEffect(() => {
    dispatch(shipmentGetChats(shipmentId))

    return () => {
      dispatch(clearChatState())
    }
  }, [])

  useEffect(() => {
    if (
      location.pathname.includes(PRIVATE_CHAT) &&
      permissionTo('shipments.private_chat.view')
    ) {
      setSelectValue(PRIVATE_CHAT)
      scrollTo(routes.privateChat)
    }
    if (
      location.pathname.includes(PUBLIC_CHAT) &&
      permissionTo('shipments.public_chat.view')
    ) {
      setSelectValue(PUBLIC_CHAT)
      scrollTo(routes.publicChat)
    }
    if (
      location.pathname.includes(AGENTS_CHAT) &&
      permissionTo('shipments.agents_chat.view')
    ) {
      setSelectValue(AGENTS_CHAT)
      scrollTo(routes.publicChat)
    }
  }, [location.pathname])

  useEffect(() => {
    const currentChat = filteredTabs.find((x) => x.value === selectValue)
    if (!permissionTo(`shipments.${selectValue}.view`) || !currentChat) {
      if (filteredTabs[0]) setSelectValue(filteredTabs[0].value)
    }
  }, [filteredTabs, selectValue])

  const handleChange = (newValue: TTabType) => {
    setSelectValue(newValue)
    history.replace({ pathname: `/shipments/${shipmentId}/chats/${newValue}` })
  }

  const renderChats = () => {
    if (!permissionTo(`shipments.${selectValue}.view`)) {
      return (
        <InlineNotification
          color="warning"
          show
          message="You don't have permission to see this chat."
          showClose={false}
        />
      )
    }
    switch (selectValue) {
      case PRIVATE_CHAT:
        return <PrivateChat {...props} />
      case PUBLIC_CHAT:
        return <PublicChat {...props} />
      case AGENTS_CHAT:
        return <AgentsChat {...props} />
    }
  }

  const handleReceivedNotifications = (response: any): void => {
    if (
      response.message_type === 'unread_count' &&
      +response.shipment_id === shipmentId
    ) {
      const privateChat: IChatUnreadData = find(response.message, {
        chat_type: 'private_chat',
      })
      const publicChat: IChatUnreadData = find(response.message, {
        chat_type: 'public_chat',
      })
      const agentsChat: IChatUnreadData = find(response.message, {
        chat_type: 'agents_chat',
      })
      const newCounters: IShipmentLayoutCounters = {
        ...counters,
      }

      if (!!privateChat) {
        newCounters.privateChat = privateChat.unread_count
      }

      if (!!publicChat) {
        newCounters.publicChat = publicChat.unread_count
      }

      if (!!agentsChat) {
        newCounters.agentsChat = agentsChat.unread_count
      }
      setCounters({ ...counters, ...newCounters })
    }
  }

  return (
    <>
      <Box m="-11px -0.75rem 0 -0.75rem">
        <UserChannelClient onReceived={handleReceivedNotifications} />
        <Tabs value={selectValue} onChange={handleChange} tabs={filteredTabs} />
      </Box>
      {renderChats()}
    </>
  )
}

export default Chats

import { FunctionComponent } from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import './styles.scss'

import {
  containerTypeDescription,
  containerTypeIcon,
} from '../../../utils/helpers'

interface IProps {
  shipmentData: IShipmentMainData
  containerData: IShipmentContainer
  isLclOrAir: boolean
}

const ContainerOverviewHeader: FunctionComponent<IProps> = (props) => {
  const {
    shipment_phase,
    delivery_available_from,
    closing_datetime,
  } = props.shipmentData

  const { t } = useTranslation()

  const isOriginPhase: boolean = shipment_phase === 'origin'

  const dateBlockFrom = (): string => {
    if (props.isLclOrAir) {
      if (isOriginPhase) {
        return closing_datetime
          ? t('common.date_medium', {
              date: closing_datetime,
              defaultValue: '{{date, DATE_MED}}',
            })
          : '-'
      } else {
        return delivery_available_from
          ? t('common.date_medium', {
              date: delivery_available_from,
              defaultValue: '{{date, DATE_MED}}',
            })
          : '-'
      }
    } else {
      return props.containerData.number || '-'
    }
  }

  const dateBlockTo = (): string => {
    if (props.isLclOrAir) {
      if (!isOriginPhase) {
        return props.containerData.devanning_date
          ? t('common.date_medium', {
              date: props.containerData.devanning_date,
              defaultValue: '{{date, DATE_MED}}',
            })
          : '-'
      }
      return ''
    } else {
      return props.containerData.seal_number || '-'
    }
  }

  return (
    <div className="container-overview-header">
      <div className="container-overview-header--expanded">
        <div className="container-info">
          <div className="container-info--title">
            <i
              className={
                'icon ' +
                containerTypeIcon(
                  props.shipmentData.type,
                  props.containerData.container_type.code || null
                )
              }
            />
          </div>{' '}
          {!props.isLclOrAir && (
            <div className="container-info--value">
              {containerTypeDescription(
                props.shipmentData.type,
                props.containerData.container_type.code || null
              )}
            </div>
          )}
        </div>
        <div className="date-info">
          <div className="date-info--title">
            <Typography className="small light">
              {props.isLclOrAir
                ? isOriginPhase
                  ? t(
                      'shipment_containers.cargo_closing_date',
                      'Cargo closing date'
                    )
                  : t(
                      'shipment_containers.delivery_available_from',
                      'Delivery available from'
                    )
                : t('shipment_containers.container_number', 'Container number')}
            </Typography>
          </div>{' '}
          <div className="date-info--value">
            <Typography className="medium normal" children={dateBlockFrom()} />
          </div>
        </div>
        <div className="date-info">
          <div className="date-info--title paragraph__small grey">
            <Typography className="small light">
              {props.isLclOrAir
                ? isOriginPhase
                  ? ''
                  : t('shipment_containers.devanning_date', 'Devanning date')
                : t('shipment_containers.seal_number', 'Seal number')}
            </Typography>
          </div>{' '}
          <div className="date-info--value">
            <Typography className="medium normal" children={dateBlockTo()} />
          </div>
        </div>
        <div className="block-arrow">
          {!props.isLclOrAir && <i className="icon chevron" />}
        </div>
      </div>
    </div>
  )
}

export default ContainerOverviewHeader

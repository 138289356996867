import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/reports'
import * as actionCreators from '../../actionCreators/reports'
import { simpleGetAndResolve } from '../factories'

const getShipmentsCounts = simpleGetAndResolve(
  () => '/api/v1/reports/shipments_count',
  actionCreators.reportsGetShipmentsCountsSuccess,
  (response) => response.data
)

const getShipmentsCosts = simpleGetAndResolve(
  () => '/api/v1/reports/shipments_revenue',
  actionCreators.reportsGetShipmentsCostsSuccess,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.REPORTS_GET_COUNTS_OF_SHIPMENTS, getShipmentsCounts)
  yield takeEvery(actions.REPORTS_GET_COSTS_OF_SHIPMENTS, getShipmentsCosts)
}

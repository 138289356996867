import { useTranslation } from 'react-i18next'
import { BreadcrumbItem, Breadcrumbs } from 'src/stories/Breadcrumbs'
import Box from '@mui/material/Box'

const DemurrageAndDetentionTopSectionBreadcrumbs = () => {
  const { t } = useTranslation()
  const links: BreadcrumbItem[] = [
    {
      href: '/rates',
      label: t('rates.title', 'Rates'),
    },
  ]
  return (
    <Box display="flex" alignItems="center" height="100%">
      <Breadcrumbs
        current={t('demurrage_and_detention.title', 'Demurrage and detention')}
        links={links}
      />
    </Box>
  )
}

export default DemurrageAndDetentionTopSectionBreadcrumbs

import { AnyAction } from 'redux'
import { put } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { stringify } from 'query-string'
import { authorizedRequest } from '../wrappers'
import { Logger } from '../../../utils'
import { serverErrors } from '../../../utils/helpers'
import * as actionCreators from '../../actionCreators/searchOverview'

export default function* (action: AnyAction): Iterator<any> {
  try {
    const response = yield authorizedRequest({
      method: 'GET',
      url: 'api/v1/request_quotes/overview',
      params: action.payload,
    })
    yield put(actionCreators.searchQuotesOverviewSuccess(response))
  } catch (error) {
    const unknownError: any = error
    yield* serverErrors(unknownError)
    yield delay(4000)
    window.location.href = '/search?' + stringify(action.payload)
    yield Logger.sagaError(unknownError, 'store::user::loadState')
  }
}

import { set } from 'lodash'
import { useTranslation } from 'react-i18next'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Checkbox from '@mui/material/Checkbox'
import { useGetContainerTypes } from 'src/services/Api/common'
import { Box, Grid, InputLabel, Stack, Typography } from '@mui/material'
import QuantityCounter from 'src/stories/QuantityCounter'
import { useFormContext } from '../../../FormContext'
import { TemplateFormContextProps } from '../../../Form.props'
import { ventsAndDrainsOptions } from '../../../Form.constants'
import SingleSelect from '../../FormElements/SingleSelect'
import Input from '../../FormElements/Input'

const TemplateFormContainer = (props) => {
  const {
    formState,
    onChange,
    selectedContainerType,
  } = useFormContext() as TemplateFormContextProps
  const { container } = formState
  const { nonOperatingReefer } = container

  const { data: containerTypes } = useGetContainerTypes({
    refetchOnMount: false,
  })

  const options =
    containerTypes?.map((containerType) => ({
      id: containerType.code,
      label: containerType.name,
    })) ?? []

  const onFieldChange = (
    fieldPath: string,
    value: string | boolean | number | null
  ) => {
    onChange({
      container: set(formState.container, fieldPath, value),
    })
  }

  const onNonOperatingReeferChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target
    onFieldChange('nonOperatingReefer', checked)
  }

  const { t } = useTranslation()

  return (
    <>
      <InputLabel required={true}>
        {t('templates.cargo_details.container_type', 'Container type')}
      </InputLabel>
      <Stack direction="row">
        <Box width={300}>
          <SingleSelect
            fieldPath="container.containerTypeEnum"
            options={options}
            SelectDisplayProps={{
              'data-testid': 'shipment-template-container-type-select',
            }}
          />
        </Box>
        <QuantityCounter
          min="0"
          totalError=""
          value={container.amount}
          name="amount"
          onChange={(newValue) => onFieldChange('amount', newValue)}
        />
      </Stack>
      {selectedContainerType?.equipment_controlled_atmosphere && (
        <>
          <Typography mt={3} mb={2} variant="h5">
            Reefer settings
          </Typography>
          <FormGroup onChange={onNonOperatingReeferChange}>
            <FormControlLabel
              control={<Checkbox checked={nonOperatingReefer} />}
              label={t(
                'templates.cargo_details.non_operating',
                'Non-operating'
              )}
              data-testid="shipment-template-non-operating-reefer"
            />
          </FormGroup>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={3}>
              <Input
                disabled={nonOperatingReefer}
                numberInput
                fieldPath="container.temperature"
                label={t('templates.cargo_details.temperature', 'Temperature')}
                inputProps={{
                  'data-testid': 'shipment-template-reefer-temperature',
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                disabled={nonOperatingReefer}
                numberInput
                fieldPath="container.humidity"
                label={t('templates.cargo_details.humidity', 'Humidity')}
                inputProps={{
                  'data-testid': 'shipment-template-reefer-humidity',
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                disabled={nonOperatingReefer}
                numberInput
                fieldPath="container.ventilation"
                label={t('templates.cargo_details.ventilation', 'Ventilation')}
                inputProps={{
                  'data-testid': 'shipment-template-reefer-ventilation',
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <SingleSelect
                disabled={nonOperatingReefer}
                fieldPath="container.ventsOpen"
                label={t('templates.cargo_details.vents', 'Vents')}
                mapIdToValue={true}
                options={ventsAndDrainsOptions}
                SelectDisplayProps={{
                  'data-testid': 'shipment-template-reefer-vents',
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <SingleSelect
                disabled={nonOperatingReefer}
                fieldPath="container.drainsOpen"
                label={t('templates.cargo_details.drains', 'Drains')}
                mapIdToValue={true}
                options={ventsAndDrainsOptions}
                SelectDisplayProps={{
                  'data-testid': 'shipment-template-reefer-drains',
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                disabled={nonOperatingReefer}
                numberInput
                fieldPath="container.co2Regulation"
                label={t(
                  'templates.cargo_details.co_regulation',
                  'CO2 regulation'
                )}
                inputProps={{
                  'data-testid': 'shipment-template-reefer-co2-regulation',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                disabled={nonOperatingReefer}
                fieldPath="container.comments"
                label={t('templates.cargo_details.comments', 'Comments')}
                inputProps={{
                  'data-testid': 'shipment-template-reefer-comments',
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default TemplateFormContainer

import RenewIcon from '@mui/icons-material/Autorenew'
import { Button } from '@mui/material'
import { omit } from 'lodash'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useUpdateSavedFilter } from 'src/services/Api/savedFilters'
import { queryClient } from 'src/services/http-common'
import { useTranslation } from 'react-i18next'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { useActionButtonIsActive, useFilter } from '../hooks'
import { ActionButtonsModal } from './ActionButtonsModal'

export const UpdateFilterButton = () => {
  const {
    filters,
    page,
    filterId,
    quickFilter,
    setFilterId,
    defaultFiltersKeys,
    filterOptionsQueryKey,
  } = useFilter()
  const { mutateAsync: updateSavedFilter } = useUpdateSavedFilter(
    filterId || null
  )
  const dispatch = useDispatch()
  const isActive = useActionButtonIsActive('update')
  const [open, setOpen] = useState(false)

  const { t } = useTranslation()

  const onSave = (filterName: string) => {
    updateSavedFilter({
      name: filterName,
      page,
      filter: omit(filters, defaultFiltersKeys || []),
    }).then((response) => {
      queryClient.setQueriesData([filterOptionsQueryKey], (oldParams: any) => {
        if (!oldParams) return oldParams

        const { saved_filters: savedFilters = [] } = oldParams
        const updatedIndex = savedFilters.findIndex(
          ({ id }) => id === response.id
        )

        savedFilters[updatedIndex] = response

        return { ...oldParams, saved_filters: [...savedFilters] }
      })
      setFilterId(filterId)
      dispatch(
        showNotification({
          message: t('common.notifications.filter_updated', { filterName }),
          severity: 'info',
        })
      )
    })
  }

  return (
    <>
      <Button
        variant="text"
        size="large"
        onClick={() => setOpen(true)}
        startIcon={<RenewIcon />}
        disabled={!isActive}
      >
        {t('common.buttons.update_quick_filter', 'Update quick filter')}
      </Button>
      <ActionButtonsModal
        onSave={onSave}
        onClose={() => setOpen(false)}
        filterName={quickFilter?.name || ''}
        open={open}
        type="update"
      />
    </>
  )
}

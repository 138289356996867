import { FunctionComponent, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'

import { Table } from 'src/stories'
import { history } from 'src/shyppleStore'
import DashboardEmptyLine from 'src/components/DashboardOverview/DashboardEmptyLine'
import { promisifyAction } from '../../../utils'
import { convertDateToDateTime } from '../../../utils/helpers'
import { fetchDashboardUpdatedByYou } from '../../../stores/actionCreators'
import { dashboardLine } from '../DashboardShipmentLine/dashboardLine'
import columns from '../common'

const DashboardUpdatedByOthers: FunctionComponent<{}> = () => {
  const dispatch = useDispatch()
  const [busy, setBusy] = useState<boolean>(true)

  const { records, page, onlyWatchedShipments } = useSelector(
    (state: IGlobalState) => ({
      records: state.dashboard.updatedByYou.records,
      page: state.dashboard.updatedByYou.page,
      onlyWatchedShipments: state.shipmentsWatchedBy.onlyWatchedShipments,
    })
  )

  const fetchDashboardUpdatedByYouAsync = promisifyAction(
    dispatch,
    fetchDashboardUpdatedByYou
  )

  const fetchDataAsync = async () => {
    setBusy(true)
    await fetchDashboardUpdatedByYouAsync({
      page,
      only_watched_shipments: onlyWatchedShipments,
    })
    setBusy(false)
  }

  useEffect(() => {
    fetchDataAsync()
  }, [onlyWatchedShipments])

  if (!busy && !records.length) {
    return <DashboardEmptyLine />
  }

  return (
    <Table
      columns={columns}
      loading={busy}
      maxHeight={320}
      onRowClick={(id) => {
        history.push(`/shipments/${id}/conversations`)
      }}
      showHeader={false}
      rows={
        !!records.length
          ? records.map((shipment: IDashboardShipmentWithComment) => {
              const info = (
                <Typography
                  variant="body1"
                  children={shipment.chat_comment_message}
                />
              )
              const subtitle = `On: ${convertDateToDateTime(
                shipment.chat_comment_created_at
              )}`
              return dashboardLine(shipment, subtitle, info)
            })
          : []
      }
    />
  )
}

export default DashboardUpdatedByOthers

import { createReducer, Reducer } from 'redux-create-reducer'
import { AnyAction } from 'redux'

declare global {
  interface IComment {
    id: number
    shipment_id: number
    author: IAuthor
    body: string
    created_at: string
    updated_at: string
  }

  interface IShipmentCommentsState {
    comments: IComment[]
    isFetching: boolean
    isCreating: boolean
  }
}

export const initialState: IShipmentCommentsState = {
  isFetching: false,
  isCreating: false,
  comments: [],
}

const fetchComments: Reducer<IShipmentCommentsState, AnyAction> = (state) => ({
  ...state,
  isFetching: true,
})

const receiveComments: Reducer<IShipmentCommentsState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  comments: action.payload,
  isFetching: false,
})

const createComment: Reducer<IShipmentCommentsState, AnyAction> = (state) => ({
  ...state,
  isCreating: true,
})

const commentCreated: Reducer<IShipmentCommentsState, AnyAction> = (
  state,
  action
) => {
  const comments = [...state.comments]
  comments.unshift(action.payload)
  return {
    ...state,
    comments,
    isCreating: false,
  }
}

const commentDestroyed: Reducer<IShipmentCommentsState, AnyAction> = (
  state,
  action
) => {
  const comments = state.comments.filter(
    (comment) => comment.id !== action.payload.id
  )

  return {
    ...state,
    comments,
  }
}

const clearComments: Reducer<IShipmentCommentsState, AnyAction> = (state) => ({
  ...state,
  comments: [],
})

export default createReducer(initialState, {
  SHIPMENT_COMMENTS_FETCH: fetchComments,
  SHIPMENT_COMMENTS_FETCH_SUCCESS: receiveComments,
  SHIPMENT_COMMENTS_CREATE: createComment,
  SHIPMENT_COMMENTS_CREATE_SUCCESS: commentCreated,
  SHIPMENT_COMMENTS_DESTROY_SUCCESS: commentDestroyed,
  SHIPMENT_COMMENTS_CLEAR: clearComments,
})

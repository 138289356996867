import { createReducer, Handlers, Reducer } from 'redux-create-reducer'
import { AnyAction } from 'redux'
import * as actions from '../actions/searches'

declare global {
  interface ILinkedCustomersResult {
    customers: IOrganization[]
  }
}

export const initialLinkedCustomersState: ILinkedCustomersResult = {
  customers: [],
}

const getLinkedCustomers: Reducer<ILinkedCustomersResult, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  customers: payload,
})

const actionHandlers: Handlers<ILinkedCustomersResult> = {
  [actions.LINKED_CUSTOMERS_SUCCESS]: getLinkedCustomers,
}

export default createReducer(initialLinkedCustomersState, actionHandlers)

import { Draggable } from 'react-beautiful-dnd'
import Box from '@mui/material/Box'

export type DraggableListItemProps = {
  index: number
  children: any
}

const DraggableListItem = ({ index, children }: DraggableListItemProps) => {
  return (
    <Draggable draggableId={`item-${index}`} index={index}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={snapshot.isDragging ? 'draggingListItem' : ''}
        >
          {children}
        </Box>
      )}
    </Draggable>
  )
}

export default DraggableListItem

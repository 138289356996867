import { getHumanizeCalculationMethod } from '../helpers'
import { CalculationMethodText } from './RatesLineDetails.styles'

const RatesLineDetailsChargesTableCalculationMethod: React.FC<{
  price: IRatesPriceItem
}> = ({ price }) => {
  if (price.type === 'included_price')
    return <CalculationMethodText>Included</CalculationMethodText>
  return (
    <CalculationMethodText noWrap>
      {price.currency} /{' '}
      {getHumanizeCalculationMethod(price.calculation_method)}
    </CalculationMethodText>
  )
}

export default RatesLineDetailsChargesTableCalculationMethod

import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import * as actions from '../actions/personalDetails'

declare global {
  interface IPersonalDetailsState {
    list: IPersonalDetail[]
    isFetching: boolean
    singlePersonalDetail?: IPersonalDetail
  }

  interface IPersonalDetail {
    id: number
    name: string
    email: string
    phone: string
    company_address_id: number | null
    company_name: string
    companyAddress: IAddress | null
    organizationId: number
    updated_at: string
    address_type?: null // Used for type checking.
    reference?: string
    contact_type?: string
  }

  interface INullPersonalDetail {
    id: number | null
    name: string | null
    email: string | null
    phone: string | null
    company_address_id: number | null
    company_name?: string | null
    company_address?: IAddress | null
    organizationId?: number
    updated_at?: string
    disabled?: boolean
  }

  interface IAddressContact {
    id: number
    name: string
  }

  interface IPersonContact {
    id: number
    name: string
    company_name: string
  }
}

export const initialPersonalDetailsState: IPersonalDetailsState = {
  list: [],
  singlePersonalDetail: undefined,
  isFetching: true,
}

const receiveList: Reducer<IPersonalDetailsState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  list: action.payload,
  isFetching: false,
})

const receiveOne: Reducer<IPersonalDetailsState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  singlePersonalDetail: action.payload,
})

export default createReducer(initialPersonalDetailsState, {
  [actions.PERSONAL_DETAILS_GET_DATA_SUCCESS]: receiveList,
  [actions.PERSONAL_DETAILS_GET_ONE_PERSONAL_DETAIL_SUCCESS]: receiveOne,
  [actions.PERSONAL_DETAILS_PUT_PERSONAL_DETAIL_SUCCESS]: receiveOne,
  [actions.PERSONAL_DETAILS_ADD_PERSONAL_DETAIL_SUCCESS]: receiveOne,
})

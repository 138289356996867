import { FunctionComponent } from 'react'
import { Avatar } from 'src/stories/Avatar'
import Table from 'src/components/Common/Table'
import { convertDateToRelative } from '../../../utils/helpers'
import { tableHeaders } from '../tableHeaders'

const ConnectionUsers: FunctionComponent<any> = ({
  users,
}: {
  users: IUser[]
}) => {
  return (
    <>
      <Table.StripedTable theme="rates organization-users">
        <Table.StripedTableHeader cells={tableHeaders} />
        {(users || []).map((user: any, index) => {
          return (
            <Table.StripedTableRow index={index} key={index}>
              <Table.StripedTableCell className="short-70 user-avatar">
                <Avatar
                  userCard
                  userInfo={{
                    name: user.full_name,
                    department: user.full_name,
                    company: user.organization?.name,
                    phone: user.phone,
                    out_of_office_till: user.out_of_office_till,
                  }}
                  alt={user.full_name}
                  src={user.avatar || 'no-logo'}
                  className={''}
                />
              </Table.StripedTableCell>
              <Table.StripedTableCell className="left-aligned wide">
                {user.full_name}
              </Table.StripedTableCell>
              <Table.StripedTableCell className="left-aligned s-wide">
                <span className="text-wrapper">{user.email}</span>
              </Table.StripedTableCell>
              <Table.StripedTableCell className="left-aligned wide">
                {user.last_active ? (
                  convertDateToRelative(user.last_active)
                ) : (
                  <span className="invited">Invited</span>
                )}
              </Table.StripedTableCell>
              <Table.StripedTableCell></Table.StripedTableCell>
              <Table.StripedTableCell></Table.StripedTableCell>
              <Table.StripedTableCell></Table.StripedTableCell>
              <Table.StripedTableCell></Table.StripedTableCell>
              <Table.StripedTableCell></Table.StripedTableCell>
            </Table.StripedTableRow>
          )
        })}
      </Table.StripedTable>
    </>
  )
}
export default ConnectionUsers

export const ORGANIZATIONS_GET_DATA = 'ORGANIZATIONS_GET_DATA'
export const ORGANIZATIONS_GET_DATA_SUCCESS = 'ORGANIZATIONS_GET_DATA_SUCCESS'
export const ORGANIZATION_GET_DATA = 'ORGANIZATION_GET_DATA'
export const ORGANIZATION_GET_DATA_SUCCESS = 'ORGANIZATION_GET_DATA_SUCCESS'
export const ORGANIZATION_UPLOAD_LOGO = 'ORGANIZATION_UPLOAD_LOGO'
export const ORGANIZATIONS_CLEAR_LIST = 'ORGANIZATIONS_CLEAR_LIST'
export const ORGANIZATION_UPDATE_INVOICE_ADDRESS =
  'ORGANIZATION_UPDATE_INVOICE_ADDRESS'
export const ORGANIZATIONS_PRELOAD = 'ORGANIZATIONS_PRELOAD'
export const ORGANIZATIONS_PRELOAD_SUCCESS = 'ORGANIZATIONS_PRELOAD_SUCCESS'
export const ORGANIZATIONS_CLEAR_PRELOADED = 'ORGANIZATIONS_CLEAR_PRELOADED'
export const UPDATE_PREFERRED_FORWARDER = 'UPDATE_PREFERRED_FORWARDER'
export const UPDATE_PREFERRED_SHIPMENT_ROLE = 'UPDATE_PREFERRED_SHIPMENT_ROLE'
export const ORGANIZATION_GET_EXPORTED_RATES_DATA =
  'ORGANIZATION_GET_EXPORTED_RATES_DATA'
export const ORGANIZATION_GET_EXPORTED_RATES_DATA_SUCCESS =
  'ORGANIZATION_GET_EXPORTED_RATES_DATA_SUCCESS'
export const ORGANIZATION_DELETE_EXPORTED_RATES_DATA =
  'ORGANIZATION_DELETE_EXPORTED_RATES_DATA'

import { history } from 'src/shyppleStore'

export const isQuotationsAvailable = (
  quotations: IShipmentQuotation[]
): boolean =>
  quotations.length >= 1 &&
  quotations.some(
    (quotation: IShipmentQuotation) => quotation.status === 'open'
  )

export const scrollToTab = (tabRoute: string) => {
  history.push(tabRoute)
  const anchor = document.querySelector(`#${tabRoute}`)
  anchor &&
    anchor.scrollIntoView({
      behavior: 'smooth',
    })
}

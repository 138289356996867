// @ts-nocheck
// FIXME
import { withTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import bind from 'autobind-decorator'
import { xor, includes, pull } from 'lodash'

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LegendProps,
  TooltipProps,
} from 'recharts'
import { ToggleButton, ToggleButtonGroup, Box } from '@mui/material'
import CustomizedAxisTick from 'src/components/CustomizedAxisTick'
import MultiSelector from 'src/components/MultiSelector'
import MonthPicker from 'src/components/MonthPicker'
import { promisifyAction, Logger } from '../../utils'

import { reportsGetShipmentsCounts } from '../../stores/actionCreators'

import './styles.scss'

interface IDataMonth {
  name: string
  fcl: number
  lcl: number
  air: number
  total: number
}

interface IMonthsObject {
  from: string
  to: string
}

interface IFiltrationData {
  pols: Array<Array<string | number>>
  pods: Array<Array<string | number>>
  types: string[][]
  statuses: string[][]
  consignees: string[]
  shippers: string[]
  carriers: Array<Array<string | number>>
}

interface IReportsShipmentsCountProps {
  shipmentsCountsData: IActionPromiseFactory
  reports: {
    dataset: IDataMonth[]
    filtrationData: IFiltrationData
  }
}

const now = new Date()

const toOption = (line: string) => [line, line]

const initialState: IReportsShipmentsCountState = {
  filters: {
    orderBy: 'etd',
    startDate: `${now.getMonth() + 1}/${now.getFullYear() - 1}`,
    endDate: `${now.getMonth() + 1}/${now.getFullYear()}`,
    pods: [],
    pols: [],
    loadTypes: [],
    status: [],
    shippers: [],
    consignees: [],
    carriers: [],
  },
  filtrationData: {
    pols: [],
    pods: [],
    types: [],
    statuses: [],
    shippers: [],
    consignees: [],
    carriers: [],
  },
  chartDimensions: {
    width: 0,
    height: 0,
  },
  legendDimensions: {
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'top',
  },
  chartRatio: 0.85,
}

interface IReportsShipmentsCountState {
  filters: {
    orderBy: 'etd' | 'eta'
    startDate?: string
    endDate?: string
    pods: string[]
    pols: string[]
    loadTypes: string[]
    status: string[]
    consignees: string[]
    shippers: string[]
    carriers: string[]
  }
  filtrationData: IFiltrationData
  chartDimensions: {
    width: number
    height: number
  }
  legendDimensions: LegendProps
  chartRatio: number
}

const mapDispatchToProps = (dispatch: Dispatch): any => ({
  shipmentsCountsData: promisifyAction(dispatch, reportsGetShipmentsCounts),
})

const mapStateToProps = (state: IGlobalState): any => ({
  reports: state.reportsShipmentsCount,
})

const toggleClasses = {
  root: 'reports-toggle-group',
}

const toggleButtonClasses = {
  selected: 'reports-toggle-button-selected',
  root: 'reports-toggle-button-root',
}

class ReportsShipmentsCount extends React.Component<
  IReportsShipmentsCountProps,
  IReportsShipmentsCountState
> {
  public readonly state = initialState

  private isComponentMounted: boolean = false
  private resizeThrottleTimeout: any = undefined

  private loadTypesProps = {
    '20_ft': { name: '20 FT', color: '#b8dff5', dash: '10000 0' },
    '40_ft': { name: '40 FT', color: '#3ef6ff', dash: '10000 0' },
    '40_ft_hc': { name: '40 FT HC', color: '#014f7c', dash: '10000 0' },
    lcl: { name: 'LCL', color: '#05ceb6', dash: '10000 0' },
    air: { name: 'AIR', color: '#ed9300', dash: '10000 0' },
    fcl: { name: 'FCL', color: '#0596e9', dash: '10000 0' },
    total: { name: 'Total', color: '#fb2228', dash: '3 3' },
  }

  public async componentDidMount(): Promise<any> {
    this.isComponentMounted = true
    this.throttledSetChartDimensions()
    window.addEventListener('resize', this.throttledSetChartDimensions)
    try {
      await this.search({})
      if (this.isComponentMounted) {
        this.setState({ filtrationData: this.props.reports.filtrationData })
      }
    } catch (error) {
      Logger.log(error)
    }
  }

  public componentWillUnmount(): void {
    this.isComponentMounted = false
    window.removeEventListener('resize', this.throttledSetChartDimensions)
  }

  public render() {
    const { t } = this.props
    return (
      <div className="shipments-count-container">
        <header className="reports-page__filters">
          <div className="chart-header">
            <div>
              <i className="icon dashboard" />
              <span className="reports-page_header-title">
                {t('reports.booked_shipments', 'Number of booked shipments')}
              </span>
            </div>
          </div>
          <Box sx={{ ml: 1, display: 'flex', '& button': { mr: 1 } }}>
            <MonthPicker
              onChange={this.handleDateChange}
              startDate={this.state.filters.startDate}
              endDate={this.state.filters.endDate}
            />
            <MultiSelector
              placeholder={t(
                'common.forms.fields.carrier.no_options_text',
                'Type to search'
              )}
              title={t('common.load_type', 'Load type')}
              fieldName="loadTypes"
              options={this.state.filtrationData.types}
              pickedOptionIds={this.state.filters.loadTypes}
              onPickOption={this.pickFilterOption}
              onCancel={this.resetFiltersOfType}
            />
            <MultiSelector
              placeholder={t(
                'common.forms.fields.carrier.no_options_text',
                'Type to search'
              )}
              title={t(
                'templates.shipment_details.port_of_loading',
                'Port of loading'
              )}
              fieldName="pols"
              options={this.state.filtrationData.pols}
              pickedOptionIds={this.state.filters.pols}
              onPickOption={this.pickFilterOption}
              onCancel={this.resetFiltersOfType}
            />
            <MultiSelector
              placeholder={t(
                'common.forms.fields.carrier.no_options_text',
                'Type to search'
              )}
              title={t(
                'common.forms.fields.port_of_discharge.label',
                'Port of discharge'
              )}
              fieldName="pods"
              options={this.state.filtrationData.pods}
              pickedOptionIds={this.state.filters.pods}
              onPickOption={this.pickFilterOption}
              onCancel={this.resetFiltersOfType}
            />
            <MultiSelector
              placeholder={t(
                'common.forms.fields.carrier.no_options_text',
                'Type to search'
              )}
              title={t('common.status', 'Status')}
              fieldName="status"
              options={this.state.filtrationData.statuses}
              pickedOptionIds={this.state.filters.status}
              onPickOption={this.pickFilterOption}
              onCancel={this.resetFiltersOfType}
            />
            <MultiSelector
              placeholder={t(
                'common.forms.fields.carrier.no_options_text',
                'Type to search'
              )}
              title={t('common.modality_sea.carrier', 'Carrier')}
              fieldName="carriers"
              options={this.state.filtrationData.carriers}
              pickedOptionIds={this.state.filters.carriers}
              onPickOption={this.pickFilterOption}
              onCancel={this.resetFiltersOfType}
            />
            <MultiSelector
              placeholder={t(
                'common.forms.fields.carrier.no_options_text',
                'Type to search'
              )}
              title={t('common.shipper', 'Shipper')}
              fieldName="shippers"
              options={this.state.filtrationData.shippers.map(toOption)}
              pickedOptionIds={this.state.filters.shippers}
              onPickOption={this.pickFilterOption}
              onCancel={this.resetFiltersOfType}
            />
            <MultiSelector
              placeholder={t(
                'common.forms.fields.carrier.no_options_text',
                'Type to search'
              )}
              title={t('common.consignee', 'Consignee')}
              fieldName="consignees"
              options={this.state.filtrationData.consignees.map(toOption)}
              pickedOptionIds={this.state.filters.consignees}
              onPickOption={this.pickFilterOption}
              onCancel={this.resetFiltersOfType}
            />
          </Box>
        </header>
        {this.renderChart()}
        <div
          className="toggle-sort"
          style={{
            width: this.state.chartDimensions.width,
            marginLeft: '-3.3rem',
          }}
        >
          <ToggleButtonGroup
            value={this.state.filters.orderBy}
            exclusive={true}
            onChange={this.setSorting}
            classes={toggleClasses}
          >
            <ToggleButton value="etd" classes={toggleButtonClasses}>
              ETD
            </ToggleButton>
            <ToggleButton value="eta" classes={toggleButtonClasses}>
              ETA
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    )
  }

  private renderChart(): React.ReactNode {
    let keys: string[] = []
    const { legendDimensions } = this.state
    if (this.props.reports.dataset && this.props.reports.dataset.length) {
      keys = Object.keys(this.props.reports.dataset[0])
      pull(keys, 'name')
    }

    return (
      <LineChart
        width={this.state.chartDimensions.width}
        height={this.state.chartDimensions.height}
        data={this.props.reports.dataset}
        margin={{ top: 20, right: 30, bottom: 5 }}
      >
        <CartesianGrid stroke="#f5f5f5" vertical={false} />
        <XAxis
          stroke="#fff"
          dataKey="name"
          tick={<CustomizedAxisTick />}
          tickFormatter={this.tickFormatter}
          tickMargin={7}
        />
        <YAxis
          stroke="#fff"
          tick={{ fill: '#98a0a5' }}
          tickMargin={7}
          tickFormatter={this.yTickFormatter}
        />
        <Tooltip content={this.renderTooltip} />
        <Legend
          layout={legendDimensions.layout}
          align={legendDimensions.align}
          verticalAlign={legendDimensions.verticalAlign}
          content={this.customLegend}
        />
        {keys.map((key: string) =>
          this.loadTypesProps[key] ? (
            <Line
              key={key}
              dataKey={key}
              stroke={this.loadTypesProps[key].color}
              strokeDasharray={this.loadTypesProps[key].dash}
              name={this.loadTypesProps[key].name}
            />
          ) : (
            ''
          )
        )}
      </LineChart>
    )
  }

  private renderTooltip(data: TooltipProps): React.ReactNode {
    const { payload, label } = data
    return (
      <div className="shypple-custom-tooltip">
        <div className="shypple-custom-tooltip-header">{label}</div>
        {(payload || []).map((entry, index) => (
          <div key={index}>
            <div
              className="shypple-custom-tooltip-item"
              style={{ color: entry.color }}
            >
              <span>{entry.name}:</span>
              <span className="right-aligned">{entry.value}</span>
            </div>
          </div>
        ))}
      </div>
    )
  }

  private customLegend(props: LegendProps): React.ReactNode {
    let { payload } = props
    payload = payload || []
    return (
      <div className="shypple-custom-legend">
        {payload.map((entry, index) => (
          <div key={index}>
            <div
              className="shypple-custom-legend__color"
              style={{ borderColor: entry.color }}
            />
            <span className="shypple-custom-legend__text"> {entry.value} </span>
          </div>
        ))}
      </div>
    )
  }

  @bind
  private async pickFilterOption(
    optionId: string,
    fieldName?: string
  ): Promise<any> {
    if (fieldName && this.state.filters[fieldName]) {
      await this.search({
        filters: {
          ...this.state.filters,
          [fieldName]: xor(this.state.filters[fieldName], [optionId]),
        },
      })
    }
  }

  @bind
  private async setSorting(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    sortBy: string
  ): Promise<any> {
    if (includes(['eta', 'etd'], sortBy)) {
      await this.search({
        filters: {
          ...this.state.filters,
          orderBy: sortBy,
        },
      })
    }
  }

  @bind
  private async handleDateChange(datesObject: IMonthsObject): Promise<any> {
    if (datesObject.from && datesObject.to) {
      await this.search({
        filters: {
          ...this.state.filters,
          startDate: datesObject.from,
          endDate: datesObject.to,
        },
      })
    }
  }

  @bind
  private async resetFiltersOfType(filterType: string): Promise<any> {
    await this.search({
      filters: {
        ...this.state.filters,
        [filterType]: [],
      },
    })
  }

  private async search(stateChanges: any = {}): Promise<any> {
    const newState = { ...this.state, ...stateChanges }
    this.setState({ ...stateChanges })
    try {
      await this.props.shipmentsCountsData({
        pol: newState.filters.pols,
        pod: newState.filters.pods,
        load_types: newState.filters.loadTypes,
        start_date: newState.filters.startDate,
        end_date: newState.filters.endDate,
        order_by: newState.filters.orderBy,
        status: newState.filters.status,
        carrier: newState.filters.carriers,
        consignee: newState.filters.consignees,
        shipper: newState.filters.shippers,
      })
    } catch (error) {
      Logger.error(error)
    }
  }

  private tickFormatter(x: string): string {
    return x.substring(0, 3)
  }

  @bind
  private yTickFormatter(x: number): number | string {
    return this.isFloat(x) ? '' : x
  }

  @bind
  private setChartDimensions(): void {
    const chartContainerWidth = document.getElementsByClassName(
      'shipments-count-container'
    )[0].clientWidth
    let chartWidth = Math.floor(chartContainerWidth * this.state.chartRatio)
    if (chartWidth > 1380) {
      chartWidth = 1380
    }
    const chartHeight = Math.floor(chartWidth * 0.34)

    this.setState({
      chartDimensions: { height: chartHeight, width: chartWidth },
    })
  }

  @bind
  private throttledSetChartDimensions(): void {
    if (!this.resizeThrottleTimeout) {
      this.resizeThrottleTimeout = setTimeout(() => {
        this.resizeThrottleTimeout = null
        this.setChartDimensions()
      }, 300)
    }

    if (window.outerWidth > 1200) {
      this.setState({
        legendDimensions: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'top',
        },
        chartRatio: 0.9,
      })
    } else {
      this.setState({
        legendDimensions: {
          layout: 'horizontal',
          align: 'left',
          verticalAlign: 'bottom',
        },
        chartRatio: 1.05,
      })
    }
  }

  private isFloat(n: number | string): boolean {
    return Number(n) === n && n % 1 !== 0
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ReportsShipmentsCount))

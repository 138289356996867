import { uniq } from 'lodash'

function orderValidation(order, productsState: string) {
  if (productsState === 'add') {
    const productNames: boolean[] = order.purchase_order_lines.map(
      (orderLine) => {
        return !!orderLine.product_name
      }
    )

    const productQuantities: boolean[] = order.purchase_order_lines.map(
      (orderLine) => {
        return !!orderLine.units_ordered
      }
    )

    const updatedProductNamesArr = uniq(productNames)
    const updatedProductQuantitiesArr = uniq(productQuantities)

    return (
      !!updatedProductNamesArr.length &&
      !!updatedProductNamesArr[0] &&
      !!updatedProductQuantitiesArr.length &&
      !!updatedProductQuantitiesArr[0] &&
      !!order.purchase_order_number
    )
  }

  return !!order.purchase_order_number
}

export { orderValidation }

import { FunctionComponent, useState, useEffect } from 'react'
import NumberFormat, { NumberFormatValues } from 'react-number-format'

interface IProps {
  className?: string
  placeholder?: string
  disabled?: boolean
  label?: string
  value?: string
  name?: string
  showError?: boolean
  errorMessage?: string | null
  onChange?: (fieldName: string | undefined, value: string | number) => void
  decimalScale?: number
  pureValue?: boolean
}

const Currency: FunctionComponent<IProps> = (props) => {
  const [showError, setShowError] = useState<boolean>(true)
  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(false)

  const onValueChange = (values: NumberFormatValues): void => {
    if (props.onChange) {
      props.onChange(
        props.name,
        props.pureValue ? values.floatValue : values.formattedValue
      )
    }
  }

  const handleFocus = (): void => {
    setShowError(false)
    setShowPlaceholder(true)
  }

  const handleBlur = (): void => {
    setShowError(true)
    setShowPlaceholder(false)
  }

  useEffect(() => {
    setShowError(true)
  }, [props.value])

  return (
    <div
      className={`text-input-form ${props.className} ${
        props.disabled ? 'disabled' : ''
      } ${props.errorMessage && showError ? 'invalid' : ''}`}
    >
      <div className="input-group">
        <NumberFormat
          className="text-input-field"
          name={props.name}
          disabled={props.disabled}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={showPlaceholder ? '1,000.00' : ' '}
          thousandSeparator={true}
          decimalScale={props.decimalScale || 2}
          value={props.value}
          required={true}
          onValueChange={onValueChange}
        />
        <span className="highlight" />
        <span className="bar" />
        <label className="text-input-label">{props.label}</label>
      </div>
      <div className="text-input-error">{showError && props.errorMessage}</div>
    </div>
  )
}

Currency.defaultProps = {
  disabled: false,
  showError: false,
  className: '',
  onChange: (): void => undefined,
}

export default Currency

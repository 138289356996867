import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Typography, Link as MuiLink } from '@mui/material'
import emptyPoImage from '../../../../assets/images/empty_po.png'

import './styles.scss'

const OrdersBlankTab: FunctionComponent<{}> = (props) => {
  const { t } = useTranslation()
  return (
    <div className="bookings--orders--blank">
      <div className="bookings--orders--blank_icon">
        <img src={emptyPoImage} alt="orders - blank" />
      </div>
      <div className="bookings--orders--blank_title">
        <Typography
          variant="h3"
          children={t(
            'shipments.bookings.no_orders',
            'There is nothing in here yet'
          )}
        />
      </div>
      <div className="bookings--orders--blank_content">
        <Typography
          variant="body1"
          children={t(
            'purchase_orders.shipment.bookings.empty_state.description',
            'Assign orders to this booking'
          )}
        />
      </div>
      <MuiLink
        component={Link}
        variant="body1"
        to={`/order-management?scope=pending`}
      >
        {t(
          'purchase_orders.shipment.bookings.empty_state.action_link',
          'Add orders'
        )}
      </MuiLink>
    </div>
  )
}

export default OrdersBlankTab

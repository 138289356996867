import { useState } from 'react'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import DoneIcon from '@mui/icons-material/Done'
import Zoom from '@mui/material/Zoom'
import { makeStyles } from '@mui/styles'
import { isString } from 'lodash'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles({
  container: {
    '&:hover .copy-wrapper-icons': {
      visibility: 'visible',
    },
  },
  icons: {
    marginLeft: 5,
    position: 'relative',
    width: 16,
    height: 16,
    visibility: 'hidden',
  },
  icon: {
    top: 0,
    width: 16,
    height: 16,
    position: 'absolute',
    cursor: 'pointer',
  },
})

interface Props {
  successMessage?: string
  children: React.ReactElement | string
  className?: string
}

const CopyWrapper = ({ successMessage, children, className = '' }: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles({ error: false })
  const [isCopied, setIsCopied] = useState(false)

  const getNodeText = (node: React.ReactElement | string): string => {
    if (isString(node)) {
      return node
    }
    if (node instanceof Array) {
      return node.map(getNodeText).join('')
    }
    if (typeof node === 'object' && node) {
      return getNodeText(node.props.children)
    }
    return ''
  }

  const copyText = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()

    const text: string = getNodeText(children)
    const input = document.createElement('input')
    input.setAttribute('value', text || '')
    document.body.appendChild(input)
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 1000)
    if (successMessage) {
      dispatch(
        showNotification({ message: successMessage, severity: 'success' })
      )
    }
  }

  return (
    <span className={`${classes.container} ${className}`}>
      <span>{children}</span>
      <span
        onClick={copyText}
        className={`${classes.icons} copy-wrapper-icons`}
      >
        <Zoom in={!isCopied}>
          <FileCopyOutlinedIcon className={classes.icon} />
        </Zoom>
        <Zoom in={isCopied}>
          <DoneIcon className={classes.icon} />
        </Zoom>
      </span>
    </span>
  )
}

export default CopyWrapper

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useSelector } from 'react-redux'
import { IShipmentsMapResponse } from 'src/@types/endpoints/maps'
import endpoints, { queryKeys } from '../endpoints'
import { apiClient } from '../http-common'
import { createQueryString } from '../utils'

export const useGetShipmentsTrack = (
  payload: object = {},
  options?: UseQueryOptions<
    IShipmentsMapResponse,
    AxiosError,
    IShipmentsMapResponse
  >
) => {
  const queryParams = createQueryString(payload)
  const url = endpoints.maps + queryParams
  const { maps: key } = queryKeys

  const getAccount = () => apiClient.get<any, IShipmentsMapResponse>(url)
  return useQuery<IShipmentsMapResponse, AxiosError>(
    [key, queryParams],
    getAccount,
    options
  )
}

export const useGetOnlyWatchedShipmentsTrack = () => {
  const { only_watched_shipments } = useSelector(
    ({ shipmentsWatchedBy }: IGlobalState) => ({
      only_watched_shipments: shipmentsWatchedBy.onlyWatchedShipments,
    })
  )
  return useGetShipmentsTrack({ only_watched_shipments })
}

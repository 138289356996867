import ReactGA from 'react-ga4'
import { flow } from 'lodash'

import Logger from '../utils/Logger'

interface IShyppleGaUser {
  id?: number | null
  organizationId?: number | null
  isDummy: boolean
  organizationRole: string
  customerState: string
}

interface ILocalGaSettings {
  initialized: boolean
}

// declare const window: ILocalWindow

class ShyppleGa {
  public user: IShyppleGaUser = {
    id: null,
    isDummy: false,
    organizationId: null,
    organizationRole: '',
    customerState: '',
  }

  private localGaSettings: ILocalGaSettings = {
    initialized: false,
  }

  private modifiers: Array<(pathname: string) => string> = [
    (pathname: string): string => {
      const shipmentRegexp = /\/shipments\/\d+\//
      return pathname.replace(shipmentRegexp, '/shipments/$SHIPMENT_ID/')
    },

    (pathname: string): string => {
      const contactRegexp = /\/contacts\/\d+\//
      return pathname.replace(contactRegexp, '/contacts/$CONTACT_ID/')
    },

    (pathname: string): string => {
      const poRegexp = /\/public-purchase-orders\/\S+/
      return pathname.replace(poRegexp, '/public-purchase-orders/$PO_ID')
    },

    (pathname: string): string => {
      const poRegexp = /\/public-orders\/\S+/
      return pathname.replace(poRegexp, '/public-orders/$PO_ID')
    },

    (pathname: string): string => {
      const publicShipmentsRegexp = /\/public-shipments\/\S+/
      return pathname.replace(
        publicShipmentsRegexp,
        '/public-shipments/$PUBLIC_SHIPMENT_ID'
      )
    },
  ]

  private filters: RegExp[] = [/^\/shipments\/\d+\/*$/, /^\/message_centre\/*$/]

  constructor() {
    Logger.log('Initializing ReactGa.')
    if (window.location.href.includes('app.shypple.com')) {
      ReactGA.initialize(window.shyppleConfig.googleAnalyticsKey)
      this._setToGa({ anonymizeIp: true })
      this.localGaSettings.initialized = true
    }
  }

  public pageview(pathname: string): void {
    if (!window.location.href.includes('app.shypple.com')) return
    const pageURL = this._applyModifiers(pathname)
    this._setToGa({ location: window.location.href })
    this._conditionalPageview(pageURL)
  }

  public modalview(modalPath: string): void {
    this._conditionalPageview(modalPath)
  }

  public setUserData(user: IUser): void {
    if (!window.location.href.includes('app.shypple.com')) return
    this.user.isDummy = user.isDummy
    this.user.id = user.id
    this.user.organizationId = user.organizationId
    this.user.organizationRole = user.organizationRole
    this.user.customerState = user.customerState
    this._setToGa({
      userId: this.user.id,
      dimension1: this.user.organizationId.toString(),
      dimension2: this.user.organizationRole.toString(),
      dimension3: this.user.customerState.toString(),
    })
    Logger.log('Assigned user data to Shypple GA:', this.user)
  }

  public clearUserData(): void {
    this.user.id = null
    this.user.organizationId = null
    this.user.isDummy = false
    this.user.organizationRole = ''
    this._setToGa({
      userId: this.user.id,
      dimension1: null,
      dimension2: null,
      dimension3: null,
    })
    Logger.log('Cleared user data from Shypple GA.')
  }

  private _applyModifiers(pathname: string): string {
    const redactedPathname = flow(this.modifiers)(pathname)
    return this.filters.some((regexp) => regexp.test(redactedPathname))
      ? null
      : redactedPathname
  }

  private _conditionalPageview(pathname: string | null): void {
    if (!pathname) {
      Logger.log('Pathname was filtered and not sent to GA.')
      return
    }

    if (this.user.isDummy) {
      Logger.log('Not reporting dummy user visit to GA:', pathname)
      return
    }

    if (!window.location.href.includes('app.shypple.com')) {
      Logger.log('(Imitating GA call) pathname:', pathname)
      return
    }

    Logger.log('Reporting a pageview:', pathname)
    ReactGA.send('pageview')
  }

  private _setToGa(object: any): void {
    if (this.localGaSettings.initialized) {
      ReactGA.set(object)
    }
  }
}

const shyppleGa = new ShyppleGa()
export default shyppleGa

import { FunctionComponent, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ContentDropDown from 'src/components/Common/ContentDropDown'
import BlankPurchaseOrderRow from 'src/components/PurchaseOrders/BlankPurchaseOrderRow'
import PurchaseOrderRowHeader from 'src/components/PurchaseOrders/PurchaseOrderRowHeader'
import PurchaseOrderRowDetails from 'src/components/PurchaseOrders/PurchaseOrderRowDetails'

import { purchaseOrdersOpenItem } from '../../../stores/actionCreators'

interface IPurchaseOrderRowProps {
  purchaseOrder: IPurchaseOrder
  index: number
  blank: boolean
  editPurchaseOrder: (purchaseOrder: IPurchaseOrder) => void
  deletePurchaseOrder: (purchaseOrder: IPurchaseOrder) => void
  forShipment?: boolean
  shipmentId?: number
  fetchData?: () => void
  isBuyer: boolean
  testId?: string
}

const PurchaseOrderRow: FunctionComponent<IPurchaseOrderRowProps> = (props) => {
  const { purchaseOrder, index, blank } = props
  const dispatch = useDispatch()

  const onDropdownOpen = useCallback(
    (id: number, isOpen: boolean) => {
      dispatch(purchaseOrdersOpenItem(isOpen ? id : null))
    },
    [purchaseOrder]
  )

  const { openItem } = useSelector((state: IGlobalState) => ({
    openItem: state.purchaseOrders.openItem,
  }))

  const editItem = useCallback(() => {
    props.editPurchaseOrder(purchaseOrder)
  }, [purchaseOrder])

  const deleteItem = useCallback(() => {
    props.deletePurchaseOrder(purchaseOrder)
  }, [purchaseOrder])

  if (blank) {
    return <BlankPurchaseOrderRow index={index} />
  } else {
    return (
      <ContentDropDown
        key={props.index}
        className="container-overview-block"
        testId={props.testId}
        header={
          <PurchaseOrderRowHeader
            item={purchaseOrder}
            opened={true}
            editItem={editItem}
            forShipment={props.forShipment}
          />
        }
        id={purchaseOrder.id}
        onToggle={onDropdownOpen}
        forcedOpen={openItem === purchaseOrder.id}
        headerCollapsed={
          <PurchaseOrderRowHeader
            item={purchaseOrder}
            opened={false}
            editItem={editItem}
            forShipment={props.forShipment}
          />
        }
        body={
          <PurchaseOrderRowDetails
            purchaseOrder={purchaseOrder}
            editItem={editItem}
            deleteItem={deleteItem}
            forShipment={props.forShipment}
            shipmentId={props.shipmentId}
            fetchData={props.fetchData}
            isBuyer={props.isBuyer}
          />
        }
        disableCollapse={false}
      />
    )
  }
}

export default PurchaseOrderRow

import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import ShipmentCostsQuoteRequestRateMatchDialogContent from './ShipmentCostsQuoteRequestRateMatchDialogContent'

const ShipmentCostsQuoteRequestRateMatch = ({ shipmentId }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  return (
    <>
      <Box mr={2.5} display="flex" alignItems="center">
        <Button
          onClick={openDialog}
          size="large"
          children={t(
            'common.buttons.request_rate_match',
            'Request rate match'
          )}
          variant="outlined"
        />
      </Box>
      <Dialog maxWidth="lg" open={open} onClose={closeDialog}>
        {open && (
          <ShipmentCostsQuoteRequestRateMatchDialogContent
            onClose={closeDialog}
            shipmentId={shipmentId}
          />
        )}
      </Dialog>
    </>
  )
}

export default ShipmentCostsQuoteRequestRateMatch

import { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Button, Typography } from '@mui/material'
import { find } from 'lodash'

import { Dialog, DialogContent } from 'src/stories/Dialogs'
import { TextArea } from 'src/stories/TextArea'
import { promisifyAction } from '../../utils'
import './styles.scss'

import {
  shipmentLayoutCancelShipment,
  shipmentLayoutGetRemarks,
} from '../../stores/actionCreators'

interface IProps {
  open: boolean
  close: () => void
  afterCancel: () => void
  shipmentId: number
}

const CancelShipmentModal: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const [remarkId, setRemarkId] = useState<number | null>(null)
  const [additionalRemark, setAdditionalRemark] = useState<string>('')
  const dispatch = useDispatch()

  const getRemarks = promisifyAction(dispatch, shipmentLayoutGetRemarks)
  const cancelShipment = promisifyAction(dispatch, shipmentLayoutCancelShipment)

  const { remarks } = useSelector((globalState: IGlobalState) => ({
    remarks: globalState.shipmentLayout.remarks,
  }))

  useEffect(() => {
    if (!props.open) {
      setRemarkId(null)
      setAdditionalRemark('')
    }
    if (props.open && !remarks.length) {
      getRemarks()
    }
  }, [props.open])

  const cancel = async (): Promise<void> => {
    if (remarkId) {
      await cancelShipment(props.shipmentId, {
        remark_id: remarkId,
        additional_remark: additionalRemark,
      })
      props.afterCancel()
    }
  }

  const handleChangeRemark = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setRemarkId(+event.target.value)
    if (checkRequiredRemark(+event.target.value)) {
      const area: HTMLAreaElement = document.getElementById(
        'textarea-field'
      ) as HTMLAreaElement
      area.focus()
    }
  }

  const handleChangeArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAdditionalRemark(event.target.value)
  }

  const checkRequiredRemark = (selectedId: number): boolean => {
    const remark: IRemark | undefined = find(remarks, { id: selectedId })
    return !!remark && remark.additional_remark_required
  }

  const disableSaveButton = (): boolean => {
    return !remarkId || (checkRequiredRemark(remarkId) && !additionalRemark)
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      title={t('shipment_costs.cancel_shipment.title', 'Cancel shipment')}
      maxWidth="lg"
      actions={
        <>
          <Button variant="text" onClick={props.close}>
            {t('common.buttons.cancel', 'Cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={cancel}
            disabled={disableSaveButton()}
          >
            {t('common.buttons.save', 'Save')}
          </Button>
        </>
      }
    >
      <DialogContent>
        <Typography variant="h5">
          {t(
            'shipment_costs.cancel_shipment.reason.title',
            'Why are you canceling your shipment?'
          )}
        </Typography>
        <RadioGroup
          className="cancel-shipmen-modal--radio-group"
          aria-label="gender"
          name="gender1"
          value={`${remarkId}`}
          onChange={handleChangeRemark}
        >
          {remarks.map((remark: any) => (
            <FormControlLabel
              key={remark.id}
              value={`${remark.id}`}
              control={<Radio />}
              label={remark.name}
            />
          ))}
        </RadioGroup>
        <TextArea
          className="cancel-shipmen-modal--suggestions"
          id="textarea-field"
          maxLength={400}
          name="cancelshipment"
          onChange={handleChangeArea}
          value={additionalRemark}
          placeholder={t(
            'shipment_costs.cancel_shipment.suggestions.placeholder',
            'Please type your suggestions'
          )}
          label={t(
            'shipment_costs.cancel_shipment.suggestions.label',
            'Do you have any suggestions to improve our services?'
          )}
        />
      </DialogContent>
    </Dialog>
  )
}
export default CancelShipmentModal

import {
  FunctionComponent,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
  MutableRefObject,
} from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Input from 'src/components/Common/Input/MuiInput'
import { Link } from '@mui/material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { showNotification } from 'src/stores/actionCreators/notifications'
import CropAvatarModal from 'src/components/CropAvatarModal'
import EditOrganizationAddressModal from 'src/components/EditOrganizationAddressModal'
import { InfoTooltip } from 'src/stories/MUI/Tooltip/InfoTooltip'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import {
  organizationGetData,
  getAllConnections,
  updatePreferredShipmentRole,
  shipmentsGetOrganizationRoles,
} from '../../../stores/actionCreators'
import { promisifyAction } from '../../../utils'
import './styles.scss'
import { organizationUploadLogo } from '../../../stores/actionCreators/organizations'

const OrganizationForm: FunctionComponent<{}> = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    organization,
    forwarders,
    organizationRoles,
    organizationRoleId,
    userAirId,
    userSeaId,
  } = useSelector(
    (state: IGlobalState) => ({
      organization: state.organizations.organization,
      forwarders: state.connections.list,
      organizationRoles: state.shipments.organizationRoles,
      organizationRoleId: state.user.organizationRoleId,
      userAirId: state.user.preferredAirFreightForwarderId,
      userSeaId: state.user.preferredSeaFreightForwarderId,
    }),
    shallowEqual
  )

  const [role, setRole] = useState<number | string>(
    organization?.preferred_shipment_role_id || ''
  )
  const [airForwarder, setAirForwarder] = useState<IShipmentConnection | null>(
    null
  )
  const [seaForwarder, setSeaForwarder] = useState<IShipmentConnection | null>(
    null
  )
  const [logoFile, setLogoFile] = useState<FileReader['result']>(null)
  const [addressEditModalOpen, setAddressEditModalOpen] = useState<boolean>(
    false
  )

  const ref: MutableRefObject<HTMLInputElement | null> = useRef(null)

  const organizationGetDataAsync = promisifyAction(
    dispatch,
    organizationGetData
  )
  const getForwardersAsync = promisifyAction(dispatch, getAllConnections)
  const getShipmentRolesAsync = promisifyAction(
    dispatch,
    shipmentsGetOrganizationRoles
  )

  const updatePreferredShipmentRoleAsync = promisifyAction(
    dispatch,
    updatePreferredShipmentRole
  )

  useEffect(() => {
    organizationGetDataAsync()
    getShipmentRolesAsync()
    getForwardersAsync({
      only_forwarders: true,
      search: '',
    })
  }, [])

  useEffect(() => {
    if (forwarders.length > 0) {
      setAirForwarder(
        forwarders.find((value) => value.organization_id === userAirId) || null
      )
      setSeaForwarder(
        forwarders.find((value) => value.organization_id === userSeaId) || null
      )
    }
  }, [forwarders])

  useEffect(() => {
    setRole(organization?.preferred_shipment_role_id || '')
  }, [organization.preferred_shipment_role_id])

  const shipmentRoles: any = useMemo(() => {
    const currentOrg: IOrganizationRoles | undefined = organizationRoles.find(
      (role: IOrganizationRoles) => {
        return role.id === organizationRoleId
      }
    )

    return currentOrg
      ? currentOrg.shipment_roles.map((role) => ({
          id: role.id,
          label: role.name,
        }))
      : []
  }, [organizationRoles])

  const onFileUpload = useCallback((event) => {
    const input = event.target
    const file = input.files ? input.files[0] : null
    const reader = new FileReader()

    reader.onloadend = () => {
      setLogoFile(reader.result)
    }

    if (file) {
      reader.readAsDataURL(file)
    } else {
      setLogoFile(null)
    }
  }, [])

  const onEditClick = useCallback(() => {
    ref.current && ref.current.click()
  }, [])

  const addressBlock = useMemo(() => {
    const address = organization.default_address

    if (!address) {
      return (
        <div className="organization-form__default_address__blank">
          <i className="icon plus" />
          <div>{t('address_book.connections.add_address', 'Add address')}</div>
        </div>
      )
    } else {
      return (
        <div className="organization-form__default_address__address_details">
          <Typography variant="body1Strong" children={address.name} />
          <Typography variant="body1" children={address.address} />
          <Typography variant="body1">
            {address.postal_code} {address.city}
          </Typography>
          <Typography variant="body1" children={address.country.name} />
        </div>
      )
    }
  }, [organization.default_address])

  const tooltipContent = useMemo(() => {
    return (
      <div>
        <div className="organization-form__tooltip-role">
          <div
            className="organization-form__tooltip-title"
            children="Lead Forwarder"
          />

          <span className="organization-form__tooltip-text">
            The organization that makes arrangements at the destination.
          </span>
        </div>
        <div className="organization-form__tooltip-role">
          <div
            className="organization-form__tooltip-title"
            children="Destination Forwarder"
          />
          <span className="organization-form__tooltip-text">
            The organization that makes arrangements at the destination.
          </span>
        </div>
        <div className="organization-form__tooltip-role">
          <div
            className="organization-form__tooltip-title"
            children="Origin Forwarder"
          />
          <span className="organization-form__tooltip-text">
            The forwarder/agent that orchestrates the shipment at origin port.
          </span>
        </div>
        <div className="organization-form__tooltip-role">
          <div
            className="organization-form__tooltip-title"
            children="Shipper"
          />
          <span className="organization-form__tooltip-text">
            The supplier of the goods.
          </span>
        </div>
        <div className="organization-form__tooltip-role">
          <div
            className="organization-form__tooltip-title"
            children="Consignee"
          />
          <span className="organization-form__tooltip-text">
            This organization that has bought the goods.
          </span>
        </div>
        <div className="organization-form__tooltip-role">
          <div
            className="organization-form__tooltip-title"
            children="Notify Party"
          />
          <span className="organization-form__tooltip-text">
            Any organization that needs to be notified about the arrival of the
            shipment.
          </span>
        </div>
      </div>
    )
  }, [])

  const onShipmentRoleChange = useCallback(
    async (event) => {
      const { value } = event.target
      setRole(value)
      await updatePreferredShipmentRoleAsync({ role_id: value })
      dispatch(
        showNotification({
          message: 'Shipment role updated successfully',
          severity: 'success',
        })
      )
    },
    [shipmentRoles]
  )

  return (
    <div className="organization-form">
      <div className="organization-form__name">
        <Typography variant="h3" children={organization.name} />
      </div>
      <div className="organization-form__logo">
        <div className="organization-form__logo__image">
          <Avatar
            alt={organization.name}
            src={organization.logo || 'no-logo'}
            className={`${organization.role_code} organization-logo`}
          />
        </div>

        <div className="organization-form__logo__edit-link">
          <Link component="button" variant="body1" onClick={onEditClick}>
            {t('organization_settings.edit_logo', 'Edit logo')}
          </Link>
        </div>

        <input
          ref={ref}
          type="file"
          id="selectedFile"
          className="hidden"
          onChange={onFileUpload}
          accept="image/png, image/jpeg"
        />
        <CropAvatarModal
          logo={logoFile}
          handleClose={() => {
            setLogoFile(null)
          }}
          update={organizationUploadLogo}
          fetch={organizationGetData}
        />
      </div>

      <div
        className="organization-form__default_address"
        onClick={() => {
          setAddressEditModalOpen(true)
        }}
      >
        {addressBlock}
      </div>
      <div>
        <label className="organization-form__preferred-role">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="body1Strong"
              children={t(
                'organization_settings.preferred_shipment_role',
                'Preferred Shipment Role'
              )}
            />
            <InfoTooltip
              title={tooltipContent}
              placement="right-start"
              classes={{
                tooltip: 'roles-tooltip mui-override',
              }}
            />
          </Box>
        </label>
        <div className="organization-form__shipment-role">
          <SingleSelect
            value={role}
            placeholder="Select Shipment role"
            options={shipmentRoles}
            onChange={onShipmentRoleChange}
            data-testid="shipment-role-select"
          />
        </div>
      </div>
      <div>
        <Typography
          variant="body1Strong"
          children={t(
            'organization_settings.preferred_sea_forwarder',
            'Preferred Sea Forwarder'
          )}
        />
        <div className="organization-form__preferred_forwarder block x-long">
          <Input
            name="description"
            className="input-field"
            placeholder="Type description"
            value={seaForwarder ? seaForwarder.name : 'Non Applicable'}
            data-testid="sea-field"
            disabled={true}
          />
        </div>
        <Typography
          variant="body1Strong"
          children={t(
            'organization_settings.preferred_air_forwarder',
            'Preferred Air Forwarder'
          )}
        />
        <div className="organization-form__preferred_forwarder block x-long">
          <Input
            name="description"
            className="input-field"
            placeholder="Type description"
            value={airForwarder ? airForwarder.name : 'Non Applicable'}
            data-testid="air-field"
            disabled={true}
          />
        </div>
      </div>
      <EditOrganizationAddressModal
        open={addressEditModalOpen}
        address={organization.default_address}
        handleClose={() => {
          setAddressEditModalOpen(false)
        }}
      />
    </div>
  )
}

export default OrganizationForm

import { FunctionComponent } from 'react'
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux'

import { convertDatePickerToUniversalFormat } from '../../../../utils/helpers'
import BookingEventTemplateDocument from './BookingEventTemplateDocument'

import './styles.scss'

interface IProps {
  templateName: string
  templateVariables: IBookingTemplateVariables
}

const BookingEventTemplate: FunctionComponent<IProps> = (props) => {
  const { userOrganizationId } = useSelector((globalState: IGlobalState) => ({
    userOrganizationId: globalState.user.organizationId,
  }))

  const fromPart: string = props.templateVariables.previous_value
    ? ` from ${props.templateVariables.previous_value}`
    : ''

  const uploadedDocumentProps = {
    userOrganizationId,
    templateVariables: props.templateVariables,
  }

  const renderTemplate = (): React.ReactNode => {
    switch (props.templateName) {
      case 'booking_created':
        return <>Booking created</>
      case 'booking_reference_updated':
        return (
          <>
            Booking reference updated{fromPart} to{' '}
            {props.templateVariables.updated_value}
          </>
        )
      case 'booking_removed':
        return <>Booking removed</>
      case 'shipment_updated':
        return (
          <>
            Shipment updated{fromPart} to{' '}
            {props.templateVariables.updated_value}
          </>
        )
      case 'cargo_ready_date_updated':
        const cargoFromPart: string = props.templateVariables.previous_value
          ? ` from ${convertDatePickerToUniversalFormat(
              props.templateVariables.previous_value
            )}`
          : ''
        return (
          <>
            Cargo ready date updated{cargoFromPart} to{' '}
            {convertDatePickerToUniversalFormat(
              props.templateVariables.updated_value
            )}
          </>
        )
      case 'document_uploaded':
        return (
          <>
            <BookingEventTemplateDocument {...uploadedDocumentProps} /> uploaded
          </>
        )
      case 'document_removed':
        return (
          <>
            <BookingEventTemplateDocument {...uploadedDocumentProps} /> removed
            from this booking
          </>
        )
      case 'shipper_updated':
        return (
          <>
            Shipper updated{fromPart} to {props.templateVariables.updated_value}
          </>
        )
      case 'consignee_updated':
        return (
          <>
            Consignee updated{fromPart} to{' '}
            {props.templateVariables.updated_value}
          </>
        )
      case 'notify_party_updated':
        return (
          <>
            Notify party updated{fromPart} to{' '}
            {props.templateVariables.updated_value}
          </>
        )
      case 'document_type_updated':
        return (
          <>
            {props.templateVariables.previous_value} removed,{' '}
            <BookingEventTemplateDocument {...uploadedDocumentProps} /> added
          </>
        )
      case 'document_deleted':
        return <>{props.templateVariables.previous_value} deleted</>
      default:
        return null
    }
  }

  return (
    <div className="booking-event__template">
      <Typography variant="body1Strong" children={renderTemplate()} />
    </div>
  )
}

export default BookingEventTemplate

import { Divider } from '@mui/material'
import { Stack } from '@mui/system'
import { sortBy } from 'lodash'
import NotificationsContentListItem from './NotificationsContentListItem'
import NotificationsContentEmptyState from './NotificationsContentEmptyState'

interface NotificationsContentNotificationsProps {
  list: INavigationNotification[]
  onClickNotification: () => {}
}

const NotificationsContentList: React.FC<NotificationsContentNotificationsProps> = ({
  list,
  onClickNotification,
}) => {
  const sortedList = sortBy(list, 'created_at')

  if (sortedList.length) {
    return (
      <Stack divider={<Divider orientation="horizontal" flexItem />}>
        {sortedList.map((notification: INavigationNotification) => (
          <NotificationsContentListItem
            key={notification.id}
            notification={notification}
            onClick={onClickNotification}
          />
        ))}
      </Stack>
    )
  }

  return <NotificationsContentEmptyState />
}

export default NotificationsContentList

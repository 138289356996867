import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Tooltip from '@mui/material/Tooltip'
import { services } from 'src/pages/SearchAndBook/constants'
import { ModalityEnum } from 'src/config/constants'
import { defaultServiceValues } from 'src/components/Templates/Form/Form.constants'

type SimpleServices = { [key: string]: boolean }

interface Props {
  initialSelectedServices?: SimpleServices
  onChange: (services: SimpleServices) => void
  modality: ModalityEnum
  disabledFreight?: boolean
  childDataTestId?: string
}

const Services = (props: Props) => {
  const {
    initialSelectedServices,
    onChange,
    modality,
    disabledFreight = false,
    childDataTestId = 'form-services',
  } = props
  const { t } = useTranslation()
  const [selectedServices, setSelectedServices] = useState(defaultServiceValues)
  const [shipmentServices, setShipmentServices] = useState(services(modality))

  useEffect(() => {
    if (initialSelectedServices) {
      setSelectedServices(initialSelectedServices)
    }

    setShipmentServices(services(modality))

    if (disabledFreight) {
      setShipmentServices(
        shipmentServices.map((service) => {
          if (service.value === 'freight') {
            service.disabled = true
          }
          return service
        })
      )
    }
  }, [initialSelectedServices, disabledFreight, modality])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target
    const updatedSelectedService = { ...selectedServices, [value]: checked }
    setSelectedServices(updatedSelectedService)
    onChange(updatedSelectedService)
  }

  return (
    <>
      <FormControl component="fieldset">
        <FormGroup aria-label="position" row onChange={handleChange}>
          {shipmentServices.map((service) => (
            <Tooltip title={t(service.tooltip)} key={service.value}>
              <FormControlLabel
                value={service.value}
                label={t(service.label, service.value)}
                disabled={service.disabled}
                control={<Checkbox />}
                labelPlacement="bottom"
                data-testid={`${childDataTestId}-${service.value}`}
                checked={selectedServices?.[service.value] ?? false}
                sx={{ mx: 1, width: 92, textAlign: 'center' }}
              />
            </Tooltip>
          ))}
        </FormGroup>
      </FormControl>
    </>
  )
}

export default Services

import { Dispatch } from 'redux'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useEffect } from 'react'
import { parse, stringify } from 'query-string'
import { replace } from 'connected-react-router'
import { apiClient } from 'src/services/http-common'

import { organizationsGetData } from '../../stores/actionCreators'
import { promisifyAction } from '../../utils'

export default function useScopedOrganizationFromUrl(setOrganization) {
  const dispatch: Dispatch = useDispatch()
  const store = useStore()

  const { user } = useSelector((globalState: IGlobalState) => ({
    user: globalState.user,
  }))

  const organizationsGetDataAsync = promisifyAction(
    dispatch,
    organizationsGetData
  )

  const location: Location = window.location

  const getScopedOrganizationIdFromURL = (location: any): any | null => {
    if (location && location.search) {
      return parse(location.search)['scoped_organization_id'] || null
    }
    return null
  }

  const removeOrganizationIdFromURL = (location: Location): string => {
    const parsedLocation: any = parse(location.search)
    parsedLocation.scoped_organization_id = undefined
    return `${location.pathname}?${stringify(parsedLocation)}`
  }

  const setOrganizationFromURL = async (scopedOrganizationId: number) => {
    await organizationsGetDataAsync({
      search: scopedOrganizationId,
      only_ids: true,
    })
    const result: IOrganization[] = store.getState().organizations.list
    const organizationToSet: IOrganization | null =
      result.length === 1 ? result[0] : null // to avoid endless loop
    setOrganization(organizationToSet)
  }

  useEffect(() => {
    if (!user.uid) {
      return
    }
    const scopedOrganizationId = getScopedOrganizationIdFromURL(location)

    if (user.organizationRole === 'shypple_admin' && scopedOrganizationId) {
      // To make other request start using this organization ID for request
      // and not waiting till we fetch proper organization name.
      setOrganization({ id: scopedOrganizationId, name: '', prefix: '' })

      // ...And finally setting organization name for the select.
      setOrganizationFromURL(scopedOrganizationId)
      apiClient.defaults.headers.common[
        'scoped-organization-id'
      ] = scopedOrganizationId
    }

    const redirectPath: string =
      removeOrganizationIdFromURL(location) + location.hash
    dispatch(replace(redirectPath))
  }, [user.uid])
}

import Box from '@mui/material/Box'
import { ShipmentStatusProps } from '../shipmentContentProps'
import { ShipmentStatusChip } from '../components/ShipmentStatusChip'
import { ShipmentStatusMenu } from '../components/ShipmentStatusMenu'

export const ShipmentStatus: React.FC<ShipmentStatusProps> = (props) => {
  const {
    status,
    updating,
    statuses,
    statusMenuVisible,
    onStatusChange,
  } = props
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: props.align,
      }}
    >
      {statusMenuVisible && (
        <ShipmentStatusMenu
          status={status}
          statuses={statuses}
          updating={updating}
          onStatusChange={onStatusChange}
        />
      )}
      {!statusMenuVisible && (
        <ShipmentStatusChip phase={status.phase} label={status.name} />
      )}
    </Box>
  )
}

import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { flagsWindowActive } from '../../stores/actionCreators'

// should be mounted ONCE at the application level.
export default function useWindowFocus(): boolean {
  const [active, setActive] = useState<boolean>(document.hasFocus())
  const dispatch: Dispatch = useDispatch()
  const focusListener = (): void => {
    setActive(true)
    dispatch(flagsWindowActive(true))
  }

  const blurListener = (): void => {
    setActive(false)
    dispatch(flagsWindowActive(false))
  }

  useEffect(() => {
    window.addEventListener('focus', focusListener)
    window.addEventListener('blur', blurListener)
    return () => {
      window.removeEventListener('focus', focusListener)
      window.removeEventListener('blur', blurListener)
    }
  }, [])

  return active
}

import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { history } from 'src/shyppleStore'
import { routes } from 'src/pages/AddressBook'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { personalDetailsAddPersonalDetail } from '../../../stores/actionCreators'
import { promisifyAction } from '../../../utils'

const SaveContactButton = ({
  disabled,
  onClick,
}: {
  disabled?: boolean
  onClick: (
    saveContact: (contact: IPersonalDetail) => void,
    showError: (message: string) => void
  ) => void
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const showError = (text: string) =>
    dispatch(showNotification({ message: text, severity: 'error' }))
  const createContact = promisifyAction(
    dispatch,
    personalDetailsAddPersonalDetail
  )
  const saveContact = async (contact: IPersonalDetail) => {
    await createContact(contact)
    history.push(routes.persons)
    dispatch(
      showNotification({
        message: `Contact "${contact.name}" added.`,
        severity: 'success',
      })
    )
  }

  return (
    <Button
      disabled={disabled}
      variant="contained"
      color="primary"
      disableElevation
      onClick={() => onClick(saveContact, showError)}
    >
      {t('address_book.add_contact.save_contact')}
    </Button>
  )
}

export default SaveContactButton

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import { memo, Fragment } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: theme.palette.grey[50],
    '& th': {
      fontWeight: 600,
      fontSize: 12,
      textTransform: 'uppercase',
      color: theme.palette.grey[500],
      [theme.breakpoints.down('md')]: {
        fontSize: 10,
      },
    },
  },
  tableBody: {
    '& td, & th p': {
      fontSize: 16,
      [theme.breakpoints.down('md')]: {
        fontSize: 14,
      },
    },
  },
  currency: {
    color: theme.palette.grey[500],
    display: 'inline-block',
  },
  rowSubTitle: {
    paddingLeft: 40,
  },
  xMark: {
    position: 'absolute',
    left: -20,
  },
  rowSummary: {
    borderTop: `2px solid ${theme.palette.grey[500]}`,
  },
}))

interface ProposedRevenuesProps {
  quotation: IShipmentQuotation
}

const ProposedRevenues = memo(
  ({
    quotation: { proposed_revenues, costs_total_usd, costs_total_eur },
  }: ProposedRevenuesProps) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
      <TableContainer
        component={(props) => <Paper {...props} square elevation={0} />}
      >
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>
                {t('shipment_costs.table_header.service_item', 'Service Item')}
              </TableCell>
              <TableCell align="right">
                {t('shipment_costs.table_header.quantity', 'Quantity')}
              </TableCell>
              <TableCell align="right">
                {t('shipment_costs.table_header.unit_price', 'Unit Price')}
              </TableCell>
              <TableCell align="right">
                {t('shipment_costs.table_header.total_usd', 'Total USD')}
              </TableCell>
              <TableCell align="right">
                {t('shipment_costs.table_header.total_eur', 'Total EUR')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {proposed_revenues.map((revenue) => (
              <Fragment key={revenue.name}>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <b>{revenue.name}</b>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
                {revenue.items.map((item) => (
                  <TableRow key={item.service_item_name}>
                    <TableCell
                      className={classes.rowSubTitle}
                      component="th"
                      scope="row"
                    >
                      <Typography>{item.service_item_name}</Typography>
                      <Typography>
                        <i>{item.description}</i>
                      </Typography>
                    </TableCell>
                    <TableCell align="right">{item.quantity}</TableCell>
                    <TableCell align="right">
                      <Box position="relative">
                        <Box className={classes.xMark}>×</Box>
                      </Box>
                      <Box whiteSpace="nowrap">
                        {item.unit_price}{' '}
                        <Box className={classes.currency}>{item.currency}</Box>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Box whiteSpace="nowrap">
                        {item.total_value_usd}{' '}
                        <Box className={classes.currency}>
                          {t('common.currencies.usd', 'USD')}
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Box whiteSpace="nowrap">
                        {item.total_value_eur}{' '}
                        <Box className={classes.currency}>
                          {t('common.currencies.eur', 'EUR')}
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </Fragment>
            ))}
            <TableRow className={classes.rowSummary}>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell align="right">
                <Box whiteSpace="nowrap">
                  <b>{costs_total_usd}</b>{' '}
                  <Box className={classes.currency}>
                    {t('common.currencies.usd', 'USD')}
                  </Box>
                </Box>
              </TableCell>
              <TableCell align="right">
                <Box whiteSpace="nowrap">
                  <b>{costs_total_eur}</b>{' '}
                  <Box className={classes.currency}>
                    {' '}
                    {t('common.currencies.eur', 'EUR')}
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  },
  (prevProps, nextProps) =>
    prevProps.quotation.costs_total_eur === nextProps.quotation.costs_total_eur
)

export default ProposedRevenues

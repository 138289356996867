import { Link } from 'react-router-dom'
import { Link as MuiLink } from '@mui/material'
import Box from '@mui/material/Box'
import { getPriceValue } from 'src/services/Api/demurrageAndDetention/utils'
import { useTranslation } from 'react-i18next'

const ContainerDemurrageAndDetentionItemPrice = ({
  price,
  linkText,
  addSuffix,
}) => {
  const { t } = useTranslation()
  const formattedPrice = getPriceValue(price, false)
  const hasPriceValue = price.note === null
  return (
    <Box data-testid="container-demurrage-and-detention-item-price">
      {hasPriceValue && (
        <>
          {formattedPrice}
          {addSuffix && ',-'}
        </>
      )}
      {!hasPriceValue && (
        <>
          {linkText}
          <MuiLink variant="body1" component={Link} to="/demurrage-detention">
            {t(
              'shipment_containers.demurrage_and_detention.on_request',
              'on request'
            )}
          </MuiLink>
        </>
      )}
    </Box>
  )
}

export default ContainerDemurrageAndDetentionItemPrice

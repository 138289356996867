import { FunctionComponent } from 'react'
import ContainerEvent from './ContainerEvent'
import './styles.scss'

interface IProps {
  container_events: IContainerEvent[]
}

const ContainerEventsBlock: FunctionComponent<IProps> = (props) => {
  return (
    <div className="purchase-order-events-container">
      {props.container_events.map((event: IContainerEvent) => (
        <ContainerEvent event={event} key={event.id} />
      ))}
    </div>
  )
}

export default ContainerEventsBlock

import { ShipmentModalityIcon } from 'src/stories/MUI/ShipmentContent/components/ShipmentModalityIcon'
import { StyledStepBox } from './SABSearchItem.styles'
import { SABSearchItemTimelineModalityIconProps } from './SABSearchItem.props'

const SABSearchItemTimelineModalityIcon: React.FC<SABSearchItemTimelineModalityIconProps> = ({
  modality,
}) => {
  return (
    <StyledStepBox>
      <ShipmentModalityIcon modality={modality} />
    </StyledStepBox>
  )
}

export default SABSearchItemTimelineModalityIcon

import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import * as actions from '../actions/messageCenter'

declare global {
  interface IMessagesState {
    approvals: {
      comments: IComment[]
    }
    mentions: {
      comments: IComment[]
    }
    unread: any
    saved: {
      comments: IComment[]
    }
    counters: any
    totalUnread: number
    fetchingUnread: boolean
  }

  type IMessages = any

  interface IParentComment {
    author: string
    author_type: string
    comment_attachments: ICommentAttachment[]
    content: string
  }
}

export const initialMessagesState: IMessagesState = {
  approvals: {
    comments: [],
  },
  mentions: {
    comments: [],
  },
  unread: [],
  saved: {
    comments: [],
  },
  counters: {},
  totalUnread: 0,
  fetchingUnread: false,
}

const receiveApprovals: Reducer<IMessagesState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  approvals: action.payload,
})

const receiveSaved: Reducer<IMessagesState, AnyAction> = (state, action) => ({
  ...state,
  saved: action.payload,
})
const receiveMentions: Reducer<IMessagesState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  mentions: action.payload,
})

const fetchUnread: Reducer<IMessagesState, AnyAction> = (state) => ({
  ...state,
  fetchingUnread: true,
})

const receiveUnread: Reducer<IMessagesState, AnyAction> = (state, action) => ({
  ...state,
  unread: action.payload.shipments,
  totalUnread: action.payload.total_shipments,
  fetchingUnread: false,
})

const receiveCounters: Reducer<IMessagesState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  counters: action.payload.counters,
})

export default createReducer(initialMessagesState, {
  [actions.APPROVALS_GET_DATA_SUCCESS]: receiveApprovals,
  [actions.MENTIONS_GET_DATA_SUCCESS]: receiveMentions,
  [actions.GET_SAVED_MESSAGES_SUCCESS]: receiveSaved,
  [actions.UNREAD_GET_DATA]: fetchUnread,
  [actions.UNREAD_GET_DATA_SUCCESS]: receiveUnread,
  [actions.COUNTERS_GET_DATA_SUCCESS]: receiveCounters,
  [actions.SET_MESSAGES_COUNTERS]: receiveCounters,
})

export const getAddressesURL = () => 'api/v1/addresses/'
export const getOneAddressURL = (action) => `api/v1/addresses/${action.id}`
export const getOrganizationAddressesURL = (action) =>
  `api/v1/organizations/${action.payload.organization_id}/addresses`
export const getDefaultAddressUrl = (action) =>
  `api/v1/addresses/${action.payload.id}/set_default`

export const responseData = (response) => response.data

export default {}

import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import MuiLink from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import UnarchiveIcon from '@mui/icons-material/Unarchive'
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab'
import { capitalize } from 'lodash'

import { convertDateToTimeRange } from '../../utils/helpers'

const statusColors = {
  estimated: 'secondary',
  proposed: 'warning',
  requested: 'info',
  misc: 'success',
}

type ShipmentReferenceType = string | null
interface RowProps {
  row: IPickupAndDeliveryItem
}
const classNameRoot = 'pickup-and-delivery'

const getShipmentReference = (refs: ShipmentReferenceType[]) => {
  const filtered = refs.filter((ref) => !!ref)
  return filtered.length ? filtered.join(' - ') : '-'
}

const getTimeRangeSuffix = (rowProps: RowProps) => {
  const { row } = rowProps
  const { status } = row
  if (
    (status === 'estimated' && row.pickup_delivery_time_editable) ||
    status === 'proposed'
  ) {
    const text = status === 'estimated' ? `Plan ${row.service}` : 'Confirm'
    return (
      <Box component="span" ml={2}>
        <TableCellLink
          text={text}
          rowProps={rowProps}
          redirectToContainer={true}
        />
      </Box>
    )
  }
  return ''
}

const TableCellLink = ({ text, rowProps, redirectToContainer }) => (
  <MuiLink
    title={text}
    component={Link}
    to={`/shipments/${rowProps.row.shipment.id}${
      redirectToContainer ? `/containers#${rowProps.row.container.id}` : ''
    }`}
  >
    {text}
  </MuiLink>
)

export const columns = {
  service: {
    name: '',
    className: `${classNameRoot}--service`,
    component: (rowProps: RowProps) => {
      const IconTagName =
        rowProps.row.service === 'delivery' ? KeyboardTabIcon : UnarchiveIcon
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip
            placement="right"
            sx={{ cursor: 'pointer' }}
            title={capitalize(rowProps.row.service)}
          >
            <IconTagName />
          </Tooltip>
        </Box>
      )
    },
  },
  type: {
    name: 'TYPE',
    component: (rowProps: RowProps) => {
      const lcl = 'LCL'
      return (
        <TableCellLink
          rowProps={rowProps}
          redirectToContainer={true}
          text={
            rowProps.row.shipment_details.load_type.toLowerCase() ===
            lcl.toLowerCase()
              ? lcl
              : rowProps.row.container.type_name
          }
        />
      )
    },
  },
  container: {
    name: 'CONTAINER NR',
    component: (rowProps: RowProps) => {
      return (
        <TableCellLink
          rowProps={rowProps}
          redirectToContainer={true}
          text={rowProps.row.container.number ?? '-'}
        />
      )
    },
  },
  shipment: {
    name: 'SHIPMENT',
    className: `${classNameRoot}--shipment`,
    component: (rowProps: RowProps) => {
      return (
        <TableCellLink
          rowProps={rowProps}
          redirectToContainer={false}
          text={rowProps.row.shipment.our_reference}
        />
      )
    },
  },
  reference: {
    name: 'REFERENCE',
    className: `${classNameRoot}--reference`,
    component: (rowProps: RowProps) => {
      const {
        internal_reference: internalReference,
        reference,
      } = rowProps.row.shipment
      return (
        <TableCellLink
          rowProps={rowProps}
          redirectToContainer={false}
          text={getShipmentReference([internalReference, reference])}
        />
      )
    },
  },
  status: {
    name: 'STATUS',
    className: `${classNameRoot}--status`,
    component: (rowProps: RowProps) => {
      const { status } = rowProps.row
      const color = statusColors?.[status] ?? statusColors.misc
      return <Chip size="small" color={color} label={capitalize(status)} />
    },
  },
  time: {
    name: 'TIME',
    className: `${classNameRoot}--range`,
    component: (rowProps: RowProps) => {
      const { row } = rowProps
      return (
        <>
          <Typography variant="body1Strong" component="span">
            {convertDateToTimeRange(
              row?.pickup_delivery_time,
              row?.closing_time
            )}
          </Typography>
          {getTimeRangeSuffix(rowProps)}
        </>
      )
    },
  },
  addresses: {
    name: 'ADDRESSES',
    className: `${classNameRoot}--addresses`,
    component: (rowProps: RowProps) => {
      const { inland_transport_addresses: inlandAdresses } = rowProps.row
      const addresses = inlandAdresses.reduce((filtered: string[], item) => {
        if (item.address && item.pickup_delivery) {
          filtered.push(item.address)
        }
        return filtered
      }, [])
      const text = addresses.length ? addresses.join(', ') : 'Add address'
      return (
        <TableCellLink
          text={text}
          rowProps={rowProps}
          redirectToContainer={true}
        />
      )
    },
  },
}

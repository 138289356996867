import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system'
import { Fade, Link, Popper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { convertDateToDateWithoutYear } from 'src/utils/helpers/shipmentDate'
import { Avatar, ShyppleAvatarProps } from './Avatar'

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    minHeight: '151px',
    height: 'auto',
    minWidth: '254px',
    width: 'auto',
    borderRadius: theme.shape.borderRadius,
    border: `${theme.palette.grey[300]} 1px solid`,
  },
  cardContent: {
    display: 'flex',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
  },
}))

export interface IUserCardInfo {
  name?: string | null
  department?: string | null
  company?: string | null
  phone?: string | null
  out_of_office_till?: string | null
}

export interface UserCardProps extends ShyppleAvatarProps {
  show: boolean
  anchorEl: HTMLAnchorElement | null
  role?: OrganizationRole | string
}

const UserCard: React.FC<UserCardProps> = ({
  show,
  src,
  alt,
  anchorEl,
  role = '',
  userInfo,
  doubleInitials,
  disablePopper,
  placement,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const phoneWithoutSpacing: string | undefined = userInfo?.phone
    ? userInfo?.phone.replace(/\s/g, '')
    : undefined

  const isEmptyField = (
    value: string | number | null | undefined
  ): string | number => (value ? value : '-')

  const userCardElement: JSX.Element = (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Avatar
          size="large"
          src={src}
          alt={alt}
          className={role}
          doubleInitials={doubleInitials}
          userInfo={userInfo}
        />
        <Box className={classes.wrapper}>
          <Typography variant="h5" children={isEmptyField(userInfo?.name)} />
          <Typography variant="body2">
            {isEmptyField(userInfo?.department)}
          </Typography>
          <Typography variant="subtitle1">
            {isEmptyField(userInfo?.company)}
          </Typography>
          <br />
          {userInfo?.out_of_office_till && (
            <>
              <Typography
                variant="h5"
                children={t('common.user_card.ooo', 'Out of office')}
              />
              <Typography variant="body2">
                {t('common.user_card.ooo_until', 'Until')}{' '}
                {convertDateToDateWithoutYear(userInfo?.out_of_office_till)}
              </Typography>
              <br />
            </>
          )}
          <Typography
            variant="h5"
            children={t('common.user_card.phone_number', 'Phone number')}
          />
          <Typography variant="body2">
            {phoneWithoutSpacing ? (
              <Link href={`tel:${phoneWithoutSpacing}`}>{userInfo?.phone}</Link>
            ) : (
              '-'
            )}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )

  if (disablePopper) return userCardElement

  return (
    <Popper
      open={show}
      id={src || alt}
      anchorEl={anchorEl}
      placement={placement ? placement : 'auto'}
      style={{ zIndex: 8 }}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={250}>
          {userCardElement}
        </Fade>
      )}
    </Popper>
  )
}

export default UserCard

import Chip from '@mui/material/Chip'
import { useTranslation } from 'react-i18next'
import DeliveryInfoItem from './DeliveryInfoItem'
import { DeliveryInfoItemFirstDayOfDemurrageProps } from './DeliveryInfo.props'

const DeliveryInfoItemFirstDayOfDemurrage: React.FC<DeliveryInfoItemFirstDayOfDemurrageProps> = ({
  actualFirstDayOfDemurrage,
  destinationDemurrageStartingFrom,
}) => {
  const { t } = useTranslation()
  const firstDayOfDemurrage =
    actualFirstDayOfDemurrage ?? destinationDemurrageStartingFrom
  return (
    <DeliveryInfoItem
      date={firstDayOfDemurrage}
      title={t(
        'transports.drawer.first_day_of_demurrage',
        'First day of demurrage'
      )}
    >
      {firstDayOfDemurrage && (
        <Chip
          size="small"
          sx={{ ml: 0.5 }}
          data-testid="delivery-info-item-chip"
          label={
            actualFirstDayOfDemurrage
              ? t('transports.drawer.actual', 'Actual')
              : t('transports.drawer.estimated', 'Estimated')
          }
          color={actualFirstDayOfDemurrage ? 'success' : 'secondary'}
        />
      )}
    </DeliveryInfoItem>
  )
}

export default DeliveryInfoItemFirstDayOfDemurrage

import ListItemIcon from '@mui/material/ListItemIcon'
import { MenuItemWithIconProps } from '../Menu.props'
import { MenuItemText } from './MenuItemText'
import { StyledMenuItem } from './MenuItem.utils'

export const MenuItemWithIcon: React.FC<MenuItemWithIconProps> = (props) => {
  const { icon: Icon, text, ...otherProps } = props
  return (
    <StyledMenuItem className="with-icon" {...otherProps}>
      {Icon && (
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
      )}
      <MenuItemText text={text} />
    </StyledMenuItem>
  )
}

import { FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import { DateTime } from 'luxon-business-days'
import { showNotification } from 'src/stores/actionCreators/notifications'
import DatePicker from 'src/stories/DatePicker'
import { useTranslation } from 'react-i18next'
import { convertToDate } from '../../utils/helpers'

import { userUpdateUserData } from '../../stores/actionCreators'
import { promisifyAction } from '../../utils'

const OutOfOfficeTab: FunctionComponent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { outOfOfficeState } = useSelector((state: IGlobalState) => ({
    outOfOfficeState: state.user.out_of_office_till,
  }))

  const updateUser = promisifyAction(dispatch, userUpdateUserData)
  const handleOutOfOffice = async (value: string | null) => {
    if (value === null) {
      dispatch(
        showNotification({
          message: t('account.tabs.out_of_office.notification.ooo_cleared'),
          severity: 'success',
        })
      )
    } else {
      dispatch(
        showNotification({
          message: t('account.tabs.out_of_office.notification.ooo_set', {
            until: convertToDate(value),
          }),
          severity: 'success',
        })
      )
    }
    await updateUser({ out_of_office_till: value })
  }

  return (
    <>
      <Box
        data-testid="out-of-office"
        sx={{ maxWidth: '750px', padding: '20px' }}
      >
        <Typography
          variant="body1"
          children={t(
            'account.tabs.out_of_office.description',
            'Heading out of the office for a while? Let your colleagues and contacts know by setting your end date. We’ll flag that you’re out and show the selected end date to other users. Don’t forget to clear the date when you’re back in the office.'
          )}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-end', ml: 2, pb: 2 }}>
        <Box sx={{ maxWidth: '300px' }}>
          <DatePicker
            label={t(
              'account.tabs.out_of_office.datepicker_label',
              'Select end date'
            )}
            value={outOfOfficeState}
            minDate={DateTime.local().toISO()}
            onChange={(date: DateTime | null) => {
              if (date === null || date.isValid) {
                handleOutOfOffice(date?.toISO() || null)
              }
            }}
            data-testid="datepicker-input"
          />
        </Box>
      </Box>
    </>
  )
}

export default OutOfOfficeTab

export type PasswordModalState = {
  password: string
  confirm: string
  current: string
}

const validations = {
  correctLength: (password: string) => password.length >= 10,
  hasUppercaseCharacters: (password: string) => password.match(/[A-Z]/g),
  hasLowercaseCharacters: (password: string) => password.match(/[a-z]/g),
  hasNumbers: (password: string) => password.match(/\d/g),
  hasSpecialCharacters: (password: string) => password.match(/\W/g),
}

export const isValid = (password: string) =>
  Object.values(validations).every((validation) => validation(password))

export const canUpdatePassword = (formData: PasswordModalState) =>
  formData.current &&
  formData.password &&
  formData.password === formData.confirm &&
  isValid(formData.password)

export default validations

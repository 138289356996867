import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get, flatMap } from 'lodash'
import { Button } from '@mui/material'
import { useUpdateShipmentTemplates } from 'src/services/Api/shipmentTemplates'
import VerticalLinearStepper from 'src/stories/Stepper'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { useFormContext } from '../../FormContext'
import { getFormPayload } from '../../Payload.utils'
import TemplateFormInitialDetails from '../Steps/InitialDetails'
import { TemplateFormContextProps } from '../../Form.props'

const TemplatesFormCreate = ({ steps }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams<{ id: string }>()
  const { mutateAsync: updateTemplate, isLoading } = useUpdateShipmentTemplates(
    params?.id ?? ''
  )

  const {
    formState,
    shipmentRoles,
  } = useFormContext() as TemplateFormContextProps
  const { user } = useSelector((state: IGlobalState) => ({
    user: state.user,
  }))

  const requiredFields = flatMap(steps, 'requiredFields') ?? []

  const saveButtonEnabled = requiredFields.every(
    (field) => !!get(formState, field) || get(formState, field) === false
  )

  const handleSaveSuccess = () => {
    dispatch(
      showNotification({
        message: `Template "${formState.name}" successfully updated`,
        severity: 'success',
      })
    )
    history.push('/templates')
  }

  const handleSave = () => {
    const payload = getFormPayload({
      formState,
      shipmentRoles,
      userOrganizationId: user.organizationId,
    })
    updateTemplate(payload).then(handleSaveSuccess)
  }

  const { t } = useTranslation()

  return (
    <div data-testid="edit-template-form">
      <TemplateFormInitialDetails />
      <VerticalLinearStepper
        steps={steps}
        stepProps={{}}
        loading={false}
        endButtonText="Save"
        activeAllSteps={true}
        showFinalBlock={false}
        hideFinalButton={false}
        isForwardBtnDisabled={false}
      />
      <Button
        size="large"
        variant="contained"
        sx={{ mt: 2, ml: 4 }}
        onClick={handleSave}
        data-testid="save-button"
        disabled={!saveButtonEnabled || isLoading}
      >
        {t('common.save')}
      </Button>
    </div>
  )
}

export default TemplatesFormCreate

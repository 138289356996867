import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { promisifyAction } from 'src/utils'
import { Box, Typography } from '@mui/material'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { updateContainerInformation } from 'src/stores/actionCreators'
import { DateTime } from 'luxon'
import DatePicker from 'src/stories/DatePicker'
import InlandTransportRowEditableCellText from './InlandTransportRowEditableCellText'

const InlandTransportRowContainerDischargeDate: React.FC<{
  rowId: number
  containerId: number
  className: string
  value: null | string
  updatable: boolean
}> = ({ className, value, containerId, updatable }) => {
  const dispatch = useDispatch()
  const format = 'dd MMM HH:mm'

  const updateContainerInfo = promisifyAction(
    dispatch,
    updateContainerInformation
  )

  const handleUpdateSuccess = (message: string) => {
    dispatch(
      showNotification({
        severity: 'success',
        message,
      })
    )
  }

  const [open, setOpen] = useState<boolean>(false)

  const [currentDate, setCurrentDate] = useState<DateTime | null>(
    value ? DateTime.fromISO(value, { setZone: true }) : null
  )

  const handlePopoverOpen = () => {
    if (!updatable) {
      return
    }
    setOpen(true)
  }

  const handlePopoverClose = () => {
    setOpen(false)
  }

  const onChange = (newDate: DateTime | null) => {
    setCurrentDate(newDate)
  }

  const handleDateChange = (newDate: DateTime | null, key?: string) => {
    const actualDischarge = newDate?.toFormat('yyyy-MM-dd HH:mm') ?? null
    if (key === 'Escape') {
      setCurrentDate(
        value
          ? DateTime.fromISO(value, {
              setZone: true,
            })
          : null
      )
      handlePopoverClose()
      return
    }
    handlePopoverClose()
    setCurrentDate(newDate)
    updateContainerInfo(containerId, {
      actual_discharge: actualDischarge,
    })
      .then(() => {
        handleUpdateSuccess('Container discharge date & time updated')
      })
      .catch(() => {
        setCurrentDate(
          value
            ? DateTime.fromISO(value, {
                setZone: true,
              })
            : null
        )
      })
  }

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      alignItems="center"
      className={className}
    >
      {!open && currentDate && (
        <Typography
          children={currentDate.toFormat(format)}
          onClick={handlePopoverOpen}
        />
      )}
      {!open && !currentDate && !updatable && '-'}
      {!open && !currentDate && updatable && (
        <InlandTransportRowEditableCellText
          text={null}
          onClick={handlePopoverOpen}
        />
      )}
      {open && updatable && (
        <DatePicker
          closeOnSelect={false}
          open={open}
          placeholder="DD-MM-YYYY HH:MM"
          inputFormat="dd-MM-yyyy HH:mm"
          clearable={false}
          value={currentDate?.toString() ?? null}
          onClose={handleDateChange}
          onChange={onChange}
          onClick={() => {
            setOpen(true)
          }}
          data-testid="container-discharge-date-picker"
        />
      )}
    </Box>
  )
}

export default InlandTransportRowContainerDischargeDate

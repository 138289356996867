import { FunctionComponent, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import { Box, Paper } from '@mui/material'
import { isEmpty } from 'lodash'
import DashboardSupplyChainChart from 'src/components/DashboardSupplyChain/DashboardSupplyChainChart'
import DashboardSupplyChainShipments from 'src/components/DashboardSupplyChain/DashboardSupplyChainShipments'
import {
  fetchDashboardSupplyChain,
  fetchDashboardSupplyChainShipments,
} from '../../stores/actionCreators/dashboard'
import { promisifyAction } from '../../utils'

import './styles.scss'

const DashboardSupplyChain: FunctionComponent<{}> = () => {
  const dispatch = useDispatch()
  const [openedShipments, setOpenedShipments] = useState<boolean>(false)
  const [statusGroup, setStatusGroup] = useState<StatusGroupType | undefined>(
    undefined
  )

  const { overview, shipments, onlyWatchedShipments } = useSelector(
    (state: IGlobalState) => ({
      overview: state.dashboard.supplyChain,
      shipments: state.dashboard.supplyChainShipments,
      onlyWatchedShipments: state.shipmentsWatchedBy.onlyWatchedShipments,
    })
  )

  const fetchDashboardSupplyChainAsync = promisifyAction(
    dispatch,
    fetchDashboardSupplyChain
  )

  const fetchDashboardSupplyChainShipmentsAsync = promisifyAction(
    dispatch,
    fetchDashboardSupplyChainShipments
  )

  useEffect(() => {
    const fetchDataAsync = async () => {
      await fetchDashboardSupplyChainAsync({
        only_watched_shipments: onlyWatchedShipments,
      })
    }
    fetchDataAsync()
  }, [onlyWatchedShipments])

  useEffect(() => {
    if (statusGroup) {
      const fetchDataAsync = async () => {
        await fetchDashboardSupplyChainShipmentsAsync({
          statusGroup: statusGroup,
          only_watched_shipments: onlyWatchedShipments,
        })
      }
      fetchDataAsync()
    }
  }, [onlyWatchedShipments])

  const toggleShipments = async (
    newStatusGroup: StatusGroupType
  ): Promise<any> => {
    if (newStatusGroup === statusGroup) {
      setOpenedShipments(!openedShipments)
    }
    if (newStatusGroup) {
      await fetchDashboardSupplyChainShipmentsAsync({
        statusGroup: newStatusGroup,
        only_watched_shipments: onlyWatchedShipments,
      })
      setOpenedShipments(true)
      setStatusGroup(newStatusGroup)
    } else {
      setOpenedShipments(false)
    }
  }

  return (
    <Box sx={{ contentVisibility: 'auto' }}>
      <Typography
        variant="h2"
        className="dashboard-header"
        children="Supply chain overview"
        data-testid="dashboard-supply-chain-header"
      />
      <Paper variant="elevation">
        {!isEmpty(overview) && (
          <DashboardSupplyChainChart
            onToggle={toggleShipments}
            overview={overview}
          />
        )}
        {openedShipments && statusGroup && (
          <DashboardSupplyChainShipments
            shipments={shipments}
            statusGroup={statusGroup}
          />
        )}
      </Paper>
    </Box>
  )
}
export default DashboardSupplyChain

// @ts-nocheck
// FIXME

import { useCallback, useState } from 'react'
import { makeStyles } from '@mui/styles'
import ListSubheader from '@mui/material/ListSubheader'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import './index.scss'

const useStyles = makeStyles((theme) => ({
  button: {
    '&.MuiIconButton-root': {
      backgroundColor: theme.palette.grey[500],
      color: theme.palette.common.white,
      width: '100%',
      borderRadius: 3,
      padding: `0 ${theme.spacing(1)}`,
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 600,
      height: 18,
    },
    '& .icon': {
      fontSize: 9,
    },
    '& .MuiSvgIcon-root': {
      width: 12,
      height: 12,
      verticalAlign: 'top',
    },
    '& .MuiIconButton-label': {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  item: {
    borderRadius: 3,
    display: 'flex',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[500],
    fontSize: 12,
    padding: `0 ${theme.spacing(1)}`,
    fontWeight: 600,
    textTransform: 'uppercase',
    alignItems: 'center',
    width: '100%',
    height: 18,
  },
  icon: {
    display: 'flex',
    '& .MuiSvgIcon-root': {
      width: 12,
      height: 12,
    },
    '& .icon': {
      width: 12,
      height: 12,
      fontSize: 9,
      textAlign: 'center',
    },
  },
  menuItem: {
    padding: theme.spacing(1),
    minHeight: 'auto',
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
      '&.Mui-disabled': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      '&:hover': {
        backgroundColor: theme.palette.grey[50],
      },
      backgroundColor: theme.palette.primary.main,
    },
    '&.Mui-disabled': {
      opacity: 1,
      pointerEvents: 'auto',
    },
  },
  listSubheader: {
    color: theme.palette.common.black,
    lineHeight: 'normal',
    padding: theme.spacing(1),
    margin: 0,
    backgroundColor: 'white',
    textTransform: 'uppercase',
  },
  arrowIcon: {
    color: theme.palette.grey[500],
    width: 24,
    height: 24,
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.complex,
    }),
    transform: ({ open }: { open: boolean }) =>
      open ? 'rotate(180deg)' : 'rotate(0)',
  },
  root: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export type Color =
  | 'white'
  | 'green'
  | 'yellow'
  | 'orange'
  | 'grey'
  | 'red'
  | 'blue'
  | 'light-grey'
  | 'light-orange'
  | 'purple'
  | 'light-blue'
  | 'dark-blue'
  | 'light-green'
  | 'light-red'

export interface OptionsItem {
  id?: string | number
  name: string
  icon?: React.ReactNode
  color?: Color
  disabled?: string
}

export interface OptionsHeader {
  id?: string
  type: 'header'
  name: string
}

export interface Props {
  options: (OptionsItem | OptionsHeader)[]
  onChange: (option: OptionsItem) => void
  value: OptionsItem
  disabled?: boolean
  hideName?: boolean
  /*
    Shows shevron icon on the right side from the input
  */
  showArrowIcon?: boolean
  /*
    function that determines how to render icon and name inside select button
  */
  children?: (Icon: React.ReactNode, Name: React.ReactNode) => JSX.Element
}

export default function DashboardSelect({
  showArrowIcon = false,
  ...props
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const classes = useStyles({
    disabled: !!props.value.disabled,
    open,
  })

  const handleMenuItemClick = useCallback((option) => {
    if (option.disabled) return
    if (option.type === 'header') return
    props.onChange(option)
    setAnchorEl(null)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleButtonClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      event.stopPropagation()
      setAnchorEl(event.currentTarget)
    },
    []
  )

  return (
    <div className={`dashboard-select ${classes.root}`}>
      <IconButton
        title={props.value.name}
        className={`${classes.button} ${props.value.color} ${
          showArrowIcon ? 'dashboard-select--with-arrow' : ''
        }`}
        disabled={props.disabled}
        onClick={handleButtonClick}
        size="large"
      >
        {props.children && props.children(props.value.icon, props.value.name)}
        {!props.children && (
          <>
            {props.value.icon} {!props.hideName && props.value.name}
          </>
        )}
      </IconButton>
      {!props.disabled && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ horizontal: 'center' }}
          PopoverClasses={{
            root: 'dashboard-select__list',
          }}
        >
          {props.options.map((option) => {
            if (option.type === 'header')
              return (
                <ListSubheader
                  key={option.id || option.name}
                  className={classes.listSubheader}
                  disableGutters
                >
                  {option.name}
                </ListSubheader>
              )
            return (
              <MenuItem
                disableGutters
                className={classes.menuItem}
                key={option.id || option.name}
                selected={option.name === props.value.name}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleMenuItemClick(option)
                }}
                disabled={!!option.disabled}
              >
                <Tooltip arrow title={option.disabled || ''}>
                  <div
                    className={`${classes.item} ${
                      option.disabled ? 'disabled' : option.color
                    }`}
                  >
                    <div className={classes.icon}>{option.icon}</div>
                    <Box mr={1} />
                    <div>{option.name}</div>
                  </div>
                </Tooltip>
              </MenuItem>
            )
          })}
        </Menu>
      )}
      {showArrowIcon && !props.disabled && (
        <ExpandMoreIcon className={classes.arrowIcon} />
      )}
    </div>
  )
}

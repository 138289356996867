import { takeLatest } from 'redux-saga/effects'
import * as actions from '../../actions/ports'
import * as actionCreators from '../../actionCreators/ports'
import { simpleGetAndResolve } from '../factories'

const getPorts = simpleGetAndResolve(
  () => `/api/v1/ports`,
  actionCreators.getPortsSuccess,
  (request) => request.data
)

const preloadPols = simpleGetAndResolve(
  () => `/api/v1/ports`,
  actionCreators.preloadPolsSuccess,
  (request) => request.data
)

const preloadPods = simpleGetAndResolve(
  () => `/api/v1/ports`,
  actionCreators.preloadPodsSuccess,
  (request) => request.data
)

export default function* (): Iterator<any> {
  yield takeLatest(actions.PORTS_GET_DATA, getPorts)
  yield takeLatest(actions.PORTS_PRELOAD_POLS, preloadPols)
  yield takeLatest(actions.PORTS_PRELOAD_PODS, preloadPods)
}

import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import * as actions from '../actions/watchShipmentRules'

declare global {
  interface IWatchShipmentRuleCondition {
    id?: number
    condition: string
    condition_direction: string
    value: string
    value_label: string
    _destroy?: boolean
  }

  interface IWatchShipmentRule {
    id?: number
    name: string
    user_id?: number
    watch_shipment_rule_conditions: IWatchShipmentRuleCondition[]
  }

  interface IWatchShipmentRulesState {
    currentRule: IWatchShipmentRule
    isModalOpen: boolean
    shippers: IAddress[]
  }
}

export const initialWatchShipmentRulesState: IWatchShipmentRulesState = {
  currentRule: {
    name: '',
    watch_shipment_rule_conditions: [],
  },
  isModalOpen: false,
  shippers: [],
}

const toggleModal: Reducer<IWatchShipmentRulesState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  isModalOpen: action.isModalOpen,
})

const setCurrentRule: Reducer<IWatchShipmentRulesState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  currentRule: action.currentRule,
})

const getShippers: Reducer<IWatchShipmentRulesState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  shippers: action.payload,
})

export default createReducer(initialWatchShipmentRulesState, {
  [actions.WATCH_SHIPMENT_RULE_TOGGLE_MODAL]: toggleModal,
  [actions.WATCH_SHIPMENT_RULE_SET_CURRENT_RULE]: setCurrentRule,
  [actions.WATCH_SHIPMENT_RULE_GET_SHIPPERS_SUCCESS]: getShippers,
})

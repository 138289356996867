import IconComponent from '@mui/icons-material/KeyboardArrowDown'
import Select from '@mui/material/Select'
import { styled } from '@mui/styles'
import { map, filter } from 'lodash'
import { SelectProps } from './Select.props'
import { SelectPlaceholder } from './SelectPlaceholder'

const defaultSelectPlaceholder = 'Select'

const StyledSelect = styled(Select)(() => ({
  paddingRight: 0,
  '&.clearable': {
    '& .MuiSelect-select.MuiOutlinedInput-input': {
      paddingRight: `58px`,
    },
  },
}))

const getRenderValueForSingleSelect = (
  selected: string,
  options: SelectProps['options'],
  placeholder: SelectProps['placeholder']
) => {
  if (!selected) {
    return <SelectPlaceholder placeholder={placeholder} />
  }
  const found = options.find((option) => option.id === selected)
  return found?.label ?? ''
}

const getRenderValueForMultipleSelect = (
  selected: (string | number)[],
  options: SelectProps['options'],
  placeholder: SelectProps['placeholder']
) => {
  if (selected.length === 0) {
    return <SelectPlaceholder placeholder={placeholder} />
  }
  return map(
    filter(options, (option) => selected.indexOf(option.id) > -1),
    'label'
  ).join(', ')
}

export {
  IconComponent,
  StyledSelect,
  defaultSelectPlaceholder,
  getRenderValueForSingleSelect,
  getRenderValueForMultipleSelect,
}

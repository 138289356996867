import { useState } from 'react'
import ButtonBase from '@mui/material/ButtonBase'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import DoneIcon from '@mui/icons-material/Done'
import Zoom from '@mui/material/Zoom'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  copyButton: {
    fontSize: 15,
    color: theme.palette.grey[500],
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  icons: {
    position: 'relative',
    width: 16,
    height: 16,
  },
  icon: {
    left: 0,
    top: 0,
    width: 16,
    height: 16,
    position: 'absolute',
  },
}))

interface Props {
  successMessage: string
  inputRef: React.RefObject<HTMLTextAreaElement>
  children?: React.ReactNode
}

const CopyButton = ({ inputRef, successMessage, children }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles({ error: false })
  const [isCopied, setIsCopied] = useState(false)
  const text = children ?? t('common.buttons.copy', 'Copy')

  const copyRefText = () => {
    if (inputRef.current) {
      inputRef.current.select()
      document.execCommand('copy')
      document.getSelection()?.removeAllRanges()
      setIsCopied(true)
      setTimeout(() => setIsCopied(false), 1000)
      dispatch(
        showNotification({ message: successMessage, severity: 'success' })
      )
    }
  }

  return (
    <ButtonBase
      focusRipple
      disableTouchRipple
      className={classes.copyButton}
      onClick={copyRefText}
    >
      <div className={classes.icons}>
        <Zoom in={!isCopied}>
          <FileCopyOutlinedIcon className={classes.icon} />
        </Zoom>
        <Zoom in={isCopied}>
          <DoneIcon className={classes.icon} />
        </Zoom>
      </div>
      <Box ml="6px" />
      <div>{text}</div>
    </ButtonBase>
  )
}

export default CopyButton

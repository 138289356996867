import { FunctionComponent, useState, useRef } from 'react'
import { groupBy, orderBy, keys, Dictionary } from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Popper from '@mui/material/Popper'

import { LoadTypeEnum, ModalityEnum } from 'src/config/constants'
import { useTranslation } from 'react-i18next'

const maxContainerTypes = 3

type Props = { shipment: IOverviewShipment }

const ShipmentOverviewLoad: FunctionComponent<Props> = ({ shipment }) => {
  const { t } = useTranslation()
  const [tooltipExpanded, setTooltipExpanded] = useState<boolean>(false)
  const anchorEl: React.MutableRefObject<HTMLElement | null> = useRef(null)

  const openContainersTooltip = (e: React.MouseEvent<HTMLElement>): void => {
    setTooltipExpanded(true)
    anchorEl.current = e.currentTarget
  }

  const closeContainersTooltip = (): void => setTooltipExpanded(false)

  const groupContainers:
    | Dictionary<IContainerIdentifier[]>
    | never[] = shipment.containers
    ? groupBy(shipment.containers, 'container_type.name')
    : []

  const containerTypeNames: string[] = orderBy(keys(groupContainers)) || []

  const typesCount: number = containerTypeNames.length

  const renderContainerTypeNames = (typeNames: string[]): string =>
    typeNames
      .map((name: string) => `${groupContainers[name].length} x ${name}`)
      .join(', ')

  const renderContainersBlock = (): JSX.Element => (
    <div className="shipment-overview-top__details--block">
      <div className="shipment-overview-top__details--block-title">
        <Typography
          variant="body1Strong"
          children={t('common.entities.container_other', 'Containers')}
          data-testid="shipment-load-type-containers"
        />
      </div>
      <div className="shipment-overview-top__details--block-content">
        <Box typography="body1">
          <div className="container-types-block">
            <div
              className="container-types-block__base"
              data-testid="shipment-overview-containers-value"
            >
              {renderContainerTypeNames(
                typesCount > 3
                  ? containerTypeNames.slice(0, maxContainerTypes)
                  : containerTypeNames
              )}
            </div>
            {typesCount > maxContainerTypes && (
              <>
                <span
                  className="containers-tooltip-link"
                  onClick={openContainersTooltip}
                >
                  {t('common.plus_n_more', {
                    count: typesCount - maxContainerTypes,
                  })}
                </span>
                <Popper
                  open={tooltipExpanded}
                  anchorEl={anchorEl.current}
                  placement="bottom-start"
                  className="mui-override"
                >
                  <ClickAwayListener onClickAway={closeContainersTooltip}>
                    <div className="shipment-parties__popper-content">
                      {renderContainerTypeNames(
                        containerTypeNames.slice(maxContainerTypes, typesCount)
                      )}
                    </div>
                  </ClickAwayListener>
                </Popper>
              </>
            )}
          </div>
        </Box>
      </div>
    </div>
  )

  if (shipment.shipment_type === LoadTypeEnum.fcl) {
    return renderContainersBlock()
  }

  if (shipment.shipment_type === ModalityEnum.Air) {
    return (
      <div className="shipment-overview-top__details--block">
        <div className="shipment-overview-top__details--block-title">
          <Typography
            variant="body1Strong"
            children={t('common.chargeable_weight')}
          />
        </div>
        <div className="shipment-overview-top__details--block-content">
          <Typography
            variant="body1"
            data-testid="shipment-overview-chargeable-weight"
            children={`${shipment.cargo_chargeable_weight}kg`}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="shipment-overview-top__details--block">
        <div className="shipment-overview-top__details--block-title paragraph__small grey">
          <Typography
            variant="body1Strong"
            children={t('common.entities.cargo_one')}
          />
        </div>
        <div
          className="shipment-overview-top__details--block-content paragraph__medium black"
          data-testid="shipment-overview-cargo-value"
        >
          <Typography variant="body1">
            {shipment.cargo_weight}kg | {shipment.cargo_volume}m<sup>3</sup>
          </Typography>
        </div>
      </div>
      {shipment.shipment_type === ModalityEnum.Rail && (
        <>{renderContainersBlock()}</>
      )}
    </>
  )
}

export default ShipmentOverviewLoad

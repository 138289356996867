import Box from '@mui/material/Box'
import ReceiptIcon from '@mui/icons-material/ReceiptLongOutlined'
import { EmptyState } from 'src/stories/MUI/EmptyState'
import { useTranslation } from 'react-i18next'

const DemurrageAndDetentionContentRatesEmptyState = () => {
  const { t } = useTranslation()
  return (
    <Box mt={2} data-testid="demurrage-and-detention-rates-empty-state">
      <EmptyState
        px={0}
        badgeColor="primary"
        badgeIcon={ReceiptIcon}
        title={t(
          'demurrage_and_detention.empty_state.title',
          'No rates available'
        )}
        description={t(
          'demurrage_and_detention.empty_state.description',
          'It appears you are not booking with Shypple for dry, non-dangerous, ocean import. Therefore we also can’t show you Shypple’s rates.\n\nIf you’re looking for other rates please contact our customer success team via the support button in the bottom right corner of the app.'
        )}
      />
    </Box>
  )
}

export default DemurrageAndDetentionContentRatesEmptyState

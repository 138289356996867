import { useSelector } from 'react-redux'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import { Badge, IconButton, Tooltip } from '@mui/material'

import { INotificationsButtonProps } from 'src/components/Notifications/Notifications.props'
import { useTranslation } from 'react-i18next'

const TasksButton: React.FC<INotificationsButtonProps> = ({
  onClick,
  iconProps,
  buttonProps,
}) => {
  const { t } = useTranslation()
  const { yourOpenTasks } = useSelector((state: IGlobalState) => ({
    yourOpenTasks: state.tasks?.yourOpenTasks ?? [],
  }))

  return (
    <Tooltip title={t('top_navigation.tasks.tooltip', 'Tasks')}>
      <IconButton
        size="large"
        color="inherit"
        onClick={onClick}
        data-testid="task-icon"
        {...buttonProps}
      >
        <Badge badgeContent={yourOpenTasks.length} color="info">
          <CheckCircleOutlineOutlinedIcon {...iconProps} />
        </Badge>
      </IconButton>
    </Tooltip>
  )
}

export default TasksButton

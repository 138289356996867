import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useRef, useState } from 'react'
import { endpoints } from './endpoints'

class ManageablePromise<Response> {
  promise?: Promise<Response>
  resolve?: (value: Response | PromiseLike<Response>) => void
  reject?: (reason?: any) => void

  constructor() {
    this.renewPromise()
  }

  renewPromise = () => {
    this.promise = new Promise<Response>((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }
}

// disabled on initial load, pass {enabled: true} to fetch data on initial load.
export const useQueryAsync = <
  Response,
  Error = AxiosError,
  Request extends object = object
>(
  keys: (keyof typeof endpoints | string)[],
  fetcher: (payload: Request) => Promise<Response>,
  options: UseQueryOptions<Response, Error, Response> = {}
) => {
  const [payload, setPayload] = useState<Request>({} as Request)
  const [enabled, setEnabled] = useState(false)
  const promise = useRef(new ManageablePromise<Response>()).current

  const fetchAsync = async (input: Request) => {
    setPayload(input)
    setEnabled(true)
    return promise.promise
  }

  const resetStates = () => {
    promise.renewPromise()
    setEnabled(false)
  }

  return {
    ...useQuery<Response, Error>([...keys, payload], () => fetcher(payload), {
      enabled,
      ...options,
      onSuccess: (data) => {
        promise.resolve?.(data)
        options.onSuccess?.(data)
        resetStates()
      },
      onError: (error) => {
        promise.reject?.(error)
        options.onError?.(error)
        resetStates()
      },
    }),
    fetchAsync,
  }
}

import { TooltipPayload, TooltipProps } from 'recharts'
import { sumBy, reverse } from 'lodash'

export enum CurrencyLabel {
  eur = '€',
  usd = '$',
}

interface ITooltipProps extends TooltipProps {
  currency: CurrencyLabel
}

export const getTotalNumber = (payload: readonly TooltipPayload[]) =>
  sumBy(payload, (entry) => Math.floor(entry.value as number))

export const CustomTooltip: React.FC<ITooltipProps> = (props) => {
  const { payload = [], label, currency } = props
  const locale = currency === CurrencyLabel.usd ? 'us-US' : 'es-ES'

  return (
    <div className="shypple-custom-tooltip">
      <div className="shypple-custom-tooltip-header">{label}</div>
      {reverse(payload).map((entry, index) => (
        <div key={`item-${index}`}>
          {!!entry.value && (
            <div
              className="shypple-custom-tooltip-item"
              style={{ color: entry.color }}
            >
              <span>{entry.name}:</span>
              <span className="right-aligned">
                {CurrencyLabel[currency]}{' '}
                {Math.floor(entry.value as number).toLocaleString(locale)}
              </span>
            </div>
          )}
        </div>
      ))}
      {
        <div>
          <div
            className="shypple-custom-tooltip-item"
            style={{ color: '#000' }}
          >
            <span>Total:</span>
            <span className="right-aligned">
              {CurrencyLabel[currency]}{' '}
              {getTotalNumber(payload).toLocaleString(locale)}
            </span>
          </div>
        </div>
      }
    </div>
  )
}

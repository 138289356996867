import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { promisifyAction } from 'src/utils'
import { capitalize, Box, Typography, Tooltip } from '@mui/material'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { convertDateToOrderFormat } from 'src/utils/helpers'
import { updatePickupsAndDeliveriesItem } from 'src/stores/actionCreators'
import { DateTime } from 'luxon'
import DatePicker from 'src/stories/DatePicker'
import { InlandTransportRowTransportDateProps } from './InlandTransportRow.props'
import InlandTransportRowEditableCellText from './InlandTransportRowEditableCellText'
import { getPickupOrDeliveryDate } from './InlandTransportRow.utils'

const InlandTransportRowDeliveryDate: React.FC<InlandTransportRowTransportDateProps> = ({
  rowId,
  service,
  arrival,
  addresses,
  departure,
  updatable,
  pickupDeliverDateTime,
  inline,
  onSelectDate,
  className,
  width,
}) => {
  const dispatch = useDispatch()
  const isDelivery = service === 'delivery'
  const format = 'dd MMM HH:mm'
  const transportAddress = addresses.find((x) => x.pickup_delivery)
  const hasAddressDate = !!transportAddress && !!transportAddress.datetime_from
  const minDate = isDelivery && arrival ? arrival : undefined
  const maxDate = !isDelivery && departure ? departure : undefined
  const updateDateTimeAsync = promisifyAction(
    dispatch,
    updatePickupsAndDeliveriesItem
  )
  const [open, setOpen] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const hasErrors = () => {
    if (!currentDate) return 'Delivery date cannot be empty'
    if (minDate && currentDate < minDate)
      return 'Delivery cannot occur before ETA'
    if (maxDate && currentDate > maxDate)
      return 'Delivery cannot occur after ETD'
    return ''
  }

  const pickupOrDeliveryDate = getPickupOrDeliveryDate(
    transportAddress,
    pickupDeliverDateTime
  )

  const [currentDate, setCurrentDate] = useState<DateTime | null>(
    pickupOrDeliveryDate
  )

  useEffect(() => {
    if (rowId) {
      setCurrentDate(pickupOrDeliveryDate)
    }
  }, [pickupDeliverDateTime])

  const handlePopoverOpen = () => {
    if (!updatable) {
      return
    }
    setOpen(true)
  }

  const handlePopoverClose = () => {
    setOpen(false)
  }

  const handleUpdateSuccess = (message: string) => {
    dispatch(
      showNotification({
        severity: 'success',
        message,
      })
    )
  }

  const onChange = (newDate: DateTime | null) => {
    setCurrentDate(newDate)
  }

  const handleDateChange = (newDate: DateTime | null, key?: string) => {
    if (key === 'Escape') {
      setCurrentDate(pickupOrDeliveryDate)
      setError('')
      handlePopoverClose()
      return
    }
    const error = hasErrors()
    setError(error)
    handlePopoverClose()
    if (newDate?.toMillis() === pickupOrDeliveryDate?.toMillis()) return
    if (!transportAddress) {
      return
    }
    if (error) {
      return
    }
    setCurrentDate(newDate)
    updateDateTimeAsync(rowId, {
      inland_transport_addresses_attributes: [
        {
          id: transportAddress.id,
          datetime_from: newDate?.toISO() || null,
          datetime_to: null,
        },
      ],
    })
      .then(() => {
        handleUpdateSuccess(`${capitalize(service)} date & time updated`)
      })
      .catch(() => {
        setError('')
        setCurrentDate(pickupOrDeliveryDate)
      })
  }

  const formattedDate = convertDateToOrderFormat(
    currentDate ? currentDate.toISO() : null,
    format
  )

  const handleDateSelect = (newDate: DateTime | null) => {
    setCurrentDate(newDate)
    if (onSelectDate) {
      onSelectDate(newDate)
    }
  }

  return (
    <Tooltip title={error} open={!!error} placement="top">
      <Box
        height="100%"
        width="100%"
        display="flex"
        alignItems="center"
        data-testid="inland-transport-pickup-delivery-date"
        className={className}
      >
        {!open && currentDate && !inline && !error && (
          <Typography
            children={formattedDate}
            onClick={handlePopoverOpen}
            data-testid="inland-transport-pickup-delivery-date-trigger"
            color={hasAddressDate ? 'primary' : 'grey.600'}
            variant={hasAddressDate ? 'body1Strong' : 'body1'}
          />
        )}
        {!open && !currentDate && !updatable && '-'}
        {!open && !currentDate && updatable && !inline && !error && (
          <InlandTransportRowEditableCellText
            text={null}
            onClick={handlePopoverOpen}
          />
        )}
        {(inline || open || error) && updatable && (
          <DatePicker
            closeOnSelect={false}
            open={open}
            error={!!error}
            data-testid="inland-transport-editable-date-picker"
            placeholder="DD-MM-YYYY HH:MM"
            inputFormat="dd-MM-yyyy HH:mm"
            clearable={false}
            minDate={minDate}
            maxDate={maxDate}
            value={currentDate?.toISO() || null}
            onClose={inline ? handleDateSelect : handleDateChange}
            onChange={onChange}
            onClick={() => {
              setOpen(true)
              setError('')
            }}
            width={width}
          />
        )}
      </Box>
    </Tooltip>
  )
}

export default InlandTransportRowDeliveryDate

import { makeStyles } from '@mui/styles'

export const CargoWindowStyles = makeStyles((theme) => ({
  root: {
    '& .x-long': {
      width: '175px',
    },
    '& .x-short': {
      width: '41px',
    },
    '& .x-medium': {
      width: '80px',
    },
    '& .short': {
      width: '70px',
    },
    '& .MuiTableCell-sizeMedium': {
      paddingTop: 8,
      paddingBottom: 8,
      height: 40,
      verticalAlign: 'top',

      '& p, & h6': {
        whiteSpace: 'normal',
      },
    },
  },
}))

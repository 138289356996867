import { FunctionComponent, useState, useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import primary from 'src/theme/colors/light/primary'
import {
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Link,
} from '@mui/material'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import VisibilityIcon from '@mui/icons-material/Visibility'
import NotificationsIcon from '@mui/icons-material/Notifications'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import FilterListIcon from '@mui/icons-material/FilterList'

import Typography from '@mui/material/Typography'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { promisifyAction } from 'src/utils'
import { convertDateToDateTimeWithoutYear } from 'src/utils/helpers'
import { Trans, useTranslation } from 'react-i18next'
import {
  userSubmitUserData,
  watchShipmentRuleSync,
  usersUpdateData,
} from '../../stores/actionCreators'
import CustomGroupOfShipments from './CustomGroupOfShipments'
import './styles.scss'

interface IProps {
  userId?: string
}

const MyShipments: FunctionComponent<IProps> = ({ userId }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const {
    watchShipmentSetting,
    watchShipmentRules,
    currentUserId,
    lastSyncedAt,
  } = useSelector(
    (state: IGlobalState) => ({
      watchShipmentSetting: (userId ? state.users.currentUser : state.user)
        ?.watchShipmentSetting,
      watchShipmentRules: (userId ? state.users.currentUser : state.user)
        ?.watchShipmentRules,
      currentUserId: (userId ? state.users.currentUser : state.user)?.id,
      lastSyncedAt: (userId ? state.users.currentUser : state.user)
        ?.shipment_groups_last_synced_at,
    }),
    shallowEqual
  )

  const [radioValue, setRadioValue] = useState<string>(watchShipmentSetting)

  useEffect(() => {
    setRadioValue(watchShipmentSetting)
  }, [watchShipmentSetting])

  const userSubmitUserDataAsync = promisifyAction(dispatch, userSubmitUserData)
  const usersUpdateDataAsync = promisifyAction(dispatch, usersUpdateData)
  const showNotificationAsync = promisifyAction(dispatch, showNotification)

  const handleChange = (event) => {
    setRadioValue(event.target.value)
    if (
      ['all_shipments', 'none_shipments'].includes(event.target.value) ||
      watchShipmentRules.length
    ) {
      if (userId) {
        usersUpdateDataAsync(Number(currentUserId), {
          watch_shipment_setting: event.target.value,
          id: Number(currentUserId),
        })
      } else {
        userSubmitUserDataAsync({
          watch_shipment_setting: event.target.value,
        })
      }
      showNotificationAsync({
        message: t('account.tabs.shipments.notification.settings_applied'),
        severity: 'success',
      })
    }
  }

  const onSyncClick = () => {
    dispatch(watchShipmentRuleSync({ user_id: Number(currentUserId) }))
    showNotificationAsync({
      message: t(
        'account.tabs.shipments.notification.background_sync',
        'We will sync existing shipments with your selected watch settings in the background.'
      ),
      severity: 'success',
    })
  }

  const renderEmptyState = () => {
    if (radioValue === 'custom') return

    return (
      <Box
        sx={{
          backgroundColor: '#F2F2F3',
          borderRadius: '10px',
          padding: 3,
          maxWidth: '755px',
          display: 'flex',
        }}
      >
        <VisibilityIcon />
        <Typography
          variant="h5"
          ml={1}
          children={
            radioValue === 'all_shipments'
              ? t('account.tabs.shipments.shipments_to_watch.notifications_all')
              : t(
                  'account.tabs.shipments.shipments_to_watch.notifications_none'
                )
          }
        />
      </Box>
    )
  }

  return (
    <Box data-testid="my-shipments">
      <div className="my-shipments">
        <div className="notify-settings-form--block">
          <div className="notify-settings-form--title">
            <Box
              sx={{
                padding: 2,
                width: 520,
                border: `1px solid ${primary[900]}`,
                borderRadius: 1,
                background: primary[50],
              }}
            >
              <Typography
                variant="body1Strong"
                children={t(
                  'account.tabs.shipments.help_text.title',
                  'Only watch shipments that are relevant for you'
                )}
                mb={2}
              />
              <Typography
                variant="body1"
                mb={1}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <NotificationsIcon sx={{ marginRight: 2 }} />
                {t(
                  'account.tabs.shipments.help_text.tip1.text',
                  'Define which shipments you want to watch and be notified about.'
                )}
              </Typography>
              <Typography
                variant="body1"
                mb={1}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <FilterListIcon sx={{ marginRight: 2 }} />
                {t(
                  'account.tabs.shipments.help_text.tip2.text',
                  'Easily filter overviews based on your preferences.'
                )}
              </Typography>
              <Typography
                variant="body1"
                mb={1}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <InfoOutlinedIcon sx={{ marginRight: 2 }} />
                <Trans
                  i18nKey="account.tabs.shipments.help_text.tip3.text"
                  defaults={'<0>Learn more</0> about shipment groups'}
                  components={[
                    <Link
                      component={Link}
                      mr={0.5}
                      target="_blank"
                      href={t(
                        'account.tabs.shipments.help_text.tip3.link',
                        'https://help.shypple.com/en/articles/6504561-create-a-shipment-group?utm_source=platform&utm_medium=account-settings&utm_campaign=knowledge-base-promotion'
                      )}
                    />,
                  ]}
                />
              </Typography>
            </Box>

            <Typography
              mt={2}
              variant="h5"
              children={t(
                'account.tabs.shipments.shipments_to_watch.title',
                'Which shipments do you want to watch?'
              )}
            />
            <RadioGroup
              value={radioValue}
              onChange={handleChange}
              data-testid="my-shipments-radio-group"
              sx={{ marginLeft: '-10px' }}
            >
              <div className="flex">
                <FormControlLabel
                  data-testid="my-shipments-radio-group-all_shipments"
                  value="all_shipments"
                  control={<Radio color="primary" />}
                  label={t(
                    'account.tabs.shipments.shipments_to_watch.all',
                    'All shipments'
                  )}
                />
                <FormControlLabel
                  value="none_shipments"
                  data-testid="my-shipments-radio-group-none_shipments"
                  control={<Radio color="primary" />}
                  label={t(
                    'account.tabs.shipments.shipments_to_watch.none',
                    'No shipments'
                  )}
                />
                <FormControlLabel
                  value="custom"
                  data-testid="my-shipments-radio-group-custom"
                  control={<Radio color="primary" />}
                  label={t(
                    'account.tabs.shipments.shipments_to_watch.my',
                    'My shipments'
                  )}
                />
              </div>
            </RadioGroup>

            <Box sx={{ display: 'flex' }} mb={2} mt={0.5}>
              {(radioValue !== 'custom' || !!watchShipmentRules.length) && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    data-testid="sync-shipment-groups"
                    variant="outlined"
                    size="large"
                    onClick={onSyncClick}
                    startIcon={<AutorenewIcon />}
                  >
                    {t(
                      'account.tabs.shipments.shipments_to_watch.apply_button',
                      'Apply filters to existing shipments'
                    )}
                  </Button>
                  {lastSyncedAt && (
                    <Typography variant="body1" ml={1}>
                      {t(
                        'account.tabs.shipments.shipments_to_watch.last_synced_at',
                        {
                          date: convertDateToDateTimeWithoutYear(lastSyncedAt),
                          defaultValue: 'Last applied at {{date}}',
                        }
                      )}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
            {renderEmptyState()}

            {radioValue === 'custom' && (
              <CustomGroupOfShipments userId={userId} />
            )}
          </div>
        </div>
      </div>
    </Box>
  )
}

export default MyShipments

import { createReducer, Handlers, Reducer } from 'redux-create-reducer'
import { AnyAction } from 'redux'
import * as actions from '../actions/users'
import { initialUserState } from './user'

declare global {
  interface IUsersState {
    currentUser: IUser
  }
}

export const initialUsersState: IUsersState = {
  currentUser: initialUserState,
}

const getUsersData: Reducer<IUsersState, AnyAction> = (state, { payload }) => ({
  ...state,
  currentUser: payload,
})

const actionHandlers: Handlers<IUsersState> = {
  [actions.USERS_GET_DATA_SUCCESS]: getUsersData,
}

export default createReducer(initialUsersState, actionHandlers)

import { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'

import { Typography, Box, Button, Link } from '@mui/material'
import { Alert } from 'src/stories/Alert'
import { convertDateToFullWithTimePoint } from 'src/utils/helpers'
import { useTranslation } from 'react-i18next'
import { permissionTo } from 'src/utils'
import { toggleTransportWindow } from 'src/stores/actionCreators'
import TransportOrderEmailWindow from './TransportOrderEmailWindow'

type IProps = {
  inlandTransport: ISingleInlandTransport
}

const SendTransportOrderEmailBlock: FunctionComponent<IProps> = ({
  inlandTransport,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  if (
    !permissionTo([
      'pickups_deliveries.full_manage',
      'pickups_deliveries.shypple_admin',
    ])
  ) {
    return null
  }

  return (
    <Box>
      <Typography variant="h6">
        {t('transports.drawer.send_transport_order', 'Send transport order')}
      </Typography>
      <Box
        sx={{
          mt: 1,
        }}
      >
        {inlandTransport.transporter_email_action_items.map(
          (actionItem, index) => {
            return (
              <Alert
                key={index}
                color="warning"
                message={actionItem}
                handleClose={undefined}
              />
            )
          }
        )}
        {!inlandTransport.transport_email_sent_at &&
          !inlandTransport.transporter_email_action_items.length && (
            <Alert
              color="success"
              message={t(
                'transports.drawer.transport_order_complete',
                'Transport order is complete. You can proceed and email the transport order'
              )}
              handleClose={undefined}
            />
          )}
        {!!inlandTransport.transport_email_sent_at && (
          <>
            {t(
              'transports.drawer.transport_has_been_sent_on',
              'Transport order has been sent on'
            )}{' '}
            {convertDateToFullWithTimePoint(
              inlandTransport.transport_email_sent_at || ''
            )}
            {t('transports.drawer.you_can', '. You can')}
            <Link
              sx={{
                ml: 0.5,
                cursor: 'pointer',
              }}
              onClick={() => dispatch(toggleTransportWindow(true))}
            >
              {t(
                'transports.drawer.send_new_order',
                'send a new transport order'
              )}
            </Link>
          </>
        )}
      </Box>
      {!inlandTransport.transport_email_sent_at && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 2,
            width: 670,
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            onClick={() => dispatch(toggleTransportWindow(true))}
            disabled={!!inlandTransport.transporter_email_action_items.length}
            sx={{
              mb: 3,
            }}
          >
            {t(
              'transports.drawer.send_transport_order',
              'Email transport order'
            )}
          </Button>
        </Box>
      )}
      <TransportOrderEmailWindow />
    </Box>
  )
}

export default SendTransportOrderEmailBlock

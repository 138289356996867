import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { SABSearchItemAvatarWithTextProps } from './SABSearchItem.props'

const SABSearchItemAvatarWithText: React.FC<SABSearchItemAvatarWithTextProps> = ({
  children = null,
  title,
  content,
}) => {
  return (
    <Stack direction="row">
      {children}
      <Box ml={1.5}>
        <Typography variant="body1Strong" children={title} />
        <Typography mr={2.5} children={content} />
      </Box>
    </Stack>
  )
}

export default SABSearchItemAvatarWithText

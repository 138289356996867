import { EmptyState } from 'src/stories/MUI/EmptyState'
import FilterListIcon from '@mui/icons-material/FilterList'
import { useTranslation } from 'react-i18next'

const QuickFiltersManageDrawerEmptyState = () => {
  const { t } = useTranslation()
  return (
    <EmptyState
      px={0}
      badgeColor="primary"
      badgeIcon={FilterListIcon}
      title={t(
        'filters.manage_quick_filters.empty_state.title',
        'Create your first quick filter'
      )}
      description={t(
        'filters.manage_quick_filters.empty_state.description',
        'You haven’t created any quick filters yet. Close this drawer, filter the page on the preferred criteria and press ‘+ Save as new quick filter’.'
      )}
    />
  )
}

export default QuickFiltersManageDrawerEmptyState

import { TableStorageKeysProps } from './TableWrapper.props'

const storageKeyList = [
  'density',
  'columnOrder',
  'columnSizing',
  'columnPinning',
  'columnSorting',
  'columnVisibility',
]

export const setLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const getStorageKeys = (tableStorageKey: string) => {
  return storageKeyList.reduce((acc: TableStorageKeysProps, key: string) => {
    acc[key] = `${tableStorageKey}_${key}`
    return acc
  }, {} as TableStorageKeysProps)
}

export const getLocalStorage = (key: string, defaultValue) => {
  const storedValue = localStorage.getItem(key)
  if (storedValue) {
    return JSON.parse(storedValue)
  }
  return defaultValue
}

import Box from '@mui/material/Box'
import { NavLink } from 'react-router-dom'
import { StyledMenuButton } from 'src/components/TopNavigation/TopNavigation.styles'
import { MenuItemLinkProps } from 'src/components/TopNavigation/TopNavigation.props'
import TopNavigationLinksDropdown from './TopNavigationLinksDropdown'

interface TopNavigationLinksProps {
  navItems: MenuItemLinkProps[]
}

const TopNavigationLinks: React.FC<TopNavigationLinksProps> = ({
  navItems,
}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      {navItems.map((item) => {
        if (item.children) {
          return <TopNavigationLinksDropdown key={item.key} menuItem={item} />
        }
        return (
          <StyledMenuButton
            size="large"
            to={item.to}
            sx={{ ml: 2 }}
            key={item.key}
            component={NavLink}
            startIcon={item.icon}
            children={item.label}
            activeClassName="active"
            exact={item.key === 'shipments'}
            data-testid={`${item.key}-nav-link`}
          />
        )
      })}
    </Box>
  )
}

export default TopNavigationLinks

import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import './styles.scss'

interface IProps {
  activeStep: number
}

const AssignToShipmentProgress: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const { activeStep } = props
  return (
    <div className="assign-to-shipment-progress">
      <div className="assign-to-shipment-progress__bar">
        <div className="assign-to-shipment-progress__bar-circle completed"></div>
        <div className="assign-to-shipment-progress__bar-line active"></div>

        <div
          className={`assign-to-shipment-progress__bar-circle ${
            activeStep === 2 && 'completed'
          }`}
        ></div>
      </div>
      <div className="assign-to-shipment-progress__captions">
        <div
          className={`assign-to-shipment-progress__captions-text first paragraph__medium ${
            activeStep > 1 && 'completed'
          } ${activeStep === 1 && 'active'}`}
        >
          <div className="contents">
            {t(
              'purchase_orders.assign_to_shipment_progress.assign',
              'Assign to shipment'
            )}
          </div>
        </div>
        <div
          className={`assign-to-shipment-progress__captions-text third paragraph__medium ${
            activeStep === 2 && 'completed'
          }`}
        >
          <div className="contents">
            {t(
              'purchase_orders.assign_to_shipment_progress.confirm',
              'Confirmation'
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssignToShipmentProgress

import Box from '@mui/material/Box'
import { containerTypeIcon } from 'src/utils/helpers'
import { getContainerSVGIcon } from './ContainerIcons.utils'
import { ContainerInfoIconProps } from './ContainerIcon.props'
import { containerSVGIconMap } from './ContainerIcons.constants'

const ContainerInfoIcon: React.FC<ContainerInfoIconProps> = ({
  shipmentType,
  containerName,
  sx = {},
}) => {
  const name = containerName ?? ''
  const loadSVGIcon = Object.keys(containerSVGIconMap).includes(name)
  if (loadSVGIcon) {
    const ContainerIcon = getContainerSVGIcon(name)
    return (
      <Box display="flex" sx={sx} data-testid="container-svg-icon">
        <ContainerIcon width="48" />
      </Box>
    )
  }
  return (
    <i
      className={`icon ${containerTypeIcon(shipmentType, containerName)}`}
      data-testid="container-font-icon"
    />
  )
}

export default ContainerInfoIcon

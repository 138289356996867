import { FunctionComponent, useCallback, useRef, useState } from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Popper from '@mui/material/Popper'
import { t } from 'i18next'

type Props = {
  bookings: IBookingShort[]
}

const ShipmentOverviewShippers: FunctionComponent<Props> = ({ bookings }) => {
  const [tooltipExpanded, setTooltipExpanded] = useState<boolean>(false)
  const anchorEl: React.MutableRefObject<HTMLElement | null> = useRef(null)

  const openShippersTooltip = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      setTooltipExpanded(true)
      anchorEl.current = e.currentTarget
    },
    [setTooltipExpanded]
  )

  const closeShippersTooltip = useCallback(() => {
    setTooltipExpanded(false)
  }, [setTooltipExpanded])

  if (!bookings.length) {
    return (
      <div className="shipment-overview-top__details--block-content">-</div>
    )
  } else if (bookings.length === 1) {
    return (
      <div className="shipment-overview-top__details--block-content">
        {bookings[0].shipper_name}
      </div>
    )
  }

  return (
    <>
      <div className="shipment-overview-top__details--block-content">
        {bookings[0].shipper_name}
      </div>
      <div
        className="shipment-overview-top__details--block-content"
        data-testid="shipment-header-shippers-value"
      >
        <span
          className="shippers-tooltip-link"
          id="shippers-anchor-element"
          onClick={openShippersTooltip}
        >
          {t('common.plush_n_more', {
            count: bookings.length - 1,
            defaultValue: 'Plus {{count}} more',
          })}
        </span>
      </div>

      <Popper
        id="shipment-shippers-popper"
        open={tooltipExpanded}
        anchorEl={anchorEl.current}
        placement="bottom-start"
        className="mui-override"
      >
        <ClickAwayListener onClickAway={closeShippersTooltip}>
          <div className="shipment-parties__popper-content">
            {bookings.map((booking: IBookingShort) => (
              <div className="party-container" key={booking.id}>
                <div>{booking.shipper_name}</div>
              </div>
            ))}
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export default ShipmentOverviewShippers

import { FunctionComponent, useEffect, useCallback } from 'react'
import { Route, Switch, Redirect } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import Tabs from 'src/stories/Tabs'
import Box from '@mui/material/Box'
import Paper from 'src/components/Paper'
import MessageCenterApprovals from 'src/components/MessageCenter/MessageCenterApprovals'
import MessageCenterMentions from 'src/components/MessageCenter/MessageCenterMentions'
import MessageCenterUnread, {
  UNREADS_PER_PAGE,
} from 'src/components/MessageCenter/MessageCenterUnread'
import MessageCenterSavedMessages from 'src/components/MessageCenter/MessageCenterSavedMessages'
import UserChannelClient from 'src/components/SocketHandlers/UserChannelClient'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { promisifyAction, permissionTo } from '../../utils'
import {
  countersGetData,
  approvalsGetData,
  mentionsGetData,
  unreadGetData,
  getSavedMessages,
  setMessagesCounters,
} from '../../stores/actionCreators'
import './styles.scss'

type MessageCenterTab = '/approvals' | '/mentions' | '/unread' | '/saved'

interface IMessageCenterProps {
  location: Location
  match?: IMatch | null
}

const tabs = (t: TFunction<'translation', undefined>) => [
  {
    label: t('message_center.tabs.approvals.title', 'Approvals'),
    value: '/message_centre/approvals',
    counter: 'approvals',
    permissions: [
      'shipments.private_chat.approvals.manage',
      'shipments.public_chat.approvals.manage',
      'shipments.agents_chat.approvals.manage',
    ],
  },
  {
    label: t('message_center.tabs.mentions.title', 'Mentions'),
    value: '/message_centre/mentions',
    counter: 'mentions',
    permissions: [],
  },
  {
    label: t('message_center.tabs.unread.title', 'Unread'),
    value: '/message_centre/unread',
    counter: 'messages',
    permissions: [],
  },
  {
    label: t('message_center.tabs.saved.title', 'Saved'),
    value: '/message_centre/saved',
    counter: 'saved',
    permissions: [],
  },
]

const MessageCenter: FunctionComponent<IMessageCenterProps> = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const { counters } = useSelector((state: IGlobalState) => ({
    counters: state.messages.counters,
  }))

  const countersGetDataAsync = promisifyAction(dispatch, countersGetData)
  const approvalsGetDataAsync = promisifyAction(dispatch, approvalsGetData)
  const mentionsGetDataAsync = promisifyAction(dispatch, mentionsGetData)
  const unreadGetDataAsync = promisifyAction(dispatch, unreadGetData)
  const getSavedMessagesAsync = promisifyAction(dispatch, getSavedMessages)
  const setMessagesCountersAsync = promisifyAction(
    dispatch,
    setMessagesCounters
  )

  useEffect(() => {
    const fetchDataAsync = async () => {
      await countersGetDataAsync()
    }
    fetchDataAsync()
  }, [])

  const handleReceivedNotifications = useCallback(
    async (response: any) => {
      if (response.message_type === 'messaging_counters') {
        const countersReceived = response.message
        if (countersReceived.approvals !== counters.approvals) {
          await approvalsGetDataAsync()
        }

        if (countersReceived.mentions !== counters.mentions) {
          await mentionsGetDataAsync()
        }

        if (countersReceived.messages !== counters.messages) {
          await unreadGetDataAsync({ page: 1, per_page: UNREADS_PER_PAGE })
        }

        if (countersReceived.saved !== counters.saved) {
          await getSavedMessagesAsync()
        }

        setMessagesCountersAsync({ counters: countersReceived })
      }
    },
    [counters]
  )

  const renderCount = useCallback((label: string, count: number) => {
    return (
      <div className="messageCenter-count">
        <div>{label}</div>
        {!!count &&
          (['Unread', 'Saved'].includes(label) ? (
            <Box
              sx={{
                display: 'flex',
                marginLeft: '2px',
                marginTop: '-15px',
                fontSize: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                top: 20,
                color: 'info.dark',
                backgroundColor: 'info.light',
                minWidth: '14px',
                minHeight: '14px',
                borderRadius: 10,
                padding: '3px',
              }}
            >
              {count}
            </Box>
          ) : (
            <span>
              <i className="icon attention" />
            </span>
          ))}
      </div>
    )
  }, [])

  const selectTab = useCallback(() => {
    const approvalsPerms: boolean = permissionTo([
      'shipments.private_chat.approvals.manage',
      'shipments.public_chat.approvals.manage',
    ])
    let tab: MessageCenterTab
    if (approvalsPerms) {
      switch (true) {
        case counters.approvals > 0:
          tab = '/approvals'
          break
        case counters.mentions > 0:
          tab = '/mentions'
          break
        case counters.messages > 0:
          tab = '/unread'
          break
        default:
          tab = '/approvals'
      }
    } else {
      switch (true) {
        case counters.mentions > 0:
          tab = '/mentions'
          break
        case counters.messages > 0:
          tab = '/unread'
          break
        default:
          tab = '/mentions'
      }
    }
    return tab
  }, [counters])

  const filteredTabs = tabs(t)
    .filter((tab) => {
      return tab.permissions.length === 0 ? true : permissionTo(tab.permissions)
    })
    .map((tab) => ({
      label: renderCount(tab.label, counters[tab.counter]),
      value: tab.value,
    }))

  const renderAllBlocks = useCallback(() => {
    const { match } = props
    const tabValue: string = getTabValue()

    return (
      <article>
        <Box mb={2}>
          <Typography
            variant="h2"
            children={t('message_center.title', 'Message center')}
          />
        </Box>
        <Paper className="quote__header message-center">
          <Tabs value={tabValue} onChange={history.push} tabs={filteredTabs} />
          <section className="quote__tab-section">
            <Switch>
              {permissionTo([
                'shipments.private_chat.approvals.manage',
                'shipments.public_chat.approvals.manage',
                'shipments.agents_chat.approvals.manage',
              ]) && (
                <Route
                  path="/message_centre/approvals"
                  component={MessageCenterApprovals}
                />
              )}
              <Route
                path="/message_centre/mentions"
                component={MessageCenterMentions}
              />
              <Route
                path="/message_centre/unread"
                component={MessageCenterUnread}
              />
              <Route
                path="/message_centre/saved"
                component={MessageCenterSavedMessages}
              />
              <Redirect to={`${match ? match.url : ''}${selectTab()}`} />
            </Switch>
          </section>
        </Paper>
      </article>
    )
  }, [counters, props])

  const getTabValue = useCallback((): string => {
    const { location } = props
    const approvalsPerms: boolean = permissionTo([
      'shipments.private_chat.approvals.manage',
      'shipments.public_chat.approvals.manage',
    ])
    return location.pathname.match(/^\/?message_centre\/?$/gm)
      ? approvalsPerms
        ? '/message_centre/approvals'
        : '/message_centre/mentions'
      : location.pathname
  }, [props])

  return (
    <>
      <UserChannelClient onReceived={handleReceivedNotifications} />
      {renderAllBlocks()}
    </>
  )
}

export default MessageCenter

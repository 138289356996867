import { put } from 'redux-saga/effects'

import { resolveAction, rejectAction, authorizedRequest } from '../wrappers'
import { Logger } from '../../../utils'
import * as actionCreators from '../../actionCreators'

export default function* (action: IPromisifiedAction): Iterator<any> {
  const { payload } = action

  try {
    const formData = new FormData()
    formData.append('file', payload.file)
    const response: any = yield authorizedRequest({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'POST',
      url: `/api/v1/purchase_orders/upload`,
      data: formData,
    })
    yield put(actionCreators.createShipmentDocumentSuccess(response.data.data))
    yield resolveAction(action)
  } catch (error) {
    const unknownError: any = error
    yield Logger.sagaError(unknownError, 'store::user::getUserData')
    yield rejectAction(action, unknownError)
  }
}

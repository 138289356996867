import { ReactNode, FunctionComponent } from 'react'

interface IProps {
  children: ReactNode
}

const StripedTableBody: FunctionComponent<IProps> = (props) => {
  return <div className="striped-table--body">{props.children}</div>
}

export default StripedTableBody

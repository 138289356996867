import Box from '@mui/material/Box'
import { permissionTo } from 'src/utils'
import Stack from '@mui/material/Stack'
import Tasks from 'src/components/Tasks'
import TopNavSelectSearch from 'src/components/TopNavBar/TopNavSelectSearch'
import Notifications from 'src/components/Notifications'
import { tasksPermissions } from './TopNavigation.constants'
import TopNavigationBrand from './components/TopNavigationBrand'
import TopNavigationLinks from './components/TopNavigationLinks'
import TopNavigationUserMenu from './components/TopNavigationUserMenu'
import TopNavigationCreateDropdown from './components/TopNavigationCreateDropdown'
import TopNavigationKnowledgedBaseLink from './components/TopNavigationKnowledgedBaseLink'
import LanguageSwitch from './LanguageSwitch'

const TopNavigation = ({
  email,
  avatar,
  isUser,
  fullName,
  navItems,
  visibilityTrial,
}) => {
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        data-testid="top-navigation-desktop"
      >
        <TopNavigationBrand />
        {isUser && (
          <TopNavigationCreateDropdown
            visibilityTrial={visibilityTrial}
            isMobile={false}
          />
        )}
      </Stack>
      {isUser && (
        <>
          <TopNavigationLinks navItems={navItems} />
          <Box>
            <TopNavSelectSearch />
          </Box>
          <Stack mx={1} spacing={1} direction="row">
            {permissionTo(tasksPermissions) && <Tasks />}
            <Notifications />
            <TopNavigationKnowledgedBaseLink />
          </Stack>
          <TopNavigationUserMenu
            email={email}
            avatar={avatar}
            name={fullName}
          />
        </>
      )}
      <LanguageSwitch />
    </>
  )
}

export default TopNavigation

import { Logger } from '../../utils/Logger'

function urlB64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

function unsubscribeWrapper(unsubscribe, logout, subscription) {
  unsubscribe(subscription)
  logout()
}

function unsubscribeAction(unsubscribe, logout) {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/sw.js')
      .then((sw) => {
        sw.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlB64ToUint8Array(
              'BKXFfRP-1cvDT8G7uZpmAoG8WyE9-4t6j7SKlrcy5Iljh7TNX63os-vMSe1eBGfJF-NprTawv3gvMKibUrEnzJE='
            ),
          })
          .then((subscription) => {
            unsubscribeWrapper(unsubscribe, logout, subscription)
          })
          .catch((error) => {
            Logger.log('ServiceWorker did not registered!', error)
            unsubscribeWrapper(unsubscribe, logout, {})
          })
      })
      .catch((error) => {
        Logger.log('ServiceWorker did not registered!', error)
        unsubscribeWrapper(unsubscribe, logout, {})
      })
  } else {
    unsubscribeWrapper(unsubscribe, logout, {})
  }
}

export { urlB64ToUint8Array, unsubscribeAction, unsubscribeWrapper }

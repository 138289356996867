import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/pickupAndDeliveries'
import * as actionCreators from '../../actionCreators/pickupsAndDeliveries'
import {
  simpleGetAndResolve,
  simplePutAndResolve,
  simplePostAndResolve,
  simplePatchAndResolve,
  simpleDeleteAndResolve,
} from '../factories'

const getPickupsAndDeliveries = simpleGetAndResolve(
  () => `api/v1/pickups_deliveries`,
  actionCreators.pickupsAndDeliveriesGetDataSuccess,
  (response) => response.data
)

const updatePickupsAndDeliveries = simplePutAndResolve(
  (action) => `api/v1/pickups_deliveries/${action.id}`,
  actionCreators.pickupsAndDeliveriesUpdateSuccess,
  (response) => response.data
)

const bulkEditTransporter = simplePatchAndResolve(
  (action) => `api/v1/pickups_deliveries/bulk_update`,
  actionCreators.bulkEditTransporterSuccess,
  (response) => response.data
)

const updatePickupsAndDeliveriesItem = simplePutAndResolve(
  (action) => `api/v1/pickups_deliveries/${action.id}`,
  actionCreators.updatePickupsAndDeliveriesItemSuccess,
  (response) => response.data
)

const updatePickupsAndDeliveriesShipmentStatus = simplePutAndResolve(
  (action) => `api/v1/shipments/${action.id}`,
  actionCreators.updatePickupsAndDeliveriesShipmentStatusSuccess,
  (response) => response.data
)

const getPickupsAndDeliveriesUserColumns = simpleGetAndResolve(
  () => `api/v1/pickups_deliveries/user_pickup_delivery_columns`,
  actionCreators.getPickupsAndDeliveriesUserColumnsSuccess,
  (response) => response.data
)

const updatePickupsAndDeliveriesUserColumns = simplePutAndResolve(
  () => `api/v1/pickups_deliveries/update_user_pickup_delivery_columns`,
  actionCreators.updatePickupsAndDeliveriesUserColumnsSuccess,
  (response) => response.data
)

const getOnePickupOrDelivery = simpleGetAndResolve(
  (action) => `api/v1/pickups_deliveries/${action.id}`,
  actionCreators.pickupsAndDeliveriesGetOneSuccess,
  (response) => response.data
)

const sendTransportEmail = simplePostAndResolve(
  (action) => `api/v1/pickups_deliveries/${action.id}/send_transport_email`,
  actionCreators.sendTransportEmailSuccess,
  (response) => response.data
)

const createPickupsAndDeliveriesQuickFilter = simplePostAndResolve(
  (action) => `api/v1/saved_filters`,
  actionCreators.createPickupsAndDeliveriesQuickFilterSuccess,
  (response) => response.data
)

const updatePickupsAndDeliveriesQuickFilter = simplePutAndResolve(
  (action) => `api/v1/saved_filters/${action.id}`,
  actionCreators.updatePickupsAndDeliveriesQuickFilterSuccess,
  (response) => response.data
)

const deletePickupsAndDeliveriesQuickFilter = simpleDeleteAndResolve(
  (action) => `/api/v1/saved_filters/${action.id}`,
  actionCreators.deletePickupsAndDeliveriesQuickFilterSuccess,
  (_response, action) => action
)

const updatePickupsAndDeliveriesQuickFilters = simplePutAndResolve(
  () => `/api/v1/saved_filters/bulk_update`,
  actionCreators.updatePickupsAndDeliveriesQuickFiltersSuccess,
  (response) => response.data
)

export const updateTaskStatus = simplePutAndResolve(
  (action) => `api/v1/shipments/${action.shipmentId}/tasks/${action.taskId}`,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeEvery(
    actions.PICK_UPS_AND_DELIVERIES_GET_DATA,
    getPickupsAndDeliveries
  )
  yield takeEvery(
    actions.PICK_UPS_AND_DELIVERIES_UPDATE_DATA,
    updatePickupsAndDeliveries
  )
  yield takeEvery(actions.BULK_EDIT_TRANSPORTER, bulkEditTransporter)
  yield takeEvery(
    actions.GET_PICK_UPS_AND_DELIVERIES_USER_COLUMNS,
    getPickupsAndDeliveriesUserColumns
  )
  yield takeEvery(
    actions.UPDATE_PICK_UPS_AND_DELIVERIES_USER_COLUMNS,
    updatePickupsAndDeliveriesUserColumns
  )
  yield takeEvery(
    actions.PICK_UPS_AND_DELIVERIES_GET_ONE,
    getOnePickupOrDelivery
  )
  yield takeEvery(actions.SEND_TRANSPORT_EMAIL, sendTransportEmail)
  yield takeEvery(
    actions.UPDATE_PICK_UPS_AND_DELIVERIES_SHIPMENT_STATUS,
    updatePickupsAndDeliveriesShipmentStatus
  )
  yield takeEvery(
    actions.PICK_UPS_AND_DELIVERIES_UPDATE_ITEM,
    updatePickupsAndDeliveriesItem
  )
  yield takeEvery(
    actions.ADD_PICK_UPS_AND_QUICK_FILTER,
    createPickupsAndDeliveriesQuickFilter
  )
  yield takeEvery(
    actions.UPDATE_PICK_UPS_AND_QUICK_FILTER,
    updatePickupsAndDeliveriesQuickFilter
  )
  yield takeEvery(
    actions.UPDATE_PICK_UPS_AND_QUICK_FILTERS,
    updatePickupsAndDeliveriesQuickFilters
  )
  yield takeEvery(
    actions.DELETE_PICK_UPS_AND_QUICK_FILTER,
    deletePickupsAndDeliveriesQuickFilter
  )
  yield takeEvery(actions.UPDATE_TASK_STATUS, updateTaskStatus)
}

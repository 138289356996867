import { convertToUnixDate } from 'src/utils/helpers'

const toUnixDate = (date: string[] | number[] | string | null): string | null =>
  date && typeof date === 'string' ? convertToUnixDate(date) : null

export const formatFiltersToParams = (filters: IShipmentFilters) => {
  return {
    pol: filters.pols,
    pod: filters.pods,
    load_type: filters.types,
    modality: filters.modalities,
    contact: filters.contacts,
    consignee: filters.consignees,
    status_ids: filters.statuses,
    shipper: filters.shippers,
    agent: filters.agents,
    customer: filters.customers,
    supplier: filters.suppliers,
    search: filters.search,
    order_by: filters.sortBy,
    missing_information: filters.missing_information,
    track_trace_statuses: filters.track_trace_statuses,
    arrival_date_start: toUnixDate(filters.arrivalDateStart),
    arrival_date_end: toUnixDate(filters.arrivalDateEnd),
    departure_date_start: toUnixDate(filters.departureDateStart),
    departure_date_end: toUnixDate(filters.departureDateEnd),
    scope: filters.scope,
    shipments_per_page: filters.shipmentsPerPage,
    page: filters.page,
    visibility_only: filters.visibility_only,
    my_shipments: filters.my_shipments,
    user_ids: filters.users,
  }
}

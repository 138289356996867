import { times } from 'lodash'
import TextPlaceholder from 'src/components/Common/TextPlaceholder'

const ShipmentBodyLoader = () => (
  <>
    <div className="shipment-overview-top__details">
      {times(2, (index) => (
        <div className="shipment-overview-top__details--block" key={index}>
          <div className="shipment-overview-top__details--block-title">
            <TextPlaceholder color="light-grey" size={2} />
          </div>
          <div className="shipment-overview-top__details--block-content">
            <TextPlaceholder color="light-grey" size={4} />
          </div>
        </div>
      ))}
    </div>
    <div className="shipment-overview-top__details">
      {times(4, (index) => (
        <div className="shipment-overview-top__details--block" key={index}>
          <div className="shipment-overview-top__details--block-title">
            <TextPlaceholder color="light-grey" size={2} />
          </div>
          <div className="shipment-overview-top__details--block-content">
            <TextPlaceholder color="light-grey" size={3} />
          </div>
        </div>
      ))}
    </div>
  </>
)

const ShipmentHeadingLoader = () => (
  <div className="shipment-overview__title">
    <header className="heading__2 blue flex-center">
      <TextPlaceholder color="dark-blue" size={3} />
      <TextPlaceholder color="dark-blue" className="mr-10" size={1} />
      <TextPlaceholder color="dark-blue" size={3} />
      <TextPlaceholder color="blue" size={3} />
    </header>
    <TextPlaceholder color="blue" size={3} />
  </div>
)

export { ShipmentBodyLoader, ShipmentHeadingLoader }

import { FunctionComponent, useCallback, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { times } from 'lodash'
import { useTranslation } from 'react-i18next'

import { LinearProgress } from 'src/stories'
import Table from 'src/components/Common/Table'
import PurchaseOrderRow from 'src/components/PurchaseOrders/PurchaseOrderRow'

import { dummyPurchaseOrder } from '../../../stores/reducers/purchaseOrders'
import PurchaseOrderRowTotals from '../PurchaseOrderRowTotals'
import { shipmentTableHeaders, commonTableHeaders } from './tableHeaders'

interface IPurchaseOrderTableProps {
  busy: boolean
  setCurrentPurchaseOrder: (purchaseOrder: IPurchaseOrder) => void
  deleteOpenedPurchaseOrder: (purchaseOrder: IPurchaseOrder) => void
  forShipment?: boolean
  shipmentId?: number
  fetchData?: () => void
  isBuyer: boolean
}

const PurchaseOrdersTable: FunctionComponent<IPurchaseOrderTableProps> = (
  props
) => {
  const [orders, setOrders] = useState<IPurchaseOrder[]>([])
  const { t } = useTranslation()
  const {
    purchaseOrders,
    shipmentPurchaseOrders,
    totalWeight,
    totalVolume,
  } = useSelector((state: IGlobalState) => ({
    totalWeight: state.shipmentPurchaseOrders.totalWeightKg,
    totalVolume: state.shipmentPurchaseOrders.totalVolumeCbm,
    purchaseOrders: state.purchaseOrders.list,
    shipmentPurchaseOrders: state.shipmentPurchaseOrders.list,
  }))

  useEffect(() => {
    setOrders(props.forShipment ? shipmentPurchaseOrders : purchaseOrders)
  }, [purchaseOrders, shipmentPurchaseOrders])

  const editPurchaseOrder = useCallback(
    (purchaseOrder: IPurchaseOrder): void => {
      props.setCurrentPurchaseOrder(purchaseOrder)
    },
    [orders]
  )

  const deletePurchaseOrder = useCallback(
    (purchaseOrder: IPurchaseOrder): void => {
      props.deleteOpenedPurchaseOrder(purchaseOrder)
    },
    [orders]
  )

  return (
    <>
      {props.busy && <LinearProgress />}

      <Table.StripedTable theme="rates">
        <Table.StripedTableHeader
          cells={
            props.forShipment ? shipmentTableHeaders(t) : commonTableHeaders(t)
          }
          component={'purchase-orders'}
        />
        <Table.StripedTableBody>
          {props.busy &&
            times(props.forShipment ? 6 : 10, (i) => (
              <PurchaseOrderRow
                testId="dummy-purchase-order-row"
                index={i}
                purchaseOrder={dummyPurchaseOrder}
                key={i}
                blank={true}
                editPurchaseOrder={editPurchaseOrder}
                deletePurchaseOrder={deletePurchaseOrder}
                forShipment={props.forShipment}
                shipmentId={props.shipmentId}
                isBuyer={props.isBuyer}
              />
            ))}

          {!props.busy &&
            orders.map((item: IPurchaseOrder, index: number) => (
              <PurchaseOrderRow
                testId="purchase-order-row"
                index={index}
                purchaseOrder={item}
                key={item.id}
                blank={false}
                editPurchaseOrder={editPurchaseOrder}
                deletePurchaseOrder={deletePurchaseOrder}
                forShipment={props.forShipment}
                shipmentId={props.shipmentId}
                fetchData={props.fetchData}
                isBuyer={props.isBuyer}
              />
            ))}
          {props.forShipment && !!orders.length && (
            <PurchaseOrderRowTotals
              totalWeight={totalWeight}
              totalVolume={totalVolume}
              busy={props.busy}
            />
          )}
        </Table.StripedTableBody>
      </Table.StripedTable>
    </>
  )
}

export default PurchaseOrdersTable

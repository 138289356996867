import { AnyAction } from 'redux'
import * as actions from '../actions/user'

export const userLoadState = (): AnyAction => ({
  type: actions.USER_LOAD_STATE,
})

export const userSignIn = (email: string, password: string): AnyAction => ({
  type: actions.USER_SIGN_IN,
  payload: { email, password },
})

export const userSignInSuccess = (payload: IUser): AnyAction => ({
  type: actions.USER_SIGN_IN_SUCCESS,
  payload,
})

export const userLoadStateSuccess = (payload: IUser): AnyAction => ({
  type: actions.USER_LOAD_STATE_SUCCESS,
  payload,
})

export const userSignOut = (): AnyAction => ({ type: actions.USER_SIGN_OUT })

export const userStoreState = (): AnyAction => ({
  type: actions.USER_STORE_STATE,
})

export const userGetUserData = (): AnyAction => ({
  type: actions.USER_GET_USER_DATA,
})

export const userLoadStateFailure = (): AnyAction => ({
  type: actions.USER_LOAD_STATE_FAILURE,
})

export const userGetUserDataSuccess = (payload: IUser): AnyAction => ({
  type: actions.USER_GET_USER_DATA_SUCCESS,
  payload,
})

export const userSubmitUserData = (payload: any): AnyAction => ({
  type: actions.USER_SUBMIT_USER_DATA,
  payload,
})

export const userUpdateUserData = (payload: any): AnyAction => ({
  type: actions.USER_UPDATE_USER_DATA,
  payload,
})

export const userUpdateUserSettingsData = (payload: any): AnyAction => ({
  type: actions.USER_UPDATE_USER_SETTINGS_DATA,
  payload,
})

export const userUpdateUserSettingsDataSuccess = (payload: any): AnyAction => ({
  type: actions.USER_UPDATE_USER_SETTINGS_DATA_SUCCESS,
  payload,
})

export const userUpdateNotification = (payload: any): AnyAction => ({
  type: actions.USER_SUBMIT_NOTIFICATION,
  payload,
})

export const userUpdateMilestone = (payload: any): AnyAction => ({
  type: actions.USER_SUBMIT_MILESTONE,
  payload,
})

export const userDoNothing = (payload: any): AnyAction => ({
  type: actions.USER_DO_NOTHING,
  payload,
})

export const userUpdatePassword = (payload: any): AnyAction => ({
  type: actions.USER_UPDATE_PASSWORD,
  payload,
})
export const userForgotPassword = (payload: any): AnyAction => ({
  type: actions.USER_FORGOT_PASSWORD,
  payload,
})

export const userForgotPasswordSuccess = (): AnyAction => ({
  type: actions.USER_FORGOT_PASSWORD_SUCCESS,
})

export const userChangePassword = (payload: any): AnyAction => ({
  type: actions.USER_CHANGE_PASSWORD,
  payload,
})

export const setOrdersVisit = (): AnyAction => ({
  type: actions.USER_SET_ORDERS_VISITED,
})

export const userSubscribeToBrowserNotifications = (
  payload: any
): AnyAction => ({
  type: actions.USER_SUBSCRIBE_TO_BROWSER_NOTIFICATIONS,
  payload,
})

export const userUnsubscribeToBrowserNotifications = (
  payload: any
): AnyAction => ({
  type: actions.USER_UNSUBSCRIBE_TO_BROWSER_NOTIFICATIONS,
  payload,
})

export const uploadAvatar = (avatar: any): AnyAction => ({
  type: actions.USER_AVATAR_UPLOAD,
  payload: { avatar },
})

export const userSyncShipmentGroups = (): AnyAction => ({
  type: actions.USER_SYNC_SHIPMENT_GROUPS,
})

import uuid from 'uuid/v4'
import { DateTime as DateTimeBusiness } from 'luxon-business-days'
import { DateTime } from 'luxon'
import { LoadTypeEnum, ModalityEnum } from 'src/config/constants'
import { SearchState } from './SearchContainer.props'
import { services, temperatureOptions } from './constants'
import { getIncotermServices } from './utils'

export const getFirstAvailableDepartureDate = (modality: ModalityEnum) =>
  // MUI DatePicker's doesn't work with luxon-business-days
  DateTime.fromISO(
    DateTimeBusiness.local()
      .plusBusiness({
        days: modality === ModalityEnum.Air ? 0 : 3,
      })
      .toISO()
  )

export const getDepartureDate = (
  currentDate: Date,
  modality: ModalityEnum
): DateTime => {
  const firstAvailableDepartureDate = getFirstAvailableDepartureDate(modality)

  const currentDepartureDate = DateTime.fromJSDate(currentDate)

  const actualDepartureDate =
    currentDepartureDate.startOf('day') <=
    firstAvailableDepartureDate.startOf('day')
      ? firstAvailableDepartureDate
      : currentDepartureDate

  return actualDepartureDate
}

export const searchQueryToState = (
  searchQuery,
  searchState: SearchState,
  { containerTypes }: { containerTypes: IContainerType[] }
) => {
  const currentTemperatureOptionLabel = temperatureOptions.find((item) => {
    return item.id === searchQuery.temperature_setting ? item.label : ''
  })

  return {
    ...(searchQuery.modality && { modality: searchQuery.modality }),
    ...(searchQuery.load_type && { load_type: searchQuery.load_type }),
    ...(searchQuery.sum_cbm && { sum_cbm: searchQuery.sum_cbm }),
    ...(searchQuery.purchase_order_line_ids && {
      purchase_order_line_ids: searchQuery.purchase_order_line_ids,
    }),
    ...(searchQuery.purchase_orders && {
      purchase_orders: searchQuery.purchase_orders,
    }),
    ...(searchQuery.start && {
      origin: {
        main_text: searchQuery.start,
        result_type: searchQuery.origin_type,
        [`${searchQuery.origin_type}_id`]: searchQuery.origin_id,
      },
    }),
    ...(searchQuery.end && {
      destination: {
        main_text: searchQuery.end,
        result_type: searchQuery.destination_type,
        [`${searchQuery.destination_type}_id`]: searchQuery.destination_id,
      },
    }),
    ...(searchQuery.departure_date && {
      departure_date: DateTime.fromISO(searchQuery.departure_date).toJSDate(),
    }),
    containers: containerTypes.reduce(
      (res, container) => ({
        ...res,
        [container.code]: parseInt(searchQuery[container.code]) || 0,
      }),
      {}
    ),
    ...(searchQuery.load_type === LoadTypeEnum.lcl &&
      searchQuery.quantities && {
        cargo: Array.from({
          length: searchQuery.quantities.length,
        }).map((_, index) => ({
          id: uuid(),
          quantity: searchQuery.quantities[index],
          height: searchQuery.heights[index],
          length: searchQuery.lengths[index],
          width: searchQuery.widths[index],
          weight: searchQuery.weights[index],
          total_volume_cbm: searchQuery.total_volume_cbms[index],
          package_type_id: searchQuery.package_type_ids[index],
          description: searchQuery.goods_descriptions[index],
          stackable: searchQuery.stackables
            ? searchQuery.stackables[index]
            : false,
        })),
      }),
    ...(searchQuery.tags && {
      tags: searchQuery.tags.reduce(
        (res: SearchState['tags'], tag: string) => ({
          ...res,
          [tag]: true,
        }),
        searchState.tags
      ),
    }),
    ...(searchQuery.temperature_setting && {
      temperature_setting: {
        label: currentTemperatureOptionLabel,
        id: searchQuery.temperature_setting,
      },
    }),
    services: services(searchState.modality).reduce((res, service) => {
      return {
        ...res,
        [service.value]:
          searchQuery[service.value] || searchState.services[service.value],
      }
    }, searchState.services),
  }
}

export const stateToRequestAndUrl = (
  state: SearchState,
  {
    userOrganizationId,
    containerTypes,
  }: { userOrganizationId: number; containerTypes: IContainerType[] }
) => {
  return Object.keys(state).reduce((res, key) => {
    switch (key) {
      case 'origin':
        if (!state.origin) return res
        return {
          ...res,
          start: state.origin.main_text,
          origin_id: state.origin[`${state.origin.result_type}_id`],
          origin_type: state.origin.result_type,
        }
      case 'destination':
        if (!state.destination) return res
        return {
          ...res,
          end: state.destination.main_text,
          destination_id:
            state.destination[`${state.destination.result_type}_id`],
          destination_type: state.destination.result_type,
        }
      case 'organization':
        if (!state.organization) return res
        return {
          ...res,
          customer_organization_id: state.organization.id,
          preferred_shipment_role_id:
            state.organization.preferred_shipment_role_id,
        }
      case 'role':
        if (!state.role) return res
        return {
          ...res,
          // for the backend
          shipment_organizations: [
            JSON.stringify({
              organization_id: userOrganizationId,
              role_ids: [state.role.id],
            }),
          ],
          // to restore state
          role_id: state.role.id,
        }
      case 'incoterm':
        if (!state.incoterm) return res
        return {
          ...res,
          // to restore state
          incoterm_id: state.incoterm.id,
        }
      case 'services':
        return { ...res, ...state.services }
      case 'cargo':
        if (!state.cargo) return res
        if (state.load_type === LoadTypeEnum.fcl) return res

        return {
          ...res,
          heights: state.cargo.map((item) => item.height),
          lengths: state.cargo.map((item) => item.length),
          widths: state.cargo.map((item) => item.width),
          weights: state.cargo.map((item) => item.weight),
          quantities: state.cargo.map((item) => item.quantity),
          total_volume_cbms: state.cargo.map((item) => item.total_volume_cbm),
          package_type_ids: state.cargo.map((item) => item.package_type_id),
          goods_descriptions: state.cargo.map((item) => item.description),
          stackables: state.cargo.map((item) => item.stackable),
        }
      case 'containers':
        if (state.load_type === LoadTypeEnum.lcl) return res
        return {
          ...res,
          ...state.containers,
          container_types: JSON.stringify(
            Object.keys(state.containers).reduce((res, key) => {
              const container = containerTypes.find(
                (container) => container.code === key
              )
              if (container) {
                return {
                  ...res,
                  [container.id]: state.containers[key],
                }
              }
              return res
            }, {})
          ),
        }
      case 'tags':
        return {
          ...res,
          tags: Object.entries(state.tags)
            .filter((pair) => pair[1])
            .map((pair) => pair[0]),
        }
      case 'departure_date':
        return {
          ...res,
          departure_date: DateTime.fromJSDate(state.departure_date).toISODate(),
        }
      case 'temperature_setting':
        if (state.modality === ModalityEnum.Air) {
          return {
            ...res,
            temperature_setting: state?.temperature_setting?.id,
          }
        } else {
          return { ...res }
        }
      default:
        return { ...res, [key]: state[key] }
    }
  }, {})
}

export const getUpdatedState = (
  field: string,
  value,
  prevLocalState: SearchState,
  organizationRoleRequiresIncotermBinding: boolean
) => {
  const sideEffects = {
    modality: (modality: ModalityEnum) => {
      if (modality === ModalityEnum.Air) {
        return {
          modality: modality,
          load_type: LoadTypeEnum.lcl,
          origin: null,
          destination: null,
        }
      }
      return {
        departure_date: getDepartureDate(new Date(), modality).toJSDate(),
        modality,
        origin: null,
        destination: null,
      }
    },
    role: (role: IRoleShort) => {
      if (!organizationRoleRequiresIncotermBinding) {
        return { role }
      }
      return {
        role,
        services: getIncotermServices(
          role.name,
          prevLocalState.incoterm,
          prevLocalState.services
        ),
      }
    },
    incoterm: (incoterm: IIncoterm) => {
      if (!organizationRoleRequiresIncotermBinding) {
        return { incoterm }
      }
      return {
        incoterm,
        services: getIncotermServices(
          prevLocalState.role?.name ?? '',
          incoterm,
          prevLocalState.services
        ),
      }
    },
  }
  if (!sideEffects[field]) {
    return { [field]: value }
  }
  return sideEffects[field](value)
}

export const intialCargo = {
  id: uuid(),
  length: 0,
  width: 0,
  height: 0,
  volume: 0,
  weight: 0,
  total_volume_cbm: 0,
  description: '',
  package_type_id: 9,
  quantity: 1,
  stackable: null,
}

import RvHookupIcon from '@mui/icons-material/RvHookup'
import { EmptyState } from 'src/stories'

const ContentTableEmptyState = () => {
  return (
    <EmptyState
      badgeColor="primary"
      badgeIcon={RvHookupIcon}
      title="Don't start the engine just yet"
      button={{ text: 'Book shipment', link: '/search' }}
      description="There are no pickups and deliveries scheduled over the next week."
    />
  )
}

export default ContentTableEmptyState

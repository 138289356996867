import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import * as actions from '../actions/countries'
import { ISelectOption } from './selectOptions'

declare global {
  interface ICountry {
    id: number
    name: string
    code: string
    trade_zone: string
  }

  interface ICountriesState {
    list: ISelectOption[]
    notFormatted: ICountry[]
  }
}

export const initialCountriesState: ICountriesState = {
  list: [],
  notFormatted: [],
}

const receiveList: Reducer<ICountriesState, AnyAction> = (state, action) => ({
  ...state,
  list: action.payload,
})

const receiveNotFormatted: Reducer<ICountriesState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  notFormatted: action.payload,
})

export default createReducer(initialCountriesState, {
  [actions.COUNTRIES_GET_COUNTRIES_SUCCESS]: receiveList,
  [actions.COUNTRIES_GET_COUNTRIES_NOT_FORMATTED_SUCCESS]: receiveNotFormatted,
})

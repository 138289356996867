import { FunctionComponent, useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Link, Typography } from '@mui/material'
import { find } from 'lodash'

import './styles.scss'

interface IDefaultOption {
  value: string
  name: string
  icon: string
}

interface IProps {
  className?: string
  placeholder?: string
  disabled?: boolean
  label?: string
  value?: string | number | null
  name?: string
  theme?: string
  onChange?: (value: string) => void
  options?: IDefaultOption[]
}

const DropDown: FunctionComponent<IProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<any>(null)

  const selectOption = (event: React.MouseEvent): void => {
    if (props.onChange) {
      props.onChange(event.currentTarget.id)
    }
    setAnchorEl(null)
  }

  const setAnchor = (event: React.MouseEvent): void => {
    setAnchorEl(event.currentTarget)
  }

  const resetAnchor = (): void => {
    setAnchorEl(null)
  }

  const optionName = (): React.ReactNode => {
    const option: IDefaultOption | undefined = find(props.options || [], {
      value: `${props.value}`,
    })
    return (
      <>
        <i className={`icon ${option ? option.icon : ''}`} />
        <Typography variant="body1Strong">
          {option ? option.name : ''}
        </Typography>
      </>
    )
  }

  const setOptions = (): React.ReactNode => {
    return (props.options || []).map((option) => (
      <MenuItem
        classes={{
          root: 'common__sorting-block--menu-item',
          selected: 'common__sorting-block--menu-item-selected',
        }}
        key={`${option.name}_${option.value}`}
        id={option.value}
        selected={option.value === props.value}
        onClick={selectOption}
      >
        <>
          <i className={`icon ${option.icon}`} />
          <Typography variant="body1">{option.name}</Typography>
        </>
      </MenuItem>
    ))
  }

  return (
    <div className={props.theme}>
      <div className="common__sorting-block">
        <Link
          variant="body1"
          component="button"
          className={`common__sorting-block--main ${anchorEl && 'active'}`}
          aria-owns={'simple-menu'}
          aria-haspopup="true"
          onClick={setAnchor}
        >
          <div className="common__sorting-block--menu small">
            {optionName()}
            <i className={`icon chevron ${anchorEl ? 'revert' : ''}`} />
          </div>
        </Link>
        <Menu
          classes={{ paper: 'common__sorting-block--menu-items' }}
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={resetAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {setOptions()}
        </Menu>
      </div>
    </div>
  )
}

DropDown.defaultProps = {
  disabled: false,
  className: '',
  onChange: () => undefined,
  options: [],
}

export default DropDown

import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { history } from 'src/shyppleStore'
import { routes } from 'src/pages/AddressBook'
import { showNotification } from 'src/stores/actionCreators/notifications'
import {
  addressesAddAddress,
  setDefaultAddress,
} from '../../../stores/actionCreators'
import { promisifyAction } from '../../../utils'
import { IAddressExtended } from './index'

const SaveAddressButton = ({
  disabled,
  onClick,
}: {
  disabled: boolean
  onClick: (
    saveAddress: (address: IAddressExtended) => void,
    showError: (message: string) => void
  ) => void
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const showError = (text: string) =>
    dispatch(showNotification({ message: text, severity: 'error' }))
  const createAddress = promisifyAction(dispatch, addressesAddAddress)
  const setDefault = promisifyAction(dispatch, setDefaultAddress)
  const saveAddress = async (address: IAddressExtended) => {
    const res = await createAddress({
      ...address,
      port_ids: address.port_ids || [],
    })
    if (address.shouldBeDefault) {
      await setDefault({ ...address, id: res.id })
    }
    history.push(routes.companies)
    dispatch(
      showNotification({
        message: `Address "${address.name}" added.`,
        severity: 'success',
      })
    )
  }
  return (
    <Button
      disabled={disabled}
      variant="contained"
      color="primary"
      disableElevation
      onClick={() => onClick(saveAddress, showError)}
    >
      {t('address_book.add_address.save_address', 'Save address')}
    </Button>
  )
}

export default SaveAddressButton

import { AnyAction } from 'redux'
import * as actions from '../actions/quotes'

export const quotesGetData = (payload): AnyAction => ({
  type: actions.QUOTES_GET_DATA,
  payload,
})

export const quotesGetDataSuccess = (payload: any): AnyAction => ({
  type: actions.QUOTES_GET_DATA_SUCCESS,
  payload,
})

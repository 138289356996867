import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Divider, Link, Stack, Typography, Box } from '@mui/material'
import { chatsMarkAllRead } from 'src/stores/actionCreators'
import NotificationsContentList from './NotificationsContentList'

const NotificationsContent = ({
  list,
  contentWidth = 400,
  contentHeigh = '60vh',
  onClickNotification,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const markAllAsRead = () => {
    dispatch(chatsMarkAllRead())
  }

  return (
    <>
      <Box
        sx={{
          top: 0,
          zIndex: 1,
          position: 'sticky',
          backgroundColor: 'common.white',
        }}
      >
        <Stack direction="row" spacing={1} justifyContent="space-between" p={2}>
          <Typography
            variant="body1Strong"
            children={t(
              'top_navigation.notifications.unread_title',
              'Unread Notifications'
            )}
          />
          <Link variant="body1" component="button" onClick={markAllAsRead}>
            {t(
              'top_navigation.notifications.mark_as_read_link',
              'Mark all as read'
            )}
          </Link>
        </Stack>
        <Divider />
      </Box>
      <Box width={contentWidth} height={contentHeigh}>
        <NotificationsContentList
          list={list}
          onClickNotification={onClickNotification}
        />
      </Box>
    </>
  )
}

export default NotificationsContent

import { Box } from '@mui/material'
import shyppleIcon from 'src/assets/images/shyppleBadge.svg'

export type BadgeColor =
  | 'primary'
  | 'secondary'
  | 'default'
  | 'error'
  | 'success'
  | 'warning'

export const badgeColorsBasedOnTnTStatus: {
  [key: string]: BadgeColor
} = {
  active: 'success',
  completed: 'success',
  paused: 'warning',
  error: 'error',
  pending: 'secondary',
  archived: 'default',
  no_subscription: 'default',
}

export const iconColorsBasedOnTnTStatus = {
  active: 'success',
  completed: 'success',
  paused: 'warning',
  error: 'error',
  pending: 'secondary',
  archived: 'disabled',
  no_subscription: 'disabled',
}

export const shyppleIconStatuses = [
  'paused',
  'error',
  'pending',
  'archived',
  'no_subscription',
]

export const shyppleShipmentsTooltipText = {
  active: 'shipment_card.tooltip.track_and_trace_active',
  completed: 'shipment_card.tooltip.shipment_delivered',
  paused: 'shipment_card.tooltip.providing_accurate_status_and_eta',
  error: 'shipment_card.tooltip.providing_accurate_status_and_eta',
  pending: 'shipment_card.tooltip.providing_accurate_status_and_eta',
  archived: 'shipment_card.tooltip.providing_accurate_status_and_eta',
  no_subscription: 'shipment_card.tooltip.providing_accurate_status_and_eta',
}

export const visibilityShipmentsTooltipText = {
  active: 'shipment_card.shipment_visibility_tooltip.active',
  completed: 'shipment_card.shipment_visibility_tooltip.completed',
  paused: 'shipment_card.shipment_visibility_tooltip.paused',
  error: 'shipment_card.shipment_visibility_tooltip.error',
  pending: 'shipment_card.shipment_visibility_tooltip.pending',
  archived: 'shipment_card.shipment_visibility_tooltip.archived',
  no_subscription: 'shipment_card.shipment_visibility_tooltip.no_subscription',
}

export const returnShyppleIcon = (
  <Box
    sx={{
      borderRadius: '50%',
      border: '2px solid white',
      width: '18px',
      height: '18px',
    }}
  >
    <img src={shyppleIcon} alt="shypple-icon" />
  </Box>
)

export const exceptionsCounter = (value) => (
  <Box
    sx={{
      borderRadius: '4px',
      width: '15px',
      height: '15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      border: '2px solid white',
      backgroundColor: 'var(--error-700)',
      fontSize: '10px',
    }}
  >
    {value}
  </Box>
)

export const warningBadge = (color) => (
  <Box
    sx={{
      borderRadius: '4px',
      width: '15px',
      height: '15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      border: '2px solid white',
      backgroundColor: `var(--${
        color === 'secondary' ? 'secondary-500)' : 'warning-400)'
      }`,
      fontSize: '10px',
    }}
  >
    !
  </Box>
)

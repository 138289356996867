import i18next from 'i18next'
import { ModalityEnum } from 'src/config/constants'
import { validateCargoDimensions } from 'src/utils/helpers/validations/index'

export function cargoItemValidation(
  item: IInitialCargoItem,
  modality: ModalityEnum
) {
  const errors = {}

  const dimensionsError = validateCargoDimensions(
    item.length,
    item.width,
    item.height,
    modality
  )

  if (!!dimensionsError) {
    errors['dimensions'] = dimensionsError
  }

  if (Number(item.length) % 1 !== 0) {
    const lengthError = i18next.t(
      'shipment_containers.cargo_window.validations.length_invalid',
      {
        defaultValue:
          'Please select a valid value. The two nearest valid values are {{val1}} and {{val2}}.',
        val1: Math.floor(item.length || 0),
        val2: Math.ceil(item.length || 0),
      }
    )
    errors['dimensions'] = lengthError
  }

  if (
    (Number(item.length) || Number(item.width) || Number(item.height)) < 1 &&
    item.total_volume_cbm < 1
  ) {
    errors['dimensions'] = i18next.t(
      'shipment_containers.cargo_window.validations.must_be_greater_than_zero',
      'Must be greater than 0'
    )
  }

  if (Number(item.width) % 1 !== 0) {
    const widthError = i18next.t(
      'shipment_containers.cargo_window.validations.width_invalid',
      {
        defaultValue:
          'Please select a valid value. The two nearest valid values are {{val1}} and {{val2}}.',
        val1: item.width || 0,
        val2: Math.ceil(item.width || 0),
      }
    )
    errors['dimensions'] = widthError
  }

  if (Number(item.height) % 1 !== 0) {
    const heightError = i18next.t(
      'shipment_containers.cargo_window.validations.height_invalid',
      {
        defaultValue:
          'Please select a valid value. The two nearest valid values are {{val1}} and {{val2}}.',
        val1: item.height || 0,
        val2: Math.ceil(item.height || 0),
      }
    )
    errors['dimensions'] = heightError
  }

  if (Number(item.total_weight_kg) <= 0) {
    errors['total_weight_kg'] = i18next.t(
      'shipment_containers.cargo_window.validations.must_be_greater_than_zero',
      'Must be greater than 0'
    )
  }

  if (!item.goods_description) {
    errors['goods_description'] = i18next.t(
      'shipment_containers.cargo_window.validations.goods_required',
      "Goods description can't be blank"
    )
  }

  if (item.quantity < 1) {
    errors['quantity'] = i18next.t(
      'shipment_containers.cargo_window.validations.must_be_greater_than_zero',
      'Must be greater than 0'
    )
  }

  if (Number(item.total_volume_cbm) <= 0) {
    errors['total_volume_cbm'] = i18next.t(
      'shipment_containers.cargo_window.validations.must_be_greater_than_zero',
      'Must be greater than 0'
    )
  }

  if (typeof item.stackable !== 'boolean') {
    errors['stackable'] = i18next.t(
      'shipment_containers.cargo_window.validations.stackable_required',
      'Please select stackable'
    )
  }

  return errors
}

// FIXME
import { useState } from 'react'
import DashboardEmptyLine from 'src/components/DashboardOverview/DashboardEmptyLine'
import DashboardShipmentLine from 'src/components/DashboardOverview/DashboardShipmentLine'
import LoadableContainer from 'src/components/LoadableContainer'
import { formatDate } from './helpers'

interface IDashboardSupplyChainShipmentsProps {
  shipments: IShipment[]
  statusGroup: StatusGroupType
}

function DashboardSupplyChainShipments({
  shipments,
}: IDashboardSupplyChainShipmentsProps) {
  const [busy] = useState<boolean>(false)

  return (
    <LoadableContainer loading={busy}>
      <div className="supply-chain__records-block">
        {!!shipments.length ? (
          shipments.map((shipment) => (
            <DashboardShipmentLine
              shipment={shipment}
              key={shipment.id}
              link={`/shipments/${shipment.id}`}
              grey={shipment.status_group === 'supplier_initiated_booking'}
            >
              {shipment.status === 'Delivered' ? (
                <div>
                  <div>Delivered on {formatDate(shipment.delivered_at)} </div>
                  <div className="subtitle">
                    {shipment.delivery_address_name}
                  </div>
                </div>
              ) : (
                <span>{shipment.status}</span>
              )}
            </DashboardShipmentLine>
          ))
        ) : (
          <DashboardEmptyLine />
        )}
      </div>
    </LoadableContainer>
  )
}

export default DashboardSupplyChainShipments

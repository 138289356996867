import { get, snakeCase, uniqBy } from 'lodash'
import snakecaseKeys from 'snakecase-keys'

import {
  getPayloadAddresses,
  getPayloadBookingPartyCollaborators,
} from 'src/components/Templates/Form/Payload.utils'

const readyFields = [
  'cifValue',
  'incoterm',
  'services',
  'container_number',
  'booking_number',
  'bl_number',
]

export const getFormPayload = ({ id = '', formState, shipmentRoles, user }) => {
  const bookingPartyCollaborators = getPayloadBookingPartyCollaborators(
    formState,
    shipmentRoles
  )

  const addresses = getPayloadAddresses(formState)

  const payload = {
    addresses,
    shipment_role_id: formState.shipmentRole,
    collaborators: [
      {
        organization_id: user.organizationId,
        role_ids: [formState.shipmentRole],
      },
      ...bookingPartyCollaborators,
    ],
    customer_organization_id: user.organizationId,
    carrier_id: formState.carrier?.id,
    shared_reference: formState.references.sharedReference,
    services_only: true,
    // to state the obvious
    services: {
      freight: false,
    },
  }

  if (formState.customer) {
    payload.customer_organization_id = formState.customer.id
    payload.collaborators = [
      ...payload.collaborators,
      {
        organization_id: formState.customer.id,
        role_ids: [formState.customer.preferred_shipment_role_id],
      },
    ]
  }

  payload.collaborators = uniqBy(payload.collaborators, 'organization_id')

  readyFields.forEach((field) => {
    const value = get(formState, field)
    const key = snakeCase(field)
    if (value && typeof value === 'object') {
      payload[key] = snakecaseKeys(value)
    } else {
      payload[key] = value
    }
  })

  // just to prevent the freight service to be selected by changing Incoterms input:
  ;(payload as any).services.freight = false

  return payload
}

import { FunctionComponent } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Trans, useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { InfoTooltip } from '../../stories'
import { calculateEmissions } from '../../utils/helpers'

interface IProps {
  shipment: IOverviewShipment
  isPublicShipment?: boolean
}

interface TotalEmissionItemProps {
  title: string
  amount: number
}

const rightHandGridItemStyles = { display: 'flex', justifyContent: 'flex-end' }

const TotalEmissionItem: React.FC<TotalEmissionItemProps> = ({
  title,
  amount,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <Grid item xs={7}>
        <Link
          title={title}
          target="_blank"
          rel="noopener noreferrer"
          href={t(
            'shipment_details.carbon_emission_tooltip.item_external_link'
          )}
        >
          {title}
        </Link>
      </Grid>
      <Grid sx={rightHandGridItemStyles} item xs={5}>
        {`${calculateEmissions(amount)} kg`}
      </Grid>
    </>
  )
}

const CarbonEmissionsBlock: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const emissionsTooltipBody = (): React.ReactElement => {
    if (!props.shipment.co2_emissions) {
      return <div />
    }

    const title: string = t(
      'shipment_details.carbon_emission_tooltip_line_one',
      {
        container:
          props.shipment.shipment_type === 'fcl'
            ? t('common.modality_sea.container')
            : t('common.modality_air.container'),
      }
    )

    return (
      <>
        <Box component="section">{title}</Box>
        <Grid sx={{ py: 2 }} container columnSpacing={2} rowSpacing={0.25}>
          <Grid item xs={7}>
            {t('shipment_details.carbon_emission_tooltip_total_emission')}
          </Grid>
          <Grid sx={rightHandGridItemStyles} item xs={5}>
            {`${calculateEmissions(
              props.shipment.co2_emissions.total_emission_gram
            )} kg`}
          </Grid>

          <TotalEmissionItem
            title={t('shipment_details.carbon_emission_tooltip_well_to_tank')}
            amount={props.shipment.co2_emissions.wtt_emission_gram}
          />
          <TotalEmissionItem
            title={t('shipment_details.carbon_emission_tooltip_tank_to_wheel')}
            amount={props.shipment.co2_emissions.ttw_emission_gram}
          />
        </Grid>
        <Box component="section">
          <Trans
            i18nKey="shipment_details.carbon_emission_tooltip.line_with_link"
            components={{
              externalLink: (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Sustainability page"
                  href={t(
                    'shipment_details.carbon_emission_tooltip.external_link'
                  )}
                />
              ),
            }}
          />
        </Box>
      </>
    )
  }

  return (
    <div className="carbon-emissions">
      <Typography component="span" variant="body1">
        {calculateEmissions(
          props.shipment.co2_emissions
            ? props.shipment.co2_emissions.total_emission_gram
            : 0
        ) + ' kg'}
      </Typography>
      <InfoTooltip title={emissionsTooltipBody()} />
    </div>
  )
}

export default CarbonEmissionsBlock

import { FunctionComponent, memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Box, Paper, Typography } from '@mui/material'
import {
  toggleEditWindow,
  pickupsAndDeliveriesSelectedIds,
} from 'src/stores/actionCreators'
import PickupAndDeliveriesFilters from './PickupsAndDeliveriesFilters'
import PickupsAndDeliveriesTable from './PickupsAndDeliveriesTable'
import PickupsAndDeliveriesTabs from './PickupsAndDeliveriesTabs'
import PickupsAndDeliveriesTableActions from './PickupsAndDeliveriesTableActions'
import PickupDeliveriesPagination from './PickupsAndDeliveriesPagination'
import PickupsAndDeliveriesHeaderActions from './PickupsAndDeliveriesHeaderActions'
import PickupDeliveryDrawer from './PickupsAndDeliveriesDrawer'
import PickupsAndDeliveriesEditWindow from './PickupsAndDeliveriesEditWindow'
import './styles.scss'

const PickupsAndDeliveries: FunctionComponent = () => {
  const dispatch = useDispatch()

  const [filtersOpen, setFiltersOpen] = useState<boolean>(
    localStorage.getItem('showContainersFilters') === 'true'
  )

  useEffect(() => {
    return () => {
      dispatch(pickupsAndDeliveriesSelectedIds([]))
    }
  }, [])

  const { selectedRowsIds, isWindowOpen } = useSelector(
    (state: IGlobalState) => ({
      selectedRowsIds:
        state.pickUpsAndDeliveries.selectedInlandTransportRowsIds,
      isWindowOpen: state.pickUpsAndDeliveries.isEditTransportWindowOpen,
    })
  )

  const isSelectedRows = useMemo(() => {
    return !!selectedRowsIds.length
  }, [selectedRowsIds])

  const closeEditWindow = () =>
    dispatch(toggleEditWindow({ status: false, type: '' }))

  const openEditWindow = (type) =>
    dispatch(toggleEditWindow({ status: true, type }))
  const { t } = useTranslation()
  return (
    <Box
      className={`pickups-and-deliveries ${
        filtersOpen ? 'pickups-and-deliveries--show-filters' : ''
      }  `}
    >
      <Grid container spacing={0} mb={2}>
        <Grid item xs={12} sm={12} md={4} display="flex" alignItems="center">
          <Typography variant="h2">
            {t('top_navigation.transports.title', 'Transports')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: {
              xs: 'start',
              sm: 'start',
              md: 'end',
              lg: 'end',
              xl: 'end',
            },
          }}
        >
          <PickupsAndDeliveriesHeaderActions
            onFiltersVisibilityToggle={() => {
              setFiltersOpen(!filtersOpen)
            }}
          />
        </Grid>
      </Grid>
      <PickupAndDeliveriesFilters filtersOpen={filtersOpen} />
      <Paper sx={{ mt: 2 }} className="pickups-and-deliveries__table-section">
        {isSelectedRows && (
          <PickupsAndDeliveriesTableActions openWindow={openEditWindow} />
        )}
        <PickupsAndDeliveriesTabs />
        <PickupsAndDeliveriesTable filtersOpen={filtersOpen} />
        <PickupDeliveriesPagination />
      </Paper>
      <PickupDeliveryDrawer />
      <PickupsAndDeliveriesEditWindow
        open={isWindowOpen}
        handleClose={closeEditWindow}
      />
    </Box>
  )
}

export default memo(PickupsAndDeliveries, () => true)

import {
  FunctionComponent,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import { History, LocationState } from 'history'
import { isEmpty, pickBy } from 'lodash'
import { stringify, parse } from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import { LinearProgress } from 'src/stories'
import LoadableContainer from 'src/components/LoadableContainer'
import ConfirmDialog from 'src/components/ConfirmDialog'
import BlankState from 'src/components/Common/BlankState'
import ServiceLevelAgreementsModalWindow from 'src/components/OrganizationSettings/SLA/ServiceLevelAgreementsModalWindow'
import ServiceLevelAgreementsTable from 'src/components/OrganizationSettings/SLA/ServiceLevelAgreementsTable'
import { promisifyAction } from '../../../utils'
import { dummySLA } from '../../../stores/reducers/organizationSettings'

import {
  slaGetData,
  slaOpenItem,
  deleteSLA,
  addressesGetData,
} from '../../../stores/actionCreators'

import './styles.scss'

interface IProps {
  history: History<LocationState>
  location: Location
}

const SLA: FunctionComponent<IProps> = (props) => {
  const [isSLAModalOpen, setIsSLAModalOpen] = useState<boolean>(false)
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState<boolean>(false)
  const [currentSLA, setCurrentSLA] = useState<ISLA | null>(null)
  const [busy, setBusy] = useState<boolean>(true)
  const dispatch = useDispatch()

  const slaGetDataAsync = promisifyAction(dispatch, slaGetData)
  const addressesGetDataAsync = promisifyAction(dispatch, addressesGetData)
  const deleteSLAAsync = promisifyAction(dispatch, deleteSLA)

  const { slaCheckpoints, openedSLAId } = useSelector(
    (state: IGlobalState) => ({
      slaCheckpoints: state.organizationSettings.list,
      openedSLAId: state.organizationSettings.openItem,
    })
  )

  useEffect(() => {
    const locationSearch = parseLocationSearch()
    if (!isEmpty(locationSearch)) {
      if (locationSearch.open_item) {
        dispatch(slaOpenItem(parseInt(locationSearch.open_item as any)))
      }
    }

    const fetchSLAAsync = async () => {
      setBusy(true)
      await slaGetDataAsync()
      await addressesGetDataAsync()
      setBusy(false)
    }

    fetchSLAAsync()

    return () => {
      dispatch(slaOpenItem(null))
    }
  }, [])

  useEffect(() => {
    props.history.push({
      search: stringify(pickBy({ open_item: openedSLAId }), {
        arrayFormat: 'bracket',
      }),
    })
  }, [openedSLAId])

  const setSLA = async (slaCheckpoint: ISLA): Promise<any> => {
    await setCurrentSLA(slaCheckpoint)
    setIsSLAModalOpen(true)
  }

  const removeSLA = async (slaCheckpoint: ISLA): Promise<any> => {
    await setCurrentSLA(slaCheckpoint)
    setConfirmDeleteIsOpen(true)
  }

  const parseLocationSearch = useCallback(() => {
    return parse(props.location.search, {
      arrayFormat: 'bracket',
    })
  }, [props.location.search])

  const isEmptyState: boolean = useMemo(() => {
    return slaCheckpoints && !slaCheckpoints.length && !busy
  }, [slaCheckpoints, busy])

  const isNotEmptyState: boolean = useMemo(() => {
    return slaCheckpoints && !!slaCheckpoints.length
  }, [slaCheckpoints])

  const onCloseWindow = (): void => {
    setIsSLAModalOpen(false)
    setCurrentSLA(null)
  }

  const fetchData = async (): Promise<any> => {
    await slaGetDataAsync()
  }

  const confirmDeleteSLA = async (): Promise<any> => {
    await deleteSLAAsync(currentSLA ? currentSLA.id : 0)
    setConfirmDeleteIsOpen(false)
    setCurrentSLA(null)
    fetchData()
  }

  const { t } = useTranslation()

  const blankStateIcons = [
    {
      icon: 'pencil',
      text: t(
        'organization_settings.service_level_agreement.empy_state.set_up',
        'Set up SLA'
      ),
    },
    {
      icon: 'file',
      text: t(
        'organization_settings.service_level_agreement.empy_state.automated_alerts',
        'Send automated alerts'
      ),
    },
    {
      icon: 'file',
      text: t(
        'organization_settings.service_level_agreement.empy_state.track_performance',
        'Track perfomance of your suppliers'
      ),
    },
  ]

  return (
    <div className="sla-page">
      <LoadableContainer
        loading={busy}
        placeholder={
          <div>
            <LinearProgress />
            <ServiceLevelAgreementsTable
              busy={busy}
              fetchData={fetchData}
              setCurrentSLA={setSLA}
              deleteOpenedSLA={removeSLA}
            />
          </div>
        }
      >
        {isNotEmptyState && (
          <Button
            className="add-checkpoint-button"
            variant="contained"
            onClick={setIsSLAModalOpen.bind(null, true)}
          >
            {t(
              'organization_settings.service_level_agreement.button_checkpoint',
              'Add new checkpoint'
            )}
          </Button>
        )}
        {isNotEmptyState && (
          <ServiceLevelAgreementsTable
            busy={busy}
            fetchData={fetchData}
            setCurrentSLA={setSLA}
            deleteOpenedSLA={removeSLA}
          />
        )}
        {isEmptyState && (
          <BlankState.Action
            theme="PO"
            type="button"
            buttonText={t(
              'organization_settings.service_level_agreement.button',
              'Add new SLA for your organization'
            )}
            buttonAction={setIsSLAModalOpen.bind(null, true)}
            iconsArray={blankStateIcons}
          />
        )}
      </LoadableContainer>

      <ServiceLevelAgreementsModalWindow
        open={isSLAModalOpen}
        close={onCloseWindow}
        fetchData={fetchData}
        slaCheckpoint={currentSLA || dummySLA}
      />
      <ConfirmDialog
        title="Confirmation"
        message={t(
          'organization_settings.service_level_agreement.confirm_delete'
        )}
        isOpen={confirmDeleteIsOpen}
        confirm={confirmDeleteSLA}
        reject={setConfirmDeleteIsOpen.bind(null, false)}
        onClose={setConfirmDeleteIsOpen.bind(null, false)}
      />
    </div>
  )
}
export default SLA

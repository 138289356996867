import { AnyAction } from 'redux'
import { select, call, CallEffect } from 'redux-saga/effects'

import { userInformationSelector } from '../'
import { IPersonalInformation } from './interfaces'

type HTTPMethod = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE'

function* reportTimeout(
  makeUrl: (action: AnyAction) => string,
  action: AnyAction,
  method: HTTPMethod
): Iterator<any> {
  const creds: IPersonalInformation | undefined = yield select(
    userInformationSelector
  )
  ;(window as any).onerror(
    `Timeout error at ${method} ${makeUrl(
      action
    )} with payload ${JSON.stringify(action.payload)}, ${JSON.stringify(
      creds
    )}`,
    makeUrl(action)
  )
}

export default (
  makeUrl: (action: AnyAction) => string,
  action: AnyAction,
  method: HTTPMethod
): CallEffect => call(reportTimeout, makeUrl, action, method)

import bind from 'autobind-decorator'
import { PureComponent } from 'react'
import { values, max, omit } from 'lodash'
import FormatPaintIcon from '@mui/icons-material/FormatPaint'
import Tooltip from '@mui/material/Tooltip'
import { EmptyState } from 'src/stories'
import DashboardSupplyChainBlock from 'src/components/DashboardSupplyChain/DashboardSupplyChainBlock'
import DashboardTooltip from 'src/components/DashboardSupplyChain/DashboardTooltip'
import { statusGroups } from '../statusGroups'

interface IDashboardSupplyChainChartProps {
  onToggle: (statusGroup: StatusGroupType) => void
  overview: ISupplyChainOverview
}

interface IDashboardSupplyChainChartState {
  activeStatusGroup?: StatusGroupType
  open: boolean
  isSmallScreen: boolean
}

const classesTooltip = {
  tooltip: 'supply-chain__tooltip',
  popper: 'supply-chain__popper',
}

class DashboardSupplyChainChart extends PureComponent<
  IDashboardSupplyChainChartProps,
  IDashboardSupplyChainChartState
> {
  private resizeThrottleTimeout: any = undefined

  constructor(props) {
    super(props)
    this.state = {
      activeStatusGroup: undefined,
      open: false,
      isSmallScreen: false,
    }
  }

  public componentDidMount(): void {
    this.throttledResizeHandler()
    window.addEventListener('resize', this.throttledResizeHandler)
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.throttledResizeHandler)
  }

  public render(): React.ReactNode {
    const deliveredActiveClass =
      this.state.activeStatusGroup === 'delivered' ? 'active' : ''
    const maxCount: number =
      max(
        values(omit(this.props.overview, 'delivered')).map((block) => {
          return block.count
        })
      ) || 0
    const hideClass = !maxCount ? 'hide-number' : ''
    const hideBlockClass = !maxCount ? 'hide-block' : ''
    return (
      <div className="supply-chain__chart">
        {!maxCount && (
          <EmptyState
            badgeColor="info"
            badgeIcon={FormatPaintIcon}
            title="We can't paint a clear picture yet"
            button={{ text: 'Search & book', link: '/search' }}
            description="Once you start shipping with us we'll show you a visual summary of your supply chain here. Go ahead and book!"
          />
        )}
        {!!maxCount && (
          <>
            <div className="supply-chain__chart__graph">
              {values(statusGroups).map((blockInfo) => (
                <DashboardSupplyChainBlock
                  isSmallScreen={this.state.isSmallScreen}
                  key={blockInfo.statusGroup}
                  icon={blockInfo.icon}
                  statusGroup={blockInfo.statusGroup}
                  activeGroup={this.state.activeStatusGroup}
                  caption={blockInfo.caption}
                  maxCount={maxCount}
                  counts={this.props.overview[blockInfo.statusGroup]}
                  onActivate={this.deactivateOthers}
                  isActive={
                    this.state.activeStatusGroup === blockInfo.statusGroup
                  }
                />
              ))}
            </div>
            <div
              className={`supply-chain__chart__delivered ${deliveredActiveClass} ${hideBlockClass}`}
              onMouseEnter={this.handleTooltipOpen}
              onMouseLeave={this.handleTooltipClose}
              onClick={this.showDelivered}
            >
              <Tooltip
                title={this.renderTooltipData()}
                placement="right-start"
                classes={classesTooltip}
                open={this.state.open}
                disableHoverListener={true}
              >
                <div>
                  <div className={`delivered-content ${hideClass}`}>
                    <div className="delivered-content--number">
                      {this.props.overview.delivered.count}
                    </div>
                    {!this.state.isSmallScreen && (
                      <div className="delivered-content--stats">
                        {!!this.props.overview.delivered.fcl_count && (
                          <div className="delivered-content--stat-line">
                            <i className="icon container20ft" /> x
                            <span className="types-count">
                              {this.props.overview.delivered.fcl_count}
                            </span>
                          </div>
                        )}
                        {!!this.props.overview.delivered.lcl_count && (
                          <div className="delivered-content--stat-line">
                            <i className="icon lcl" /> x
                            <span className="types-count">
                              {this.props.overview.delivered.lcl_count}
                            </span>
                          </div>
                        )}
                        {!!this.props.overview.delivered.air_count && (
                          <div className="delivered-content--stat-line">
                            <i className="icon airport" /> x
                            <span className="types-count">
                              {this.props.overview.delivered.air_count}
                            </span>
                          </div>
                        )}
                        {!!this.props.overview.delivered.rail_count && (
                          <div className="delivered-content--stat-line">
                            <i className="icon train" /> x
                            <span className="types-count">
                              {this.props.overview.delivered.rail_count}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div
                    className={`supply-chain__chart__delivered--caption ${deliveredActiveClass}`}
                  >
                    Delivered {!this.state.isSmallScreen && ' in last 3 months'}
                    <div className="block-row">
                      <i className="icon chevron" />
                    </div>
                  </div>
                </div>
              </Tooltip>
            </div>
          </>
        )}
      </div>
    )
  }

  @bind
  private renderTooltipData(): React.ReactElement {
    return (
      <DashboardTooltip
        data={this.props.overview.delivered}
        currentGroup="delivered"
        onRevealShipments={this.showDelivered}
        active={this.state.activeStatusGroup === 'delivered'}
      />
    )
  }

  @bind
  private handleTooltipOpen(): void {
    const { overview } = this.props
    if (!overview.delivered.count || this.state.isSmallScreen) {
      return
    }
    this.setState({ open: true })
  }

  @bind
  private handleTooltipClose(): void {
    this.setState({ open: false })
  }

  @bind
  private showDelivered(): void {
    if (this.props.overview.delivered.count) {
      this.deactivateOthers('delivered')
    }
  }

  @bind
  private throttledResizeHandler(): void {
    if (!this.resizeThrottleTimeout) {
      this.resizeThrottleTimeout = setTimeout(() => {
        this.resizeThrottleTimeout = null
        const isSmallScreen = window.outerWidth < 768
        if (isSmallScreen !== this.state.isSmallScreen) {
          this.setState({ isSmallScreen })
        }
      }, 300)
    }
  }

  @bind
  private deactivateOthers(activeStatusGroup: StatusGroupType): void {
    if (activeStatusGroup !== this.state.activeStatusGroup) {
      this.setState({ activeStatusGroup })
      this.props.onToggle(activeStatusGroup)
    }
  }
}

export default DashboardSupplyChainChart

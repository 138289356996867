import {
  MuiInputBase,
  NumberFormatCustom,
} from 'src/components/Common/Input/MuiInput'
import Autocomplete from 'src/stories/SearchBar'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import { SelectChangeEvent } from '@mui/material'

function DefaultField(props: { value?: string }) {
  return <div data-testid={props['data-testid']}>{props.value}</div>
}

function CustomInputBaseNumber(props) {
  return <MuiInputBase {...props} inputComponent={NumberFormatCustom as any} />
}

function CustomSelect(props) {
  return (
    <SingleSelect
      {...props}
      inputProps={{ 'data-testid': 'currency-select-input' }}
      SelectDisplayProps={{ 'data-testid': 'currency-select-trigger' }}
      onChange={(event: SelectChangeEvent<unknown>) =>
        props.onChange(event.target.value)
      }
    />
  )
}

export {
  MuiInputBase,
  CustomInputBaseNumber,
  DefaultField,
  CustomSelect,
  Autocomplete,
}

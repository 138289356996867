import Stack from '@mui/material/Stack'
import { entityGroupMap } from '../constants'
import OverviewTableText from './OverviewTableText'

const OverviewTableAggregatedCell = ({ title, text, grouping }) => {
  if (!grouping.includes(entityGroupMap.vessel)) {
    return null
  }
  return (
    <Stack spacing={0.5}>
      <OverviewTableText text={`${title}:`} />
      <OverviewTableText variant="body1Strong" text={text} />
    </Stack>
  )
}

export default OverviewTableAggregatedCell

import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import * as actions from '../actions/packageTypes'

declare global {
  interface IPackageTypeState {
    list: IPackageType[]
    container_types: IContainerType[]
  }

  interface IContainerType {
    teu: number
    id: number
    name: string
    code: string
    iso_code: string
    equipment_controlled_atmosphere: boolean
  }

  interface IPackageType {
    code: string
    default_height_mm: number | null
    default_length_mm: number | null
    default_type: boolean
    default_width_mm: number | null
    description: string | null
    id: number
    name: string
  }
}

export const initialPackageTypesState = {
  list: [],
  container_types: [],
}

const receiveList: Reducer<IPackageTypeState, AnyAction> = (state, action) => ({
  ...state,
  list: action.payload,
})

const receiveContainerTypes: Reducer<IPackageTypeState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  container_types: action.payload,
})

export default createReducer(initialPackageTypesState, {
  [actions.PACKAGE_TYPES_GET_DATA_SUCCESS]: receiveList,
  [actions.CONTAINER_TYPES_GET_DATA_SUCCESS]: receiveContainerTypes,
})

import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/messageCenter'
import * as actionCreators from '../../actionCreators/messageCenter'
import { simpleGetAndResolve } from '../factories'

const getApprovals = simpleGetAndResolve(
  () => 'api/v1/messaging/approvals',
  actionCreators.approvalsGetDataSuccess,
  (response) => response.data.data
)

const getMentions = simpleGetAndResolve(
  () => 'api/v1/messaging/mentions',
  actionCreators.mentionsGetDataSuccess,
  (response) => response.data.data
)

const getSavedMessages = simpleGetAndResolve(
  () => 'api/v1/messaging/saved',
  actionCreators.getSavedMessagesSuccess,
  (response) => response.data.data
)

const getUnread = simpleGetAndResolve(
  () => 'api/v1/messaging/messages',
  actionCreators.unreadGetDataSuccess,
  (response) => response.data.data
)

const getCounters = simpleGetAndResolve(
  () => 'api/v1/messaging/counters',
  actionCreators.countersGetDataSuccess,
  (response) => response.data.data
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.APPROVALS_GET_DATA, getApprovals)
  yield takeEvery(actions.MENTIONS_GET_DATA, getMentions)
  yield takeEvery(actions.GET_SAVED_MESSAGES, getSavedMessages)
  yield takeEvery(actions.UNREAD_GET_DATA, getUnread)
  yield takeEvery(actions.COUNTERS_GET_DATA, getCounters)
}

import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import * as actions from '../actions'

declare global {
  type VoidRenderFunc = () => React.ReactNode
  type VoidFunc = () => void
  type VoidPromiseFunc = () => Promise<any>

  type ChatType = 'private_chat' | 'public_chat' | 'agents_chat'
  interface IShipmentLayoutCounters {
    documents: number
    instructions: number
    privateChat: number
    publicChat: number
    agentsChat: number
    chatCounter: number
  }

  interface IChatIdentifier {
    id: number
    chat_type: ChatType
  }

  interface IRemark {
    id: number
    name: string
    additional_remark_required: boolean
  }

  interface IOpenMileStones {
    bookings: boolean
    containers: boolean
  }

  interface IShipmentLayoutState {
    remarks: IRemark[]
  }
}

export const initialShipmentLayoutState: IShipmentLayoutState = {
  remarks: [],
}

const receiveRemarks: Reducer<IShipmentLayoutState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  remarks: action.payload,
})

export default createReducer(initialShipmentLayoutState, {
  [actions.SHIPMENT_LAYOUT_GET_REMARKS_SUCCESS]: receiveRemarks,
})

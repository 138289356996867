import { call, put } from 'redux-saga/effects'
import i18next from 'i18next'
import { Method } from 'axios'
import { showNotification } from 'src/stores/actionCreators/notifications'
import * as actionCreators from '../../actionCreators'
import { Logger, requester } from '../../../utils'

export default function* (action: IPromisifiedAction): Iterator<any> {
  try {
    yield call([requester, 'request'], {
      method: 'POST' as Method,
      url: '/api/auth/password',
      data: action.payload,
    })
    yield put(actionCreators.userForgotPasswordSuccess())
    yield put(
      showNotification({
        message: i18next.t('forgot_password.success_message'),
        severity: 'success',
      })
    )
  } catch (error) {
    const unknownError: any = error
    yield Logger.sagaError(unknownError, 'store::saga::forgot-password')
    yield put(actionCreators.userForgotPasswordSuccess())
  }
}

import { Tooltip } from '@mui/material'
import { useState } from 'react'

import DashboardTooltip from 'src/components/DashboardSupplyChain/DashboardTooltip'

interface IDashboardSupplyChainBlockProps {
  isSmallScreen: boolean
  icon: string
  statusGroup: StatusGroupType
  activeGroup?: StatusGroupType
  caption: string
  counts: ISupplyChainOverviewItem
  maxCount: number
  onActivate: (statusGroup: StatusGroupType) => void
  isActive: boolean
}

interface IDashboardSupplyChainBlockState {
  open: boolean
}

const classesTooltip = {
  tooltip: 'supply-chain__tooltip',
  popper: 'supply-chain__popper',
}

const DashboardSupplyChainBlock = (props: IDashboardSupplyChainBlockProps) => {
  const [state, setState] = useState<IDashboardSupplyChainBlockState>({
    open: false,
  })

  const { counts, maxCount, activeGroup, statusGroup } = props
  const activeClass = props.isActive ? 'active' : ''
  const notActiveClass =
    activeGroup && activeGroup !== statusGroup ? 'not-active' : ''
  const elHeight = {
    height: `${Math.floor((counts.count / (maxCount || 1)) * 90)}%`,
  }
  const hideClass = !maxCount ? 'hide-number' : ''

  const revealShipments = () => {
    props.onActivate(props.statusGroup)
  }

  const handleTooltipClose = () => {
    setState({ open: false })
  }

  const renderTooltipData = () => {
    return (
      <DashboardTooltip
        data={props.counts}
        currentGroup={props.statusGroup}
        onRevealShipments={revealShipments}
        active={props.statusGroup === props.activeGroup}
      />
    )
  }

  const renderIcons = () => (
    <div className="supply-chain__chart__graph--block--bar--icons">
      {!!props.counts.fcl_count && (
        <div className="delivered-content--stat-line">
          <i className="icon container20ft" />
          {' x '}
          <span className="types-count">{props.counts.fcl_count}</span>
        </div>
      )}
      {!!props.counts.lcl_count && (
        <div className="delivered-content--stat-line">
          <i className="icon lcl" />
          {' x '}
          <span className="types-count">{props.counts.lcl_count}</span>
        </div>
      )}
      {!!props.counts.air_count && (
        <div className="delivered-content--stat-line">
          <i className="icon airport" />
          {' x '}
          <span className="types-count">{props.counts.air_count}</span>
        </div>
      )}
      {!!props.counts.rail_count && (
        <div className="delivered-content--stat-line">
          <i className="icon train" />
          {' x '}
          <span className="types-count">{props.counts.rail_count}</span>
        </div>
      )}
    </div>
  )

  const handleTooltipOpen = () => {
    const { counts, isSmallScreen } = props
    if (!counts.count || isSmallScreen) {
      return
    }
    setState({ open: true })
  }

  return (
    <div
      className={`supply-chain__chart__graph--main ${props.statusGroup}`}
      onMouseLeave={handleTooltipClose}
      id="chart-bar"
    >
      <Tooltip
        title={renderTooltipData()}
        classes={classesTooltip}
        placement={
          statusGroup === 'destination_port' ? 'left-end' : 'right-end'
        }
        open={state.open}
        disableHoverListener={true}
      >
        <div
          className={`supply-chain__chart__graph--block ${activeClass} ${notActiveClass}`}
          style={elHeight}
          onMouseEnter={handleTooltipOpen}
          data-testid="chart-bar"
        >
          <div
            className={`supply-chain__chart__graph--block--number ${hideClass}`}
          >
            {counts.count}
          </div>
          <div
            className={`supply-chain__chart__graph--block--bar ${activeClass}`}
            onClick={revealShipments}
          >
            {!props.isSmallScreen && renderIcons()}
          </div>
          <div
            className={`supply-chain__chart__graph--block--caption ${activeClass}`}
          >
            {!props.isSmallScreen && props.caption}
            <div className="block-icon">
              <i className={`icon ${props.icon}`} />
            </div>
            <div className="block-row">
              <i className="icon chevron" />
            </div>
          </div>
        </div>
      </Tooltip>
    </div>
  )
}

export default DashboardSupplyChainBlock

import { FunctionComponent } from 'react'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import GenericDateField from '../GenericDateField'

interface IProps {
  port: {
    name: string
  }
  date: string | null
  children?: React.ReactNode
}

const PortField: FunctionComponent<IProps> = ({ port, date, children }) => (
  <>
    <Box>
      <Tooltip placement="top" title={port.name.length < 20 ? '' : port.name}>
        <Typography variant="body1Strong" children={port.name} />
      </Tooltip>
    </Box>
    <Box className="flex">
      {date ? <GenericDateField date={date} /> : ''}
      {!!children ? children : null}
    </Box>
  </>
)

export default PortField

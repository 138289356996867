import { presentGoodsCode } from '../../../utils/helpers'
import './styles.scss'

interface IProps {
  children?: React.ReactNode
  option: IHsCode
  isFocused: boolean
  isSelected: boolean
  onSelect: (option, event) => void
  onFocus: (option, event) => void
}

function HsCodeOptionComponent(props: IProps) {
  const onMouseDown = (event) => {
    event.preventDefault()
    event.stopPropagation()
    props.onSelect(props.option, event)
  }

  const onMouseEnter = (event) => {
    props.onFocus(props.option, event)
  }

  const onMouseMove = (event) => {
    if (props.isFocused) return
    props.onFocus(props.option, event)
  }

  return (
    <div
      className="hs-code-line-option"
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseMove={onMouseMove}
    >
      <div className="hs-code-line-option__goods-code">
        {presentGoodsCode(props.option.goods_code)}
      </div>
      <div className="hs-code-line-option__description">
        {props.option.description}
        {props.children}
      </div>
    </div>
  )
}

export default HsCodeOptionComponent

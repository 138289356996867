import { FunctionComponent, useMemo, useCallback } from 'react'

import { compact } from 'lodash'
import { useDispatch } from 'react-redux'

import { Avatar } from 'src/stories/Avatar'
import { deleteShipmentComment } from 'src/stores/actionCreators'
import { convertDateToDateTime } from 'src/utils/helpers'
import { promisifyAction } from 'src/utils'

import './styles.scss'

interface IProps {
  comment: IComment
  onDelete: (commentId: number) => void
}

const ShipmentComment: FunctionComponent<IProps> = ({ comment, onDelete }) => {
  const dispatch = useDispatch()
  const deleteCommentAsync = promisifyAction(dispatch, deleteShipmentComment)

  const onClickDelete = useCallback(async () => {
    await deleteCommentAsync(comment.shipment_id, comment.id)
    onDelete(comment.id)
  }, [comment])

  const authorNameWithOrganization: React.ReactNode = useMemo(() => {
    let authorName: string = ''

    if (comment.author.organization.id === 1) {
      authorName = compact([
        comment.author.first_name,
        comment.author.department,
        comment.author.phone,
      ]).join(' | ')
    } else {
      authorName = comment.author.full_name
    }
    authorName += ` | ${comment.author.organization.name}`

    return authorName
  }, [comment])

  return (
    <div className="shipment-comment">
      <div className="shipment-comment__avatar-wrapper">
        <Avatar
          userCard
          userInfo={{
            name: comment.author.full_name,
            department: comment.author.department || undefined,
            phone: comment.author.phone,
            company: comment.author.organization.name,
            out_of_office_till: comment.author.out_of_office_till,
          }}
          alt={comment.author.full_name}
          src={comment.author.avatar_thumb || 'no-logo'}
          className="shypple_admin"
        />
      </div>
      <div className="shipment-comment__text-wrapper">
        <div className="shipment-comment__text-wrapper--body">
          {comment.body}
        </div>
        <div className="shipment-comment__text-wrapper--meta">
          {convertDateToDateTime(comment.created_at)} -{' '}
          {authorNameWithOrganization}
        </div>
      </div>
      <div className="shipment-comment__icon-wrapper">
        <i className="icontrash" onClick={onClickDelete} />
      </div>
    </div>
  )
}

export default ShipmentComment

import userflow from 'userflow.js'

import { useEffect } from 'react'
import { useSelector } from 'react-redux'

export default function useUserFlow(): void {
  const { currentUser } = useSelector((globalState: IGlobalState) => ({
    currentUser: globalState.user,
  }))

  const userflowToken = window.userflowToken

  useEffect(() => {
    if (!userflow || !window.location.href.includes('app.shypple.com')) return
    if (currentUser.uid) {
      userflow.init(userflowToken)
      userflow.identify(`${currentUser.id}`, {
        name: `${currentUser.firstName} ${currentUser.lastName}`,
        email: currentUser.email,
        phone: currentUser.phone,
        signed_up_at: currentUser.createdAt,
      })

      userflow.group(`${currentUser.organizationId}`, {
        name: currentUser.organizationName,
        customer_state: currentUser.customerState,
        company_role: currentUser.organizationRole,
        visibility_trial: currentUser.organization_visibility_trial,
        preferred_forwarder_id: currentUser.preferredFreightForwarderId,
      })
    }
  }, [currentUser.uid])
}

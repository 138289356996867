import { AnyAction } from 'redux'
import * as actions from '../actions/publicPO'

export const publicPurchaseOrdersGetData = (
  token: string,
  search: string
): AnyAction => ({
  type: actions.PUBLIC_PO_GET_DATA,
  token,
  search,
})

export const publicPurchaseOrdersGetDataSuccess = (
  payload: any
): AnyAction => ({
  type: actions.PUBLIC_PO_GET_DATA_SUCCESS,
  payload,
})

export const publicPurchaseOrdersUpdate = (
  token: string,
  search: string,
  payload
): AnyAction => ({
  type: actions.PUBLIC_PO_UPDATE,
  token,
  search,
  payload,
})

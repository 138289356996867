import FormHelperText from '@mui/material/FormHelperText'
import TextField from '@mui/material/TextField'
import Popper from '@mui/material/Popper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { find, toLower } from 'lodash'
import { useEffect, useRef, useState } from 'react'

type Option = [string, string | number]

interface IFormFieldSelectProps {
  label?: string
  fallbackValue?: string | number | null
  className?: string
  popperClassName?: string
  value?: string | number | null
  fieldName: string
  errorNotes?: string | null
  onChange: (fieldName: string, value?: string | number | null) => void
  isRequired?: boolean
  disabled: boolean
  options?: Option[]
  hasLimitedHeight?: boolean
  isSearchable?: boolean
  'data-testid'?: string
}

interface IFormFieldSelectState {
  isOpen: boolean
  anchorEl: HTMLElement | null
  search: string
  lowerCaseSearch: string
}

function FormFieldSelect(props: IFormFieldSelectProps) {
  const inputRef = useRef<HTMLElement>(null)

  const [state, setState] = useState<IFormFieldSelectState>({
    anchorEl: null,
    isOpen: false,
    search: '',
    lowerCaseSearch: '',
  })

  useEffect(() => {
    //@ts-ignore
    open(inputRef.current)
  }, [])

  function renderOptions(options): React.ReactNode {
    return options.map(
      ([title, id]: string[]): React.ReactNode => (
        <div
          className={`selector-button__option ${
            props.value === id ? 'selector-button__option_selected' : ''
          }`}
          key={`${id}-option`}
          onClick={() => pickOption(id)}
        >
          <div
            className="selector-button__option-name"
            data-testid={`address-form-select-country-${title}`}
          >
            {title}
          </div>
        </div>
      )
    )
  }

  function pickOption(optionId: string | number): void {
    const value = optionId || props.fallbackValue
    props.onChange(props.fieldName, value)
    close()
  }

  function resolveValue(): string | number {
    return props.value == null ? props.fallbackValue || '' : props.value
  }

  function getOptionTitle(): string {
    const { options } = props
    const value = resolveValue()

    if (!options) {
      return ''
    }

    const rightOption: Option | undefined = find(
      options,
      ([title, id]: Option) => id === value
    )
    if (rightOption) {
      return rightOption[0]
    }
    return ''
  }

  function changeSearch(event: React.FormEvent<any>): void {
    setState({
      ...state,
      search: event.currentTarget.value,
      lowerCaseSearch: toLower(event.currentTarget.value),
    })
  }

  function matchSearch(option: Option): boolean {
    const { search } = state
    return !search || toLower(option[0]).includes(state.lowerCaseSearch)
  }

  function open(target: HTMLElement | null): void {
    setState({
      ...state,
      anchorEl: target,
    })
  }

  function openSelect(): void {
    if (!props.disabled) {
      setState({ ...state, isOpen: true })
    }
  }

  function close(): void {
    setState({ ...state, isOpen: false })
  }

  let options = props.options || []

  if (state.search) {
    options = options.filter(matchSearch)
  }

  return (
    <>
      <div key={`${props.fieldName}-SelectField`} className="form-field">
        <TextField
          variant="standard"
          disabled={props.disabled}
          value={getOptionTitle()}
          margin="normal"
          label={props.label}
          inputRef={inputRef}
          onClick={openSelect}
          className={props.className || ''}
          error={!!props.errorNotes}
          InputLabelProps={{
            classes: {
              focused: 'focused',
              root: 'label shipping-instructions__shypple-font',
              error: 'error',
              disabled: 'disabled',
            },
          }}
          InputProps={{
            classes: {
              focused: 'focused',
              root: 'input shipping-instructions__shypple-font',
              error: 'error',
              disabled: 'disabled',
            },
            readOnly: true,
          }}
          data-testid={props['data-testid']}
        />
        {props.errorNotes && (
          <FormHelperText className="form-field__red">
            {props.errorNotes}
          </FormHelperText>
        )}
      </div>
      <Popper
        key={`id.Popper`}
        id={'id'}
        open={state.isOpen}
        anchorEl={state.anchorEl}
        placement={'bottom-start'}
        className={`menu-button__popper mui-override ${props.popperClassName}`}
      >
        <ClickAwayListener onClickAway={close}>
          <section className={`menu-button__menu`} style={undefined}>
            <div
              className={`selector-button__options ${
                props.hasLimitedHeight ? 'selector-button__options_limited' : ''
              }`}
            >
              {props.isSearchable && (
                <div className="selector-button__search-container">
                  <input
                    className="selector-button__search"
                    placeholder="Type to search"
                    value={state.search}
                    onChange={changeSearch}
                    autoFocus={true}
                    data-testid="address-form-select-search"
                  />
                </div>
              )}
              {renderOptions(options)}
            </div>
          </section>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export default FormFieldSelect

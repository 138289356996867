import { SelectChangeEvent } from '@mui/material'
import FormLabel from 'src/stories/Lab/FormLabel'
import { MultiSelect } from 'src/stories/Lab/Select/MultiSelect'
import { SelectOptionProps } from 'src/stories/Lab/Select/Select.props'
import { useFilter } from '../hooks'
import { FilterContext, FilterInputsCommonPropsType } from '../types'
import { extractAnOptionFromFilterOptions } from '../utils'

type FormElementProps = Omit<FilterInputsCommonPropsType, 'placeholder'> & {
  options?: SelectOptionProps[]
  placeholder?: FilterInputsCommonPropsType['placeholder']
  optionsExtractor?: (
    filterOptions: FilterContext['filterOptions'],
    name: string
  ) => SelectOptionProps[]
}

export const FilterMultipleSelect = ({
  name,
  label,
  placeholder,
  optionsExtractor = extractAnOptionFromFilterOptions,
  filterOptionKey = '',
  ...props
}: FormElementProps) => {
  const { filterOptions, onInputChange, filters } = useFilter()

  const options: SelectOptionProps[] =
    props.options || optionsExtractor?.(filterOptions, filterOptionKey) || []

  const selectedOptions = options.filter(({ id }) =>
    filters[name]?.includes(id)
  )

  const onReset = () => onInputChange(name, [])

  const onChange = ({ target }: SelectChangeEvent<unknown>) =>
    onInputChange(name, target.value as SelectOptionProps)

  return (
    <>
      <FormLabel label={label} count={selectedOptions.length} />
      <MultiSelect
        options={options}
        value={filters[name] || []}
        onReset={onReset}
        onChange={onChange}
        placeholder={placeholder}
        data-testid={props['data-testid']}
      />
    </>
  )
}

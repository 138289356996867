interface IStatusGroup {
  icon: string
  statusGroup: StatusGroupType
  caption: string
}

interface IStatusGroups {
  [key: string]: IStatusGroup
}

export const statusGroups: IStatusGroups = {
  pre_booked: {
    icon: 'calendar',
    statusGroup: 'pre_booked',
    caption: 'Booking Placed',
  },
  booking_confirmed: {
    icon: 'big-check',
    statusGroup: 'booking_confirmed',
    caption: 'Booking confirmed',
  },
  origin_port: {
    icon: 'crane',
    statusGroup: 'origin_port',
    caption: 'Origin port',
  },
  in_transit: {
    icon: 'transport-1',
    statusGroup: 'in_transit',
    caption: 'In-transit',
  },
  destination_port: {
    icon: 'crane',
    statusGroup: 'destination_port',
    caption: 'Destination port',
  },
}

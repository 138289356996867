import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/shipmentPurchaseOrders'
import * as actionCreators from '../../actionCreators/shipmentPurchaseOrders'
import { simpleGetAndResolve } from '../factories'

const getPurchaseOrders = simpleGetAndResolve(
  (action) => `/api/v1/shipments/${action.id}/purchase_orders`,
  actionCreators.shipmentPurchaseOrdersGetDataSuccess,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.SHIPMENT_PURCHASE_ORDERS_GET_DATA, getPurchaseOrders)
}

import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import * as actions from '../actions/connections'

declare global {
  interface IConnectionsState {
    list: IShipmentConnection[]
    total: number
    singleConnection: IShipmentConnection
    isFetchingConnections: boolean
  }

  interface IShipmentConnection {
    last_seen: string | null
    logo: string | null
    name: string
    organization_id: number
    role: string
    role_code: string
    url: string
    country: ICountry | null
    users: IUser[]
    manageable: boolean
    addresses: IAddress[]
    is_preferred_forwarder: boolean
  }

  interface ICollaborator {
    id: number
    logo: string | null
    name: string
    organization_id: number
    role: string
    role_code: OrganizationRole | undefined
    organization_role: string
    organization_role_code?: OrganizationRole | undefined
    organization_role_name?: string
    manageable: boolean
    roles: IRoleShort[]
  }
}

export const initialConnectionsState: IConnectionsState = {
  list: [],
  singleConnection: {
    last_seen: null,
    logo: null,
    name: '',
    organization_id: 0,
    role: '',
    role_code: '',
    url: '',
    country: null,
    users: [],
    manageable: false,
    addresses: [],
    is_preferred_forwarder: false,
  },
  isFetchingConnections: false,
  total: 0,
}

const fetchingConnections: Reducer<IConnectionsState, AnyAction> = (state) => ({
  ...state,
  isFetchingConnections: true,
})

const retrieveAllConnections: Reducer<IConnectionsState, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  list: payload.list,
  total: payload.total,
  isFetchingConnections: false,
})

const receiveOne: Reducer<IConnectionsState, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  singleConnection: payload,
})

export default createReducer(initialConnectionsState, {
  [actions.GET_ALL_CONNECTIONS_SUCCESS]: retrieveAllConnections,
  [actions.GET_ALL_CONNECTIONS]: fetchingConnections,
  [actions.GET_SINGLE_CONNECTION_SUCCESS]: receiveOne,
})

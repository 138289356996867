import {
  Autocomplete,
  InputLabel,
  AutocompleteProps,
  TextField,
  Checkbox,
  Typography,
  ListItem,
  Skeleton,
} from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import { debounce } from 'lodash'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { makeStyles } from '@mui/styles'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const icon = <CheckBoxOutlineBlankIcon />
const checkedIcon = <CheckBoxIcon />

export interface AutoCompleteSelectProps<T>
  extends Omit<
      AutocompleteProps<any, any, any, any>,
      'renderInput' | 'renderOption' | 'options' | 'onChange'
    >,
    Partial<
      Pick<
        AutocompleteProps<any, any, any, any>,
        'renderInput' | 'renderOption' | 'options'
      >
    > {
  children?: (
    text: true | React.ReactChild | React.ReactFragment | React.ReactPortal,
    option: T
  ) => React.ReactNode
  noOptionsText?: string
  checkbox?: boolean
  required?: boolean
  name?: string
  onChange?: (option: string | T | NonNullable<T>) => void
  disableClearable?: boolean
  /**
   * Field placeholder
   */
  placeholder?: string
  value: T | null
  options?: T[]
  /**
   * Callback to get options asynchronously
   */
  getData?: (searchQuery: string) => Promise<T[]>
  /**
   * A field that will be used to display an option in the dropdown
   */
  optionLabel?: (option: any) => string
  /**
   * A function to determine how to divide options into groups
   */
  groupBy?: (option: T) => string
  /**
   * Error state of component
   */
  error?: boolean
  label?: string | React.ReactNode
  optionsKey?: string
  disableChip?: boolean
  disabledOptions?: string[]
}

const endAdornment = <SearchRoundedIcon color="inherit" />

export const AutoCompleteSelect = <T extends Record<string, any>>(
  props: AutoCompleteSelectProps<T>
) => {
  const {
    placeholder = 'Start typing to search',
    optionLabel = (option) => option.name,
    optionsKey,
    getData,
    onChange: _onChange,
    disableChip,
    renderTags,
    checkbox,
    disabledOptions,
    options: _options,
    noOptionsText,
    ...rest
  } = props

  const [isLoading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<T[]>(_options || [])
  const classes = useStyles()

  const getOptions = useCallback(
    async (query: string) => {
      if (!getData) return Promise.resolve()
      setLoading(true)
      try {
        const _dataOptions = await getData(query)
        setOptions((prev) => {
          const response = optionsKey ? _dataOptions[optionsKey] : _dataOptions

          return response
        })
      } finally {
        setLoading(false)
      }
    },
    [optionsKey, getData]
  )

  const onInputChange = useCallback(
    debounce((_event, newValue: string, reason) => {
      if (newValue.length > 0) setInputValue(newValue)
      props.onInputChange?.(_event, newValue, reason)
    }, 500),
    []
  )

  const onChange = useCallback(
    (_event: React.SyntheticEvent<Element, Event>, option) => {
      _onChange?.(option)
    },
    []
  )

  useEffect(() => {
    let done = false
    if (inputValue === (props.value && optionLabel(props.value))) {
      return
    }

    if (_options && _options.length) {
      setOptions(_options)
    }

    if (!done) {
      getOptions(inputValue)
    }

    return () => {
      done = true
    }
  }, [inputValue, props.value, _options])

  const tags = (values) => {
    return (
      <>
        <Typography
          sx={{
            maxWidth: '50%',
            maxHeight: '40px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {values.map((v) => optionLabel(v)).join(', ')}
        </Typography>
        <Typography>
          {values.length > 1 ? `+${values.length - 1}` : ''}
        </Typography>
      </>
    )
  }

  const checkOptionValue = (option, value): boolean => {
    return !!(
      option &&
      value &&
      option[optionsKey || 'id'] === value[optionsKey || 'id']
    )
  }

  return (
    <>
      {props.label && (
        <InputLabel required={props.required} disabled={props.disabled}>
          {props.label}
        </InputLabel>
      )}
      <Autocomplete
        {...rest}
        classes={{ root: classes.root }}
        loading={isLoading}
        onChange={onChange}
        options={options}
        value={props.value}
        getOptionLabel={optionLabel}
        renderTags={disableChip ? tags : renderTags}
        isOptionEqualToValue={checkOptionValue}
        ChipProps={{ size: 'small' }}
        renderInput={
          props.renderInput
            ? props.renderInput
            : (params) => (
                <TextField
                  placeholder={placeholder}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    'data-testid': 'search-bar',
                  }}
                />
              )
        }
        onInputChange={onInputChange}
        popupIcon={props.popupIcon || endAdornment}
        noOptionsText={noOptionsText}
        loadingText={<Skeleton />}
        renderOption={
          props.renderOption
            ? props.renderOption
            : (p, option, { selected }) => {
                const matches = match(optionLabel(option), inputValue)
                const parts = parse(optionLabel(option), matches)

                const OptionText = (
                  <>
                    {parts.map((part, index: number) => (
                      <Typography
                        key={index}
                        sx={{
                          fontWeight: part.highlight ? 600 : 400,
                          display: 'inline',
                          whiteSpace: 'pre',
                          textOverflow: 'ellipsis',
                          maxWidth: '95%',
                          overflow: 'hidden',
                        }}
                      >
                        {part.text}
                      </Typography>
                    ))}
                  </>
                )

                const _checkbox = (
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    sx={{ marginRight: 1 }}
                    checked={selected}
                  />
                )

                const disabled = disabledOptions?.includes(
                  option[optionsKey || 'id']
                )
                return props.children ? (
                  <ListItem
                    disabled={disabled}
                    {...p}
                    key={`${option[optionsKey || 'id']}-${props.name}`}
                  >
                    {checkbox && _checkbox}
                    {props.children(OptionText, option)}
                  </ListItem>
                ) : (
                  <ListItem
                    disabled={disabled}
                    {...p}
                    key={`${option[optionsKey || 'id']}-${props.name}`}
                    data-testid={`autocomplete-select-option-${
                      option[optionsKey || 'id']
                    }`}
                  >
                    {checkbox && _checkbox}
                    {OptionText}
                  </ListItem>
                )
              }
        }
      />
    </>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiAutocomplete-popupIndicator': {
      transform: 'none',
    },
  },
}))

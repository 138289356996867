import { AnyAction } from 'redux'
import * as actions from '../actions/searchBooking'

export const searchQuotesBooking = (payload: any): AnyAction => ({
  type: actions.SEARCH_QUOTES_BOOKING,
  payload,
})

export const searchQuotesBookingSuccess = (payload: any): AnyAction => ({
  type: actions.SEARCH_QUOTES_BOOKING_SUCCESS,
  payload,
})

export const searchQuotesBookingReset = (): AnyAction => ({
  type: actions.SEARCH_QUOTES_BOOKING_RESET,
})

export const addressSuggestions = (payload: { input: string }): AnyAction => ({
  type: actions.SEARCH_ADDRESS,
  payload,
})
export const addressSuggestionsSuccess = (payload: {
  places: IPlaceSuggestion[]
}): AnyAction => ({
  type: actions.SEARCH_ADDRESS_SUCCESS,
  payload,
})

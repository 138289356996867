export const getIndicators = (shipment: IReportsShipment) => {
  const {
    count,
    stackable,
    dangerous_goods: dangerousGoods,
  } = shipment.cargos.summary
  const isCargoExist = count > 0

  return {
    isSpot: stackable && !dangerousGoods && !shipment.fixed_rates_enabled,
    isNonStackable: isCargoExist && !stackable && !dangerousGoods,
    isDangerous: isCargoExist && dangerousGoods,
  }
}

import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/publicPO'
import * as actionCreators from '../../actionCreators/publicPO'
import { simpleGetAndResolve, simplePutAndResolve } from '../factories'

const getPublicPO = simpleGetAndResolve(
  (action) => `api/v1/public_purchase_orders/${action.token}${action.search}`,
  actionCreators.publicPurchaseOrdersGetDataSuccess,
  (response) => response.data
)
const updatePublicPO = simplePutAndResolve(
  (action) => `api/v1/public_purchase_orders/${action.token}${action.search}`,
  actionCreators.publicPurchaseOrdersGetDataSuccess,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.PUBLIC_PO_GET_DATA, getPublicPO)
  yield takeEvery(actions.PUBLIC_PO_UPDATE, updatePublicPO)
}

import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'

const Loader = () => (
  <Box sx={{ width: '100%' }}>
    <Box sx={{ display: 'flex' }}>
      <Skeleton
        animation="wave"
        sx={{
          flex: 1,
          margin: '8px',
          height: '20vh',
          transform: 'none',
        }}
      />
      <Skeleton
        animation="wave"
        sx={{
          flex: 1,
          margin: '8px',
          height: '20vh',
          transform: 'none',
        }}
      />
    </Box>
    <Box sx={{ display: 'flex' }}>
      <Skeleton
        animation="wave"
        sx={{
          flex: 1,
          margin: '8px',
          height: '50vh',
          transform: 'none',
        }}
      />
      <Skeleton
        animation="wave"
        sx={{
          flex: 1,
          margin: '8px',
          height: '50vh',
          transform: 'none',
        }}
      />
    </Box>
  </Box>
)

export default Loader

import { ChipProps } from '@mui/material'
import Typography from '@mui/material/Typography'
import { StyledFilledBox } from '../styled'

const OverviewTableShipmentColoredCell = ({
  color,
  text,
}: {
  color: ChipProps['color']
  text: string
}) => {
  return (
    <StyledFilledBox
      className={`filled-cell ${color}`}
      sx={{
        color: `${color}.900`,
        backgroundColor: `${color}.50`,
      }}
      data-testid="filled-cell"
    >
      <Typography variant="body1" noWrap children={text} />
    </StyledFilledBox>
  )
}

export default OverviewTableShipmentColoredCell

import { ReactNode, FunctionComponent } from 'react'

interface IProps {
  index?: number | null
  children: ReactNode
  className?: string
}

const StripedTableRow: FunctionComponent<IProps> = (props) => {
  return (
    <div className={`striped-table--row ${props.className || ''}`}>
      {props.children}
    </div>
  )
}

export default StripedTableRow

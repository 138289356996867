import { ReactNode, FunctionComponent } from 'react'
import DialogActions from '@mui/material/DialogActions'

interface IProps {
  children: ReactNode
}

const Actions: FunctionComponent<IProps> = (props) => (
  <DialogActions sx={{ paddingTop: '24px !important' }}>
    {props.children}
  </DialogActions>
)

export default Actions

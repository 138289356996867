import { FunctionComponent } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { kebabCase } from 'lodash'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import { pickupsAndDeliveriesSelectedIds } from 'src/stores/actionCreators'
import { permissionTo } from 'src/utils'
import { canBulkEdit, canUpdateTransport } from '../helpers'
import { inspectionStatusWidth } from '../constants'

const PickupsDeliveryTableHeader: FunctionComponent = () => {
  const dispatch = useDispatch()

  const { tableColumns, scope, selectedRowsIds, isLoading, list } = useSelector(
    (state: IGlobalState) => ({
      tableColumns: state.pickUpsAndDeliveries.tableColumns,
      scope: state.pickUpsAndDeliveries.filters.scope,
      selectedRowsIds:
        state.pickUpsAndDeliveries.selectedInlandTransportRowsIds,
      isLoading: state.pickUpsAndDeliveries.isLoading,
      list: state.pickUpsAndDeliveries.list,
    }),
    shallowEqual
  )

  const taskTableCell = (task: ITaskTypeItem, column: ITableConfiguration) => (
    <TableCell
      data-testid={`${kebabCase(column.name)}-header-${kebabCase(task.code)}`}
      key={`${column.name}-header-${task.code}`}
    >
      {task?.column_display_name}
    </TableCell>
  )

  const renderTableHeader = (column: ITableConfiguration) => {
    switch (column.name) {
      case 'Tasks':
        if (!permissionTo('pickups_deliveries.shypple_admin')) {
          return null
        }
        return column.task_type_group?.task_types.map((task: ITaskTypeItem) =>
          taskTableCell(task, column)
        )
      case 'Transporter':
        if (!canUpdateTransport()) {
          return null
        }
        return (
          <TableCell
            data-testid={`${column.name}-header`}
            key={`${column.name}-header`}
          >
            {column.name}
          </TableCell>
        )
      case '(Pick-up and delivery) address':
        return (
          <TableCell
            data-testid={`${column.name}-header`}
            key={`${column.name}-header`}
          >
            {scope} address
          </TableCell>
        )
      case '(Pick-up and delivery) date':
        return (
          <TableCell
            data-testid={`${column.name}-header`}
            key={`${column.name}-header`}
          >
            {scope} date
          </TableCell>
        )
      case '(Pick-up and delivery) status':
        return (
          <TableCell
            data-testid={`${column.name}-header`}
            key={`${column.name}-header`}
          >
            {scope} status
          </TableCell>
        )
      case 'Inspection':
        return (
          <TableCell
            data-testid={`${column.name}-header`}
            key={`${column.name}-header`}
          >
            <Stack direction="row">
              <Box width={inspectionStatusWidth}>Inspection status</Box>
              <Box>Inspection date</Box>
            </Stack>
          </TableCell>
        )
      case 'Air Tasks':
        if (!permissionTo('pickups_deliveries.shypple_admin')) {
          return null
        }
        return column.task_type_group?.task_types.map((task) =>
          taskTableCell(task, column)
        )
      default:
        return (
          <TableCell
            data-testid={`${kebabCase(column.name)}-header`}
            key={`${column.name}-header`}
          >
            {column.name}
          </TableCell>
        )
    }
  }

  const handleOnChange = () => {
    if (list.length >= selectedRowsIds.length) {
      dispatch(pickupsAndDeliveriesSelectedIds([]))
    }
    if (!selectedRowsIds.length) {
      const allIds = list.map((item) => item.id)
      dispatch(pickupsAndDeliveriesSelectedIds(allIds))
    }
  }

  return (
    <TableHead>
      <TableRow>
        {!isLoading && canBulkEdit() && (
          <TableCell key={`start-cell-header`}>
            <Checkbox
              sx={{ padding: 0 }}
              indeterminate={!!selectedRowsIds.length}
              checked={list.length === selectedRowsIds.length}
              onChange={handleOnChange}
              data-testid="select-all-rows"
            />
          </TableCell>
        )}
        {tableColumns.map((column) => renderTableHeader(column))}
      </TableRow>
    </TableHead>
  )
}
export default PickupsDeliveryTableHeader

export enum RatesListTypeEnum {
  fcl = 'fcl',
  lcl = 'lcl',
  air = 'air',
}

export const ratesRequestParams = {
  [RatesListTypeEnum.fcl]: {
    load_type: RatesListTypeEnum.fcl,
    include_demurrage_and_detention: false,
  },
  [RatesListTypeEnum.lcl]: {
    load_type: RatesListTypeEnum.lcl,
    include_demurrage_and_detention: false,
  },
  [RatesListTypeEnum.air]: {
    load_type: RatesListTypeEnum.lcl,
    modality_specific: RatesListTypeEnum.air,
  },
}

interface IRequestQuoteParamsBase {
  start_lat: number
  start_lng: number
  start: string
  start_country: string
  end_lat: string
  end_lng: string
  end: string
  end_country: string
  departure_date: string
  freight: boolean
  shipment_type: string
  quantities: number[]
}

declare global {
  interface IPriceQuoteItem {
    price: string
    currency: string
    calculation_method: string
  }

  interface INamedPriceQuoteItem extends IPriceQuoteItem {
    description: string
  }

  interface ILclRequestQuoteParams extends IRequestQuoteParamsBase {
    widths: number[]
    lengths: number[]
    heights: number[]
    weights: number[]
  }

  interface IFclRequestQuoteParams extends IRequestQuoteParamsBase {
    container_types: string[]
  }

  interface IRatesPriceItem {
    type: 'price' | 'included_price'
    range_min: string | null
    range_max: string | null
    container_type: { code: string; name: string }
    range_calculation_method: string
    calculation_method: string
    id: number
    service_item: {
      priority: number
      name: string
      code: 'FREI_OCEAN_WM' | string
      service_code:
        | 'pickup'
        | 'export_customs'
        | 'origin_port'
        | 'destination_port'
        | 'import_customs'
        | 'delivery'
        | 'others'
        | 'insurance'
        | 'freight'
      additional: boolean
      tiered_pricing: boolean
    }
    valid_from: string
    valid_to: string
    value: string
    currency: 'USD' | 'EUR'
    origin: {
      local: IRatesPriceItem[]
      additional: IRatesPriceItem[]
    }
    destination: {
      local: IRatesPriceItem[]
      additional: IRatesPriceItem[]
    }
  }

  interface IRateBase {
    loading_port: IPort
    discharge_port: IPort
    prices: IRatesPriceItem[]
    valid_from: string
    valid_to: string
    carrier: ICarrier
    seller_organization: IOrganizationShort
  }
  interface IRateType {
    hasOceanFreightPrices: boolean
    currency: 'USD' | 'EUR'
    origin: {
      local: IRatesPriceItem[]
      additional: IRatesPriceItem[]
      demurrage: IRatesPriceItem[]
      detention: IRatesPriceItem[]
    }
    destination: {
      local: IRatesPriceItem[]
      additional: IRatesPriceItem[]
      demurrage: IRatesPriceItem[]
      detention: IRatesPriceItem[]
    }
  }

  interface ILclRate extends IRateBase, IRateType {
    value: string
    load_type: RatesListTypeEnum
    list_type: RatesListTypeEnum
  }

  interface IFclRate extends IRateBase, IRateType {
    load_type: RatesListTypeEnum
    list_type: RatesListTypeEnum
    containers: {
      [key: string]: IRatesPriceItem
    }
  }
  interface IAirRate extends IRateBase, IRateType {
    value: string
    load_type: RatesListTypeEnum
    list_type: RatesListTypeEnum
    local_charges: IRatesPriceItem[]
    rates_per_chargeable_weight: IRatesPriceItem[]
  }
  interface IRateFilterOptions {
    origin_countries: any[]
    origin_ports: IPort[]
    destination_countries: any[]
    destination_ports: IPort[]
    tradelanes: any[]
    user_container_types: IContainerType[]
  }

  type IRate = IFclRate | ILclRate | IAirRate

  interface IRatesState {
    ratesListType: RatesListTypeEnum
    isLCLLoading: boolean
    isFCLLoading: boolean
    isAIRLoading: boolean
    lists: {
      fcl: IFclRate[] | null
      lcl: ILclRate[] | null
      air: IAirRate[] | null
    }
    filterOptions: IRateFilterOptions
  }
}

export type PricesResponse = {
  message: string
  result: IRate[]
}
export type FilterOptionsResponse = IRateFilterOptions

export type UpdateUserContainerTypeRequest = Partial<{
  container_type_ids: number[]
}>
export type UpdateUserContainerTypeResponse = IContainerType[]

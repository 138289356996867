import { FunctionComponent } from 'react'

interface IProps {
  children?: React.ReactNode
  className?: string
}

const StripedTableCell: FunctionComponent<IProps> = (props) => {
  const children: React.ReactNode = props.children || null
  return (
    <div className={`striped-table--row-cell ${props.className || ''}`}>
      {children}
    </div>
  )
}

export default StripedTableCell

const success = {
  50: '#E8F7E8',
  100: '#C8EBC7',
  200: '#A4DFA4',
  300: '#7DD37E',
  400: '#5EC860',
  500: '#3DBE42',
  600: '#33AE38',
  700: '#249C2C',
  800: '#006B06',
  900: '#013710',
}

export default success

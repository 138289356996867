import { takeLatest } from 'redux-saga/effects'
import * as actions from 'src/stores/actions/reportsDashboard'
import * as actionCreators from '../../actionCreators/reportsDashboard'

import { simpleGetAndResolve, simplePutAndResolve } from '../factories'

const getDashboardData = simpleGetAndResolve(
  () => 'api/v1/reports_dashboard/',
  actionCreators.fetchReportsDashboardShipmentsSuccess,
  (response) => response.data
)

const getDashboardFilterOptions = simpleGetAndResolve(
  () => 'api/v1/reports_dashboard/filter_options',
  actionCreators.fetchReportsDashboardFilterOptionsSuccess,
  (response) => response.data
)

const updateTaskStatus = simplePutAndResolve(
  (action) => `api/v1/shipments/${action.shipmentId}/tasks/${action.taskId}`,
  actionCreators.reportsDashboardUpdateTaskStatusSuccess,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeLatest(actions.REPORTS_DASHBOARD_FETCH_DATA, getDashboardData)
  yield takeLatest(
    actions.REPORTS_DASHBOARD_FETCH_FILTER_OPTIONS,
    getDashboardFilterOptions
  )
  yield takeLatest(
    actions.REPORTS_DASHBOARD_UPDATE_TASK_STATUS,
    updateTaskStatus
  )
}

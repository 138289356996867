import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import { withStyles } from '@mui/styles'

export const TableCustom = withStyles(() => ({
  root: {
    tableLayout: 'auto',
    '& th': {
      cursor: 'initial',
      border: 'none',
      textTransform: 'none',
      backgroundColor: 'white',
    },
    '& td': {
      cursor: 'initial',
      verticalAlign: 'bottom',
    },
  },
}))(Table)

export const TableCustomCell = withStyles(() => ({
  root: {
    '&.MuiTableCell-sizeSmall': {
      padding: '5px 0',
      border: 'none',
    },
  },
}))(TableCell)

export const CalculationMethodText = withStyles((theme) => ({
  root: {
    color: theme.palette.grey[500],
    paddingLeft: theme.spacing(1),
    flex: 2,
    display: 'inline-block',
  },
}))(Typography)

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import set from 'lodash/set'
import { useGetPackageTypes } from 'src/services/Api/common'
import { Grid, IconButton, InputLabel, Stack, Typography } from '@mui/material'
import { Refresh } from '@mui/icons-material'
import { cargoRequiredStackableOptions } from 'src/config/constants'
import { defaultCargo } from '../../../Form.constants'
import { useFormContext } from '../../../FormContext'
import { TemplateFormContextProps } from '../../../Form.props'
import { useFormFieldChangeSideEffects } from '../../../Form.hooks'
import SingleSelect from '../../FormElements/SingleSelect'
import Input from '../../FormElements/Input'
import TemplateFormCargoDimensions from './CargoDimensions'

const TemplateFormCargo = () => {
  const {
    onPackageTypeChange: handlePackageTypeChange,
  } = useFormFieldChangeSideEffects()

  const { data: packageTypes } = useGetPackageTypes({
    refetchOnMount: false,
  })

  const {
    onChange,
    cargoDetailsRequiredFields,
    formState,
  } = useFormContext() as TemplateFormContextProps

  const { width, height, length } = formState.cargo

  const { dimensionValidations: initialValidations } = handlePackageTypeChange(
    formState.cargo.packageTypeId
  )

  const [dimensionValidation, setDimensionValidation] = useState(
    initialValidations
  )

  const options =
    packageTypes?.map((packageType) => ({
      id: packageType.id,
      label: packageType.name,
    })) ?? []

  const onReset = () => {
    onChange({
      cargo: { ...defaultCargo },
    })
  }

  const onPackageTypeChange = (packageTypeId) => {
    const { dimensionValues, dimensionValidations } = handlePackageTypeChange(
      packageTypeId
    )

    setDimensionValidation(dimensionValidations)
    onChange(
      set(formState, 'cargo', { ...formState.cargo, ...dimensionValues })
    )
  }

  const { t } = useTranslation()

  const cargoItems = [
    {
      label: t('templates.cargo_details.quantity', 'Quantity'),
      key: 'quantity',
      component: (
        <Input
          fieldPath="cargo.quantity"
          numberInput
          inputProps={{
            'data-testid': 'shipment-template-cargo-quantity',
          }}
        />
      ),
      columnsSize: 1,
    },
    {
      label: t('templates.cargo_details.package_type', 'Package type'),
      key: 'packageTypeId',
      component: (
        <SingleSelect
          options={options}
          fieldPath="cargo.packageTypeId"
          onSingleSelectChange={onPackageTypeChange}
          SelectDisplayProps={{
            'data-testid': 'shipment-template-cargo-package-type-id',
          }}
        />
      ),
      columnsSize: 2,
    },
    {
      label: t('templates.cargo_details.dimensions', 'Dimensions'),
      key: 'width',
      component: (
        <TemplateFormCargoDimensions
          dimensionValidation={dimensionValidation}
        />
      ),
      columnsSize: 2,
    },
    {
      label: t('templates.cargo_details.volume', 'Volume'),
      key: 'totalVolumeCbm',
      component: (
        <Input
          fieldPath="cargo.totalVolumeCbm"
          disabled={width > 0 || height > 0 || length > 0}
          inputProps={{
            'data-testid': 'shipment-template-cargo-volume',
          }}
          InputProps={{
            endAdornment: (
              <>
                m<sup>3</sup>
              </>
            ),
          }}
        />
      ),
      columnsSize: 2,
    },
    {
      label: t('templates.cargo_details.total_weight', 'Total weight'),
      key: 'weight',
      component: (
        <Input
          fieldPath="cargo.weight"
          InputProps={{
            endAdornment: 'kg',
          }}
          inputProps={{
            'data-testid': 'shipment-template-cargo-weight',
          }}
        />
      ),
      columnsSize: 2,
    },
    {
      label: t('templates.cargo_details.description', 'Description'),
      key: 'description',
      component: (
        <Input
          fieldPath="cargo.description"
          inputProps={{
            'data-testid': 'shipment-template-cargo-description',
          }}
        />
      ),
      columnsSize: 2,
    },
    {
      label: t('templates.cargo_details.stackable', 'Stackable'),
      key: 'stackable',
      component: (
        <SingleSelect
          mapIdToValue
          fieldPath="cargo.stackable"
          options={cargoRequiredStackableOptions}
          SelectDisplayProps={{
            'data-testid': 'shipment-template-cargo-stackable',
          }}
        />
      ),
      columnsSize: 1,
    },
  ]

  return (
    <Stack spacing={2} direction="row" alignItems="end">
      <Grid container rowSpacing={1} columnSpacing={2} flexGrow={1}>
        <Grid item xs={12}>
          <InputLabel
            children={t(
              'templates.cargo_details.goods_details',
              'Cargo details'
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container rowSpacing={1} columnSpacing={2} flexGrow={1}>
            {cargoItems.map((item) => {
              return (
                <Grid item xs={item.columnsSize} key={item.key}>
                  <InputLabel
                    sx={{ mb: 0 }}
                    required={cargoDetailsRequiredFields.includes(
                      `cargo.${item.key}`
                    )}
                  >
                    <Typography variant="overline" children={item.label} />
                  </InputLabel>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container rowSpacing={1} columnSpacing={2} flexGrow={1}>
            {cargoItems.map((item) => (
              <Grid item xs={item.columnsSize} key={item.key}>
                {item.component}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <IconButton
        sx={{ height: 40 }}
        onClick={onReset}
        data-testid="shipment-template-reset-cargo"
      >
        <Refresh />
      </IconButton>
    </Stack>
  )
}

export default TemplateFormCargo

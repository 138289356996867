import { FunctionComponent } from 'react'
import qs from 'query-string'
import InlineNotification from 'src/components/Common/InlineNotification'

const PurchaseOrdersNotification: FunctionComponent<{}> = () => {
  const params = qs.parse(document.location.search, { arrayFormat: 'bracket' })
  const purchaseOrderNumbers = params.purchase_orders || []
  const sum_cbm = params.sum_cbm

  const message = `You have selected ${purchaseOrderNumbers.length} Order${
    purchaseOrderNumbers.length > 1
      ? `s ${(purchaseOrderNumbers as string[]).join(', ')}) / ${sum_cbm} m3`
      : ` ${purchaseOrderNumbers[0]} (${sum_cbm} m3)`
  }
    `

  return (
    <InlineNotification
      color="info"
      show={!!purchaseOrderNumbers.length}
      message={message}
      alertTitle="Create shipment from order."
      showClose={true}
    />
  )
}

export default PurchaseOrdersNotification

import MuiTable from '@mui/material/Table'
import MuiTableBody from '@mui/material/TableBody'
import MuiTableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import MuiTableHead from '@mui/material/TableHead'
import MuiTableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import { Box, IconButton } from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { useState } from 'react'
import { Skeleton } from 'src/stories/Skeleton'
import { useStyles } from './table.styles'

export interface RowItem extends Record<string, any> {
  open?: boolean
  header?: React.ReactNode
}

export interface TableComponentProps<T> {
  columns: Record<
    string,
    {
      className?: string
      name: string | React.ReactNode
      value?: (row: T) => string
      component?: ({ row }: { row: T }) => React.ReactNode
    }
  >
  rows: T[]
  loading: boolean
  // a function to tell the table that data is grouped in a format of array
  // where each element is a group that contains items of that group
  grouped?: (row: T) => T[]
  component?: React.ElementType
  emptyState?: React.ReactNode
  pagination?: {
    rowsOptions?: number[]
    rows: number
    total: number
    current: number
    onChange: (page: number) => void
  }
  size?: 'medium' | 'small'
  maxHeight?: number
  onRowClick?: (id) => void
  showHeader?: boolean
}

interface RowProps<T> {
  columns: TableComponentProps<T>['columns']
  row: T
  grouped?: boolean
  onRowClick?: (id) => void
}

const Row = <T extends RowItem>({
  row,
  columns,
  grouped,
  onRowClick,
}: RowProps<T>) => {
  const classes = useStyles({ grouped })

  const getCellComponent = (row: T, columnKey: string) => {
    const columnConfig = columns[columnKey]
    const component = columnConfig.component
      ? columnConfig.component({ row })
      : row[columnKey]
    return component
  }

  return (
    <MuiTableRow
      data-testid="table-row"
      key={row.id}
      className={`${classes.row} ${onRowClick && 'active'}`}
      onClick={() => {
        if (onRowClick) {
          onRowClick(row.id)
        }
      }}
    >
      {Object.keys(columns).map((columnKey) => {
        const component = getCellComponent(row, columnKey)
        return (
          <MuiTableCell
            key={columnKey}
            className={`${columns[columnKey]?.className ?? ''}`}
          >
            {component || '-'}
          </MuiTableCell>
        )
      })}
    </MuiTableRow>
  )
}

const GroupedRows = <T extends RowItem>({
  row,
  columns,
  rows,
}: RowProps<T> & { rows: T[] }) => {
  const [open, setOpen] = useState(row.open)
  const classes = useStyles({ grouped: true })

  return (
    <>
      <MuiTableRow
        onClick={() => setOpen(!open)}
        className={classes.groupedRows}
        data-testid="table-grouped-row"
      >
        <MuiTableCell colSpan={Object.keys(columns).length}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {row.header}
            <Box sx={{ flexGrow: 1 }} />
            <IconButton size="small" sx={{ p: 0 }}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </Box>
        </MuiTableCell>
      </MuiTableRow>
      {open &&
        rows.map((row: T) => (
          <Row key={row.id} row={row} columns={columns} grouped={true} />
        ))}
    </>
  )
}

export const Table = <T extends RowItem>({
  columns,
  rows = [],
  loading,
  grouped,
  size = 'medium',
  maxHeight,
  onRowClick,
  emptyState,
  showHeader = true,
  ...props
}: TableComponentProps<T>) => {
  const classes = useStyles({
    grouped: false,
  })

  const rowsPerPage = props?.pagination?.rows ?? 20

  return (
    <Paper elevation={0}>
      <TableContainer
        component={props.component || 'div'}
        className={classes.tablePaperWrapper}
        style={{ maxHeight: maxHeight ? maxHeight : '70vh' }}
      >
        <MuiTable stickyHeader size={size}>
          {showHeader && (
            <MuiTableHead>
              <MuiTableRow data-testid="table-header-row">
                {Object.keys(columns).map((columnKey) => (
                  <MuiTableCell
                    className={`${columns[columnKey]?.className ?? ''}`}
                    key={columnKey}
                    data-column={columnKey}
                  >
                    {columns[columnKey].name}
                  </MuiTableCell>
                ))}
              </MuiTableRow>
            </MuiTableHead>
          )}
          <MuiTableBody>
            {loading &&
              Array.from({ length: rowsPerPage }).map((_, index) => (
                <MuiTableRow key={index}>
                  {Object.keys(columns).map((key) => (
                    <MuiTableCell
                      className={`${columns[key]?.className ?? ''}`}
                      key={key}
                    >
                      <Skeleton animation="wave" />
                    </MuiTableCell>
                  ))}
                </MuiTableRow>
              ))}
            {!loading &&
              rows.map((row: T, index) =>
                grouped ? (
                  <GroupedRows
                    key={index}
                    row={row}
                    columns={columns}
                    rows={grouped(row)}
                  />
                ) : (
                  <Row
                    key={index}
                    row={row}
                    columns={columns}
                    grouped={false}
                    onRowClick={onRowClick}
                  />
                )
              )}
          </MuiTableBody>
        </MuiTable>
      </TableContainer>
      {!loading && emptyState && rows.length === 0 ? emptyState : ''}
      {props.pagination && (
        <TablePagination
          sx={{
            borderTopWidth: '1px',
            borderTopStyle: 'solid',
            borderTopColor: 'grey.400',
          }}
          rowsPerPageOptions={props.pagination.rowsOptions || []}
          component="div"
          rowsPerPage={+props.pagination.rows}
          count={props.pagination.total}
          page={props.pagination.current}
          onPageChange={(event, page) => props.pagination?.onChange(page)}
        />
      )}
    </Paper>
  )
}

import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'
import Chip, { ChipProps } from '@mui/material/Chip'
import Badge, { BadgeProps } from '@mui/material/Badge'
import Avatar, { AvatarProps } from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Popper, { PopperProps } from '@mui/material/Popper'
import { StyledButtonProps } from './Search/types'

export const StyledRowBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -22,
    top: 11,
    backgroundColor: theme.palette.primary['A100'],
    color: theme.palette.primary['main'],
    padding: '0 4px',
  },
}))

export const StyledBaseAutocompletePopper = styled(Popper)<PopperProps>(
  ({ theme }) => ({
    maxWidth: 300,
    width: '100%',
    overflow: 'hidden',
    zIndex: theme.zIndex.modal,
    boxShadow: theme.shadows[2],
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
  })
)

export const StyledTriggerButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'value',
})<StyledButtonProps>(({ theme, value }) => ({
  backgroundColor:
    value.length === 0
      ? theme.palette.grey['50']
      : theme.palette.primary['A100'],
  color: theme.palette.primary.main,
  '&:hover, &:active': {
    backgroundColor: theme.palette.primary['100'],
  },
}))

export const StyledHelpTextAvatar = styled(Avatar)<AvatarProps>(
  ({ theme }) => ({
    borderColor: theme.palette.grey['200'],
    borderStyle: 'solid',
    borderWidth: '1px',
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
    width: 24,
    height: 24,
  })
)

export const StyledPlaceholderChip = styled(Chip)<ChipProps>(({ theme }) => ({
  '&.MuiChip-outlinedDefault': {
    '& .MuiChip-label, & .MuiSvgIcon-root': {
      color: theme.palette.grey[400],
    },
  },
}))

export const StyledFilledBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  left: 0,
  top: 0,
  padding: theme.spacing(1, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
}))

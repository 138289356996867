import { FunctionComponent, useMemo } from 'react'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import WarningIcon from '@mui/icons-material/Warning'
import Typography from '@mui/material/Typography'
import { getCurrentDate, fromISOwithZone } from 'src/utils/helpers'
import './styles.scss'

interface IProps {
  shipment: IReportsShipment
  type: 'etd' | 'eta' | 'followup'
}

const AlertField: FunctionComponent<IProps> = ({ shipment, type }) => {
  const alerts: string[] = useMemo(() => {
    const alertMessages: string[] = []

    const currentDate = getCurrentDate().startOf('day')

    if (type === 'followup') {
      const followUpDate = fromISOwithZone(shipment.follow_up || '').startOf(
        'day'
      )

      if (currentDate >= followUpDate) {
        alertMessages.push('Follow up date')
      }
    }

    if (type === 'etd') {
      const estimatedDeparture = fromISOwithZone(
        shipment.estimated_departure || ''
      ).startOf('day')
      // for readability sake
      const statusCondition1: boolean =
        !['CPOL', 'IT', 'DEP'].includes(shipment.status_code) &&
        currentDate === estimatedDeparture
      const statusCondition2: boolean =
        shipment.status_sequence < 420 && estimatedDeparture < currentDate
      const statusCondition3: boolean =
        shipment.status_sequence >= 420 && estimatedDeparture > currentDate

      if (statusCondition1 || statusCondition2 || statusCondition3) {
        alertMessages.push('Check shipment status')
      }

      if (shipment.margin_negative) {
        alertMessages.push('Check margin')
      }
    }

    if (type === 'eta') {
      const estimatedArrival = fromISOwithZone(
        shipment.estimated_arrival || ''
      ).startOf('day')

      if (estimatedArrival <= currentDate.plus({ days: 14 })) {
        alertMessages.push('ETA < 14 days')
      }
    }

    return alertMessages
  }, [shipment])

  if (!alerts.length) {
    return null
  }

  const TooltipIcon = () => (
    <WarningIcon
      fontSize="small"
      sx={{ mx: 0.5, color: 'error.dark', cursor: 'pointer' }}
    />
  )

  const tooltipText = () => (
    <Box className="alert-field-tooltip">
      {alerts.map((alert: string) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }} key={alert}>
          <TooltipIcon />
          <Typography component="span" children={alert} />
        </Box>
      ))}
    </Box>
  )

  return (
    <Tooltip placement="top" title={tooltipText()}>
      <span className="alert-field">
        <TooltipIcon />
      </span>
    </Tooltip>
  )
}

export default AlertField

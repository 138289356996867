import { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import { Color } from 'src/stories/DashboardSelect'

import { Logger } from 'src/utils'
import ColoredField, { Icon } from '../ColoredField'
import { OptionsItemWithIconCode, deliveryStatuses } from '../mappings/tasks'
import { MappingItem, MappingObject, mappings } from './mappings'

interface IProps {
  mapping: string
  value?: string | number
  type: 'task' | 'indicator'
}

const ColoredFieldWithMappings: FunctionComponent<IProps> = ({
  mapping,
  value,
  type,
}) => {
  const { taskMappings } = useSelector((state: IGlobalState) => ({
    taskMappings: state.reportsDashboard.filterOptions.task_type_statuses,
  }))

  let mappingItem: undefined | OptionsItemWithIconCode | MappingItem = undefined
  let shortcut: string | undefined = undefined

  if (type === 'indicator') {
    const selectedMapping: MappingObject = mappings[mapping] as MappingObject
    mappingItem = selectedMapping[value || '']

    if (!mappingItem) {
      Logger.log(`key ${value} is not present in mapping ${mapping}.`)
      return null
    }
  } else {
    let selectedMapping = taskMappings[mapping] as OptionsItemWithIconCode[]
    if (mapping === 'delivery') {
      selectedMapping = deliveryStatuses
    }

    mappingItem = selectedMapping.find((x) => x.id === value)

    if (!mappingItem) {
      return null
    }

    if (mappingItem.shortcut) {
      shortcut = mappingItem.shortcut
    }
  }

  return (
    <ColoredField
      value={mappingItem.name}
      icon={mappingItem.icon as Icon}
      color={mappingItem.color as Color}
      shownValue={shortcut}
    />
  )
}

export default ColoredFieldWithMappings

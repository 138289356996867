import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { SelectChangeEvent } from '@mui/material'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import FormLabel from 'src/stories/Lab/FormLabel'

import { DemurrageAndDetentionPortPairProps } from './DemurrageAndDetentionContent.props'

const DemurrageAndDetentionContentRatesPortPairs: React.FC<{
  portPairs: DemurrageAndDetentionPortPairProps[]
  currentTab?: string
  onPortPairChange: (value: string) => void
}> = ({ portPairs, currentTab, onPortPairChange }) => {
  const { t } = useTranslation()
  const currentPortPairId = portPairs[0]?.id || ''
  const [currentPortPair, setCurrentPortPair] = useState(currentPortPairId)

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setCurrentPortPair(event.target.value as string)
  }

  useEffect(() => {
    setCurrentPortPair(currentPortPairId)
  }, [currentTab])

  useEffect(() => {
    onPortPairChange(currentPortPair)
  }, [currentPortPair])

  if (portPairs.length > 0) {
    return (
      <Box
        mt={3}
        sx={{ maxWidth: 450 }}
        data-testid="demurrage-and-detention-rates-content-port-pairs"
      >
        <FormLabel
          label={t('demurrage_and_detention.filters.port_pair', 'Port pair')}
        />
        <SingleSelect
          autoWidth={false}
          options={portPairs}
          value={currentPortPair}
          onChange={handleChange}
        />
      </Box>
    )
  }
  return null
}

export default DemurrageAndDetentionContentRatesPortPairs

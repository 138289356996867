import { FunctionComponent, useEffect, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toNumber, capitalize } from 'lodash'
import { parse } from 'query-string'
import Drawer from 'src/stories/Drawer'
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser'
import {
  pickupsAndDeliveriesSetDrawer,
  pickupsAndDeliveriesSetSelectedId,
  pickupsAndDeliveriesGetOneSuccess,
} from 'src/stores/actionCreators'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { permissionTo } from 'src/utils'
import CustomiseColumns from './CustomiseColumns'
import DetailsTabs from './InlandTransport/DetailsTabs'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.action.active,
    '&:hover': { cursor: 'pointer', color: theme.palette.primary.main },
  },
}))

const PickupDeliveryDrawer: FunctionComponent = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const classes = useStyles()

  const { drawerType, drawerOpen, singleInlandTransport } = useSelector(
    (state: IGlobalState) => ({
      drawerType: state.pickUpsAndDeliveries.drawer.type,
      drawerOpen: state.pickUpsAndDeliveries.drawer.open,
      singleInlandTransport: state.pickUpsAndDeliveries.singleInlandTransport,
    }),
    shallowEqual
  )

  useEffect(() => {
    const locationParams = parse(location.search, {
      arrayFormat: 'bracket',
    })
    if (locationParams.openItem) {
      dispatch(
        pickupsAndDeliveriesSetSelectedId(toNumber(locationParams.openItem))
      )
      dispatch(
        pickupsAndDeliveriesSetDrawer({ type: 'inland_transport', open: true })
      )
    }
  }, [])

  const onDrawerClose = () => {
    dispatch(pickupsAndDeliveriesSetSelectedId(null))
    dispatch(pickupsAndDeliveriesSetDrawer({ type: 'none', open: false }))
    dispatch(pickupsAndDeliveriesGetOneSuccess(null))
  }

  const renderChildren = useMemo(() => {
    switch (drawerType) {
      case 'customize':
        return <CustomiseColumns onDrawerClose={onDrawerClose} />
      case 'inland_transport':
        return <DetailsTabs />
      default:
        ;<></>
    }
  }, [drawerType])

  const openShipmentInCortana = (_) =>
    window.open(
      `${window.shyppleConfig.apiUrl}/admin/shipments/${singleInlandTransport?.shipment.id}`,
      '_blank'
    )

  const containerName =
    singleInlandTransport?.shipment.load_type === 'lcl'
      ? 'LCL'
      : singleInlandTransport?.container.container_type.name
  const { t } = useTranslation()
  const drawerHeader = useMemo(() => {
    switch (drawerType) {
      case 'customize':
        return t('common.customize', 'Customize')
      case 'none':
        return ''
      case 'inland_transport':
        if (singleInlandTransport) {
          return (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              {permissionTo('pickups_deliveries.shypple_admin') && (
                <Box mr={1.5}>
                  <OpenInBrowserIcon
                    className={classes.icon}
                    onClick={openShipmentInCortana}
                  />
                </Box>
              )}
              {`${capitalize(singleInlandTransport.service)} ${t(
                'transports.drawer.details',
                'details for'
              )} ${containerName} ${
                singleInlandTransport.container.container_number
                  ? `(${singleInlandTransport.container.container_number})`
                  : ''
              }`}
            </Box>
          )
        }
        return ''
    }
  }, [drawerType, singleInlandTransport])

  return (
    <Drawer
      heading={drawerHeader}
      open={drawerOpen}
      onClose={onDrawerClose}
      children={renderChildren}
    />
  )
}
export default memo(PickupDeliveryDrawer, () => true)

import { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import SearchBar from 'src/stories/SearchBar'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import { Grid, InputLabel, Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import { isPlainObject, capitalize, lowerCase } from 'lodash'
import CustomInput from 'src/components/Common/Input/MuiInput'
import { Alert } from 'src/stories/Alert'
import { moveTypes } from './constants'

const humanizeSnakeCase = (str: string) => capitalize(lowerCase(str))

interface CarrierBookingReference {
  id: number
  reference: string
  reference_type: string
}

interface ICarrierDetailsProps {
  booking: {
    id: number
    move_type: string
    carrier_booking_organizations: ICarrierBookingOrganization[]
    carrier_booking_references: CarrierBookingReference[]
  }
  shipment: IOverviewShipment
  formState: any
  organizations: any[]
  setFormState: (formState: any) => void
  carrierOrganizations: any[]
  users: any[]
  carrier: ICarrier
  defaultAddressMessages: string[]
  currentUser: IUserState
}

const CarrierDetails: FC<ICarrierDetailsProps> = (props) => {
  const { formState, currentUser, carrier, defaultAddressMessages } = props
  const history = useHistory()

  useEffect(() => {
    if (!formState.roles['carrier']) {
      // Auto fill carrier field in state
      props.setFormState((prevState) => ({
        ...prevState,
        roles: { ...prevState['roles'], carrier: { ...carrier } },
      }))
    }
  }, [])

  const handleRedirect = () => history.push('/contacts/addresses')

  const onChange = (name: string, value) => {
    props.setFormState((prevState) => ({
      ...prevState,
      [name]: isPlainObject(value) ? { ...prevState[name], ...value } : value,
    }))
  }
  const isBookerNull = !props.organizations.some(
    (o) => o.party_role === 'booker'
  )

  if (isBookerNull) {
    props.organizations.push({ party_role: 'booker' })
  }

  if (props.carrierOrganizations.length === 0) return null
  return (
    <>
      <Box mt={4} mb={2}>
        <Typography variant="h2" gutterBottom>
          Carrier details
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <InputLabel htmlFor="carrier">Carrier booking office</InputLabel>
          <SearchBar
            id="carrier"
            onChange={(option) => onChange('roles', { carrier: option })}
            value={
              formState.roles['carrier'] ? formState.roles['carrier'] : carrier
            }
            options={props.carrierOrganizations}
            optionLabel={(org: any) => org.name}
          />
        </Grid>
        <Grid item xs={4}>
          <InputLabel>Move type</InputLabel>
          <SingleSelect
            disabled
            id="move-type"
            value={formState['moveType']}
            options={moveTypes}
          />
        </Grid>
      </Grid>
      <Box mt={4} mb={2}>
        <Typography variant="h2" gutterBottom>
          Contract party details
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={3}>
          <InputLabel htmlFor="booker">Contract organization</InputLabel>
          <SearchBar
            id="booker"
            onChange={(option) => onChange('roles', { contract_party: option })}
            value={formState.roles['contract_party']}
            options={props.shipment.collaborators}
            optionLabel={(org: any) => org?.name}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel htmlFor="contact">Notification contact</InputLabel>
          <SearchBar
            disableClearable
            id="contact"
            onChange={(value) => onChange('bookerContact', value)}
            value={formState['bookerContact']}
            options={props.users}
            optionLabel={(option: any) => option?.full_name}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomInput
            id="contact-number"
            label="Contract number"
            placeholder="Contract number"
            value={formState.references['contract_number']}
            onChange={(value) =>
              onChange('references', { contract_number: value })
            }
          />
        </Grid>
        <Grid item xs={3}>
          <CustomInput
            id="booking-reference"
            label="Booking reference"
            placeholder="Booking reference"
            value={formState.references['contract_party_reference_number']}
            onChange={(value) =>
              onChange('references', { contract_party_reference_number: value })
            }
          />
        </Grid>
      </Grid>
      <Box mt={4} mb={2}>
        <Typography variant="h2" gutterBottom>
          Additional organizations
        </Typography>
      </Box>
      {props.organizations
        .filter(
          (org) =>
            !(
              org.party_role === 'contract_party' ||
              org.party_role === 'carrier'
            )
        )
        .map((org, index) => (
          <Grid container spacing={4} key={index}>
            <Grid item xs={2}>
              <InputLabel htmlFor={org.party_role}>
                {index === 0 ? 'Role' : <>&nbsp;</>}
              </InputLabel>
              <Box height={32} display="flex" alignItems="center">
                <Typography variant="body1">
                  {humanizeSnakeCase(org.party_role)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <InputLabel htmlFor={org.party_role}>
                {index === 0 ? 'Organization' : <>&nbsp;</>}
              </InputLabel>
              <SearchBar
                id={org.party_role}
                onChange={(option) =>
                  onChange('roles', { [org.party_role]: option })
                }
                value={formState.roles[org.party_role]}
                options={props.shipment.collaborators}
                optionLabel={(org: any) => org.name}
              />
            </Grid>
          </Grid>
        ))}
      <Box mb={2} />
      {defaultAddressMessages &&
        defaultAddressMessages.map((msg) => (
          <Alert
            actionHandler={handleRedirect}
            actionText={
              msg.includes(currentUser.organizationName)
                ? 'Go to address book'
                : undefined
            }
            message={msg}
            color="warning"
          />
        ))}
    </>
  )
}

export default CarrierDetails

import {
  Container20FlatRack,
  Container40FlatRack,
  Container20Tank,
  Container40Tank,
} from './ContainerIcons.icons'

export const containerSVGIconMap = {
  '20_fr': Container20FlatRack,
  '40_fr': Container40FlatRack,
  '20_tc': Container20Tank,
  '40_tc': Container40Tank,
}

// @ts-nocheck
// FIXME

import {
  FunctionComponent,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react'
import { useSelector, useDispatch, useStore } from 'react-redux'
import { keys, each, includes } from 'lodash'
import { Button } from '@mui/material'
import Modal from 'src/components/Common/Modal'
import Select from 'src/components/Common/Select'
import Input from 'src/components/Common/Input'
import FormFieldSelect from 'src/components/FormFieldSelect'
import InlineNotification from 'src/components/Common/InlineNotification'
import { ModalityEnum } from '../../../config/constants'
import {
  linkAddressToConnection,
  addressesAddAddress,
  addressesPutAddress,
  addressesGetData,
} from '../../../stores/actionCreators'
import { promisifyAction } from '../../../utils'
import addressValidators from './addressValidators'

import 'src/components/SelectorButton/styles.scss'
import 'src/components/MenuButton/styles.scss'
import 'src/components/Booking/ShipmentBookingParties/BookingPartiesModal/styles.scss'
import './styles.scss'

interface IProps {
  open: boolean
  address: IAddress | null
  connectionId: number
  fetchData: () => void
  handleClose: () => void
}

interface IAddressDetails {
  name: string
  address: string
  postal_code: string
  comment: string
  city: string
  country_id: number | null
  address_id: number | null
  preferred_modalities: ModalityEnum[]
  address_opening_hours: any[]
  connection: IShipmentConnection | null
}

interface IValidationErrors {
  [x: string]: string | null | undefined
}

const companyNameUniqValidationMessage: string = 'Company already exists'

const ADDRESS = {
  name: 'Company name*',
  address: 'Address*',
  postal_code: 'Postal code',
  city: 'City*',
}

const ADDRESS_NOTE = {
  comment: 'Additional Information',
}

const initialAddressDetails: IAddressDetails = {
  name: '',
  address: '',
  postal_code: '',
  comment: '',
  city: '',
  country_id: 0,
  address_id: null,
  preferred_modalities: [],
  address_opening_hours: [],
  connection: null,
}

const constructAddress = (address): IAddressDetails => ({
  name: address.name,
  address: address.address,
  postal_code: address.postal_code,
  city: address.city,
  country_id: address.country.id,
  address_id: address.id,
  comment: address.comment || '',
  preferred_modalities: address.preferred_modalities,
  address_opening_hours: address.address_opening_hours || [],
  connection: address.connection,
})

const OrganizationAddressModal: FunctionComponent<IProps> = (props) => {
  const dispatch = useDispatch()
  const store = useStore()
  const [addressDetails, setAddressDetails] = useState<IAddressDetails>(
    initialAddressDetails
  )
  const [addressDetailsErrors, setAddressDetailsErrors] = useState<
    IValidationErrors
  >({})
  const [isAddressUsed, setIsAddressUsed] = useState<boolean>(false)
  const linkAddressToConnectionAsync = promisifyAction(
    dispatch,
    linkAddressToConnection
  )
  const createAddressAsync = promisifyAction(dispatch, addressesAddAddress)
  const updateAddressAsync = promisifyAction(dispatch, addressesPutAddress)
  const addressesGetDataAsync = promisifyAction(dispatch, addressesGetData)

  const { countries, addresses } = useSelector((state: IGlobalState) => ({
    countries: state.countries.list,
    addresses: state.addresses.list,
  }))

  const isCompanyNameUniq = useMemo((): boolean => {
    const companyNames: string[] = addresses.map((x) => {
      return addressDetails.address_id === x.id ? '' : x.name
    })
    return !includes(companyNames, addressDetails.name)
  }, [addresses, addressDetails.name])

  useEffect(() => {
    setAddressDetails(
      props.address ? constructAddress(props.address) : initialAddressDetails
    )
    if (props.address) {
      setIsAddressUsed(true)
    }
    setAddressDetailsErrors({})
  }, [props.address])

  useEffect(() => {
    setAddressDetailsErrors({
      ...addressDetailsErrors,
      name: isCompanyNameUniq ? null : companyNameUniqValidationMessage,
    })
  }, [isCompanyNameUniq])

  const contacts = useMemo((): any[] => {
    const list: any[] = addresses.slice() || []
    list.unshift({ id: null, name: 'Add new company' })
    return list
  }, [addresses])

  const handleAddressSelect = (address: IAddress): void => {
    if (address.id) {
      setAddressDetails(constructAddress(address))
    } else {
      setAddressDetails(initialAddressDetails)
    }
    setAddressDetailsErrors({})
    setIsAddressUsed(true)
  }

  const checkValidations = (): boolean => {
    let isValid: boolean = true
    const addressErrorsObject: { [key: string]: string } = {}

    each(addressValidators, ({ field, validate }: IFieldValidator): void => {
      const errorMessage: string = validate(addressDetails[field])
      if (errorMessage) {
        addressErrorsObject[field] = errorMessage
        isValid = false
      }
    })

    if (!isCompanyNameUniq) {
      addressErrorsObject.name = companyNameUniqValidationMessage
      isValid = false
    }

    setAddressDetailsErrors(addressErrorsObject)

    return isValid
  }

  const onSave = async (): Promise<any> => {
    const isValid: boolean = checkValidations()
    if (!isValid) {
      return
    }
    let addressId: number = 0

    if (addressDetails.address_id) {
      addressId = addressDetails.address_id
      await updateAddressAsync(addressId, addressDetails)
    } else {
      await createAddressAsync(addressDetails)
      const singleAddress = store.getState().addresses.singleAddress
      addressId = singleAddress.id
    }
    await addressesGetDataAsync()
    const linkData = {
      address_ids: [addressId],
    }
    await linkAddressToConnectionAsync(props.connectionId, linkData)
    props.fetchData()
    props.handleClose()
  }

  const renderCountrySelect = (
    label,
    field,
    value,
    errors,
    onChange
  ): React.ReactNode => {
    return (
      <FormFieldSelect
        label={label}
        fieldName={field}
        hasLimitedHeight={true}
        isSearchable={true}
        isRequired={true}
        fallbackValue={value}
        className="organization-address-window--select-input"
        popperClassName={'shipping-instructions__modal-popper'}
        value={value}
        errorNotes={errors}
        onChange={onChange}
        options={countries}
        disabled={false}
      />
    )
  }

  const isSwowAlert: boolean = useMemo(() => {
    return props.address
      ? !!addressDetails.connection &&
          props.address.id !== addressDetails.address_id
      : !!addressDetails.connection
  }, [props.address, addressDetails])

  const onClose = (): void => {
    setAddressDetails(initialAddressDetails)
    setAddressDetailsErrors({})
    setIsAddressUsed(false)
    props.handleClose()
  }

  const handleAddressChange = useCallback(
    (fieldName: string, value: string | string[] | number[]): void => {
      setAddressDetails({ ...addressDetails, [fieldName]: value })
      setAddressDetailsErrors({ ...addressDetailsErrors, [fieldName]: null })
    },
    [addressDetails]
  )

  const renderAddressInputs = (): React.ReactNode => {
    if (!isAddressUsed) {
      return null
    }
    return (
      <>
        {(keys(ADDRESS) || []).map((field: string) => (
          <Input.Text
            key={field}
            children={''}
            label={ADDRESS[field]}
            disabled={!!(field === 'name' && addressDetails.address_id)}
            errorMessage={addressDetailsErrors[field]}
            name={field}
            value={addressDetails[field]}
            onChange={handleAddressChange}
          />
        ))}
        {renderCountrySelect(
          'Country*',
          'country_id',
          addressDetails.country_id,
          addressDetailsErrors.country_id,
          handleAddressChange
        )}
        {(keys(ADDRESS_NOTE) || []).map((field: string) => (
          <Input.Text
            key={field}
            children={''}
            disabled={false}
            label={ADDRESS_NOTE[field]}
            errorMessage={addressDetailsErrors[field]}
            name={field}
            value={addressDetails[field]}
            onChange={handleAddressChange}
          />
        ))}
      </>
    )
  }

  return (
    <Modal.Window open={props.open} onClose={onClose}>
      <Modal.Title children="Link address to connection" onClose={onClose} />
      <Modal.Content>
        <div className="organization-address-window">
          <div className="address-block">
            <div className="organization-address-window--reference">
              <div className="organization-address-window--reference--notes">
                Link an address to this connection. This will enable you to
                automatically add this organization as a collaborator to
                shipments and orders.
              </div>
            </div>
            <Select.Single
              className={`company__select ${
                !addressDetails.address_id && isAddressUsed
                  ? 'open-and-empty'
                  : ''
              }`}
              placeholder={
                !addressDetails.address_id && isAddressUsed
                  ? 'New company'
                  : 'Select company'
              }
              value={addressDetails.address_id}
              onChange={handleAddressSelect}
              options={contacts}
              theme="common"
            />
            {isSwowAlert && (
              <div className="organization-address-window--alert">
                <InlineNotification
                  type={'warning'}
                  show={true}
                  showClose={false}
                  message="This address is already linked to an organization. Proceeding will unlink your address from the existing connection."
                />
              </div>
            )}
            {renderAddressInputs()}
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSave}>
          Save
        </Button>
      </Modal.Actions>
    </Modal.Window>
  )
}
export default OrganizationAddressModal

import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import * as actions from '../actions/shipmentsWatchedBy'

declare global {
  interface IShipmentsWatchedByState {
    onlyWatchedShipments: boolean
  }
}

export const initialShipmentsWatchedByState: IShipmentsWatchedByState = {
  onlyWatchedShipments:
    window.localStorage.getItem('dashboardMyShipments') === 'true',
}

const toggleShipmentsWatchedBy: Reducer<IShipmentsWatchedByState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  onlyWatchedShipments: action.onlyWatchedShipments,
})

export default createReducer(initialShipmentsWatchedByState, {
  [actions.SHIPMENT_WATCHED_BY_TOGGLE]: toggleShipmentsWatchedBy,
})

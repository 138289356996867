import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import Typography from '@mui/material/Typography'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import useMediaQuery from '@mui/material/useMediaQuery'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { Link } from '@mui/material'
import ColorBadge from 'src/components/Common/ColorBadge'
import { permissionTo } from '../../utils'
import { convertDateToRelative } from '../../utils/helpers'
import { routes } from './index'

const useStyles = makeStyles((theme) => ({
  supportMessage: {
    margin: '20px',
  },
}))

const ConnectionsTable = ({
  connections,
  className,
}: {
  connections: IShipmentConnection[]
  className: string
}) => {
  const theme = useTheme()
  const history = useHistory()
  const classes = useStyles()
  const isScreenSmall = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()

  return (
    <TableContainer
      classes={{ root: className }}
      component={(props) => <Paper {...props} square elevation={0} />}
    >
      <Table stickyHeader size={isScreenSmall ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            <TableCell className="x-short" />
            <TableCell>{t('address_book.connections.name', 'Name')}</TableCell>
            <TableCell>{t('address_book.connections.role', 'Role')}</TableCell>
            <TableCell>
              {t('address_book.connections.country', 'Country')}
            </TableCell>
            <TableCell>
              {t('address_book.connections.last_active', 'Last active')}
            </TableCell>
            <TableCell />
            <TableCell className="x-short" />
          </TableRow>
        </TableHead>
        <TableBody>
          {connections.map((item: IShipmentConnection) => (
            <TableRow
              key={item.organization_id}
              hover={permissionTo('address_book.manage')}
              onClick={() => {
                if (!permissionTo('address_book.manage') || !item.manageable)
                  return
                history.push(
                  routes.connectionsView.replace(
                    ':id',
                    item.organization_id.toString()
                  )
                )
              }}
            >
              <TableCell component="th" scope="row" className="x-short">
                <Avatar
                  alt={item.name}
                  src={item.logo || 'no-logo'}
                  className={item.role_code}
                />
              </TableCell>
              <TableCell>
                <Typography variant="body1Strong">{item.name}</Typography>
              </TableCell>
              <TableCell>{item.role}</TableCell>
              <TableCell>{item.country?.name}</TableCell>
              <TableCell>
                {item.organization_id !== 1 ? (
                  item.last_seen ? (
                    convertDateToRelative(item.last_seen)
                  ) : (
                    <ColorBadge
                      content={t('address_book.connections.invited', 'Invited')}
                      className="grey"
                    />
                  )
                ) : (
                  '-'
                )}
              </TableCell>
              <TableCell>
                {item.is_preferred_forwarder && (
                  <ColorBadge
                    content={t(
                      'address_book.connections.preferred_forwarder',
                      'Preferred forwarder'
                    )}
                    className="blue-outlined"
                  />
                )}
              </TableCell>
              <TableCell className="x-short no-overflow">
                {item.manageable ? (
                  <IconButton size="large">
                    <EditIcon />
                  </IconButton>
                ) : (
                  ''
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.supportMessage}>
        {t(
          'address_book.connections.support_message',
          "Can't find the organization that you are looking for? Please contact"
        )}{' '}
        <Link
          variant="body1"
          component="button"
          onClick={() => {
            window.location.href = 'mailto:CS@shypple.com'
          }}
        >
          {t(
            'address_book.connections.support_message_link',
            'customer support'
          )}
        </Link>
      </div>
    </TableContainer>
  )
}

export default ConnectionsTable

import { Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { estimatedArrivalDelayColor } from '../helpers'

const InlandTransportRowEstimatedArrivalDelay = ({
  delay,
  ...props
}: {
  delay: EstimatedArrivalDelay
  'data-testid'?: string
}) => {
  const { t } = useTranslation()
  const estimatedArrivalDelayMessage = (d: number, max: number = 9999) => {
    const days = Math.min(Math.abs(d), max)
    if (d > 0) {
      return t('shipment.timeline.pod.delay_message.delay', { days: days })
    }
    if (d < 0) {
      return t('shipment.timeline.pod.delay_message.early', { days: days })
    }
    return t('shipment.timeline.pod.delay_message.on_time')
  }

  if (!delay) return null

  return (
    <Chip
      data-testid={props['data-testid']}
      color={estimatedArrivalDelayColor(delay.category)}
      label={estimatedArrivalDelayMessage(delay.days)}
      size="small"
    />
  )
}

export default InlandTransportRowEstimatedArrivalDelay

import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/shipmentPickupAndDelivery'
import * as actionCreators from '../../actionCreators/shipmentPickupAndDelivery'
import {
  simpleGetAndResolve,
  simplePutAndResolve,
  simplePatchAndResolve,
  simplePostAndResolve,
  simpleDeleteAndResolve,
} from '../factories'

const getShipmentData = simpleGetAndResolve(
  (action) => `/api/v1/shipments/${action.id}`,
  actionCreators.shipmentGetMainDataSuccess,
  (response) => response.data
)

const getContainers = simpleGetAndResolve(
  (action) => `/api/v1/shipments/${action.id}/containers`,
  actionCreators.shipmentContainersGetDataSuccess,
  (response) => response.data
)

const showInlandTransportStatus = simpleGetAndResolve(
  (action) => `/api/v1/inland_transport_addresses/${action.id}`,
  actionCreators.showInlandTransportStatusSuccess,
  (response) => response.data
)

const updateCargoLine = simplePutAndResolve(
  (action) => `/api/v1/cargos/${action.id}`,
  actionCreators.updateCargoLineSuccess,
  (response) => response.data
)

const createCargoLine = simplePostAndResolve(
  (action) => `/api/v1/cargos`,
  actionCreators.createCargoLineSuccess,
  (response) => response.data
)

const assignAddressToContainer = simplePatchAndResolve(
  (action) => `/api/v1/containers/${action.id}/assign_address`,
  actionCreators.assignAddressToContainerSuccess,
  (response) => response.data
)

const assignReferenceToContainer = simplePatchAndResolve(
  (action) => `/api/v1/containers/${action.id}/assign_reference`,
  actionCreators.assignReferenceToContainerSuccess,
  (response) => response.data
)

const assignDatetimeToContainer = simplePatchAndResolve(
  (action) => `/api/v1/containers/${action.id}/assign_datetime`,
  actionCreators.containerAssignDatetimeSuccess,
  (response) => response.data
)
const acceptInlandTransportStatus = simplePatchAndResolve(
  (action) => `/api/v1/containers/${action.id}/confirm_datetime`,
  actionCreators.updateInlandTransportStatusSuccess,
  (response) => response.data
)

const deleteCargoLine = simpleDeleteAndResolve(
  (action) => `/api/v1/cargos/${action.id}`,
  actionCreators.updateCargoLineSuccess,
  (response) => response.data
)

const createContainer = simplePostAndResolve(
  (action) => `/api/v1/containers`,
  (response) => response.data
)

const updateContainer = simplePutAndResolve(
  (action) => `/api/v1/containers/${action.id}`,
  (response) => response.data
)

const deleteContainer = simpleDeleteAndResolve(
  (action) => `/api/v1/containers/${action.id}`,
  (response) => response.data
)

const duplicateShipmentContainer = simplePostAndResolve(
  (action) =>
    `/api/v1/shipments/${action.shipment_id}/containers/${action.container_id}/duplicate`,
  actionCreators.shipmentContainersGetDataSuccess,
  (response) => response.data
)

const copyContainerAttributes = simplePostAndResolve(
  (action) =>
    `/api/v1/shipments/${action.shipment_id}/containers/${action.container_id}/copy_attributes`,
  actionCreators.copyContainerAttributesSuccess,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.SHIPMENT_GET_MAIN_DATA, getShipmentData)
  yield takeEvery(actions.SHIPMENT_CONTAINERS_GET_DATA, getContainers)
  yield takeEvery(
    actions.SHOW_INLAND_TRANSPORT_STATUS,
    showInlandTransportStatus
  )

  yield takeEvery(
    actions.ACCEPT_INLAND_TRANSPORT_STATUS,
    acceptInlandTransportStatus
  )

  yield takeEvery(actions.CREATE_CARGO_LINE, createCargoLine)
  yield takeEvery(actions.UPDATE_CARGO_LINE, updateCargoLine)
  yield takeEvery(actions.CREATE_SHIPMENT_CONTAINER, createContainer)
  yield takeEvery(actions.UPDATE_CONTAINER_INFORMATION, updateContainer)
  yield takeEvery(actions.ASSIGN_ADDRESS_TO_CONTAINER, assignAddressToContainer)
  yield takeEvery(actions.CONTAINER_ASSIGN_DATETIME, assignDatetimeToContainer)

  yield takeEvery(actions.DELETE_CARGO_LINE, deleteCargoLine)
  yield takeEvery(
    actions.ASSIGN_REFERENCE_TO_CONTAINER,
    assignReferenceToContainer
  )
  yield takeEvery(actions.DELETE_SHIPMENT_CONTAINER, deleteContainer)
  yield takeEvery(
    actions.DUPLICATE_SHIPMENT_CONTAINER,
    duplicateShipmentContainer
  )
  yield takeEvery(actions.COPY_CONTAINER_ATTRIBUTES, copyContainerAttributes)
}

import { AnyAction } from 'redux'
import * as actions from '../actions/serviceItems'

export const getServiceItemsSuccess = (payload: any): AnyAction => ({
  type: actions.GET_SERVICE_ITEMS_SUCCESS,
  payload,
})

export const getServiceItems = (): AnyAction => ({
  type: actions.GET_SERVICE_ITEMS,
})

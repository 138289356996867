import ShipmentTemplateDialog from 'src/components/ShipmentTemplate/ShipmentTemplateDialog'
import { permissionTo } from 'src/utils'

const ShipmentTemplate: React.FC<{
  shipmentId: number
  shipmentContainerType: string
}> = ({ shipmentId, shipmentContainerType }) => {
  if (
    !permissionTo('shipment_templates.create') ||
    shipmentContainerType === 'fcl_mixed'
  ) {
    return null
  }
  return <ShipmentTemplateDialog shipmentId={shipmentId} />
}

export default ShipmentTemplate

import { FunctionComponent, useState, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Paper from '@mui/material/Paper'
import MenuItem from '@mui/material/MenuItem'
import { DateTime } from 'luxon'
import { uniq, uniqBy, min, max, toNumber, sortBy, isEmpty } from 'lodash'
import { Button, Box } from '@mui/material'
import FormLabel from 'src/stories/Lab/FormLabel'
import './styles.scss'
import { ModalityEnum } from 'src/config/constants'
import { FormDatePicker } from 'src/components/Filters/FormElements/FormDatePicker'
import SABSearchAirItem from '../SABSearchItem/SABSearchAirItem'
import SABSearchSeaItem from '../SABSearchItem/SABSearchSeaItem'
import {
  shippingDate,
  convertDatePickerToUniversalFormat,
  convertToUnixDate,
  formattedDateNow,
  increaseDate,
  decreaseDate,
  convertDateForComparison,
} from '../../../utils/helpers'
import SABSearchResultAirSpotRates from './SABSearchResultAirSpotRates'
import SABSearchResultMultiSelect from './SABSearchResultMultiSelect'
import SABSearchResultFiltersTransitTime from './SABSearchResultFilters/SABSearchResultFiltersTransitTime'
import { getSearchUrl } from './utils'

const toUnixDate = (date: string) =>
  date ? toNumber(convertToUnixDate(date)) : 0

const sortIdMap = {
  Price: 'total_price_usd',
  'Transit Time': 'transit_time_days',
  'Departure Date': 'estimated_departure_date',
  'Arrival Date': 'estimated_arrival_date',
}

const initialFilters = {
  departureDateStart: '',
  departureDateEnd: '',
  arrivalDateStart: '',
  arrivalDateEnd: '',
  transit: '',
  carriers: [],
  direct: false,
}

interface IFilters {
  departureDateStart: string
  departureDateEnd: string
  arrivalDateStart: string
  arrivalDateEnd: string
  transit: string
  carriers: any
  direct: boolean
}

interface ISABResultProps {
  searchResult: ISearchResult
  searchQuery: ISearchQuery | any
  sortF: any
  sortType: string
  isStaff: boolean
}

const SABSearchResult: FunctionComponent<ISABResultProps> = (props) => {
  const [filters, setFilters] = useState<IFilters>(initialFilters)
  const [anchorEl, setAnchorEl] = useState<any>(null)

  const { currentUser } = useSelector((state: IGlobalState) => ({
    currentUser: state.user,
  }))

  const { quotes: searchResultQuotes } = props.searchResult
  const { searchQuery } = props
  const { modality } = searchQuery
  const hasSearchQuery = !isEmpty(searchQuery)
  const isAirModality = modality === ModalityEnum.Air
  const isRailModality = modality === ModalityEnum.Rail
  const isSeaModality = modality === ModalityEnum.Sea
  const SearchItemComponent = isAirModality
    ? SABSearchAirItem
    : SABSearchSeaItem
  const validQuotes = !isSeaModality
    ? searchResultQuotes.filter((quote) => !!quote.schedule.id)
    : searchResultQuotes

  const hasValidQuotes = validQuotes.length > 0

  const buildSearchUrl = (path: string, options: any = {}): string => {
    return getSearchUrl(path, options, searchQuery)
  }

  const getCarrierName = (quote: ISearchQuote): string => {
    if (quote.schedule.carrier) {
      return quote.schedule.carrier.name
    } else {
      return 'Unknown'
    }
  }

  const [
    minDateDeparture,
    maxDateDeparture,
    minDateArrival,
    maxDateArrival,
    prevSearchPossible,
    prevSearchUrl,
    nextSearchUrl,
  ] = useMemo(() => {
    const departureDate = searchQuery.departure_date
      ? convertDateForComparison(searchQuery.departure_date)
      : formattedDateNow()

    const etas: string[] = uniq(
      props.searchResult.quotes.map(
        (quote: ISearchQuote) => quote.schedule.estimated_arrival_date
      )
    )
    const prevSearchDate = decreaseDate(departureDate, 'days', 7)

    const nextSearchDate = increaseDate(departureDate, 'days', 7)

    return [
      departureDate,
      increaseDate(departureDate, 'days', 6),
      min(etas),
      max(etas),
      shippingDate(modality) < departureDate,
      buildSearchUrl('/search', {
        departure_date: prevSearchDate,
      }),
      buildSearchUrl('/search', {
        departure_date: nextSearchDate,
      }),
    ]
  }, [searchQuery.departure_date, props.searchResult.quotes])

  const filteredRoutes = useMemo(() => {
    const sortType: string = sortIdMap[props.sortType]
    const sortedQuotes = sortBy(props.searchResult.quotes || [], (quote) =>
      sortType !== 'total_price_usd' ? quote.schedule[sortType] : sortType
    )

    return (sortedQuotes || []).filter((quote: ISearchQuote) => {
      let isSuitable: boolean = true
      const arrival: number = toUnixDate(quote.schedule.estimated_arrival_date)
      const departure: number = toUnixDate(
        quote.schedule.estimated_departure_date
      )
      const transitTime: number | null =
        quote.schedule?.transit_time_days ?? null
      const carrier: string = getCarrierName(quote)
      const direct: boolean = !!quote.schedule.direct_route

      if (filters.departureDateStart && filters.departureDateEnd) {
        isSuitable =
          toUnixDate(filters.departureDateStart) <= departure &&
          departure <= toUnixDate(filters.departureDateEnd)
      }

      if (isSuitable && filters.arrivalDateStart && filters.arrivalDateEnd) {
        isSuitable =
          toUnixDate(filters.arrivalDateStart) <= arrival &&
          arrival <= toUnixDate(filters.arrivalDateEnd)
      }

      if (isSuitable && filters.transit) {
        isSuitable =
          transitTime !== null &&
          filters.transit !== '' &&
          Number(filters.transit) >= transitTime
      }

      if (isSuitable && filters.carriers && filters.carriers.length) {
        isSuitable = filters.carriers.map(({ name }) => name).includes(carrier)
      }

      if (isSuitable && filters.direct) {
        isSuitable = direct
      }

      return isSuitable
    })
  }, [filters, props.sortType, props.searchResult.quotes])

  const resetFilters = useCallback(() => {
    setFilters(initialFilters)
  }, [])

  const updateForce = useCallback(() => {
    setFilters(initialFilters)
  }, [])

  const handleMenuClick = useCallback((action) => {
    return () => {
      menuClick(action)
    }
  }, [])

  const menuClick = (type) => {
    setAnchorEl(null)
    props.sortF(type)
  }

  const pickFilterOption = useCallback(
    (option, fieldName?: string) => {
      if (fieldName && fieldName in filters) {
        setFilters({
          ...filters,
          [fieldName]: option,
        })
      }
    },
    [filters]
  )

  const setDirectSailing = useCallback(() => {
    setFilters({
      ...filters,
      direct: !filters.direct,
    })
  }, [filters])

  const getCarriers = () => {
    const carriers = props.searchResult.quotes.map((quote) => {
      return { name: getCarrierName(quote) }
    })
    return uniqBy(carriers, 'name')
  }

  const onDateChange = (value) => {
    setFilters({
      ...filters,
      ...value,
    })
  }
  const { t } = useTranslation()
  const renderFiltersAndSorting = (
    searchType: ModalityEnum,
    maxDateArrival,
    minDateArrival,
    maxDateDeparture,
    minDateDeparture
  ): React.ReactNode => {
    const quotes = props.searchResult.quotes

    if (!quotes.length) {
      return null
    }

    const { valid_to, valid_from } = quotes[0].price_details
    if (quotes.length === 1 && !valid_to && !valid_from) {
      return null
    }

    return (
      <>
        <div
          className="button-and-sorting-container"
          data-testid="search-book-search-result"
        >
          {!!props.searchResult.quotes.length && !isAirModality && (
            <div className="button-container">
              <div className="button-container--wrapper date">
                <FormDatePicker
                  onChange={onDateChange}
                  minDate={DateTime.fromISO(minDateDeparture)}
                  maxDate={DateTime.fromISO(maxDateDeparture)}
                  label={t(
                    'vessels_page.data_table.filters.departure',
                    'Departure date/time (ETD)'
                  )}
                  name={['departureDateStart', 'departureDateEnd']}
                  qs={filters}
                />
              </div>
              <div className="button-container--wrapper date">
                <FormDatePicker
                  label={t(
                    'vessels_page.data_table.filters.arrival',
                    'Arrival date/time (ETA)'
                  )}
                  minDate={DateTime.fromISO(minDateArrival)}
                  maxDate={DateTime.fromISO(maxDateArrival)}
                  name={['arrivalDateStart', 'arrivalDateEnd']}
                  onChange={onDateChange}
                  qs={filters}
                />
              </div>
              <SABSearchResultFiltersTransitTime
                value={filters.transit}
                quotes={props.searchResult.quotes}
                name="transit"
                onChange={pickFilterOption}
              />
              <div className="button-container--wrapper fixes-width">
                <SABSearchResultMultiSelect
                  label={t('common.modality_sea.carrier', 'Carrier')}
                  name="carriers"
                  value={filters.carriers}
                  onChange={pickFilterOption}
                  selectOptions={getCarriers() || []}
                />
              </div>
              {searchQuery.load_type !== 'lcl' && (
                <div className="button-container--wrapper flex-width">
                  <FormLabel label="&nbsp;" />
                  <Button
                    variant={`${filters.direct ? 'contained' : 'outlined'}`}
                    onClick={setDirectSailing}
                    size="large"
                  >
                    {t('search_and_book.direct_sailing', 'Direct Sailing')}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </>
    )
  }

  if (props.searchResult && !props.searchResult.quotes.length) {
    return <div />
  }

  return (
    <div className="SABSearchResult__mt" data-testid="search-result">
      <Paper>
        <div className="SABSearchResult__search-row">
          <div className="result-info" data-testid="SAB-result-info">
            <Typography
              className="medium bold"
              children={hasValidQuotes ? filteredRoutes.length : 0}
              data-testid="search-result-count"
            />
            &nbsp; {t('search_and_book.results_from', 'results from')} &nbsp;
            <Typography
              className="medium bold"
              children={convertDatePickerToUniversalFormat(minDateDeparture)}
            />
            &nbsp; {t('search_and_book.to', 'to')} &nbsp;
            <Typography
              className="medium bold"
              children={convertDatePickerToUniversalFormat(maxDateDeparture)}
            />
          </div>
          {renderFiltersAndSorting(
            modality,
            maxDateArrival,
            minDateArrival,
            maxDateDeparture,
            minDateDeparture
          )}
        </div>
      </Paper>

      {hasValidQuotes && (
        <div className="sorting-row">
          {t('search_and_book.sort_by', 'Sort by')}
          <div
            className="sorting-row__menu"
            aria-owns={'simple-menu'}
            aria-haspopup="true"
            onClick={(e) => {
              setAnchorEl(e.currentTarget)
            }}
          >
            {props.sortType} <i className="icon chevron" />
          </div>
          <Menu
            classes={{ paper: 'sorting-row__menu-items' }}
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null)
            }}
          >
            <MenuItem onClick={handleMenuClick('Price')}>
              {t('shipment_costs.edit_costs.fields.unit_price', 'Price')}
            </MenuItem>
            <MenuItem onClick={handleMenuClick('Departure Date')}>
              {t('common.modality_sea.departure_date', 'Departure date')}
            </MenuItem>
            <MenuItem onClick={handleMenuClick('Arrival Date')}>
              {t('common.modality_sea.arrival_date', 'Arrival date')}
            </MenuItem>
            <MenuItem onClick={handleMenuClick('Transit Time')}>
              {t('common.modality_sea.transit_time', 'Transit time')}
            </MenuItem>
          </Menu>
        </div>
      )}

      {hasValidQuotes && !isRailModality && (
        <div className="SABSearchResult__result-container">
          {filteredRoutes.map((item: ISearchQuote, index: number) => {
            return (
              <SearchItemComponent
                key={index}
                quote={item}
                searchQuery={searchQuery}
                currentUser={currentUser}
              />
            )
          })}
        </div>
      )}
      {((!hasValidQuotes && hasSearchQuery && isSeaModality) ||
        isRailModality) && (
        <Paper
          data-testid="SAB-quote-text"
          sx={{
            mt: 2,
            p: 2.5,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography
              mb={1}
              variant="h3"
              children="Please request a quote for this route"
            />
            {isSeaModality && (
              <>
                <Typography>
                  At the moment we cannot find a sailing schedule and/or price
                  for the route you’ve requested.
                </Typography>
                <Typography>
                  We know they’re out there, so please request a quote and we’ll
                  get back to you within a few hours with a price and sailing
                  schedule.
                </Typography>
              </>
            )}
            {isRailModality && (
              <>
                <Typography>
                  We'll get back to you within a few hours with a price and
                  schedule.
                </Typography>
                <Typography>
                  Note: The Rail service is currently only available for China -
                  EU trade lane. In case you are interested in Rail service for
                  any other trade lanes, please contact us!
                </Typography>
              </>
            )}
          </Box>
          <Box sx={{ minWidth: 132 }}>
            <Button
              component={Link}
              to={buildSearchUrl('/search/quote')}
              variant="outlined"
              data-testid="proceed-to-quote-rail"
            >
              {t('quotes.request_quote', 'Request quote')}
            </Button>
          </Box>
        </Paper>
      )}
      {!filteredRoutes.length && !!props.searchResult.quotes.length && (
        <div className="SABSearchResult__result-container SABSearchResult__result-container--empty">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <div>
              {t(
                'search_and_book.cannot_find_matches',
                'We cannot find any shipment that matches your current filter criteria'
              )}
            </div>
          </div>
          <Button variant="outlined" onClick={resetFilters}>
            {t('search_and_book.reset_filters', 'Reset filters')}
          </Button>
        </div>
      )}

      {isAirModality && hasSearchQuery && <SABSearchResultAirSpotRates />}

      {isSeaModality && hasValidQuotes && (
        <div className="SABSearchResult__nav">
          {prevSearchPossible && (
            <Box mr={1}>
              <Button
                component={Link}
                to={prevSearchUrl}
                variant="outlined"
                onClick={updateForce}
              >
                {t('search_and_book.search_previous', 'Search previous week')}
              </Button>
            </Box>
          )}
          <Button
            component={Link}
            to={nextSearchUrl}
            variant="outlined"
            onClick={updateForce}
          >
            {t('search_and_book.search_next', 'Search next week')}
          </Button>
        </div>
      )}
    </div>
  )
}
export default SABSearchResult

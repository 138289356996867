import { FunctionComponent } from 'react'
import Box from '@mui/material/Box'
import { Avatar } from 'src/stories/Avatar'
import { convertDateToDateTime } from 'src/utils/helpers'

import './styles.scss'

interface IProps {
  author: IAuthor
  updatedAt: string
}

const UserField: FunctionComponent<IProps> = ({ author, updatedAt }) => (
  <Box className="user-field">
    <Box className="user-field__avatar-wrapper">
      <Avatar
        userCard
        userInfo={{
          name: author.full_name,
          department: author.full_name,
          company: author.organization.name,
          phone: author.phone,
        }}
        alt={author.full_name}
        src={author.avatar_thumb || 'no-logo'}
        className="shypple_admin"
      />
    </Box>
    <Box className="user-field__text-wrapper">
      <Box className="user-field__text-wrapper--body">{author.full_name}</Box>
      <Box className="user-field__text-wrapper--meta">
        {convertDateToDateTime(updatedAt)}
      </Box>
    </Box>
  </Box>
)

export default UserField

import { Link } from '@mui/material'
import Box from '@mui/material/Box'
import CopyWrapper from 'src/stories/CopyWrapper'
import IconField from '../components/IconField'
import ShipmentField from '../components/ShipmentField'
import GenericDateField from '../components/GenericDateField'
import CommentField from '../components/CommentField'
import ColoredFieldWithMappings from '../components/ColoredFieldWithMappings'
import ColoredField from '../components/ColoredField'
import CustomsTask from '../components/CustomsTask'
import DeliveriesWarningIconTooltip from '../components/DeliveriesWarningIconTooltip'

export const deliveriesFCLSchema = {
  icon: {
    field: (shipment: IDeliveriesShipment) => <IconField shipment={shipment} />,
    name: '',
    sortable: false,
    sortValue: '',
    customClassName: 'short-50',
  },
  shipment: {
    field: (shipment: IDeliveriesShipment) => (
      <ShipmentField showSpot={false} shipment={shipment} />
    ),
    name: 'SHIPMENT',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  containerNumber: {
    field: (shipment: IDeliveriesShipment) => (
      <>
        <Box className="text-wrapper">
          <Link
            variant="body1"
            href={`${shipment.cortana_shipment_url}/containers/${shipment.container_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {shipment.container_number || '-'}
          </Link>
          <Box className="text-wrapper">
            {shipment.bl_number ? (
              <CopyWrapper>{shipment.bl_number}</CopyWrapper>
            ) : (
              '-'
            )}
          </Box>
        </Box>
      </>
    ),
    name: 'CONTAINER & BL',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned x-medium',
  },
  vessel: {
    field: (shipment: IDeliveriesShipment) => (
      <>
        <Box className="text-wrapper">
          {shipment.vessel ? <CopyWrapper>{shipment.vessel}</CopyWrapper> : '-'}
        </Box>
        <GenericDateField date={shipment.estimated_arrival} />
      </>
    ),
    name: 'VESSEL & ETA',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned x-medium',
  },
  comment: {
    field: (shipment: IDeliveriesShipment) => (
      <CommentField comment={shipment.comment} />
    ),
    name: 'COMMENT',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  delivery: {
    field: (shipment: IDeliveriesShipment) => (
      <Box
        sx={{ display: 'flex', alignItems: 'center' }}
        data-testid="deliveries-fcl-delivery"
      >
        <Box>
          <ColoredFieldWithMappings
            type="task"
            mapping="delivery"
            value={shipment.inland_transport_status}
          />
          {shipment.inland_transport_status !== 'estimated' && (
            <GenericDateField withTime={true} date={shipment.delivery_date} />
          )}
        </Box>
        <DeliveriesWarningIconTooltip shipment={shipment} />
      </Box>
    ),
    name: 'DELIVERY',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned x-medium',
  },
  truck: {
    field: (shipment: IDeliveriesShipment) =>
      shipment.trucking_task ? (
        <ColoredFieldWithMappings
          type="task"
          mapping="align_delivery_with_transporter"
          value={shipment.trucking_task.task_status_id}
        />
      ) : (
        <ColoredField value="" icon="remove" color="white" />
      ),
    name: 'TRUCK',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned short-50',
  },
  commu: {
    field: (shipment: IDeliveriesShipment) =>
      shipment.communication_task ? (
        <ColoredFieldWithMappings
          type="task"
          mapping="obtain_delivery_date_from_consignee"
          value={shipment.communication_task.task_status_id}
        />
      ) : (
        <ColoredField value="" icon="remove" color="white" />
      ),
    name: 'COMMU.',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned short-50',
  },
  custom: {
    field: (shipment: IDeliveriesShipment) => (
      <CustomsTask shipment={shipment} />
    ),
    name: 'CUSTOM',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  hbl: {
    field: (shipment: IDeliveriesShipment) =>
      shipment.obtain_hbl_task ? (
        <ColoredFieldWithMappings
          type="task"
          mapping="obtain_released_hbl"
          value={shipment.obtain_hbl_task.task_status_id}
        />
      ) : (
        <ColoredField value="" icon="remove" color="white" />
      ),
    name: 'HBL',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned short-50',
  },
  mbl: {
    field: (shipment: IDeliveriesShipment) =>
      shipment.obtain_mbl_task ? (
        <ColoredFieldWithMappings
          type="task"
          mapping="obtain_released_mbl"
          value={shipment.obtain_mbl_task.task_status_id}
        />
      ) : (
        <ColoredField value="" icon="remove" color="white" />
      ),
    name: 'MBL',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned short-50',
  },
  release: {
    field: (shipment: IDeliveriesShipment) =>
      shipment.release_task ? (
        <ColoredFieldWithMappings
          type="task"
          mapping="obtain_carrier/coloader_release"
          value={shipment.release_task.task_status_id}
        />
      ) : (
        <ColoredField value="" icon="remove" color="white" />
      ),
    name: 'RELEASE',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
}

import { Color } from 'src/stories/DashboardSelect'
import { Icon } from '../ColoredField'

export type MappingName = 'hblBookingConfirmed' | 'mblBookingConfirmed' | 'tnt'

export type MappingItem = {
  icon: Icon
  color: Color
  name: string
}

export type MappingObject = {
  [key: string]: MappingItem
}

export type AllMappings = {
  [key in MappingName]: MappingObject
}

const hblBookingConfirmedMapping: MappingObject = {
  released: {
    icon: 'check',
    color: 'green',
    name: 'RELEASED',
  },
  final: {
    icon: 'check',
    color: 'green',
    name: 'FINAL',
  },
  draft: {
    icon: 'check',
    color: 'yellow',
    name: 'DRAFT',
  },
  blank: {
    icon: 'remove',
    color: 'grey',
    name: 'NOT UPLOADED',
  },
}

const mblBookingConfirmedMapping: MappingObject = {
  ...hblBookingConfirmedMapping,
}

const tntMapping: MappingObject = {
  active: {
    icon: 'check',
    color: 'green',
    name: 'ACTIVE',
  },
  not_active: {
    icon: 'remove',
    color: 'blue',
    name: 'NOT ACTIVE',
  },
}

export const mappings: AllMappings = {
  hblBookingConfirmed: hblBookingConfirmedMapping,
  mblBookingConfirmed: mblBookingConfirmedMapping,
  tnt: tntMapping,
}

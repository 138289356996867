import { makeStyles } from '@mui/styles'
import MuiAvatarGroup, { AvatarGroupProps } from '@mui/material/AvatarGroup'
import { ShyppleAvatarProps, Avatar } from './Avatar'

const useStyles = makeStyles((theme) => ({
  small: {
    '& .MuiAvatarGroup-avatar': {
      width: theme.spacing(3),
      height: theme.spacing(3),
      fontSize: 14,
    },
  },
  medium: {
    '& .MuiAvatarGroup-avatar': {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },
  large: {
    '& .MuiAvatarGroup-avatar': {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  },
}))

export interface ShyppleAvatarGroupProps extends AvatarGroupProps {
  avatars: (ShyppleAvatarProps & { id?: string })[]
  /**
   * How many avatars are previewed
   */
  max?: number
  size?: ShyppleAvatarProps['size']
  disableTooltips?: boolean
}

/**
 * AvatarGroup component to show N avatars in a group.
 *
 */
export const AvatarGroup: React.FC<ShyppleAvatarGroupProps> = ({
  avatars,
  size = 'medium',
  disableTooltips,
  ...props
}) => {
  const classes = useStyles()
  return (
    <MuiAvatarGroup {...props} className={classes[size]}>
      {avatars.map((avatar) => (
        <Avatar
          title={!disableTooltips ? avatar.alt : ''}
          key={avatar.id || avatar.alt}
          alt={avatar.alt}
          src={avatar.src || 'no-logo'}
          className={avatar.className}
          size={size}
        />
      ))}
    </MuiAvatarGroup>
  )
}

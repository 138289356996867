import { Typography, TypographyProps } from '@mui/material'

const OverviewTableText: React.FC<{
  text: string | null | undefined
  variant?: TypographyProps['variant']
  'data-testid'?: string
}> = ({ text, variant = 'body1', ...otherProps }) => {
  return (
    <Typography noWrap variant={variant} {...otherProps}>
      {text ?? '-'}
    </Typography>
  )
}

export default OverviewTableText

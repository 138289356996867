const itemHeight = 48
const itemPaddingTop = 8

const DefaultMenuProps = {
  transitionDuration: 0,
  PaperProps: {
    style: {
      maxHeight: itemHeight * 5 + itemPaddingTop,
    },
  },
}

export { itemHeight, itemPaddingTop, DefaultMenuProps }

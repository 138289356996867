import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { CarrierAvatar } from 'src/stories/MUI/CarrierAvatar'
import { SABSearchItemProps } from '../components/SABSearchItem.props'
import SABSearchItemTimeline from '../components/SABSearchItemTimeline'
import SABSearchItemAvatarWithText from '../components/SABSearchItemAvatarWithText'
import SABSearchItemInfo from '../components/SABSearchItemInfo'
import { LEFT_PADDING_1, TOP_MARGIN_1 } from '../components/constants'

const SABSearchAirItem: React.FC<SABSearchItemProps> = (props) => {
  const { quote, searchQuery, currentUser } = props
  const { schedule } = quote
  const { carrier, direct_route: isDirect } = schedule
  const { modality } = searchQuery
  const carrierName = carrier?.name ?? 'Unknown'
  const carrierScac = carrier?.scac ?? '5E'

  return (
    <Paper
      variant="outlined"
      sx={{ pt: 2.5, pr: 2.5, pb: 2.5, pl: 1.5, mt: 2 }}
      data-testid="search-and-book-air-quote"
    >
      <Grid container>
        <Grid item container lg={8} xl={8} xs={12} sm={12} md={6}>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <SABSearchItemTimeline schedule={schedule} modality={modality} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={4}
            mt={TOP_MARGIN_1}
            pl={LEFT_PADDING_1}
          >
            <SABSearchItemAvatarWithText title="Carrier" content={carrierName}>
              <CarrierAvatar
                size={40}
                name={carrierName}
                scac={carrierScac}
                modality="air"
              />
            </SABSearchItemAvatarWithText>

            {isDirect && (
              <Box sx={{ mt: 2 }}>
                <Chip label="Direct flight" color="secondary" />
              </Box>
            )}
          </Grid>
        </Grid>
        <SABSearchItemInfo
          quote={quote}
          searchQuery={searchQuery}
          currentUser={currentUser}
        />
      </Grid>
    </Paper>
  )
}

export default SABSearchAirItem

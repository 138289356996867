import { FunctionComponent, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import InlineNotification from 'src/components/Common/InlineNotification'
import { LocationDescriptorObject } from 'history'
import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux'
import { promisifyAction } from 'src/utils'
import { routes } from 'src/pages/AddressBook'
import { makeStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'
import CircularProgress from '@mui/material/CircularProgress'
import { BreadcrumbItem, Breadcrumbs } from 'src/stories/Breadcrumbs'
import {
  addressesGetOneAddress,
  personalDetailsGetOnePersonalDetail,
} from '../../stores/actionCreators'
import AddressEditPage from './AddressEditPage/index'
import ContactEditPage from './ContactEditPage/index'
import './styles.scss'

const useStyles = makeStyles((theme) => ({
  addressPageBlock: {
    margin: '2px',
    padding: theme.spacing(3),
    '& .MuiFormLabel-root': {
      margin: `${theme.spacing(1)} 0`,
    },
  },
}))

const Loader = () => (
  <Box
    display="flex"
    width="100%"
    justifyContent="center"
    alignItems="center"
    height="100px"
  >
    <CircularProgress />
  </Box>
)

const addressesPageHref = '/contacts/addresses'

const contactsPageHref = '/contacts/persons'

interface IAddressBookEditProps {
  match: {
    params: {
      id?: string
    }
    path: string
  }
  toLocation: (location: LocationDescriptorObject) => void
}

const BreadcrumbNavigation = ({
  current,
  links,
}: {
  current: string
  links: BreadcrumbItem[]
}) => {
  return (
    <Box mb={1}>
      <Breadcrumbs links={links} current={current} />
    </Box>
  )
}

const AddressEditPageContainer = ({
  addressId,
  className,
}: {
  addressId: string
  className: string
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const getOneAddress = promisifyAction(dispatch, addressesGetOneAddress)
  const { t } = useTranslation()

  useEffect(() => {
    getOneAddress(parseInt(addressId)).catch(() => {
      history.push(routes.companies)
    })
  }, [])

  const { address } = useSelector((state: IGlobalState) => ({
    address: state.addresses.singleAddress,
  }))

  const links: BreadcrumbItem[] = [
    {
      href: addressesPageHref,
      label: t('address_book.add_address.main_header', 'Address details'),
    },
    {
      href: addressesPageHref,
      label: t('address_book.addresses.tab_header', 'Addresses'),
    },
  ]
  return (
    <>
      <Box mb={2}>
        <BreadcrumbNavigation current={address?.name ?? ''} links={links} />
      </Box>
      <Paper elevation={0} className={className}>
        {!address && <Loader />}
        {address && <AddressEditPage address={address} />}
      </Paper>
    </>
  )
}

const ContactEditPageContainer = ({
  personId,
  className,
}: {
  personId: string
  className: string
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const getOneAddress = promisifyAction(
    dispatch,
    personalDetailsGetOnePersonalDetail
  )

  useEffect(() => {
    getOneAddress(parseInt(personId)).catch(() => {
      history.push(routes.persons)
    })
  }, [])

  const { personalDetail } = useSelector((state: IGlobalState) => ({
    personalDetail: state.personalDetails.singlePersonalDetail,
  }))

  const links: BreadcrumbItem[] = [
    {
      href: contactsPageHref,
      label: t('address_book.add_address.main_header', 'Address details'),
    },
    {
      href: contactsPageHref,
      label: t('address_book.contacts.tab_header', 'Contacts'),
    },
  ]

  return (
    <>
      <Box mb={2}>
        <BreadcrumbNavigation
          current={personalDetail?.name ?? ''}
          links={links}
        />
      </Box>
      <Paper elevation={0} className={className}>
        {!personalDetail && <Loader />}
        {personalDetail && <ContactEditPage contact={personalDetail} />}
      </Paper>
    </>
  )
}

const AddressBookEdit: FunctionComponent<any> = (
  props: IAddressBookEditProps
) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const { address } = useSelector((state: IGlobalState) => ({
    address: state.addresses.singleAddress,
  }))
  const addressId = props.match.params.id
  const isSameAddress = useMemo(
    () => address && address.id.toString() === addressId,
    [address, addressId]
  )

  const contactType = props.match.path.includes('contacts')
    ? 'contact'
    : 'address'

  const className = classes.addressPageBlock

  const href = contactType === 'address' ? addressesPageHref : contactsPageHref

  const links: BreadcrumbItem[] = [
    {
      href,
      label: t('address_book.add_address.main_header', 'Address details'),
    },
    {
      href,
      label:
        contactType === 'address'
          ? t('address_book.addresses.tab_header', 'Addresses')
          : t('address_book.contacts.tab_header', 'Contacts'),
    },
  ]

  return (
    <>
      {address && isSameAddress && address.default && (
        <Box mb={2}>
          <InlineNotification
            color="info"
            showClose={false}
            show
            action={() => history.push(routes.companies)}
            actionName={t(
              'address_book.connections.go_to_address',
              'Go to address'
            )}
            message={t(
              'address_book.connections.cant_delete',
              'It is currently not possible to delete this contact because it is your default. Please first select another address as your default.'
            )}
          />
        </Box>
      )}
      {!addressId && (
        <>
          <BreadcrumbNavigation current={`Add ${contactType}`} links={links} />
          <Paper elevation={0} className={className}>
            {contactType === 'address' && <AddressEditPage />}
            {contactType === 'contact' && <ContactEditPage />}
          </Paper>
        </>
      )}
      {addressId && (
        <>
          {contactType === 'address' && (
            <AddressEditPageContainer
              addressId={addressId}
              className={className}
            />
          )}
          {contactType === 'contact' && addressId && (
            <ContactEditPageContainer
              personId={addressId}
              className={className}
            />
          )}
        </>
      )}
    </>
  )
}

export default AddressBookEdit

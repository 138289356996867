import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import { Button } from '@mui/material'
import urlJoin from 'url-join'
import { Trans, useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundImage: `url(${urlJoin(
      process.env.BASE_URL || '',
      'assets/404-page-background.png'
    )})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.grey[300],
  },
  typography: {
    ...theme.typography.h3,
    color: 'white',
    fontWeight: 600,
  },
  text: {
    position: 'absolute',
    left: '5%',
    bottom: '15%',
  },
  button: {
    marginTop: '50px',
  },
}))

const NotFoundPage: FunctionComponent<any> = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Box className={classes.text}>
        <Trans
          i18nKey="common.not_found_page.description"
          components={[<div className={classes.typography} />]}
        />
        <Button
          className={classes.button}
          color="secondary"
          children={t('common.not_found_page.button')}
          variant="contained"
          component={Link}
          to="/"
        />
      </Box>
    </Box>
  )
}

export default NotFoundPage

import { find, isEqual, isNil, pick, omit } from 'lodash'
import { parse } from 'query-string'
import { createContext, FC, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { queryClient } from 'src/services/http-common'
import { FormDatePicker } from './Inputs/FormDatePicker'
import { FilterMultipleAutoComplete } from './Inputs/MultipleAutocomplete'
import { FilterMultipleSelect } from './Inputs/MultipleSelect'
import { TextInput } from './Inputs/TextInput'
import {
  FilterContext,
  FilterProps,
  FiltersComposition,
  QuickFilterType,
} from './types'
import {
  filterIsUnchanged,
  getFilterId,
  updateUrl,
  updateUrlOnFilterChange,
} from './utils'
import { Wrapper } from './Wrapper'

const filterContext = createContext<FilterContext | undefined>(undefined)

const Filters: FC<FilterProps> & FiltersComposition = ({
  children,
  onFiltersUpdate,
  initialFilters,
  page,
  open,
  defaultFiltersKeys = [],
  filterOptions,
  isLoading = false,
  filterOptionsQueryKey,
  miniFilterLabels = {},
  ...props
}) => {
  const [filterId, setFilterId] = useState<FilterContext['filterId']>(
    getFilterId(page)
  )
  const [filters, setFilters] = useState(initialFilters)
  const { search } = useLocation()
  const quickFilter: QuickFilterType = find(filterOptions?.saved_filters, {
    id: filterId,
  })
  const optionsLoaded = useRef(false)
  const updateFilters = (
    newFilters: object,
    filterId?: number,
    initial = false
  ) => {
    // to prevent unnecessary re-renders
    if (
      !initial &&
      isEqual(
        omit(filters, defaultFiltersKeys),
        omit(newFilters, defaultFiltersKeys)
      )
    )
      return

    /* IF a filter id didn't exist, update url by injecting all the options to the URL
        otherwise just put the filter id in the URL and delete options from URL (since options 
        are stored in a quick filter, we have access to filter option there, so injecting then 
        to the URL is unnecessary) */
    filterId
      ? updateUrlOnFilterChange(page, filterId, defaultFiltersKeys)
      : updateUrl(page, newFilters)
    setFilters({ ...newFilters })
    onFiltersUpdate(newFilters, initial)
  }

  useEffect(() => {
    if (filterOptions && !isLoading && !optionsLoaded.current) {
      optionsLoaded.current = true

      const quickFilter: QuickFilterType = find(filterOptions?.saved_filters, {
        id: filterId,
      })
      const savedParams = parse(
        window.localStorage.getItem(`${page}_filters_query`) || '',
        { arrayFormat: 'bracket' }
      )
      const searchParams = parse(search, { arrayFormat: 'bracket' })
      // url changes has priority over saved query
      const urlParams = { ...savedParams, ...searchParams }

      /* IF a valid filter id was in the URL and a quick filter existed, put quickFilter and some other options in 
        `filters`(the state of the component) (since each quickFilter contains some options, we should 
        store them in `filters` to pre-populate inputs correctly) */
      if (quickFilter) {
        const defaultFilters = pick(filters, defaultFiltersKeys)
        const { filter: selectedQuickFilter } = quickFilter
        const quickFilterParams = {
          ...defaultFilters,
          ...selectedQuickFilter,
          ...urlParams,
        }
        const { filterIsSameAsQuickFilter } = filterIsUnchanged(
          quickFilterParams,
          quickFilter,
          initialFilters,
          defaultFiltersKeys
        )

        /* if filter is same as quick filter, pass filter id to `updateFilters` to prevent injecting options in the 
          URL(since all the options are stored in quickFilter, adding them to URL is unnecessary) 

        By passing `undefined` to `updateFilters` it will inject all options in the URL. since a filter id doesn't exist 
        to contain filter options and some options should override the default quickFilter in order to pre-populate inputs properly,
        passing `undefined` is necessary.
         */
        updateFilters(
          quickFilterParams,
          filterIsSameAsQuickFilter ? quickFilter.id || undefined : undefined,
          true
        )
      } else {
        const updatedParams = { ...filters, ...urlParams }
        updateFilters(updatedParams, undefined, true)
      }
    }
  }, [filterOptions])

  const onInputChange: FilterContext['onInputChange'] = (keys, value) => {
    if (Array.isArray(keys)) {
      const newFilters = { ...filters }
      keys.forEach((key, index) => (newFilters[key] = value[index]))
      updateFilters(newFilters)
      return
    }

    updateFilters({ ...filters, [keys]: value })
  }

  const resetFilters = () => {
    setFilterId(undefined)
    updateFilters(initialFilters)
  }

  const updateFilterId = (
    id: FilterContext['filterId'],
    newFilter?: QuickFilterType
  ) => {
    if (isNil(id)) {
      resetFilters()
      return
    }

    const filterOptions = queryClient.getQueryData<
      FilterProps['filterOptions']
    >([filterOptionsQueryKey])

    setFilterId(id)
    const defaultFilters = pick(filters, defaultFiltersKeys)
    const quickFilter = find(filterOptions?.saved_filters, { id }) || newFilter

    updateFilters({ ...quickFilter?.filter, ...defaultFilters, filter: id }, id)
  }

  return (
    <filterContext.Provider
      value={{
        miniFilterLabels,
        updateFilters,
        onInputChange,
        resetFilters,
        filters,
        initialFilters,
        page,
        filterId,
        quickFilter,
        setFilterId: updateFilterId,
        isLoading,
        filterOptions,
        defaultFiltersKeys,
        filterOptionsQueryKey,
      }}
    >
      <Wrapper open={open} hasQuickFilters={props.hasQuickFilters} {...props}>
        {children}
      </Wrapper>
    </filterContext.Provider>
  )
}

Filters.TextInput = TextInput
Filters.FormDatePicker = FormDatePicker
Filters.MultipleSelect = FilterMultipleSelect
Filters.MultipleAutocomplete = FilterMultipleAutoComplete

export { Filters, filterContext }

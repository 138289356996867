import { takeEvery, takeLatest } from 'redux-saga/effects'

import * as actions from '../../actions/user'
import * as actionCreators from '../../actionCreators/user'
import {
  simpleGetAndResolve,
  simplePutAndResolve,
  simplePostAndResolve,
  simpleDeleteAndResolve,
  simplePatchAndResolve,
} from '../factories'
import { userSerializer } from '../../../utils/serializers'
import signIn from './signIn'
import signInSuccess from './signInSuccess'
import loadState from './loadState'
import signOut from './signOut'
import changePassword from './changePassword'
import uploadAvatar from './uploadAvatar'
import forgotPassword from './forgotPassword'

const getUserData = simpleGetAndResolve(
  () => '/api/v1/account',
  actionCreators.userGetUserDataSuccess,
  userSerializer
)

const submitUserData = simplePutAndResolve(
  () => '/api/v1/account',
  actionCreators.userGetUserDataSuccess,
  userSerializer
)

const submitProfileData = simplePutAndResolve(
  () => '/api/v1/account/profile',
  actionCreators.userGetUserDataSuccess,
  userSerializer
)

const updateUserData = simplePatchAndResolve(
  () => '/api/v1/account',
  actionCreators.userGetUserDataSuccess,
  userSerializer
)

const updateUserSettingsData = simplePutAndResolve(
  () => '/api/v1/dashboard/update_dashboard_setting',
  actionCreators.userUpdateUserSettingsDataSuccess,
  (response) => response.data
)

const submitNotification = simplePatchAndResolve(
  () => '/api/v1/account/update_notification',
  actionCreators.userDoNothing,
  (response) => response.data
)

const submitMilestone = simplePatchAndResolve(
  () => '/api/v1/account/update_milestone',
  actionCreators.userDoNothing,
  (response) => response.data
)

const updatePassword = simplePatchAndResolve(
  () => '/api/v1/account/change_password',
  actionCreators.userGetUserDataSuccess,
  userSerializer
)

const subscribeToNotifications = simplePostAndResolve(
  () => '/api/v1/account/subscribe',
  actionCreators.userGetUserDataSuccess
)

const unsubscribeToNotifications = simpleDeleteAndResolve(
  () => '/api/v1/account/unsubscribe',
  actionCreators.userGetUserDataSuccess
)

const syncShipmentGroups = simplePostAndResolve(
  () => 'api/v1/account/sync_shipment_groups',
  actionCreators.userDoNothing,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.USER_SIGN_IN, signIn)
  yield takeEvery(actions.USER_LOAD_STATE, loadState)
  yield takeLatest(actions.USER_SIGN_OUT, signOut)
  yield takeEvery(actions.USER_SIGN_IN_SUCCESS, signInSuccess)
  yield takeEvery(actions.USER_GET_USER_DATA, getUserData)
  yield takeEvery(actions.USER_SUBMIT_USER_DATA, submitUserData)
  yield takeEvery(actions.USER_UPDATE_USER_DATA, updateUserData)
  yield takeEvery(
    actions.USER_UPDATE_USER_SETTINGS_DATA,
    updateUserSettingsData
  )
  yield takeEvery(actions.USER_SUBMIT_PROFILE, submitProfileData)
  yield takeEvery(actions.USER_SUBMIT_NOTIFICATION, submitNotification)
  yield takeEvery(actions.USER_SUBMIT_MILESTONE, submitMilestone)
  yield takeEvery(actions.USER_FORGOT_PASSWORD, forgotPassword)
  yield takeEvery(actions.USER_CHANGE_PASSWORD, changePassword)
  yield takeEvery(actions.USER_UPDATE_PASSWORD, updatePassword)
  yield takeEvery(actions.USER_AVATAR_UPLOAD, uploadAvatar)
  yield takeEvery(
    actions.USER_SUBSCRIBE_TO_BROWSER_NOTIFICATIONS,
    subscribeToNotifications
  )
  yield takeEvery(
    actions.USER_UNSUBSCRIBE_TO_BROWSER_NOTIFICATIONS,
    unsubscribeToNotifications
  )
  yield takeEvery(actions.USER_SYNC_SHIPMENT_GROUPS, syncShipmentGroups)
}

import {
  useGetIncoterms,
  useGetContainerTypes,
  useGetPackageTypes,
  useGetOrganizationShipmentRoles,
} from 'src/services/Api/common'
import {
  useGetOrganizationHSCodes,
  useGetOrganizations,
} from 'src/services/Api/organizations'

export const usePageDataFetched = () => {
  const { isFetched: containerTypesFetched } = useGetContainerTypes()

  const { isFetched: packageTypesFetched } = useGetPackageTypes()

  const { isFetched: hsCodesFetched } = useGetOrganizationHSCodes()

  const { isFetched: organizationsFetched } = useGetOrganizations()

  const {
    isFetched: organizationShipmenRolesFetched,
  } = useGetOrganizationShipmentRoles()

  const { isFetched: incotermsFetched } = useGetIncoterms()
  return (
    organizationShipmenRolesFetched &&
    incotermsFetched &&
    containerTypesFetched &&
    packageTypesFetched &&
    hsCodesFetched &&
    organizationsFetched
  )
}

import { uniq, min, isNull } from 'lodash'
import { ModalityEnum, patterns } from 'src/config/constants'

export const getFlightNumberIsValid = (
  flightNumber: ShipmentLegFlightNumber
) => {
  return (
    isNull(flightNumber) ||
    flightNumber.length === 0 ||
    patterns.flightNumber.test(flightNumber)
  )
}

function shipmentInfoValidation(shipmentInfo, isVisibility) {
  const loadingPorts: boolean[] = shipmentInfo.shipment_legs.map(
    (shipmentLeg) => {
      return !!shipmentLeg.loading_port_id || !!shipmentLeg.loading_port
    }
  )

  const dischargePorts: boolean[] = shipmentInfo.shipment_legs.map(
    (shipmentLeg) => {
      return (
        (!!shipmentLeg.discharge_port_id || !!shipmentLeg.discharge_port) &&
        (isVisibility ||
          shipmentLeg.discharge_port_id !== shipmentLeg.loading_port_id)
      )
    }
  )

  const departures: boolean[] = shipmentInfo.shipment_legs.map(
    (shipmentLeg) => {
      return isVisibility || !!shipmentLeg.estimated_departure
    }
  )

  const arrivals: boolean[] = shipmentInfo.shipment_legs.map((shipmentLeg) => {
    return (
      isVisibility ||
      shipmentLeg.estimated_arrival > shipmentLeg.estimated_departure
    )
  })

  const flightNumbers: boolean[] = shipmentInfo.shipment_legs.map(
    (shipmentLeg) => {
      if (shipmentInfo.modality !== ModalityEnum.Air) {
        return true
      }
      return getFlightNumberIsValid(shipmentLeg.voyage_flight_no)
    }
  )

  const updatedLoadingPortsArr = uniq(loadingPorts)
  const updatedDischargePortsArr = uniq(dischargePorts)
  const updatedDeparturesNamesArr = uniq(departures)
  const updatedArrivalsArr = uniq(arrivals)
  const updatedFlightNumberArr = uniq(flightNumbers)

  return (
    updatedLoadingPortsArr.length === 1 &&
    !!updatedLoadingPortsArr[0] &&
    updatedDischargePortsArr.length === 1 &&
    !!updatedDischargePortsArr[0] &&
    updatedDeparturesNamesArr.length === 1 &&
    !!updatedDeparturesNamesArr[0] &&
    updatedArrivalsArr.length === 1 &&
    !!updatedArrivalsArr[0] &&
    updatedFlightNumberArr.length === 1 &&
    !!updatedFlightNumberArr[0]
  )
}

function cargoClosingValidation(shipmentInfo) {
  const datesArr: string[] = shipmentInfo.shipment_legs.map((shipmentLeg) => {
    return shipmentLeg.estimated_departure
  })
  const minDate: string = min(datesArr) || ''
  return shipmentInfo.closing_datetime < minDate
}

export { shipmentInfoValidation, cargoClosingValidation }

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { getFlagEmoji } from 'src/utils/helpers'
import { formatDate } from 'src/utils/helpers/shipmentDate'
import { ModalityEnum } from 'src/config/constants'
import SABSearchItemTimelineModalityIcon from './SABSearchItemTimelineModalityIcon'
import { SABSearchItemTimelineVoyageInfoProps } from './SABSearchItem.props'

const SABSearchItemTimelineVoyageInfo: React.FC<SABSearchItemTimelineVoyageInfoProps> = ({
  port,
  modality,
  date,
  textAlign,
}) => {
  return (
    <Box
      textAlign={textAlign}
      flexDirection="column"
      data-testid="search-and-book-item-voyage-info"
    >
      <SABSearchItemTimelineModalityIcon modality={modality} />
      <Box px={1}>
        <Typography
          variant="body1Strong"
          children={`${port.code} ${getFlagEmoji(port.country.code)}`}
        />
        <Typography children={port.name} textAlign={textAlign} />
        {modality !== ModalityEnum.Sea && (
          <Typography
            variant="body1Strong"
            textAlign={textAlign}
            children={formatDate(date)}
          />
        )}
      </Box>
    </Box>
  )
}

export default SABSearchItemTimelineVoyageInfo

import { FunctionComponent, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import { updateReportsDashboardScope } from 'src/stores/actionCreators'
import ReportsDashboardSorting from '../ReportsDashboardSorting'
import { tabRoutes } from './tabRoutes'
import scopeByRoute from './scopeByRoute'

import './styles.scss'

const ReportsDashboardTabs: FunctionComponent<any> = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [tab, setTab] = useState<IReportsDashboardScope | null>(null)

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: IReportsDashboardScope
  ) => {
    setTab(newValue)
  }

  useEffect(() => {
    setTab(scopeByRoute(location.pathname))
  }, [])

  useEffect(() => {
    if (tab) {
      dispatch(updateReportsDashboardScope(tab))
    }
  }, [tab])

  if (!tab) {
    return null
  }

  return (
    <AppBar elevation={0} position="static" color="transparent">
      <Tabs
        value={tab}
        onChange={handleChange}
        indicatorColor="primary"
        scrollButtons="auto"
        variant="scrollable"
        classes={{
          root: 'tabs-navigation__tab-panel',
        }}
      >
        {tabRoutes.map((route) => (
          <Tab key={route.scope} label={route.title} value={route.scope} />
        ))}
        <ReportsDashboardSorting />
      </Tabs>
    </AppBar>
  )
}

export default ReportsDashboardTabs

// @ts-nocheck
// FIXME

import { call, put } from 'redux-saga/effects'
import { Method } from 'axios'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { retrieveCredentials, resolveAction, rejectAction } from '../wrappers'
import { requester, Logger } from '../../../utils'

export default function* (action: IPromisifiedAction): Iterator<any> {
  const { avatar } = action.payload
  try {
    const credentials: ICredentials | undefined = yield retrieveCredentials()
    const formData = new FormData()
    formData.append('avatar', avatar, 'avatar.png')
    yield call([requester, 'request'], {
      headers: {
        uid: credentials ? credentials!.uid : '',
        'access-token': credentials ? credentials!.accessToken : '',
        client: credentials ? credentials!.clientToken : '',
        'Content-Type': 'multipart/form-data',
      },
      method: 'PATCH' as Method,
      url: '/api/v1/account/avatar',
      data: formData,
    })
    yield resolveAction(action)
  } catch (error) {
    yield Logger.sagaError(error, 'store::user::getUserData')
    yield rejectAction(action, error)
    yield put(showNotification({ message: error, severity: 'error' }))
  }
}

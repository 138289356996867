import { FunctionComponent } from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

interface IProps {
  data: IShipmentContainer
}

const ReeferSettingsOverview: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const renderBlock = (label: string, column: string, value: string) => {
    return (
      <div
        className="settings-block-humidity"
        data-testid={`shipment-container-reefer-${column}`}
      >
        <Typography variant="body1Strong" children={label} />
        {props.data[column] ? (
          <Typography variant="body1" children={value} />
        ) : (
          '-'
        )}
      </div>
    )
  }

  const renderVentsDrains = (label: string, column: string, value: string) => {
    let text: React.ReactNode = ''
    switch (props.data[column]) {
      case null:
        text = t('common.forms.fields.vents.options.unspecified', 'Unspecified')
        break
      case true:
        text = t('common.forms.fields.vents.options.open', 'Open')
        break
      case false:
        text = t('common.forms.fields.vents.options.closed', 'Closed')
        break
    }

    return (
      <div
        className={`settings-block-${column}`}
        data-testid={`shipment-container-reefer-${column}`}
      >
        <Typography variant="body1Strong" children={label} />
        <Typography variant="body2" children={text} />
      </div>
    )
  }

  return (
    <>
      <div className="reefer-settings-block--title">
        <Typography
          variant="h5"
          children={t(
            'common.forms.fields.reefer_settings.label',
            'Reefer Settings'
          )}
        />
      </div>
      <div
        className="reefer-settings-block"
        data-testid="shipment-container-reefer-block"
      >
        <div className="settings-block">
          {renderBlock(
            t('common.forms.fields.temperature.label', 'Temperature'),
            'temperature',
            `${props.data.temperature} C°`
          )}
          {renderBlock(
            t('common.forms.fields.humidity.label', 'Humidity'),
            'humidity',
            `${props.data.humidity}%`
          )}
          {renderBlock(
            t('common.forms.fields.ventilation.label', 'Ventilation'),
            'ventilation',
            `${props.data.ventilation} CBM/HR`
          )}
          {renderVentsDrains(
            t('common.forms.fields.vents.label', 'Vents'),
            'vents_open',
            `${props.data.vents_open}`
          )}
          {renderVentsDrains(
            t('common.forms.fields.drains.label', 'Drains'),
            'drains_open',
            `${props.data.drains_open}`
          )}
          {renderBlock(
            t('common.forms.fields.co2.label', 'CO2'),
            'co2_regulation',
            `${props.data.co2_regulation}%`
          )}
          <div
            className="settings-block-comments"
            data-testid="shipment-container-reefer-comments"
          >
            <Typography
              variant="body1Strong"
              children={t(
                'common.forms.fields.temperature_control_instructions.label',
                'Temperature control instructions'
              )}
            />
            <Typography variant="body1">
              {props.data.comments ? `${props.data.comments}` : '-'}
            </Typography>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReeferSettingsOverview

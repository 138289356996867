import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import {
  StyledBox,
  BorderLinearProgress,
} from './ContainerDemurrageAndDetention.styles'

const ContainerDemurrageAndDetentionEmptyState: React.FC<{
  freightServiceRequested: boolean
}> = ({ freightServiceRequested }) => {
  const { t } = useTranslation()
  return (
    <StyledBox data-testid="container-demurrage-and-detention-empty-state">
      <Typography
        variant="body1Strong"
        children={t(
          'shipment_containers.demurrage_and_detention.empty_state.title',
          'No agreements available'
        )}
      />
      {!freightServiceRequested && (
        <Typography
          children={t(
            'shipment_containers.demurrage_and_detention.empty_state.description',
            'Book your freight with Shypple to get insight into your demurrage and detention costs.'
          )}
        />
      )}
      <Grid container columnSpacing={2} alignItems="center" mt={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <BorderLinearProgress
            value={0}
            className="disabled"
            variant="determinate"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography
            color="grey.400"
            children={t(
              'shipment_containers.demurrage_and_detention.empty_state.not_available',
              'Not available'
            )}
          />
        </Grid>
      </Grid>
    </StyledBox>
  )
}

export default ContainerDemurrageAndDetentionEmptyState

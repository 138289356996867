import { FunctionComponent, useRef, useCallback, useEffect } from 'react'
import { ActionCableConsumer } from 'react-actioncable-provider'
import { useDispatch, useSelector } from 'react-redux'
import { last } from 'lodash'

import { promisifyAction } from '../../../utils'
import { userChannelReceived } from '../../../stores/actionCreators'
import UserChannelConsumerWrapper from './UserChannelConsumerWrapper'

interface IProps {
  userId?: number
}

const UserChannelSubscription: FunctionComponent<IProps> = (props) => {
  const actionCableRef = useRef<any>(null)
  const dispatch = useDispatch()

  const emitReceivedUserChannelMessage = promisifyAction(
    dispatch,
    userChannelReceived
  )

  const getActionCableRef = useCallback((elem: ActionCableConsumer) => {
    actionCableRef.current = elem
  }, [])

  const onReceived = useCallback((receivedMessage: any) => {
    emitReceivedUserChannelMessage(receivedMessage)
  }, [])

  const { userChannelCommands } = useSelector((state: IGlobalState) => ({
    userChannelCommands: state.socketMessages.userChannelCommands,
  }))

  useEffect(() => {
    if (!actionCableRef.current || !userChannelCommands.length) {
      return
    }

    const lastCommand: ISocketCommand = last(
      userChannelCommands
    ) as ISocketCommand
    actionCableRef.current.perform(lastCommand.name, lastCommand.params)
  }, [userChannelCommands])

  if (!props.userId) {
    return null
  }

  return (
    <>
      <UserChannelConsumerWrapper
        forwardRef={getActionCableRef}
        onReceived={onReceived}
      />
    </>
  )
}

export default UserChannelSubscription

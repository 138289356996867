import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const LogoTypeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 88 26">
      <path d="M72.4305 0.719906L69.4841 2.71958V19.3815H72.4305V0.719906Z" />
      <path d="M15.2998 7.87428C16.2044 6.64412 17.6647 5.79837 19.3705 5.79837C22.5883 5.79837 24.7205 7.92554 24.7205 11.1932V19.3815H21.7741V11.4495C21.7741 9.82205 20.5336 8.39967 18.7631 8.39967C16.9281 8.39967 15.2998 9.91175 15.2998 12.0774V19.3815H12.3405V0.672668H15.2998V7.87428Z" />
      <path d="M30.4534 25.3273L37.7935 6.10592H34.7179L31.2158 15.6525L27.7396 6.10592H24.664L29.7297 19.3559L27.3649 25.3273H30.4534Z" />
      <path d="M46.5099 5.79837C50.2834 5.79837 53.0359 8.88661 53.0359 12.7437C53.0359 16.6136 50.2834 19.689 46.5099 19.689C44.5457 19.689 42.9045 18.8817 41.7931 17.5875V25.3273H38.8597V6.10592H41.7931V7.91273C42.9045 6.60567 44.5457 5.79837 46.5099 5.79837ZM45.9284 17.0877C48.2674 17.0877 50.0637 15.1656 50.0637 12.7437C50.0637 10.3346 48.3062 8.39967 45.9284 8.39967C43.5377 8.39967 41.7931 10.3346 41.7931 12.7437C41.7931 15.1656 43.5765 17.0877 45.9284 17.0877Z" />
      <path d="M68.3002 12.7437C68.3002 8.88661 65.5476 5.79837 61.7742 5.79837C59.8099 5.79837 58.1688 6.60567 57.0574 7.91273V6.10592H54.1239V25.3273H57.0574V17.5875C58.1688 18.8817 59.8099 19.689 61.7742 19.689C65.5476 19.689 68.3002 16.6136 68.3002 12.7437ZM65.328 12.7437C65.328 15.1656 63.5317 17.0877 61.1927 17.0877C58.8407 17.0877 57.0574 15.1656 57.0574 12.7437C57.0574 10.3346 58.802 8.39967 61.1927 8.39967C63.5705 8.39967 65.328 10.3346 65.328 12.7437Z" />
      <path d="M76.8993 13.6407H87.8708C87.9483 13.3203 88 12.859 88 12.4874C88 8.80973 85.1311 5.79837 81.0863 5.79837C76.9769 5.79837 73.9013 8.88661 73.9013 12.7437C73.9013 16.6136 76.8864 19.689 81.3448 19.689C84.0914 19.689 85.9244 18.528 87.3831 16.8679L86.5617 16.0534L85.8161 15.3322C84.5109 16.6136 83.1798 17.28 81.306 17.28C78.9411 17.28 77.1836 15.7422 76.8993 13.6407ZM81.0734 8.20746C83.0635 8.20746 84.5625 9.55295 84.9373 11.411H76.9898C77.4679 9.55295 79.1091 8.20746 81.0734 8.20746Z" />
      <path d="M27.7928 6.10834H24.6604L29.5547 19.4063H29.5651L27.3033 25.3273H30.4357L37.7773 6.10834H34.6449L31.1551 15.2439L27.7928 6.10834Z" />
      <path d="M0 16.4811C1.36385 18.2513 3.17865 19.4264 5.42417 19.4264C8.85445 19.4264 10.8796 17.6018 10.8796 15.1644C10.8796 12.7951 9.32284 11.3381 6.03033 10.6845C4.23942 10.3305 3.42662 9.60877 3.42662 8.72369C3.42662 7.8386 4.28075 7.23947 5.4655 7.23947C6.67781 7.23947 7.25901 7.42076 8.21274 8.36345L9.80231 6.78102C8.90876 5.42206 7.35478 4.66591 5.28641 4.66591C2.24187 4.66591 0.313196 6.38162 0.313196 8.68284C0.313196 10.9432 1.84236 12.4547 4.95578 13.0674C6.99466 13.4623 7.76613 14.1023 7.76613 15.1235C7.76613 16.172 6.81557 16.8665 5.25886 16.8665C3.74347 16.8665 2.45005 16.1564 1.43061 15.0671L0 16.4811Z" />
    </SvgIcon>
  )
}

const CalculateIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM13.03 7.06L14.09 6L15.5 7.41L16.91 6L17.97 7.06L16.56 8.47L17.97 9.88L16.91 10.94L15.5 9.54L14.09 10.95L13.03 9.89L14.44 8.48L13.03 7.06ZM6.25 7.72H11.25V9.22H6.25V7.72ZM11.5 16H9.5V18H8V16H6V14.5H8V12.5H9.5V14.5H11.5V16ZM18 17.25H13V15.75H18V17.25ZM18 14.75H13V13.25H18V14.75Z" />
    </SvgIcon>
  )
}

const LCLIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4617 2.02073C11.7408 1.74244 12.1924 1.74244 12.4715 2.02073L13.753 3.29878C13.9935 3.53862 13.9941 3.92802 13.7542 4.16852C13.5144 4.40902 13.125 4.40956 12.8845 4.16972L12.5722 3.85824V5.92893L18.1914 8.93894L18.2314 8.91748L18.2637 8.97767L18.9057 9.32156V16.4197L21.6389 14.969L21.3209 14.8831C20.993 14.7946 20.799 14.457 20.8875 14.1291C20.976 13.8012 21.3136 13.6071 21.6415 13.6957L23.3889 14.1674C23.7694 14.2702 23.995 14.6614 23.8933 15.0422L23.4264 16.7908C23.3388 17.119 23.0017 17.314 22.6736 17.2264C22.3454 17.1387 22.1504 16.8017 22.238 16.4735L22.3728 15.9688L13.6806 20.5821C13.6246 21.4784 12.88 22.188 11.9696 22.188C11.0479 22.188 10.2961 21.4606 10.2569 20.5486L1.62791 15.9688L1.76269 16.4735C1.85032 16.8017 1.65533 17.1387 1.32718 17.2264C0.999018 17.314 0.661958 17.119 0.574331 16.7908L0.107388 15.0422C0.00571072 14.6614 0.231325 14.2702 0.611813 14.1674L2.35918 13.6957C2.68709 13.6071 3.02469 13.8012 3.11322 14.1291C3.20176 14.457 3.0077 14.7946 2.67979 14.8831L2.36179 14.969L5.02185 16.3808V9.32156L5.6629 8.97817L5.69541 8.91748L5.73581 8.93912L11.3422 5.93599V3.87703L11.0487 4.16972C10.8082 4.40956 10.4188 4.40902 10.1789 4.16852C9.93907 3.92802 9.93961 3.53862 10.1801 3.29878L11.4617 2.02073ZM6.25185 10.6109V16.8793L10.7372 19.282C10.9062 19.1072 11.112 18.9683 11.3422 18.8777V13.3376L6.25185 10.6109ZM11.3422 11.9422L7.03826 9.6368L11.3422 7.33135V11.9422ZM12.5722 13.3443V18.8682C12.8105 18.9577 13.0237 19.0988 13.198 19.2778L17.6757 16.8793V10.6105L12.5722 13.3443ZM16.8889 9.63661L12.5722 11.9489V7.32428L16.8889 9.63661Z"
      />
    </SvgIcon>
  )
}

const FCLIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M6.89955 6.29826V17.5455C6.89955 17.8765 7.16791 18.1449 7.49895 18.1449C7.82998 18.1449 8.09834 17.8765 8.09834 17.5455V6.29826C8.09834 5.96723 7.82998 5.69887 7.49895 5.69887C7.16791 5.69887 6.89955 5.96723 6.89955 6.29826Z" />
      <path d="M9.89955 17.5455V6.29826C9.89955 5.96723 10.1679 5.69887 10.4989 5.69887C10.83 5.69887 11.0983 5.96723 11.0983 6.29826V17.5455C11.0983 17.8765 10.83 18.1449 10.4989 18.1449C10.1679 18.1449 9.89955 17.8765 9.89955 17.5455Z" />
      <path d="M12.8996 6.29826V17.5455C12.8996 17.8765 13.1679 18.1449 13.4989 18.1449C13.83 18.1449 14.0983 17.8765 14.0983 17.5455V6.29826C14.0983 5.96723 13.83 5.69887 13.4989 5.69887C13.1679 5.69887 12.8996 5.96723 12.8996 6.29826Z" />
      <path d="M15.8996 17.5455V6.29826C15.8996 5.96723 16.1679 5.69887 16.4989 5.69887C16.83 5.69887 17.0983 5.96723 17.0983 6.29826V17.5455C17.0983 17.8765 16.83 18.1449 16.4989 18.1449C16.1679 18.1449 15.8996 17.8765 15.8996 17.5455Z" />
      <path d="M5.05528 21.0157C4.92382 21.3649 4.58732 21.6149 4.19021 21.6149H1.80895C1.29637 21.6149 0.884766 21.1984 0.884766 20.6895V3.3106C0.884766 2.80171 1.29638 2.38525 1.80895 2.38525H4.19021C4.61547 2.38525 4.97124 2.67192 5.0801 3.0602H18.9191C19.0279 2.67192 19.3837 2.38525 19.809 2.38525H22.1902C22.7028 2.38525 23.1144 2.80171 23.1144 3.3106V20.6895C23.1144 21.1984 22.7028 21.6149 22.1902 21.6149H19.809C19.4118 21.6149 19.0753 21.3649 18.9439 21.0157H5.05528ZM3.88477 20.3853V3.61488H2.1144V20.3853H3.88477ZM5.1144 19.786H18.8848V4.28983H5.1144V19.786ZM21.8848 20.3853V3.61488H20.1144V20.3853H21.8848Z" />
    </SvgIcon>
  )
}

export { CalculateIcon, LogoTypeIcon, LCLIcon, FCLIcon }

import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { useActionButtonIsActive, useFilter } from '../hooks'

export const ClearFiltersButton = () => {
  const { t } = useTranslation()
  const { resetFilters } = useFilter()
  const isEnabled = useActionButtonIsActive('clear')

  return (
    <Button
      variant="text"
      size="large"
      onClick={resetFilters}
      startIcon={<CloseRoundedIcon />}
      disabled={!isEnabled}
      data-testid="clear-filters"
    >
      {t('common.buttons.clear_filters', 'Clear filters')}
    </Button>
  )
}

import { FunctionComponent } from 'react'
import Tooltip from '@mui/material/Tooltip'
import { Color } from 'src/stories/DashboardSelect'
import { isUndefined } from 'lodash'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import iconMapping from '../mappings/icons'
import './styles.scss'

export type Icon =
  | 'check'
  | 'remove'
  | 'time'
  | 'help'
  | 'warning'
  | 'mail'
  | 'chat'
  | 'phone'
  | 'home'

interface IProps {
  icon: Icon
  color?: Color
  value?: string
  shownValue?: string
}
const useStyles = makeStyles((theme) => ({
  iconContainer: {
    '& .MuiSvgIcon-root': {
      width: 14,
      height: 14,
      fontSize: 14,
    },
  },
  iconWithPadding: {
    '& .MuiSvgIcon-root': {
      marginRight: '2px',
      verticalAlign: 'text-top',
    },
  },
}))

const ColoredField: FunctionComponent<IProps> = ({
  value,
  color,
  icon,
  shownValue,
}) => {
  const classes = useStyles()

  const withCaptionClass: string = isUndefined(shownValue) ? '' : 'with-caption'

  const tooltipText = () => (
    <Box
      className={`colored-field--tooltip ${color} ${classes.iconContainer} ${classes.iconWithPadding}`}
    >
      {iconMapping[icon]}
      <span>{value}</span>
    </Box>
  )

  return (
    <Tooltip placement="top" title={tooltipText()}>
      <Box
        className={`colored-field ${color} ${classes.iconContainer} ${withCaptionClass}`}
      >
        {iconMapping[icon]}
        {shownValue ? <span>{shownValue}</span> : ''}
      </Box>
    </Tooltip>
  )
}

export default ColoredField

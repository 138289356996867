import { createReducer, Reducer } from 'redux-create-reducer'
import { AnyAction } from 'redux'
import { ModalityEnum } from 'src/config/constants'
import { findIndex } from 'lodash'
import * as actions from '../actions'

declare global {
  type ShipmentPhase = 'origin' | 'in_transit' | 'destination' | 'quote'

  type ShipmentLegFlightNumber = string | null

  interface IContainerIdentifier {
    id: number
    type: string
    container_number: string | null
  }

  interface ICarbonEmission {
    total_emission_gram: number
    ttw_emission_gram: number
    wtt_emission_gram: number
  }
  interface ITrackTraceSubscription {
    id: number
    tracking_key: string
    tracking_number: string
    status: string
    subscription_status: string
    provider: string
    last_provider_update: string
    error: string | null
    created_at: string
    discharge_port: IPortShort
    loading_port: IPortShort
    carrier: ICarrier
  }

  interface IShipperShort {
    id: number
    name: string
  }

  interface IRoleShort {
    id: number
    role: string
    name: string
  }

  interface IProductGroup {
    id: number
    code: string
    name: string
  }

  interface IShipmentParty {
    organization_id: number
    name: string
    role: string
    logo: string
    organization_role: string
    organization_role_code: OrganizationRole
    organization_role_name: string
    preferred_shipment_role_id?: number | null
    manageable: boolean
    id?: number
    role_code?: string
    flagged_at: string | null
    roles: IRoleShort[]
  }

  interface IReferenceNumber {
    organization_id: number
    organization_name: string
    reference_number: string
  }

  interface IShipmentLegPort {
    id: number
    name: string
    code: string
    time_zone: string
  }
  interface IShipmentLeg {
    voyage_flight_no: string | null
    discharge_port: IShipmentLegPort
    estimated_arrival: string
    estimated_departure: string
    id: number
    loading_port: IShipmentLegPort
    status: string
    total_co2_emission_gram: number
    vessel: string
    element_id?: number
    _destroy?: boolean
  }

  type EstimatedArrivalDelay = null | {
    days: number
    category: 'critical_delay' | 'delay' | 'early_arrival' | 'on_time'
  }

  interface IOverviewShipment {
    initial_estimated_arrival: null | number
    estimated_arrival_delay: EstimatedArrivalDelay
    id: number
    bl_number: string | null
    booking_number: string | null
    closing_datetime: string
    closing_datetime_status: string
    carrier: ICarrier
    carrier_id: number
    carrier_name: string
    carrier_scac: string
    delivery_available_from: string
    operation_type: 'freight' | 'visibility_only' | 'services_only'
    visibility_only: boolean
    destination_demurrage_starting_from: string
    modality:
      | ModalityEnum.Air
      | ModalityEnum.Barge
      | ModalityEnum.Road
      | ModalityEnum.Sea
      | ModalityEnum.Rail

    mbl_freight_payable: string
    shipment_type: string
    shipment_legs: IShipmentLeg[]
    sailing_to_be_announced: boolean
    incoterm: IIncoterm | null
    bookings: IBookingShort[]
    collaborators: IShipmentParty[]
    cargo_volume: number
    cargo_weight: number
    chats: IChatIdentifier[]
    cif_value: string | null
    co2_emissions: ICarbonEmission | null
    container_type: string
    containers: IContainerIdentifier[]
    costs_total_eur: number
    costs_total_usd: number
    dangerous_goods: boolean
    discharge_port: string
    discharge_port_code: string
    discharge_port_id?: number
    estimated_arrival: string
    eta_last_checked_at: string
    estimated_departure: string
    actual_arrival: string
    actual_departure: string
    humanized_status: string
    loading_port: string
    loading_port_code: string
    loading_port_id?: number
    delivery_address_name: string | null
    delivery_requested: boolean
    drawerOpen: boolean
    editModalOpen: boolean
    owner_organization_id: number
    shipment_organizations: IShipmentParty[]
    pickup_address_name: string | null
    pickup_requested: boolean
    shipment_quotations: number
    progress_percent: number
    quote_expiration_at: string | null
    reference_number: string
    reference_numbers: IReferenceNumber[]
    shared_reference: string | null
    status_name: string
    shipment_phase: ShipmentPhase
    status_sequence: number
    status: string
    status_groups: string[]
    supplier_ids: number[]
    title: string
    cargo_chargeable_weight: number
    token: string | null
    token_enabled: boolean | null
    total_quantity: number
    type: string
    valid_information: boolean
    vessel: string | null
    watched: boolean
    watchers_count: number
    load_type: string
    track_trace_status: string
    add_and_remove_bookings: boolean
    hs_codes_requested: boolean
    counters: IShipmentLayoutCounters
    relevant_subscription: ITrackTraceSubscription
    track_trace_subscriptions: ITrackTraceSubscription[]
    has_reefer_containers: boolean
    product_group: IProductGroup | null
    open_milestones: {
      open_milestones_count: number
      overdue_milestones_count: number
    }
    tab_badges: { bookings: boolean; containers: boolean }
    services: {
      pickup: boolean
      export_customs: boolean
      origin_port: boolean
      freight: boolean
      destination_port: boolean
      import_customs: boolean
      delivery: boolean
      insurance: boolean
    }
    trade_direction: string
    temperature_setting: null | string
  }

  interface IPinnedMessage {
    id: number
    author: string
    author_organization_name: string
    content: string
    link: string
    chat_id: number
    approval_text: string | null
    comment_attachments: ICommentAttachment[]
  }
}

export const initialShipmentOverviewState = {
  drawerOpen: false,
  editModalOpen: false,
  track_trace_subscriptions: [] as ITrackTraceSubscription[],
  tab_badges: { bookings: false, containers: false },
  services: {
    pickup: false,
    export_customs: false,
    origin_port: false,
    freight: true,
    destination_port: false,
    import_customs: false,
    delivery: false,
    insurance: false,
  },
} as IOverviewShipment

const receiveShipmentOverview: Reducer<IOverviewShipment, AnyAction> = (
  state,
  action
) => ({
  ...state,
  ...action.payload,
})

const updateMessagesInShipmentOverview: Reducer<
  IOverviewShipment,
  AnyAction
> = (state, action) => {
  const shipmentOverviewData = { ...state }
  const chatType: ChatType = action.payload.data.comment.chat_type
  const field: string =
    chatType === 'private_chat'
      ? 'pinned_messages_private'
      : 'pinned_messages_public'
  shipmentOverviewData[field] = shipmentOverviewData[field].filter(
    (message) => message.id !== action.payload.data.comment.id
  )

  // Need this to fix the tooltips position at the overview page.
  window.dispatchEvent(new Event('resize'))

  return {
    ...state,
    shipmentOverviewData,
  }
}

const receiveFlagResponse: Reducer<IOverviewShipment, AnyAction> = (
  state,
  action
) => {
  const shipment = { ...state }
  shipment.collaborators.map((party: IShipmentParty) => {
    if (party.organization_id === action.payload.organization_id) {
      party.flagged_at = action.payload.flagged_at
    }
    return party
  })

  return shipment
}

const receiveWatchers: Reducer<IOverviewShipment, AnyAction> = (
  state,
  action
) => ({
  ...state,
  ...action.payload,
})

const setDrawer: Reducer<IOverviewShipment, AnyAction> = (state, action) => ({
  ...state,
  drawerOpen: action.payload,
})

const updateTrackAndTrace: Reducer<IOverviewShipment, AnyAction> = (
  state,
  action
) => {
  const track_trace_subscriptions = state.track_trace_subscriptions
  const currentIndex = findIndex(track_trace_subscriptions, {
    id: action.payload.id,
  })
  if (currentIndex >= 0) {
    track_trace_subscriptions[currentIndex] = action.payload
  }

  return {
    ...state,
    track_trace_subscriptions,
  }
}

const pushNewTTSubscription: Reducer<IOverviewShipment, AnyAction> = (
  state,
  action
) => ({
  ...state,
  track_trace_subscriptions: state.track_trace_subscriptions.concat([
    action.payload,
  ]),
})

const getTrackAndTrace: Reducer<IOverviewShipment, AnyAction> = (
  state,
  action
) => ({
  ...state,
  track_trace_subscriptions: action.payload,
})

const setEditModalOpen: Reducer<IOverviewShipment, AnyAction> = (
  state,
  action
) => ({
  ...state,
  editModalOpen: action.payload,
})

const clearState: Reducer<IOverviewShipment, AnyAction> = (state, action) =>
  initialShipmentOverviewState

export default createReducer(initialShipmentOverviewState, {
  [actions.SHIPMENT_OVERVIEW_GET_DATA_SUCCESS]: receiveShipmentOverview,
  [actions.UPDATE_SHIPMENT_REFERENCE_SUCCESS]: receiveShipmentOverview,
  [actions.SHIPMENT_OVERVIEW_UNPIN_MESSAGE_SUCCESS]: updateMessagesInShipmentOverview,
  [actions.SHIPMENT_LAYOUT_ACCEPT_QUOTE_SUCCESS]: receiveShipmentOverview,
  [actions.SHIPMENT_LAYOUT_UPDATE_BOOKING_SUCCESS]: receiveShipmentOverview,
  [actions.SHIPMENTS_TOGGLE_FLAG_SUCCESS]: receiveFlagResponse,
  [actions.SHIPMENTS_TOGGLE_WATCH_SUCCESS]: receiveWatchers,
  [actions.SHIPMENT_OVERVIEW_SET_DRAWER]: setDrawer,
  [actions.TRACK_AND_TRACE_UPDATE_SUCCESS]: updateTrackAndTrace,
  [actions.CLEAR_SHIPMENT_OVERVIEW_STATE]: clearState,
  [actions.TRACK_AND_TRACE_SUBSCRIPTION_CREATE_SUCCESS]: pushNewTTSubscription,
  [actions.TRACK_AND_TRACE_GET_DATA_SUCCESS]: getTrackAndTrace,
  [actions.SET_EDIT_SHIPMENT_MODAL_OPEN]: setEditModalOpen,
})

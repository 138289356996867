import { FunctionComponent, useCallback } from 'react'
import Stack from '@mui/material/Stack'
import { Typography, Box } from '@mui/material'
import { history } from 'src/shyppleStore'
import { CompletionPoint } from 'src/stories/CompletionPoint'
import { convertDateToUniversalFormat } from 'src/utils/helpers/shipmentDate'

interface ITaskRowProps {
  task: ITask
  onClick: () => void
}

const TaskRow: FunctionComponent<ITaskRowProps> = ({ task, onClick }) => {
  const shipmentShortTitle: string = task.title.split('-')[1]
  const dateToShow = convertDateToUniversalFormat(task.due_date)
  const internalOnClick = useCallback(() => {
    const shipmentTab = task.booking_level ? 'bookings' : 'containers'
    history.push(`/shipments/${task.shipment_id}/${shipmentTab}`)
    onClick()
  }, [task])

  return (
    <Stack
      p={2}
      spacing={1}
      direction="row"
      alignItems="start"
      onClick={internalOnClick}
      sx={{ cursor: 'pointer', '&:hover': { backgroundColor: 'grey.50' } }}
    >
      <Box mt={0.25}>
        <CompletionPoint completed={false} />
      </Box>
      <Stack direction="column" justifyContent="flex-start">
        <Typography variant="body1Strong" children={task.name} mb={0.25} />
        <Stack direction="row" spacing={0.5} alignItems="start">
          <Typography
            component="div"
            color="grey.600"
            variant="subtitle1"
            children={`${shipmentShortTitle} - Due ${dateToShow}`}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default TaskRow

import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded'
import MailRoundedIcon from '@mui/icons-material/MailRounded'
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'

export default {
  remove: <RemoveCircleRoundedIcon color="inherit" />,
  help: <HelpRoundedIcon color="inherit" />,
  time: <AccessTimeRoundedIcon color="inherit" />,
  check: <CheckCircleOutlineRoundedIcon color="inherit" />,
  warning: <WarningRoundedIcon color="inherit" />,
  mail: <MailRoundedIcon color="inherit" />,
  chat: <ChatBubbleRoundedIcon color="inherit" />,
  phone: <PhoneRoundedIcon color="inherit" />,
  home: <HomeRoundedIcon color="inherit" />,
}

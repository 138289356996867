import { useCallback, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import Input, {
  MuiInputBase as InputBase,
  NumberFormatCustom,
} from 'src/components/Common/Input/MuiInput'
import {
  Grid,
  Checkbox,
  Typography,
  FormControlLabel,
  SelectChangeEvent,
} from '@mui/material'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import FormLabel from 'src/stories/Lab/FormLabel'
import { IContainerDetails } from './'

type SelectOption = {
  id: string
  label: string
  value: boolean | null
}

const ReeferSettingsInput = (props: {
  value: string
  onChange: (value: string, field: string) => void
  placeholder: string
  label: ReactNode
  name: string
  allowNegative: boolean
  disabled: boolean
}) => {
  return (
    <>
      <FormLabel label={props.label} />
      <InputBase
        {...props}
        name={props.name}
        onChange={props.onChange}
        inputComponent={NumberFormatCustom as any}
        disabled={props.disabled}
        data-testid={props.name}
        inputProps={{
          allowNegative: props.allowNegative,
          isAllowed: (values) => {
            const { formattedValue, floatValue } = values
            if (
              ['humidity', 'co2_regulation', 'temperature'].includes(props.name)
            ) {
              return formattedValue === '' || floatValue <= 100
            }
            return true
          },
        }}
      />
    </>
  )
}

interface IProps {
  containerDetails: IContainerDetails | IBookingContainer
  setContainerDetails: (data) => void
  onCheckboxChange: (e, checked) => void
  sealError: string
}

const ReeferSettings: React.FC<IProps> = ({
  containerDetails,
  setContainerDetails,
  sealError,
  onCheckboxChange,
}) => {
  const { t } = useTranslation()
  const selectOptions: SelectOption[] = [
    {
      id: 'open',
      value: true,
      label: t('common.forms.fields.vents.options.open', 'Open'),
    },
    {
      id: 'close',
      value: false,
      label: t('common.forms.fields.vents.options.closed', 'Closed'),
    },
    {
      id: 'unspecified',
      value: null,
      label: t('common.forms.fields.vents.options.unspecified', 'Unspecified'),
    },
  ]
  const renderInput = useCallback(
    (
      label: string,
      field: string,
      placeholder: string = t('common.enter_value', 'Enter value'),
      disabled: boolean = false,
      error: boolean = false,
      helperText: string = ''
    ): React.ReactNode => {
      return (
        <>
          <FormLabel label={label} />
          <Input
            name={field}
            value={containerDetails[field] || ''}
            onChange={handleSettingsDetailsChange}
            disabled={disabled}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
            data-testid={field}
          />
        </>
      )
    },
    [containerDetails, sealError]
  )

  const renderReeferInput = useCallback(
    (
      label: ReactNode,
      field: string,
      placeholder: string = t('common.enter_value', 'Enter value')
    ): React.ReactNode => {
      return (
        <div className="reefer-settings__input-fields">
          <ReeferSettingsInput
            name={field}
            label={label}
            value={containerDetails[field] || ''}
            onChange={handleSettingsDetailsChange}
            placeholder={placeholder}
            disabled={containerDetails.non_operating_reefer}
            allowNegative={field === 'temperature'}
          />
        </div>
      )
    },
    [containerDetails]
  )

  const renderCheckbox = (name: string, label: string) => {
    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              name={name}
              checked={containerDetails[name]}
              onChange={onCheckboxChange}
            />
          }
          label={label}
        />
      </div>
    )
  }

  const renderCOLabel = () => {
    return (
      <>{t('shipment_containers.reefer_settings.co2', 'CO2 Regulation (%)')}</>
    )
  }

  const onSelect = (name: string, event: SelectChangeEvent<unknown>): void => {
    const { value } = event.target
    const newVentValue = selectOptions.find(
      (selectOption) => selectOption.id === value
    )?.value

    setContainerDetails({
      ...containerDetails,
      [name]: newVentValue,
    })
  }

  const handleSettingsDetailsChange = (value: string, field: string): void => {
    setContainerDetails({
      ...containerDetails,
      [field]: value,
    })
  }

  const ventsValue = selectOptions.find(
    (selectOption) => selectOption.value === containerDetails.vents_open
  )?.id

  const drainsValue = selectOptions.find(
    (selectOption) => selectOption.value === containerDetails.drains_open
  )?.id

  return (
    <div className="edit-container--block" data-testid="reefer-block">
      <Typography
        variant="h5"
        children={t(
          'common.forms.fields.reefer_settings.label',
          'Reefer Settings'
        )}
        className="mb-9"
      />
      {renderCheckbox(
        'non_operating_reefer',
        t(
          'common.forms.fields.non_operating_reefer.label',
          'Non-operating reefer'
        )
      )}
      <Grid container spacing={2}>
        <Grid item xs={3}>
          {renderReeferInput(
            t(
              'shipment_containers.reefer_settings.temperature',
              'Temperature (C°)'
            ),
            'temperature'
          )}
        </Grid>
        <Grid item xs={3}>
          {renderReeferInput(
            t('shipment_containers.reefer_settings.humidity', 'Humidity (%)'),
            'humidity'
          )}
        </Grid>
        <Grid item xs={3}>
          {renderReeferInput(
            t(
              'shipment_containers.reefer_settings.ventilation',
              'Ventilation (CBM/HR)'
            ),
            'ventilation'
          )}
        </Grid>
        <Grid item xs={3} data-testid="shipment-container-reefer-vents">
          <SingleSelect
            value={ventsValue}
            placeholder={t(
              'shipment_containers.reefer_settings.vents',
              'Vents'
            )}
            onChange={(event) => onSelect('vents_open', event)}
            options={selectOptions}
            disabled={containerDetails.non_operating_reefer}
            label={t('shipment_containers.reefer_settings.vents', 'Vents')}
          />
        </Grid>
        <Grid item xs={3} data-testid="shipment-container-reefer-drains">
          <SingleSelect
            value={drainsValue}
            placeholder={t(
              'shipment_containers.reefer_settings.drains',
              'Drains'
            )}
            label={t('shipment_containers.reefer_settings.drains', 'Drains')}
            onChange={(event) => onSelect('drains_open', event)}
            options={selectOptions}
            disabled={containerDetails.non_operating_reefer}
          />
        </Grid>
        <Grid item xs={3}>
          {renderReeferInput(renderCOLabel(), 'co2_regulation')}
        </Grid>
        <Grid item xs={6}>
          {renderInput(
            t('shipment_containers.reefer_settings.comments', 'Comments'),
            'comments',
            t(
              'shipment_containers.reefer_settings.specify_comments',
              'Specify comments'
            ),
            containerDetails.non_operating_reefer
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default ReeferSettings

import { FunctionComponent } from 'react'
import Table from 'src/components/Common/Table'
import TextPlaceholder from 'src/components/Common/TextPlaceholder'

interface IProps {
  index: number
}

const BlankPurchaseOrderRow: FunctionComponent<IProps> = (props) => {
  const { index } = props

  return (
    <Table.StripedTableRow index={index}>
      <Table.StripedTableCell className="short"></Table.StripedTableCell>
      <Table.StripedTableCell className="left-aligned to-plan short"></Table.StripedTableCell>
      <Table.StripedTableCell className="left-aligned">
        <TextPlaceholder size={7} color="light-grey" />
      </Table.StripedTableCell>
      <Table.StripedTableCell className="left-aligned">
        <TextPlaceholder size={7} color="light-grey" />
      </Table.StripedTableCell>
      <Table.StripedTableCell className="left-aligned"></Table.StripedTableCell>
      <Table.StripedTableCell className="left-aligned"></Table.StripedTableCell>
      <Table.StripedTableCell className="increased-margin left-aligned">
        <TextPlaceholder size={7} color="light-grey" />
      </Table.StripedTableCell>
      <Table.StripedTableCell className="left-aligned">
        <TextPlaceholder size={7} color="light-grey" />
      </Table.StripedTableCell>
      <Table.StripedTableCell className="left-aligned"></Table.StripedTableCell>
      <Table.StripedTableCell className="left-aligned">
        <TextPlaceholder size={5} color="light-grey" />
      </Table.StripedTableCell>
      <Table.StripedTableCell className="short left-aligned"></Table.StripedTableCell>
    </Table.StripedTableRow>
  )
}

export default BlankPurchaseOrderRow

import { FunctionComponent, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import MuiLink from '@mui/material/Link'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LoadableContainer from 'src/components/LoadableContainer'
import ProgressWithDays from 'src/components/ProgressWithDays'
import { useTranslation } from 'react-i18next'

import { LinearProgress, Pagination } from '../../../stories'
import { promisifyAction } from '../../../utils'
import { convertEstimatedDate } from '../../../utils/helpers'
import { progressColors } from '../progressColors'
import { CHAT_TYPES } from '../../../config/constants'
import { unreadGetData } from '../../../stores/actionCreators'
import './styles.scss'

export const UNREADS_PER_PAGE = 20

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'inline-block',
    fontWeight: 400,
    width: '100px',
    maxWidth: '100px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  reference: {
    display: 'inline-block',
    fontWeight: 700,
    width: '100px',
    fontSize: '13px',
    maxWidth: '100px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  depature: {
    width: '150px',
  },
  date: {
    fontWeight: 600,
  },
}))

const MessageCenterUnread: FunctionComponent<{}> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [page, setPage] = useState<number>(1)

  const { unread, totalCount, fetchingUnread } = useSelector(
    (state: IGlobalState) => ({
      unread: state.messages.unread || [],
      totalCount: state.messages.totalUnread,
      fetchingUnread: state.messages.fetchingUnread,
    })
  )

  const unreadGetDataAsync = promisifyAction(dispatch, unreadGetData)

  useEffect(() => {
    const fetchDataAsync = async () => {
      await unreadGetDataAsync({
        per_page: UNREADS_PER_PAGE,
        page: page,
      })
    }
    fetchDataAsync()
  }, [page])

  const onChangePage = useCallback(
    (event: React.ChangeEvent<unknown>, page: number) => {
      setPage(page)
    },
    []
  )

  const renderUnread = useCallback((shipment: any) => {
    const group: string[] = progressColors[shipment.status_group] || [
      'grey',
      'grey',
    ]
    const tabLink: string =
      shipment.chats.length === 1
        ? shipment.chats[0].chat_type.replace('_', '-')
        : 'chats'

    return (
      <div className="message-center-unreadItem" key={shipment.id}>
        <MuiLink
          variant="body1"
          sx={{ textDecoration: 'none' }}
          component={Link}
          to={`/shipments/${shipment.id}/${tabLink}`}
        >
          <Grid
            container
            spacing={2}
            ml={1}
            wrap="nowrap"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Grid item>
              <Grid
                container
                spacing={0}
                flexDirection={'column'}
                wrap="nowrap"
              >
                <Grid item classes={{ root: classes.reference }}>
                  {shipment.reference_number}
                </Grid>
                <Grid classes={{ root: classes.title }} item>
                  {shipment.title}
                </Grid>
              </Grid>
            </Grid>
            <Grid item classes={{ root: classes.depature }}>
              <Grid container spacing={0} flexDirection={'column'}>
                <Grid item textAlign="right" classes={{ root: classes.date }}>
                  {convertEstimatedDate(shipment.estimated_departure)}
                </Grid>
                <Grid item textAlign="right" sx={{ fontSize: '13px' }}>
                  {shipment.loading_port}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={4}
              ml={-1}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ProgressWithDays
                departureIcon={
                  shipment.shipment_type === 'air'
                    ? 'plane-landing'
                    : 'container'
                }
                destinationIcon={
                  shipment.shipment_type === 'air'
                    ? 'plane-lift-off'
                    : 'container'
                }
                primaryColor={group[0]}
                secondaryColor={group[1]}
                progress={shipment.progress_percent}
              />
            </Grid>
            <Grid item ml={-1} classes={{ root: classes.depature }}>
              <Grid container spacing={0} flexDirection={'column'}>
                <Grid item textAlign="left" classes={{ root: classes.date }}>
                  {convertEstimatedDate(shipment.estimated_arrival)}
                </Grid>
                <Grid item textAlign="left" sx={{ fontSize: '13px' }}>
                  {shipment.discharge_port}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={0} flexDirection={'column'}>
                <Grid item>
                  <div className="message-center-unreadItem-divider-line" />
                </Grid>
                <Grid item sx={{ marginLeft: '-13px' }}>
                  <InfoOutlinedIcon
                    sx={{
                      display: 'flex',
                      width: '19px !important',
                      height: '18px !important',
                      marginBottom: '-2px !important',
                    }}
                    color="disabled"
                  />
                </Grid>
                <Grid item>
                  <div className="message-center-unreadItem-divider-line" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={1} flexDirection={'column'}>
                {shipment.chats.map((chat) => (
                  <Grid item key={chat.chat_type}>
                    <Grid container spacing={1}>
                      <Grid item>
                        {CHAT_TYPES[chat.chat_type]}:{' '}
                        {chat.unread_messages_count}
                      </Grid>
                      <Grid item>
                        Last:{' '}
                        <span style={{ color: chat.organization.color }}>
                          {chat.last_author_name} |{' '}
                          <b> {chat.organization.name} </b>
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            {shipment.flagged && (
              <Grid item>
                <InfoOutlinedIcon fontSize="small" color="error" />
              </Grid>
            )}
          </Grid>
        </MuiLink>
      </div>
    )
  }, [])

  return (
    <>
      <LoadableContainer
        loading={fetchingUnread}
        placeholder={<LinearProgress />}
      >
        <div className="message-center-unread">
          {!!unread.length ? (
            unread.map(renderUnread)
          ) : (
            <div className="message-center-emptyTab">
              <i className="iconbig-check" />
              {t('message_center.unread_messages.empty_placeholder')}
            </div>
          )}
        </div>
      </LoadableContainer>
      {totalCount ? (
        <Pagination
          page={page}
          sx={{ py: 1 }}
          color="primary"
          totalItems={totalCount}
          onChange={onChangePage}
          itemsPerPage={UNREADS_PER_PAGE}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default MessageCenterUnread

import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { Skeleton } from '@mui/material'
import { SearchContainerInputTemplate } from './SearchContainerInputTemplate'

export const SearchContainerLoadingSkeleton = () => {
  return (
    <Paper
      elevation={0}
      sx={{
        mb: 2,
        p: 4,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {SearchContainerInputTemplate(180)}
        {SearchContainerInputTemplate(180)}
        {SearchContainerInputTemplate(240)}
        {SearchContainerInputTemplate(240)}
        {SearchContainerInputTemplate(240)}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 6,
        }}
      >
        {SearchContainerInputTemplate(180)}
        {SearchContainerInputTemplate(150)}
        <Skeleton animation="wave" variant="text" width={700} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 6,
        }}
      >
        {SearchContainerInputTemplate(130)}
        {SearchContainerInputTemplate(300)}
        <Skeleton animation="wave" variant="text" width={700} />
      </Box>
    </Paper>
  )
}

import { FunctionComponent } from 'react'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import CopyWrapper from 'src/stories/CopyWrapper'
import CircleIcon from '@mui/icons-material/FilterTiltShift'
import GridOff from '@mui/icons-material/GridOff'
import LocalFireDepartment from '@mui/icons-material/LocalFireDepartment'
import Tooltip from '@mui/material/Tooltip'
import { getIndicators } from 'src/stores/selectors/cargoIndicators'
import { LoadTypeEnum } from 'src/config/constants'

const ChipWithTooltip = ({ title, icon }) => (
  <Tooltip title={title} placement="top" data-testid="chip">
    <Chip
      sx={{
        marginLeft: 2,
        paddingLeft: 1.5,
        width: 35,
      }}
      icon={icon}
      color="warning"
      size="small"
    />
  </Tooltip>
)

const ShipmentField: FunctionComponent<{
  shipment: IReportsShipment
  showSpot?: boolean
  showNonStackable?: boolean
}> = ({ shipment, showSpot = true, showNonStackable = true }) => {
  const { name } = shipment.owner_organization
  const { isSpot, isNonStackable, isDangerous } = getIndicators(shipment)

  const showNonStackableChip =
    showNonStackable &&
    isNonStackable &&
    shipment.load_type === LoadTypeEnum.lcl

  return (
    <Box>
      <Box>
        {shipment.operation_type === 'services_only' &&
          ['lcl_import_orders', 'fcl_import_orders'].includes(
            shipment.service_department.code
          ) && (
            <Chip
              label="NEW"
              size="small"
              color="success"
              className="mt-5 mr-5"
            />
          )}
        <CopyWrapper>
          <Link
            variant="body1"
            href={shipment.cortana_shipment_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {shipment.our_reference}
          </Link>
        </CopyWrapper>
        {showSpot && isSpot && (
          <Chip
            data-testid="spot-chip"
            sx={{
              marginLeft: 2,
            }}
            color="info"
            size="small"
            icon={<CircleIcon />}
            title="Spot"
            label="Spot"
          />
        )}
        {showNonStackableChip && (
          <ChipWithTooltip title="Non-stackable" icon={<GridOff />} />
        )}
        {isDangerous && (
          <ChipWithTooltip title="Dangerous" icon={<LocalFireDepartment />} />
        )}
      </Box>
      <Box>
        <Typography children={name} />
      </Box>
    </Box>
  )
}

export default ShipmentField

import axios from 'axios'
import { LOCAL_STORAGE_CREDENTIAL_KEY } from 'src/config/constants'
import { shyppleGa } from 'src/utils'
import { handle401ServerError } from 'src/services/utils'
import { redirectToLogin } from 'src/utils/navigation'

const instance = axios.create({
  timeout: 90000,
  baseURL: window.shyppleConfig.apiUrl,
})

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    handle401ServerError(error)
    // session expired or user logged out in another tab, browser
    if (
      error &&
      error.response &&
      error.response.status === 401 &&
      error.config.url !== 'api/auth/sign_in'
    ) {
      localStorage.removeItem(LOCAL_STORAGE_CREDENTIAL_KEY)
      localStorage.removeItem('scopedOrganization')
      shyppleGa.clearUserData()
      document.cookie = 'jwt= ; expires = Thu, 01 Jan 1970 00:00:00 GMT'
      redirectToLogin()
    }
    return Promise.reject(error)
  }
)

export default instance

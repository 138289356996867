import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/hsCodes'
import * as actionCreators from '../../actionCreators/hsCodes'
import {
  simpleGetAndResolve,
  simplePatchAndResolve,
  simplePostAndResolve,
  simpleDeleteAndResolve,
} from '../factories'

const searchHsCodes = simpleGetAndResolve(
  (action) => '/api/v1/hs_codes',
  actionCreators.searchHsCodesSuccess,
  (response) => response.data
)

const addHsCode = simplePostAndResolve(
  (action) => `/api/v1/containers/${action.containerId}/container_hs_codes/`,
  actionCreators.addHsCodeSuccess,
  (response) => response.data
)

const editHsCode = simplePatchAndResolve(
  (action) =>
    `/api/v1/containers/${action.containerId}/container_hs_codes/${action.hsCodeId}`,
  actionCreators.editHsCodeSuccess,
  (response) => response.data
)

const deleteHsCode = simpleDeleteAndResolve(
  (action) =>
    `/api/v1/containers/${action.containerId}/container_hs_codes/${action.hsCodeId}`,
  actionCreators.deleteHsCodeSuccess,
  (response) => response.data
)

const getHsCodes = simpleGetAndResolve(
  (action) => '/api/v1/common/hs_codes',
  actionCreators.hsCodesGetDataSuccess,
  (response) => response.data
)

const getOrganizationHsCodes = simpleGetAndResolve(
  (action) => '/api/v1/organizations/hs_codes',
  actionCreators.organizationHsCodesGetSuccess,
  (response) => response.data
)

const getRelevantHSCodes = simpleGetAndResolve(
  (action) => `/api/v1/containers/${action.containerId}/relevant_hs_codes`,
  actionCreators.relevantHsCodesGetDataSuccess,
  (response) => response.data
)

const containerHsCodesSubmitData = simplePatchAndResolve(
  (action) => `/api/v1/containers/${action.containerId}/hs_codes`,
  actionCreators.containerhsCodesSubmitDataSuccess,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.SEARCH_HS_CODES, searchHsCodes)
  yield takeEvery(actions.ADD_HS_CODE, addHsCode)
  yield takeEvery(actions.EDIT_HS_CODE, editHsCode)
  yield takeEvery(actions.DELETE_HS_CODE, deleteHsCode)
  yield takeEvery(actions.ORGANIZATION_HS_CODES_GET, getOrganizationHsCodes)
  yield takeEvery(actions.HS_CODES_GET_DATA, getHsCodes)
  yield takeEvery(actions.RELEVANT_HS_CODES_GET_DATA, getRelevantHSCodes)
  yield takeEvery(
    actions.SUBMIT_CONTAINER_HS_CODES_DATA,
    containerHsCodesSubmitData
  )
}

import { takeLatest } from 'redux-saga/effects'
import * as actions from '../../actions/csat'
import * as actionCreators from '../../actionCreators/csat'
import { simplePostAndResolve, simplePatchAndResolve } from '../factories'

const saveRating = simplePostAndResolve(
  () => 'api/v1/csat_scores',
  actionCreators.saveRatingSuccess,
  (response) => response.data
)

const saveComments = simplePatchAndResolve(
  (action) => `api/v1/csat_scores/${action.payload.id}`,
  actionCreators.saveCommentSuccess,
  (response) => response.data
)

const saveCommentByToken = simplePatchAndResolve(
  (action) => `api/v1/csat_scores/${action.payload.token}/update_by_token`,
  actionCreators.saveCommentSuccess,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeLatest(actions.CSAT_SAVE_RATING, saveRating)
  yield takeLatest(actions.CSAT_SAVE_COMMENT, saveComments)
  yield takeLatest(actions.CSAT_SAVE_COMMENT_BY_TOKEN, saveCommentByToken)
}

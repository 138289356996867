import Grid from '@mui/material/Grid'
import ContentTable from './ContentTable'

const Content = () => {
  return (
    <Grid container spacing={0} height="100%">
      <Grid container item xs display="flex">
        <ContentTable />
      </Grid>
    </Grid>
  )
}

export default Content

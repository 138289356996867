const error = {
  50: '#FFEBEE',
  100: '#FFCCD2',
  200: '#F69898',
  300: '#EE6F70',
  400: '#F94C4B',
  500: '#FF3A2E',
  600: '#F02C2E',
  700: '#DE2028',
  800: '#D11521',
  900: '#C30012',
}

export default error

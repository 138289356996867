export const SHIPMENT_COMMENTS_FETCH = 'SHIPMENT_COMMENTS_FETCH'
export const SHIPMENT_COMMENTS_FETCH_SUCCESS = 'SHIPMENT_COMMENTS_FETCH_SUCCESS'
export const SHIPMENT_COMMENTS_CREATE = 'SHIPMENT_COMMENTS_CREATE'
export const SHIPMENT_COMMENTS_CREATE_SUCCESS =
  'SHIPMENT_COMMENTS_CREATE_SUCCESS'
export const SHIPMENT_COMMENTS_DESTROY = 'SHIPMENT_COMMENTS_DESTROY'
export const SHIPMENT_COMMENTS_DESTROY_SUCCESS =
  'SHIPMENT_COMMENTS_DESTROY_SUCCESS'

export const SHIPMENT_COMMENTS_CLEAR = 'SHIPMENT_COMMENTS_CLEAR'

import { FilterList, SearchOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { StyledPlaceholderChip } from 'src/components/Overview/styled'

const CategoryAutocompletePlaceholder = () => {
  const { t } = useTranslation()

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={0.75}
      data-testid="category-autocomplete-placeholder"
      sx={{ position: 'absolute', left: 0, top: 0, pl: 0.25 }}
    >
      <Typography
        variant="body2"
        component="div"
        children={t('vessels_page.search_placeholder')}
        color="grey.600"
      />
      <StyledPlaceholderChip
        icon={<SearchOutlined />}
        label={t('vessels_page.searching')}
        variant="outlined"
        color="default"
      />
      <Typography
        variant="body2"
        component="div"
        children={t('vessels_page.or')}
        color="grey.600"
      />
      <StyledPlaceholderChip
        icon={<FilterList />}
        label={t('vessels_page.filtering')}
        variant="outlined"
        color="default"
      />
    </Stack>
  )
}

export default CategoryAutocompletePlaceholder

import Services from 'src/stories/Services'
import { ModalityEnum } from 'src/config/constants'
import { useFormContext } from '../../FormContext'

const ServicesContextWrapper = (props) => {
  const { formState, onChange } = useFormContext()
  const {
    testId = 'template-form-services',
    modality = ModalityEnum.Sea,
    disabledFreight = false,
    fieldPath,
  } = props

  const handleChange = (updatedSelectedServices) => {
    onChange({ [fieldPath]: updatedSelectedServices })
  }

  return (
    <Services
      onChange={handleChange}
      modality={modality}
      initialSelectedServices={formState.services}
      childDataTestId={testId}
      disabledFreight={disabledFreight}
    />
  )
}

export default ServicesContextWrapper

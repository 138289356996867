import { AnyAction } from 'redux'
import * as actions from '../actions/personalDetails'

export const personalDetailsGetData = (
  payload?: IPersonalDetail[]
): AnyAction => ({
  type: actions.PERSONAL_DETAILS_GET_DATA,
  payload,
})

export const personalDetailsGetDataSuccess = (
  payload: IPersonalDetail[]
): AnyAction => ({
  type: actions.PERSONAL_DETAILS_GET_DATA_SUCCESS,
  payload,
})

export const personalDetailsGetOnePersonalDetail = (id: number): AnyAction => ({
  type: actions.PERSONAL_DETAILS_GET_ONE_PERSONAL_DETAIL,
  id,
})

export const personalDetailsPutPersonalDetail = (
  id: number,
  payload: IPersonalDetail
): AnyAction => ({
  type: actions.PERSONAL_DETAILS_PUT_PERSONAL_DETAIL,
  id,
  payload,
})

export const personalDetailsGetOnePersonalDetailSuccess = (
  payload: IPersonalDetail
): AnyAction => ({
  type: actions.PERSONAL_DETAILS_GET_ONE_PERSONAL_DETAIL_SUCCESS,
  payload,
})

export const personalDetailsPutPersonalDetailSuccess = (
  payload: IPersonalDetail
): AnyAction => ({
  type: actions.PERSONAL_DETAILS_PUT_PERSONAL_DETAIL_SUCCESS,
  payload,
})

export const personalDetailsAddPersonalDetail = (
  payload: IPersonalDetail
): AnyAction => ({
  type: actions.PERSONAL_DETAILS_ADD_PERSONAL_DETAIL,
  payload,
})

export const personalDetailsAddPersonalDetailSuccess = (
  payload: IPersonalDetail
): AnyAction => ({
  type: actions.PERSONAL_DETAILS_ADD_PERSONAL_DETAIL_SUCCESS,
  payload,
})

export const personalDetailsDeletePersonalDetail = (
  id: string | number
): AnyAction => ({
  type: actions.PERSONAL_DETAILS_DELETE_PERSONAL_DETAIL,
  id,
})

export const personalDetailsDeletePersonalDetailSuccess = (
  payload: IPersonalDetail
): AnyAction => ({
  type: actions.PERSONAL_DETAILS_DELETE_PERSONAL_DETAIL_SUCCESS,
  payload,
})

import { Popover } from '@mui/material'
import { useSelector, shallowEqual } from 'react-redux'
import { useState } from 'react'
import NotificationsButton from 'src/components/Notifications/NotificationsButton'
import { popupProps } from 'src/components/TopNavigation/TopNavigation.constants'
import NotificationsContent from 'src/components/Notifications/NotificationsContent'

const Notifications = () => {
  const { list } = useSelector(
    (state: IGlobalState) => ({
      list: state.navigationNotifications.list,
    }),
    shallowEqual
  )

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const isOpen = Boolean(anchorEl)

  const toggleNotificationPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget as HTMLElement)
  }

  const onClose = () => setAnchorEl(null)

  return (
    <>
      <NotificationsButton onClick={toggleNotificationPopover} />
      {isOpen && (
        <Popover open anchorEl={anchorEl} onClose={onClose} {...popupProps}>
          <NotificationsContent list={list} onClickNotification={onClose} />
        </Popover>
      )}
    </>
  )
}

export default Notifications

import { useGetOrganizationHSCodes } from 'src/services/Api/organizations'
import SingleSelect from '../FormElements/SingleSelect'

const HsCode = (props) => {
  const { fieldPath, label, required = false } = props

  const { data: hsCodes } = useGetOrganizationHSCodes({
    refetchOnMount: false,
  })

  const options =
    hsCodes?.map((hsCode) => ({
      label: `${hsCode.goods_code} - ${hsCode?.description}}`,
      id: hsCode.goods_code,
    })) ?? []

  return (
    <SingleSelect
      label={label}
      options={options}
      required={required}
      fieldPath={fieldPath}
      MenuProps={{ sx: { maxWidth: 200 } }}
      mandatory={false}
      SelectDisplayProps={{
        'data-testid': 'shipment-template-hs-code',
      }}
    />
  )
}

export default HsCode

import { createElement } from 'react'
import * as Fields from './index'

export const getComponent = (
  componentName?: string,
  props: Record<string, unknown> = {}
) => {
  return componentName
    ? createElement(Fields[componentName], props)
    : createElement(Fields.DefaultField, props)
}

import { find, isEqual } from 'lodash'
import {
  setLocalStorage,
  getLocalStorage,
} from 'src/components/Common/Table/DataTable/TableWrapper.utils'
import { FilterOptionValue } from './types'

export interface Filter {
  key: string
  text: string
  label: string
  data: string | number[] | null | FilterOptionValue[]
}

export interface SavedFilterProps extends Filter {
  timestamp: number
}

interface IRecentSearchesConfig {
  limit?: number
}

export interface IRecentFiltersManager {
  getRecentFilters: () => SavedFilterProps[]
  addRecentFilter: (filter: Filter) => void
}

type Filters = SavedFilterProps[]

class RecentFiltersManager implements IRecentFiltersManager {
  private readonly storageKey = 'overviewRecentFilters'
  private readonly LIMIT: number

  constructor(config: IRecentSearchesConfig = {}) {
    this.LIMIT = config?.limit ?? 10
  }

  private getRecentFiltersFromLocalStorage(): Filters {
    return getLocalStorage(this.storageKey, [])
  }

  private saveRecentFiltersToLocalStorage(filters: Filters): void {
    setLocalStorage(this.storageKey, filters)
  }

  addRecentFilter(filter: Filter): void {
    const recentFilters = this.getRecentFiltersFromLocalStorage()

    const existingFilter = find(
      recentFilters,
      (recentFilter) =>
        isEqual(recentFilter.data, filter.data) &&
        recentFilter.key === filter.key
    )

    if (existingFilter) {
      return
    }

    recentFilters.unshift({ ...filter, timestamp: Date.now() })

    const finalRecentFilters = recentFilters.slice(0, this.LIMIT)

    this.saveRecentFiltersToLocalStorage(finalRecentFilters)
  }

  getRecentFilters(): SavedFilterProps[] {
    return this.getRecentFiltersFromLocalStorage()
  }
}

export default RecentFiltersManager

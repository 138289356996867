import { forwardRef, MouseEvent, RefObject } from 'react'
import {
  Alert as MuiAlert,
  AlertColor,
  AlertProps,
  AlertTitle,
  Box,
  IconButton,
  Link,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

export interface IAlertProps extends AlertProps {
  color: AlertColor | 'secondary'
  message: string
  title?: string
  actionHandler?: ((event: MouseEvent) => void) | undefined
  actionText?: string
  showClose?: boolean
  handleClose?: () => void
  icon?: React.ReactNode
}

export const Alert = forwardRef(
  (
    props: IAlertProps,
    ref:
      | ((instance: HTMLDivElement | null) => void)
      | RefObject<HTMLDivElement>
      | null
      | undefined
  ) => {
    const {
      color,
      message,
      title,
      actionHandler,
      actionText,
      showClose,
      handleClose,
      icon,
      ...other
    } = props

    const noActions: boolean = !actionHandler && !showClose && !handleClose

    const actionBlock: JSX.Element | undefined = (
      <Box ml={1} sx={{ display: 'flex', alignItems: 'center' }}>
        <Link
          color="inherit"
          onClick={actionHandler}
          data-testid="alert-action"
        >
          {actionText && actionText}
        </Link>

        {(showClose || handleClose) && (
          <IconButton
            aria-label="close"
            size="medium"
            color="inherit"
            onClick={handleClose && handleClose}
          >
            <CloseIcon fontSize="inherit" color="inherit" />
          </IconButton>
        )}
      </Box>
    )

    return (
      <MuiAlert
        variant="filled"
        color={color}
        severity={color === 'secondary' ? 'info' : color}
        action={noActions ? undefined : actionBlock}
        {...other}
        ref={ref}
        icon={icon}
      >
        <Typography variant="body2" color="inherit" component="div">
          {title && <AlertTitle color="inherit">{title}</AlertTitle>}
          {message}
        </Typography>
      </MuiAlert>
    )
  }
)

import { FunctionComponent } from 'react'
import useWindowFocus from '../../../hooks/useWindowFocus'

// Destroy this component once Chat will be rewritten to FunctionComponent.
// Usage: if you need to track window activity at the current screen, include this
// component.
const TrackWindowFocus: FunctionComponent<{}> = () => {
  useWindowFocus()
  return <></>
}

export default TrackWindowFocus

import { FunctionComponent, useState } from 'react'
import { useDispatch } from 'react-redux'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import { withStyles } from '@mui/styles'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { CircularProgress } from 'src/stories'
import ExportShipmentsButton from 'src/components/ExportShipmentsButton'
import UserChannelClient from 'src/components/SocketHandlers/UserChannelClient'
import CheckIcon from '@mui/icons-material/Check'
import SaveAltIcon from '@mui/icons-material/SaveAlt'

import './styles.scss'

import {
  setExportFile,
  userChannelClear,
  shipmentsGetMetaData,
} from 'src/stores/actionCreators'

interface IProps {
  bodyType: string
  buttonText: string
  getData?: () => void
}

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    width: 320,
    maxWidth: 320,
    padding: 0,
    marginTop: 5,
  },
  popper: {
    opacity: 1,
    zIndex: 0,
  },
}))(Tooltip)

const PopupButton: FunctionComponent<IProps> = (props) => {
  const [isWindowOpen, setIsWindowOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isCompleted, setIsCompleted] = useState<boolean>(false)
  const dispatch = useDispatch()

  const click = (): void => {
    setIsWindowOpen(!isWindowOpen)
  }

  const changeButtonStatus = (status: string) => {
    switch (status) {
      case 'in_progress':
        setIsCompleted(false)
        setIsLoading(true)
        return
      case 'completed':
        setIsLoading(false)
        setIsCompleted(true)
        return
      default:
        setIsLoading(false)
        setIsCompleted(false)
    }
  }

  const handleReceivedNotifications = (response: any) => {
    if (response.message_type === 'shipment_export_completed') {
      dispatch(setExportFile(response.message))
      dispatch(
        showNotification({
          message: 'Shipments export file is ready to be downloaded',
          severity: 'success',
        })
      )
      dispatch(shipmentsGetMetaData())
      dispatch(userChannelClear())
      setIsLoading(false)
      setIsCompleted(true)
      setTimeout(() => {
        window.location = response.message.file.url
      }, 1000)
    }
  }

  const closeWindow = () => {
    setIsWindowOpen(false)
  }

  return (
    <>
      <UserChannelClient onReceived={handleReceivedNotifications} />
      <LightTooltip
        title={
          <ExportShipmentsButton
            changeButtonStatus={changeButtonStatus}
            close={closeWindow}
          />
        }
        placement={'bottom-end'}
        open={isWindowOpen}
        disableHoverListener={true}
      >
        <Button
          className={`${isWindowOpen ? 'active' : ''}`}
          variant="outlined"
          onClick={click}
          data-testid={props['data-testid'] || 'popup-button'}
          sx={{ minWidth: '136px' }}
        >
          {isLoading && !isCompleted && (
            <CircularProgress
              variant="indeterminate"
              classes={{
                root: 'popup-button--element__root',
                svg: 'popup-button--element__spinner mui-override',
              }}
            />
          )}
          {!isLoading && isCompleted && <CheckIcon sx={{ mr: 1 }} />}
          {!isLoading && !isCompleted && <SaveAltIcon sx={{ mr: 1 }} />}
          {props.buttonText}
        </Button>
      </LightTooltip>
    </>
  )
}

export default PopupButton

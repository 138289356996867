// @ts-nocheck
// FIXME

import { FunctionComponent, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Input from 'src/components/Common/Input'
import { updateReportsDashboardFilters } from 'src/stores/actionCreators'

import './styles.scss'

interface ISelectSortOption {
  name: string
  value: ISortOption
  icon: string
}

const initialSortingOptions: ISelectSortOption[] = [
  { name: 'ETA', value: 'eta_asc', icon: 'sort-arrow' },
  { name: 'ETA', value: 'eta_desc', icon: 'sort-arrow down' },
  { name: 'ETD', value: 'etd_asc', icon: 'sort-arrow' },
  { name: 'ETD', value: 'etd_desc', icon: 'sort-arrow down' },
  { name: 'Newest first', value: 'newest_first', icon: '' },
]

const deliveryHandoverSortingOptions: ISelectSortOption[] = [
  { name: 'Delivery date', value: 'delivery_asc', icon: 'sort-arrow' },
  { name: 'Delivery date', value: 'delivery_desc', icon: 'sort-arrow down' },
]

const deliveryLCLSortingOptions: ISelectSortOption[] = [
  {
    name: 'Devanning date',
    value: 'devanning_asc',
    icon: 'sort-arrow up',
  },
  {
    name: 'Devanning date',
    value: 'devanning_desc',
    icon: 'sort-arrow down',
  },
]

const crdSortingOptions: ISelectSortOption[] = [
  { name: 'CRD', value: 'crd_desc', icon: 'sort-arrow down' },
  { name: 'CRD', value: 'crd_asc', icon: 'sort-arrow' },
]

let sortingOptions: ISelectSortOption[] = []

const ReportsDashboardSorting: FunctionComponent<{}> = () => {
  const dispatch = useDispatch()

  const onChangeSort = useCallback((orderBy: ISortOption) => {
    dispatch(updateReportsDashboardFilters({ order_by: orderBy }))
  }, [])

  const { orderBy, scope } = useSelector((state: IGlobalState) => ({
    orderBy: state.reportsDashboard.filters.order_by,
    scope: state.reportsDashboard.scope,
  }))

  useEffect(() => {
    if (!scope) {
      return
    }

    if (['handover', 'deliveries_lcl', 'deliveries_fcl'].includes(scope)) {
      sortingOptions = initialSortingOptions.concat(
        deliveryHandoverSortingOptions
      )
    } else if (scope === 'schedule_tba') {
      sortingOptions = initialSortingOptions.concat(crdSortingOptions)
    } else {
      sortingOptions = initialSortingOptions
    }

    if (scope === 'deliveries_lcl') {
      sortingOptions = sortingOptions.concat(deliveryLCLSortingOptions)
    }
  }, [scope])

  return (
    <Input.DropDown
      theme="reports-dashboard-sorting"
      value={orderBy}
      onChange={onChangeSort}
      options={sortingOptions}
    />
  )
}

export default ReportsDashboardSorting

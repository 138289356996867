import { createReducer, Reducer } from 'redux-create-reducer'
import { AnyAction } from 'redux'

import * as actions from '../actions/chat'
import * as csatActions from '../actions/csat'

declare global {
  interface IChatUser {
    id: number
    user_type: string
    first_name: string
    department: string | null
    phone: string | null
    last_name: string
    full_name: string
    display?: string
    avatar_thumb: string | null
    anonymous_user: boolean
    api_integration: boolean
    can_be_mentioned?: boolean
    organization: {
      id: number
      name: string
      role: string
      color: string
      role_code: OrganizationRole
    }
    out_of_office_till: string | null
  }
  interface IChatOrganization {
    id: number
    name: string
    color: string
    role: string
    role_code: OrganizationRole
    logo: string
  }

  interface ICsat {
    id: number | null
    score: number | null
    comment: string | null
    created_at: string | null
    request_csat: boolean
    csat_requested_at: string | null
  }

  interface IChatState {
    csat: ICsat
    totalPages: number
    commentsLoading: boolean
    comments: IComment[]
    followers: IFollower[]
    organizations: IChatOrganization[]
    related_users: IChatUser[]
    chats: IChatIdentifier[]
  }

  interface ICommentAttachment {
    id: number
    original_filename: string
    file_url: string
    created_at: string
  }

  interface IFollower {
    id: number
    color: string
    type: UserType
    first_name: string
    full_name: string
    last_name: string
    mini_thumb: string
  }

  interface IChatIdentifier {
    id: number
    chat_type: ChatType
  }

  interface IAuthor {
    id: number
    type: string
    full_name: string
    first_name: string
    last_name: string
    avatar_thumb: string
    anonymous_user: boolean
    api_integration: boolean
    department: string | null
    color: string
    organization: {
      id: number
      name: string
      color: string
      role_code: OrganizationRole
    }
    phone?: string
    out_of_office_till: string | null
  }

  interface IComment {
    type?: 'csat'
    id: number
    deleted_at?: string
    deleted_by?: string
    is_system: boolean
    pinned: boolean
    content: string
    link: string
    answered_comment_id?: number
    chat_type: string
    chat_id: number
    created_at: string
    updated_at: string
    parent_comment?: any
    shipment?: any
    shipment_id: number
    shipment_title: string
    human_time: string
    approval_id: null | number
    saved_for_user_ids: number[]
    approval?: {
      approved: boolean | null
      mentioned_id: null | number
      approval_content: string
      actual: boolean
      document?: ICommentAttachment
    }
    author: IAuthor
    comment_attachments: ICommentAttachment[]
  }

  interface ITyper {
    user_id: number
    user_type: UserType
    full_name: string
    organization: {
      id: number
      color: string
      name: string
    }
  }

  interface IChatComponentState {
    csat: ICsat
    comments: IComment[]
    content: string
    isWatcherAlert: boolean
    filesToUpload: File[]
    failedFiles: File[]
    typers: ITyper[]
    replyCommentId: number | null
    typersNames: string[]
    hover: boolean
    currentUserId: number
    invalid: boolean
    interval?: number
    currentDropdownMenu: string | null
    currentDropdownEl: HTMLElement | null
    approvalButtonPressed: boolean
  }
}

export const initialChatState = {
  csat: {
    id: null,
    score: null,
    comment: null,
    created_at: null,
    request_csat: false,
    csat_requested_at: null,
  },
  totalPages: 1,
  commentsLoading: true,
  comments: [],
  followers: [],
  organizations: [],
  related_users: [],
  chats: [],
}

const receiveChats: Reducer<IChatState, AnyAction> = (state, action) => ({
  ...state,
  chats: action.payload.chats,
})

const recieveGetDataStarted: Reducer<IChatState, AnyAction> = (state) => ({
  ...state,
  commentsLoading: true,
  totalPages: 1,
})

const receiveInitialCommentsList: Reducer<IChatState, AnyAction> = (
  state,
  action
) => {
  return {
    ...state,
    totalPages: action.payload.total_pages,
    commentsLoading: false,
    comments:
      action.payload.page === 1
        ? action.payload.chat_comments
        : [...action.payload.chat_comments, ...state.comments],
    csat: action.payload.csat,
  }
}

const receiveCommentsList: Reducer<IChatState, AnyAction> = (state, action) => {
  return {
    ...state,
    comments: action.payload.chat_comments,
  }
}

const receiveUsersList: Reducer<IChatState, AnyAction> = (state, action) => ({
  ...state,
  followers: action.payload.followers.followers,
  organizations: action.payload.organizations,
  related_users: action.payload.related_users,
})

const updateCsat: Reducer<IChatState, AnyAction> = (state, action) => ({
  ...state,
  csat: { ...state.csat, ...action.payload },
})

const clearState: Reducer<IChatState, AnyAction> = (state, action) =>
  initialChatState

export default createReducer(initialChatState, {
  [csatActions.CSAT_SAVE_RATING_SUCCESS]: updateCsat,
  [csatActions.CSAT_SAVE_COMMENT_SUCCESS]: updateCsat,
  [actions.SHIPMENT_GET_CHATS_SUCCESS]: receiveChats,
  [actions.CHAT_GET_INITIAL_DATA]: recieveGetDataStarted,
  [actions.CHAT_GET_INITIAL_DATA_SUCCESS]: receiveInitialCommentsList,
  [actions.CHAT_GET_DATA_SUCCESS]: receiveCommentsList,
  [actions.CHAT_GET_USERS_SUCCESS]: receiveUsersList,
  [actions.CLEAR_CHAT_STATE]: clearState,
})

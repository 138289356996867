export const HS_CODES_GET_DATA = 'HS_CODES_GET_DATA'
export const HS_CODES_GET_DATA_SUCCESS = 'HS_CODES_GET_DATA_SUCCESS'
export const RELEVANT_HS_CODES_GET_DATA = 'RELEVANT_HS_CODES_GET_DATA'
export const RELEVANT_HS_CODES_GET_DATA_SUCCESS =
  'RELEVANT_HS_CODES_GET_DATA_SUCCESS'
export const SUBMIT_CONTAINER_HS_CODES_DATA = 'SUBMIT_CONTAINER_HS_CODES_DATA'
export const SUBMIT_CONTAINER_HS_CODES_DATA_SUCCESS =
  'SUBMIT_CONTAINER_HS_CODES_DATA_SUCCESS'
export const ORGANIZATION_HS_CODES_GET = 'ORGANIZATION_HS_CODES_GET'
export const ORGANIZATION_HS_CODES_GET_SUCCESS =
  'ORGANIZATION_HS_CODES_GET_SUCCESS'
export const CLEAR_HS_CODES_DATA = 'CLEAR_HS_CODES_DATA'

export const ADD_HS_CODE = 'ADD_HS_CODE'
export const ADD_HS_CODE_SUCCESS = 'ADD_HS_CODE_SUCCESS'
export const EDIT_HS_CODE = 'EDIT_HS_CODE'
export const EDIT_HS_CODE_SUCCESS = 'EDIT_HS_CODE_SUCCESS'
export const DELETE_HS_CODE = 'DELETE_HS_CODE'
export const DELETE_HS_CODE_SUCCESS = 'DELETE_HS_CODE_SUCCESS'
export const SEARCH_HS_CODES = 'SEARCH_HS_CODES'
export const SEARCH_HS_CODES_SUCCESS = 'SEARCH_HS_CODES_SUCCESS'
export const CACHE_HS_CODE = 'CACHE_HS_CODE'
export const TOGGLE_HS_MODAL_OPEN = 'TOGGLE_HS_MODAL_OPEN'

import { permissionTo } from 'src/utils'
import { MenuItemProps, CreateMenuItemProps } from './TopNavigation.props'

export const getUserFullName = (user: IUser) => {
  return `${user?.firstName ?? ''} ${user?.lastName ?? ''}`
}

export const getPermittedMenuItems = (
  items: MenuItemProps[] | CreateMenuItemProps[]
) => {
  return items.filter((item: MenuItemProps | CreateMenuItemProps) => {
    if (!item.permission) {
      return true
    }
    return permissionTo(item.permission)
  })
}

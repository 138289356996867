import { orderBy } from 'lodash'
import groupBy from 'lodash/groupBy'

import { IDemurrageAndDetentionRow } from 'src/@types/endpoints/demurrageAndDetention'

export const getDemurrageAndDetentionServiceItemSections = (
  tableData: IDemurrageAndDetentionRow[]
) => {
  const groups = groupBy(tableData, 'serviceItemName')

  return Object.keys(groups).map((group) => {
    const items = groups[group].reduce(
      (acc: IDemurrageAndDetentionRow[], curr: IDemurrageAndDetentionRow) => {
        const foundIndex = acc.findIndex((item: { range: string }) => {
          return item.range === curr.range
        })

        if (foundIndex === -1) {
          acc.push(curr)
        } else {
          acc[foundIndex] = Object.assign({}, acc[foundIndex], curr)
        }

        return acc
      },
      []
    )

    return {
      group,
      items,
    }
  })
}

export const sortedDemurrageAndDetentionServiceItems = (list, sortBy) => {
  const isAlphabetFirst = (value: string) => /^[a-z]/i.test(value)

  return orderBy(list, [
    (item) => !isAlphabetFirst(item[sortBy].toLowerCase()),
    (item) => item[sortBy].toLowerCase(),
  ])
}

import { TimelineLegsLegItemProps } from './TimelineLegs.props'

export const getLegItems = (legs: IShipmentLeg[], legsLength: number) =>
  legs.reduce((result: TimelineLegsLegItemProps[], leg, index) => {
    const nextLeg = legs[index + 1]
    if (index < legsLength - 1) {
      result.push({
        id: leg.id,
        eta: leg.estimated_arrival,
        port: leg.discharge_port.name,
        etd: nextLeg.estimated_departure,
        arrivalFlightNumber: leg.voyage_flight_no,
        departureFlightNumber: nextLeg.voyage_flight_no,
      })
    }
    return result
  }, [])

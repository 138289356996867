import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { getIsFreightPresent, getRange } from '../helpers'
import { TableCustom, TableCustomCell } from './RatesLineDetails.styles'
import RatesLineDetailsChargesTable from './RatesLineDetailsChargesTable'
import RatesLineDetailsNotification from './RatesLineDetailsNotification'
import RatesLineDetailsChargesTablePriceBlock from './RatesLineDetailsChargesTablePriceBlock'
import RatesLineDetailsAirAdditionalInformation from './RatesLineDetailsAirAdditionalInformation'
import RatesLineDetailsChargesTableCalculationMethod from './RatesLineDetailsChargesTableCalculationMethod'

interface IProps {
  rate: IAirRate
  status: string
  bookUrl: string
}

export const RatesLineDetailsAir: React.FC<IProps> = ({
  rate,
  status,
  bookUrl,
}) => {
  const {
    local_charges: localCharges,
    rates_per_chargeable_weight: ratesPerChargeableWeight,
  } = rate

  const isFreightPresent: boolean = getIsFreightPresent(rate.prices)

  const { t } = useTranslation()
  return (
    <Grid
      p={3}
      container
      spacing={0}
      component={Paper}
      className="rates-details"
    >
      {!isFreightPresent && (
        <Grid item xs={12} mb={3}>
          <RatesLineDetailsNotification
            bookUrl={bookUrl}
            prices={rate.prices}
            loadingPort={rate.loading_port.name}
            dischargePort={rate.discharge_port.name}
          />
        </Grid>
      )}
      <Grid item sm={12} md={12} lg={4} mb={2}>
        <RatesLineDetailsAirAdditionalInformation rate={rate} status={status} />
      </Grid>
      {ratesPerChargeableWeight.length > 0 && (
        <Grid item sm={12} md={6} lg={4} data-testid="chargeable_weight">
          <Typography
            variant="h5"
            children={t(
              'rates.table_content.headings.rates_for_chargeable_weight',
              'Chargeable weight'
            )}
          />
          <TableCustom size="small">
            <TableBody>
              {ratesPerChargeableWeight.map((price: IRatesPriceItem) => (
                <TableRow key={price.id}>
                  <TableCustomCell>
                    {getRange(price.range_min, price.range_max)}
                  </TableCustomCell>
                  <TableCustomCell>
                    <Box display="flex">
                      <RatesLineDetailsChargesTablePriceBlock price={price} />
                      <RatesLineDetailsChargesTableCalculationMethod
                        price={price}
                      />
                    </Box>
                  </TableCustomCell>
                </TableRow>
              ))}
            </TableBody>
          </TableCustom>
        </Grid>
      )}
      {localCharges.length > 0 && (
        <Grid
          item
          sm={12}
          md={6}
          lg={4}
          mt={{ lg: 0, md: 0, sm: 2, xs: 2 }}
          data-testid="local_charges"
        >
          <Typography
            variant="h5"
            children={t(
              'rates.table_content.headings.local_charges',
              'Local charges'
            )}
          />
          <RatesLineDetailsChargesTable prices={localCharges} />
        </Grid>
      )}
    </Grid>
  )
}

import { createReducer, Reducer } from 'redux-create-reducer'
import { AnyAction } from 'redux'
import * as actions from 'src/stores/actions/notifications'
import { AlertColor } from '@mui/material'
import { MouseEvent } from 'react'

declare global {
  interface INotification {
    id?: string
    message: string
    severity: AlertColor | 'secondary'
    actionHandler?: ((event: MouseEvent) => void) | undefined
    actionText?: string
    showClose?: boolean
  }
  interface INotificationsState {
    list: INotification[]
  }
}

export const initialState: INotificationsState = {
  list: [],
}

const showNotification: Reducer<INotificationsState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  list: [...state.list, action.payload],
})

const removeNotification: Reducer<INotificationsState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  list: state.list.filter(
    (notification) => notification.id !== action.payload.id
  ),
})

export default createReducer(initialState, {
  [actions.NOTIFICATIONS_SHOW_MESSAGE]: showNotification,
  [actions.NOTIFICATIONS_REMOVE_MESSAGE]: removeNotification,
})

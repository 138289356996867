import { FunctionComponent } from 'react'
import { Box } from '@mui/material'
import Milestones from './Milestones'
import Notifications from './Notifications'

import './styles.scss'

const NotifySettingsForm: FunctionComponent = () => {
  return (
    <Box>
      <div className="notify-settings-form">
        <Milestones />
        <Notifications />
      </div>
    </Box>
  )
}

export default NotifySettingsForm

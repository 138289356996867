import { FunctionComponent, useCallback, useState } from 'react'

import Input from 'src/components/Common/Input'
import Checkbox from 'src/components/Checkbox'

import { presentGoodsCode } from '../../../utils/helpers'

import './styles.scss'

interface IProps {
  hsCode: IContainerHsCode
  onNotesEdited: (id: number, value: string) => void
  selected: boolean
  onToggleSelected: (id: number, value: boolean) => void
}

const RelevantHsCodeLine: FunctionComponent<IProps> = (props) => {
  const { hsCode } = props

  const [selected, setSelected] = useState<boolean>(props.selected)
  const [notes, setNotes] = useState<string>(hsCode.notes)

  const toggleHsCodeSelected = useCallback(
    (event) => {
      if (
        event &&
        event.target.classList.contains('bordered-text-input-field')
      ) {
        return
      }
      props.onToggleSelected(hsCode.id as number, !selected)
      setSelected(!selected)
    },
    [selected]
  )

  const onNotesEdited = useCallback((fieldName, value) => {
    setNotes(value)
    props.onNotesEdited(hsCode.id as number, value)
  }, [])

  return (
    <div className="relevant-hs-code-line" onClick={toggleHsCodeSelected}>
      <div className="relevant-hs-code-line__checkbox">
        <Checkbox checked={selected} onChange={toggleHsCodeSelected} />
      </div>
      <div className="relevant-hs-code-line__code">
        {presentGoodsCode(hsCode.goods_code)}
      </div>
      <div className="relevant-hs-code-line__content">
        <div className="relevant-hs-code-line__content-description">
          {hsCode.description}
        </div>
        <div
          className={`relevant-hs-code-line__content-notes ${
            !selected ? 'hidden' : ''
          }`}
        >
          <Input.TextBordered
            placeholder="Add notes"
            className="full"
            name="note"
            value={notes}
            onChange={onNotesEdited}
          />
        </div>
      </div>
    </div>
  )
}

export default RelevantHsCodeLine

import Link from '@mui/material/Link'
import { useTranslation } from 'react-i18next'

interface ShipmentPortPickupAndDeliveryTitleProps {
  serviceName: string
  onClick: () => void
  address: string | null
  serviceRequested: boolean
}

const ShipmentPortPickupAndDeliveryTitleText = ({
  address,
  onClick,
  serviceName,
  serviceRequested,
}: ShipmentPortPickupAndDeliveryTitleProps) => {
  const { t } = useTranslation()
  if (!!address) {
    return <>{address}</>
  }

  if (serviceRequested) {
    return (
      <>
        {t('shipment_card.port.service_address_not_set', {
          serviceName,
          defaultValue: '{{serviceName}} address not set',
        })}
      </>
    )
  }

  return (
    <Link
      color="inherit"
      onClick={onClick}
      className="blocked-open"
      data-testid="shipments-pickup-delivery-cta"
    >
      {t('common.buttons.request_service', 'Request service')}
    </Link>
  )
}

const ShipmentPortPickupAndDeliveryTitle: React.FC<ShipmentPortPickupAndDeliveryTitleProps> = (
  props
) => {
  return (
    <header
      className="shipment__port-name shipment__line"
      data-testid="shipments-pickup-delivery-title"
    >
      <ShipmentPortPickupAndDeliveryTitleText {...props} />
    </header>
  )
}

export default ShipmentPortPickupAndDeliveryTitle

import { FunctionComponent, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { MRT_ColumnDef } from 'material-react-table'
import { stringify, parse } from 'query-string'
import { promisifyAction } from 'src/utils'
import { useHistory, useLocation } from 'react-router-dom'
import ReactTable from 'src/stories/MUI/ReactTable'
import InboxIcon from '@mui/icons-material/Inbox'
import { EmptyState } from 'src/stories'
import TableWrapper from 'src/components/Common/Table/DataTable/TableWrapper'
import { WrappedTableProps } from 'src/components/Common/Table/DataTable/TableWrapper.props'
import {
  getDocumentsData,
  getDocumentsFiltersData,
  documentsUpdateFilters,
} from 'src/stores/actionCreators'
import { getTableColumn } from './DocumentTable.utils'

const tableStorageKey = 'documentsTableState'

const DocumentsTable: FunctionComponent = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()

  const urlParams = parse(location.search, {
    arrayFormat: 'bracket',
  })

  const { initial, filters, documents, totalCount } = useSelector(
    (state: IGlobalState) => ({
      initial: state.documents.initial,
      documents: state.documents.list,
      totalCount: state.documents.total_count,
      filters: state.documents.filters,
    })
  )

  const columns = useMemo(() => getTableColumn(t), [documents, t])

  const [busy, setBusy] = useState<boolean>(false)
  const [pageIndex, setPageIndex] = useState<number>(
    urlParams.page ? +urlParams.page - 1 : 0
  )
  const [pageSize, setPageSize] = useState<number>(
    urlParams.documents_per_page ? +urlParams.documents_per_page : 20
  )

  const getDocumentsDataAsync = promisifyAction(dispatch, getDocumentsData)
  const documentsUpdateFiltersAsync = promisifyAction(
    dispatch,
    documentsUpdateFilters
  )
  const getDocumentsFiltersDataAsync = promisifyAction(
    dispatch,
    getDocumentsFiltersData
  )

  const getDocs = async (params) => {
    setBusy(true)
    await getDocumentsDataAsync(params)
    setBusy(false)
  }

  const pagination = {
    pageIndex,
    pageSize,
  }

  const onPaginationChange = async (updater) => {
    if (busy || initial) {
      return
    }
    const newPagination = updater(pagination)
    const newPageIndex = newPagination.pageIndex
    const newPageSize = newPagination.pageSize
    if (newPageIndex === pageIndex && newPageSize === pageSize) {
      return
    }
    setPageIndex(newPageIndex)
    setPageSize(newPageSize)
    const newPageParams = {
      page: newPageIndex + 1,
      documents_per_page: newPageSize,
    }
    history.push({
      search: stringify(newPageParams, { arrayFormat: 'bracket' }),
    })
    const newRequestParams = { ...filters, ...newPageParams }
    getDocs(newRequestParams)
  }

  useEffect(() => {
    const params = parse(location.search, {
      arrayFormat: 'bracket',
    })
    getDocumentsFiltersDataAsync()
    documentsUpdateFiltersAsync(params)
  }, [])

  useEffect(() => {
    if (initial) {
      return
    }
    history.push({
      search: stringify(filters, { arrayFormat: 'bracket' }),
    })

    getDocs(filters)
  }, [filters])

  return (
    <TableWrapper
      columns={columns}
      tableStorageKey={tableStorageKey}
      data-testid="document-manager-table"
      ComponentProps={{
        elevation: 0,
        sx: { height: '100%', overflowY: 'hidden', mt: 2 },
      }}
      WrappedTable={(props: WrappedTableProps) => {
        const { columnOrder } = props
        const tableColumnOrder = columnOrder.length > 0 ? { columnOrder } : {}
        const tableConfig = {
          enablePinning: true,
          manualPagination: true,
          manualFiltering: true,
          columns: columns as MRT_ColumnDef<{}>[],
          enableColumnResizing: true,
          enableColumnFilters: false,
          enableColumnPinning: true,
          rowCount: totalCount,
          enableSorting: false,
          data: documents ?? [],
          autoResetPageIndex: false,
          enableGlobalFilter: false,
          enableColumnOrdering: true,
          onDensityChange: props.onDensityChange,
          onColumnOrderChange: props.onColumnOrderChange,
          onColumnSizingChange: props.onColumnSizingChange,
          onColumnPinningChange: props.onColumnPinningChange,
          onColumnVisibilityChange: props.onColumnVisibilityChange,
          renderEmptyRowsFallback: () => (
            <EmptyState
              badgeColor="primary"
              badgeIcon={InboxIcon}
              title={t(
                'documents.filters.table.empty_state.title',
                'You can start with a clean slate'
              )}
              button={{
                text: t(
                  'documents.filters.table.empty_state.text_button',
                  'Go to your shipments'
                ),
                link: t(
                  'documents.filters.table.empty_state.text_link',
                  '/shipments'
                ),
              }}
              description={t(
                'documents.filters.table.empty_state.description',
                "There are no documents to show yet. Once you add a file to your shipments, we'll create an overview for you here."
              )}
            />
          ),
          initialState: { pagination },
          state: {
            pagination,
            isLoading: busy,
            showProgressBars: false,
            showColumnFilters: false,
            density: props.density,
            columnSizing: props.columnSizing,
            columnPinning: props.columnPinning,
            columnVisibility: props.columnVisibility,
            ...tableColumnOrder,
          },
          onPaginationChange,
        }
        return <ReactTable tableConfig={tableConfig} />
      }}
    />
  )
}
export default DocumentsTable

import { useController } from 'react-hook-form'
import { toNumber } from 'lodash'
import Input from 'src/components/Common/Input/MuiInput'
import { useStyle } from '../GoodsLoadTypeLCL.styles'
import { ItemProps } from '../GoodsLoadTypeLCL.props'

export const DimensionInput = (props: ItemProps) => {
  const { cargo, onChange, nameChange = '', isDisabled, index } = props
  const { field } = useController(props)
  const classes = useStyle({ isDimensionsDisabled: isDisabled })

  const handleChange = (e) => {
    onChange(cargo.id, nameChange, Number(e), index)
    field.onChange(e)
  }

  return (
    <>
      <Input
        {...field}
        value={toNumber(cargo[`${nameChange}`]) || 0}
        className={classes.input}
        type="number"
        onChange={handleChange}
        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
          e.target.value = Math.max(0, parseInt(e.target.value))
            .toString()
            .slice(0, 3)
        }}
        ref={null}
        data-testid={`dimensions-${nameChange}`}
      />
      {nameChange === 'height' ? (
        <span className="unit">cm</span>
      ) : (
        <span>x</span>
      )}
    </>
  )
}

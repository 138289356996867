import GroupIcon from '@mui/icons-material/Group'
import { useTranslation } from 'react-i18next'
import { EmptyState } from 'src/stories'

interface AddressBookEmptyStateProps {
  type: 'addresses' | 'contacts' | 'connections'
}

const defaultI18nValues = {
  addresses: {
    title: 'You don’t have any addresses yet',
    description:
      'Before you can use your addresses in shipments, make sure to add them here.',
    button: 'Add address',
  },
  contacts: {
    title: 'You don’t have any contacts yet',
    description:
      'Before you can use your contacts in shipments, make sure to add them here.',
    button: 'Add contact',
  },
  connections: {
    title: 'You don’t have any connections yet',
    description:
      'Before you can use your connections in shipments, make sure to add them.',
    button: null,
  },
}

const urls = {
  addresses: '/addresses/new',
  contacts: '/contacts/new',
  connections: null,
}

const AddressBookEmptyState = (props: AddressBookEmptyStateProps) => {
  const { type } = props
  const i18nDefaults = defaultI18nValues[type]
  const { title, description, button } = i18nDefaults
  const url = urls[type]
  const { t } = useTranslation()
  const buttonProps =
    button && url
      ? {
          text: t(`address_book.empty_state.${type}.button`, button),
          link: url,
        }
      : undefined
  return (
    <EmptyState
      badgeColor="primary"
      badgeIcon={GroupIcon}
      title={t(`address_book.empty_state.${type}.title`, title)}
      description={t(
        `address_book.empty_state.${type}.description`,
        description
      )}
      button={buttonProps}
    />
  )
}

export default AddressBookEmptyState

import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import './styles.scss'
import shipImage from '../../../../assets/images/Ship_no-signal@2x.png'

const ShipmentsEmptyState: FunctionComponent<{}> = () => {
  const { t } = useTranslation()
  return (
    <div className="select-shipments-step__empty">
      <div className="select-shipments-step__empty-image">
        <img src={shipImage} alt="" />
      </div>
      <div className="select-shipments-step__empty-content">
        <div className="select-shipments-step__empty-content__title paragraph__medium strong">
          {t(
            'purchase_order.modals.assign_order_to_shipment.empty_state.title',
            "We couldn't find results matching your filters"
          )}
        </div>
        <div className="select-shipments-step__empty-content__text paragraph__medium">
          {t(
            'purchase_order.modals.assign_order_to_shipment.empty_state.description',
            'Try adjusting your search by changing or removing filters'
          )}
        </div>
      </div>
    </div>
  )
}

export default ShipmentsEmptyState

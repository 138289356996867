import {
  FunctionComponent,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import Select from 'src/components/Common/Select'
import FormFieldSelect from 'src/components/FormFieldSelect'
import FormFieldTextBasic from 'src/components/FormFieldTextBasic'
import { useTranslation } from 'react-i18next'
import { withoutNullFields } from '../../../../utils'
import {
  prepareForRequest,
  prepareImportedData,
  initialState,
} from './constants'

interface ICompanyFormProps {
  addressObj: any
  companyType: string
  shipmentId: number
  permission: boolean
  disableTextInputs?: boolean
  addressBookPermission: boolean
  handleCompanyChange: (data: any) => void
  validationErrors: { [x: string]: string }
  setSaveDisable: (status: boolean) => void
  checkCompanyName: (name: string) => void
  label: string
}

const AddressForm: FunctionComponent<ICompanyFormProps> = (props) => {
  const { t } = useTranslation()
  const [state, setState] = useState<any>(initialState)
  const [getFieldData, setGetFieldData] = useState<boolean>(false)

  const { addresses, countryOptions } = useSelector((state: IGlobalState) => ({
    addresses: state.addresses.list,
    countryOptions: state.countries.list,
  }))

  const {
    company,
    address_id,
    address,
    company_name,
    city,
    postal_code,
    country_id,
    comment,
    vat_number,
    eori_number,
  } = useMemo(() => {
    return {
      company: props.addressObj,
      address_id: (props.addressObj || {}).address_id,
      address: (props.addressObj || {}).address,
      company_name: (props.addressObj || {}).name,
      city: (props.addressObj || {}).city,
      postal_code: (props.addressObj || {}).postal_code,
      country_id: (props.addressObj || {}).country
        ? (props.addressObj || {}).country.id
        : null,
      comment: (props.addressObj || {}).comment,
      vat_number: (props.addressObj || {}).vat_number,
      eori_number: (props.addressObj || {}).eori_number,
    }
  }, [props])

  useEffect(() => {
    setState({
      baseState: props,
      ...{
        company,
        address_id,
        address,
        company_name,
        city,
        postal_code,
        country_id,
        comment,
        vat_number,
        eori_number,
      },
    })
    setGetFieldData(true)
  }, [])

  useEffect(() => {
    if (getFieldData) {
      const dataToSend = withoutNullFields(prepareForRequest(state))
      props.handleCompanyChange(dataToSend)
      setGetFieldData(false)
    }
  }, [getFieldData])

  const openAndEmpty: boolean = useMemo(
    () => !state.address_id && state.openForNew,
    [state.address_id, state.openForNew]
  )

  const existEntry: boolean = useMemo(
    () => !isEmpty(company) || !!state.address_id,
    [state.address_id, company]
  )

  const addressOptions = useMemo(() => {
    const options: any[] = (addresses.slice() || []) as any[]
    if (!props.disableTextInputs) {
      options.unshift({
        id: null,
        name: t(
          'shipments.bookings.parties_modal.options.add_new_address',
          'Add new address'
        ),
      })
    }
    return options
  }, [addresses])

  const renderInput = (
    label: string,
    field: string,
    className?: string | '',
    displaySelect?: boolean | false,
    multiLine?: boolean | false
  ): React.ReactNode => {
    const changed: string = isFieldChanged(field) ? 'changed' : ''
    const blocked: boolean =
      !props.permission ||
      (!!state.address_id && field === 'company_name') ||
      !!props.disableTextInputs
    return (
      <FormFieldTextBasic
        disabled={blocked}
        multiLine={multiLine}
        label={label}
        field={field}
        contactType="company"
        value={state[field]}
        displaySelect={displaySelect}
        // FIXME
        contacts={addresses as any}
        className={`${className} ${changed}`}
        popperClassName={'shipping-instructions__modal-popper'}
        errorNotes={props.validationErrors[field]}
        onChange={handleFieldChange}
        onBlur={onBlur}
        onContactSelect={onContactSelect}
        clearState={clearState}
        data-testid={`address-form-${field}`}
      />
    )
  }

  const renderCountryInput = (
    label: string,
    field: string,
    className?: string | ''
  ) => {
    const changed: string = isFieldChanged(field) ? 'changed' : ''
    const blocked: boolean = !props.permission || !!props.disableTextInputs
    return (
      <FormFieldSelect
        disabled={blocked}
        label={label}
        fieldName={field}
        hasLimitedHeight={true}
        isSearchable={true}
        isRequired={true}
        fallbackValue={country_id}
        className={`${className} ${changed}`}
        popperClassName={'shipping-instructions__modal-popper'}
        errorNotes={props.validationErrors[field]}
        value={state.country_id}
        onChange={handleFieldChange}
        options={countryOptions}
        data-testid="address-form-country_id"
      />
    )
  }

  const isFieldChanged = useCallback(
    (field: string): boolean => {
      return (
        state.baseState &&
        prepareForRequest(state)[field] !==
          prepareForRequest(state.baseState)[field]
      )
    },
    [state]
  )

  const clearState = useCallback((): void => {
    setState(initialState)
    props.handleCompanyChange(
      withoutNullFields(prepareForRequest(initialState))
    )
  }, [])

  const handleSelect = useCallback((data: IAddress): void => {
    if (data?.id) {
      onContactSelect(data)
      props.setSaveDisable(false)
    } else {
      const newState = {
        ...initialState,
        ...{ openForNew: true },
      }
      setState(newState)
      props.handleCompanyChange(withoutNullFields(prepareForRequest(newState)))
      props.setSaveDisable(false)
    }
  }, [])

  const handleFieldChange = useCallback(
    (field: string, value: string | number | null | undefined): void => {
      const errorMessages: any = Object.assign({}, state.errorNotes)
      errorMessages[field] = null
      setState({ ...state, [field]: value, errorNotes: errorMessages })
      setGetFieldData(true)
      props.setSaveDisable(false)
    },
    [state]
  )

  const onContactSelect = (value: IAddress): void => {
    setState(prepareImportedData(value))
    setGetFieldData(true)
  }

  const onBlur = useCallback(
    (fieldName: string, event: React.FocusEvent<HTMLInputElement>): void => {
      if (fieldName === 'company_name') {
        props.checkCompanyName(event.target.value)
      }
    },
    []
  )

  return (
    <div className="shipping-instructions__form">
      <div className="instructions-form">
        <div className="instructions-form__title">
          <div>{props.label}</div>
        </div>
        <div className="instructions-form__content">
          <Select.Single
            className={`company__select ${
              openAndEmpty ? 'open-and-empty' : ''
            } ${!!props.disableTextInputs ? 'disabled-new' : ''}`}
            value={state.address_id || 0}
            placeholder={
              openAndEmpty
                ? t(
                    'shipments.bookings.parties_modal.options.new_address',
                    'New address'
                  )
                : t(
                    'shipments.bookings.parties_modal.options.select_address',
                    'Select address'
                  )
            }
            onChange={handleSelect}
            options={addressOptions}
            theme="common"
            dataTestid="address-form-select"
          />
          {(existEntry || state.openForNew) && (
            <div className="instructions-form__content--fields">
              {renderInput(
                t(
                  'shipments.bookings.parties_modal.fields.company_name',
                  'Company Name*'
                ),
                'company_name',
                'first-field'
              )}
              {renderInput(
                t(
                  'shipments.bookings.parties_modal.fields.address',
                  'Address*'
                ),
                'address',
                'full-width'
              )}
              {renderInput(
                t('shipments.bookings.parties_modal.fields.city', 'City*'),
                'city',
                'full-width'
              )}
              {renderInput(
                t(
                  'shipments.bookings.parties_modal.fields.postal_code',
                  'Postal code'
                ),
                'postal_code',
                'full-width'
              )}
              {renderCountryInput(
                t(
                  'shipments.bookings.parties_modal.fields.country',
                  'Country*'
                ),
                'country_id',
                'full-width'
              )}
              {renderInput(
                t(
                  'shipments.bookings.parties_modal.fields.vat_number',
                  'Vat number'
                ),
                'vat_number',
                'full-width'
              )}
              {renderInput(
                t(
                  'shipments.bookings.parties_modal.fields.eori_number',
                  'Eori number'
                ),
                'eori_number',
                'full-width'
              )}
              {renderInput(
                t(
                  'shipments.bookings.parties_modal.fields.additional_information',
                  'Additional Information'
                ),
                'comment',
                'full-width',
                false,
                true
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default AddressForm

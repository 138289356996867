import Input from '@mui/material/Input'
import { createStyles, withStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const SelectorInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '130px',
      margin: '0px 7px',
      '&:before': {
        border: 'none',
      },
    },
    underline: {
      '&:before': {
        borderBottom: 'none !important;',
      },
      '&:after': {
        border: 'none',
      },
    },
  })
)(Input)

export default SelectorInput

import { FCLIcon, LCLIcon } from 'src/svgIcons'
import { LoadTypeEnum } from 'src/config/constants'

export const loadTypeIcons = {
  [LoadTypeEnum.fcl]: FCLIcon,
  [LoadTypeEnum.lcl]: LCLIcon,
}

type LoadTypeIconProps = {
  loadType: LoadTypeEnum
}

export const ShipmentLoadTypeIcon: React.FC<LoadTypeIconProps> = (props) => {
  const { loadType, ...other } = props
  const DynamicIcon = loadTypeIcons[loadType]
  if (!DynamicIcon) {
    return null
  }
  return <DynamicIcon {...other} />
}

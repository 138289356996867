import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import EditIcon from '@mui/icons-material/Edit'
import useMediaQuery from '@mui/material/useMediaQuery'
import { permissionTo } from '../../utils'
import { routes } from './index'

const PersonsTable = ({
  addresses,
  className,
}: {
  addresses: IPersonalDetail[]
  className: string
}) => {
  const history = useHistory()
  const theme = useTheme()

  const isScreenSmall = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()

  return (
    <TableContainer
      classes={{ root: className }}
      component={(props) => <Paper {...props} square elevation={0} />}
    >
      <Table stickyHeader size={isScreenSmall ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            <TableCell className="x-short" />
            <TableCell>{t('address_book.contacts.name', 'Name')}</TableCell>
            <TableCell>
              {t('address_book.contacts.company', 'Company')}
            </TableCell>
            <TableCell>{t('address_book.contacts.email', 'Email')}</TableCell>
            <TableCell>{t('address_book.contacts.phone', 'Phone')}</TableCell>
            <TableCell className="x-short" />
          </TableRow>
        </TableHead>
        <TableBody>
          {addresses.map((item: IPersonalDetail) => (
            <TableRow
              key={item.id}
              hover={permissionTo('address_book.manage')}
              onClick={() => {
                if (!permissionTo('address_book.manage')) return
                history.push(
                  routes.personsEdit.replace(':id', item.id.toString())
                )
              }}
            >
              <TableCell component="th" scope="row" className="x-short">
                <i
                  style={{ fontSize: '18px', display: 'flex' }}
                  className="icon account"
                />
              </TableCell>
              <TableCell>
                <Typography variant="body1Strong">{item.name}</Typography>
              </TableCell>
              <TableCell>{item.company_name}</TableCell>
              <TableCell>{item.email}</TableCell>
              <TableCell>{item.phone}</TableCell>
              <TableCell className="x-short no-overflow">
                <IconButton size="large">
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PersonsTable

export const OMIT_FILTERS = [
  'page',
  'scope',
  'shipmentsPerPage',
  'initial',
  'sortBy',
  'filter',
  'my_shipments',
]

export const yesNoOptions = [
  { label: 'Yes', id: 'true' },
  { label: 'No', id: 'false' },
]

import Stack from '@mui/material/Stack'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { ArrowDownward, ArrowUpward, KeyboardReturn } from '@mui/icons-material'
import { StyledHelpTextAvatar } from '../../styled'

export const CategoryAutocompleteHelperTextAvatar = ({ Icon }) => (
  <StyledHelpTextAvatar variant="rounded">
    <Icon color="inherit" sx={{ fontSize: '14px' }} />
  </StyledHelpTextAvatar>
)

const CategoryAutocompleteHelperText = () => {
  const { t } = useTranslation()

  return (
    <Stack px={2} py={1} direction="row" spacing={1.25} alignItems="center">
      <Stack direction="row" spacing={1} alignItems="center">
        <Stack direction="row" spacing={0.25}>
          <CategoryAutocompleteHelperTextAvatar Icon={ArrowUpward} />
          <CategoryAutocompleteHelperTextAvatar Icon={ArrowDownward} />
        </Stack>
        <Typography
          variant="subtitle1"
          color="text.disabled"
          children={t('vessels_page.search_autocomplete_help_text.to_navigate')}
        />
      </Stack>
      <Stack direction="row" spacing={0.75} alignItems="center">
        <CategoryAutocompleteHelperTextAvatar Icon={KeyboardReturn} />
        <Typography
          variant="subtitle1"
          color="text.disabled"
          children={t('vessels_page.search_autocomplete_help_text.to_select')}
        />
      </Stack>
      <Stack direction="row" spacing={0.75} alignItems="center">
        <StyledHelpTextAvatar variant="rounded">
          <Typography
            variant="overline"
            color="text.secondary"
            children="tab"
            fontSize={8}
          />
        </StyledHelpTextAvatar>
        <Typography
          variant="subtitle1"
          color="text.disabled"
          children={t('vessels_page.search_autocomplete_help_text.to_close')}
        />
      </Stack>
    </Stack>
  )
}

export default CategoryAutocompleteHelperText

import { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { find } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Button, Chip, SelectChangeEvent, Typography } from '@mui/material'
import Modal from 'src/components/Common/Modal'
import { Box } from '@mui/system'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import DatePicker from 'src/stories/DatePicker'
import { DateTime } from 'luxon'
import { makeStyles } from '@mui/styles'
import { showNotification } from 'src/stores/actionCreators/notifications'
import Warning from '@mui/icons-material/Warning'
import { promisifyAction } from '../../../utils'
import { OPENING_HOURS } from '../../../config/constants'

import { containerAssignDatetime } from '../../../stores/actionCreators'

import {
  extractHours,
  extractMinutes,
  convertDateForComparison,
  convertDateToLocalFormat,
  startOfDay,
  increaseDate,
} from '../../../utils/helpers'

const useStyles = makeStyles(() => ({
  root: {
    '&.MuiDialogContent-root': {
      '& .MuiAlert-filledWarning': {
        padding: 0,
      },
      '& .MuiSelect-select > *': {
        marginTop: '0px !important',
      },
    },
  },
}))

interface ITime {
  id: number
  name: string
  count: number
}

interface IDateTimeData {
  date: string | null
  timeFrom: ITime | null
  timeTo: ITime | null
}
interface IProps {
  open: boolean
  close: () => void
  save?: (data: IDateTimeData) => void
  transport: IInlandTransport | null
  shipmentData: IShipmentMainData
  fetchData: () => void
  isLclOrAir: boolean
  containerId: number | null
  containerData: IShipmentContainer
}

const DateAndTimeWindow: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const isPickup = props.transport?.service === 'pickup'
  const minDate = isPickup
    ? props.shipmentData.pickup_available_from
    : props.shipmentData.delivery_available_from

  const [dateTimeData, setDateTimeData] = useState<IDateTimeData>({
    date: minDate,
    timeFrom: null,
    timeTo: null,
  })
  const dispatch = useDispatch()
  const classes = useStyles()

  const containerAssignDatetimeAsync = promisifyAction(
    dispatch,
    containerAssignDatetime
  )

  useEffect(() => {
    if (
      props.transport?.status === 'requested' ||
      props.transport?.status === 'proposed'
    ) {
      const { pickup_delivery_time, closing_time } = props.transport

      if (pickup_delivery_time && closing_time) {
        const date: string = startOfDay(pickup_delivery_time)

        const hoursFrom = extractHours(pickup_delivery_time)
        const minsFrom = extractMinutes(pickup_delivery_time)
        const hoursTo = extractHours(closing_time)
        const minsTo = extractMinutes(closing_time)

        const timeFrom: ITime | null =
          find(OPENING_HOURS.slice(0, -1), {
            count: minsFrom === 30 ? hoursFrom + 0.5 : hoursFrom,
          }) || null
        const timeTo: ITime | null =
          find(OPENING_HOURS, {
            count: minsTo === 30 ? hoursTo + 0.5 : hoursTo,
          }) || null
        setDateTimeData({ ...dateTimeData, date, timeFrom, timeTo })
      }
    }
  }, [props.transport])

  const handleDateSelect = (date: DateTime | null) => {
    setDateTimeData({
      ...dateTimeData,
      date: date?.toISO() || null,
    })
  }

  const handleTimeSelect = (event: SelectChangeEvent<unknown>) => {
    const selected = event.target.value as number
    const timeFrom: ITime | null = find(OPENING_HOURS, { id: selected }) || null
    const timeTo: ITime | null =
      find(OPENING_HOURS, { id: selected + 1 }) || null
    setDateTimeData({ ...dateTimeData, timeFrom, timeTo })
  }

  const onSave = async (): Promise<any> => {
    const dateFrom: string | null = increaseDate(
      dateTimeData.date || '',
      'hours',
      props.isLclOrAir
        ? 8
        : dateTimeData.timeFrom
        ? dateTimeData.timeFrom.count
        : 0
    )
    const dateTo: string | null = increaseDate(
      dateTimeData.date || '',
      'hours',
      props.isLclOrAir
        ? 18
        : dateTimeData.timeTo
        ? dateTimeData.timeTo.count
        : 0
    )

    if (props.transport && props.containerId && dateFrom && dateTo) {
      const dataObj: { [key: string]: string | number } = {
        service_type: props.transport.service,
        datetime_from: convertDateToLocalFormat(dateFrom),
        datetime_to: convertDateToLocalFormat(dateTo),
      }
      await containerAssignDatetimeAsync(props.containerId, dataObj)
      props.fetchData()
      dispatch(
        showNotification({
          severity: 'info',
          message: t('shipment_containers.plan_service.delivery_date_is_set', {
            defaultValue:
              'Delivery date has been set to {{date}} between {{time}}.',
            date: t('common.date_medium', {
              defaultValue: '',
              date: dateTimeData.date,
            }),
            time: dateTimeData.timeFrom?.name,
          }),
        })
      )
    }
    closeWindow()
  }

  const closeWindow = (): void => {
    props.close()
  }

  const disableSaveConditions = (): boolean => {
    const {
      delivery_available_from,
      pickup_available_from,
      pickup_available_to,
    } = props.shipmentData
    const deliveryDate: string = convertDateForComparison(
      delivery_available_from || ''
    )
    const pickupDateFrom: string = convertDateForComparison(
      pickup_available_from || ''
    )
    const pickupDateTo: string = convertDateForComparison(
      pickup_available_to || ''
    )
    const selectedDate: string = convertDateForComparison(
      dateTimeData.date || ''
    )
    const commonCondition: boolean =
      props.transport?.service === 'pickup'
        ? !dateTimeData.date ||
          pickupDateFrom > selectedDate ||
          selectedDate > pickupDateTo
        : !dateTimeData.date || deliveryDate > selectedDate
    if (!props.isLclOrAir) {
      return commonCondition || !dateTimeData.timeFrom || !dateTimeData.timeTo
    }
    return commonCondition
  }

  const actualDayOfDemurrage = props.containerData.actual_first_day_of_demurrage
  const destinationDemurrage =
    props.shipmentData.destination_demurrage_starting_from
  const demurrageDate = actualDayOfDemurrage || destinationDemurrage

  const dayOfDemurrage = t('common.date_medium', {
    defaultValue: '{{date, DATE_MED}}',
    date: demurrageDate,
  })

  const dateTimeDataLuxon = DateTime.fromISO(dateTimeData?.date || '')

  const demurrageDateLuxon = DateTime.fromISO(demurrageDate || '')

  /**
   * Show warning if delivery date is planned outside free days
   */
  const showWarning =
    props.transport?.service === 'delivery' &&
    dateTimeDataLuxon.isValid &&
    demurrageDateLuxon.isValid &&
    dateTimeDataLuxon.toISODate() >= demurrageDateLuxon.toISODate()

  return (
    <Modal.Window open={props.open} onClose={closeWindow}>
      <Modal.Title
        children={`Plan ${props.transport?.service}`}
        onClose={closeWindow}
      />
      <Modal.Content className={classes.root} sx={{ width: 600 }}>
        <Box>
          <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap="1rem">
            <Box>
              <Typography
                variant="body1Strong"
                children={
                  isPickup
                    ? t(
                        'shipment_containers.plan_service.pickup_available_from',
                        'Pickup available from'
                      )
                    : t(
                        'shipment_containers.plan_service.delivery_available_from',
                        'Delivery available from'
                      )
                }
              />
              <Typography
                mt={1}
                variant="body1"
                children={
                  isPickup
                    ? t('common.date_medium', {
                        defaultValue: '{{date, DATE_MED}}',
                        date: props.shipmentData.pickup_available_from,
                      })
                    : t('common.date_medium', {
                        defaultValue: '{{date, DATE_MED}}',
                        date: props.shipmentData.delivery_available_from,
                      })
                }
              />
            </Box>
            <Box>
              <Typography
                variant="body1Strong"
                children={t(
                  'shipment_containers.plan_service.first_day_of_demurrage',
                  'First day of demurrage'
                )}
              />
              <Box mt={1} display="flex" alignItems="center">
                <Typography variant="body1" mr={1} children={dayOfDemurrage} />{' '}
                {!!demurrageDate && (
                  <Chip
                    label={
                      actualDayOfDemurrage
                        ? t('shipment_containers.plan_service.actual', 'Actual')
                        : t(
                            'shipment_containers.plan_service.estimated',
                            'Estimated'
                          )
                    }
                    color={actualDayOfDemurrage ? 'success' : 'secondary'}
                    size="small"
                  />
                )}
              </Box>
            </Box>
          </Box>
          <Box
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            gap={2}
            mt={3}
          >
            <Box>
              <DatePicker
                label={`${
                  props.transport?.service === 'pickup'
                    ? t(
                        'shipment_containers.plan_service.pickup_date',
                        'Pickup date'
                      )
                    : t(
                        'shipment_containers.plan_service.delivery_date',
                        'Delivery date'
                      )
                }`}
                value={dateTimeData.date}
                minDate={DateTime.fromISO(
                  props.shipmentData.delivery_available_from as string
                )}
                onChange={handleDateSelect}
                clearable={false}
              />
            </Box>
            <Box>
              <SingleSelect
                value={dateTimeData.timeFrom?.id || ''}
                label={t(
                  'shipment_containers.plan_service.time_slot',
                  'Time slot'
                )}
                placeholder={t('common.select', 'Select')}
                options={OPENING_HOURS.map((hour) => ({
                  ...hour,
                  label: hour.name,
                }))}
                onChange={handleTimeSelect}
              />
            </Box>
          </Box>
          {showWarning && (
            <Box display="flex" alignItems="center" color="#C44503" mt={2}>
              <Warning color="inherit" viewBox="24px" />
              <Typography
                color="inherit"
                variant="body1"
                ml={1}
                children={t(
                  'shipment_containers.plan_service.warning_message',
                  'The delivery date is set on or after the first day of demurrage.\nCosts may arise when a container is in demurrage.'
                )}
              />
            </Box>
          )}
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="outlined" onClick={closeWindow}>
          {t('common.buttons.cancel', 'Cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={onSave}
          disabled={disableSaveConditions()}
        >
          {t('common.buttons.save', 'Save')}
        </Button>
      </Modal.Actions>
    </Modal.Window>
  )
}

export default DateAndTimeWindow

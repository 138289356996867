import { ModalityEnum } from 'src/config/constants'

export const modalityGoodsDescription = {
  [ModalityEnum.Air]: {
    length: 300,
    width: 240,
    height: 295,
    message:
      'Entered dimensions are exceeding the standard range. We will investigate the options for shipping.',
  },
  [ModalityEnum.Sea]: {
    length: 500,
    width: 500,
    height: 255,
  },
}

import { useMemo, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { parse } from 'query-string'
import { Link as MuiLink } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'
import ErrorIcon from '@mui/icons-material/SmsFailed'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CustomInput from 'src/components/Common/Input/MuiInput'
import { saveCommentByToken } from 'src/stores/actionCreators/csat'
import { showNotification } from 'src/stores/actionCreators/notifications'

import CenteredLayout from 'src/pages/Layout/Centered'

const CSATResponse = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const parameters = parse(location.search, {
    arrayFormat: 'bracket',
  })

  const { message, csat_token } = parameters

  const [comment, setComment] = useState<string>('')

  const saveCsatComment = useCallback(() => {
    dispatch(saveCommentByToken({ token: csat_token, comment: comment }))
    dispatch(
      showNotification({
        message: t('csat_response.title.success'),
        severity: 'success',
      })
    )
    history.push({ pathname: '/dashboard' })
  }, [comment, csat_token, saveCommentByToken])

  const { t } = useTranslation()

  const content = useMemo(() => {
    if (message || !csat_token) {
      return (
        <MuiLink
          component={Link}
          to="/dashboard"
          variant="body1"
          data-testid="link"
        >
          {t('common.actions.back_to_dashboard')}
        </MuiLink>
      )
    } else {
      return (
        <Box width="100%">
          <CustomInput
            fullWidth
            placeholder={t('csat_response.comment.placeholder')}
            value={comment}
            onChange={setComment}
          />
          <Box mb={1} />
          <Button
            disabled={!comment}
            variant="contained"
            color="primary"
            onClick={saveCsatComment}
          >
            {t('common.actions.save_and_back_to_dashboard')}
          </Button>
          <Box mb={3} />
          <MuiLink
            component={Link}
            to="/dashboard"
            variant="body1"
            data-testid="link"
          >
            {t('common.actions.back_to_dashboard')}
          </MuiLink>
        </Box>
      )
    }
  }, [message, csat_token, comment])

  let title = '',
    description = '',
    success = true

  if (csat_token) {
    title = t('csat_response.title.success')
    description = t('csat_response.description.success')
  } else if (message === 'csat_score_already_submitted') {
    title = t('csat_response.title.already_submitted')
    description = t('csat_response.description.already_submitted')
    success = false
  } else {
    title = t('csat_response.title.invalid')
    description = t('csat_response.description.invalid')
    success = false
  }

  return (
    <CenteredLayout
      icon={success ? CheckCircleIcon : ErrorIcon}
      variant={success ? 'success' : 'error'}
      title={title}
      description={description}
    >
      {content}
    </CenteredLayout>
  )
}

export default CSATResponse

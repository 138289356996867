import { set, get, isNaN } from 'lodash'
import { useTranslation } from 'react-i18next'
import TextField from '@mui/material/TextField'
import { NumberFormatCustom } from 'src/components/Common/Input/MuiInput'
import InputLabel from '@mui/material/InputLabel'
import { useFormContext } from '../../FormContext'

const getInputValue = (value: string, numberInput: boolean) => {
  if (numberInput) {
    return !isNaN(+value) ? +value : 0
  }
  return value
}

const TemplateFormNumberInput = ({
  fieldPath,
  disabled = false,
  numberInput = false,
  required = false,
  columnSize = 2,
  ...otherProps
}) => {
  const { t } = useTranslation()
  const placeholder =
    otherProps?.placeholder ?? t('common.enter_value', 'Enter value')
  const { formState, onChange } = useFormContext()
  const { label, ...textFieldProps } = otherProps

  const { InputProps } = textFieldProps

  const value = get(formState, fieldPath, '') ?? ''

  const onInputChange = (event) => {
    const { value } = event.target
    onChange(set(formState, fieldPath, value))
  }

  const onInputBlur = (event) => {
    if (!numberInput) return
    const { value } = event.target
    const numberValue = getInputValue(value, numberInput)
    onChange(set(formState, fieldPath, numberValue))
  }

  const inputComponentProps = numberInput
    ? {
        inputComponent: NumberFormatCustom as any,
        ...InputProps,
      }
    : InputProps

  return (
    <>
      {label && <InputLabel required={required}>{label}</InputLabel>}
      <TextField
        fullWidth
        value={value}
        disabled={disabled}
        onBlur={onInputBlur}
        onChange={onInputChange}
        InputProps={inputComponentProps}
        placeholder={placeholder}
        {...textFieldProps}
      />
    </>
  )
}

export default TemplateFormNumberInput

import { Chip } from '@mui/material'
import { estimatedArrivalDelayColor } from '../helpers'

const estimatedArrivalDelayLabelMessage = (d: number, max: number = 99) => {
  const days = Math.min(Math.abs(d), max)
  if (d > 0) {
    return `+${days}`
  }
  if (d < 0) {
    return days
  }
  return '•'
}

const EstimatedArrivalDelayChip = (props) => {
  if (!props.delay) return null
  return (
    <Chip
      data-testid={props['data-testid']}
      sx={{
        '.MuiChip-label': {
          paddingLeft: '5px !important',
          paddingRight: '5px !important',
        },
        marginLeft: '5px !important',
        fontWeight: 'bold',
        fontSize: '12px',
        margin: 0,
        height: 'auto',
      }}
      label={estimatedArrivalDelayLabelMessage(props.delay.days)}
      color={estimatedArrivalDelayColor(props.delay.category)}
      size="small"
    />
  )
}

export default EstimatedArrivalDelayChip

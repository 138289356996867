import { FunctionComponent, useState, useEffect } from 'react'
import './styles.scss'

interface IProps {
  className?: string
  placeholder?: string
  disabled?: boolean
  value?: string
  name?: string
  showError?: boolean
  errorMessage?: string | null
  onChange?: (fieldName: string | undefined, value: string) => void
  onFocus?: (event) => void
  onBlur?: () => void
}

const Text: FunctionComponent<IProps> = (props) => {
  const [showError, setShowError] = useState<boolean>(true)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (props.onChange) {
      props.onChange(props.name, event.target.value)
    }
  }

  const handleFocus = (event): void => {
    setShowError(false)
    if (props.onFocus) {
      props.onFocus(event)
    }
  }

  const handleBlur = (): void => {
    setShowError(true)
    if (props.onBlur) {
      props.onBlur()
    }
  }

  useEffect(() => {
    setShowError(true)
  }, [props.value])

  return (
    <div
      className={`bordered-text-input-form ${props.className} ${
        props.disabled ? 'disabled' : ''
      } ${props.errorMessage && showError ? 'invalid' : ''}`}
    >
      <input
        className="bordered-text-input-field"
        type="text"
        name={props.name}
        onChange={handleChange}
        disabled={props.disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={props.placeholder}
        value={props.value}
        required={true}
      />
      <div className="bordered-text-input-error">
        {showError && props.errorMessage}
      </div>
    </div>
  )
}

Text.defaultProps = {
  disabled: false,
  showError: false,
  className: '',
  placeholder: '',
  onChange: (e) => undefined,
}

export default Text

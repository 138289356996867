import { createReducer, Reducer } from 'redux-create-reducer'
import { AnyAction } from 'redux'
import iconMappings from 'src/pages/PickupsAndDeliveries/PickupsAndDeliveriesTable/TaskStatusSelect/mappings/icons'
import { chipColor } from 'src/config/constants'
import * as actions from '../actions/pickupAndDeliveries'
import { AddressBookTypeOptionsEnum } from './../../pages/PickupsAndDeliveries/constants'

declare global {
  interface IPickupsAndDeliveriesDefaultFilters {
    scope: 'delivery' | 'pickup'
    page: number
    order_by: string
    per_page: number
  }
  interface IPickupsAndDeliveriesFilters
    extends IPickupsAndDeliveriesDefaultFilters {
    search: string
    load_types: any
    modalities: string[]
    cluster_ids: string[]
    transporter_ids: string[]
    service_departments: string[]
    pol_ids: string[]
    pod_ids: string[]
    service_requested: string[]
    show_delivered: string[]
    organization_ids: string[]
    statuses: string[]
    my_shipments: boolean
    departure_date_start: string | null
    departure_date_end: string | null
    visibility_only: string[]
    arrival_date_start: string | null
    arrival_date_end: string | null
    delivery_date_start: string | null
    delivery_date_end: string | null
  }

  interface Container {
    id: number
    number: string
  }

  interface IContainerTypeShort {
    id: number
    name: string
    equipment_controlled_atmosphere: boolean
  }

  interface IContainerDocumentShort {
    id: number
    name: string
    document_types: IDocumentType
  }

  interface ICustomer {
    id: number
    name: string
  }

  interface ITransporterUser {
    avatar: string | null
    email: string
    full_name: string
    id: number
    last_active: string | null
    organization_name: string
  }

  interface ITransporter {
    id: number
    number: string
    name: string
    inland_transport_email: string | null
    users: ITransporterUser[]
  }

  interface InlandTransportAddress {
    id: number
    street: string
    postal_code: string
    city: string
    name: string | null
    pickup_delivery: boolean
    datetime_from: string
    datetime_to: string
    position?: number
    temp_id?: string
    reference: string
  }

  interface InlandTransportAddressAttributes {
    id: number
    street: string
    postal_code: string
    city: string
    name: string | null
    temp_id: string | number
    datetime_from: string
    datetime_to: string
    reference: string
    address_id: number
    comment?: string
    position?: number
    address_type?: string
    pickup_delivery: boolean
    address_book_type: AddressBookTypeOptionsEnum
    _destroy?: boolean
  }

  interface ITaskTypeGroup {
    id: number
    name: string
    task_types: ITaskTypeItem[]
  }

  interface ITaskTypeItem {
    code: string
    column_display_abbreviation: null | string
    column_display_name: null | string
    description: string
    id: number
    name: string
  }

  interface ITableConfiguration {
    id: number
    name: string
    enabled: boolean
    order: number
    task_type_group: null | ITaskTypeGroup
  }

  interface IInlandTransportState {
    container: Container
    customer: ICustomer
    id: number
    inland_transport_addresses: InlandTransportAddress[]
    pickup_delivery_time: string | null
    pickup_delivery_time_editable: boolean
    status: string
    service: 'pickup' | 'delivery'
    transporter: ITransporter
    shipment: IShipment & {
      estimated_arrival_delay: EstimatedArrivalDelay
    }
    pod_customs_documents_present: boolean
    tasks: ReportsDashboardTaskTypeStatuses
  }

  interface ISingleInlandTransportShipmentData {
    bl_number: string | null
    estimated_arrival: string | null
    estimated_departure: string | null
    id: number
    load_type: string
    modality: string
    port_pair: IPortPair
    reference: string
    shippers: IShipperShort
    status: IShipmentStatus
    title: string
    vessel: string | null
    shipment_phase: string
    reference_number: string
    shared_reference: string
    shipment_legs: IShipmentLeg[]
    cargo_closing_date: string
    cargo_closing_status: string
    collaborators: IShipmentParty[]
    containers?: IContainerIdentifier[]
    mbl_freight_payable: string
    booking_number: string
    carrier_id: null | number
    closing_datetime: string
    closing_datetime_status: string
    delivery_available_from: string
    incoterm: IIncoterm | null
    has_reefer_containers: boolean
    product_group: IProductGroup
    carrier_name: string
    shipment_type: string
    destination_demurrage_starting_from: string
    sailing_to_be_announced: boolean
    visibility_only: boolean
  }
  interface ISingleInlandTransport {
    container: {
      container_number: string | null
      container_type: IContainerTypeShort
      documents: IContainerDocumentShort[]
      id: number
      seal_number: string | null
      status: {
        id: number
        name: string
      }
      non_operating_reefer: boolean
      delivery_inspection_datetime: string | undefined
      actual_first_day_of_demurrage?: string | null
    }
    pickup_delivery_time_editable: boolean
    customer: ICustomer
    id: string | number
    inland_transport_addresses: Array<
      InlandTransportAddress | InlandTransportAddressAttributes
    >
    transporter: ITransporter | null
    transporter_organization_id: string | number
    modality: string
    service: string
    status: string
    shipment: ISingleInlandTransportShipmentData
    inland_transport_addresses_attributes: InlandTransportAddressAttributes[]
    transporter_email_action_items: string[]
    transport_email_sent_at: string | null
  }

  interface PickUpAndDeliveryQuickFilter {
    id: number | null
    name: string
    page: 'inland_transports'
    filter: IPickupsAndDeliveriesFilters
    user_id?: number
  }

  interface IPickUpAndDeliveryState {
    isLoading: boolean
    isLoadingOptions: boolean
    list: IInlandTransportState[]
    tableConfigurations: ITableConfiguration[]
    tableColumns: ITableConfiguration[]
    singleInlandTransport: ISingleInlandTransport | null
    selectedInlandTransportId: number | null
    selectedInlandTransportRowsIds: number[]
    openAddressIndex: number | null
    total: number
    filtersOptions: IPickUpAndDeliveryFiltersOptions
    filtersOptionsFetched: boolean
    filters: IPickupsAndDeliveriesFilters
    quickFilter: PickUpAndDeliveryQuickFilter
    drawer: { type: 'inland_transport' | 'customize' | 'none'; open: boolean }
    editTransportWindowType: string
    isTransportOrderWindowOpen: boolean
    isEditTransportWindowOpen: boolean
  }
}

export const defaultFilters: IPickupsAndDeliveriesDefaultFilters = {
  page: 1,
  scope: 'delivery',
  per_page: 20,
  order_by: window.localStorage.getItem('pickupDeliverySorting') || 'eta_asc',
}

export const initialFilters: IPickupsAndDeliveriesFilters = {
  ...defaultFilters,
  search: '',
  load_types: [],
  modalities: [],
  cluster_ids: [],
  transporter_ids: [],
  service_departments: [],
  pol_ids: [],
  pod_ids: [],
  organization_ids: [],
  statuses: [],
  departure_date_start: null,
  departure_date_end: null,
  arrival_date_start: null,
  arrival_date_end: null,
  delivery_date_start: null,
  delivery_date_end: null,
  service_requested: [],
  visibility_only: [],
  show_delivered: [],
  my_shipments: window.localStorage.getItem('containersMyShipments') === 'true',
}

export const initialQuickFilter: PickUpAndDeliveryQuickFilter = {
  id: null,
  name: '',
  page: 'inland_transports',
  filter: initialFilters,
  user_id: undefined,
}

export const initialPickUpAndDeliveryState: IPickUpAndDeliveryState = {
  isLoading: true,
  isLoadingOptions: true,
  list: [],
  tableConfigurations: [],
  tableColumns: [],
  singleInlandTransport: null,
  selectedInlandTransportId: null,
  selectedInlandTransportRowsIds: [],
  openAddressIndex: null,
  total: 0,
  filtersOptions: {
    clusters: [],
    load_types: [],
    modalities: [],
    service_departments: [],
    transporters: [],
    task_type_statuses: {},
    saved_filters: [],
  },
  filtersOptionsFetched: false,
  filters: initialFilters,
  drawer: { type: 'customize', open: false },
  editTransportWindowType: '',
  isTransportOrderWindowOpen: false,
  isEditTransportWindowOpen: false,
  quickFilter: initialQuickFilter,
}

const startLoading: Reducer<IPickUpAndDeliveryState, AnyAction> = (state) => ({
  ...state,
  isLoading: true,
})

const toggleTransportWindow: Reducer<IPickUpAndDeliveryState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  isTransportOrderWindowOpen: action.payload,
})

const toggleEditWindow: Reducer<IPickUpAndDeliveryState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  isEditTransportWindowOpen: action.payload.status,
  editTransportWindowType: action.payload.type,
})

const receiveList: Reducer<IPickUpAndDeliveryState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  list: action.payload.inland_transports,
  total: action.payload.total,
  isLoading: false,
})

const receiveUpdatedList: Reducer<IPickUpAndDeliveryState, AnyAction> = (
  state,
  action
) => {
  const { payload } = action

  return {
    ...state,
    list: state.list.map((item) => {
      const updatedTransport = payload.inland_transports.find(
        (transport) => transport.id === item.id
      )
      return updatedTransport || item
    }),
  }
}

const receiveColumns: Reducer<IPickUpAndDeliveryState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  tableConfigurations: action.payload.table_configurations,
  tableColumns: action.payload.table_configurations.filter((x) => x.enabled),
})

const receiveUpdatedColumns: Reducer<IPickUpAndDeliveryState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  tableConfigurations: action.payload.table_configurations,
  tableColumns: action.payload.table_configurations.filter((x) => x.enabled),
  isLoading: false,
})

const getOne: Reducer<IPickUpAndDeliveryState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  singleInlandTransport: action.payload,
})

const updateFilters: Reducer<IPickUpAndDeliveryState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  filters: { ...initialPickUpAndDeliveryState.filters, ...action.payload },
})

const updateQuickFilter: Reducer<IPickUpAndDeliveryState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  quickFilter: { ...state.quickFilter, ...action.payload },
})

const updatePickupAndDeliveryShipmentStatus: Reducer<
  IPickUpAndDeliveryState,
  AnyAction
> = (state, action) => {
  const { singleInlandTransport } = state
  const { payload } = action
  const updatedPart = {
    status: payload.status,
    shipment_phase: payload.shipment_phase,
  }
  return {
    ...state,
    singleInlandTransport: singleInlandTransport
      ? {
          ...singleInlandTransport,
          shipment: {
            ...singleInlandTransport.shipment,
            ...updatedPart,
          },
        }
      : singleInlandTransport,
    list: state.list.map((item) => {
      return item.shipment.id === payload.id
        ? {
            ...item,
            shipment: {
              ...item.shipment,
              ...updatedPart,
            },
          }
        : item
    }),
  }
}

const updatePickupAndDeliveryItem: Reducer<
  IPickUpAndDeliveryState,
  AnyAction
> = (state, action) => {
  const { payload } = action
  return {
    ...state,
    list: state.list.map((item) => {
      return item.id === payload.id
        ? {
            ...item,
            ...payload,
          }
        : item
    }),
  }
}

const setSelectedId: Reducer<IPickUpAndDeliveryState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  selectedInlandTransportId: action.id,
})

const setSelectedIds: Reducer<IPickUpAndDeliveryState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  selectedInlandTransportRowsIds: action.ids,
})

const setOpenAddressIndex: Reducer<IPickUpAndDeliveryState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  openAddressIndex: action.index,
})

const requestFiltersOptions: Reducer<IPickUpAndDeliveryState, AnyAction> = (
  state
) => ({
  ...state,
  isLoadingOptions: true,
})

const formatStatuses = (filterOptions: any) => {
  Object.keys(filterOptions.task_type_statuses).forEach((key) => {
    filterOptions.task_type_statuses[key] = filterOptions.task_type_statuses[
      key
    ].map((x) => ({
      ...x,
      icon: x.icon ? (iconMappings[x.icon] as React.ReactElement) : undefined,
      type: 'item',
      name: x.name,
      color: x.color ? chipColor[x.color] : 'default',
    }))
  })
  return filterOptions
}

const receiveFiltersOptions: Reducer<IPickUpAndDeliveryState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  filtersOptions: formatStatuses(action.payload),
  isLoadingOptions: false,
  filtersOptionsFetched: true,
})

const setDrawer: Reducer<IPickUpAndDeliveryState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  drawer: action.payload,
})

const updatePickupAndDeliverQuickFilterItem: Reducer<
  IPickUpAndDeliveryState,
  AnyAction
> = (state, action) => {
  const { saved_filters } = state.filtersOptions
  const { payload } = action
  const updatedItemIndex = saved_filters.findIndex(
    (filter) => filter.id === payload.id
  )
  return {
    ...state,
    filtersOptions: {
      ...state.filtersOptions,
      saved_filters: saved_filters.map((filter, index) =>
        index === updatedItemIndex ? { ...filter, ...action.payload } : filter
      ),
    },
    quickFilter: {
      ...state.quickFilter,
      ...payload,
    },
    filters: {
      ...state.filters,
      ...payload.filter,
    },
  }
}

const addPickupAndDeliverQuickFilterItem: Reducer<
  IPickUpAndDeliveryState,
  AnyAction
> = (state, action) => {
  const { saved_filters } = state.filtersOptions
  const { payload } = action
  return {
    ...state,
    filtersOptions: {
      ...state.filtersOptions,
      saved_filters: [payload, ...saved_filters],
    },
    quickFilter: {
      ...state.quickFilter,
      ...payload,
    },
    filters: {
      ...state.filters,
      ...payload.filter,
    },
  }
}

const updatePickupAndDeliverQuickFilterItems: Reducer<
  IPickUpAndDeliveryState,
  AnyAction
> = (state, action) => {
  const { saved_filters } = state.filtersOptions
  const { payload } = action
  const { quickFilter } = state
  const quickFilterIndex = payload.findIndex(
    (payloadItem) => payloadItem.id === quickFilter.id
  )
  const updatedQuickFilterName =
    quickFilterIndex > -1 ? payload[quickFilterIndex].name : quickFilter.name

  return {
    ...state,
    filtersOptions: {
      ...state.filtersOptions,
      saved_filters: saved_filters.map((filter) => {
        const found = payload.find(
          (payloadItem) => payloadItem.id === filter.id
        )
        if (found) {
          return found
        }
        return filter
      }),
    },
    quickFilter: {
      ...state.quickFilter,
      name: updatedQuickFilterName,
    },
  }
}

const deletePickupAndDeliverQuickFilterItem: Reducer<
  IPickUpAndDeliveryState,
  AnyAction
> = (state, action) => {
  const { id } = action.payload
  const { quickFilter } = state
  const { saved_filters } = state.filtersOptions
  const newFilters = saved_filters.filter((filter) => `${filter.id}` !== id)
  const deleteFilterIsActive = `${quickFilter.id}` === id

  if (deleteFilterIsActive) {
    const updatedQuickFilter = deleteFilterIsActive
      ? initialQuickFilter
      : quickFilter
    return {
      ...state,
      filtersOptions: {
        ...state.filtersOptions,
        saved_filters: newFilters,
      },
      quickFilter: {
        ...state.quickFilter,
        ...updatedQuickFilter,
      },
      filters: {
        ...state.filters,
        ...updatedQuickFilter.filter,
      },
    }
  }
  return {
    ...state,
    filtersOptions: {
      ...state.filtersOptions,
      saved_filters: newFilters,
    },
  }
}

export default createReducer(initialPickUpAndDeliveryState, {
  [actions.PICK_UPS_AND_DELIVERIES_LOADING]: startLoading,
  [actions.PICK_UPS_AND_DELIVERIES_GET_DATA]: startLoading,
  [actions.PICK_UPS_AND_DELIVERIES_GET_DATA_SUCCESS]: receiveList,
  [actions.BULK_EDIT_TRANSPORTER_SUCCESS]: receiveUpdatedList,
  [actions.PICK_UPS_AND_DELIVERIES_UPDATE_DATA_SUCCESS]: getOne,
  [actions.GET_PICK_UPS_AND_DELIVERIES_USER_COLUMNS_SUCCESS]: receiveColumns,
  [actions.PICK_UPS_AND_DELIVERIES_GET_ONE_SUCCESS]: getOne,
  [actions.SEND_TRANSPORT_EMAIL_SUCCESS]: getOne,
  [actions.UPDATE_PICK_UPS_AND_DELIVERIES_USER_COLUMNS]: startLoading,
  [actions.PICK_UPS_AND_DELIVERIES_TOGGLE_TRANSPORT_WINDOW]: toggleTransportWindow,
  [actions.PICK_UPS_AND_DELIVERIES_TOGGLE_EDIT_WINDOW]: toggleEditWindow,
  [actions.UPDATE_PICK_UPS_AND_DELIVERIES_USER_COLUMNS_SUCCESS]: receiveUpdatedColumns,
  [actions.GET_PICK_UPS_AND_DELIVERIES_FILTERS_OPTIONS]: requestFiltersOptions,
  [actions.GET_PICK_UPS_AND_DELIVERIES_FILTERS_OPTIONS_SUCCESS]: receiveFiltersOptions,
  [actions.PICK_UPS_AND_DELIVERIES_UPDATE_FILTERS]: updateFilters,
  [actions.PICK_UPS_AND_DELIVERIES_UPDATE_QUICK_FILTER]: updateQuickFilter,
  [actions.PICK_UPS_AND_DELIVERIES_SET_DRAWER]: setDrawer,
  [actions.PICK_UPS_AND_DELIVERIES_SET_SELECTED]: setSelectedId,
  [actions.PICK_UPS_AND_DELIVERIES_SET_OPEN_ADDRESS_INDEX]: setOpenAddressIndex,
  [actions.UPDATE_PICK_UPS_AND_DELIVERIES_SHIPMENT_STATUS_SUCCESS]: updatePickupAndDeliveryShipmentStatus,
  [actions.UPDATE_PICK_UPS_AND_QUICK_FILTER_SUCCESS]: updatePickupAndDeliverQuickFilterItem,
  [actions.ADD_PICK_UPS_AND_QUICK_FILTER_SUCCESS]: addPickupAndDeliverQuickFilterItem,
  [actions.UPDATE_PICK_UPS_AND_QUICK_FILTERS_SUCCESS]: updatePickupAndDeliverQuickFilterItems,
  [actions.DELETE_PICK_UPS_AND_QUICK_FILTER_SUCCESS]: deletePickupAndDeliverQuickFilterItem,
  [actions.PICK_UPS_AND_DELIVERIES_UPDATE_ITEM_SUCCESS]: updatePickupAndDeliveryItem,
  [actions.PICK_UPS_AND_DELIVERIES_SELECTED_IDS]: setSelectedIds,
})

import { FunctionComponent, memo } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Drawer from 'src/stories/Drawer'
import { shipmentsOverviewSetDrawer } from 'src/stores/actionCreators'
import TrackAndTraceSubscriptionsTable from './TrackAndTraceSubscriptionsTable'
import CarrierBlock from './CarrierBlock'

const ShipmentOverviewDrawerBody: FunctionComponent<{}> = () => (
  <>
    <TrackAndTraceSubscriptionsTable />
    <CarrierBlock />
  </>
)

const ShipmentOverviewDrawer: FunctionComponent<{}> = () => {
  const dispatch = useDispatch()

  const { shipmentOverview } = useSelector(
    (state: IGlobalState) => ({
      shipmentOverview: state.shipmentOverview,
    }),
    shallowEqual
  )

  const onClose = () => {
    dispatch(shipmentsOverviewSetDrawer(false))
  }

  return (
    <Drawer
      data-testid="shipment-overview-drawer"
      heading={`Track and trace details for ${shipmentOverview.title}`}
      open={shipmentOverview.drawerOpen}
      onClose={onClose}
      children={<ShipmentOverviewDrawerBody />}
    />
  )
}
export default memo(ShipmentOverviewDrawer, () => true)

import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Link as MuiLink } from '@mui/material'
import { stringify } from 'query-string'

import { permissionTo } from '../../../utils'

interface IProps {
  purchaseOrder: IPurchaseOrder
}

const ShipmentRowDetails: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const openItemQueryString: string = stringify(
    { open_item: props.purchaseOrder.id },
    { arrayFormat: 'bracket' }
  )

  return (
    <div className="purchase-order__details-header-action-ship paragraph__medium lightblue">
      {permissionTo('purchase_orders.view') ? (
        <MuiLink
          variant="body1"
          component={Link}
          to={`/order-management?${openItemQueryString}`}
        >
          {t(
            'purchase_orders.row_details.go_to_complete_order',
            'Go to complete order'
          )}
        </MuiLink>
      ) : (
        <span className="paragraph__medium disabled">
          {t(
            'purchase_orders.row_details.go_to_complete_order',
            'Go to complete order'
          )}
        </span>
      )}
    </div>
  )
}

export default ShipmentRowDetails

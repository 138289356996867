import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import endpoints, { queryKeys } from '../endpoints'
import { apiClient } from '../http-common'
import { createQueryString } from '../utils'
import {
  IPickupAndDeliveryResponse,
  ITaskManagerResponse,
  ITasksResponse,
  IUpdatedByOthersResponse,
} from '../../@types/endpoints/dashboard'

export function useGetPickupDelivery(
  payload: object = {},
  options: UseQueryOptions<
    IPickupAndDeliveryResponse,
    AxiosError,
    IPickupAndDeliveryResponse
  > = {}
) {
  const { dashboardPickupDelivery: key } = queryKeys
  const queryParams = createQueryString(payload)
  const url = endpoints.dashboardPickupDelivery + queryParams

  const getPickupDelivery = () =>
    apiClient.get<any, IPickupAndDeliveryResponse>(url, payload)

  return useQuery<IPickupAndDeliveryResponse, AxiosError>(
    [key, queryParams],
    getPickupDelivery,
    options
  )
}

export function useGetUpdatedByOthersShipments(
  payload: object = {},
  options: UseQueryOptions<
    IUpdatedByOthersResponse,
    AxiosError,
    IUpdatedByOthersResponse
  > = {}
) {
  const { dashboardUpdatedByOthers: key } = queryKeys
  const queryParams = createQueryString(payload)
  const url = endpoints.dashboardUpdatedByOthers + queryParams

  const getUpdatedByOthersShipments = () =>
    apiClient.get<any, IUpdatedByOthersResponse>(url, payload)

  return useQuery<IUpdatedByOthersResponse, AxiosError>(
    [key, queryParams],
    getUpdatedByOthersShipments,
    options
  )
}

export function useGetTasks(
  payload = {},
  options: UseQueryOptions<ITasksResponse, AxiosError, ITasksResponse> = {}
) {
  const { dashboardTasks: key } = queryKeys

  const queryParams = createQueryString(payload)
  const url = endpoints.dashboardTasks + queryParams

  const getTasks = () => apiClient.get<any, ITasksResponse>(url, payload)

  return useQuery<ITasksResponse, AxiosError>(
    [key, queryParams],
    getTasks,
    options
  )
}

export function useGetTaskManager(
  payload: object = {},
  options: UseQueryOptions<
    ITaskManagerResponse,
    AxiosError,
    ITaskManagerResponse
  > = {}
) {
  const { dashboardTaskManager: key } = queryKeys
  const queryParams = createQueryString(payload)
  const url = endpoints.dashboardTaskManager + queryParams

  const getTaskManager = () =>
    apiClient.get<any, ITaskManagerResponse>(url, payload)

  return useQuery<ITaskManagerResponse, AxiosError>(
    [key, queryParams],
    getTaskManager,
    options
  )
}

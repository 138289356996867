import { isEmpty, isEqual, isNil, omit, omitBy, pick } from 'lodash'
import { parse, stringify } from 'query-string'
import { history } from 'src/shyppleStore'
import { FilterContext, FilterProps } from './types'

export const updateUrl = (page, params: object) => {
  const search = stringify(params, {
    arrayFormat: 'bracket',
    skipEmptyString: true,
    skipNull: true,
  })
  window.localStorage.setItem(`${page}_filters_query`, search)
  history.push({ search })
}

export const getFilterId = (page: FilterProps['page']) => {
  const { filter } = parse(
    window.location.search ||
      window.localStorage.getItem(`${page}_filters_query`) ||
      '',
    { arrayFormat: 'bracket' }
  )
  return filter ? +filter : undefined
}

export const updateUrlOnFilterChange = (
  page: FilterProps['page'],
  filterId: FilterContext['filterId'],
  defaultFiltersKeys: FilterContext['defaultFiltersKeys'] = []
) => {
  const urlParams = parse(window.location.search)
  updateUrl(page, { ...pick(urlParams, defaultFiltersKeys), filter: filterId })
}

export const extractAnOptionFromFilterOptions = (
  options: FilterContext['filterOptions'],
  key: string
): { label: string; id: string }[] => {
  return (
    options?.[key]?.map(([label, id]) => {
      return { label: `${label}`, id: `${id}` }
    }) || []
  )
}

export const filterIsUnchanged = (
  filters: FilterContext['filters'],
  quickFilter: FilterContext['quickFilter'],
  initialFilters: FilterContext['initialFilters'],
  defaultFiltersKeys: FilterContext['defaultFiltersKeys'] = []
) => {
  const trimmedFilters = omitBy(
    omit(filters, defaultFiltersKeys),
    (e) => isNil(e) || isEmpty(e)
  )
  const trimmedQuickFilters = omitBy(
    quickFilter?.filter,
    (e) => isNil(e) || isEmpty(e)
  )

  const filterIsSameAsInitialFilter = isEqual(
    trimmedFilters,
    omit(initialFilters, defaultFiltersKeys)
  )
  const filterIsSameAsQuickFilter = isEqual(trimmedQuickFilters, trimmedFilters)

  return { filterIsSameAsInitialFilter, filterIsSameAsQuickFilter }
}

import { FunctionComponent } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

import 'react-select/dist/react-select.css'

import {
  Theme,
  IFilterOptionValue,
  IFilterOptionWithName,
  IFilterOptionWithDescription,
  IFilterOptionWithTitle,
} from '../interfaces'
import chevronArrow from '../ChevronArrow'

interface ISingleProps {
  className?: string | null
  placeholder?: string | null
  dataTestid?: string | null
  value: string | number
  onChange: (e: React.ChangeEvent<HTMLInputElement>, extendedParam: any) => void
  options: IFilterOptionValue[]
  disabled?: boolean
  labelKey?: string
  valueKey?: string
  theme?: Theme
  error?: boolean
  searchable?: boolean
  extendedParam?: any
}

const Single: FunctionComponent<any> = (props: ISingleProps) => {
  const onSelect = (data) => {
    props.onChange(data, props.extendedParam)
  }

  const filterOptions = (option: IFilterOptionValue, input: string) => {
    const inputValidation: RegExp = /^[a-z0-9\s]+$/i
    if (input && !inputValidation.test(input)) {
      return false
    }
    if (input) {
      const personRegExp: RegExp = new RegExp(input.toLowerCase())
      const name =
        (option as IFilterOptionWithName).name ||
        (option as IFilterOptionWithDescription).description ||
        (option as IFilterOptionWithTitle).title
      return (
        personRegExp.test(name.toLowerCase()) ||
        (!option.id && !option.disabled)
      )
    }
    return true
  }

  return (
    <div data-testid={props.dataTestid} style={{ width: '100%' }}>
      <Select
        className={`custom-select ${props.className} ${props.theme} ${
          props.error ? 'error' : ''
        }`}
        value={props.value}
        placeholder={props.placeholder}
        valueKey={props.valueKey || 'id'}
        labelKey={props.labelKey || 'name'}
        clearable={false}
        autoBlur={false}
        searchable={props.searchable}
        onChange={onSelect}
        options={props.options}
        disabled={props.disabled}
        arrowRenderer={chevronArrow}
        filterOption={filterOptions}
      />
    </div>
  )
}

Single.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
}

Single.defaultProps = {
  className: '',
  value: '',
  placeholder: '',
  options: [],
  disabled: false,
  searchable: true,
  theme: 'default',
  error: false,
}

export default Single

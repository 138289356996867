import { Button } from '@mui/material'
import { FunctionComponent, useCallback } from 'react'
import { useSelector } from 'react-redux'

import useLocalStorage from 'src/hooks/useLocalStorage'
import { permissionTo } from 'src/utils'

const FilterOrganizationButton: FunctionComponent<{}> = () => {
  const { modalState } = useSelector((state: IGlobalState) => ({
    modalState: state.reportsDashboard.commentEditModal,
  }))

  const [
    organization,
    setOrganization,
  ] = useLocalStorage<IOrganizationShort | null>('scopedOrganization', null)

  const applyFilter = useCallback(() => {
    if (
      organization &&
      organization.id === modalState.shipment?.owner_organization.id
    ) {
      return
    }
    setOrganization({
      id: modalState.shipment?.owner_organization.id,
      name: modalState.shipment?.owner_organization.name,
      prefix: '',
      logo: '',
      role_code: '',
    })
    window.location.reload()
  }, [modalState])

  if (!permissionTo('menu.account.application_filter')) {
    return null
  }

  return (
    <Button variant="contained" onClick={applyFilter}>
      Filter application
    </Button>
  )
}

export default FilterOrganizationButton

import { FunctionComponent, useMemo } from 'react'
import { Tooltip, Typography } from '@mui/material'
import { formatDate } from 'src/utils/helpers'
import { RatesListTypeEnum } from 'src/@types/endpoints/prices'
import { Avatar } from 'src/stories/Avatar'
import Table from 'src/components/Common/Table'
import { roundPrice } from '../helpers'

import '../index.scss'

interface IProps {
  item: IRate
  index: number
  opened: boolean
  status: 'on_request' | 'future' | 'historic' | 'valid'
  containerCodes: string[]
  codes: string[]
}

const RatesLineHeader: FunctionComponent<IProps> = (props) => {
  const { item, index, status, codes, containerCodes } = props

  const renderLclDetails = () => {
    const { currency, value } = item as ILclRate
    return (
      <>
        <Table.StripedTableCell className="left-aligned short-70">
          {currency || '-'}
        </Table.StripedTableCell>
        <Table.StripedTableCell className="left-aligned x-medium">
          {value || '-'}
        </Table.StripedTableCell>
      </>
    )
  }

  const renderPortData = (portData: IPort) => {
    const { name, country } = portData
    return (
      <Typography noWrap>
        <span>{name}</span>
        {props.opened && <span>{` (${country.name || '-'})`}</span>}
      </Typography>
    )
  }

  const renderOrganizationAvatar = () => {
    const {
      name,
      logo,
      organization_role_code,
      role_code,
    } = item.seller_organization
    return (
      <Tooltip
        title={name}
        placement="top-end"
        classes={{
          tooltip: 'mui-override',
        }}
      >
        <div style={{ width: 40 }}>
          <Avatar
            alt={name}
            src={logo || 'no-logo'}
            className={organization_role_code || role_code}
          />
        </div>
      </Tooltip>
    )
  }

  const renderFclDetails = useMemo(() => {
    const allCurrencies: string[] = []
    const { containers } = item as IFclRate
    if (item.list_type !== RatesListTypeEnum.fcl) return
    if (codes.length) {
      codes.forEach((code) => {
        const currency = containers[code]?.currency
        if (currency && !allCurrencies.includes(currency)) {
          allCurrencies.push(currency)
        }
      })
    }

    return (
      <>
        <Table.StripedTableCell className="left-aligned short-70">
          {allCurrencies.length === 1 ? allCurrencies[0] : '-'}
        </Table.StripedTableCell>
        {containerCodes.map((code) => (
          <Table.StripedTableCell
            key={code + index + item.list_type}
            className="center-aligned medium"
          >
            {roundPrice(containers[code]?.value) || '-'}
          </Table.StripedTableCell>
        ))}
      </>
    )
  }, [item, containerCodes])

  const dataCellClassName =
    item.list_type === RatesListTypeEnum.air
      ? 'left-aligned wide'
      : 'left-aligned x-medium'

  return (
    <Table.StripedTableRow index={0}>
      <Table.StripedTableCell className="center-aligned short-50">
        {renderOrganizationAvatar()}
      </Table.StripedTableCell>
      <Table.StripedTableCell className={dataCellClassName}>
        {renderPortData(item.loading_port)}
      </Table.StripedTableCell>
      <Table.StripedTableCell className={dataCellClassName}>
        {renderPortData(item.discharge_port)}
      </Table.StripedTableCell>
      <Table.StripedTableCell className={dataCellClassName}>
        <Typography variant="body1Strong" noWrap>
          {item.carrier?.name || (status === 'on_request' ? '-' : 'Various')}
        </Typography>
      </Table.StripedTableCell>
      <Table.StripedTableCell className="left-aligned wide">
        {`${formatDate(item.valid_from) + ' - ' + formatDate(item.valid_to)}`}
      </Table.StripedTableCell>

      {item.list_type !== RatesListTypeEnum.fcl && renderLclDetails()}
      {item.list_type === RatesListTypeEnum.fcl && renderFclDetails}
      <Table.StripedTableCell className="fill-width">
        <div className="po-toggle">
          <i className={`icon chevron ${props.opened ? '' : 'collapsed'}`} />
        </div>
      </Table.StripedTableCell>
    </Table.StripedTableRow>
  )
}

export default RatesLineHeader

import { FunctionComponent } from 'react'
import { Avatar, AvatarSize } from 'src/stories/Avatar'
import { PopperPlacementType } from '@mui/material'
import apiIntegrationLogo from '../../assets/images/api-integration.png'
import anonymousUserLogo from '../../assets/images/anonymous-user.png'

import './styles.scss'

interface IAuthorAvatarProps {
  author: IAuthor | IChatUser
  placement?: PopperPlacementType
  size?: AvatarSize
}

const AuthorAvatar: FunctionComponent<IAuthorAvatarProps> = ({
  author,
  size,
  placement,
}) => {
  let authorSrc: string | null = author.avatar_thumb
  const authorFirstName: string = author.first_name || ''
  const authorLastName: string = author.last_name || ''
  const authorLastNameFirstLetter: string =
    authorLastName?.match(/\p{Lu}/gu)?.[0] || ''

  if (author.anonymous_user) {
    authorSrc = anonymousUserLogo
  } else if (author.api_integration) {
    authorSrc = apiIntegrationLogo
  }

  const authorInitials: string = `${authorFirstName[0]}${authorLastNameFirstLetter}`

  return (
    <Avatar
      userCard
      userInfo={{
        name: `${authorFirstName} ${authorLastName}`,
        department: author.department || '',
        phone: author.phone || '',
        company: author.organization.name,
        out_of_office_till: author.out_of_office_till,
      }}
      size={size}
      src={authorSrc || 'no-logo'}
      alt={authorInitials}
      doubleInitials={true}
      className={author.organization.role_code}
      placement={placement}
    />
  )
}

export default AuthorAvatar

import { withStyles } from '@mui/styles'
import { QueryClientProvider } from '@tanstack/react-query'
import { FunctionComponent, Suspense } from 'react'
import { Route, Switch } from 'react-router'
import ResetPassword from 'src/components/AccountInfo/ForgotPassword'
import HelmetInjector from 'src/components/HelmetInjector'
import SnackbarNotifications from 'src/components/Snackbar'
import { CSS_THEME_VARIABLES } from 'src/config/constants'
import UnhandledException from 'src/pages/errors/UnhandledException'
import ForgotPassword from 'src/pages/ForgotPassword'
import Layout from 'src/pages/Layout'
import Loader from 'src/pages/Layout/Loader'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import NotFound from 'src/pages/NotFound'
import PublicPOPage from 'src/pages/PublicPOPage'
import Login from 'src/pages/Login'
import ShipmentLink from 'src/pages/ShipmentLink'
import TokenExpired from 'src/pages/TokenExpired'
import ResetPasswordEmailRequest from 'src/pages/Password/ResetPasswordRequest'
import CSATResponse from 'src/pages/CSATResponse'
import './App.scss'
import AuthWrapper from './AuthWrapper'
import { queryClient } from './services/http-common'

const LayoutComponent = () => (
  <AuthWrapper>
    <Layout />
  </AuthWrapper>
)

const App: FunctionComponent<{}> = () => {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <SnackbarNotifications />
        <HelmetInjector />
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/public-shipments/:token" component={ShipmentLink} />
            <Route
              path={['/public-purchase-orders/:token', '/public-orders/:token']}
              component={PublicPOPage}
            />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/change-password" component={ResetPassword} />
            <Route
              path="/reset-password-link-expired"
              component={TokenExpired}
            />
            <Route
              path="/reset-password-request"
              component={ResetPasswordEmailRequest}
            />
            <Route path="/csat-response" component={CSATResponse} />
            <Route path="/500" component={UnhandledException} />
            <Route path="/404" component={NotFound} />
            <Route render={LayoutComponent} />
          </Switch>
        </Suspense>
      </QueryClientProvider>
    </div>
  )
}

export default withStyles(CSS_THEME_VARIABLES)(App)

import { find, get } from 'lodash'

type TabMap = [IReportsDashboardScope, RegExp]
const tabMap: TabMap[] = [
  ['bookings_placed', /\/operations-dashboard\/bookings-placed\/?/],
  ['schedule_tba', /\/operations-dashboard\/schedule-tba\/?/],
  ['on_hold', /\/operations-dashboard\/on-hold\/?/],
  ['schedule_announced', /\/operations-dashboard\/schedule-announced\/?/],
  ['booking_confirmed', /\/operations-dashboard\/booking-confirmed\/?/],
  ['inactive_t_n_t', /\/operations-dashboard\/inactive-t-n-t\/?/],
  ['rollings', /\/operations-dashboard\/rollings\/?/],
  ['handover', /\/operations-dashboard\/handover\/?/],
  ['deliveries_lcl', /\/operations-dashboard\/deliveries-lcl\/?/],
  ['deliveries_fcl', /\/operations-dashboard\/deliveries-fcl\/?/],
  ['recent_deliveries', /\/operations-dashboard\/recent-deliveries\/?/],
]

const isMatch = (route: string, [field, regex]: any[]): boolean =>
  regex.test(route)

export default (path: string): IReportsDashboardScope =>
  get(find(tabMap, isMatch.bind(null, path)), 0, 'bookings_placed')

// @ts-nocheck

import { FunctionComponent, Fragment, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import Chat from 'src/components/Chat'

interface IProps {
  match: IMatch | null
  shipmentId?: number
}

const PrivateChat: FunctionComponent<IProps> = (props) => {
  const [shipmentId, setShipmentId] = useState<number>(
    props.shipmentId || parseInt(props.match!.params.id, 10)
  )
  const [chatId, setChatId] = useState<number>(0)

  const { chats } = useSelector((state: IGlobalState) => ({
    chats: state.chat.chats,
  }))

  useEffect(() => {
    setShipmentId(props.shipmentId || parseInt(props.match!.params.id, 10))
  }, [props.shipmentId, props.match!.params.id])

  useEffect(() => {
    const privateChat: IChatIdentifier | undefined = chats.find(
      (x) => x.chat_type === 'private_chat'
    )

    if (privateChat) {
      setChatId(privateChat.id)
    }
  }, [chats])

  return chatId ? (
    <Fragment>
      <Chat chatId={chatId} shipmentId={shipmentId} match={props.match} />
    </Fragment>
  ) : null
}

export default PrivateChat

import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useGetShipmentTemplate } from 'src/services/Api/shipmentTemplates'
import ShipmentTemplateForm from 'src/components/Templates/Form'
import { usePageDataFetched } from '../Api'
import TemplatesLayout from '../Layout'

const TemplatesRebook = () => {
  const pageDataFetched = usePageDataFetched()
  const params = useParams<{ id: string }>()

  const {
    data: templateData,
    isFetched: templateFetched,
    isFetching,
  } = useGetShipmentTemplate({
    templateId: Number(params.id),
  })

  const isFetched = templateFetched && pageDataFetched && !isFetching

  const currentPage = templateData?.name ?? ''

  const { t } = useTranslation()

  return (
    <TemplatesLayout
      content={
        <ShipmentTemplateForm
          templateData={templateData}
          isLoading={!isFetched}
          mode="rebook"
        />
      }
      currentPage={`${t(
        'templates.sub_heading',
        'Rebook from template'
      )} "${currentPage}"`}
    />
  )
}

export default TemplatesRebook

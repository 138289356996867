import { useState } from 'react'
import Box from '@mui/material/Box'
import { useHistory } from 'react-router'
import Menu from '@mui/material/Menu'
import Button from '@mui/material/Button'
import { useDispatch } from 'react-redux'
import ConfirmDialog from 'src/components/ConfirmDialog'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { MenuItemWithIcon } from 'src/stories/Lab/Menu/components/MenuItemWithIcon'
import { useDeleteShipmentTemplate } from 'src/services/Api/shipmentTemplates'
import EditIcon from '@mui/icons-material/Edit'
import { Delete, MoreVert } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

const TemplatesListItemActions = ({ id, name }) => {
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false)
  const dispatch = useDispatch()
  const { mutateAsync: deleteTemplate, isLoading } = useDeleteShipmentTemplate()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const closeDropDown = () => {
    setAnchorEl(null)
  }

  const openDeleteConfirmDialog = () => {
    setDeleteConfirmDialogOpen(true)
    closeDropDown()
  }

  const closeDeleteConfirmDialog = () => {
    setDeleteConfirmDialogOpen(false)
  }

  const onClickEdit = () => {
    history.push(`/templates/${id}/edit`)
    closeDropDown()
  }

  const onDeleteClick = () => {
    deleteTemplate(+id)
      .then(() => {
        dispatch(
          showNotification({
            message: `Template "${name}" deleted`,
            severity: 'success',
          })
        )
      })
      .catch(() => {
        dispatch(
          showNotification({
            message: `Template "${name}" could not be deleted`,
            severity: 'error',
          })
        )
      })
    closeDeleteConfirmDialog()
  }

  const { t } = useTranslation()

  return (
    <Box
      onClick={(event) => {
        event.preventDefault()
      }}
      display="flex"
      justifyContent="flex-end"
    >
      <Button
        id="templates-item-actions"
        aria-controls={open ? 'templates-item-actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        disableElevation
        onClick={handleClick}
        startIcon={<MoreVert />}
        data-testid="templates-item-actions"
      >
        {t('common.more', 'More')}
      </Button>
      <Menu
        id="templates-item-actions-menu"
        MenuListProps={{
          'aria-labelledby': 'templates-item-actions',
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={closeDropDown}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItemWithIcon
          onClick={onClickEdit}
          icon={EditIcon}
          text={t('templates.edit', 'Edit')}
          data-testid="edit-template"
        />
        <MenuItemWithIcon
          onClick={openDeleteConfirmDialog}
          icon={Delete}
          text={t('templates.delete', 'Delete')}
          disabled={isLoading}
          data-testid="delete-template"
        />
      </Menu>
      <ConfirmDialog
        title="Delete template"
        message="Do you really want to delete this template?"
        isOpen={deleteConfirmDialogOpen}
        confirm={onDeleteClick}
        reject={closeDeleteConfirmDialog}
        onClose={closeDeleteConfirmDialog}
      />
    </Box>
  )
}

export default TemplatesListItemActions

import { extractLabelFromPayload, getStyles } from './helpers'

type PropTypes = Partial<{
  x: number
  y: number
  payload: { value: string }
}>

const CustomizedAxisTick = ({ x, y, payload }: PropTypes) => {
  const { fillValue, fontWeight } = getStyles(payload?.value || '')
  const label = extractLabelFromPayload(payload?.value)

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        style={{ fontWeight }}
        fill={fillValue}
      >
        {label}
      </text>
    </g>
  )
}

export default CustomizedAxisTick

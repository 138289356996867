import EmojiNatureIcon from '@mui/icons-material/EmojiNature'
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrill'
import { ITab } from './Tasks.props'

export enum PathNames {
  Account = '/account',
  MyShipments = '/my_shipments',
  Notifications = '/notifications',
  OutOfOffice = '/out_of_office',
}

const emptyStateButton = (t) => ({
  text: t('top_navigation.tasks.empty_state_button', 'Go to task settings'),
  link: '/account',
})

export const taskTab = {
  root: 'tasks__tab mui-override',
  selected: 'tasks__tab_selected mui-override',
}

export const contentWidth = 500

export const tabs = (t): ITab[] => [
  {
    label: t('top_navigation.tasks.your_open_tasks.label', 'Your open tasks'),
    value: 'your_open_tasks',
    emptyState: {
      badgeColor: 'warning',
      badgeIcon: EmojiNatureIcon,
      title: t(
        'top_navigation.tasks.your_open.empty_state_title',
        "Someone's been a busy bee"
      ),
      button: emptyStateButton(t),
      description: t(
        'top_navigation.tasks.your_open.empty_state_description',
        'You seem to have no open tasks. Buzz on over to All open tasks or change your task settings for more.'
      ),
    },
  },
  {
    label: t('top_navigation.tasks.all_open_tasks.label', 'All open tasks'),
    value: 'all_open_tasks',
    emptyState: {
      badgeColor: 'info',
      badgeIcon: OutdoorGrillIcon,
      title: t(
        'top_navigation.tasks.all_open_tasks.empty_state_title',
        "It's barbeque time!"
      ),
      button: emptyStateButton(t),
      description: t(
        'top_navigation.tasks.all_open_tasks.empty_state_description',
        'Everyone has been very productive – there are no open tasks. Change your task settings or light the fire so long.'
      ),
    },
  },
]

export const orderHeaders = (t) => [
  {
    name: t('purchase_orders.table.headers.po_number', 'PO NUMBER'),
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned x-medium',
  },
  {
    name: t('purchase_orders.table.headers.shipper', 'SHIPPER'),
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned wide',
  },
  {
    name: t('purchase_orders.table.headers.incoterm', 'INCOTERM'),
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  {
    name: t('purchase_orders.table.headers.from_pol', 'FROM (POL)'),
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  {
    name: t('purchase_orders.table.headers.order_date', 'ORDER DATE'),
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  {
    name: t('purchase_orders.table.headers.ex_factory', 'EX-FACTORY'),
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  {
    name: t('purchase_orders.table.headers.cargo_ready', 'CARGO READY'),
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  {
    name: t('purchase_orders.table.headers.volume', 'VOLUME'),
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  {
    name: t('purchase_orders.table.headers.weight', 'WEIGHT'),
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
]

export const lineHeaders = (t) => [
  {
    name: '',
    sortable: false,
    sortValue: '',
    customClassName: 'short-50',
  },
  {
    name: t('purchase_orders.table.headers.product_name', 'PRODUCT NAME'),
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned wide',
  },
  {
    name: t('purchase_orders.table.headers.product_code', 'PRODUCT CODE'),
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  {
    name: t('purchase_orders.table.headers.volume', 'VOLUME'),
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  {
    name: t('purchase_orders.table.headers.weight', 'WEIGHT'),
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  {
    name: t('purchase_orders.table.headers.status', 'STATUS'),
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  {
    name: t('purchase_orders.table.headers.shipment', 'SHIPMENT'),
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  {
    name: '',
    sortable: false,
    sortValue: '',
    customClassName: '',
  },
  {
    name: '',
    sortable: false,
    sortValue: '',
    customClassName: '',
  },
  {
    name: '',
    sortable: false,
    sortValue: '',
    customClassName: '',
  },
]

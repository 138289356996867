import i18next from 'i18next'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import FormLabel from 'src/stories/Lab/FormLabel'
import { useEffect, useRef, useState, useMemo } from 'react'
import { DateTime } from 'luxon'
import {
  Box,
  Divider,
  Stack,
  Typography,
  IconButton,
  ClickAwayListener,
  Popper,
} from '@mui/material'
import {
  deDE,
  frFR,
  esES,
  nlNL,
  enUS,
  PickersLocaleText,
} from '@mui/x-date-pickers/locales'
import CloseIcon from '@mui/icons-material/Close'
import { StaticDatePicker, StaticDatePickerSlots } from '@mui/x-date-pickers'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import Paper from 'src/components/Paper'
import { useTranslation } from 'react-i18next'

// styles are in src/assets/styles/general.scss

const FROM_FORMAT = 'dd-MM-yyyy'

const localTexts = {
  en: enUS,
  de: deDE,
  fr: frFR,
  es: esES,
  nl: nlNL,
}

interface Props {
  calendarSlots?: StaticDatePickerSlots<DateTime<boolean>>
  label?: string
  placeholder?: string
  value: DateTime | string | null
  inputFormat?: string
  disablePast?: boolean
  disableFuture?: boolean
  clearable?: boolean
  disabled?: boolean
  minDate?: DateTime
  maxDate?: DateTime
  error?: boolean
  open?: boolean
  width?: number | string
  closeOnSelect?: boolean
  'data-testid'?: string
  onClose?: (value: DateTime | null, key?: string) => void
  onChange: (value: DateTime | null, fromKeyboard: boolean) => void
  onClick?: () => void
  localeText?: Partial<PickersLocaleText<DateTime<false> | DateTime<true>>>
}

const stringToDateTime = (str?: string | null) =>
  str ? DateTime.fromISO(str) : null

const DateUniversalPicker = ({
  label,
  placeholder = 'DD-MM-YYYY',
  inputFormat = FROM_FORMAT,
  disableFuture = false,
  disablePast = false,
  clearable = true,
  disabled = false,
  minDate = undefined,
  maxDate = undefined,
  error = false,
  open = false,
  width = '100%',
  closeOnSelect = true,
  onChange,
  onClick,
  calendarSlots,
  localeText = {},
  ...props
}: Props) => {
  const ref = useRef()
  const { t } = useTranslation()
  const [isOpen, setOpen] = useState(false)
  const hasTime = inputFormat.includes('HH:mm')

  const value = useMemo(() => {
    return typeof props.value === 'string'
      ? stringToDateTime(props.value)
      : props.value
  }, [props.value])

  useEffect(() => {
    if (ref.current && open) {
      setOpen(true)
    }
  }, [ref.current])

  const onClear = (e) => {
    e.stopPropagation()
    onChange(null, false)
  }

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = (event) => {
    setOpen(false)
    props.onClose && props.onClose(value, event?.key)
  }

  const onDateTimeChange = (value: DateTime | null, fromKeyboard = false) => {
    onChange(value, fromKeyboard)
    if (closeOnSelect) {
      handleClose({ key: null })
    }
  }

  const onKeyUp = (event) => {
    if (['Enter'].includes(event.key)) {
      handleClose(event)
    }
    if (['Escape'].includes(event.key)) {
      props.onClose && props.onClose(value, event.key)
      setOpen(false)
    }
  }

  const language = i18next?.language ?? 'en'

  const customeLocaleText = localTexts?.[language] ?? enUS

  const adapterLocale = language === 'en' ? 'en-GB' : language

  return (
    <LocalizationProvider
      dateAdapter={AdapterLuxon}
      adapterLocale={adapterLocale}
      localeText={{
        ...customeLocaleText.components.MuiLocalizationProvider.defaultProps
          .localeText,
        calendarWeekNumberHeaderText: '',
        calendarWeekNumberText: (weekNumber) => (
          <Box
            title={t(
              'calendar_component.week_number_title',
              {
                number: weekNumber,
              },
              'Week {{number}}'
            )}
          >
            {weekNumber}
          </Box>
        ),
        ...localeText,
      }}
    >
      {label && <FormLabel label={label} />}
      <Box
        ref={ref}
        sx={{
          display: 'flex',
          cursor: 'pointer',
          pointerEvents: disabled ? 'none' : 'auto',
        }}
        onClick={handleClick}
        width={width}
      >
        <DatePicker
          value={value}
          displayWeekNumber
          format={inputFormat}
          disablePast={disablePast}
          minDate={minDate}
          maxDate={maxDate}
          disableFuture={disableFuture}
          disabled={disabled}
          onChange={(date) => onDateTimeChange(date, true)}
          slotProps={{
            textField: {
              onKeyUp,
              sx: {
                width: '100%',
              },
              inputProps: {
                'data-testid': props['data-testid'],
              },
              InputProps: {
                endAdornment: (
                  <>
                    {clearable && value && (
                      <IconButton
                        sx={{ position: 'absolute', right: '3px' }}
                        size="small"
                        onClick={onClear}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </>
                ),
              },
            },
          }}
        />
      </Box>
      <Popper
        open={isOpen}
        data-testid="datepicker-popover"
        anchorEl={ref.current}
        placement="bottom-start"
        sx={{
          zIndex: 1301,
          boxShadow: 8,
          borderRadius: 1,
          overflow: 'hidden',
          '& .MuiPickersCalendarHeader-label': {
            textTransform: 'capitalize',
          },
        }}
      >
        <ClickAwayListener onClickAway={() => handleClose({ key: null })}>
          <div>
            <Paper>
              <Stack>
                <StaticDatePicker
                  displayWeekNumber
                  minDate={minDate}
                  maxDate={maxDate}
                  disablePast={disablePast}
                  disableFuture={disableFuture}
                  displayStaticWrapperAs="desktop"
                  value={value}
                  autoFocus={false}
                  onChange={(date) => onDateTimeChange(date, false)}
                  slots={calendarSlots}
                />
                <Divider />
                <Box>
                  {hasTime && (
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      p="12px"
                      justifyContent="space-between"
                    >
                      <Stack spacing={1} direction="row" alignItems="center">
                        <AccessTimeIcon />
                        <Typography
                          noWrap
                          children="Time"
                          variant="body1Strong"
                          data-testid="form-label-text"
                        />
                      </Stack>
                      <TimePicker
                        value={value}
                        ampm={false}
                        minTime={minDate}
                        maxTime={maxDate}
                        onChange={(date) => onDateTimeChange(date, false)}
                        slotProps={{
                          textField: {
                            onKeyUp,
                            inputProps: {
                              'data-testid': 'time-picker-input',
                            },
                            InputProps: {
                              endAdornment: null,
                            },
                          },
                        }}
                      />
                    </Stack>
                  )}
                </Box>
              </Stack>
            </Paper>
          </div>
        </ClickAwayListener>
      </Popper>
    </LocalizationProvider>
  )
}

export default DateUniversalPicker

import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/dashboard'
import * as actionCreators from '../../actionCreators/dashboard'
import { simpleGetAndResolve } from '../factories'

const getDashboardTaskManager = simpleGetAndResolve(
  () => 'api/v1/dashboard/task_manager',
  actionCreators.fetchDashboardTaskManagerSuccess,
  (response) => response.data.data
)

const getDashboardTasks = simpleGetAndResolve(
  (action) => `api/v1/dashboard/tasks`,
  actionCreators.fetchDashboardTasksSuccess,
  (response) => response.data
)

const getPickupAndDelivery = simpleGetAndResolve(
  (action) => 'api/v1/dashboard/pickup_delivery',
  actionCreators.fetchDashboardPickupAndDeliverySuccess,
  (response) => response.data
)

const getDashboardUpdatedByYouData = simpleGetAndResolve(
  () => 'api/v1/dashboard/updated_by_you',
  actionCreators.fetchDashboardUpdatedByYouSuccess,
  (response) => response.data.data
)

const getDashboardUpdatedByOthersData = simpleGetAndResolve(
  () => 'api/v1/dashboard/updated_by_others',
  actionCreators.fetchDashboardUpdatedByOthersSuccess,
  (response) => response.data.data
)

const getDashboardArrivingData = simpleGetAndResolve(
  () => 'api/v1/dashboard/arriving',
  actionCreators.fetchDashboardArrivingSuccess,
  (response) => response.data.data
)

const getDashboardDeparturingData = simpleGetAndResolve(
  () => 'api/v1/dashboard/departing',
  actionCreators.fetchDashboardDepartingSuccess,
  (response) => response.data.data
)

const getDashboardRecentlyBookedData = simpleGetAndResolve(
  () => 'api/v1/dashboard/recently_booked',
  actionCreators.fetchDashboardRecentlyBookedSuccess,
  (response) => response.data.data
)

const getSupplyChainData = simpleGetAndResolve(
  () => 'api/v1/dashboard/supply_chain',
  actionCreators.fetchDashboardSupplyChainSuccess,
  (response) => response.data.data
)

const getSupplyChainShipmentsData = simpleGetAndResolve(
  (action) => `api/v1/dashboard/supply_chain/${action.payload.statusGroup}`,
  actionCreators.fetchDashboardSupplyChainShipmentsSuccess,
  (response) => response.data.data
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.FETCH_DASHBOARD_TASK_MANAGER, getDashboardTaskManager)
  yield takeEvery(actions.FETCH_DASHBOARD_TASKS, getDashboardTasks)
  yield takeEvery(
    actions.FETCH_DASHBOARD_PICKUP_AND_DELIVERY,
    getPickupAndDelivery
  )

  yield takeEvery(
    actions.FETCH_DASHBOARD_UPDATED_BY_YOU,
    getDashboardUpdatedByYouData
  )
  yield takeEvery(
    actions.FETCH_DASHBOARD_UPDATED_BY_OTHERS,
    getDashboardUpdatedByOthersData
  )
  yield takeEvery(actions.FETCH_DASHBOARD_ARRIVING, getDashboardArrivingData)
  yield takeEvery(
    actions.FETCH_DASHBOARD_DEPARTING,
    getDashboardDeparturingData
  )
  yield takeEvery(
    actions.FETCH_DASHBOARD_RECENTLY_BOOKED,
    getDashboardRecentlyBookedData
  )

  yield takeEvery(actions.FETCH_DASHBOARD_SUPPLY_CHAIN, getSupplyChainData)
  yield takeEvery(
    actions.FETCH_DASHBOARD_SUPPLY_CHAIN_SHIPMENTS,
    getSupplyChainShipmentsData
  )
}

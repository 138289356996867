import {
  PureComponent,
  ReactNode,
  ReactNodeArray,
  RefObject,
  createRef,
} from 'react'
import Popper from '@mui/material/Popper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { v4 as getID } from 'uuid'
import bind from 'autobind-decorator'
import Button from '@mui/material/Button'
import { IMenuOriginProps } from './interfaces'
import './styles.scss'

export type IMenuOriginRender = (props: IMenuOriginProps) => ReactNode

interface IMenuButtonProps {
  title?: string
  className?: string
  menuClassName?: string
  popperClassName?: string
  placement?: any
  buttonColor?: string
  buttonColorActive?: string
  isOpen?: boolean
  isActive?: boolean
  clearable?: boolean
  onOpen?: () => void
  onClose?: () => void
  onCancel?: () => void
  onClick?: () => void
  onClickAway?: () => void
  children?: ReactNode | ReactNodeArray
  renderOrigin?: IMenuOriginRender
  popperVisible: boolean
  icon?: string
  maxMenuWidth?: number
  longTitles?: boolean
  disabled?: boolean
}

interface IMenuButtonState {
  anchorEl: HTMLElement | null
  screenWidth: number
}

const MAX_MOBILE_SCREEN_WIDTH = 768

class MenuButton extends PureComponent<IMenuButtonProps, IMenuButtonState> {
  public static propTypes = {}

  public static defaultProps = {
    title: '',
    className: '',
    menuClassName: '',
    popperClassName: '',
    buttonColor: 'grey-outline-green',
    buttonColorActive: 'green',
    isOpen: true,
    isActive: false,
    clearable: true,
    onOpen: null,
    onClose: null,
    onCancel: null,
    onClick: null,
    onClickAway: null,
    children: '',
    renderOrigin: null,
    popperVisible: true,
  }

  private id: string
  private buttonRef: RefObject<HTMLElement>

  constructor(props, context) {
    super(props, context)
    this.id = `MenuButton_${getID()}`
    this.buttonRef = createRef<HTMLElement>()
    this.state = {
      anchorEl: null,
      screenWidth: MAX_MOBILE_SCREEN_WIDTH + 9000,
    }
  }

  public render() {
    const {
      menuClassName,
      popperClassName,
      isActive,
      renderOrigin,
      longTitles,
    } = this.props
    const { anchorEl, screenWidth } = this.state
    const isOpen = !!anchorEl
    const isHighlighted = isOpen || isActive
    const mobileMenuWidth =
      screenWidth < MAX_MOBILE_SCREEN_WIDTH
        ? { width: `${screenWidth * 0.88}px` }
        : undefined
    const customTitle: React.ReactNode = (
      <div className="menu-button__title">{this.props.title}</div>
    )
    const maxMenuWidth: { maxWidth: string } | undefined = this.props
      .maxMenuWidth
      ? { maxWidth: `${this.props.maxMenuWidth - 30}px` }
      : undefined

    return [
      renderOrigin
        ? renderOrigin({
            key: `${this.id}.OpenMenu`,
            'aria-owns': isOpen ? this.id : null,
            'aria-haspopup': 'true',
            anchorRef: this.buttonRef,
            isHighlighted,
            onClick: this.toggle,
          })
        : null,
      <Button
        variant="outlined"
        onClick={this.toggle}
        disabled={!!this.props.disabled}
      >
        {this.props.icon && <i className={`icon ${this.props.icon}`} />}
        {longTitles ? customTitle : this.props.title}
        {isActive && this.props.clearable && (
          <i
            className="menu-button__icon icon close"
            onClick={this.cancel}
            role="img"
          />
        )}
      </Button>,
      <Popper
        key={`${this.id}.Popper`}
        id={this.id}
        open={isOpen && this.props.popperVisible}
        anchorEl={anchorEl}
        placement={this.props.placement || 'bottom-start'}
        className={`menu-button__popper mui-override ${popperClassName}`}
      >
        <ClickAwayListener onClickAway={this.close}>
          <section
            className={`menu-button__menu ${menuClassName}`}
            style={mobileMenuWidth || maxMenuWidth}
          >
            {this.props.children}
          </section>
        </ClickAwayListener>
      </Popper>,
    ]
  }

  public componentDidUpdate(prevProps: IMenuButtonProps): void {
    const { isOpen } = this.props
    if (isOpen != null && prevProps.isOpen !== isOpen) {
      if (isOpen) {
        this.open(this.buttonRef.current)
      } else {
        this.close()
      }
    }
  }

  @bind
  private toggle(event?: any): void {
    const { onClick } = this.props
    event.bubbles = true
    if (onClick) {
      onClick()
    }
    if (this.state.anchorEl) {
      this.close()
    } else {
      this.open(event.target)
    }
  }

  @bind
  private close(): void {
    const { onClose } = this.props
    if (onClose) {
      onClose()
    }
    this.setState({ anchorEl: null })
  }

  @bind
  private open(target: HTMLElement | null): void {
    const { onOpen } = this.props
    if (onOpen) {
      onOpen()
    }
    this.setState({
      anchorEl: target,
      screenWidth: window.innerWidth,
    })
  }

  @bind
  private cancel(event: any): void {
    const { onCancel } = this.props
    if (onCancel) {
      onCancel()
    }
    if (event) {
      event.stopPropagation()
    }
    this.close()
  }
}

export default MenuButton

import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/connections'
import * as actionCreators from '../../actionCreators/connections'
import { simpleGetAndResolve, simplePostAndResolve } from '../factories'

const linkAddressURL = (action) =>
  `api/v1/connections/${action.id}/assign_to_address`

const getAllConnections = simpleGetAndResolve(
  (action) => `/api/v1/connections`,
  actionCreators.getAllConnectionsSuccess,
  (response) => response.data
)

const getConnection = simpleGetAndResolve(
  (action) => `/api/v1/connections/${action.id}`,
  actionCreators.getConnectionSuccess,
  (response) => response.data
)

const linkAddressToConnection = simplePostAndResolve(
  linkAddressURL,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.GET_ALL_CONNECTIONS, getAllConnections)
  yield takeEvery(actions.GET_SINGLE_CONNECTION, getConnection)
  yield takeEvery(actions.LINK_ADDRESS_TO_CONNECTION, linkAddressToConnection)
}

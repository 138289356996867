import capitalize from 'lodash/capitalize'
import flatMap from 'lodash/flatMap'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import {
  fromISOwithZone,
  DATE_STRING_FORMAT,
} from 'src/utils/helpers/shipmentDate'
import Input from 'src/components/Overview/Search/FormElements/Input'
import ManageSearch from '@mui/icons-material/ManageSearch'
import { TFunction } from 'i18next'
import { TableSortProps } from 'src/components/Common/Table/DataTable/TableWrapper.props'
import {
  OverviewResponseRecord,
  OverviewResponse,
} from 'src/services/Api/overview/types'
import AutocompleteAsync from 'src/components/Overview/Search/FormElements/AutocompleteAsync'
import BookingPartiesAutocompleteAsync from 'src/components/Overview/Search/FormElements/BookingPartiesAutocompleteAsync'
import DatePicker from 'src/components/Overview/Search/FormElements/DatePicker'
import AutocompleteMulti from 'src/components/Overview/Search/FormElements/AutocompleteMulti'
import ShipmentStatusesAutocomplete from 'src/components/Overview/Search/FormElements/ShipmentStatusesAutocomplete'
import { searchParams, customLabelsForWeekOptions } from './Search/constants'
import { tableSortingKeys } from './constants'
import OverviewTableText from './Table/OverviewTableText'
import OverviewTableShipmentLink from './Table/OverviewTableShipmentLink'
import OverviewTableVesselsGroupedCell from './Table/OverviewTableVesselsGroupedCell'
import OverviewTableAggregatedCell from './Table/OverviewTableVesselsAggregatedCell'
import OverviewTableShipmentStatusCell from './Table/OverviewTableShipmentStatusCell'
import OverviewTableFirstDayDemurrageCell from './Table/OverviewTableFirstDayDemurrageCell'
import OverviewTableContainerCustomsStatus from './Table/OverviewTableContainerCustomsStatus'
import OverviewTablePickupOrDeliveryStatusCell from './Table/OverviewTablePickupOrDeliveryStatusCell'
import OverviewTableShipmentBookingParties from './Table/OverviewTableShipmentBookingParties'
import { StyledRowBadge } from './styled'
import { DateSelectorOptionProps, FilterOptions } from './Search/types'
import OverviewTableContainerInspectionStatus from './Table/OverviewTableContainerInspectionStatus'

export const getSearchToken = (input: string) => {
  return {
    value: 'search',
    icon: ManageSearch,
    input,
    label: `Press enter ⏎ to search for "${input}"`,
    component: Input,
    group: 'filters',
  } as FilterOptions
}

export const getOrderParams = (order: TableSortProps[] | undefined) => {
  return (
    order?.map((sort: TableSortProps) => {
      return `${sort.id}:${sort.desc ? 'desc' : 'asc'}`
    }) ?? []
  )
}

export const getDisplayDate = (date: string, omitTime = false) => {
  if (!date) {
    return '-'
  }
  const zone = DateTime.local().zoneName
  const luxonDate = fromISOwithZone(date).setZone(zone)
  if (Math.abs(luxonDate.diffNow('months').months) >= 1 || omitTime) {
    return luxonDate.toFormat('dd MMM yyyy')
  } else {
    return luxonDate.toFormat('dd MMM HH:mm')
  }
}

export const getTableColumns = (t: TFunction<'translation', undefined>) => {
  return [
    {
      accessorKey: tableSortingKeys.containerType,
      accessorFn: (row) => row?.type_name ?? '-',
      Header: t('vessels_page.data_table.column_headers.type', 'Type'),
      header: t('vessels_page.data_table.show_hide.type', 'Container type'),
      enableGrouping: false,
      Cell: ({ cell }) => {
        return <OverviewTableText text={cell.getValue()} />
      },
      size: 131,
      group: 'vessel',
    },
    {
      accessorKey: tableSortingKeys.shipmentStatus,
      accessorFn: (row) => row?.shipment_status_name ?? '-',
      Header: t(
        'vessels_page.data_table.column_headers.shipment_status',
        'Shipment status'
      ),
      header: t(
        'vessels_page.data_table.show_hide.shipment_status',
        'Shipment status'
      ),
      enableGrouping: false,
      enableSorting: false,
      Cell: ({ row }) => {
        return (
          <OverviewTableShipmentStatusCell
            row={row}
            field="shipment_status_name"
          />
        )
      },
      size: 200,
    },
    {
      accessorKey: tableSortingKeys.containerPodCustomsDocumentsPresent,
      accessorFn: (row) => row.pod_customs_documents_present,
      Header: t(
        'vessels_page.data_table.column_headers.pod_customs_documents_present',
        'Customs documents'
      ),
      header: t(
        'vessels_page.data_table.show_hide.pod_customs_documents_present',
        'Customs documents'
      ),
      enableGrouping: false,
      enableSorting: false,
      Cell: ({ row }) => {
        return <OverviewTableContainerCustomsStatus row={row} />
      },
      size: 200,
    },
    {
      accessorKey: tableSortingKeys.containerNumber,
      accessorFn: (row) => row.number,
      Header: t(
        'vessels_page.data_table.column_headers.container_number',
        'Container'
      ),
      header: t(
        'vessels_page.data_table.show_hide.container_number',
        'Container number'
      ),
      enableGrouping: false,
      Cell: ({ cell, row }) => {
        return (
          <OverviewTableShipmentLink
            text={cell.getValue()}
            id={`${row.original.shipment_id}/containers`}
          />
        )
      },
      size: 179,
    },
    {
      accessorKey: tableSortingKeys.containerSealNumber,
      accessorFn: (row) => row?.seal_number ?? '-',
      Header: t('vessels_page.data_table.column_headers.seal', 'Seal'),
      header: t('vessels_page.data_table.show_hide.seal', 'Seal number'),
      enableGrouping: false,
      Cell: ({ cell }) => {
        return <OverviewTableText text={capitalize(cell.getValue())} />
      },
      size: 116,
    },
    {
      accessorKey: tableSortingKeys.containerStatus,
      accessorFn: (row) => row?.status_name ?? '-',
      Header: t(
        'vessels_page.data_table.column_headers.container_status',
        'Container status'
      ),
      header: t(
        'vessels_page.data_table.show_hide.container_status',
        'Container status'
      ),
      enableGrouping: false,
      Cell: ({ row }) => {
        return <OverviewTableShipmentStatusCell row={row} field="status_name" />
      },
      size: 237,
    },
    {
      accessorKey: tableSortingKeys.shipmentTitle,
      accessorFn: (row) => row.shipment_title,
      Header: t('vessels_page.data_table.column_headers.shipment', 'Shipment'),
      header: t('vessels_page.data_table.show_hide.shipment', 'Shipment'),
      enableColumnOrdering: true,
      enableGrouping: false,
      GroupedCell: ({ row, cell }) => {
        return (
          <Box className="grouped-cell">
            <StyledRowBadge badgeContent={row.leafRows.length}>
              {cell.getValue()}
            </StyledRowBadge>
          </Box>
        )
      },
      Cell: ({ cell, row }) => {
        return (
          <OverviewTableShipmentLink
            text={cell.getValue()}
            id={row.original.shipment_id}
          />
        )
      },
      size: 179,
    },
    {
      accessorKey: tableSortingKeys.shipmentVessel,
      accessorFn: (row) => row?.shipment_vessel_name ?? '-',
      Header: t('vessels_page.data_table.column_headers.vessel', 'Vessel'),
      header: t('vessels_page.data_table.show_hide.vessel', 'Vessel'),
      enableGrouping: false,
      Cell: ({ cell }) => {
        return <OverviewTableText text={cell.getValue()} />
      },
      GroupedCell: OverviewTableVesselsGroupedCell,
      size: 200,
    },
    {
      accessorKey: tableSortingKeys.shipmentSharedReference,
      accessorFn: (row) => row?.shipment_shared_reference ?? '-',
      Header: t(
        'vessels_page.data_table.column_headers.shipment_shared_reference',
        'Shared reference'
      ),
      header: t(
        'vessels_page.data_table.show_hide.shipment_shared_reference',
        'Shared reference'
      ),
      enableColumnOrdering: true,
      enableGrouping: false,
      Cell: ({ cell, row }) => {
        return (
          <OverviewTableShipmentLink
            text={cell.getValue()}
            id={`${row.original.shipment_id}`}
          />
        )
      },
      size: 200,
    },
    {
      accessorKey: tableSortingKeys.containerDischargeAt,
      Header: t(
        'vessels_page.data_table.column_headers.discharged_at',
        'Discharged at'
      ),
      header: t(
        'vessels_page.data_table.show_hide.discharged_at',
        'Discharged at'
      ),
      enableGrouping: false,
      accessorFn: (row) => row.discharged_at,
      Cell: ({ cell }) => {
        return <OverviewTableText text={getDisplayDate(cell.getValue())} />
      },
      size: 199,
    },
    {
      accessorKey: tableSortingKeys.shipmentDischargePort,
      accessorFn: (row) => row?.shipment_discharge_port_name ?? '-',
      Header: t('vessels_page.data_table.column_headers.pod', 'POD'),
      header: t(
        'vessels_page.data_table.show_hide.pod',
        'Port of discharge (POD)'
      ),
      enableGrouping: false,
      Cell: ({ cell }) => {
        return <OverviewTableText text={cell.getValue()} />
      },
      AggregatedCell: ({ row }) => {
        return (
          <OverviewTableText
            variant="body1Strong"
            text={row.original.discharge_port_name}
          />
        )
      },
      size: 155,
    },
    {
      accessorKey: tableSortingKeys.shipmentEstimatedDeparture,
      accessorFn: (row) => row.shipment_estimated_departure,
      Header: t(
        'vessels_page.data_table.column_headers.departure',
        'Departure'
      ),
      header: t('vessels_page.data_table.show_hide.departure', 'Departure'),
      enableGrouping: false,
      Cell: ({ cell }) => {
        return <OverviewTableText text={getDisplayDate(cell.getValue())} />
      },
      AggregatedCell: ({ row, table }) => {
        return (
          <OverviewTableAggregatedCell
            title={t('vessels_page.field.earliest_etd', 'Earliest ETD')}
            grouping={table.getState().grouping}
            text={getDisplayDate(row.original.earliest_etd)}
          />
        )
      },
      size: 180,
    },
    {
      accessorKey: tableSortingKeys.shipmentLoadingPort,
      accessorFn: (row) => row?.shipment_loading_port_name ?? '-',
      Header: t('vessels_page.data_table.column_headers.pol', 'POL'),
      header: t(
        'vessels_page.data_table.show_hide.pol',
        'Port of loading (POL)'
      ),
      enableGrouping: false,
      Cell: ({ cell }) => {
        return <OverviewTableText text={cell.getValue()} />
      },
      AggregatedCell: ({ row }) => {
        return (
          <OverviewTableText
            variant="body1Strong"
            text={row.original.loading_port_name}
          />
        )
      },
      size: 155,
    },
    {
      accessorKey: tableSortingKeys.shipmentTerminal,
      accessorFn: (row) => row?.shipment_terminal_name ?? '-',
      Header: t('vessels_page.data_table.column_headers.terminal', 'Terminal'),
      header: t('vessels_page.data_table.show_hide.terminal', 'Terminal'),
      enableGrouping: false,
      Cell: ({ cell, row }) => {
        return <OverviewTableText text={cell.getValue()} />
      },
      AggregatedCell: ({ row }) => {
        return (
          <OverviewTableText
            variant="body1Strong"
            text={
              row.original.terminal_name &&
              row.original.terminal_name.length > 0
                ? row.original.terminal_name
                : null
            }
          />
        )
      },
      size: 185,
    },
    {
      accessorKey: tableSortingKeys.shipmentEstimatedArrival,
      accessorFn: (row) => row.shipment_estimated_arrival,
      Header: t('vessels_page.data_table.column_headers.arrival', 'Arrival'),
      header: t('vessels_page.data_table.show_hide.arrival', 'Arrival'),
      enableGrouping: false,
      Cell: ({ cell }) => {
        return (
          <OverviewTableText
            data-testid="eta"
            text={getDisplayDate(cell.getValue())}
          />
        )
      },
      AggregatedCell: ({ row, table }) => {
        return (
          <OverviewTableAggregatedCell
            title={t('vessels_page.field.earliest_eta', 'Earliest ETA')}
            grouping={table.getState().grouping}
            text={getDisplayDate(row.original.earliest_eta)}
          />
        )
      },
      size: 160,
    },
    {
      accessorKey: tableSortingKeys.shipmentEstimatedDischarge,
      accessorFn: (row) => row.shipment_estimated_discharge,
      Header: t(
        'vessels_page.data_table.column_headers.pod_departure',
        'POD departure'
      ),
      header: t(
        'vessels_page.data_table.show_hide.pod_departure',
        'POD departure'
      ),
      enableGrouping: false,
      Cell: ({ cell }) => {
        return <OverviewTableText text={getDisplayDate(cell.getValue())} />
      },
      AggregatedCell: ({ row, table }) => {
        return (
          <OverviewTableAggregatedCell
            title={t('vessels_page.field.earliest_pod_etd')}
            grouping={table.getState().grouping}
            text={getDisplayDate(row.original.earliest_estimated_discharge)}
          />
        )
      },
      size: 203,
    },
    {
      accessorKey: tableSortingKeys.containerPickupStatus,
      accessorFn: (row) => row?.pickup_status ?? '-',
      Header: t(
        'vessels_page.data_table.column_headers.pickup_status',
        'Pick-up status'
      ),
      header: t(
        'vessels_page.data_table.show_hide.pickup_status',
        'Pick-up status'
      ),
      enableGrouping: false,
      enableSorting: false,
      Cell: ({ row }) => {
        return (
          <OverviewTablePickupOrDeliveryStatusCell
            row={row}
            field="pickup_status"
          />
        )
      },
      size: 140,
    },
    {
      accessorKey: tableSortingKeys.shipmentConsignee,
      accessorFn: (row) => row?.shipment_shipment_organizations ?? [],
      Header: t(
        'vessels_page.data_table.column_headers.shipment_consignee',
        'Consignee'
      ),
      header: t(
        'vessels_page.data_table.show_hide.shipment_consignee',
        'Consignee'
      ),
      enableGrouping: false,
      enableSorting: false,
      Cell: ({ cell }) => {
        return (
          <OverviewTableShipmentBookingParties
            shipmentOrganizations={cell.getValue()}
            role="consignee"
          />
        )
      },
      size: 168,
    },
    {
      accessorKey: tableSortingKeys.containerPickupAddress,
      accessorFn: (row) => row?.pickup_address ?? '-',
      Header: t(
        'vessels_page.data_table.column_headers.pickup_address',
        'Pick-up address'
      ),
      header: t(
        'vessels_page.data_table.show_hide.pickup_address',
        'Pick-up address'
      ),
      enableGrouping: false,
      enableSorting: false,
      Cell: ({ cell }) => {
        const value = cell.getValue()
        return <OverviewTableText text={value.length > 0 ? value : '-'} />
      },
      size: 168,
    },
    {
      accessorKey: tableSortingKeys.containerDeliveryAddress,
      accessorFn: (row) => row?.delivery_address ?? '-',
      Header: t(
        'vessels_page.data_table.column_headers.delivery_address',
        'Delivery address'
      ),
      header: t(
        'vessels_page.data_table.show_hide.delivery_address',
        'Delivery address'
      ),
      enableGrouping: false,
      enableSorting: false,
      Cell: ({ cell }) => {
        const value = cell.getValue()
        return <OverviewTableText text={value.length > 0 ? value : '-'} />
      },
      size: 168,
    },
    {
      accessorKey: tableSortingKeys.containerPickupTime,
      accessorFn: (row) => row.pickup_time,
      Header: t(
        'vessels_page.data_table.column_headers.pickup_time',
        'Pickup date'
      ),
      header: t('vessels_page.data_table.show_hide.pickup_time', 'Pickup date'),
      enableSorting: false,
      enableGrouping: false,
      Cell: ({ cell }) => {
        return (
          <OverviewTableText
            data-testid="pickup-time"
            text={getDisplayDate(cell.getValue())}
          />
        )
      },
      size: 140,
    },
    {
      accessorKey: tableSortingKeys.containerDeliveryStatus,
      accessorFn: (row) => row?.delivery_status ?? '-',
      Header: t(
        'vessels_page.data_table.column_headers.delivery_status',
        'Delivery status'
      ),
      header: t(
        'vessels_page.data_table.show_hide.delivery_status',
        'Delivery status'
      ),
      enableGrouping: false,
      enableSorting: false,
      Cell: ({ row }) => {
        return (
          <OverviewTablePickupOrDeliveryStatusCell
            row={row}
            field="delivery_status"
          />
        )
      },
      size: 140,
    },
    {
      accessorKey: tableSortingKeys.containerDeliveryTime,
      accessorFn: (row) => row.delivery_time,
      Header: t(
        'vessels_page.data_table.column_headers.delivery_time',
        'Delivery date'
      ),
      header: t(
        'vessels_page.data_table.show_hide.delivery_time',
        'Delivery date'
      ),
      enableSorting: false,
      enableGrouping: false,
      Cell: ({ cell }) => {
        return (
          <OverviewTableText
            data-testid="delivery-time"
            text={getDisplayDate(cell.getValue())}
          />
        )
      },
      size: 140,
    },
    {
      accessorKey: tableSortingKeys.shipmentTerminalEstimatedArrival,
      accessorFn: (row) => row.shipment_terminal_estimated_arrival,
      Header: t(
        'vessels_page.data_table.column_headers.terminal_estimated_arrival',
        'Terminal ETA'
      ),
      header: t(
        'vessels_page.data_table.show_hide.terminal_estimated_arrival',
        'Terminal ETA'
      ),
      enableSorting: false,
      enableGrouping: false,
      Cell: ({ cell }) => {
        return (
          <OverviewTableText
            data-testid="terminal-eta"
            text={getDisplayDate(cell.getValue())}
          />
        )
      },
      size: 140,
    },
    {
      accessorKey: tableSortingKeys.containerGateOut,
      accessorFn: (row) => row.gate_out,
      Header: t(
        'vessels_page.data_table.column_headers.container_gate_out',
        'Container gate out'
      ),
      header: t(
        'vessels_page.data_table.show_hide.container_gate_out',
        'Container gate out'
      ),
      enableSorting: false,
      enableGrouping: false,
      Cell: ({ cell }) => {
        return (
          <OverviewTableText
            data-testid="container-gate-out"
            text={getDisplayDate(cell.getValue())}
          />
        )
      },
      size: 160,
    },
    {
      accessorKey: tableSortingKeys.containerPodCustomsInspectionStatus,
      accessorFn: (row) => row.pod_customs_inspection_status,
      Header: t(
        'vessels_page.data_table.column_headers.pod_customs_inspection_status',
        'Container scan'
      ),
      header: t(
        'vessels_page.data_table.show_hide.pod_customs_inspection_status',
        'Container scan'
      ),
      enableSorting: true,
      enableGrouping: false,
      Cell: ({ cell }) => {
        return (
          <OverviewTableContainerInspectionStatus value={cell.getValue()} />
        )
      },
      size: 160,
    },
    {
      accessorKey: tableSortingKeys.containerPodCustomsInspectionType,
      accessorFn: (row) => row.pod_customs_inspection_type,
      Header: t(
        'vessels_page.data_table.column_headers.pod_customs_inspection_type',
        'Inspection type'
      ),
      header: t(
        'vessels_page.data_table.show_hide.pod_customs_inspection_type',
        'Inspection type'
      ),
      enableSorting: true,
      enableGrouping: false,
      Cell: ({ cell }) => {
        return (
          <OverviewTableContainerInspectionStatus value={cell.getValue()} />
        )
      },
      size: 160,
    },
    {
      accessorKey: tableSortingKeys.containerInspectionDatetime,
      accessorFn: (row) => row.inspection_datetime,
      Header: t(
        'vessels_page.data_table.column_headers.inspection_datetime',
        'Inspection'
      ),
      header: t(
        'vessels_page.data_table.show_hide.inspection_datetime',
        'Inspection'
      ),
      enableSorting: false,
      enableGrouping: false,
      Cell: ({ cell }) => {
        return (
          <OverviewTableText
            data-testid="inspection-date"
            text={getDisplayDate(cell.getValue())}
          />
        )
      },
      size: 140,
    },
    {
      accessorKey: tableSortingKeys.containerActualFirstDayOfDemurrage,
      accessorFn: (row) => row.actual_first_day_of_demurrage,
      Header: t(
        'vessels_page.data_table.column_headers.actual_first_day_of_demurrage',
        'First day of demurrage'
      ),
      header: t(
        'vessels_page.data_table.show_hide.actual_first_day_of_demurrage',
        'Actual first day of demurrage'
      ),
      enableSorting: false,
      enableGrouping: false,
      Cell: ({ row }) => {
        return (
          <OverviewTableFirstDayDemurrageCell
            row={row}
            field="actual_first_day_of_demurrage"
          />
        )
      },
      size: 220,
    },
    {
      accessorKey: tableSortingKeys.shipmentCarrierEstimatedArrival,
      accessorFn: (row) => row.shipment_carrier_estimated_arrival,
      Header: t(
        'vessels_page.data_table.column_headers.carrier_estimated_arrival',
        'Carrier ETA'
      ),
      header: t(
        'vessels_page.data_table.show_hide.carrier_estimated_arrival',
        'Carrier ETA'
      ),
      enableSorting: false,
      enableGrouping: false,
      Cell: ({ cell }) => {
        return (
          <OverviewTableText
            data-testid="carrier-eta"
            text={getDisplayDate(cell.getValue())}
          />
        )
      },
      size: 140,
    },
    {
      accessorKey: tableSortingKeys.shipmentModality,
      accessorFn: (row) => row?.shipment_modality ?? '-',
      Header: t('vessels_page.data_table.column_headers.modality'),
      header: t('vessels_page.data_table.show_hide.modality', 'Modality'),
      enableGrouping: false,
      Cell: ({ cell }) => {
        return <OverviewTableText text={capitalize(cell.getValue())} />
      },
      size: 164,
    },
    {
      accessorKey: tableSortingKeys.shipmentLoadType,
      accessorFn: (row) => row?.shipment_load_type ?? '-',
      Header: t('vessels_page.data_table.column_headers.load', 'Load'),
      header: t('vessels_page.data_table.show_hide.load', 'Load type'),
      enableGrouping: false,
      Cell: ({ cell }) => {
        return <OverviewTableText text={cell.getValue().toUpperCase()} />
      },
      size: 130,
    },
  ]
}

export const flattenObject = (input) => {
  let result = {}
  for (const key in input) {
    if (!input.hasOwnProperty(key)) {
      continue
    }
    if (typeof input[key] === 'object' && !Array.isArray(input[key])) {
      let subFlatObject = flattenObject(input[key])
      for (const subkey in subFlatObject) {
        result[key + '_' + subkey] = subFlatObject[subkey]
      }
    } else {
      result[key] = input[key]
    }
  }
  return result
}

export const reorder = (
  list: Iterable<unknown> | ArrayLike<unknown>,
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const convertDateToUnixTimestamp = (date: string, endOfDay = false) => {
  const dateToConvert: DateTime = endOfDay
    ? DateTime.fromISO(date).endOf('day')
    : DateTime.fromISO(date).startOf('day')

  return dateToConvert.toUTC().toSeconds()
}

export const convertDateFromUnixTimestamp = (
  date: number,
  format = DATE_STRING_FORMAT
) => {
  return DateTime.fromSeconds(date).toFormat(format)
}

export const convertDatePairToUnixTimestampRange = (
  start: DateTime,
  end: DateTime
): number[] => {
  return [
    start.startOf('day').toUTC().toSeconds(),
    end.endOf('day').toUTC().toSeconds(),
  ]
}

export const getDateOptionRange = (option: DateSelectorOptionProps) => {
  return option.diff()
}

const getDateOptionForSaturdayToFriday = (
  currentWeekNumber: number,
  t: TFunction<'translation', undefined>
): DateSelectorOptionProps => {
  const dt = DateTime.fromObject({
    weekNumber: currentWeekNumber,
  })

  const nextSaturday = dt.endOf('week').minus({ days: 1 })

  const nextFriday = nextSaturday.plus({ days: 6 })

  return {
    label: t('vessels_page.filters.date_picker.next_saturday_to_friday', {
      defaultValue: 'Next Sat - Fri',
    }),
    value: 'sat-fri',
    diff: () => {
      return convertDatePairToUnixTimestampRange(nextSaturday, nextFriday)
    },
  }
}

export const getDateOptionRangeForWeek = (
  t: TFunction<'translation', undefined>
) => {
  const options: DateSelectorOptionProps[] = []
  const now = DateTime.now()
  const currentWeekNumber = Number(now.toFormat('WW'))

  options.push(getDateOptionForSaturdayToFriday(currentWeekNumber, t))

  for (let i = 0; i < 3; i++) {
    const label = customLabelsForWeekOptions[i]
    const week = currentWeekNumber + i
    const dt = DateTime.fromObject({
      weekNumber: week,
    })

    const start = dt.startOf('week')

    const end = dt.endOf('week')

    options.push({
      label: t(`vessels_page.filters.date_picker.${label.i18nKey}`, {
        defaultValue: label.defaultValue,
        weekNumber: week,
      }),
      value: `week-${week}`,
      diff: () => {
        return convertDatePairToUnixTimestampRange(start, end)
      },
    })
  }
  return options
}

export const getFlattenTableData = (records: OverviewResponseRecord[]) => {
  return flatMap(records, (record) => {
    if (
      record.type === 'vessel' &&
      record.record_type === 'group' &&
      record.data.records.length === 0
    ) {
      return [
        {
          shipment_vessel_name: record.title,
          shipment_vessel_id: record.id,
          ...record?.metadata,
        },
      ]
    }
    return record.data.records.map((subRecord) => {
      return {
        ...subRecord,
        ...record?.metadata,
      }
    })
  })
}

export const getPreparedTableData = (records: OverviewResponseRecord[]) => {
  const firstLevelRecords = getFlattenTableData(records)

  return firstLevelRecords.map((record) => {
    return flattenObject(record)
  })
}

export const getExpandedRowData = (
  res: OverviewResponse,
  id: number,
  tableRecords: OverviewResponseRecord[]
) => {
  return (
    tableRecords.map((record) => {
      if (record.id === id) {
        return {
          ...record,
          data: {
            ...record.data,
            records: [
              ...record.data.records,
              ...flatMap(res?.data?.records, (record) => record.data.records),
            ].map((recordItem, index) => {
              return { ...recordItem, leafIndex: index }
            }),
          },
        }
      }
      return record
    }) ?? []
  )
}

export const isInViewport = (element, container: HTMLElement | null) => {
  if (!container) {
    return false
  }
  const eleTop = element.offsetTop
  const eleBottom = eleTop + element.clientHeight

  const containerTop = container.scrollTop
  const containerBottom = containerTop + container.clientHeight

  // The element is fully visible in the container
  return (
    (eleTop >= containerTop && eleBottom <= containerBottom) ||
    // Some part of the element is visible in the container
    (eleTop < containerTop && containerTop < eleBottom) ||
    (eleTop < containerBottom && containerBottom < eleBottom)
  )
}

export const getSearchOptions = (
  t: TFunction<'translation', undefined>
): FilterOptions[] => {
  return [
    {
      value: searchParams.pol,
      shortLabel: 'POL',
      label: t('vessels_page.data_table.filters.pol', 'Port of loading (POL)'),
      component: AutocompleteAsync,
      group: 'filters',
    },
    {
      value: searchParams.pod,
      shortLabel: 'POD',
      label: t(
        'vessels_page.data_table.filters.pod',
        'Port of discharge (POD)'
      ),
      component: AutocompleteAsync,
      group: 'filters',
    },
    {
      value: searchParams.consignee,
      shortLabel: 'Consignee',
      label: t('vessels_page.data_table.filters.consignee', 'Consignee'),
      component: BookingPartiesAutocompleteAsync,
      group: 'filters',
    },
    {
      value: searchParams.departure,
      shortLabel: 'ETD',
      label: t('vessels_page.data_table.filters.departure', 'Departure'),
      component: DatePicker,
      group: 'filters',
    },
    {
      value: searchParams.arrival,
      shortLabel: 'ETA',
      label: t('vessels_page.data_table.filters.arrival', 'Arrival'),
      component: DatePicker,
      group: 'filters',
    },
    {
      value: searchParams.pickupTime,
      shortLabel: 'Pickup time',
      label: t('vessels_page.data_table.filters.pickup_time', 'Pickup date'),
      component: DatePicker,
      group: 'filters',
    },
    {
      value: searchParams.deliveryTime,
      shortLabel: 'Delivery date',
      label: t(
        'vessels_page.data_table.filters.delivery_time',
        'Delivery date'
      ),
      component: DatePicker,
      group: 'filters',
    },
    {
      value: searchParams.modality,
      label: t('vessels_page.data_table.filters.modality', 'Modality'),
      component: AutocompleteMulti,
      group: 'filters',
    },
    {
      value: searchParams.loadType,
      label: t('vessels_page.data_table.filters.load', 'Load type'),
      component: AutocompleteMulti,
      group: 'filters',
    },
    {
      value: searchParams.shipmentStatus,
      label: t(
        'vessels_page.data_table.filters.shipment_status',
        'Shipment status'
      ),
      component: ShipmentStatusesAutocomplete,
      group: 'filters',
    },
    {
      value: searchParams.pickupStatus,
      label: t(
        'vessels_page.data_table.filters.pickup_status',
        'Pick-up status'
      ),
      component: AutocompleteMulti,
      group: 'filters',
    },
    {
      value: searchParams.deliveryStatus,
      label: t(
        'vessels_page.data_table.filters.delivery_status',
        'Delivery status'
      ),
      component: AutocompleteMulti,
      group: 'filters',
    },
  ]
}

import Window from './Window'
import Title from './Title'
import Content from './Content'
import Actions from './Actions'

const Modal = {
  Window,
  Title,
  Content,
  Actions,
}

export default Modal

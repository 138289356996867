import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  collapsedExpandableCell: {
    '&.MuiTableCell-sizeMedium': {
      height: 0,
    },
  },
  expandedExpandableCell: {
    '&.MuiTableCell-sizeMedium': {
      height: 'auto',
    },
  },
}))

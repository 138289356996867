import { AnyAction } from 'redux'
import * as actions from '../actions/shipmentPrice'

export const shipmentTransactionItemsGetData = (id: number): AnyAction => ({
  type: actions.SHIPMENT_TRANSACTION_ITEMS_GET_DATA,
  id,
})

export const shipmentTransactionItemsGetDataSuccess = (
  payload: any
): AnyAction => ({
  type: actions.SHIPMENT_TRANSACTION_ITEMS_GET_DATA_SUCCESS,
  payload,
})

export const SHIPMENTS_GET_DATA = 'SHIPMENTS_GET_DATA'
export const SHIPMENTS_GET_DATA_SUCCESS = 'SHIPMENTS_GET_DATA_SUCCESS'
export const SHIPMENTS_GET_PARTIES = 'SHIPMENTS_GET_PARTIES'
export const SHIPMENTS_GET_PARTIES_SUCCESS = 'SHIPMENTS_GET_PARTIES_SUCCESS'
export const SHIPMENTS_GET_TIMELINE = 'SHIPMENTS_GET_TIMELINE'
export const SHIPMENTS_GET_TIMELINE_SUCCESS = 'SHIPMENTS_GET_TIMELINE_SUCCESS'
export const SHIPMENTS_CLEAR_TIMELINE = 'SHIPMENTS_CLEAR_TIMELINE'
export const SHIPMENTS_GET_META_DATA = 'SHIPMENTS_GET_META_DATA'
export const SHIPMENTS_GET_META_DATA_SUCCESS = 'SHIPMENTS_GET_META_DATA_SUCCESS'
export const SHIPMENTS_REQUEST_EXCEL = 'SHIPMENTS_REQUEST_EXCEL'
export const SHIPMENTS_REQUEST_EXCEL_SUCCESS = 'SHIPMENTS_REQUEST_EXCEL_SUCCESS'
export const SCOPE_PARAMS_CHANGE = 'SCOPE_PARAMS_CHANGE'
export const SET_EXPORT_FILE = 'SET_EXPORT_FILE'
export const SHIPMENTS_TO_ASSIGN_PO_GET = 'SHIPMENTS_TO_ASSIGN_PO_GET'
export const SHIPMENTS_TO_ASSIGN_PO_GET_SUCCESS =
  'SHIPMENTS_TO_ASSIGN_PO_GET_SUCCESS'
export const SHIPMENTS_TO_ASSIGN_PO_CLEAN = 'SHIPMENTS_TO_ASSIGN_PO_CLEAN'
export const SHIPMENTS_FORCE_CLICK_TAB = 'SHIPMENTS_FORCE_CLICK_TAB'
export const SHIPMENTS_UPDATE_CONNECTIONS = 'SHIPMENTS_UPDATE_CONNECTIONS'
export const SHIPMENTS_UPDATE_CONNECTIONS_SUCCESS =
  'SHIPMENTS_UPDATE_CONNECTIONS_SUCCESS'
export const SHIPMENT_UPDATE_DATA = 'SHIPMENT_UPDATE_DATA'
export const GET_SHIPMENT_STATUSES = 'GET_SHIPMENT_STATUSES'
export const GET_SHIPMENT_STATUSES_SUCCESS = 'GET_SHIPMENT_STATUSES_SUCCESS'
export const SHIPMENT_MODIFY_DATA = 'SHIPMENT_MODIFY_DATA'
export const SHIPMENTS_GET_ORGANIZATION_ROLES =
  'SHIPMENTS_GET_ORGANIZATION_ROLES'
export const SHIPMENTS_GET_ORGANIZATION_ROLES_SUCCESS =
  'SHIPMENTS_GET_ORGANIZATION_ROLES_SUCCESS'
export const CREATE_VISIBILITY_SHIPMENT = 'CREATE_VISIBILITY_SHIPMENT'
export const CREATE_VISIBILITY_SHIPMENT_SUCCESS =
  'CREATE_VISIBILITY_SHIPMENT_SUCCESS'
export const SHIPMENTS_UPDATE_FILTERS = 'SHIPMENTS_UPDATE_FILTERS'
export const SHIPMENTS_SET_FILTERS_INITIAL = 'SHIPMENTS_SET_FILTERS_INITIAL'
export const SHIPMENTS_UPDATE_SHIPMENT_FORM = 'SHIPMENTS_UPDATE_SHIPMENT_FORM'
export const SHIPMENTS_UPDATE_SHIPMENT_FORM_ERRORS =
  'SHIPMENTS_UPDATE_SHIPMENT_FORM_ERRORS'
export const SHIPMENTS_GET_UNIQUE_TRACKING_KEY =
  'SHIPMENTS_GET_UNIQUE_TRACKING_KEY'
export const SHIPMENTS_GET_UNIQUE_TRACKING_KEY_SUCCESS =
  'SHIPMENTS_GET_UNIQUE_TRACKING_KEY_SUCCESS'
export const GET_VISIBILITY_SHIPMENT_CARRIERS =
  'GET_VISIBILITY_SHIPMENT_CARRIERS'
export const GET_VISIBILITY_SHIPMENT_CARRIERS_SUCCESS =
  'GET_VISIBILITY_SHIPMENT_CARRIERS_SUCCESS'
export const SET_VISIBILITY_SHIPMENT = 'SET_VISIBILITY_SHIPMENT'
export const ADD_QUICK_FILTER = 'ADD_QUICK_FILTER'
export const PUT_QUICK_FILTER = 'PUT_QUICK_FILTER'
export const DELETE_QUICK_FILTER = 'DELETE_QUICK_FILTER'
export const SHIPMENTS_MANAGE_QUICK_FILTERS = 'SHIPMENTS_MANAGE_QUICK_FILTERS'
export const SHIPMENTS_SHARE_EMAIL = 'SHIPMENTS_SHARE_EMAIL'
export const SHIPMENTS_SHARE_EMAIL_SUCCESS = 'SHIPMENTS_SHARE_EMAIL_SUCCESS'
export const MODAL_DISPLAY_CHANGE = 'MODAL_DISPLAY_CHANGE'

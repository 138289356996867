import { FunctionComponent, useEffect, useCallback } from 'react'
import debounce from 'lodash/debounce'
import { makeStyles } from '@mui/styles'

import Box from '@mui/material/Box'
import { CircularProgress, InputBase } from '@mui/material'
import OptionsPicker from 'src/components/Common/Input/OptionsPicker'

type PickerTheme = 'inlineFormPicker' | 'filtersPagePicker'

const useStyles = makeStyles((theme) => ({
  // this style to be applied if used in forms.
  inlineFormPicker: {
    height: 40,
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .chevron': {
      top: 11,
      right: 20,
    },
    '& .add': {
      top: 8,
      right: 14,
    },
  },
  // this style to be applied if used in a filters block, i.e. reportsDashboard
  filtersPagePicker: {
    height: 32,
    '& .chevron': {
      top: 7,
      right: 14,
    },
    '& .add': {
      top: 5,
      right: 8,
    },
  },
  optionsPickerAsyncLoader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -9,
    marginLeft: -9,
  },
}))

export interface IOptionsPickerAsyncProps {
  label?: string
  selectedLabel?: string
  fieldName: string
  error?: boolean
  values: string[][]
  onChange: (values: string[][], name: string) => void
  options: (string | number)[][]
  isFetching: boolean
  theme?: PickerTheme
  clearOptionsAction: () => void
  fetchOptionsAction: (search: string) => void
}

const OptionsPickerAsync: FunctionComponent<IOptionsPickerAsyncProps> = ({
  label,
  error = false,
  fieldName,
  onChange,
  options,
  values,
  selectedLabel,
  clearOptionsAction,
  fetchOptionsAction,
  isFetching = true,
  theme = 'inlineFormPicker',
}) => {
  const classes = useStyles()

  useEffect(() => {
    clearOptionsAction()
  }, [])

  const onValueChange = useCallback(
    (option: string, _fieldName?: string, item?: string[]) => {
      if (!_fieldName || !item) return
      const ids = values.map(([name, id]) => id)
      if (ids.includes(option)) {
        onChange(
          values.filter(([name, id]) => id !== option),
          fieldName
        )
      } else {
        onChange([...values, item], fieldName)
      }
    },
    [fieldName, values, onChange]
  )

  const delayedOnSearch = debounce((searchQuery: string) => {
    fetchOptionsAction(searchQuery)
  }, 200)

  const onCancel = useCallback(() => {
    onChange([], fieldName)
    clearOptionsAction()
  }, [onChange, fieldName, clearOptionsAction])

  return (
    <>
      {isFetching && (
        <Box position="relative" mt={3}>
          <InputBase fullWidth disabled />
          <CircularProgress
            size={18}
            className={classes.optionsPickerAsyncLoader}
          />
        </Box>
      )}
      {!isFetching && (
        <OptionsPicker
          disableSorting
          onSearch={delayedOnSearch}
          searchable
          theme={classes[theme]}
          selectedTitle={selectedLabel ? selectedLabel : `${label}s`}
          title={label}
          error={error}
          onChange={onValueChange}
          onCancel={onCancel}
          options={options}
          values={values.map(([name, id]) => id)}
          fieldName={fieldName}
        />
      )}
    </>
  )
}

export default OptionsPickerAsync

export const prepareForRequest = (state: any) => ({
  address_id: state.address_id || null,
  company_name: state.company_name || '',
  address: state.address || '',
  city: state.city || '',
  postal_code: state.postal_code || '',
  country_id: state.country_id || null,
  vat_number: state.vat_number || '',
  eori_number: state.eori_number || '',
  comment: state.comment || '',
})

export const prepareImportedData = (contact: IAddress) => ({
  address_id: contact.id || null,
  company_name: contact.name || '',
  address: contact.address || '',
  city: contact.city || '',
  postal_code: contact.postal_code || '',
  country_id: contact.country.id || null,
  vat_number: contact.vat_number || null,
  eori_number: contact.eori_number || null,
  comment: contact.comment || '',
})

export const initialState = {
  contacts: [],
  address_id: null,
  company_name: '',
  address: '',
  postal_code: '',
  city: '',
  country_id: 0,
  comment: '',
  vat_number: '',
  eori_number: '',
  validators: [],
  baseState: {},
  errorNotes: {},
  openForNew: false,
}

import { FunctionComponent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'src/components/Common/Select'

import { daysOptions, dateOptions } from '../constants'

interface IProps {
  handleChangeSLA: (value: string | number, type: string) => void
  slaCheckpoint: ISLA
  isEdit: boolean
}

const SettingsBlock: FunctionComponent<IProps> = (props) => {
  const handleChangeInput = (event: React.ChangeEvent<any>): void => {
    const commonExp: RegExp = /^\d+$/

    if (
      event.target.name !== 'name' &&
      event.target.value &&
      !commonExp.test(event.target.value)
    ) {
      return
    }

    props.handleChangeSLA(event.target.value, event.target.name)
  }

  const handleChange = (data, field: string): void => {
    props.handleChangeSLA(data.id, field)
  }

  const getCheckpointValue = useMemo(() => {
    return (
      props.slaCheckpoint.sla_checkpoint_value_id ||
      (props.slaCheckpoint.sla_checkpoint_value
        ? props.slaCheckpoint.sla_checkpoint_value.id
        : 1)
    )
  }, [props.slaCheckpoint])

  const getCheckpointDaysDirection = useMemo(() => {
    return (
      props.slaCheckpoint.dt_days_id ||
      (props.slaCheckpoint.dt_days <= 0 ? 1 : 2)
    )
  }, [props.slaCheckpoint])

  const { t } = useTranslation()

  return (
    <div className="sla-window--block-content">
      <div className="sla-window--block-wrapper">
        <div className="field-block long">
          <div className="field-title paragraph__small grey">
            {t('address_book.add_address.type', 'Type')} <sup>*</sup>
          </div>
          <Select.Single
            placeholder={t(
              'address_book.add_address.production_update',
              'Production update'
            )}
            options={[]}
            value={0}
            onChange={handleChange}
            disabled={true}
            theme="cargo"
          />
        </div>
        <div className="field-block x-long">
          <div className="field-title paragraph__small grey">
            {t('address_book.add_address.name', 'Name')} <sup>*</sup>
          </div>
          <input
            className="purchase-order-number-input"
            name="name"
            value={props.slaCheckpoint.name || ''}
            onChange={handleChangeInput}
          />
        </div>

        <div className="field-block days-field x-short">
          <div className="field-title paragraph__small grey">
            {t('address_book.add_address.when', 'When')} <sup>*</sup>
          </div>
          <input
            className="purchase-order-number-input"
            name="dt_days"
            value={Math.abs(props.slaCheckpoint.dt_days || 0) || ''}
            onChange={handleChangeInput}
            maxLength={2}
          />
          <div className="days-field--desc">
            {t('address_book.add_address.days', 'Days')}
          </div>
        </div>

        <div className="field-block short">
          <div className="field-title paragraph__small grey"></div>
          <Select.Single
            placeholder=""
            options={daysOptions}
            value={getCheckpointDaysDirection}
            extendedParam="dt_days_id"
            onChange={handleChange}
            searchable={false}
            theme="cargo"
          />
        </div>

        <div className="field-block long">
          <div className="field-title paragraph__small grey"></div>
          <Select.Single
            placeholder=""
            options={dateOptions}
            value={getCheckpointValue}
            extendedParam="sla_checkpoint_value_id"
            onChange={handleChange}
            searchable={false}
            theme="cargo"
          />
        </div>
      </div>
    </div>
  )
}

export default SettingsBlock

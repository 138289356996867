import { put } from 'redux-saga/effects'
import { ActionCreator, AnyAction } from 'redux'
import { ResponseType } from 'axios'
import { serverErrors } from '../../../utils/helpers'
import { authorizedRequest, resolveAction, rejectAction } from '../wrappers'
import { Logger } from '../../../utils'
import { reportTimeout } from './'

// Factory creating saga, which does simple get request and resolving promisified action
// Action may contain payload. If it does, it will be converted to query parameters of the request
// If action contains id property, request would be to 'url/id'
export default (
  makeUrl: (action: AnyAction) => string,
  actionCreator: ActionCreator<AnyAction>,
  serializer?: IResponseSerializer,
  responseType: ResponseType = 'json'
): ISaga1<IPromisifiedAction> =>
  function* (action: IPromisifiedAction): Iterator<any> {
    const { signal = null } = action
    try {
      const response: any = yield authorizedRequest({
        method: 'GET',
        url: makeUrl(action),
        params: action.payload || null,
        responseType: responseType,
        signal,
      })
      const result = serializer
        ? serializer(response, action.payload)
        : response
      yield put(actionCreator(result))
      yield resolveAction(action, result)
    } catch (error) {
      const unknownError: any = error
      yield Logger.sagaError(
        unknownError,
        'store::factories::simpleGetAndResolve'
      )
      if (unknownError.code === 'ECONNABORTED') {
        yield reportTimeout(makeUrl, action, 'GET')
      }
      yield* serverErrors(unknownError)
      yield rejectAction(action, error)
    }
  }

import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { capitalize, toLower, startCase } from 'lodash'
import Typography from '@mui/material/Typography'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import { Box, Divider, Link } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { chipColor } from 'src/config/constants'
import { makeStyles } from '@mui/styles'

import SelectableChip, {
  ISelectChipColor,
  OptionsItem as ISelectOption,
} from 'src/components/SelectableChip'
import {
  updateTaskStatus,
  shipmentContainersGetData,
} from 'src/stores/actionCreators'

import { promisifyAction } from 'src/utils'

import { CompletionPoint } from './CompletionPoint'

export interface MilestoneRowProps {
  milestone: IMilestone
  onClick: (task: IMilestoneTask) => void
}

const MilestoneRowStyles = makeStyles(() => ({
  root: {
    minHeight: '48px !important',

    '& .MuiAccordionSummary-contentGutters': {
      margin: 0,
    },
  },
}))

const milestoneStatusColor: { [status: string]: ISelectChipColor } = {
  open_task: 'secondary',
  overdue: 'error',
}

const notUpdatableManuallyTaskTypeCodes: string[] = [
  'upload_commercial_invoice',
  'upload_packing_list',
  'add_notify_party_address',
  'add_consignee_address',
  'add_shipper_address',
  'add_delivery_address',
  'request_delivery_date',
  'request_pickup_date',
  'add_pickup_address',
]

export const MilestoneRow: React.FC<MilestoneRowProps> = ({
  milestone,
  onClick,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = MilestoneRowStyles()
  const isUpcoming: boolean = milestone.status === 'upcoming'
  const statusColor: ISelectChipColor =
    milestoneStatusColor[milestone.status] || 'default'
  const statusContent = capitalize(startCase(milestone.status))

  const updateTaskStatusAsync = promisifyAction(dispatch, updateTaskStatus)
  const shipmentContainersGetDataAsync = promisifyAction(
    dispatch,
    shipmentContainersGetData
  )

  const [expanded, setExpanded] = useState<string | boolean>(isUpcoming)

  const milestoneName = (task: IMilestoneTask): string => {
    if (!!task.container) {
      return `${task.task_type.name} for ${toLower(
        task.container.container_index
      )} ${task.container.number ? '- ' + task.container.number : ''}`
    }
    return task.task_type.name
  }

  const handleChange = (panel: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false)
  }

  const isTaskResolved = (task) =>
    task.task_status?.task_status_type.code === 'resolved'

  const onTaskChange = useCallback(async (task, item) => {
    await updateTaskStatusAsync(task.shipment.id, task.id, {
      task_status_id: item.id,
    })
    await shipmentContainersGetDataAsync(task.shipment.id)
  }, [])

  const StatusSelector = (task) => {
    if (!notUpdatableManuallyTaskTypeCodes.includes(task.task_type.code)) {
      const options = task.possible_task_statuses.map((x) => ({
        ...x,
        icon: undefined,
        type: 'item',
        name: x.name,
        color: x.color ? chipColor[x.color] : 'default',
      }))
      const value = options.find((x) => x.id === task.task_status.id)

      return (
        <SelectableChip
          options={options as ISelectOption[]}
          size="small"
          sx={{ marginLeft: '10px' }}
          value={value as ISelectOption}
          variant="filled"
          onChange={(item) => {
            onTaskChange(task, item)
          }}
        />
      )
    }

    return null
  }

  return (
    <Accordion
      sx={{
        border: '1px solid',
        borderColor: 'grey.100',
        boxShadow: 'none',
        borderRadius: 1,
        marginBottom: '8px',
      }}
      expanded={!!expanded}
      onChange={handleChange(milestone.name)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        classes={classes}
      >
        <Box display="flex" alignItems="center" width="99%">
          <Typography pr={2} variant="body1Strong">
            {milestone.name}
          </Typography>
          <SelectableChip
            disabled
            size="small"
            value={{
              name: statusContent,
              color: statusColor,
              icon:
                statusColor === 'error' ? (
                  <ErrorOutlineRoundedIcon />
                ) : undefined,
            }}
            variant="filled"
          />
          <Typography variant="body2" ml="auto" color="grey.600">
            {milestone.due_date &&
              t('milestone.tasks.due_on', {
                defaultValue: 'Due {{date, DATE_MED}}',
                date: milestone.due_date,
              })}
          </Typography>
        </Box>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        {milestone.tasks.map((task) => (
          <Box
            display="flex"
            alignItems="center"
            py={1}
            key={task.id}
            data-testid="milestone-row-task-item"
          >
            <CompletionPoint completed={isTaskResolved(task)} />
            {isTaskResolved(task) && (
              <Typography
                variant="body1"
                pl={1}
                children={milestoneName(task)}
              />
            )}
            {!isTaskResolved(task) && (
              <Link
                onClick={() => {
                  onClick(task)
                }}
                sx={{ paddingLeft: 1 }}
                variant="body1"
                component="button"
                children={milestoneName(task)}
              />
            )}
            {StatusSelector(task)}
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  )
}

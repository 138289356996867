import { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import { find } from 'lodash'

import TimelineActivePoint from '../TimelineActivePoint'
import TimelineProgressSection from '../TimelineProgressSection'
import TimelinePickup from '../TimelinePickup'
import TimelinePOL from '../TimelinePOL'
import TimelinePOD from '../TimelinePOD'
import TimelineLegs from '../TimelineLegs'
import TimelineDelivery from '../TimelineDelivery'
import TimelineQuotation from '../TimelineQuotation'
import BlankTimeline from '../BlankTimeline'

import {
  getTotalPhaseCount,
  getPercentagePerPhase,
  getTimelineMainPhases,
  getTimelineProgressClass,
} from '../helpers'

const ShipmentTimelinePhases: FunctionComponent<any> = ({
  timeline,
  public_shipment,
  public_shipment_legs,
}) => {
  const { shipment, phases } = useSelector((state: IGlobalState) => ({
    shipment: public_shipment || state.shipmentOverview,
    phases: timeline || state.shipments.timeline,
  }))

  if (!shipment.id || !phases) {
    return <BlankTimeline />
  }

  const isPublicShipment = !!public_shipment_legs
  const isQuote = ['QUO', 'PQUO'].includes(shipment.status)

  const originPhase: IShipmentPhase | undefined = find(phases, {
    code: 'origin',
  }) as IShipmentPhase
  const inTransitPhase: IShipmentPhase = find(phases, {
    code: 'in_transit',
  }) as IShipmentPhase
  const destinationPhase: IShipmentPhase = find(phases, {
    code: 'destination',
  }) as IShipmentPhase

  const { shipment_legs: legs } = shipment
  const legsCount = legs ? legs.length : public_shipment_legs.length
  const totalPhaseCount = getTotalPhaseCount(legsCount)
  const percentagePerPhase = getPercentagePerPhase(totalPhaseCount)
  const mainPhases = getTimelineMainPhases(legsCount, percentagePerPhase)
  return (
    <div className="shipment-timeline--phases">
      {isQuote ? (
        <div className="shipment-timeline--phases-item">
          <div className="shipment-timeline--phases-item--progress-background"></div>
          <TimelineProgressSection
            totalPhaseCount={totalPhaseCount}
            percentage={shipment.progress_percent}
            threshold={mainPhases.start}
          />
          <div
            className={`shipment-timeline--phases-item--point ${getTimelineProgressClass(
              shipment.progress_percent,
              mainPhases.start
            )}`}
          ></div>
          <TimelineActivePoint
            type={shipment.shipment_type}
            percentage={shipment.progress_percent}
            threshold={mainPhases.start}
            status={shipment.humanized_status}
            vessel={shipment.vessel}
            totalPhaseCount={totalPhaseCount}
            isPublicShipment={isPublicShipment}
          />
          <TimelineQuotation shipment={shipment} />
        </div>
      ) : (
        ''
      )}
      <div className="shipment-timeline--phases-item">
        <div className="shipment-timeline--phases-item--progress-background"></div>
        <TimelineProgressSection
          totalPhaseCount={totalPhaseCount}
          percentage={shipment.progress_percent}
          threshold={mainPhases.start}
        />
        <div
          className={`shipment-timeline--phases-item--point ${
            !isQuote && shipment.progress_percent > 0 ? 'completed' : ''
          }`}
        ></div>
        {isQuote ? (
          ''
        ) : (
          <TimelineActivePoint
            type={shipment.shipment_type}
            percentage={shipment.progress_percent}
            threshold={mainPhases.start}
            status={shipment.humanized_status}
            vessel={shipment.vessel}
            totalPhaseCount={totalPhaseCount}
            isPublicShipment={isPublicShipment}
          />
        )}

        <TimelinePickup
          shipment={shipment}
          phase={originPhase}
          milestones={originPhase.milestones}
        />
      </div>
      <div className="shipment-timeline--phases-item">
        <div className="shipment-timeline--phases-item--progress-background"></div>
        <TimelineProgressSection
          totalPhaseCount={totalPhaseCount}
          percentage={shipment.progress_percent}
          threshold={mainPhases.pol}
        />
        <div
          className={`shipment-timeline--phases-item--point ${getTimelineProgressClass(
            shipment.progress_percent,
            mainPhases.pol
          )}`}
        ></div>
        <TimelineActivePoint
          type={shipment.shipment_type}
          percentage={shipment.progress_percent}
          threshold={mainPhases.pol}
          status={shipment.humanized_status}
          vessel={shipment.vessel}
          totalPhaseCount={totalPhaseCount}
          isPublicShipment={isPublicShipment}
        />
        <TimelinePOL
          shipment={shipment}
          milestones={inTransitPhase.milestones}
        />
      </div>
      {(shipment.shipment_legs || public_shipment_legs).length > 1 && (
        <TimelineLegs
          shipment={shipment}
          publicShipmentLegs={public_shipment_legs}
          totalPhaseCount={totalPhaseCount}
          percentagePerPhase={percentagePerPhase}
          percentageForFirstPhase={mainPhases.pol}
        />
      )}
      <div className="shipment-timeline--phases-item">
        <div className="shipment-timeline--phases-item--progress-background"></div>
        <TimelineProgressSection
          totalPhaseCount={totalPhaseCount}
          percentage={shipment.progress_percent}
          threshold={mainPhases.pod}
        />
        <div
          className={`shipment-timeline--phases-item--point ${getTimelineProgressClass(
            shipment.progress_percent,
            mainPhases.pod
          )}`}
        ></div>
        <TimelineActivePoint
          type={shipment.shipment_type}
          percentage={shipment.progress_percent}
          threshold={mainPhases.pod}
          status={shipment.humanized_status}
          vessel={shipment.vessel}
          totalPhaseCount={totalPhaseCount}
          isPublicShipment={isPublicShipment}
        />
        <TimelinePOD
          shipment={shipment}
          milestones={destinationPhase.milestones}
        />
      </div>
      <div className="shipment-timeline--phases-item">
        <div
          className={`shipment-timeline--phases-item--point ${getTimelineProgressClass(
            shipment.progress_percent,
            mainPhases.end
          )}`}
        ></div>
        <TimelineActivePoint
          type={shipment.shipment_type}
          percentage={shipment.progress_percent}
          threshold={mainPhases.end}
          status={shipment.humanized_status}
          vessel={shipment.vessel}
          totalPhaseCount={totalPhaseCount}
          isPublicShipment={isPublicShipment}
        />
        <TimelineDelivery shipment={shipment} phase={destinationPhase} />
      </div>
    </div>
  )
}

export default ShipmentTimelinePhases

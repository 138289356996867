import { takeEvery, takeLatest } from 'redux-saga/effects'

import * as actions from '../../actions/shipments'
import * as actionCreators from '../../actionCreators/shipments'
import {
  simpleGetAndResolve,
  simplePutAndResolve,
  simplePatchAndResolve,
  simplePostAndResolve,
  simpleDeleteAndResolve,
} from '../factories'

const getShipmentStatuses = simpleGetAndResolve(
  () => `/api/v1/common/shipment_statuses`,
  actionCreators.getShipmentStatusesSuccess,
  (response) => response.data
)

const getShipments = simpleGetAndResolve(
  (action) => `/api/v1/shipments/`,
  actionCreators.shipmentsGetDataSuccess,
  (response) => response.data
)

const getShipmentParties = simpleGetAndResolve(
  (action) => `/api/v1/shipments/${action.id}/parties`,
  actionCreators.shipmentsGetPartiesSuccess,
  (response) => response.data
)

const getShipmentOrganizationRoles = simpleGetAndResolve(
  (action) => `/api/v1/common/organization_shipment_roles`,
  actionCreators.shipmentsGetOrganizationRolesSuccess,
  (response) => response.data
)

const updateShipmentData = simplePutAndResolve(
  (action) => `/api/v1/shipments/${action.id}`,
  (response) => response.data
)

const modifyShipmentData = simplePatchAndResolve(
  (action) => `/api/v1/shipments/${action.id}`,
  (response) => response.data
)

const getShipmentTimeline = simpleGetAndResolve(
  (action) => `/api/v1/shipments/${action.id}/timeline`,
  actionCreators.shipmentsGetTimelineSuccess,
  (response) => response.data
)

const getShipmentsMeta = simpleGetAndResolve(
  (action) => `/api/v1/shipments/meta`,
  actionCreators.shipmentsGetMetaDataSuccess,
  (response) => response.data
)

const requestExcel = simpleGetAndResolve(
  (action) => `/api/v1/shipments/request_export`,
  actionCreators.requestExcelSuccess,
  (response) => response.data
)

const getShipmentsToAssignPO = simpleGetAndResolve(
  (action) => `/api/v1/shipments/assignable_to_purchase_order`,
  actionCreators.shipmentsToAssignPOSuccess,
  (response) => response.data
)

const connectionsUpdate = simplePutAndResolve(
  (action) => `/api/v1/shipments/${action.id}/shipment_collaborators`,
  actionCreators.updateConnectionsSuccess,
  (response) => response.data
)

const createVisibilityShipment = simplePostAndResolve(
  () => '/api/v1/shipments',
  actionCreators.createVisibilityShipmentSuccess,
  (response) => response.data
)

const getVisibilityCarriers = simpleGetAndResolve(
  () => '/api/v1/shipments/visibility_shipment_carriers',
  actionCreators.getVisibilityShipmentCarriersSuccess,
  (response) => response.data
)

const getUniqueTrackingKey = simpleGetAndResolve(
  () => '/api/v1/shipments/unique_tracking_key',
  actionCreators.getUniqueTrackingKeySuccess,
  (response) => response.data
)

const addQuickFilter = simplePostAndResolve(
  () => '/api/v1/saved_filters',
  (response) => response.data
)

const putQuickFilter = simplePutAndResolve(
  (action) => `/api/v1/saved_filters/${action.id}`,
  (response) => response.data
)

const deleteQuickFilter = simpleDeleteAndResolve(
  (action) => `/api/v1/saved_filters/${action.id}`,
  (response) => response.data
)

const manageQuickFilters = simplePutAndResolve(
  () => `/api/v1/saved_filters/bulk_update`,
  (response) => response.data
)

const shareShipmentViaEmail = simplePostAndResolve(
  (action) => `/api/v1/shipments/${action.id}/share_via_email`,
  actionCreators.shareShipmentViaEmailSuccess,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeLatest(actions.GET_SHIPMENT_STATUSES, getShipmentStatuses)
  yield takeEvery(actions.SHIPMENTS_GET_DATA, getShipments)
  yield takeEvery(actions.SHIPMENTS_GET_PARTIES, getShipmentParties)
  yield takeEvery(
    actions.SHIPMENTS_GET_ORGANIZATION_ROLES,
    getShipmentOrganizationRoles
  )
  yield takeEvery(actions.SHIPMENTS_GET_TIMELINE, getShipmentTimeline)
  yield takeEvery(actions.SHIPMENTS_GET_META_DATA, getShipmentsMeta)
  yield takeEvery(actions.SHIPMENTS_REQUEST_EXCEL, requestExcel)
  yield takeEvery(actions.SHIPMENTS_TO_ASSIGN_PO_GET, getShipmentsToAssignPO)
  yield takeEvery(actions.SHIPMENTS_UPDATE_CONNECTIONS, connectionsUpdate)
  yield takeEvery(actions.SHIPMENT_UPDATE_DATA, updateShipmentData)
  yield takeEvery(actions.SHIPMENT_MODIFY_DATA, modifyShipmentData)
  yield takeEvery(actions.CREATE_VISIBILITY_SHIPMENT, createVisibilityShipment)
  yield takeEvery(
    actions.GET_VISIBILITY_SHIPMENT_CARRIERS,
    getVisibilityCarriers
  )
  yield takeEvery(
    actions.SHIPMENTS_GET_UNIQUE_TRACKING_KEY,
    getUniqueTrackingKey
  )

  yield takeEvery(actions.ADD_QUICK_FILTER, addQuickFilter)
  yield takeEvery(actions.PUT_QUICK_FILTER, putQuickFilter)
  yield takeEvery(actions.DELETE_QUICK_FILTER, deleteQuickFilter)
  yield takeEvery(actions.SHIPMENTS_MANAGE_QUICK_FILTERS, manageQuickFilters)
  yield takeEvery(actions.SHIPMENTS_SHARE_EMAIL, shareShipmentViaEmail)
}

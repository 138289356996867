import { FunctionComponent, useEffect, useState, useMemo } from 'react'
import isEmail from 'validator/lib/isEmail'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { forEach } from 'lodash'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { Button, Link } from '@mui/material'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import Textarea from 'src/components/Common/Textarea'
import MuiMultipleInput from '../../../components/Common/Input/MuiMultipleInput'
import getClassName from '../../../utils/styling/index'
import { promisifyAction } from '../../../utils'

import { purchaseOrderShareEmail } from '../../../stores/actionCreators'

interface IProps {
  close: () => void
  copyItemPublicLink: () => void
  purchaseOrderId: number
  refreshOrdersData?: () => void
  isCopyDisabled: boolean
}

const SharePurchaseOrder: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [emails, setEmails] = useState<string[]>([])
  const [emailError, setEmailError] = useState<string | null>(null)
  const [additionalMessage, setAdditionalMessage] = useState<string>('')
  const [messageError, setMessageError] = useState<string | null>(null)

  const shareEmailAsync = promisifyAction(dispatch, purchaseOrderShareEmail)

  useEffect(() => {
    forEach(emails, (email: string) => {
      if (!isEmail(email)) {
        setEmailError(
          t(
            'purchase_orders.row_details.notifications.invalid_email',
            'One or more email addresses are invalid'
          )
        )
        return false
      } else {
        setEmailError(null)
        return true
      }
    })
  }, [emails])

  const handleChangeArea = (value: string): void => {
    setAdditionalMessage(value)
    setMessageError(
      value && value.length >= 200
        ? t(
            'purchase_orders.row_details.notifications.message_too_long',
            'Message cannot be longer than 200 characters'
          )
        : null
    )
  }

  const onSaveAndClose = async (): Promise<any> => {
    if (!emails.length) {
      setEmailError(
        t(
          'purchase_orders.row_details.notifications.invalid_email',
          'One or more email addresses are invalid'
        )
      )
    }

    if (!!emails.length && !emailError) {
      const emailData = {
        to_email_addresses: emails,
        additional_message_consignee: additionalMessage,
      }
      try {
        await shareEmailAsync(props.purchaseOrderId, emailData)
        dispatch(
          showNotification({
            message: t(
              'purchase_orders.row_details.notifications.order_shared',
              'Order shared.'
            ),
            severity: 'success',
          })
        )
      } catch {
        dispatch(
          showNotification({
            message: t(
              'purchase_orders.row_details.notifications.unable_to_share',
              'Unable to share the email, please contact customer service.'
            ),
            severity: 'error',
          })
        )
      }
      props.close()
      if (props.refreshOrdersData) {
        await props.refreshOrdersData()
      }
    }
  }

  const emailInputChange = (values, name) => {
    if (values.length) {
      setEmails(values)
    } else {
      setEmails([])
      setEmailError(null)
    }
  }

  const disableCondition = useMemo(() => {
    return !emails.length || !!emailError
  }, [emails, emailError])

  return (
    <ClickAwayListener onClickAway={props.close}>
      <div className="purchase-order__details--sharing-email">
        <div className="purchase-order__details--sharing-email--header">
          <div className="paragraph__medium strong blue">
            {t('purchase_orders.row_details.share.title', 'Share')}
          </div>
          <Link
            variant="body1"
            component="button"
            onClick={props.copyItemPublicLink}
            disabled={props.isCopyDisabled}
          >
            <LinkRoundedIcon />
            {t('purchase_orders.row_details.copy_link_button', 'Copy link')}
          </Link>
        </div>
        <div className="sharing-email-block">
          <MuiMultipleInput
            name="emails"
            label=""
            placeholder={t(
              'purchase_orders.row_details.share.email_field.placeholder',
              'Enter email addresses'
            )}
            onChange={emailInputChange}
            error={!!emailError}
          />
          {!!emailError && (
            <div
              className={getClassName('error-message', {
                hidden: !emailError,
              })}
            >
              {emailError}
            </div>
          )}
        </div>
        <div className="sharing-email-block">
          <Textarea
            className={`purchase-order__details--sharing-email--area  ${
              messageError ? 'value-error' : ''
            }`}
            onChange={handleChangeArea}
            rows={4}
            placeholder={t(
              'purchase_orders.row_details.share.message_field.placeholder',
              'Add a message'
            )}
            maxLength={200}
            value={additionalMessage}
          />
          {!!messageError && (
            <div
              className={getClassName('error-message', {
                hidden: !messageError,
              })}
            >
              {messageError}
            </div>
          )}
        </div>
        <div className="purchase-order__details--sharing-email--actions">
          <Button
            variant="contained"
            onClick={onSaveAndClose}
            disabled={disableCondition}
          >
            {t('purchase_orders.row_details.send_button', 'Send')}
          </Button>
        </div>
      </div>
    </ClickAwayListener>
  )
}

export default SharePurchaseOrder

import { useMemo, memo } from 'react'
import { useTranslation } from 'react-i18next'
import TableWrapper from 'src/components/Common/Table/DataTable/TableWrapper'
import { getTableColumns } from './utils'
import {
  tableStorageKey,
  defaultColumnOrder,
  defaultColumnVisibility,
} from './constants'
import WrappedTable from './ContentTableColumns'

const ContentTable = () => {
  const { t } = useTranslation()
  const columns = useMemo(() => {
    return getTableColumns(t)
  }, [t])

  return (
    <TableWrapper
      columns={columns}
      data-testid="overview-table"
      tableStorageKey={tableStorageKey}
      defaultColumnOrder={defaultColumnOrder}
      defaultColumnVisibility={defaultColumnVisibility}
      ComponentProps={{
        elevation: 0,
        sx: {
          height: '100%',
          overflowY: 'hidden',
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          position: 'relative',
        },
      }}
      WrappedTable={WrappedTable}
    />
  )
}

export default memo(ContentTable)

import { FunctionComponent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore, useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { isEqual } from 'lodash'
import { Box, Button, Typography } from '@mui/material'
import Autocomplete from 'src/stories/SearchBar'
import Modal from 'src/components/Common/Modal'
import useLocalStorage from 'src/hooks/useLocalStorage'
import {
  organizationsGetData,
  toggleApplicationFilterModal,
} from '../../stores/actionCreators'
import { promisifyAction } from '../../utils'

interface IProps {
  close?: () => void
}

const ApplicationFilterModal: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const store = useStore()

  const [
    organization,
    setOrganization,
  ] = useLocalStorage<IOrganizationShort | null>('scopedOrganization', null)

  const [
    selectedOrganization,
    setSelectedOrganization,
  ] = useState<IOrganizationShort | null>(null)

  const organizationsGetDataAsync = promisifyAction(
    dispatch,
    organizationsGetData
  )

  const closeApplicationFilterModal = promisifyAction(
    dispatch,
    toggleApplicationFilterModal
  )

  const { displayApplicationFilterModal } = useSelector(
    (globalState: IGlobalState) => ({
      displayApplicationFilterModal:
        globalState.flags.displayApplicationFilterModal,
    })
  )

  const close = () => {
    closeApplicationFilterModal(false)
  }

  const updateScopingOrganization = (
    selectedOrganization: IOrganizationShort | null
  ): void => {
    setSelectedOrganization(selectedOrganization)
  }

  const applyFilter = (): void => {
    const sameOrganization = isEqual(organization, selectedOrganization)
    if (!sameOrganization) {
      setOrganization(selectedOrganization)
      window.location.reload()
    } else {
      setSelectedOrganization(null)
    }
    close()
  }

  const fetchOrganizations = useCallback((searchInput: string) => {
    const fetchOrganizationsAsync = async (input: string) => {
      await organizationsGetDataAsync({ search: input.toLowerCase() })
      const organizations: IOrganizationShort[] = store.getState().organizations
        .list
      return organizations
    }
    return fetchOrganizationsAsync(searchInput)
  }, [])

  return (
    <Modal.Window open={displayApplicationFilterModal} onClose={close}>
      <Modal.Title
        children={t(
          'top_navigation.modals.filter_applicaiton.title',
          'Filter application'
        )}
        onClose={close}
      />
      <Modal.Content>
        <Typography variant="body1Strong">
          {t(
            'top_navigation.modals.filter_applicaiton.description',
            'Which organization do you want to filter by?'
          )}
        </Typography>
        <Box mt={1}>
          <Autocomplete
            onChange={updateScopingOrganization}
            value={selectedOrganization}
            placeholder={t(
              'top_navigation.modals.filter_applicaiton.input_placeholder',
              'Select organization...'
            )}
            getData={fetchOrganizations}
          />
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="outlined" onClick={close}>
          {t(
            'top_navigation.modals.filter_applicaiton.cancel_button',
            'Cancel'
          )}
        </Button>
        <Button
          variant="contained"
          onClick={applyFilter}
          disabled={!selectedOrganization}
        >
          {t(
            'top_navigation.modals.filter_applicaiton.apply_button',
            'Apply filter'
          )}
        </Button>
      </Modal.Actions>
    </Modal.Window>
  )
}
export default ApplicationFilterModal

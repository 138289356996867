import { stringify } from 'query-string'
import uniq from 'lodash/uniq'
import reduce from 'lodash/reduce'
import orderBy from 'lodash/orderBy'
import toNumber from 'lodash/toNumber'
import startCase from 'lodash/startCase'
import { RatesListTypeEnum } from 'src/@types/endpoints/prices'
import { TFunction } from 'i18next'
import {
  formattedDateNow,
  convertEstimatedDate,
  convertDateForComparison,
} from 'src/utils/helpers'

import { rangeItemSeparator, rangeCalculationMethods } from './constants'

export const getFormattedRange = (
  start: string | null,
  end: string | null,
  formatter?: Function
) => {
  return uniq(
    reduce(
      [start, end],
      (filtered: string[], item) => {
        if (!!item) {
          const value = formatter ? formatter(item) : item
          filtered.push(`${value}`)
        }
        return filtered
      },
      []
    )
  ).join(` ${rangeItemSeparator} `)
}

export const getHumanizeCalculationMethod = (method: string) => {
  if (method.length <= 3) return method.toUpperCase()
  return rangeCalculationMethods?.[method] ?? startCase(method)
}

export const getPrice = (price: string) => {
  if (price.includes(rangeItemSeparator)) {
    return price
  }
  return parseFloat(price) <= 0 ? 'Free' : price
}

export const getRange = (min: string | null, max: string | null) => {
  return getFormattedRange(min, max, parseInt)
}

export const getDateRange = (from: string | null, to: string | null) => {
  return getFormattedRange(from, to, convertEstimatedDate)
}

export const isHistoricRate = (date: string): boolean => {
  return formattedDateNow() > convertDateForComparison(date)
}

export const isFutureRate = (date: string): boolean => {
  return convertDateForComparison(date) > formattedDateNow()
}

export const stringifyParams = (
  queryObject: ILclRequestQuoteParams | IFclRequestQuoteParams
) => stringify(queryObject, { arrayFormat: 'bracket' }).replace(/%2C/gi, ',')

export const roundPrice = (price: string): number | string => {
  const newPrice = toNumber(price || '')
  return newPrice ? Math.ceil(newPrice) : rangeItemSeparator
}

export const getActionButtonText = (
  isFreightPresent: boolean,
  t: TFunction<'translation', undefined>
) => {
  if (isFreightPresent) {
    return t('rates.table_content.search_with_rate', 'Search with rate')
  }
  return t('rates.table_content.request_quote', 'Request quote')
}

export const getIsFreightPresent = (prices: IRatesPriceItem[]): boolean => {
  return prices.some((x) => x.service_item.service_code === 'freight')
}

export const getSortedRatesBasedOnListType = (
  listType: RatesListTypeEnum,
  sortOrder: boolean,
  sortValue: string,
  rates: IRate[] | null
) => {
  const order = sortOrder ? 'asc' : 'desc'
  const sortDependencies: string | string[] =
    sortValue === 'status' ? ['valid_to', 'valid_from'] : sortValue

  const defaultSort =
    listType === RatesListTypeEnum.air
      ? 'rates_per_chargeable_weight'
      : 'hasOceanFreightPrices'

  return orderBy(rates, [defaultSort, sortDependencies], ['desc', order])
}

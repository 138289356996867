import { noop } from 'lodash'

import { flagsUpdateApp } from '../../stores/actionCreators'
import { store } from '../../shyppleStore'

const deployMessageHandlers = {
  done(message: any): void {
    store.dispatch(flagsUpdateApp(true))
  },
}

const messageTypeHandlers = {
  deploy(message: any): void {
    ;(deployMessageHandlers[message.message.deploy] || noop)(message)
  },
}

const ShyppleChannel = {
  connected: noop,
  disconnected: noop,

  received(message: any) {
    ;(messageTypeHandlers[message.message_type] || noop)(message)
  },
}

export default ShyppleChannel

import LocationOnIcon from '@mui/icons-material/LocationOn'
import BusinessIcon from '@mui/icons-material/Business'
import { modalityIcons } from 'src/stories/MUI/ShipmentContent/components/ShipmentModalityIcon'
import { ModalityEnum } from 'src/config/constants'
import { services } from 'src/pages/SearchAndBook/constants'
import {
  FormStateProps,
  RequestHandlingFormErrorProps,
  FormStateBookingPartiesProps,
} from './Form.props'

export const defaultServiceValues = services(ModalityEnum.Sea)
  .map((service) => service.value)
  .reduce((serviceMap, service) => {
    serviceMap[service] = false
    return serviceMap
  }, {})

export const defaultBookingParties: FormStateBookingPartiesProps = {
  shipper: {
    collaborator: null,
    address: null,
  },
  consignee: {
    collaborator: null,
    address: null,
  },
}

export const initialState: FormStateProps = {
  customer: null,
  shipmentRole: '',
  cifValue: 0,
  incoterm: '',
  services: defaultServiceValues,
  pickupAddress: null,
  deliveryAddress: null,
  bookingParties: { ...defaultBookingParties },
  container_number: '',
  booking_number: '',
  bl_number: '',
  carrier: null,
  references: {
    sharedReference: '',
    internalReference: '',
  },
  sellerOrganization: null,
}

export const bookingPartyKeys = ['shipper', 'consignee']

export const initialFormErrors: RequestHandlingFormErrorProps = {
  bookingParties: false,
  sharedReference: '',
  container_number: '',
  booking_number: '',
  bl_number: '',
}

export const selectOptionTypeIconMap = {
  ...modalityIcons,
  address: LocationOnIcon,
  collaborator: BusinessIcon,
}

import {
  Box,
  Stack,
  InputLabel,
  Checkbox,
  IconButton,
  Typography,
} from '@mui/material'
import MuiMultipleInput from 'src/components/Common/Input/MuiMultipleInput'
import { useTranslation } from 'react-i18next'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import isEmail from 'validator/lib/isEmail'
import { IShareData } from '.'

const EmailAddressesComponent = (props: {
  options: IShareData['options']
  values: string[]
  onChange: (value: Record<string, any>) => void
}) => {
  const { t } = useTranslation()
  const onChange = (values: string[] | number[] | { [key: string]: any }[]) => {
    const newValues: string[] = values.map((v) => v.id ?? v)
    const uniqueOptions = [
      ...new Set([...props.options.map((o) => o.id), ...newValues]),
    ]
    const newOptions = uniqueOptions.map((o) => ({ title: o, id: o }))

    props.onChange({
      to_email_addresses: newValues.filter((v) => isEmail(v)),
      options: newOptions.filter((option) => isEmail(option.title)),
    })
  }

  const onDelete = (e, option: { title: string; id: string }) => {
    e.stopPropagation()
    const newOptions = props.options.filter((email) => email.id !== option.id)
    const newValues = props.values.filter((email) => email !== option.id)

    props.onChange({ to_email_addresses: newValues, options: newOptions })
  }

  return (
    <Box>
      <Stack direction="row" spacing={0.5}>
        <InputLabel htmlFor="customer">
          {t('shipment_share.email.to.label', 'To*')}
        </InputLabel>
        <Typography
          variant="body1"
          children={t(
            'shipment_share.email.press_enter_after_each_email',
            'Press enter after each email address'
          )}
        />
      </Stack>
      <MuiMultipleInput
        name="to_email_addresses"
        label=""
        freeSolo
        disableCloseOnSelect
        placeholder={t(
          'shipment_share.email.to.placeholder',
          'Enter email address(es)'
        )}
        options={props.options}
        onChange={onChange}
        values={props.values}
        saveOnBlur
        customOption={(optionProps, option, selected) => (
          <li {...optionProps}>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            <div style={{ width: '100%' }}>{option['title']}</div>
            <IconButton
              aria-label="delete"
              onClick={(e) => onDelete(e, option)}
            >
              <DeleteRoundedIcon />
            </IconButton>
          </li>
        )}
      />
    </Box>
  )
}

export default EmailAddressesComponent

// @ts-nocheck
// FIXME

import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { withStyles } from '@mui/styles'
import Box from '@mui/material/Box'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { pickBy, xor, isEmpty, omitBy } from 'lodash'
import qs from 'query-string'
import { useLocation } from 'react-router-dom'
import { Button, Paper } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import { history } from 'src/shyppleStore'
import {
  fetchReportsDashboardShipments,
  updateReportsDashboardFilters,
  fetchReportsDashboardFilterOptions,
  updateReportsDashboardAsyncFilters,
  reportsDashboardClearFilters,
} from 'src/stores/actionCreators'
import Input from 'src/components/Common/Input'
import useInterval from 'src/hooks/useInterval'
import useDeepCompareEffect from 'src/hooks/useDeepCompareEffect'
import { routeByScope } from '../ReportsDashboardTabs/tabRoutes'
import scopeByRoute from '../ReportsDashboardTabs/scopeByRoute'
import PortFilter from './filters/PortFilter'
import OrganizationFilter from './filters/OrganizationFilter'
import { getStateFromQueryString } from './utils'
import useSavedFilters from './hooks/useSavedFilters'

const formatQueryString = {
  page: parseInt,
  per_page: parseInt,
}

const FILTERS = [
  'search',
  'load_type',
  'cluster',
  'modality',
  'pol',
  'pod',
  'organization',
  'rolling_status',
  'service_department',
]

const MoreFiltersButton = withStyles((theme) => ({
  startIcon: {
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.complex,
    }),
    transform: ({ open }: { open: boolean }) =>
      open ? 'rotate(180deg)' : 'rotate(0)',
  },
}))(Button)

const ReportsDashboardFilters: FunctionComponent<any> = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const savedFilters = useSavedFilters()

  const [showAllFilters, setShowAllFilters] = useState<boolean>(false)

  const { scope, filters, filterOptions, asyncFilterOptions } = useSelector(
    (state: IGlobalState) => ({
      scope: state.reportsDashboard.scope,
      filters: state.reportsDashboard.filters,
      filterOptions: state.reportsDashboard.filterOptions,
      asyncFilterOptions: state.reportsDashboard.asyncFilterOptions,
    }),
    shallowEqual
  )

  const activeFilters = Object.keys(omitBy(filters, isEmpty)).filter((key) =>
    FILTERS.includes(key)
  )

  useEffect(() => {
    const currentScope: string = scopeByRoute(location.pathname)
    let newState = getStateFromQueryString(
      qs.parse(location.search, {
        arrayFormat: 'bracket',
      }),
      formatQueryString
    )
    if (savedFilters[currentScope]) {
      newState = { ...newState, ...savedFilters[currentScope] }
    }

    dispatch(updateReportsDashboardFilters(newState))
    dispatch(fetchReportsDashboardFilterOptions())
  }, [])

  useDeepCompareEffect(() => {
    if (!scope) {
      return
    }

    // cleaning up rolling_status if browsing not a rolling tab.
    if (scope !== 'rollings' && filters.rolling_status.length) {
      dispatch(updateReportsDashboardFilters({ rolling_status: [] }))
      return
    }

    const filtersForRequest = pickBy(filters, (value) => !!value)
    const filtersWithScope = { ...filtersForRequest, scope: scope }
    dispatch(fetchReportsDashboardShipments(filtersWithScope))

    history.push({
      search: qs.stringify(filtersForRequest, {
        arrayFormat: 'bracket',
      }),
      pathname: routeByScope(scope) as string,
    })
  }, [filters, scope])

  useInterval(() => {
    const filtersForRequest = pickBy(filters, (value) => !!value)
    dispatch(
      fetchReportsDashboardShipments(
        { ...filtersForRequest, scope: scope },
        true
      )
    )
  }, 5 * 60 * 1000)

  const pickFilterOption = useCallback(
    (optionId: string, fieldName: string): void => {
      dispatch(
        updateReportsDashboardFilters({
          [fieldName]: xor(filters[fieldName], [optionId]),
        })
      )
    },
    [filters, updateReportsDashboardFilters]
  )

  const pickAsyncOption = useCallback(
    (option: string[][], fieldName: string): void => {
      dispatch(
        updateReportsDashboardAsyncFilters({
          [fieldName]: option,
        })
      )
    },
    [updateReportsDashboardAsyncFilters]
  )

  const resetFiltersOfType = useCallback(
    (fieldName: string): void => {
      dispatch(
        updateReportsDashboardFilters({
          [fieldName]: [],
        })
      )
    },
    [updateReportsDashboardFilters]
  )

  const onToggleFilters = useCallback(() => {
    setShowAllFilters((value) => !value)
  }, [setShowAllFilters])

  const clearFilters = useCallback(() => {
    dispatch(reportsDashboardClearFilters())
  }, [reportsDashboardClearFilters])

  const changeSearch = useCallback((search: string) => {
    dispatch(updateReportsDashboardFilters({ search }))
  }, [])

  return (
    <Paper variant="elevation">
      <Box display="flex" mb={2} alignItems="center">
        <Box p={2}>
          <Box
            height={showAllFilters ? 'auto' : 40}
            overflow={showAllFilters ? 'auto' : 'hidden'}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            <Box mr={2} mb={1} display="inline-block">
              <Input.SearchBar
                data-testid="reports-dashboard-search-input"
                value={filters.search}
                onChange={changeSearch}
                text="shipments"
                disabled={false}
              />
            </Box>
            <Box mr={2} mb={1} display="inline-block">
              <Input.OptionsPicker
                options={filterOptions.load_types}
                title="Load Types"
                searchable={true}
                selectedTitle="Load Types"
                fieldName="load_type"
                onChange={pickFilterOption}
                onCancel={resetFiltersOfType}
                values={filters.load_type}
                size="short"
                disabled={false}
              />
            </Box>
            <Box mr={2} mb={1} display="inline-block">
              <Input.OptionsPicker
                options={filterOptions.clusters}
                title="Clusters"
                searchable={true}
                selectedTitle="Clusters"
                fieldName="cluster"
                onChange={pickFilterOption}
                onCancel={resetFiltersOfType}
                values={filters.cluster}
                size="short"
                disabled={false}
              />
            </Box>
            <Box mr={2} mb={1} display="inline-block">
              <Input.OptionsPicker
                options={filterOptions.modalities}
                title="Modalities"
                searchable={true}
                selectedTitle="Modalities"
                fieldName="modality"
                onChange={pickFilterOption}
                onCancel={resetFiltersOfType}
                values={filters.modality}
                size="short"
                disabled={false}
              />
            </Box>
            <Box mr={2} mb={1} display="inline-block">
              <Input.OptionsPicker
                options={filterOptions.service_departments}
                title="Ser. departments"
                searchable={true}
                selectedTitle="Service deps"
                fieldName="service_department"
                onChange={pickFilterOption}
                onCancel={resetFiltersOfType}
                values={filters.service_department}
                disabled={false}
              />
            </Box>
            <Box mr={2} mb={1} display="inline-block">
              <PortFilter
                portType="pol"
                fieldName="polsWithNames"
                label="POL"
                values={asyncFilterOptions.polsWithNames}
                onChange={pickAsyncOption}
                selectedLabel="POL"
                selectedIds={filters.pol}
              />
            </Box>
            <Box mr={2} mb={1} display="inline-block">
              <PortFilter
                portType="pod"
                fieldName="podsWithNames"
                label="POD"
                values={asyncFilterOptions.podsWithNames}
                onChange={pickAsyncOption}
                selectedLabel="POD"
                selectedIds={filters.pod}
              />
            </Box>
            <Box mr={2} mb={1} display="inline-block">
              <OrganizationFilter
                fieldName="organizationsWithNames"
                label="Organization"
                values={asyncFilterOptions.organizationsWithNames}
                onChange={pickAsyncOption}
                selectedIds={filters.organization}
              />
            </Box>
            <Box mr={2} mb={1}>
              <Input.OptionsPicker
                options={[
                  ['Yes', 'true'],
                  ['No', 'false'],
                ]}
                title="Visibility only"
                selectedTitle="Visibility only"
                fieldName="visibility_only"
                onChange={pickFilterOption}
                onCancel={resetFiltersOfType}
                values={filters.visibility_only}
              />
            </Box>
            {scope === 'rollings' ? (
              <Box mr={2} mb={1} display="inline-block">
                <Input.OptionsPicker
                  options={filterOptions.rolling_statuses}
                  title="Rollings"
                  searchable={false}
                  selectedTitle="Rollings"
                  fieldName="rolling_status"
                  onChange={pickFilterOption}
                  onCancel={resetFiltersOfType}
                  values={filters.rolling_status}
                  size="short"
                  disabled={false}
                />
              </Box>
            ) : (
              ''
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" minWidth={290}>
          <Button
            variant="text"
            onClick={clearFilters}
            disabled={activeFilters.length === 0}
            startIcon={<CloseRoundedIcon />}
          >
            Clear filters{' '}
            {activeFilters.length > 0 ? `(${activeFilters.length})` : ''}{' '}
          </Button>
          <MoreFiltersButton
            variant="text"
            onClick={onToggleFilters}
            startIcon={<KeyboardArrowDownRoundedIcon />}
            open={showAllFilters}
          >
            {showAllFilters ? 'Less' : 'More'} filters
          </MoreFiltersButton>
        </Box>
      </Box>
    </Paper>
  )
}

export default ReportsDashboardFilters

import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/modalities'
import * as actionCreators from '../../actionCreators/modalities'
import { simplePutAndResolve } from '../factories'

const updateCurrentModality = simplePutAndResolve(
  (action) => `/api/v1/inland_transports/${action.id}`,
  actionCreators.updateCurrentModalitySuccess,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.UPDATE_CURRENT_MODALITY, updateCurrentModality)
}

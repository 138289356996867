import AddIcon from '@mui/icons-material/Add'
import { TFunction } from 'i18next'
import { IDemurrageAndDetentionCarrier } from 'src/@types/endpoints/demurrageAndDetention'
import DemurrageAndDetentionContentRates from './DemurrageAndDetentionContentRates'
import DemurrageAndDetentionContentAddAgreement from './DemurrageAndDetentionContentAddAgreement'
import DemurrageAndDetentionContentAddAgreementDownload from './DemurrageAndDetentionContentAddAgreementDownload'
import DemurrageAndDetentionContentAddAgreementCopyEmail from './DemurrageAndDetentionContentAddAgreementCopyEmail'
import DemurrageAndDetentionContentAddAgreementStepOne from './DemurrageAndDetentionContentAddAgreementStepOne'
import {
  DemurrageAndDetentionAddAgreementSectionProps,
  DemurrageAndDetentionContentProps,
} from './DemurrageAndDetentionContent.props'

export const getTabs = (
  carriers: IDemurrageAndDetentionCarrier[],
  props: DemurrageAndDetentionContentProps,
  t: TFunction<'translation', undefined>
) => [
  {
    key: 'default',
    label: t('demurrage_and_detention.tabs.company_name', 'Shypple'),
    component: <DemurrageAndDetentionContentRates {...props} />,
  },
  ...carriers.map((carrier) => ({
    key: carrier.code,
    label: carrier.name,
    component: <DemurrageAndDetentionContentRates {...props} />,
  })),
  {
    key: 'add_agreement',
    label: t('demurrage_and_detention.tabs.add_agreement', 'Add agreement'),
    icon: <AddIcon />,
    component: <DemurrageAndDetentionContentAddAgreement />,
  },
]

export const addAgreementEmail = 'demurragedetention@shypple.com'

export const getAddAgreementSections = (
  t: TFunction<'translation', undefined>
): DemurrageAndDetentionAddAgreementSectionProps[] => {
  return [
    {
      step: 1,
      title: t(
        'demurrage_and_detention.add_agreement.steps_one.title',
        'Download the template'
      ),
      description: <DemurrageAndDetentionContentAddAgreementStepOne />,
      renderFooter: () => <DemurrageAndDetentionContentAddAgreementDownload />,
    },
    {
      step: 2,
      title: t(
        'demurrage_and_detention.add_agreement.steps_two.title',
        'Email your agreement'
      ),
      description: t(
        'demurrage_and_detention.add_agreement.steps_two.description',
        'Once you have filled the required information in the template you can send it to the email address below.'
      ),
      renderFooter: () => <DemurrageAndDetentionContentAddAgreementCopyEmail />,
    },
    {
      step: 3,
      title: t(
        'demurrage_and_detention.add_agreement.steps_three.title',
        'Sit back and relax'
      ),
      description: t(
        'demurrage_and_detention.add_agreement.steps_three.description',
        'It may take a few days for us to process your agreement(s) and add it to the platform.\n\nWe’ll notify you when they are ready to view.'
      ),
    },
  ]
}

export const getClientSegmentation = (
  t: TFunction<'translation', undefined>
) => ({
  oneView: {
    description: t(
      'demurrage_and_detention.client_segment.one_view',
      'You can add agreements with other parties. Together with our dry, non-dangerous, ocean import rates you can view all your agreements in one place. The rates are per calendar day.'
    ),
  },
  threePl: {
    description: t(
      'demurrage_and_detention.client_segment.three_pl',
      'Here you’ll find the rates we currently have for dry, non-dangerous, ocean import shipments. These rates only apply to shipments you’ve booked with Shypple. The rates are per calendar day and Yard Occupancy Charges for demurrage are not included in the below overview.'
    ),
  },
})

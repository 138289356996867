import { Typography } from '@mui/material'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  show?: boolean
  className?: string
  text?: string
}

const SubjectToSpaceAndAvailability: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  return (
    <>
      {props.show && (
        <Typography className={`space-and-availability ${props.className}`}>
          <i className="icon attention" />{' '}
          {props.text
            ? t(props.text, 'Schedule is subject to space and availability')
            : ''}
        </Typography>
      )}
    </>
  )
}

SubjectToSpaceAndAvailability.defaultProps = {
  show: true,
  text: 'common.notifications.subject_to_space_and_availability',
  className: '',
}

export default SubjectToSpaceAndAvailability

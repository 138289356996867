import { takeEvery } from 'redux-saga/effects'
import * as actions from '../../actions/rates'
import { simpleGetAndResolve } from '../factories'

const getListShypplePublickDocument = simpleGetAndResolve(
  () => `/api/v1/public_documents/for_organization/1`,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.PUBLIC_DOCUMENTS_LIST, getListShypplePublickDocument)
}

export const SHIPMENT_GET_CHATS = 'SHIPMENT_GET_CHATS'
export const SHIPMENT_GET_CHATS_SUCCESS = 'SHIPMENT_GET_CHATS_SUCCESS'
export const CHAT_GET_INITIAL_DATA = 'CHAT_GET_INITIAL_DATA'
export const CHAT_GET_INITIAL_DATA_SUCCESS = 'CHAT_GET_INITIAL_DATA_SUCCESS'
export const CHAT_GET_DATA_SUCCESS = 'CHAT_GET_DATA_SUCCESS'
export const CHAT_GET_USERS = 'CHAT_GET_USERS'
export const CHAT_GET_USERS_SUCCESS = 'CHAT_GET_USERS_SUCCESS'
export const CHAT_ATTACH_FILE = 'CHAT_ATTACH_FILE'
export const CHAT_ATTACH_FILE_SUCCESS = 'CHAT_ATTACH_FILE_SUCCESS'
export const CHAT_PIN_COMMENT = 'CHAT_PIN_COMMENT'
export const CHAT_PIN_COMMENT_SUCCESS = 'CHAT_PIN_COMMENT_SUCCESS'
export const CHAT_SAVE_MESSAGE = 'CHAT_SAVE_MESSAGE'
export const CHAT_SAVE_MESSAGE_SUCCESS = 'CHAT_SAVE_MESSAGE_SUCCESS'
export const CHAT_SUBMIT_COMMENT = 'CHAT_SUBMIT_COMMENT'
export const CHAT_SUBMIT_COMMENT_SUCCESS = 'CHAT_SUBMIT_COMMENT_SUCCESS'
export const CHAT_TOGGLE_FOLLOW = 'CHAT_TOGGLE_FOLLOW'
export const CHAT_TOGGLE_FOLLOW_SUCCESS = 'CHAT_TOGGLE_FOLLOW_SUCCESS'
export const CHAT_ACCEPT_APPROVAL = 'CHAT_ACCEPT_APPROVAL'
export const CHAT_ACCEPT_APPROVAL_SUCCESS = 'CHAT_ACCEPT_APPROVAL_SUCCESS'
export const CHAT_REJECT_APPROVAL = 'CHAT_REJECT_APPROVAL'
export const CHAT_REJECT_APPROVAL_SUCCESS = 'CHAT_REJECT_APPROVAL_SUCCESS'
export const CLEAR_CHAT_STATE = 'CLEAR_CHAT_STATE'
export const CHATS_MARK_ALL_READ = 'CHATS_MARK_ALL_READ'
export const CHATS_MARK_ALL_READ_SUCCESS = 'CHATS_MARK_ALL_READ_SUCCESS'

import { FC, useEffect, useState, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'
import { History, LocationState } from 'history'
import { Box, Chip, Paper } from '@mui/material'
import Typography from '@mui/material/Typography'
import { promisifyAction } from 'src/utils'
import {
  shipmentOverviewGetData,
  carrierBookingCreateData,
  carrierBookingRequestData,
  bookingsCarrierBookingGetData,
  carrierBookingUpdateData,
  shipmentContainersGetData,
  getCarrierOrganizations,
  organizationUsersGetData,
} from 'src/stores/actionCreators'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { capitalize } from 'lodash'
import { makeStyles } from '@mui/styles'
import VerticalLinearStepper, { IStep } from 'src/stories/Stepper'
import { carrierBookingStatuses, ChipStatus, moveTypes } from './constants'
import CarrierDetails from './CarrierDetails'
import CarrierReview from './CarrierReview'

interface ICarrierBookingViewProps {
  match: IMatch
  history: History<LocationState>
}

const useStyles = makeStyles(() => ({
  conatiner: { display: 'flex' },
}))

const obligatoryRoles: string[] = ['shipper', 'forwarder']

const updateOrganizations = (
  initialOrganizations: ICarrierBookingOrganization[]
) => {
  return obligatoryRoles.reduce((res, role) => {
    const index = res.findIndex((org) => org.party_role === role)
    return index === -1
      ? [
          ...res,
          { carrier_booking_users: [], organization: null, party_role: role },
        ]
      : res
  }, initialOrganizations)
}

const renderDescription = (text: string): JSX.Element => {
  return (
    <Box mt={1} mb={2}>
      <Typography variant="body2" maxWidth={'65%'}>
        {text}
      </Typography>
    </Box>
  )
}

const steps: IStep[] = [
  {
    label: 'Review your shipment',
    component: (args) => <CarrierReview {...args} />,
  },
  {
    label: 'Provide carrier booking details',
    component: (args) => (
      <Box>
        {renderDescription(
          'Please provide and review your carrier booking details. Some of the information has automatically been filled in based on the shipment collaborators and their roles'
        )}
        <CarrierDetails {...args} />
      </Box>
    ),
  },
  {
    label: 'Place booking',
    component: () => (
      <Box>
        {renderDescription(
          'Your booking information is completed. Check information carefully before placing your booking. You will receive a confirmation and updates via email'
        )}
      </Box>
    ),
  },
]

const CarrierBooking: FC<ICarrierBookingViewProps> = (props) => {
  const dispatch: Dispatch<AnyAction> = useDispatch()
  const shipmentId: string = props.match.params.id
  const classes = useStyles()

  const {
    currentUser,
    shipmentOverview,
    carrierBookingData,
    containersDetailedData,
    carrierOrganizationsData,
    users,
  } = useSelector((store: IGlobalState) => ({
    currentUser: store.user,
    shipmentOverview: store.shipmentOverview,
    carrierBookingData: store.bookings.carrierBooking,
    containersDetailedData:
      store.shipmentPickupAndDelivery.shipmentContainersData,
    carrierOrganizationsData: store.carrierOrganizations.list,
    users: store.organizationSettings.users,
  }))

  const organizations = updateOrganizations(
    carrierBookingData ? carrierBookingData.carrier_booking_organizations : []
  )
  const [afterSaveGoods, setAferSaveGoods] = useState<boolean>(false)
  const [activeStep, setActiveStep] = useState(0)
  const [isSaving, setSaving] = useState(false)
  const [formState, setFormState] = useState<any>({})
  const [carrierOrganizations, setCarrierOrganizations] = useState<
    IOrganizationShort[]
  >([])

  const getOrganizations = promisifyAction(dispatch, getCarrierOrganizations)
  const getUsers = promisifyAction(dispatch, organizationUsersGetData)

  useEffect(() => {
    ;(async () => {
      await getUsers()
      await getOrganizations()
    })()
  }, [])
  useEffect(() => {
    ;(() => {
      setCarrierOrganizations(
        carrierOrganizationsData.map((org) => org.organization)
      )
    })()
  }, [carrierOrganizationsData])

  useEffect(() => {
    setFormState({
      bookerContact: carrierBookingData?.carrier_booking_organizations
        .find((org) => org.party_role === 'contract_party')
        ?.carrier_booking_users.map((user) => user.user)[0],
      references: {
        contract_party_reference_number:
          carrierBookingData?.carrier_booking_references.find(
            (ref) => ref.reference_type === 'contract_party_reference_number'
          )?.reference || '',
        contract_number:
          carrierBookingData?.carrier_booking_references.find(
            (ref) => ref.reference_type === 'contract_number'
          )?.reference || '',
      },
      moveType: moveTypes.find(
        (type) => type.id === carrierBookingData?.move_type
      ),
      roles: organizations.reduce(
        (res, org) => ({
          ...res,
          [org.party_role]: org.organization,
        }),
        {}
      ),
    })
  }, [carrierBookingData])

  const getCarrier = useCallback(() => {
    for (const org of carrierOrganizationsData) {
      if (org.carrier.name === shipmentOverview.carrier_name) {
        return org.organization
      }
    }
  }, [carrierOrganizationsData, shipmentOverview])

  const getDefaultMessage = useCallback(() => {
    const notifications =
      carrierBookingData?.action_items?.length &&
      carrierBookingData?.action_items.filter(
        (i) => i.model_name === 'Organization'
      )
    if (notifications && notifications.length > 0) {
      const notification = notifications.map((x) => x.description)
      return notification
    }
  }, [carrierBookingData])

  const isEmptyField = useMemo(() => {
    return ['references', 'roles'].some((f) => {
      if (f === 'references') {
        return (
          !formState.references?.contract_party_reference_number ||
          !formState.references?.contract_number
        )
      }

      if (f === 'roles') {
        return !!Object.keys(formState.roles).some(
          (role) => !!formState.roles[role] === false
        )
      } else {
        return !!formState[f] === false
      }
    })
  }, [formState])

  const handleNext = async () => {
    if (activeStep === 1) {
      setSaving(true)
      await onSave()
      setSaving(false)
      return
    }
  }

  const getShipmentOverview = promisifyAction(dispatch, shipmentOverviewGetData)
  const getCarrierBooking = promisifyAction(
    dispatch,
    bookingsCarrierBookingGetData
  )

  const updateBooking = promisifyAction(dispatch, carrierBookingUpdateData)

  const getContainersAsync = promisifyAction(
    dispatch,
    shipmentContainersGetData
  )

  const createCarrierBooking = promisifyAction(
    dispatch,
    carrierBookingCreateData
  )
  const requestCarrierBooking = promisifyAction(
    dispatch,
    carrierBookingRequestData
  )

  const handleRedirect = () =>
    props.history.push(`/shipments/${shipmentId}/bookings`)

  const handleFinish = async () => {
    setSaving(true)
    await requestCarrierBooking(shipmentId, carrierBookingData)
    setSaving(false)
    handleRedirect()
  }

  const getCarrierBookingData = async () => {
    await getCarrierBooking(shipmentId)
  }

  const stepProps = {
    shipment: shipmentOverview,
    users,
    organizations,
    formState,
    setFormState,
    carrierOrganizations,
    getCarrierBookingData,
    setAferSaveGoods,
    afterSaveGoods,
    containersDetailedData,
    carrier: getCarrier(),
    defaultAddressMessages: getDefaultMessage(),
    currentUser,
  }

  const getCarrierBookingOrganizations = useCallback(
    () =>
      Object.keys(formState.roles).map((party_role) => {
        const organization = carrierBookingData?.carrier_booking_organizations.find(
          (org) => org.party_role === party_role
        )
        return {
          id: organization?.id || null,
          organization_id:
            formState.roles[party_role]?.organization_id ||
            formState.roles[party_role]?.id,
          party_role,
          ...(party_role === 'contract_party' &&
            formState['bookerContact'] && {
              carrier_booking_users_attributes: [
                {
                  ...(organization && {
                    id: organization.carrier_booking_users.find(
                      (user) => user.contact_type === 'notification_contact'
                    )?.id,
                  }),
                  user_id: formState['bookerContact'].id,
                  contact_type: 'notification_contact',
                },
              ],
            }),
        }
      }),
    [formState.roles]
  )

  const getReferences = () =>
    Object.keys(formState.references).reduce((res: object[], referenceType) => {
      const reference = carrierBookingData?.carrier_booking_references.find(
        (ref) => ref.reference_type === referenceType
      )

      if (!formState.references[referenceType]) return res

      return [
        ...res,
        {
          ...(reference && { id: reference.id }),
          reference: formState.references[referenceType],
          reference_type: referenceType,
        },
      ]
    }, [])

  const updateBookingAsync = async () => {
    try {
      await updateBooking(shipmentOverview.id, carrierBookingData?.id, {
        sender_id: '808600',
        contact_user_id: currentUser.id,
        carrier_booking_organizations_attributes: getCarrierBookingOrganizations(),
        carrier_booking_references_attributes: getReferences(),
      })

      dispatch(
        showNotification({
          message: 'Carrier booking details updated successfully',
          severity: 'success',
        })
      )
    } finally {
      setSaving(false)
    }
  }

  const onSave = async () => {
    await updateBookingAsync()
    await getCarrierBookingData()
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const status: ChipStatus | null | undefined = useMemo(() => {
    return carrierBookingData?.booking_state
      ? carrierBookingStatuses.find(
          (s) => s.label === carrierBookingData?.booking_state
        )
      : null
  }, [carrierBookingData?.booking_state])

  const isDisabled: boolean = useMemo(() => {
    let result: boolean
    const value = !!carrierBookingData?.action_items.some(
      (item) => item.model_name === 'Shipment'
    )
    const notificationContact = !carrierBookingData?.action_items.some(
      (item) =>
        item.model_name === 'CarrierBookingUser' && item.required === false
    )

    switch (true) {
      case !carrierBookingData?.action_items.length:
        result = false
        break
      case activeStep === 0:
        result =
          !!carrierBookingData?.action_items.some(
            (item) => item.model_name === 'Container'
          ) || !!value
        break
      case activeStep === 2:
        result =
          carrierBookingData?.action_items.length === 1
            ? notificationContact
            : !!carrierBookingData?.action_items.length
        break
      default:
        result = false
    }
    return result
  }, [carrierBookingData?.action_items, activeStep])

  useEffect(() => {
    ;(async () => {
      getShipmentOverview(shipmentId)
      getContainersAsync(shipmentId)
      const currentData = await getCarrierBooking(shipmentId)
      if (!currentData) {
        await createCarrierBooking(shipmentId, {
          sender_id: '808600',
          contact_user_id: currentUser.id,
        })
      }
      if (afterSaveGoods) {
        setAferSaveGoods(false)
      }
    })()
  }, [afterSaveGoods])

  if (!carrierBookingData) return null
  return (
    <Paper sx={{ mt: 6, p: 2, width: '100%' }}>
      <Box mt={2} mb={3} className={classes.conatiner}>
        <Typography variant="h2">Carrier booking</Typography>
        {status && (
          <Box ml={2}>
            <Chip
              color={status?.color || 'default'}
              label={capitalize(status?.label) || ''}
              size="medium"
              variant="outlined"
            />
          </Box>
        )}
      </Box>

      <VerticalLinearStepper
        steps={steps}
        stepProps={stepProps}
        loading={isSaving}
        activeStep={activeStep}
        forwardBtnLabel={
          activeStep === steps.length ? 'Place my booking' : 'Next'
        }
        handleNext={(i) =>
          i === steps.length - 1 ? handleFinish() : handleNext()
        }
        endButtonText="Finish"
        handleBack={(i) => (i === 0 ? handleRedirect() : undefined)}
        isForwardBtnDisabled={activeStep === 1 ? isEmptyField : isDisabled}
        handleGetActiveStep={(step) => setActiveStep(step)}
      />
    </Paper>
  )
}

export default CarrierBooking

import { Box, Chip, ChipTypeMap, Typography } from '@mui/material'
import { convertDateToDateWithoutYear } from 'src/utils/helpers'

interface IFirstDayOfDemurrageProps {
  date: string
  color: ChipTypeMap['props']['color']
  chipLabel: string
}
export const FirstDayOfDemurrage: React.FC<IFirstDayOfDemurrageProps> = (
  props
) => {
  return (
    <Box display="flex" alignItems="center">
      <Typography color="gray">
        {convertDateToDateWithoutYear(props.date)}
      </Typography>
      <Chip
        className="ml-8"
        label={props.chipLabel}
        size="small"
        color={props.color}
      />
    </Box>
  )
}

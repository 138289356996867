import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import {
  userSignOut,
  userUnsubscribeToBrowserNotifications,
} from 'src/stores/actionCreators'
import { unsubscribeAction } from 'src/utils/helpers'
import { promisifyAction } from 'src/utils'
import LogoutIcon from '@mui/icons-material/Logout'
import { StyledMenuItem } from 'src/components/TopNavigation/TopNavigation.styles'
import TopNavigationMenuItemContent from '../TopNavigationMenuItem/TopNavigationMenuItemContent'

function TopNavigationUserMenuLogout() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const history = useHistory()

  const unsubscribeAsync = promisifyAction(
    dispatch,
    userUnsubscribeToBrowserNotifications
  )

  const logout = () => {
    dispatch(userSignOut())

    history.push({
      pathname: '/login',
    })
  }

  const unsubscribe = (subscription: any) => {
    unsubscribeAsync({ subscription })
  }

  const onLogoutClick = () => {
    unsubscribeAction(unsubscribe, logout)
  }

  return (
    <StyledMenuItem onClick={onLogoutClick}>
      <TopNavigationMenuItemContent
        icon={<LogoutIcon />}
        text={t('top_navigation.user_menu.logout_button', 'Logout')}
      />
    </StyledMenuItem>
  )
}

export default TopNavigationUserMenuLogout

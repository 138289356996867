import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import { calculateProgress, getShipmentProgressColor } from '../utils'
import { ShipmentLegsProps } from '../shipmentContentProps'
import { ShipmentPorts } from './ShipmentPorts'
import { ShipmentVessel } from './ShipmentVessel'
import { ShipmentProgress } from './ShipmentProgress'
import { ShipmentEmission } from './ShipmentEmission'

export const ShipmentLegs: React.FC<ShipmentLegsProps> = ({ align, legs }) => {
  return (
    <Stack spacing={2} divider={<Divider orientation="horizontal" flexItem />}>
      {legs.map((leg: IShipmentLeg) => {
        const {
          status,
          vessel,
          estimated_arrival: arrival,
          estimated_departure: departure,
          total_co2_emission_gram: totalEmissionGram,
        } = leg
        const showProgress: boolean =
          (status === 'current' || status === 'completed') &&
          !!arrival &&
          !!departure
        return (
          <Stack spacing={1} key={leg.id}>
            {/* Ports */}
            <ShipmentPorts
              align={align}
              loadingPort={leg.loading_port.name}
              dischargePort={leg.discharge_port.name}
            />
            {/* Progress */}
            <ShipmentProgress
              align={align}
              progressColor={getShipmentProgressColor(status)}
              progressPercentage={calculateProgress({
                status,
                departure,
                arrival,
              })}
              showProgress={showProgress}
              estimatedArrival={leg.estimated_arrival}
              estimatedDeparture={leg.estimated_departure}
            />
            {/* Vessel */}
            <ShipmentVessel align={align} vessel={vessel} />
            {/* Emission */}
            <ShipmentEmission
              totalEmissionGram={totalEmissionGram}
              align={align}
            />
          </Stack>
        )
      })}
    </Stack>
  )
}

import { FunctionComponent, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { compact } from 'lodash'
import Typography from '@mui/material/Typography'
import AuthorAvatar from 'src/components/AuthorAvatar'
import { Box } from '@mui/material'
import { convertDateToDateTime } from '../../../../utils/helpers'
import ContainerEventTemplate from './ContainerEventTemplate'

interface IProps {
  event: IContainerEvent
}

const ContainerEvent: FunctionComponent<IProps> = (props) => {
  const { currentUserId } = useSelector((state: IGlobalState) => ({
    currentUserId: state.user.id,
  }))

  const authorNameWithOrganization: React.ReactNode = useMemo(() => {
    const author: IAuthor = props.event.user
    let authorName: string = ''
    if (props.event.user.id === currentUserId) {
      authorName = 'You'
    } else {
      if (author.organization.id === 1) {
        authorName = compact([
          author.first_name,
          author.department,
          author.phone,
        ]).join(' | ')
      } else {
        authorName = author.full_name
      }
      authorName += ` | ${author.organization.name}`
    }

    return (
      <span className="booking-event__content-bottom--author">
        {authorName}
      </span>
    )
  }, [props.event])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        my: 2,
      }}
    >
      <AuthorAvatar author={props.event.user} size="medium" />
      <Box
        sx={{
          ml: 1,
        }}
      >
        <ContainerEventTemplate
          fallback={props.event.event_type_description}
          templateName={props.event.template_name}
          templateVariables={props.event.template_variables}
          exception={props.event.exception}
        />
        <div className="purchase-order-event__content-bottom">
          <Typography variant="body1">
            {convertDateToDateTime(props.event.created_at)} -{' '}
            {authorNameWithOrganization}
          </Typography>
        </div>
      </Box>
    </Box>
  )
}

export default ContainerEvent

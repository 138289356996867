import Box from '@mui/material/Box'
import AlertField from '../components/AlertField'
import IconField from '../components/IconField'
import ShipmentField from '../components/ShipmentField'
import PortField from '../components/PortField'
import StatusField from '../components/StatusField'
import GenericDateField from '../components/GenericDateField'
import CommentField from '../components/CommentField'

export const onHoldSchema = {
  icon: {
    field: (shipment: IDashboardOnHoldShipment) => (
      <IconField shipment={shipment} />
    ),
    name: '',
    sortable: false,
    sortValue: '',
    customClassName: 'short-50',
  },
  shipment: {
    field: (shipment: IDashboardOnHoldShipment) => (
      <ShipmentField shipment={shipment} />
    ),
    name: 'SHIPMENT',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  status: {
    field: (shipment: IDashboardOnHoldShipment) => (
      <StatusField shipment={shipment} />
    ),
    name: 'STATUS',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned x-medium',
  },
  followUp: {
    field: (shipment: IDashboardOnHoldShipment) => (
      <Box className="flex">
        <GenericDateField date={shipment.follow_up} />
        <AlertField shipment={shipment} type="followup" />
      </Box>
    ),
    name: 'FOLLOW UP',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned s-medium',
  },
  from: {
    field: (shipment: IDashboardOnHoldShipment) => (
      <PortField
        port={shipment.loading_port}
        date={shipment.estimated_departure}
      />
    ),
    name: 'POL',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  to: {
    field: (shipment: IDashboardOnHoldShipment) => (
      <PortField
        port={shipment.discharge_port}
        date={shipment.estimated_arrival}
      />
    ),
    name: 'POD',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  volume: {
    field: (shipment: IDashboardOnHoldShipment) => (
      <>
        <Box className="text-wrapper">{shipment.carrier?.name}</Box>
        <Box>{shipment.volume}</Box>
      </>
    ),
    name: 'VOLUME',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned s-medium',
  },
  comment: {
    field: (shipment: IDashboardOnHoldShipment) => (
      <CommentField comment={shipment.comment} />
    ),
    name: 'COMMENT',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  cargoReady: {
    field: (shipment: IDashboardOnHoldShipment) => (
      <GenericDateField date={shipment.cargo_ready_date} />
    ),
    name: 'CARGO READY',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned s-medium',
  },
  created: {
    field: (shipment: IDashboardOnHoldShipment) => (
      <GenericDateField date={shipment.created_at} />
    ),
    name: 'CREATED',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned s-medium',
  },
}

import { values, groupBy } from 'lodash'

import { ShipmentContainersProps } from '../shipmentContentProps'
import { ShipmentTwoLineInfo } from './ShipmentTwoLineInfo'

export const ShipmentContainers: React.FC<ShipmentContainersProps> = (
  props
) => {
  const groups = values(groupBy(props.containers)).map((d) => ({
    name: d[0],
    count: d.length,
  }))

  const content = groups
    .map((group) => `${group.count} x ${group.name}`)
    .join(', ')

  return <ShipmentTwoLineInfo content={content} title="Container #" />
}

// https://github.com/JedWatson/react-select/tree/v1.x#select-props

import { FunctionComponent } from 'react'
import Select from 'react-select'

import { Theme, IAsyncCallbackResponse } from '../interfaces'
import chevronArrow from '../ChevronArrow'
import clearRenderer from '../ClearRenderer'

import 'react-select/dist/react-select.css'

interface IValueObjectWithName {
  id: number
  name: string
}

interface IValueObjectWithDescription {
  id: number
  description: string
}

type ValueObject = IValueObjectWithName | IValueObjectWithDescription

type ValueType = ValueObject | string | number | null

interface ISingleAsyncProps {
  className?: string | null
  placeholder?: string | null
  value: ValueType
  onChange: (data: any, extendedParam: any) => void
  disabled?: boolean
  clearable?: boolean
  labelKey?: string
  optionComponent?: React.ReactNode
  valueRenderer?: React.ReactNode
  arrowRenderer?: React.ReactNode
  filterOptions?: any
  onInputChange?: (input: string) => string
  theme?: Theme
  error?: boolean
  extendedParam?: any
  loadOptions: (input: string, callback: any) => Promise<IAsyncCallbackResponse>
}

const SingleAsync: FunctionComponent<ISingleAsyncProps> = (props) => {
  const onSelect = <T extends {}>(data: T) => {
    props.onChange(data, props.extendedParam)
  }

  const arrowRenderer = props.arrowRenderer || chevronArrow

  return (
    <Select.Async
      className={`custom-select ${props.className} ${props.theme} ${
        props.error ? 'error' : ''
      }`}
      value={props.value}
      placeholder={props.placeholder}
      labelKey={props.labelKey || 'name'}
      onChange={onSelect}
      disabled={props.disabled}
      clearable={props.clearable}
      filterOptions={props.filterOptions}
      optionComponent={props.optionComponent}
      valueRenderer={props.valueRenderer}
      backspaceRemoves={!props.clearable}
      onInputChange={props.onInputChange}
      arrowRenderer={props.value ? null : arrowRenderer}
      clearRenderer={clearRenderer}
      loadOptions={props.loadOptions}
    />
  )
}

SingleAsync.defaultProps = {
  className: '',
  value: '',
  placeholder: '',
  disabled: false,
  theme: 'default',
  error: false,
  clearable: false,
  onInputChange: undefined,
  optionComponent: undefined,
  arrowRenderer: undefined,
  valueRenderer: undefined,
  loadOptions: undefined,
  filterOptions: undefined,
}

export default SingleAsync

import { AlertColor } from '@mui/material'
import { FunctionComponent, useEffect, useState } from 'react'
import { Alert } from 'src/stories/Alert'

interface IProps {
  color?: AlertColor | 'secondary'
  show?: boolean
  message?: string
  action?: (event: React.MouseEvent) => void
  actionName?: string
  index?: number
  onClose?: (index: number) => void
  showClose?: boolean
  icon?: React.ReactNode
  alertTitle?: string
}

const InlineNotification: FunctionComponent<IProps> = (props) => {
  const {
    color,
    message,
    action,
    actionName,
    index,
    onClose,
    showClose,
    icon,
    alertTitle,
  } = props
  const [show, setShow] = useState<boolean>(!!props.show)

  useEffect(() => {
    setShow(!!props.show)
  }, [props])

  const handleClose = () => {
    setShow(false)
    if (onClose) {
      onClose(index || 0)
    }
  }

  if (!show) {
    return <></>
  }

  return (
    <Alert
      color={color || 'warning'}
      actionHandler={action}
      actionText={actionName}
      message={message || ''}
      title={alertTitle || ''}
      handleClose={showClose ? handleClose : undefined}
      icon={icon}
    />
  )
}

export default InlineNotification

import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { formatDate } from 'src/utils/helpers/shipmentDate'
import { SABSearchItemDateWithTooltipProps } from './SABSearchItem.props'

const SABSearchItemDateWithTooltip: React.FC<SABSearchItemDateWithTooltipProps> = ({
  abbriviation,
  text,
  date,
  textAlign,
}) => {
  const renderDate = (date) => {
    return date === 'On request' ? 'On request' : formatDate(date)
  }
  return (
    <Tooltip title={text} placement="bottom">
      <Box
        sx={{
          cursor: 'pointer',
          textAlign,
        }}
      >
        <Typography
          pr={0.5}
          children={abbriviation}
          textAlign={textAlign}
          component="span"
        />
        <Typography
          variant="body1Strong"
          textAlign={textAlign}
          component="span"
          children={renderDate(date)}
        />
      </Box>
    </Tooltip>
  )
}

export default SABSearchItemDateWithTooltip

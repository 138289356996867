import Chip, { ChipProps } from '@mui/material/Chip'
import { getShipmentStatusChipColorBasedOnShipmentPhase } from '../../../constants'

interface ShipmentStatusChipProps extends ChipProps {
  phase: string
}

export const ShipmentStatusChip: React.FC<ShipmentStatusChipProps> = (
  props
) => {
  const color = getShipmentStatusChipColorBasedOnShipmentPhase(props.phase)
  return (
    <Chip
      size="small"
      color={color}
      label={props.label}
      sx={{ maxWidth: '100%' }}
    />
  )
}

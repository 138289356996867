import { useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { promisifyAction } from 'src/utils'
import { ShipmentQuotationsContainerProps } from 'src/pages/ShipmentLayout/ShipmentCosts/ShipmentCosts.props'
import {
  bookShipmentQuotation,
  getShipmentQuotations,
} from 'src/stores/actionCreators/shipmentQuotations'
import { shipmentOverviewReQuote } from 'src/stores/actionCreators/shipmentOverview'
import { ShipmentQuotationStatusEnum } from 'src/config/constants'
import { getDateIsExpired } from 'src/utils/helpers/shipmentDate'
import { sortBy, uniq } from 'lodash'
import { ShipmentCostsAirQuotationsTableRow } from './ShipmentCostsAirQuotationsTableRow'
import { ShipmentCostsLoadingButton } from './ShipmentCostsLoadingButton'
import ShipmentCostsAirQuotationsStatus from './ShipmentCostsAirQuotationsStatus'
import { ShipmentCostsParty } from './ShipmentCostsParty'
import { getExtremes, getCollaboratorWithLeadForwarderRole } from './utils'

export const ShipmentCostsAirQuotations: React.FC<ShipmentQuotationsContainerProps> = ({
  shipment,
  quotations,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const [reQuoting, setReQuoting] = useState<boolean>(false)
  const status = shipment.status.toLocaleLowerCase()
  const openQuotations = quotations.filter(
    (quotation) =>
      quotation.status === ShipmentQuotationStatusEnum.Open &&
      !getDateIsExpired(quotation.quote_expiration_at)
  )
  const extremes = getExtremes(openQuotations)
  const orderedQuotations = sortBy(quotations, (item) =>
    Object.values(ShipmentQuotationStatusEnum).indexOf(
      item.status as ShipmentQuotationStatusEnum
    )
  )
  const offeredBy = getCollaboratorWithLeadForwarderRole(shipment.collaborators)
  const acceptQuoteAsync = promisifyAction(dispatch, bookShipmentQuotation)
  const getShipmentQuotationsAsync = promisifyAction(
    dispatch,
    getShipmentQuotations
  )

  const requestShipmentReQuote = promisifyAction(
    dispatch,
    shipmentOverviewReQuote
  )

  const handleClickOnBook = (quotation: IShipmentQuotation) => {
    setLoading(true)
    acceptQuoteAsync({ quotation: { id: quotation.id } })
      .then(() => {
        getShipmentQuotationsAsync({
          shipment: { id: shipment.id },
        }).finally(() => {
          setLoading(false)
        })
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const handleClickOnReQuote = () => {
    setReQuoting(true)
    requestShipmentReQuote(shipment.id).finally(() => {
      setReQuoting(false)
    })
  }

  const isOnlyEur = useMemo(() => {
    const arr: string[] = []
    orderedQuotations.forEach((quotation) =>
      quotation.proposed_revenues.forEach((revenue) => {
        revenue.items.forEach((item) => {
          arr.push(item.currency)
        })
      })
    )

    const currencyArr = uniq(arr)
    return currencyArr.length === 1 && currencyArr[0] === 'EUR'
  }, [orderedQuotations])
  return (
    <>
      <Stack
        direction="row"
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Box>
          <ShipmentCostsAirQuotationsStatus
            status={status}
            shipmentID={shipment.id}
          />
          {offeredBy && (
            <ShipmentCostsParty
              marginTop={2}
              label={t('common.offered_by', 'Offered by')}
              name={offeredBy.name}
              className={offeredBy.organization_role_code}
            />
          )}
        </Box>
        {openQuotations.length === 0 && status === 'pquo' && (
          <ShipmentCostsLoadingButton
            size="large"
            text={t('common.buttons.requote', 'Requote')}
            variant="contained"
            sx={{ width: 106 }}
            loading={reQuoting}
            onClick={handleClickOnReQuote}
          />
        )}
      </Stack>
      <Divider sx={{ mt: 2 }} />
      <Table aria-label="collapsible table" className="mb-10">
        <TableBody>
          {orderedQuotations.map((row) => (
            <ShipmentCostsAirQuotationsTableRow
              row={row}
              key={row.id}
              loading={loading}
              size={openQuotations.length}
              onBookClicked={handleClickOnBook}
              fastest={row.id === extremes.fastest}
              longest={row.id === extremes.longest}
              cheapest={row.id === extremes.cheapest}
            />
          ))}
        </TableBody>
      </Table>
      {!isOnlyEur && (
        <div className="alert-container">
          <Alert variant="filled" severity="warning">
            These costs are subject to exchange rate fluctuations
          </Alert>
        </div>
      )}
    </>
  )
}

import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_RowData,
  MRT_TableOptions,
  useMaterialReactTable,
} from 'material-react-table'
import getMRTOptions from './utils'

interface ReactTableProps<TData extends MRT_RowData>
  extends MRT_TableOptions<TData> {
  columns: MRT_ColumnDef<TData>[]
}

const ReactTable = <TData extends MRT_RowData>({
  tableConfig,
}: {
  tableConfig: ReactTableProps<TData>
}) => {
  const table = useMaterialReactTable(getMRTOptions({ tableConfig }))

  return <MaterialReactTable table={table} />
}

export default ReactTable

import { Grid, InputLabel, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFormContext } from '../../FormContext'
import Skeleton from '../Skeleton'
import Port from '../Parts/Port'
import Incoterm from '../Parts/Incoterm'
import CifValue from '../Parts/CifValue'
import LoadType from '../Parts/LoadType'
import Modality from '../Parts/Modality'
import Address from '../Parts/Address'
import CargoReadyDate from '../Parts/CargoReadyDate'
import SizedBox from '../Parts/Box'
import ServicesContextWrapper from '../Parts/ServicesContextWrapper'
import { TemplateFormContextProps } from '../../Form.props'

const TemplateFormDetails = () => {
  const { t } = useTranslation()
  const {
    isLoading,
    mode,
    formState,
  } = useFormContext() as TemplateFormContextProps
  return (
    <>
      <Grid container spacing={2} mt={2}>
        <Skeleton isLoading={isLoading}>
          {mode !== 'rebook' && <Modality />}
          {mode !== 'rebook' && <LoadType columnSize={1.5} />}
          {mode === 'rebook' && (
            <CargoReadyDate
              columnSize={1.5}
              fieldPath="booking.cargoReadyDate"
              required={true}
              label={t(
                'templates.shipment_details.cargo_ready_date',
                'Cargo ready date'
              )}
            />
          )}

          <Port
            fieldPath="loadingPort"
            label={t(
              'templates.shipment_details.port_of_loading',
              'Port of loading'
            )}
            required={true}
          />
          <Port
            fieldPath="dischargePort"
            label={t(
              'templates.shipment_details.port_of_discharge',
              'Port of discharge'
            )}
            required={true}
          />
          <Incoterm
            fieldPath="incoterm"
            label={t('templates.shipment_details.incoterm', 'Incoterm')}
            columnSize={2.5}
          />
          <SizedBox columnSize={9}>
            <InputLabel sx={{ mt: 2 }}>
              {t('templates.shipment_details.shipment_services', 'Services')}
            </InputLabel>
            <Grid container spacing={2}>
              <Grid item xs>
                <ServicesContextWrapper
                  fieldPath="services"
                  modality={formState.modality}
                />
              </Grid>
              <Grid item>
                <CifValue
                  fieldPath="cifValue"
                  label={t('templates.shipment_details.cif_value', 'CIF value')}
                />
              </Grid>
            </Grid>
          </SizedBox>
          <SizedBox columnSize={6}>
            <Stack spacing={1}>
              <Address
                fieldPath="pickupAddress"
                label={t(
                  'templates.shipment_details.pick_up_address',
                  'Pick up address'
                )}
                service="pickup"
              />
              <Address
                fieldPath="deliveryAddress"
                label={t(
                  'templates.shipment_details.delivery_address',
                  'Delivery address'
                )}
                service="delivery"
              />
            </Stack>
          </SizedBox>
        </Skeleton>
      </Grid>
    </>
  )
}

export default TemplateFormDetails

import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/organizationSettings'
import * as actionCreators from '../../actionCreators/organizationSettings'
import {
  simplePostAndResolve,
  simpleGetAndResolve,
  simplePutAndResolve,
  simpleDeleteAndResolve,
} from '../factories'

const slaGetData = simpleGetAndResolve(
  () => 'api/v1/sla_checkpoints',
  actionCreators.slaGetDataSuccess,
  (response) => response.data
)

const updateAutomatedAlert = simplePutAndResolve(
  (action) =>
    `/api/v1/sla_checkpoints/${action.sla_id}/sla_checkpoint_addresses/${action.address_id}/toggle_automated_alert`,
  (response) => response.data
)

const slaCreate = simplePostAndResolve(
  () => 'api/v1/sla_checkpoints',
  actionCreators.createSLASuccess,
  (response) => response.data
)

const slaUpdate = simplePutAndResolve(
  (action) => `/api/v1/sla_checkpoints/${action.id}`,
  actionCreators.updateSLASuccess,
  (response) => response.data
)

const slaDelete = simpleDeleteAndResolve(
  (action) => `/api/v1/sla_checkpoints/${action.id}`,
  actionCreators.deleteSLASuccess,
  (response) => response
)

const organziationUsersGetData = simpleGetAndResolve(
  () => 'api/v1/organizations/users',
  actionCreators.organizationUsersGetDataSuccess,
  (response) => response.data
)

const getOrganizationApiToken = simpleGetAndResolve(
  () => 'api/v1/organizations/api_token',
  actionCreators.getOrganizationApiTokenSuccess,
  (response) => response.data
)
const postOrganizationApiToken = simplePostAndResolve(
  () => 'api/v1/organizations/api_token',
  actionCreators.getOrganizationApiTokenSuccess,
  (response) => response.data
)
const deleteOrganizationApiToken = simpleDeleteAndResolve(
  () => 'api/v1/organizations/api_token',
  actionCreators.deleteOrganizationApiTokenSuccess,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.SLA_GET_DATA, slaGetData)
  yield takeEvery(actions.ORGANIZATION_USERS_GET_DATA, organziationUsersGetData)
  yield takeEvery(actions.GET_ORGANIATION_API_TOKEN, getOrganizationApiToken)
  yield takeEvery(actions.POST_ORGANIATION_API_TOKEN, postOrganizationApiToken)
  yield takeEvery(
    actions.DELETE_ORGANIATION_API_TOKEN,
    deleteOrganizationApiToken
  )
  yield takeEvery(actions.UPDATE_SLA_ALERTS, updateAutomatedAlert)
  yield takeEvery(actions.CREATE_SLA, slaCreate)
  yield takeEvery(actions.UPDATE_SLA, slaUpdate)
  yield takeEvery(actions.DELETE_SLA, slaDelete)
}

import { FunctionComponent, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { promisifyAction } from 'src/utils'
import Avatar from '@mui/material/Avatar'
import { Box, Link, Typography } from '@mui/material'
import { routes } from 'src/pages/AddressBook'
import { BreadcrumbItem, Breadcrumbs } from 'src/stories/Breadcrumbs'
import Tabs from 'src/stories/Tabs'
import BlankState from 'src/components/Common/BlankState'
import {
  getConnection,
  addressesGetData,
  countriesGetCountries,
} from '../../stores/actionCreators'
import ConnectionUsers from './ConnectionUsers'
import OrganizationAddressModal from './OrganizationAddressModal'

import './styles.scss'

const blankStateInfo: React.ReactNode = (
  <>
    <div className="paragraph__medium black strong">
      It looks like you have no users in your organization
    </div>
    <div className="paragraph__small black">
      Please contact{' '}
      <Link
        variant="body1"
        component="button"
        onClick={() => {
          window.location.href = 'mailto:CS@shypple.com'
        }}
      >
        customer support
      </Link>{' '}
      to add your first User
    </div>
  </>
)

const ConnectionsView: FunctionComponent<any> = (props) => {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const connectionId = props.match.params.id

  const [tabValue, setTabValue] = useState<string>('users')
  const [addressModalOpen, setAddressModalOpen] = useState<boolean>(false)

  const getOneConnection = promisifyAction(dispatch, getConnection)
  const addressesGetDataAsync = promisifyAction(dispatch, addressesGetData)
  const countriesGetDataAsync = promisifyAction(dispatch, countriesGetCountries)

  const { connection } = useSelector((state: IGlobalState) => ({
    connection: state.connections.singleConnection,
  }))

  useEffect(() => {
    getOneConnection(parseInt(connectionId)).catch(() => {
      history.push(routes.connections)
    })
    addressesGetDataAsync()
    countriesGetDataAsync()
  }, [])

  const fetchData = (): void => {
    getOneConnection(parseInt(connectionId)).catch(() => {
      history.push(routes.connections)
    })
  }

  const addressBlock = useMemo(() => {
    const address: IAddress | null = connection.addresses.length
      ? connection.addresses[0]
      : null

    if (!address) {
      return (
        <div className="organization-block__default_address__blank">
          <i className="icon plus" />
          <div>{t('address_book.connections.link', 'Link address')}</div>
        </div>
      )
    } else {
      return (
        <div className="organization-form__default_address__address_details">
          <Typography variant="body1Strong">{address.name}</Typography>
          <Typography variant="body1">
            {address.postal_code} {address.address}
          </Typography>
          <Typography variant="body1">{address.country.name}</Typography>
          <Typography variant="body1">{address.vat_number}</Typography>
        </div>
      )
    }
  }, [connection])

  const links: BreadcrumbItem[] = [
    {
      href: '/contacts/connections',
      label: 'Address Book',
    },
    {
      href: '/contacts/connections',
      label: 'Connections',
    },
  ]

  return (
    <div className="connections-view">
      <Box mb={2}>
        <Breadcrumbs links={links} current={connection.name} />
      </Box>
      <div className="flex">
        <div className="organization-block">
          <div className="organization-block__name">
            {connection.name || ''}
          </div>
          <div className="organization-block__logo">
            <div className="organization-block__logo__image">
              <Avatar
                alt={connection.name || ''}
                src={connection.logo || 'no-logo'}
                className={`${connection.role_code} organization-logo`}
              />
            </div>
          </div>
          <div
            className="organization-block__default_address"
            onClick={() => {
              setAddressModalOpen(true)
            }}
          >
            {addressBlock}
          </div>
        </div>
        <div className="users-block">
          <Tabs
            value={tabValue}
            tabs={[
              {
                label: t('address_book.connections.users', 'Users'),
                value: 'users',
              },
            ]}
            onChange={(value: string) => setTabValue(value)}
          ></Tabs>
          {!!connection.users.length ? (
            <ConnectionUsers users={connection.users || []} />
          ) : (
            <BlankState.Info
              type="link"
              theme="connectionUsers"
              iconType="container"
              linkBlock={blankStateInfo}
            />
          )}
        </div>
      </div>
      <OrganizationAddressModal
        open={addressModalOpen}
        connectionId={connectionId}
        address={connection.addresses.length ? connection.addresses[0] : null}
        fetchData={fetchData}
        handleClose={() => {
          setAddressModalOpen(false)
        }}
      />
    </div>
  )
}

export default ConnectionsView

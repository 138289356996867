import { useState } from 'react'
import { orderBy } from 'lodash'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Add, ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import Skeleton from '@mui/material/Skeleton'
import { Button, Paper, Stack, Typography, Box } from '@mui/material'
import { useGetShipmentTemplates } from 'src/services/Api/shipmentTemplates'
import TemplatesListItem from './TemplatesListItem'
import TemplatesListSort from './TemplatesListSort'
import { TemplateListSortOptionProps } from './props'

interface ITemplatesListProps {
  limit?: number
  showTemplateNavigation?: boolean
}

const TemplatesList = (props: ITemplatesListProps) => {
  const { t } = useTranslation()

  const sortOptions: TemplateListSortOptionProps[] = [
    {
      label: t('common.filter.most_used', 'Most used'),
      iteratee: 'times_used',
      order: 'desc',
      value: 'times_used',
    },
    {
      label: t('common.filter.newest_first', 'Newest first'),
      iteratee: 'id',
      order: 'desc',
      value: 'newest',
    },
    {
      label: t('common.filter.oldest_first', 'Oldest first'),
      iteratee: 'id',
      order: 'asc',
      value: 'oldest',
    },
    {
      label: t('common.filter.alphabetical_asc', 'A-Z'),
      iteratee: 'name',
      order: 'asc',
      value: 'a-z',
      icon: <ArrowDropUp />,
    },
    {
      label: t('common.filter.alphabetical_desc', 'Z-A'),
      iteratee: 'name',
      order: 'desc',
      value: 'z-a',
      icon: <ArrowDropDown />,
    },
  ]
  const [sort, setSort] = useState(sortOptions[0])

  const { data: templatesList, isLoading } = useGetShipmentTemplates({
    limit: props.limit,
  })

  const sortedTemplatesList = orderBy(
    templatesList ?? [],
    sort.iteratee,
    sort.order
  )

  return (
    <Paper sx={{ p: 2, mt: 2 }}>
      <Stack
        mb={2}
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="h3">
            {t('templates.sub_heading', 'Rebook from templates')}{' '}
          </Typography>
          {!props.showTemplateNavigation && (
            <Typography variant="body1" mt={0.5}>
              {t(
                'templates.sub_heading_description',
                'Click on a template card to start the booking process.'
              )}
            </Typography>
          )}
        </Box>
        {!isLoading && props.showTemplateNavigation && (
          <Box>
            <Button
              component={Link}
              size="medium"
              to="/templates/"
              color="primary"
              sx={{ mr: 2 }}
            >
              {t(
                'search_and_book.book_from_template_alert.action_button',
                'Go to templates'
              )}
            </Button>
            <Button
              component={Link}
              size="medium"
              startIcon={<Add />}
              to="/templates/create"
              variant="outlined"
              color="primary"
            >
              {t('templates.button_new_template', 'New template')}
            </Button>
          </Box>
        )}

        {!isLoading &&
          !props.showTemplateNavigation &&
          sortedTemplatesList.length > 0 && (
            <TemplatesListSort
              onChange={setSort}
              selectedOption={sort}
              options={sortOptions}
            />
          )}
      </Stack>
      <Stack spacing={2} mt={2}>
        {isLoading && (
          <Skeleton
            variant="rectangular"
            data-testid="templates-list-skeleton"
            sx={{ height: 122, borderRadius: 1 }}
          />
        )}
        {!isLoading &&
          sortedTemplatesList.map((template) => (
            <TemplatesListItem key={template.id} template={template} />
          ))}
        {!isLoading && sortedTemplatesList.length === 0 && (
          <TemplatesListItem
            template={{
              name: t(
                'templates.first_template_heading',
                'Create your first template'
              ),
            }}
          />
        )}
      </Stack>
    </Paper>
  )
}

export default TemplatesList

import { AnyAction } from 'redux'
import * as actions from '../actions/countries'
import { ISelectOption } from '../reducers/selectOptions'

export const countriesGetCountries = (): AnyAction => ({
  type: actions.COUNTRIES_GET_COUNTRIES,
})

export const countriesGetCountriesSuccess = (
  payload: ISelectOption[]
): AnyAction => ({
  type: actions.COUNTRIES_GET_COUNTRIES_SUCCESS,
  payload,
})

export const countriesGetNotFormattedCountries = (): AnyAction => ({
  type: actions.COUNTRIES_GET_COUNTRIES_NOT_FORMATTED,
})

export const countriesGetNotFormattedCountriesSuccess = (
  payload: any
): AnyAction => ({
  type: actions.COUNTRIES_GET_COUNTRIES_NOT_FORMATTED_SUCCESS,
  payload,
})

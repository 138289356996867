import { FunctionComponent, useEffect, useState, useMemo, useRef } from 'react'
import { History, LocationState } from 'history'
import { useSelector, useDispatch } from 'react-redux'
import { find, toNumber, includes, each } from 'lodash'
import './styles.scss'

import { showNotification } from 'src/stores/actionCreators/notifications'
import ContentDropDown from 'src/components/Common/ContentDropDown'
import StatusLine from 'src/components/Common/StatusLine'
import LoadableContainer from 'src/components/LoadableContainer'
import ConfirmDialog from 'src/components/ConfirmDialog'
import DataLoad from 'src/components/Common/DataLoad'
import { useTranslation } from 'react-i18next'

import { promisifyAction, permissionTo } from '../../utils'

import ShipmentOverviewHeader from '../../components/PickupAndDelivery/ShipmentOverviewHeader'
import ContainerOverviewHeader from '../../components/PickupAndDelivery/ContainerOverviewHeader'
import ContainerOverviewHeaderCollapsed from '../../components/PickupAndDelivery/ContainerOverviewHeaderCollapsed'
import ContainerOverviewExpanded from '../../components/PickupAndDelivery/ContainerOverviewExpanded'
import AddressWindow from '../../components/PickupAndDelivery/AddressWindow'
import DateAndTimeWindow from '../../components/PickupAndDelivery/DateAndTimeWindow'
import ContainerEditWindow from '../../components/PickupAndDelivery/ContainerEditWindow'
import ContainerDuplicateWindow from '../../components/PickupAndDelivery/ContainerDuplicateWindow'

import { CargoWindow } from '../../components/PickupAndDelivery/CargoWindow'

import {
  shipmentContainersGetData,
  shipmentGetMainData,
  addressesGetData,
  countriesGetCountries,
  deleteShipmentContainer,
  clearPickupAndDeliveryState,
  containerTypesGetData,
  shipmentOverviewGetData,
  bookingsGetData,
  shipmentsGetParties,
  documentTypesGetData,
  createShipmentDocument,
} from '../../stores/actionCreators'

interface IProps {
  match: IMatch | null
  history: History<LocationState>
  location: Location
}

const ShipmentPickupAndDelivery: FunctionComponent<IProps> = (props) => {
  const showStatusLine: boolean = false
  const [dateTimeModal, setDateTimeModal] = useState<boolean>(false)
  const [openContainerWindow, setOpenContainerWindow] = useState<boolean>(false)
  const [
    openDuplicateContainerWindow,
    setOpenDuplicateContainerWindow,
  ] = useState<boolean>(false)
  const [isNewContainer, setIsNewContainer] = useState<boolean>(false)
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false)
  const [displayCargoModal, setDisplayCargoModal] = useState<boolean>(false)
  const [displayAddressModal, setDisplayAddressModal] = useState<boolean>(false)
  const [
    currentAddress,
    setCurrentAddress,
  ] = useState<IInlandTransportAddress | null>(null)
  const [serviceType, setServiceType] = useState<'pickup' | 'delivery'>(
    'pickup'
  )
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false)
  const [filesArr, setFilesArr] = useState<File[]>([])

  const [
    currentTransport,
    setCurrentTransport,
  ] = useState<IInlandTransport | null>(null)
  const [currentContainerId, setCurrentContainerId] = useState<number | null>(
    null
  )
  const match = props.match || { params: { id: 0 } }
  const dispatch = useDispatch()
  const uploadButtonRef = useRef<any>(null)

  const getContainersAsync = promisifyAction(
    dispatch,
    shipmentContainersGetData
  )

  const getShipmentDataAsync = promisifyAction(dispatch, shipmentGetMainData)
  const addressesGetDataAsync = promisifyAction(dispatch, addressesGetData)
  const countriesGetDataAsync = promisifyAction(dispatch, countriesGetCountries)
  const deleteContainerAsync = promisifyAction(
    dispatch,
    deleteShipmentContainer
  )
  const containerTypesGetDataAsync = promisifyAction(
    dispatch,
    containerTypesGetData
  )
  const getOverviewDataAsync = promisifyAction(
    dispatch,
    shipmentOverviewGetData
  )
  const getBookingsAsync = promisifyAction(dispatch, bookingsGetData)
  const shipmentPartiesGetDataAsync = promisifyAction(
    dispatch,
    shipmentsGetParties
  )
  const documentTypesGetDataAsync = promisifyAction(
    dispatch,
    documentTypesGetData
  )

  const createShipmentDocumentAsync = promisifyAction(
    dispatch,
    createShipmentDocument
  )

  const {
    shipmentModality,
    sharedReference,
    shipmentContainersData,
    shipmentMainData,
    pickupAndDeliveryDataReceived,
    statusGroups,
    freightServiceRequestedForShipment,
    shipmentTradeDirection,
    documentTypes,
    parties,
    bookingsArr,
    containersArr,
    organizationId,
  } = useSelector((state: IGlobalState) => ({
    shipmentContainersData:
      state.shipmentPickupAndDelivery.shipmentContainersData,
    shipmentMainData: state.shipmentPickupAndDelivery.shipmentMainData,
    statusGroups: state.shipmentOverview.status_groups,
    shipmentModality: state.shipmentOverview.modality,
    shipmentTradeDirection: state.shipmentOverview.trade_direction,
    sharedReference: state.shipmentOverview.shared_reference,
    pickupAndDeliveryDataReceived:
      state.shipmentPickupAndDelivery.pickupAndDeliveryDataReceived,
    freightServiceRequestedForShipment: state.shipmentOverview.services.freight,
    documentTypes: state.shipmentDocs.documentTypes,
    parties: state.shipmentOverview.collaborators,
    bookingsArr: state.bookings.bookings,
    containersArr: state.shipmentPickupAndDelivery.shipmentContainersData,
    organizationId: state.user.organizationId,
  }))

  const { t } = useTranslation()

  const containerIdFromURL: string = window.location.hash.replace('#', '')

  const leadForwarder = useMemo(() => {
    return parties.find((x) => x.roles.some((x) => x.id === 17))
  }, [parties])

  useEffect(() => {
    Promise.all([
      getContainersAsync(match.params.id),
      documentTypesGetDataAsync(),
      shipmentPartiesGetDataAsync(match.params.id),
      getShipmentDataAsync(match.params.id),
      countriesGetDataAsync(),
      containerTypesGetDataAsync(),
      addressesGetDataAsync(),
    ])
    const getBookings = async () => {
      await getBookingsAsync(match.params.id)
    }

    if (
      permissionTo('bookings.view') ||
      permissionTo('shipments.bookings.view')
    ) {
      getBookings()
    }

    if (containerIdFromURL) {
      setCurrentContainerId(toNumber(containerIdFromURL))
    }
    return () => {
      dispatch(clearPickupAndDeliveryState())
    }
  }, [match.params.id])

  const isLclOrAir: boolean =
    shipmentMainData.load_type === 'lcl' || shipmentMainData.type === 'air'
  const isOriginPhase: boolean = shipmentMainData.shipment_phase === 'origin'

  const isSingleContainer = useMemo(() => {
    return !!shipmentContainersData && shipmentContainersData.length === 1
  }, [shipmentContainersData])

  const openDateTimeModal = (
    transport: IInlandTransport,
    containerId: number | null
  ): void => {
    setCurrentTransport(transport)
    setDateTimeModal(true)
    if (containerId) {
      setCurrentContainerId(containerId)
    }
  }

  const openAddressModal = (
    address: IInlandTransportAddress | null,
    service: InlandTransportService,
    containerId: number | null
  ): void => {
    setServiceType(service)
    setCurrentContainerId(containerId)
    setCurrentAddress(address)
    setDisplayAddressModal(true)
  }

  const openCargoDetails = (id): void => {
    setCurrentContainerId(id)
    setDisplayCargoModal(true)
  }

  const onClose = (): void => {
    setDateTimeModal(false)
    setDisplayAddressModal(false)
    setCurrentAddress(null)
    setCurrentTransport(null)
  }

  const onCloseCargoWindow = (): void => {
    fetchContainersData()
    setDisplayCargoModal(false)
  }

  const onOpenContainerWindow = (id): void => {
    setCurrentContainerId(id)
    setOpenContainerWindow(true)
  }
  const onOpenDuplicateContainerWindow = (id): void => {
    setCurrentContainerId(id)
    setOpenDuplicateContainerWindow(true)
  }

  const onNewContainerClick = (): void => {
    setOpenContainerWindow(true)
    setIsNewContainer(true)
  }
  const confirmDeletion = (id): void => {
    setCurrentContainerId(id)
    setIsConfirmOpen(true)
  }
  const closeConfirmModal = (): void => {
    setIsConfirmOpen(false)
  }

  const onCloseContainerWindow = (status: boolean): void => {
    setOpenContainerWindow(false)
    setIsNewContainer(false)
    if (status) {
      setCurrentContainerId(null)
    }
  }

  const onCloseDuplicateContainerWindow = (status: boolean): void => {
    setOpenDuplicateContainerWindow(false)
  }

  const afterSave = (): void => {
    Promise.all([
      getContainersAsync(match.params.id),
      getShipmentDataAsync(match.params.id),
      addressesGetDataAsync(),
      getOverviewDataAsync(match.params.id),
    ])
    onClose()
  }

  const fetchContainersData = (): void => {
    getContainersAsync(match.params.id)
    getOverviewDataAsync(match.params.id)
  }

  const confirmDeleteDocument = async (): Promise<any> => {
    try {
      await deleteContainerAsync(getContainerData()[0].id)
      afterSave()
      closeConfirmModal()
    } catch (error) {
      dispatch(
        showNotification({
          message: t(
            'shipment_containers.notifications.containers_are_required',
            'Shipment must have at least one container.'
          ),
          severity: 'error',
        })
      )
    }
  }

  const setContainerId = (id: number | null): void => {
    setCurrentContainerId(id)
    props.history.push(
      id ? `#${id}` : `/shipments/${match.params.id}/containers`
    )
  }

  const getContainerData = (): IShipmentContainer[] => {
    if (currentContainerId) {
      const currentContainer: IShipmentContainer | {} =
        find(shipmentContainersData, { id: currentContainerId }) || {}
      return [currentContainer as IShipmentContainer]
    }
    return shipmentContainersData
  }

  const addNewDocument = () => {
    uploadButtonRef.current.click()
  }

  const onChangeUploadFile = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const inputFiles: FileList | null = event.target.files

    if (!inputFiles) {
      return
    }

    if (inputFiles != null && inputFiles.length > 0) {
      const files: File[] = [] || filesArr
      let i: number = 0
      while (i < inputFiles.length) {
        files.push(inputFiles[i])
        i++
      }
      setFilesArr(files)
      setOpenUploadModal(true)
    }

    uploadButtonRef.current.value = null
  }

  const uploadModalClose = () => {
    setFilesArr([])
    setOpenUploadModal(false)
  }

  const saveDocuments = async (
    documents: IFileData[],
    isEditDocument: boolean,
    openNew: boolean
  ): Promise<any> => {
    each(documents, (document: IFileData) => {
      if (!document.file) {
        return
      }

      const fileReader = new FileReader()
      fileReader.readAsDataURL(document.file)
      fileReader.onload = async () => {
        await createShipmentDocumentAsync(match.params.id, {
          organization_ids: document.viewable_by,
          booking_id: document.booking,
          file: document.file,
          container_ids: document.containers,
          document_type_ids: document.types,
        })

        uploadModalClose()
        dispatch(
          showNotification({
            severity: 'success',
            message: t(
              'shipment_containers.notifications.document_uploaded',
              'New document uploaded'
            ),
          })
        )
        if (openNew) {
          addNewDocument()
        }
      }
    })
  }

  const getFileData = (): IFileData[] => {
    const viewable_by = [`${organizationId}`]
    if (leadForwarder) {
      viewable_by.push(`${leadForwarder.organization_id}`)
    }
    return (filesArr || []).map((sub: File) => {
      return {
        containers: [],
        name: sub.name,
        types: [],
        booking: '',
        file: sub,
        viewable_by,
      }
    })
  }

  return (
    <div className="pickup-and-delivery">
      <style
        dangerouslySetInnerHTML={{
          __html: `.shipment-layout__content {
                      padding: 0px 16px 24px;
                     }`,
        }}
      />
      <LoadableContainer
        className="pickup-and-delivery__global-loader"
        loading={!pickupAndDeliveryDataReceived}
      >
        {showStatusLine && (
          <StatusLine
            successful={false}
            icon={false}
            text={
              isOriginPhase
                ? t(
                    'shipment_containers.confirm_pickup_time',
                    'Confirm the pickup time for each container'
                  )
                : t(
                    'shipment_containers.confirm_delivery_time',
                    'Confirm the delivery time for each container'
                  )
            }
          />
        )}
        {!isLclOrAir && (
          <ShipmentOverviewHeader
            shipmentData={shipmentMainData}
            isLclOrAir={isLclOrAir}
            collapsed={true}
            isSingleContainer={isSingleContainer}
            onNewContainerClick={onNewContainerClick}
          />
        )}
        {(shipmentContainersData || []).map((container: IShipmentContainer) => {
          return (
            <ContentDropDown
              key={container.id}
              className="container-overview-block"
              itemId={container.id}
              setItemIdSelected={setContainerId}
              header={
                !isLclOrAir ? (
                  <ContainerOverviewHeaderCollapsed
                    shipmentData={shipmentMainData}
                    containerData={container}
                    isLclOrAir={isLclOrAir}
                    collapsed={false}
                    openDateTimeModal={openDateTimeModal}
                    openContainerWindow={onOpenContainerWindow}
                    openDuplicateContainerWindow={
                      onOpenDuplicateContainerWindow
                    }
                    confirmDeletion={confirmDeletion}
                    openAddressModal={openAddressModal}
                    openCargoDetails={openCargoDetails}
                    fetchData={fetchContainersData}
                    isSingleContainer={isSingleContainer}
                    shipmentModality={shipmentModality}
                    isSailingTBA={includes(
                      statusGroups,
                      'sailing_to_be_announced'
                    )}
                  />
                ) : (
                  <ContainerOverviewHeader
                    shipmentData={shipmentMainData}
                    containerData={container}
                    isLclOrAir={isLclOrAir}
                  />
                )
              }
              headerCollapsed={
                <ContainerOverviewHeaderCollapsed
                  shipmentData={shipmentMainData}
                  containerData={container}
                  isLclOrAir={isLclOrAir}
                  collapsed={true}
                  openDateTimeModal={openDateTimeModal}
                  openContainerWindow={onOpenContainerWindow}
                  openDuplicateContainerWindow={onOpenDuplicateContainerWindow}
                  confirmDeletion={confirmDeletion}
                  openAddressModal={openAddressModal}
                  openCargoDetails={openCargoDetails}
                  fetchData={fetchContainersData}
                  isSingleContainer={isSingleContainer}
                  shipmentModality={shipmentModality}
                  isSailingTBA={includes(
                    statusGroups,
                    'sailing_to_be_announced'
                  )}
                />
              }
              body={
                <>
                  {dateTimeModal && (
                    <DateAndTimeWindow
                      open
                      transport={currentTransport}
                      containerId={currentContainerId}
                      shipmentData={shipmentMainData}
                      close={onClose}
                      fetchData={fetchContainersData}
                      isLclOrAir={isLclOrAir}
                      containerData={container}
                    />
                  )}
                  <ContainerOverviewExpanded
                    data={container}
                    destinationDemurrageStartingFrom={
                      shipmentMainData.destination_demurrage_starting_from
                    }
                    addNewDocument={addNewDocument}
                    shipmentModality={shipmentModality}
                    fetchData={fetchContainersData}
                    openDateTimeModal={openDateTimeModal}
                    isOriginPhase={isOriginPhase}
                    openAddressModal={openAddressModal}
                    isLclOrAir={isLclOrAir}
                    tradeDirection={shipmentTradeDirection}
                    freightServiceRequested={freightServiceRequestedForShipment}
                    isSailingTBA={includes(
                      statusGroups,
                      'sailing_to_be_announced'
                    )}
                  />
                </>
              }
              forcedOpen={
                isSingleContainer || container.id === currentContainerId
              }
              disableCollapse={isSingleContainer && isLclOrAir}
            />
          )
        })}

        <AddressWindow
          serviceType={serviceType}
          open={displayAddressModal}
          close={onClose}
          afterSave={afterSave}
          address={currentAddress}
          containerData={getContainerData()}
        />
        <CargoWindow
          open={displayCargoModal}
          close={onCloseCargoWindow}
          cargoItems={[]}
          containerId={currentContainerId}
          fetchContainerData={fetchContainersData}
          shipmentModality={shipmentModality}
        />
        {openContainerWindow && (
          <ContainerEditWindow
            isNewContainer={isNewContainer}
            shipmentId={match.params.id}
            container={isNewContainer ? null : getContainerData()[0]}
            open={openContainerWindow}
            close={onCloseContainerWindow}
            fetchData={fetchContainersData}
            isLclOrAir={isLclOrAir}
            sharedReference={sharedReference}
          />
        )}
        {openDuplicateContainerWindow && (
          <ContainerDuplicateWindow
            shipmentId={match.params.id}
            shipmentType="Sea"
            container={getContainerData()[0]}
            open={openDuplicateContainerWindow}
            close={onCloseDuplicateContainerWindow}
            fetchData={fetchContainersData}
            isLclOrAir={isLclOrAir}
          />
        )}
        <ConfirmDialog
          title={t(
            'shipment_containers.delete_container_confirmation.title',
            'Delete container'
          )}
          message={t(
            'shipment_containers.delete_container_confirmation.message',
            'Do you really want to delete this container?'
          )}
          isOpen={isConfirmOpen}
          confirm={confirmDeleteDocument}
          reject={closeConfirmModal}
          onClose={closeConfirmModal}
          confirmButtonText={t('common.buttons.yes', 'Yes')}
          cancelButtonText={t('common.buttons.no', 'No')}
        />
        <input
          ref={uploadButtonRef}
          type="file"
          id="selectedFile"
          style={{
            display: 'none',
          }}
          onChange={onChangeUploadFile}
          multiple={true}
          data-testid="add-shipment-documents-file-input"
        />
        <DataLoad.UploadFiles
          shipment={true}
          title="documents"
          isEditDocument={false}
          fileData={getFileData()}
          isOpen={openUploadModal}
          documentTypes={documentTypes}
          containers={containersArr || []}
          bookings={bookingsArr || []}
          onClose={uploadModalClose}
          submitUpload={saveDocuments}
          parties={parties}
        />
      </LoadableContainer>
    </div>
  )
}

export default ShipmentPickupAndDelivery

import { useEffect, useState, useCallback } from 'react'
import { AlertProps } from '@mui/material/Alert'
import { Stack } from '@mui/material'
import { Alert } from 'src/stories/Alert'
import Grow from '@mui/material/Grow'
import { removeNotification } from 'src/stores/actionCreators/notifications'
import { useDispatch, useSelector } from 'react-redux'

interface Props extends AlertProps {
  notification: INotification
}

const Snackbar = (props: Props) => {
  const dispatch = useDispatch()
  const [show, setShow] = useState(true)
  const { notification } = props
  const hideNotification = () => setShow(false)

  useEffect(() => {
    setTimeout(hideNotification, 10000)
  }, [])

  const onExit = useCallback(() => {
    dispatch(removeNotification(notification.id))
  }, [notification])

  return (
    <Grow in={show} onExit={onExit} timeout={300}>
      <Alert
        actionHandler={notification.actionHandler}
        actionText={notification.actionText}
        handleClose={hideNotification}
        showClose={notification.showClose}
        color={notification.severity}
        message={notification.message}
        {...props}
      />
    </Grow>
  )
}

const Notifications = () => {
  const { notifications } = useSelector((globalState: IGlobalState) => ({
    notifications: globalState.notifications.list,
  }))

  return (
    <Stack
      spacing={2}
      data-testid="global-snackbar-notification"
      sx={{ zIndex: 1301, position: 'fixed', bottom: 32, left: 32 }}
    >
      {notifications.slice(-5).map((notification) => (
        <Snackbar notification={notification} key={notification.id} />
      ))}
    </Stack>
  )
}

export default Notifications

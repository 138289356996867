import { useGetOnlyWatchedShipmentsTrack } from 'src/services/Api/maps'
import { FunctionComponent } from 'react'
import { Alert } from 'src/stories/Alert'
import Map from 'src/components/Map'
import './styles.scss'

const MapOverviews: FunctionComponent = () => {
  const { data } = useGetOnlyWatchedShipmentsTrack()
  const total = data?.data.total
  const items = data?.data.map_content || []

  return (
    <div className="map-overview">
      {total === 0 && (
        <Alert
          color="secondary"
          severity="info"
          variant="filled"
          sx={{ position: 'absolute', bottom: 109, left: 24, zIndex: 1 }}
          title="All your shipments in one view"
          message="You can add other shipments to our platform, even if we are not your forwarder."
          showClose={false}
        />
      )}
      <Map markers={items} />
    </div>
  )
}

export default MapOverviews

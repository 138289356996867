import { call, CallEffect } from 'redux-saga/effects'
import { LOCAL_STORAGE_CREDENTIAL_KEY } from '../../../config/constants'

function* storeCredentials(
  uid?: string,
  accessToken?: string,
  clientToken?: string
): Iterator<any> {
  if (uid && accessToken && clientToken) {
    const value: any = yield call([JSON, 'stringify'], {
      uid,
      accessToken,
      clientToken,
    })
    yield call([localStorage, 'setItem'], LOCAL_STORAGE_CREDENTIAL_KEY, value)
  }
}

export default (
  uid?: string,
  accessToken?: string,
  clientToken?: string
): CallEffect => call(storeCredentials, uid, accessToken, clientToken)

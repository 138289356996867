import { useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import { isQuotationsAvailable } from 'src/pages/ShipmentQuotations/helpers'
import { CircularProgress } from 'src/stories'
import { permissionTo } from '../../utils'
import ShipmentPrice from '../ShipmentPrice'
import ShipmentQuotation from './ShipmentQuotation'

interface ShipmentQuotationsContainerProps {
  shipment: IOverviewShipment
  quotations: IShipmentQuotation[]
  isFetching: boolean
  isBooking: boolean
}

const ShipmentQuotationsContainer = () => {
  const { shipment, quotations, isFetching, isBooking } = useSelector(
    (state: IGlobalState): ShipmentQuotationsContainerProps => ({
      quotations: state.shipmentQuotations.items,
      isFetching: state.shipmentQuotations.isFetching,
      isBooking: state.shipmentQuotations.isBooking,
      shipment: state.shipmentOverview,
    }),
    shallowEqual
  )

  const showQuotations = useMemo(
    () =>
      shipment.status_groups.includes('quotes_page_proposed_quotes') &&
      isQuotationsAvailable(quotations),
    [shipment.status_groups, quotations]
  )

  const isAcceptQuote: boolean = permissionTo('shipments.accept_quote.all')

  return (
    <>
      {isFetching && (
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          alignItems="center"
          height="100px"
        >
          <CircularProgress />
        </Box>
      )}
      {!isFetching && showQuotations ? (
        isAcceptQuote ? (
          <>
            <style
              dangerouslySetInnerHTML={{
                __html: `.shipment-layout__content {
                        padding: 0;
                        background-color: var(--grey-A100);
                        display: flex;
                        flex-direction: column;
                    };
                    `,
              }}
            />
            {quotations.map((quotation) => (
              <ShipmentQuotation
                key={quotation.id}
                quotation={quotation}
                isBooking={isBooking}
                modality={shipment.modality}
              />
            ))}
          </>
        ) : (
          <ShipmentPrice />
        )
      ) : (
        <ShipmentPrice />
      )}
    </>
  )
}

export default ShipmentQuotationsContainer

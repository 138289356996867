import Typography from '@mui/material/Typography'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import './styles.scss'

interface IPersonalDetailProps {
  personalDetail: IPersonalDetail
  toEditing: (id: number, type: string) => void
}

function PersonalDetail({ personalDetail, toEditing }: IPersonalDetailProps) {
  const PLACEHOLDER = '-'

  const handleClick = (event: React.FormEvent<HTMLElement>): void => {
    toEditing(personalDetail.id, 'contact')
  }

  return (
    <section
      className="personal__detail"
      onClick={handleClick}
      data-testid="personalDetail"
    >
      <AccountCircleIcon color="primary" />

      <div className="personal__detail__field personal__detail__field_name">
        <header className="contact__label"> Name </header>

        <Typography
          variant="body1"
          children={personalDetail.name || PLACEHOLDER}
        />
      </div>
      <div className="personal__detail__field personal__detail__field_address wide">
        <header className="contact__label">Company</header>

        <Typography
          variant="body1"
          children={personalDetail.company_name || 'Not linked'}
        />
      </div>
      <div className="personal__detail__field personal__detail__field_email wide">
        <header className="contact__label"> Email </header>
        <Typography
          variant="body1"
          children={personalDetail.email || PLACEHOLDER}
        />
      </div>
      <div className="personal__detail__field personal__detail__field_phone">
        <header className="contact__label"> Phone </header>
        <Typography
          variant="body1"
          children={personalDetail.phone || PLACEHOLDER}
        />
      </div>
    </section>
  )
}

export default PersonalDetail

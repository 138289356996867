import { FunctionComponent } from 'react'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import './styles.scss'

const lengthLimit: number = 23

const getTooltipTitle = (initializedBy: string) => {
  if (initializedBy.length > lengthLimit) {
    return initializedBy
  }
  return ''
}

const StatusField: FunctionComponent<{
  shipment: IReportsShipment
}> = ({ shipment }) => {
  const { status, initialized_by: initializedBy = '' } = shipment
  return (
    <Box>
      <Chip
        size="small"
        color="primary"
        label={status.length > lengthLimit ? shipment.status_code : status}
      />
      {initializedBy ? (
        <Box mt={0.25}>
          <Tooltip title={getTooltipTitle(initializedBy)}>
            <Typography variant="body2" children={initializedBy} />
          </Tooltip>
        </Box>
      ) : (
        ''
      )}
    </Box>
  )
}

export default StatusField

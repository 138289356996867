import { FunctionComponent, useState, useCallback } from 'react'
import { Dispatch } from 'redux'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from '@mui/material'
import CreateRoundedIcon from '@mui/icons-material/CreateRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import ConfirmDialog from 'src/components/ConfirmDialog'
import { promisifyAction } from '../../../utils/'
import { presentGoodsCode } from '../../../utils/helpers'
import {
  shipmentContainersGetData,
  deleteHsCode,
} from '../../../stores/actionCreators'
import EditHsCodeModal from '../EditHsCodeModal'

import './styles.scss'

interface IProps {
  hsCode: IContainerHsCode
  containerId: number
}

const HsCodeLine: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)

  const dispatch: Dispatch = useDispatch()
  const refreshContainerInformation = promisifyAction(
    dispatch,
    shipmentContainersGetData
  )
  const deleteHsCodeAsync = promisifyAction(dispatch, deleteHsCode)

  const { shipmentId } = useSelector((state: IGlobalState) => ({
    shipmentId: state.shipmentOverview.id,
  }))

  const closeEditHsCodeModal = useCallback(() => {
    refreshContainerInformation(shipmentId)
    setOpenEditModal(false)
  }, [shipmentId])

  const openEditHsCodeModal = useCallback(() => {
    setOpenEditModal(true)
  }, [])

  const closeDeleteHsCodeModal = useCallback(() => {
    setOpenDeleteModal(false)
  }, [])

  const openDeleteHsCodeModal = useCallback(() => {
    setOpenDeleteModal(true)
  }, [])

  const confirmDeleteHsCode = useCallback(async () => {
    await deleteHsCodeAsync(props.containerId, props.hsCode.id)
    refreshContainerInformation(shipmentId)
    setOpenDeleteModal(false)
  }, [])

  return (
    <div className="hs-code-line">
      <div className="hs-code-line__goods-code">
        <Typography
          variant="body1Strong"
          children={presentGoodsCode(props.hsCode.goods_code)}
        />
      </div>
      <div className="hs-code-line__description">
        <Typography variant="body1" children={props.hsCode.description} />
      </div>
      <div className="hs-code-line__notes">
        <Typography variant="body1" children={props.hsCode.notes} />
      </div>
      <div>
        <Link onClick={openEditHsCodeModal}>
          <CreateRoundedIcon />
          {t('common.buttons.edit_notes', 'Edit notes')}
        </Link>
        <br />
        <Link onClick={openDeleteHsCodeModal}>
          <DeleteRoundedIcon />
          {t('common.buttons.remove', 'Remove')}
        </Link>
      </div>
      <EditHsCodeModal
        hsCode={props.hsCode}
        open={openEditModal}
        containerId={props.containerId}
        onClose={closeEditHsCodeModal}
      />
      <ConfirmDialog
        title={t(
          'shipment_containers.hs_code_tabs.delete_hs_code.title',
          'Delete HS code'
        )}
        message={t(
          'shipment_containers.hs_code_tabs.delete_hs_code.description',
          'Do you want to remove this HS code from the container?'
        )}
        confirmButtonText={t('common.buttons.yes', 'Yes')}
        rejectButtonText={t('common.buttons.no', 'No')}
        isOpen={openDeleteModal}
        confirm={confirmDeleteHsCode}
        reject={closeDeleteHsCodeModal}
        onClose={closeDeleteHsCodeModal}
      />
    </div>
  )
}

export default HsCodeLine

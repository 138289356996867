import { createReducer, Reducer } from 'redux-create-reducer'
import { AnyAction } from 'redux'
import * as actions from 'src/stores/actions/navigationNotifications'

interface INavigationNotificationAuthor {
  full_name: string
  organization: {
    color: string
    name: string
  }
}

declare global {
  interface INavigationNotificationCommentAttachment {
    id: number
    original_filename: string
    file_url: string
  }
  interface INavigationNotification {
    author: INavigationNotificationAuthor
    comment_attachments: INavigationNotificationCommentAttachment[]
    content: string
    human_time: string
    shipment_link: string
    shipment_title: string
    id: number
    shipment_id: number
    is_system: boolean
    chat_type: string
    created_at: string
  }
  interface INavigationNotificationState {
    list: INavigationNotification[]
    count: number
  }
}

export const initialState: INavigationNotificationState = {
  list: [],
  count: 0,
}

const setNavigationNotificationCount: Reducer<
  INavigationNotificationState,
  AnyAction
> = (state, action) => ({
  ...state,
  count: action.payload.count,
})

const setNavigationNotificationList: Reducer<
  INavigationNotificationState,
  AnyAction
> = (state, action) => ({
  ...state,
  list: action.payload.list,
})

export default createReducer(initialState, {
  [actions.NAVIGATION_NOTIFICATIONS_SET_COUNT]: setNavigationNotificationCount,
  [actions.NAVIGATION_NOTIFICATIONS_SET_LIST]: setNavigationNotificationList,
})

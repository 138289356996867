import { RefObject, useRef, useState } from 'react'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { TooltipProps, TypographyProps } from '@mui/material'

const isCutOff = <T extends HTMLElement>(node: T | null) =>
  node ? node.offsetWidth < node.scrollWidth : false

export const useIsTextCutOff = <T extends HTMLElement>(
  ref: RefObject<T>
): [boolean, () => void] => {
  const [isTextCutOff, setIsTextCutOff] = useState(isCutOff(ref.current))

  const refresh = () => {
    setIsTextCutOff(isCutOff(ref.current))
  }

  return [isTextCutOff, refresh]
}

interface Props {
  text: string
  tooltipPlacement?: TooltipProps['placement']
  typographyVariant?: TypographyProps['variant']
  typographyColor?: TypographyProps['color']
}

export const TruncatedTextWithTooltip: React.FC<Props> = ({
  text,
  tooltipPlacement = 'right',
  typographyVariant = 'body1',
  typographyColor = 'primary.main',
}) => {
  const labelRef = useRef<HTMLDivElement>(null)
  const [isTextCutOff, refresh] = useIsTextCutOff(labelRef)

  return (
    <Tooltip
      onMouseEnter={refresh}
      placement={tooltipPlacement}
      title={isTextCutOff ? text : ''}
    >
      <Typography
        ref={labelRef}
        noWrap={true}
        children={text}
        component="div"
        color={typographyColor}
        variant={typographyVariant}
      />
    </Tooltip>
  )
}

import { FunctionComponent, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LinearProgress } from 'src/stories'
import LoadableContainer from 'src/components/LoadableContainer'
import MessageCenterSingleMessage from 'src/components/MessageCenter/MessageCenterSingleMessage'
import { useTranslation } from 'react-i18next'
import { promisifyAction } from '../../../utils'
import { getSavedMessages } from '../../../stores/actionCreators'

import './styles.scss'

const MessageCenterSavedMessages: FunctionComponent<{}> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [busy, setBusy] = useState<boolean>(true)

  const { comments } = useSelector((state: IGlobalState) => ({
    comments: state.messages.saved.comments || [],
  }))

  const getSavedMessagesAsync = promisifyAction(dispatch, getSavedMessages)

  useEffect(() => {
    const fetchDataAsync = async () => {
      await getSavedMessagesAsync()
      setBusy(false)
    }
    fetchDataAsync()
  }, [])

  return (
    <LoadableContainer loading={busy} placeholder={<LinearProgress />}>
      <div className="message-center-messages">
        {!!comments.length ? (
          comments.map((comment: IComment) => (
            <MessageCenterSingleMessage
              comment={comment}
              saved={true}
              key={comment.id}
            />
          ))
        ) : (
          <div className="message-center-empty saved-messages">
            <i className="iconbookmark" />
            <div className="description">
              {t('message_center.saved_messages.empty_placeholder')}
            </div>
          </div>
        )}
      </div>
    </LoadableContainer>
  )
}

export default MessageCenterSavedMessages

import { AnyAction } from 'redux'
import * as actions from '../actions/shipmentPickupAndDelivery'

export const clearPickupAndDeliveryState = (): AnyAction => ({
  type: actions.CLEAR_PICKUP_AND_DELIVERY_STATE,
})

export const shipmentContainersGetData = (id: string): AnyAction => ({
  type: actions.SHIPMENT_CONTAINERS_GET_DATA,
  id,
})

export const shipmentContainersGetDataSuccess = (payload: any): AnyAction => ({
  type: actions.SHIPMENT_CONTAINERS_GET_DATA_SUCCESS,
  payload,
})

export const shipmentGetMainData = (id: string): AnyAction => ({
  type: actions.SHIPMENT_GET_MAIN_DATA,
  id,
})

export const shipmentGetMainDataSuccess = (payload: any): AnyAction => ({
  type: actions.SHIPMENT_GET_MAIN_DATA_SUCCESS,
  payload,
})

export const showInlandTransportStatus = (id: string): AnyAction => ({
  type: actions.SHOW_INLAND_TRANSPORT_STATUS,
  id,
})

export const showInlandTransportStatusSuccess = (payload: any): AnyAction => ({
  type: actions.SHOW_INLAND_TRANSPORT_STATUS_SUCCESS,
  payload,
})

export const acceptInlandTransportStatus = (
  id: string,
  payload: any
): AnyAction => ({
  type: actions.ACCEPT_INLAND_TRANSPORT_STATUS,
  id,
  payload,
})

export const updateCargoLine = (id: string, payload: any): AnyAction => ({
  type: actions.UPDATE_CARGO_LINE,
  id,
  payload,
})

export const updateInlandTransportStatus = (
  id: string,
  payload: any
): AnyAction => ({
  type: actions.UPDATE_INLAND_TRANSPORT_STATUS,
  id,
  payload,
})

export const updateContainerInformation = (
  id: string,
  payload: any
): AnyAction => ({
  type: actions.UPDATE_CONTAINER_INFORMATION,
  id,
  payload,
})

export const updateInlandTransportStatusSuccess = (
  payload: any
): AnyAction => ({
  type: actions.UPDATE_INLAND_TRANSPORT_STATUS_SUCCESS,
  payload,
})

export const updateCargoLineSuccess = (payload: any): AnyAction => ({
  type: actions.UPDATE_CARGO_LINE_SUCCESS,
  payload,
})

export const createCargoLine = (payload: any): AnyAction => ({
  type: actions.CREATE_CARGO_LINE,
  payload,
})

export const createCargoLineSuccess = (payload: any): AnyAction => ({
  type: actions.CREATE_CARGO_LINE_SUCCESS,
  payload,
})

export const containerAssignDatetime = (
  id: string,
  payload: any
): AnyAction => ({
  type: actions.CONTAINER_ASSIGN_DATETIME,
  id,
  payload,
})

export const containerAssignDatetimeSuccess = (payload: any): AnyAction => ({
  type: actions.CONTAINER_ASSIGN_DATETIME_SUCCESS,
  payload,
})

export const assignAddressToContainer = (
  id: string,
  payload: any
): AnyAction => ({
  type: actions.ASSIGN_ADDRESS_TO_CONTAINER,
  id,
  payload,
})

export const assignAddressToContainerSuccess = (payload: any): AnyAction => ({
  type: actions.ASSIGN_ADDRESS_TO_CONTAINER_SUCCESS,
  payload,
})

export const assignReferenceToContainer = (
  id: string,
  payload: any
): AnyAction => ({
  type: actions.ASSIGN_REFERENCE_TO_CONTAINER,
  id,
  payload,
})

export const assignReferenceToContainerSuccess = (payload: any): AnyAction => ({
  type: actions.ASSIGN_REFERENCE_TO_CONTAINER_SUCCESS,
  payload,
})

export const deleteCargoLine = (id: string): AnyAction => ({
  type: actions.DELETE_CARGO_LINE,
  id,
})

export const createShipmentContainer = (payload: any): AnyAction => ({
  type: actions.CREATE_SHIPMENT_CONTAINER,
  payload,
})

export const updateShipmentContainer = (payload: any): AnyAction => ({
  type: actions.UPDATE_SHIPMENT_CONTAINER,
  payload,
})

export const deleteShipmentContainer = (id: number): AnyAction => ({
  type: actions.DELETE_SHIPMENT_CONTAINER,
  id,
})

export const duplicateShipmentContainer = ({
  shipment_id,
  container_id,
  payload,
}): AnyAction => ({
  type: actions.DUPLICATE_SHIPMENT_CONTAINER,
  shipment_id,
  container_id,
  payload,
})

export const copyContainerAttributes = ({
  shipment_id,
  container_id,
}): AnyAction => ({
  type: actions.COPY_CONTAINER_ATTRIBUTES,
  shipment_id,
  container_id,
})

export const copyContainerAttributesSuccess = (payload): AnyAction => ({
  type: actions.COPY_CONTAINER_ATTRIBUTES_SUCCESS,
  payload,
})

import { useState, useCallback } from 'react'
import { uniqBy, debounce } from 'lodash'
import { useFormContext } from 'react-hook-form'
import { useGetOrganizationsAsync } from 'src/services/Api/organizations'
import { FilterOptionValue } from '../types'
import AutocompleteBase from './AutocompleteBase'

const BookingPartiesAutocompleteAsync = ({ token, onDelete }) => {
  const {
    fetchAsync: getOrganizations,
    isFetched,
    isFetching,
  } = useGetOrganizationsAsync()
  const { getValues } = useFormContext()
  const defaultValue = getValues(token.value) ?? []

  const [options, setOptions] = useState<FilterOptionValue[]>(defaultValue)

  const [inputValue, setInputValue] = useState('')

  const handleDebounceFn = async (input: string) => {
    setInputValue(input)
    const hasOption = options.find((option) => option.label === input)
    if (input.length === 0 || !!hasOption) {
      return
    }
    const data = await getOrganizations({
      search: input,
    })
    const organizations: FilterOptionValue[] = (data ?? []).map((org) => ({
      label: org.name,
      value: `${org.id}`,
    }))

    setOptions(uniqBy([...defaultValue, ...organizations], 'value'))
  }

  const handleInputChangeWithDebounce = useCallback(
    debounce(handleDebounceFn, 800),
    [defaultValue]
  )
  const handleOnInputChange = (search: string) => {
    handleInputChangeWithDebounce(search)
  }

  const handleDelete = () => {
    onDelete(token.value)
  }

  const noOptionsText =
    isFetched && inputValue.length > 0 ? 'No options' : 'Start typing...'

  return (
    <AutocompleteBase
      options={options}
      noOptionsText={noOptionsText}
      token={token}
      asynchronous={true}
      defaultValue={defaultValue}
      loading={isFetching}
      onDelete={handleDelete}
      onInputChange={handleOnInputChange}
    />
  )
}

export default BookingPartiesAutocompleteAsync

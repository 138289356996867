import Typography from '@mui/material/Typography'
import { ShipmentVesselProps } from '../shipmentContentProps'
import { AlignedStack } from './ShipmentAlignedStack'

export const ShipmentVessel: React.FC<ShipmentVesselProps> = (props) => {
  const { align, vessel } = props
  if (!vessel) {
    return null
  }
  return (
    <AlignedStack justifyContent={align} direction="row" spacing={0}>
      <Typography
        noWrap={true}
        variant="body1"
        color="primary"
        children={vessel}
        component="div"
      />
    </AlignedStack>
  )
}

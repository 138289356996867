import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'

import { SABSearchItemActionsProps } from './SABSearchItem.props'

const SABSearchItemActions: React.FC<SABSearchItemActionsProps> = ({
  isBooking,
  queryString,
}) => {
  const { t } = useTranslation()
  return (
    <Button
      size="large"
      component={Link}
      variant="outlined"
      children={
        isBooking
          ? t('search_and_book.request_booking', 'Request booking')
          : t('search_and_book.get_quote', 'Request quote')
      }
      to={`/search/quote?${queryString}`}
    />
  )
}

export default SABSearchItemActions

import { useState, useCallback } from 'react'
import { uniqBy, debounce } from 'lodash'
import { useFormContext } from 'react-hook-form'
import { useGetAutocompleteAsync } from 'src/services/Api/common'
import { FilterOptionValue } from '../types'
import AutocompleteBase from './AutocompleteBase'

const AutocompleteAsync = ({ token, onDelete }) => {
  const {
    fetchAsync: getPorts,
    isFetched,
    isFetching,
  } = useGetAutocompleteAsync()
  const { getValues } = useFormContext()
  const defaultValue = getValues(token.value) ?? []

  const [defaultOptions, setDefaultOptions] = useState<FilterOptionValue[]>(
    defaultValue
  )

  const [inputValue, setInputValue] = useState('')

  const sortedOptions = [...defaultOptions].sort((a, b) => {
    // Display the selected options first.
    let ai = defaultValue.indexOf(a)
    ai = ai === -1 ? defaultValue.length + defaultOptions.indexOf(a) : ai
    let bi = defaultValue.indexOf(b)
    bi = bi === -1 ? defaultValue.length + defaultOptions.indexOf(b) : bi
    return ai - bi
  })

  const handleDebounceFn = async (input: string) => {
    setInputValue(input)
    const hasOption = defaultOptions.find((option) => option.label === input)
    if (input.length === 0 || !!hasOption) {
      return
    }
    const data = await getPorts({
      input,
      only_ports: true,
      with_ports: true,
    })
    const ports: FilterOptionValue[] = (data?.data?.places ?? []).map(
      (port) => ({
        value: `${port.port_id}`,
        label: port.main_text,
      })
    )
    setDefaultOptions(uniqBy([...defaultValue, ...ports], 'value'))
  }

  const handleInputChangeWithDebounce = useCallback(
    debounce(handleDebounceFn, 800),
    [defaultValue]
  )
  const handleOnInputChange = (search: string) => {
    handleInputChangeWithDebounce(search)
  }

  const handleDelete = () => {
    onDelete(token.value)
  }

  const noOptionsText =
    isFetched && inputValue.length > 0 ? 'No options' : 'Start typing...'

  return (
    <AutocompleteBase
      options={sortedOptions}
      noOptionsText={noOptionsText}
      token={token}
      asynchronous={true}
      defaultValue={defaultValue}
      loading={isFetching}
      onDelete={handleDelete}
      onInputChange={handleOnInputChange}
    />
  )
}

export default AutocompleteAsync

import { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import { ShipmentStatus } from 'src/stories'
import SailingInformation from 'src/components/SailingInformation'
import { ShipmentTitle } from 'src/stories/MUI/ShipmentContent/components/ShipmentTitle'
import DirectionsBoatRoundedIcon from '@mui/icons-material/DirectionsBoatRounded'

interface IProps {
  type: string
  percentage: number
  totalPhaseCount: number
  threshold: number
  status: string
  vessel: string | null
  isPublicShipment: boolean
}

const iconClass = {
  sea: 'sea',
  fcl: 'sea',
  lcl: 'sea',
  rail: 'train',
  air: 'airport',
}

const TimelineActivePoint: FunctionComponent<IProps> = ({
  type,
  status,
  threshold,
  percentage,
  totalPhaseCount,
  isPublicShipment,
}) => {
  const percent = Math.ceil((percentage - threshold) * totalPhaseCount)
  const { shipment, shipment_legs } = useSelector((state: IGlobalState) => ({
    shipment: isPublicShipment
      ? state.shipmentLink.public_shipment
      : state.shipmentOverview,
    shipment_legs: isPublicShipment
      ? state.shipmentLink.legs
      : state.shipmentOverview.shipment_legs,
  }))

  if (percentage < threshold || percent > 100) {
    return null
  }

  const TooltipTitle = () => {
    return (
      <>
        <Box mb={2}>
          <ShipmentTitle
            id={`${shipment.id}`}
            align="start"
            title={shipment.title}
            modality={shipment.modality}
          />
        </Box>
        <ShipmentStatus
          align="center"
          status={{
            name: status,
            phase: shipment?.shipment_phase ?? 'unknown',
          }}
        />
        {shipment_legs.length && (
          <>
            <Divider sx={{ my: 1.5 }} />
            <SailingInformation shipmentLegs={shipment_legs} />
          </>
        )}
      </>
    )
  }

  return (
    <Tooltip placement="left" title={<TooltipTitle />}>
      <div
        className="shipment-timeline--phases-item--point active"
        style={{ top: `${percent}%` }}
      >
        {type === 'fcl' ? (
          <DirectionsBoatRoundedIcon
            color="primary"
            sx={{ fontSize: 20, marginTop: '3px' }}
          />
        ) : (
          <i className={`icon ${iconClass[type]}`} />
        )}
      </div>
    </Tooltip>
  )
}

export default TimelineActivePoint

import { Fragment } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useTranslation } from 'react-i18next'

interface InnerRows {
  name: string
  currency: string
  id: number | string
  quantity: number | string
  totalUsd: number | string
  totalEur: number | string
  unitPrice: number | string
}

interface Rows {
  group: string
  innerRows: InnerRows[]
}

interface ShipmentCostsItemsTableProps {
  loading?: boolean
  rows: Rows[]
  totals: {
    usd: number | string
    eur: number | string
  }
}

export const ShipmentCostsItemsTable: React.FC<ShipmentCostsItemsTableProps> = ({
  rows,
  totals,
}) => {
  const { t } = useTranslation()
  const headers = [
    {
      width: 'auto',
      text: t('shipment_costs.table_header.service_item', 'Service Item'),
      key: 'name',
    },
    {
      width: '120px',
      text: t('shipment_costs.table_header.quantity', 'Quantity'),
      key: 'quantity',
    },
    {
      width: '120px',
      text: t('shipment_costs.table_header.unit_price', 'Unit Price'),
      key: 'unitPrice',
    },
    {
      width: '120px',
      text: t('shipment_costs.table_header.total_usd', 'Total USD'),
      key: 'totalUsd',
    },
    {
      width: '120px',
      text: t('shipment_costs.table_header.total_eur', 'Total EUR'),
      key: 'totalEur',
    },
  ]
  return (
    <Table size="small" aria-label="Service items">
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableCell key={header.key} sx={{ width: header.width }}>
              {header.text}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody className="shipment-costs-item-inner-table-body">
        {rows.map((row, index) => (
          <Fragment key={`row_group_${index}`}>
            <TableRow>
              <TableCell colSpan={5}>
                <Typography variant="body1Strong" children={row.group} />
              </TableCell>
            </TableRow>
            {row.innerRows.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  <Box ml={2}>{item.name}</Box>
                </TableCell>
                <TableCell>
                  <Box display="flex" sx={{ justifyContent: 'space-between' }}>
                    <Box>{item.quantity}</Box>
                    <Box>x</Box>
                  </Box>
                </TableCell>
                <TableCell>
                  {item.unitPrice} {item.currency}
                </TableCell>
                <TableCell>
                  {item.totalUsd} {t('common.currencies.usd', 'USD')}
                </TableCell>
                <TableCell>
                  {item.totalEur} {t('common.currencies.eur', 'EUR')}
                </TableCell>
              </TableRow>
            ))}
          </Fragment>
        ))}
        <TableRow>
          <TableCell align="right" colSpan={3}>
            <Typography
              variant="body1Strong"
              children={t('shipment_costs.table.total', 'Total')}
              component="span"
            />{' '}
            {t('shipment_costs.table.excl_vat', '(excl. VAT)')}
          </TableCell>
          <TableCell>
            <Typography
              variant="body1Strong"
              children={`${totals.usd} ${t('common.currencies.usd', 'USD')}`}
            />
          </TableCell>
          <TableCell>
            <Typography
              variant="body1Strong"
              children={`${totals.eur} ${t('common.currencies.eur', 'EUR')}`}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default ShipmentCostsItemsTable

import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useTimer } from 'react-timer-hook'

const ResetPasswordRequestCounter = ({ retryAfter }) => {
  const { t } = useTranslation()
  const time = new Date()
  time.setSeconds(time.getSeconds() + retryAfter)
  const { seconds, minutes } = useTimer({ expiryTimestamp: time })
  const secondsText = `${seconds}`.padStart(2, '0')
  const minutesText = `${minutes}`.padStart(2, '0')
  const timeLeft = `${minutesText}:${secondsText}`

  return (
    <Typography variant="body1" mb={2} data-testid="retry-counter">
      {t('reset_password_link_request_retry_after', { timeLeft })}
    </Typography>
  )
}

export default ResetPasswordRequestCounter

import IconField from '../components/IconField'
import ShipmentField from '../components/ShipmentField'
import ColoredFieldWithMappings from '../components/ColoredFieldWithMappings'
import ColoredField from '../components/ColoredField'
import StatusField from '../components/StatusField'
import PortField from '../components/PortField'
import AlertField from '../components/AlertField'

export const recentDeliveriesSchema = {
  icon: {
    field: (shipment: IDashboardRecentDeliveriesShipment) => (
      <IconField shipment={shipment} />
    ),
    name: '',
    sortable: false,
    sortValue: '',
    customClassName: 'short-50',
  },
  shipment: {
    field: (shipment: IDashboardRecentDeliveriesShipment) => (
      <ShipmentField
        showSpot={false}
        showNonStackable={false}
        shipment={shipment}
      />
    ),
    name: 'SHIPMENT',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  status: {
    field: (shipment: IDashboardRecentDeliveriesShipment) => (
      <StatusField shipment={shipment} />
    ),
    name: 'STATUS',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned x-medium',
  },

  from: {
    field: (shipment: IDashboardRecentDeliveriesShipment) => (
      <PortField
        port={shipment.loading_port}
        date={shipment.estimated_departure}
      >
        <AlertField shipment={shipment} type="etd" />
      </PortField>
    ),
    name: 'POL',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned two-lines',
  },
  to: {
    field: (shipment: IDashboardRecentDeliveriesShipment) => (
      <PortField
        port={shipment.discharge_port}
        date={shipment.estimated_arrival}
      ></PortField>
    ),
    name: 'POD',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned two-lines',
  },
  finance: {
    field: (shipment: IDashboardRecentDeliveriesShipment) =>
      shipment.finance_delivery_task ? (
        <ColoredFieldWithMappings
          type="task"
          mapping="finance_delivery_check"
          value={shipment.finance_delivery_task.task_status_id}
        />
      ) : (
        <ColoredField value="" icon="remove" color="white" />
      ),
    name: 'FINANCE DELIVERY',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
}

import { FunctionComponent, useRef, useCallback, useEffect } from 'react'
import { ActionCableConsumer } from 'react-actioncable-provider'
import { useSelector } from 'react-redux'
import { last } from 'lodash'

import ChatChannelConsumerWrapper from './ChatChannelConsumerWrapper'

interface IProps {
  chatId?: number
  onReceived: (message: ISocketMessage) => void
}

const ChatChannelHandler: FunctionComponent<IProps> = (props) => {
  const actionCableRef = useRef<any>(null)

  const getActionCableRef = useCallback((elem: ActionCableConsumer) => {
    actionCableRef.current = elem
  }, [])

  const { chatChannelCommands } = useSelector((state: IGlobalState) => ({
    chatChannelCommands: state.socketMessages.chatChannelCommands,
  }))

  useEffect(() => {
    if (!actionCableRef.current || !chatChannelCommands.length) {
      return
    }

    const lastCommand: ISocketCommand = last(
      chatChannelCommands
    ) as ISocketCommand
    actionCableRef.current.perform(lastCommand.name, lastCommand.params)
  }, [chatChannelCommands])

  return (
    <>
      <ChatChannelConsumerWrapper
        forwardRef={getActionCableRef}
        onReceived={props.onReceived}
        channel={{ channel: 'ChatChannel', chat_id: props.chatId }}
      />
    </>
  )
}

export default ChatChannelHandler

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { routes } from 'src/pages/AddressBook'
import { history } from 'src/shyppleStore'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { LoadingButton } from '@mui/lab'
import {
  addressesPutAddress,
  setDefaultAddress,
} from '../../../stores/actionCreators'
import { promisifyAction } from '../../../utils'

interface IAddressExtended extends IAddress {
  shouldBeDefault?: boolean
  port_ids?: string[][]
}

const UpdateAddressButton = ({
  addressId,
  address,
  disabled,
}: {
  addressId: number
  address: IAddressExtended
  disabled: boolean
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const updateAddress = promisifyAction(dispatch, addressesPutAddress)
  const update = async () => {
    setLoading(true)
    await updateAddress(addressId, {
      ...address,
      port_ids: (address.port_ids || []).map(([name, id]) => id),
    })
    dispatch(
      showNotification({
        message: `Address "${address.name}" updated.`,
        severity: 'success',
      })
    )
    if (address && address.shouldBeDefault) {
      dispatch(setDefaultAddress(address))
    }
    history.push(routes.companies)
  }
  return (
    <LoadingButton
      disabled={disabled}
      variant="contained"
      color="primary"
      disableElevation
      onClick={update}
      loading={loading}
    >
      {t('address_book.connections.update_address', 'Update address')}
    </LoadingButton>
  )
}

export default UpdateAddressButton

export const FORBIDDEN_FORMATS: any = /\.(app|bat|action|bin|csh|exe|ksh|sh|out|run|pyc|pyo|rb)$/i
export const SERVICES_TO_REQUEST = {
  '1': 'destination port handling',
  '2': 'import customs',
  '3': 'delivery',
}
export const ICONS = {
  pinIcon: 'pin',
  pinOutfilledIcon: 'pin_outline',
  bookmarkIcon: 'bookmark',
  bookmarkOutfilledIcon: 'bookmark_outline',
  replyIcon: 'backarrow',
}

import { useHistory } from 'react-router-dom'
import { Alert } from 'src/stories/Alert'
import { useTranslation } from 'react-i18next'
import { getIsFreightPresent, getActionButtonText } from '../helpers'

const RatesLineDetailsNotification: React.FC<{
  bookUrl: string
  loadingPort: string
  dischargePort: string
  prices: IRatesPriceItem[]
}> = ({ prices, loadingPort, dischargePort, bookUrl }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const isFreightPresent: boolean = getIsFreightPresent(prices)

  const onNotificationClick = () => {
    history.push(bookUrl)
  }

  return (
    <Alert
      message={t('rates.notifications.freight_prices_unavailable', {
        loadingPort,
        dischargePort,
        defaultValue:
          'Unfortunately no freight prices are available from {{loadingPort}} to {{dischargePort}}.',
      })}
      actionHandler={onNotificationClick}
      color="info"
      actionText={getActionButtonText(isFreightPresent, t)}
    />
  )
}

export default RatesLineDetailsNotification

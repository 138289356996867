import { createReducer, Reducer } from 'redux-create-reducer'
import { findIndex } from 'lodash'

import { AnyAction } from 'redux'
import { ModalityEnum } from 'src/config/constants'
import * as actions from '../actions/addresses'
import * as bankDetailsActions from '../actions/addressBankDetails'

declare global {
  interface IAddressesState {
    list: IAddress[]
    page: number
    page_size: number
    total_pages: number
    singleAddress?: IAddress
    singleBankDetail?: IBankDetails
    isFetching: boolean
  }

  interface IAddressDetails {
    value: string
    lat: number
    lng: number
    country: string
    zip: string
  }

  interface ITradeFinanceUser {
    id: number
    full_name: string
  }

  interface IAddressOpeningHours {
    id: number | null
    weekday: string | null
    weekday_number: number | null
    open_at: string | null
    close_at: string | null
  }

  interface IBankDetails {
    address_id: number
    bank_account_number: string
    bic_number: string
    bank_name: string
    bank_country: ICountry
    bank_city: string
    branch: string
    requested_by: ITradeFinanceUser
    requested_at: string
    approved_by: ITradeFinanceUser | null
    approved_at: string | null
    created_at: string
  }

  type AddressType = 'invoice' | 'company'

  interface IAddress {
    id: number
    name: string
    address_type: AddressType
    address: string | null
    postal_code: string | null
    state: string | null
    city: string | null
    country_id?: number
    country: ICountry
    email: string | string[] | null
    phone: string | null
    vat_number: string | null
    eori_number: string | null
    reference: string | null
    contacts: IPersonalDetail[] | null
    comment: string | null
    updated_at: string
    address_bank_detail: IBankDetails | null
    preferred_modalities: ModalityEnum[]
    address_opening_hours: IAddressOpeningHours[]
    default: boolean
    ports?: IPort[]
    port_ids?: string[][]
    sla_checkpoints: ISLA[]
    connection: IOrganization | null
  }
}

export const initialAddressesState = {
  list: [],
  page: 1,
  page_size: 1,
  total_pages: 1,
  isFetching: true,
  singleAddress: undefined,
  singleBankDetail: undefined,
  preferred_modalities: [],
}

const isFetchingAddresses: Reducer<IAddressesState, AnyAction> = (state) => ({
  ...state,
  isFetching: true,
})

const receiveList: Reducer<IAddressesState, AnyAction> = (state, action) => ({
  ...state,
  page: action.payload.page,
  page_size: action.payload.per_page,
  total_pages: action.payload.total_pages,
  list: action.payload?.data.map((address) => ({
    ...address,
    tags: address.default ? ['default'] : [],
  })),
  isFetching: false,
})

const receiveOne: Reducer<IAddressesState, AnyAction> = (state, action) => ({
  ...state,
  singleAddress: {
    ...action.payload,
    country_id: action.payload.country.id,
    port_ids: (action.payload.ports || []).map((port: IPort) => [
      port.display_name,
      port.id.toString(),
    ]),
  },
})

const receiveBankDetail: Reducer<IAddressesState, AnyAction> = (
  state,
  action
) => {
  const list = state.list
  const index = findIndex(list, { id: action.payload.address_id })

  if (index >= 0) {
    list[index].address_bank_detail = action.payload
  }

  return {
    ...state,
    list,
    singleBankDetail: action.payload,
  }
}

const setDefaultAddress: Reducer<IAddressesState, AnyAction> = (state) => {
  return {
    ...state,
    list: state.list.map((item) => ({ ...item, default: false })),
    singleAddress: state.singleAddress
      ? { ...state.singleAddress, default: true }
      : undefined,
  }
}

export default createReducer(initialAddressesState, {
  [actions.ADDRESSES_GET_DATA]: isFetchingAddresses,
  [actions.ADDRESSES_GET_DATA_SUCCESS]: receiveList,
  [actions.ADDRESSES_GET_ONE_ADDRESS_SUCCESS]: receiveOne,
  [actions.ADDRESSES_PUT_ADDRESS_SUCCESS]: receiveOne,
  [actions.ADDRESSES_ADD_ADDRESS_SUCCESS]: receiveOne,
  [actions.SET_DEFAULT_ADDRESS_SUCCESS]: setDefaultAddress,
  [bankDetailsActions.GET_BANK_DETAIL_SUCCESS]: receiveBankDetail,
  [bankDetailsActions.CREATE_BANK_DETAIL_SUCCESS]: receiveBankDetail,
  [bankDetailsActions.UPDATE_BANK_DETAIL_SUCCESS]: receiveBankDetail,
})

import { set } from 'lodash'
import { useEffect, useState } from 'react'
import { Stack, Typography, Box } from '@mui/material'
import { useFormContext } from '../../../FormContext'
import { defaultDimensionValidation } from '../../../Form.constants'
import TemplateFormCargoDimensionsInput from './CargoDimensionsInput'

type ValidationProps = {
  min: number
  max: number
}

const getDimensionErrorText = ({ dimensionValidation }) => {
  if (!dimensionValidation) {
    return ''
  }

  const values: ValidationProps[] = Object.values(dimensionValidation)

  const validationType = values.every(
    (item: ValidationProps) => item.min === item.max
  )
    ? 'equal'
    : 'minmax'

  const text = `${dimensionValidation.length.max} x ${dimensionValidation.width.max} x ${dimensionValidation.height.max} cm`
  if (validationType === 'equal') {
    return `Dimensions must be equal to ${text}`
  }
  return `Dimensions must be less than ${text}`
}

const TemplateFormCargoDimensions = ({ dimensionValidation }) => {
  const { formState, onChange, onFormErrorChange } = useFormContext()
  const { cargo } = formState
  const { packageTypeId, width, height, length, totalVolumeCbm } = cargo

  const [dimensionError, setDimensionError] = useState('')

  const dimensions = Object.keys(defaultDimensionValidation)

  const validateCargoDimensions = () => {
    const dimensionError = dimensions.find((dimensionKey) => {
      const dimensionValue = cargo[dimensionKey]
      const { min, max } = dimensionValidation[dimensionKey]
      return dimensionValue < min || dimensionValue > max
    })

    const errorText = dimensionError
      ? getDimensionErrorText({ dimensionValidation })
      : ''

    onFormErrorChange({ dimensions: dimensionError })

    setDimensionError(errorText)
  }

  const setVolume = () => {
    const volume = (width * height * length) / (1000 * 1000)
    onChange(set(formState, 'cargo.totalVolumeCbm', volume))
  }

  useEffect(() => {
    setVolume()
    if (!packageTypeId) {
      return setDimensionError('')
    }
    validateCargoDimensions()
  }, [width, height, length])

  const dimensionsDisabled =
    width === 0 && height === 0 && length === 0 && totalVolumeCbm > 0

  const textColor = dimensionsDisabled ? 'text.disabled' : 'text.primary'

  return (
    <Box sx={{ position: 'relative' }}>
      <Stack
        direction="row"
        alignItems="center"
        className={dimensionsDisabled ? 'disabled' : ''}
        sx={{
          borderWidth: 1,
          borderRadius: 1,
          borderStyle: 'solid',
          borderColor: 'grey.400',
          '&:not(.disabled)': {
            '&:hover, &:focus-within': {
              borderColor: 'primary.main',
            },
          },
          '&.disabled': {
            pointerEvents: 'none',
          },
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          divider={
            <Typography component="span" children="x" color={textColor} />
          }
        >
          <TemplateFormCargoDimensionsInput
            field="length"
            disabled={dimensionsDisabled}
          />
          <TemplateFormCargoDimensionsInput
            field="width"
            disabled={dimensionsDisabled}
          />
          <TemplateFormCargoDimensionsInput
            field="height"
            disabled={dimensionsDisabled}
          />
        </Stack>
        <Typography
          component="span"
          flexGrow={1}
          children="cm"
          textAlign="right"
          px={1}
          color={textColor}
        />
      </Stack>
      {dimensionError.length > 0 && (
        <Typography
          variant="body2"
          color="error.main"
          sx={{ position: 'absolute' }}
        >
          {dimensionError}
        </Typography>
      )}
    </Box>
  )
}

export default TemplateFormCargoDimensions

import { isArray, some } from 'lodash'
import { store } from '../shyppleStore'

// Returns true if ANY of permissions is TRUE
function permissionTo(permissionName: string | string[]): boolean {
  if (isArray(permissionName)) {
    return some(permissionName, permissionGranted)
  } else {
    return permissionGranted(permissionName as string)
  }
}

function permissionGranted(permissionName: string | undefined): boolean {
  if (!permissionName) {
    return false
  }
  const user: IUser = store.getState().user
  return user.permissions.includes(permissionName)
}

export { permissionTo }

import { FunctionComponent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { Tooltip, Checkbox } from '@mui/material'
import Table from 'src/components/Common/Table'

import './styles.scss'
import {
  totalVolumeFormatted,
  totalWeightFormatted,
  statusFormatted,
  orderLineShipmentReference,
} from './helpers'

interface IPurchaseOrderLineProps {
  orderLine: IPurchaseOrderLine
  onToggleSelected: (id: number) => void
  forShipment?: boolean
  purchaseOrderId: number
  isBuyer: boolean
  selected: boolean
}

const PurchaseOrderLine: FunctionComponent<IPurchaseOrderLineProps> = (
  props
) => {
  const { t } = useTranslation()
  const toggleSelected = useCallback(() => {
    props.onToggleSelected(props.orderLine.id)
  }, [props.orderLine])

  const isEmpty: boolean = !props.orderLine.units_ordered

  const renderCode = (): React.ReactNode =>
    isEmpty ? null : (
      <Tooltip title={props.orderLine.product_code} placement="top">
        <div className="text-wrapper">{props.orderLine.product_code}</div>
      </Tooltip>
    )

  const renderVolume = (): React.ReactNode =>
    isEmpty ? null : totalVolumeFormatted(props.orderLine) || '-'

  const renderWeight = (): React.ReactNode =>
    isEmpty ? null : totalWeightFormatted(props.orderLine) || '-'

  return (
    <Table.StripedTableRow className={props.selected ? 'selected' : ''}>
      <Table.StripedTableCell className="short-50">
        <Checkbox checked={props.selected} onChange={toggleSelected} />
      </Table.StripedTableCell>
      <Table.StripedTableCell className="left-aligned medium">
        <Typography
          variant="body1Strong"
          children={isEmpty ? '-' : `${props.orderLine.units_ordered} x`}
        />
      </Table.StripedTableCell>
      <Table.StripedTableCell className="left-aligned x-wide">
        {isEmpty ? (
          <div className="text-wrapper">
            <Typography
              variant="body1Strong"
              children={t(
                'purchase_orders.table.po_line_table.empty_state',
                'No products specified'
              )}
            />
          </div>
        ) : (
          <Tooltip title={props.orderLine.product_name} placement="top">
            <div className="text-wrapper">
              <Typography
                variant="body1Strong"
                children={props.orderLine.product_name}
              />
            </div>
          </Tooltip>
        )}
      </Table.StripedTableCell>
      <Table.StripedTableCell className="left-aligned x-medium">
        <Typography variant="body1" component="div" children={renderCode()} />
      </Table.StripedTableCell>
      <Table.StripedTableCell className="left-aligned medium">
        <Typography variant="body1" component="div" children={renderVolume()} />
      </Table.StripedTableCell>
      <Table.StripedTableCell className="left-aligned medium">
        <Typography variant="body1" component="div" children={renderWeight()} />
      </Table.StripedTableCell>
      <Table.StripedTableCell className="left-aligned dynamic">
        {!props.forShipment && (
          <>
            <div
              className={`purchase-order-line__status ${props.orderLine.status}`}
            >
              {statusFormatted(t, props.orderLine)}
            </div>
            <div className="purchase-order-line__shipment">
              <div className="text-wrapper paragraph__medium lightblue">
                {orderLineShipmentReference(
                  props.orderLine,
                  props.purchaseOrderId
                )}
              </div>
            </div>
          </>
        )}
      </Table.StripedTableCell>
    </Table.StripedTableRow>
  )
}

export default PurchaseOrderLine

import {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { parse } from 'query-string'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import SABSearchResult from 'src/components/SearchAndBook/SABSearchResult'
import SABSearchPrevious from 'src/components/SearchAndBook/SABSearchPrevious'
import PhraseLoader from 'src/components/PhraseLoader'
import SearchContainer from 'src/pages/SearchAndBook/SearchContainer'
import TemplatesList from 'src/pages/Templates/List/TemplatesList'
import { searchPrevious } from '../../stores/actionCreators'
import promisifyAction from '../../utils/promisifyAction'
import { searchQuotesReset } from '../../stores/actionCreators/searches'
import PurchaseOrdersNotification from './PurchaseOrdersNotification'
import './styles.scss'

const SearchAndBook: FunctionComponent<{}> = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    loading,
    searchResult,
    searchPreviousSearches,
    is_staff,
  } = useSelector((state: IGlobalState) => ({
    loading: state.searches.loading,
    searchResult: state.searches,
    searchPreviousSearches: state.searchPrevious.searches,
    is_staff: state.user.isStaff,
    preferredShipmentRoleId: state.user.preferredShipmentRoleId,
  }))
  const [sort, setSort] = useState<string>(
    t('common.modality_sea.departure_date', 'Departure date')
  )

  const searchNotPerformedYet = useMemo(
    () => searchResult && !searchResult.quotes.length,
    [searchResult]
  )

  const searchPreviousAsync = promisifyAction(dispatch, searchPrevious)
  const searchQuotesResetAsync = promisifyAction(dispatch, searchQuotesReset)

  useEffect(() => {
    const fetchDataAsync = async () => {
      await searchPreviousAsync()
    }
    fetchDataAsync()
    return () => {
      const resetDataAsync = async () => {
        await searchQuotesResetAsync()
      }
      resetDataAsync()
    }
  }, [])

  const sortResult = useCallback(async (val: string) => {
    setSort(val)
  }, [])

  return (
    <div>
      <div className="dashboard-header--top">
        <Typography
          variant="h2"
          children={t('common.search.new_booking', 'New booking')}
          sx={{ mb: 1 }}
        />
      </div>

      <PurchaseOrdersNotification />
      <SearchContainer />
      <Box mt={2} />
      <Box mb={2} />
      <SABSearchResult
        searchResult={searchResult}
        searchQuery={parse(window.location.search, { arrayFormat: 'bracket' })}
        sortF={sortResult}
        sortType={sort}
        isStaff={is_staff}
      />
      {searchNotPerformedYet && (
        <>
          <TemplatesList limit={3} showTemplateNavigation={true} />
          <SABSearchPrevious previousSearches={searchPreviousSearches} />
        </>
      )}

      <PhraseLoader loading={loading} type="search" />
    </div>
  )
}

export default SearchAndBook

import { FunctionComponent, useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showNotification } from 'src/stores/actionCreators/notifications'
import InlineNotification from 'src/components/Common/InlineNotification'
import ConfirmDialog from 'src/components/ConfirmDialog'
import PurchaseOrderLine from 'src/components/PurchaseOrders/PurchaseOrderLine'
import Table from 'src/components/Common/Table'
import { promisifyAction } from '../../../utils'
import CargoTasks from '../CargoTasks'
import POTabs from '../POTabs'

import {
  triggerFetchingOrders,
  shipmentPurchaseOrdersGetData,
  purchaseOrderUnmarkException,
  updatePurchaseOrder,
  togglePOSelect,
} from '../../../stores/actionCreators'

import RowDetails from './RowDetails'
import ShipmentRowDetails from './ShipmentRowDetails'
import { cells } from './constants'

import './styles.scss'

interface IProps {
  purchaseOrder: IPurchaseOrder
  editItem: () => void
  deleteItem: () => void
  forShipment?: boolean
  shipmentId?: number
  fetchData?: () => void
  isBuyer: boolean
}

const PurchaseOrderLineDetails: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { selectedLineIds } = useSelector((state: IGlobalState) => ({
    selectedLineIds: state.purchaseOrders.selectedLineIds,
  }))

  const [confirmUnmarkIsOpen, setConfirmUnmarkIsOpen] = useState<boolean>(false)
  const [currentEventId, setCurrentEventId] = useState<number | null>(null)

  const showSuccess = (message: string, duration?: number) => {
    dispatch(showNotification({ message, severity: 'success' }))
  }

  const togglePOSelectAsync = promisifyAction(dispatch, togglePOSelect)

  const updatePurchaseOrderAsync = promisifyAction(
    dispatch,
    updatePurchaseOrder
  )

  const purchaseOrderUnmarkExceptionAsync = promisifyAction(
    dispatch,
    purchaseOrderUnmarkException
  )
  const fetchOrders = promisifyAction(dispatch, triggerFetchingOrders)
  const shipmentPurchaseOrdersGetDataAsync = promisifyAction(
    dispatch,
    shipmentPurchaseOrdersGetData
  )

  const isSellerPresent: boolean = useMemo(
    () => !!props.purchaseOrder.seller && !!props.purchaseOrder.seller.address,
    [props.purchaseOrder]
  )

  const onSelectChildCheckbox = useCallback((id: number) => {
    togglePOSelectAsync(id)
  }, [])

  const confirmUnmark = useCallback(async (): Promise<any> => {
    await purchaseOrderUnmarkExceptionAsync(props.purchaseOrder.id, {
      po_event_id: currentEventId,
    })
    if (props.forShipment) {
      await shipmentPurchaseOrdersGetDataAsync(props.shipmentId || 0)
    } else {
      await fetchOrders()
    }
    closeDialog()
  }, [currentEventId])

  const closeDialog = (): void => {
    setConfirmUnmarkIsOpen(false)
    setCurrentEventId(null)
  }

  const copyItemPublicLink = (): void => {
    if (props.purchaseOrder.public_po_link) {
      navigator.clipboard.writeText(props.purchaseOrder.public_po_link)
      showSuccess(
        t(
          'purchase_orders.row_details.notifications.copied_to_clipboard',
          'Sharable link is copied to your clipboard.'
        )
      )
    }
  }

  const showStatusBlock = (): boolean => {
    return !props.purchaseOrder.seller
  }

  const onUnmarkClick = (eventId: number): void => {
    setCurrentEventId(eventId)
    setConfirmUnmarkIsOpen(true)
  }

  const onConfirmCRD = async () => {
    await updatePurchaseOrderAsync(props.purchaseOrder.id, {
      confirm_cargo_ready_date: true,
    })
    await showSuccess(
      t(
        'purchase_orders.row_details.notifications.cargo_ready_date_confirmed',
        'Cargo ready date confirmed.'
      )
    )
    await fetchOrders()
  }
  const onPOUpdate = async (poData) => {
    await updatePurchaseOrderAsync(props.purchaseOrder.id, poData)
    await showSuccess(
      t(
        'purchase_orders.row_details.notifications.order_details_updated',
        'Order details updated.'
      )
    )
  }

  return (
    <div className="purchase-order__details">
      {!props.isBuyer && (
        <CargoTasks
          purchaseOrder={props.purchaseOrder}
          onConfirmCargoReady={onConfirmCRD}
          onModalWindowFetch={async () => {
            await fetchOrders()
          }}
          purchaseOrdersUpdate={onPOUpdate}
          editAvailable={true}
          ready={!!props.purchaseOrder}
        />
      )}
      {showStatusBlock() && (
        <div className="purchase-order__details-status-block">
          <InlineNotification
            color={'warning'}
            show={true}
            message={t(
              'purchase_orders.row_details.notifications.assign_shipper.message',
              'Please assign a shipper to the order before assigning it to a shipment'
            )}
            actionName={t(
              'purchase_orders.row_details.notifications.assign_shipper.action_button',
              'Add shipper'
            )}
            action={props.editItem}
            showClose={false}
          />
        </div>
      )}
      <div>
        <Table.StripedTable theme="rates">
          <div>
            {props.forShipment ? (
              <ShipmentRowDetails purchaseOrder={props.purchaseOrder} />
            ) : (
              <RowDetails
                isSellerPresent={isSellerPresent}
                purchaseOrderId={props.purchaseOrder.id}
                editItem={props.editItem}
                deleteItem={props.deleteItem}
                copyItemPublicLink={copyItemPublicLink}
                isCopyDisabled={!props.purchaseOrder.public_po_link}
                refreshOrdersData={props.fetchData}
                isBuyer={props.isBuyer}
                purchaseOrder={props.purchaseOrder}
              />
            )}
          </div>

          <Table.StripedTableHeader
            cells={cells(t)}
            component={'purchase-orders'}
          />
          <Table.StripedTableBody>
            {props.purchaseOrder.purchase_order_lines.map(
              (orderLine: IPurchaseOrderLine) => (
                <PurchaseOrderLine
                  orderLine={orderLine}
                  key={orderLine.id}
                  onToggleSelected={onSelectChildCheckbox}
                  forShipment={props.forShipment}
                  purchaseOrderId={props.purchaseOrder.id}
                  isBuyer={props.isBuyer}
                  selected={selectedLineIds.includes(orderLine.id)}
                />
              )
            )}
          </Table.StripedTableBody>
        </Table.StripedTable>
      </div>

      {!props.forShipment && (
        <POTabs
          purchaseOrder={props.purchaseOrder}
          onUnmark={onUnmarkClick}
          editItem={props.editItem}
        />
      )}
      <ConfirmDialog
        title={t(
          'purchase_orders.row_details.dialogs.unmark_exception.title',
          'Confirmation'
        )}
        message={t(
          'purchase_orders.row_details.dialogs.unmark_exception.message',
          'You are about to unmark this event as an exception. This change cannot be undone. Please press confirm to proceed.'
        )}
        isOpen={confirmUnmarkIsOpen}
        confirm={confirmUnmark}
        reject={closeDialog}
        onClose={closeDialog}
      />
    </div>
  )
}

export default PurchaseOrderLineDetails

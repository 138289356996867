import { FunctionComponent, useMemo } from 'react'
import containerIcon from 'src/assets/images/container.png'
import shipIcon from 'src/assets/images/ship.png'
import '../styles.scss'
import { LoadingButton } from '@mui/lab'

type Theme = 'PO' | 'shipmentPO' | 'shareWindow' | 'connectionUsers'

interface IProps {
  theme?: Theme
  type: string
  descMainText?: string
  descSubText?: string
  buttonAction?: () => void
  buttonColor?: string
  buttonText?: string
  buttonLoading?: boolean
  iconType: string
  linkBlock?: React.ReactNode
}

const Info: FunctionComponent<IProps> = (props) => {
  const infoImage = useMemo(() => {
    switch (props.iconType) {
      case 'ship':
        return shipIcon
      default:
        return containerIcon
    }
  }, [props.iconType])

  return (
    <div className={`common-blank-state ${props.theme}`}>
      <div className="common-blank-state--info_block">
        <img height="200px" src={infoImage} alt="No Results" />
      </div>
      {props.type === 'link' && (
        <div className="common-blank-state--action_desc">{props.linkBlock}</div>
      )}
      {!props.type && (
        <div className="common-blank-state--info_block">
          <div className="common-blank-state--info_desc">
            {props.descMainText}
          </div>
          <div className="common-blank-state--info_sub-desc">
            {props.descSubText}
          </div>
        </div>
      )}
      {props.type === 'button' && (
        <div className="common-blank-state--button_block">
          <LoadingButton variant="contained" onClick={props.buttonAction}>
            {props.buttonText || ''}
          </LoadingButton>
        </div>
      )}
    </div>
  )
}

export default Info

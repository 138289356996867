import { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import Typography from '@mui/material/Typography'

import Input from 'src/components/Common/Input'

import { shipmentsToAssignPO } from '../../../../stores/actionCreators'
import { promisifyAction } from '../../../../utils'

import './styles.scss'

const ShipmentFilters: FunctionComponent<{}> = () => {
  const { t } = useTranslation()
  const [search, setSearch] = useState<string>('')

  const dispatch: Dispatch = useDispatch()

  const fetchShipments = promisifyAction(dispatch, shipmentsToAssignPO)

  useEffect(() => {
    fetchShipments({ search: search })
  }, [search])

  return (
    <div className="select-shipments-step__filters">
      <Typography variant="h5">
        {t(
          'purchase_order.modals.assign_order_to_shipment.shipment_filters',
          'Choose shipment'
        )}
      </Typography>
      <Input.SearchBar
        theme="shipmentsPage"
        value={search}
        onChange={setSearch}
        text="shipments"
      />
    </div>
  )
}

export default ShipmentFilters

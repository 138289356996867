import { createReducer, Handlers, Reducer } from 'redux-create-reducer'
import { AnyAction } from 'redux'
import * as actions from '../actions/searches'
import { convertToUnixDate } from '../../utils/helpers'
import { IPriceDetails } from './prices'
import { ISchedule } from './schedule'

declare global {
  interface IStaffData {
    carrier_url: string
    sailing_url: string
  }

  interface IContainerShort {
    container_type: string
    container_index: string
    quantity: number
    weight_kg: number | null
    length_mm: number | null
    width_mm: number | null
    height_mm: number | null
    volume_cbm: number | null
    stackable: boolean
    goods_description: string | null
    package_type: string | null
  }

  interface ISearchQuote {
    url: string
    book_url: string
    total_price_eur: string
    total_price_usd: string
    estimated_prices: boolean
    delivery_date: string
    incomplete_prices: boolean
    fastest: boolean
    cheapest: boolean
    schedule: ISchedule
    cargo_closing_date: string
    pickup_address: string
    distance_to_loading_port: number
    delivery_address: string
    distance_to_discharge_port: number
    user_id: number
    price_details: IPriceDetails
    staff_data?: IStaffData
    query: ISearchQuery
    currentUserId?: number
    owner_organization_name: string
    owner_organization_id: number
    seller_organization: IOrganizationWithTerms
    requested_air_tt?: null | string
  }

  interface ISearchResult {
    shipment_type: string
    containers: IContainerShort[]
    quotes: ISearchQuote[]
    loading: boolean
  }

  interface IPriceServices {
    service_name: string
    freight: boolean
    price_on_request: boolean
    subtotal: string
    subtotal_currency: string
    subtotal_eur: string
    subtotal_usd: string
    items: IPriceItem[]
  }

  interface IPriceItem {
    description: string
    included: boolean
    price_on_request: boolean
    quantity: string
    unit_price: string | null
    price: string | null
    currency: string | null
    currency_symbol: string | null
    price_usd: string | null
    price_eur: string | null
  }
}

export const initialSearchQuotesState: ISearchResult = {
  shipment_type: 'unknown',
  containers: [],
  quotes: [],
  loading: false,
}

const searchQuotes: Reducer<ISearchResult, AnyAction> = (
  state,
  { payload }
) => ({
  shipment_type: payload.data.data.shipment_type,
  containers: payload.data.data.containers,
  quotes: payload.data.data.quotes,
  loading: false,
})

const searchQuotesStart: Reducer<ISearchResult, AnyAction> = (state) => ({
  ...state,
  loading: true,
})

const searchQuotesError: Reducer<ISearchResult, AnyAction> = () => ({
  shipment_type: 'unknown',
  containers: [],
  quotes: [],
  loading: false,
})

const searchQuotesReset: Reducer<ISearchResult, AnyAction> = (state) => ({
  ...state,
  schedules: [],
  quotes: [],
  containers: [],
  shipment_type: 'unknown',
})

const searchQuotesSort: Reducer<ISearchResult, AnyAction> = (
  state,
  { payload }
) => {
  switch (payload) {
    case 'total_usd':
      return {
        ...state,
        schedules: state.quotes && [
          ...state.quotes.sort((a, b) => {
            if (
              parseFloat(a.total_price_usd) === parseFloat(b.total_price_usd)
            ) {
              if (
                formatDate(a.schedule.estimated_departure_date) ===
                formatDate(b.schedule.estimated_departure_date)
              ) {
                return (
                  a.schedule.transit_time_days - b.schedule.transit_time_days
                )
              }
              return (
                formatDate(a.schedule.estimated_departure_date) -
                formatDate(b.schedule.estimated_departure_date)
              )
            }
            return parseFloat(a.total_price_usd) - parseFloat(b.total_price_usd)
          }),
        ],
      }
    case 'transit_time_days':
      return {
        ...state,
        schedules: state.quotes && [
          ...state.quotes.sort((a, b) => {
            if (a.schedule.transit_time_days === b.schedule.transit_time_days) {
              if (
                parseFloat(a.total_price_usd) === parseFloat(b.total_price_usd)
              ) {
                return (
                  formatDate(a.schedule.estimated_departure_date) -
                  formatDate(b.schedule.estimated_departure_date)
                )
              }
              return (
                parseFloat(a.total_price_usd) - parseFloat(b.total_price_usd)
              )
            }
            return a.schedule.transit_time_days - b.schedule.transit_time_days
          }),
        ],
      }
    case 'estimated_departure_date':
      return {
        ...state,
        schedules: state.quotes && [
          ...state.quotes.sort((a, b) => {
            if (
              formatDate(a.schedule.estimated_departure_date) ===
              formatDate(b.schedule.estimated_departure_date)
            ) {
              if (
                parseFloat(a.total_price_usd) === parseFloat(b.total_price_usd)
              ) {
                return (
                  a.schedule.transit_time_days - b.schedule.transit_time_days
                )
              }
              return (
                parseFloat(a.total_price_usd) - parseFloat(b.total_price_usd)
              )
            }
            return (
              formatDate(a.schedule.estimated_departure_date) -
              formatDate(b.schedule.estimated_departure_date)
            )
          }),
        ],
      }
    case 'estimated_arrival_date':
      return {
        ...state,
        schedules: state.quotes && [
          ...state.quotes.sort((a, b) => {
            if (
              formatDate(a.schedule.estimated_arrival_date) ===
              formatDate(b.schedule.estimated_arrival_date)
            ) {
              if (
                parseFloat(a.total_price_usd) === parseFloat(b.total_price_usd)
              ) {
                return (
                  a.schedule.transit_time_days - b.schedule.transit_time_days
                )
              }
              return (
                parseFloat(a.total_price_usd) - parseFloat(b.total_price_usd)
              )
            }
            return (
              formatDate(a.schedule.estimated_arrival_date) -
              formatDate(b.schedule.estimated_arrival_date)
            )
          }),
        ],
      }
    default:
      return { ...state }
  }
}

const formatDate = (data: string): number => {
  return +convertToUnixDate(data)
}

const actionHandlers: Handlers<ISearchResult> = {
  [actions.SEARCH_QUOTES_SUCCESS]: searchQuotes,
  [actions.SEARCH_QUOTES_ERROR]: searchQuotesError,
  [actions.SEARCH_QUOTES]: searchQuotesStart,
  [actions.SEARCH_QUOTES_RESET]: searchQuotesReset,
  [actions.SEARCH_QUOTES_SORT]: searchQuotesSort,
}

export default createReducer(initialSearchQuotesState, actionHandlers)

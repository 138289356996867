import { FunctionComponent } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import './styles.scss'

export interface CompletionPointProps {
  /**
   * Completion Point status value
   */
  completed: boolean
}

const CompletionPoint: FunctionComponent<CompletionPointProps> = (props) => {
  return (
    <div className={`completion-point ${props.completed ? 'completed' : ''}`}>
      <CheckIcon className="icon" />
    </div>
  )
}

export default CompletionPoint

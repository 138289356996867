// @ts-nocheck
// FIXME

import MuiTab, { TabProps } from '@mui/material/Tab'
import { push } from 'connected-react-router'
import { LocationDescriptorObject } from 'history'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import joinURL from 'url-join'

interface IProps extends TabProps {
  value: string
  queryString: string
  basePath: string
  onTabClick?(value: string): void
  toLocation(location: LocationDescriptorObject): void
  label: string
}

const mapStateToProps = (state: IGlobalState) => ({
  queryString: get(state.router, 'location.search', ''),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toLocation(location: LocationDescriptorObject) {
    dispatch(push(location))
  },
})

function Tab({
  toLocation,
  queryString,
  value,
  basePath,
  onTabClick,
  ...tabProps
}: IProps) {
  const switchTab = () => {
    const location = {
      pathname: joinURL(basePath, value),
      search: queryString,
    }
    onTabClick?.(value)
    toLocation?.(location)
  }

  return <MuiTab {...tabProps} onChange={switchTab} id={value} />
}

export default connect(mapStateToProps, mapDispatchToProps)(Tab)

import { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import OptionsPickerAsync, {
  IOptionsPickerAsyncProps,
} from 'src/components/Common/Input/OptionsPickerAsync'
import {
  getPorts,
  clearPorts,
  preloadPols,
  preloadPods,
} from 'src/stores/actionCreators'

interface IProps
  extends Omit<
    IOptionsPickerAsyncProps,
    | 'onChange'
    | 'options'
    | 'isFetching'
    | 'clearOptionsAction'
    | 'fetchOptionsAction'
    | 'theme'
  > {
  onChange: (values: string[][], name: string) => void
  selectedIds: string[]
  portType: PortStorageType
}

const PortFilter = ({
  fieldName,
  label,
  error = false,
  onChange,
  values,
  selectedLabel,
  selectedIds,
  portType,
}: IProps) => {
  const dispatch = useDispatch()
  // We usually need two ports storages at the same page - for POLs and PODs.
  const preloadAction = portType === 'pol' ? preloadPols : preloadPods

  useEffect(() => {
    if (selectedIds.length && !values.length) {
      dispatch(preloadAction({ ids: selectedIds }))
    }
  }, [selectedIds, values])

  const clearOptionsAction = () => {
    dispatch(clearPorts())
  }

  const fetchOptionsAction = (search: string) => {
    dispatch(getPorts({ search }))
  }

  const { ports, isFetching, pols, pods } = useSelector(
    (state: IGlobalState) => ({
      ports: state.ports.items,
      isFetching: state.ports.isFetching,
      pols: state.ports.pol.items,
      pods: state.ports.pod.items,
    })
  )

  useEffect(() => {
    if (pols.length && !values.length && portType === 'pol') {
      onChange(
        pols.map(({ name, id, code }) => [`${code} - ${name}`, id.toString()]),
        fieldName
      )
      dispatch(clearPorts('pol'))
    }

    if (pods.length && !values.length && portType === 'pod') {
      onChange(
        pods.map(({ name, id }) => [name, id.toString()]),
        fieldName
      )
      dispatch(clearPorts('pod'))
    }
  }, [pols, pods, portType])

  const mappedOptions = useMemo(
    () =>
      ports
        .filter(
          (port) => !values.map(([name, id]) => id).includes(port.id.toString())
        )
        .map((port) => [port.display_name, port.id]),
    [ports, values]
  )

  return (
    <OptionsPickerAsync
      fetchOptionsAction={fetchOptionsAction}
      clearOptionsAction={clearOptionsAction}
      isFetching={isFetching}
      values={values}
      label={label}
      onChange={onChange}
      fieldName={fieldName}
      options={[...values, ...mappedOptions]}
      error={error}
      theme="filtersPagePicker"
      selectedLabel={selectedLabel || undefined}
    />
  )
}

export default PortFilter

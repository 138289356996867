import { Dispatch } from 'redux'
import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { updatePickupsAndDeliveriesShipmentStatus } from 'src/stores/actionCreators'
import Card from '@mui/material/Card'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { permissionTo, promisifyAction } from 'src/utils'
import { ShipmentContent, StatusProps } from 'src/stories'

export const ShipmentInformationCard = (
  props: ISingleInlandTransportShipmentData
) => {
  let isMounted = true
  const shipment = props
  const initialStatus = {
    id: shipment.status.id,
    name: shipment.status.name,
    phase: shipment?.shipment_phase ?? 'unknown',
  }
  const dispatch: Dispatch = useDispatch()
  const [updating, setUpdating] = useState<boolean>(false)
  const [status, setStatus] = useState<StatusProps>(initialStatus)
  const hasStatusUpdatePermission = permissionTo('shipments.status.manage')
  const {
    shared_reference: sharedRef,
    reference_number: internalRef,
  } = shipment

  const { statuses } = useSelector(
    (globalState: IGlobalState) => ({
      statuses: globalState.shipments.shipmentStatuses.map((shipmentStatus) => {
        return {
          name: shipmentStatus.name,
          id: `${shipmentStatus.id}`,
          phase: shipmentStatus?.phase?.code ?? '',
          group: shipmentStatus?.phase?.name ?? '',
        }
      }),
    }),
    shallowEqual
  )

  const updateShipment = promisifyAction(
    dispatch,
    updatePickupsAndDeliveriesShipmentStatus
  )

  const updateShipmentStatus = (item: StatusProps) => {
    const { id } = item
    setStatus(item)
    setUpdating(true)
    updateShipment(shipment.id, {
      status_id: id,
    })
      .then(() => {
        dispatch(
          showNotification({
            severity: 'success',
            message: 'Shipment status updated',
          })
        )
      })
      .catch(() => {
        setStatus(initialStatus)
      })
      .finally(() => {
        setUpdating(false)
      })
  }

  useEffect(() => {
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    setStatus(initialStatus)
  }, [shipment.status.id])

  return (
    <Card sx={{ padding: 2.5, marginTop: 2.5 }} variant="outlined">
      {isMounted && (
        <ShipmentContent
          {...{
            updating,
            showStatusMenu: hasStatusUpdatePermission,
            onStatusChange: updateShipmentStatus,
            data: {
              status,
              statuses,
              id: `${shipment.id}`,
              title: shipment.title,
              shipment_legs: shipment.shipment_legs,
              shared_reference: !!sharedRef ? sharedRef : '-',
              internal_reference: !!internalRef ? internalRef : '-',
              visibility_only: shipment.visibility_only,
            },
          }}
        />
      )}
    </Card>
  )
}

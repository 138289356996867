import { makeStyles } from '@mui/styles'
import { Box, Typography } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  PasswordRequirementItem: {
    strokeLinecap: 'round',
    strokeWidth: '2px',
    stroke: theme.palette.success.main,
    strokeDasharray: 45,
    strokeDashoffset: 45,
    animation: '$check 0.2s 0.1s ease-in-out forwards',
  },
  '@keyframes check': {
    '0%': {
      strokeDashoffset: 45,
    },
    '100%': {
      strokeDashoffset: 20,
    },
  },
}))

const PasswordRequirementItem = ({ text, isChecked }) => {
  const classes = useStyles()
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {isChecked ? (
        <svg
          width="14"
          height="12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polyline
            className={classes.PasswordRequirementItem}
            points="1,7 5,11 13,1"
            fill="none"
          />
        </svg>
      ) : (
        <Box
          sx={{
            width: '14px',
            height: '12px',
          }}
        />
      )}
      <Box ml={1}>
        <Typography
          color={isChecked ? 'text.disabled' : 'primary.main'}
          variant="subtitle2"
        >
          {text}
        </Typography>
      </Box>
    </Box>
  )
}

export default PasswordRequirementItem

import { FunctionComponent, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { useUpdateUserContainerType } from 'src/services/Api/prices'
import { queryClient } from 'src/services/http-common'
import { queryKeys } from 'src/services/endpoints'
import { useTranslation } from 'react-i18next'

interface IProps {
  container_types: IContainerType[]
  user_container_types: IContainerType[]
}

const RatesColumnsFilter: FunctionComponent<IProps> = ({
  container_types,
  user_container_types,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {
    mutateAsync: updateUserContainers,
    isLoading,
  } = useUpdateUserContainerType({
    onSuccess: () => {
      return queryClient.invalidateQueries([queryKeys.pricesFilterOptions])
    },
  })

  const enabledContainers = useMemo(() => {
    return user_container_types.map((i) => i.name)
  }, [user_container_types])

  const [options, setOptions] = useState(
    container_types?.map((type) => ({
      id: type.id,
      name: type.name,
      enabled: enabledContainers.includes(type.name),
    }))
  )

  const handleSelectColumn = (optionId: number): void => {
    const idx = options.findIndex((option) => optionId === option.id)
    setOptions((prev) => {
      return [
        ...prev.slice(0, idx),
        { ...prev[idx], enabled: !prev[idx].enabled },
        ...prev.slice(idx + 1),
      ]
    })
  }

  const handleSubmit = async (): Promise<void> => {
    const container_type_ids = options.filter((i) => i.enabled).map((i) => i.id)
    await updateUserContainers({ container_type_ids })
    dispatch(
      showNotification({
        message: 'Table layout updated.',
        severity: 'success',
      })
    )
  }

  return (
    <FormGroup>
      {options.map((option) => (
        <FormControlLabel
          key={option.id}
          label={option.name}
          control={<Checkbox />}
          disabled={isLoading}
          checked={option.enabled}
          onChange={(_) => handleSelectColumn(option.id)}
          data-testid={option.id + '-' + option.name}
        />
      ))}

      <Box mt={4} display="flex" justifyContent="end">
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={handleSubmit}
          data-testid="save-button"
        >
          {t('common.save', 'Save')}
        </LoadingButton>
      </Box>
    </FormGroup>
  )
}

export default RatesColumnsFilter

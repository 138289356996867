import SelectField from '../../../ReportsDashboardTable/components/SelectField'

type SchemaItem = {
  shouldRender: (shipment: AnyReportsShipment) => boolean
  field: (shipment: AnyReportsShipment) => React.ReactNode
  name: string
  sortable: boolean
  sortValue: string
  customClassName: string
}

type TaskStatusesSchema = {
  [key: string]: SchemaItem
}

export const taskStatusesSchema: TaskStatusesSchema = {
  delivery: {
    shouldRender: (shipment) => !!shipment.inland_transport_status,
    field: (shipment) =>
      shipment.inland_transport_status ? (
        <SelectField
          shipmentId={shipment.id}
          currentTaskTypeStatusId={shipment.inland_transport_status as string}
          taskType="delivery"
          selectType="deliveryStatus"
          taskOrInlandTransportId={shipment.inland_transport_id as number}
        />
      ) : null,
    name: 'DELIVERY',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  truck: {
    shouldRender: (shipment) => !!shipment.trucking_task,
    field: (shipment) =>
      shipment.trucking_task ? (
        <SelectField
          shipmentId={shipment.id}
          currentTaskTypeStatusId={shipment.trucking_task.task_status_id}
          taskType="align_delivery_with_transporter"
          taskOrInlandTransportId={shipment.trucking_task.id}
        />
      ) : null,
    name: 'TRUCK',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  commu: {
    shouldRender: (shipment) => !!shipment.communication_task,
    field: (shipment) =>
      shipment.communication_task ? (
        <SelectField
          shipmentId={shipment.id}
          currentTaskTypeStatusId={shipment.communication_task.task_status_id}
          taskType="obtain_delivery_date_from_consignee"
          taskOrInlandTransportId={shipment.communication_task.id}
        />
      ) : null,
    name: 'COMMU.',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  finance: {
    shouldRender: (shipment) => !!shipment.finance_task,
    field: (shipment) =>
      shipment.finance_task ? (
        <SelectField
          shipmentId={shipment.id}
          currentTaskTypeStatusId={shipment.finance_task.task_status_id}
          taskType="finance_etd_check"
          taskOrInlandTransportId={shipment.finance_task.id}
        />
      ) : null,
    name: 'FINANCE',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  finance_delivery: {
    shouldRender: (shipment) => !!shipment.finance_delivery_task,
    field: (shipment) =>
      shipment.finance_delivery_task ? (
        <SelectField
          shipmentId={shipment.id}
          currentTaskTypeStatusId={
            shipment.finance_delivery_task.task_status_id
          }
          taskType="finance_delivery_check"
          taskOrInlandTransportId={shipment.finance_delivery_task.id}
        />
      ) : null,
    name: 'FINANCE DELIVERY',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  destinationCustomClearance: {
    shouldRender: (shipment) => !!shipment.destination_customs_clearance_task,
    field: (shipment) =>
      shipment.destination_customs_clearance_task ? (
        <SelectField
          shipmentId={shipment.id}
          currentTaskTypeStatusId={
            shipment.destination_customs_clearance_task.task_status_id
          }
          taskType="obtain_destination_customs_clearance"
          taskOrInlandTransportId={
            shipment.destination_customs_clearance_task.id
          }
        />
      ) : null,
    name: 'DEST. CLEARANCE',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned x-medium',
  },
  performCustomsDocsCheck: {
    shouldRender: (shipment) => !!shipment.perform_customs_docs_check_task,
    field: (shipment) =>
      shipment.perform_customs_docs_check_task ? (
        <SelectField
          shipmentId={shipment.id}
          currentTaskTypeStatusId={
            shipment.perform_customs_docs_check_task.task_status_id
          }
          taskType="perform_customs_documents_check"
          taskOrInlandTransportId={shipment.perform_customs_docs_check_task.id}
        />
      ) : null,
    name: 'CUSTOMS DOCS CHECK',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned  x-medium',
  },
  obtainCustomsDocs: {
    shouldRender: (shipment) => !!shipment.obtain_customs_documents_task,
    field: (shipment) =>
      shipment.obtain_customs_documents_task ? (
        <SelectField
          shipmentId={shipment.id}
          currentTaskTypeStatusId={
            shipment.obtain_customs_documents_task.task_status_id
          }
          taskType="obtain_customs_documents"
          taskOrInlandTransportId={shipment.obtain_customs_documents_task.id}
        />
      ) : null,
    name: 'OBTAIN CUSTOMS DOCS',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned x-medium',
  },
  hbl: {
    shouldRender: (shipment) => !!shipment.obtain_hbl_task,
    field: (shipment) =>
      shipment.obtain_hbl_task ? (
        <SelectField
          shipmentId={shipment.id}
          currentTaskTypeStatusId={shipment.obtain_hbl_task.task_status_id}
          taskType="obtain_released_hbl"
          taskOrInlandTransportId={shipment.obtain_hbl_task.id}
        />
      ) : null,
    name: 'HBL',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  mbl: {
    shouldRender: (shipment) => !!shipment.obtain_mbl_task,
    field: (shipment) =>
      shipment.obtain_mbl_task ? (
        <SelectField
          shipmentId={shipment.id}
          currentTaskTypeStatusId={shipment.obtain_mbl_task.task_status_id}
          taskType="obtain_released_mbl"
          taskOrInlandTransportId={shipment.obtain_mbl_task.id}
        />
      ) : null,
    name: 'MBL',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  release: {
    shouldRender: (shipment) => !!shipment.release_task,
    field: (shipment) =>
      shipment.release_task ? (
        <SelectField
          shipmentId={shipment.id}
          currentTaskTypeStatusId={shipment.release_task.task_status_id}
          taskType="obtain_carrier/coloader_release"
          taskOrInlandTransportId={shipment.release_task.id}
        />
      ) : null,
    name: 'RELEASE',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
}

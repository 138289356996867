import { useEffect, useState } from 'react'
import uniq from 'lodash/uniq'
import { Paper } from '@mui/material'
import {
  MRT_DensityState,
  MRT_VisibilityState,
  MRT_ColumnOrderState,
  MRT_ColumnPinningState,
  MRT_ColumnSizingState,
  MRT_SortingState,
} from 'material-react-table'
import { TableWrapperProps } from './TableWrapper.props'
import {
  getStorageKeys,
  getLocalStorage,
  setLocalStorage,
} from './TableWrapper.utils'

const TableWrapper: React.FC<TableWrapperProps> = ({
  columns,
  WrappedTable,
  ComponentProps,
  tableStorageKey,
  defaultColumnOrder = [],
  defaultColumnVisibility = {},
  ...otherProps
}) => {
  const storageKeys = getStorageKeys(tableStorageKey)

  const storedDensity: MRT_DensityState = getLocalStorage(
    storageKeys['density'],
    'compact'
  )

  const storedColumnOrder: MRT_ColumnOrderState = getLocalStorage(
    storageKeys['columnOrder'],
    []
  )

  const storedColumnVisibility: MRT_VisibilityState = getLocalStorage(
    storageKeys['columnVisibility'],
    {}
  )

  const storedColumnSizing: MRT_ColumnSizingState = getLocalStorage(
    storageKeys['columnSizing'],
    {}
  )

  const storedColumnPinning: MRT_ColumnPinningState = getLocalStorage(
    storageKeys['columnPinning'],
    {}
  )

  const storedColumnSorting: MRT_SortingState = getLocalStorage(
    storageKeys['columnSorting'],
    []
  )

  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    { ...defaultColumnVisibility, ...storedColumnVisibility }
  )

  const [columnSizing, setColumnSizing] = useState<MRT_ColumnSizingState>(
    storedColumnSizing
  )

  const [columnPinning, setColumnPinning] = useState<MRT_ColumnPinningState>(
    storedColumnPinning
  )

  const [columnSorting, setColumnSorting] = useState<MRT_SortingState>(
    storedColumnSorting
  )

  const [density, setDensity] = useState<MRT_DensityState>(storedDensity)

  // This is to prevent future problems when accessor keys change or are removed/added
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>(
    uniq([...storedColumnOrder, ...defaultColumnOrder])
  )

  useEffect(() => {
    setLocalStorage(storageKeys['columnVisibility'], columnVisibility)
  }, [columnVisibility])

  useEffect(() => {
    setLocalStorage(storageKeys['columnPinning'], columnPinning)
  }, [columnPinning])

  useEffect(() => {
    setLocalStorage(storageKeys['columnSizing'], columnSizing)
  }, [columnSizing])

  useEffect(() => {
    setLocalStorage(storageKeys['columnOrder'], columnOrder)
  }, [columnOrder])

  useEffect(() => {
    setLocalStorage(storageKeys['columnSorting'], columnSorting)
  }, [columnSorting])

  useEffect(() => {
    setLocalStorage(storageKeys['density'], density)
  }, [density])

  useEffect(() => {
    if (columnOrder.length === 0) {
      setColumnOrder(defaultColumnOrder)
    }
  }, [columns])

  return (
    <Paper variant="elevation" {...otherProps} {...ComponentProps}>
      <WrappedTable
        columns={columns}
        density={density}
        columnSorting={columnSorting}
        columnOrder={columnOrder}
        columnSizing={columnSizing}
        columnPinning={columnPinning}
        columnVisibility={columnVisibility}
        onDensityChange={setDensity}
        onColumnOrderChange={setColumnOrder}
        onColumnSizingChange={setColumnSizing}
        onColumnPinningChange={setColumnPinning}
        onColumnVisibilityChange={setColumnVisibility}
        onSortingChange={setColumnSorting}
      />
    </Paper>
  )
}
export default TableWrapper

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import AddIcon from '@mui/icons-material/Add'
import { getPermittedMenuItems } from 'src/components/TopNavigation/TopNavigation.utils'
import { StyledCreateIconButton } from 'src/components/TopNavigation/TopNavigation.styles'
import {
  popupProps,
  createMenuLinkItems,
} from 'src/components/TopNavigation/TopNavigation.constants'
import TopNavigationCreateDropdownItem from './TopNavigationCreateDropdownItem'

const TopNavigationCreateDropdown = ({ visibilityTrial, isMobile }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const { t } = useTranslation()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'create-popover' : undefined

  const filteredItems = getPermittedMenuItems(createMenuLinkItems(t)).filter(
    (item) => {
      if (visibilityTrial) {
        return true
      }
      return !item.visibilityOnly
    }
  )

  if (filteredItems.length === 0) {
    return null
  }

  return (
    <Box>
      {!isMobile && (
        <Button
          color="info"
          size="large"
          sx={{ ml: 2 }}
          variant="contained"
          startIcon={<AddIcon />}
          aria-describedby={id}
          onClick={handleClick}
          data-testid="top-nav-create-dropdown-trigger"
        >
          {t('top_navigation.create_button', 'Create')}
        </Button>
      )}
      {isMobile && (
        <StyledCreateIconButton
          aria-describedby={id}
          onClick={handleClick}
          className={open ? 'open' : ''}
        >
          <AddIcon sx={{ color: 'common.white' }} />
        </StyledCreateIconButton>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        {...popupProps}
      >
        <Stack width={320} p={1.5} spacing={1.5}>
          {filteredItems.map((item) => (
            <TopNavigationCreateDropdownItem
              key={item.key}
              item={item}
              onClick={handleClose}
            />
          ))}
        </Stack>
      </Popover>
    </Box>
  )
}

export default TopNavigationCreateDropdown

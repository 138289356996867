import ShyppleCompletionPoint, {
  CompletionPointProps,
} from '../../src/components/Common/CompletionPoint'

/**
 * Component for displaying the status of an action.
 */
export const CompletionPoint: React.FC<CompletionPointProps> = (props) => (
  <ShyppleCompletionPoint {...props} />
)

import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import PersonalDetail from 'src/components/PersonalDetail'
import { routes } from 'src/pages/AddressBook'

const Contacts = ({ contacts = [] }: { contacts: IPersonalDetail[] }) => {
  const history = useHistory()
  if (contacts.length === 0) return null
  return (
    <Box mt={2}>
      {contacts.map((personalDetail: IPersonalDetail) => (
        <PersonalDetail
          key={`personal-${personalDetail.id}`}
          personalDetail={personalDetail}
          toEditing={(id) =>
            history.push(routes.personsEdit.replace(':id', id.toString()))
          }
        />
      ))}
    </Box>
  )
}

export default Contacts

import { FunctionComponent, useState } from 'react'
import { keys, has, omit, pickBy, xor } from 'lodash'
import { Button, Box } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import { withStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import Input from 'src/components/Common/Input'
import { FormDatePicker } from 'src/components/Filters/FormElements/FormDatePicker'
import FormLabel from 'src/stories/Lab/FormLabel'

import { IPurchaseFilters } from '../../../pages/PurchaseOrders'

import './styles.scss'

const statusOptions = (t) => [
  [
    t('purchase_orders.filters.product_status.options.to_plan', '1 - To plan'),
    'to_plan',
  ],
  [
    t('purchase_orders.filters.product_status.options.planned', '2 - Planned'),
    'planned',
  ],
  [
    t('purchase_orders.filters.product_status.options.shipped', '3 - Shipped'),
    'shipped',
  ],
  [
    t(
      'purchase_orders.filters.product_status.options.delivered',
      '4 - Delivered'
    ),
    'delivered',
  ],
]

interface IPurchaseOrdersFiltersProps {
  filtersValues: IPurchaseFilters
  filtersOptions: any
  setFilter: (data: any) => void
  resetFilter: (data: any) => void
  resetAllFilters: () => void
  busy: boolean
  isBuyer: boolean
}

const MoreFiltersButton = withStyles((theme) => ({
  startIcon: {
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.complex,
    }),
    transform: ({ open }: { open: boolean }) =>
      open ? 'rotate(180deg)' : 'rotate(0)',
  },
}))(Button)

const PurchaseOrdersFilters: FunctionComponent<IPurchaseOrdersFiltersProps> = (
  props
) => {
  const [showAllFilters, setShowAllFilters] = useState<boolean>(false)
  const { t } = useTranslation()
  const resetAllFilters = (): void => {
    if (!!activeFiltersCounter()) {
      props.resetAllFilters()
    }
  }

  const switchAllFilters = (): void => {
    setShowAllFilters(!showAllFilters)
  }

  const pickFilterOption = (
    optionId: string,
    fieldName: string | undefined
  ): void => {
    if (!fieldName) return
    const values = props.filtersValues[fieldName]
    props.setFilter({ [fieldName]: xor(values, [optionId]) })
  }

  const resetFiltersOfType = (filterType: string): void => {
    props.resetFilter({
      [filterType]: [],
    })
  }

  const changeSearch = (value: string): void => {
    props.setFilter({ search: value })
  }

  const activeFiltersCounter = (): number => {
    let filters = omit(props.filtersValues, [
      'order_by',
      'scope',
      'open_item',
      'page',
      'purchase_orders_per_page',
      !props.filtersValues.pol.length ? 'pol' : '',
      !props.filtersValues.seller.length ? 'seller' : '',
      !props.filtersValues.exceptions.length ? 'exceptions' : '',
      !props.filtersValues.statuses.length ? 'statuses' : '',
    ])
    let counter: number = keys(pickBy(filters)).length

    if (
      has(pickBy(props.filtersValues), 'ex_factory_date_start') &&
      has(pickBy(props.filtersValues), 'ex_factory_date_end')
    ) {
      counter -= 1
    }

    if (
      has(pickBy(props.filtersValues), 'order_date_start') &&
      has(pickBy(props.filtersValues), 'order_date_end')
    ) {
      counter -= 1
    }

    return counter
  }

  const calculateFilters: number = activeFiltersCounter()

  return (
    <Box display="flex" mb={2} alignItems="center">
      <Box p={2}>
        <Box
          height={showAllFilters ? 'auto' : 64}
          overflow={showAllFilters ? 'auto' : 'hidden'}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <Box mr={2} mb={1}>
            <FormLabel
              label={t(
                'purchase_orders.filters.search_input.label',
                'Search in orders'
              )}
            />
            <Input.SearchBar
              data-testid="po-search-input"
              theme="shipmentsPage"
              value={props.filtersValues.search}
              onChange={changeSearch}
              text={t(
                'purchase_orders.filters.search_input.placeholder',
                'Search in orders'
              )}
              disabled={props.busy}
            />
          </Box>
          <Box mr={2} mb={1}>
            <FormLabel
              label={t('purchase_orders.filters.pol.label', 'From (POL)')}
            />
            <Input.OptionsPicker
              theme="shipmentsPage"
              options={props.filtersOptions ? props.filtersOptions.pols : []}
              title={t('purchase_orders.filters.pol.label', 'From (POL)')}
              searchable={true}
              selectedTitle={t(
                'purchase_orders.filters.pol.text_when_selected',
                'POL'
              )}
              fieldName="pol"
              onChange={pickFilterOption}
              onCancel={resetFiltersOfType}
              values={props.filtersValues.pol}
              size="short"
              disabled={props.busy}
            />
          </Box>
          {props.isBuyer && (
            <Box mr={2} mb={1}>
              <>
                <FormLabel
                  label={t('purchase_orders.filters.seller.label', 'Shipper')}
                />
                <Input.OptionsPicker
                  theme="shipmentsPage"
                  options={
                    props.filtersOptions ? props.filtersOptions.sellers : []
                  }
                  title={t('purchase_orders.filters.seller.label', 'Shipper')}
                  searchable={true}
                  selectedTitle={t(
                    'purchase_orders.filters.seller.text_when_selected',
                    'Shippers'
                  )}
                  fieldName="seller"
                  onChange={pickFilterOption}
                  onCancel={resetFiltersOfType}
                  values={props.filtersValues.seller}
                  size="long"
                  disabled={props.busy}
                />
              </>
            </Box>
          )}
          <Box mr={2} mb={1}>
            <FormLabel
              label={t(
                'purchase_orders.filters.exceptions.label',
                'Exceptions'
              )}
            />
            <Input.OptionsPicker
              theme="shipmentsPage"
              options={
                props.filtersOptions ? props.filtersOptions.exceptions : []
              }
              title={t(
                'purchase_orders.filters.exceptions.label',
                'Exceptions'
              )}
              selectedTitle="Exceptions"
              searchable={false}
              emptySearchMessage={t(
                'purchase_orders.filters.exceptions.empty_text',
                'There are no orders with exceptions'
              )}
              fieldName="exceptions"
              onChange={pickFilterOption}
              onCancel={resetFiltersOfType}
              values={props.filtersValues.exceptions}
              size="x-long"
              disabled={props.busy}
            />
          </Box>
          <Box mr={2} mb={1}>
            <FormLabel
              label={t(
                'purchase_orders.filters.product_status.label',
                'Product statuses'
              )}
            />
            <Input.OptionsPicker
              theme="shipmentsPage"
              options={statusOptions(t)}
              title={t(
                'purchase_orders.filters.product_status.label',
                'Product statuses'
              )}
              selectedTitle={t(
                'purchase_orders.filters.product_status.text_when_selected',
                'Product statuses'
              )}
              searchable={false}
              fieldName="statuses"
              onChange={pickFilterOption}
              onCancel={resetFiltersOfType}
              values={props.filtersValues.statuses}
              size="x-long"
              disabled={props.busy}
            />
          </Box>
          <Box mr={2} mb={1}>
            <FormDatePicker
              label={t(
                'purchase_orders.filters.order_date.label',
                'Order date'
              )}
              onChange={props.setFilter}
              name={['order_date_start', 'order_date_end']}
              qs={props.filtersValues}
              disabled={props.busy}
            />
          </Box>
          <Box mr={2} mb={1}>
            <FormDatePicker
              label={t(
                'purchase_orders.filters.ex_factory_date.label',
                'Ex factory'
              )}
              onChange={props.setFilter}
              name={['ex_factory_date_start', 'ex_factory_date_end']}
              qs={props.filtersValues}
              disabled={props.busy}
            />
          </Box>
          <Box mr={2} mb={1}>
            <FormDatePicker
              label={t(
                'purchase_orders.filters.cargo_ready_date.label',
                'Cargo ready'
              )}
              onChange={props.setFilter}
              name={['cargo_ready_date_start', 'cargo_ready_date_end']}
              qs={props.filtersValues}
              disabled={props.busy}
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" minWidth={290}>
        <Button
          variant="text"
          onClick={resetAllFilters}
          disabled={calculateFilters === 0}
          startIcon={<CloseRoundedIcon />}
        >
          {t('purchase_orders.filters.clear_button', 'Clear filters', {
            count: calculateFilters,
          })}
        </Button>
        <MoreFiltersButton
          variant="text"
          onClick={switchAllFilters}
          startIcon={<KeyboardArrowDownRoundedIcon />}
          open={showAllFilters}
        >
          {showAllFilters
            ? t('purchase_orders.filters.hide_button.hide', 'Less filters')
            : t('purchase_orders.filters.hide_button.show', 'More filters')}
        </MoreFiltersButton>
      </Box>
    </Box>
  )
}

export default PurchaseOrdersFilters

import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { permissionTo } from '../../../utils'
import ShipmentPortPickupAndDeliveryTitle from '../ShipmentPortPickupAndDeliveryTitle'

interface IShipmentPortPickupProps {
  pickupEnabled: boolean
  addressName: string | null
  progressPercent: number
  visibilityOnly: boolean
  showServicesWindow: () => void
}

const ShipmentPortPickup: FunctionComponent<IShipmentPortPickupProps> = (
  props
) => {
  const { t } = useTranslation()
  let completedStyle: string = props.pickupEnabled
    ? ''
    : 'shipment__port-node_disabled'

  if (props.progressPercent >= 5) {
    completedStyle = 'shipment__port-node_completed'
  }

  return (
    <div className="shipment__port">
      <i
        className={`
         shipment__port-node
         shipment__port-node_start
         shipment__port-node_mark
         ${completedStyle}
        `}
      />
      {permissionTo('shipments.containers_|_cargo.pickup.view') && (
        <ShipmentPortPickupAndDeliveryTitle
          serviceName={t('common.services.pickup', 'Pickup')}
          address={props.addressName}
          onClick={props.showServicesWindow}
          serviceRequested={props.pickupEnabled}
        />
      )}
    </div>
  )
}

export default ShipmentPortPickup

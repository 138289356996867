import ShipmentTemplateForm from 'src/components/Templates/Form'
import { useTranslation } from 'react-i18next'
import { usePageDataFetched } from '../Api'
import TemplatesLayout from '../Layout'

const TemplateCreate = () => {
  const pageDataFetched = usePageDataFetched()
  const { t } = useTranslation()

  return (
    <TemplatesLayout
      content={
        <ShipmentTemplateForm
          templateData={null}
          isLoading={!pageDataFetched}
          mode="create"
        />
      }
      currentPage={t('templates.heading')}
    />
  )
}

export default TemplateCreate

import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import {
  IDemurrageAndDetentionColumn,
  IDemurrageAndDetentionSection,
  IDemurrageAndDetentionRow,
} from 'src/@types/endpoints/demurrageAndDetention'
import { sortedDemurrageAndDetentionServiceItems } from './DemurrageAndDetentionContent.utils'
import {
  StyledBox,
  StyledTableRow,
} from './DemurrageAndDetentionContent.styles'

const DemurrageAndDetentionContentRatesTable: React.FC<{
  section: IDemurrageAndDetentionSection
  columns: IDemurrageAndDetentionColumn[]
}> = ({ section, columns }) => {
  const sectionItemKeys = section.items.map((item) => Object.keys(item))
  const uniqueSectionItemKeys = [...new Set(sectionItemKeys.flat())]
  const filteredColumns: IDemurrageAndDetentionColumn[] = columns.reduce(
    (
      filtered: IDemurrageAndDetentionColumn[],
      column: IDemurrageAndDetentionColumn
    ) => {
      if (uniqueSectionItemKeys.includes(column.value)) {
        filtered.push(column)
      }
      return sortedDemurrageAndDetentionServiceItems(filtered, 'label')
    },
    []
  )

  return (
    <TableContainer component={StyledBox}>
      <Table
        aria-label="Demurrage and detention rates table"
        data-testid="demurrage-and-detention-table"
      >
        <TableHead>
          <TableRow>
            {filteredColumns.map((column: IDemurrageAndDetentionColumn) => (
              <TableCell key={column.value} children={column.label} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {section.items.map(
            (row: IDemurrageAndDetentionRow, rowIndex: number) => (
              <StyledTableRow key={rowIndex}>
                {filteredColumns.map((column: IDemurrageAndDetentionColumn) => (
                  <TableCell
                    key={column.value}
                    children={row[column.value] ?? '-'}
                    sx={{ bgcolor: 'common.white' }}
                  />
                ))}
              </StyledTableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DemurrageAndDetentionContentRatesTable

import { useState } from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { TemplateListSortOptionProps } from './props'

interface TemplatesListSortProps {
  onChange: (sort: TemplateListSortOptionProps) => void
  selectedOption: TemplateListSortOptionProps
  options: TemplateListSortOptionProps[]
}

const TemplatesListSort: React.FC<TemplatesListSortProps> = ({
  onChange,
  options,
  selectedOption,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (option: TemplateListSortOptionProps) => {
    handleClose()
    onChange(option)
  }

  return (
    <>
      <Button
        size="large"
        aria-haspopup="true"
        onClick={handleClick}
        id="templates-sort-button"
        aria-expanded={open ? 'true' : undefined}
        aria-controls={open ? 'templates-sort-menu' : undefined}
        endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      >
        {selectedOption.label}
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        id="templates-sort-menu"
        MenuListProps={{
          'aria-labelledby': 'templates-sort-button',
        }}
      >
        {options.map((option) => (
          <MenuItem
            sx={{ height: 40 }}
            onClick={() => {
              handleChange(option)
            }}
            selected={selectedOption.value === option.value}
            key={option.value}
          >
            {option.icon}
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default TemplatesListSort

import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/documents'
import * as actionCreators from '../../actionCreators/documents'
import { simpleGetAndResolve } from '../factories'

const getDocumentsManagerData = simpleGetAndResolve(
  () => 'api/v1/documents',
  actionCreators.getDocumentsDataSuccess,
  (response) => response.data
)

const getDocumentsFiltersData = simpleGetAndResolve(
  () => 'api/v1/documents/filters_data',
  actionCreators.getDocumentsFiltersDataSuccess,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.DOCUMENTS_GET_DATA, getDocumentsManagerData)
  yield takeEvery(actions.DOCUMENTS_FILTERS_GET_DATA, getDocumentsFiltersData)
}

import Box from '@mui/material/Box'
import emptyImageUrl from 'src/images/empty-container.png'

const EmptyResults = ({ children }: { children: React.ReactNode }) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    sx={{ backgroundColor: 'common.white' }}
    p={4}
  >
    <img height="200px" src={emptyImageUrl} alt="No Results" />
    <Box
      m={2}
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      {children}
    </Box>
  </Box>
)

export default EmptyResults

import { AnyAction } from 'redux'
import * as actions from '../actions/shipmentQuotations'

export const getShipmentQuotations = (payload: {
  shipment: { id: number }
}): AnyAction => ({
  type: actions.REQUESTED_SHIPMENT_QUOTATION,
  payload,
})

export const shipmentQuotationsReceived = (
  payload: IShipmentQuotation[]
): AnyAction => ({
  type: actions.RECEIVED_SHIPMENT_QUOTATION,
  payload,
})

export const bookShipmentQuotation = (payload: {
  quotation: { id: number }
}): AnyAction => ({
  type: actions.BOOK_SHIPMENT_QUOTATION,
  payload,
})

export const shipmentQuotationBooked = (payload: any): AnyAction => ({
  type: actions.BOOKED_SHIPMENT_QUOTATION,
  payload,
})

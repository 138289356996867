import { FunctionComponent, useRef } from 'react'
import { Typography } from '@mui/material'
import InlineNotification from 'src/components/Common/InlineNotification'
import { useTranslation } from 'react-i18next'

interface IProps {
  files: File[]
  setFiles: (files: File[]) => void
  showError: boolean
  removeFile: (index: number) => void
  configuredPOUpload: boolean
}

const UploadFilesBlock: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const uploadButtonRef = useRef<any>(null)

  const onChangeUploadFile = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const filesObj: FileList | null = event.target.files

    if (!filesObj) {
      return
    }

    if (filesObj != null && filesObj.length > 0) {
      const files: File[] = [] || props.files
      let i: number = 0
      while (i < filesObj.length) {
        files.push(filesObj[i])
        i++
      }
      props.setFiles(files)
    }

    uploadButtonRef.current.value = null
  }

  const addNewFile = () => {
    uploadButtonRef.current.click()
  }

  const addDocumentsBlock = () => {
    return (
      <div
        className="purchase-orders-window--add-docs-button"
        onClick={addNewFile}
      >
        <div className="icon-area">
          <i className="icon close" />
        </div>
        <div>
          <div className="add-docs-info">
            {t(
              'purchase_orders.modals.purchase_modal.upload_files_block.description',
              'Drag your PDF files here or select a file'
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="purchase-orders-window--upload-files-block">
      <InlineNotification
        color={'error'}
        show={props.showError}
        showClose={true}
        message={t(
          'purchase_orders.modals.purchase_modal.upload_files_block.wrong_format_notification',
          'It seems that you did not upload a .pdf format. Please try again.'
        )}
      />
      {!props.configuredPOUpload && (
        <div className="purchase-orders-window--blank-block">
          <div className="purchase-orders-window--blank">
            <div className="purchase-orders-window--blank--info_block">
              <div className="purchase-orders-window--blank--info_block--item">
                <div className="circle-icon">
                  <i className="icon file" />
                </div>
              </div>
              <div className="purchase-orders-window--blank--arrow">
                <i className="icon chevron" />
              </div>
              <div className="purchase-orders-window--blank--info_block--item">
                <div className="circle-icon second">
                  <i className="icon loading" />
                </div>
              </div>
            </div>
          </div>
          {props.files && props.files.length < 3 && (
            <Typography variant="body2">
              {t(
                'purchase_orders.modals.purchase_modal.upload_files_block.more_orders_needed_message',
                'You are almost ready to process your orders in the Shypple application. We need 3/5 orders to configure the order tool for you.'
              )}
            </Typography>
          )}
        </div>
      )}
      {!props.files.length && addDocumentsBlock()}
      {!!props.files.length && (
        <div className="files-list">
          {props.files.map((file, index) => {
            return (
              <div className="file-row">
                <div className="file-name">{file.name}</div>
                <div>
                  <i
                    className="icon trash"
                    onClick={props.removeFile.bind(null, index)}
                  />
                </div>
              </div>
            )
          })}
          <div className="file-info">
            {t(
              'purchase_orders.modals.purchase_modal.upload_files_block.files_added',
              '{{count}} files added',
              { count: props.files.length }
            )}
          </div>
        </div>
      )}
      <input
        ref={uploadButtonRef}
        type="file"
        id="selectedFile"
        style={{
          display: 'none',
        }}
        onChange={onChangeUploadFile}
        multiple={true}
      />
    </div>
  )
}

export default UploadFilesBlock

import { createReducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import * as actions from '../actions/shipmentPurchaseOrders'

declare global {
  interface IShipmentPurchaseOrdersState {
    list: IPurchaseOrder[]
    totalVolumeCbm: number
    totalWeightKg: number
  }
}
export const initialShipmentPurchaseOrdersState = {
  list: [],
}

export default createReducer(initialShipmentPurchaseOrdersState, {
  [actions.SHIPMENT_PURCHASE_ORDERS_GET_DATA_SUCCESS]: (
    state,
    action: AnyAction
  ) => ({
    ...state,
    list: action.payload.purchase_orders,
    totalVolumeCbm: action.payload.total_volume_cbm,
    totalWeightKg: action.payload.total_weight_kg,
  }),
})

import { parse } from 'query-string'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import DashboardIcon from '@mui/icons-material/Dashboard'
import FilterListIcon from '@mui/icons-material/FilterList'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import useLocalStorage from 'src/hooks/useLocalStorage'
import WatchShipmentsToggler from 'src/components/Common/WatchShipmentsToggler'
import {
  pickupsAndDeliveriesGetData,
  pickupsAndDeliveriesSetDrawer,
  pickupsAndDeliveriesUpdateFilters,
} from 'src/stores/actionCreators'
import { updateUrl } from 'src/components/Filters/utils'

const PickupsAndDeliveriesHeaderActions = ({ onFiltersVisibilityToggle }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { filters } = useSelector(
    (state: IGlobalState) => ({
      filters: state.pickUpsAndDeliveries.filters,
    }),
    shallowEqual
  )

  const [showFilters, setShowFilters] = useLocalStorage(
    'showContainersFilters',
    false
  )

  const handleClickOnCustomize = () => {
    dispatch(pickupsAndDeliveriesSetDrawer({ type: 'customize', open: true }))
  }

  const handleWatchedShipmentsChange = (newValue) => {
    const newFilters = { my_shipments: newValue }
    dispatch(pickupsAndDeliveriesGetData({ ...filters, ...newFilters }))
    localStorage.setItem('containersMyShipments', JSON.stringify(newValue))
    dispatch(pickupsAndDeliveriesUpdateFilters({ ...filters, ...newFilters }))
    updateUrl('inland_transports', { ...filters, ...newFilters })
  }

  const handleFilterVisibilityChange = () => {
    onFiltersVisibilityToggle()
    setShowFilters(!showFilters)
  }

  const locationParams = parse(location.search, {
    arrayFormat: 'bracket',
    parseBooleans: true,
  })

  const watchMyShipments = (locationParams?.my_shipments ?? false) as boolean
  const { t } = useTranslation()
  return (
    <Stack
      direction={{ xs: 'column', sm: 'row', md: 'row' }}
      spacing={1}
      mt={{ xs: 1, sm: 1, md: 0 }}
    >
      <WatchShipmentsToggler
        value={watchMyShipments}
        action={handleWatchedShipmentsChange}
      />
      <Stack direction="row">
        <Button
          variant={showFilters ? 'contained' : 'outlined'}
          onClick={handleFilterVisibilityChange}
          startIcon={<FilterListIcon />}
          sx={{ verticalAlign: 'text-bottom', mr: 1 }}
          data-testid="transport-page-filters-button"
        >
          {t('common.buttons.filter', 'Filter')}
        </Button>
        <Button
          size="large"
          variant="outlined"
          onClick={handleClickOnCustomize}
          startIcon={<DashboardIcon />}
          data-testid="transport-page-customize-button"
        >
          {t('common.customize', 'Customize')}
        </Button>
      </Stack>
    </Stack>
  )
}

export default PickupsAndDeliveriesHeaderActions

import set from 'lodash/set'
import get from 'lodash/get'
import { DateTime } from 'luxon'
import { InputLabel } from '@mui/material'
import DatePicker from 'src/stories/DatePicker'
import { useFormContext } from '../../FormContext'

const CargoReadyDate = (props) => {
  const { fieldPath, label, required } = props
  const { formState, onChange } = useFormContext()

  const value = get(formState, fieldPath)

  const handleChange = (date: DateTime | null) => {
    onChange(set(formState, fieldPath, date?.toISO() ?? null))
  }

  return (
    <>
      <InputLabel required={required}>{label}</InputLabel>
      <DatePicker
        clearable={false}
        value={value}
        onChange={handleChange}
        data-testid="shipment-template-cargo-ready-date"
      />
    </>
  )
}

export default CargoReadyDate

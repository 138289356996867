import {
  FunctionComponent,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { pick, reject, includes, clone, pullAt, concat } from 'lodash'
import { useTranslation, Trans } from 'react-i18next'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { LoadingButton } from '@mui/lab'
import { Button, Link } from '@mui/material'
import Modal from 'src/components/Common/Modal'
import TabPanel from 'src/components/Common/TabPanel'
import { promisifyAction, Logger } from '../../../utils/'
import {
  organizationHsCodesGet,
  addHsCode,
  shipmentContainersGetData,
} from '../../../stores/actionCreators'

import RelevantHsCodeLine from '../RelevantHsCodeLine'
import NewHsCodeLine from '../NewHsCodeLine'

import './styles.scss'

interface IProps {
  currentHsCodes: IContainerHsCode[]
  onClose: () => void
}

const tabClasses = {
  root: 'shipment-layout__tab hs-codes mui-override',
  wrapper: 'shipment-layout__tab-text-wrapper mui-override',
  selected: 'shipment-layout__tab_selected mui-override',
}

const initialHsCodeToSubmit: IHsCodeToSubmit = {
  hs_code_id: 0,
  notes: '',
}

const HsCodeAddModal: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const [tabValue, setTabValue] = useState<string>('recently_used')
  const [uploading, setUploading] = useState<boolean>(false)
  const [newHsCodes, setNewHsCodes] = useState<IHsCodeToSubmit[]>([
    clone(initialHsCodeToSubmit),
  ])
  const dispatch: Dispatch = useDispatch()

  const { relevantHsCodes, shipmentId, hsCodesModal } = useSelector(
    (state: IGlobalState) => ({
      relevantHsCodes: state.hsCodes.organizationHsCodes,
      hsCodesModal: state.hsCodes.hsCodesModal,
      shipmentId: state.shipmentOverview.id,
    })
  )

  const [selectedHsCodes, setSelectedHsCodes] = useState<number[]>([])
  const [containerHsCodes, setContainerHsCodes] = useState<IContainerHsCode[]>(
    []
  )

  useEffect(() => {
    const usedGoodsCodes = props.currentHsCodes.map((code) => code.goods_code)
    const uniqueRelevantCodes = reject(relevantHsCodes, (x) =>
      includes(usedGoodsCodes, x.goods_code)
    )
    setContainerHsCodes(
      uniqueRelevantCodes.map((code: IHsCode) => ({
        ...code,
        notes: '',
      }))
    )
  }, [relevantHsCodes, props.currentHsCodes])

  const getRelevantHSCodesAsync = promisifyAction(
    dispatch,
    organizationHsCodesGet
  )

  const createHsCode = promisifyAction(dispatch, addHsCode)
  const refreshContainerInformation = promisifyAction(
    dispatch,
    shipmentContainersGetData
  )

  useEffect(() => {
    getRelevantHSCodesAsync()
    if (hsCodesModal.open) {
      setTabValue('recently_used')
      setSelectedHsCodes([])
      setNewHsCodes([clone(initialHsCodeToSubmit)])
    }
  }, [hsCodesModal.open])

  const onTabsChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: string) => {
      setTabValue(newValue)
    },
    []
  )

  const toShowTabs: boolean = useMemo(() => !!containerHsCodes.length, [
    containerHsCodes,
  ])

  const onSaveAndClose = useCallback(async () => {
    const newCodesToSubmit = newHsCodes.filter(
      (code: IHsCodeToSubmit) => !!code.hs_code_id
    )

    const relevantCodesToSubmit = containerHsCodes
      .filter((code) => selectedHsCodes.includes(code.id as number))
      .map((code) => {
        code.hs_code_id = code.id
        return pick(code, ['hs_code_id', 'notes']) as IHsCodeToSubmit
      })
    const dataToSubmit = concat(relevantCodesToSubmit, newCodesToSubmit)

    setUploading(true)
    for (const item of dataToSubmit) {
      try {
        await createHsCode(hsCodesModal.containerId, item)
      } catch (error) {
        Logger.log(error)
      }
    }
    await refreshContainerInformation(shipmentId)
    setUploading(false)
    props.onClose()
  }, [containerHsCodes, selectedHsCodes, newHsCodes])

  // Relevant HS codes callbacks

  const onNotesEdited = useCallback(
    (id, notesValue) => {
      setContainerHsCodes(
        containerHsCodes.map((code) => {
          if (code.id === id) {
            code.notes = notesValue
          }
          return code
        })
      )
    },
    [containerHsCodes]
  )

  const onToggleSelected = useCallback(
    (id: number, isSelected: boolean) => {
      if (isSelected) {
        setSelectedHsCodes((oldArray) => [...oldArray, id])
      } else {
        setSelectedHsCodes((oldArray) =>
          oldArray.filter((x: number) => x !== id)
        )
      }
    },
    [selectedHsCodes, setSelectedHsCodes]
  )

  // New HS codes callbacks

  const onChangeNewHsCode = useCallback(
    (index: number, updatedHsCode: IHsCodeToSubmit) => {
      setNewHsCodes((prevHsCodes) => {
        prevHsCodes[index] = updatedHsCode
        return prevHsCodes
      })
    },
    []
  )

  const addNewBlankHsCode = useCallback(() => {
    setNewHsCodes((prevHsCodes) => [
      ...prevHsCodes,
      clone(initialHsCodeToSubmit),
    ])
  }, [])

  const removeHsCode = useCallback((index: number) => {
    setNewHsCodes((prevHsCodes) => {
      pullAt(prevHsCodes, index)
      return clone(prevHsCodes)
    })
  }, [])

  // render helpers

  const modalTitle: React.ReactNode = (
    <span>
      <Trans
        i18nKey="shipment_containers.add_hs_code.title"
        defaults="Add HS codes for container <0>{{val}}</0>"
        values={{
          val: `${hsCodesModal.containerType} #${hsCodesModal.containerId}`,
        }}
        components={[<strong />]}
      />
    </span>
  )

  const newHsCodesContent = (): React.ReactNode => (
    <>
      {newHsCodes.map((code, index) => (
        <NewHsCodeLine
          key={index}
          newHsCode={code}
          onChange={onChangeNewHsCode}
          onRemove={removeHsCode}
          index={index}
        />
      ))}
      <Link variant="body1" component="button" onClick={addNewBlankHsCode}>
        <AddRoundedIcon />
        {t('common.buttons.add_hs_codes', 'Add HS codes')}
      </Link>
    </>
  )

  return (
    <Modal.Window open={hsCodesModal.open} onClose={props.onClose}>
      <Modal.Title children={modalTitle} onClose={props.onClose} />
      <Modal.Content>
        {toShowTabs && (
          <>
            <Trans
              i18nKey="shipment_containers.select_or_add_hs_codes"
              defaults="Please select an HS code that your organization has used before or <0>add new HS codes</0> to your collection."
              components={[<strong />]}
            />
            <Tabs
              value={tabValue}
              classes={{
                root:
                  'shipment-layout__tab-panel docs-panel add-hs-code-modal mui-override',
                indicator: 'shipment-layout__tab-indicator mui-override',
                flexContainer: 'shipment-layout__tab-container mui-override',
              }}
              onChange={onTabsChange}
            >
              <Tab
                label={t(
                  'shipment_containers.hs_code_tabs.old',
                  'Previously used HS codes'
                )}
                value="recently_used"
                classes={tabClasses}
              />
              <Tab
                label={t('shipment_containers.hs_code_tabs.new', 'New HS code')}
                value="new_code"
                classes={tabClasses}
              />
            </Tabs>
            <TabPanel value={tabValue} index="recently_used">
              {containerHsCodes.map((code) => (
                <RelevantHsCodeLine
                  key={code.goods_code}
                  hsCode={code}
                  onNotesEdited={onNotesEdited}
                  onToggleSelected={onToggleSelected}
                  selected={selectedHsCodes.includes(code.id as number)}
                />
              ))}
            </TabPanel>
            <TabPanel value={tabValue} index="new_code">
              {newHsCodesContent()}
            </TabPanel>
          </>
        )}
        {!toShowTabs && newHsCodesContent()}
      </Modal.Content>
      <Modal.Actions>
        <Button variant="outlined" onClick={props.onClose}>
          {t('common.buttons.cancel', 'Cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={onSaveAndClose}
          loading={uploading}
        >
          {t('common.buttons.save', 'Save')}
        </LoadingButton>
      </Modal.Actions>
    </Modal.Window>
  )
}

export default HsCodeAddModal

import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import SelectableChip, {
  OptionsItem,
  SelectableChipOptions,
} from 'src/components/SelectableChip'
import Box from '@mui/material/Box'
import { promisifyAction } from 'src/utils'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { updatePickupsAndDeliveriesShipmentStatus } from 'src/stores/actionCreators'
import { shipmentChipColor } from '../constants'

const InlandTransportRowShipmentStatus: React.FC<{
  updatable: boolean
  status: string
  phase: string | null
  shipmentId: number
  shipmentStatuses: SelectableChipOptions
}> = ({ updatable, status, phase, shipmentId, shipmentStatuses }) => {
  const [currentStatus, setCurrentStatus] = useState({ name: status, phase })
  const [updating, setUpdating] = useState(false)

  const dispatch = useDispatch()

  const updateShipmentStatusAsync = promisifyAction(
    dispatch,
    updatePickupsAndDeliveriesShipmentStatus
  )

  const handleStatusUpdate = (newValue: OptionsItem) => {
    if (!updatable) {
      return
    }

    const tempStatus = currentStatus

    setCurrentStatus({
      name: newValue?.name ?? '',
      phase: newValue?.phase?.code ?? null,
    })

    setUpdating(true)

    updateShipmentStatusAsync(shipmentId, {
      status_id: newValue.id,
    })
      .then(() => {
        dispatch(
          showNotification({
            severity: 'success',
            message: 'Shipment status updated',
          })
        )
      })
      .catch(() => {
        setCurrentStatus(tempStatus)
      })
      .finally(() => {
        setUpdating(false)
      })
  }

  useEffect(() => {
    setCurrentStatus({ name: status, phase })
  }, [status])

  return (
    <Box data-testid="inland-transport-shipment-status">
      <SelectableChip
        variant="filled"
        loading={updating}
        disabled={!updatable}
        options={shipmentStatuses}
        onChange={handleStatusUpdate}
        value={{
          name: currentStatus.name,
          color: shipmentChipColor[currentStatus?.phase ?? 'initial'],
        }}
      />
    </Box>
  )
}

export default InlandTransportRowShipmentStatus

import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/shipmentOverview'
import * as actionCreators from '../../actionCreators/shipmentOverview'
import {
  simpleGetAndResolve,
  simplePutAndResolve,
  simplePatchAndResolve,
  simplePostAndResolve,
} from '../factories'
import { shipmentOverviewSerializer } from '../../../utils/serializers'

const getShipment = simpleGetAndResolve(
  (action) => `/api/v1/shipments/${action.id}/overview`,
  actionCreators.shipmentOverviewGetDataSuccess,
  shipmentOverviewSerializer
)

const getShipmentCarrierBooking = simpleGetAndResolve(
  (action) => `/api/v1/shipments/${action.id}/carrier_booking`,
  actionCreators.carrierBookingGetDataSuccess,
  (response) => response.data
)

const createShipmentCarrierBooking = simplePostAndResolve(
  (action) => `/api/v1/shipments/${action.id}/carrier_booking`,
  actionCreators.carrierBookingCreateDataSuccess,
  (response) => response.data
)

const requestShipmentCarrierBooking = simplePostAndResolve(
  (action) =>
    `/api/v1/shipments/${action.id}/carrier_booking/${action.payload.id}/request_booking`,
  actionCreators.carrierBookingRequestDataSuccess,
  (response) => response.data
)

const updateShipmentCarrierBooking = simplePutAndResolve(
  (action) =>
    `/api/v1/shipments/${action.shipmentId}/carrier_booking/${action.bookingId}`,
  actionCreators.carrierBookingGetDataSuccess,
  (response) => response.data
)

const updateShipmentReference = simplePutAndResolve(
  (action) => `/api/v1/shipments/${action.id}/update_reference`,
  actionCreators.updateShipmentReferenceSuccess,
  shipmentOverviewSerializer
)

const shipmentOverviewReQuote = simplePatchAndResolve(
  (action) => `/api/v1/shipments/${action.id}/requote`,
  actionCreators.shipmentOverviewGetDataSuccess,
  shipmentOverviewSerializer
)

const shipmentOverviewUnpinMessage = simplePatchAndResolve(
  (action) =>
    `/api/v1/chats/${action.chatId}/chat_comments/${action.messageId}/toggle_pin`,
  actionCreators.shipmentOverviewUnpinMessageSuccess,
  (response) => response.data
)

const toggleFlag = simplePatchAndResolve(
  (action) => `/api/v1/shipments/${action.id}/toggle_flag`,
  actionCreators.toggleFlagSuccess,
  (response) => response.data
)

const toggleWatch = simplePatchAndResolve(
  (action) => `/api/v1/shipments/${action.id}/toggle_watcher`,
  actionCreators.toggleWatchSuccess,
  (response) => response.data
)

const getShipmentsOverviewPdfFiles = simpleGetAndResolve(
  (action) => `/api/v1/shipments/${action.id}/shipment_overview_pdf_files`,
  actionCreators.shipmentsOverviewGetPdfFilesSuccess,
  (response) => response.data,
  'blob'
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.CARRIER_BOOKING_GET_DATA, getShipmentCarrierBooking)
  yield takeEvery(
    actions.CARRIER_BOOKING_CREATE_DATA,
    createShipmentCarrierBooking
  )
  yield takeEvery(
    actions.CARRIER_BOOKING_REQUEST_DATA,
    requestShipmentCarrierBooking
  )
  yield takeEvery(
    actions.CARRIER_BOOKING_UPDATE_DATA,
    updateShipmentCarrierBooking
  )
  yield takeEvery(actions.SHIPMENT_OVERVIEW_GET_DATA, getShipment)
  yield takeEvery(actions.UPDATE_SHIPMENT_REFERENCE, updateShipmentReference)
  yield takeEvery(
    actions.SHIPMENT_OVERVIEW_UNPIN_MESSAGE,
    shipmentOverviewUnpinMessage
  )
  yield takeEvery(actions.SHIPMENTS_TOGGLE_FLAG, toggleFlag)
  yield takeEvery(actions.SHIPMENTS_TOGGLE_WATCH, toggleWatch)
  yield takeEvery(actions.SHIPMENT_RE_QUOTE, shipmentOverviewReQuote)
  yield takeEvery(
    actions.SHIPMENTS_OVERVIEW_GET_PDF_FILES,
    getShipmentsOverviewPdfFiles
  )
}

export const getFormattedPrice = (
  price: number,
  currency: string,
  language: string = 'en-US'
) =>
  Intl.NumberFormat(language, {
    style: 'currency',
    currency,
  })
    .format(price)
    .replace(/([.,])00$/g, '')

import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/tasks'
import * as actionCreators from '../../actionCreators/tasks'
import { simpleGetAndResolve } from '../factories'

const getYourTasks = simpleGetAndResolve(
  (action) => 'api/v1/tasks/your_open_tasks',
  actionCreators.getYourOpenTasksSuccess,
  (response) => response.data
)

const getAllTasks = simpleGetAndResolve(
  (action) => 'api/v1/tasks/all_open_tasks',
  actionCreators.getAllOpenTasksSuccess,
  (response) => response.data
)

const getTasksStatusTypes = simpleGetAndResolve(
  (action) => `api/v1/common/task_status_types`,
  actionCreators.getTasksStatusTypesSuccess,
  (response) => response.data
)

const getShipmentTasks = simpleGetAndResolve(
  (action) => `api/v1/shipments/${action.id}/tasks`,
  actionCreators.getShipmentTasksSuccess,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.GET_YOUR_OPEN_TASKS, getYourTasks)
  yield takeEvery(actions.GET_ALL_OPEN_TASKS, getAllTasks)
  yield takeEvery(actions.GET_TASKS_STATUS_TYPES, getTasksStatusTypes)
  yield takeEvery(actions.GET_SHIPMENT_TASKS, getShipmentTasks)
}

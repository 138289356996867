import set from 'lodash/set'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useFormContext } from '../../FormContext'

const DangerousGoods = (props) => {
  const { fieldPath, label } = props
  const { formState, onChange } = useFormContext()
  const { dangerousGoods } = formState.tags

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    onChange(set(formState, fieldPath, checked))
  }

  return (
    <>
      <FormGroup onChange={handleChange}>
        <FormControlLabel
          label={label}
          control={<Checkbox checked={dangerousGoods} />}
          data-testid="shipment-template-dangerous-goods"
        />
      </FormGroup>
    </>
  )
}

export default DangerousGoods

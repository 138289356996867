import { createBrowserHistory, Location } from 'history'
import { shyppleGa } from './utils'
import createStore from './stores'

export const history = createBrowserHistory()
export const store = createStore(history)

history.listen((location: Location): void => {
  const user: IUser = store.getState().user
  if (!user.id) {
    return
  }

  if (user.id !== shyppleGa.user.id) {
    shyppleGa.setUserData(user)
  }
  shyppleGa.pageview(location.pathname)
})

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import { getTransitTimes } from './SABSearchResultFilters.utils'

const SABSearchResultFiltersTransitTime = ({
  name,
  value,
  quotes,
  onChange,
}: {
  name: string
  value: string
  quotes: ISearchQuote[]
  onChange: (value: string, name: string) => void
}) => {
  const { t } = useTranslation()
  const transitTimes = useMemo(() => getTransitTimes(quotes), [quotes])

  if (!transitTimes.length) {
    return null
  }

  return (
    <div className="button-container--wrapper fixes-width">
      <SingleSelect
        value={value}
        name={name}
        mandatory={false}
        options={transitTimes}
        label={t(
          'search_and_book.results.filters.transit_time',
          'Max transit time'
        )}
        onChange={(event) => {
          onChange(event.target.value as string, name)
        }}
        onReset={() => onChange('', name)}
      />
    </div>
  )
}

export default SABSearchResultFiltersTransitTime

import { FunctionComponent } from 'react'

interface IProps {
  children?: React.ReactNode
  dir?: string
  index: string
  value: string
  className?: string
}

const TabPanel: FunctionComponent<IProps> = (props) => {
  const { children, value, index, ...other } = props
  const className: string = props.className || ''

  return (
    <div
      className={className}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  )
}

export default TabPanel

import { FunctionComponent } from 'react'
import GoogleRadio, { RadioProps } from '@mui/material/Radio'
import { withStyles } from '@mui/styles'

import './styles.scss'

interface IProps {
  checked: boolean
  onChange: (e: React.ChangeEvent, value: boolean) => void
  id: number | string
  value: boolean
  label: string
}

const BlueRadio = withStyles({
  root: {
    padding: 0,
    '&$checked': {
      color: 'var(--secondary-main)',
    },
  },
  checked: {},
})((props: RadioProps) => <GoogleRadio color="default" {...props} />)

const Radio: FunctionComponent<IProps> = (props) => {
  return (
    <BlueRadio
      checked={props.checked}
      onChange={props.onChange}
      id={`radio-${props.id}`}
      value={props.value}
      aria-label={props.label}
      className="shypple-radio"
      color="default"
      size="small"
    />
  )
}

export default Radio

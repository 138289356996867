import {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import { useDispatch } from 'react-redux'
import QuantityCounter from 'src/stories/QuantityCounter'
import { showNotification } from 'src/stores/actionCreators/notifications'
import {
  Box,
  Button,
  Checkbox,
  Typography,
  FormControlLabel,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { Dialog, DialogContent } from 'src/stories'
import { promisifyAction } from 'src/utils'
import { duplicateShipmentContainer } from 'src/stores/actionCreators'
import { containerTypeDescription, containerTypeIcon } from 'src/utils/helpers'
import { reeferIcons } from 'src/pages/SearchAndBook/reeferIcons'
import './styles.scss'
import { useTranslation } from 'react-i18next'

interface IProps {
  shipmentId: string | number
  shipmentType: string
  container: IShipmentContainer | null
  open: boolean
  close: (status: boolean) => void
  fetchData: () => void
  isLclOrAir?: boolean
  sharedReference?: string | null
}

interface IContainerDetails {
  id: string | number
  container_type: { code: string; name: string }
  container_event_exceptions: string[]
  temperature: string | number
  humidity: string | number
  ventilation: string | number
  vents_open: boolean | null
  drains_open: boolean | null
  co2_regulation: string
  shipment_id: string | number
  cargo: ICargo[]
  hs_codes: IContainerHsCode[]
  inland_transports: IInlandTransport[]
}

export const initialContainerDetails: IContainerDetails = {
  id: '',
  container_type: { code: '', name: '' },
  container_event_exceptions: [],
  temperature: '',
  humidity: '',
  ventilation: '',
  vents_open: null,
  drains_open: null,
  co2_regulation: '',
  shipment_id: '',
  cargo: [],
  hs_codes: [],
  inland_transports: [],
}

interface IContainerDuplicateDetails {
  number_of_containers: number
  cargo: number
  pickup_address: number
  delivery_address: number
  reefer_settings: number
  hs_codes: number
}

export const initialContainerDuplicateDetails: IContainerDuplicateDetails = {
  number_of_containers: 1,
  cargo: 0,
  pickup_address: 0,
  delivery_address: 0,
  reefer_settings: 0,
  hs_codes: 0,
}

const ContainerDuplicateWindow: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isRefeer, setIsRefeer] = useState<boolean>(false)
  const [loadingButton, setLoadingButton] = useState<boolean>(false)
  const [containerDetails, setContainerDetails] = useState<IContainerDetails>(
    initialContainerDetails
  )
  const [containerDuplicateDetails, setContainerDuplicateDetails] = useState<
    IContainerDuplicateDetails
  >(initialContainerDuplicateDetails)

  const duplicateContainerAsync = promisifyAction(
    dispatch,
    duplicateShipmentContainer
  )

  const setValuesFromProps = (props) => {
    setContainerDetails({
      id: props.container.id,
      container_type: props.container.container_type,
      container_event_exceptions: props.container.container_event_exceptions,
      temperature: props.container.temperature || '',
      humidity: props.container.humidity || '',
      ventilation: props.container.ventilation || '',
      vents_open: props.container.vents_open,
      drains_open: props.container.drains_open,
      co2_regulation: props.container.co2_regulation || '',
      cargo: props.container.cargo,
      hs_codes: props.container.hs_codes,
      inland_transports: props.container.inland_transports,
      shipment_id: props.container.shipmentId,
    })
    setIsRefeer(props.container.equipment_controlled_atmosphere)
  }

  useEffect(() => {
    setValuesFromProps(props)
  }, [props])

  const isExeptionType: string | undefined = useMemo(() => {
    if (containerDetails.container_type.code) {
      const names: string[] = Object.keys(reeferIcons)
      const name: string | undefined = names.find(
        (i: string) => i === containerDetails.container_type.code
      )
      return name
    }
    return undefined
  }, [containerDetails.container_type.code])

  const onCheckboxChange = (e, checked) => {
    setContainerDuplicateDetails({
      ...containerDuplicateDetails,
      [e.target.name]: checked ? 1 : 0,
    })
  }

  const onSelectAll = (_e, checked) => {
    if (checked) {
      setContainerDuplicateDetails({
        ...containerDuplicateDetails,
        cargo: 1,
        pickup_address: 1,
        delivery_address: 1,
        hs_codes: 1,
        reefer_settings: isRefeer ? 1 : 0,
      })
    } else {
      setContainerDuplicateDetails({
        ...containerDuplicateDetails,
        cargo: 0,
        pickup_address: 0,
        delivery_address: 0,
        hs_codes: 0,
        reefer_settings: 0,
      })
    }
  }

  const onCounterChange = (e) => {
    setContainerDuplicateDetails({
      ...containerDuplicateDetails,
      number_of_containers: e,
    })
  }

  const onSave = async (): Promise<any> => {
    setLoadingButton(true)
    await duplicateContainerAsync({
      shipment_id: props.shipmentId,
      container_id: containerDetails.id,
      payload: { duplicate_params: containerDuplicateDetails },
    })

    dispatch(
      showNotification({
        message: t(
          'shipment_containers.notifications.container_duplicated',
          'Container Duplicated'
        ),
        severity: 'success',
      })
    )
    setLoadingButton(false)
    props.close(true)
  }

  const onClose = useCallback((): void => {
    setLoadingButton(false)
    props.close(true)
  }, [props.close])

  const renderCheckbox = (name: string, label: string) => {
    return (
      <div className="duplicate-container--input">
        <FormControlLabel
          control={
            <Checkbox
              name={name}
              checked={containerDuplicateDetails[name]}
              onChange={onCheckboxChange}
            />
          }
          label={label}
        />
      </div>
    )
  }

  return (
    <Dialog
      maxWidth="lg"
      open={props.open}
      onClose={onClose}
      title={t(
        'shipment_containers.headings.container_duplications_settings',
        'Duplicate container settings'
      )}
      actions={
        <>
          <Button variant="text" onClick={onClose}>
            {t('common.buttons.cancel', 'Cancel')}
          </Button>
          <LoadingButton
            onClick={onSave}
            variant="contained"
            loading={loadingButton}
          >
            {t('common.buttons.save', 'Save')}
          </LoadingButton>
        </>
      }
    >
      <DialogContent>
        <div
          className="duplicate-container--wrapper"
          data-testid="container-duplicate-window"
        >
          <div className="duplicate-container--header">
            <Typography variant="body1">
              {t(
                'shipment_containers.headings.container_duplications_amount',
                'Amount of containers to duplicate'
              )}
            </Typography>
            <div className="duplicate-container--container-icon">
              {isExeptionType ? (
                reeferIcons[isExeptionType]
              ) : (
                <i
                  className={
                    'icon ' +
                    containerTypeIcon(
                      props.shipmentType,
                      containerDetails.container_type.code || null
                    )
                  }
                />
              )}
              {containerDetails.container_event_exceptions.length ? (
                <i className="icon attention" />
              ) : null}
              {!props.isLclOrAir && (
                <Typography variant="h3">
                  {containerTypeDescription(
                    props.shipmentType,
                    containerDetails.container_type.name || null
                  )}
                </Typography>
              )}
            </div>
            <Box sx={{ width: 140, mr: 2 }}>
              <QuantityCounter
                totalError={''}
                min="0"
                value={containerDuplicateDetails.number_of_containers}
                name={'number_of_containers'}
                onChange={onCounterChange}
              />
            </Box>
          </div>
          <div className="duplicate-container--container-details">
            <div className="duplicate-container--input">
              <FormControlLabel
                control={
                  <Checkbox name={'select_all'} onChange={onSelectAll} />
                }
                label={t('shipment_containers.select_all', 'SELECT ALL')}
              />

              <hr />
            </div>
            {containerDetails.cargo.length > 0 &&
              containerDetails.cargo[0].id != null && (
                <div className="duplicate-container--checkbox">
                  {renderCheckbox(
                    'cargo',
                    t('common.forms.fields.goods.label', 'Goods')
                  )}
                  <div
                    className="duplicate-container--description"
                    data-testid="dynamicvalues-goods"
                  >
                    {containerDetails.cargo.map((goods) => {
                      return (
                        <>
                          {t('shipment_containers.cargo_details_stackability', {
                            context: goods.stackable
                              ? 'stackable'
                              : 'not_stackable',
                            defaultValue:
                              '{{quantity}} x {{packageType}}, {{description}}, {{volume}} m3, {{weight}} kg stackable',
                            quantity: goods.quantity,
                            packageType: goods.package_type_name,
                            volume: goods.volume_cbm,
                            weight: goods.total_weight_kg,
                            description: goods.goods_description,
                          })}
                        </>
                      )
                    })}
                  </div>
                  <hr />
                </div>
              )}
            {isRefeer && (
              <div className="duplicate-container--checkbox">
                {renderCheckbox(
                  'reefer_settings',
                  t(
                    'common.forms.fields.reefer_settings.label',
                    'Reefer Settings'
                  )
                )}
                <div
                  className="duplicate-container--description"
                  data-testid="dynamicvalues-reefer"
                >
                  <Typography variant="body1">
                    {containerDetails.temperature !== ''
                      ? containerDetails.temperature + ' C°, '
                      : ''}
                    {containerDetails.humidity !== ''
                      ? containerDetails.humidity + '%, '
                      : ''}
                    {containerDetails.ventilation !== ''
                      ? containerDetails.ventilation + 'CBM/HR, '
                      : ''}
                    {containerDetails.vents_open != null
                      ? containerDetails.vents_open
                        ? 'Vents Open, '
                        : 'Vents Closed, '
                      : ' Vents not specified, '}
                    {containerDetails.drains_open != null
                      ? containerDetails.drains_open
                        ? 'Drains Open, '
                        : 'Drains Closed, '
                      : ' Drains not specified, '}
                    {containerDetails.co2_regulation !== ''
                      ? containerDetails.co2_regulation + '% CO2'
                      : ''}
                  </Typography>
                </div>
                <hr />
              </div>
            )}
            {containerDetails.inland_transports &&
              (containerDetails.inland_transports[0]
                ?.inland_transport_addresses[0]?.address != null ||
                containerDetails.inland_transports[1]
                  ?.inland_transport_addresses[0]?.address != null) && (
                <div
                  className="duplicate-container--checkbox"
                  data-testid="dynamicvalues-addresses"
                >
                  {renderCheckbox(
                    'pickup_address',
                    t(
                      'common.forms.fields.pickup_or_delivery_address.label',
                      'Pick-up/Delivery address'
                    )
                  )}
                  <div className="duplicate-container--description">
                    {containerDetails.inland_transports.map((t) =>
                      t.inland_transport_addresses.map((data) => (
                        <Typography variant="body1">
                          {data.name?.length ? data.name + ', ' : ''}
                          {data.address?.length ? data.address + ', ' : ''}
                          {data.postal_code?.length
                            ? data.postal_code + ', '
                            : ''}
                          {data.city?.length ? data.city + ', ' : ''}
                          {data.country?.name}
                        </Typography>
                      ))
                    )}
                  </div>
                  <hr />
                </div>
              )}

            {containerDetails.hs_codes.length > 0 && (
              <div className="duplicate-container--checkbox">
                {renderCheckbox(
                  'hs_codes',
                  t('common.forms.fields.hs_codes.label', 'HS code')
                )}
                <div className="duplicate-container--description">
                  {containerDetails.hs_codes.map((code) => (
                    <Typography variant="body1">
                      {code.goods_code} - {code.description}
                    </Typography>
                  ))}
                </div>
                <hr />
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ContainerDuplicateWindow

import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFormContext } from '../../../FormContext'
import { getBookingPartiesAreEqual } from '../../../Form.utils'
import BookingParties from './BookingParty'

const TemplatesForm = ({ columnSize, editable = true }) => {
  const { formState } = useFormContext()

  const bookingPartiesAreEqual = getBookingPartiesAreEqual(formState)

  const { t } = useTranslation()

  return (
    <Grid container spacing={2} height="100%">
      <Grid item xs={6}>
        <BookingParties
          fieldPath="shipper"
          label={t('common.shipper', 'Shipper')}
          dependant="consignee"
          editable={editable}
        />
      </Grid>
      <Grid item xs={6}>
        <BookingParties
          fieldPath="consignee"
          label={t('common.consignee', 'Consignee')}
          dependant="shipper"
          editable={editable}
        />
      </Grid>
      {bookingPartiesAreEqual && editable && (
        <Grid item xs={12}>
          <Typography
            color="error"
            variant="body2"
            children="Shipper and consignee cannot be the same"
          />
        </Grid>
      )}
    </Grid>
  )
}

export default TemplatesForm

import { flatten, isPlainObject, lowerCase, values } from 'lodash'
import qs from 'query-string'
import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { LoadTypeEnum, ModalityEnum } from 'src/config/constants'
import useLocalStorage from 'src/hooks/useLocalStorage'
import {
  containerTypesGetData,
  incotermsGetData,
  packageTypesGetData,
  searchQuotes,
  shipmentsGetOrganizationRoles,
} from 'src/stores/actionCreators'
import { promisifyAction } from 'src/utils'
import { initialCargo } from 'src/components/GoodsLoadType/GoodsLoadTypeLCL/constants'
import {
  defaultServices,
  incotermServiceRoles,
  organizationRolesForIncotermServiceBinding,
} from './constants'
import { SearchState } from './SearchContainer.props'
import SearchContainerNotifications from './SearchContainerNotifications'
import SearchView from './SearchView'
import { getIncotermServicesManipulated } from './utils'
import {
  getUpdatedState,
  searchQueryToState,
  stateToRequestAndUrl,
  getDepartureDate,
} from './SearchContainer.utils'
import { SearchContainerLoadingSkeleton } from './SearchContainerLoadingSkeleton'

const SearchContainer = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [organization] = useLocalStorage<IOrganization | null>(
    'scopedOrganization',
    null
  )

  const [isLoading, setLoading] = useState<boolean>(true)
  const [incotermTypes, setIncotermTypes] = useState<IIncoterm[]>([])
  const [shipmentRoles, setShipmentRoles] = useState<IRoleShort[]>([])
  const [containerTypes, setContainerTypes] = useState<IContainerType[]>([])

  const { user } = useSelector(
    (state: IGlobalState) => ({
      user: state.user,
    }),
    shallowEqual
  )

  const getShipmentsOrganizationRoles = promisifyAction(
    dispatch,
    shipmentsGetOrganizationRoles
  )
  const getPackageTypes = promisifyAction(dispatch, packageTypesGetData)
  const getContainerTypes = promisifyAction(dispatch, containerTypesGetData)
  const getIncotermTypes = promisifyAction(dispatch, incotermsGetData)

  const [searchState, setSearchState] = useState<SearchState>({
    modality: ModalityEnum.Sea,
    origin: null,
    destination: null,
    organization,
    role: null,
    load_type: LoadTypeEnum.fcl,
    services: defaultServices,
    incoterm: null,
    departure_date: getDepartureDate(new Date(), ModalityEnum.Sea).toJSDate(),
    containers: {},
    cargo: [initialCargo()],
    tags: {
      dangerous_goods: false,
    },
    cif_value: 0,
    temperature_setting: null,
    purchase_order_line_ids: [],
    sum_cbm: 0,
    purchase_orders: [],
  })

  const roleName = searchState.role?.name ?? ''

  const organizationRoleRequiresIncotermBinding = organizationRolesForIncotermServiceBinding.includes(
    user.organizationRole
  )

  const incotermServiceBindingApplies =
    flatten(values(incotermServiceRoles)).includes(lowerCase(roleName)) &&
    organizationRoleRequiresIncotermBinding

  const incotermServicesManipulated = getIncotermServicesManipulated(
    roleName,
    incotermServiceBindingApplies,
    searchState.incoterm,
    searchState.services
  )

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      await getPackageTypes()
      const organizationRoles: IOrganizationRoles[] = await getShipmentsOrganizationRoles()
      const containerTypes = await getContainerTypes()
      const incotermTypes = await getIncotermTypes()

      const organizationShipmentRoles =
        organizationRoles.find((role) => role.id === user.organizationRoleId)
          ?.shipment_roles || []

      const searchQuery: any = qs.parse(document.location.search, {
        arrayFormat: 'bracket',
        parseNumbers: true,
        parseBooleans: true,
      })

      setContainerTypes(containerTypes)
      setIncotermTypes(incotermTypes)
      setShipmentRoles(organizationShipmentRoles)
      setSearchState((prevState) => {
        const newState = {
          ...prevState,
          ...searchQueryToState(searchQuery, prevState, { containerTypes }),
          role:
            organizationShipmentRoles.find(
              (role) =>
                role.id ===
                (searchQuery.role_id || user.preferredShipmentRoleId)
            ) || null,
          incoterm:
            incotermTypes.find(
              (incoterm) => incoterm.id === searchQuery.incoterm_id
            ) || null,
        }
        if (location.search.length > 0) {
          dispatch(
            searchQuotes(
              stateToRequestAndUrl(newState, {
                userOrganizationId: user.organizationId,
                containerTypes,
              })
            )
          )
        }
        return newState
      })
      setLoading(false)
    })()
  }, [location.search])

  const onChange = (field: string, value) => {
    setSearchState((prevLocalState) => {
      const update = {
        ...prevLocalState,
        ...getUpdatedState(
          field,
          isPlainObject(value) ? { ...prevLocalState[field], ...value } : value,
          prevLocalState,
          organizationRoleRequiresIncotermBinding
        ),
      }
      return update
    })
  }

  const requestState: any = stateToRequestAndUrl(searchState, {
    userOrganizationId: user.organizationId,
    containerTypes,
  })

  const onSubmit = () => {
    dispatch(searchQuotes(requestState))
  }

  return (
    <>
      {!isLoading && (
        <>
          <SearchView
            onChange={onChange}
            state={searchState}
            url={qs.stringify(requestState, { arrayFormat: 'bracket' })}
            onSubmit={onSubmit}
            data={{ roles: shipmentRoles, incoterms: incotermTypes }}
          />
          <SearchContainerNotifications
            incotermServicesManipulated={incotermServicesManipulated}
          />
        </>
      )}
      {isLoading && <SearchContainerLoadingSkeleton />}
    </>
  )
}

export default SearchContainer

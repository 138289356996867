import { useSelector } from 'react-redux'
import InputLabel from '@mui/material/InputLabel'
import { useGetOrganizationShipmentRoles } from 'src/services/Api/common'
import { useFormContext } from '../../FormContext'
import SingleSelect from '../FormElements/SingleSelect'
import { useFormFieldChangeSideEffects } from '../../Form.hooks'
import FieldValueText from './FieldValueText'

const ShipmentRole = (props) => {
  const { formState } = useFormContext()
  const { fieldPath, label, required = false, editable = true } = props

  const {
    onShipmentRoleChange,
    onIncotermOrShipmentRoleChange,
  } = useFormFieldChangeSideEffects()

  const { user } = useSelector((state: IGlobalState) => ({
    user: state.user,
  }))

  const { data: organizationShipmentRoles } = useGetOrganizationShipmentRoles({
    refetchOnMount: false,
  })

  const userOrganizationShipmentRole = organizationShipmentRoles?.find(
    (shipmentRole) => shipmentRole.id === user.organizationRoleId
  )

  const options =
    userOrganizationShipmentRole?.shipment_roles?.map(
      (organizationShipmentRole) => ({
        label: organizationShipmentRole.name,
        id: organizationShipmentRole.id,
      })
    ) ?? []

  const selectedOption = options.find(
    (option) => option.id === formState.shipmentRole
  )

  const onSelectChange = (newRoleId) => {
    onShipmentRoleChange(newRoleId, user)
    onIncotermOrShipmentRoleChange()
  }

  return (
    <>
      <InputLabel required={required} children={label} />
      {editable && (
        <SingleSelect
          options={options}
          required={required}
          fieldPath={fieldPath}
          onSingleSelectChange={onSelectChange}
          SelectDisplayProps={{
            'data-testid': 'shipment-template-shipment-role-select',
          }}
        />
      )}
      {!editable && <FieldValueText text={selectedOption?.label} />}
    </>
  )
}

export default ShipmentRole

export const FETCH_DASHBOARD_TASK_MANAGER = 'FETCH_DASHBOARD_TASK_MANAGER'
export const FETCH_DASHBOARD_TASK_MANAGER_SUCCESS =
  'FETCH_DASHBOARD_TASK_MANAGER_SUCCESS'
export const FETCH_DASHBOARD_TASKS = 'FETCH_DASHBOARD_TASKS'
export const FETCH_DASHBOARD_TASKS_SUCCESS = 'FETCH_DASHBOARD_TASKS_SUCCESS'

export const FETCH_DASHBOARD_PICKUP_AND_DELIVERY =
  'FETCH_DASHBOARD_PICKUP_AND_DELIVERY'
export const FETCH_DASHBOARD_PICKUP_AND_DELIVERY_SUCCESS =
  'FETCH_DASHBOARD_PICKUP_AND_DELIVERY_SUCCESS'

export const FETCH_DASHBOARD_UPDATED_BY_YOU = 'FETCH_DASHBOARD_UPDATED_BY_YOU'
export const FETCH_DASHBOARD_UPDATED_BY_YOU_SUCCESS =
  'FETCH_DASHBOARD_UPDATED_BY_YOU_SUCCESS'
export const FETCH_DASHBOARD_UPDATED_BY_OTHERS =
  'FETCH_DASHBOARD_UPDATED_BY_OTHERS'
export const FETCH_DASHBOARD_UPDATED_BY_OTHERS_SUCCESS =
  'FETCH_DASHBOARD_UPDATED_BY_OTHERS_SUCCESS'
export const FETCH_DASHBOARD_ARRIVING = 'FETCH_DASHBOARD_ARRIVING'
export const FETCH_DASHBOARD_ARRIVING_SUCCESS =
  'FETCH_DASHBOARD_ARRIVING_SUCCESS'
export const FETCH_DASHBOARD_DEPARTING = 'FETCH_DASHBOARD_DEPARTING'
export const FETCH_DASHBOARD_DEPARTING_SUCCESS =
  'FETCH_DASHBOARD_DEPARTING_SUCCESS'
export const FETCH_DASHBOARD_RECENTLY_BOOKED = 'FETCH_DASHBOARD_RECENTLY_BOOKED'
export const FETCH_DASHBOARD_RECENTLY_BOOKED_SUCCESS =
  'FETCH_DASHBOARD_RECENTLY_BOOKED_SUCCESS'

export const FETCH_DASHBOARD_SUPPLY_CHAIN = 'FETCH_DASHBOARD_SUPPLY_CHAIN'
export const FETCH_DASHBOARD_SUPPLY_CHAIN_SUCCESS =
  'FETCH_DASHBOARD_SUPPLY_CHAIN_SUCCESS'

export const FETCH_DASHBOARD_SUPPLY_CHAIN_SHIPMENTS =
  'FETCH_DASHBOARD_SUPPLY_CHAIN_SHIPMENTS'
export const FETCH_DASHBOARD_SUPPLY_CHAIN_SHIPMENTS_SUCCESS =
  'FETCH_DASHBOARD_SUPPLY_CHAIN_SHIPMENTS_SUCCESS'

import { FunctionComponent } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { shipmentsUpdateFilters } from 'src/stores/actionCreators'
import Pagination from 'src/components/Common/Pagination'
import { useGetShipments } from 'src/services/Api/shipments'
import { updateUrl } from 'src/components/Filters/utils'
import { formatFiltersToParams } from '../helpers'

const ShipmentsPagination: FunctionComponent = () => {
  const dispatch = useDispatch()

  const { page, shipmentsPerPage, filters } = useSelector(
    (state: IGlobalState) => ({
      page: +state.shipments.filters.page,
      shipmentsPerPage: +state.shipments.filters.shipmentsPerPage,
      filters: state.shipments.filters,
    }),
    shallowEqual
  )

  const { data } = useGetShipments(formatFiltersToParams({ ...filters }), {
    enabled: !filters.initial,
  })

  const onChangePage = (page: number) => {
    dispatch(shipmentsUpdateFilters({ ...filters, page }))
    updateUrl('shipments', { ...filters, page })
  }

  return (
    <Pagination
      onChangePage={onChangePage}
      totalItemsNumber={data?.total_count || 0}
      itemsPerPage={shipmentsPerPage}
      currentPage={page}
    />
  )
}

export default ShipmentsPagination

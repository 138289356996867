import { AnyAction } from 'redux'
import * as actions from '../actions/account'

export const getAccessToken = (id: number): AnyAction => ({
  type: actions.GET_ACCESS_TOKEN,
  id,
})

export const getAccessTokenSuccess = (payload: any): AnyAction => ({
  type: actions.GET_ACCESS_TOKEN_SUCCESS,
  payload,
})

export const resetAccessToken = (payload: any): AnyAction => ({
  type: actions.RESET_ACCESS_TOKEN,
  payload,
})

export const resetAccessTokenSuccess = (payload: any): AnyAction => ({
  type: actions.RESET_ACCESS_TOKEN_SUCCESS,
  payload,
})

export const deleteAccessToken = (payload: any): AnyAction => ({
  type: actions.DELETE_ACCESS_TOKEN,
  payload,
})

export const deleteAccessTokenSuccess = (payload: any): AnyAction => ({
  type: actions.DELETE_ACCESS_TOKEN_SUCCESS,
  payload,
})

import { bookingsPlacedSchema } from './bookingsPlacedSchema'
import { scheduleTbaSchema } from './scheduleTbaSchema'
import { onHoldSchema } from './onHoldSchema'
import { scheduleAnnouncedSchema } from './scheduleAnnouncedSchema'
import { bookingConfirmedSchema } from './bookingConfirmedSchema'
import { inactiveTNTSchema } from './inactiveTNTSchema'
import { rollingSchema } from './rollingSchema'
import { handoverSchema } from './handoverSchema'
import { deliveriesLCLSchema } from './deliveriesLCLSchema'
import { deliveriesFCLSchema } from './deliveriesFCLSchema'
import { recentDeliveriesSchema } from './recentDeliveriesSchema'

export default {
  bookings_placed: bookingsPlacedSchema,
  schedule_tba: scheduleTbaSchema,
  on_hold: onHoldSchema,
  schedule_announced: scheduleAnnouncedSchema,
  booking_confirmed: bookingConfirmedSchema,
  inactive_t_n_t: inactiveTNTSchema,
  rollings: rollingSchema,
  handover: handoverSchema,
  deliveries_lcl: deliveriesLCLSchema,
  deliveries_fcl: deliveriesFCLSchema,
  recent_deliveries: recentDeliveriesSchema,
}

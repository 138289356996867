export const SHIPMENT_LAYOUT_CANCEL_SHIPMENT = 'SHIPMENT_LAYOUT_CANCEL_SHIPMENT'
export const SHIPMENT_LAYOUT_CANCEL_SHIPMENT_SUCCESS =
  'SHIPMENT_LAYOUT_CANCEL_SHIPMENT_SUCCESS'
export const SHIPMENT_LAYOUT_ACCEPT_QUOTE = 'SHIPMENT_LAYOUT_ACCEPT_QUOTE'
export const SHIPMENT_LAYOUT_ACCEPT_QUOTE_SUCCESS =
  'SHIPMENT_LAYOUT_ACCEPT_QUOTE_SUCCESS'
export const SHIPMENT_LAYOUT_UPDATE_BOOKING = 'SHIPMENT_LAYOUT_UPDATE_BOOKING'
export const SHIPMENT_LAYOUT_UPDATE_BOOKING_SUCCESS =
  'SHIPMENT_LAYOUT_UPDATE_BOOKING_SUCCESS'
export const SHIPMENT_LAYOUT_GET_REMARKS = 'SHIPMENT_LAYOUT_GET_REMARKS'
export const SHIPMENT_LAYOUT_GET_REMARKS_SUCCESS =
  'SHIPMENT_LAYOUT_GET_REMARKS_SUCCESS'

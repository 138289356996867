import ScheduleSendIcon from '@mui/icons-material/ScheduleSend'
import { useTranslation } from 'react-i18next'
import CenteredLayout from 'src/pages/Layout/Centered'

const ResetPasswordRequestLoading = () => {
  const { t } = useTranslation()
  return (
    <CenteredLayout
      icon={ScheduleSendIcon}
      title={t('reset_password_link_request_loading.title')}
      description=""
      variant="info"
      isLoading={true}
    />
  )
}

export default ResetPasswordRequestLoading

import isArray from 'lodash/isArray'

export const downloadFile = (url: string, fileName: string) => {
  const element = document.createElement('a')
  document.body.appendChild(element)
  element.setAttribute('href', url)
  element.setAttribute('download', fileName)
  element.setAttribute('rel', 'noopener noreferrer')
  element.setAttribute('target', '_blank')
  element.style.display = 'none'
  element.click()
  document.body.removeChild(element)
}

export const getCsvUrlFromTwoDimensionalArray = (items: string[][]) => {
  if (!isArray(items)) {
    return ''
  }
  return encodeURI(
    'data:text/csv;charset=utf-8,' +
      items.map((item: string[]) => item.join(',')).join('\n')
  )
}

export const UPLOAD_PDF_DOCUMENT = 'UPLOAD_PDF_DOCUMENT'
export const PURCHASE_ORDERS_GET_DATA = 'PURCHASE_ORDERS_GET_DATA'
export const PURCHASE_ORDERS_GET_DATA_SUCCESS =
  'PURCHASE_ORDERS_GET_DATA_SUCCESS'
export const PURCHASE_ORDERS_GET_FILTERS_DATA =
  'PURCHASE_ORDERS_GET_FILTERS_DATA'
export const PURCHASE_ORDERS_GET_FILTERS_DATA_SUCCESS =
  'PURCHASE_ORDERS_GET_FILTERS_DATA_SUCCESS'
export const PURCHASE_ORDERS_GET_META = 'PURCHASE_ORDERS_GET_META'
export const PURCHASE_ORDERS_GET_META_SUCCESS =
  'PURCHASE_ORDERS_GET_META_SUCCESS'
export const PURCHASE_ORDERS_OPEN_ITEM = 'PURCHASE_ORDERS_OPEN_ITEM'
export const PURCHASE_ORDERS_CREATE = 'PURCHASE_ORDERS_CREATE'
export const PURCHASE_ORDERS_CREATE_SUCCESS = 'PURCHASE_ORDERS_CREATE_SUCCESS'
export const PURCHASE_ORDERS_CLEAR = 'PURCHASE_ORDERS_CLEAR'
export const PURCHASE_ORDERS_SET_ASSIGNABLE_STATUSES =
  'PURCHASE_ORDERS_SET_ASSIGNABLE_STATUSES'
export const PURCHASE_ORDERS_CHECK_UNIQUE = 'PURCHASE_ORDERS_CHECK_UNIQUE'
export const PURCHASE_ORDERS_CHECK_UNIQUE_SUCCESS =
  'PURCHASE_ORDERS_CHECK_UNIQUE_SUCCESS'
export const UPDATE_PURCHASE_ORDER = 'UPDATE_PURCHASE_ORDER'
export const UPDATE_PURCHASE_ORDER_SUCCESS = 'UPDATE_PURCHASE_ORDER_SUCCESS'
export const DELETE_PURCHASE_ORDER = 'DELETE_PURCHASE_ORDER'
export const DELETE_PURCHASE_ORDER_SUCCESS = 'DELETE_PURCHASE_ORDER_SUCCESS'
export const TRIGGER_FETCHING_ORDERS = 'TRIGGER_FETCHING_ORDERS'
export const TRIGGER_OPEN_ADD_MODAL = 'TRIGGER_OPEN_ADD_MODAL'
export const CLEAR_OPEN_ADD_MODAL_COUNT = 'CLEAR_OPEN_ADD_MODAL_COUNT'
export const PURCHASE_ORDER_DISMISS_STATUSES = 'PURCHASE_ORDER_DISMISS_STATUSES'
export const PURCHASE_ORDER_SHARE_EMAIL = 'PURCHASE_ORDER_SHARE_EMAIL'
export const PURCHASE_ORDER_UNMARK_EXCEPTION = 'PURCHASE_ORDER_UNMARK_EXCEPTION'
export const ASSIGN_SELLER_ORGANIZATION = 'ASSIGN_SELLER_ORGANIZATION'
export const RESET_INFO_REQUESTS = 'RESET_INFO_REQUESTS'
export const RESET_INFO_REQUESTS_SUCCESS = 'RESET_INFO_REQUESTS_SUCCESS'
export const TOGGLE_SELECTED = 'TOGGLE_SELECTED'
export const SET_SELECTED = 'SET_SELECTED'
export const REMOVE_PURCHASE_ORDERS_FROM_SHIPMENT =
  'REMOVE_PURCHASE_ORDERS_FROM_SHIPMENT'
export const ASSIGN_PURCHASE_ORDERS_TO_SHIPMENT =
  'ASSIGN_PURCHASE_ORDERS_TO_SHIPMENT'

import {
  Autocomplete,
  InputLabel,
  AutocompleteProps,
  TextField,
  Checkbox,
  Typography,
  ListItem,
  CircularProgress,
} from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { makeStyles } from '@mui/styles'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const icon = <CheckBoxOutlineBlankIcon />
const checkedIcon = <CheckBoxIcon />

export interface AutoCompleteMultiProps<T>
  extends Omit<
      AutocompleteProps<any, any, any, any>,
      'renderInput' | 'renderOption' | 'options' | 'onChange'
    >,
    Partial<
      Pick<
        AutocompleteProps<any, any, any, any>,
        'renderInput' | 'renderOption' | 'options'
      >
    > {
  children?: (
    text: true | React.ReactChild | React.ReactFragment | React.ReactPortal,
    option: T
  ) => React.ReactNode
  noOptionsText?: string
  checkbox?: boolean
  required?: boolean
  name?: string
  onChange?: (values: any[]) => void
  disableClearable?: boolean
  /**
   * Field placeholder
   */
  placeholder?: string
  value: any[]
  options: any[]
  /**
   * Callback to get options asynchronously
   */
  onSearch?: (searchQuery: string) => Promise<T[]>
  /**
   * A field that will be used to display an option in the dropdown
   */
  optionLabel?: (option: any) => string
  /**
   * A function to determine how to divide options into groups
   */
  groupBy?: (option: T) => string
  /**
   * Error state of component
   */
  error?: boolean
  label?: string | React.ReactNode
  optionsKey?: string
  disableChip?: boolean
  disabledOptions?: string[]
}

const endAdornment = <KeyboardArrowDownIcon color="inherit" />

export const AutoCompleteMulti = <T extends Record<string, any>>(
  props: AutoCompleteMultiProps<T>
) => {
  const {
    placeholder = 'Start typing to search',
    optionLabel = (option) => option.name,
    optionsKey,
    onSearch,
    onChange: _onChange,
    disableChip,
    renderTags,
    checkbox,
    disabledOptions,
    options: _options,
    noOptionsText,
    ...rest
  } = props

  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const classes = useStyles()

  const onChange = useCallback(
    (_event: React.SyntheticEvent<Element, Event>, option) => {
      _onChange?.(option)
    },
    []
  )

  useEffect(() => {
    let done = false

    if (!done && onSearch) {
      setLoading(true)
      const search = async () => {
        await onSearch(inputValue)
        setLoading(false)
      }
      search()
    }

    return () => {
      done = true
    }
  }, [inputValue])

  const tags = (values) => {
    return (
      <>
        <Typography
          sx={{
            maxWidth: '50%',
            maxHeight: '40px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            mr: 0.5,
          }}
        >
          Selected values
        </Typography>
        <Typography>{values.length > 0 ? `(${values.length})` : ''}</Typography>
      </>
    )
  }

  const checkOptionValue = (option, value): boolean => {
    return !!(
      option &&
      value &&
      option[optionsKey || 'value'] === value[optionsKey || 'value']
    )
  }

  const optionTextWrapper = (part, index) => (
    <Typography
      key={index}
      sx={{
        display: 'inline',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '95%',
        fontWeight: part.highlight ? 600 : 400,
      }}
    >
      {part.text}
    </Typography>
  )

  const renderOption = (p, option, { selected }) => {
    const matches = match(optionLabel(option), inputValue)
    const parts = parse(optionLabel(option), matches)

    const OptionText = (
      <>{parts.map((part, index: number) => optionTextWrapper(part, index))}</>
    )

    const disabled = disabledOptions?.includes(option[optionsKey || 'id'])
    const _checkbox = (
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        sx={{ marginRight: 1, ml: -1 }}
        checked={selected}
      />
    )

    return props.children ? (
      <ListItem
        disabled={disabled}
        {...p}
        key={`${option[optionsKey || 'value']}`}
        sx={{ height: 44 }}
      >
        {checkbox && _checkbox}
        {props.children(OptionText, option)}
      </ListItem>
    ) : (
      <ListItem
        disabled={disabled}
        {...p}
        key={`${option[optionsKey || 'value']}`}
        sx={{ height: 44 }}
      >
        {checkbox && _checkbox}
        {OptionText}
      </ListItem>
    )
  }

  return (
    <>
      {props.label && (
        <InputLabel required={props.required} disabled={props.disabled}>
          {props.label}
        </InputLabel>
      )}
      <Autocomplete
        {...rest}
        multiple
        classes={{ root: classes.root }}
        options={props.options}
        value={props.value}
        getOptionLabel={optionLabel}
        ChipProps={{ size: 'small' }}
        onInputChange={(_event, newValue: string, reason) => {
          if (reason === 'input') {
            setInputValue(newValue)
          }
        }}
        inputValue={inputValue || ''}
        onChange={onChange}
        noOptionsText={noOptionsText}
        renderTags={disableChip ? tags : renderTags}
        isOptionEqualToValue={checkOptionValue}
        renderInput={(params) => (
          <TextField
            placeholder={placeholder}
            {...params}
            inputProps={{
              ...params.inputProps,
              'data-testid': 'search-bar',
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={16} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        popupIcon={endAdornment}
        renderOption={renderOption}
      />
    </>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiAutocomplete-popupIndicator': {
      transform: 'none',
    },
  },
}))

import Stack from '@mui/material/Stack'
import { ShipmentVessel } from 'src/stories/MUI/ShipmentContent/components/ShipmentVessel'
import { ShipmentProgress } from 'src/stories/MUI/ShipmentContent/components/ShipmentProgress'
import { ShipmentPorts } from 'src/stories/MUI/ShipmentContent/components/ShipmentPorts'

import { TooltipFooterProps } from './MapTooltip.props'

interface MapTooltipFooterProps {
  shipment: TooltipFooterProps
}

const MapTooltipFooter: React.FC<MapTooltipFooterProps> = ({ shipment }) => {
  return (
    <Stack spacing={1}>
      <ShipmentPorts
        align="center"
        loadingPort={shipment.loading_port}
        dischargePort={shipment.discharge_port}
      />
      {shipment.estimated_arrival && shipment.estimated_departure && (
        <ShipmentProgress
          align="center"
          showProgress={true}
          progressColor="primary"
          estimatedArrival={shipment.estimated_arrival}
          progressPercentage={shipment.progress_percent}
          estimatedDeparture={shipment.estimated_departure}
        />
      )}
      <ShipmentVessel align="center" vessel={shipment.vessel} />
    </Stack>
  )
}

export default MapTooltipFooter

import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/countries'
import * as actionCreators from '../../actionCreators/countries'
import { simpleGetAndResolve } from '../factories'
import { ISelectOption } from '../../reducers/selectOptions'

const serializeCountries = ({ id, title }: any): ISelectOption => [title, id]

const getCountries = simpleGetAndResolve(
  () => 'api/v1/common/countries/',
  actionCreators.countriesGetCountriesSuccess,
  (response) => response.data.data.countries.map(serializeCountries)
)

const getCountriesNotFormatted = simpleGetAndResolve(
  () => 'api/v1/common/countries/',
  actionCreators.countriesGetNotFormattedCountriesSuccess,
  (response) => response.data.data.countries
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.COUNTRIES_GET_COUNTRIES, getCountries)
  yield takeEvery(
    actions.COUNTRIES_GET_COUNTRIES_NOT_FORMATTED,
    getCountriesNotFormatted
  )
}

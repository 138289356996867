import { set, get } from 'lodash'
import { InputLabel, Box } from '@mui/material'
import i18n from 'i18next'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import { useFormContext } from '../../FormContext'

const getSelectValue = ({ mapIdToValue, formState, fieldPath, options }) => {
  const initialValue = get(formState, fieldPath, '') ?? ''

  if (!mapIdToValue) {
    const optionsHasValue = options.find((option) => option.id === initialValue)
    if (optionsHasValue) return initialValue
    return ''
  }

  const option = options.find((option) => option.value === initialValue)
  return option?.id ?? ''
}

const Select = (props) => {
  const {
    fieldPath,
    options,
    label = null,
    required = false,
    disabled = false,
    mapIdToValue = false,
    inputPlaceholder = i18n.t('common.select', 'Select'),
    onSingleSelectChange,
    ...selectProps
  } = props

  const { formState, onChange } = useFormContext()

  const value = getSelectValue({
    mapIdToValue,
    formState,
    fieldPath,
    options,
  })

  const onSelectChange = (event) => {
    const updatedValue = event.target.value

    if (mapIdToValue) {
      const option = options.find((option) => option.id === updatedValue)
      return onChange(set(formState, fieldPath, option?.value ?? null))
    }

    onChange(set(formState, fieldPath, event.target.value))
    if (onSingleSelectChange) {
      onSingleSelectChange(event.target.value)
    }
  }

  const onSelectReset = () => {
    onChange(set(formState, fieldPath, null))
  }

  return (
    <Box>
      {label && <InputLabel required={required}>{label}</InputLabel>}
      <SingleSelect
        value={value}
        {...selectProps}
        options={options}
        disabled={disabled}
        onReset={onSelectReset}
        onChange={onSelectChange}
        placeholder={inputPlaceholder}
      />
    </Box>
  )
}

export default Select

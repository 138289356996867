export const USER_LOAD_STATE = 'USER_LOAD_STATE'
export const USER_LOAD_STATE_SUCCESS = 'USER_LOAD_STATE_SUCCESS'
export const USER_LOAD_STATE_FAILURE = 'USER_GET_USER_DATA_FAILURE'
export const USER_SIGN_IN = 'USER_SIGN_IN'
export const USER_SIGN_IN_SUCCESS = 'USER_SIGN_IN_SUCCESS'
export const USER_SIGN_OUT = 'USER_SIGN_OUT'
export const USER_GET_USER_DATA = 'USER_GET_USER_DATA'
export const USER_GET_USER_DATA_SUCCESS = 'USER_GET_USER_DATA_SUCCESS'
export const USER_STORE_STATE = 'USER_STORE_STATE'
export const USER_SUBMIT_USER_DATA = 'USER_SUBMIT_USER_DATA'
export const USER_UPDATE_USER_DATA = 'USER_UPDATE_USER_DATA'
export const USER_UPDATE_USER_SETTINGS_DATA = 'USER_UPDATE_USER_SETTINGS_DATA'
export const USER_UPDATE_USER_SETTINGS_DATA_SUCCESS =
  'USER_UPDATE_USER_SETTINGS_DATA_SUCCESS'
export const USER_SUBMIT_PROFILE = 'USER_SUBMIT_PROFILE'
export const USER_SUBMIT_NOTIFICATION = 'USER_SUBMIT_NOTIFICATION'
export const USER_DO_NOTHING = 'USER_DO_NOTHING'
export const USER_SUBMIT_MILESTONE = 'USER_SUBMIT_MILESTONE'

export const USER_FORGOT_PASSWORD = 'USER_FORGOT_PASSWORD'
export const USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS'
export const USER_UPDATE_PASSWORD = 'USER_UPDATE_PASSWORD'
export const USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD'
export const USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD'
export const USER_SET_ORDERS_VISITED = 'USER_SET_ORDERS_VISITED'
export const USER_SKIP_CSV_CONFIG_SUCCESS = 'USER_SKIP_CSV_CONFIG_SUCCESS'
export const USER_AVATAR_UPLOAD = 'USER_AVATAR_UPLOAD'
export const USER_SYNC_SHIPMENT_GROUPS = 'USER_SYNC_SHIPMENT_GROUPS'
export const USER_SUBSCRIBE_TO_BROWSER_NOTIFICATIONS =
  'USER_SUBSCRIBE_TO_BROWSER_NOTIFICATIONS'
export const USER_UNSUBSCRIBE_TO_BROWSER_NOTIFICATIONS =
  'USER_UNSUBSCRIBE_TO_BROWSER_NOTIFICATIONS'

import { Box, Button, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'src/components/Common/Modal'
import FormLabel from 'src/stories/Lab/FormLabel'

type PropTypes = {
  filterName: string
  onClose: () => void
  onSave: (newValue: string) => void
  open: boolean
  type: 'update' | 'add'
}

export const ActionButtonsModal = ({
  filterName,
  onClose,
  onSave,
  open,
  type,
}: PropTypes) => {
  const { t } = useTranslation()
  const [input, setInput] = useState<string>(filterName)
  const title =
    type === 'add'
      ? t('common.buttons.save_as_quick_filter', 'Save as quick filter')
      : t('common.buttons.update_quick_filter', 'Update quick filter')

  const onModalClose = () => {
    setInput(filterName)
    onClose()
  }

  const onSaveClick = async (): Promise<void> => {
    onSave(input)
    onModalClose()
  }

  useEffect(() => {
    filterName && setInput(filterName)
  }, [filterName])

  return (
    <>
      <Modal.Window open={open}>
        <Modal.Title onClose={onModalClose}>{title}</Modal.Title>
        <Box px={3} width={600}>
          {type === 'update' && (
            <>
              <Typography mb={2} variant="body1">
                {t('common.prompts.quick_filter_update_confirmation', {
                  filterName,
                })}
              </Typography>
              <Typography mb={2} variant="body1">
                {t('common.prompts.action_cannot_be_undone')}
              </Typography>
            </>
          )}
          <FormLabel label={t('filters.manage_quick_filters.name')} />
          <TextField
            fullWidth
            value={input}
            autoFocus={true}
            placeholder={t('common.placeholders.descriptive_name')}
            onChange={(event) => {
              setInput(event.target.value)
            }}
          />
        </Box>
        <Modal.Actions>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Button
              size="large"
              sx={{ mr: 2 }}
              variant="text"
              onClick={onModalClose}
            >
              {t('common.buttons.cancel')}
            </Button>
            <Button
              size="large"
              variant="contained"
              disabled={input.length === 0}
              onClick={onSaveClick}
            >
              {title}
            </Button>
          </Box>
        </Modal.Actions>
      </Modal.Window>
    </>
  )
}

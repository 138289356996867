import Link from '@mui/material/Link'
import { Trans } from 'react-i18next'
import addAgreementInstructions from 'src/assets/files/demurrageAndDetentionOneInstructions.pdf'

const DemurrageAndDetentionContentAddAgreementStepOne = () => {
  return (
    <Trans
      i18nKey="demurrage_and_detention.add_agreement.steps_one.description"
      defaults="Download our CSV template file below and follow <0>these instructions</0> to proceed."
      components={[<Link href={addAgreementInstructions} target="_blank" />]}
    />
  )
}

export default DemurrageAndDetentionContentAddAgreementStepOne

import { LinearProgressProps } from '@mui/material/LinearProgress'

import { getCurrentDateString, datesDifference } from 'src/utils/helpers'

export const calculateProgress = ({ status, arrival, departure }): number => {
  if (status === 'current') {
    if (!arrival || !departure || arrival === departure) {
      return 0
    }
    return Math.floor(
      (datesDifference(getCurrentDateString(), departure) * 100) /
        datesDifference(arrival, departure)
    )
  }
  if (status === 'completed') {
    return 100
  }
  return 0
}

export const getShipmentProgressColor = (
  status: string
): LinearProgressProps['color'] => {
  if (status === 'current') {
    return 'primary'
  }
  if (status === 'completed') {
    return 'success'
  }
  return 'inherit'
}

import { ButtonProps } from '@mui/material'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'

interface ShipmentCostsLoadingButtonProps extends ButtonProps {
  text: string
  loading: boolean
}

export const ShipmentCostsLoadingButton: React.FC<ShipmentCostsLoadingButtonProps> = (
  props
) => {
  const { text, loading, onClick, ...buttonProps } = props
  return (
    <>
      {loading && (
        <LoadingButton
          className="loading-button"
          loading
          startIcon={<SaveIcon />}
          {...buttonProps}
        />
      )}
      {!loading && (
        <Button
          className="action-button"
          {...buttonProps}
          children={text}
          onClick={onClick}
        />
      )}
    </>
  )
}

// @ts-nocheck
// FIXME

import bind from 'autobind-decorator'
import PropTypes from 'prop-types'
import { Component, EventHandler } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import isEmail from 'validator/lib/isEmail'
import { Link } from 'react-router-dom'
import { parse } from 'query-string'
import { get } from 'lodash'
import { Button, Link as MuiLink } from '@mui/material'
import { showNotification } from 'src/stores/actionCreators/notifications'
import LoginHeader from 'src/components/LoginHeader'
import { withTranslation } from 'react-i18next'
import { promisifyAction } from '../../utils'
import { userForgotPassword } from '../../stores/actionCreators'
import Input from '../../components/Common/Input/MuiInput'
import LoginLayout from './Layout'

import 'src/pages/Login/styles.scss'
import './styles.scss'

interface ILoginFieldSubstate {
  error: boolean
  note: string
  value: string
}

interface ILoginState {
  [x: string]: ILoginFieldSubstate | string | boolean
  email: ILoginFieldSubstate
  note: string
}

interface ILoginProps {
  submit: IActionPromiseFactory
  query: string
  showNotificaion: any
}

const mapStateToProps = (state: IGlobalState): any => ({
  query: get(state.router, 'location.search'),
})

const mapDispatchToProps = (dispatch: Dispatch): any => ({
  submit: promisifyAction(dispatch, userForgotPassword),
  showMessage: (notification: INotification) =>
    dispatch(showNotification(notification)),
})

class ForgotPassword extends Component<ILoginProps, ILoginState> {
  public static propTypes: IDictionary<any> = {
    submit: PropTypes.func,
  }
  public static defaultProps: IDictionary<any> = {
    submit: (values: { email: string }): boolean => true,
  }
  public handleEmailChange: EventHandler<any>

  constructor(props: any, context: any) {
    super(props, context)
    this.state = {
      email: {
        error: false,
        note: '',
        value: '',
      },
      note: '',
    }
    this.handleEmailChange = this.createFieldChangeHandler('email')
  }

  public componentDidMount() {
    const messagesMap = {
      user_not_found: this.props.t('forgot_password.error.user_not_found'),
      invitation_invalid: this.props.t(
        'forgot_password.error.invitation_invalid'
      ),
    }
    const query = parse(this.props.query)
    if (query.success === 'false') {
      this.props.showMessage({
        message: messagesMap[query.reason],
        severity: 'error',
      })
    }
  }

  public render() {
    const { email } = this.state
    return (
      <div>
        <LoginHeader />
        <LoginLayout
          emailField={
            <Input
              label={email.note ? `Email - ${email.note}` : 'Email'}
              value={email.value}
              error={email.error}
              onChange={this.handleEmailChange}
              autoComplete="on"
            />
          }
          back={
            <MuiLink component={Link} variant="body1" to="/login">
              {this.props.t(
                'forgot_password.back_to_login_link',
                'Back to login'
              )}
            </MuiLink>
          }
          button={
            <Button
              variant="contained"
              onClick={this.submit}
              disabled={this.isError()}
            >
              {this.props.t('forgot_password.reset_password_button', 'Reset')}
            </Button>
          }
        />
      </div>
    )
  }

  @bind
  private async submit(): Promise<any> {
    const { email } = this.state
    if (isEmail(email.value)) {
      try {
        await this.props.submit({
          email: email.value,
          redirect_url: `${window.location.origin}/change-password`,
        })
      } catch (error) {
        this.setState((state, props) => ({
          email: {
            ...state.email,
            error: true,
          },
        }))
      }
    } else {
      this.setState({
        email: {
          ...email,
          error: true,
          note: this.props.t('forgot_password.error.invalid_email'),
        },
      })
    }
  }

  private isError(): boolean {
    const { email } = this.state
    return !!email.error
  }

  private createFieldChangeHandler(
    stateField: string,
    validator?: (value: string) => boolean,
    note: string = ''
  ): React.EventHandler<any> {
    const self = this
    function changeHandler(value): void {
      const error = !!(validator && !validator(value))
      self.setState({
        [stateField]: {
          error,
          note: self.state.note || error ? note : '',
          value,
        },
      })
    }
    return changeHandler
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ForgotPassword))

import { Link } from 'react-router-dom'
import { Link as MuiLink } from '@mui/material'
import { sumBy } from 'lodash'

const statusesDictionary = (t) => ({
  to_plan: t('purchase_orders.table.po_line_table.statuses.to_plan', 'To Plan'),
  planned: t('purchase_orders.table.po_line_table.statuses.planned', 'Planned'),
  shipped: t('purchase_orders.table.po_line_table.statuses.shipped', 'Shipped'),
  delivered: t(
    'purchase_orders.table.po_line_table.statuses.delivered',
    'Delivered'
  ),
})

export const totalVolume = (orderLine: IPurchaseOrderLine): number =>
  sumBy(orderLine.booking_lines, (bookingLine: IBookingLine) =>
    parseFloat(bookingLine.volume_cbm)
  )

export const totalVolumeFormatted = (orderLine: IPurchaseOrderLine): string => {
  const totalVolumeCalculated: number = totalVolume(orderLine)
  return totalVolumeCalculated ? `${totalVolumeCalculated}m3` : ''
}

export const totalWeight = (orderLine: IPurchaseOrderLine): number =>
  sumBy(orderLine.booking_lines, (bookingLine: IBookingLine) =>
    parseFloat(bookingLine.weight_kg)
  )

export const totalWeightFormatted = (orderLine: IPurchaseOrderLine): string => {
  const totalWeightCalculated = totalWeight(orderLine)
  return totalWeightCalculated ? `${totalWeightCalculated}kg` : ''
}

export const statusFormatted = (t, orderLine: IPurchaseOrderLine): string =>
  statusesDictionary(t)[orderLine.status]

export const orderLineShipment = (
  orderLine: IPurchaseOrderLine
): IShipmentShort | null => {
  if (orderLine.booking_lines.length) {
    return orderLine.booking_lines[0].shipment
  }
  return null
}

export const orderLineShipmentReference = (
  orderLine: IPurchaseOrderLine,
  orderId: number
): React.ReactNode => {
  const shipment = orderLineShipment(orderLine)
  if (!shipment) {
    return null
  }

  return (
    <MuiLink
      variant="body1"
      component={Link}
      to={`/shipments/${shipment.id}/orders?open_item=${orderId}`}
    >
      SF{shipment.id} {shipment.reference ? `| ${shipment.reference}` : ''}
    </MuiLink>
  )
}

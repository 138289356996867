import { useState, useEffect } from 'react'
import { capitalize } from 'lodash'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import { promisifyAction } from 'src/utils'
import { showNotification } from 'src/stores/actionCreators/notifications'
import SelectableChip, { OptionsItem } from 'src/components/SelectableChip'
import { updatePickupsAndDeliveriesItem } from 'src/stores/actionCreators'
import { statusOptions, statusColors } from '../constants'

const InlandTransportRowDeliveryStatus: React.FC<{
  rowId: number
  status: string
  service: string
  updatable: boolean
}> = ({ updatable, status, service, rowId }) => {
  const [currentStatus, setCurrentStatus] = useState(status)
  const [updating, setUpdating] = useState(false)

  const dispatch = useDispatch()

  const color = statusColors?.[currentStatus] ?? statusColors.misc

  const inlandTransportStatusOptions: OptionsItem[] = statusOptions.map(
    (statusOption) => {
      return {
        name: statusOption.label,
        id: statusOption.id,
        type: 'item',
      }
    }
  )

  const updateDeliveryStatusAsync = promisifyAction(
    dispatch,
    updatePickupsAndDeliveriesItem
  )

  const handleStatusUpdate = (newValue: OptionsItem) => {
    if (!updatable) {
      return
    }

    const tempStatus = currentStatus
    setCurrentStatus(newValue.id as string)
    setUpdating(true)

    updateDeliveryStatusAsync(rowId, {
      status: newValue.id,
    })
      .then(() => {
        dispatch(
          showNotification({
            severity: 'success',
            message: `${capitalize(service)} status updated`,
          })
        )
      })
      .catch(() => {
        setCurrentStatus(tempStatus)
      })
      .finally(() => {
        setUpdating(false)
      })
  }

  useEffect(() => {
    setCurrentStatus(status)
  }, [status])

  return (
    <Box data-testid="inland-transport-delivery-status">
      <SelectableChip
        variant="filled"
        loading={updating}
        disabled={!updatable}
        onChange={handleStatusUpdate}
        options={inlandTransportStatusOptions}
        value={{
          color: color,
          name: capitalize(currentStatus),
        }}
      />
    </Box>
  )
}

export default InlandTransportRowDeliveryStatus

import {
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
  useCallback,
  Fragment,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { useParams } from 'react-router'
import { entries, groupBy, sumBy, times, uniq } from 'lodash'
import MuiTable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import { Box, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import CreateRoundedIcon from '@mui/icons-material/CreateRounded'
import CostManagementDialog from 'src/pages/ShipmentPrice/ShipmentCostManagementDialog'
import { showNotification } from 'src/stores/actionCreators/notifications'
import CancelShipmentModal from 'src/components/CancelShipmentModal'
import ShipmentCostsQuote from 'src/components/ShipmentCostsQuote'
import TextPlaceholder from 'src/components/Common/TextPlaceholder'
import { Avatar } from '../../stories/Avatar'
import { promisifyAction, permissionTo } from '../../utils'
import EmptyResults from '../../components/Common/EmptyResults'
import { ContentDropdown } from '../../stories/ContentDropdown'
import useGoBack from '../../hooks/useGoBack'

import {
  shipmentTransactionItemsGetData,
  shipmentLayoutAcceptQuote,
  shipmentLayoutUpdateBooking,
} from '../../stores/actionCreators'

import AddCostsRow from './AddCostsRow'

import './styles.scss'
import ShipmentCostsEmptyState from './ShipmentCostsEmptyState'
import { CurrencyEnum } from './constants'

export interface IBuyer {
  id: number
  logo: string | null
  name: string
  role_code: string
}

type IGroupedItems = [string, ITransactionItem[]]

const ShipmentsPrice: FunctionComponent<{}> = () => {
  const { t } = useTranslation()
  const [cancelDialogOpen, setCancelDialogOpen] = useState<boolean>(false)
  const [isCostManagementDialogOpen, setCostManagementDialogOpen] = useState<
    boolean
  >(false)
  const [transactionsGroup, editTransactionsGroup] = useState<{
    buyer: IBuyer
    items: ITransactionItem[]
  } | null>(null)
  const [openItem, setOpenItem] = useState<number | null>(null)
  const [busy, setBusy] = useState<boolean>(false)
  const dispatch = useDispatch()
  const goBack = useGoBack()
  const params = useParams<{ id: string }>()

  const showShipmentCostManagementDialog = (
    buyer: IShipmentOrganization | IShipmentParty,
    items: ITransactionItem[]
  ) => {
    const { name, logo } = buyer
    if ('organization_id' in buyer) {
      editTransactionsGroup({
        buyer: {
          id: buyer.organization_id,
          role_code: buyer.organization_role_code,
          name,
          logo,
        },
        items,
      })
    } else {
      editTransactionsGroup({
        buyer,
        items,
      })
    }
    setCostManagementDialogOpen(true)
  }

  const shipmentTransactionItemsGetDataAsync = promisifyAction(
    dispatch,
    shipmentTransactionItemsGetData
  )

  const shipmentLayoutAcceptQuoteAsync = promisifyAction(
    dispatch,
    shipmentLayoutAcceptQuote
  )

  const shipmentLayoutUpdateBookingAsync = promisifyAction(
    dispatch,
    shipmentLayoutUpdateBooking
  )

  useEffect(() => {
    const getData = async () => {
      setBusy(true)
      await shipmentTransactionItemsGetDataAsync(params.id)
      setBusy(false)
    }

    getData()
  }, [])

  const {
    transactionItems,
    showOnlyOriginalCurrency,
    status,
    currentUser,
  } = useSelector((state: IGlobalState) => ({
    transactionItems: state.shipmentPrice.transactionItems,
    showOnlyOriginalCurrency: state.shipmentPrice.showOnlyOriginalCurrency,
    status: state.shipmentOverview.status,
    currentUser: state.user,
  }))

  const groupedBySellerBuyerPair = useMemo(
    () =>
      entries(
        groupBy(
          transactionItems,
          (item) =>
            `${item.seller_organization.id}_${item.buyer_organization.id}`
        )
      ),
    [transactionItems]
  )
  const currency = groupedBySellerBuyerPair[0]?.[1]?.[0]?.currency
  const shouldShowTotalUsdColumn =
    currency === CurrencyEnum.EUR || currency === CurrencyEnum.USD

  const isQuote = useMemo(() => status === 'QUO', [status])
  const isProposedQuote = useMemo(() => status === 'PQUO', [status])
  const isSIB = useMemo(() => status === 'SIB', [status])
  const isOnlyEur = useMemo(() => {
    const currencyArr = uniq(transactionItems.map((item) => item.currency))
    return currencyArr.length === 1 && currencyArr[0] === 'EUR'
  }, [transactionItems])

  const canManageSIB: boolean =
    isSIB && permissionTo('shipments.accept_sib.all')
  const canManageQuote: boolean =
    (isQuote || isProposedQuote) && permissionTo('shipments.accept_quote.all')
  const canManageCosts: boolean = permissionTo('shipments.costs.manage')

  const closeCancelModal = useCallback(() => {
    setCancelDialogOpen(false)
  }, [setCancelDialogOpen])

  const openCancelModal = useCallback(() => {
    setCancelDialogOpen(true)
  }, [setCancelDialogOpen])

  const acceptQuoteOrSIB = useCallback(() => {
    const acceptAsync = async (): Promise<any> => {
      if (isProposedQuote) {
        try {
          await shipmentLayoutAcceptQuoteAsync(parseInt(params.id))
          dispatch(
            showNotification({
              message: t(
                'shipment_costs.notifications.quote_accepted',
                'Quote has been accepted.'
              ),
              severity: 'success',
            })
          )
        } catch {
          dispatch(
            showNotification({
              message: t(
                'shipment_costs.notifications.quote_not_accepted',
                'Quote cannot be accepted.'
              ),
              severity: 'error',
            })
          )
        }
      } else if (isSIB) {
        try {
          await shipmentLayoutUpdateBookingAsync(
            parseInt(params.id),
            'approved'
          )
          dispatch(
            showNotification({
              message: t(
                'shipment_costs.notifications.shipment_status_updated',
                'Shipment status has been updated.'
              ),
              severity: 'success',
            })
          )
        } catch {
          dispatch(
            showNotification({
              message: t(
                'shipment_costs.notifications.shipment_status_not_updated',
                "Shipment status wasn't updated."
              ),
              severity: 'error',
            })
          )
        }
      }
    }

    acceptAsync()
  }, [status, params.id])

  const onToggleItem = (id: number): void => {
    setOpenItem(id)
  }

  const renderTransactionItem = (sellerItems: IGroupedItems, index: number) => {
    return (
      <ContentDropdown
        key={sellerItems[0]}
        id={index + 1}
        className="task-overview-block"
        header={costHeader(sellerItems, false)}
        headerCollapsed={costHeader(sellerItems, true)}
        body={costBody(sellerItems)}
        forcedOpen={
          groupedBySellerBuyerPair.length === 1 || openItem === index + 1
        }
        disableCollapse={false}
        onToggle={onToggleItem}
        testId="costs-body"
      />
    )
  }

  const loadingRows = (): React.ReactNode => {
    return (
      <MuiTable className="costs__items-table">
        <TableBody>
          {times(6, (index) => (
            <TableRow key={index} className="costs__items-table--body--row">
              <TableCell className="costs-name">
                <TextPlaceholder size={10} color="light-grey" />
              </TableCell>
              <TableCell className="costs-quantity">
                <TextPlaceholder size={2} color="light-grey" />
              </TableCell>
              <TableCell className="costs-price">
                <TextPlaceholder size={4} color="light-grey" />
              </TableCell>
              <TableCell className="costs-price">
                <TextPlaceholder size={4} color="light-grey" />
              </TableCell>
              <TableCell className="costs-price">
                <TextPlaceholder size={4} color="light-grey" />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    )
  }

  const notPriceRow = (item: ITransactionItem): React.ReactNode => {
    return (
      <TableRow className="costs__items-table--body--row">
        <TableCell className="costs-name">
          <Typography variant="body1" children={item.service_item.name} />
          <Typography variant="body1" children={item.description || ''} />
        </TableCell>
        <TableCell className="costs-quantity">
          <Typography variant="body1" children={item.quantity} />
          <div className="x-divider">x</div>
        </TableCell>
        <TableCell className="costs-price"></TableCell>
        <TableCell className="costs-price"></TableCell>
        <TableCell className="costs-price not-price">
          {item.on_request ? <i className="icon attention" /> : ''}
          <Typography
            variant="body1"
            children={
              item.on_request
                ? t(
                    'shipment_costs.notifications.price_required',
                    'Price is requested'
                  )
                : t('shipment_costs.notifications.price_included', 'Included')
            }
          />
        </TableCell>
      </TableRow>
    )
  }

  const renderUSDPrice = (item: ITransactionItem) => {
    if (showOnlyOriginalCurrency && item.currency !== 'USD') {
      return null
    }

    return (
      <>
        <Typography variant="body1" children={item.total_usd} />{' '}
        <Typography
          variant="body1"
          children={t('common.currencies.usd', 'USD')}
        />
      </>
    )
  }

  const renderEURPrice = (item: ITransactionItem) => {
    if (showOnlyOriginalCurrency && item.currency !== 'EUR') {
      return null
    }

    return (
      <>
        <Typography variant="body1" children={item.total_eur} />{' '}
        <Typography
          variant="body1"
          children={t('common.currencies.eur', 'EUR')}
        />
      </>
    )
  }

  const renderServiceItems = (services: IGroupedItems[]) => {
    const sortByPriority = (a, b) =>
      a.service_item.priority - b.service_item.priority

    return (services || []).map((service: IGroupedItems, i: number) => {
      return (
        <Fragment key={i}>
          <TableRow
            className="costs__items-table--body--row"
            data-testid="costs-items-table"
          >
            <TableCell className="costs-name">
              <Typography variant="body1Strong" children={service[0]} />
            </TableCell>
            <TableCell className="costs-quantity"></TableCell>
            <TableCell className="costs-price"></TableCell>
            {shouldShowTotalUsdColumn && (
              <TableCell className="costs-price"></TableCell>
            )}
            <TableCell className="costs-price"></TableCell>
          </TableRow>
          {(service[1] || [])
            .sort(sortByPriority)
            .map((item: ITransactionItem, i: number) => {
              if (item.included || item.on_request) {
                return notPriceRow(item)
              }

              return (
                <TableRow
                  key={`body--row${i}`}
                  className="costs__items-table--body--row"
                >
                  <TableCell className="costs-name">
                    <Typography
                      variant="body1"
                      children={item.service_item.name}
                    />
                    <Typography
                      variant="body1"
                      children={item.description || ''}
                    />
                  </TableCell>
                  <TableCell className="costs-quantity">
                    <Typography variant="body1" children={item.quantity} />
                    <div className="x-divider">x</div>
                  </TableCell>
                  <TableCell className="costs-price">
                    <Typography variant="body1" children={item.unit_price} />{' '}
                    <Typography variant="body1" children={item.currency} />
                  </TableCell>
                  {shouldShowTotalUsdColumn && (
                    <TableCell className="costs-price">
                      {renderUSDPrice(item)}
                    </TableCell>
                  )}
                  <TableCell className="costs-price">
                    {renderEURPrice(item)}
                  </TableCell>
                </TableRow>
              )
            })}
        </Fragment>
      )
    })
  }

  const costHeader = (
    sellerItems: IGroupedItems,
    collapsed: boolean
  ): React.ReactNode => {
    const seller = sellerItems[1][0].seller_organization
    const totalEur = sumBy(sellerItems[1], (data: ITransactionItem) =>
      Number(data.total_eur)
    )
    const groupedByBuyer = entries(
      groupBy(sellerItems[1], 'buyer_organization.id')
    )

    return (
      <div className="costs__row-header" data-testid="costs-row-header">
        <div className="costs__row-header--organizations">
          <div className="seller">
            <Avatar
              variant="circular"
              alt={seller.name}
              src={seller.logo || 'no-logo'}
              className={seller.role_code as any}
            />

            <div className="agent-name">{seller.name}</div>
          </div>
          {canManageCosts &&
            groupedByBuyer.map((buyerItems) => {
              const buyer = buyerItems[1][0].buyer_organization
              return (
                <div className="buyer" key={buyer.id}>
                  <Avatar
                    variant="circular"
                    alt={buyer.name}
                    src={buyer.logo || 'no-logo'}
                    className={buyer.role_code as any}
                  />

                  <div className="agent-name">{buyer.name}</div>
                </div>
              )
            })}
        </div>
        <div className="costs__row-header--total">
          <div className="price-value">
            <Typography
              variant="body1Strong"
              children={`${totalEur.toFixed(2)} ${t(
                'common.currencies.eur',
                'EUR'
              )}`}
            />
          </div>
          <div className="block-arrow">
            <i className={`icon chevron ${collapsed ? 'collapsed' : ''}`} />
          </div>
        </div>
      </div>
    )
  }

  const costBody = (sellerItems: IGroupedItems): React.ReactNode => {
    const sortByPriority = (a, b) =>
      a.service_item.service_priority - b.service_item.service_priority

    const groupedByService = groupBy(
      sellerItems[1].sort(sortByPriority),
      'service_item.service_name'
    )

    const totalEur: number = sumBy(sellerItems[1], (data: ITransactionItem) =>
      Number(data.total_eur)
    )
    const totalUsd: number = sumBy(sellerItems[1], (data: ITransactionItem) =>
      Number(data.total_usd)
    )

    const [sellerId] = sellerItems[0].split('_')

    const onClick = () => {
      showShipmentCostManagementDialog(
        sellerItems[1][0].buyer_organization,
        sellerItems[1]
      )
    }

    return (
      <>
        <MuiTable className="costs__items-table">
          <TableHead className="costs__items-table--header">
            <TableRow>
              <TableCell className="costs-name">
                {t('shipment_costs.table_header.service_item', 'Service Item')}
              </TableCell>
              <TableCell className="costs-quantity">
                {t('shipment_costs.table_header.quantity', 'Quantity')}
              </TableCell>
              <TableCell className="costs-price">
                {t('shipment_costs.table_header.unit_price', 'Unit Price')}
              </TableCell>
              {shouldShowTotalUsdColumn && (
                <TableCell className="costs-price">
                  {t('shipment_costs.table_header.total_usd', 'Total USD')}
                </TableCell>
              )}
              <TableCell className="costs-price">
                {t('shipment_costs.table_header.total_eur', 'Total EUR')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="costs__items-table--body">
            {renderServiceItems(entries(groupedByService))}
            <TableRow
              key="total-row"
              className="costs__items-table--body--total-row"
            >
              <TableCell className="costs-name"></TableCell>
              <TableCell className="costs-quantity"></TableCell>
              <TableCell className="costs-price"></TableCell>
              {shouldShowTotalUsdColumn && (
                <TableCell className="costs-price">
                  <>
                    <Typography
                      variant="body1Strong"
                      children={totalUsd.toFixed(2)}
                    />{' '}
                    <Typography
                      variant="body1Strong"
                      children={t('common.currencies.usd', 'USD')}
                    />
                  </>
                </TableCell>
              )}
              <TableCell className="costs-price">
                <Typography
                  variant="body1Strong"
                  data-testid="total-eur"
                  children={totalEur.toFixed(2)}
                />{' '}
                <Typography
                  variant="body1Strong"
                  children={t('common.currencies.eur', 'EUR')}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </MuiTable>
        {!isOnlyEur ? (
          <div className="alert-container">
            <Alert variant="filled" severity="warning">
              {t(
                'shipment_costs.notifications.costs_are_subject_to_fluctuations',
                'These costs are subject to exchange rate fluctuations'
              )}
            </Alert>
          </div>
        ) : null}
        {canManageCosts && currentUser.organizationId.toString() === sellerId && (
          <div className="costs__items-button--edit">
            <Button
              children={t('common.buttons.edit_costs', 'Edit costs')}
              onClick={onClick}
              variant="contained"
              startIcon={<CreateRoundedIcon />}
              data-testid="add-costs-btn"
            />
          </div>
        )}
      </>
    )
  }

  const shipmentEmpty: React.ReactNode = (
    <>
      <EmptyResults>
        <b>
          {t(
            'shipment_costs.empty_state.title',
            'Prices for this shipment are not known yet.'
          )}
        </b>
        <b>
          {t(
            'shipment_costs.empty_state.description',
            'We will inform you when our offer is complete.'
          )}
        </b>
        {(isProposedQuote && canManageQuote) || (isSIB && canManageSIB) ? (
          <>
            <Box mt={1}>
              <Button variant="outlined" onClick={acceptQuoteOrSIB}>
                {t('common.buttons.book', 'Book')}
              </Button>
            </Box>

            <Box mt={1} mb={1}>
              <Button onClick={openCancelModal}>
                {t('common.buttons.cancel_shipment', 'Cancel shipment')}
              </Button>
            </Box>
          </>
        ) : (
          ''
        )}

        <Box mt={1}>
          <Trans
            i18nKey="shipment_costs.empty_state.helper_text"
            defaults="If there are any questions, contact us <0>via chat</0>."
            components={[<Link to={`/shipments/${params.id}/chats`} />]}
          />
        </Box>
      </EmptyResults>
    </>
  )

  const closeShipmentCostManagementDialog = () =>
    setCostManagementDialogOpen(false)

  const loadNewTransactionItems = () =>
    shipmentTransactionItemsGetDataAsync(params.id)

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `.shipment-layout__content {
                      padding: 0;
                     }`,
        }}
      />
      <div className="costs">
        {(isQuote || isProposedQuote || isSIB) && (
          <div className="costs__header">
            <div className="costs__header-title">
              {t('shipment_costs.costs', 'Costs')}
            </div>
            <div className="costs__header-actions">
              <ShipmentCostsQuote />
            </div>
          </div>
        )}
        {transactionItems.length > 0 && (
          <div className="costs__sub-header">
            <div className="costs__sub-header--title">
              {t('shipment_costs.offered_by', 'Offered by')}
            </div>
            {canManageCosts && (
              <div className="costs__sub-header--title offered-to">
                {t('shipment_costs.offered_to', 'Offered to')}
              </div>
            )}
          </div>
        )}
        {!busy && transactionItems.length === 0 && (
          <ShipmentCostsEmptyState
            isQuote={isQuote}
            canManageCosts={canManageCosts}
            canManageQuote={canManageQuote}
            openCancelModal={openCancelModal}
            handleCostsDialog={showShipmentCostManagementDialog}
            shipmentEmpty={shipmentEmpty}
            shipmentId={params.id}
          />
        )}
        {transactionItems.length > 0 && (
          <div className="costs__body" data-testid="costs__body">
            {busy
              ? loadingRows()
              : (groupedBySellerBuyerPair || []).map(
                  (sellerItems: IGroupedItems, index: number) => {
                    return renderTransactionItem(sellerItems, index)
                  }
                )}
            <AddCostsRow onClick={showShipmentCostManagementDialog} />
          </div>
        )}
      </div>
      <CancelShipmentModal
        open={cancelDialogOpen}
        close={closeCancelModal}
        afterCancel={goBack}
        shipmentId={parseInt(params.id)}
      />
      {canManageCosts && isCostManagementDialogOpen && (
        <CostManagementDialog
          items={transactionsGroup?.items || []}
          buyer={transactionsGroup?.buyer || null}
          onClose={closeShipmentCostManagementDialog}
          onSave={loadNewTransactionItems}
        />
      )}
    </>
  )
}

export default ShipmentsPrice

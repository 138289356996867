import { AnyAction } from 'redux'
import * as actions from '../actions/users'

export const usersGetData = (id): AnyAction => ({
  type: actions.USERS_GET_DATA,
  id,
})

export const usersGetDataSuccess = (payload): AnyAction => ({
  type: actions.USERS_GET_DATA_SUCCESS,
  payload,
})

export const usersUpdateData = (id, payload: any): AnyAction => ({
  type: actions.USERS_UPDATE_DATA,
  id,
  payload,
})

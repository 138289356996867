import { useEffect, useState } from 'react'
import { findKey, get } from 'lodash'
import { useSelector } from 'react-redux'
import {
  useGetPackageTypes,
  useGetOrganizationShipmentRoles,
  useGetContainerTypes,
} from 'src/services/Api/common'
import { OrganizationRoleEnum } from 'src/config/constants'
import { useTranslation } from 'react-i18next'
import { IShipmentTemplateResponse } from 'src/@types/endpoints/shipmentTemplates'
import { FormProvider } from './FormContext'
import TemplatesFormEdit from './components/Pages/Edit'
import TemplatesFormCreate from './components/Pages/Create'
import TemplatesFormRebook from './components/Pages/Rebook'
import { initialState, initialFormErrors } from './Form.constants'

import {
  getShipmentRoles,
  getEditAndCreateSteps,
  getCargoDetailsRequiredFields,
  mapTemplateResponsePayloadToFormState,
} from './Form.utils'

interface TemplatesFormProps {
  templateData: IShipmentTemplateResponse | undefined | null
  isLoading: boolean
  mode: 'create' | 'edit' | 'rebook'
}

const TemplatesForm: React.FC<TemplatesFormProps> = ({
  templateData,
  isLoading,
  mode = 'create',
}) => {
  const { t } = useTranslation()
  const [formState, setFormState] = useState({ ...initialState })
  const [formErrors, setFormErrors] = useState({ ...initialFormErrors })
  const { data: organizationShipmentRoles } = useGetOrganizationShipmentRoles({
    refetchOnMount: false,
  })
  const { data: packageTypes } = useGetPackageTypes({
    refetchOnMount: false,
  })

  const { data: containerTypes } = useGetContainerTypes({
    refetchOnMount: false,
  })

  const { user } = useSelector((state: IGlobalState) => ({
    user: state.user,
  }))

  const isUserOrganizationCustomer =
    user.organizationRole === OrganizationRoleEnum.Customer

  const editAndCreateSteps = getEditAndCreateSteps(
    t,
    isUserOrganizationCustomer
  )

  const selectedPackageType =
    packageTypes?.find(
      (packageType) => packageType.id === get(formState, 'cargo.packageTypeId')
    ) ?? null

  const selectedContainerType =
    containerTypes?.find(
      (containerType) =>
        containerType.code === get(formState, 'container.containerTypeEnum')
    ) ?? null

  const userPreferredShipmentRole = user.preferredShipmentRoleId

  const defaultUserOrganization = {
    value: user.organizationId,
    label: user.organizationName,
  }

  const shipmentRoles = getShipmentRoles(organizationShipmentRoles)
  const userPreferredShipmentRoleCode =
    findKey(shipmentRoles, (v) => {
      return v === userPreferredShipmentRole
    }) ?? ''

  const onChange = (newPartiallyUpdatedForm) => {
    setFormState((prevState) => ({
      ...prevState,
      ...newPartiallyUpdatedForm,
    }))
  }

  const onFormErrorChange = (newPartiallyUpdatedForm) => {
    setFormErrors((prevState) => ({
      ...prevState,
      ...newPartiallyUpdatedForm,
    }))
  }

  useEffect(() => {
    const initialFormState = mapTemplateResponsePayloadToFormState({
      templateData,
      shipmentRoles,
      userPreferredShipmentRole,
      userPreferredShipmentRoleCode,
      defaultUserOrganization,
    })
    setFormState(initialFormState)
  }, [isLoading])

  const cargoDetailsRequiredFields = getCargoDetailsRequiredFields(
    formState.loadType,
    formState.modality,
    mode
  )

  const steps = editAndCreateSteps.map((step) => {
    if (step.key === 'loadDetails') {
      return {
        ...step,
        requiredFields: cargoDetailsRequiredFields,
      }
    }
    if (step.key === 'details' && mode === 'rebook') {
      return {
        ...step,
        requiredFields: [
          ...(step?.requiredFields ?? []),
          'booking.cargoReadyDate',
        ],
      }
    }
    return step
  })

  return (
    <FormProvider
      value={{
        mode,
        formState,
        onChange,
        isLoading,
        formErrors,
        shipmentRoles,
        onFormErrorChange,
        selectedPackageType,
        selectedContainerType,
        cargoDetailsRequiredFields,
      }}
    >
      {mode === 'create' && <TemplatesFormCreate steps={steps} />}
      {mode === 'edit' && <TemplatesFormEdit steps={steps} />}
      {mode === 'rebook' && <TemplatesFormRebook steps={steps} />}
    </FormProvider>
  )
}

export default TemplatesForm

import { createReducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import * as actions from '../actions/organizationSettings'

declare global {
  interface ISLA {
    id: number
    name: string
    created_at: string
    dt_days: number
    owner: ISLAOwner | null
    sla_checkpoint_addresses: ISLAAddress[]
    sla_checkpoint_addresses_attributes?: ISLAAddress[]
    sla_checkpoint_type: ISLACheckpointType | null
    sla_checkpoint_value: ISLACheckpointValue | null
    sla_checkpoint_type_id?: number
    sla_checkpoint_value_id?: number
    dt_days_id?: number
    due_date_message_status: IDueDateMessage
    has_info_requests: boolean
  }

  interface ISLAOwner {
    id: number
    email: string
    full_name: string
    organization_name: string
  }

  interface IDueDateMessage {
    due_date: string | null
    message_status: string
  }

  interface ISLAAddress {
    id?: number
    address: any
    address_id?: number
    address_attributes?: IAddressAttributes
    automated_alert: boolean
    _destroy?: boolean
  }

  interface ISLACheckpointValue {
    id: number
    date_field: string
    description: string
  }

  interface ISLACheckpointType {
    id: number
    code: string
    name: string
  }

  interface IOrganizationsSettingsState {
    list: ISLA[]
    openItem: number | null
    users: any[]
    api_token: string
  }

  interface IAddressAttributes {
    id?: number
    email: string
  }
}

export const dummySLA: ISLA = {
  id: 0,
  name: '',
  created_at: '',
  dt_days: 0,
  owner: null,
  has_info_requests: false,
  sla_checkpoint_addresses: [
    {
      address_id: 0,
      address: null,
      automated_alert: false,
    },
  ],
  sla_checkpoint_type: null,
  sla_checkpoint_value: null,
  sla_checkpoint_type_id: 1,
  sla_checkpoint_value_id: 1,
  dt_days_id: 1,
  due_date_message_status: {
    due_date: null,
    message_status: '',
  },
}

export const initialOrganizationSettingsState: IOrganizationsSettingsState = {
  list: [],
  users: [],
  api_token: '',
  openItem: null,
}

export default createReducer(initialOrganizationSettingsState, {
  [actions.SLA_GET_DATA_SUCCESS]: (state, action: AnyAction) => ({
    ...state,
    list: action.payload,
  }),
  [actions.ORGANIZATION_USERS_GET_DATA_SUCCESS]: (
    state,
    action: AnyAction
  ) => ({
    ...state,
    users: action.payload,
  }),
  [actions.GET_ORGANIATION_API_TOKEN_SUCCESS]: (state, action: AnyAction) => ({
    ...state,
    api_token: action.payload.data.api_token,
  }),
  [actions.DELETE_ORGANIATION_API_TOKEN_SUCCESS]: (state) => ({
    ...state,
    api_token: '',
  }),
  [actions.SLA_OPEN_ITEM]: (state, action: AnyAction) => ({
    ...state,
    openItem: action.payload,
  }),
})

import { FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Link } from '@mui/material'

import TabPanel from 'src/components/Common/TabPanel'
import { convertDatePickerToUniversalFormat } from '../../../utils/helpers'
import PurchaseOrderEvent from './PurchaseOrderEvent'
import PurchaseOrderSLA from './PurchaseOrderSLA'
import './styles.scss'

interface IProps {
  purchaseOrder: IPurchaseOrder
  onUnmark?: (eventId: number) => void
  publicPage?: boolean
  editItem?: () => void
}

const tabClasses = {
  root: 'shipment-layout__tab bookings-page-tab mui-override',
  wrapper: 'shipment-layout__tab-text-wrapper mui-override',
  selected: 'shipment-layout__tab_selected mui-override',
}

const POTabs: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const [tabValue, setTabValue] = useState<string>('events')

  const shortify = (value: string): string => {
    return value.length > 40 ? `${value.substring(0, 35)}...` : value
  }

  const onTabsChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTabValue(newValue)
  }

  const getSLAs = (): React.ReactNode => {
    return (
      <PurchaseOrderSLA
        slaCheckpoints={props.purchaseOrder.sla_checkpoints}
        editItem={props.editItem}
      />
    )
  }

  const getEvents = (): React.ReactNode => {
    if (
      props.purchaseOrder.purchase_order_events &&
      props.purchaseOrder.purchase_order_events.length
    ) {
      return (
        <div className="purchase-order-events-container">
          {props.purchaseOrder.purchase_order_events.map(
            (event: IPurchaseOrderEvent, i: number) => (
              <PurchaseOrderEvent
                publicPage={!!props.publicPage}
                event={event}
                key={i}
                onUnmark={props.onUnmark}
              />
            )
          )}
        </div>
      )
    } else {
      return (
        <div className="message-title">
          {t(
            'purchase_orders.table.tabs.events.empty_state',
            'No events available'
          )}
        </div>
      )
    }
  }

  const getDocuments = (): React.ReactNode => {
    const { original_po_document } = props.purchaseOrder
    if (!!original_po_document) {
      return (
        <div className="po-tabs--document-container">
          <div className="document-column">
            <i className="icon file" />
          </div>
          <div className="document-column">
            <Link
              variant="body1"
              href={original_po_document.download_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {shortify(original_po_document.original_filename)}
            </Link>
          </div>
          <div className="document-column">
            {convertDatePickerToUniversalFormat(
              original_po_document.created_at || ''
            )}
          </div>
        </div>
      )
    } else {
      return (
        <div className="message-title">
          {t(
            'purchase_orders.table.tabs.documents.empty_state',
            'No document available'
          )}
        </div>
      )
    }
  }

  const tableLabel = (label: string, icon: string): React.ReactNode => {
    return (
      <div className="iconed-label">
        <span>
          <i className={`icon ${icon}`} />
        </span>
        {label}
      </div>
    )
  }

  return (
    <div className="po-tabs">
      <Tabs
        value={tabValue}
        classes={{
          root: 'shipment-layout__tab-panel docs-panel mui-override',
          indicator: 'shipment-layout__tab-indicator mui-override',
          flexContainer: 'shipment-layout__tab-container mui-override',
        }}
        onChange={onTabsChange}
      >
        <Tab
          label={tableLabel(
            t('purchase_orders.table.tabs.events.title', 'Events'),
            'events'
          )}
          value="events"
          classes={tabClasses}
        />
        <Tab
          label={tableLabel(
            t(
              'purchase_orders.table.tabs.sla.title',
              'Service level agreements'
            ),
            'supplier-initiated'
          )}
          value="sla"
          classes={tabClasses}
        />
        <Tab
          label={tableLabel(
            t('purchase_orders.table.tabs.documents.title', 'Documents'),
            'paperclip'
          )}
          value="documents"
          classes={tabClasses}
        />
      </Tabs>
      <TabPanel value={tabValue} index="sla" className="po-tabs--tabpanel">
        {getSLAs()}
      </TabPanel>
      <TabPanel
        value={tabValue}
        index="documents"
        className="po-tabs--tabpanel"
      >
        {getDocuments()}
      </TabPanel>
      <TabPanel value={tabValue} index="events" className="po-tabs--tabpanel">
        {getEvents()}
      </TabPanel>
    </div>
  )
}
export default POTabs

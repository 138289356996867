import { FormHelperText, TextField } from '@mui/material'
import { find } from 'lodash'
import { useEffect, useState } from 'react'
import SelectorButton from 'src/components/SelectorButton'
import { IMenuOriginProps } from '../MenuButton/interfaces'

interface IContact {
  id: number
  name: string
  company_name: string
}

type IOption = [string, number]
type IPerson = [string, number, string]

interface IProps {
  label?: string
  field: string
  contactType: string
  contacts?: IContact[]
  value?: string | number | null
  className?: string
  popperClassName?: string
  disabled?: boolean | false
  multiLine?: boolean | false
  displaySelect?: boolean | false
  errorNotes?: string | null
  onChange: (fieldName: string, value: string) => void
  onBlur?: (
    fieldName: string,
    event: React.FocusEvent<HTMLInputElement>
  ) => void
  onContactSelect: (value: IAddress) => void
  clearState?: () => void
  'data-testid'?: string
}

interface IState {
  options: IOption[]
  persons: IPerson[]
}

const toOption = ({ name, id }: IContact): IOption => [name, id]
const toPerson = ({ name, id, company_name }: IContact): IPerson => [
  name,
  id,
  company_name,
]

function FormFieldTextBasic(props: IProps) {
  const [state, setState] = useState<IState>({
    options: [],
    persons: [],
  })

  const updateOptions = () => {
    const { contacts } = props
    setState({
      options: contacts ? contacts.map(toOption) : [],
      persons: contacts ? contacts.map(toPerson) : [],
    })
  }

  const renderSelectorButton = (buttonProps: IMenuOriginProps) => {
    return (
      <div
        role="button"
        key={buttonProps.key}
        className="contact-select"
        onClick={buttonProps.onClick}
      >
        <i
          aria-owns={buttonProps['aria-owns'] || ''}
          aria-haspopup={buttonProps['aria-haspopup'] || 'false'}
          ref={buttonProps.anchorRef}
          className="icon address-book"
        />
      </div>
    )
  }

  const handleItemClick = (id: string | number) => {
    const { contacts, onContactSelect } = props
    const contact = find(contacts, { id })
    if (contact) {
      onContactSelect(contact as any)
    }
  }

  const clearContact = () => {
    props.clearState?.()
  }

  const renderSelect = () => {
    const { displaySelect, contactType, popperClassName } = props
    if (displaySelect) {
      return (
        <SelectorButton
          contactType={contactType}
          hasLimitedHeight={true}
          isSearchable={true}
          isShippingInstuctions={true}
          renderOrigin={renderSelectorButton}
          popperClassName={popperClassName}
          onChange={handleItemClick}
          clearContact={clearContact}
          options={contactType === 'company' ? state.options : state.persons}
        />
      )
    }
  }

  const handleChange = (event: any) => {
    props.onChange(props.field, event.target.value)
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    props.onBlur?.(props.field, event)
  }

  useEffect(() => {
    updateOptions()
  }, [])

  useEffect(() => {
    updateOptions()
  }, [props.contacts])

  return (
    <div className="form-field">
      {renderSelect()}
      <TextField
        variant="standard"
        label={props.label}
        fullWidth={true}
        multiline={props.multiLine}
        disabled={props.disabled}
        className={props.className || ''}
        margin="normal"
        error={!!props.errorNotes}
        value={props.value || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        InputLabelProps={{
          classes: {
            focused: 'focused',
            root: 'label shipping-instructions__shypple-font',
            error: 'error',
            disabled: 'disabled',
          },
        }}
        InputProps={{
          classes: {
            focused: 'focused',
            root: 'input shipping-instructions__shypple-font',
            error: 'error',
            disabled: 'disabled',
          },
        }}
        data-testid={props['data-testid']}
      />
      {props.errorNotes && (
        <FormHelperText className="form-field__red">
          {props.errorNotes}
        </FormHelperText>
      )}
    </div>
  )
}

export default FormFieldTextBasic

import { FunctionComponent } from 'react'
import '../styles.scss'
import { LoadingButton } from '@mui/lab'

type Theme = 'PO' | 'shipmentPO' | 'slaPO'

interface IProps {
  theme: Theme
  type: string
  linkBlock?: React.ReactNode
  buttonAction?: () => void
  buttonColor?: string
  buttonText?: string
  buttonLoading?: boolean
  iconsArray: any[]
}

const Action: FunctionComponent<IProps> = (props) => {
  return (
    <div className={`common-blank-state ${props.theme}`}>
      <div className="common-blank-state--action_block">
        {(props.iconsArray || []).map((item: any, index: number) => {
          return (
            <div key={index} className="flex">
              <div className="common-blank-state--action_block--item">
                <div className="circle-icon">
                  <i className={`icon ${item.icon}`} />
                </div>
                <div className="icon-desc paragraph__medium black">
                  {item.text}
                </div>
              </div>
              {props.iconsArray.length - 1 !== index && (
                <div className="common-blank-state--arrow">
                  <i className="icon chevron" />
                </div>
              )}
            </div>
          )
        })}
      </div>
      {props.type === 'link' && (
        <div className="common-blank-state--action_desc">{props.linkBlock}</div>
      )}
      {props.type === 'button' && (
        <div className="common-blank-state--button_block">
          <LoadingButton
            variant="contained"
            onClick={props.buttonAction}
            loading={props.buttonLoading}
          >
            {props.buttonText || ''}
          </LoadingButton>
        </div>
      )}
    </div>
  )
}

export default Action

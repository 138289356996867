import { useState } from 'react'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import LibraryAddIcon from '@mui/icons-material/LibraryAddOutlined'
import ShipmentTemplateDialogContent from './ShipmentTemplateDialogContent'

const ShipmentTemplateDialog = ({ shipmentId }) => {
  const [open, setOpen] = useState(false)

  const handleDialogOpen = () => {
    setOpen(true)
  }

  const handleDialogClose = () => {
    setOpen(false)
  }

  return (
    <Box data-testid="save-template-from-shipment">
      <Tooltip title="Save this shipment as a template">
        <IconButton
          sx={{ mx: 1 }}
          size="small"
          color="primary"
          component="label"
          onClick={handleDialogOpen}
          aria-label="Save this shipment as a template"
          data-testid="save-template-from-shipment-trigger"
        >
          <LibraryAddIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        maxWidth="sm"
        onClose={handleDialogClose}
        data-testid="save-template-from-shipment-dialog"
      >
        <DialogTitle>
          Save as new template
          <IconButton
            sx={{ p: 0.5 }}
            aria-label="Close dialog"
            onClick={handleDialogClose}
            data-testid="save-template-from-shipment-dialog-close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Shipments can be saved to make rebooking easier. Once saved you will
            find it on the Templates page. There you can manage your templates.
          </Typography>
        </DialogContent>
        <Box minHeight={154}>
          {open && (
            <ShipmentTemplateDialogContent
              shipmentId={shipmentId}
              onClose={handleDialogClose}
            />
          )}
        </Box>
      </Dialog>
    </Box>
  )
}

export default ShipmentTemplateDialog

import { FunctionComponent, useState, useEffect } from 'react'
import './styles.scss'

interface IProps {
  isActive: boolean
  sortDirection: string
  setSort: (direction: string, id: string) => void
  sortColumn: string
}

const SortingIconsBlock: FunctionComponent<IProps> = (props) => {
  const [highlightUp, setHighlightUp] = useState<boolean>(false)
  const [highlightDown, setHighlightDown] = useState<boolean>(false)

  useEffect(() => {
    switch (true) {
      case props.sortDirection === 'asc':
        setHighlightUp(true)
        setHighlightDown(false)
        break
      case props.sortDirection === 'desc':
        setHighlightUp(false)
        setHighlightDown(true)
        break
    }
  }, [props])

  const clickUp = (): void => {
    setHighlightUp(true)
    setHighlightDown(false)
    props.setSort('asc', props.sortColumn)
  }

  const clickDown = (): void => {
    setHighlightUp(false)
    setHighlightDown(true)
    props.setSort('desc', props.sortColumn)
  }

  return (
    <div className="table-head-sorting-block">
      <i
        className={`icon sort-arrow ${
          highlightUp && props.isActive ? 'active' : ''
        }`}
        onClick={clickUp}
      />
      <i
        className={`icon sort-arrow ${
          highlightDown && props.isActive ? 'active' : ''
        } down`}
        onClick={clickDown}
      />
    </div>
  )
}

export default SortingIconsBlock

import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Input from 'src/components/Common/Input/MuiInput'
import { companiesGetData } from 'src/stores/actionCreators'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import SearchBar from 'src/stories/SearchBar'
import { toNumber } from 'lodash'
import { validationErrorsFor, cannotBeBlank, shouldBeEmail } from '../utils'
import SaveContactButton from './SaveContactButton'
import UpdateContactButton from './UpdateContactButton'
import DeleteContactButton from './DeleteContactButton'

const validations = {
  name: [cannotBeBlank],
  email: [cannotBeBlank, shouldBeEmail],
  phone: [cannotBeBlank],
}

const useStyles = makeStyles(() => ({
  formContainer: {
    width: 800,
  },
  spacing: {
    height: 23,
  },
}))

const ContactEditPage = ({ contact }: { contact?: IPersonalDetail }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { companies } = useSelector((state: IGlobalState) => ({
    companies: state.companies.list,
  }))

  const [formData, setFormData] = useState(contact || ({} as IPersonalDetail))
  const [validationErrors, setValidationErrors] = useState({})
  const [selectedItem, setSelected] = useState<{
    id: number
    name: string
  } | null>(null)

  useEffect(() => {
    dispatch(companiesGetData())
  }, [])

  useEffect(() => {
    if (contact) {
      setFormData(contact)
    }
  }, [contact])

  useEffect(() => {
    setValidationErrors(validationErrorsFor(formData, validations))
    if (!!formData.company_address_id) {
      const company = companies.find(
        (company) => toNumber(company[1]) === formData.company_address_id
      )
      setSelected(
        company ? { id: toNumber(company[1]), name: company[0] } : null
      )
    }
  }, [formData.company_address_id])

  useEffect(() => {
    if (selectedItem?.id) {
      setFormData({
        ...formData,
        company_address_id: toNumber(selectedItem.id),
      })
    }
  }, [selectedItem])

  const onChange = (value: string | number, name: string) => {
    setValidationErrors({ ...validationErrors, [name]: null })
    setFormData({ ...formData, [name]: value })
  }

  const { t } = useTranslation()

  const textFieldProps = (name: string) => ({
    name: name,
    required: true,
    value: formData[name] || '',
    onChange,
    error: !!validationErrors[name],
    helperText: validationErrors[name],
  })

  const isFormInvalid = useMemo(() => {
    return (
      Object.keys(validationErrors).length > 0 &&
      Object.keys(validationErrors).some(
        (name) => !!validationErrors[name] === true
      )
    )
  }, [validationErrors])

  const renderInput = (name, placeholder, label) => (
    <>
      <Input
        placeholder={placeholder}
        label={label}
        {...textFieldProps(name)}
      />
      {!validationErrors[name] && <Box className={classes.spacing} />}
    </>
  )

  const isFormUpdated = useMemo(() => {
    const fields = ['name', 'email', 'phone', 'company_address_id']

    return contact && fields.some((field) => contact[field] !== formData[field])
  }, [formData, contact])

  return (
    <Box className={classes.formContainer}>
      <form noValidate>
        <Box
          display="flex"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <Box mb={2}>
            <Typography variant="h3">
              {t('address_book.add_contact.header')}
            </Typography>
          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {renderInput(
              'name',
              t('address_book.add_contact.enter_name'),
              t('address_book.add_contact.name')
            )}
            {renderInput(
              'email',
              t('address_book.add_address.enter_email_address'),
              t('address_book.add_address.email_address')
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderInput(
              'phone',
              t('address_book.add_address.enter_phone_number'),
              t('address_book.add_address.phone_number')
            )}
            <SearchBar
              id="company_address_id"
              optionLabel={(option) => option.name}
              label={t('address_book.add_address.company_name')}
              value={selectedItem}
              onChange={setSelected}
              options={companies.map((country) => ({
                id: toNumber(country[1]),
                name: country[0],
              }))}
              placeholder={t('common.select', 'Select')}
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" mt={4.25}>
          {contact && (
            <>
              <DeleteContactButton contact={contact} />
              <Box mr={3} />
              <UpdateContactButton
                contactId={contact.id}
                contact={formData}
                disabled={isFormInvalid || !isFormUpdated}
              />
            </>
          )}
          <Box display="flex" alignItems="center">
            {!contact && (
              <SaveContactButton
                onClick={(saveContact, showError) => {
                  const errors = validationErrorsFor(
                    formData,
                    validations,
                    true
                  )
                  if (Object.keys(errors).length === 0) {
                    saveContact(formData)
                  } else {
                    setValidationErrors(errors)
                    showError(
                      'Your contact could not be saved. Please see the error message(s)'
                    )
                  }
                }}
                disabled={isFormInvalid}
              />
            )}
          </Box>
        </Box>
      </form>
    </Box>
  )
}

export default ContactEditPage

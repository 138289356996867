import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Tooltip } from '@mui/material'
import Table from 'src/components/Common/Table'
import { lineHeaders } from '../tableHeaders'
import {
  totalVolumeFormatted,
  totalWeightFormatted,
  statusFormatted,
} from '../../../components/PurchaseOrders/PurchaseOrderLine/helpers'

interface IProps {
  purchaseOrder: IPurchaseOrder
}

const PublicPOLinesTable: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  return (
    <Table.StripedTable theme="organizationSettings PO-tool">
      <Table.StripedTableHeader cells={lineHeaders(t)} />
      <Table.StripedTableBody>
        {props.purchaseOrder.purchase_order_lines.map((po_line, index) => {
          const isEmpty: boolean = !po_line.units_ordered
          const shipment = po_line.booking_lines[0].shipment
          return (
            <Table.StripedTableRow index={index} key={index}>
              <Table.StripedTableCell className="short-50">
                {isEmpty ? '-' : `${po_line.units_ordered} x`}
              </Table.StripedTableCell>
              <Table.StripedTableCell className="left-aligned wide">
                {isEmpty ? (
                  <div className="text-wrapper paragraph__medium strong">
                    {t(
                      'purchase_orders.table.po_line_table.empty_state',
                      'No products specified'
                    )}
                  </div>
                ) : (
                  <Tooltip title={po_line.product_name} placement="top">
                    <div className="text-wrapper paragraph__medium strong ">
                      {po_line.product_name}
                    </div>
                  </Tooltip>
                )}
              </Table.StripedTableCell>
              <Table.StripedTableCell className="left-aligned">
                {po_line.product_code}
              </Table.StripedTableCell>
              <Table.StripedTableCell className="left-aligned">
                {isEmpty ? '' : totalVolumeFormatted(po_line) || '-'}
              </Table.StripedTableCell>
              <Table.StripedTableCell className="left-aligned">
                {isEmpty ? '' : totalWeightFormatted(po_line) || '-'}
              </Table.StripedTableCell>
              <Table.StripedTableCell className="left-aligned">
                {
                  <div
                    className={`paragraph__medium purchase-order-line__status strong ${po_line.status}`}
                  >
                    {statusFormatted(t, po_line)}
                  </div>
                }
              </Table.StripedTableCell>
              <Table.StripedTableCell className="left-aligned">
                {shipment ? (
                  shipment.public_shipment_link ? (
                    <Link
                      variant="body1"
                      href={shipment.public_shipment_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SF{shipment.id}{' '}
                      {shipment.reference ? `| ${shipment.reference}` : ''}
                    </Link>
                  ) : (
                    <span>
                      SF{shipment.id}{' '}
                      {shipment.reference ? `| ${shipment.reference}` : ''}
                    </span>
                  )
                ) : null}
              </Table.StripedTableCell>
              <Table.StripedTableCell></Table.StripedTableCell>
              <Table.StripedTableCell></Table.StripedTableCell>
              <Table.StripedTableCell></Table.StripedTableCell>
            </Table.StripedTableRow>
          )
        })}
      </Table.StripedTableBody>
    </Table.StripedTable>
  )
}
export default PublicPOLinesTable

import Button from '@mui/material/Button'
import SaveAltIcon from '@mui/icons-material/SaveAltRounded'
import {
  downloadFile,
  getCsvUrlFromTwoDimensionalArray,
} from 'src/utils/helpers/content'
import addAgreementTemplate from 'src/assets/files/demurrageAndDetentionOneViewTemplate.csv'
import { useTranslation } from 'react-i18next'

const DemurrageAndDetentionContentAddAgreementDownload = () => {
  const { t } = useTranslation()
  const handleClickOnDownloadButton = () => {
    downloadFile(
      getCsvUrlFromTwoDimensionalArray(addAgreementTemplate),
      'demurrageAndDetentionOneViewTemplate.csv'
    )
  }
  return (
    <>
      <Button
        size="large"
        variant="contained"
        startIcon={<SaveAltIcon />}
        children={t('common.buttons.download_template', 'Download template')}
        onClick={handleClickOnDownloadButton}
      />
    </>
  )
}

export default DemurrageAndDetentionContentAddAgreementDownload

import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { SelectClearButtonProps } from './Select.props'

export const SelectClearButton: React.FC<SelectClearButtonProps> = ({
  visible,
  onClick,
}) => {
  if (visible) {
    return (
      <IconButton
        size="small"
        onClick={onClick}
        sx={{ position: 'absolute', right: 30 }}
        data-testid="select-clear-button"
      >
        <CloseIcon />
      </IconButton>
    )
  }
  return null
}

import { FunctionComponent, useCallback, useState } from 'react'
import { Dispatch } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { sumBy } from 'lodash'
import CancelShipmentModal from 'src/components/CancelShipmentModal'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { shipmentOverviewReQuote } from 'src/stores/actionCreators/shipmentOverview'
import { Button, Link, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  shipmentLayoutAcceptQuote,
  shipmentLayoutUpdateBooking,
} from '../../stores/actionCreators'
import useGoBack from '../../hooks/useGoBack'
import { promisifyAction, permissionTo } from '../../utils'
import { ShipmentCostsLoadingButton } from '../ShipmentCosts/ShipmentCostsLoadingButton'
import ShipmentCostsQuoteRequestRateMatch from './ShipmentCostsQuoteRequestRateMatch'

const ShipmentCostsQuote: FunctionComponent<{}> = () => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()

  const { shipmentId, status, validUntil, transactionItems } = useSelector(
    (globalState: IGlobalState) => ({
      shipmentId: globalState.shipmentOverview.id,
      status: globalState.shipmentOverview.status,
      transactionItems: globalState.shipmentPrice.transactionItems,
      validUntil: globalState.shipmentOverview.quote_expiration_at,
    })
  )

  const goBack = useGoBack()

  const acceptQuote = promisifyAction(dispatch, shipmentLayoutAcceptQuote)
  const updateBooking = promisifyAction(dispatch, shipmentLayoutUpdateBooking)
  const requestShipmentReQuote = promisifyAction(
    dispatch,
    shipmentOverviewReQuote
  )
  const showSuccess = (message: string) => {
    dispatch(showNotification({ message, severity: 'success' }))
  }
  const showError = (message: string) => {
    dispatch(showNotification({ message, severity: 'error' }))
  }

  const [cancelDialogOpen, setCancelDialogOpen] = useState<boolean>(false)
  const [reQuoting, setReQuoting] = useState<boolean>(false)

  const isQuote: boolean = status === 'QUO'
  const isProposedQuote: boolean = status === 'PQUO'
  const isSIB: boolean = status === 'SIB'

  const cancelText: string = isSIB
    ? t('shipment_costs.cancel_shipment', 'Cancel shipment')
    : t('shipment_costs.cancel_quote', 'Cancel quote')

  const canManageQuote: boolean =
    (isQuote || isProposedQuote) && permissionTo('shipments.accept_quote.all')
  const canManageSIB: boolean =
    isSIB && permissionTo('shipments.accept_sib.all')

  const isExpired: boolean = !!(
    validUntil && new Date().getTime() > new Date(validUntil).getTime()
  )

  const closeCancelModal = useCallback(() => {
    setCancelDialogOpen(false)
  }, [setCancelDialogOpen])

  const openCancelModal = useCallback(() => {
    setCancelDialogOpen(true)
  }, [setCancelDialogOpen])

  const totalEur = sumBy(transactionItems, (data: ITransactionItem) =>
    Number(data.total_eur)
  )

  const acceptQuoteOrSIB = useCallback(() => {
    const acceptAsync = async (): Promise<any> => {
      if (isProposedQuote) {
        try {
          await acceptQuote(shipmentId)
          showSuccess(
            t(
              'shipment_costs.notifications.quote_accepted',
              'Quote has been accepted.'
            )
          )
        } catch {
          showError(
            t(
              'shipment_costs.notifications.quote_not_accepted',
              'Quote cannot be accepted.'
            )
          )
        }
      } else if (isSIB) {
        try {
          await updateBooking(shipmentId, 'approved')
          showSuccess(
            t(
              'shipment_costs.notifications.shipment_status_updated',
              'Shipment status has been updated.'
            )
          )
        } catch {
          showError(
            t(
              'shipment_costs.notifications.shipment_status_not_updated',
              "Shipment status wasn't updated."
            )
          )
        }
      }
    }

    acceptAsync()
  }, [status, shipmentId])

  const handleClickOnReQuote = () => {
    setReQuoting(true)
    requestShipmentReQuote(shipmentId).finally(() => {
      setReQuoting(false)
    })
  }

  return (
    <div className="costs__header--quote">
      {isProposedQuote && !isExpired && (
        <ShipmentCostsQuoteRequestRateMatch shipmentId={shipmentId} />
      )}
      {isExpired && (
        <ShipmentCostsLoadingButton
          size="large"
          text="Requote"
          variant="contained"
          data-testid="requote-button"
          sx={{ width: 106, marginRight: '10px', marginTop: '10px' }}
          loading={reQuoting}
          onClick={handleClickOnReQuote}
        />
      )}
      {isProposedQuote || isSIB ? (
        <div className="costs__header--quote_information">
          <div
            className={`costs__header--quote_information-price bold ${
              isExpired && 'expired'
            }`}
          >
            {totalEur.toFixed(2)} {t('common.currencies.eur', 'EUR')}
          </div>
          <div
            className={`costs__header--quote_information-validity ${
              isExpired && 'expired'
            }`}
          >
            {t('shipment.timeline.quotation.valid_until', {
              defaultValue: 'Valid until: {{val, DATE_MED}}',
              val: validUntil,
            })}
          </div>
        </div>
      ) : (
        ''
      )}

      {(canManageQuote || canManageSIB) && !isExpired && isProposedQuote ? (
        <div className="costs__header--quote_actions">
          <Tooltip
            title={t(
              'shipment_costs.tooltips.quote_not_completed',
              'Your quote is not complete yet.'
            )}
            placement="top"
          >
            <Button
              variant="contained"
              onClick={acceptQuoteOrSIB}
              disabled={isQuote}
            >
              {t('common.buttons.book', 'Book')}
            </Button>
          </Tooltip>

          <Link variant="body1" component="button" onClick={openCancelModal}>
            {cancelText}
          </Link>
        </div>
      ) : (
        ''
      )}
      <CancelShipmentModal
        open={cancelDialogOpen}
        close={closeCancelModal}
        afterCancel={goBack}
        shipmentId={shipmentId}
      />
    </div>
  )
}

export default ShipmentCostsQuote

// @ts-nocheck
// FIXME

import { useState, useEffect, useCallback, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography, InputLabel } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Input from 'src/components/Common/Input/MuiInput'
import { useDispatch, useSelector } from 'react-redux'
import { uniqBy } from 'lodash'
import { countriesGetCountries, getPorts } from 'src/stores/actionCreators'
import { AutoComplete } from 'src/stories'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import Tabs from 'src/stories/Tabs'
import TabPanel from 'src/components/Common/TabPanel'
import { promisifyAction } from 'src/utils'
import {
  validationErrorsFor,
  isEmpty,
  cannotBeBlank,
  shouldBeEmail,
} from '../utils'
import Contacts from './Contacts'
import SaveAddressButton from './SaveAddressButton'
import DeleteAddressButton from './DeleteAddressButton'
import UpdateAddressButton from './UpdateAddressButton'
import AddressSLA from './AddressSLA'
import BankDetails from './BankDetails'

const validations = {
  name: [cannotBeBlank],
  email: [(value: string) => (!isEmpty(value) ? shouldBeEmail(value) : null)],
  address: [cannotBeBlank],
  city: [cannotBeBlank],
  country_id: [cannotBeBlank],
}

const tableLabel = (label: string, icon: string): React.ReactNode => {
  return (
    <div className="iconed-label">
      <span>
        <i className={`icon ${icon}`} />
      </span>
      {label}
    </div>
  )
}

export interface IAddressExtended extends IAddress {
  shouldBeDefault?: boolean
  port_ids?: number[]
}

const AddressEditPage: FC<any> = ({
  address,
}: {
  address?: IAddressExtended
}) => {
  const dispatch = useDispatch()
  const { countries, ports } = useSelector((state: IGlobalState) => ({
    countries: state.countries.list,
    ports: state.ports.items,
  }))

  const [formData, setFormData] = useState(address || ({} as IAddressExtended))
  const [validationErrors, setValidationErrors] = useState({})
  const [tabValue, setTabValue] = useState('contact_persons')
  const getPortsAsync = promisifyAction(dispatch, getPorts)
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(countriesGetCountries())
  }, [])

  useEffect(() => {
    if (address) {
      setFormData(address)
    }
  }, [address])

  useEffect(() => {
    setValidationErrors(validationErrorsFor(formData, validations))
  }, [formData])

  const onChangeDefaultAddress = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({
      ...formData,
      shouldBeDefault: event.target.checked,
    })
  }

  const onChange = (value: string | number | string[][], name: string) => {
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const isFormValid = () => Object.keys(validationErrors).length === 0

  const onTabsChange = useCallback(
    (value: string) => {
      setTabValue(value)
    },
    [tabValue]
  )

  const renderInput = (
    name: string,
    label: string,
    placeholder: string,
    required?: boolean
  ): React.ReactNode => (
    <Input
      name={name}
      label={label}
      placeholder={placeholder}
      value={formData[name] || ''}
      onChange={onChange}
      error={!!validationErrors[name]}
      helperText={validationErrors[name]}
      required={required}
    />
  )

  const saveAddressButton = (
    <SaveAddressButton
      disabled={!isFormValid()}
      onClick={(saveAddress, showError) => {
        const errors = validationErrorsFor(formData, validations, true)
        if (Object.keys(errors).length === 0) {
          saveAddress(formData)
        } else {
          setValidationErrors(errors)
          showError(
            'Your address could not be saved. Please see the error message(s)'
          )
        }
      }}
    />
  )

  const renderTabs = (): React.ReactNode => (
    <>
      <Tabs
        value={tabValue}
        onChange={onTabsChange}
        tabs={[
          {
            value: 'contact_persons',
            label: tableLabel(
              t('address_book.connections.contact_person', 'Contact persons'),
              'contact_persons'
            ),
          },
          {
            value: 'sla',
            label: tableLabel(t('address_book.connections.sla', 'SLA'), 'sla'),
          },
        ]}
      />
      <TabPanel
        value={tabValue}
        index="contact_persons"
        className="po-tabs--tabpanel"
      >
        <Contacts contacts={address.contacts || []} />
      </TabPanel>
      <TabPanel value={tabValue} index="sla" className="po-tabs--tabpanel">
        <AddressSLA
          slaCheckpoints={address.sla_checkpoints || []}
          addressId={address.id}
        />
      </TabPanel>
    </>
  )

  const bankDetails: React.ReactNode = (
    <>
      <Box mt={3} mb={3}>
        <Typography variant="h3">Bank details</Typography>
      </Box>
      <BankDetails details={{} as IBankDetails} />
    </>
  )

  const onSelect = (option: any[]) => {
    setFormData({
      ...formData,
      port_ids: option.map((val) => [val.label, val.value]),
    })
  }

  const onSearchPort = (searchInput: string) => {
    return getPortsAsync({ search: searchInput })
  }

  const renderAutocomplete = () => {
    const optionsToselector = ports.map((opt: any) => {
      return { value: opt.id, label: opt.display_name, data: opt }
    })
    const selectedOptions = (formData.port_ids || []).map((port) => {
      return { value: port[1], label: port[0], data: {} }
    })

    return (
      <AutoComplete
        multiple={true}
        name="port"
        key="preferred-ports"
        onSearch={onSearchPort}
        label={t('address_book.add_address.select_port', 'Select port')}
        onChange={onSelect}
        placeholder={t('address_book.add_address.select_port', 'Select port')}
        noOptionsText={t('address_book.add_address.no_options', 'No options')}
        disableChip={true}
        checkbox={true}
        value={selectedOptions}
        options={uniqBy(optionsToselector.concat(selectedOptions), 'value')}
        data-testid="rule-condition-multiselect"
      />
    )
  }

  return (
    <form noValidate>
      <Box mb={3}>
        <Box
          display="flex"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <Box mb={2}>
            <Typography variant="h3">
              {t('address_book.add_address.main_header', 'Address details')}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            {!address && saveAddressButton}

            {address && !address.address_bank_detail && (
              <DeleteAddressButton address={address} />
            )}
            <Box mr={2} />
            {address && (
              <UpdateAddressButton
                disabled={!isFormValid()}
                addressId={address.id}
                address={formData}
              />
            )}
          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8} mb={3}>
            {renderInput(
              'name',
              t('address_book.add_address.company_name', 'Company name'),
              t('address_book.add_address.enter_company_name', 'Company name'),
              true
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} mb={3}>
            {renderInput(
              'phone',
              t('address_book.add_address.phone_number', 'Phone number'),
              t('address_book.add_address.enter_phone_number', 'Phone number')
            )}
          </Grid>
          <Grid item xs={12} sm={4} mb={3}>
            {renderInput(
              'email',
              t('address_book.add_address.email_address', 'Email address'),
              t('address_book.add_address.enter_email_address', 'Email address')
            )}
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Box mt={3} mb={2}>
          <Typography variant="h3">
            {t('address_book.add_address.sub_header', 'Address details')}
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Grid item mb={3}>
              {renderInput(
                'address',
                t('address_book.add_address.street', 'Street'),
                t('address_book.add_address.enter_street', 'Street'),
                true
              )}
            </Grid>
            <Grid item mb={3}>
              {renderInput(
                'postal_code',
                t('address_book.add_address.postal_code_label', 'Postal code'),
                t('address_book.add_address.enter_code', 'Postal code')
              )}
            </Grid>
            <Grid item mb={3}>
              {renderInput(
                'city',
                t('address_book.add_address.city', 'City'),
                t('address_book.add_address.enter_city', 'City'),
                true
              )}
            </Grid>
            <Grid item mb={3} data-testid="address-book-edit-country">
              <InputLabel>
                {' '}
                {t('address_book.add_address.country', 'Country')}
              </InputLabel>
              <SingleSelect
                value={formData?.country_id || ''}
                options={countries.map((country) => ({
                  id: country[1],
                  label: country[0],
                }))}
                onChange={(event) => onChange(event.target.value, 'country_id')}
                placeholder={t('common.select', 'Select')}
                error={!!validationErrors.country_id}
                data-testid="address-book-edit-country-select"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid item mb={3}>
              {renderInput(
                'vat_number',
                t('address_book.add_addess.vat_label', 'VAT number'),
                t('address_book.add_addess.enter_vat', 'VAT number')
              )}
            </Grid>
            <Grid item mb={3}>
              {renderInput(
                'eori_number',
                t('address_book.add_address.eori_label', 'EORI number'),
                t('address_book.add_address.enter_eori', 'EORI number')
              )}
            </Grid>
            <Grid item mb={3}>
              {renderInput(
                'comment',
                t('address_book.add_address.comment_label', 'Address comment'),
                t('address_book.add_address.enter_comment', 'Address comment')
              )}
            </Grid>

            <Box mt={7} whiteSpace="nowrap">
              <FormControlLabel
                control={<Checkbox color="primary" />}
                checked={
                  address
                    ? address.default || formData.shouldBeDefault
                    : formData.shouldBeDefault || false
                }
                disabled={address && address.default}
                label={t('address_book.add_address.default_address', 'Default')}
                onChange={onChangeDefaultAddress}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} mb={3}>
            <InputLabel>
              {t('address_book.add_address.preferred_port', 'Preferred port')}
            </InputLabel>
            {renderAutocomplete()}
          </Grid>
        </Grid>
        {address && address.address_bank_detail && bankDetails}

        <Grid item mt={3}>
          {address && renderTabs()}
        </Grid>
      </Box>
    </form>
  )
}

export default AddressEditPage

import { FunctionComponent } from 'react'
import { Link } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import HorizontalLogo from 'src/assets/images/Togetherwetrade.svg'
import { useTranslation } from 'react-i18next'

import './styles.scss'

const useStyles = makeStyles((theme) => ({
  item: {
    paddingLeft: '5px !important',
  },
}))

const Footer: FunctionComponent<{}> = () => {
  const classes = useStyles()
  const thisYear = new Date().getFullYear()
  const { t } = useTranslation()

  return (
    <footer className="footer">
      <Grid
        sx={{ flexGrow: 1 }}
        container
        justifyContent="center"
        alignItems="center"
        marginTop="64px"
      >
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          marginBottom="40px"
        >
          <img
            src={HorizontalLogo}
            alt="HorizontalLogo"
            width={167}
            height={19}
          />
        </Grid>
        <Grid item classes={{ item: classes.item }} xs="auto">
          <Link
            variant="body1"
            target="_blank"
            rel="noopener noreferrer"
            href="https://shypple.com/general-terms-and-conditions/"
          >
            {t('footer.general_conditions', 'General terms and conditions')}
          </Link>
        </Grid>
        <Grid item xs="auto" classes={{ item: classes.item }}>
          <Typography variant="body1" children="|" />
        </Grid>
        <Grid item xs="auto" classes={{ item: classes.item }}>
          <Link
            variant="body1"
            target="_blank"
            rel="noopener noreferrer"
            href="https://shypple.com/warehousing-conditions"
          >
            {t('footer.warehousing_conditions', 'Warehousing conditions')}
          </Link>
        </Grid>
        <Grid item xs="auto" classes={{ item: classes.item }}>
          <Typography variant="body1" children="|" />
        </Grid>
        <Grid item xs="auto" classes={{ item: classes.item }}>
          <Link
            variant="body1"
            target="_blank"
            rel="noopener noreferrer"
            href="https://shypple.com/fenex-conditions/"
          >
            {t('footer.fenex_conditions', 'Fenex conditions')}
          </Link>
        </Grid>
        <Grid item xs="auto" classes={{ item: classes.item }}>
          <Typography variant="body1" children="|" />
        </Grid>
        <Grid item xs="auto" classes={{ item: classes.item }}>
          <Link
            variant="body1"
            target="_blank"
            rel="noopener noreferrer"
            href="https://shypple.com/privacy-statement/"
          >
            {t('footer.privacy_statement', 'Privacy statement')}
          </Link>
        </Grid>
        <Grid item xs="auto" classes={{ item: classes.item }}>
          <Typography variant="body1" children="|" />
        </Grid>
        <Grid item xs="auto" classes={{ item: classes.item }}>
          <Link variant="body1" href="tel:+31106002500">
            {t('footer.phone_number', '+31 10 600 2500')}
          </Link>
        </Grid>
        <Grid item xs="auto" classes={{ item: classes.item }}>
          <Typography variant="body1" children="|" />
        </Grid>
        <Grid item xs="auto" classes={{ item: classes.item }}>
          <Link variant="body1" href="mailto:info@shypple.com">
            {t('footer.e_mail', 'info@shypple.com')}
          </Link>
        </Grid>
        <Grid item xs="auto" classes={{ item: classes.item }}>
          <Typography variant="body1" children="|" />
        </Grid>
        <Grid item xs="auto" classes={{ item: classes.item }}>
          <Typography
            variant="body1"
            children={t('footer.address', 'Vasteland 100, 3011 BP, Rotterdam')}
          />
        </Grid>
        <Grid
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          classes={{ item: classes.item }}
          marginTop="24px"
          marginBottom="24px"
        >
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            {t('footer.copyright', 'Copyright Shypple B.V.')} {thisYear}.{' '}
            {t('footer.rights', 'All rights reserved.')}
          </Typography>
        </Grid>
      </Grid>
    </footer>
  )
}

export default Footer

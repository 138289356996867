import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import { CarrierAvatar } from 'src/stories/MUI/CarrierAvatar'
import { SABSearchItemProps } from '../components/SABSearchItem.props'
import SABSearchItemAvatarWithText from '../components/SABSearchItemAvatarWithText'
import SABSearchItemTimeline from '../components/SABSearchItemTimeline'
import SABSearchItemInfo from '../components/SABSearchItemInfo'
import { LEFT_PADDING_1, TOP_MARGIN_1 } from '../components/constants'

const SABSearchSeaItem: React.FC<SABSearchItemProps> = (props) => {
  const { quote, searchQuery, currentUser } = props
  const { schedule, fastest: isFastest } = quote
  const { modality } = searchQuery
  const {
    carrier,
    direct_route: isDirect,
    predicted_schedule: predictedSchedule,
  } = schedule
  const isShowAlert = predictedSchedule || Boolean(schedule.id)

  const carrierName = carrier?.name ?? 'Unknown'

  const { t } = useTranslation()

  return (
    <Paper
      variant="outlined"
      sx={{ pt: 2.5, pr: 2.5, pb: 2.5, pl: 1.5, mt: 2 }}
      data-testid="search-and-book-sea-quote"
    >
      <Grid container>
        <Grid item container lg={8} xl={8} xs={12} sm={12} md={6}>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <SABSearchItemTimeline
              schedule={schedule}
              modality={modality}
              showAlert={isShowAlert}
              cargoClosingDate={quote.cargo_closing_date}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={4}
            mt={TOP_MARGIN_1}
            pl={LEFT_PADDING_1}
          >
            <SABSearchItemAvatarWithText
              title={t('common.modality_sea.carrier', 'Carrier')}
              content={carrierName}
            >
              <CarrierAvatar
                size={75}
                name={carrierName}
                modality="sea"
                carrier={schedule.carrier}
              />
            </SABSearchItemAvatarWithText>
            {isDirect || isFastest ? (
              <Grid container item>
                {isDirect && (
                  <Box sx={{ mt: 2, mr: 1.5 }}>
                    <Chip
                      label={t('search_and_book.direct_sailing', 'Direct')}
                      color="secondary"
                    />
                  </Box>
                )}
                {isFastest && (
                  <Box sx={{ mt: 2 }}>
                    <Chip
                      label={t('search_and_book.fastest', 'Fastest')}
                      color="success"
                    />
                  </Box>
                )}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <SABSearchItemInfo
          currentUser={currentUser}
          quote={quote}
          searchQuery={searchQuery}
        />
      </Grid>
    </Paper>
  )
}

export default SABSearchSeaItem

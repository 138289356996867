// @ts-nocheck
// FIXME

import { FunctionComponent, useState } from 'react'
import { DebounceInput } from 'react-debounce-input'

import './styles.scss'

interface IProps {
  className?: string
  placeholder?: string
  disabled?: boolean
  value?: string | number | null
  text?: string
  theme?: string
  onChange: (value: string) => void
  onKeyDown?: (value: string) => void
  'data-testid'?: string
}

const SearchBar: FunctionComponent<IProps> = (props) => {
  const [textColor, setTextColor] = useState<string>('')
  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    props.onChange(event.target.value)
  }

  const typingValue = (event): void => {
    props.onKeyDown && props.onKeyDown(event.target.value)
    setTextColor('')
  }

  return (
    <div className={`common__search-bar ${props.theme}`}>
      <label
        className="common__search-bar--input"
        htmlFor="common__search-bar--input-element"
      >
        <DebounceInput
          className={`common__search-bar--input-element ${textColor} ${
            props.disabled ? 'disabled' : ''
          }`}
          data-testid={props['data-testid']}
          debounceTimeout={1000}
          placeholder={props.text}
          onChange={changeSearch}
          onKeyUp={typingValue}
          value={props.value || ''}
          disabled={props.disabled}
        />
        <i className="common__search-bar--icon icon search" />
      </label>
    </div>
  )
}

export default SearchBar

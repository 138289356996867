import { isEqual, findKey, isNull, lowerCase } from 'lodash'
import { incotermServiceRoles, freightServiceCode } from './constants'
import { SearchStateServices } from './SearchContainer.props'

export const getRoleServices = (
  roleName: string,
  incoterm: IIncoterm | null
) => {
  const roleServiceCode =
    findKey(incotermServiceRoles, (role) => {
      return role.includes(lowerCase(roleName))
    }) || ''

  return (
    incoterm?.role_services
      .find((roleService) => roleService.code === roleServiceCode)
      ?.services.map((service) => service.service_type) ?? []
  )
}

export const getIncotermServices = (
  roleName: string,
  incoterm: IIncoterm | null,
  currentServices: SearchStateServices
) => {
  const updatedServices = { ...currentServices }
  const roleServices = getRoleServices(roleName, incoterm)

  for (let service in updatedServices) {
    const value = roleServices.includes(service)

    updatedServices[service] = value
  }

  return updatedServices
}

export const getIncotermExcludesFreight = (
  roleName: string,
  incotermServiceBindingApplies: boolean,
  incoterm: IIncoterm | null
) => {
  if (isNull(incoterm) || !incotermServiceBindingApplies) {
    return false
  }

  return !getRoleServices(roleName, incoterm).includes(freightServiceCode)
}

export const getIncotermServicesManipulated = (
  roleName: string,
  incotermServiceBindingApplies: boolean,
  incoterm: IIncoterm | null,
  services: SearchStateServices
) => {
  if (isNull(incoterm) || !incotermServiceBindingApplies) {
    return false
  }

  return !isEqual(services, getIncotermServices(roleName, incoterm, services))
}

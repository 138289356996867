import Box from '@mui/material/Box'
import IconField from '../components/IconField'
import ShipmentField from '../components/ShipmentField'
import PortField from '../components/PortField'
import AlertField from '../components/AlertField'
import StatusField from '../components/StatusField'
import CommentField from '../components/CommentField'
import ColoredField from '../components/ColoredField'
import ColoredFieldWithMappings from '../components/ColoredFieldWithMappings'

export const bookingConfirmedSchema = {
  icon: {
    field: (shipment: IDashboardBookingConfirmedShipment) => (
      <IconField shipment={shipment} />
    ),
    name: '',
    sortable: false,
    sortValue: '',
    customClassName: 'short-50 two-lines',
  },
  shipment: {
    field: (shipment: IDashboardBookingConfirmedShipment) => (
      <ShipmentField shipment={shipment} />
    ),
    name: 'SHIPMENT',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned two-lines',
  },
  status: {
    field: (shipment: IDashboardBookingConfirmedShipment) => (
      <StatusField shipment={shipment} />
    ),
    name: 'STATUS',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned x-medium',
  },
  from: {
    field: (shipment: IDashboardBookingConfirmedShipment) => (
      <PortField
        port={shipment.loading_port}
        date={shipment.estimated_departure}
      >
        <AlertField shipment={shipment} type="etd" />
      </PortField>
    ),
    name: 'POL',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned two-lines',
  },
  to: {
    field: (shipment: IDashboardBookingConfirmedShipment) => (
      <PortField
        port={shipment.discharge_port}
        date={shipment.estimated_arrival}
      >
        <AlertField shipment={shipment} type="eta" />
      </PortField>
    ),
    name: 'POD',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned two-lines',
  },
  volume: {
    field: (shipment: IDashboardBookingConfirmedShipment) => (
      <Box>{shipment.volume}</Box>
    ),
    name: 'VOLUME',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned s-medium',
  },
  comment: {
    field: (shipment: IDashboardBookingConfirmedShipment) => (
      <CommentField comment={shipment.comment} />
    ),
    name: 'COMMENT',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned two-lines',
  },
  finance: {
    field: (shipment: IDashboardBookingConfirmedShipment) =>
      shipment.finance_task ? (
        <ColoredFieldWithMappings
          type="task"
          mapping="finance_etd_check"
          value={shipment.finance_task.task_status_id}
        />
      ) : (
        <ColoredField value="" icon="remove" color="white" />
      ),
    name: 'FINANCE',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned s-medium',
  },
  hbl: {
    field: (shipment: IDashboardBookingConfirmedShipment) => (
      <ColoredFieldWithMappings
        value={shipment.hbl_status || 'blank'}
        mapping="hblBookingConfirmed"
        type="indicator"
      />
    ),
    name: 'HBL',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned short-50',
  },
  mbl: {
    field: (shipment: IDashboardBookingConfirmedShipment) => (
      <ColoredFieldWithMappings
        type="indicator"
        value={shipment.mbl_status || 'blank'}
        mapping="mblBookingConfirmed"
      />
    ),
    name: 'MBL',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned short-50',
  },
  tnt: {
    field: (shipment: IDashboardBookingConfirmedShipment) => (
      <ColoredFieldWithMappings
        type="indicator"
        value={shipment.tnt_status === 'active' ? 'active' : 'not_active'}
        mapping="tnt"
      />
    ),
    name: 'T&T',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned short-50',
  },
}

import { FunctionComponent, useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { DateTime } from 'luxon'
import { isEqual, omit, pickBy, isArray } from 'lodash'
import { Trans, useTranslation } from 'react-i18next'
import { Link, Typography } from '@mui/material'
import UserChannelClient from 'src/components/SocketHandlers/UserChannelClient'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { formatFiltersToParams } from 'src/pages/Shipments/helpers'
import { initialFilters } from 'src/stores/reducers/shipments'
import { OMIT_FILTERS } from 'src/pages/Shipments/ShipmentsFilters/constants'
import { useGetShipmentsMeta } from 'src/services/Api/shipments'
import { promisifyAction } from '../../utils'
import { requestExcel, setExportFile } from '../../stores/actionCreators'
import ExportShipmentsConfirmWindow from '../ExportShipmentsConfirmWindow'

import './styles.scss'

interface IProps {
  changeButtonStatus: (status: string) => void
  close: () => void
}

const ExportShipmentsButton: FunctionComponent<any> = (props: IProps) => {
  const [
    currentExportFile,
    setCurrentExportFile,
  ] = useState<IExportFile | null>(null)
  const { t, i18n } = useTranslation()
  const [openExportWindow, setOpenExportWindow] = useState<boolean>(false)
  const dispatch = useDispatch()

  const requestExcelAsync = promisifyAction(dispatch, requestExcel)

  const { filters } = useSelector((globalState: IGlobalState) => ({
    filters: globalState.shipments.filters,
  }))

  const { fetchAsync: getShipmentsMetaAsync } = useGetShipmentsMeta(
    formatFiltersToParams({ ...filters })
  )

  const { data: initialShipmentsMeta } = useGetShipmentsMeta(
    formatFiltersToParams({ ...filters }),
    {
      refetchOnMount: false,
    }
  )

  useEffect(() => {
    setCurrentExportFile(initialShipmentsMeta?.export || null)
  }, [initialShipmentsMeta])

  const handleReceivedNotifications = (response: any) => {
    if (response.message_type === 'shipment_export_completed') {
      setCurrentExportFile(response.message)
      dispatch(setExportFile(response.message))
    }
  }

  const isInProgress = useMemo(
    (): boolean => currentExportFile?.status === 'in_progress',
    [currentExportFile]
  )

  const isNotPresent = useMemo(
    (): boolean => currentExportFile?.status === 'not_present',
    [currentExportFile]
  )

  const clickOnExport = () => {
    if (isInProgress) {
      dispatch(
        showNotification({
          message: t('shipments.download.export_already_in_progress'),
          severity: 'error',
        })
      )
      return
    }
    if (isAnyFilterApplied) {
      requestExport(true)
    } else {
      setOpenExportWindow(true)
    }
  }

  const closeExportWindow = () => {
    setOpenExportWindow(false)
  }

  const clearFiltersObject = (obj) =>
    pickBy(obj, (val) => (isArray(val) ? val.length : val))

  const isAnyFilterApplied: boolean = useMemo(() => {
    const initialFilteredObj = pickBy(
      omit(initialFilters, OMIT_FILTERS),
      (val) => val
    )
    const filteredObj = omit(filters, OMIT_FILTERS)
    return isEqual(
      clearFiltersObject(filteredObj),
      clearFiltersObject(initialFilteredObj)
    )
  }, [filters])

  const requestExport = async (useFilters: boolean): Promise<any> => {
    if (!isInProgress) {
      props.changeButtonStatus('in_progress')
      await requestExcelAsync(
        useFilters
          ? formatFiltersToParams({
              ...filters,
            })
          : ''
      )

      const updatedShipmentsMeta = await getShipmentsMetaAsync({})
      setCurrentExportFile(updatedShipmentsMeta?.export || null)
      props.close()
    }
  }

  const renderExportRequested = () => {
    return (
      <div className="export-tooltip--requester">
        <Link
          variant="body1"
          component="button"
          data-testid="export-tooltip--requester--link"
          onClick={clickOnExport}
        >
          {t('shipments.download.export_excel_file', 'Export Excel file')}
        </Link>
      </div>
    )
  }

  const clickOnFile = () => {
    props.changeButtonStatus('not_present')
    props.close()
  }

  return (
    <>
      <UserChannelClient onReceived={handleReceivedNotifications} />

      <ClickAwayListener onClickAway={props.close}>
        <div>
          <div className="export-tooltip">
            {renderExportRequested()}
            <div className="export-tooltip--link-block">
              {currentExportFile?.file?.url && (
                <div className="export-tooltip--info">
                  <i className="icon check" />
                  <div
                    className="export-tooltip--link-wrapper"
                    onClick={clickOnFile}
                  >
                    <Typography variant="body1" component="div">
                      <Trans
                        i18nKey="shipments.download.click_to_download"
                        defaults="Click <downloadLink>here</downloadLink> to download previous file"
                        components={{
                          downloadLink: (
                            <Link
                              variant="body1"
                              href={currentExportFile.file.url}
                              download={currentExportFile.file.file_name}
                            />
                          ),
                        }}
                      />
                    </Typography>
                  </div>
                </div>
              )}
              {(!currentExportFile || isNotPresent) && (
                <div className="export-tooltip--link">
                  {t(
                    'shipments.download.no_recent_export_available',
                    'No recent export available'
                  )}
                </div>
              )}
              {currentExportFile?.file && (
                <div className="export-tooltip--time">
                  {t('shipments.download.exported', {
                    defaultValue: 'Exported {{timeAgo}}',
                    timeAgo: DateTime.fromISO(
                      currentExportFile.file.created_at
                    ).toRelative({ locale: i18n?.language ?? 'en' }),
                  })}
                </div>
              )}
            </div>
          </div>
          <ExportShipmentsConfirmWindow
            open={openExportWindow && !isAnyFilterApplied}
            onClose={closeExportWindow}
            onConfirm={requestExport}
            title={t(
              'shipments.download.prompt.apply_filters.title',
              'Apply filters to export file'
            )}
          />
        </div>
      </ClickAwayListener>
    </>
  )
}

export default ExportShipmentsButton

export interface ITabRoute {
  route: string
  title: string
  scope: IReportsDashboardScope
}

export const tabRoutes: ITabRoute[] = [
  {
    route: '/operations-dashboard/bookings-placed',
    title: 'Bookings placed',
    scope: 'bookings_placed',
  },
  {
    route: '/operations-dashboard/schedule-tba',
    title: 'Schedule TBA',
    scope: 'schedule_tba',
  },
  {
    route: '/operations-dashboard/on-hold',
    title: 'On hold',
    scope: 'on_hold',
  },
  {
    route: '/operations-dashboard/schedule-announced',
    title: 'Schedule announced',
    scope: 'schedule_announced',
  },
  {
    route: '/operations-dashboard/booking-confirmed',
    title: 'Booking confirmed',
    scope: 'booking_confirmed',
  },
  {
    route: '/operations-dashboard/rollings',
    title: 'Rolling',
    scope: 'rollings',
  },
  {
    route: '/operations-dashboard/handover',
    title: 'Handover',
    scope: 'handover',
  },
  {
    route: '/operations-dashboard/deliveries-fcl',
    title: 'Deliveries FCL',
    scope: 'deliveries_fcl',
  },
  {
    route: '/operations-dashboard/deliveries-lcl',
    title: 'Deliveries LCL',
    scope: 'deliveries_lcl',
  },
  {
    route: '/operations-dashboard/recent-deliveries',
    title: 'Recent Deliveries',
    scope: 'recent_deliveries',
  },
  {
    route: '/operations-dashboard/inactive-t-n-t',
    title: 'Inactive T&T',
    scope: 'inactive_t_n_t',
  },
]

export const routeByScope = (scope: IReportsDashboardScope) => {
  const tabRoute =
    tabRoutes.find((x: ITabRoute) => x.scope === scope) || tabRoutes[0]
  return tabRoute.route
}

import SettingsIcon from '@mui/icons-material/Settings'
import {
  Box,
  Chip,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import { remove } from 'lodash'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import FullScreenToggleButton from 'src/components/FullScreenToggleButton'
import {
  useDeleteSavedFilter,
  useUpdateSavedFilters,
} from 'src/services/Api/savedFilters'
import { queryClient } from 'src/services/http-common'
import { useTranslation } from 'react-i18next'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { useFilter } from '../hooks'
import { QuickFilterType } from '../types'
import QuickFiltersManageDrawer from './QuickFiltersManageDrawer'
import {
  QuickFiltersManageDrawerFormItemDeleteProps,
  QuickFiltersManageDrawerFormSubmitProps,
} from './QuickFiltersManageDrawer.props'

const QuickFiltersChips = () => {
  const dispatch = useDispatch()
  const {
    page,
    filterId,
    setFilterId,
    quickFilter,
    filterOptions,
    isLoading,
    filterOptionsQueryKey,
    resetFilters,
  } = useFilter()
  const { t } = useTranslation()
  const { mutateAsync: deleteFilter } = useDeleteSavedFilter()
  const { mutateAsync: updateSavedFilters } = useUpdateSavedFilters()
  const [open, setOpen] = useState(false)

  const savedFilters: QuickFilterType[] = filterOptions?.saved_filters || []

  const onDelete = (
    selectedItem: QuickFiltersManageDrawerFormItemDeleteProps
  ) => {
    deleteFilter(+selectedItem.id).then(() => {
      resetFilters()
      queryClient.setQueriesData([filterOptionsQueryKey], (oldParams: any) => {
        if (!oldParams) return oldParams

        const { saved_filters = [] } = oldParams || {}

        return {
          ...oldParams,
          saved_filters: remove(
            saved_filters,
            (obj: any) => +obj.id !== +selectedItem.id
          ),
        }
      })
      dispatch(
        showNotification({
          message: `The quick filter ‘${selectedItem.name}’ has been deleted`,
          severity: 'info',
        })
      )
    })
    setOpen(false)
  }

  const onSubmit = (filters: QuickFiltersManageDrawerFormSubmitProps) => {
    const savedFilters = filters.qf.map(({ name, key }) => ({ name, id: key }))
    updateSavedFilters({ saved_filters_attributes: savedFilters }).then(
      (res) => {
        queryClient.setQueriesData(
          [filterOptionsQueryKey],
          (oldParams: any) => {
            if (!oldParams) return oldParams

            return {
              ...oldParams,
              saved_filters: res.filter((i) => i.page === page),
            }
          }
        )
        setOpen(false)
      }
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        p: 2,
        py: 1,
      }}
    >
      <Stack
        spacing={1}
        rowGap={1}
        direction="row"
        alignItems="center"
        sx={{ flexWrap: 'wrap', width: '100%' }}
        data-testid="quick-filters"
      >
        <Typography variant="body1Strong" data-testid="quick-filters-title">
          {t('filters.headings.quick_filters', 'Quick filters')}
        </Typography>

        <IconButton onClick={() => setOpen(true)} size="large">
          <SettingsIcon sx={{ color: 'grey.400', fontSize: '23px' }} />
        </IconButton>

        {isLoading ? (
          <Skeleton
            width={120}
            height={32}
            animation="wave"
            variant="rectangular"
            sx={{ my: 1, borderRadius: '16px' }}
          />
        ) : (
          savedFilters.map(({ id, name }) => (
            <Chip
              sx={{ my: 1, maxWidth: 500 }}
              key={id}
              label={name}
              onClick={() => setFilterId(filterId === id ? null : id)}
              color={quickFilter?.id === id ? 'secondary' : 'default'}
              data-testid="quick-filter-chip"
            />
          ))
        )}

        <Typography
          hidden={!!savedFilters.length || isLoading}
          variant="body1"
          color="grey.600"
        >
          {t(
            'filters.quick_filters.description',
            'Add a quick filter by enabling filters and press ‘+ Save as new quick filter’.'
          )}
        </Typography>
        <Box sx={{ marginLeft: 'auto !important' }}>
          <FullScreenToggleButton />
        </Box>
      </Stack>
      <QuickFiltersManageDrawer
        open={open}
        onClose={() => setOpen(false)}
        onDelete={onDelete}
        onSubmit={onSubmit}
        savedFilters={savedFilters}
      />
    </Box>
  )
}

export default QuickFiltersChips

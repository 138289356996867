import {
  ShipmentQuotationStatusEnum,
  ShipmentQuotationStatus,
} from 'src/config/constants'

import {
  maxBy,
  minBy,
  find,
  isUndefined,
  lowerCase,
  groupBy,
  map,
} from 'lodash'

const getTransactionItemsSum = (items: ITransactionItem[], key: string) => {
  return items
    .reduce((sum: number, item) => {
      sum += Number(item[key])
      return sum
    }, 0)
    .toFixed(2)
}

export const getDateDisplayProps = (
  quoteExpired: boolean,
  status: ShipmentQuotationStatusEnum
) => {
  if (status === ShipmentQuotationStatusEnum.Open && quoteExpired) {
    return ShipmentQuotationStatus.expired.date
  }
  return ShipmentQuotationStatus[status].date
}

export const getExtremes = (quotations: IShipmentQuotation[]) => {
  const fastest =
    minBy(quotations, (o) => {
      return o.transit_time
    })?.id ?? ''

  const cheapest =
    minBy(quotations, (o) => {
      return parseFloat(o.costs_total_eur)
    })?.id ?? ''

  const longest =
    maxBy(quotations, (o) => {
      return o.transit_time
    })?.id ?? ''

  return { fastest, cheapest, longest }
}

export const getTransactionItemGroups = (
  transactionItems: ITransactionItem[]
) => {
  const groupedTransactions = groupBy(
    transactionItems,
    (item) => `${item.seller_organization.id}_${item.buyer_organization.id}`
  )
  const mapParties = map(groupedTransactions, (items) => {
    const groupedByServiceCode = groupBy(
      items,
      (innerItem) => innerItem.service_item.service_code
    )
    const rows = map(groupedByServiceCode, (innerItems) => ({
      group: innerItems[0].service_item.name,
      innerRows: innerItems.map((item) => {
        return {
          id: item.id,
          currency: item.currency,
          quantity: item.quantity,
          totalUsd: item.total_usd,
          totalEur: item.total_eur,
          unitPrice: item.unit_price,
          name: item.service_item.name,
        }
      }),
    }))

    return {
      parties: [
        {
          label: 'Invoiced by',
          ...items[0].seller_organization,
        },
        {
          label: 'Offered to',
          ...items[0].buyer_organization,
        },
      ],
      totals: {
        usd: getTransactionItemsSum(items, 'total_usd'),
        eur: getTransactionItemsSum(items, 'total_eur'),
      },
      rows,
    }
  })
  return mapParties
}

export const getRevenueItemRows = (revenueItems: IRevenue[]) => {
  return revenueItems.map((revenue: IRevenue) => {
    return {
      group: revenue.name,
      innerRows: revenue.items.map((item, index) => {
        return {
          quantity: item.quantity,
          currency: item.currency,
          unitPrice: item.unit_price,
          name: item.service_item_name,
          totalUsd: item.total_value_usd,
          totalEur: item.total_value_eur,
          id: `${index}_${revenue.name}_${item.service_item_name}`,
        }
      }),
    }
  })
}

export const getCollaboratorWithLeadForwarderRole = (
  collaborators: IShipmentParty[]
): undefined | IShipmentParty => {
  return find(
    collaborators,
    (collaborator) =>
      !isUndefined(
        find(
          collaborator.roles,
          (item) => lowerCase(item.role) === 'lead forwarder'
        )
      )
  )
}

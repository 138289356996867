import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { ContainerTypesResponse } from 'src/@types/endpoints/contianerTypes'
import endpoints, { queryKeys } from '../endpoints'
import { apiClient } from '../http-common'

export const useGetContainerTypes = (
  options?: UseQueryOptions<
    ContainerTypesResponse,
    AxiosError,
    ContainerTypesResponse
  >
) => {
  const url = endpoints.containerTypes
  const { containerTypes: key } = queryKeys

  const getContainerTypes = () =>
    apiClient.get<any, ContainerTypesResponse>(url)
  return useQuery<ContainerTypesResponse, AxiosError>(
    [key],
    getContainerTypes,
    options
  )
}

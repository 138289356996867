// @ts-nocheck
// FIXME
import { Typography } from '@mui/material'
import { FunctionComponent, useState } from 'react'
import SortingIconsBlock from '../SortingIconsBlock'

export interface IColumnHeader {
  sortable: boolean | undefined
  sortValue: string
  customClassName: string
  name?: string
  required?: boolean
}

interface IProps {
  cells: IColumnHeader[] | {}[]
  clickOnSort?: (value: string, direction: 'asc' | 'desc') => void
  defaultSortValue?: string
  defaultSortDirection?: 'asc' | 'desc'
  component?: string
}

const StripedTableHeader: FunctionComponent<IProps> = (props) => {
  const [sortColumn, setSortColumn] = useState<string>(
    props.defaultSortValue || ''
  )
  const [sortDirection, setSortDeriction] = useState<string>(
    props.defaultSortDirection || ''
  )

  const handleSort = (event: React.MouseEvent): void => {
    let direction: 'asc' | 'desc' = 'desc'
    if (sortColumn !== event.currentTarget.id) {
      direction = 'desc'
    } else {
      direction = sortDirection === 'desc' ? 'asc' : 'desc'
    }
    if (props.clickOnSort) {
      setSortColumn(event.currentTarget.id)
      props.clickOnSort(event.currentTarget.id, direction)
      setSortDeriction(direction)
    }
  }

  const setSort = (direction: 'asc' | 'desc', id: string): void => {
    if (props.clickOnSort) {
      setSortColumn(id)
      props.clickOnSort(id, direction)
      setSortDeriction(direction)
    }
  }

  return (
    <div
      className={`striped-table--header ${
        props.component ? props.component : ''
      }`}
    >
      {props.cells.map((cell, index) => {
        return (
          <div
            id={cell.sortValue || index}
            key={index}
            className={`striped-table--header-cell ${cell.customClassName} ${
              cell.sortValue ? 'sortable-cell' : ''
            }`}
          >
            <span id={cell.sortValue || index} onClick={handleSort}>
              <Typography variant="overline" children={cell.name} />
              {cell.required && <sup>*</sup>}
            </span>
            {cell.sortable && (
              <SortingIconsBlock
                isActive={sortColumn === (cell.sortValue || index)}
                sortDirection={sortDirection}
                setSort={setSort}
                sortColumn={cell.sortValue || index}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default StripedTableHeader

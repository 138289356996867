import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import { useSelector } from 'react-redux'
import { useGetDemurrageAndDetention } from 'src/services/Api/demurrageAndDetention'
import DemurrageAndDetentionContentText from './DemurrageAndDetentionContentText'
import DemurrageAndDetentionContentTabs from './DemurrageAndDetentionContentTabs'
import DemurrageAndDetentionContentRates from './DemurrageAndDetentionContentRates'

const DemurrageAndDetentionContent = () => {
  const { data, isLoading } = useGetDemurrageAndDetention()

  const columns = data?.columns || []

  const items = data?.items || []

  const carriers = data?.carriers || []

  const props = {
    items,
    columns,
    carriers,
    isLoading,
  }

  const { hasVisibilityTrial } = useSelector((state: IGlobalState) => ({
    hasVisibilityTrial: state.user.organization_visibility_trial,
  }))

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Paper sx={{ py: 3 }}>
          <DemurrageAndDetentionContentText
            typeOfUser={hasVisibilityTrial ? 'oneView' : 'threePl'}
          />
          {hasVisibilityTrial && (
            <DemurrageAndDetentionContentTabs {...props} />
          )}
          {!hasVisibilityTrial && (
            <>
              <Divider sx={{ mt: 3 }} />
              <DemurrageAndDetentionContentRates {...props} />
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}

export default DemurrageAndDetentionContent

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { useHistory } from 'react-router-dom'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import debounce from 'lodash/debounce'
import { permissionTo } from '../../utils'
import { routes } from './index'

const useStyles = makeStyles((theme) => ({
  searchBlock: {
    margin: '0 2px', // shadows
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
  },
  searchRoot: {
    boxSizing: 'border-box',
    display: 'flex',
    width: 400,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 3,
    height: 40,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchButton: {
    width: 38,
    color: theme.palette.grey[600],
    pointerEvents: 'none',
  },
  searchButtonIcon: {
    width: 20,
  },
  searchAddButton: {
    height: 40,
    whiteSpace: 'nowrap',
  },
  searchControls: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'space-between',
    },
  },
}))

interface AddressBookControlsProps {
  onChange: (value: string) => void
  pathname: string
}

const AddressBookControls = ({
  onChange,
  pathname,
}: AddressBookControlsProps) => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const { addresses, personalDetails, connections } = useSelector(
    (state: IGlobalState) => ({
      addresses: state.addresses.list,
      personalDetails: state.personalDetails.list,
      connections: state.shipments.connections,
    })
  )

  const placeholder = useMemo((): string => {
    let count: number = 0
    switch (pathname) {
      case routes.companies:
        count = addresses.length
        break
      case routes.connections:
        count = connections.length
        break
      default:
        count = personalDetails.length
    }
    return t('address_book.connections.search_placeholder', {
      count,
      defaultValue: 'Search results',
    })
  }, [addresses, connections, personalDetails, pathname, t]) // Added t to dependencies

  const delayedOnChange = debounce((searchQuery: string) => {
    onChange(searchQuery)
  }, 200)

  return (
    <Paper elevation={0} className={classes.searchBlock}>
      <Box className={classes.searchRoot}>
        <InputBase
          className={classes.searchInput}
          placeholder={placeholder}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            delayedOnChange(event.target.value)
          }
        />
        <IconButton className={classes.searchButton} size="large">
          <SearchIcon className={classes.searchButtonIcon} />
        </IconButton>
      </Box>
      {permissionTo('address_book.manage') && (
        <Box className={classes.searchControls}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={
              <i
                style={{ fontSize: '16px', display: 'flex' }}
                className="icon company"
              />
            }
            onClick={() => history.push(routes.companiesNew)}
            className={classes.searchAddButton}
          >
            {t('address_book.connections.add_address', 'Add address')}
          </Button>
          <Box m={1} />
          <Button
            variant="outlined"
            color="primary"
            startIcon={
              <i
                style={{ fontSize: '18px', display: 'flex' }}
                className="icon account"
              />
            }
            onClick={() => history.push(routes.personsNew)}
            className={classes.searchAddButton}
          >
            <b>{t('address_book.connections.add_contact', 'Add contact')}</b>
          </Button>
        </Box>
      )}
    </Paper>
  )
}

export default AddressBookControls

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  IOrganizationsRequest,
  IOrganizationsResponse,
  IOrganizationsHsCodeTypesResponse,
} from 'src/@types/endpoints/organizations'
import { endpoints, queryKeys } from '../endpoints'
import { useQueryAsync } from '../hooks'
import { apiClient } from '../http-common'
import { createQueryString } from '../utils'

export const useGetOrganizationsAsync = (
  options?: UseQueryOptions<
    IOrganizationsResponse,
    AxiosError,
    IOrganizationsResponse
  >
) => {
  const { organizations: key } = queryKeys

  const getOrganizations = (payload: IOrganizationsRequest) => {
    const queryString = createQueryString(payload, {
      skipEmptyString: false,
    })
    const url = endpoints.organizations + queryString

    return apiClient.get<any, IOrganizationsResponse>(url)
  }

  return useQueryAsync([key], getOrganizations, options)
}

export const useGetCustomersAsync = (
  options?: UseQueryOptions<
    IOrganizationsResponse,
    AxiosError,
    IOrganizationsResponse
  >
) => {
  const { fetchAsync, ...rest } = useGetOrganizationsAsync(options)

  return {
    ...rest,
    fetchAsync: (payload: object) =>
      fetchAsync({ ...payload, role: 'customer' }),
  }
}

export const useGetOrganizationHSCodes = (
  options: UseQueryOptions<
    IOrganizationsHsCodeTypesResponse,
    AxiosError,
    IOrganizationsHsCodeTypesResponse
  > = {}
) => {
  const url = endpoints.organizationsHSCodes
  const { organizationsHSCodes: key } = queryKeys

  const getHSCodes = () =>
    apiClient.get<any, IOrganizationsHsCodeTypesResponse>(url)

  return useQuery<IOrganizationsHsCodeTypesResponse, AxiosError>(
    [key],
    getHSCodes,
    options
  )
}

export const useGetOrganizations = (
  options: UseQueryOptions<
    IOrganizationsResponse,
    AxiosError,
    IOrganizationsResponse
  > = {}
) => {
  const url = endpoints.organizations
  const { organizations: key } = queryKeys

  const getOrganizations = () => apiClient.get<any, IOrganizationsResponse>(url)

  return useQuery<IOrganizationsResponse, AxiosError>(
    [key],
    getOrganizations,
    options
  )
}

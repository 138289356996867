import uniqBy from 'lodash/uniqBy'
import Box from '@mui/material/Box'
import { Add } from '@mui/icons-material'
import { Button, InputLabel, Stack } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { useState } from 'react'
import { useGetAddressesAsync } from 'src/services/Api/common'
import { useTranslation } from 'react-i18next'
import { getAutocompleteSelectOptions } from '../../Form.utils'
import { useFormContext } from '../../FormContext'
import AsyncAutocomplete from '../FormElements/AsyncAutocomplete'

const Address = (props) => {
  const {
    fetchAsync: getAddresses,
    isFetching: portsFetching,
  } = useGetAddressesAsync()

  const { fieldPath, label, required = false } = props
  const { formState, onChange } = useFormContext()

  const type = 'address'

  const selected = formState[fieldPath] ?? null

  const defaultOptions = selected ? [{ ...selected, type }] : []

  const [fetchedOptions, setFetchedOptions] = useState(defaultOptions)

  const options = uniqBy([...fetchedOptions, ...defaultOptions], 'value')

  const { t } = useTranslation()

  const onSelectChange = (newSelection) => {
    onChange({ [fieldPath]: newSelection })
    if (newSelection === null) {
      setFetchedOptions([])
    }
  }

  const onInputChange = async (search: string) => {
    const hasOption = options.find((option) => option.label === search)
    if (search.length === 0 || !!hasOption) {
      return
    }
    const addresses = await getAddresses({
      search,
      page_size: 100,
    })

    const newlyFetchedOptions =
      addresses?.data?.map((address) => ({
        label: address?.name ?? '',
        value: address?.id ?? '',
        type,
      })) ?? []

    const newOptions =
      getAutocompleteSelectOptions(defaultOptions, newlyFetchedOptions) ?? []

    setFetchedOptions(newOptions)
  }

  if (!formState.services?.[props.service]) {
    return null
  }

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <Stack direction="row" spacing={2} width="100%">
        <Box maxWidth={300} flexGrow={1}>
          <AsyncAutocomplete
            isFetched={true}
            options={options}
            required={required}
            selected={selected}
            onChange={onSelectChange}
            onInputChange={onInputChange}
            isFetching={portsFetching}
            data-testid={`address-autocomplete-${fieldPath}`}
          />
        </Box>
        <NavLink to="/addresses/new" target="_blank">
          <Button variant="outlined" size="large" startIcon={<Add />}>
            {t('templates.shipment_details.button_new_address')}
          </Button>
        </NavLink>
      </Stack>
    </>
  )
}

export default Address

import { takeEvery } from 'redux-saga/effects'
import * as actionCreators from '../../actionCreators/shipments'
import * as ruleActionCreators from '../../actionCreators/watchShipmentRules'
import * as actions from '../../actions/watchShipmentRules'

import {
  simplePostAndResolve,
  simplePutAndResolve,
  simpleDeleteAndResolve,
  simpleGetAndResolve,
} from '../factories'

import { watchShipmentRuleShippers } from './constants'

const createWatchShipmentRule = simplePostAndResolve(
  (action) => 'api/v1/watch_shipment_rules',
  actionCreators.shipmentsGetDataSuccess,
  (response) => response.data
)

const syncShipmentGroups = simplePostAndResolve(
  (action) => 'api/v1/watch_shipment_rules/sync_shipment_groups',
  (response) => response.data
)

const updateWatchShipmentRule = simplePutAndResolve(
  (action) => `api/v1/watch_shipment_rules/${action.id}`,
  (response) => response.data
)

const deleteWatchShipmentRule = simpleDeleteAndResolve(
  (action) => `api/v1/watch_shipment_rules/${action.id}`,
  (response) => response.data
)

const getShippers = simpleGetAndResolve(
  watchShipmentRuleShippers,
  ruleActionCreators.watchShipmentRulesGetShipperSuccess,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.WATCH_SHIPMENT_RULE_CREATE, createWatchShipmentRule)
  yield takeEvery(actions.WATCH_SHIPMENT_RULE_SYNC, syncShipmentGroups)
  yield takeEvery(actions.WATCH_SHIPMENT_RULE_UPDATE, updateWatchShipmentRule)
  yield takeEvery(actions.WATCH_SHIPMENT_RULE_DELETE, deleteWatchShipmentRule)
  yield takeEvery(actions.WATCH_SHIPMENT_RULE_GET_SHIPPERS, getShippers)
}

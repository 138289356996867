import { AnyAction } from 'redux'
import * as actions from '../actions/shipmentComments'

export const fetchShipmentComments = (shipmentId: number): AnyAction => ({
  type: actions.SHIPMENT_COMMENTS_FETCH,
  shipmentId,
})

export const fetchShipmentCommentsSuccess = (payload: any): AnyAction => ({
  type: actions.SHIPMENT_COMMENTS_FETCH_SUCCESS,
  payload,
})

export const createShipmentComment = (
  shipmentId: number,
  payload: any
): AnyAction => ({
  type: actions.SHIPMENT_COMMENTS_CREATE,
  shipmentId,
  payload,
})

export const createShipmentCommentSuccess = (payload: any): AnyAction => ({
  type: actions.SHIPMENT_COMMENTS_CREATE_SUCCESS,
  payload,
})

export const deleteShipmentComment = (
  shipmentId: number,
  id: number
): AnyAction => ({
  type: actions.SHIPMENT_COMMENTS_DESTROY,
  shipmentId,
  id,
})

export const deleteShipmentCommentSuccess = (payload: any): AnyAction => ({
  type: actions.SHIPMENT_COMMENTS_DESTROY_SUCCESS,
  payload,
})

export const clearShipmentComments = (): AnyAction => ({
  type: actions.SHIPMENT_COMMENTS_CLEAR,
})

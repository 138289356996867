import { AnyAction } from 'redux'
import * as actions from '../actions/navigationNotifications'

export const setNavigationNotificationCount = (count: number): AnyAction => ({
  type: actions.NAVIGATION_NOTIFICATIONS_SET_COUNT,
  payload: { count },
})

export const setNavigationNotificationList = (
  list: INavigationNotification[]
): AnyAction => ({
  type: actions.NAVIGATION_NOTIFICATIONS_SET_LIST,
  payload: { list },
})

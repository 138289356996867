import {
  FunctionComponent,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import qs from 'query-string'
import { compact, union, sumBy, max } from 'lodash'
import { ToggleButtonProps } from '@mui/lab'
import { Box, SvgIcon, Typography } from '@mui/material'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { ButtonGroup } from 'src/stories/ButtonGroup'
import ConfirmDialog from 'src/components/ConfirmDialog'
import AssignOrderToShipmentModal from 'src/components/PurchaseOrders/AssignOrderToShipmentModal'
import { promisifyAction } from '../../../utils'
import {
  triggerFetchingOrders,
  removePurchaseOrdersFromShipment,
  assignPurchaseOrdersToShipment,
  setPOSelect,
} from '../../../stores/actionCreators'

const TableActions: FunctionComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const { selectedLineIds, purchaseOrders, currentUser } = useSelector(
    (state: IGlobalState) => ({
      selectedLineIds: state.purchaseOrders.selectedLineIds,
      purchaseOrders: state.purchaseOrders.list,
      currentUser: state.user,
    })
  )

  useEffect(() => {
    return () => {
      setPOSelectAsync([])
    }
  }, [])

  const selectedPOs = purchaseOrders.filter((order) =>
    order.purchase_order_lines.some((x) => selectedLineIds.includes(x.id))
  )

  const selectedLines = useMemo(() => {
    let lines: IPurchaseOrderLine[] = []
    selectedPOs.forEach((po) => {
      lines = union(
        lines,
        po.purchase_order_lines.filter((x) => selectedLineIds.includes(x.id))
      )
    })
    return lines
  }, [selectedLineIds, selectedPOs])

  const sumCbm = sumBy(selectedLines, (x) =>
    sumBy(x.booking_lines, (y) => +y.volume_cbm)
  )

  const buttons = (t) => [
    {
      value: 'counter',
      children: (
        <Typography
          variant="body1"
          children={t(
            'purchase_orders.table.table_actions.rows_counter',
            '{{selectedPOs}} Orders ({{selectedLines}} order lines / {{sumCbm}} m3)',
            {
              selectedPOs: selectedPOs.length,
              selectedLines: selectedLines.length,
              sumCbm: sumCbm,
            }
          )}
          data-testid="selected-rows-counter"
        />
      ),
    },
    {
      value: 'action',
      children: (
        <>
          <SvgIcon>
            <AddCircleOutlineIcon color="primary" />
          </SvgIcon>
          <Typography
            variant="body1"
            children={t(
              'purchase_orders.table.table_actions.new_shipment',
              'New shipment'
            )}
          />
        </>
      ),
      onClick: () => setConfirmNewShipmentIsOpen(true),
      'data-testid': 'open-window-button',
    },
    {
      value: 'action',
      children: (
        <>
          <SvgIcon>
            <SwapHorizIcon color="primary" />
          </SvgIcon>
          <Typography
            variant="body1"
            children={t(
              'purchase_orders.table.table_actions.assign_to_shipment',
              'Assign to existing shipment'
            )}
          />
        </>
      ),
      onClick: () => setOpenAssignModal(true),
      'data-testid': 'open-window-button',
    },
    {
      value: 'action',
      children: (
        <>
          <SvgIcon>
            <RemoveCircleOutlineIcon color="primary" />
          </SvgIcon>
          <Typography
            variant="body1"
            children={t(
              'purchase_orders.table.table_actions.remove_from_shipment',
              'Remove from shipment'
            )}
          />
        </>
      ),
      onClick: () => setConfirmRemoveIsOpen(true),
      'data-testid': 'open-window-button',
    },
  ]

  const [confirmRemoveIsOpen, setConfirmRemoveIsOpen] = useState<boolean>(false)
  const [confirmNewShipmentIsOpen, setConfirmNewShipmentIsOpen] = useState<
    boolean
  >(false)
  const [openAssignModal, setOpenAssignModal] = useState<boolean>(false)

  const fetchOrders = promisifyAction(dispatch, triggerFetchingOrders)
  const removePurchaseOrdersFromShipmentAsync = promisifyAction(
    dispatch,
    removePurchaseOrdersFromShipment
  )
  const assignPurchaseOrdersToShipmentAsync = promisifyAction(
    dispatch,
    assignPurchaseOrdersToShipment
  )
  const setPOSelectAsync = promisifyAction(dispatch, setPOSelect)

  const closeDialog = (): void => {
    setConfirmRemoveIsOpen(false)
    setConfirmNewShipmentIsOpen(false)
  }

  const confirmRemovePOLines = useCallback(async (): Promise<any> => {
    const requestData = {
      po_line_ids: selectedLineIds,
    }
    await removePurchaseOrdersFromShipmentAsync(requestData)
    setConfirmRemoveIsOpen(false)
    await fetchOrders()
  }, [selectedLineIds])

  const onNewShipmentConfirm = useCallback(async (): Promise<any> => {
    const params: any = {
      purchase_order_line_ids: selectedLineIds,
      purchase_orders: selectedPOs.map((x) => x.purchase_order_number),
      sum_cbm: sumCbm,
    }

    const departureDate = max(selectedPOs.map((x) => x.cargo_ready_date))
    if (departureDate) {
      params.departure_date = departureDate
    }

    const incotermId = max(selectedPOs.map((x) => x.incoterm?.id))
    const incoterm = selectedPOs
      .map((x) => x.incoterm)
      .find((x) => x?.id === incotermId)
    if (incoterm) {
      params.incoterm_id = incoterm.id

      if (currentUser.preferredShipmentRoleId) {
        incoterm.role_services
          .find((x) => x.id === currentUser.preferredShipmentRoleId)
          ?.services.forEach((x) => (params[x.service_type] = true))
      }
    }

    const port = selectedPOs[0].loading_port
    const allPolAreSame = selectedPOs.every(
      (val, i, arr) => val.loading_port?.id === port?.id
    )

    if (allPolAreSame && port) {
      params.start = `Port of ${port.name}`
      params.origin_type = 'port'
      params.origin_id = port.id
    }

    const url = qs.stringify(params, { arrayFormat: 'bracket' })
    history.push(`/search?${url}`)
  }, [selectedLineIds])

  const confirmAssignPurchaseOrders = useCallback(
    async (shipmentId, formData): Promise<any> => {
      const requestData = {
        po_line_ids: selectedLineIds,
        shipment_id: shipmentId,
        po_data: formData,
      }
      await assignPurchaseOrdersToShipmentAsync(requestData)
    },
    [selectedLineIds]
  )

  return (
    <>
      <Box
        m={0.5}
        sx={{
          position: 'absolute',
          backgroundColor: 'grey.50',
          zIndex: '1',
          borderRadius: 1,
        }}
      >
        <ButtonGroup buttons={compact(buttons(t)) as ToggleButtonProps[]} />
      </Box>
      <ConfirmDialog
        title={t(
          'purchase_orders.table.table_actions.removing_po_dialog.title',
          'Confirmation'
        )}
        message={t(
          'purchase_orders.table.table_actions.removing_po_dialog.description',
          'You are about to remove the selected order lines from their shipments. Please press confirm to proceed.'
        )}
        isOpen={confirmRemoveIsOpen}
        confirm={confirmRemovePOLines}
        reject={closeDialog}
        onClose={closeDialog}
      />
      <ConfirmDialog
        title={t(
          'purchase_orders.table.table_actions.creating_po_dialog.title',
          'Confirmation'
        )}
        message={
          <>
            <Trans
              i18nKey="purchase_orders.table.table_actions.creating_po_dialog.description"
              defaults="<mb>You have selected: <b>{{orders}}</b></mb> <div>Are you sure you want to create a new shipment for these purchase
                  orders?</div> You will be redirected to search & book."
              values={{
                orders: selectedPOs
                  .map((x) => x.purchase_order_number)
                  .join(', '),
              }}
              components={{
                mb: <div className="mb-10" />,
                b: <b />,
                div: <div />,
              }}
            />
          </>
        }
        isOpen={confirmNewShipmentIsOpen}
        confirm={onNewShipmentConfirm}
        reject={closeDialog}
        onClose={closeDialog}
      />
      <AssignOrderToShipmentModal
        open={openAssignModal}
        purchaseOrders={purchaseOrders.filter((po) =>
          po.purchase_order_lines.some((l) => selectedLineIds.includes(l.id))
        )}
        onConfirm={confirmAssignPurchaseOrders}
        onClose={setOpenAssignModal.bind(null, false)}
      />
    </>
  )
}

export default TableActions

import { FunctionComponent, memo, useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { uniq, flatten, compact } from 'lodash'
import { Chip } from '@mui/material'

const ShipmentTag: FunctionComponent<{
  visibilityOnly: boolean
  collaborators: ICollaborator[]
}> = memo(({ visibilityOnly, collaborators }) => {
  const { visibilityTrial } = useSelector(
    (state: IGlobalState) => ({
      visibilityTrial: state.user.organization_visibility_trial,
    }),
    shallowEqual
  )

  const getLabel = useMemo(() => {
    if (visibilityOnly) return 'OneView'
    const collaboratorsRoles = (collaborators || []).map((x) => {
      return compact(
        x.roles.map((role) => {
          return role.id === 17 ? x.name : '' //Lead forwarder
        })
      )
    })
    return uniq(flatten(collaboratorsRoles))[0]
  }, [visibilityOnly])

  if (!visibilityTrial || !getLabel) return <></>

  return (
    <Chip
      sx={{ marginTop: 1 }}
      color={visibilityOnly ? 'default' : 'primary'}
      label={getLabel}
    />
  )
})

export default ShipmentTag

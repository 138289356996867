import { ReactNode } from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

const DemurrageAndDetentionContentRatesSectionHeader: React.FC<{
  subtitle?: string | null
  title: ReactNode
}> = ({ title, subtitle }) => {
  return (
    <Stack mt={3} mb={1} spacing={1} direction="row" alignItems="baseline">
      <Typography
        variant="h5"
        children={title}
        data-testid="demurrage-and-detention-section-title"
      />
      {subtitle && (
        <Typography
          children={subtitle}
          color="grey.600"
          data-testid="demurrage-and-detention-section-subtitle"
        />
      )}
    </Stack>
  )
}

export default DemurrageAndDetentionContentRatesSectionHeader

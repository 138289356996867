import LinearProgressMui from '@mui/material/LinearProgress'
import { makeStyles } from '@mui/styles'
import { LinearProgressProps } from '.'

const useStyles = makeStyles({
  loader: {
    height: '3px',
  },
})

export const LinearProgress: React.FC<LinearProgressProps> = (props) => {
  const classes = useStyles()

  return <LinearProgressMui className={classes.loader} {...props} />
}

import {
  FunctionComponent,
  useState,
  useCallback,
  useMemo,
  useRef,
  MutableRefObject,
  useEffect,
} from 'react'
import { parse } from 'query-string'
import { Avatar } from 'src/stories/Avatar'
import { Paper, Link as MuiLink } from '@mui/material'
import Typography from '@mui/material/Typography'
import { get, trim } from 'lodash'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { userGetUserData, uploadAvatar } from '../../stores/actionCreators'
import CropAvatarModal from '../CropAvatarModal'
import AccountInfoModal from './AccountInfoModal'
import PasswordModalContainer from './PasswordModalContainer'
import './styles.scss'

const AccountInfoSideBar: FunctionComponent = () => {
  const history = useHistory()
  const urlParams = parse(window.location.search)
  const passwordModalOpenByDefault = urlParams?.['action'] === 'reset_password'
  const { t } = useTranslation()
  const [logoFile, setLogoFile] = useState<FileReader['result']>(null)
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [passwordModalOpen, setPasswordModalOpen] = useState<boolean>(
    passwordModalOpenByDefault
  )
  const { user, organization } = useSelector((globalState: IGlobalState) => ({
    user: globalState.user,
    organization: globalState.organizations.organization,
  }))

  const ref: MutableRefObject<HTMLInputElement | null> = useRef(null)

  const onEditClick = useCallback(() => {
    ref.current && ref.current.click()
  }, [])

  const onChangePasswordClick = useCallback(() => {
    setPasswordModalOpen(true)
  }, [])

  const onChangePasswordEnd = useCallback(() => {
    setPasswordModalOpen(false)
    history.replace({
      search: '',
    })
  }, [])

  const onFileUpload = useCallback((event) => {
    const input = event.target
    const file = input.files ? input.files[0] : null
    const reader = new FileReader()

    reader.onloadend = () => {
      setLogoFile(reader.result)
    }

    if (file) {
      reader.readAsDataURL(file)
    } else {
      setLogoFile(null)
    }
  }, [])

  const nameAbbr: string = useMemo(() => {
    return get(trim(user.firstName), 0, '') + get(trim(user.lastName), 0, '')
  }, [user])

  useEffect(() => {
    if (passwordModalOpenByDefault) {
      setPasswordModalOpen(true)
    }
  }, [urlParams])

  return (
    <Paper variant="elevation">
      <div className="organization-form">
        <div className="organization-form__name mb-2">
          <Typography
            variant="h3"
            children={`${user.firstName} ${user.lastName}`}
          />
        </div>
        <div className="organization-form__logo">
          <div className="organization-form__logo__edit-link mt-2">
            <MuiLink
              variant="body1"
              component={Link}
              to={'/organization_settings'}
            >
              {organization.name}
            </MuiLink>
          </div>
          <div className="organization-form__logo__image mt-12">
            <Avatar
              size="super-size"
              alt={nameAbbr}
              userInfo={{
                out_of_office_till: user.out_of_office_till,
              }}
              src={user.avatar || 'no-logo'}
              className={`organization-logo ${organization.role_code}`}
            />
          </div>

          <div className="organization-form__logo__edit-link">
            <MuiLink variant="body1" component="button" onClick={onEditClick}>
              {t('account.side_bar.edit_avatar_button', 'Edit avatar')}
            </MuiLink>
          </div>

          <input
            ref={ref}
            type="file"
            id="selectedFile"
            className="hidden"
            onChange={onFileUpload}
            accept="image/png, image/jpeg"
          />
        </div>
        <div
          className="organization-form__default_address"
          onClick={() => {
            setEditModalOpen(true)
          }}
        >
          <div className="organization-form__default_address__address_details">
            <Typography variant="body1Strong">
              {user.firstName} {user.lastName}
            </Typography>
            <Typography variant="body1" children={user.email} />
            <Typography variant="body1" children={user.phone} />
          </div>
        </div>
        <div className="organization-form__logo__edit-link">
          <MuiLink
            variant="body1"
            component="button"
            onClick={onChangePasswordClick}
            data-testid="link-reset-password"
          >
            {t('account.side_bar.reset_password_button', 'Reset password')}
          </MuiLink>
        </div>
        {passwordModalOpen && (
          <PasswordModalContainer handleClose={onChangePasswordEnd} />
        )}
        <AccountInfoModal
          open={editModalOpen}
          user={user}
          handleClose={() => {
            setEditModalOpen(false)
          }}
        />
        <CropAvatarModal
          logo={logoFile}
          handleClose={() => {
            setLogoFile(null)
          }}
          fetch={userGetUserData}
          update={uploadAvatar}
        />
      </div>
    </Paper>
  )
}

export default AccountInfoSideBar

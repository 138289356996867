import { palette } from 'src/stories/constants'

export const CONSIGNEE_ROLE = 9
export const LEAD_FORWARDER_ROLE = 17
export const SHYPPLE_ID = 1
export enum LoadTypeEnum {
  lcl = 'lcl',
  fcl = 'fcl',
}

export const chipColor = {
  grey: 'default',
  green: 'success',
  orange: 'warning',
  red: 'error',
  blue: 'secondary',
  white: 'primary',
}

export enum ModalityEnum {
  Air = 'air',
  Barge = 'barge',
  Rail = 'rail',
  Sea = 'sea',
  Road = 'road',
}

export enum TradeDirectionEnum {
  Import = 'import',
  Export = 'export',
  crossTrade = 'cross_trade',
}

export enum OrganizationRoleEnum {
  Customer = 'customer',
  Agent = 'agent',
  Supplier = 'supplier',
}

export interface IModalityOption {
  id: ModalityEnum
  name: string
}

export enum ShipmentQuotationStatusEnum {
  Open = 'open',
  Booked = 'booked',
  PreviouslyBooked = 'previously_booked',
  Canceled = 'canceled',
  Expired = 'expired',
}

export const ShipmentQuotationStatus = {
  [ShipmentQuotationStatusEnum.Booked]: {
    text: 'Accepted',
    color: 'success',
    date: {
      text: 'Accepted on',
      color: 'success.main',
      prop: 'shipment_booked_at',
    },
  },
  [ShipmentQuotationStatusEnum.Canceled]: {
    text: 'Not Accepted',
    color: 'error',
    date: {
      text: 'No longer valid',
      color: 'error.main',
      prop: 'quote_expiration_at',
    },
  },
  [ShipmentQuotationStatusEnum.Open]: {
    text: 'Open',
    color: 'secondary',
    date: {
      text: 'Valid until',
      color: 'text.secondary',
      prop: 'quote_expiration_at',
    },
  },
  [ShipmentQuotationStatusEnum.PreviouslyBooked]: {
    text: 'Previously Accepted',
    color: 'primary',
    date: {
      text: 'Expired on',
      color: 'error.main',
      prop: 'shipment_booked_at',
    },
  },
  [ShipmentQuotationStatusEnum.Expired]: {
    text: 'Expired',
    color: 'error',
    date: {
      text: 'Expired on',
      color: 'error.main',
      prop: 'quote_expiration_at',
    },
  },
}

export const modalityOptions = [
  { id: ModalityEnum.Sea, label: 'Sea', inland: false },
  { id: ModalityEnum.Air, label: 'Air', inland: false },
  { id: ModalityEnum.Road, label: 'Road', inland: true },
  { id: ModalityEnum.Rail, label: 'Rail', inland: true },
  { id: ModalityEnum.Barge, label: 'Barge', inland: true },
]

export interface ITimePickerTimeInterval {
  id: number
  name: string
  count: number
}

interface IShipmentThresholds {
  pickup: number
  departure: number
  arrival: number
  delivery: number
}

interface IThc {
  origin: string
  destination: string
  bothSAB: string
  both: string
}

interface IServerErrors {
  technical_error: string
  network_error: string
  connection_aborted: string
}
interface IRequestedAirTT {
  courier: string
  regular: string
  fast: string
}

export const LOCAL_STORAGE_CREDENTIAL_KEY: string = 'credentials'

export const LOCAL_STORAGE_MAP_STATE: string = 'isMapShown'

export const SHIPMENTS_QUICK_FILTER_STORAGE_KEY: string = 'quickFilter'

export const DEFAULT_PAGE: string = '/dashboard'

export const DATE_FORMAT: string = 'YYYY-MM-DD'

export const FULL_DATE_FORMAT: string = 'dd MMM yyyy  HH:mm'

export const SEARCH_REQUEST_DELAY: number = 800

export const ORIGIN_COUNTRY_CODES: string[] = ['NL', 'BE']

export const SMALL_SCREEN_WIDTH: number = 1200

export const MOBILE_SCREEN_WIDTH: number = 768

export const SHIPMENT_THRESHOLDS: IShipmentThresholds = {
  pickup: 10,
  departure: 33,
  arrival: 66,
  delivery: 99,
}

export const OPENING_HOURS: ITimePickerTimeInterval[] = [
  {
    id: 1,
    name: '6:00 - 6:30',
    count: 6,
  },
  {
    id: 2,
    name: '6:30 - 7:00',
    count: 6.5,
  },
  {
    id: 3,
    name: '7:00 - 7:30',
    count: 7,
  },
  {
    id: 4,
    name: '7:30 - 8:00',
    count: 7.5,
  },
  {
    id: 5,
    name: '8:00 - 8:30',
    count: 8,
  },
  {
    id: 6,
    name: '8:30 - 9:00',
    count: 8.5,
  },
  {
    id: 7,
    name: '9:00 - 9:30',
    count: 9,
  },
  {
    id: 8,
    name: '9:30 - 10:00',
    count: 9.5,
  },
  {
    id: 9,
    name: '10:00 - 10:30',
    count: 10,
  },
  {
    id: 10,
    name: '10:30 - 11:00',
    count: 10.5,
  },
  {
    id: 11,
    name: '11:00 - 11:30',
    count: 11,
  },
  {
    id: 12,
    name: '11:30 - 12:00',
    count: 11.5,
  },
  {
    id: 13,
    name: '12:00 - 12:30',
    count: 12,
  },
  {
    id: 14,
    name: '12:30 - 13:00',
    count: 12.5,
  },
  {
    id: 15,
    name: '13:00 - 13:30',
    count: 13,
  },
  {
    id: 16,
    name: '13:30 - 14:00',
    count: 13.5,
  },
  {
    id: 17,
    name: '14:00 - 14:30',
    count: 14,
  },
  {
    id: 18,
    name: '14:30 - 15:00',
    count: 14.5,
  },
  {
    id: 19,
    name: '15:00 - 15:30',
    count: 15,
  },
  {
    id: 20,
    name: '15:30 - 16:00',
    count: 15.5,
  },
  {
    id: 21,
    name: '16:00 - 16:30',
    count: 16,
  },
  {
    id: 22,
    name: '16:30 - 17:00',
    count: 16.5,
  },
  {
    id: 23,
    name: '17:00 - 17:30',
    count: 17,
  },
  {
    id: 24,
    name: '17:30 - 18:00',
    count: 17.5,
  },
  {
    id: 25,
    name: '18:00 - 18:30',
    count: 18,
  },
]

export const THC: IThc = {
  origin: ' (Origin THC included)',
  destination: ' (Destination THC included)',
  bothSAB: ' (Both THC included)',
  both: ' (Origin & Destination THC included)',
}

export const CHAT_TYPES = {
  private_chat: 'Private chat',
  public_chat: 'Public chat',
  agents_chat: 'Agents chat',
}

export const SELECTOR_DIVIDER: INullPersonalDetail = {
  id: null,
  name: '-'.repeat(36),
  disabled: true,
  company_address_id: null,
  email: null,
  phone: null,
}

export const PHASE_COLORS = (shipment_phase?: string, status_name?: string) => {
  switch (true) {
    case shipment_phase === 'delivered':
      return 'light-green'
    case shipment_phase === 'cancelled':
      return 'light-red'
    case shipment_phase === 'destination':
      return 'dark-blue'
    case shipment_phase === 'in_transit':
      return 'light-blue'
    case shipment_phase === 'origin':
      return 'purple'
    case shipment_phase === 'quote':
      return 'light-orange'
    default:
      return 'light-grey'
  }
}

export const REQUESTED_AIR_TT: IRequestedAirTT = {
  courier: 'Express (door-to-door)',
  regular: 'Indirect (Regular)',
  fast: 'Direct (Fast)',
}

export const SERVER_ERRORS: IServerErrors = {
  technical_error:
    "A technical error has occurred. If it's not resolved in a few minutes, please let us know and we'll get on it as soon as possible.",
  network_error:
    'Something went wrong. Please try again. When the issue persists, please contact us on +31 10 600 2500.',
  connection_aborted:
    'Request timed out. Please try again. When the issue persists, please contact us on +31 10 600 2500.',
}

export const CLIENT_ERRORS = {
  UNAUTHORIZED:
    'Unauthorized request, session expired or logged out in another tab or browser.',
  RESOURCE_NOT_FOUND: 'Resource not found.',
  INVALID_REQUEST: 'Something went wrong, we are working on that.',
}

export const THEME_COLORS = [...palette, 'grey', 'common']

export const CSS_THEME_VARIABLES = (theme) => {
  const colorVariables = THEME_COLORS.map((color) => {
    return Object.keys(theme.palette[color]).reduce((obj, key) => {
      if (key !== 'contrastText') {
        obj[`--${color}-${key}`] = theme.palette[color][key]
      }
      return obj
    }, {})
  })
  return {
    '@global': {
      ':root': Object.assign({}, ...colorVariables),
    },
  }
}

export const patterns = {
  flightNumber: /^[A-Z]{2,3}\s?\d{1,4}$/,
}

export const cargoErrorsDependencies = {
  packageType: ['total_volume_cbm', 'dimensions'],
  dimensions: ['total_volume_cbm'],
  total_volume_cbm: ['dimensions'],
  width: ['total_volume_cbm', 'dimensions'],
  height: ['total_volume_cbm', 'dimensions'],
  length: ['total_volume_cbm', 'dimensions'],
}

export const cargoRequiredStackableOptions = [
  { id: 'yes', value: true, label: 'Yes' },
  { id: 'no', value: false, label: 'No' },
]

export const DEFAULT_SORT_VALUE = 'newest_first'

export const LOCAL_STORAGE_TEMPLATE_SORT = 'templateSortOrder'

export const AIRLINE_LOGOS_URL =
  'https://www.gstatic.com/flights/airline_logos/70px'

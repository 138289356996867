import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ButtonBase from '@mui/material/ButtonBase'
import Typography, { TypographyTypeMap } from '@mui/material/Typography'

import { stringify } from 'query-string'
import { toLower, truncate } from 'lodash'

import DateBox from 'src/components/DateBox'
import ShipmentRangedProgress from 'src/components/ProgressWithDays'

import {
  convertDatePickerToUniversalFormat,
  convertDateToUniversalFormat,
  datesDifferenceInDays,
} from '../../utils/helpers'

interface IProps {
  quote: IQuote
  fromPath: string
  isAcceptQuote: boolean
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey[50]}`,
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
  },
}))

const QuoteLine: FunctionComponent<IProps> = ({
  quote,
  isAcceptQuote,
  fromPath,
}) => {
  const classes = useStyles()

  let startIcon = 'cargo'
  let endIcon = 'cargo'
  if (toLower(quote.container_type) === 'air') {
    startIcon = 'air-depart'
    endIcon = 'air-arrive'
  }

  const renderDate = (date: string, portCode: string, port: string) => {
    return (
      <Grid
        item
        xs={4}
        sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}
      >
        <Box
          mr={1}
          sx={{ width: '44px', display: { xs: 'none', sm: 'block' } }}
        >
          <DateBox date={date} />
        </Box>
        <Typography variant="h3" sx={{ display: { xs: 'block', sm: 'none' } }}>
          {date ? convertDateToUniversalFormat(date) : 'N/A'}
        </Typography>
        {renderTwoLinesText(portCode, port)}
      </Grid>
    )
  }

  const renderTwoLinesText = (
    topText: string,
    bottomText: string,
    topTextVariant: TypographyTypeMap['props']['variant'] = 'body1',
    bottomTextVariant: TypographyTypeMap['props']['variant'] = 'body1',
    topTextColor: string = 'primary.main',
    bottomTextColor: string = 'grey.500'
  ) => {
    return (
      <Box data-testid={`quote-${topText}`}>
        <Typography
          component="div"
          variant={topTextVariant}
          sx={{ color: topTextColor }}
        >
          {topText}
        </Typography>
        <Typography sx={{ color: bottomTextColor }} variant={bottomTextVariant}>
          {bottomText}
        </Typography>
      </Box>
    )
  }

  const { t } = useTranslation()

  const getDaysInTransit = (etaString: string, etdString: string): number => {
    return Math.floor(datesDifferenceInDays(etaString, etdString))
  }

  const getReferenceNumber = () => {
    if (quote.reference_number) {
      return truncate(quote.reference_number, { length: 12 })
    }
    return '-'
  }

  const renderPQuoteValidTo = (): React.ReactNode => {
    if (quote.status !== 'PQUO') {
      return null
    }
    return renderTwoLinesText(
      t('common.forms.fields.valid_until.label', 'Valid to'),
      getExpirationText(quote.quote_expiration_at),
      'subtitle1',
      'body1',
      'grey.500',
      'primary.main'
    )
  }

  const isExpired: boolean = !!(
    quote.quote_expiration_at &&
    new Date().getTime() > new Date(quote.quote_expiration_at).getTime()
  )

  const getExpirationText = (expirationAt: string | null) => {
    if (isExpired) {
      return t('quotes.expired', 'Expired')
    }
    if (expirationAt) {
      return convertDatePickerToUniversalFormat(expirationAt)
    }
    return '-'
  }

  const renderPQuotePrice = (): React.ReactNode => {
    if (quote.status !== 'PQUO') {
      return null
    }
    return (
      <>
        <Typography
          sx={{
            color: isExpired ? 'secondary.main' : 'error.main',
          }}
          variant="body1Strong"
        >
          {quote.costs_total_eur} EUR
        </Typography>
        {quote.shipment_quotations > 1 && (
          <Typography sx={{ color: 'secondary.main' }} variant="subtitle2">
            {`+ ${quote.shipment_quotations - 1} ${t(
              'quotes.more_quotations',
              'more quotations'
            )}`}
          </Typography>
        )}
      </>
    )
  }

  return (
    <ButtonBase
      key={quote.id}
      data-testid={`quote-line-${quote.id}`}
      component={Link}
      sx={{ width: '100%' }}
      classes={{
        root: classes.wrapper,
      }}
      to={`/shipments/${quote.id}/price-structure?${stringify({
        from: fromPath,
      })}`}
    >
      <Grid p={2} container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12} md={12} lg={7}>
          <Grid container item spacing={3}>
            {renderDate(
              quote.estimated_departure,
              quote.loading_port_code,
              quote.loading_port
            )}
            <Grid item xs={4}>
              <ShipmentRangedProgress
                startIcon={startIcon}
                endIcon={endIcon}
                progress={100}
                daysInTransit={getDaysInTransit(
                  quote.estimated_arrival,
                  quote.estimated_departure
                )}
              />
            </Grid>
            {renderDate(
              quote.estimated_arrival,
              quote.discharge_port_code,
              quote.discharge_port
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={5}>
          <Grid container item spacing={3}>
            <Grid item xs={6} sm={3} lg={3}>
              {renderTwoLinesText(
                t('quotes.id', 'ID'),
                quote.title,
                'subtitle1',
                'body1',
                'grey.500',
                'primary.main'
              )}
            </Grid>
            <Grid
              item
              xs={6}
              sm={3}
              lg={3}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: { xs: 'flex-end', sm: 'flex-start' },
              }}
            >
              {renderTwoLinesText(
                t('documents.table.columns.reference', 'Reference'),
                getReferenceNumber(),
                'subtitle1',
                'body1',
                'grey.500',
                'primary.main'
              )}
            </Grid>
            <Grid item xs={6} sm={3} lg={3}>
              {renderPQuoteValidTo()}
            </Grid>
            <Grid
              item
              xs={6}
              sm={3}
              lg={3}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: { xs: 'flex-end', sm: 'flex-start' },
              }}
            >
              {isAcceptQuote && renderPQuotePrice()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ButtonBase>
  )
}
export default QuoteLine

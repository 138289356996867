import { Box, Typography, Paper } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { CHAT_TYPES } from 'src/config/constants'
import NotificationsContentListItemAttachments from './NotificationsContentListItemAttachments'

interface NotificationsContentNotificationProps {
  notification: INavigationNotification
  onClick: () => {}
}

const NotificationsContentListItem: React.FC<NotificationsContentNotificationProps> = ({
  notification,
  onClick,
}) => {
  const history = useHistory()

  const onNotificationClick = () => {
    history.push({
      pathname: notification.shipment_link,
    })
    onClick()
  }

  return (
    <Box
      key={notification.id}
      onClick={onNotificationClick}
      sx={{
        p: 2,
        cursor: 'pointer',
        textDecoration: 'none',
        '&:hover': { backgroundColor: 'grey.50' },
      }}
      data-testid="navigation-notification-list-item"
    >
      <Typography variant="body1Strong">
        {notification.author.full_name} |{' '}
        {notification.author.organization.name}
      </Typography>
      <Typography variant="subtitle1" color="grey.600" mt={0.5}>
        {`${CHAT_TYPES[notification.chat_type]} ${notification.shipment_title}`}
      </Typography>
      <Paper
        elevation={0}
        sx={{ backgroundColor: 'secondary.50', mt: 2, p: 1.5 }}
      >
        <Typography
          component="div"
          dangerouslySetInnerHTML={{ __html: notification.content }}
        />
        <NotificationsContentListItemAttachments
          attachments={notification.comment_attachments}
        />
      </Paper>
      <Typography variant="subtitle1" color="grey.600" mt={2}>
        {notification.human_time} ago
      </Typography>
    </Box>
  )
}

export default NotificationsContentListItem

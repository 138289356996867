import { IPersonalInformation } from './interfaces'

export function userInformationSelector(
  state: IGlobalState
): IPersonalInformation {
  if (!state || !state.user) {
    return {
      userId: '',
      organizationId: '',
      isDummy: false,
      uid: '',
    }
  }
  return {
    userId: state.user.id || '',
    organizationId: state.user.organizationId || '',
    isDummy: state.user.isDummy || false,
    uid: state.user.uid || '',
  }
}

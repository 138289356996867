import { FC } from 'react'
import { includes, sumBy } from 'lodash'
import { containerTypeDescription, containerTypeIcon } from 'src/utils/helpers'

interface IShipmentCOntainersProps {
  containers: IContainerShort[]
  shipmentType: string
}

export const ShipmentContainers: FC<IShipmentCOntainersProps> = (props) => {
  const { containers, shipmentType } = props
  if (includes(['lcl', 'air'], shipmentType)) {
    const totalQuantity: number = sumBy(containers, 'quantity')
    return (
      <span className="multiple-containers">
        <i className={'icon ' + containerTypeIcon(shipmentType, null)} />{' '}
        {totalQuantity || 0} x {containerTypeDescription(shipmentType, null)}
      </span>
    )
  }

  return (
    <span>
      {(containers || []).map((item: IContainerShort, index) => (
        <span key={index} className="multiple-containers">
          <i
            className={
              'icon ' +
              containerTypeIcon(
                shipmentType,
                shipmentType === 'fcl' ? item.container_type : null
              )
            }
          />{' '}
          {item.quantity} x{' '}
          {containerTypeDescription(
            shipmentType,
            shipmentType === 'fcl' ? item.container_type : null
          )}
        </span>
      ))}
    </span>
  )
}

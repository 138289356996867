import { FunctionComponent } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface ITemplateRowRoleTextProps {
  template: IBookingTemplate
}

const TemplateRowRoleText: FunctionComponent<ITemplateRowRoleTextProps> = ({
  template,
}) => {
  const orgArr = template.template?.collaborators.map((item) => {
    return { name: item.name, role: item.roles[0]?.role }
  })
  const consignee = (orgArr || []).find((item) => item.role === 'Consignee')
  const shipper = (orgArr || []).find((item) => item.role === 'Shipper')
  const { t } = useTranslation()

  return (
    <Box mt={2} sx={{ textAlign: 'left' }}>
      <Typography variant="body1Strong" mb={0.5}>
        {consignee
          ? t('common.consignee', 'Consignee')
          : shipper
          ? t('common.shipper', 'Shipper')
          : '-'}
      </Typography>
      <Typography variant="body1">
        {consignee ? consignee.name : shipper?.name || ''}
      </Typography>
    </Box>
  )
}

export default TemplateRowRoleText

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import isServiceBlocked from 'src/utils/isServiceBlocked'
import urlJoin from 'url-join'

export default () => {
  const [loaded, setLoaded] = useState(false)
  const { user } = useSelector((state: IGlobalState) => ({
    user: state.user,
  }))

  useEffect(() => {
    if (
      window.location.href.includes('app.shypple.com') &&
      user.uid && // user is loaded
      !loaded &&
      !isServiceBlocked('Intercom')
    ) {
      const script = document.createElement('script')
      script.onload = () => {
        window.Intercom('boot', {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          phone: user.phone,
          company: {
            company_id: user.organizationId,
            name: user.companyName,
            customer_state: user.customerState,
            company_role: user.organizationRole,
            preferred_forwarder_id: user.preferredFreightForwarderId,
          },
        })
        window.Intercom('update')
        setLoaded(true)
      }
      script.src = urlJoin(process.env.BASE_URL || '', '/analytics/intercom.js')
      document.body.appendChild(script)
    }
  }, [])

  return null
}

import { TFunction } from 'i18next'

export const getFirstDayOfDemurrage = (
  t: TFunction<'translation', undefined>,
  destinationDemurrage: string | null,
  actualDayOfDemurrage?: string
) => {
  const demurrageDate = actualDayOfDemurrage || destinationDemurrage

  return demurrageDate
    ? t('common.date_medium', {
        date: demurrageDate,
        defaultValue: '{{date, DATE_MED}}',
      })
    : t('shipment_containers.to_be_announced', 'To be announced')
}

import { Grid } from '@mui/material'
import { useFilter } from '../hooks'
import { FilterProps } from '../types'
import { ClearFiltersButton } from './ActionButtonsClear'
import { SaveNewFilterButton } from './ActionButtonsSaveNewFilter'
import { UpdateFilterButton } from './ActionButtonsUpdate'

type ActionButtonsPropsType = {
  hasQuickFilters: FilterProps['hasQuickFilters']
}

export const ActionButtons = ({ hasQuickFilters }: ActionButtonsPropsType) => {
  const { isLoading } = useFilter()

  if (isLoading) return <></>

  return (
    <Grid item xs={12} mt={2.5}>
      <Grid container columnSpacing={2} rowSpacing={1.5}>
        <Grid item xs={12} md={8}>
          {hasQuickFilters && (
            <>
              <SaveNewFilterButton />
              <UpdateFilterButton />
            </>
          )}
        </Grid>
        <Grid item xs={12} md={4} display="flex" justifyContent={{ md: 'end' }}>
          <ClearFiltersButton />
        </Grid>
      </Grid>
    </Grid>
  )
}

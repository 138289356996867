import { AnyAction } from 'redux'
import * as actions from '../actions/webSocketActions'

export const userChannelReceived = (payload: any): AnyAction => ({
  type: actions.USER_CHANNEL_RECEIVED,
  payload,
})

export const userChannelClear = (): AnyAction => ({
  type: actions.USER_CHANNEL_CLEAR,
})

export const userChannelPerform = (payload: any): AnyAction => ({
  type: actions.USER_CHANNEL_PERFORM,
  payload,
})

export const chatChannelPerform = (payload: any): AnyAction => ({
  type: actions.CHAT_CHANNEL_PERFORM,
  payload,
})

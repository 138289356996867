import { Link as MuiLink, Typography } from '@mui/material'

import { Link } from 'react-router-dom'

const OverviewTableShipmentLink: React.FC<{
  text: string | null | undefined
  id: number | string
}> = ({ text, id }) => {
  if (!text) {
    return <Typography>-</Typography>
  }
  return (
    <MuiLink
      variant="body1"
      component={Link}
      to={`shipments/${id}`}
      target="_blank"
      tabIndex={-1}
    >
      <Typography noWrap>{text}</Typography>
    </MuiLink>
  )
}
export default OverviewTableShipmentLink

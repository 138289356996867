import { capitalize } from 'lodash'
import { pickupOrDeliveryStatusColors } from 'src/components/Overview/Search/constants'
import OverviewTableText from './OverviewTableText'
import OverviewTableShipmentColoredCell from './OverviewTableShipmentColoredCell'

const OverviewTablePickupOrDeliveryStatusCell = ({ row, field }) => {
  const statusValue = row.original?.[field] ?? null

  if (!statusValue) return <OverviewTableText text="-" />

  const statusColor = pickupOrDeliveryStatusColors?.[statusValue] ?? 'primary'

  return (
    <OverviewTableShipmentColoredCell
      color={statusColor}
      text={capitalize(statusValue)}
    />
  )
}

export default OverviewTablePickupOrDeliveryStatusCell

import Typography from '@mui/material/Typography'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { CompletionPoint } from './CompletionPoint'
import { MilestoneRow } from './MilestoneRow'

import './milestoneTable.scss'

export interface MilestoneTableProps {
  /**
   * Milestones array
   */
  milestones: IMilestone[]
  /**
   * Click on milestone task event
   */
  onClick: (task: IMilestoneTask) => void
  /**
   * Resolved tasks message
   */
  resolvedTasksMessage: string
}

export const MilestoneTable: React.FC<MilestoneTableProps> = ({
  milestones,
  onClick,
  resolvedTasksMessage,
}) => {
  const { t } = useTranslation()
  const milestonesToShow: IMilestone[] = milestones.filter((x) => !x.reached)

  return (
    <>
      <Box pt={2}>
        <Typography variant="h5" pb={2} data-testid="milestone-table-title">
          {t('shipment_containers.tasks', 'Tasks')}
        </Typography>
        {!milestonesToShow.length && (
          <Box display="flex">
            <CompletionPoint completed={true} />
            <Typography
              ml={1}
              variant="body1"
              children={resolvedTasksMessage}
            />
          </Box>
        )}
        {milestonesToShow.map((milestone: IMilestone) => {
          return (
            <MilestoneRow
              key={milestone.id}
              milestone={milestone}
              onClick={onClick}
            />
          )
        })}
      </Box>
    </>
  )
}

import { FunctionComponent } from 'react'
import { includes, compact } from 'lodash'
import { Typography, Box, Chip } from '@mui/material'
import SubjectToSpaceAndAvailability from 'src/components/SubjectToSpaceAndAvailablity/SubjectToSpaceAndAvailablity'
import {
  containerTypeDescription,
  formatDesktopDate,
  FORMAT_YEAR,
} from '../../../utils/helpers'
import ShipmentIndicator from './ShipmentIndicator'
import ShipmentTag from './ShipmentTag'

const shipmentChipColor = {
  delivered: 'success',
  destination: 'primary',
  in_transit: 'secondary',
  origin: 'info',
  quote: 'warning',
  deleted: 'error',
  cancelled: 'error',
}

const ShipmentMainInfo: FunctionComponent<{ shipment: IDetailedShipment }> = ({
  shipment,
}) => {
  const shipmentTypeIcon: string =
    shipment.type === 'air' ? 'air' : 'trade-ship'

  const shipmentReference = (
    <Typography
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '90%',
      }}
      className="medium bold"
      children={
        compact([shipment.reference_number, shipment.shared_reference]).join(
          ' | '
        ) || '-'
      }
    />
  )

  return (
    <Box
      sx={{ paddingRight: 1 }}
      className="shipment__main"
      data-testid="shipment-main"
    >
      <header className="shipment__title">
        <Typography className="large bold" children={shipment.title} />
        <i className={`shipment__title-icon icon ${shipmentTypeIcon}`} />
      </header>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <ShipmentIndicator
          status={shipment.track_trace_status}
          visibility_only={shipment.visibility_only}
          open_milestones={shipment.open_milestones}
          shipment_rolled={shipment.shipment_rolled}
          type={shipment.modality}
        />
        <div className="shipment__reference">{shipmentReference}</div>
      </Box>
      <div className="shipment__status">
        <div className="shipment__line">
          <Typography className="small light">
            {shipment.flagged ? <i className="icon flag red mr-5" /> : ''}
          </Typography>
          <Chip
            sx={{ marginTop: 1, marginRight: 1 }}
            color={shipmentChipColor[shipment.shipment_phase]}
            label={shipment.humanized_status}
          />
          <ShipmentTag
            visibilityOnly={shipment.visibility_only}
            collaborators={shipment.collaborators}
          />
        </div>
        {shipment.status === 'D' && (
          <span className="shipment__line shipment__status-date">
            &nbsp;&nbsp;(
            {formatDesktopDate(shipment.estimated_arrival, FORMAT_YEAR)})
          </span>
        )}
      </div>
      <SubjectToSpaceAndAvailability
        show={
          !shipment.visibility_only &&
          includes(shipment.status_groups, 'subject_to_space_and_availability')
        }
      />
      <div className="shipment__line shipment__line_amount">
        <Typography className="xsmall normal">
          <span className="shipment__logo">{shipment.carrier_name}</span>
          {shipment.total_quantity} x{' '}
          {containerTypeDescription(
            shipment.shipment_type,
            shipment.container_type
          )}
        </Typography>
      </div>
    </Box>
  )
}

export default ShipmentMainInfo

import { FunctionComponent, ReactNode } from 'react'

import { Box, InputLabel } from '@mui/material'

import { AutoCompleteSelect } from 'src/stories/MUI/Select/AutoCompleteSelect'
import { InfoTooltip } from 'src/stories/MUI/Tooltip/InfoTooltip'

import { getSearchTooltipDescription } from 'src/pages/SearchAndBook/searchTooltipDescription'
import { ListAlt } from '@mui/icons-material'
import { autocompleteIcon } from 'src/pages/SearchAndBook/constants'
import { useDispatch } from 'react-redux'
import { promisifyAction } from 'src/utils'
import { addressSuggestions } from 'src/stores/actionCreators'
import { ModalityEnum } from 'src/config/constants'

interface ISearchPortProps {
  name: string
  label: string
  value: Record<string, any> | null
  modality: ModalityEnum
  placeholder: string
  role: IRoleShort | null
  destination: 'from' | 'to'
  onChange: (option) => void
  optionLabel: (option) => string
  dataTestId?: string
  required?: boolean
  portsOnly?: boolean
  popupIcon?: ReactNode
}

const PortsSearch: FunctionComponent<ISearchPortProps> = ({
  name,
  label,
  value,
  placeholder,
  role,
  modality,
  destination,
  optionLabel,
  onChange,
  dataTestId,
  required,
  portsOnly,
  popupIcon,
}) => {
  const dispatch = useDispatch()
  const getSuggestions = promisifyAction(dispatch, addressSuggestions)
  const roleData = role && getSearchTooltipDescription(role)

  return (
    <>
      <InputLabel htmlFor={name} required={required}>
        <>{label}</>
        {roleData
          ? roleData[destination] && (
              <InfoTooltip
                sx={{
                  marginBottom: 'auto',
                  marginTop: 'auto',
                }}
                title={roleData[destination]}
              />
            )
          : null}
      </InputLabel>
      <AutoCompleteSelect
        id={name}
        name={name}
        popupIcon={popupIcon || <ListAlt />}
        getData={async (q) => {
          const { places } = await getSuggestions({
            input: q || '',
            with_ports: true,
            only_ports: portsOnly,
            shipment_type: modality,
          })
          return places
        }}
        value={value}
        placeholder={placeholder}
        optionLabel={optionLabel}
        onChange={onChange}
        data-testid={dataTestId}
        noOptionsText="Type to search"
      >
        {(Text, option) => (
          <Box display="flex" data-testid={`port-id-${option.secondary_text}`}>
            {autocompleteIcon(option.result_type, modality)}
            <Box ml={1} />
            {Text}
          </Box>
        )}
      </AutoCompleteSelect>
    </>
  )
}

export default PortsSearch

import { FunctionComponent, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TextPlaceholder from 'src/components/Common/TextPlaceholder'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import ContentDropDown from 'src/components/Common/ContentDropDown'
import ServiceLevelAgreementsRowHeader from 'src/components/OrganizationSettings/SLA/ServiceLevelAgreementsRowHeader'
import ServiceLevelAgreementsRowDetails from 'src/components/OrganizationSettings/SLA/ServiceLevelAgreementsRowDetails'
import { slaOpenItem } from '../../../../stores/actionCreators'

interface IServiseLevelAgreementsRowProps {
  slaCheckpoint: ISLA
  blank: boolean
  editSLACheckpoint: (slaCheckpoint: ISLA) => void
  deleteSLACheckpoint: (slaCheckpoint: ISLA) => void
  forShipment?: boolean
  shipmentId?: number
  fetchData: () => void
}

const ServiceLevelAgreementsRow: FunctionComponent<IServiseLevelAgreementsRowProps> = (
  props
) => {
  const { slaCheckpoint, blank } = props
  const dispatch = useDispatch()
  const onDrowdownOpen = useCallback(
    (id: number, isOpen: boolean) => {
      dispatch(slaOpenItem(isOpen ? id : null))
    },
    [slaCheckpoint]
  )

  const { openItem } = useSelector((state: IGlobalState) => ({
    openItem: state.organizationSettings.openItem,
  }))

  const editItem = useCallback(() => {
    props.editSLACheckpoint(slaCheckpoint)
  }, [slaCheckpoint])

  const deleteItem = useCallback(() => {
    props.deleteSLACheckpoint(slaCheckpoint)
  }, [slaCheckpoint])

  const BlankTableRow = (index) => {
    return (
      <TableRow key={index}>
        <TableCell className="left-aligned to-plan short"></TableCell>
        <TableCell className="left-aligned">
          <TextPlaceholder size={5} color="light-grey" />
        </TableCell>
        <TableCell className="left-aligned">
          <TextPlaceholder size={3} color="light-grey" />
        </TableCell>
        <TableCell className="left-aligned">
          <TextPlaceholder size={5} color="light-grey" />
        </TableCell>
        <TableCell className="left-aligned">
          <TextPlaceholder size={3} color="light-grey" />
        </TableCell>
        <TableCell className="left-aligned">
          <TextPlaceholder size={3} color="light-grey" />
        </TableCell>
        <TableCell className="short left-aligned"></TableCell>
      </TableRow>
    )
  }

  if (blank) {
    return <BlankTableRow index={slaCheckpoint.id} />
  } else {
    return (
      <ContentDropDown
        key={slaCheckpoint.id}
        className="container-overview-block"
        header={
          <ServiceLevelAgreementsRowHeader item={slaCheckpoint} opened={true} />
        }
        id={slaCheckpoint.id}
        onToggle={onDrowdownOpen}
        forcedOpen={openItem === slaCheckpoint.id}
        headerCollapsed={
          <ServiceLevelAgreementsRowHeader
            item={slaCheckpoint}
            opened={false}
          />
        }
        body={
          <ServiceLevelAgreementsRowDetails
            slaCheckpoint={slaCheckpoint}
            fetchData={props.fetchData}
            editItem={editItem}
            deleteItem={deleteItem}
          />
        }
        disableCollapse={false}
        tableCellsCount={7}
      />
    )
  }
}

export default ServiceLevelAgreementsRow

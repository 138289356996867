import SelectableChip, {
  ISelectChipColor,
  SelectableChipOptions,
} from 'src/components/SelectableChip'
import { Box } from '@mui/system'
import { inspectionStatusWidth } from '../constants'
import { InlandTransportRowInspectionStatusProps } from './InlandTransportRow.props'

const InlandTransportRowInspectionStatus: React.FC<InlandTransportRowInspectionStatusProps> = ({
  task,
  tasks,
  updatable,
  onChange,
}) => {
  const color =
    tasks.find((taskItem) => taskItem.id === task.id)?.color ?? 'default'

  const chipStatuses: SelectableChipOptions = tasks.map((taskItem) => {
    return {
      type: 'item',
      id: taskItem.id,
      name: taskItem.name,
    }
  })

  const hasInspectionStatus = task.name && task.name.length > 0

  return (
    <Box
      width={inspectionStatusWidth}
      data-testid="inland-transport-inspection-status"
    >
      {hasInspectionStatus && (
        <SelectableChip
          variant="filled"
          disabled={!updatable}
          onChange={(val) => {
            onChange(val)
          }}
          options={chipStatuses}
          value={{
            color: color as ISelectChipColor,
            name: task.name,
          }}
        />
      )}
      {!hasInspectionStatus && '-'}
    </Box>
  )
}

export default InlandTransportRowInspectionStatus

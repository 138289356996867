export const cells = (t): any[] =>
  [
    {
      name: '',
      customClassName: 'short-50',
    },
    {
      name: t('purchase_orders.row_details.cells.amount', 'AMOUNT'),
      customClassName: 'left-aligned medium',
    },
    {
      name: t(
        'purchase_orders.row_details.cells.goods_desc',
        'GOODS DESCRIPTION'
      ),
      customClassName: 'left-aligned x-wide',
    },
    {
      name: t(
        'purchase_orders.row_details.cells.product_number',
        'PRODUCT NUMBER'
      ),
      customClassName: 'left-aligned x-medium',
    },
    {
      name: t('purchase_orders.row_details.cells.volume', 'VOLUME'),
      customClassName: 'left-aligned medium',
    },
    {
      name: t('purchase_orders.row_details.cells.weight', 'WEIGHT'),
      customClassName: 'left-aligned medium',
    },
    {
      name: '',
      customClassName: 'left-aligned dynamic',
    },
  ].map((x) => {
    return {
      name: x.name,
      sortable: false,
      sortValue: '',
      customClassName: x.customClassName,
    }
  })

import { ModalityEnum } from 'src/config/constants'
import { modalityGoodsDescription } from './constants'

export const validateCargoDimensions = (
  length: string | number,
  width: string | number,
  height: string | number,
  modality: ModalityEnum
): string => {
  const data =
    modalityGoodsDescription?.[modality] ??
    modalityGoodsDescription[ModalityEnum.Sea]

  if (
    Number(length) > data.length ||
    Number(width) > data.width ||
    Number(height) > data.height
  ) {
    return (
      data?.message ??
      `The maximum dimensions are ${data.width} x ${data.height} x ${data.length}`
    )
  }

  return ''
}

// @ts-nocheck
// FIXME

import { createReducer, Reducer } from 'redux-create-reducer'
import { ModalityEnum } from 'src/config/constants'

import { AnyAction } from 'redux'
import * as actions from '../actions/shipmentLink'

declare global {
  interface IShipmentLink {
    id: number
    token: string
  }
  interface IShipmentLinkState {
    public_shipment: IPublicShipment
    markers: any
    timeline: any
    legs: any
  }
  interface IPublicShipment {
    id: number
    token: string
    shipment_phase: string
    title: string
    modality: ModalityEnum
  }
}
export const initialShipmentLinkState = {
  public_shipment: {},
  markers: [],
}
const receiveList: Reducer<IShipmentLinkState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  markers: action.payload.map_content,
  public_shipment: action.payload.public_shipment,
  timeline: action.payload.timeline,
  legs: action.payload.shipment_legs,
})
export default createReducer(initialShipmentLinkState, {
  [actions.SHIPMENT_LINK_GET_DATA_SUCCESS]: receiveList,
})

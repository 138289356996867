import { FunctionComponent, useState } from 'react'
import { useSelector } from 'react-redux'
import { isNull } from 'lodash'
import { useTranslation } from 'react-i18next'
import VisibilityIcon from '@mui/icons-material/Visibility'
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined'
import { ToggleButtonGroup, ToggleButton, SvgIcon } from '@mui/material'
import SetUpMyShipmentsModal from 'src/components/Common/WatchShipmentsToggler/SetUpMyShipmentsModal'

const WatchShipmentsToggler: FunctionComponent<{
  value: boolean
  action: (newValue: boolean) => void
}> = ({ value, action }) => {
  const { t } = useTranslation()
  const { watchShipmentSetting } = useSelector((state: IGlobalState) => ({
    watchShipmentSetting: state.user.watchShipmentSetting,
  }))
  const [myShipmentsModalOpen, setMyShipmentsModalOpen] = useState(false)

  return (
    <>
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={(_, newValue) => {
          if (isNull(newValue) || newValue === value) {
            return
          }
          if (watchShipmentSetting !== 'custom' && newValue) {
            setMyShipmentsModalOpen(true)
            return
          }
          action(newValue)
        }}
      >
        <ToggleButton
          value={false}
          data-testid="all-shipments-toggle-button"
          sx={{ paddingRight: 2 }}
        >
          <SvgIcon>
            <RouteOutlinedIcon />
          </SvgIcon>
          {t('common.buttons.all_shipments', 'All shipments')}
        </ToggleButton>
        <ToggleButton value={true} data-testid="my-shipments-toggle-button">
          <SvgIcon>
            <VisibilityIcon />
          </SvgIcon>
          {t('common.buttons.my_shipments', 'My shipments')}
        </ToggleButton>
      </ToggleButtonGroup>
      <SetUpMyShipmentsModal
        open={myShipmentsModalOpen}
        handleClose={() => setMyShipmentsModalOpen(false)}
      />
    </>
  )
}

export default WatchShipmentsToggler

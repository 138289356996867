import { styled } from '@mui/material/styles'
import MenuItem from '@mui/material/MenuItem'
import Stack, { StackProps } from '@mui/material/Stack'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Toolbar, { ToolbarProps } from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import Drawer, { DrawerProps } from '@mui/material/Drawer'
import ListItemIcon, { ListItemIconProps } from '@mui/material/ListItemIcon'
import { StyledMenuItemProps } from './TopNavigation.props'
import {
  defaultMobileBreakpoint,
  drawerWidth,
  toolbarHeight,
} from './TopNavigation.constants'

export const StyledDrawerSearchAction = styled(Stack)<StackProps>(() => ({
  top: 0,
  right: 0,
  zIndex: 1,
  alignItems: 'center',
  position: 'absolute',
  justifyContent: 'flex-end',
}))

export const StyledToolbar = styled(Toolbar)<ToolbarProps>(({ theme }) => ({
  [theme.breakpoints.down(defaultMobileBreakpoint)]: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(1.5),
  },
  [theme.breakpoints.up(defaultMobileBreakpoint)]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },

  minHeight: toolbarHeight,
  justifyContent: 'space-between',
}))

export const StyledCreateMenuItem = styled(Stack)<StyledMenuItemProps>(
  ({ theme }) => ({
    padding: theme.spacing(1.5, 1.5, 2.5, 1.5),
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
    '&.active': {
      backgroundColor: theme.palette.primary.light,
    },
  })
)

export const StyledCreateIconButton = styled(IconButton)<IconButtonProps>(
  ({ theme }) => ({
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.info.main,
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
    '&.open': {
      transform: 'rotate(45deg)',
    },
  })
)

export const StyledMenuButton = styled(Button)<StyledMenuItemProps>(
  ({ theme }) => ({
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    '& svg': {
      transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.shortest,
      }),
    },
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary[500],
    },
    '&.active': {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.primary.main,
    },
    '&.open': {
      '& svg': {
        transform: 'rotate(180deg)',
      },
    },
  })
)

export const StyledDrawer = styled(Drawer)<DrawerProps>(({ theme }) => ({
  zIndex: theme.zIndex.appBar - 1,
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    width: drawerWidth,
    boxShadow: theme.shadows[8],
    paddingTop: toolbarHeight,
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(255, 255, 255, 0)',
  },
}))

export const StyledMenuItem = styled(MenuItem)<StyledMenuItemProps>(
  ({ theme }) => ({
    height: 40,
    cursor: 'pointer',
    '&.active': {
      backgroundColor: theme.palette.primary.light,
      cursor: 'default',
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
  })
)

export const StyledMenuItemIcon = styled(ListItemIcon)<ListItemIconProps>(
  ({ theme }) => ({
    color: theme.palette.primary.main,
  })
)

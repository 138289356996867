import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { Link as MuiLink } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'

import { convertDatePickerToUniversalFormat } from '../../../../utils/helpers'

interface IProps {
  item: ISLA
  opened?: boolean
  iconed?: boolean
  expandable?: boolean
  slaLink?: boolean
}

const ServiceLevelAgreementsRowHeader: FunctionComponent<IProps> = (props) => {
  const { item } = props

  return (
    <>
      <TableCell className={`left-aligned short`}>
        {props.iconed && <i className="icon supplier-initiated" />}
      </TableCell>
      <TableCell className="left-aligned x-medium">
        <div className="text-wrapper">
          <Typography
            variant="body1"
            children={
              item.sla_checkpoint_type ? item.sla_checkpoint_type.name : '-'
            }
          />
        </div>
      </TableCell>
      <TableCell className="left-aligned medium">
        <Typography variant="body1Strong">
          {props.slaLink ? (
            <MuiLink
              variant="body1"
              component={Link}
              to={`/organization_settings/service_level_agreements?open_item=${item.id}`}
            >
              {item.name}
            </MuiLink>
          ) : (
            item.name
          )}
        </Typography>
      </TableCell>
      <TableCell className="left-aligned x-wide">
        <div className="text-wrapper">
          <Typography variant="body1">
            {item.sla_checkpoint_value
              ? item.sla_checkpoint_value.description
              : '-'}{' '}
            {item.dt_days && item.dt_days > 0 ? '+ ' : ''}
            {item.dt_days} days
          </Typography>
        </div>
      </TableCell>
      <TableCell className="left-aligned large">
        <Typography
          variant="body1"
          children={item.owner ? item.owner.full_name : '-'}
        />
      </TableCell>
      <TableCell className="left-aligned x-medium">
        <Typography
          variant="body1"
          children={convertDatePickerToUniversalFormat(item.created_at || '')}
        />
      </TableCell>
      <TableCell className="short dynamic">
        {props.expandable && (
          <div className="po-toggle">
            <i className={`icon chevron ${props.opened ? '' : 'collapsed'}`} />
          </div>
        )}
      </TableCell>
    </>
  )
}

export default ServiceLevelAgreementsRowHeader

ServiceLevelAgreementsRowHeader.defaultProps = {
  expandable: true,
  iconed: true,
  slaLink: false,
}

import { memo } from 'react'
import Box from '@mui/material/Box'
import Drawer from 'src/stories/Drawer'
import { useTranslation } from 'react-i18next'
import QuickFiltersManageDrawerContent from './QuickFiltersManageDrawerContent'
import QuickFiltersManageDrawerHeader from './QuickFiltersManageDrawerHeader'
import { QuickFiltersManageDrawerProps } from './QuickFiltersManageDrawer.props'

const QuickFiltersManageDrawer: React.FC<QuickFiltersManageDrawerProps> = memo(
  ({ open, onClose, savedFilters, onSubmit, onDelete }) => {
    const { t } = useTranslation()
    return (
      <Drawer
        onClose={onClose}
        open={open}
        heading={t(
          'filters.headings.manage_quick_filters',
          'Manage Quick Filters'
        )}
      >
        <Box width={600} data-testid="quick-filters-body">
          <QuickFiltersManageDrawerHeader />
          {open && (
            <QuickFiltersManageDrawerContent
              onDelete={onDelete}
              onSubmit={onSubmit}
              savedFilters={savedFilters}
            />
          )}
        </Box>
      </Drawer>
    )
  }
)

export default QuickFiltersManageDrawer

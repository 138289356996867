import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { getClientSegmentation } from './DemurrageAndDetentionContent.constants'
import { DemurrageAndDetentionClientSegmentationType } from './DemurrageAndDetentionContent.props'

const DemurrageAndDetentionContentText: React.FC<{
  typeOfUser: DemurrageAndDetentionClientSegmentationType
}> = ({ typeOfUser }) => {
  const { t } = useTranslation()
  const clientSegmentation = getClientSegmentation(t)
  return (
    <Box px={3}>
      <Typography
        variant="h3"
        children={t('demurrage_and_detention.title', 'Demurrage and detention')}
      />
      <Typography
        mt={0.5}
        maxWidth={480}
        variant="body1"
        children={clientSegmentation[typeOfUser].description}
      />
    </Box>
  )
}

export default DemurrageAndDetentionContentText

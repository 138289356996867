import { Helmet } from 'react-helmet'

function HelmetInjector() {
  return (
    <Helmet>
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
    </Helmet>
  )
}

export default HelmetInjector

import { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import OptionsPickerAsync, {
  IOptionsPickerAsyncProps,
} from 'src/components/Common/Input/OptionsPickerAsync'
import {
  organizationsGetData,
  organizationGetPreloaded,
  clearOrganizations,
  clearPreloadedOrganizations,
} from 'src/stores/actionCreators'

interface IProps
  extends Omit<
    IOptionsPickerAsyncProps,
    | 'onChange'
    | 'options'
    | 'isFetching'
    | 'clearOptionsAction'
    | 'fetchOptionsAction'
    | 'theme'
  > {
  onChange: (values: string[][], name: string) => void
  selectedIds: string[]
}

const OrganizationFilter = ({
  fieldName,
  label,
  error = false,
  onChange,
  values,
  selectedLabel,
  selectedIds,
}: IProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (selectedIds.length && !values.length) {
      dispatch(organizationGetPreloaded({ ids: selectedIds }))
    }
  }, [selectedIds, values])

  const clearOptionsAction = () => {
    dispatch(clearOrganizations())
  }

  const fetchOptionsAction = (search: string) => {
    dispatch(organizationsGetData({ search }))
  }

  const { organizations, preloaded } = useSelector((state: IGlobalState) => ({
    organizations: state.organizations.list,
    preloaded: state.organizations.preloaded,
  }))

  useEffect(() => {
    if (preloaded.length && !values.length) {
      onChange(
        preloaded.map(({ name, id }) => [name, id.toString()]),
        fieldName
      )
      dispatch(clearPreloadedOrganizations())
    }
  }, [preloaded, values])

  const mappedOptions = useMemo(
    () =>
      organizations
        .filter(
          (org) => !values.map(([name, id]) => id).includes(org.id.toString())
        )
        .map((org) => [org.name, org.id]),
    [organizations, values]
  )

  return (
    <OptionsPickerAsync
      fetchOptionsAction={fetchOptionsAction}
      clearOptionsAction={clearOptionsAction}
      isFetching={false}
      values={values}
      label={label}
      onChange={onChange}
      fieldName={fieldName}
      options={[...values, ...mappedOptions]}
      error={error}
      theme="filtersPagePicker"
      selectedLabel={selectedLabel || undefined}
    />
  )
}

export default OrganizationFilter

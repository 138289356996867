import { createReducer, Handlers, Reducer } from 'redux-create-reducer'
import { AnyAction } from 'redux'
import * as actions from '../actions/searches'

declare global {
  interface ICarrierOrganization {
    id: number
    organization: IOrganization
    carrier: ICarrier
  }
  interface ICarrierOrganizationResult {
    list: ICarrierOrganization[]
  }
}

export const initialCarriersOrgState: ICarrierOrganizationResult = {
  list: [],
}

const getCarrierOrganizatons: Reducer<ICarrierOrganizationResult, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  list: payload,
})

const actionHandlers: Handlers<ICarrierOrganizationResult> = {
  [actions.GET_CARRIER_ORGANIZATIONS_SUCCESS]: getCarrierOrganizatons,
}

export default createReducer(initialCarriersOrgState, actionHandlers)

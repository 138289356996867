import { createReducer, Reducer } from 'redux-create-reducer'
import { AnyAction } from 'redux'
import {
  FLAGS_UPDATE_APP,
  FLAGS_WINDOW_ACTIVE,
  APPLICATION_FILTER_MODAL,
  SHIPMENT_SHARE_MODAL,
} from '../actions/flags'

declare global {
  interface IFlagsState {
    updateApp: boolean
    windowActive: boolean
    displayApplicationFilterModal: boolean
    displayShipmentShareModal: boolean
    selectedDocument: number | null
  }
}

export const initialFlagsState: IFlagsState = {
  updateApp: false,
  windowActive: true,
  displayApplicationFilterModal: false,
  displayShipmentShareModal: false,
  selectedDocument: null,
}

const setUpdateAppFlag: Reducer<IFlagsState, AnyAction> = (state, action) => ({
  ...state,
  updateApp: action.payload,
})

const setWindowActiveFlag: Reducer<IFlagsState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  windowActive: action.payload,
})

const toggleApplicationFilterModal: Reducer<IFlagsState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  displayApplicationFilterModal: action.payload,
})

const toggleShipmentShareModal: Reducer<IFlagsState, AnyAction> = (
  state,
  action
) => {
  return {
    ...state,
    displayShipmentShareModal: action.status,
    selectedDocument: action.docId,
  }
}

export default createReducer(initialFlagsState, {
  [FLAGS_UPDATE_APP]: setUpdateAppFlag,
  [FLAGS_WINDOW_ACTIVE]: setWindowActiveFlag,
  [APPLICATION_FILTER_MODAL]: toggleApplicationFilterModal,
  [SHIPMENT_SHARE_MODAL]: toggleShipmentShareModal,
})

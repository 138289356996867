import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Logger, permissionTo, promisifyAction } from 'src/utils'
import { Tooltip } from '@mui/material'
import { updateTaskStatus } from 'src/stores/actionCreators'
import SelectableChip, {
  OptionsItem as ISelectOption,
} from 'src/components/SelectableChip'

interface IProps {
  currentTaskTypeStatusId: number | string
  shipmentId: number
  taskId: number
  selectedMapping: ISelectOption[]
}

const TaskStatusSelect: FunctionComponent<IProps> = ({
  currentTaskTypeStatusId,
  shipmentId,
  taskId,
  selectedMapping,
}) => {
  const dispatch = useDispatch()
  const [selectedStatusId, setSelectedStatusId] = useState<number | string>(
    currentTaskTypeStatusId
  )
  const updateTaskStatusAsync = promisifyAction(dispatch, updateTaskStatus)
  const value: ISelectOption = useMemo(() => {
    return selectedMapping.find(
      (option) => option.id === selectedStatusId
    ) as ISelectOption
  }, [selectedStatusId])

  const onChange = useCallback(
    async (item) => {
      setSelectedStatusId(item.id)
      await updateTaskStatusAsync(shipmentId, taskId, {
        task_status_id: item.id,
      })
    },
    [shipmentId, taskId]
  )

  if (!value) {
    Logger.log(
      `Task status id=${currentTaskTypeStatusId} is not in the list of ${selectedMapping}`
    )
    return null
  }

  return (
    <Tooltip
      title={value.name as string}
      disableInteractive={true}
      placement="top"
    >
      <div>
        <SelectableChip
          disabled={!permissionTo('pickups_deliveries.shypple_admin')}
          options={selectedMapping as ISelectOption[]}
          size="small"
          value={value}
          variant="filled"
          onChange={onChange}
          noLabel
        />
      </div>
    </Tooltip>
  )
}

export default TaskStatusSelect

import { FunctionComponent, useMemo, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { includes, find, values, compact } from 'lodash'
import useGoBack from 'src/hooks/useGoBack'
import { useHistory, useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import VisibilityIcon from '@mui/icons-material/Visibility'
import FlagIcon from '@mui/icons-material/Flag'
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined'
import { Box, Button, Link } from '@mui/material'
import { showNotification } from 'src/stores/actionCreators/notifications'
import ShipmentTemplate from 'src/components/ShipmentTemplate'
import { BreadcrumbItem, Breadcrumbs } from 'src/stories/Breadcrumbs'
import { CreateRounded } from '@mui/icons-material'
import { AvatarGroup } from 'src/stories/AvatarGroup'
import TextPlaceholder from 'src/components/Common/TextPlaceholder'
import ShipmentReferencesModal from 'src/components/ShipmentReferencesModal'
import ShipmentShareModal from 'src/components/ShipmentShareModal'
import ShipmentCollaboratorsPopper from 'src/components/ShipmentCollaboratorsPopper'
import { getInternalReference } from '../ShipmentReferencesModal'
import { permissionTo, promisifyAction } from '../../utils'

import {
  updateConnections,
  shipmentOverviewGetData,
} from '../../stores/actionCreators'
import { renderTooltipReference, getPartiesAvatarsArray } from '../helpers'
import './styles.scss'

const classesTooltip = {
  tooltip: 'shipment__header__tooltip',
  popper: 'shipment__header__popper',
}

const useStyles = makeStyles((theme) => ({
  referenceButton: {
    color: theme.palette.action.active,
  },
  editButton: {
    background: 'none',
    color: theme.palette.action.active,
    '&:hover': {
      background: 'none',
      color: theme.palette.primary.main,
    },
  },
}))

const ShipmentHeader: FunctionComponent<{}> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()

  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [openShareModal, setOpenShareModal] = useState<boolean>(false)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  const updateConnectionsAsync = promisifyAction(dispatch, updateConnections)
  const shipmentOverviewGetDataAsync = promisifyAction(
    dispatch,
    shipmentOverviewGetData
  )

  const showSuccess = (message: string): void => {
    dispatch(showNotification({ message, severity: 'success' }))
  }
  const showError = (message: string): void => {
    dispatch(showNotification({ message, severity: 'error' }))
  }

  const closeModal = useCallback(() => {
    setOpenEditModal(false)
  }, [])

  const openModal = useCallback(() => {
    setOpenEditModal(true)
  }, [])

  const { shipment, currentUser, displayShipmentShareModal } = useSelector(
    (state: IGlobalState) => ({
      shipment: state.shipmentOverview,
      currentUser: state.user,
      displayShipmentShareModal: state.flags.displayShipmentShareModal,
    })
  )

  const isCarrierBooking = !!(
    location?.pathname === `/shipments/${shipment.id}/carrier-booking`
  )

  const goBack = useGoBack()
  const isQuote: boolean = useMemo(
    (): boolean => includes(['QUO', 'PQUO'], shipment.status),
    [shipment]
  )

  const organizationReference: string = useMemo(
    () => getInternalReference(shipment, currentUser),
    [shipment]
  )

  const flagBlock = useCallback((): React.ReactNode => {
    const currentParty: IShipmentParty | undefined = find(
      shipment.collaborators,
      (party) => party.organization_id === currentUser.organizationId
    )
    if (!currentParty) {
      return null
    }

    return currentParty.flagged_at ? (
      <FlagIcon color="primary" />
    ) : (
      <FlagOutlinedIcon color="primary" />
    )
  }, [shipment.collaborators, currentUser.organizationId])

  const manageReferenceBlock = (): React.ReactNode => {
    if (!permissionTo('shipments.overview.reference.manage')) {
      return null
    }

    return (
      <Box
        ml={1}
        className={classes.referenceButton}
        data-testid="shipment-header-edit"
      >
        <Button
          variant="text"
          className={classes.editButton}
          onClick={openModal}
          startIcon={<CreateRounded />}
        >
          <Link typography="body1" color="inherit">
            {t('common.buttons.edit', 'Edit')}
          </Link>
        </Button>
      </Box>
    )
  }

  const reference = useMemo(() => {
    const references = [
      shipment.title,
      organizationReference,
      shipment.shared_reference,
    ]

    return shipment.shared_reference || organizationReference ? (
      <Box className="references" data-testid="shipment-header-references">
        {compact(references).join(' | ')}
      </Box>
    ) : (
      shipment.title
    )
  }, [shipment.title, organizationReference, shipment.shared_reference])

  const referenceBlock = (
    <Box mt={0.5}>
      <Typography variant="body1Strong">{reference}</Typography>
    </Box>
  )

  const shipmentCollaborators = useMemo(() => {
    if (shipment.visibility_only) {
      return shipment.collaborators.filter((collaborator) => {
        return (
          collaborator.roles.length > 1 ||
          collaborator.roles[0].role !== 'Visibility provider'
        )
      })
    }

    return shipment.collaborators
  }, [shipment.collaborators])

  const links = useMemo(() => {
    const items: BreadcrumbItem[] = [
      {
        label: isQuote
          ? t('common.entities.quote_other', 'Quotes')
          : t('common.entities.shipment_other', 'Shipments'),
        onClick: goBack,
      },
    ]
    if (isCarrierBooking) {
      return [
        ...items,
        {
          label: referenceBlock,
          onClick: () => history.push(`/shipments/${shipment.id}`),
        },
      ]
    }

    return items
  }, [isCarrierBooking, isQuote])

  if (!shipment.id) {
    return (
      <header className="shipment__header">
        <div id="shipment-header-content" className="shipment__header--content">
          <div className="shipment__header--reference-content">
            <TextPlaceholder size={4} color="blue" />
            <div className="shipment__header--slash">/</div>
            <TextPlaceholder size={6} color="dark-blue" />
            <div className="shipment__header--reference">
              <TextPlaceholder size={3} color="blue" />
            </div>
          </div>
          <div className="shipment__header--share-content">
            <TextPlaceholder size={4} color="blue" />
          </div>
        </div>
      </header>
    )
  }

  const showPublicLink: boolean =
    permissionTo('shipments.public_link.all') &&
    !!shipment &&
    !!shipment.token_enabled &&
    !!shipment.token

  const onSaveAndFetch = async (selectedConnections) => {
    const dataObj = selectedConnections.map((party) => {
      return {
        id: party?.id,
        organization_id: party.organization_id,
        role_ids: (party.roles || []).map((role) => role.id) || [],
      }
    })
    try {
      setButtonLoading(true)
      await updateConnectionsAsync(shipment ? shipment.id : '', {
        shipment_organizations: dataObj,
      })
      showSuccess('You have added one or more new collaborators.')
      await shipmentOverviewGetDataAsync(shipment ? shipment.id : '')
      setOpenShareModal(false)
    } catch (error) {
      const unknownError: any = error
      const errorsArr: string[] = values(unknownError.response.data.errors).map(
        (item) => {
          return item || '-'
        }
      )
      showError(
        values(unknownError.response.data.errors).length > 1
          ? compact(errorsArr).join(', ')
          : errorsArr[0]
      )
      setButtonLoading(false)
    }
    setButtonLoading(false)
  }

  const tooltipElement = (
    <Tooltip
      title={renderTooltipReference(
        shipment.shared_reference,
        organizationReference,
        shipment.title
      )}
      classes={classesTooltip}
      placement="bottom"
    >
      {referenceBlock}
    </Tooltip>
  )

  return (
    <header className="shipment__header">
      <div
        id="shipment-header-content"
        data-testid="shipment-header-content"
        className="shipment__header--content heading__2 blue"
      >
        <div className="shipment__header--reference-content">
          <Breadcrumbs
            links={links}
            current={
              location?.pathname === `/shipments/${shipment.id}/carrier-booking`
                ? 'Carrier booking'
                : tooltipElement
            }
          ></Breadcrumbs>

          {manageReferenceBlock()}
        </div>

        <div
          className="shipment__header--share-content"
          data-testid="shipment-header-share-content"
        >
          <ShipmentTemplate
            shipmentId={shipment.id}
            shipmentContainerType={shipment.container_type}
          />
          <ShipmentCollaboratorsPopper
            shipmentId={shipment.id}
            parties={shipment.collaborators}
            groupByValue="organization_id"
            watchersCount={shipment.watchers_count}
            watched={shipment.watched}
            withFlag={true}
          >
            {shipment.watched ? <VisibilityIcon color="primary" /> : ''}
            {permissionTo('shipments.shipment_collaborators.view') && (
              <>
                {flagBlock()}
                <Box mr={2}>
                  <AvatarGroup
                    avatars={getPartiesAvatarsArray(
                      shipmentCollaborators,
                      'organization_id'
                    )}
                    max={4}
                    className="mr-15"
                    onClick={setOpenShareModal.bind(null, true)}
                  />
                </Box>
              </>
            )}
          </ShipmentCollaboratorsPopper>
          {permissionTo('shipments.shipment_collaborators.view') && (
            <Button
              variant="contained"
              onClick={setOpenShareModal.bind(null, true)}
            >
              {t('common.buttons.share', 'Share')}
            </Button>
          )}
        </div>
      </div>
      {openEditModal && (
        <ShipmentReferencesModal
          shipmentTitle={shipment.title}
          shipmentId={shipment.id}
          open
          onClose={closeModal}
        />
      )}
      {(openShareModal || displayShipmentShareModal) && (
        <ShipmentShareModal
          shipmentId={shipment.id}
          collaborators={shipment.collaborators || []}
          showPublicLink={showPublicLink}
          open
          onClose={setOpenShareModal.bind(null, false)}
          onSaveAndFetch={onSaveAndFetch}
          buttonLoading={buttonLoading}
          token={shipment.token || ''}
          allowManageable={false}
          headerText={t('shipment_share.title', 'Share shipment')}
        />
      )}
    </header>
  )
}

export default ShipmentHeader

import { Box, Divider, Grid, Paper } from '@mui/material'
import { ReactNode } from 'react'
import { filterIsUnchanged } from '../utils'
import { useFilter } from '../hooks'
import QuickFilters from '../QuickFilter'
import { FilterProps } from '../types'
import { ActionButtons } from './ActionButtons'
import MiniFilters from './MiniFilters'
import { FiltersSkeleton } from './Skeleton'
import { ClearFiltersButton } from './ActionButtonsClear'

type WrapperPropTypes = {
  open?: boolean
  testId?: string
  children: ReactNode
  hasQuickFilters: FilterProps['hasQuickFilters']
}

export const Wrapper = ({
  children,
  open = true,
  hasQuickFilters = true,
  ...props
}: WrapperPropTypes) => {
  const {
    defaultFiltersKeys,
    filters,
    quickFilter,
    initialFilters,
  } = useFilter()

  const { filterIsSameAsInitialFilter } = filterIsUnchanged(
    filters,
    quickFilter,
    initialFilters,
    defaultFiltersKeys
  )

  return (
    <Paper variant="outlined" sx={{ mt: 1 }} {...props}>
      {hasQuickFilters && (
        <>
          <QuickFilters />
          <Divider />
        </>
      )}
      {open && (
        <Box p={2}>
          <Grid container columnSpacing={2} rowSpacing={1.5}>
            <FiltersSkeleton>{children}</FiltersSkeleton>
          </Grid>
          <ActionButtons hasQuickFilters={hasQuickFilters} />
        </Box>
      )}
      {!open && !filterIsSameAsInitialFilter && (
        <Box p={2} pt={1} sx={{ display: 'flex' }}>
          <Box sx={{ flex: 1 }}>
            <MiniFilters />
          </Box>
          <Box sx={{ alignSelf: 'end' }}>
            <ClearFiltersButton />
          </Box>
        </Box>
      )}
    </Paper>
  )
}

import { Skeleton } from '@mui/material'
import Box from '@mui/material/Box'

export const SearchContainerInputTemplate = (width: number) => (
  <Box>
    <Skeleton
      animation="wave"
      variant="text"
      sx={{ width: 100, height: 20, ml: 2 }}
    />
    <Skeleton
      animation="wave"
      variant="text"
      sx={{ width, height: 60, ml: 2 }}
    />
  </Box>
)

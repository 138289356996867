import capitalize from 'lodash/startCase'
import { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'

import { Chip } from '@mui/material'
import MuiLink from '@mui/material/Link'
import { Link } from 'react-router-dom'
import { useGetOnlyWatchedShipmentsTrack } from 'src/services/Api/maps'
import { useTranslation } from 'react-i18next'

interface IException {
  name: string
  color: 'primary' | 'error' | 'warning' | 'default'
}

const exceptions_list: IException[] = [
  { name: 'pending', color: 'primary' },
  { name: 'error', color: 'error' },
  { name: 'paused', color: 'warning' },
  { name: 'no_subscription', color: 'default' },
]

const MapExceptions: FunctionComponent = () => {
  const { visibilityTrial } = useSelector((state: IGlobalState) => ({
    visibilityTrial: state.user.organization_visibility_trial,
  }))
  const { t } = useTranslation()
  const { data } = useGetOnlyWatchedShipmentsTrack()
  const exceptions = data?.data.exceptions || {}

  const renderCounter = (exception: IException) => {
    if (!exceptions[exception.name]) {
      return null
    }

    return (
      <div key={exception.name} className="map-overview--exceptions--item">
        <Chip
          sx={{ marginRight: '10px' }}
          size="small"
          color={exception.color}
          label={exceptions[exception.name]}
        />
        <MuiLink
          component={Link}
          to={`/shipments?track_trace_statuses[]=${exception.name}`}
        >
          {capitalize(exception.name)}
        </MuiLink>
      </div>
    )
  }

  const renderExceptions = () => {
    if (!Object.keys(exceptions).length || !visibilityTrial) {
      return (
        <div className="map-overview--exceptions">
          {t(
            'dashboard.map.exceptions.no_exceptions',
            'Great visibility. It looks like smooth sailing.'
          )}
        </div>
      )
    }

    return (
      <>
        {t(
          'dashboard.map.exceptions.attention',
          'These shipments may need your attention:'
        )}
        {exceptions_list.map((x) => renderCounter(x))}
      </>
    )
  }

  return (
    <div className="map-overview--exceptions" data-testid="map-exceptions">
      {renderExceptions()}
    </div>
  )
}

export default MapExceptions

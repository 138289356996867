import { ReactNode, RefObject, useEffect, useRef, useState } from 'react'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { Box, Paper, Button } from '@mui/material'

import './styles.scss'

export interface ScrollShadowWrapperProps {
  children: ReactNode
  isItemsPresent: boolean
}

const ScrollShadowWrapper = (props: ScrollShadowWrapperProps) => {
  const { children } = props

  const [scrollLeft, setScrollLeft] = useState(0)
  const [scrollWidth, setScrollWidth] = useState(0)
  const [offsetWidth, setOffsetWidth] = useState(0)
  const [isTableBottom, setIsTableBottom] = useState(true)

  const onScrollHandler = (event: React.WheelEvent<HTMLDivElement>) => {
    setScrollLeft(event.currentTarget.scrollLeft)
    setScrollWidth(event.currentTarget.scrollWidth)
    setOffsetWidth(event.currentTarget.offsetWidth)
  }
  const wrapperRef = useRef<HTMLDivElement>(null)

  const onWindowScroll = () => {
    const wrapper = wrapperRef?.current
    if (wrapper) {
      setIsTableBottom(window.scrollY < wrapper.clientHeight - 36)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onWindowScroll)
    return () => window.removeEventListener('scroll', onWindowScroll)
  }, [])

  useEffect(() => {
    const resetRefSizes = (ref: RefObject<HTMLDivElement>) => {
      if (!ref.current) return

      setScrollLeft(ref.current.scrollLeft)
      setScrollWidth(ref.current.scrollWidth)
      setOffsetWidth(ref.current.offsetWidth)
    }

    resetRefSizes(wrapperRef)
  }, [wrapperRef?.current?.offsetWidth])

  const getScrollableSides = (): {
    left: boolean
    right: boolean
  } => {
    const isLeft = offsetWidth === scrollWidth - scrollLeft
    const isRight = scrollLeft === 0
    const isBetween = scrollLeft > 0 && offsetWidth < scrollWidth - scrollLeft

    return {
      left: (isLeft || isBetween) && !(isRight && isLeft),
      right: (isRight || isBetween) && !(isRight && isLeft),
    }
  }

  const clickOnLeft = () => {
    const wrapper = wrapperRef?.current
    if (wrapper) wrapper.scrollLeft -= 600
  }
  const clickOnRight = () => {
    const wrapper = wrapperRef?.current
    if (wrapper) wrapper.scrollLeft += 600
  }

  return (
    <Box
      className={`${getScrollableSides().left ? 'is-left' : ''} ${
        getScrollableSides().right ? 'is-right' : ''
      }`}
      overflow="hidden"
    >
      {props.isItemsPresent && isTableBottom && (
        <>
          <Button
            id="scroll-left"
            className="scrolling-button"
            sx={{
              left: 16,
              borderRadius: '0% 50% 50% 0%',
              borderLeft: 'none !important',
            }}
            onClick={clickOnLeft}
            data-testid="scroll-left"
          >
            <ChevronLeftIcon />
          </Button>
          <Button
            id="scroll-right"
            className="scrolling-button"
            sx={{
              right: 16,
              borderRadius: '50% 0% 0% 50%',
              borderRight: 'none !important',
            }}
            onClick={clickOnRight}
            data-testid="scroll-right"
          >
            <ChevronRightIcon />
          </Button>
        </>
      )}
      <Paper
        square
        elevation={0}
        ref={wrapperRef}
        onScroll={onScrollHandler}
        data-testid="scroll-shadow-wrapper"
        sx={{ maxHeight: '100%', overflowX: 'auto', scrollBehavior: 'smooth' }}
      >
        {children}
      </Paper>
    </Box>
  )
}

export default ScrollShadowWrapper

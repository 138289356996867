import { AnyAction } from 'redux'
import { takeEvery, select } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'connected-react-router'

const selector = (state: IGlobalState): boolean => state.flags.updateApp

function* handleLocationChange(action: AnyAction): Iterator<any> {
  const shouldAppUpdate = yield select(selector)
  if (shouldAppUpdate) {
    window.location.reload()
  }
}
export default function* (): Iterator<any> {
  yield takeEvery(LOCATION_CHANGE, handleLocationChange)
}

import { AnyAction } from 'redux'
import * as actions from '../actions/shipmentPurchaseOrders'

export const shipmentPurchaseOrdersGetData = (id: number): AnyAction => ({
  type: actions.SHIPMENT_PURCHASE_ORDERS_GET_DATA,
  id,
})

export const shipmentPurchaseOrdersGetDataSuccess = (
  payload: any
): AnyAction => ({
  type: actions.SHIPMENT_PURCHASE_ORDERS_GET_DATA_SUCCESS,
  payload,
})

import { createReducer, Handlers, Reducer } from 'redux-create-reducer'
import { AnyAction } from 'redux'
import * as actions from '../actions/searches'

declare global {
  interface IVessel {
    display_name: string
    id: number
    imo: number
    name: string
    created_at: string
    updated_at: string
  }

  interface IVesselsResult {
    list: IVessel[]
  }
}

export const initialVesselsState: IVesselsResult = {
  list: [],
}

const getVessels: Reducer<IVesselsResult, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  list: payload,
})

const actionHandlers: Handlers<IVesselsResult> = {
  [actions.GET_VESSELS_SUCCESS]: getVessels,
}

export default createReducer(initialVesselsState, actionHandlers)

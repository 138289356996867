import { Typography, ButtonBase } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { omitBy } from 'lodash'
import { stringify } from 'query-string'
import { Link } from 'react-router-dom'
import useLocalStorage from 'src/hooks/useLocalStorage'

import {
  containerTypeDescription,
  containerTypeIcon,
} from '../../../../utils/helpers'

interface IRowProps {
  search: ISearchPrevious
}

const Row = (props: IRowProps) => {
  const [organization] = useLocalStorage<IOrganization | null>(
    'scopedOrganization',
    null
  )
  const { containerTypes, user } = useSelector((state: IGlobalState) => ({
    containerTypes: state.packageTypes.container_types,
    user: state.user,
  }))

  const containers = Object.keys(props.search.container_types || {}).reduce(
    (res, containerId) => {
      const container = containerTypes.find(
        (container) => container.id === parseInt(containerId)
      )
      if (!container) return res
      return {
        ...res,
        [container.code]:
          (props.search.container_types || {})[containerId] || 0,
      }
    },
    {}
  )

  const searchUrl = (): string => {
    const params = omitBy(
      props.search,
      (value, key) => value === false || value == null || value === 'false'
    )

    return stringify(
      {
        ...params,
        ...containers,
        container_types: JSON.stringify(params.container_types),
        shipment_organizations: [
          JSON.stringify({
            organization_id: user.organizationId,
            role_ids: [user.preferredShipmentRoleId],
          }),
        ],
        ...(organization && {
          customer_organization_id: organization.id,
          preferred_shipment_role_id: organization.preferred_shipment_role_id,
        }),
      },
      { arrayFormat: 'bracket' }
    )
  }

  const totalContainers = Object.values(
    props.search.container_types || props.search.quantities
  ).reduce((sum, v) => sum + (parseInt(v.toString()) || 0), 0)

  const containerType =
    totalContainers === 1
      ? Object.keys(containers).find((key) => containers[key] >= 1) ||
        'multiple'
      : 'multiple'
  const icon = containerTypeIcon(props.search.load_type, containerType)
  const description = containerTypeDescription(
    props.search.load_type,
    containerType
  )

  const { t } = useTranslation()

  return (
    <ButtonBase
      component={Link}
      to={`/search?${searchUrl()}`}
      sx={{ width: '100%' }}
    >
      <div className="search-previous__row" style={{ width: '100%' }}>
        <div
          className="search-previous__location"
          data-testid="search-previous-origin"
        >
          <Typography
            className="small light"
            children={t('common.origin', 'Origin')}
          />
          <div className="search-previous__location-block">
            <span className="search-previous__location-name">
              <Typography
                className="medium black text-wrapper"
                children={props.search.start}
              />
            </span>
          </div>
        </div>
        <i className="icon chevron" />
        <div
          className="search-previous__location"
          data-testid="search-previous-destination"
        >
          <Typography
            className="small light"
            children={t('common.destination', 'Destination')}
          />
          <div className="search-previous__location-block">
            <span className="search-previous__location-name">
              <Typography
                className="medium black text-wrapper"
                children={props.search.end}
              />
            </span>
          </div>
        </div>
        <div className="search-previous__empty" />
        <div
          className="search-previous__carriage"
          data-testid="search-previous-carriage"
        >
          {typeof icon === 'string' ? <i className={`icon ${icon}`} /> : icon}
          {description}
          <div>{totalContainers}x</div>
        </div>
      </div>
    </ButtonBase>
  )
}

export default Row

import {
  find,
  minBy,
  maxBy,
  orderBy,
  filter,
  sortBy,
  groupBy,
  values,
  map,
} from 'lodash'
import { RatesListTypeEnum } from 'src/@types/endpoints/prices'
import { getFormattedRange } from 'src/components/Rates/helpers'

const AIR_SERVICE_ITEM_CODE = 'FREI_AIR'
const ORIGIN_SERVICE_CODES = ['pickup', 'export_customs', 'origin_port']
const DESTINATION_SERVICE_CODES = [
  'destination_port',
  'import_customs',
  'delivery',
  'other',
  'insurance',
]

const OCEAN_FREIGHT_PER_WEIGHT_MEASURE_CODE = 'FREI_OCEAN_WM'

const calculationMethods = {
  CONTAINER: 'container',
  TEU: 'teu',
  WM: 'wm',
}

const FCL_SERVICE_CODES = [
  'FREI_OCEAN_20',
  'FREI_OCEAN_20_HC',
  'FREI_OCEAN_40',
  'FREI_OCEAN_40_HC',
  'FREI_OCEAN_45_HC',
  'FREI_OCEAN_20_OT',
  'FREI_OCEAN_40_OT',
  'FREI_OCEAN_40_HC_OT',
  'FREI_OCEAN_45_OT',
  'FREI_OCEAN_20_REEF',
  'FREI_OCEAN_40_REEF',
  'FREI_OCEAN_40_HC_REEF',
  'FREI_OCEAN_45_HC_REEF',
  'FREI_OCEAN_20_FR',
  'FREI_OCEAN_40_FR',
  'FREI_OCEAN_CBM',
  'FREI_OCEAN_TON',
  OCEAN_FREIGHT_PER_WEIGHT_MEASURE_CODE,
]

export const getRateByType = (
  rate: IRate,
  ratesListType: RatesListTypeEnum,
  userContainerType: IContainerType[]
) => {
  if (ratesListType === RatesListTypeEnum.air) {
    return mapToAirRate(rate)
  }
  if (ratesListType === RatesListTypeEnum.lcl) {
    return mapToLclRate(rate, userContainerType)
  }
  return mapToFclRate(rate, userContainerType)
}

export const ratesDataToViewData = (
  ratesListType: RatesListTypeEnum,
  data: IRate[],
  userContainerType: IContainerType[]
): IRate[] => {
  const filteredRates =
    ratesListType === RatesListTypeEnum.air
      ? data
      : data.filter(filterOutAirportsFromRates)

  const ratesByType = filteredRates.map((rate) => {
    return getRateByType(rate, ratesListType, userContainerType)
  })

  return ratesByType.slice().sort(sortRates)
}

const groupPricesByLocalAdditionalCharges = (prices: IRatesPriceItem[]) =>
  prices.reduce(
    (res, price) => {
      if (!price.service_item.tiered_pricing) {
        if (price.service_item.additional) {
          return { ...res, additional: [...res.additional, price] }
        }
        if (!price.service_item.additional) {
          return { ...res, local: [...res.local, price] }
        }
      } else {
        if (price.service_item.code.includes('DEMURRAGE')) {
          return { ...res, demurrage: [...res.demurrage, price] }
        }
        if (price.service_item.code.includes('DETENTION')) {
          return { ...res, detention: [...res.detention, price] }
        }
      }
      return res
    },
    { local: [], additional: [], demurrage: [], detention: [] } as any
  )

const groupByOriginDestination = (prices: IRatesPriceItem[]) => {
  const sortByPriority = (a: IRatesPriceItem, b: IRatesPriceItem) =>
    a.service_item.priority - b.service_item.priority

  const grpupedByDirection = prices.reduce(
    (res, price) => {
      if (ORIGIN_SERVICE_CODES.includes(price.service_item.service_code)) {
        return {
          ...res,
          origin: [...res.origin, price],
        }
      }
      if (DESTINATION_SERVICE_CODES.includes(price.service_item.service_code)) {
        return {
          ...res,
          destination: [...res.destination, price],
        }
      }
      return res
    },
    {
      origin: [],
      destination: [],
    } as any
  )

  return {
    origin: groupPricesByLocalAdditionalCharges(
      grpupedByDirection.origin.slice().sort(sortByPriority)
    ),
    destination: groupPricesByLocalAdditionalCharges(
      grpupedByDirection.destination.slice().sort(sortByPriority)
    ),
  }
}

const getContainers = (prices: IRatesPriceItem[]) => {
  const containers = {}

  prices.forEach((price) => {
    const containerType = price.container_type.code

    if (
      !containers[containerType] &&
      FCL_SERVICE_CODES.includes(price.service_item.code)
    ) {
      containers[containerType] = price
    }
  })
  return containers
}

const getContainersWithSurcharges = (
  prices: IRatesPriceItem[],
  user_container_types: IContainerType[]
) => {
  const containers = prices.filter((price) =>
    FCL_SERVICE_CODES.includes(price.service_item.code)
  )

  const hasOceanFreightPrices = !!containers.length

  if (hasOceanFreightPrices) {
    const surcharges = prices.filter(
      (price) =>
        !price.service_item.additional &&
        price.service_item.service_code === 'freight' &&
        !price.container_type &&
        price.calculation_method !== 'shipment'
    )

    if (surcharges.length) {
      const containersWithUpdatedPrices = addSurchargesToContainerPrices(
        containers,
        surcharges,
        user_container_types
      )

      return {
        hasOceanFreightPrices: true,
        containers: getContainers(containersWithUpdatedPrices),
      }
    }

    return {
      hasOceanFreightPrices: true,
      containers: getContainers(containers),
    }
  }

  return {
    hasOceanFreightPrices: false,
    containers: {} as IRatesPriceItem,
  }
}

// IF carrier is null THEN the surcharges apply to ocean freight of all carriers.
// IF carrier is not null THEN surcharges apply to ocean freight prices of only that specific carrier.
export const addSurchargesToContainerPrices = (
  containers: IRatesPriceItem[],
  surcharges: IRatesPriceItem[],
  user_container_types: IContainerType[]
): IRatesPriceItem[] => {
  if (!surcharges.length) return containers
  return containers.map((container) => {
    const newContainerValue = surcharges.reduce((containerValue, surcharge) => {
      if (
        surcharge.calculation_method === calculationMethods.CONTAINER ||
        surcharge.calculation_method === calculationMethods.WM
      ) {
        return containerValue + parseFloat(surcharge.value)
      }
      if (surcharge.calculation_method === calculationMethods.TEU) {
        const containerType = user_container_types.find(
          (i) => i.code === container.container_type.code
        )

        if (containerType) {
          return (
            containerValue + containerType?.teu * parseFloat(surcharge.value)
          )
        }

        return containerValue + parseFloat(surcharge.value)
      }

      return containerValue
    }, parseFloat(container.value))
    return { ...container, value: newContainerValue.toFixed(2) }
  })
}

export const filterOutAirportsFromRates = (rate: IRate) =>
  rate.discharge_port.type !== 'airport' && rate.loading_port.type !== 'airport'

const sortRates = (a: IRate, b: IRate) =>
  a.loading_port.name.localeCompare(b.loading_port.name) ||
  a.discharge_port.name.localeCompare(b.discharge_port.name) ||
  new Date(a.valid_from).getTime() - new Date(b.valid_from).getTime()

export const mapToLclRate = (
  rate: IRate,
  user_container_types: IContainerType[]
): ILclRate => {
  const oceanFreightPerWeightMeasure = find(rate.prices, [
    'service_item.code',
    OCEAN_FREIGHT_PER_WEIGHT_MEASURE_CODE,
  ])

  const surcharges = rate.prices.filter(
    (price) =>
      !price.service_item.additional &&
      price.service_item.service_code === 'freight' &&
      price.service_item.code !== OCEAN_FREIGHT_PER_WEIGHT_MEASURE_CODE &&
      price.calculation_method !== 'shipment'
  )

  return {
    ...rate,
    ...groupByOriginDestination(rate.prices),
    hasOceanFreightPrices: !!oceanFreightPerWeightMeasure?.value,
    load_type: RatesListTypeEnum.lcl,
    list_type: RatesListTypeEnum.lcl,
    value: oceanFreightPerWeightMeasure
      ? addSurchargesToContainerPrices(
          [oceanFreightPerWeightMeasure],
          surcharges,
          user_container_types
        )[0].value
      : '',
    currency: oceanFreightPerWeightMeasure?.currency || 'EUR',
  }
}

export const mapToFclRate = (
  rate: IRate,
  user_container_types: IContainerType[]
): IFclRate => {
  const { hasOceanFreightPrices, containers } = getContainersWithSurcharges(
    rate.prices,
    user_container_types
  )

  return {
    ...rate,
    ...groupByOriginDestination(rate.prices),
    load_type: RatesListTypeEnum.fcl,
    list_type: RatesListTypeEnum.fcl,
    currency: containers[0]?.currency,
    hasOceanFreightPrices,
    containers,
  }
}

export const getPriceRange = (list: IRatesPriceItem[]) => {
  const minPrice =
    minBy(list, (price) => {
      return parseFloat(price.value)
    })?.value ?? null

  const maxPrice =
    maxBy(list, (price) => {
      return parseFloat(price.value)
    })?.value ?? null

  return getFormattedRange(minPrice, maxPrice)
}

export const getLocalChargesForAirRate = (prices: IRatesPriceItem[]) => {
  const airServiceItems = filter(
    prices,
    (price: IRatesPriceItem) =>
      price.service_item.code !== AIR_SERVICE_ITEM_CODE
  )
  const orderedByCalculatedMethods = orderBy(
    airServiceItems,
    'calculation_method',
    'desc'
  )
  const sortedByName = sortBy(orderedByCalculatedMethods, 'service_item.name')
  const grouped = groupBy(sortedByName, (price) => {
    return `${price.service_item.code}-${price.calculation_method}`
  })
  return map(values(grouped), (items) => {
    return {
      ...items[0],
      value: getPriceRange(items),
    }
  })
}

export const getRatesPerChargeableWeight = (prices: IRatesPriceItem[]) => {
  return prices.filter(
    (price) => price.service_item.code === AIR_SERVICE_ITEM_CODE
  )
}

export const mapToAirRate = (rate: IRate): IAirRate => {
  const localCharges = getLocalChargesForAirRate(rate.prices)

  const ratesPerChargeableWeight = getRatesPerChargeableWeight(rate.prices)

  const range = getPriceRange(ratesPerChargeableWeight)

  return {
    ...rate,
    value: range,
    local_charges: localCharges,
    load_type: RatesListTypeEnum.lcl,
    list_type: RatesListTypeEnum.air,
    currency: ratesPerChargeableWeight[0]?.currency,
    rates_per_chargeable_weight: ratesPerChargeableWeight,
  }
}

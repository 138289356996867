import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded'
import DirectionsBoatRoundedIcon from '@mui/icons-material/DirectionsBoatRounded'
import FlightRoundedIcon from '@mui/icons-material/FlightRounded'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import { LoadTypeEnum, ModalityEnum } from 'src/config/constants'

export const autocompleteIcon = (
  type: IPlaceSuggestion['result_type'],
  modality: ModalityEnum
) => {
  switch (type) {
    case 'port':
      return modality === ModalityEnum.Air ? (
        <FlightRoundedIcon />
      ) : (
        <DirectionsBoatRoundedIcon />
      )
    case 'address':
      return <ApartmentRoundedIcon />
    default:
      return <LocationOnRoundedIcon />
  }
}

export const tags = [
  {
    label: 'search_and_book.dangerous-goods',
    value: 'dangerous_goods',
    load_type: [LoadTypeEnum.lcl, LoadTypeEnum.fcl],
  },
]

const freightService = (modality: ModalityEnum) => {
  switch (modality) {
    case ModalityEnum.Sea:
      return {
        label: 'common.service_item.sea_freight.title',
        value: 'freight',
        tooltip: 'common.service_item.sea_freight.description',
        disabled: false,
      }
    case ModalityEnum.Air:
      return {
        label: 'common.service_item.air_freight.title',
        value: 'freight',
        tooltip: 'common.service_item.air_freight.description',
        disabled: false,
      }
    default:
      return {
        label: 'common.service_item.rail_freight.title',
        value: 'freight',
        tooltip: 'common.service_item.rail_freight.description',
        disabled: false,
      }
  }
}

export const services = (modality: ModalityEnum) => [
  {
    label: 'common.service_item.pickup.title',
    value: 'pickup',
    tooltip: 'common.service_item.pickup.description',
    disabled: false,
  },
  {
    label: 'common.service_item.export_custom.title',
    value: 'export_customs',
    tooltip: 'common.service_item.export_custom.description',
    disabled: false,
  },
  {
    label: 'common.service_item.origin_port.title',
    value: 'origin_port',
    tooltip: 'common.service_item.origin_port.description',
    disabled: false,
  },
  freightService(modality),
  {
    label: 'common.service_item.destination_port.title',
    value: 'destination_port',
    tooltip: 'common.service_item.destination_port.description',
    disabled: false,
  },
  {
    label: 'common.service_item.import_customs.title',
    value: 'import_customs',
    tooltip: 'common.service_item.import_customs.description',
    disabled: false,
  },
  {
    label: 'common.service_item.delivery.title',
    value: 'delivery',
    tooltip: 'common.service_item.delivery.description', // if we know the end destination we might show it here
    disabled: false,
  },
  {
    label: 'common.service_item.insurance.title',
    value: 'insurance',
    tooltip: 'common.service_item.insurance.description',
    disabled: false,
  },
]

export const freightServiceCode = 'freight'

export const organizationRolesForIncotermServiceBinding = [
  'customer',
  'forwarder',
  'shypple_admin',
]

export const incotermServiceRoles = {
  shipper: ['shipper', 'origin forwarder'],
  consignee: ['consignee', 'destination forwarder'],
}

export const defaultServices = {
  pickup: false,
  export_customs: false,
  origin_port: false,
  freight: false,
  destination_port: false,
  import_customs: false,
  delivery: false,
  insurance: false,
}

export const bookFromTemplatePromptKey = 'do-not-prompt-book-from-template'

export const temperatureOptions = [
  {
    label: 'common.temperature_option.dry',
    id: 'dry',
  },
  {
    label: 'common.temperature_option.cold',
    id: 'cold',
  },
  {
    label: 'common.temperature_option.warm',
    id: 'warm',
  },
]

import { useHistory, useParams } from 'react-router-dom'
import get from 'lodash/get'
import { useGetOrganizations } from 'src/services/Api/organizations'
import { useSelector } from 'react-redux'
import { useCreateNewShipmentFromTemplate } from 'src/services/Api/shipmentTemplates'
import ShipmentTemplateAcceptTerm from 'src/pages/ShipmentTemplateEdit/components/ShipmentTemplateAcceptTerm'
import { getFormPayload } from '../../Payload.utils'
import { useFormContext } from '../../FormContext'
import { TemplateFormContextProps } from '../../Form.props'

const CreateBookingRequest = () => {
  const history = useHistory()
  const {
    formState,
    shipmentRoles,
  } = useFormContext() as TemplateFormContextProps
  const params = useParams<{ id: string }>()
  const {
    mutateAsync: createShipment,
    isLoading,
  } = useCreateNewShipmentFromTemplate()

  const { data: organizations } = useGetOrganizations({
    refetchOnMount: false,
  })

  const { user } = useSelector((state: IGlobalState) => ({
    user: state.user,
  }))

  const sellerOrganization = get(formState, 'sellerOrganization')

  const preferredForwarder =
    organizations?.find(
      (organization) => organization.id === user.preferredFreightForwarderId
    ) ?? null

  const handleFinish = () => {
    const payload = getFormPayload({
      id: params?.id ?? '',
      formState,
      shipmentRoles,
      userOrganizationId: user.organizationId,
    })
    createShipment(payload).then((res) => {
      const { id } = res
      history.push(`/shipments/${id}`)
    })
  }

  return (
    <ShipmentTemplateAcceptTerm
      onSave={handleFinish}
      preferredForwarder={preferredForwarder}
      seller={sellerOrganization}
      loading={isLoading}
    />
  )
}

export default CreateBookingRequest

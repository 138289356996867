import { FunctionComponent, useState } from 'react'
import { includes, pickBy, keys, find } from 'lodash'
import { useTranslation } from 'react-i18next'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import Popper from '@mui/material/Popper'
import CustomInput from '../../Common/Input/MuiInput'
import QuantityCounter from '../../../stories/QuantityCounter'
import { reeferIcons } from '../../../pages/SearchAndBook/reeferIcons'
import './styles.scss'

const reeferTypes = ['20_rf', '40_rf', '40_hc_rf']

const containerTypes = [
  {
    name: "20' general",
    code: '20_ft',
    icon: 'standard-container-20ft',
  },
  {
    name: "40' general",
    code: '40_ft',
    icon: 'standard-container-40ft',
  },
  {
    name: "40' HC",
    code: '40_ft_hc',
    icon: 'HC-container-40ft',
  },
  {
    name: "45' HC",
    code: '45_ft',
    icon: 'HC-container-45ft',
  },
  {
    name: "40' HC open top",
    code: '40_hc_ot',
    icon: 'HC-open-top-container-40ft',
  },
  {
    name: "20' reefer",
    code: '20_rf',
    icon: reeferIcons['20_rf'],
  },
  {
    name: "40' HC reefer",
    code: '40_hc_rf',
    icon: reeferIcons['40_hc_rf'],
  },
  {
    name: "20' open top",
    code: '20_ot',
    icon: 'open-top-container-20ft',
  },
  {
    name: "40' open top",
    code: '40_ot',
    icon: 'open-top-container-40ft',
  },
]

interface ISABContainersSelectProps {
  onChange: (field: string, value: any) => void
  containers: Record<string, number>
  dataTestId: string
}

const SABContainersSelect: FunctionComponent<ISABContainersSelectProps> = (
  props
) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const isActive = Boolean(anchorEl)

  const onClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const onCancel = (): void => {
    setAnchorEl(null)
  }

  const onChange = (value, name): void => {
    props.onChange('containers', {
      ...props.containers,
      [name]: value,
    })
  }

  const containerBlock = (containerData: any): React.ReactElement => {
    return (
      <div className="select-block">
        <div className="select-block--icon-name">
          {includes(reeferTypes, containerData.code) ? (
            containerData.icon
          ) : (
            <i className={`icon ${containerData.icon}`} />
          )}
          {containerData.name}
        </div>

        <QuantityCounter
          totalError={''}
          value={props.containers[containerData.code] || 0}
          onChange={onChange}
          min="0"
          name={containerData.code}
        />
      </div>
    )
  }

  const inputValue = (): string => {
    const selectedContainers: any = pickBy(props.containers)
    const containersCount: string[] = keys(selectedContainers).map(
      (container) => {
        const containerType: any = find(containerTypes, { code: container })
        return containerType
          ? ` ${selectedContainers[container]} x ${containerType.name}`
          : ''
      }
    )
    return containersCount.toString()
  }

  const { t } = useTranslation()

  return (
    <div className="containers-select">
      <CustomInput
        onClick={onClick}
        label={t('load_requirements_component.label', 'Load requirements')}
        placeholder={t(
          'load_requirements_component.placeholder',
          'Select container types and quantities'
        )}
        value={inputValue()}
        readOnly={true}
        endAdornment={isActive ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
        data-testid={props.dataTestId}
      />
      <Popper
        open={isActive}
        anchorEl={anchorEl}
        placement="bottom"
        className="containers-select--popper"
        data-testid="containers-select--popper"
      >
        <ClickAwayListener onClickAway={onCancel}>
          <div>
            <div className="containers-select--wrapper">
              <div className="containers-select--column">
                {containerTypes.slice(0, 5).map((container) => (
                  <div key={container.name}>{containerBlock(container)}</div>
                ))}
              </div>
              <div className="containers-select--column">
                {containerTypes.slice(5, 10).map((container) => (
                  <div key={container.name}>{containerBlock(container)}</div>
                ))}
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

export default SABContainersSelect

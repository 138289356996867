import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import { ShipmentTitleProps } from '../shipmentContentProps'
import { ShipmentModalityIcon } from './ShipmentModalityIcon'

export const ShipmentTitle: React.FC<ShipmentTitleProps> = (props) => {
  const { modality } = props
  return (
    <Box display="flex" alignItems="center">
      {modality && <ShipmentModalityIcon modality={modality} />}
      <Link
        variant="body1Strong"
        display="flex"
        color="primary"
        target="_blank"
        href={`/shipments/${props.id}`}
        sx={{ justifyContent: props.align, ml: modality ? 1.5 : 0 }}
      >
        {props.title}
      </Link>
    </Box>
  )
}

import { FunctionComponent, useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { promisifyAction } from 'src/utils'
import { Button } from '@mui/material'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import ServiceLevelAgreementsRowHeader from 'src/components/OrganizationSettings/SLA/ServiceLevelAgreementsRowHeader'
import ServiceLevelAgreementsModalWindow from 'src/components/OrganizationSettings/SLA/ServiceLevelAgreementsModalWindow'
import { dummySLA } from '../../../stores/reducers/organizationSettings'
import {
  addressesGetOneAddress,
  addressesGetData,
} from '../../../stores/actionCreators'

interface IProps {
  slaCheckpoints: ISLA[]
  addressId: number
}

const AddressSLA: FunctionComponent<IProps> = ({
  slaCheckpoints,
  addressId,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [forModalOpen, setForModalOpen] = useState<boolean>(false)

  const getAddressAsync = promisifyAction(dispatch, addressesGetOneAddress)
  const addressesGetDataAsync = promisifyAction(dispatch, addressesGetData)

  useEffect(() => {
    const fetchSLAAsync = async () => {
      await addressesGetDataAsync()
    }

    fetchSLAAsync()
  }, [])
  const fetchData = useCallback(async () => {
    await getAddressAsync(addressId)
  }, [])

  const renderEmptyState = useCallback(() => {
    return (
      <div className="empty-state-for-sla">
        <Typography
          variant="body1"
          children={t(
            'address_book.add_address.empty_state',
            'No service level agreements for this organization'
          )}
        />
      </div>
    )
  }, [])

  const renderSLARows = useCallback(() => {
    return slaCheckpoints.map((item: ISLA, index: number) => (
      <TableRow>
        <ServiceLevelAgreementsRowHeader
          item={item}
          opened={false}
          iconed={false}
          slaLink={true}
          expandable={false}
          key={item.id}
        />
      </TableRow>
    ))
  }, [slaCheckpoints])

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="short"></TableCell>
            <TableCell className="left-aligned x-long">
              {t('address_book.add_address.type', 'Type')}
            </TableCell>
            <TableCell className="left-aligned x-long">
              {t('address_book.add_address.name', 'Name')}
            </TableCell>
            <TableCell className="left-aligned x-long">
              {t('address_book.add_address.timing', 'Timing')}
            </TableCell>
            <TableCell className="left-aligned medium">
              {t('address_book.add_address.owner', 'Owner')}
            </TableCell>
            <TableCell className="left-aligned medium">
              {t('address_book.add_address.created_at', 'Created at')}
            </TableCell>
            <TableCell className="short"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{slaCheckpoints.length ? renderSLARows() : null}</TableBody>
      </Table>
      {!slaCheckpoints.length && renderEmptyState()}
      <div className=" mt-20">
        <Button
          variant="text"
          onClick={() => {
            setForModalOpen(true)
          }}
        >
          {t('address_book.add_address.add_sla', 'Add SLA')}
        </Button>
      </div>
      <ServiceLevelAgreementsModalWindow
        open={forModalOpen}
        close={() => {
          setForModalOpen(false)
        }}
        fetchData={fetchData}
        slaCheckpoint={dummySLA}
      />
    </>
  )
}
export default AddressSLA

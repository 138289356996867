import { FunctionComponent } from 'react'
import { Typography, ButtonBase } from '@mui/material'
import { Skeleton } from 'src/stories'

const ShipmentsSkeleton: FunctionComponent = () => {
  return (
    <div data-testid="shipments-skeleton">
      {Array.from({ length: 4 }).map((e, i) => (
        <ButtonBase
          key={i}
          sx={{
            width: '98%',
            margin: '10px 10px',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'grey.100',
            padding: '10px',
          }}
        >
          <div className="shipment__block">
            <div className="shipment__main">
              <div className="shipment__reference">
                <Skeleton animation="wave" width="40px" height="20px" />
              </div>

              <div className="shipment__status">
                <div className="shipment__line">
                  <Skeleton animation="wave" width="100px" height="20px" />
                </div>
              </div>

              <Skeleton animation="wave" width="150px" height="80px" />

              <div className="shipment__line shipment__line_amount">
                <Skeleton animation="wave" width="150px" height="80px" />
              </div>
            </div>

            <div className="shipment__ports">
              <Skeleton animation="wave" width="750px" height="80px" />
            </div>

            <div className="shipment__sub-main">
              <header className="shipment__sub-title">
                <Skeleton animation="wave" width="100px" height="20px" />
              </header>
              <div className="shipment__line shipment__line_sub-amount">
                <span className="shipment__logo">
                  <Typography className="small light">
                    <>
                      <Skeleton animation="wave" width="100px" height="20px" />
                      <Skeleton animation="wave" width="100px" height="20px" />
                    </>
                  </Typography>
                </span>
                <Skeleton animation="wave" width="45px" height="20px" />
              </div>
            </div>
            <div className="shipment__collaborators">
              <Skeleton
                animation="wave"
                variant="circular"
                width="30px"
                height="30px"
              />
            </div>
          </div>
        </ButtonBase>
      ))}
    </div>
  )
}

export default ShipmentsSkeleton

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConfirmDialog from 'src/components/ConfirmDialog'
import { useDispatch } from 'react-redux'
import { routes } from 'src/pages/AddressBook'
import { history } from 'src/shyppleStore'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { Button } from '@mui/material'
import { promisifyAction } from '../../../utils'
import { addressesDeleteAddress } from '../../../stores/actionCreators'

const DeleteAddressButton = ({ address }: { address: IAddress }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const deleteAddress = promisifyAction(dispatch, addressesDeleteAddress)

  const removeAddress = async () => {
    setVisible(false)
    await deleteAddress(address.id)
    history.push(routes.companies)
    dispatch(
      showNotification({
        message: `Address "${address.name}" deleted.`,
        severity: 'success',
      })
    )
  }

  return (
    <>
      <Button
        variant="text"
        color="primary"
        component="button"
        disabled={address.default}
        onClick={(e) => {
          e.preventDefault()
          setVisible(true)
        }}
      >
        {t('address_book.connections.remove_address', 'Remove address')}
      </Button>

      <ConfirmDialog
        title="Delete address"
        message={`Are you sure you want to delete address "${address.name}?"`}
        isOpen={visible}
        confirm={removeAddress}
        reject={() => setVisible(false)}
        onClose={() => setVisible(false)}
      />
    </>
  )
}

export default DeleteAddressButton

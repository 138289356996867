import { useCallback, SyntheticEvent } from 'react'
import { Box } from '@mui/system'
import { Link, Typography } from '@mui/material'

const InlandTransportRowEditableCellText: React.FC<{
  text: string | null | undefined
  linkText?: string
  onClick: (event: SyntheticEvent) => void
}> = ({ text, onClick, linkText = 'Add' }) => {
  const handleClick = useCallback(onClick, [text])
  return (
    <Box
      height="100%"
      flexGrow={1}
      display="flex"
      alignItems="center"
      onClick={handleClick}
      data-testid="inland-transport-editable-text"
    >
      {text && <Typography width={200} children={text} noWrap />}
      {!text && (
        <Link
          data-testid="inland-transport-editable-link-button"
          onClick={(e: { preventDefault: () => void }) => {
            e.preventDefault()
          }}
        >
          {linkText}
        </Link>
      )}
    </Box>
  )
}

export default InlandTransportRowEditableCellText

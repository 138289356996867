import get from 'lodash/get'
import { useState } from 'react'
import VerticalLinearStepper from 'src/stories/Stepper'
import { useTranslation } from 'react-i18next'
import { useFormContext } from '../../FormContext'
import { useGetStepsAdditionalInfo } from '../../Form.hooks'
import { initialFormErrors } from '../../Form.constants'
import TemplateFormInitialDetailsView from '../Steps/InitialDetailsView'
import TemplateFormCreateBookingRequest from '../Steps/CreateBookingRequest'
import { TemplateFormContextProps } from '../../Form.props'

const dimensionFields = ['cargo.length', 'cargo.width', 'cargo.height']

const TemplatesFormRebook = ({ steps }) => {
  const { t } = useTranslation() // Move inside the functional component
  const [step, setStep] = useState(0)
  const {
    formState,
    onFormErrorChange,
    formErrors,
    selectedContainerType,
  } = useFormContext() as TemplateFormContextProps
  const additionalInfo = useGetStepsAdditionalInfo()

  const getRequiredFields = () => {
    const requiredFields = steps[step]?.requiredFields ?? []
    if (get(formState, 'cargo.totalVolumeCbm', 0) > 0) {
      return requiredFields.filter(
        (field: string) => !dimensionFields.includes(field)
      )
    }
    return requiredFields
  }

  const requiredFields = getRequiredFields()

  const nextButtonEnabled =
    requiredFields.every((field) => {
      return !!get(formState, field) || get(formState, field) === false
    }) && Object.values(formErrors).every((error) => !error)

  const handleGetActiveStep = (step: number) => {
    onFormErrorChange(initialFormErrors)
    setStep(step)
  }

  const forwardBtnLabel =
    step === 1 && selectedContainerType?.equipment_controlled_atmosphere
      ? 'Confirm'
      : 'Next'

  const finalStep = {
    key: 'bookingRequest',
    label: t('templates.booking_request.heading', 'Create booking request'),
    component: () => <TemplateFormCreateBookingRequest />,
    requiredFields: [],
  }

  return (
    <div data-testid="rebook-template-form">
      <TemplateFormInitialDetailsView />
      <VerticalLinearStepper
        stepProps={{}}
        loading={false}
        activeStep={step}
        endButtonText="Save"
        showFinalBlock={false}
        forwardBtnLabel={forwardBtnLabel}
        hideFinalButton={step === steps.length}
        steps={[...steps, finalStep]}
        additionalInfo={additionalInfo}
        isForwardBtnDisabled={!nextButtonEnabled}
        handleGetActiveStep={handleGetActiveStep}
      />
    </div>
  )
}

export default TemplatesFormRebook

import { useEffect, useState, useRef, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, withStyles } from '@mui/styles'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Fuse from 'fuse.js'
import Paper from '@mui/material/Paper'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import {
  addressesGetData,
  personalDetailsGetData,
  getAllConnections,
} from 'src/stores/actionCreators'
import LoadableContainer from 'src/components/LoadableContainer'
import { LinearProgress, Pagination } from '../../stories'
import ContactsTable from './ContactsTable'
import AddressesTable from './AddressesTable'
import ConnectionsTable from './ConnectionsTable'
import AddressBookControls from './AddressBookControls'
import AddressBookEmptySearchResults from './AddressBookEmptySearchResults'
import AddressBookEmptyState from './AddressBookEmptyState'
import { routes } from './index'

const RESULTS_PER_PAGE = 20

const SEARCH_ENGINE_OPTIONS = {
  keys: ['name', 'address', 'email', 'phone', 'company_name', 'tags'],
  threshold: 0.4,
}

const basePath: RegExp = /^\/contacts\/?$/

const CustomTabs = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
}))(Tabs)

const CustomTab = withStyles((theme) => ({
  root: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.body1.fontSize,
  },
  selected: {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
}))(Tab)

const useStyles = makeStyles(() => ({
  tabsBlock: {
    margin: '16px 2px 2px 2px', // shadows
    minHeight: 500,
  },
  addressBookTable: {
    maxHeight: 'none',
  },
}))

interface TabProps {
  searchQuery: string
}

const ConnectionsTab = memo(({ searchQuery }: TabProps) => {
  const fuse = useRef<Fuse<IShipmentConnection> | null>(null)
  const dispatch = useDispatch()
  const classes = useStyles()

  const { connections, isFetchingConnections, total } = useSelector(
    (state: IGlobalState) => ({
      connections: state.connections.list,
      total: state.connections.total,
      isFetchingConnections: state.connections.isFetchingConnections,
    }),
    shallowEqual
  )

  const [page, setPage] = useState<number>(1)

  useEffect(() => {
    dispatch(getAllConnections({ page, per_page: RESULTS_PER_PAGE }))
  }, [])

  useEffect(() => {
    if (connections.length) {
      fuse.current = new Fuse(connections, SEARCH_ENGINE_OPTIONS)
    }
  }, [connections])

  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page)
    dispatch(getAllConnections({ page, per_page: RESULTS_PER_PAGE }))
  }

  const filteredConnections =
    searchQuery && fuse.current
      ? fuse.current.search(searchQuery).map(({ item }) => item)
      : connections

  const emptyState = (
    <>
      {searchQuery ? (
        <AddressBookEmptySearchResults />
      ) : (
        <AddressBookEmptyState type="connections" />
      )}
    </>
  )

  return (
    <>
      {!isFetchingConnections && !filteredConnections.length && emptyState}
      <LoadableContainer
        loading={isFetchingConnections}
        placeholder={<LinearProgress />}
      >
        {filteredConnections.length > 0 && (
          <>
            <ConnectionsTable
              connections={filteredConnections}
              className={classes.addressBookTable}
            />
            <Pagination
              page={page}
              color="primary"
              defaultPage={page}
              totalItems={total}
              sx={{ px: 1, pb: 1 }}
              onChange={onPageChange}
              itemsPerPage={RESULTS_PER_PAGE}
            />
          </>
        )}
      </LoadableContainer>
    </>
  )
})

const AddressesTab = memo(({ searchQuery }: TabProps) => {
  const fuse = useRef<Fuse<IAddress> | null>(null)
  const dispatch = useDispatch()
  const classes = useStyles()

  const { addresses, isFetching, page, page_size, total_pages } = useSelector(
    (state: IGlobalState) => ({
      addresses: state.addresses.list,
      isFetching: state.addresses.isFetching,
      page: state.addresses.page,
      page_size: state.addresses.page_size,
      total_pages: state.addresses.total_pages,
    }),
    shallowEqual
  )

  const getDataForPage = (page: number) => {
    dispatch(
      addressesGetData({
        page,
        search: searchQuery,
        page_size: RESULTS_PER_PAGE,
      })
    )
  }

  useEffect(() => {
    getDataForPage(1)
  }, [searchQuery])

  useEffect(() => {
    if (addresses.length > 0) {
      fuse.current = new Fuse(addresses, SEARCH_ENGINE_OPTIONS)
    }
  }, [addresses])

  const onPageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    getDataForPage(newPage)
  }

  const emptyState = (
    <>
      {searchQuery ? (
        <AddressBookEmptySearchResults />
      ) : (
        <AddressBookEmptyState type="addresses" />
      )}
    </>
  )

  return (
    <>
      {!isFetching && !addresses.length && emptyState}
      <LoadableContainer loading={isFetching} placeholder={<LinearProgress />}>
        {addresses.length > 0 && (
          <>
            <AddressesTable
              addresses={addresses}
              className={classes.addressBookTable}
            />
            <Pagination
              page={+page}
              color="primary"
              defaultPage={+page}
              sx={{ px: 1, py: 2 }}
              onChange={onPageChange}
              itemsPerPage={page_size}
              totalItems={total_pages * RESULTS_PER_PAGE}
            />
          </>
        )}
      </LoadableContainer>
    </>
  )
})

const ContactsTab = memo(({ searchQuery }: TabProps) => {
  const fuse = useRef<Fuse<IPersonalDetail> | null>(null)
  const dispatch = useDispatch()
  const classes = useStyles()

  const { addresses, isFetching } = useSelector(
    (state: IGlobalState) => ({
      addresses: state.personalDetails.list,
      isFetching: state.personalDetails.isFetching,
    }),
    shallowEqual
  )

  useEffect(() => {
    dispatch(personalDetailsGetData())
  }, [])

  useEffect(() => {
    if (addresses.length > 0) {
      fuse.current = new Fuse(addresses, SEARCH_ENGINE_OPTIONS)
    }
  }, [addresses])

  const filteredAddresses =
    searchQuery && fuse.current
      ? fuse.current.search(searchQuery).map(({ item }) => item)
      : addresses

  const emptyState = (
    <>
      {searchQuery ? (
        <AddressBookEmptySearchResults />
      ) : (
        <AddressBookEmptyState type="contacts" />
      )}
    </>
  )

  return (
    <>
      {!isFetching && !filteredAddresses.length && emptyState}
      <LoadableContainer loading={isFetching} placeholder={<LinearProgress />}>
        {filteredAddresses.length > 0 && (
          <ContactsTable
            addresses={filteredAddresses}
            className={classes.addressBookTable}
          />
        )}
      </LoadableContainer>
    </>
  )
})

const AddressBookTabs = memo(
  ({ searchQuery, pathname }: { searchQuery: string; pathname: string }) => {
    const classes = useStyles()
    const history = useHistory()

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
      history.push(newValue)
    }

    const { t } = useTranslation()

    return (
      <Paper elevation={0} className={classes.tabsBlock}>
        <AppBar elevation={0} position="static" color="transparent">
          <CustomTabs
            value={pathname}
            onChange={handleChange}
            indicatorColor="primary"
          >
            <CustomTab
              label={t('address_book.connections.tab_header', 'Connections')}
              value={routes.connections}
            />
            <CustomTab
              label={t('address_book.addresses.tab_header', 'Addresses')}
              value={routes.companies}
            />
            <CustomTab
              label={t('address_book.contacts.tab_header', 'Contacts')}
              value={routes.persons}
            />
          </CustomTabs>
        </AppBar>
        {pathname === routes.connections && (
          <ConnectionsTab searchQuery={searchQuery} />
        )}
        {pathname === routes.companies && (
          <AddressesTab searchQuery={searchQuery} />
        )}
        {pathname === routes.persons && (
          <ContactsTab searchQuery={searchQuery} />
        )}
      </Paper>
    )
  }
)

const AddressBook = ({ pathname }: { pathname: string }) => {
  const history = useHistory()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [currentPath, setCurrentPath] = useState<string>(routes.connections)

  useEffect(() => {
    setCurrentPath(basePath.test(pathname) ? routes.connections : pathname)
  }, [pathname])

  useEffect(() => {
    if (basePath.test(pathname)) history.push(routes.connections)
  }, [])

  return (
    <>
      <AddressBookControls pathname={currentPath} onChange={setSearchQuery} />
      <AddressBookTabs searchQuery={searchQuery} pathname={currentPath} />
    </>
  )
}

export default AddressBook

import Typography from '@mui/material/Typography'
import { StyledMenuItemIcon } from 'src/components/TopNavigation/TopNavigation.styles'

interface TopNavigationUserMenuItemContentProps {
  icon?: React.ReactElement
  text: string
}

const TopNavigationMenuItemContent: React.FC<TopNavigationUserMenuItemContentProps> = ({
  text,
  icon,
}) => {
  return (
    <>
      <StyledMenuItemIcon>{icon}</StyledMenuItemIcon>
      <Typography variant="body1Medium" children={text} />
    </>
  )
}

export default TopNavigationMenuItemContent

import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { SHIPMENT_THRESHOLDS } from '../../../config/constants'
import { permissionTo } from '../../../utils'
import ShipmentPortPickupAndDeliveryTitle from '../ShipmentPortPickupAndDeliveryTitle'

interface IShipmentPortDeliveryProps {
  deliveryEnabled: boolean
  addressName: string | null
  progressPercent: number
  visibilityOnly: boolean
  showServicesWindow: () => void
}

const ShipmentPortDelivery: FunctionComponent<IShipmentPortDeliveryProps> = (
  props
) => {
  const { t } = useTranslation()
  const completedStyle: string =
    props.progressPercent > SHIPMENT_THRESHOLDS.delivery
      ? 'shipment__port-node_completed'
      : ''

  return (
    <div className="shipment__port">
      <div className="shipment__port-node-line">
        <i
          className={`
            shipment__port-node
            shipment__port-node_end
            shipment__port-node_mark
            ${completedStyle}
          `}
        />
      </div>
      {permissionTo('shipments.containers_|_cargo.delivery.view') && (
        <ShipmentPortPickupAndDeliveryTitle
          serviceName={t('common.services.delivery', 'Delivery')}
          address={props.addressName}
          onClick={props.showServicesWindow}
          serviceRequested={props.deliveryEnabled}
        />
      )}
    </div>
  )
}

export default ShipmentPortDelivery

import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { pickBy } from 'lodash'

import useLocalStorage from 'src/hooks/useLocalStorage'
import { updateReportsDashboardFilters } from 'src/stores/actionCreators'

const initialScopeState = {
  load_type: [],
  cluster: [],
  modality: [],
  pol: [],
  pod: [],
  organization: [],
  rolling_status: [],
  service_department: [],
  order_by: 'newest_first',
}

export default function useSavedFilters() {
  const dispatch: Dispatch = useDispatch()

  const [savedFilters, setSavedFilters] = useLocalStorage<any>(
    'opsDashboardFilters',
    {}
  )

  const { scope, filters } = useSelector((state: IGlobalState) => ({
    scope: state.reportsDashboard.scope,
    filters: state.reportsDashboard.filters,
  }))

  useEffect(() => {
    if (!scope) {
      return
    }
    const filtersForRequest = pickBy(filters, (value) => !!value)

    setSavedFilters({
      ...savedFilters,
      [scope]: {
        ...(savedFilters[scope] || {}),
        ...pickBy(filtersForRequest, (_value, key) =>
          Object.keys(initialScopeState).includes(key)
        ),
      },
    })
  }, [filters])

  useEffect(() => {
    if (!scope) {
      return
    }

    if (savedFilters[scope]) {
      dispatch(updateReportsDashboardFilters(savedFilters[scope]))
    } else {
      setSavedFilters({
        ...savedFilters,
        [scope]: initialScopeState,
      })
      dispatch(updateReportsDashboardFilters(initialScopeState))
    }
  }, [scope])

  return savedFilters
}

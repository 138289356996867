import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/styles'

const StyledTimelineGrid = styled(Grid)(({ theme }) => ({
  position: 'relative',
  '&::before': {
    zIndex: 1,
    top: '12px',
    content: '""',
    height: '4px',
    width: '100%',
    marginTop: '-2px',
    position: 'absolute',
    background: theme.palette.grey[200],
  },
}))

const StyledStepBox = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  padding: `0 ${theme.spacing(1)}`,
  backgroundColor: theme.palette.common.white,
}))

const StyledWrapper = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacing(1)}`,
  backgroundColor: theme.palette.common.white,
}))

const StyledPaper = styled(Paper)(({ theme }) => ({
  '&:hover': {
    cursor: 'pointer',
    borderColor: theme.palette.primary.main,
  },
  '&.disabled:hover': {
    cursor: 'default',
    borderColor: theme.palette.grey[200],
  },
}))

const StyledGridItem = styled(Grid)(() => ({
  zIndex: 2,
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '& svg': {
    transform: 'rotate(0)',
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.short,
    }),
  },
  '&.opened': {
    '& svg': {
      transform: 'rotate(180deg)',
    },
  },
}))

export {
  StyledPaper,
  StyledWrapper,
  StyledStepBox,
  StyledGridItem,
  StyledIconButton,
  StyledTimelineGrid,
}

import { FunctionComponent, useEffect, useMemo } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Paper, Box } from '@mui/material'
import MyShipments from 'src/components/MyShipments'
import { BreadcrumbItem, Breadcrumbs } from 'src/stories/Breadcrumbs'
import { promisifyAction } from 'src/utils'
import { usersGetData } from 'src/stores/actionCreators'

interface IProps {
  match?: IMatch
}

const links: BreadcrumbItem[] = [
  {
    label: 'Organization settings',
    href: '/organization_settings',
  },
  {
    label: 'Users',
    href: '/organization_settings/users',
  },
]

const ShipmentSettings: FunctionComponent<IProps> = (props) => {
  const dispatch = useDispatch()
  const getUsersDataAsync = promisifyAction(dispatch, usersGetData)

  useEffect(() => {
    getUsersDataAsync(props.match?.params.id)
  }, [])

  const { user } = useSelector(
    (state: IGlobalState) => ({
      user: state.users.currentUser,
    }),
    shallowEqual
  )

  const userName = useMemo(() => {
    return `${user.firstName} ${user.lastName}`
  }, [user])

  return (
    <Box>
      <Box mb={2}>
        <Breadcrumbs links={links} current={userName} />
      </Box>
      <Paper data-testid="shipment-settings-page">
        <MyShipments userId={props.match?.params.id} />
      </Paper>
    </Box>
  )
}

export default ShipmentSettings

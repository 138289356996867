import { useDispatch, useSelector } from 'react-redux'
import { xor } from 'lodash'
import { useTranslation } from 'react-i18next'
import { LegendProps } from 'recharts'

import { Box } from '@mui/material'
import MultiSelector from 'src/components/MultiSelector'
import MonthPicker from 'src/components/MonthPicker'
import { useEffect, useState } from 'react'
import { promisifyAction, Logger } from '../../utils'
import { reportsGetShipmentsCosts } from '../../stores/actionCreators'

import './styles.scss'
import { CustomChart } from './CustomChart'

interface IDataMonth {
  name: string
  fcl: number
  lcl: number
  air: number
  total: number
}

interface IMonthsObject {
  from: string
  to: string
}

interface IFiltrationData {
  pols: Array<Array<string | number>>
  pods: Array<Array<string | number>>
  types: string[][]
  statuses: string[][]
  consignees: string[]
  shippers: string[]
  carriers: Array<Array<string | number>>
  services: Array<Array<string | number>>
  currencies: string[][]
}

const now = new Date()

const toOption = (line: string) => [line, line]

const initialState: IReportsState = {
  filters: {
    orderBy: 'etd',
    startDate: `${now.getMonth() + 1}/${now.getFullYear() - 1}`,
    endDate: `${now.getMonth() + 1}/${now.getFullYear()}`,
    pods: [],
    pols: [],
    loadTypes: [],
    status: [],
    consignees: [],
    shippers: [],
    carriers: [],
    services: [],
    currency: 'eur',
  },
  filtrationData: {
    pols: [],
    pods: [],
    types: [],
    statuses: [],
    consignees: [],
    shippers: [],
    carriers: [],
    services: [],
    currencies: [],
  },
  chartDimensions: {
    width: 0,
    height: 0,
  },
  legendDimensions: {
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'top',
  },
  chartRatio: 0.85,
}

export interface IReportsState {
  filters: {
    orderBy: 'etd' | 'eta'
    startDate?: string
    endDate?: string
    pods: string[]
    pols: string[]
    loadTypes: string[]
    status: string[]
    consignees: string[]
    shippers: string[]
    carriers: string[]
    services: string[]
    currency: 'eur' | 'usd'
  }
  filtrationData: IFiltrationData
  chartDimensions: {
    width: number
    height: number
  }
  legendDimensions: LegendProps
  chartRatio: number
  dataset?: IDataMonth[]
}

export const ReportsShipmentsCosts = () => {
  const [reportsState, setReportsState] = useState(initialState)
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const shipmentsCostsData = promisifyAction(dispatch, reportsGetShipmentsCosts)

  const { reports } = useSelector((state: IGlobalState) => ({
    reports: state.reportsShipmentsCosts,
  }))

  let resizeThrottleTimeout: any = undefined

  useEffect(() => {
    throttledSetChartDimensions()
    window.addEventListener('resize', throttledSetChartDimensions)

    search()
    return () => {
      window.removeEventListener('resize', throttledSetChartDimensions)
    }
  }, [])

  const handleDateChange = (datesObject: IMonthsObject) => {
    if (datesObject.from && datesObject.to) {
      search({
        filters: {
          ...reportsState.filters,
          startDate: datesObject.from,
          endDate: datesObject.to,
        },
      })
    }
  }

  const pickFilterOption = async (
    optionId: string,
    fieldName?: string
  ): Promise<any> => {
    if (fieldName && reportsState.filters[fieldName]) {
      await search({
        filters: {
          ...reportsState.filters,
          [fieldName]: xor(reportsState.filters[fieldName], [optionId]),
        },
      })
    }
  }

  const resetFiltersOfType = async (filterType: string): Promise<any> => {
    await search({
      filters: {
        ...reportsState.filters,
        [filterType]: [],
      },
    })
  }

  const search = async (stateChanges: any = {}) => {
    const newState = { ...reportsState, ...stateChanges }
    try {
      await shipmentsCostsData({
        pol: newState.filters.pols,
        pod: newState.filters.pods,
        load_types: newState.filters.loadTypes,
        start_date: newState.filters.startDate,
        end_date: newState.filters.endDate,
        order_by: newState.filters.orderBy,
        status: newState.filters.status,
        carrier: newState.filters.carriers,
        consignee: newState.filters.consignees,
        shipper: newState.filters.shippers,
        services: newState.filters.services,
        currency: newState.filters.currency,
      })

      setReportsState((prevState) => ({
        ...prevState,
        ...stateChanges,
      }))
    } catch (error) {
      Logger.error(error)
    }
  }

  const setChartDimensions = (): void => {
    const chartContainerWidth = document.getElementsByClassName(
      'shipments-count-container'
    )[0].clientWidth
    let chartWidth = Math.floor(chartContainerWidth * reportsState.chartRatio)
    if (chartWidth > 1380) {
      chartWidth = 1380
    }
    const chartHeight = Math.floor(chartWidth * 0.44)

    setReportsState((prevState) => ({
      ...prevState,
      chartDimensions: { height: chartHeight, width: chartWidth },
    }))
  }

  const throttledSetChartDimensions = (): void => {
    if (!resizeThrottleTimeout) {
      resizeThrottleTimeout = setTimeout(() => {
        resizeThrottleTimeout = null
        setChartDimensions()

        // The actualResizeHandler will execute at a rate of 15fps
      }, 300)
    }

    if (window.outerWidth > 1200) {
      setReportsState((prevState) => ({
        ...prevState,
        legendDimensions: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'top',
        },
        chartRatio: 0.85,
      }))
    } else {
      setReportsState((prevState) => ({
        ...prevState,
        legendDimensions: {
          layout: 'horizontal',
          align: 'left',
          verticalAlign: 'bottom',
        },
        chartRatio: 1.015,
      }))
    }
  }

  return (
    <div className="shipments-count-container costs-chart">
      <header className="reports-page__filters">
        <div className="chart-header">
          <div className="reports-page_header-text">
            <i className="icon rates" />
            <span className="reports-page_header-title">
              {' '}
              {t('reports.costs_shipments', 'Shipment costs')}
            </span>
          </div>
        </div>
        <Box sx={{ ml: 1, display: 'flex', '& button': { mr: 1 } }}>
          <MonthPicker
            onChange={handleDateChange}
            startDate={reportsState.filters.startDate || ''}
            endDate={reportsState.filters.endDate || ''}
            testId="date-picker"
          />
          <MultiSelector
            placeholder={t(
              'common.forms.fields.carrier.no_options_text',
              'Type to search'
            )}
            title={t('common.load_type', 'Load type')}
            fieldName="loadTypes"
            options={reports.filtrationData?.types}
            pickedOptionIds={reportsState.filters.loadTypes}
            onPickOption={pickFilterOption}
            onCancel={resetFiltersOfType}
          />
          <MultiSelector
            placeholder={t(
              'common.forms.fields.carrier.no_options_text',
              'Type to search'
            )}
            title={t(
              'templates.shipment_details.port_of_loading',
              'Port of loading'
            )}
            fieldName="pols"
            options={reports.filtrationData?.pols}
            pickedOptionIds={reportsState.filters.pols}
            onPickOption={pickFilterOption}
            onCancel={resetFiltersOfType}
          />
          <MultiSelector
            placeholder={t(
              'common.forms.fields.carrier.no_options_text',
              'Type to search'
            )}
            title={t(
              'common.forms.fields.port_of_discharge.label',
              'Port of discharge'
            )}
            fieldName="pods"
            options={reports.filtrationData?.pods}
            pickedOptionIds={reportsState.filters.pods}
            onPickOption={pickFilterOption}
            onCancel={resetFiltersOfType}
          />
          <MultiSelector
            placeholder={t(
              'common.forms.fields.carrier.no_options_text',
              'Type to search'
            )}
            title={t('common.status', 'Status')}
            fieldName="status"
            options={reports.filtrationData?.statuses}
            pickedOptionIds={reportsState.filters.status}
            onPickOption={pickFilterOption}
            onCancel={resetFiltersOfType}
          />
          <MultiSelector
            placeholder={t(
              'common.forms.fields.carrier.no_options_text',
              'Type to search'
            )}
            title={t('common.modality_sea.carrier', 'Carrier')}
            fieldName="carriers"
            options={reports.filtrationData?.carriers}
            pickedOptionIds={reportsState.filters.carriers}
            onPickOption={pickFilterOption}
            onCancel={resetFiltersOfType}
          />
          <MultiSelector
            placeholder={t(
              'common.forms.fields.carrier.no_options_text',
              'Type to search'
            )}
            title={t('reports.services', 'Service')}
            fieldName="services"
            options={reports.filtrationData?.services}
            pickedOptionIds={reportsState.filters.services}
            onPickOption={pickFilterOption}
            onCancel={resetFiltersOfType}
          />
          <MultiSelector
            placeholder={t(
              'common.forms.fields.carrier.no_options_text',
              'Type to search'
            )}
            title={t('common.shipper', 'Shipper')}
            fieldName="shippers"
            options={reports.filtrationData?.shippers.map(toOption)}
            pickedOptionIds={reportsState.filters.shippers}
            onPickOption={pickFilterOption}
            onCancel={resetFiltersOfType}
          />
          <MultiSelector
            placeholder={t(
              'common.forms.fields.carrier.no_options_text',
              'Type to search'
            )}
            title={t('common.consignee', 'Consignee')}
            fieldName="consignees"
            options={reports.filtrationData?.consignees.map(toOption)}
            pickedOptionIds={reportsState.filters.consignees}
            onPickOption={pickFilterOption}
            onCancel={resetFiltersOfType}
          />
        </Box>
      </header>
      <CustomChart
        reports={reports}
        reportsState={reportsState}
        search={search}
      />
    </div>
  )
}

export default ReportsShipmentsCosts

import { isEmpty } from 'lodash'
import { parse } from 'query-string'
import { LOCAL_STORAGE_CREDENTIAL_KEY } from 'src/config/constants'

const getScopedOrganizationIdFromURL = () => {
  const location = window.location
  if (location?.search)
    return parse(location.search)['scoped_organization_id'] || null

  return null
}

export const getCredentials = () => {
  const storedCredentials =
    localStorage.getItem(LOCAL_STORAGE_CREDENTIAL_KEY) || '{}'
  const storedScopedOrg = localStorage.getItem('scopedOrganization') || 'null'

  const credentials = JSON.parse(storedCredentials)
  const scopedOrg = JSON.parse(storedScopedOrg)
  const scopedOrgIdInUrl = getScopedOrganizationIdFromURL()
  const origin = window.shyppleConfig.apiUrl

  const headers = {
    uid: credentials?.uid || '',
    'access-token': credentials?.accessToken || '',
    client: credentials?.clientToken || '',
    'Access-Control-Allow-Origin': isEmpty(credentials) ? '*' : origin,
  }

  if (scopedOrg) headers['scoped-organization-id'] = scopedOrg.id
  if (scopedOrgIdInUrl) headers['scoped-organization-id'] = scopedOrgIdInUrl

  return headers
}

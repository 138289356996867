import { useMemo } from 'react'
import { useGetShipmentStatuses } from 'src/services/Api/common'
import { FilterOptionValue } from '../types'
import AutocompleteBase from './AutocompleteBase'

const ShipmentStatusesAutocomplete = ({ token, onDelete }) => {
  const { data: shipmentStatuses = [] } = useGetShipmentStatuses()

  const options: FilterOptionValue[] = useMemo(() => {
    return shipmentStatuses.map((status) => ({
      value: `${status.id}`,
      label: status.name,
    }))
  }, [shipmentStatuses])

  const handleDelete = () => {
    onDelete(token.value)
  }

  return (
    <AutocompleteBase
      options={options}
      noOptionsText="No options"
      token={token}
      loading={false}
      onDelete={handleDelete}
    />
  )
}

export default ShipmentStatusesAutocomplete

import { FunctionComponent, useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import Modal from 'src/components/Common/Modal'
import { setEditCommentsModalInformation } from 'src/stores/actionCreators'
import { initialCommentEditModalState } from 'src/stores/reducers/reportsDashboard'
import { permissionTo } from 'src/utils'
import { Button, Box } from '@mui/material'
import CommentsBlock from 'src/components/CommentsBlock'
import FilterOrganizationButton from './components/FilterOrganizationButton'
import TaskStatusesTable from './components/TaskStatusesTable'

import './styles.scss'

const EditShipmentCommentModal: FunctionComponent<{}> = () => {
  const dispatch = useDispatch()

  const { modalState } = useSelector((state: IGlobalState) => ({
    modalState: state.reportsDashboard.commentEditModal,
  }))

  const onClose = useCallback(() => {
    dispatch(setEditCommentsModalInformation(initialCommentEditModalState))
  }, [])

  if (!modalState.shipment) {
    return null
  }

  return (
    <Modal.Window open={modalState.open} onClose={onClose} fullWidth>
      <Modal.Title onClose={onClose}>
        <span>
          Edit {modalState.shipment.our_reference} for{' '}
          {modalState.shipment.owner_organization.name}
        </span>
      </Modal.Title>
      <Modal.Content>
        <div className="edit-comment">
          <Box sx={{ '& button': { m: 1 } }}>
            {permissionTo('organizations.others.view') ? (
              <Button
                href={`/admin/organizations/${modalState.shipment.owner_organization.id}`}
                target="_blank"
                rel="noopener noreferrer"
                variant="outlined"
              >
                Go to organization page
              </Button>
            ) : (
              ''
            )}
            <FilterOrganizationButton />
          </Box>
          <TaskStatusesTable shipment={modalState.shipment} />
          {permissionTo('pickups_deliveries.shypple_admin') && (
            <Box width="80%">
              <div className="title">Comments</div>
              <CommentsBlock shipmentId={modalState.shipment.id} />
            </Box>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal.Window>
  )
}
export default EditShipmentCommentModal

import { AvatarGroup } from 'src/stories/AvatarGroup'

const getAvatars = (collaborators) => {
  if (collaborators.length > 0) {
    return collaborators
      .map((collaborator, index) => ({
        id: index,
        alt: collaborator.name,
        src: collaborator.logo,
        className: collaborator.organization_role_code,
      }))
      .sort((a, b) => {
        if (a.alt < b.alt) {
          return -1
        }
        if (a.alt > b.alt) {
          return 1
        }
        return 0
      })
  }
  return [
    ...Array(3)
      .fill(0)
      .map((x, index) => ({
        id: `${index}`,
        alt: '',
        src: '',
        className: '',
      })),
  ]
}

const TemplatesListItemCollaborator = ({ collaborators }) => {
  const avatars = getAvatars(collaborators)

  return <AvatarGroup avatars={avatars} />
}

export default TemplatesListItemCollaborator

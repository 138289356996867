import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/organizations'
import * as actionCreators from '../../actionCreators/organizations'
import {
  simpleGetAndResolve,
  simplePutAndResolve,
  simplePatchAndResolve,
} from '../factories'
import uploadLogo from './uploadLogo'

const organizationGetData = simpleGetAndResolve(
  () => 'api/v1/organizations/self_organization',
  actionCreators.organizationGetDataSuccess,
  (response) => response.data
)
const organizationsGetData = simpleGetAndResolve(
  () => 'api/v1/organizations',
  actionCreators.organizationsGetDataSuccess,
  (response) => response.data
)
const organizationsGetPreloaded = simpleGetAndResolve(
  () => 'api/v1/organizations',
  actionCreators.organizationGetPreloadedSuccess,
  (response) => response.data
)
const updateInvoiceAddress = simplePutAndResolve(
  () => 'api/v1/organizations/update_invoice_address',
  (response) => response.data
)
const updatePreferredShipmentRole = simplePatchAndResolve(
  () => 'api/v1/organizations/update_preferred_role',
  actionCreators.organizationGetDataSuccess,
  (response) => response.data
)
const updatePreferredForwarder = simplePatchAndResolve(
  () => 'api/v1/organizations/update_preferred_forwarder',
  actionCreators.organizationGetDataSuccess,
  (response) => response.data
)

const getExportedRatesData = simpleGetAndResolve(
  () => 'api/v1/organizations/export_ratesheet',
  actionCreators.organizationGetExportedRatesDataSuccess,
  (response) => response.data,
  'blob'
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.ORGANIZATIONS_GET_DATA, organizationsGetData)
  yield takeEvery(actions.ORGANIZATIONS_PRELOAD, organizationsGetPreloaded)
  yield takeEvery(actions.ORGANIZATION_GET_DATA, organizationGetData)
  yield takeEvery(actions.ORGANIZATION_UPLOAD_LOGO, uploadLogo)
  yield takeEvery(
    actions.ORGANIZATION_UPDATE_INVOICE_ADDRESS,
    updateInvoiceAddress
  )
  yield takeEvery(actions.UPDATE_PREFERRED_FORWARDER, updatePreferredForwarder)
  yield takeEvery(
    actions.UPDATE_PREFERRED_SHIPMENT_ROLE,
    updatePreferredShipmentRole
  )
  yield takeEvery(
    actions.ORGANIZATION_GET_EXPORTED_RATES_DATA,
    getExportedRatesData
  )
}

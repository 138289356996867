export const reeferIcons = {
  '40_hc_rf': (
    <>
      <i className="icon HC-reefer-container1">
        <span className="path1"></span>
        <span className="path2"></span>
        <span className="path3"></span>
        <span className="path4"></span>
        <span className="path5"></span>
        <span className="path6"></span>
        <span className="path7"></span>
        <span className="path8"></span>
        <span className="path9"></span>
        <span className="path10"></span>
        <span className="path11"></span>
        <span className="path12"></span>
        <span className="path13"></span>
        <span className="path14"></span>
        <span className="path15"></span>
        <span className="path16"></span>
        <span className="path17"></span>
      </i>
    </>
  ),
  '20_rf': (
    <>
      <i className="icon reefer-container1">
        <span className="path1"></span>
        <span className="path2"></span>
        <span className="path3"></span>
        <span className="path4"></span>
        <span className="path5"></span>
        <span className="path6"></span>
        <span className="path7"></span>
        <span className="path8"></span>
        <span className="path9"></span>
        <span className="path10"></span>
        <span className="path11"></span>
        <span className="path12"></span>
        <span className="path13"></span>
        <span className="path14"></span>
        <span className="path15"></span>
        <span className="path16"></span>
        <span className="path17"></span>
      </i>
    </>
  ),
  '40_rf': (
    <>
      <i className="icon reefer-container">
        <span className="path1"></span>
        <span className="path2"></span>
        <span className="path3"></span>
        <span className="path4"></span>
        <span className="path5"></span>
        <span className="path6"></span>
        <span className="path7"></span>
        <span className="path8"></span>
        <span className="path9"></span>
        <span className="path10"></span>
        <span className="path11"></span>
        <span className="path12"></span>
        <span className="path13"></span>
        <span className="path14"></span>
        <span className="path15"></span>
        <span className="path16"></span>
        <span className="path17"></span>
        <span className="path18"></span>
        <span className="path19"></span>
        <span className="path20"></span>
        <span className="path21"></span>
        <span className="path22"></span>
        <span className="path23"></span>
        <span className="path24"></span>
        <span className="path25"></span>
        <span className="path26"></span>
        <span className="path27"></span>
      </i>
    </>
  ),
}

import { useHistory } from 'react-router-dom'
import { Alert as CustomAlert } from 'src/stories/Alert'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

const ExpiredPasswordAlert = ({ email }) => {
  const history = useHistory()
  const { t } = useTranslation()

  localStorage.setItem('resetPasswordEmail', email)

  const onActionClick = () => {
    history.push('/reset-password-request')
  }

  return (
    <Box data-testid="password-expiration-alert">
      <CustomAlert
        color="info"
        actionText={t('login.error.password_expired.action_button')}
        title={t('login.error.password_expired.title')}
        message={t('login.error.password_expired.message')}
        actionHandler={onActionClick}
      />
    </Box>
  )
}

export default ExpiredPasswordAlert

import { FC, useEffect, useState } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { includes } from 'lodash'
import Box from '@mui/material/Box'
import Stepper, { StepperProps } from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { LoadingButton } from '@mui/lab'
import { makeStyles, styled } from '@mui/styles'
import { StepConnector, stepConnectorClasses } from '@mui/material'

export interface IStep {
  key?: string
  label: string
  component: (args) => JSX.Element | string
  requiredFields?: string[]
}

type StepProps<T> = T & { [key: string]: unknown }

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  button: {
    position: 'initial',
  },
  label: {
    color: theme.palette.grey[400],
  },
  primary: {
    color: theme.palette.primary.main,
  },
  none: {
    borderLeft: 'none',
  },
  line: {
    borderLeft: `3px dashed ${theme.palette.grey[300]} !important`,
  },
}))

export interface VerticalLinearStepperProps extends StepperProps {
  steps: IStep[]
  stepProps: StepProps<{ [key: string]: unknown }>
  handleNext?: (index: number) => void
  handleBack?: (index: number) => void
  loading?: boolean
  forwardBtnLabel?: string
  isForwardBtnDisabled?: boolean
  handleGetActiveStep?: (handleGetActiveStep: number) => void
  showFinalBlock?: boolean
  endButtonText?: string
  additionalInfo?: string[]
  buttonTestId?: string
  hideFinalButton?: boolean
  activeAllSteps?: boolean
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderLeft: `${theme.palette.grey[300]} 3px dashed`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderLeft: `${theme.palette.grey[300]} 3px dashed`,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderLeft: `${theme.palette.grey[300]} 3px dashed !important`,
  },
}))

const VerticalLinearStepper: FC<VerticalLinearStepperProps> = (props) => {
  const classes = useStyles()
  const {
    stepProps,
    steps,
    loading,
    forwardBtnLabel,
    isForwardBtnDisabled,
    handleGetActiveStep,
    showFinalBlock = true,
    endButtonText,
    additionalInfo,
    hideFinalButton,
    activeAllSteps = false,
  } = props
  const [activeStep, setActiveStep] = useState(0)
  const lastStep = steps.length - 1

  useEffect(() => {
    if (handleGetActiveStep) {
      handleGetActiveStep(activeStep)
    }
  }, [activeStep])

  const handleNext = (index: number) => {
    if (props.handleNext) {
      props.handleNext(index)
    }
    if (activeStep === lastStep) return
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = (index: number) => {
    if (props.handleBack) {
      props.handleBack(index)
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => setActiveStep(0)

  const btnText =
    activeStep === lastStep
      ? endButtonText
      : i18n.t('templates.cargo_details.button_next', 'Next')

  const isShowBorder = (activeStep: number, index: number) => {
    if (activeStep === lastStep) return classes.none
    if (activeAllSteps && lastStep === index) return classes.none
    return classes.line
  }

  const { t } = useTranslation()

  return (
    <Box>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        connector={<ColorlibConnector />}
      >
        {steps.map((step, index) => (
          <Step
            key={step.label}
            sx={{ position: 'relative' }}
            {...(activeAllSteps && { active: activeAllSteps })}
          >
            <StepLabel>
              <Typography
                variant="h5"
                className={
                  index <= activeStep || activeAllSteps
                    ? classes.primary
                    : classes.label
                }
              >
                {step.label}
              </Typography>
            </StepLabel>
            <StepContent className={isShowBorder(activeStep, index)}>
              <Box sx={{ mb: 2, mt: 2 }}>{step.component(stepProps)}</Box>
              {!activeAllSteps && (
                <Box sx={{ mb: 2, mt: 2 }} mt={5}>
                  <Box className={classes.wrapper}>
                    {activeStep !== 0 && (
                      <Button
                        onClick={() => handleBack(index)}
                        sx={{ mt: 1, mr: 2 }}
                        data-testid="back-button"
                        size="medium"
                      >
                        {t('templates.cargo_details.button_back', 'Back')}
                      </Button>
                    )}
                    {!hideFinalButton && (
                      <LoadingButton
                        className={classes.button}
                        loading={loading}
                        size="medium"
                        disabled={isForwardBtnDisabled}
                        variant="contained"
                        onClick={() => handleNext(index)}
                        sx={{
                          mt: 1,
                          mr: 1,
                          position: 'absolute',
                          right: 3,
                        }}
                        data-testid="next-button"
                      >
                        {btnText || forwardBtnLabel}
                      </LoadingButton>
                    )}
                  </Box>
                </Box>
              )}
            </StepContent>
            {activeStep !== index &&
              includes(
                Array.from({ length: activeStep }, (v, k) => k),
                index
              ) && (
                <Box
                  sx={{
                    position: 'absolute',
                    ml: 4,
                    mt: 2.25,
                    width: '95%',
                  }}
                >
                  <Typography
                    variant="body1"
                    children={!!additionalInfo && additionalInfo[index]}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  />
                </Box>
              )}
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && showFinalBlock && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  )
}

export default VerticalLinearStepper

import Grid from '@mui/material/Grid'
import DemurrageAndDetentionTopSectionBreadcrumbs from './DemurrageAndDetentionTopSectionBreadcrumbs'
import DemurrageAndDetentionTopSectionActions from './DemurrageAndDetentionTopSectionActions'

const DemurrageAndDetentionTopSection = () => {
  return (
    <Grid container spacing={2} mb={2}>
      <Grid item xs={12} md={6}>
        <DemurrageAndDetentionTopSectionBreadcrumbs />
      </Grid>
      <Grid item xs={12} md={6}>
        <DemurrageAndDetentionTopSectionActions />
      </Grid>
    </Grid>
  )
}

export default DemurrageAndDetentionTopSection

import { FunctionComponent, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import BlankState from 'src/components/Common/BlankState'
import LoadableContainer from 'src/components/LoadableContainer'
import Shipment from 'src/components/Shipment'
import { useGetShipments } from 'src/services/Api/shipments'
import { formatFiltersToParams } from '../helpers'
import ShipmentsSkeleton from './ShipmentsSkeleton'

const ShipmentsBody: FunctionComponent = () => {
  const { t } = useTranslation()
  const { filters } = useSelector(
    (state: IGlobalState) => ({ filters: state.shipments.filters }),
    shallowEqual
  )

  const { data, isLoading } = useGetShipments(
    formatFiltersToParams({ ...filters }),
    { enabled: !filters.initial }
  )
  const { shipments } = data || {}

  const shipmentsList = useMemo(() => {
    if (!shipments) return null
    return shipments.map((shipment: IDetailedShipment) => (
      <div className="shipments-page__shipment" key={shipment.id}>
        <Shipment shipment={shipment} />
      </div>
    ))
  }, [shipments])

  return (
    <section
      className="shipments-page__shipments"
      data-testid="shipments-loadable-content"
    >
      <LoadableContainer
        loading={isLoading}
        placeholder={<ShipmentsSkeleton />}
        className="shipments-page__shipments-loading"
        spinnerClassName="shipments-page__shipments-spinner"
      >
        {shipments && shipments.length ? (
          shipmentsList
        ) : (
          <BlankState.Info
            type=""
            iconType="ship"
            descMainText={t('shipments.empty_state.title')}
            descSubText={t('shipments.empty_state.description')}
          />
        )}
      </LoadableContainer>
    </section>
  )
}

export default ShipmentsBody

import { AnyAction } from 'redux'
import * as actions from '../actions/addressBankDetails'

export const getBankDetails = (id: number): AnyAction => ({
  type: actions.GET_BANK_DETAIL,
  id,
})

export const getBankDetailsSuccess = (payload: any): AnyAction => ({
  type: actions.GET_BANK_DETAIL_SUCCESS,
  payload,
})

export const createBankDetails = (payload: any): AnyAction => ({
  type: actions.CREATE_BANK_DETAIL,
  payload,
})

export const createBankDetailsSuccess = (payload: any): AnyAction => ({
  type: actions.CREATE_BANK_DETAIL_SUCCESS,
  payload,
})

export const updateBankDetails = (id: string, payload: any): AnyAction => ({
  type: actions.UPDATE_BANK_DETAIL,
  id,
  payload,
})

export const updateBankDetailsSuccess = (payload: any): AnyAction => ({
  type: actions.UPDATE_BANK_DETAIL_SUCCESS,
  payload,
})

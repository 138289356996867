import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { FC } from 'react'
import { getDimensions } from './helpers'

interface IQuoteTableProps {
  containers: IContainerShort[]
}
export const QuoteTable: FC<IQuoteTableProps> = (props) => {
  return (
    <Table className="info__lcl">
      <TableHead style={{ minHeight: 74 }}>
        <TableRow>
          <TableCell className="center">Quantity*</TableCell>
          <TableCell className="left">Package type*</TableCell>
          <TableCell className="left">Goods description</TableCell>
          <TableCell>Dimensions</TableCell>
          <TableCell>Total volume*</TableCell>
          <TableCell>Total weight*</TableCell>
          <TableCell>Stackable</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(props.containers || []).map(
          (item: IContainerShort, index: number) => (
            <TableRow key={index}>
              <TableCell className="center">{item.quantity} x</TableCell>
              <TableCell className="left">{item.package_type || '-'}</TableCell>
              <TableCell className="left">
                {item.goods_description || '-'}
              </TableCell>
              <TableCell>{getDimensions(item)}</TableCell>
              <TableCell>{item.volume_cbm} m3</TableCell>
              <TableCell>{item.weight_kg} kg</TableCell>
              <TableCell>{item.stackable ? 'Yes' : 'No'}</TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  )
}

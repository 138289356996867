export const tabList = (t): ITabProps[] => [
  {
    label: t('purchase_order.tabs.pending', 'Pending'),
    value: 'pending',
    permissions: [],
  },
  {
    label: t('purchase_order.tabs.shipped', 'Shipped'),
    value: 'shipped',
    permissions: [],
  },
  {
    label: t('purchase_order.tabs.delivered', 'Delivered'),
    value: 'delivered',
    permissions: [],
  },
  {
    label: t('purchase_order.tabs.all', 'All'),
    value: 'all',
    permissions: [],
  },
]

import { FunctionComponent, useState, useMemo } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { DateTime } from 'luxon-business-days'
import { useTranslation } from 'react-i18next'
import {
  Typography,
  Box,
  Collapse,
  Chip,
  IconButton,
  Tooltip,
  SelectChangeEvent,
} from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Delete from '@mui/icons-material/Delete'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import DatePicker from 'src/stories/DatePicker'
import Input from 'src/components/Common/Input/MuiInput'
import { AutoCompleteSelect } from 'src/stories/MUI/Select/AutoCompleteSelect'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import { permissionTo, promisifyAction } from 'src/utils'
import DeliveryInfo from 'src/components/Common/DeliveryInfo'
import {
  addressesGetData,
  pickupsAndDeliveriesSetOpenAddressIndex,
} from 'src/stores/actionCreators'
import { LoadTypeEnum, ModalityEnum } from 'src/config/constants'
import FormLabel from 'src/stories/Lab/FormLabel'
import { includes } from 'lodash'
import {
  addressBookTypeOptions,
  AddressBookTypeOptionsEnum,
  deliveryPlannedOutsideFreeDaysMsg,
} from '../../constants'

interface IProps {
  data: InlandTransportAddressAttributes
  showDelete: boolean
  destroyAddress: (index) => void
  onChangeAddress: (data) => void
  index: number
  inlandTransport: ISingleInlandTransport
}

const AddressCard: FunctionComponent<IProps> = ({
  data,
  showDelete,
  destroyAddress,
  onChangeAddress,
  index,
  inlandTransport,
}) => {
  const { t } = useTranslation()

  const addressTypeOptions = [
    {
      id: 'pickup',
      label: t('transports.drawer.address_book_type.pickup', 'Pick-up'),
    },
    {
      id: 'delivery',
      label: t('transports.drawer.address_book_type.delivery', 'Delivery'),
    },
    {
      id: 'depot',
      label: t('transports.drawer.address_book_type.depot', 'Depot'),
    },
    {
      id: 'extra_stop',
      label: t('transports.drawer.address_book_type.extra_stop', 'Extra stop'),
    },
    {
      id: 'terminal',
      label: t('transports.drawer.address_book_type.terminal', 'Terminal'),
    },
  ]
  const {
    pickup_delivery_time_editable: pickupDeliveryTimeEditable,
    shipment: {
      collaborators,
      modality,
      load_type: loadType,
      delivery_available_from: deliveryAvailableFrom,
      destination_demurrage_starting_from: destinationDemurrageStartingFrom,
    },
    container: {
      actual_first_day_of_demurrage: actualFirstDayOfDemurrage = null,
    },
    inland_transport_addresses,
  } = inlandTransport

  const customerAddressTypeOptions = ['delivery', 'extra_stop']
  const dispatch = useDispatch()

  const { isItemOpen } = useSelector((state: IGlobalState) => {
    return {
      isItemOpen: index === state.pickUpsAndDeliveries.openAddressIndex,
    }
  }, shallowEqual)
  const getAddresses = promisifyAction(dispatch, addressesGetData)

  const [address, setAddress] = useState<
    InlandTransportAddressAttributes | InlandTransportAddress | null
  >(inland_transport_addresses[index])
  const [addressBookType, setAddressBookType] = useState<string>(
    data.address_book_type ?? AddressBookTypeOptionsEnum.CustomersAddressBook
  )
  const [showDemmurageWarning, setShowDemmurageWarning] = useState<boolean>(
    false
  )
  const customer = collaborators.find((collaborator) => {
    return collaborator.organization_role_code === 'customer'
  })

  const fullOrLimitedPermissions = useMemo(
    () =>
      permissionTo([
        'pickups_deliveries.limited_manage',
        'pickups_deliveries.full_manage',
        'pickups_deliveries.shypple_admin',
      ]),
    []
  )

  const isTimeEditable = useMemo(() => {
    return (
      permissionTo([
        'pickups_deliveries.full_manage',
        'pickups_deliveries.shypple_admin',
      ]) ||
      (permissionTo('pickups_deliveries.limited_manage') &&
        pickupDeliveryTimeEditable)
    )
  }, [])

  const fullOrAdminPermissions = useMemo(
    () =>
      permissionTo([
        'pickups_deliveries.full_manage',
        'pickups_deliveries.shypple_admin',
      ]),
    []
  )

  const handleClick = () => {
    dispatch(pickupsAndDeliveriesSetOpenAddressIndex(isItemOpen ? null : index))
  }

  const onChangeInfo = (name: string, value: any) => {
    setAddress(value)
    onChangeAddress({
      index,
      name,
      value,
    })

    if (name === 'datetime_from') {
      const date = DateTime.fromISO(value).toFormat('yyyy-MM-dd')
      if (
        (date >= destinationDemurrageStartingFrom &&
          actualFirstDayOfDemurrage === null) ||
        (actualFirstDayOfDemurrage !== null &&
          date >=
            DateTime.fromISO(actualFirstDayOfDemurrage).toFormat('yyyy-MM-dd'))
      ) {
        setShowDemmurageWarning(true)
      } else setShowDemmurageWarning(false)
    }
  }

  const addressType = useMemo(() => {
    return addressTypeOptions.find((opt) => opt.id === data.address_type)
  }, [data])

  const addressDesc = useMemo(() => {
    return `${data?.name || '-'} ${data?.street || ''} ${
      data?.postal_code || ''
    } ${data?.city || ''}`
  }, [data])

  const onChangeAddressType = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target

    const addressBookTypeOption = includes(customerAddressTypeOptions, value)
      ? AddressBookTypeOptionsEnum.CustomersAddressBook
      : AddressBookTypeOptionsEnum.YourAddressBook

    setAddressBookType(addressBookTypeOption)
    onChangeInfo('address_type', value)
    onChangeInfo('address_book_type', addressBookTypeOption)
  }

  const onChangeAddressBookType = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string
    setAddressBookType(value)
    onChangeInfo('address_book_type', value)
    setAddress(null)
  }
  return (
    <Box
      sx={{
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'grey.400',
        borderRadius: 1,
        p: 1.5,
        mb: 1,
        minHeight: 44,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: 'white',
        cursor: 'pointer',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: 44,
        }}
        onClick={handleClick}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton size="large">
            <DragIndicatorIcon />
          </IconButton>
          <Box sx={{ ml: 1, minWidth: 300, maxWidth: 510 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{ mr: 1 }}
                variant="body1Strong"
                children={addressType?.label || ''}
                data-testid="inland-transport-address-title"
              />
              {data.pickup_delivery && (
                <Chip
                  size="small"
                  color="secondary"
                  label={t(
                    'transports.drawer.used_for calculation',
                    'Used for price calculation'
                  )}
                />
              )}
            </Box>
            <Tooltip
              enterDelay={100}
              leaveDelay={0}
              leaveTouchDelay={1500}
              placement="top-end"
              title={addressDesc.length > 70 ? addressDesc : ''}
            >
              <Typography noWrap variant="body1" children={addressDesc} />
            </Tooltip>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {showDelete && !data.pickup_delivery && (
            <IconButton size="large" onClick={() => destroyAddress(index)}>
              <Delete color="action" />
            </IconButton>
          )}
          <IconButton size="large">
            {isItemOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
      </Box>
      <Collapse in={isItemOpen} timeout="auto" unmountOnExit>
        <Box sx={{ p: 1 }}>
          <Box sx={{ mt: 2, display: 'flex' }}>
            <Box sx={{ mr: 2, width: 200 }}>
              <SingleSelect
                value={data.address_type}
                label={t('transports.drawer.type', 'Type *')}
                placeholder={t('common.select', 'Select')}
                options={addressTypeOptions}
                onChange={onChangeAddressType}
                disabled={!fullOrAdminPermissions}
                MenuProps={{ disablePortal: true }}
                data-testid="inland-transport-address-type-select"
                inputProps={{
                  'data-testid': 'inland-transport-address-type-select-input',
                }}
              />
            </Box>
            <Box sx={{ width: 425 }}>
              <FormLabel
                label={t(
                  'transports.drawer.delivery_reference',
                  'Delivery reference'
                )}
              />
              <Input
                name={t('transports.drawer.reference', 'reference')}
                data-testid="inland-transport-address-reference"
                placeholder={t(
                  'transports.drawer.add_reference',
                  'Add reference'
                )}
                className="input-field"
                value={data.reference || ''}
                onChange={(newValue) => {
                  onChangeInfo('reference', newValue)
                }}
                disabled={!fullOrLimitedPermissions}
              />
            </Box>
          </Box>
          <Box sx={{ mt: 2, display: 'flex' }}>
            {fullOrAdminPermissions && (
              <Box sx={{ mr: 2, width: 250 }}>
                <SingleSelect
                  value={addressBookType}
                  label={t('transports.drawer.search_in', 'Search in')}
                  placeholder={t('common.select', 'Select')}
                  options={addressBookTypeOptions}
                  onChange={onChangeAddressBookType}
                  MenuProps={{ disablePortal: true }}
                  data-testid="inland-transport-address-book-type-select"
                  inputProps={{
                    'data-testid':
                      'inland-transport-address-book-type-select-input',
                  }}
                />
              </Box>
            )}
            <Box sx={{ width: !fullOrAdminPermissions ? 640 : 375 }}>
              <FormLabel label={t('transports.drawer.address', 'Address *')} />
              <AutoCompleteSelect
                id="address"
                optionLabel={(opt) => opt.name || ''}
                onChange={(newValue) => {
                  onChangeInfo('address', newValue)
                }}
                getData={async (query) => {
                  if (
                    !permissionTo([
                      'pickups_deliveries.full_manage',
                      'pickups_deliveries.limited_manage',
                      'pickups_deliveries.shypple_admin',
                    ])
                  ) {
                    return []
                  }

                  const addressesParams = {
                    search: query,
                    page_size: 4000,
                  }
                  if (
                    addressBookType ===
                    AddressBookTypeOptionsEnum.CustomersAddressBook
                  ) {
                    addressesParams['organization_id'] =
                      customer?.organization_id
                  }

                  const addressesData = await getAddresses(addressesParams)
                  return addressesData.data
                }}
                value={address}
                placeholder={t(
                  'transports.drawer.start_typing_address',
                  'Start typing to see addresses...'
                )}
                data-testid="inland-transport-address-search"
                disableClearable={true}
                disabled={!fullOrLimitedPermissions}
              />
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <FormLabel
              label={t(
                'transports.drawer.pref_unloading_time',
                'Preferred unloading time (30 minute range)'
              )}
            />
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <Box
                sx={{ width: 195, mr: 2 }}
                data-testid="inland-transport-address-datetime-from"
              >
                <DatePicker
                  closeOnSelect={false}
                  placeholder={t('common.date_notation', 'DD-MM-YYYY HH:MM')}
                  inputFormat="dd-MM-yyyy HH:mm"
                  clearable={false}
                  value={data.datetime_from}
                  onChange={(date: DateTime | null) =>
                    onChangeInfo('datetime_from', date?.toISO())
                  }
                  disabled={!isTimeEditable}
                  data-testid="inland-transport-address-datetime-from-input"
                />
              </Box>
              <Box
                sx={{ width: 195 }}
                data-testid="inland-transport-address-datetime-to"
              >
                <DatePicker
                  closeOnSelect={false}
                  placeholder={t('common.date_notation', 'DD-MM-YYYY HH:MM')}
                  inputFormat="dd-MM-yyyy HH:mm"
                  clearable={false}
                  value={data.datetime_to}
                  onChange={(date: DateTime | null) =>
                    onChangeInfo('datetime_to', date?.toISO())
                  }
                  disabled={!isTimeEditable}
                  data-testid="inland-transport-address-datetime-to-input"
                />
              </Box>
            </Box>
          </Box>

          {showDemmurageWarning && (
            <Box
              sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
              data-testid="demurrage-detantion-warning"
            >
              <WarningIcon sx={{ mr: 1.5, color: 'warning.800' }} />
              <Typography
                component="span"
                color="warning.800"
                variant="body1"
                sx={{ whiteSpace: 'pre-line' }}
                children={deliveryPlannedOutsideFreeDaysMsg}
              />
            </Box>
          )}
          {addressType?.id === 'delivery' && (
            <DeliveryInfo
              modality={modality as ModalityEnum}
              loadType={loadType as LoadTypeEnum}
              deliveryAvailableFrom={deliveryAvailableFrom}
              actualFirstDayOfDemurrage={actualFirstDayOfDemurrage}
              destinationDemurrageStartingFrom={
                destinationDemurrageStartingFrom
              }
            />
          )}
        </Box>
      </Collapse>
    </Box>
  )
}

export default AddressCard

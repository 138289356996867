import { call } from 'redux-saga/effects'
import { Method } from 'axios'
import { retrieveCredentials, rejectAction } from 'src/stores/sagas/wrappers'
import { queryClient } from 'src/services/http-common'
import { shyppleGa, requester } from '../../../utils'

import { LOCAL_STORAGE_CREDENTIAL_KEY } from '../../../config/constants'

export default function* (action: IPromisifiedAction): Iterator<any> {
  const credentials: any = yield retrieveCredentials()
  try {
    yield call([requester, 'request'], {
      headers: {
        uid: credentials.uid,
        'access-token': credentials.accessToken,
        client: credentials.clientToken,
      },
      method: 'DELETE' as Method,
      url: 'api/auth/sign_out',
    })
  } catch (error) {
    yield rejectAction(action, error)
  }
  yield call([localStorage, 'removeItem'], LOCAL_STORAGE_CREDENTIAL_KEY)
  yield call([localStorage, 'removeItem'], 'scopedOrganization')
  yield call([shyppleGa, 'clearUserData'])
  document.cookie = 'jwt= ; expires = Thu, 01 Jan 1970 00:00:00 GMT'
  queryClient.clear()
}

export const SHIPMENT_CONTAINERS_GET_DATA = 'SHIPMENT_CONTAINERS_GET_DATA'
export const SHIPMENT_CONTAINERS_GET_DATA_SUCCESS =
  'SHIPMENT_CONTAINERS_GET_DATA_SUCCESS'
export const SHIPMENT_GET_MAIN_DATA = 'SHIPMENT_GET_MAIN_DATA'
export const SHIPMENT_GET_MAIN_DATA_SUCCESS = 'SHIPMENT_GET_MAIN_DATA_SUCCESS'

export const SHOW_INLAND_TRANSPORT_STATUS = 'SHOW_INLAND_TRANSPORT_STATUS'
export const SHOW_INLAND_TRANSPORT_STATUS_SUCCESS =
  'SHOW_INLAND_TRANSPORT_STATUS_SUCCESS'

export const ACCEPT_INLAND_TRANSPORT_STATUS = 'ACCEPT_INLAND_TRANSPORT_STATUS'
export const ACCEPT_INLAND_TRANSPORT_STATUS_SUCCESS =
  'ACCEPT_INLAND_TRANSPORT_STATUS_SUCCESS'

export const CREATE_CARGO_LINE = 'CREATE_CARGO_LINE'
export const CREATE_CARGO_LINE_SUCCESS = 'CREATE_CARGO_LINE_SUCCESS'
export const UPDATE_CARGO_LINE = 'UPDATE_CARGO_LINE'
export const UPDATE_CARGO_LINE_SUCCESS = 'UPDATE_CARGO_LINE_SUCCESS'

export const ASSIGN_ADDRESS_TO_CONTAINER = 'ASSIGN_ADDRESS_TO_CONTAINER'
export const ASSIGN_ADDRESS_TO_CONTAINER_SUCCESS =
  'ASSIGN_ADDRESS_TO_CONTAINER_SUCCESS'

export const CLEAR_PICKUP_AND_DELIVERY_STATE = 'CLEAR_PICKUP_AND_DELIVERY_STATE'
export const CONTAINER_ASSIGN_DATETIME = 'CONTAINER_ASSIGN_DATETIME'
export const CONTAINER_ASSIGN_DATETIME_SUCCESS =
  'CONTAINER_ASSIGN_DATETIME_SUCCESS'
export const UPDATE_INLAND_TRANSPORT_STATUS = 'UPDATE_INLAND_TRANSPORT_STATUS'
export const UPDATE_INLAND_TRANSPORT_STATUS_SUCCESS =
  'UPDATE_INLAND_TRANSPORT_STATUS_SUCCESS'

export const DELETE_CARGO_LINE = 'DELETE_CARGO_LINE'
export const ASSIGN_REFERENCE_TO_CONTAINER = 'ASSIGN_REFERENCE_TO_CONTAINER'
export const ASSIGN_REFERENCE_TO_CONTAINER_SUCCESS =
  'ASSIGN_REFERENCE_TO_CONTAINER_SUCCESS'
export const UPDATE_CONTAINER_INFORMATION = 'UPDATE_CONTAINER_INFORMATION'
export const CREATE_SHIPMENT_CONTAINER = 'CREATE_SHIPMENT_CONTAINER'
export const UPDATE_SHIPMENT_CONTAINER = 'UPDATE_SHIPMENT_CONTAINER'
export const DELETE_SHIPMENT_CONTAINER = 'DELETE_SHIPMENT_CONTAINER'
export const DUPLICATE_SHIPMENT_CONTAINER = 'DUPLICATE_SHIPMENT_CONTAINER'
export const COPY_CONTAINER_ATTRIBUTES = 'COPY_CONTAINER_ATTRIBUTES'
export const COPY_CONTAINER_ATTRIBUTES_SUCCESS =
  'COPY_CONTAINER_ATTRIBUTES_SUCCESS'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConfirmDialog from 'src/components/ConfirmDialog'
import { useDispatch } from 'react-redux'
import { routes } from 'src/pages/AddressBook'
import { history } from 'src/shyppleStore'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { Button } from '@mui/material'
import { promisifyAction } from '../../../utils'
import { personalDetailsDeletePersonalDetail } from '../../../stores/actionCreators'

const DeleteContactButton = ({ contact }: { contact: IPersonalDetail }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const deleteContact = promisifyAction(
    dispatch,
    personalDetailsDeletePersonalDetail
  )

  const removeContact = async () => {
    setVisible(false)
    await deleteContact(contact.id)
    history.push(routes.persons)
    dispatch(
      showNotification({
        message: `Contact "${contact.name}" deleted.`,
        severity: 'success',
      })
    )
  }

  return (
    <>
      <Button
        variant="text"
        component="button"
        onClick={(e) => {
          e.preventDefault()
          setVisible(true)
        }}
      >
        {t('address_book.connections.remove_contact')}
      </Button>

      <ConfirmDialog
        title={t('address_book.connections.remove_contact')}
        message={`${t('address_book.connections.remove_contact_message')} "${
          contact.name
        }?"`}
        isOpen={visible}
        confirm={removeContact}
        reject={() => setVisible(false)}
        onClose={() => setVisible(false)}
      />
    </>
  )
}

export default DeleteContactButton

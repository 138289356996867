import { FunctionComponent, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link, Typography } from '@mui/material'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { toggleHsModalOpen } from '../../../stores/actionCreators'
import HsCodeLine from '../HsCodeLine'
import HsCodeAddModal from '../HsCodeAddModal'

import './styles.scss'

interface IProps {
  hsCodes: IContainerHsCode[]
  containerId: number
  containerType: string
}

const HsCodesBlock: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const { hsCodesRequested } = useSelector((state: IGlobalState) => ({
    hsCodesRequested: state.shipmentOverview.hs_codes_requested,
  }))
  const dispatch = useDispatch()

  const openAddModal = useCallback(() => {
    dispatch(
      toggleHsModalOpen({
        open: true,
        containerId: props.containerId,
        containerType: props.containerType,
      })
    )
  }, [])
  const onClose = useCallback(() => {
    dispatch(
      toggleHsModalOpen({
        open: false,
        containerId: null,
        containerType: null,
      })
    )
  }, [])

  return (
    <div className="hs-codes-block">
      {!hsCodesRequested && (
        <div className="hs-codes-block__not-requested">
          <Typography
            variant="body1"
            children={t(
              'shipment_containers.hs_code_not_requested',
              'Customs service has not been requested for this shipment. If you would like Shypple to handle the customs declaration, Please contact us via the chat.'
            )}
          />
        </div>
      )}
      {props.hsCodes.map((hsCode: IContainerHsCode) => (
        <HsCodeLine
          hsCode={hsCode}
          key={hsCode.id}
          containerId={props.containerId}
        />
      ))}
      <div className="hs-codes-block__add-button-container">
        <Link variant="body1" component="button" onClick={openAddModal}>
          <AddRoundedIcon />
          {t('common.buttons.add_hs_codes', 'Add HS codes')}
        </Link>
      </div>
      <HsCodeAddModal currentHsCodes={props.hsCodes} onClose={onClose} />
    </div>
  )
}

export default HsCodesBlock

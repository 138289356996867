import { put, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { parse } from 'query-string'

import { DEFAULT_PAGE } from '../../../config/constants'

function getRedirectPath(state: IGlobalState): string {
  const routing: any = state.router || {}
  const location: any = routing.location || {}
  const redirect: any = parse(location.search).redirect
  if (!redirect) {
    if (location.pathname === '/login') {
      return DEFAULT_PAGE
    }
    return ''
  }
  return redirect + location.hash
}

export default function* (): Iterator<any> {
  const redirectUrl = yield select(getRedirectPath)
  if (redirectUrl) {
    yield put(push(redirectUrl))
  }
}

import { FunctionComponent, useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { get, truncate, snakeCase } from 'lodash'
import { makeStyles } from '@mui/styles'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import {
  Link,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Chip,
  Checkbox,
  Box,
} from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { SelectableChipOptions } from 'src/components/SelectableChip'
import { LoadTypeEnum } from 'src/config/constants'
import {
  convertDateToOrderFormat,
  fromISOwithZone,
  isValidDate,
} from 'src/utils/helpers'
import { pickupsAndDeliveriesSelectedIds } from 'src/stores/actionCreators'
import { permissionTo } from 'src/utils'
import EtaHistoryPopover from 'src/components/ShipmentEstimatedArrivalDelay'
import EstimatedArrivalDelayChip from 'src/components/ShipmentEstimatedArrivalDelay/InlandTransportRow'
import {
  containerStatusChip,
  canBulkEdit,
  canUpdateTransport,
  getContainerStatusText,
  canUpdatePickupAndDeliveryTime,
} from '../helpers'
import { columnsData } from '../constants'
import TaskStatusSelect from './TaskStatusSelect'
import { FirstDayOfDemurrage } from './FirstDayOfDemurrage'
import InlandTransportRowInspection from './InlandTransportRowInspection'
import InlandTransportRowDeliveryDate from './InlandTransportRowDeliveryDate'
import InlandTransportRowDeliveryStatus from './InlandTransportRowDeliveryStatus'
import InlandTransportRowShipmentStatus from './InlandTransportRowShipmentStatus'
import InlandTransportRowTransportAddress from './InlandTransportRowTransportAddress'
import InlandTransportRowTransportTransporter from './InlandTransportRowTransportTransporter'
import InlandTransportRowDocumentsChip from './InlandTransportRowDocumentsChip'
import InlandTransportRowNotes from './InlandTransportRowNotes'
import InlandTransportRowContainerDischargeDate from './InlandTransportRowContainerDischargeDate'

const useStyles = makeStyles((theme) => ({
  arrowIcon: {
    color: theme.palette.grey[500],
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.complex,
    }),
    transform: 'rotate(0)',
  },
  open: {
    transform: 'rotate(180deg)',
  },
  reference: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '18rem',
    whiteSpace: 'nowrap',
    display: 'block',
    color: theme.palette.primary.main,
  },
  title: {
    width: '100px',
    color: theme.palette.primary.main,
  },
  firstCell: {
    width: 20,
    padding: `0 ${theme.spacing(1.5)} 0 0`,
  },
  pickup_delivery_time: {
    width: '152px',
  },
}))

const InlandTransportRow: FunctionComponent<{
  row: IInlandTransportState
  onCellClick: (event, row, column, name) => void
  shipmentStatuses: SelectableChipOptions
}> = ({ row, onCellClick, shipmentStatuses }) => {
  const { t } = useTranslation()
  const [isCopied, setIsCopied] = useState(false)
  const classes = useStyles()
  const dispatch = useDispatch()
  const baseUrl = window.shyppleConfig.apiUrl

  const {
    tableColumns,
    taskMappings,
    isRowOpen,
    isRowSelected,
    rowsSelected,
  } = useSelector(
    (state: IGlobalState) => ({
      tableColumns: state.pickUpsAndDeliveries.tableColumns,
      taskMappings:
        state.pickUpsAndDeliveries.filtersOptions.task_type_statuses,
      isRowOpen:
        state.pickUpsAndDeliveries.selectedInlandTransportId === row.id,
      rowsSelected: state.pickUpsAndDeliveries.selectedInlandTransportRowsIds,
      isRowSelected: !!state.pickUpsAndDeliveries.selectedInlandTransportRowsIds.find(
        (item) => item === row.id
      ),
    }),
    shallowEqual
  )

  const canUpdateShipmentStatus = permissionTo('shipments.status.manage')
  const isShyppleAdmin = permissionTo('pickups_deliveries.shypple_admin')

  const {
    estimated_arrival: estimatedArrival,
    estimated_departure: estimatedDeparture,
  } = row.shipment

  const shipmentEstimatedArrival =
    estimatedArrival && isValidDate(estimatedArrival)
      ? fromISOwithZone(estimatedArrival)
      : null

  const shipmentEstimatedDeparture =
    estimatedDeparture && isValidDate(estimatedDeparture)
      ? fromISOwithZone(estimatedDeparture)
      : null

  const formatCellValue = (value: any, type: string) => {
    switch (type) {
      case 'bold':
        return <Typography className="bold">{value}</Typography>
      case 'grey':
        return (
          <Typography variant="body1" color="grey.600">
            {value}
          </Typography>
        )
      case 'date':
        return convertDateToOrderFormat(value, 'dd MMM yyyy')
      case 'array_date':
        const date = value.find((x) => x.pickup_delivery)?.datetime_from
        if (!date) return '-'
        return convertDateToOrderFormat(date, 'dd MMM yyyy')
      case 'array_name':
        const name = value.find((x) => x.pickup_delivery)?.name
        return name || '-'
      case 'array_names':
        const shippers = value.map((x) => x.name).join(',')
        if (shippers.length <= 25) {
          return shippers || '-'
        }
        return (
          <Tooltip color="primary" title={shippers} placement="right" arrow>
            <span>
              {truncate(shippers, {
                length: 28,
              })}
            </span>
          </Tooltip>
        )
      default:
        return typeof value === 'string' ? value : '-'
    }
  }

  const getColumnValue = (
    row: IInlandTransportState,
    column: { [key: string]: any }
  ) => {
    let value: any = row

    const columnValue = columnsData[column.name]?.value
    const columnType = columnsData[column.name]?.type
    const inlandTransportStatus = value.status
    const pickupDeliveryProps = {
      rowId: row.id,
      service: value.service,
      addresses: value.inland_transport_addresses,
    }

    switch (columnType) {
      case 'inland_transport_address':
        return (
          <InlandTransportRowTransportAddress
            {...pickupDeliveryProps}
            updatable={permissionTo([
              'pickups_deliveries.limited_manage',
              'pickups_deliveries.shypple_admin',
              'pickups_deliveries.full_manage',
            ])}
            collaborators={value.shipment.collaborators}
          />
        )
      case 'pickup_delivery_time':
        return (
          <InlandTransportRowDeliveryDate
            {...pickupDeliveryProps}
            className={classes[columnValue]}
            arrival={shipmentEstimatedArrival}
            departure={shipmentEstimatedDeparture}
            updatable={canUpdatePickupAndDeliveryTime(row)}
            pickupDeliverDateTime={value.pickup_delivery_time}
          />
        )
      case 'transporter':
        return (
          <InlandTransportRowTransportTransporter
            rowId={row.id}
            updatable={canUpdateTransport()}
            transporter={value.transporter}
          />
        )
      case 'inspection':
        return (
          <InlandTransportRowInspection
            rowId={row.id}
            shipmentId={value.shipment.id}
            updatable={canUpdateTransport()}
            inspectionDateTime={value.inspection}
            tasks={taskMappings?.FRESH_inspection || []}
            task={value.tasks?.FRESH_inspection || null}
          />
        )
      case 'container_type':
        const loadType = value.shipment.load_type
        const data = `${value.shipment.modality} - ${loadType}`

        return loadType === LoadTypeEnum.lcl
          ? data.toUpperCase()
          : value.container.container_type.name
      case 'status':
        return (
          <InlandTransportRowShipmentStatus
            status={value.shipment?.status?.name ?? ''}
            phase={value.shipment?.shipment_phase}
            shipmentId={value.shipment.id}
            shipmentStatuses={shipmentStatuses}
            updatable={canUpdateShipmentStatus}
          />
        )
      case 'container_status':
        if (columnValue) {
          value = get(value, columnValue) || '-'
        } else {
          value = '-'
        }

        if (value === '-') return '-'

        return <Chip label={value} color="primary" />
      case 'container_nr':
        const clickOnCopy = () => {
          setIsCopied(true)
          setTimeout(() => setIsCopied(false), 1000)
        }
        return value.container?.number ? (
          <>
            {value.shipment.operation_type === 'services_only' &&
              ['lcl_import_orders', 'fcl_import_orders'].includes(
                value.shipment.service_department.code
              ) && (
                <Chip
                  label="NEW"
                  size="small"
                  color="success"
                  className="mr-5"
                />
              )}
            <Tooltip
              color="primary"
              title={getContainerStatusText(
                value.is_service_requested,
                value.container.pod_customs_inspection_status,
                row.service,
                t
              )}
              placement="top-start"
            >
              <Box display="inline">
                {containerStatusChip(
                  value.container.number,
                  value.container.status_code,
                  value.is_service_requested,
                  value.container.pod_customs_inspection_status,
                  isCopied,
                  clickOnCopy
                )}
              </Box>
            </Tooltip>
            {row.service === 'delivery' && (
              <InlandTransportRowDocumentsChip
                podCustomsDocumentsPresent={
                  value.container.pod_customs_documents_present
                }
                portbaseEnabled={value.container.portbase_enabled}
              />
            )}
          </>
        ) : (
          '-'
        )
      case 'datetime':
        const rawValue = get(row, columnValue, null)
        const datetime = DateTime.fromISO(rawValue, { setZone: true }) // this argument explicitly tells luxon to show in time zone from the string.
        const formattedRowValue = datetime.toFormat('dd MMM HH:mm')

        if (columnValue === 'shipment.estimated_arrival') {
          return (
            <EtaHistoryPopover
              delay={row.shipment.estimated_arrival_delay}
              shipmentId={row.shipment.id}
            >
              <>
                {datetime.isValid ? formattedRowValue : rawValue}
                <EstimatedArrivalDelayChip
                  delay={row.shipment.estimated_arrival_delay}
                />
              </>
            </EtaHistoryPopover>
          )
        }
        if (columnValue === 'container.actual_discharge') {
          return (
            <InlandTransportRowContainerDischargeDate
              rowId={row.id}
              containerId={row.container.id}
              className={classes[columnValue]}
              value={rawValue}
              updatable={canUpdateTransport()}
            />
          )
        }
        if (columnValue === 'pickup_delivery_time' && value) {
          return formatCellValue(
            value,
            inlandTransportStatus === 'estimated' ? 'grey' : 'bold'
          )
        }

        if (!datetime.isValid) return rawValue || '-'
        return formattedRowValue

      case 'inland_transport_status':
        return (
          <InlandTransportRowDeliveryStatus
            rowId={row.id}
            status={value.status}
            service={value.service}
            updatable={canUpdateTransport()}
          />
        )
      case 'reference':
        const text = value.shipment[columnValue]
        if (!text) {
          return '-'
        }
        return (
          <Link
            target="_blank"
            className={classes[columnValue]}
            data-testid={`reference-btn/${text}`}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              window.open(`shipments/${value.shipment.id}`, '_blank')
            }}
          >
            {text}
          </Link>
        )
      case 'notes':
        return <InlandTransportRowNotes inlandTransport={row} />
      case 'first_day_of_demurrage':
        const [
          actualFirstDayOfDemurrage,
          destinationDemurrageStartingFrom,
        ] = columnValue
        const actualDay = get(value, actualFirstDayOfDemurrage)
        const startingFrom = get(value, destinationDemurrageStartingFrom)
        if (actualDay) {
          return (
            <FirstDayOfDemurrage
              date={actualDay}
              color="success"
              chipLabel="Actual"
            />
          )
        }
        if (startingFrom) {
          return (
            <FirstDayOfDemurrage
              date={startingFrom}
              color="secondary"
              chipLabel="Estimated"
            />
          )
        }
        return '-'
      case 'carrier':
        return (
          <Tooltip
            color="primary"
            title={value.shipment.carrier?.name ?? ''}
            placement="top-start"
          >
            <Box>{value.shipment.carrier?.scac ?? '-'}</Box>
          </Tooltip>
        )
      default:
        if (columnValue) {
          value = get(value, columnValue) || '-'
        } else {
          value = '-'
        }

        if (value === '-') return '-'
        return formatCellValue(value, columnType)
    }
  }

  const renderTask = (inlandTransport, code) => {
    const task = inlandTransport.tasks[code]
    if (!task || !taskMappings[code]) {
      return '-'
    }

    return (
      <TaskStatusSelect
        selectedMapping={taskMappings[code]}
        shipmentId={task.shipment_id}
        currentTaskTypeStatusId={task.task_status_id}
        taskId={task.id}
      />
    )
  }

  const selectRow = () => {
    let rowsIds = rowsSelected
    if (isRowSelected) {
      rowsIds = rowsIds.filter((id) => id !== row.id)
    } else {
      rowsIds = rowsIds.concat([row.id])
    }
    dispatch(pickupsAndDeliveriesSelectedIds(rowsIds))
  }

  const openInCortana = (e) => {
    e.stopPropagation()
    window.open(`${baseUrl}/admin/shipments/${row.shipment.id}`, '_blank')
  }

  return (
    <TableRow
      sx={{
        cursor: 'pointer',
        backgroundColor: isRowOpen || isRowSelected ? 'primary.light' : 'none',
        '&:hover': {
          backgroundColor:
            isRowOpen || isRowSelected ? 'primary.light' : 'grey.50',
        },
      }}
      data-testid="table-row"
      id={`${row.id}`}
    >
      {canBulkEdit() && (
        <TableCell key={`${row.id}-cell-header`}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              data-testid="select-row-action"
              sx={{ padding: 0 }}
              checked={!!isRowSelected}
              onChange={selectRow}
            />
            {isShyppleAdmin && (
              <Tooltip color="primary" title="Go to Cortana" placement="right">
                <OpenInNewIcon
                  className="pickups-and-deliveries__cortana-link"
                  onClick={openInCortana}
                  data-testid="cortana-link"
                />
              </Tooltip>
            )}
          </Box>
        </TableCell>
      )}
      {tableColumns.map((column, j) => {
        if (['Tasks', 'Air Tasks'].includes(column.name)) {
          if (!permissionTo('pickups_deliveries.shypple_admin')) {
            return null
          }
          return column.task_type_group?.task_types.map((task) => (
            <TableCell key={`${task.code}-${row.id}-${j}-cell-header`}>
              {renderTask(row, task.code)}
            </TableCell>
          ))
        }
        if (column.name === 'Transporter' && !canUpdateTransport()) {
          return null
        }
        const value = getColumnValue(row, column)

        return (
          <TableCell
            onClick={(event) =>
              onCellClick(
                event,
                row,
                columnsData[column.name]?.type,
                column.name
              )
            }
            key={`${row.id}-${j}-cell-header`}
            data-testid={`${snakeCase(column.name)}-cell-body`}
          >
            {value}
          </TableCell>
        )
      })}
    </TableRow>
  )
}
export default InlandTransportRow

import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { truncate } from 'lodash'

import Box from '@mui/material/Box'
import Link from '@mui/material/Link'

import { promisifyAction } from 'src/utils'
import { updatePickupsAndDeliveriesItem } from 'src/stores/actionCreators'

import Input from 'src/components/Common/Input/MuiInput'
import Tooltip from '@mui/material/Tooltip'
import { useTranslation } from 'react-i18next'

const InlandTransportRowNotes: React.FC<{ inlandTransport }> = ({
  inlandTransport,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [value, setValue] = useState(inlandTransport.notes)
  const [inputOpen, setInputOpen] = useState(false)

  const updateRowAsync = promisifyAction(
    dispatch,
    updatePickupsAndDeliveriesItem
  )

  const onBoxClick = useCallback((e) => {
    e.stopPropagation()
    setInputOpen(true)
  }, [])

  const onInputBlur = useCallback(async () => {
    await updateRowAsync(inlandTransport.id, { notes: value || '' })
    setInputOpen(false)
  }, [value])

  const onKeyDown = useCallback(
    (e) => {
      if (['Enter', 'NumpadEnter'].includes(e.code)) {
        onInputBlur()
      }
    },
    [value]
  )

  const onChange = useCallback((newValue) => {
    setValue(newValue)
  }, [])

  return (
    <Box
      alignItems="center"
      onClick={onBoxClick}
      sx={{ width: '200px' }}
      data-testid="transport-notes"
    >
      {inputOpen ? (
        <Input
          autoFocus
          name="reference"
          placeholder={t('transports.fields.notes.placeholder', 'Add notes')}
          className="input-field"
          data-testid="transport-notes-input"
          value={value}
          onChange={onChange}
          onBlur={onInputBlur}
          onKeyDown={onKeyDown}
        />
      ) : (
        <Tooltip title={value}>
          <div data-testid="transport-notes-body">
            {truncate(value, {
              length: 28,
            }).trim() || (
              <Link
                onClick={(e: { preventDefault: () => void }) => {
                  e.preventDefault()
                }}
              >
                {t('common.buttons.add', 'Add')}
              </Link>
            )}
          </div>
        </Tooltip>
      )}
    </Box>
  )
}

export default InlandTransportRowNotes

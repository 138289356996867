import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/users'
import * as actionCreators from '../../actionCreators/users'
import { simpleGetAndResolve, simplePutAndResolve } from '../factories'
import { userSerializer } from '../../../utils/serializers'

const getUsersData = simpleGetAndResolve(
  (action) => `/api/v1/users/${action.id}`,
  actionCreators.usersGetDataSuccess,
  userSerializer
)

const updateUsersData = simplePutAndResolve(
  (action) => `/api/v1/users/${action.id}`,
  actionCreators.usersGetDataSuccess,
  userSerializer
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.USERS_GET_DATA, getUsersData)
  yield takeEvery(actions.USERS_UPDATE_DATA, updateUsersData)
}

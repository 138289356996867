import { call, put, select } from 'redux-saga/effects'
import { parse } from 'query-string'
import { get } from 'lodash'
import { Method } from 'axios'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { rejectAction, resolveAction } from '../wrappers'
import { requester, Logger } from '../../../utils'

const getSearch = (state: IGlobalState) =>
  get(state.router, 'location.search', '')

export default function* (action: IPromisifiedAction): Iterator<any> {
  try {
    const searchString: string | undefined = yield select(getSearch)
    const search: any = parse(searchString || '')
    const response = yield call([requester, 'request'], {
      headers: {
        uid: search.uid,
        'access-token': search['access-token'],
        client: search.client,
      },
      method: 'PUT' as Method,
      data: {
        password: action.payload.password,
        password_confirmation: action.payload.password_confirmation,
      },
      url: '/api/auth/password',
    })
    yield put(
      showNotification({
        message: get(response, 'data.message', 'Password successfully changed'),
        severity: 'success',
      })
    )
    yield resolveAction(action)
  } catch (error) {
    const defaultErrorMessage = 'Error occurred during password change'
    const unknownError: any = error
    yield Logger.sagaError(unknownError, 'store::user::submitUserData')
    yield rejectAction(action, error)
    const errors = get(error, 'response.data.errors.full_messages', [
      defaultErrorMessage,
    ])
    yield put(
      showNotification({
        message: errors?.[0] ?? defaultErrorMessage,
        severity: 'error',
      })
    )
  }
}

import { useSelector } from 'react-redux'
import { useController } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import { SelectChangeEvent } from '@mui/material'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import { ItemProps } from '../GoodsLoadTypeLCL.props'
import { countVolume, getDimensionValue, selectedType } from './helpers'

export const PackageTypeSelect = (props: ItemProps) => {
  const { cargo, onChange, dimensionsDisabled, index, ...otherProps } = props
  const { field, fieldState } = useController(otherProps)
  const { packageTypes } = useSelector((state: IGlobalState) => ({
    packageTypes: state.packageTypes.list,
  }))

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as number

    const selectedPackageType = selectedType(value, packageTypes)
    const length = getDimensionValue(selectedPackageType, 'default_length_mm')
    const width = getDimensionValue(selectedPackageType, 'default_width_mm')
    const height = getDimensionValue(selectedPackageType, 'default_height_mm')
    const volume = countVolume(width, height, length, cargo.quantity)

    onChange(cargo.id, 'total_volume_cbm', volume)
    onChange(cargo.id, 'package_type_id', value, index)
    onChange(cargo.id, 'length', length)
    onChange(cargo.id, 'width', width)
    onChange(cargo.id, 'height', height)

    field.onChange(value)

    dimensionsDisabled?.(false)
  }

  const options = packageTypes.map((type) => ({
    id: type.id,
    label: type.name,
  }))

  const { ref, ...fieldProps } = field

  return (
    <Grid item xs={2} pr={1.5} pl={1.5}>
      <SingleSelect
        {...fieldProps}
        value={cargo.package_type_id}
        options={options}
        onChange={handleChange}
        inputProps={{ ref }}
        error={Boolean(fieldState.error)}
        data-testid="cargo-type-field"
      />
    </Grid>
  )
}

import IconButton from '@mui/material/IconButton'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Tooltip, { TooltipProps } from '@mui/material/Tooltip'
import { makeStyles } from '@mui/styles'

interface InfoTooltipProps extends Omit<TooltipProps, 'children'> {
  fluid?: boolean
}

const useStyles = makeStyles((theme) => ({
  fluid: {
    maxWidth: 'none',
  },
  button: {
    paddingTop: 0,
    paddingBottom: 0,
    color: theme.palette.grey[500],
    '&:hover': {
      background: 'transparent',
      color: theme.palette.primary.main,
    },
  },
}))

export const InfoTooltip: React.FC<InfoTooltipProps> = (props) => {
  const { fluid = false, placement = 'top', ...tooltipProps } = props
  const classes = useStyles()
  return (
    <Tooltip
      {...tooltipProps}
      placement={placement}
      classes={{ tooltip: fluid ? classes.fluid : '' }}
    >
      <IconButton
        size="small"
        component="span"
        disableRipple={true}
        aria-label="more info"
        disableFocusRipple={true}
        classes={{
          root: classes.button,
        }}
      >
        <InfoOutlinedIcon fontSize="inherit" color="inherit" />
      </IconButton>
    </Tooltip>
  )
}

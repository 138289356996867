import { FunctionComponent, useMemo, useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  incotermsGetData,
  pickupsAndDeliveriesGetData,
  pickupsAndDeliveriesGetOne,
} from 'src/stores/actionCreators'

import { Box, Button } from '@mui/material'
import CreateRoundedIcon from '@mui/icons-material/CreateRounded'

import EditShipmentWindow from 'src/components/EditShipmentWindow'
import { permissionTo } from 'src/utils'
import { AvatarGroup } from 'src/stories/AvatarGroup'

const EditShipmentBlock: FunctionComponent = () => {
  const dispatch = useDispatch()

  const { selectedId, singleInlandTransport, filters } = useSelector(
    (state: IGlobalState) => ({
      selectedId: state.pickUpsAndDeliveries.selectedInlandTransportId,
      singleInlandTransport: state.pickUpsAndDeliveries.singleInlandTransport,
      filters: state.pickUpsAndDeliveries.filters,
    }),
    shallowEqual
  )

  const [openShipmentModal, setOpenShipmentModal] = useState<boolean>(false)

  const collaborators = useMemo(() => {
    return singleInlandTransport?.shipment?.collaborators.map(
      ({ name, organization_role_code, logo }) => ({
        alt: name,
        className: organization_role_code,
        src: logo,
      })
    )
  }, [singleInlandTransport])

  const onSave = (): void => {
    if (selectedId) {
      dispatch(pickupsAndDeliveriesGetOne(selectedId))
    }
    dispatch(pickupsAndDeliveriesGetData(filters))
  }

  const openEditShipment = (): void => {
    setOpenShipmentModal(true)
    dispatch(incotermsGetData())
  }

  const onCloseEditShipment = (): void => {
    setOpenShipmentModal(false)
  }

  const { t } = useTranslation()

  if (!singleInlandTransport) {
    return null
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'end' }} mt={1}>
      {permissionTo('shipments.shipment_collaborators.view') && (
        <Box mr={1}>
          <AvatarGroup avatars={collaborators || []} max={4} />
        </Box>
      )}
      {permissionTo('shipments.manage') && (
        <Button
          variant="outlined"
          onClick={openEditShipment}
          startIcon={<CreateRoundedIcon />}
        >
          {t('common.buttons.edit_shipment', 'Edit shipment')}
        </Button>
      )}
      <EditShipmentWindow
        open={openShipmentModal}
        shipment={singleInlandTransport?.shipment}
        onClose={onCloseEditShipment}
        fetchData={onSave}
      />
    </Box>
  )
}

export default EditShipmentBlock

import {
  FunctionComponent,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button, SelectChangeEvent } from '@mui/material'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import TextField from '@mui/material/TextField'
import FormLabel from 'src/stories/Lab/FormLabel'
import { promisifyAction } from '../../utils/'
import {
  organizationGetData,
  updateInvoiceAddress,
  addressesAddAddress,
  countriesGetCountries,
} from '../../stores/actionCreators'
import './styles.scss'
import {
  validationErrorsFor,
  fieldCannotBeBlank,
} from '../../pages/AddressBookEdit/utils'
import { Dialog, DialogContent } from '../../stories'

interface IProps {
  open: boolean
  address: IAddress | null
  handleClose: () => void
}

const validations = {
  name: [fieldCannotBeBlank],
  email: [fieldCannotBeBlank],
  address: [fieldCannotBeBlank],
  city: [fieldCannotBeBlank],
  country_id: [fieldCannotBeBlank],
  vat_number: [fieldCannotBeBlank],
  postal_code: [fieldCannotBeBlank],
}

const newAddress = {
  address: '',
  address_id: 0,
  city: '',
  comment: '',
  name: '',
  country_id: '',
  eori_number: '',
  postal_code: '',
  vat_number: '',
}

const EditOrganizationAddressModal: FunctionComponent<IProps> = (props) => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState<any>(newAddress)
  const [validationErrors, setValidationErrors] = useState({})

  const isFormValid = useMemo(
    () => Object.keys(validationErrors).length === 0,
    [validationErrors]
  )

  const organizationGetDataAsync = promisifyAction(
    dispatch,
    organizationGetData
  )
  const updateInvoiceAddressAsync = promisifyAction(
    dispatch,
    updateInvoiceAddress
  )
  const addressesAddAddressAsync = promisifyAction(
    dispatch,
    addressesAddAddress
  )

  const { countries } = useSelector((state: IGlobalState) => ({
    countries: state.countries.list.map((country) => ({
      id: country[1],
      label: country[0],
    })),
  }))

  const onChange = (value: string | number, field: string) => {
    setFormData({ ...formData, [field]: value })
  }

  const textFieldProps = (name: string) => ({
    value: formData[name] || '',
    onChange,
    error: !!validationErrors[name],
    helperText: validationErrors[name],
  })

  useEffect(() => {
    dispatch(countriesGetCountries())
  }, [])

  useEffect(() => {
    if (props.address) {
      setFormData({
        address: props.address.address,
        address_id: props.address.id,
        city: props.address.city,
        comment: props.address.comment,
        name: props.address.name,
        country_id: props.address.country.id,
        eori_number: props.address.eori_number,
        postal_code: props.address.postal_code,
        vat_number: props.address.vat_number,
      })
    }
  }, [props.address])

  const onSave = useCallback(async () => {
    if (!formData.address_id) {
      addressesAddAddressAsync(formData)
    } else {
      await updateInvoiceAddressAsync(formData)
    }
    await organizationGetDataAsync()
    props.handleClose()
  }, [formData])

  useEffect(() => {
    setValidationErrors(validationErrorsFor(formData, validations))
  }, [formData])

  const renderInput = useCallback(
    (
      label: string,
      field: string,
      disabled: boolean = false
    ): React.ReactNode => {
      const { error, value, onChange, helperText = '' } = textFieldProps(field)
      const onInputChange = (event) => {
        onChange(event.target.value, field)
      }
      return (
        !disabled && (
          <div data-testid={`edit-organization-form-${field}`}>
            <FormLabel label={label} />
            <TextField
              fullWidth
              value={value}
              size="small"
              variant="outlined"
              name={field}
              required
              error={error}
              helperText={helperText}
              onChange={onInputChange}
            />
          </div>
        )
      )
    },
    [formData, validationErrors]
  )

  // if vat validate all else ignore vat error
  const disableSaveButton =
    (!!(props.address && props.address.vat_number) && !isFormValid) ||
    (!isFormValid &&
      Object.keys(validationErrors).length === 1 &&
      !!validationErrors['vat_number'])

  const { t } = useTranslation()

  const onCountryChange = (event: SelectChangeEvent<unknown>) =>
    onChange(event.target.value as string, 'country_id')

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      title={t('organization_settings.edit_address.header', 'Edit address')}
      actions={
        <>
          <Button variant="text" onClick={props.handleClose}>
            {t('common.buttons.cancel', 'Cancel')}
          </Button>
          <Button
            variant="contained"
            disabled={disableSaveButton}
            onClick={onSave}
          >
            {t('common.save', 'Save')}
          </Button>
        </>
      }
    >
      <DialogContent>
        <div
          className="organization-address-form"
          data-testid="edit-organization-form"
        >
          <div className="organization-address-form__side">
            <div className="mb-16">
              {renderInput(
                t('address_book.add_address.company_name', 'Name'),
                'name'
              )}
            </div>
            <div className="mb-16">
              {renderInput(
                t('address_book.add_address.street', 'Address'),
                'address'
              )}
            </div>
            {renderInput(
              t('address_book.add_address.postal_code_label', 'Postal code'),
              'postal_code'
            )}
          </div>
          <div className="organization-address-form__side">
            <div className="mb-16">
              {renderInput(t('address_book.add_address.city', 'City'), 'city')}
            </div>
            <div className="field-group input-field">
              <SingleSelect
                data-testid="edit-organization-form-country"
                value={formData.country_id}
                label={t('address_book.connections.country', 'Country')}
                options={countries}
                error={!!validationErrors['country_id']}
                onChange={onCountryChange}
              />
            </div>
            {renderInput(
              t('address_book.add_addess.vat_label', 'VAT number'),
              'vat_number',
              !!(props.address && props.address.vat_number)
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
export default EditOrganizationAddressModal

import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { ChipProps } from '@mui/material'
import OverviewTableShipmentColoredCell from './OverviewTableShipmentColoredCell'

const getStatusTextAndColor = (
  t: TFunction<'translation', undefined>,
  isPortbaseEnabled: boolean,
  isPodDocumentStatusPresent: boolean
): {
  text: string
  color: ChipProps['color']
} => {
  if (!isPortbaseEnabled) {
    return {
      text: t('vessels_page.customs_status.no_information', 'No information'),
      color: 'default',
    }
  }
  if (!isPodDocumentStatusPresent) {
    return {
      text: t('vessels_page.customs_status.missing', 'Documents missing'),
      color: 'warning',
    }
  }
  return {
    text: t('vessels_page.customs_status.available', 'Documents available'),
    color: 'success',
  }
}

const OverviewTableContainerCustomsStatus = ({ row }) => {
  const { t } = useTranslation()
  const status = getStatusTextAndColor(
    t,
    row.original?.portbase_enabled,
    row.original?.pod_customs_documents_present
  )

  return (
    <OverviewTableShipmentColoredCell color={status.color} text={status.text} />
  )
}

export default OverviewTableContainerCustomsStatus

import { IColumnHeader } from 'src/components/Common/Table/StripedTableHeader'

export const tableHeaders: IColumnHeader[] | {}[] = [
  {
    name: '',
    sortable: false,
    sortValue: '',
    customClassName: 'short-70',
  },
  {
    name: 'NAME',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned wide',
  },
  {
    name: 'EMAIL',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned s-wide',
  },
  {
    name: 'LAST ACTIVE',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned wide',
  },
  {},
  {},
  {},
  {},
  {},
]

import { ChangeEvent, useRef, forwardRef } from 'react'
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'

interface CustomTextAreaProps extends TextareaAutosizeProps {
  endAdornment?: React.ReactNode
  startAdornment?: React.ReactNode
  error?: string
}

const useStyles = makeStyles((theme) => ({
  textArea: {
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    minHeight: 40,
    padding: '0 12px',
    boxSizing: 'border-box',
    alignItems: 'center',
    border: ({ error }: { error: boolean }) =>
      error
        ? `1px solid ${theme.palette.error.main}`
        : `1px solid ${theme.palette.grey[400]}`,

    '&:hover': {
      borderColor: theme.palette.grey[600],
    },
    '&:focus-within': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-error': {
      border: `1px solid ${theme.palette.error.main}`,
    },
    '&.Mui-disabled': {
      border: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  textAreaInput: {
    width: '100%',
    fontSize: 15,
    padding: '8px 0 8px',
    fontFamily: theme.typography.fontFamily,
    border: 'none',
    resize: 'none',
    outline: 'none',
    color: theme.palette.primary.main,
    '&::placeholder': {
      color: theme.palette.grey[600],
    },
  },
  inputLabel: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: 0,
  },
  inputLabelWrapper: {
    margin: '0 0 8px',
    lineHeight: 0,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  afterLabel: {
    marginLeft: 'auto',
  },
  endAdornment: {
    marginLeft: theme.spacing(1),

    display: 'flex',
    '&>*': {
      margin: 0,
    },
  },
  startAdornment: {
    marginRight: theme.spacing(1),
    marginBottom: 'auto',
    marginTop: theme.spacing(1.25),
    '&>*': {
      margin: 0,
    },
  },
}))

const CustomTextArea = forwardRef(
  (props: CustomTextAreaProps, ref: React.Ref<HTMLTextAreaElement>) => {
    const {
      startAdornment: before,
      onChange,
      endAdornment: after,
      ...rest
    } = props
    const classes = useStyles({ error: !!props.error })
    const _onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      if (props.onChange && props.name) props.onChange(event)
    }

    return (
      <div className={`${classes.textArea} ${props.className}`}>
        {before && <div className={classes.startAdornment}>{before}</div>}
        <TextareaAutosize
          {...rest}
          className={classes.textAreaInput}
          ref={ref}
          onChange={_onChange}
        />
        {after && <div className={classes.endAdornment}>{after}</div>}
      </div>
    )
  }
)

export interface TextAreaProps extends CustomTextAreaProps {
  id?: string
  label?: React.ReactNode
  afterLabel?: React.ReactNode
  startAdornment?: (
    ref: React.RefObject<HTMLTextAreaElement>
  ) => React.ReactNode
  endAdornment?: (ref: React.RefObject<HTMLTextAreaElement>) => React.ReactNode
  maxLength?: number
  name: string
  value: string
  placeholder?: string
  error?: string
  isLoading?: boolean
}

export const TextArea = (props: TextAreaProps) => {
  const classes = useStyles({ error: !!props.error })
  const ref = useRef<HTMLTextAreaElement>(null)

  const { startAdornment, endAdornment, afterLabel, isLoading, ...rest } = props

  return (
    <>
      {(props.label || afterLabel) && (
        <div className={classes.inputLabelWrapper}>
          {props.label && (
            <InputLabel htmlFor={props.id} className={classes.inputLabel}>
              {props.label}
            </InputLabel>
          )}
          {afterLabel && <div className={classes.afterLabel}>{afterLabel}</div>}
        </div>
      )}
      {isLoading && (
        <Box height={32} width="100%" className="text-placeholder light-grey" />
      )}
      {!isLoading && (
        <CustomTextArea
          ref={ref}
          startAdornment={startAdornment && startAdornment(ref)}
          endAdornment={endAdornment && endAdornment(ref)}
          {...rest}
        />
      )}
      {props.error && <FormHelperText error>{props.error}</FormHelperText>}
    </>
  )
}

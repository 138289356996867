import { FunctionComponent, useCallback } from 'react'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Map from 'src/components/Map'

interface IProps {
  onClose: () => void
  markers: any
}

const ShipmentOverviewMapModal: FunctionComponent<IProps> = (props) => {
  const onClose = useCallback(() => {
    props.onClose()
  }, [props.onClose])

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="lg">
      <div
        style={{
          position: 'absolute',
          right: '20px',
          top: '20px',
          zIndex: 1,
          color: 'white',
          fontSize: '24px',
          cursor: 'pointer',
        }}
        className="close-icon-container"
        onClick={onClose}
      >
        <i className="icon shrink2 close-map-modal" />
      </div>
      <Box sx={{ height: '80vh ' }}>
        <Map markers={props.markers} />
      </Box>
    </Dialog>
  )
}

export default ShipmentOverviewMapModal

import { put } from 'redux-saga/effects'
import { ActionCreator, AnyAction } from 'redux'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { serverErrors } from '../../../utils/helpers'
import { authorizedRequest, rejectAction, resolveAction } from '../wrappers'
import { Logger } from '../../../utils'
import { reportTimeout } from './'

// Factory for standartized PATCH request. Action should have payload which is compatible with
// server API. Resolves with updated data, which can be serialized
export default function (
  makeUrl: (action: AnyAction) => string,
  actionCreator: ActionCreator<AnyAction>,
  serializer?: IResponseSerializer,
  emitMessage?: boolean
): ISaga1<IPromisifiedAction> {
  return function* (action: IPromisifiedAction): Iterator<any> {
    try {
      const response: any = yield authorizedRequest({
        method: 'PATCH',
        data: action.payload,
        url: makeUrl(action),
      })
      if (serializer) {
        yield put(actionCreator(serializer(response, action)))
      }
      yield resolveAction(action, response.data)
      if (emitMessage) {
        yield put(showNotification({ message: response, severity: 'success' }))
      }
    } catch (error) {
      const unknownError: any = error
      yield Logger.sagaError(unknownError, 'store::user::submitUserData')
      if (unknownError.code === 'ECONNABORTED') {
        yield reportTimeout(makeUrl, action, 'PATCH')
      }
      yield* serverErrors(unknownError)

      yield rejectAction(action, error)
      if (emitMessage) {
        yield put(
          showNotification({
            message: unknownError.response.data.message,
            severity: 'error',
          })
        )
      }
    }
  }
}

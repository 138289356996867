import { Typography } from '@mui/material'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import DatePicker from 'src/stories/DatePicker'
import Input from 'src/components/Common/Input/MuiInput'

import './styles.scss'

interface IProps {
  shipment: IDetailedShipment
  purchaseOrders: IPurchaseOrder[]
  formData: any
  onFormDataChange: (obj) => void
}

const ConfirmationStep: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const shipmentReference = () =>
    props.shipment.reference_number
      ? `${props.shipment.title} | ${props.shipment.reference_number}`
      : props.shipment.title

  const onFormDataChange = (poId, field, value) => {
    const newFormData = { ...props.formData }
    newFormData[poId][field] = value
    props.onFormDataChange(newFormData)
  }

  return (
    <div className="confirmation-step">
      <div className="confirmation-step__container">
        <div>
          <Typography variant="h5">
            {t(
              'purchase_order.modals.assign_order_to_shipment.confirmation_step.shipment_details.title',
              'Shipment details'
            )}
          </Typography>
        </div>

        <div className="confirmation-step__container__body">
          <div className="confirmation-step__container__body-row">
            <div className="confirmation-step__block-shipment_details">
              <div className="confirmation-step__block-header">
                {t(
                  'purchase_order.modals.assign_order_to_shipment.confirmation_step.shipment_details.shipment_reference',
                  'Shipment reference'
                )}
              </div>
              <div className="confirmation-step__block-content">
                {shipmentReference()}
              </div>
            </div>
            <div className="confirmation-step__block-shipment_details">
              <div className="confirmation-step__block-header">
                {t(
                  'purchase_order.modals.assign_order_to_shipment.confirmation_step.shipment_details.shared_reference',
                  'Shared reference'
                )}
              </div>
              <div className="confirmation-step__block-content">
                {shipmentReference()}
              </div>
            </div>
          </div>
          <div className="confirmation-step__container__body-row">
            <div className="confirmation-step__block-shipment_details">
              <div className="confirmation-step__block-header">
                {t(
                  'purchase_order.modals.assign_order_to_shipment.confirmation_step.shipment_details.pol',
                  'Port of loading'
                )}
              </div>
              <div className="confirmation-step__block-content">
                {props.shipment.loading_port}
              </div>
            </div>
            <div className="confirmation-step__block-shipment_details">
              <div className="confirmation-step__block-header">
                {t(
                  'purchase_order.modals.assign_order_to_shipment.confirmation_step.shipment_details.pod',
                  'Port of destination'
                )}
              </div>
              <div className="confirmation-step__block-content">
                {props.shipment.discharge_port}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="confirmation-step__container">
        <div>
          <Typography variant="h5">
            {t(
              'purchase_order.modals.assign_order_to_shipment.confirmation_step.booking_details.title',
              'Booking details'
            )}
          </Typography>
        </div>

        <div className="confirmation-step__container__body">
          {props.purchaseOrders.map((po, index) => (
            <div className="confirmation-step__container__body-row" key={index}>
              <div className="confirmation-step__block-purchase-order shipper">
                <div className="confirmation-step__block-header">Shipper</div>
                <div className="confirmation-step__block-content">
                  {po.seller.address?.name}
                </div>
              </div>
              <div className="confirmation-step__block-purchase-order cargo-ready">
                <div className="confirmation-step__block-header">
                  {t(
                    'purchase_order.modals.assign_order_to_shipment.confirmation_step.booking_details.cargo_ready_date',
                    'Cargo ready date'
                  )}
                </div>
                <div className="confirmation-step__block-content">
                  <DatePicker
                    clearable={false}
                    value={props.formData[po.id]?.cargo_ready_date}
                    onChange={(value) => {
                      onFormDataChange(
                        po.id,
                        'cargo_ready_date',
                        value?.toISO() || ''
                      )
                    }}
                  />
                </div>
              </div>
              <div className="confirmation-step__block-purchase-order reference">
                <div className="confirmation-step__block-header">
                  {t(
                    'purchase_order.modals.assign_order_to_shipment.confirmation_step.booking_details.booking_reference',
                    'Booking reference'
                  )}
                </div>
                <div className="confirmation-step__block-content">
                  <Input
                    name="reference"
                    data-testid="booking_reference"
                    placeholder={t(
                      'purchase_order.modals.assign_order_to_shipment.confirmation_step.booking_details.add_reference',
                      'Add reference'
                    )}
                    className="input-field"
                    value={props.formData[po.id]?.booking_reference || ''}
                    onChange={(newValue) => {
                      onFormDataChange(po.id, 'booking_reference', newValue)
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ConfirmationStep

import { AnyAction } from 'redux'
import * as actions from '../actions/connections'

export const linkAddressToConnection = (
  id: number,
  payload: any
): AnyAction => ({
  type: actions.LINK_ADDRESS_TO_CONNECTION,
  id,
  payload,
})

export const getAllConnections = (payload?: any): AnyAction => ({
  type: actions.GET_ALL_CONNECTIONS,
  payload,
})

export const getAllConnectionsSuccess = (payload: any): AnyAction => ({
  type: actions.GET_ALL_CONNECTIONS_SUCCESS,
  payload,
})

export const getConnection = (id: number): AnyAction => ({
  type: actions.GET_SINGLE_CONNECTION,
  id,
})

export const getConnectionSuccess = (payload: any): AnyAction => ({
  type: actions.GET_SINGLE_CONNECTION_SUCCESS,
  payload,
})

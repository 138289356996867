import { ShipmentQuotationStatusEnum, ModalityEnum } from 'src/config/constants'

import { getDateIsExpired } from 'src/utils/helpers/shipmentDate'

export const getBookButtonVisible = (
  modality: ModalityEnum,
  status: ShipmentQuotationStatusEnum,
  expirationDate: string
) => {
  const quoteExpired = getDateIsExpired(expirationDate)
  const quoteIsOpen = status === ShipmentQuotationStatusEnum.Open
  const modalityIsAir = modality === ModalityEnum.Air
  return !modalityIsAir || (!quoteExpired && quoteIsOpen)
}

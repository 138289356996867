import { AnyAction } from 'redux'
import * as actions from '../actions/addresses'

declare global {
  interface IAddressesProps {
    organization_id?: number
    page_size?: number
    page?: string | number
    address_types?: string[]
    search?: string
  }
}

const addressesDefaults = {
  page_size: 20,
  page: 1,
}

export const addressesGetData = (payload?: IAddressesProps): AnyAction => ({
  type: actions.ADDRESSES_GET_DATA,
  payload,
})

export const addressesGetDataSuccess = (
  payload: IAddressesProps
): AnyAction => ({
  type: actions.ADDRESSES_GET_DATA_SUCCESS,
  payload: { ...addressesDefaults, ...payload },
})

export const getOrganizationAddresses = (
  payload?: IAddressesProps
): AnyAction => ({
  type: actions.GET_ORGANIZATION_ADDRESSES,
  payload: { ...addressesDefaults, ...payload },
})

export const getOrganizationAddressesSuccess = (
  payload?: IAddressesProps
): AnyAction => ({
  type: actions.GET_ORGANIZATION_ADDRESSES_SUCCESS,
  payload,
})

export const addressesGetOneAddress = (id: number): AnyAction => ({
  type: actions.ADDRESSES_GET_ONE_ADDRESS,
  id,
})

export const addressesPutAddress = (
  id: number,
  payload: IAddress
): AnyAction => ({
  type: actions.ADDRESSES_PUT_ADDRESS,
  id,
  payload,
})

export const addressesGetOneAddressSuccess = (
  payload: IAddress
): AnyAction => ({
  type: actions.ADDRESSES_GET_ONE_ADDRESS_SUCCESS,
  payload,
})

export const addressesPutAddressSuccess = (payload: IAddress): AnyAction => ({
  type: actions.ADDRESSES_PUT_ADDRESS_SUCCESS,
  payload,
})

export const addressesAddAddress = (payload: IAddress): AnyAction => ({
  type: actions.ADDRESSES_ADD_ADDRESS,
  payload,
})

export const addressesAddAddressSuccess = (payload: IAddress): AnyAction => ({
  type: actions.ADDRESSES_ADD_ADDRESS_SUCCESS,
  payload,
})

export const addressesDeleteAddress = (id: string | number): AnyAction => ({
  type: actions.ADDRESSES_DELETE_ADDRESS,
  id,
})

export const addressesDeleteAddressSuccess = (
  payload: IAddress
): AnyAction => ({
  type: actions.ADDRESSES_DELETE_ADDRESS_SUCCESS,
  payload,
})

export const setDefaultAddress = (payload: IAddress): AnyAction => ({
  type: actions.SET_DEFAULT_ADDRESS,
  payload,
})

export const setDefaultAddressSuccess = (payload: IAddress): AnyAction => ({
  type: actions.SET_DEFAULT_ADDRESS_SUCCESS,
  payload,
})

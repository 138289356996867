import Search from '@mui/icons-material/Search'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useState } from 'react'
import FormLabel from 'src/stories/Lab/FormLabel'
import { useFilter } from '../hooks'
import { FilterInputsCommonPropsType } from '../types'
import FormElementIcon from './FormElementIcon'

export const TextInput = ({
  name,
  label,
  placeholder = '',
  ...props
}: FilterInputsCommonPropsType) => {
  const { onInputChange, filters } = useFilter()
  const inputRegisteredValue = filters[name] || ''
  const [inputTypedValue, setInputTypedValue] = useState(inputRegisteredValue)

  const debounceInputChange = useCallback(debounce(onInputChange, 1000), [
    onInputChange,
  ])

  const handleOnChange = (event: { target: { value: string } }) => {
    const { value } = event.target
    setInputTypedValue(value)
    debounceInputChange(name, value)
  }

  useEffect(() => {
    if (
      typeof inputRegisteredValue !== 'string' ||
      inputRegisteredValue === inputTypedValue
    )
      return

    setInputTypedValue(inputRegisteredValue)
  }, [inputRegisteredValue])

  return (
    <>
      <FormLabel label={label} />
      <StyledTextField
        fullWidth
        size="small"
        variant="outlined"
        onChange={handleOnChange}
        placeholder={placeholder}
        value={inputTypedValue}
        InputProps={{ endAdornment: <FormElementIcon Icon={Search} /> }}
        data-testid={props['data-testid']}
      />
    </>
  )
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    paddingRight: theme.spacing(1),
  },
}))

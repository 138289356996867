import { useController } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import Input, { NumberFormatCustom } from 'src/components/Common/Input/MuiInput'
import { ItemProps } from '../GoodsLoadTypeLCL.props'

export const TotalWeightInput = (props: ItemProps) => {
  const { cargo, onChange } = props
  const { field, fieldState } = useController(props)

  const handleChange = (value: string) => {
    onChange(cargo.id, 'weight', Number(value))
  }

  return (
    <Grid item xs={1.5} pr={1.5} pl={1.5}>
      <Input
        {...field}
        inputComponent={NumberFormatCustom as any}
        placeholder="0.00"
        onChange={(value) => {
          field.onChange(value)
          handleChange(value)
        }}
        value={cargo.weight}
        ref={null}
        error={!props.isClearError && Boolean(fieldState.error)}
        endAdornment={<div>kg</div>}
        data-testid="total-weight-field"
      />
    </Grid>
  )
}

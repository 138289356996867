import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Check, ListAlt } from '@mui/icons-material'
import { useFormContext } from 'react-hook-form'
import { MenuList, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Popover from '@mui/material/Popover'
import { MenuItemWithIcon } from 'src/stories/Lab/Menu/components/MenuItemWithIcon'
import TriggerButton from './Search/TriggerButton'
import { groups, groupingKey } from './constants'

const SearchGroup = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'display-button-label' : undefined

  const { getValues, setValue } = useFormContext()

  const grouping = getValues(groupingKey)

  const onMenuItemClick = (value: string | null) => {
    setValue(groupingKey, value)
    handleClose()
  }

  const selectedGroup = groups.find((group) => group.value === grouping)

  const { t } = useTranslation()

  return (
    <>
      <TriggerButton
        id={id}
        open={open}
        label={t('vessels_page.group_containers_by')}
        value={grouping ?? ''}
        onClick={handleClick}
        onReset={() => onMenuItemClick(null)}
        startIcon={<ListAlt />}
        endIcon={null}
        data-testid="search-group-trigger"
      >
        {grouping && selectedGroup && (
          <Typography children={`: ${t(selectedGroup.label)}`} />
        )}
      </TriggerButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        data-testid="search-group-popover"
      >
        <Box width={200}>
          <MenuList>
            {groups.map((group) => (
              <MenuItemWithIcon
                icon={group.value === grouping ? Check : undefined}
                text={t(group.label)}
                key={group.label}
                onClick={() => {
                  onMenuItemClick(group.value)
                }}
              />
            ))}
          </MenuList>
        </Box>
      </Popover>
    </>
  )
}

export default SearchGroup

/**
 * Helper functions for container types
 * backend always sends shipment_type (fcl, lcl, air)
 * but container_type only for fcl, for other
 * shipment_types, container_type is set to null
 */
import { reeferIcons } from 'src/pages/SearchAndBook/reeferIcons'

export const containerTypeNames = [
  '20_ft GP',
  '40_ft GP',
  '45_ft GP',
  '40_ft HC',
  '45_ft HC',
  '20_ft OT',
  '40_ft OT',
  '40_ft HC OT',
]

const containerTypeMap = {
  '20_ft': '20 ft',
  '40_ft': '40 ft',
  '40_ft_hc': '40 ft HC',
  '45_ft': '45 ft HC',
  '20_ot': '20 OT',
  '40_ot': '40 OT',
  '40_hc_ot': '40 HC OT',
  '20_rf': '20 RF',
  '40_rf': '40 RF',
  '40_hc_rf': '40 HC RF',
  fcl_mixed: 'FCL mixed',
  multiple: 'Multiple sizes',
  rail: 'train',
}

const containerIconMap = {
  '20_ft': 'standard-container-20ft',
  '40_ft': 'standard-container-40ft',
  '45_ft': 'HC-container-45ft',
  '45_ft_hc': 'HC-container-45ft',
  '40_ft_hc': 'HC-container-40ft',
  '20_ot': 'open-top-container-20ft',
  '40_ot': 'open-top-container-40ft',
  '40_hc_ot': 'HC-open-top-container-40ft',
  '20_rf': reeferIcons['20_rf'],
  '40_rf': reeferIcons['40_rf'],
  '40_hc_rf': reeferIcons['40_hc_rf'],
  fcl_mixed: 'standard-container-40ft',
  LCL: 'lcl',
  rail: 'train',
}

// provides a mapping from container_type to human-friendly description
function containerTypeDescription(
  shipmentType: string,
  containerType: string | null
): string {
  if (!shipmentType) {
    return '-'
  }
  if (containerType == null) {
    return shipmentType.toUpperCase()
  }

  if (shipmentType === 'rail' && containerType === 'multiple') {
    return shipmentType.toUpperCase()
  }
  if (containerType === 'Unknown') {
    return 'unknown'
  }

  if (!(containerType in containerTypeMap)) {
    return containerType
  }

  return containerTypeMap[containerType]
}

function containerTypeIcon(
  shipmentType: string,
  containerType: string | null
): string {
  if (!shipmentType) {
    return ''
  }
  if (shipmentType === 'air') {
    return 'airport'
  }
  if (shipmentType === 'lcl') {
    return 'lcl'
  }
  if (shipmentType === 'rail' && containerType === 'multiple') {
    return 'train'
  }
  if (containerType === 'unknown') {
    return 'standard-container-20ft'
  }

  if (containerType == null) {
    return shipmentType
  }

  if (!(containerType in containerIconMap)) {
    return containerType
  }

  return containerIconMap[containerType]
}

export { containerTypeDescription, containerTypeIcon }

import { Fragment, memo, useCallback, useRef } from 'react'
import { ModalityEnum } from 'src/config/constants'
import {
  MapContainer,
  Marker,
  TileLayer,
  Polyline,
  ZoomControl,
  Popup,
  FeatureGroup,
} from 'react-leaflet'
import L, { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import './index.scss'
import { useTheme } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import boatIconUrl from '../../assets/images/map_overview/boat_icon.svg'
import airIconUrl from '../../assets/images/map_overview/air_icon.svg'
import trainIconUrl from '../../assets/images/map_overview/train_icon.svg'
import startMarkerUrl from '../../assets/images/map_overview/start_marker.svg'
import endMarkerUrl from '../../assets/images/map_overview/end_marker.svg'
import MapTooltip from './MapTooltip'
import SmoothScroll from './SmoothScroll'

SmoothScroll(L)

const boatIcon = new Icon({
  iconUrl: boatIconUrl,
  iconSize: [32, 32],
})

const airIcon = new Icon({
  iconUrl: airIconUrl,
  iconSize: [32, 32],
})

const trainIcon = new Icon({
  iconUrl: trainIconUrl,
  iconSize: [32, 32],
})

const startMarker = new Icon({
  iconUrl: startMarkerUrl,
  iconSize: [16, 16],
})

const endMarker = new Icon({
  iconUrl: endMarkerUrl,
  iconSize: [16, 16],
})

const getIconBasedOnModality = (modality: ModalityEnum) => {
  if (modality === ModalityEnum.Sea) {
    return boatIcon
  }
  if (modality === ModalityEnum.Air) {
    return airIcon
  }
  return trainIcon
}

interface Props {
  markers: IShipmentTrack[]
  styles?: any
  disableLine?: boolean
}

const CustomMap = memo((props: Props) => {
  const { t } = useTranslation()
  const featureGroupRef: any = useRef()
  const theme = useTheme()

  const onMapReady = useCallback(
    (map) => {
      if (map !== null && props.markers.length > 0) {
        const bounds = L.latLngBounds(
          props.markers.reduce(
            (res, marker) => [...res, ...marker.route],
            [] as any
          )
        )
        map.fitBounds(bounds)
      }
    },
    [props.markers]
  )

  return (
    <MapContainer
      ref={onMapReady}
      attributionControl={false}
      center={[0, 0]}
      zoom={2}
      zoomControl={false}
      scrollWheelZoom={false}
      style={props.styles}
    >
      <ZoomControl
        ref={featureGroupRef}
        position="bottomright"
        zoomInTitle={t('common.maps.controls.zoom_in')}
        zoomOutTitle={t('common.maps.controls.zoom_out')}
      />
      <FeatureGroup>
        {props.markers.map((marker) => (
          <Fragment key={marker.shipment_code}>
            <Marker position={marker.route[0]} icon={startMarker} />
            <Marker
              position={marker.route[marker.route.length - 1]}
              icon={endMarker}
            />
            {!props.disableLine && (
              <Polyline
                pathOptions={{
                  color: theme.palette.primary.main,
                  dashArray: '5, 10',
                }}
                positions={marker.route.map((route) => [route.lat, route.lng])}
              />
            )}
            {marker.current_position &&
              marker.current_position.lat !== 0 &&
              marker.current_position.lng !== 0 && (
                <Marker
                  key={marker.shipment_code}
                  position={marker.current_position}
                  icon={getIconBasedOnModality(marker.type)}
                >
                  <Popup>
                    <MapTooltip shipments={marker.shipment_content.shipments} />
                  </Popup>
                </Marker>
              )}
          </Fragment>
        ))}
      </FeatureGroup>
      <TileLayer
        crossOrigin
        url="https://api.maptiler.com/maps/basic-v2/{z}/{x}/{y}.png?key=Mo18Hv7gYYAKwx9mN7wz"
      />
    </MapContainer>
  )
})

export default CustomMap

import { Stack } from '@mui/system'
import { Typography } from '@mui/material'
import AttachFileIcon from '@mui/icons-material/AttachFile'

interface NotificationsContentAttachmentsProps {
  attachments: INavigationNotificationCommentAttachment[]
}

const NotificationsContentListItemAttachments: React.FC<NotificationsContentAttachmentsProps> = ({
  attachments,
}) => {
  if (attachments.length === 0) {
    return null
  }
  return (
    <Stack mt={1.5} spacing={1.5}>
      {attachments.map(
        (attachment: INavigationNotificationCommentAttachment) => {
          return (
            <Stack
              spacing={0.5}
              direction="row"
              alignItems="center"
              key={attachment.id}
            >
              <AttachFileIcon
                fontSize="inherit"
                sx={{ transform: 'rotate(45deg)' }}
              />
              <Typography component="div" variant="subtitle1">
                {attachment.original_filename}
              </Typography>
            </Stack>
          )
        }
      )}
    </Stack>
  )
}

export default NotificationsContentListItemAttachments

import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import LinearProgress from '@mui/material/LinearProgress'
import { formatDesktopDate, FORMAT_SHORT_DATE } from 'src/utils/helpers'
import { ShipmentProgressProps } from '../shipmentContentProps'
import { AlignedStack, AlignedStackItem } from './ShipmentAlignedStack'

const useStyles = makeStyles((theme) => {
  const grey = theme.palette.grey[300]
  return {
    dashedProgress: {
      color: 'transparent',
      backgroundSize: '8px 4px, 100% 4px',
      backgroundImage: `linear-gradient(90deg, ${grey}, ${grey} 50%, transparent 50%, transparent 100%)`,
    },
  }
})

type DateProps = {
  date: string
}

export const ShipmentProgressDate: React.FC<DateProps> = ({ date }) => {
  return (
    <Typography
      variant="body1"
      noWrap={true}
      component="div"
      children={formatDesktopDate(date, FORMAT_SHORT_DATE)}
    />
  )
}

export const ShipmentProgress: React.FC<ShipmentProgressProps> = (props) => {
  const classes = useStyles()
  return (
    <AlignedStack justifyContent={props.align} direction="row" spacing={0}>
      <AlignedStackItem>
        <ShipmentProgressDate date={props.estimatedDeparture} />
      </AlignedStackItem>
      <LinearProgress
        variant="determinate"
        color={props.progressColor}
        value={props.progressPercentage}
        sx={{ mx: 1, width: 100, height: 4 }}
        classes={{
          root: !props.showProgress ? classes.dashedProgress : '',
        }}
      />
      <AlignedStackItem>
        <ShipmentProgressDate date={props.estimatedArrival} />
      </AlignedStackItem>
    </AlignedStack>
  )
}

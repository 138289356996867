import { createReducer, Reducer } from 'redux-create-reducer'
import { AnyAction } from 'redux'

import { LCL_FORM_DATA } from '../actions/lclForm'

declare global {
  interface ILCLForm {
    isValid: boolean | null
  }
}

export const initialLCLFormState: ILCLForm = {
  isValid: null,
}

const lclFormState: Reducer<ILCLForm, AnyAction> = (state, { payload }) => ({
  isValid: payload,
})

export default createReducer(initialLCLFormState, {
  [LCL_FORM_DATA]: lclFormState,
})

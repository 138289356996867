import { FormControlLabel, Switch } from '@mui/material'

type PropTypes = {
  label: string
  field: string | number
  value: boolean
  onChange: (field: PropTypes['field'], value: PropTypes['value']) => void
}

function CustomSwitchButton({ value, label, onChange, field }: PropTypes) {
  function onSwitchChange(event: React.ChangeEvent) {
    onChange(field, !value)
  }

  return (
    <FormControlLabel
      control={<Switch checked={value} onChange={onSwitchChange} />}
      label={label}
    />
  )
}

export default CustomSwitchButton

import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material'
import { ArrowBackRounded } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system'

export interface BreadcrumbItem {
  label: string | JSX.Element
  href?: string
  onClick?: () => void
}

export interface IBreadcrumbsProps {
  links: BreadcrumbItem[]
  current: string | JSX.Element
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'fit-content',
    color: theme.palette.action.active,
    margin: 0,
  },
  text: {
    color: theme.palette.action.active,
  },
  link: {
    color: theme.palette.action.active,
    textDecorationColor: theme.palette.action.active,
    '&:hover': {
      color: theme.palette.primary.main,
      textDecorationColor: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
}))

export const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({
  links = [],
  current,
}) => {
  const classes = useStyles()

  if (!links.length) return null

  const renderBreadcrumbItems = links.map(
    ({ href, label, onClick = () => {} }, idx) => (
      <Link
        href={href}
        underline="always"
        className={classes.link}
        onClick={onClick}
        key={idx}
      >
        <Typography variant="body1" color="inherit" component="div">
          {idx === 0 && <ArrowBackRounded color="inherit" />}
          {label}
        </Typography>
      </Link>
    )
  )

  return (
    <Box className={classes.wrapper} mb={3}>
      <MuiBreadcrumbs aria-label="breadcrumb" className={classes.text}>
        {renderBreadcrumbItems}
        <Typography variant="body1Strong" component="div">
          {current}
        </Typography>
      </MuiBreadcrumbs>
    </Box>
  )
}

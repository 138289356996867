import ApplicationFilterModal from 'src/components/ApplicationFilterModal'
import { useTranslation } from 'react-i18next'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import { StyledMenuItem } from 'src/components/TopNavigation/TopNavigation.styles'
import TopNavigationMenuItemContent from '../TopNavigationMenuItem/TopNavigationMenuItemContent'

const TopNavigationUserMenuFilterApplication = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <div>
      <StyledMenuItem
        onClick={onClick}
        data-testid="top-navigation-filter-application"
      >
        <TopNavigationMenuItemContent
          icon={<FilterListOutlinedIcon />}
          text={t(
            'top_navigation.user_menu.filter_applicaiton_button',
            'Filter application'
          )}
        />
      </StyledMenuItem>
      <ApplicationFilterModal />
    </div>
  )
}

export default TopNavigationUserMenuFilterApplication

import { FunctionComponent } from 'react'
import { times } from 'lodash'

import TextPlaceholder from 'src/components/Common/TextPlaceholder'

const BlankTimeline: FunctionComponent<{}> = () => {
  return (
    <div className="shipment-timeline-section--blank ml-20">
      {times(4, (x) => (
        <div className="ml-20 mb-20" key={x}>
          <TextPlaceholder size={7} color="dark-blue" className="mb-10" />
          <TextPlaceholder size={6} color="blue" className="mb-10" />
          <TextPlaceholder size={5} color="light-grey" className="mb-10" />
          <TextPlaceholder size={5} color="light-grey" className="mb-10" />
          <TextPlaceholder size={5} color="light-grey" className="mb-10" />
        </div>
      ))}
    </div>
  )
}

export default BlankTimeline

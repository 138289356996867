import { ReactNode, FunctionComponent } from 'react'

interface IProps {
  children: ReactNode
  theme?: string
}

const StripedTable: FunctionComponent<IProps> = (props) => {
  return (
    <div className={`striped-table ${props.theme}`}>
      <div className="striped-table--block">{props.children}</div>
    </div>
  )
}

export default StripedTable

import { useGetIncoterms } from 'src/services/Api/common'
import { useFormFieldChangeSideEffects } from '../../Form.hooks'
import SingleSelect from '../FormElements/SingleSelect'

const Incoterm = (props) => {
  const { fieldPath, label, required = false } = props

  const { onIncotermOrShipmentRoleChange } = useFormFieldChangeSideEffects()

  const { data: incoterm } = useGetIncoterms({
    refetchOnMount: false,
  })

  const options =
    incoterm?.map((incoterm) => ({
      label: incoterm.code,
      id: incoterm.code,
    })) ?? []

  return (
    <SingleSelect
      label={label}
      options={options}
      required={required}
      fieldPath={fieldPath}
      mandatory={false}
      onSingleSelectChange={onIncotermOrShipmentRoleChange}
      SelectDisplayProps={{
        'data-testid': 'shipment-template-incoterm-select',
      }}
    />
  )
}

export default Incoterm

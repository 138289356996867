import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Paper,
  InputLabel,
  Tooltip,
  Button,
  Grid,
  SelectChangeEvent,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { memo, useCallback, useMemo, useRef, useState } from 'react'
import { ModalityEnum, LoadTypeEnum } from 'src/config/constants'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon-business-days'
import DirectionsBoatRoundedIcon from '@mui/icons-material/DirectionsBoatRounded'
import FlightRoundedIcon from '@mui/icons-material/FlightRounded'
import TrainRoundedIcon from '@mui/icons-material/TrainRounded'
import { InfoTooltip, AutoCompleteSelect as SearchBar } from 'src/stories'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import SvgIcon from '@mui/material/SvgIcon'
import InputAdornment from '@mui/material/InputAdornment'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import { permissionTo, promisifyAction } from 'src/utils'
import { getTranslationForModality } from 'src/utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { getLinkedCustomers, lclFormGetState } from 'src/stores/actionCreators'
import Input, { NumberFormatCustom } from 'src/components/Common/Input/MuiInput'
import SABGoodsSelect from 'src/components/SearchAndBook/SABGoodsSelect'
import SABContainersSelect from 'src/components/SearchAndBook/SABContainersSelect'
import incoterm from 'src/assets/images/Incoterms-graphic.png'
import PortsSearch from 'src/components/PortsSearch'
import DatePicker from 'src/stories/DatePicker'
import { SearchState } from './SearchContainer.props'
import { getFirstAvailableDepartureDate } from './SearchContainer.utils'
import FCLIcon from './FCL.svg?react'
import LCLIcon from './LCL.svg?react'
import { tags, services, temperatureOptions } from './constants'

interface Props {
  url: string
  onSubmit: () => void
  onChange: (field: string, value: any) => void
  state: SearchState
  data: {
    roles: any
    incoterms: IIncoterm[]
  }
}

const SearchView = memo(
  (props: Props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const submitRef = useRef<any>(null)
    const getCustomers = promisifyAction(dispatch, getLinkedCustomers)
    const lclFormState = promisifyAction(dispatch, lclFormGetState)
    const [isSubmitBtnClicked, setSubmitBtnClicked] = useState<boolean>(false)
    const [disableSubmit, setDisableSubmit] = useState(false)

    const { isLCLFormValid } = useSelector((state: IGlobalState) => ({
      isLCLFormValid: state.lclForm.isValid,
    }))

    const temperatureSelectOptions = temperatureOptions.map((option) => ({
      ...option,
      label: t(option.label),
    }))

    const canSelectOrganization = permissionTo(
      'search_and_book.organization_select.all'
    )

    const tooltipContent = useCallback((title: string, fallback: string) => {
      return <div>{t(title, fallback)}</div>
    }, [])

    const submitValidForm = () => {
      setSubmitBtnClicked(false)
      setDisableSubmit(false)
      history.push(`/search?${props.url}`)
    }

    const handleSubmitForm = () => {
      if (props.state.load_type === LoadTypeEnum.lcl) {
        if (
          isLCLFormValid &&
          props.state.modality === ModalityEnum.Air &&
          props.state.temperature_setting !== null
        ) {
          submitValidForm()
        }
        if (isLCLFormValid && props.state.modality !== ModalityEnum.Air) {
          submitValidForm()
        }
        if (!isLCLFormValid) {
          setSubmitBtnClicked(true)
          lclFormState(false)
        }
        if (props.state.temperature_setting === null) {
          setDisableSubmit(true)
        }
      }

      if (props.state.load_type === LoadTypeEnum.fcl) {
        history.push(`/search?${props.url}`)
      }
    }

    const roleOptions = useMemo(() => {
      return props.data.roles.map((role) => ({
        id: role.id,
        label: role.name,
      }))
    }, [props.data.roles])

    const incotermOptions = useMemo(() => {
      return props.data.incoterms.map((incoterm) => ({
        id: incoterm.id,
        label: incoterm.code,
      }))
    }, [props.data.incoterms])

    const onRoleChange = (event: SelectChangeEvent<unknown>) => {
      const selectedRole = props.data.roles.find(
        (role) => role.id === event.target.value
      )
      props.onChange('role', selectedRole)
    }

    const onIncotermChange = (event: SelectChangeEvent<unknown>) => {
      const selectedIncoterm = props.data.incoterms.find(
        (role) => role.id === event.target.value
      )
      props.onChange('incoterm', selectedIncoterm)
    }

    const onTemperatureSettingsChange = (event: SelectChangeEvent<unknown>) => {
      const selectedTemperature = temperatureOptions.find(
        (role) => role.id === event.target.value
      )
      props.onChange('temperature_setting', selectedTemperature)
      setDisableSubmit(false)
    }

    const onIncotermRest = () => {
      props.onChange('incoterm', { id: '', name: '' })
    }

    return (
      <Paper elevation={0} sx={{ p: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={6} md={2} lg={2}>
                <InputLabel>
                  {t('search_and_book.booking_as', 'I am booking as')}
                </InputLabel>
                <SingleSelect
                  value={props.state.role?.id || ''}
                  options={roleOptions}
                  onChange={onRoleChange}
                  placeholder={t(
                    'search_and_book.select_role_placeholder',
                    'Select role'
                  )}
                  data-testid="select-role"
                  inputProps={{ 'data-testid': 'select-role-input' }}
                />
              </Grid>
              {canSelectOrganization && (
                <Grid item xs={12} md={2} lg={2}>
                  <SearchBar
                    id="customer"
                    data-testid="customer-select"
                    label={t('common.customer', 'Customer')}
                    getData={(search) =>
                      getCustomers({ search, role: 'customer' })
                    }
                    onChange={(newValue) => {
                      props.onChange('organization', newValue)
                    }}
                    value={props.state.organization}
                    placeholder={t(
                      'search_and_book.organization_placeholder',
                      'Select organization'
                    )}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={6}
                {...(canSelectOrganization ? { md: 'auto' } : { md: 10 })}
                lg="auto"
              >
                <InputLabel htmlFor="modality">
                  {t('common.modality', 'Modality')}
                </InputLabel>
                <ToggleButtonGroup
                  id="modality"
                  color="primary"
                  value={props.state.modality}
                  exclusive
                  onChange={(_, newValue: SearchState['modality']) => {
                    if (!newValue) return
                    props.onChange('modality', newValue)
                  }}
                >
                  <ToggleButton value="sea" data-testid="sea-button">
                    <DirectionsBoatRoundedIcon />
                    {t('common.sea', 'Sea')}
                  </ToggleButton>
                  <ToggleButton value="air" data-testid="air-button">
                    <FlightRoundedIcon />
                    {t('common.air', 'Air')}
                  </ToggleButton>
                  <ToggleButton value="rail" data-testid="rail-button">
                    <TrainRoundedIcon />
                    {t('common.rail', 'Rail')}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} md={6} lg xl={3}>
                <PortsSearch
                  name="from"
                  destination="from"
                  placeholder={t(
                    'search_and_book.port_search_placeholder',
                    'Search address book, port, etc.'
                  )}
                  modality={props.state.modality}
                  role={props.state.role}
                  label={t(
                    'search_and_book.from_port_search_label',
                    'From address (or port)'
                  )}
                  value={props.state.origin}
                  optionLabel={(option: IPlaceSuggestion) =>
                    option.secondary_text
                      ? `${option.main_text} - ${option.secondary_text}`
                      : option.main_text
                  }
                  onChange={(newValue) => {
                    props.onChange('origin', newValue)
                  }}
                  dataTestId="origin-port"
                />
              </Grid>
              <Grid item xs={12} md={6} lg xl={3}>
                <PortsSearch
                  name="to"
                  destination="to"
                  modality={props.state.modality}
                  role={props.state.role}
                  label={t(
                    'search_and_book.to_port_search_label',
                    'To address (or port)'
                  )}
                  value={props.state.destination}
                  placeholder={t(
                    'search_and_book.port_search_placeholder',
                    'Search address book, port, etc.'
                  )}
                  data-testid="destination-port"
                  optionLabel={(option: IPlaceSuggestion) =>
                    option.secondary_text
                      ? `${option.main_text} - ${option.secondary_text}`
                      : option.main_text
                  }
                  onChange={(newValue) => {
                    props.onChange('destination', newValue)
                  }}
                  dataTestId="destination-port"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={6} md={2} lg={2}>
                <InputLabel htmlFor="departure_date">
                  {getTranslationForModality(
                    props.state.modality,
                    'departure_date'
                  )}
                </InputLabel>
                <DatePicker
                  value={DateTime.fromJSDate(
                    props.state.departure_date
                  ).toISODate()}
                  onChange={(date) =>
                    props.onChange('departure_date', date?.toJSDate())
                  }
                  clearable={false}
                  minDate={getFirstAvailableDepartureDate(props.state.modality)}
                  data-testid="departure-date-picker"
                />
              </Grid>
              <Grid item xs={6} md={3} lg={1} sx={{ minWidth: 121 }}>
                <InputLabel>
                  <div className="incoterms-label">
                    {t('common.incoterm', 'Incoterm')}
                    <InfoTooltip
                      fluid={true}
                      placement="right-start"
                      title={
                        <img
                          width="700px"
                          src={incoterm}
                          height="auto"
                          alt="incoterms"
                        />
                      }
                    />
                  </div>
                </InputLabel>
                <SingleSelect
                  mandatory={false}
                  onReset={onIncotermRest}
                  value={props.state.incoterm?.id || ''}
                  options={incotermOptions}
                  onChange={onIncotermChange}
                  placeholder={t('common.select', 'Select')}
                  data-testid="incoterm-select"
                  inputProps={{ 'data-testid': 'incoterm-select-input' }}
                />
              </Grid>
              <Grid item lg="auto">
                <InputLabel>
                  {t(
                    'search_and_book.shipment_services',
                    'Shipment services (optional)'
                  )}
                </InputLabel>
                <FormControl<'input'>
                  component={'fieldset' as 'input'}
                  onChange={(e) =>
                    props.onChange('services', {
                      [e.target.value]: e.target.checked,
                    })
                  }
                >
                  <FormGroup aria-label="position" row>
                    {services(props.state.modality).map((service) => (
                      <Box
                        key={t(service.label)}
                        sx={{
                          '& .MuiFormControlLabel-root': {
                            width: 60,
                            textAlign: 'center',
                          },
                        }}
                      >
                        <Tooltip
                          title={tooltipContent(service.tooltip, service.value)}
                          placement="bottom"
                        >
                          <FormControlLabel
                            value={service.value}
                            checked={props.state.services[service.value]}
                            disableTypography
                            control={
                              <Checkbox
                                color="primary"
                                inputProps={{
                                  // @ts-ignore https://github.com/microsoft/TypeScript/issues/28960
                                  'data-testid': `${service.value}-service-checkbox`,
                                }}
                              />
                            }
                            disabled={service.disabled}
                            sx={{ margin: '0px 16px' }}
                            label={
                              <Typography variant="body2">
                                {t(service.label, service.value)}
                              </Typography>
                            }
                            labelPlacement="bottom"
                          />
                        </Tooltip>
                      </Box>
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>
              {props.state.services.insurance && (
                <Grid item xs={6} md={2} lg={2}>
                  <Input
                    inputComponent={NumberFormatCustom as any}
                    id="cif_value"
                    label={t(
                      'templates.shipment_details.cif_value',
                      'CIF value of goods'
                    )}
                    placeholder={t('common.enter_value', 'Enter value')}
                    startAdornment={
                      <InputAdornment position="start">€</InputAdornment>
                    }
                    inputProps={{ style: { textAlign: 'right' } }}
                    value={props.state.cif_value}
                    onChange={(value) => props.onChange('cif_value', value)}
                    data-testid="cif-value-input"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs="auto">
                <InputLabel htmlFor="load_type">
                  {t('common.load_type', 'Load type')}
                </InputLabel>
                <ToggleButtonGroup
                  id="load_type"
                  color="primary"
                  value={props.state.load_type}
                  exclusive
                  onChange={(_, newValue: SearchState['load_type']) => {
                    if (!newValue) return
                    props.onChange('load_type', newValue)
                  }}
                >
                  <ToggleButton
                    value="fcl"
                    disabled={props.state.modality === ModalityEnum.Air}
                    data-testid="fcl-button"
                  >
                    <SvgIcon>
                      <FCLIcon />
                    </SvgIcon>
                    {t('common.fcl', 'FCL')}
                  </ToggleButton>
                  <ToggleButton value="lcl" data-testid="lcl-button">
                    <SvgIcon>
                      <LCLIcon />
                    </SvgIcon>
                    {t('common.lcl', 'LCL')}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              {props.state.load_type === LoadTypeEnum.fcl && (
                <Grid item xs={6} md={4}>
                  <SABContainersSelect
                    onChange={props.onChange}
                    containers={props.state.containers}
                    dataTestId="containers-select"
                  />
                </Grid>
              )}
              {props.state.load_type === LoadTypeEnum.lcl && (
                <Grid item xs={6} md={4}>
                  <SABGoodsSelect
                    cargoArr={props.state.cargo}
                    name="lcl"
                    modality={props.state.modality}
                    changeState={(name, value) => {
                      props.onChange('cargo', value)
                    }}
                    dataTestId="goods-select"
                    onSubmit={handleSubmitForm}
                    formRef={submitRef}
                    isSubmited={isSubmitBtnClicked}
                  />
                </Grid>
              )}
              {props.state.modality === ModalityEnum.Air && (
                <Grid item>
                  <Grid container spacing={4}>
                    <Grid item width={270}>
                      <InputLabel htmlFor="load_type">
                        {' '}
                        {t('common.temperature', 'Temperature')}
                      </InputLabel>
                      <SingleSelect
                        value={props.state?.temperature_setting?.id || ''}
                        options={temperatureSelectOptions}
                        error={disableSubmit}
                        onChange={onTemperatureSettingsChange}
                        placeholder={t('common.select', 'Select')}
                        data-testid="temperature-select"
                        inputProps={{
                          'data-testid': 'temperature-select-input',
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs="auto">
                <InputLabel>&nbsp;</InputLabel>
                {tags.map((item) => {
                  if (item.load_type.includes(props.state.load_type)) {
                    return (
                      <FormControlLabel
                        key={item.value}
                        control={
                          <Checkbox
                            value={item.value}
                            checked={props.state.tags[item.value]}
                            onChange={(e) =>
                              props.onChange('tags', {
                                [e.target.value]: e.target.checked,
                              })
                            }
                          />
                        }
                        label={t(item.label, item.value)}
                      />
                    )
                  } else {
                    return null
                  }
                })}
              </Grid>
              <Grid item xs sx={{ textAlign: 'end' }}>
                <InputLabel>&nbsp;</InputLabel>
                <Button
                  type="submit"
                  sx={{ whiteSpace: 'nowrap' }}
                  onClick={handleSubmitForm}
                  variant="contained"
                  data-testid="best-routes"
                  form="hook-form"
                >
                  {t('search_and_book.show_results', 'Show results')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    )
  },
  (prev, next) => prev.url === next.url
)

export default SearchView

// @ts-nocheck
// FIXME

import { call, put } from 'redux-saga/effects'
import { Method } from 'axios'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { retrieveCredentials, resolveAction, rejectAction } from '../wrappers'
import { requester, Logger } from '../../../utils'

export default function* (action: IPromisifiedAction): Iterator<any> {
  const logo = action.logo
  try {
    const credentials: ICredentials | undefined = yield retrieveCredentials()
    const formData = new FormData()
    const scopedOrganization: IOrganization | null = JSON.parse(
      window.localStorage.getItem('scopedOrganization') || 'null'
    )

    formData.append('logo', logo, 'logo.png')
    yield call([requester, 'request'], {
      headers: {
        uid: credentials ? credentials!.uid : '',
        'access-token': credentials ? credentials!.accessToken : '',
        client: credentials ? credentials!.clientToken : '',
        'scoped-organization-id': scopedOrganization
          ? scopedOrganization.id
          : '',
        'Content-Type': 'multipart/form-data',
      },
      method: 'PATCH' as Method,
      url: '/api/v1/organizations/upload_logo',
      data: formData,
    })
    yield resolveAction(action)
  } catch (error) {
    yield Logger.sagaError(error, 'store::user::getUserData')
    yield rejectAction(action, error)
    yield put(showNotification({ message: error, severity: 'error' }))
  }
}

import { uniqBy } from 'lodash'
import { DateTime } from 'luxon'
import {
  AutocompleteOptionPropsOptionProps,
  InlandTransportRowInspectionTaskOption,
} from './InlandTransportRow.props'

export const getInlandTransportAddresses = (
  addresses: IInlandTransportAddress[],
  newSelection: AutocompleteOptionPropsOptionProps | null,
  service: string
) => {
  return addresses
    .map((address) => {
      const isTransportAddress = address.pickup_delivery
      const addressId = newSelection?.value ?? null
      return {
        id: address.id,
        address_type: service,
        pickup_delivery: isTransportAddress,
        address_id: isTransportAddress ? addressId : address.address_id,
      }
    })
    .filter((item) => !!item.address_id)
}

export const getAutocompleteSelectOptions = (
  selected: AutocompleteOptionPropsOptionProps | undefined | null,
  defaultOptions: AutocompleteOptionPropsOptionProps[],
  fetchedOptions: AutocompleteOptionPropsOptionProps[] | null
) => {
  if (!fetchedOptions) {
    return null
  }
  const previousOptions =
    selected &&
    defaultOptions &&
    defaultOptions.find((option) => option.value === selected.value)
      ? defaultOptions
      : []

  return uniqBy([...previousOptions, ...fetchedOptions], 'label')
}

export const findInspectionStatusByName = (
  tasks: InlandTransportRowInspectionTaskOption[],
  name: string
) => {
  return tasks.find(
    (taskItem: InlandTransportRowInspectionTaskOption) =>
      taskItem.name.toLocaleLowerCase().indexOf(name) > -1
  )
}

export const getInspectionDateText = (
  formattedDate: string,
  taskTypeCode: string
) => {
  if (['await', 'resolved'].includes(taskTypeCode)) {
    return '-'
  }

  if (formattedDate.length > 0) {
    return formattedDate
  }

  if (taskTypeCode === 'to_do') {
    return 'To be planned'
  }

  return '-'
}

export const getMergedDateTime = ({ date, time }) => {
  const newDate = new Date(date)
  newDate.setMinutes(time.getMinutes())
  newDate.setHours(time.getHours())
  newDate.setSeconds(0)
  return newDate
}

export const getPickupOrDeliveryDate = (
  address: IInlandTransportAddress | undefined,
  pickupDeliverDateTime: string | null
) => {
  if (address && address.datetime_from) {
    return DateTime.fromISO(address.datetime_from)
  }
  if (pickupDeliverDateTime) {
    return DateTime.fromISO(pickupDeliverDateTime)
  }
  return null
}

import Axios, { AxiosError } from 'axios'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import {
  IChangePasswordRequest,
  IChangePasswordResponse,
} from 'src/@types/endpoints/password'
import { getCredentials } from 'src/services/utils/http-credentials'
import endpoints from '../../endpoints'

const axios = Axios.create({
  timeout: 90000,
  baseURL: window.shyppleConfig.apiUrl + '/api',
  headers: {
    'Content-type': 'application/json',
    'Cache-Control': 'no-cache',
    ...getCredentials(),
  },
})

axios.interceptors.response.use(
  (res) => res.data,
  (error: AxiosError) => {
    return Promise.reject(error)
  }
)

export const useCreatePasswordChange = (
  options?: UseMutationOptions<
    IChangePasswordResponse,
    AxiosError,
    IChangePasswordRequest
  >
) => {
  const createPasswordChange = (payload: IChangePasswordRequest) => {
    const url = endpoints.changePassword

    return axios.post<any, IChangePasswordResponse>(
      url,
      JSON.stringify(payload)
    )
  }

  return useMutation(createPasswordChange, options)
}

import { AnyAction } from 'redux'
import * as actions from '../actions/dashboard'

export const fetchDashboardTaskManager = (payload: any): AnyAction => ({
  type: actions.FETCH_DASHBOARD_TASK_MANAGER,
  payload,
})

export const fetchDashboardTaskManagerSuccess = (payload: any): AnyAction => ({
  type: actions.FETCH_DASHBOARD_TASK_MANAGER_SUCCESS,
  payload,
})

export const fetchDashboardTasks = (payload: any): AnyAction => ({
  type: actions.FETCH_DASHBOARD_TASKS,
  payload,
})

export const fetchDashboardTasksSuccess = (payload: any): AnyAction => ({
  type: actions.FETCH_DASHBOARD_TASKS_SUCCESS,
  payload,
})

export const fetchDashboardPickupAndDelivery = (payload: any): AnyAction => ({
  type: actions.FETCH_DASHBOARD_PICKUP_AND_DELIVERY,
  payload,
})

export const fetchDashboardPickupAndDeliverySuccess = (
  page: number
): AnyAction => ({
  type: actions.FETCH_DASHBOARD_PICKUP_AND_DELIVERY_SUCCESS,
  page,
})

export const fetchDashboardUpdatedByYou = (payload?: any): AnyAction => ({
  type: actions.FETCH_DASHBOARD_UPDATED_BY_YOU,
  payload,
})

export const fetchDashboardUpdatedByYouSuccess = (payload: any): AnyAction => ({
  type: actions.FETCH_DASHBOARD_UPDATED_BY_YOU_SUCCESS,
  payload,
})

export const fetchDashboardUpdatedByOthers = (payload?: any): AnyAction => ({
  type: actions.FETCH_DASHBOARD_UPDATED_BY_OTHERS,
  payload,
})

export const fetchDashboardUpdatedByOthersSuccess = (
  payload: any
): AnyAction => ({
  type: actions.FETCH_DASHBOARD_UPDATED_BY_OTHERS_SUCCESS,
  payload,
})

export const fetchDashboardArriving = (payload: any): AnyAction => ({
  type: actions.FETCH_DASHBOARD_ARRIVING,
  payload,
})

export const fetchDashboardArrivingSuccess = (payload: any): AnyAction => ({
  type: actions.FETCH_DASHBOARD_ARRIVING_SUCCESS,
  payload,
})

export const fetchDashboardDeparting = (payload: any): AnyAction => ({
  type: actions.FETCH_DASHBOARD_DEPARTING,
  payload,
})

export const fetchDashboardDepartingSuccess = (payload: any): AnyAction => ({
  type: actions.FETCH_DASHBOARD_DEPARTING_SUCCESS,
  payload,
})

export const fetchDashboardRecentlyBooked = (payload: any): AnyAction => ({
  type: actions.FETCH_DASHBOARD_RECENTLY_BOOKED,
  payload,
})

export const fetchDashboardRecentlyBookedSuccess = (
  payload: any
): AnyAction => ({
  type: actions.FETCH_DASHBOARD_RECENTLY_BOOKED_SUCCESS,
  payload,
})

export const fetchDashboardSupplyChain = (payload: any): AnyAction => ({
  type: actions.FETCH_DASHBOARD_SUPPLY_CHAIN,
  payload,
})

export const fetchDashboardSupplyChainSuccess = (payload: any): AnyAction => ({
  type: actions.FETCH_DASHBOARD_SUPPLY_CHAIN_SUCCESS,
  payload,
})

export const fetchDashboardSupplyChainShipments = (
  payload: any
): AnyAction => ({
  type: actions.FETCH_DASHBOARD_SUPPLY_CHAIN_SHIPMENTS,
  payload,
})

export const fetchDashboardSupplyChainShipmentsSuccess = (
  payload: any
): AnyAction => ({
  type: actions.FETCH_DASHBOARD_SUPPLY_CHAIN_SHIPMENTS_SUCCESS,
  payload,
})

import { Box, Chip, Typography } from '@mui/material'
import { omit, startCase, isEmpty } from 'lodash'
import { useFilter } from '../hooks'

const ChipLabel = ({ partOne, partTwo }) => {
  return (
    <>
      <Typography component="span" mr={0.5}>
        {partOne}:
      </Typography>
      <Typography component="span" variant="body1Strong">
        {partTwo}
      </Typography>
    </>
  )
}

const isNumeric = (value) => {
  return /^-?\d+$/.test(value)
}

const MiniFilters = () => {
  const {
    defaultFiltersKeys,
    filters,
    updateFilters,
    miniFilterLabels,
  } = useFilter()

  const renderLabel = (key: string) => {
    return miniFilterLabels[key] || startCase(key)
  }

  const renderArrayValue = (arr) => {
    return arr.length === 1 && !isNumeric(arr[0])
      ? startCase(arr[0])
      : `(${arr.length})`
  }

  const renderValue = (key: string) => {
    return Array.isArray(filters[key])
      ? renderArrayValue(filters[key])
      : filters[key]
  }

  const onDelete = (key: string) => {
    updateFilters(omit(filters, key))
  }

  return (
    <Box
      sx={{
        '& .MuiChip-root': {
          marginRight: 1,
          marginTop: 1,
        },
      }}
    >
      {Object.keys(filters)
        .filter(
          (key) => !defaultFiltersKeys.includes(key) && !isEmpty(filters[key])
        )
        .map((key) => (
          <Chip
            sx={{ fontSize: '10px' }}
            key={key}
            label={
              <ChipLabel
                partOne={renderLabel(key)}
                partTwo={renderValue(key)}
              />
            }
            color="primary"
            variant="filled"
            onDelete={() => onDelete(key)}
          />
        ))}
    </Box>
  )
}

export default MiniFilters

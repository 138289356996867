import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import * as actions from '../actions/account'

declare global {
  interface IAccountState {
    token: string
  }
}

export const initialAccountState = {
  token: '',
}

const receiveToken: Reducer<IAccountState, AnyAction> = (state, action) => ({
  ...state,
  token: action.payload.api_token,
})

export default createReducer(initialAccountState, {
  [actions.GET_ACCESS_TOKEN_SUCCESS]: receiveToken,
  [actions.RESET_ACCESS_TOKEN_SUCCESS]: receiveToken,
})

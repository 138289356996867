import { Alert } from 'src/stories/Alert'
import { useTranslation } from 'react-i18next'
import { SearchContainerNotificationsProps } from './SearchContainer.props'

const SearchContainerNotifications: React.FC<SearchContainerNotificationsProps> = ({
  incotermServicesManipulated,
}) => {
  const { t } = useTranslation()
  return (
    <>
      {incotermServicesManipulated && (
        <Alert
          sx={{ mt: 2 }}
          color="info"
          data-testid="services-incoterm-mismatch-notification"
          message={t(
            'search_and_book.feedback_selected_services',
            'The selected services do not match the incoterm. Our ops team will confirm your service requirements with you.'
          )}
        />
      )}
    </>
  )
}

export default SearchContainerNotifications

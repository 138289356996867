import { createContext, useContext } from 'react'
import { RequestHandlingFormContextProps } from 'src/pages/RequestLocalHandling/Form.props'
import { VisibilityFormContextProps } from 'src/components/ShipmentVisibilityModal/Form.props'
import { TemplateFormContextProps } from './Form.props'

export const FormContext = createContext<
  | null
  | TemplateFormContextProps
  | VisibilityFormContextProps
  | RequestHandlingFormContextProps
>(null)

export const FormProvider = FormContext.Provider

export const useFormContext = () => {
  const context = useContext(FormContext)
  if (!context) {
    throw new Error('useFormContext must be used within a FormProvider')
  }
  return context
}

import Stack from '@mui/material/Stack'
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat'

import { FCLIcon } from 'src/svgIcons'
import { Typography } from '@mui/material'

const OverviewTableVesselsGroupedCell = ({ row, cell }) => {
  const count = row.original?.containers_count ?? null
  return (
    <Stack
      direction="row"
      className="grouped-cell"
      alignItems="center"
      spacing={1}
    >
      <Typography
        variant="h6"
        component="div"
        sx={{ display: 'flex', alignItems: 'center' }}
        children={<DirectionsBoatIcon fontSize="inherit" />}
      />
      <Stack direction="column" spacing={0.5} alignItems="start">
        <Typography variant="body1Strong">{cell.getValue()}</Typography>
        {count && (
          <Stack
            direction="row"
            spacing={0.5}
            sx={{ backgroundColor: 'grey.200', px: 1, borderRadius: '8px' }}
          >
            <Typography
              variant="overline"
              component="span"
              color="grey.800"
              sx={{ display: 'flex', alignItems: 'center' }}
              children={<FCLIcon fontSize="inherit" />}
            />
            <Typography
              variant="overline"
              component="span"
              color="grey.800"
              children={count}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}

export default OverviewTableVesselsGroupedCell

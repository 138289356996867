import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  ICreateFilterRequest,
  ICreateFilterResponse,
  IDeleteFilterResponse,
  ISaveFiltersRequest,
  ISaveFiltersResponse,
  IUpdateFilterRequest,
  IUpdateFilterResponse,
} from 'src/@types/endpoints/other'
import { apiClient } from 'src/services/http-common'
import endpoints from '../endpoints'

export const useUpdateSavedFilters = (
  options?: UseMutationOptions<
    ISaveFiltersResponse,
    AxiosError,
    ISaveFiltersRequest
  >
) => {
  const updateSaveFilters = (payload: ISaveFiltersRequest) => {
    const url = endpoints.savedFiltersBulkUpdate

    return apiClient.put<any, ISaveFiltersResponse>(
      url,
      JSON.stringify(payload)
    )
  }

  return useMutation(updateSaveFilters, options)
}

export const useCreateNewFilter = (
  options?: UseMutationOptions<
    ICreateFilterResponse,
    AxiosError,
    ICreateFilterRequest
  >
) => {
  const createNewFilter = (payload: ICreateFilterRequest) => {
    const url = endpoints.savedFilters

    return apiClient.post<any, ICreateFilterResponse>(
      url,
      JSON.stringify(payload)
    )
  }

  return useMutation(createNewFilter, options)
}

export const useUpdateSavedFilter = (
  filterId: number | null,
  options?: UseMutationOptions<
    IUpdateFilterResponse,
    AxiosError,
    IUpdateFilterRequest
  >
) => {
  const updateSavedFilter = (payload: IUpdateFilterRequest) => {
    const url = `${endpoints.savedFilters}/${filterId}`

    return apiClient.put<any, IUpdateFilterResponse>(
      url,
      JSON.stringify(payload)
    )
  }

  return useMutation(updateSavedFilter, options)
}

export const useDeleteSavedFilter = (
  options?: UseMutationOptions<IDeleteFilterResponse, AxiosError, number | null>
) => {
  const deleteSavedFilter = (filterId: number | null) => {
    const url = `${endpoints.savedFilters}/${filterId}`
    return apiClient.delete<any, IDeleteFilterResponse>(url)
  }

  return useMutation(deleteSavedFilter, options)
}

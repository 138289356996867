import { ShipmentLegs } from 'src/stories'

interface SailingInformationProps {
  shipmentLegs: IShipmentLeg[]
}

const SailingInformation: React.FC<SailingInformationProps> = (props) => {
  const { shipmentLegs = [] } = props
  return <ShipmentLegs align="center" legs={shipmentLegs} />
}

export default SailingInformation

import { FunctionComponent } from 'react'

import { Link } from 'react-router-dom'
import { Box, Link as MuiLink, Tooltip } from '@mui/material'
import TravelExplore from '@mui/icons-material/TravelExplore'
import { compact } from 'lodash'
import ProgressWithDays from 'src/components/ProgressWithDays'
import { convertEstimatedDate } from '../../../utils/helpers'

import { progressColors } from '../progressColors'
import './styles.scss'

const MessageCenterShipmentBlock: FunctionComponent<{ comment: IComment }> = ({
  comment,
}) => {
  const group: string[] = progressColors[comment.shipment.status_group] || [
    'grey',
    'grey',
  ]

  return (
    <div className="message-center-item-shipment">
      <MuiLink
        variant="body1"
        sx={{ textDecoration: 'none' }}
        component={Link}
        to={`/shipments/${comment.shipment_id}/chats/${comment.chat_type}?#${comment.id}`}
      >
        <div className="message-center-item-shipment-head">
          <div className="message-center-item-shipment-main">
            <Box
              sx={{ display: 'flex', alignItems: 'center' }}
              className="message-center-item-shipment-main-head"
            >
              {comment.shipment.visibility_only && (
                <Box mt={1}>
                  <Tooltip title="This is a shipment for which Shypple provides visibility and collaboration but no shipping services.">
                    <TravelExplore color="info" />
                  </Tooltip>
                </Box>
              )}
              <span>{comment.shipment_title}</span> |{' '}
              {compact([
                comment.shipment.reference_number,
                comment.shipment.shared_reference,
              ]).join(' | ')}
            </Box>
          </div>
          {comment.shipment.flagged ? (
            <i className="icon flag red mr-15" />
          ) : (
            ''
          )}
        </div>
        <div className="message-center-item-shipment-info">
          <div className="message-center-item-shipment-start">
            <div className="message-center-item-shipment-date">
              {convertEstimatedDate(comment.shipment.estimated_departure)}
            </div>
            <div className="message-center-item-shipment-port">
              {comment.shipment.loading_port}
            </div>
          </div>
          <div className="message-center-item-shipment-progress">
            <ProgressWithDays
              departureIcon={
                comment.shipment.shipment_type === 'air' ? 'arrive' : 'loading'
              }
              destinationIcon={
                comment.shipment.shipment_type === 'air' ? 'depart' : 'loading'
              }
              primaryColor={group[0]}
              secondaryColor={group[1]}
              progress={comment.shipment.progress_percent}
            />
          </div>
          <div className="message-center-item-shipment-end">
            <div className="message-center-item-shipment-date">
              {convertEstimatedDate(comment.shipment.estimated_arrival)}
            </div>
            <div className="message-center-item-shipment-port">
              {comment.shipment.discharge_port}
            </div>
          </div>
        </div>
      </MuiLink>
    </div>
  )
}

export default MessageCenterShipmentBlock

import { useEffect } from 'react'
import { TextField } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import { NumberFormatCustom } from 'src/components/Common/Input/MuiInput'
import { useFormContext } from '../../FormContext'
import { initialState } from '../../Form.constants'

const CifValue = ({ fieldPath, label }) => {
  const { formState, onChange } = useFormContext()

  const insuranceServiceEnabled = formState.services?.insurance ?? false

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    onChange({ [fieldPath]: Number(value) })
  }

  useEffect(() => {
    if (!insuranceServiceEnabled) {
      onChange({ [fieldPath]: initialState[fieldPath] })
    }
  }, [insuranceServiceEnabled])

  return (
    <>
      {formState.services?.insurance && (
        <>
          <InputLabel>{label}</InputLabel>
          <TextField
            placeholder="Enter value"
            InputProps={{
              startAdornment: '€',
              inputComponent: NumberFormatCustom as any,
            }}
            inputProps={{
              sx: { textAlign: 'right' },
              'data-testid': 'shipment-template-cif-value',
            }}
            value={formState[fieldPath]}
            onChange={handleChange}
          />
        </>
      )}
    </>
  )
}

export default CifValue

import { ReactNode, ReactNodeArray, FunctionComponent } from 'react'
import PropTypes from 'prop-types'

import './styles.scss'
import { CircularProgress } from 'src/stories'

interface ILoadableContainerProps {
  loading?: boolean
  className?: string
  children?: ReactNode | ReactNodeArray
  placeholder?: ReactNode | string
}

const LoaderSpinner: FunctionComponent = () => (
  <div className="loader-container">
    <CircularProgress
      variant="indeterminate"
      classes={{
        svg: 'loader-container__spinner mui-override',
      }}
    />
  </div>
)

// Wraps content and renders loader spinner (or custom placeholder) in it's place if loading prop is true
const LoadableContainer: FunctionComponent<any> = (
  props: ILoadableContainerProps
) => {
  if (!props.loading) {
    return <>{props.children || null}</>
  }

  return <section className={props.className}>{props.placeholder}</section>
}

LoadableContainer.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

LoadableContainer.defaultProps = {
  loading: false,
  className: '',
  children: '',
  placeholder: <LoaderSpinner />,
}

export default LoadableContainer

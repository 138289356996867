import DashboardIcon from '@mui/icons-material/Dashboard'
import { Box, Button, Paper, Typography } from '@mui/material'
import { upperCase, values } from 'lodash'
import { useState } from 'react'
import LoadableContainer from 'src/components/LoadableContainer'
import { useGetContainerTypes } from 'src/services/Api/containerTypes'
import { useGetRates, useGetRatesFilterOptions } from 'src/services/Api/prices'
import { useTranslation } from 'react-i18next'
import { RatesListTypeEnum } from 'src/@types/endpoints/prices'
import Drawer from 'src/stories/Drawer'
import Tabs from 'src/stories/Tabs'
import { IRatesFiltersType } from 'src/pages/Rates'
import RatesColumnsFilter from '../RatesColumnsFilter'
import RatesNoResults from '../RatesNoResults'
import RatesTable from '../RatesTable'
import { ratesDataToViewData } from './helpers'
import RatesTabsSkeletonLoader from './RatesTabsSkeletonLoader'

const RatesTabs = ({
  filters,
  defaultTab = RatesListTypeEnum.fcl,
}: {
  filters: IRatesFiltersType
  defaultTab?: RatesListTypeEnum
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [ratesListType, setRatesListType] = useState<RatesListTypeEnum>(
    defaultTab
  )

  const {
    isLoading,
    ratesData,
    userContainerTypesData,
    containerTypesData,
  } = useGetRatesPage({ filters, ratesListType })

  const rates = ratesDataToViewData(
    ratesListType,
    ratesData?.result || [],
    userContainerTypesData
  )

  const tabs = values(RatesListTypeEnum).map((type) => ({
    label: upperCase(t(`rates.tabs.${type}`, type)),
    value: type,
  }))

  return (
    <>
      <Drawer
        open={open}
        anchor="right"
        heading={t('common.customize', 'Customize columns')}
        onClose={() => setOpen(false)}
      >
        <Box sx={{ mt: 1.5, mb: 1.5, width: 275 }}>
          <Typography variant="h5" children={t('common.columns', 'Columns')} />
          <Typography
            variant="body1"
            children={t(
              'common.customize_columns.description',
              'Please select the column that you want to see in the table'
            )}
          />
        </Box>
        <RatesColumnsFilter
          container_types={containerTypesData || []}
          user_container_types={userContainerTypesData}
        />
      </Drawer>
      <Paper variant="elevation">
        <div className="rates-tabs-line">
          <Tabs value={ratesListType} onChange={setRatesListType} tabs={tabs} />
          {ratesListType === RatesListTypeEnum.fcl && (
            <Button
              sx={{ position: 'absolute', top: 6, right: 10 }}
              variant="outlined"
              onClick={() => setOpen(true)}
              startIcon={<DashboardIcon />}
            >
              {t('common.customize', 'Customize')}
            </Button>
          )}
        </div>
        <section
          className={`rates__rates-section ${
            ratesListType === RatesListTypeEnum.fcl && 'fcl'
          }`}
        >
          <LoadableContainer
            loading={isLoading}
            className="rates__loader"
            placeholder={<RatesTabsSkeletonLoader />}
          >
            {rates && (
              <>
                {rates.length > 0 && (
                  <RatesTable
                    scope={ratesListType}
                    rates={rates}
                    container_types={containerTypesData || []}
                    user_container_types={userContainerTypesData}
                  />
                )}
                {rates.length === 0 && (
                  <RatesNoResults message={ratesData?.message || ''} />
                )}
              </>
            )}
          </LoadableContainer>
          <div />
        </section>
      </Paper>
    </>
  )
}

const useGetRatesPage = ({
  filters,
  ratesListType,
}: {
  filters: IRatesFiltersType
  ratesListType: RatesListTypeEnum
}) => {
  const {
    data: containerTypesData,
    isLoading: containersLoading,
  } = useGetContainerTypes()

  const createRatesQuery = () => {
    const { lcl, air } = RatesListTypeEnum
    return ratesListType === air
      ? { load_type: lcl, modality_specific: air }
      : { load_type: ratesListType }
  }

  const {
    data: filterOptions,
    isLoading: optionsLoading,
  } = useGetRatesFilterOptions()

  const { data: ratesData, isLoading: ratesLoading } = useGetRates(
    {
      include_demurrage_and_detention: false,
      ...createRatesQuery(),
      ...filters,
    },
    { enabled: !!filters }
  )

  const isLoading = optionsLoading || ratesLoading || containersLoading

  return {
    isLoading,
    ratesData,
    containerTypesData,
    userContainerTypesData: filterOptions?.user_container_types || [],
  }
}

export default RatesTabs

export const SEARCH_QUOTES = 'SEARCH_QUOTES'
export const SEARCH_QUOTES_RESET = 'SEARCH_QUOTES_RESET'
export const SEARCH_QUOTES_SUCCESS = 'SEARCH_QUOTES_SUCCESS'
export const SEARCH_QUOTES_ERROR = 'SEARCH_QUOTES_ERROR'
export const SEARCH_QUOTES_SORT = 'SEARCH_QUOTES_SORT'
export const LINKED_CUSTOMERS = 'LINKED_CUSTOMERS'
export const LINKED_CUSTOMERS_SUCCESS = 'LINKED_CUSTOMERS_SUCCESS'
export const LINKED_CUSTOMERS_ERROR = 'LINKED_CUSTOMERS_ERROR'
export const GET_CARRIERS = 'GET_CARRIERS'
export const GET_CARRIERS_SUCCESS = 'GET_CARRIERS_SUCCESS'
export const GET_VESSELS = 'GET_VESSELS'
export const GET_VESSELS_SUCCESS = 'GET_VESSELS_SUCCESS'
export const GET_CARRIER_ORGANIZATIONS = 'GET_CARRIER_ORGANIZATIONS'
export const GET_CARRIER_ORGANIZATIONS_SUCCESS =
  'GET_CARRIER_ORGANIZATIONS_SUCCESS'

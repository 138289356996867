import { TFunction } from 'i18next'
import {
  Dimensions,
  Description,
  PackageTypes,
  Quantity,
  Volume,
  Weight,
  Stackable,
  RefreshRow,
} from './CargoRow/CargoRow'

export const createCargoColumn = (
  t: TFunction<'translation', undefined>,
  handleChangeCargo: ({ id, name, value }) => void,
  filteredError: (props: any, name: any) => any,
  onClear: (cargo: IInitialCargoItem) => Promise<void> | void,
  cargoItems: IInitialCargoItem[] & {
    isCargoDisabled?: boolean
  },
  modality?: string
) => {
  return {
    quantity: {
      name: (
        <>
          {t('shipment_containers.cargo_window.quantity', 'quantity')}
          <sup>*</sup>
        </>
      ),
      className: 'short',
      component: (props) => (
        <Quantity
          cargo={props.row}
          onChange={handleChangeCargo}
          error={filteredError(props, 'quantity')}
        />
      ),
    },
    packageType: {
      name: (
        <>
          {t('shipment_containers.cargo_window.package_type', 'package type')}
          <sup>*</sup>
        </>
      ),
      className: 'x-long',
      component: (props) => (
        <PackageTypes
          cargo={props.row}
          onChange={handleChangeCargo}
          error={filteredError(props, 'packageType')}
        />
      ),
    },
    dimensions: {
      name: t('shipment_containers.cargo_window.dimensions', 'dimensions'),
      className: 'x-long',
      component: (props) => (
        <Dimensions
          cargo={props.row}
          onChange={handleChangeCargo}
          error={filteredError(props, 'dimensions')}
          modality={modality}
        />
      ),
    },
    volume: {
      name: (
        <>
          {t('shipment_containers.cargo_window.volume', 'volume')}
          <sup>*</sup>
        </>
      ),
      className: 'x-medium',
      component: (props) => (
        <Volume
          cargo={props.row}
          onChange={handleChangeCargo}
          error={filteredError(props, 'total_volume_cbm')}
          modality={modality}
        />
      ),
    },
    weight: {
      name: (
        <>
          {t('shipment_containers.cargo_window.weight', 'weight')}
          <sup>*</sup>
        </>
      ),
      className: 'x-medium',
      component: (props) => (
        <Weight
          cargo={props.row}
          onChange={handleChangeCargo}
          error={filteredError(props, 'total_weight_kg')}
        />
      ),
    },
    description: {
      name: (
        <>
          {t('shipment_containers.cargo_window.description', 'description')}
          <sup>*</sup>
        </>
      ),
      className: 'x-long',
      component: (props) => (
        <Description
          cargo={props.row}
          onChange={handleChangeCargo}
          error={filteredError(props, 'goods_description')}
        />
      ),
    },
    stackable: {
      name: (
        <>
          {t('shipment_containers.cargo_window.stackable', 'stackable')}
          <sup>*</sup>
        </>
      ),
      className: 'x-medium',
      component: (props) => (
        <Stackable
          cargo={props.row}
          onChange={handleChangeCargo}
          error={filteredError(props, 'stackable')}
        />
      ),
    },
    '#': {
      name: '',
      className: 'x-short',
      component: (props) => (
        <RefreshRow
          cargo={props.row}
          onClick={onClear}
          cargoLength={cargoItems.length}
        />
      ),
    },
  }
}

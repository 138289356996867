// @ts-nocheck
// FIXME

import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import * as actions from '../actions/publicPO'

declare global {
  interface IPublicPOState {
    purchaseOrder: IPurchaseOrder
  }
}
export const initialPublicPOState = {
  purchaseOrder: {},
}
const receiveOrder: Reducer<IPublicPOState, AnyAction> = (state, action) => ({
  ...state,
  purchaseOrder: action.payload,
})
export default createReducer(initialPublicPOState, {
  [actions.PUBLIC_PO_GET_DATA_SUCCESS]: receiveOrder,
})

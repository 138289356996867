import { find, toNumber } from 'lodash'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import { createStyles, withStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import Checkbox from '@mui/material/Checkbox'

import './styles.scss'

export const CustomInputLabel = withStyles(() =>
  createStyles({
    disabled: {
      pointerEvents: 'none',
    },
  })
)(InputLabel)

const useStyles = makeStyles((theme: Theme) => ({
  option: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&[data-focus="true"]': {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
  },
}))

const StyleChip = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary[50],
    color: theme.palette.secondary[900],
    '&.Mui-disabled': {
      opacity: 1,
    },
  },
  deleteIcon: {
    color: `${theme.palette.grey[500]} !important`,
    fontSize: '8px !important',
    width: '13px !important',
    height: '17px !important',
    margin: '1px 5px 0 -6px !important',
    '&:hover': {
      color: `${theme.palette.secondary[900]} !important`,
    },
  },
}))(Chip)

const StyleTextField = withStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grey[400],
      },
      '&:hover fieldset': {
        borderColor: theme.palette.grey[600],
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
      '&.Mui-disabled fieldset': {
        border: 'none',
      },
    },
  },
}))(TextField)

export interface CustomInputProps {
  name: string
  disabled?: boolean
  label?: string
  required?: boolean
  error?: boolean
  values?: string[] | number[] | { [key: string]: any }[]
  helperText?: string
  options?: any[]
  optionLabel?: string
  placeholder?: string
  optionsKey?: string
  disabledOption?: (option: any) => boolean
  optionsGroupBy?: () => any
  onChange: (
    value: string[] | number[] | { [key: string]: any }[],
    name: string
  ) => void
  customOption?: (optionProps, option, selected) => React.ReactNode
  customChip?: (tag) => React.ReactNode
  saveOnBlur?: boolean
  freeSolo?: boolean
  disableCloseOnSelect?: boolean
}

const MuiMultipleInput = ({
  name,
  disabled,
  label,
  required = false,
  error = false,
  values,
  helperText,
  options = [],
  optionLabel = 'title',
  onChange,
  placeholder,
  disabledOption,
  optionsGroupBy,
  optionsKey = 'id',
  customOption,
  customChip,
  saveOnBlur,
  freeSolo,
  disableCloseOnSelect,
}: CustomInputProps) => {
  const classes = useStyles()
  const onInputChange = (event, values) => {
    const selectedIds = values.map((value) => toNumber(value.id) || value)
    return onChange(selectedIds, name)
  }

  const checkOptionValue = (option, value): boolean => {
    return !!(option && value && option[optionsKey] === value)
  }

  const onBlur = (event) => {
    const value: string = event.target.value
    if (saveOnBlur && value) {
      let existValues: any = values || []
      existValues.push(value)
      return onChange(existValues, name)
    }
  }

  return (
    <FormControl fullWidth>
      <Autocomplete
        disableCloseOnSelect={disableCloseOnSelect}
        multiple
        freeSolo={freeSolo ?? !options.length}
        id="tags-standard"
        options={options}
        getOptionLabel={(option) =>
          !options.length ? option : option[optionLabel]
        }
        getOptionDisabled={disabledOption}
        isOptionEqualToValue={checkOptionValue}
        groupBy={optionsGroupBy}
        clearOnBlur={true}
        onChange={onInputChange}
        defaultValue={undefined}
        disabled={disabled}
        value={values}
        data-testid="multiple-input-field"
        popupIcon={<KeyboardArrowDownIcon />}
        renderTags={(value, getTagProps) => {
          return value.map((option, index) => {
            const tag = find(options, (item) => item.id === option)
            if ((!!options.length && tag) || !options.length) {
              return (
                <StyleChip
                  label={
                    options.length && tag
                      ? customChip
                        ? customChip(tag)
                        : tag[optionLabel]
                      : option
                  }
                  {...getTagProps({ index })}
                  color="primary"
                  deleteIcon={disabled ? <></> : <CloseRoundedIcon />}
                />
              )
            }
            return ''
          })
        }}
        renderOption={(optionProps, option, { selected }) => {
          return customOption ? (
            customOption(optionProps, option, selected)
          ) : (
            <li {...optionProps}>
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              {option[optionLabel]}
            </li>
          )
        }}
        classes={{
          root: 'multiple-input__root',
          inputRoot: `multiple-input__wrapper ${disabled ? 'disabled' : ''}`,
          focused: 'multiple-input__focused',
          option: classes.option,
        }}
        renderInput={(params) => (
          <>
            <CustomInputLabel
              disabled={disabled}
              error={error}
              required={required}
              shrink
              htmlFor={label}
            >
              {label}
            </CustomInputLabel>
            <StyleTextField
              {...params}
              name={name}
              error={error}
              fullWidth
              value={values}
              variant="outlined"
              placeholder={placeholder}
              onBlur={onBlur}
            />
          </>
        )}
      />
      {!!helperText && (
        <FormHelperText error={error} id="custom-input">
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default MuiMultipleInput

import { FunctionComponent } from 'react'

import './styles.scss'

type SizeRange = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
type Color = 'dark-blue' | 'blue' | 'default' | 'white' | 'grey' | 'light-grey'

interface IProps {
  size: SizeRange
  color: Color
  className?: string
}

const TextPlaceholder: FunctionComponent<IProps> = (props) => {
  return (
    <div
      className={`text-placeholder size-${props.size} ${props.color} ${props.className}`}
    />
  )
}

TextPlaceholder.defaultProps = {
  className: '',
}

export default TextPlaceholder

import { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import Link from '@mui/material/Link'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { Logger, permissionTo, promisifyAction } from '../../../../utils'
import './styles.scss'

import BookingPartiesBlock from '../BookingPartiesBlock'

import { updateNotifyPartyAsConsignee } from '../../../../stores/actionCreators'

interface IProps {
  shipmentId: number | string
  theme?: string
  booking: IBooking
  openBookingModal: () => void
  openPartiesModal: (type: string) => void
  fetchData: () => void
}

const BookingPartiesLayout: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const permission: boolean = permissionTo('shipments.bookings.parties.manage')

  const updateNotifyAsConsignee = promisifyAction(
    dispatch,
    updateNotifyPartyAsConsignee
  )

  const clickOnBlock = (type: string): void => {
    props.openPartiesModal(type)
  }

  const updateNotifyWithConsignee = async (): Promise<any> => {
    const data: { [key: string]: boolean } = {
      consignee_as_notify_party: !props.booking.consignee_as_notify_party,
    }

    try {
      await updateNotifyAsConsignee(data, props.booking.id)
      props.fetchData()
    } catch (error) {
      Logger.log(error)
    }
  }

  const addInfoBlock: React.ReactNode = (
    <>
      {permissionTo('shipments.bookings.manage') ? (
        <Link
          variant="body1"
          component="button"
          onClick={props.openBookingModal}
        >
          {t('shipments.bookings.parties.add_incoterm', 'Add')}
        </Link>
      ) : (
        '-'
      )}
    </>
  )

  return (
    <div className={`booking-parties__layout ${props.theme}`}>
      <div className="booking-parties__layout--wrapper">
        <div className="booking-parties__layout--block">
          <BookingPartiesBlock
            title={t('shipments.bookings.parties.shipper', 'Shipper')}
            type="shipper"
            theme={props.theme}
            class={`instructions__block--shipper`}
            company={props.booking.shipper.shipment_address}
            contact={props.booking.shipper.shipment_contacts}
            openModal={clickOnBlock}
            permission={permission}
            data-testid="booking-shipper"
          />
        </div>
        <div className="booking-parties__layout--block">
          <BookingPartiesBlock
            title={t('shipments.bookings.parties.consignee', 'Consignee')}
            type="consignee"
            theme={props.theme}
            class={`instructions__block--consignee`}
            company={props.booking.consignee.shipment_address}
            contact={props.booking.consignee.shipment_contacts}
            openModal={clickOnBlock}
            updateNotifyWithConsignee={updateNotifyWithConsignee}
            consigneeAsNotifyParty={props.booking.consignee_as_notify_party}
            permission={permission}
            data-testid="booking-consignee"
          />
        </div>
      </div>
      <div className="booking-parties__layout--wrapper bottom">
        <div className="booking-parties__layout--block">
          <div className="shipping-bookings--extended-info">
            <Typography
              variant="h5"
              children={t('shipments.bookings.parties.incoterm', 'Incoterm')}
            />
            <Typography variant="body1" data-testid="booking-incoterm-value">
              {props.booking.incoterm || addInfoBlock}
            </Typography>
          </div>
        </div>
        {!props.booking.consignee_as_notify_party && (
          <div className="booking-parties__layout--block">
            <BookingPartiesBlock
              title={t(
                'shipments.bookings.parties.notify_party',
                'Notify party'
              )}
              type="notify_party"
              theme={props.theme}
              class={`instructions__block--notify`}
              company={props.booking.notify_party.shipment_address}
              contact={props.booking.notify_party.shipment_contacts}
              openModal={clickOnBlock}
              permission={permission}
              data-testid="booking-notify-party"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default BookingPartiesLayout

import { FunctionComponent, useEffect, useMemo } from 'react'
import { Table, TableBody } from '@mui/material'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import RvHookupIcon from '@mui/icons-material/RvHookup'
import { EmptyState } from 'src/stories'
import { permissionTo } from 'src/utils'
import {
  getShipmentStatuses,
  pickupsAndDeliveriesSetSelectedId,
  pickupsAndDeliveriesSetDrawer,
  getPickupsAndDeliveriesUserColumns,
} from 'src/stores/actionCreators'
import ScrollShadowWrapper from 'src/components/Common/ScrollShadowWrapper'
import { SelectableChipOptions } from 'src/components/SelectableChip'
import { getShipmentSelectableStatusOptions } from 'src/utils/helpers/shipmentStatus'
import { canUpdatePickupAndDeliveryTime, canUpdateTransport } from '../helpers'
import BlankTable from './BlankTable'
import InlandTransportRow from './InlandTransportRow'
import PickupsDeliveryTableHeader from './PickupsDeliveryTableHeader'

import '../styles.scss'

const permissions = {
  pickup_delivery_time: canUpdatePickupAndDeliveryTime,
  inspction: canUpdateTransport,
}

const PickupsAndDeliveriesTable: FunctionComponent<{
  filtersOpen: boolean
}> = ({ filtersOpen }) => {
  const dispatch = useDispatch()

  const { list, isLoading, shipmentStatuses } = useSelector(
    (state: IGlobalState) => ({
      list: state.pickUpsAndDeliveries.list,
      isLoading: state.pickUpsAndDeliveries.isLoading,
      isLoadingOptions: state.pickUpsAndDeliveries.isLoadingOptions,
      shipmentStatuses: state.shipments.shipmentStatuses,
    }),
    shallowEqual
  )

  const shipmentStatusesForSelect: SelectableChipOptions = useMemo(
    () => getShipmentSelectableStatusOptions(shipmentStatuses),
    [shipmentStatuses]
  )

  useEffect(() => {
    dispatch(getPickupsAndDeliveriesUserColumns())
    if (permissionTo('shipments.status.manage')) {
      dispatch(getShipmentStatuses())
    }
  }, [])

  const onRowClick = (row: IInlandTransportState) => {
    dispatch(
      pickupsAndDeliveriesSetDrawer({
        type: 'inland_transport',
        open: true,
      })
    )
    dispatch(pickupsAndDeliveriesSetSelectedId(row.id))
  }

  const cellClickHandler = (event, row, columnType?: string, name?: string) => {
    // logic to ignore click on a row that shows drawer otherwise
    if (
      (columnType &&
        permissions[columnType] &&
        permissions[columnType](row) &&
        ['pickup_delivery_time', 'inspection'].includes(columnType)) ||
      (name && name === 'Container discharge')
    ) {
      return
    }
    onRowClick(row)
  }

  if (isLoading) {
    return <BlankTable />
  }

  return (
    <ScrollShadowWrapper isItemsPresent={!!list.length}>
      <Table
        sx={{ tableLayout: 'auto' }}
        data-testid="pickup-and-deliveries-table"
      >
        <PickupsDeliveryTableHeader />
        <TableBody>
          {list.length ? (
            list.map((row) => (
              <InlandTransportRow
                key={row.id}
                row={row}
                onCellClick={cellClickHandler}
                shipmentStatuses={shipmentStatusesForSelect}
              />
            ))
          ) : (
            <EmptyState
              badgeColor="primary"
              badgeIcon={RvHookupIcon}
              title="Don't start the engine just yet"
              button={{ text: 'Book shipment', link: '/search' }}
              description="There are no pickups and deliveries scheduled over the next week."
            />
          )}
        </TableBody>
      </Table>
    </ScrollShadowWrapper>
  )
}

export default PickupsAndDeliveriesTable

import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { DeliveryInfoItemProps } from './DeliveryInfo.props'

const DeliveryInfoItem: React.FC<DeliveryInfoItemProps> = ({
  date,
  title,
  children,
}) => {
  const { t } = useTranslation()
  const dateText = date
    ? t('common.date_medium', {
        defaultValue: '{{date, DATE_MED}}',
        date,
      })
    : t('shipment_containers.to_be_announced', 'To be announced')
  return (
    <Box flexGrow={1}>
      <Typography
        mb={0.5}
        component="div"
        variant="body1Strong"
        children={title}
        data-testid="delivery-info-item-title"
      />
      <Box minHeight={24} display="flex" alignItems="center">
        <Typography
          component="span"
          children={dateText}
          data-testid="delivery-info-item-date"
        />
        {!!children && children}
      </Box>
    </Box>
  )
}

export default DeliveryInfoItem

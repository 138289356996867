import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'
import { SelectOptionProps } from 'src/stories/Lab/Select/Select.props'

export const getTransitTimes = (
  quotes: ISearchQuote[]
): SelectOptionProps[] => {
  const filteredTransitTimes: SelectOptionProps[] = quotes.reduce(
    (filtered: SelectOptionProps[], quote: ISearchQuote) => {
      const transitTime = quote.schedule.transit_time_days
      if (transitTime !== null && transitTime !== undefined) {
        filtered.push({ label: `${transitTime}`, id: transitTime })
      }
      return filtered
    },
    []
  )

  const uniqueTransitTimes = uniqBy(filteredTransitTimes, 'id')
  const sortedTransitTimes = sortBy(uniqueTransitTimes, 'id')

  return sortedTransitTimes
}

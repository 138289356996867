import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import LinearProgress from '@mui/material/LinearProgress'
import Box from '@mui/material/Box'
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked'
import CircleCheckedFilled from '@mui/icons-material/CheckCircle'
import { Skeleton } from 'src/stories'
import { getShipmentProgressColor } from '../utils'
import { ShipmentProgressWithPortsProps } from '../shipmentContentProps'

const useStyles = makeStyles((theme) => {
  const grey = theme.palette.grey[300]
  return {
    dashedProgress: {
      color: 'transparent',
      backgroundSize: '8px 4px, 100% 4px',
      backgroundImage: `linear-gradient(90deg, ${grey}, ${grey} 50%, transparent 50%, transparent 100%)`,
    },
  }
})

export const ShipmentProgressWithPorts: React.FC<ShipmentProgressWithPortsProps> = (
  props
) => {
  const classes = useStyles()
  const { status, progressPercent, title, description, hideProgressBar } = props
  const showProgress: boolean = !!status

  return (
    <Box data-testid="shipment-progress-with-ports">
      <Box
        data-testid="status-block"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        {showProgress ? (
          <CircleCheckedFilled data-testid="filled-icon" color="primary" />
        ) : (
          <CircleUnchecked
            data-testid="blank-icon"
            sx={{ color: 'grey.300' }}
          />
        )}
        {!hideProgressBar && (
          <LinearProgress
            variant="determinate"
            color={getShipmentProgressColor(status || '')}
            value={progressPercent || 0}
            sx={{ mx: 1, width: '100%', height: 4 }}
            classes={{
              root: !showProgress ? classes.dashedProgress : '',
            }}
            data-testid="progress-component"
          />
        )}
      </Box>
      {typeof title === 'string' ? (
        <Typography
          variant="body1Strong"
          sx={{
            ml: '3px',
            mt: 2,
            height: 20,
            textAlign: 'left',
            display: 'flex',
            alignItems: 'center',
          }}
          noWrap={true}
          children={title}
          data-testid="title-text"
        />
      ) : (
        <Skeleton
          animation={false}
          variant="text"
          sx={{ width: '100%', height: 30, backgroundColor: 'grey.200' }}
          data-testid="title-skeleton"
        />
      )}
      {typeof description === 'string' ? (
        <Typography
          variant="body1"
          sx={{ ml: '3px', height: 20, textAlign: 'left' }}
          noWrap={true}
          children={description}
          data-testid="description-text"
        />
      ) : (
        <Skeleton
          animation={false}
          variant="text"
          sx={{ width: '100%', height: 20, backgroundColor: 'grey.200' }}
          data-testid="description-skeleton"
        />
      )}
    </Box>
  )
}

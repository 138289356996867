import Typography from '@mui/material/Typography'
import { isNull } from 'lodash'
import { ShipmentEmissionProps } from '../shipmentContentProps'
import { AlignedStack } from './ShipmentAlignedStack'

export const ShipmentEmission: React.FC<ShipmentEmissionProps> = ({
  totalEmissionGram,
  align,
}) => {
  if (isNull(totalEmissionGram) || totalEmissionGram < 1) {
    return null
  }
  return (
    <AlignedStack justifyContent={align} direction="row" spacing={0}>
      <Typography variant="body1Strong" component="span" color="grey.600">
        CO<sub>2</sub>&nbsp;
      </Typography>
      <Typography variant="body1" color="grey.600" component="span">
        {Math.round(totalEmissionGram / 1000)} kg
      </Typography>
    </AlignedStack>
  )
}

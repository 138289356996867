import { FunctionComponent, useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { find } from 'lodash'
import { DateTime } from 'luxon-business-days'
import { LoadingButton } from '@mui/lab'
import { Button, Grid } from '@mui/material'
import CustomInput from 'src/components/Common/Input/MuiInput'
import { ModalityEnum } from 'src/config/constants'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import DatePicker from 'src/stories/DatePicker'
import { useTranslation } from 'react-i18next'
import { promisifyAction, permissionTo } from '../../../utils'
import { convertDateForComparison } from '../../../utils/helpers'
import {
  createBooking,
  updateBooking,
  incotermsGetData,
} from '../../../stores/actionCreators'

import { Dialog, DialogContent } from '../../../stories/Dialogs'

interface IProps {
  shipmentModality: ModalityEnum
  booking: IBooking | null
  shipment_id: number | string
  open: boolean
  close: () => void
  fetchData: () => void
  collapseItems: () => void
}

const initialBookingDetails: IBookingDetails = {
  booking_reference: '',
  house_bl: '',
  incoterm: '',
  cargo_ready_date: null,
}

interface IBookingDetails {
  booking_reference: string
  incoterm: string
  cargo_ready_date: string | null
  house_bl: string
}

const BookingEditModal: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const { shipmentModality } = props
  const { incoterms } = useSelector((state: IGlobalState) => ({
    incoterms: state.bookings.incoterms,
  }))

  const [bookingDetails, setBookingDetails] = useState<IBookingDetails>(
    initialBookingDetails
  )
  const [incoterm, setIncoterm] = useState<string>('')
  const [referenceError, setReferenceError] = useState<string>('')
  const [loadingButton, setLoadingButton] = useState<boolean>(false)

  const dispatch = useDispatch()
  const createBookingAsync = promisifyAction(dispatch, createBooking)
  const updateBookingAsync = promisifyAction(dispatch, updateBooking)
  const getIncotermTypesAsync = promisifyAction(dispatch, incotermsGetData)

  const incotermOptions =
    incoterms?.map((incoterm) => ({
      id: incoterm.code,
      label: incoterm.code,
    })) || []

  useEffect(() => {
    getIncotermTypesAsync()
  }, [])

  useEffect(() => {
    if (props.booking) {
      const incotermName = props.booking.incoterm
      const incoterm =
        incotermName &&
        find(incoterms, (incoterm) => incoterm.name === incotermName)

      const details: IBookingDetails = {
        booking_reference: props.booking?.booking_reference ?? '',
        incoterm: incoterm ? incoterm.code : '',
        cargo_ready_date: props.booking.cargo_ready_date,
        house_bl: props.booking?.house_bl ?? '',
      }
      if (incoterm) setIncoterm(incoterm.code)
      setBookingDetails(details)
    } else {
      setBookingDetails(initialBookingDetails)
    }
  }, [props.booking])

  const onSave = async (): Promise<any> => {
    setLoadingButton(true)
    if (
      bookingDetails.booking_reference &&
      bookingDetails.booking_reference.length > 50
    ) {
      setReferenceError(
        t(
          'shipments.bookings.errors.too_many_characters',
          'Cannot be more than 50 characters'
        )
      )
    } else {
      setReferenceError('')
      const detailsData = {
        ...bookingDetails,
        ...{
          incoterm_code: incoterm,
          cargo_ready_date: convertDateForComparison(
            bookingDetails.cargo_ready_date || ''
          ),
          incoterm,
        },
        shipment_id: props.shipment_id,
      }

      if (props.booking && props.booking.id) {
        await updateBookingAsync(props.booking.id, detailsData)
      } else {
        await createBookingAsync(detailsData)
        props.collapseItems()
      }
      props.close()
      props.fetchData()
      setLoadingButton(false)
      setBookingDetails(initialBookingDetails)
    }
  }

  const onClose = (): void => {
    setBookingDetails(initialBookingDetails)
    props.close()
  }

  const handleBookingChange = useCallback(
    (value: string | undefined, fieldName: string): void => {
      if (!fieldName) return
      setBookingDetails({
        ...bookingDetails,
        [fieldName]: value,
      })
    },
    [bookingDetails]
  )

  const cargoReadyChange = useCallback(
    (value: string): void => {
      setBookingDetails({
        ...bookingDetails,
        cargo_ready_date: value,
      })
    },
    [bookingDetails]
  )

  const onIncotermsChange = (event) => {
    setIncoterm(event.target.value)
  }

  const bookingInputs: React.ReactNode = (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomInput
            label={t(
              'shipments.bookings.booking_reference_label',
              'Booking reference'
            )}
            name="booking_reference"
            disabled={false}
            placeholder={t(
              'shipments.bookings.booking_reference_placeholder',
              'Enter booking reference'
            )}
            helperText={referenceError}
            value={bookingDetails.booking_reference}
            onChange={handleBookingChange}
            data-testid="booking-edit-reference"
          />
        </Grid>
        <Grid item xs={12} data-testid="booking-edit-cargo-ready-date">
          <DatePicker
            label={t(
              'shipments.bookings.cargo_ready_date_label',
              'Cargo ready date'
            )}
            clearable={false}
            value={bookingDetails.cargo_ready_date}
            onChange={(date: DateTime | null) => {
              cargoReadyChange(date.toISO())
            }}
            data-testid="booking-cargo-ready-date-picker"
          />
        </Grid>
        <Grid item xs={12}>
          <SingleSelect
            label={t('shipments.bookings.incoterm_label', 'Incoterm')}
            id="incoterm"
            placeholder={t(
              'shipments.bookings.incoterm_placeholder',
              'Select incoterm'
            )}
            onChange={onIncotermsChange}
            options={incotermOptions}
            value={incoterm}
            data-testid="booking-edit-incoterm"
          />
        </Grid>
        {permissionTo('shipments.bookings.manage') &&
          permissionTo('shipments.manage') && (
            <Grid item xs={12}>
              <CustomInput
                label={
                  shipmentModality === 'air'
                    ? t('common.modality_air.house_bl', 'House AWB')
                    : t('common.modality_sea.house_bl', 'House BL')
                }
                name="house_bl"
                disabled={false}
                placeholder={
                  shipmentModality === 'air'
                    ? t('common.modality_air.enter_house_bl', 'Enter house AWB')
                    : t('common.modality_sea.enter_house_bl', 'Enter house BL')
                }
                helperText={referenceError}
                value={bookingDetails.house_bl}
                onChange={handleBookingChange}
                data-testid="booking-edit-house-bl"
              />
            </Grid>
          )}
      </Grid>
    </Grid>
  )

  return (
    <Dialog
      title={
        props.booking && props.booking.id
          ? t('shipments.bookings.edit_booking', 'Edit booking')
          : t('shipments.bookings.add_new_booking', 'Add new booking')
      }
      open={props.open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      actions={
        <>
          <Button
            variant="text"
            color="primary"
            size="medium"
            onClick={onClose}
          >
            {t('common.buttons.cancel', 'Cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            size="medium"
            onClick={onSave}
            disabled={loadingButton}
            loading={loadingButton}
          >
            {t('common.buttons.save', 'Save')}
          </LoadingButton>
        </>
      }
    >
      <DialogContent>{bookingInputs}</DialogContent>
    </Dialog>
  )
}

export default BookingEditModal

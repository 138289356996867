import { useEffect } from 'react'
import { useController } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import Input, { NumberFormatCustom } from 'src/components/Common/Input/MuiInput'
import { ModalityEnum } from 'src/config/constants'
import { ItemProps } from '../GoodsLoadTypeLCL.props'
import { countVolume } from './helpers'

export const VolumeInput = (props: ItemProps) => {
  const { cargo, onChange, index } = props
  const { field, fieldState } = useController(props)
  const isAir: boolean = props.modality === ModalityEnum.Air
  const isDimensions: boolean =
    Boolean(cargo.height) && Boolean(cargo.length) && Boolean(cargo.width)
  const isDisable: boolean = isAir || isDimensions

  useEffect(() => {
    if (isDimensions) {
      field.onChange(
        countVolume(cargo.width, cargo.height, cargo.length, cargo.quantity)
      )
      onChange(
        cargo.id,
        'total_volume_cbm',
        countVolume(cargo.width, cargo.height, cargo.length, cargo.quantity),
        index
      )
    }
  }, [cargo.height, cargo.length, cargo.width])

  const handleChange = (value: string) => {
    onChange(cargo.id, 'total_volume_cbm', Number(value), index)
    field.onChange(Number(value))
  }

  return (
    <Grid item xs={1.5} pr={1.5} pl={1.5}>
      <Input
        {...field}
        value={cargo.total_volume_cbm}
        inputComponent={NumberFormatCustom as any}
        placeholder="0.00"
        ref={null}
        onChange={handleChange}
        error={!props.isClearError && Boolean(fieldState.error)}
        disabled={isDisable}
        endAdornment={
          <div>
            m<sup>3</sup>
          </div>
        }
        data-testid="total_volume_cbm-field"
      />
    </Grid>
  )
}

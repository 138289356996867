export const productHeaders = (t) => [
  {
    name: '',
    customClassName: 'short-50',
  },
  {
    name: t(
      'purchase_orders.modals.public_po.table.headers.product_name',
      'PRODUCT NAME'
    ),
    customClassName: 'left-aligned x-medium',
  },
  {
    name: t(
      'purchase_orders.modals.public_po.table.headers.product_code',
      'PRODUCT CODE'
    ),
    customClassName: 'left-aligned x-medium',
  },
  {
    name: t('purchase_orders.modals.public_po.table.headers.volume', 'VOLUME'),
    customClassName: 'left-aligned x-medium',
  },
  {
    name: t('purchase_orders.modals.public_po.table.headers.weight', 'WEIGHT'),
    customClassName: 'left-aligned x-medium',
  },
]

export const singleLineHeaders = (t) => [
  {
    name: t('purchase_orders.modals.public_po.table.headers.volume', 'VOLUME'),
    customClassName: 'left-aligned x-medium',
  },
  {
    name: t('purchase_orders.modals.public_po.table.headers.weight', 'WEIGHT'),
    customClassName: 'left-aligned x-medium',
  },
  {},
  {},
  {},
  {},
  {},
]

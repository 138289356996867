import { FunctionComponent, useState, useEffect } from 'react'

import {
  setContainer,
  setBackground,
  setQuotes,
  setTitle,
  setLoader,
} from './utils'

interface IReactQuoteLoaderProps {
  loading: boolean
  quotes: string[]
  random?: boolean
  speed: number
  backgroundColorLoader: string
  backgroundColorQuotes: string
  backgroundColorBackground?: string
  opacityBackground?: number
  fontSizeTitle: string
  colorTitle?: string
  fontFamilyTitle: string
}

const ReactQuoteLoader: FunctionComponent<IReactQuoteLoaderProps> = (props) => {
  const [currentQuotesIndex, setCurrentQuotesIndex] = useState<number>(0)

  let quotesInterval

  useEffect(() => {
    const quotes = props.quotes
    const speedInMilliseconds = props.speed * 1000

    quotesInterval = setInterval(() => {
      setCurrentQuotesIndex(
        props.random
          ? Math.floor(Math.random() * quotes.length)
          : getNextQuote()
      )
    }, speedInMilliseconds)

    return () => {
      clearInterval(quotesInterval)
    }
  }, [])

  useEffect(() => {
    if (!props.loading) {
      clearInterval(quotesInterval)
    }
  }, [props.loading])

  const getNextQuote = (): number =>
    currentQuotesIndex === props.quotes.length - 1 ? 0 : currentQuotesIndex + 1

  const Loader = setLoader(props)
  const Quotes = setQuotes(props)
  const Container = setContainer(props)
  const Background = setBackground(props)
  const Title = setTitle(props)

  return (
    <Container>
      <Background />
      <Quotes>
        <Title>{props.quotes[currentQuotesIndex]}</Title>
        <Loader />
      </Quotes>
    </Container>
  )
}

export default ReactQuoteLoader

ReactQuoteLoader.defaultProps = {
  random: false,
  speed: 1.5,
  backgroundColorLoader: 'yellow',
  backgroundColorQuotes: 'black',
  backgroundColorBackground: 'black',
  opacityBackground: 0.5,
  fontSizeTitle: '18px',
  colorTitle: 'white',
  fontFamilyTitle: 'Arial',
}

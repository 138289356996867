import { useController } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import Input from 'src/components/Common/Input/MuiInput'
import { ItemProps } from '../GoodsLoadTypeLCL.props'

export const DescriptionInput = (props: ItemProps) => {
  const { cargo, onChange } = props
  const { field, fieldState } = useController(props)

  const handleChange = (value: string) => {
    onChange(cargo.id, 'description', value)
  }
  const { t } = useTranslation()

  return (
    <Grid item xs={2} pr={1.5} pl={1.5}>
      <Input
        {...field}
        value={cargo.description}
        placeholder={t('common.type_description', 'Type description')}
        onChange={(value) => {
          field.onChange(value)
          handleChange(value)
        }}
        ref={null}
        error={!props.isClearError && Boolean(fieldState.error)}
        data-testid="description-field"
      />
    </Grid>
  )
}

import { takeEvery } from 'redux-saga/effects'

import * as actionCreators from '../../actionCreators/searchBooking'
import * as actions from '../../actions/searchBooking'
import { simpleGetAndResolve } from '../factories'
import searchBooking from './searchBooking'

const searchAddress = simpleGetAndResolve(
  () => 'api/v1/common/autocomplete',
  actionCreators.addressSuggestionsSuccess,
  (response) => response.data.data
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.SEARCH_QUOTES_BOOKING, searchBooking)
  yield takeEvery(actions.SEARCH_ADDRESS, searchAddress)
}

import { createReducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import * as actions from '../actions/purchaseOrders'

declare global {
  interface IPurchaseOrder {
    id: number
    purchase_order_number: string
    automated_mailing: boolean
    seller: { address: IAddress | null; organization: ICollaborator | null }
    buyer: ICollaborator | null
    incoterm: IIncoterm | null
    loading_port: IPortShort | null
    ex_factory_date: null | string
    public_po_link: null | string
    order_date: null | string
    cargo_ready_date: null | string
    purchase_order_lines: IPurchaseOrderLine[]
    total_volume_cbm: string
    total_weight_kg: string
    to_plan: boolean
    purchase_order_events?: IPurchaseOrderEvent[]
    original_po_document?: IPurchaseOrderDocument
    exceptional?: boolean
    sla_checkpoints: any[]
    shipments: IShipmentLink[]
  }

  interface IShipmentLink {
    id: number
    reference: string | null
    url: string
  }

  interface IPurchaseOrderTemplateVariables {
    previous_value: string | null
    updated_value: string | null
    seller_address_id?: string
    to_email_address: string
    user_full_name: string | null
    sla_checkpoint_name: string | null
    sla_checkpoint_id: number | null
    connection_id?: number | null
  }

  interface IPurchaseOrderEvent {
    id: number
    created_at: string
    template_name: string
    template_variables: IPurchaseOrderTemplateVariables
    user: IAuthor
    exceptional: boolean
  }

  interface IPurchaseOrderDocument {
    created_at: string
    id: number
    download_url: string
    original_filename: string
  }

  interface IPurchaseOrderLine {
    id: number
    booking_lines: IBookingLine[]
    product_code: string
    product_name: string
    units_ordered: number
    status: string
  }

  interface IBookingLine {
    id: number
    weight_kg: string
    volume_cbm: string
    shipment: IShipmentShort
  }

  interface IPurchaseOrdersState {
    list: IPurchaseOrder[]
    filtersData: any
    scopesCount: any
    openItem: number | null
    totalCount: null | number
    assignToShipmentStatuses: any
    isUniqueOrderNumber: boolean
    triggerOrdersFetchCounter: number
    triggerOpenAddCounter: number
    singlePurchaseOrder: IPurchaseOrder
    selectedLineIds: number[]
  }
}

export const dummyPurchaseOrder: IPurchaseOrder = {
  id: 0,
  purchase_order_number: '',
  automated_mailing: false,
  public_po_link: '',
  seller: { address: null, organization: null },
  buyer: null,
  incoterm: null,
  loading_port: null,
  ex_factory_date: null,
  order_date: null,
  cargo_ready_date: null,
  purchase_order_lines: [],
  total_volume_cbm: '',
  total_weight_kg: '',
  to_plan: false,
  sla_checkpoints: [],
  shipments: [],
}

export const initialPurchaseOrdersState = {
  list: [] as IPurchaseOrder[],
  filtersData: null,
  scopesCount: null,
  openItem: null,
  totalCount: null,
  assignToShipmentStatuses: [],
  selectedLineIds: [],
  isUniqueOrderNumber: false,
  triggerOrdersFetchCounter: 0,
  triggerOpenAddCounter: 0,
  singlePurchaseOrder: undefined,
}

const replaceWith = (list, item: IPurchaseOrder): IPurchaseOrder[] => {
  return list.map((x: IPurchaseOrder) => {
    return x.id === item.id ? item : x
  })
}

const toggleSelected = (array, id) => {
  const index = array.indexOf(id)
  if (~index) {
    array.splice(index, 1)
  } else {
    array.push(id)
  }
  return array
}

export default createReducer(initialPurchaseOrdersState, {
  [actions.PURCHASE_ORDERS_GET_DATA_SUCCESS]: (state, action: AnyAction) => ({
    ...state,
    totalCount: action.payload.total_count,
    list: action.payload.purchase_orders,
    selectedLineIds: [],
  }),
  [actions.PURCHASE_ORDERS_GET_META_SUCCESS]: (state, action: AnyAction) => ({
    ...state,
    scopesCount: action.payload.scope_counts,
  }),
  [actions.PURCHASE_ORDERS_GET_FILTERS_DATA_SUCCESS]: (
    state,
    action: AnyAction
  ) => ({
    ...state,
    filtersData: action.payload,
  }),
  [actions.PURCHASE_ORDERS_OPEN_ITEM]: (state, action: AnyAction) => ({
    ...state,
    openItem: action.payload,
  }),
  [actions.PURCHASE_ORDERS_CLEAR]: (state, action: AnyAction) => ({
    ...state,
    list: [],
    totalCount: null,
  }),
  [actions.TRIGGER_OPEN_ADD_MODAL]: (state, action: AnyAction) => ({
    ...state,
    triggerOpenAddCounter: state.triggerOpenAddCounter + 1,
  }),
  [actions.TRIGGER_FETCHING_ORDERS]: (state, action: AnyAction) => ({
    ...state,
    triggerOrdersFetchCounter: state.triggerOrdersFetchCounter + 1,
  }),
  [actions.CLEAR_OPEN_ADD_MODAL_COUNT]: (state, action: AnyAction) => ({
    ...state,
    triggerOpenAddCounter: 0,
  }),
  [actions.PURCHASE_ORDERS_SET_ASSIGNABLE_STATUSES]: (
    state,
    action: AnyAction
  ) => ({
    ...state,
    assignToShipmentStatuses: action.payload,
  }),
  [actions.PURCHASE_ORDERS_CHECK_UNIQUE_SUCCESS]: (
    state,
    action: AnyAction
  ) => ({
    ...state,
    isUniqueOrderNumber: action.payload,
  }),
  [actions.PURCHASE_ORDERS_CREATE_SUCCESS]: (state, action: AnyAction) => ({
    ...state,
    singlePurchaseOrder: action.payload,
  }),
  [actions.RESET_INFO_REQUESTS_SUCCESS]: (state, action: AnyAction) => ({
    ...state,
    list: replaceWith(state.list, action.payload),
  }),
  [actions.TOGGLE_SELECTED]: (state, action: AnyAction) => ({
    ...state,
    selectedLineIds: toggleSelected(state.selectedLineIds, action.payload),
  }),
  [actions.SET_SELECTED]: (state, action: AnyAction) => ({
    ...state,
    selectedLineIds: action.payload,
  }),
})

import { FunctionComponent } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Table from '@mui/material/Table'
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import BlankTable from '../BlankTable'
import ShipmentRow from './components/ShipmentRow'
import schemas from './schemas'

const ReportsDashboardTable: FunctionComponent<{}> = () => {
  const theme = useTheme()
  const isScreenSmall = useMediaQuery(theme.breakpoints.down('md'))

  const { isLoading, shipments, currentScope } = useSelector(
    (state: IGlobalState) => ({
      isLoading: state.reportsDashboard.isLoading,
      shipments: state.reportsDashboard.shipments,
      currentScope: state.reportsDashboard.scope,
    }),
    shallowEqual
  )

  if (!currentScope) {
    return null
  }

  if (isLoading) {
    return <BlankTable />
  }

  return (
    <TableContainer
      className="no-scroll"
      component={(props) => <Paper {...props} square elevation={0} />}
    >
      <Table size={isScreenSmall ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            {Object.keys(schemas[currentScope]).map((key) => (
              <TableCell
                key={key}
                className={schemas[currentScope][key].customClassName}
              >
                {schemas[currentScope][key].name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {shipments.map((shipment) => (
            <ShipmentRow
              shipment={shipment}
              key={shipment.unique_id}
              currentScope={currentScope}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ReportsDashboardTable

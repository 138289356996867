import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { push } from 'connected-react-router'
import { stringify } from 'query-string'
import { includes, isEmpty } from 'lodash'

export default function useGoBack() {
  const dispatch: Dispatch = useDispatch()
  const { scopeParams, shipment } = useSelector((state: IGlobalState) => ({
    scopeParams: state.shipments.scopeParams,
    shipment: state.shipmentOverview,
  }))

  const isQuote: boolean = useMemo(
    (): boolean => includes(['QUO', 'PQUO'], shipment.status),
    [shipment]
  )

  const goBack = useCallback((): void => {
    if (isQuote) {
      dispatch(
        push({
          pathname: '/quotes',
        })
      )
    } else {
      if (scopeParams && scopeParams.filters && !isEmpty(scopeParams.filters)) {
        scopeParams.filters = stringify(scopeParams.filters, {
          arrayFormat: 'bracket',
        })
      }
      dispatch(
        push({
          pathname: '/shipments',
          search: stringify(scopeParams || {}, { arrayFormat: 'bracket' }),
        })
      )
    }
  }, [scopeParams, isQuote])

  return goBack
}

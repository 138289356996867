export const conditionOptions = [
  {
    id: 'departing_from_port',
    label: 'Port of loading',
  },
  {
    id: 'arriving_to_port',
    label: 'Port of discharge',
  },
  {
    id: 'departing_from_country',
    label: 'Departing from country',
  },
  {
    id: 'arriving_in_country',
    label: 'Arriving in country',
  },
  {
    id: 'consignee_is',
    label: 'Consignee',
  },
  {
    id: 'shipper_is',
    label: 'Shipper',
  },
  {
    id: 'modality_is',
    label: 'Modality',
  },
]

export const conditionDirectionOptions = [
  { id: 'is', label: 'Is' },
  { id: 'is_not', label: 'Is not' },
]

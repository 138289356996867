import LocationIcon from '@mui/icons-material/LocationOn'
import { StyledStepBox } from './SABSearchItem.styles'

const SABSearchItemTimelineLocationIcon = () => {
  return (
    <StyledStepBox>
      <LocationIcon />
    </StyledStepBox>
  )
}
export default SABSearchItemTimelineLocationIcon

import { AxiosError } from 'axios'
import QueryString from 'query-string'
import { datadogRum } from '@datadog/browser-rum'
import {
  CLIENT_ERRORS,
  LOCAL_STORAGE_CREDENTIAL_KEY,
  SERVER_ERRORS,
} from 'src/config/constants'
import { store } from 'src/shyppleStore'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { shyppleGa } from 'src/utils'
import { redirectToLogin } from 'src/utils/navigation'

const isProduction: boolean = window.location.href.includes('app.shypple.com')

export const createQueryString = (
  queryObject: object,
  options: QueryString.StringifyOptions = {}
) => {
  const queryString = QueryString.stringify(queryObject, {
    skipEmptyString: true,
    skipNull: true,
    arrayFormat: 'bracket',
    ...options,
  })
  return queryString ? '?' + queryString : ''
}

export const createQueryKeys = <T extends object>(endpoints: T) => {
  type EndpointKeysType = keyof typeof endpoints
  return Object.keys(endpoints).reduce((acc, key) => {
    acc[key] = key
    return acc
  }, {} as Record<EndpointKeysType, EndpointKeysType>)
}

// only for *.test.(ts/js) files
export const withBaseUrl = (path: string) =>
  new URL(path, `${window.shyppleConfig.apiUrl}/api/v1/`).toString()

/***********************************
 *                                 *
 *         Error Handlers          *
 *                                 *
 * *********************************/

export const showNotificationOnServerError = (error: AxiosError) => {
  if (error.message === 'canceled') return

  const { response: { status = 0, data } = {}, message, code = 0 } = error
  const errorMessages = {
    'Network Error': SERVER_ERRORS['network_error'],
    ECONNABORTED: SERVER_ERRORS['connection_aborted'],
  }
  const errorCodes = {
    500: SERVER_ERRORS['technical_error'],
    400: CLIENT_ERRORS.INVALID_REQUEST,
    401: CLIENT_ERRORS.UNAUTHORIZED,
    404: CLIENT_ERRORS.RESOURCE_NOT_FOUND,
  }

  const errorMessage =
    errorMessages[message] ||
    errorMessages[code] ||
    errorCodes[status] ||
    data?.message

  if (errorMessage)
    store.dispatch(
      showNotification({ message: errorMessage, severity: 'error' })
    )
  return errorMessage
}

export const handle404ServerError = (error: AxiosError) => {
  if (error.response?.status === 404) window.location.href = '/404'
}

export const handle401ServerError = (error: AxiosError) => {
  if (isProduction && error.response?.status === 401) {
    datadogRum.addError(error)
  }
}

export const clearUnauthorizedUserInfo = (error: AxiosError) => {
  if (
    error.response?.status === 401 &&
    error.config.url !== 'api/auth/sign_in'
  ) {
    localStorage.removeItem(LOCAL_STORAGE_CREDENTIAL_KEY)
    localStorage.removeItem('scopedOrganization')
    shyppleGa.clearUserData()
    document.cookie = 'jwt= ; expires = Thu, 01 Jan 1970 00:00:00 GMT'
    redirectToLogin()
  }
}

export const onRejected = (error: AxiosError) => {
  showNotificationOnServerError(error)
  handle404ServerError(error)
  handle401ServerError(error)
  clearUnauthorizedUserInfo(error)
  return Promise.reject(error)
}

import { FunctionComponent, memo, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  includes,
  groupBy,
  orderBy,
  keys,
  isEmpty,
  uniqBy,
  Dictionary,
} from 'lodash'

import { AvatarGroup } from 'src/stories/AvatarGroup'
import { Typography, ButtonBase, Box } from '@mui/material'
import ShipmentServicesWindow from 'src/components/ShipmentTimeline/ShipmentServicesWindow'
import { containerTypeDescription } from '../../utils/helpers'
import { permissionTo } from '../../utils'

import ShipmentMainInfo from './ShipmentMainInfo'
import ShipmentPortPickup from './ShipmentPortPickup'
import ShipmentPortDischarge from './ShipmentPortDischarge'
import ShipmentPortLoading from './ShipmentPortLoading'
import ShipmentPortDelivery from './ShipmentPortDelivery'

import './styles.scss'

interface IShipmentProps {
  shipment: IDetailedShipment
}

const Shipment: FunctionComponent<IShipmentProps> = memo(({ shipment }) => {
  const [show, setShow] = useState<boolean>(false)
  const history = useHistory()
  const { t } = useTranslation()

  const { user } = useSelector(
    (state: IGlobalState) => ({
      user: state.user,
    }),
    shallowEqual
  )

  if (shipment == null || isEmpty(shipment)) {
    return null
  }

  const hideVessel =
    !!shipment && includes(shipment.status_groups, 'sailing_to_be_announced')

  const renderContainers = () => {
    const shipmentType: string = shipment ? shipment.shipment_type : ''
    const containerType: string =
      shipment && shipment.container_type ? shipment.container_type : ''
    const containers: IContainer[] = shipment ? shipment.containers : []
    const groupContainers: Dictionary<IContainerIdentifier[]> | never[] =
      groupBy(containers, 'container_type.name') || {}
    const containerNames: string[] = orderBy(keys(groupContainers)) || []

    return (
      <span className="shipment__type-desc">
        {containerNames.map((name: string) => (
          <span key={name}>
            {groupContainers[name].length +
              ' x ' +
              containerTypeDescription(
                shipmentType,
                containerType || shipmentType === 'rail' ? name : null
              )}
          </span>
        ))}
      </span>
    )
  }

  const collaborators = uniqBy(
    shipment.collaborators,
    (collaborator) => collaborator.organization_id
  ).filter((collaborator) => {
    if (shipment.visibility_only) {
      if (
        collaborator.roles.length === 1 &&
        collaborator.roles[0].role === 'Visibility provider'
      ) {
        return false
      }
    }
    return collaborator.organization_id !== user.organizationId
  })

  const goToShipment = (event) => {
    const elementClassList = event.target.classList
    if (elementClassList.contains('blocked-open')) {
      return
    }
    if (event.ctrlKey || event.metaKey) {
      window.open(`/shipments/${shipment.id}`)
    } else {
      history.push({
        pathname: `/shipments/${shipment.id}`,
      })
    }
  }

  const showServicesWindow = () => {
    setShow(true)
  }

  const hideServicesWindow = () => {
    setShow(false)
  }

  return (
    <article className="shipment" data-testid="shipment-row">
      <ButtonBase
        sx={{ width: '100%' }}
        component={Box}
        data-testid="shipment-order-row"
        onClick={goToShipment}
      >
        <Box className="shipment__block">
          <ShipmentMainInfo shipment={shipment} />
          <Box className="shipment__ports">
            <div className="shipment__timeline">
              <Box
                className="shipment__timeline_progress"
                style={{ width: `${shipment.progress_percent + 1}%` }}
              />
            </div>
            <ShipmentPortPickup
              progressPercent={shipment.progress_percent}
              pickupEnabled={shipment.pickup_requested}
              addressName={shipment.pickup_address_name}
              visibilityOnly={shipment.visibility_only}
              showServicesWindow={showServicesWindow}
            />
            <ShipmentPortLoading
              progressPercent={shipment.progress_percent}
              loadingPortCode={shipment.loading_port_code}
              loadingPort={shipment.loading_port}
              estimatedDeparture={shipment.estimated_departure}
              type={shipment.type}
              specificType={shipment.shipment_type}
              status_groups={shipment.status_groups}
              id={shipment.id}
            />
            <ShipmentPortDischarge
              status_groups={shipment.status_groups}
              progressPercent={shipment.progress_percent}
              dischargePortCode={shipment.discharge_port_code}
              dischargePort={shipment.discharge_port}
              estimatedArrival={shipment.estimated_arrival}
              type={shipment.type}
              specificType={shipment.shipment_type}
              id={shipment.id}
            />
            <ShipmentPortDelivery
              deliveryEnabled={shipment.delivery_requested}
              addressName={shipment.delivery_address_name}
              progressPercent={shipment.progress_percent}
              visibilityOnly={shipment.visibility_only}
              showServicesWindow={showServicesWindow}
            />
          </Box>

          <Box className="shipment__sub-main">
            <header className="shipment__sub-title">
              <Typography className="medium normal" children={shipment.title} />
            </header>
            <div className="shipment__line shipment__line_sub-amount">
              <span className="shipment__logo">
                <Typography className="small light" component={'div'}>
                  {!hideVessel && (
                    <>
                      <div>{shipment.carrier_name}</div>
                      <div>{shipment.vessel}</div>
                    </>
                  )}
                </Typography>
              </span>
              <Typography className="small light">
                {renderContainers()}
              </Typography>
            </div>
          </Box>
          <Box className="shipment__indicators">
            {permissionTo('shipments.shipment_collaborators.view') && (
              <Box className="shipment__collaborators">
                <AvatarGroup
                  size="small"
                  avatars={collaborators.map((collaborator: ICollaborator) => ({
                    id: collaborator.organization_id.toString(),
                    alt: collaborator.name,
                    className: collaborator.organization_role_code,
                    src: collaborator.logo || '',
                  }))}
                  max={3}
                />
              </Box>
            )}
          </Box>
        </Box>
      </ButtonBase>
      <ShipmentServicesWindow
        open={show}
        shipmentId={shipment.id}
        onClose={hideServicesWindow}
        defaultServices={shipment.services}
        title={t('shipments.request_service_modal.title')}
        header={t('shipments.request_service_modal.description')}
        showSwitch={false}
      />
    </article>
  )
})

export default Shipment

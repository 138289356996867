import { FunctionComponent, useState, useRef } from 'react'
import Popper from '@mui/material/Popper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { MenuItem } from '@mui/material/'
import { MuiInputBase } from 'src/components/Common/Input/MuiInput'
import { v4 as getID } from 'uuid'
import { find, toNumber } from 'lodash'
import Input from 'src/components/Common/Input'
import './styles.scss'

interface IProps {
  value: string | number
  theme?: string
  label?: string
  name?: string
  onChange: ({ id }: { id: number }) => void
  onCancel: () => void
  options: Array<{ [x: string]: any }>
  searchable?: boolean
  // for custom input
  customInput?: boolean
  error?: boolean
}

const SinglePicker: FunctionComponent<IProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [isActive, setIsActive] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const searchBarRef = useRef<HTMLInputElement>(null)

  const elementId = `Selector_${getID()}`

  const onCancel = (): void => {
    setAnchorEl(null)
    setIsActive(false)
    setSearch('')
  }

  const onFocus = (event: React.FocusEvent<HTMLElement>) => {
    setTimeout(() => {
      if (searchBarRef && searchBarRef.current) {
        searchBarRef.current.focus()
      }
    }, 500)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
    setIsActive(true)
  }

  const clear = (event: React.MouseEvent): void => {
    if (event) {
      event.stopPropagation()
    }
    setAnchorEl(null)
    props.onCancel()
  }

  const getValue = (): string => {
    const option = find(props.options, { id: toNumber(props.value) })
    return option ? option.name : ''
  }

  const handleChangeSearch = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setSearch(event.target.value)
  }

  const handleChange = (event: React.ChangeEvent<any>): void => {
    if (props.onChange) {
      props.onChange({
        id: toNumber(event.target.id),
      })
    }
    onCancel()
  }

  const matchSearch = (option): boolean => {
    return !search || option.name.toLowerCase().includes(search.toLowerCase())
  }

  const filteredOptions = (props.options || []).filter(matchSearch)

  return (
    <div className={`common-single-picker ${props.theme || ''}`}>
      <div
        className={`common-single-picker--selector ${
          isActive ? 'active' : ''
        } ${props.value ? 'has-value' : ''}`}
        onClick={handleClick}
      >
        <div>
          {props.customInput && (
            <MuiInputBase
              error={props.error}
              fullWidth
              onFocus={onFocus}
              value={getValue()}
            />
          )}
          {!props.customInput && (
            <Input.Text
              label={props.label}
              className="full"
              onFocus={onFocus}
              value={getValue()}
            />
          )}
        </div>
        <i className="icon add" onClick={clear} />
        <i className="icon chevron" />
      </div>
      <Popper
        id={elementId}
        open={isActive}
        anchorEl={anchorEl}
        placement={'bottom-start'}
        className={`common-single-picker--popper`}
      >
        <ClickAwayListener onClickAway={onCancel}>
          <div className="common-single-picker--popper-wrapper">
            <div className="shypple-options-picker__search-container">
              {props.searchable && (
                <input
                  ref={searchBarRef}
                  className="shypple-options-picker__search"
                  placeholder=""
                  value={search}
                  onChange={handleChangeSearch}
                />
              )}
            </div>
            {!filteredOptions.length && (
              <div className="shypple-options-picker__empty-results">
                No results
              </div>
            )}
            {(filteredOptions || []).map((option) => (
              <MenuItem
                id={option.id}
                key={option.name}
                value={option.id}
                onClick={handleChange}
                classes={{
                  root: 'common-single-picker__search-item',
                  selected: 'shypple-options-picker__search-item--selected',
                }}
              >
                <div
                  id={option.id}
                  onClick={handleChange}
                  className="shypple-options-picker__option-name"
                >
                  {option.name}
                </div>
              </MenuItem>
            ))}
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

export default SinglePicker

import { FunctionComponent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { times } from 'lodash'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import ServiceLevelAgreementsRow from 'src/components/OrganizationSettings/SLA/ServiceLevelAgreementsRow'
import { dummySLA } from '../../../../stores/reducers/organizationSettings'

interface IPurchaseOrderTableProps {
  busy: boolean
  fetchData: () => void
  setCurrentSLA: (slaCheckpoint: ISLA) => void
  deleteOpenedSLA: (slaCheckpoint: ISLA) => void
}

const ServiceLevelAgreementsTable: FunctionComponent<IPurchaseOrderTableProps> = (
  props
) => {
  const { t } = useTranslation()
  const { slaCheckpoints } = useSelector((state: IGlobalState) => ({
    slaCheckpoints: state.organizationSettings.list,
  }))

  const editSLACheckpoint = useCallback(
    (slaCheckpoint: ISLA): void => {
      props.setCurrentSLA(slaCheckpoint)
    },
    [slaCheckpoints]
  )

  const deleteSLACheckpoint = useCallback(
    (slaCheckpoint: ISLA): void => {
      props.deleteOpenedSLA(slaCheckpoint)
    },
    [slaCheckpoints]
  )

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="short"></TableCell>
            <TableCell className="left-aligned x-medium">
              {t('address_book.add_address.type', 'Type')}
            </TableCell>
            <TableCell className="left-aligned medium">
              {t('address_book.add_address.name', 'Name')}
            </TableCell>
            <TableCell className="left-aligned x-medium">
              {t('address_book.add_address.timing', 'Timing')}
            </TableCell>
            <TableCell className="left-aligned medium">
              {t('address_book.add_address.owner', 'Owner')}
            </TableCell>
            <TableCell className="left-aligned medium">
              {t('address_book.add_address.created_at', 'Created at')}
            </TableCell>
            <TableCell className="short"></TableCell>
          </TableRow>
        </TableHead>
        {props.busy &&
          times(10, (i) => (
            <ServiceLevelAgreementsRow
              slaCheckpoint={dummySLA}
              key={i}
              blank={true}
              editSLACheckpoint={editSLACheckpoint}
              deleteSLACheckpoint={deleteSLACheckpoint}
              fetchData={props.fetchData}
            />
          ))}
        {!props.busy &&
          slaCheckpoints.map((item: ISLA, index: number) => (
            <ServiceLevelAgreementsRow
              slaCheckpoint={item}
              key={item.id}
              blank={false}
              editSLACheckpoint={editSLACheckpoint}
              deleteSLACheckpoint={deleteSLACheckpoint}
              fetchData={props.fetchData}
            />
          ))}
      </Table>
    </>
  )
}

export default ServiceLevelAgreementsTable

import { createReducer, Reducer } from 'redux-create-reducer'
import { AnyAction } from 'redux'
import {
  RECEIVED_SHIPMENT_QUOTATION,
  BOOKED_SHIPMENT_QUOTATION,
  REQUESTED_SHIPMENT_QUOTATION,
  BOOK_SHIPMENT_QUOTATION,
} from '../actions/shipmentQuotations'

interface IPort {
  name: string
  code: string
}

type IShortDayName = 'mon' | 'tue' | 'wen' | 'thu' | 'fri' | 'sat' | 'sun'
interface IRevenueItem {
  service_item_name: string
  description: string
  quantity: number
  unit_price: string
  currency: string
  total_value_eur: string
  total_value_usd: string
}
declare global {
  interface IRevenue {
    name: string
    icon_class: string | null
    items: IRevenueItem[]
  }

  interface IShipmentQuotation {
    id: number
    carrier: ICarrier
    loading_port: IPort
    discharge_port: IPort
    status: string
    transit_time: string
    transit_remarks: string | null
    departure_days: IShortDayName[]
    quote_expiration_at: string
    shipment_booked_at: string | null
    transit_type: 'direct' | 'indirect' | 'express'
    costs_total_usd: string
    costs_total_eur: string
    proposed_revenues: IRevenue[]
    cheapest: boolean
  }
  interface IShipmentQuotationReducerState {
    isFetching: boolean
    isBooking: boolean
    items: IShipmentQuotation[]
  }
}

export const initialShipmentQuotationState = {
  isFetching: false,
  isBooking: false,
  items: [],
}

const quotationsRequested: Reducer<
  IShipmentQuotationReducerState,
  AnyAction
> = (state) => ({
  ...state,
  isFetching: true,
})

const quotationsReceived: Reducer<IShipmentQuotationReducerState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  items: action.payload.map((quotation: IShipmentQuotation) => ({
    ...quotation,
    cheapest:
      quotation.id ===
      [...action.payload].sort(
        (a: IShipmentQuotation, b: IShipmentQuotation) =>
          parseFloat(a.costs_total_eur) - parseFloat(b.costs_total_eur)
      )[0].id,
  })),
  isFetching: false,
})

const quotationBooked: Reducer<IShipmentQuotationReducerState, AnyAction> = (
  state
) => ({
  ...state,
  isBooking: false,
  items: [],
})

const bookQuotation: Reducer<IShipmentQuotationReducerState, AnyAction> = (
  state
) => ({
  ...state,
  isBooking: true,
})

export default createReducer(initialShipmentQuotationState, {
  [REQUESTED_SHIPMENT_QUOTATION]: quotationsRequested,
  [RECEIVED_SHIPMENT_QUOTATION]: quotationsReceived,
  [BOOK_SHIPMENT_QUOTATION]: bookQuotation,
  [BOOKED_SHIPMENT_QUOTATION]: quotationBooked,
})

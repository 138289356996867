import { FunctionComponent, useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon-business-days'
import { Typography, Box, Button, SelectChangeEvent } from '@mui/material'
import DatePicker from 'src/stories/DatePicker'
import DraggableList from 'src/stories/DraggableList'
import { orderBy } from 'lodash'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { AutoCompleteSelect as SearchBar } from 'src/stories/MUI/Select/AutoCompleteSelect'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import ModalitySelect from 'src/components/Common/ModalitySelect'
import {
  getAllConnections,
  pickupsAndDeliveriesUpdate,
  pickupsAndDeliveriesGetData,
  pickupsAndDeliveriesSetOpenAddressIndex,
} from 'src/stores/actionCreators'
import FormLabel from 'src/stories/Lab/FormLabel'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { promisifyAction, permissionTo } from 'src/utils'

import { ModalityEnum } from 'src/config/constants'
import { statusOptions, AddressBookTypeOptionsEnum } from '../../constants'
import AddressCard from '../InlandTransport/AddressCard'

type IProps = {
  inlandTransport: ISingleInlandTransport
}

const newAddressAttrs = (existingAddressesCount?: number | undefined) => {
  return {
    id: '',
    datetime_from: '',
    datetime_to: '',
    reference: '',
    address_id: 0,
    comment: '',
    position: existingAddressesCount ? existingAddressesCount + 1 : 0,
    address_type: '',
    pickup_delivery: false,
    address_book_type: AddressBookTypeOptionsEnum.CustomersAddressBook,
    _destroy: false,
  }
}

const formatInlandTransport = (inlandTransport) => {
  return {
    id: inlandTransport?.id || '',
    transporter: inlandTransport ? inlandTransport.transporter : null,
    transporter_organization_id: inlandTransport?.transporter?.id || '',
    modality: inlandTransport?.modality || '',
    status: inlandTransport?.status || '',
    delivery_inspection_datetime:
      inlandTransport?.delivery_inspection_datetime || null,
    inland_transport_addresses_attributes:
      inlandTransport?.inland_transport_addresses || [],
  }
}

const InlandTransportForm: FunctionComponent<IProps> = ({
  inlandTransport,
}) => {
  const dispatch = useDispatch()

  const { filters } = useSelector(
    (state: IGlobalState) => ({
      filters: state.pickUpsAndDeliveries.filters,
    }),
    shallowEqual
  )

  const fullOrLimitedPermission = useMemo(
    () =>
      permissionTo([
        'pickups_deliveries.limited_manage',
        'pickups_deliveries.full_manage',
        'pickups_deliveries.shypple_admin',
      ]),
    []
  )

  const manageOrAdminPermission = useMemo(() => {
    return permissionTo([
      'pickups_deliveries.full_manage',
      'pickups_deliveries.shypple_admin',
    ])
  }, [])

  const [formData, setFormData] = useState(
    formatInlandTransport(inlandTransport)
  )
  const getInlandTransports = promisifyAction(
    dispatch,
    pickupsAndDeliveriesGetData
  )
  const getTransporter = promisifyAction(dispatch, getAllConnections)
  const pickupsAndDeliveriesUpdateAsync = promisifyAction(
    dispatch,
    pickupsAndDeliveriesUpdate
  )

  useEffect(() => {
    setFormData(formatInlandTransport(inlandTransport))
  }, [inlandTransport])

  const addBlankAddress = () => {
    const newAddress = newAddressAttrs(
      formData.inland_transport_addresses_attributes.length
    )
    const updatedArr = [
      ...formData.inland_transport_addresses_attributes,
      newAddress,
    ]
    setFormData((prevLocalState) => {
      return {
        ...prevLocalState,
        inland_transport_addresses_attributes: updatedArr,
      }
    })
    dispatch(pickupsAndDeliveriesSetOpenAddressIndex(updatedArr.length - 1))
  }

  const onChangeAddress = ({ index, name, value }) => {
    setFormData((prevLocalState) => {
      const updatedArr = prevLocalState.inland_transport_addresses_attributes.map(
        (item, i) => {
          if (index === i) {
            item[name] = value
            if (name === 'address') {
              item.address_id = value.id
            }
          }
          return item
        }
      )

      return {
        ...prevLocalState,
        inland_transport_addresses_attributes: updatedArr,
      }
    })
  }

  const removeAddress = (index: number): void => {
    onChangeAddress({ index, name: '_destroy', value: true })
  }

  const onTransporterChange = (value) => {
    setFormData((prevLocalState) => {
      return {
        ...prevLocalState,
        transporter: value,
        transporter_organization_id: value.organization_id,
      }
    })
  }

  const onChangeModality = (modality: ModalityEnum) => {
    setFormData({
      ...formData,
      modality,
    })
  }

  const onChangeStatus = (event: SelectChangeEvent<unknown>) => {
    setFormData({
      ...formData,
      status: event.target.value,
    })
  }

  const onSave = async () => {
    try {
      await pickupsAndDeliveriesUpdateAsync(formData.id, formData)
      dispatch(
        showNotification({
          message: 'Transport address updated.',
          severity: 'success',
        })
      )
      dispatch(pickupsAndDeliveriesSetOpenAddressIndex(null))
      getInlandTransports(filters)
    } catch {}
  }

  const onMoveItem = ({ destination, source }) => {
    if (!fullOrLimitedPermission) {
      return
    }

    setFormData((prevLocalState) => {
      const result = Array.from(
        prevLocalState.inland_transport_addresses_attributes
      )
      const [removed] = result.splice(source.index, 1)
      result.splice(destination.index, 0, removed)

      const updatedArr = result.map((item: any, index) => {
        item.position = index + 1
        return item
      })

      return {
        ...prevLocalState,
        inland_transport_addresses_attributes: updatedArr,
      }
    })
  }

  const addressesValid = useMemo(() => {
    return formData.inland_transport_addresses_attributes.every(
      (item) => !!item.address_id && !!item.address_type
    )
  }, [formData.inland_transport_addresses_attributes])

  const onInspectionChange = (date: DateTime | null) => {
    setFormData({
      ...formData,
      delivery_inspection_datetime: date?.toISO(),
    })
  }

  const showDelete = useMemo(
    () =>
      formData.inland_transport_addresses_attributes.some((x) => !x._destroy),
    [formData.inland_transport_addresses_attributes]
  )
  const { t } = useTranslation()
  return (
    <>
      <Box sx={{ mt: 3, mb: 2, width: 670 }}>
        <Typography variant="h5">
          {t(
            'transports.drawer.inland_transport_information',
            'Inland transport information'
          )}
        </Typography>
        <Typography variant="body1">
          {t(
            'transports.drawer.inland_transport_information_description',
            'Complete inland transport information and addresses before sending the transport order.'
          )}
        </Typography>
        <Box sx={{ mt: 2, display: 'flex' }}>
          {permissionTo([
            'pickups_deliveries.shypple_admin',
            'pickups_deliveries.full_manage',
          ]) && (
            <Box sx={{ mr: 2, width: 338 }}>
              <FormLabel
                label={t('shipments.filters.transports', 'Transporter')}
              />
              <SearchBar
                id="transporter"
                optionLabel={(opt) => opt?.name || ''}
                optionsKey="list"
                getData={(search) =>
                  getTransporter({ search, role: 'Transporter', page: 1 })
                }
                onChange={onTransporterChange}
                value={formData.transporter}
                placeholder={t(
                  'transports.drawer.transport_name',
                  'Transport name'
                )}
                disableClearable={true}
              />
            </Box>
          )}

          <Box sx={{ mr: 2, width: 150 }}>
            <ModalitySelect
              inlandOnly={true}
              disablePortal={true}
              defaultValue={formData.modality}
              disabled={!fullOrLimitedPermission}
              onChangeModality={onChangeModality}
            />
          </Box>
          <Box sx={{ width: 150 }}>
            <SingleSelect
              value={formData.status}
              label={t('transports.drawer.status', 'Status')}
              placeholder={t('common.select', 'Select')}
              options={statusOptions}
              onChange={onChangeStatus}
              disabled={!manageOrAdminPermission}
              MenuProps={{ disablePortal: true }}
              data-testid="inland-transport-status-select"
              inputProps={{
                'data-testid': 'inland-transport-status-select-input',
              }}
            />
          </Box>
        </Box>
        {manageOrAdminPermission &&
          inlandTransport.container.container_type
            .equipment_controlled_atmosphere && (
            <Box sx={{ mt: 2, display: 'inline-block' }}>
              <DatePicker
                closeOnSelect={false}
                label={t(
                  'transports.drawer.inspection_date_time',
                  'Inspection date & time'
                )}
                placeholder="DD-MM-YYYY HH:MM"
                inputFormat="dd-MM-yyyy HH:mm"
                clearable={false}
                value={formData.delivery_inspection_datetime}
                onChange={onInspectionChange}
              />
            </Box>
          )}
      </Box>
      <Box sx={{ mt: 3, mb: 2, width: 670 }}>
        <Typography variant="h5">Addresses</Typography>
        <Typography variant="body1">
          {t(
            'transports.drawer.address_description',
            'Add addresses for pickup and delivery of the container.'
          )}
        </Typography>
        <Box sx={{ mt: 2 }} data-testid="inland-transport-addresses">
          <DraggableList
            movedItem={onMoveItem}
            items={orderBy(
              formData.inland_transport_addresses_attributes,
              'position'
            ).map((address, index) => {
              if (
                address._destroy ||
                (!manageOrAdminPermission && !address.pickup_delivery)
              ) {
                return <></>
              } else {
                return (
                  <AddressCard
                    index={index}
                    data={address}
                    destroyAddress={removeAddress}
                    onChangeAddress={onChangeAddress}
                    showDelete={showDelete}
                    inlandTransport={inlandTransport}
                  />
                )
              }
            })}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 3,
          mb: 5,
          width: 670,
        }}
      >
        {fullOrLimitedPermission && (
          <>
            {manageOrAdminPermission && (
              <Button
                variant="text"
                color="primary"
                size="medium"
                onClick={addBlankAddress}
                startIcon={<AddRoundedIcon />}
              >
                {t('address_book.connections.add_address', 'Add address')}
              </Button>
            )}

            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={onSave}
              disabled={!addressesValid}
              data-testid="inland-transport-save-button"
            >
              {t('common.save', 'Save')}
            </Button>
          </>
        )}
      </Box>
    </>
  )
}
export default InlandTransportForm

import { FunctionComponent } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { updateUrl } from 'src/components/Filters/utils'
import Tabs from 'src/stories/Tabs'
import Input from 'src/components/Common/Input'

import {
  pickupsAndDeliveriesGetData,
  pickupsAndDeliveriesUpdateFilters,
  pickupsAndDeliveriesSelectedIds,
} from 'src/stores/actionCreators'

import { t } from 'i18next'
import { TABS, sortingOptions } from '../constants'

const PickupsAndDeliveriesTabs: FunctionComponent = () => {
  const dispatch = useDispatch()

  const { scope, order_by, filters } = useSelector(
    (state: IGlobalState) => ({
      scope: state.pickUpsAndDeliveries.filters.scope,
      order_by: state.pickUpsAndDeliveries.filters.order_by,
      filters: state.pickUpsAndDeliveries.filters,
    }),
    shallowEqual
  )

  const updateFilters = (newFilters) => {
    dispatch(pickupsAndDeliveriesGetData({ ...filters, ...newFilters }))
    dispatch(pickupsAndDeliveriesUpdateFilters({ ...filters, ...newFilters }))
    updateUrl('inland_transports', { ...filters, ...newFilters })
  }

  const onScopeChange = (scope: string) => {
    updateFilters({ scope, page: 1 })
    dispatch(pickupsAndDeliveriesSelectedIds([]))
  }
  const onSortingChange = (order_by: string) => {
    window.localStorage.setItem('pickupDeliverySorting', order_by)
    updateFilters({ order_by, page: 1 })
  }

  return (
    <Tabs
      onChange={onScopeChange}
      orientation="horizontal"
      scrollButtons="auto"
      value={scope}
      tabs={TABS(t)}
      variant="standard"
    >
      <Input.DropDown
        theme="shipmentsPage"
        value={order_by}
        onChange={onSortingChange}
        options={sortingOptions(t)}
      />
    </Tabs>
  )
}

export default PickupsAndDeliveriesTabs

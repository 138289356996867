import { FunctionComponent, ReactNode } from 'react'
import Typography from '@mui/material/Typography'

import {
  formatDate,
  convertDateToUniversalFormat,
} from '../../../utils/helpers'
import { SHIPMENT_THRESHOLDS } from '../../../config/constants'

interface IShipmentPortDischargeProps {
  dischargePortCode: string
  dischargePort: string
  estimatedArrival: string
  type: string
  specificType?: string
  status_groups: string[]
  id: number
  progressPercent: number
}

const ShipmentPortDischarge: FunctionComponent<IShipmentPortDischargeProps> = (
  props
) => {
  const nodeIcon: string = props.type === 'air' ? 'air-arrive' : 'cargo'
  const completedStyle: string =
    props.progressPercent >= SHIPMENT_THRESHOLDS.arrival
      ? 'shipment__port-node_completed'
      : ''
  const seafreightRequested: boolean = !!props.dischargePortCode

  let content: ReactNode = 'Seafreight Available'
  let contentDesktop: ReactNode = 'Seafreight Available'
  let dateLine: string = ''
  let desktopTitleDate: ReactNode = ''

  if (seafreightRequested) {
    dateLine = 'Unknown yet'

    content = props.dischargePort

    contentDesktop = (
      <span className="shipment__port-name">{props.dischargePort}</span>
    )

    if (props.estimatedArrival) {
      dateLine = `ETA: ${formatDate(props.estimatedArrival)}`
    }
    desktopTitleDate = (
      <div className="shipment__port-date paragraph__medium lightblue strong">
        {!props.estimatedArrival
          ? 'TBA'
          : convertDateToUniversalFormat(props.estimatedArrival)}
      </div>
    )
  }

  return (
    <div className="shipment__port">
      <i
        className={`
         shipment__port-node
         shipment__port-node_middle
         shipment__port-node_${
           props.specificType === 'rail' ? 'rail' : nodeIcon
         }
         ${completedStyle}
        `}
      />
      <div className="shipment__port-mobile">
        <header className="shipment__port-id shipment__line">
          {seafreightRequested ? props.dischargePortCode : 'Not Included'}
        </header>
        <div className="shipment__line shipment__port-city">{content}</div>
        <div className="shipment__line">{dateLine}</div>
      </div>
      <div className="shipment__port-desktop">
        <header className="shipment__port-id shipment__line">
          <Typography className="small light" children="ETA" />
        </header>
        <div
          className="shipment__line shipment__port-city"
          id={`${props.id}-dis`}
        >
          {desktopTitleDate}
          <div className="shipment__port-name">
            <Typography className="medium normal" children={contentDesktop} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShipmentPortDischarge

import { FunctionComponent, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

import { setEditCommentsModalInformation } from 'src/stores/actionCreators'

import schemas from '../../schemas'

const ShipmentRow: FunctionComponent<any> = ({ shipment, currentScope }) => {
  const dispatch = useDispatch()

  const onClick = useCallback(
    (e) => {
      if (
        e.target.nodeName === 'A' ||
        e.currentTarget.className.includes('no-propagate')
      ) {
        return
      }
      dispatch(
        setEditCommentsModalInformation({
          shipment: shipment,
          open: true,
        })
      )
    },
    [shipment]
  )

  const classForTableRow = useCallback(() => {
    if (shipment.inland_transport_status === 'requested') {
      return 'warning'
    } else if (shipment.inland_transport_status === 'confirmed') {
      return 'success'
    } else {
      return ''
    }
  }, [shipment])

  return (
    <TableRow hover className={`no-pointer ${classForTableRow()}`}>
      {Object.keys(schemas[currentScope]).map((key) => (
        <TableCell
          onClick={onClick}
          key={`${shipment.id}_${key}`}
          className={schemas[currentScope][key].customClassName}
        >
          {schemas[currentScope][key].field(shipment)}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default ShipmentRow

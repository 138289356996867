export const TRACK_AND_TRACE_GET_DATA = 'TRACK_AND_TRACE_GET_DATA'
export const TRACK_AND_TRACE_GET_DATA_SUCCESS =
  'TRACK_AND_TRACE_GET_DATA_SUCCESS'
export const TRACK_AND_TRACE_RESUME = 'TRACK_AND_TRACE_RESUME'
export const TRACK_AND_TRACE_PAUSED = 'TRACK_AND_TRACE_PAUSED'
export const TRACK_AND_TRACE_USE_PORT_PAIR = 'TRACK_AND_TRACE_USE_PORT_PAIR'
export const TRACK_AND_TRACE_UPDATE_SUCCESS = 'TRACK_AND_TRACE_UPDATE_SUCCESS'
export const TRACK_AND_TRACE_CANCEL_AND_ARCHIVE =
  'TRACK_AND_TRACE_CANCEL_AND_ARCHIVE'
export const TRACK_AND_TRACE_SUBSCRIPTION_CREATE =
  'TRACK_AND_TRACE_SUBSCRIPTION_CREATE'
export const TRACK_AND_TRACE_SUBSCRIPTION_CREATE_SUCCESS =
  'TRACK_AND_TRACE_SUBSCRIPTION_CREATE_SUCCESS'

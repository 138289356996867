import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'

const QuickFiltersManageDrawerHeader = () => {
  const { t } = useTranslation()
  return (
    <Typography
      variant="body1"
      children={t(
        'filters.manage_quick_filters.description',
        'Quick filters allow you to instantly filter on specific attributes you’ve selected and saved. They are only visible to you.'
      )}
    />
  )
}

export default QuickFiltersManageDrawerHeader

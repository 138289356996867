import TopNavBar from 'src/components/TopNavigation'
import { toolbarHeight } from 'src/components/TopNavigation/TopNavigation.constants'
import { useState } from 'react'
import { parse, stringify } from 'qs'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PasswordContent from 'src/components/AccountInfo/PasswordContent'
import { Button, Typography, Paper, Box } from '@mui/material'
import { promisifyAction } from '../../utils'
import { userChangePassword } from '../../stores/actionCreators'
import { canUpdatePassword, PasswordModalState } from './validations'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const changePassword = promisifyAction(dispatch, userChangePassword)

  const [formData, setFormData] = useState<PasswordModalState>({
    password: '',
    confirm: '',
    current: 'bypass',
  })

  const onSave = async ({
    password,
    confirm,
  }: {
    password: string
    confirm: string
  }) => {
    await changePassword({ password, password_confirmation: confirm })
    const searchQuery = parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    if (searchQuery.invitation_token) {
      history.push({
        pathname: '/login',
        search: stringify({ email: searchQuery.uid, redirect: '/account' }),
      })
    } else {
      history.push({
        pathname: '/login',
        search: stringify({ email: searchQuery.uid }),
      })
    }
  }

  const onChange = (value: string | number, field: string) => {
    setFormData({ ...formData, [field]: value })
  }

  return (
    <>
      <TopNavBar />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100vw',
          height: `calc(100vh - ${toolbarHeight}px)`,
        }}
        onKeyUp={(e) => {
          if (e.code === 'Enter') {
            if (canUpdatePassword(formData)) {
              onSave(formData)
            }
          }
        }}
      >
        <Paper sx={{ padding: '20px 24px' }}>
          <Typography pb={3} variant="h2">
            Set your password
          </Typography>
          <PasswordContent
            formData={formData}
            onChange={onChange}
            currentPasswordRequired={false}
          />
          <Box pt={3} sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button component={Link} variant="outlined" to="/login">
              Back to log in
            </Button>
            <Button
              sx={{ ml: 2 }}
              data-testid="button-reset-password"
              variant="contained"
              disabled={!canUpdatePassword(formData)}
              onClick={() => onSave(formData)}
            >
              Set my password
            </Button>
          </Box>
        </Paper>
      </Box>
    </>
  )
}

export default ForgotPassword

import { uniq, filter } from 'lodash'
import isEmail from 'validator/lib/isEmail'

function addressesValidation(slaCheckpoint: ISLA) {
  const selectedAddresses = filter(
    slaCheckpoint.sla_checkpoint_addresses,
    (item) => !item._destroy
  )

  const addressesNames: boolean[] = selectedAddresses.map((address) => {
    return !!(address.address_id || (address.address ? address.address.id : 0))
  })

  const addressesEmails: boolean[] = selectedAddresses.map((address) => {
    const emailValue: string =
      address.address_attributes && address.address_attributes.email
        ? address.address_attributes.email
        : address.address
        ? address.address.email
        : ''
    return !!emailValue && isEmail(emailValue)
  })

  const updatedAddressesNamesArr = uniq(addressesNames)
  const updatedAddressesEmailsArr = uniq(addressesEmails)

  return (
    updatedAddressesNamesArr.length === 1 &&
    !!updatedAddressesNamesArr[0] &&
    updatedAddressesEmailsArr.length === 1 &&
    !!updatedAddressesEmailsArr[0]
  )
}

export { addressesValidation }

export const dateOptions = [
  {
    date_field: 'order_date',
    name: 'Order date',
    id: 1,
  },
  {
    date_field: 'cargo_ready_date',
    name: 'Cargo ready date',
    id: 2,
  },
]

export const daysOptions = [
  {
    name: 'Before',
    id: 1,
  },
  {
    name: 'After',
    id: 2,
  },
]

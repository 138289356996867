import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Menu, MenuItem, Box } from '@mui/material'
import { permissionTo } from 'src/utils'
import { StyledMenuButton } from './TopNavigation.styles'

interface Lang {
  code: string
  name: string
  flag: string
  show: () => boolean
}

const languages: Lang[] = [
  {
    code: 'en',
    name: 'English',
    flag: 'gb',
    show: () => true,
  },
  {
    code: 'nl',
    name: 'Nederlands',
    flag: 'nl',
    show: () => permissionTo('menu.account.application_filter'),
  },
  {
    code: 'fr',
    name: 'Français',
    flag: 'fr',
    show: () => permissionTo('menu.account.application_filter'),
  },
  {
    code: 'es',
    name: 'Español',
    flag: 'es',
    show: () => true,
  },
  {
    code: 'de',
    name: 'Deutsch',
    flag: 'de',
    show: () => permissionTo('menu.account.application_filter'),
  },
]

const getLang = (code: string) =>
  languages.find((lang) => lang.code === code) || languages[0]

const FlagImage = ({ code }: { code: string }) => (
  <Box mr="6px">
    <img
      loading="lazy"
      srcSet={`https://flagcdn.com/32x24/${code}.png 2x`}
      src={`https://flagcdn.com/16x12/${code}.png`}
      alt=""
    />
  </Box>
)

const LanguageSwitch = () => {
  const { i18n } = useTranslation()
  const [language, setLanguage] = useState(getLang(i18n.language))
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (lang?: Lang) => {
    setAnchorEl(null)
    if (!lang) return
    i18n.changeLanguage(lang.code)
    setLanguage(lang)
  }

  return (
    <>
      <StyledMenuButton size="large" onClick={handleClick}>
        <FlagImage code={language.flag} />
        {language.code.toUpperCase()}
      </StyledMenuButton>
      <Menu anchorEl={anchorEl} open={open} onClose={() => handleClose()}>
        {languages
          .filter((lng) => lng.show())
          .map((lang) => (
            <MenuItem
              selected={lang.code === language.code}
              key={lang.code}
              onClick={() => handleClose(lang)}
            >
              <FlagImage code={lang.flag} />
              {lang.name}
            </MenuItem>
          ))}
      </Menu>
    </>
  )
}

export default LanguageSwitch

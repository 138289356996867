import { useSelector, shallowEqual } from 'react-redux'
import { Badge, IconButton, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import { INotificationsButtonProps } from './Notifications.props'

const NotificationsButton: React.FC<INotificationsButtonProps> = ({
  onClick,
  iconProps,
  buttonProps,
}) => {
  const { t } = useTranslation()
  const { notificationCount } = useSelector(
    (state: IGlobalState) => ({
      notificationCount: state.navigationNotifications.count,
    }),
    shallowEqual
  )

  return (
    <Tooltip title={t('top_navigation.notifications.tooltip', 'Notifications')}>
      <IconButton
        color="inherit"
        size="large"
        onClick={onClick}
        data-testid="navigation-notifications-trigger"
        {...buttonProps}
      >
        <Badge badgeContent={notificationCount} color="info">
          <NotificationsNoneIcon {...iconProps} />
        </Badge>
      </IconButton>
    </Tooltip>
  )
}

export default NotificationsButton

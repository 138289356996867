import { useState } from 'react'
import Input, { NumberFormatCustom } from 'src/components/Common/Input/MuiInput'
import Delete from '@mui/icons-material/Delete'
import Refresh from '@mui/icons-material/Refresh'
import { useTranslation } from 'react-i18next'
import { ModalityEnum } from 'src/config/constants'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import { useSelector } from 'react-redux'
import { Box, IconButton, SelectChangeEvent } from '@mui/material'
import { useLocation } from 'react-router'
import { CargoWindowWarningMessageModal } from 'src/components/PickupAndDelivery/CargoWindow/CargoWindowWarningMessageModal'
import { CargoRow } from './styles'

interface IProps {
  cargo: IInitialCargoItem & {
    isCargoDisabled?: boolean
  }
  error?: {
    [key: string]: string
  }
  onChange: (params: { id: string; name: string; value: any }) => void
  modality?: string
}

export const Quantity = (props: IProps) => {
  const handleChange = (value, name) => {
    props.onChange({ id: props.cargo.id, name, value })
  }
  const error =
    props.error?.id === props.cargo.id ? props.error?.message : undefined
  return (
    <Input
      name="quantity"
      type="number"
      value={props.cargo.quantity || ''}
      disabled={props.cargo.isCargoDisabled}
      onChange={handleChange}
      error={!!error}
    />
  )
}

export const PackageTypes = (props: IProps) => {
  const { t } = useTranslation()
  const { packageTypes } = useSelector((state: IGlobalState) => ({
    packageTypes: state.packageTypes.list,
  }))

  const packageTypeOptions = packageTypes.map((packageType) => ({
    id: packageType.id,
    label: packageType.name,
  }))

  const handleChangeCargoPackageType = (event: SelectChangeEvent<unknown>) => {
    const value = packageTypes.find(
      (packageType) => packageType.id === event.target.value
    )
    props.onChange({
      id: props.cargo.id,
      name: 'packageType',
      value,
    })
  }

  const error =
    props.error?.id === props.cargo.id ? props.error?.message : undefined
  return (
    <SingleSelect
      error={!!error}
      placeholder={t(
        'shipment_containers.cargo_window.select_type',
        'Select type'
      )}
      options={packageTypeOptions}
      value={props.cargo.packageType.id}
      disabled={props.cargo.isCargoDisabled}
      onChange={handleChangeCargoPackageType}
    />
  )
}

export const Dimensions = (props: IProps) => {
  const handleChangeCargoDimensions = (value, name): void => {
    const commonExp: RegExp = /^\d+$/
    if (value && !commonExp.test(value)) {
      return
    }

    props.onChange({
      id: props.cargo.id,
      name,
      value: value.slice(0, 4),
    })
  }

  let error
  if (props.error?.id === props.cargo.id && props.error?.message) {
    error = props.error.message
  }

  const onInput = (e) => (e.target.value = e.target.value.slice(0, 4))
  const classes = CargoRow({ error })

  return (
    <Box>
      <Box className={classes.dimensions}>
        <Input
          className={classes.input}
          name="length"
          value={props.cargo.length || ''}
          onChange={handleChangeCargoDimensions}
          disabled={props.cargo.isCargoDisabled}
          placeholder="0"
          onInput={onInput}
          type="number"
          data-testid="dimensions-length"
        />
        <span>x</span>
        <Input
          className={classes.input}
          name="width"
          value={props.cargo.width || ''}
          onChange={handleChangeCargoDimensions}
          disabled={props.cargo.isCargoDisabled}
          placeholder="0"
          onInput={onInput}
          type="number"
          data-testid="dimensions-width"
        />
        <span>x</span>
        <Input
          className={classes.input}
          name="height"
          value={props.cargo.height || ''}
          onChange={handleChangeCargoDimensions}
          disabled={props.cargo.isCargoDisabled}
          placeholder="0"
          onInput={onInput}
          type="number"
          data-testid="dimensions-height"
        />
        <span className="unit">cm</span>
      </Box>
    </Box>
  )
}

export const Volume = (props: IProps) => {
  const handleChange = (value, name) => {
    if (Number(value) <= 99999.99 || !value) {
      props.onChange({ id: props.cargo.id, name, value })
    }
  }

  const error =
    props.error?.id === props.cargo.id ? props.error?.message : undefined
  const isAir: boolean = props.modality === ModalityEnum.Air
  return (
    <Input
      name="total_volume_cbm"
      className="input-field"
      placeholder="0.00"
      value={props.cargo.total_volume_cbm}
      onChange={handleChange}
      error={!!error}
      disabled={
        props.cargo.isCargoDisabled ||
        isAir ||
        !!(props.cargo.length && props.cargo.width && props.cargo.height)
      }
      inputComponent={NumberFormatCustom as any}
      endAdornment={
        <div>
          m<sup>3</sup>
        </div>
      }
      data-testid="volume-field"
    />
  )
}

export const Weight = (props: IProps) => {
  const handleChange = (value, name) => {
    props.onChange({ id: props.cargo.id, name, value })
  }
  const error =
    props.error?.id === props.cargo.id ? props.error?.message : undefined
  return (
    <Input
      name="total_weight_kg"
      className="input-field"
      placeholder="0.00"
      value={props.cargo.total_weight_kg}
      disabled={props.cargo.isCargoDisabled}
      onChange={handleChange}
      error={!!error}
      inputComponent={NumberFormatCustom as any}
      endAdornment={<div>kg</div>}
      data-testid="weight-field"
    />
  )
}

export const Description = (props: IProps) => {
  const { t } = useTranslation()
  const handleChange = (value, name) => {
    props.onChange({ id: props.cargo.id, name, value })
  }
  const error =
    props.error?.id === props.cargo.id ? props.error?.message : undefined
  return (
    <Input
      name="goods_description"
      className="input-field"
      placeholder={t(
        'shipment_containers.cargo_window.add_description',
        'Add description'
      )}
      value={props.cargo.goods_description || ''}
      onChange={handleChange}
      error={!!error}
      data-testid="description-field"
    />
  )
}

export const Stackable = (props: IProps) => {
  const { t } = useTranslation()
  const cargoStackableOptions = [
    { id: '', value: null, label: '-' },
    { id: 'yes', value: true, label: t('common.buttons.yes', 'Yes') },
    { id: 'no', value: false, label: t('common.buttons.no', 'No') },
  ]
  const stackableValue =
    cargoStackableOptions.find(
      (option) => option.value === props.cargo.stackable
    )?.id ?? ''

  const handleStackableChange = (value: string) => {
    props.onChange({
      id: props.cargo.id,
      name: 'stackable',
      value: cargoStackableOptions.find((option) => option.id === value)?.value,
    })
  }

  const error = props.error?.id === props.cargo.id && !!props.error?.message

  return (
    <SingleSelect
      placeholder={t('shipment_containers.cargo_window.select', 'Select')}
      value={stackableValue}
      options={cargoStackableOptions}
      onChange={(event) => {
        handleStackableChange(event.target.value as string)
      }}
      error={error}
      data-testid="stackable-field"
      disabled={props.cargo.isCargoDisabled}
    />
  )
}

interface IRefreshProps extends Pick<IProps, 'cargo'> {
  onClick: (cargo: IInitialCargoItem) => Promise<void> | void
  cargoLength: number
}

export const RefreshRow = (props: IRefreshProps) => {
  const [displayConfirmation, setDisplayConfirmation] = useState<boolean>(false)

  const confirmDeleteCargoLine = async () => {
    await props.onClick(props.cargo)
    setDisplayConfirmation(false)
  }

  const { pathname } = useLocation()
  const removeCargo = (): void => {
    if (!props.cargo.new && !pathname.includes('/search')) {
      setDisplayConfirmation(true)
    } else {
      props.onClick(props.cargo)
    }
  }

  const DeletePrompt = () => (
    <CargoWindowWarningMessageModal
      open={displayConfirmation}
      onSave={confirmDeleteCargoLine}
      onClose={() => setDisplayConfirmation(false)}
    />
  )

  if (props.cargoLength === 1) {
    return (
      <>
        <IconButton
          disabled={props.cargo.isCargoDisabled}
          color="primary"
          onClick={removeCargo}
          size="large"
        >
          <Refresh />
        </IconButton>
        <DeletePrompt />
      </>
    )
  }
  return (
    <>
      <IconButton
        disabled={props.cargo.isCargoDisabled}
        color={'primary'}
        onClick={removeCargo}
        size="large"
      >
        <Delete />
      </IconButton>
      <DeletePrompt />
    </>
  )
}

import { NavLink } from 'react-router-dom'
import { MenuItemProps } from '@mui/material'
import { MenuItemLinkProps } from 'src/components/TopNavigation/TopNavigation.props'
import { StyledMenuItem } from 'src/components/TopNavigation/TopNavigation.styles'
import TopNavigationMenuItemContent from './TopNavigationMenuItemContent'

interface TopNavigationMenuMenuItemProps {
  item: MenuItemLinkProps
  sx?: MenuItemProps['sx']
  onClick: () => void
}

const TopNavigationMenuItem: React.FC<TopNavigationMenuMenuItemProps> = ({
  onClick,
  item,
  sx,
}) => {
  return (
    <StyledMenuItem
      sx={sx}
      to={item.to}
      onClick={onClick}
      component={NavLink}
      activeClassName="active"
      exact={item.key === 'shipments'}
      data-testid={`top-nav-${item.key}-menu-link-item`}
    >
      <TopNavigationMenuItemContent icon={item.icon} text={item.label} />
    </StyledMenuItem>
  )
}

export default TopNavigationMenuItem

import { FunctionComponent, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { debounce, isEmpty, pick } from 'lodash'

import { Box, Typography, Tooltip } from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Input from 'src/components/Common/Input/MuiInput'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { AutoCompleteSelect } from 'src/stories/MUI/Select/AutoCompleteSelect'
import { getVisibilityShipmentCarriers } from 'src/stores/actionCreators'
import InlineNotification from 'src/components/Common/InlineNotification'
import { useGetCarriersAsync } from 'src/services/Api/common'
import { useGetIsUniqueTrackingKeyAsync } from 'src/services/Api/shipments'
import { useFormContext } from 'src/components/Templates/Form/FormContext'
import SupportedCarriers from './SupportedCarriers'

const useStyles = makeStyles((theme) => ({
  fluid: {
    maxWidth: 'none',
  },
  trackingKeysInput: {
    backgroundColor: theme.palette.common.white,
  },
  modalTrackingSection: {
    outline: `1px solid ${theme.palette.grey[200]}`,
    border: `16px solid ${theme.palette.grey[50]}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[50],
  },
}))

const TrackingInformation: FunctionComponent = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    formState,
    formErrors,
    onChange,
    onFormErrorChange,
  } = useFormContext()

  const { fetchAsync: getCarriers } = useGetCarriersAsync()
  const { fetchAsync: getUniqueTrackingKey } = useGetIsUniqueTrackingKeyAsync()

  useEffect(() => {
    ;(async () => {
      dispatch(
        getVisibilityShipmentCarriers({
          carrier_type: 'carrier',
        })
      )
    })()
  }, [])

  useEffect(() => {
    const validationAsync = async () => {
      const resp = await getUniqueTrackingKey({
        bl_number: formState.bl_number,
        booking_number: formState.booking_number,
        container_number: formState.container_number,
        carrier_id: formState.carrier?.id,
      })

      onFormErrorChange({
        ...formErrors,
        ...resp,
      })
    }
    if (
      !isEmpty(
        pick(formState, [
          'booking_number',
          'bl_number',
          'container_number',
          'carrier',
        ])
      )
    ) {
      validationAsync()
    }
  }, [
    formState.booking_number,
    formState.bl_number,
    formState.container_number,
    formState.carrier,
  ])

  const delayedOnTrackingKeyChange = useCallback(
    debounce(async (searchQuery: string, field: string) => {
      onChange({
        [field]:
          field === 'container_number'
            ? searchQuery.toUpperCase()
            : searchQuery,
      })
      if (
        field === 'container_number' &&
        searchQuery.length > 3 &&
        !formState.carrier
      ) {
        const carriers = await getCarriers({
          container_number: searchQuery.toUpperCase(),
        })

        if (carriers?.length) {
          onChange({ carrier: carriers[0] })
        }
      }
    }, 500),
    [formState, formErrors]
  )

  const renderInput = useCallback(
    (
      label: string,
      field: string,
      placeholder: string = 'Enter value',
      disabled: boolean = false,
      required: boolean = false
    ): React.ReactNode => {
      return (
        <Input
          name={field}
          label={t(label, field)}
          value={formState[field]}
          required={required}
          onChange={delayedOnTrackingKeyChange}
          disabled={disabled}
          placeholder={t(placeholder, field)}
          error={!isEmpty(formErrors[field]) && !!formState[field]}
          helperText={formState[field] ? formErrors[field] : ''}
          sx={{ bgcolor: 'common.white' }}
        />
      )
    },
    [formState, formErrors]
  )

  const fetchCarriers = useCallback((searchInput: string) => {
    const fetchCarriersAsync = async (input: string): Promise<any[]> => {
      const carrierData = {
        search: input.toLowerCase(),
        carrier_type: 'carrier',
      }

      if (input) {
        const carriersList = await getCarriers(carrierData)
        return (
          carriersList?.map((carrier) => ({
            id: carrier.id,
            ocean_insights_coverage: carrier.ocean_insights_coverage,
            name: `${carrier.name} - ${carrier.scac}`,
          })) ?? []
        )
      } else {
        return []
      }
    }
    return fetchCarriersAsync(searchInput)
  }, [])

  return (
    <Box mt={3}>
      <Typography variant="h5">
        {t(
          'shipments.create_shipment.tracking_information.title',
          'Tracking Information'
        )}
      </Typography>
      <Typography variant="subtitle1" mt={1} sx={{ color: 'text.secondary' }}>
        {t(
          'shipments.create_shipment.tracking_information.description',
          'Please provide at least 1 tracking key. A booking or MBL number is preferred for the most accurate tracking.'
        )}
      </Typography>
      <Box mt={2} mb={2} className={classes.modalTrackingSection}>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box mr={2} sx={{ width: '33%' }}>
            {renderInput(
              'common.forms.fields.mbl_number.label',
              'bl_number',
              'common.forms.fields.mbl_number.placeholder'
            )}
          </Box>
          <Box mr={2} sx={{ width: '33%' }}>
            {renderInput(
              'common.forms.fields.booking_number.label',
              'booking_number',
              'common.forms.fields.booking_number.placeholder'
            )}
          </Box>
          <Box mr={2} sx={{ width: '33%' }}>
            {renderInput(
              'common.forms.fields.container_number.label',
              'container_number',
              'common.forms.fields.container_number.placeholder'
            )}
          </Box>
        </Box>
      </Box>
      <Box width={300}>
        <AutoCompleteSelect
          id="carrier"
          label={
            <div>
              {t('common.forms.fields.carrier.label', 'Carrier')}
              <Tooltip
                title={<SupportedCarriers />}
                placement="top-end"
                classes={{ tooltip: classes.fluid }}
              >
                <IconButton
                  size="small"
                  component="span"
                  disableRipple={true}
                  aria-label="more info"
                  disableFocusRipple={true}
                >
                  <InfoOutlinedIcon fontSize="inherit" color="inherit" />
                </IconButton>
              </Tooltip>
            </div>
          }
          required
          placeholder={t('common.forms.fields.carrier.placeholder', 'Carrier')}
          noOptionsText={t(
            'common.forms.fields.carrier.no_options_text',
            'Type to search'
          )}
          name="carrier"
          onChange={(option) => onChange({ carrier: option })}
          getData={fetchCarriers}
          value={formState.carrier}
          popupIcon={<KeyboardArrowDown />}
        />
      </Box>
      <Box mt={2}>
        <InlineNotification
          color="warning"
          show={formState.carrier && !formState.carrier.ocean_insights_coverage}
          message={t(
            'common.forms.fields.carrier.track_and_trace_not_supported',
            'Track and trace is not supported for this carrier. You can still create this shipment but wont receive any updates.'
          )}
        />
      </Box>
    </Box>
  )
}

export default TrackingInformation

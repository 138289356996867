import Box from '@mui/material/Box'
import CopyWrapper from 'src/stories/CopyWrapper'
import IconField from '../components/IconField'
import ShipmentField from '../components/ShipmentField'
import PortField from '../components/PortField'
import GenericDateField from '../components/GenericDateField'
import UserField from '../components/UserField'
import RollingStatusField from '../components/RollingStatusField'

export const rollingSchema = {
  icon: {
    field: (shipment: IRollingsShipment) => <IconField shipment={shipment} />,
    name: '',
    sortable: false,
    sortValue: '',
    customClassName: 'short-50',
  },
  shipment: {
    field: (shipment: IRollingsShipment) => (
      <ShipmentField showSpot={false} shipment={shipment} />
    ),
    name: 'SHIPMENT',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  status: {
    field: (shipment: IRollingsShipment) => (
      <RollingStatusField
        status={shipment.rolling_status}
        reason={shipment.rolling_reason}
      />
    ),
    name: 'STATUS',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned x-medium',
  },
  from: {
    field: (shipment: IRollingsShipment) => (
      <PortField
        port={shipment.loading_port}
        date={shipment.estimated_departure}
      />
    ),
    name: 'POL',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  to: {
    field: (shipment: IRollingsShipment) => (
      <PortField
        port={shipment.discharge_port}
        date={shipment.estimated_arrival}
      />
    ),
    name: 'POD',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  delay: {
    field: (shipment: IRollingsShipment) => (
      <Box>
        <Box className="red">{shipment.delay_days} days</Box>
        <GenericDateField date={shipment.rolling_current_eta} />
      </Box>
    ),
    name: 'DELAY',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned s-medium',
  },

  vessel: {
    field: (shipment: IRollingsShipment) => (
      <>
        <Box className="text-wrapper">
          <CopyWrapper>{shipment.previous_vessel}</CopyWrapper>
        </Box>
        <Box className="text-wrapper">
          <CopyWrapper>{shipment.current_vessel}</CopyWrapper>
        </Box>
      </>
    ),
    name: (
      <>
        <div>OLD VESSEL</div>
        <div>NEW VESSEL</div>
      </>
    ),
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned x-medium',
  },
  updated: {
    field: (shipment: IRollingsShipment) => (
      <UserField
        author={shipment.author}
        updatedAt={shipment.rolling_updated_at}
      />
    ),
    name: 'UPDATED',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned wide',
  },
}

import { FunctionComponent, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Logger } from 'src/utils'
import {
  reportsDashboardUpdateTaskStatus,
  updateCurrentModality,
} from 'src/stores/actionCreators'
import DashboardSelect, { OptionsItem } from 'src/stories/DashboardSelect'
import iconMappings from '../mappings/icons'
import { deliveryStatuses, OptionsItemWithIconCode } from '../mappings/tasks'

interface IProps {
  taskType: DeliveryTaskType
  currentTaskTypeStatusId: number | string
  shipmentId: number
  taskOrInlandTransportId: number
  selectType?: 'deliveryStatus' | 'taskStatus'
}

const SelectField: FunctionComponent<IProps> = ({
  taskType,
  currentTaskTypeStatusId,
  shipmentId,
  taskOrInlandTransportId,
  selectType = 'taskStatus',
}) => {
  const dispatch = useDispatch()
  const { taskMappings } = useSelector((state: IGlobalState) => ({
    taskMappings: state.reportsDashboard.filterOptions.task_type_statuses,
  }))

  let selectedMapping: OptionsItemWithIconCode[] | TaskStatus[] =
    taskMappings[taskType]
  if (taskType === 'delivery') {
    selectedMapping = deliveryStatuses
  }

  const selectOptions: OptionsItem[] = selectedMapping.map(
    (x: OptionsItemWithIconCode | TaskStatus) =>
      ({
        ...x,
        icon: x.icon ? (iconMappings[x.icon] as React.ReactElement) : undefined,
      } as OptionsItem)
  )

  const value: OptionsItem | undefined = selectOptions.find(
    (option) => option.id === currentTaskTypeStatusId
  )

  const onChange = useCallback(
    (item: OptionsItem) => {
      if (selectType === 'deliveryStatus') {
        dispatch(
          updateCurrentModality(taskOrInlandTransportId, { status: item.id })
        )
      } else {
        dispatch(
          reportsDashboardUpdateTaskStatus(
            shipmentId,
            taskOrInlandTransportId,
            {
              task_status_id: item.id,
            }
          )
        )
      }
    },
    [shipmentId, taskOrInlandTransportId, selectType]
  )

  if (!value) {
    Logger.log(
      `Task status id=${currentTaskTypeStatusId} is not in the list of ${selectOptions}`
    )
    return null
  }

  return (
    <DashboardSelect
      options={selectOptions}
      value={value}
      onChange={onChange}
    />
  )
}

export default SelectField

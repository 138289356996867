import Single from './Single'
import SingleAsync from './SingleAsync'

import './styles.scss'

const Select = {
  Single,
  SingleAsync,
}

export default Select

import { FunctionComponent } from 'react'

interface IProps {
  totalPhaseCount: number
  percentage: number
  threshold: number
}

const TimelineProgressSection: FunctionComponent<IProps> = ({
  totalPhaseCount,
  percentage,
  threshold,
}) => {
  if (percentage < threshold) {
    return null
  }

  const percent = (percentage - threshold) * totalPhaseCount
  const styles = { height: percent < 100 ? `${percent}%` : '100%' }

  return (
    <div
      className="shipment-timeline--phases-item--progress"
      style={styles}
    ></div>
  )
}

export default TimelineProgressSection

import { FunctionComponent } from 'react'
import capitalize from 'lodash/capitalize'
import startCase from 'lodash/startCase'
import ColorBadge from 'src/components/Common/ColorBadge'
import { Typography } from '@mui/material'
import {
  presentGoodsCode,
  fromJSwithZone,
  convertToStandartDateTimeFormat,
} from '../../../../../utils/helpers'

import './styles.scss'

interface IProps {
  fallback: string
  templateName: string
  templateVariables: IPurchaseOrderTemplateVariables
  exception: boolean
}

const tryToConvertToDateTime = (value: string) => {
  // dates for events come in different formats for some reason
  const convertDate = fromJSwithZone(new Date(value))

  return convertDate.isValid
    ? convertToStandartDateTimeFormat(convertDate)
    : value
}

const renderEvent = (
  humanizedTemplateName: string,
  from: string,
  to: string,
  fallback: string
) => {
  if (!from && !to) return fallback
  if (!from && to)
    return `${humanizedTemplateName} to ${tryToConvertToDateTime(to)}`
  if (from && !to)
    return `${humanizedTemplateName} from ${tryToConvertToDateTime(from)}`
  return `${humanizedTemplateName} from ${tryToConvertToDateTime(
    from
  )} to ${tryToConvertToDateTime(to)}`
}

const ContainerEventTemplate: FunctionComponent<IProps> = (props) => {
  const renderTemplate = () => {
    const humanizedTemplateName = capitalize(startCase(props.templateName))

    switch (props.templateName) {
      case 'hs_code_added':
        return (
          <>
            HS code:{' '}
            {presentGoodsCode(props.templateVariables.updated_value || '')}{' '}
            added
          </>
        )
      case 'hs_code_removed':
        return (
          <>
            HS code:{' '}
            {presentGoodsCode(props.templateVariables.updated_value || '')}{' '}
            removed
          </>
        )
      default:
        return renderEvent(
          humanizedTemplateName,
          props.templateVariables.previous_value || '',
          props.templateVariables.updated_value || '',
          props.fallback
        )
    }
  }

  const exceptionLabel: React.ReactNode = props.exception ? (
    <span className="ml-20">
      <ColorBadge
        className="yellow-outlined"
        content={
          <>
            <i className="icon attention" />
            EXCEPTION
          </>
        }
      />
    </span>
  ) : null

  return (
    <div className="purchase-order__template">
      <Typography variant="body1Strong" children={renderTemplate()} />
      {exceptionLabel}
    </div>
  )
}

export default ContainerEventTemplate

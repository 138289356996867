export const bookings = 'bookings'
export const orders = 'orders'
export const instructions = 'instructions'
export const documentation = 'documentation'
export const track = 'track'
export const costs = 'price-structure'
export const chats = 'chats'
export const privateChat = 'private-chat'
export const publicChat = 'public-chat'
export const agentsChat = 'agents-chat'
export const containers = 'containers'
export const purchaseOrders = 'orders'
export const tasks = 'tasks'

import { SyntheticEvent, useMemo, useState } from 'react'
import { uniqBy } from 'lodash'
import { useFormContext } from 'react-hook-form'
import { Paper, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { defaultValues } from 'src/pages/Overview/constants'
import RecentFiltersManager from './Search/RecentSearches'
import { FilterOptions } from './Search/types'
import CategoryAutocomplete from './Search/FormElements/CategoryAutocomplete'
import { getSearchToken, getSearchOptions } from './utils'

const getDefaultTokens = (filterParams, filterOptions) => {
  return filterOptions.reduce((acc: FilterOptions[], option: FilterOptions) => {
    if (filterParams[option.value] && filterParams[option.value].length > 0) {
      acc.push(option)
    }
    return acc
  }, [])
}

const Search = () => {
  const { t } = useTranslation()
  const options = useMemo(() => getSearchOptions(t), [t])
  const recentSearches = new RecentFiltersManager()
  const { setValue, getValues } = useFormContext()

  const filters = getValues()
  const search = getValues('search')

  const defaultSearchToken: FilterOptions[] = search
    ? [getSearchToken(search)]
    : []

  const defaultTokens = getDefaultTokens(filters, [...options])

  const [tokens, setTokens] = useState<FilterOptions[]>([
    ...defaultSearchToken,
    ...defaultTokens,
  ])

  const onCategoryChange = (
    _event: SyntheticEvent<Element, Event>,
    value: FilterOptions | null
  ) => {
    if (!value) return
    if (value.group === 'recent_searches') {
      setValue(value.value, value.data)

      const selectedToken =
        value.value === 'search'
          ? [getSearchToken(value.data as string)]
          : getDefaultTokens({ [value.value]: value.data }, [...options])

      const newTokens = uniqBy(
        [...selectedToken, ...tokens],
        (token) => token.value
      ) as FilterOptions[]

      setTokens(newTokens)
      return
    }
    const newTokens = uniqBy([...tokens, value], 'value')
    if (value.input) {
      setValue('search', value.input)
      recentSearches.addRecentFilter({
        key: 'search',
        data: value.input,
        label: 'Search',
        text: value.input,
      })
    }
    setTokens(newTokens)
  }

  const onTokenDelete = (tokenValue: string) => {
    const newTokens = tokens.filter((token) => token.value !== tokenValue)

    setTokens(newTokens)
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      event.code === 'Backspace' &&
      tokens.length > 0 &&
      event.currentTarget.value === ''
    ) {
      const tokenToDelete = tokens[tokens.length - 1]
      const newTokens = tokens.slice(0, tokens.length - 1)
      setValue(tokenToDelete.value, defaultValues[tokenToDelete.value])
      setTokens(newTokens)
    }
  }

  return (
    <Paper
      sx={{
        p: 1,
        display: 'flex',
        '& input': {
          border: 'none',
          width: '100%',
          '&:focus': {
            outline: 'none',
          },
        },
      }}
    >
      <Stack
        rowGap={1}
        spacing={1}
        flexGrow={1}
        flexWrap="wrap"
        direction="row"
        alignItems="center"
      >
        {tokens.map((token) => {
          const TokenComponent = token.component
          return (
            <TokenComponent
              key={token.value}
              token={token}
              onDelete={onTokenDelete}
            />
          )
        })}
        <Stack
          direction="row"
          flexGrow={1}
          sx={{
            borderLeft: tokens.length > 0 ? '1px solid' : 'none',
            paddingLeft: tokens.length > 0 ? 1 : 0,
            borderColor: 'grey.200',
          }}
        >
          <CategoryAutocomplete
            tokens={tokens}
            options={options}
            onCategoryChange={onCategoryChange}
            onSearchKeyDown={onKeyDown}
          />
        </Stack>
      </Stack>
    </Paper>
  )
}

export default Search

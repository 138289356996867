import { useState, MouseEvent } from 'react'
import { toggleApplicationFilterModal } from 'src/stores/actionCreators'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import { permissionTo } from 'src/utils'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import { Avatar } from 'src/stories/Avatar'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  userMenuLinkItems,
  popupProps,
} from 'src/components/TopNavigation/TopNavigation.constants'
import { getPermittedMenuItems } from 'src/components/TopNavigation/TopNavigation.utils'
import TopNavigationMenuItem from 'src/components/TopNavigation/components/TopNavigationMenuItem'
import TopNavigationUserMenuInfo from './TopNavigationUserMenuInfo'
import TopNavigationUserMenuLogout from './TopNavigationUserMenuLogout'
import TopNavigationUserMenuFilterApplication from './TopNavigationUserMenuFilterApplication'

interface TopNavigationUserMenuProps {
  name: string
  email: string
  avatar: string
}

const TopNavigationUserMenu: React.FC<TopNavigationUserMenuProps> = ({
  name,
  email,
  avatar,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { user } = useSelector(
    (state: IGlobalState) => ({
      user: state.user,
    }),
    shallowEqual
  )

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

  const filteredNavItems = getPermittedMenuItems(userMenuLinkItems(t))

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const onFilterClick = () => {
    dispatch(toggleApplicationFilterModal(true))
    handleCloseUserMenu()
  }

  return (
    <Box sx={{ flexGrow: 0 }} data-testid="top-navigation-user-menu">
      <IconButton
        onClick={handleOpenUserMenu}
        sx={{ p: 0 }}
        data-testid="top-navigation-user-menu-trigger"
      >
        <Avatar
          alt={name}
          variant="circular"
          src={avatar || 'no-logo'}
          className={user.organizationRole}
        />
      </IconButton>
      <Menu
        id="menu-user-info"
        keepMounted
        anchorEl={anchorElUser}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        MenuListProps={{ sx: { maxWidth: 250 } }}
        {...popupProps}
      >
        <TopNavigationUserMenuInfo name={name} email={email} />
        <Divider sx={{ mt: 1.5 }} />
        {filteredNavItems.map((userMenuLinkItem) => (
          <TopNavigationMenuItem
            key={userMenuLinkItem.key}
            item={userMenuLinkItem}
            onClick={handleCloseUserMenu}
          />
        ))}
        {permissionTo('menu.account.application_filter') && (
          <TopNavigationUserMenuFilterApplication onClick={onFilterClick} />
        )}
        <TopNavigationUserMenuLogout />
      </Menu>
    </Box>
  )
}

export default TopNavigationUserMenu

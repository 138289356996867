import { createReducer, Handlers, Reducer } from 'redux-create-reducer'
import { AnyAction } from 'redux'

import { ModalityEnum, LoadTypeEnum } from 'src/config/constants'
import * as actions from '../actions/searchOverview'

declare global {
  interface ISearchOverview {
    shipment_type: string
    modality: ModalityEnum | null
    load_type: LoadTypeEnum | null
    containers: IContainerShort[]
    quote: ISearchQuote | null
    shipment_organizations: any[]
    loading: boolean
  }

  interface ILCLObject {
    id: string
    length: number
    lengthError?: string
    width: number
    widthError?: string
    height: number
    heightError?: string
    volume?: number
    weight: number
    weightError?: string
    total?: number
    totalError?: string
    quantity: number
    package_type_id: number
    total_volume_cbm?: number
    description: string
    stackable: boolean | null
  }

  // query: from the location bar
  interface ISearchQuery {
    cif_value: string | null
    customer_organization_id: string | null
    end: string
    end_country: string | null
    end_zip: string | null
    end_lat: string | null
    end_lng: string | null
    end_port: any
    end_include_nearby: string | null
    schedule_id: string | null
    start: string
    start_country: string | null
    start_zip: string | null
    start_lat: string | null
    start_lng: string | null
    start_port: any
    start_include_nearby: string | null
    departure_date: string
    tags: string[] | null
    freight: string
    pickup: string | null
    delivery: string | null
    export_customs: string | null
    import_customs: string | null
    origin_port: string | null
    destination_port: string | null
    insurance: string | null
    modality: ModalityEnum
    load_type: LoadTypeEnum
    container_types: string[]
    shipment_type: string
    quantities: string[]
    lengths: any[]
    widths: any[]
    heights: any[]
    weights: any[]
    shipment_organizations: any
    is_fastest: string | null
    is_cheapest: string | null
    total_volume_cbms: number[]
    package_type_ids: number[]
    stackables: boolean[]
    goods_descriptions: string[]
    seller_organization_id?: number
  }
}

export const initialSearchQuoteOverviewState: ISearchOverview = {
  shipment_type: 'unknown',
  containers: [],
  shipment_organizations: [],
  quote: null,
  loading: true,
  modality: null,
  load_type: null,
}

const searchQuotes: Reducer<ISearchOverview, AnyAction> = (
  state,
  { payload }
) => ({
  load_type: payload.data.data.load_type,
  modality: payload.data.data.modality,
  shipment_type: payload.data.data.shipment_type,
  containers: payload.data.data.containers,
  quote: payload.data.data.quote,
  shipment_organizations: payload.data.data.shipment_organizations,
  loading: false,
})

const searchQuotesStart: Reducer<ISearchOverview, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  loading: true,
})

const actionHandlers: Handlers<ISearchOverview> = {
  [actions.SEARCH_QUOTES_OVERVIEW_SUCCESS]: searchQuotes,
  [actions.SEARCH_QUOTES_OVERVIEW]: searchQuotesStart,
}

export default createReducer(initialSearchQuoteOverviewState, actionHandlers)

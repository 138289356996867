import {
  FunctionComponent,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react'
import { useSelector } from 'react-redux'
import { findIndex, sortBy, isEqual } from 'lodash'
import Select from 'src/components/Common/Select'
import FormFieldTextBasic from 'src/components/FormFieldTextBasic'
import { useTranslation } from 'react-i18next'

import { withoutNullFields } from '../../../../utils'
import { SELECTOR_DIVIDER } from '../../../../config/constants'
import {
  prepareForRequest,
  handleAfterSelect,
  defaultAddress,
  initialState,
} from './constants'

interface IContactFormProps {
  contactObj: any
  companyType: string
  contactType: string
  shipmentId: number
  permission: boolean
  disableTextInputs?: boolean
  addressBookPermission: boolean
  handleContactChange: (data: IContactFormState | null) => void
  companyAddressId: number | null
  companyObj: any
  validationErrors: any
  newCompany: boolean
  changeCompany: boolean
  setSaveDisable: (status: boolean) => void
  label: string
}

export interface IContactFormState {
  [x: string]: any
}

type IPersonalDetailWithNullOption = IPersonalDetail | INullPersonalDetail

const ContactForm: FunctionComponent<IContactFormProps> = (props) => {
  const { t } = useTranslation()
  const [state, setState] = useState<any>(initialState)
  const [getFieldData, setGetFieldData] = useState<boolean>(false)

  const { contacts } = useSelector((state: IGlobalState) => ({
    contacts: state.personalDetails.list,
  }))

  useEffect(() => {
    setState({
      baseState: props,
      ...{
        contact: props.contactObj,
        company: props.companyObj,
        name: (props.contactObj || {}).name,
        email: (props.contactObj || {}).email,
        phone: (props.contactObj || {}).phone,
        comment: (props.contactObj || {}).comment,
        contact_id: (props.contactObj || {}).contact_id,
      },
    })
    setGetFieldData(true)
  }, [])

  useEffect(() => {
    if (!state.checkingEmail) {
      if (
        props.changeCompany ||
        (props.newCompany && isEqual(props.companyObj, defaultAddress))
      ) {
        clearState(false)
      }
    } else {
      setState({ ...state, checkingEmail: false })
    }
  }, [props.companyAddressId])

  useEffect(() => {
    if (getFieldData) {
      const dataToSend = withoutNullFields(prepareForRequest(state))
      props.handleContactChange(dataToSend)
      setGetFieldData(false)
    }
  }, [getFieldData])

  const openAndEmpty: boolean = useMemo(
    () => !state.contact_id && state.openForNew,
    [state.contact_id, state.openForNew]
  )

  const isChanged: boolean = useMemo(
    () =>
      props.permission &&
      state.baseState &&
      JSON.stringify(prepareForRequest(state)) !==
        JSON.stringify(prepareForRequest(state.baseState)),
    [props.permission, state]
  )

  const isFieldChanged = useCallback(
    (field: string): boolean => {
      return (
        prepareForRequest(state)[field] !==
        prepareForRequest(state.baseState)[field]
      )
    },
    [state]
  )

  const handleFieldChange = useCallback(
    (field: string, value: string | number | null): void => {
      const errorMessages: any = Object.assign({}, state.errorNotes)
      errorMessages[field] = null
      setState({ ...state, [field]: value, errorNotes: errorMessages })
      setGetFieldData(true)
      props.setSaveDisable(!isChanged)
    },
    [state]
  )

  const clearState = (status: boolean): void => {
    const newState: IContactFormState = {
      ...initialState,
      ...{ openForNew: status },
    }
    setState(newState)
  }

  const onContactSelect = (value: any): void => {
    value.comment = state.comment || ''
    const contactFields = handleAfterSelect(value)
    setState({ ...state, ...contactFields, openForNew: true })
    setGetFieldData(true)
  }

  const handleSelect = useCallback(
    (data: IAddress): void => {
      if (data.id) {
        onContactSelect(data)
      } else {
        clearState(true)
      }
      props.setSaveDisable(false)
    },
    [state]
  )

  const renderInput = useCallback(
    (
      label: string,
      field: string,
      className?: string | '',
      displaySelect?: boolean | false,
      multiLine?: boolean | false
    ) => {
      const changed: string = isFieldChanged(field) ? 'changed' : ''
      const blocked: boolean =
        !props.permission ||
        (!!state.contact_id && field === 'name') ||
        !!props.disableTextInputs
      return (
        <FormFieldTextBasic
          disabled={blocked}
          multiLine={multiLine}
          label={label}
          field={field}
          contactType="person"
          value={state[field]}
          displaySelect={displaySelect}
          contacts={contacts}
          className={`${className} ${changed}`}
          popperClassName={'shipping-instructions__modal-popper'}
          errorNotes={props.validationErrors[field]}
          onChange={handleFieldChange}
          onContactSelect={onContactSelect}
          data-testid={`contact-form-${field}`}
        />
      )
    },
    [state, props]
  )

  const contactOptions = useMemo(() => {
    const { companyAddressId, companyObj } = props
    const addressId: number | null = !!companyObj ? companyObj.address_id : 0
    const filterId: number | null = companyAddressId || addressId
    const filtered: IPersonalDetailWithNullOption[] = contacts.filter(
      (sub: IPersonalDetail) => {
        return sub.company_address_id === filterId || !sub.company_address_id
      }
    )
    const sorted = sortBy(filtered, ['company_address_id', 'name'])
    const firstUnassigned: number = findIndex(sorted, {
      company_address_id: null,
    })
    if (firstUnassigned >= 0) {
      sorted.splice(firstUnassigned, 0, SELECTOR_DIVIDER)
    }

    const filteredContacts: IPersonalDetailWithNullOption[] = sorted
    const contactOptions: any[] = filteredContacts.slice() || []
    if (!props.disableTextInputs) {
      contactOptions.unshift({
        id: null,
        name: t(
          'shipments.bookings.parties_modal.options.add_new_contact',
          'Add new contact'
        ),
      })
    }
    return contactOptions
  }, [props.companyAddressId, contacts])

  return (
    <div className="shipping-instructions__form">
      <div className="instructions-form">
        <div className="instructions-form__title">
          <div>{props.label}</div>
        </div>
        <div className="instructions-form__content">
          <Select.Single
            value={state.contact_id || 0}
            className={`company__select ${
              openAndEmpty ? 'open-and-empty' : ''
            } ${!!props.disableTextInputs ? 'disabled-new' : ''}`}
            placeholder={
              openAndEmpty
                ? t(
                    'shipments.bookings.parties_modal.options.new_contact_person',
                    'New contact person'
                  )
                : t(
                    'shipments.bookings.parties_modal.options.select_contact_person',
                    'Select contact person'
                  )
            }
            onChange={handleSelect}
            options={contactOptions}
            disabled={
              props.companyObj &&
              !props.companyObj.address_id &&
              !props.newCompany &&
              !props.changeCompany
            }
            theme="common"
            dataTestid="contact-form-select"
          />
          {(!!state.contact_id || state.openForNew) && (
            <div className="instructions-form__content--fields">
              {renderInput(
                t('shipments.bookings.parties_modal.fields.name', 'Name*'),
                'name',
                'first-field'
              )}
              {renderInput(
                t('shipments.bookings.parties_modal.fields.email', 'Email*'),
                'email',
                'full-width'
              )}
              {renderInput(
                t('shipments.bookings.parties_modal.fields.phone', 'Phone*'),
                'phone',
                'full-width'
              )}
              {renderInput(
                t(
                  'shipments.bookings.parties_modal.fields.additional_information',
                  'Additional Information'
                ),
                'comment',
                'full-width',
                false,
                true
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ContactForm

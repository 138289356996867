import { FunctionComponent, useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { times } from 'lodash'
import { useTranslation } from 'react-i18next'
import { pickupsAndDeliveriesGetOne } from 'src/stores/actionCreators'
import { Box, Typography } from '@mui/material'
import { Skeleton } from 'src/stories'
import Tabs from 'src/stories/Tabs'
import CommentsBlock from 'src/components/CommentsBlock'
import { permissionTo } from 'src/utils'
import InlandTransport from '../InlandTransport'
import ShipmentInformation from './ShipmentInformation'
import EditShipmentBlock from './EditShipmentBlock'

const DetailsTabs: FunctionComponent = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const DETAILS_TABS = [
    {
      label: t('transports.drawer.tabs.inland_transport', 'Transport order'),
      value: 'inland_transport',
    },
    {
      label: t(
        'transports.drawer.tabs.shipment_information',
        'Shipment information'
      ),
      value: 'shipment_information',
    },
  ]

  const { selectedId, singleInlandTransport } = useSelector(
    (state: IGlobalState) => ({
      selectedId: state.pickUpsAndDeliveries.selectedInlandTransportId,
      singleInlandTransport: state.pickUpsAndDeliveries.singleInlandTransport,
    }),
    shallowEqual
  )

  const [scope, setScope] = useState('inland_transport')

  const fetchPickupsAndDeliveriesItem = () => {
    if (selectedId) {
      dispatch(pickupsAndDeliveriesGetOne(selectedId))
    }
  }
  useEffect(() => {
    fetchPickupsAndDeliveriesItem()
  }, [selectedId])

  const editShipment = useMemo(() => <EditShipmentBlock />, [selectedId])

  if (!singleInlandTransport) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {times(9).map((index) => {
          return (
            <Skeleton
              key={index}
              animation="wave"
              variant="text"
              sx={{ width: 670, height: 50, mb: 2 }}
            />
          )
        })}
      </Box>
    )
  }

  return (
    <Box sx={{ width: 670 }}>
      {editShipment}
      <Tabs
        onChange={(scope) => {
          setScope(scope)
        }}
        orientation="horizontal"
        scrollButtons="auto"
        value={scope}
        tabs={DETAILS_TABS}
        variant="standard"
      />
      {scope === 'shipment_information' && (
        <ShipmentInformation inlandTransport={singleInlandTransport} />
      )}
      {scope === 'inland_transport' && (
        <InlandTransport inlandTransport={singleInlandTransport} />
      )}
      {permissionTo('pickups_deliveries.shypple_admin') && (
        <Box>
          <Box mb={1}>
            <Typography variant="h6">
              {' '}
              {t('shipment_containers.reefer_settings.comments', 'Comments')}
            </Typography>
          </Box>
          <CommentsBlock shipmentId={singleInlandTransport.shipment?.id} />
        </Box>
      )}
    </Box>
  )
}

export default DetailsTabs

export const REPORTS_DASHBOARD_FETCH_DATA = 'REPORTS_DASHBOARD_FETCH_DATA'
export const REPORTS_DASHBOARD_FETCH_DATA_SUCCESS =
  'REPORTS_DASHBOARD_FETCH_DATA_SUCCESS'
export const REPORTS_DASHBOARD_UPDATE_SCOPE = 'REPORTS_DASHBOARD_UPDATE_SCOPE'
export const REPORTS_DASHBOARD_UPDATE_FILTERS =
  'REPORTS_DASHBOARD_UPDATE_FILTERS'
export const REPORTS_DASHBOARD_UPDATE_ASYNC_FILTERS =
  'REPORTS_DASHBOARD_UPDATE_ASYNC_FILTERS'

export const REPORTS_DASHBOARD_FETCH_FILTER_OPTIONS =
  'REPORTS_DASHBOARD_FETCH_FILTER_OPTIONS'
export const REPORTS_DASHBOARD_FETCH_FILTER_OPTIONS_SUCCESS =
  'REPORTS_DASHBOARD_FETCH_FILTER_OPTIONS_SUCCESS'
export const REPORTS_DASHBOARD_CLEAR_FILTERS = 'REPORTS_DASHBOARD_CLEAR_FILTERS'
export const REPORTS_DASHBOARD_SET_EDIT_COMMENT_INFO =
  'REPORTS_DASHBOARD_SET_EDIT_COMMENT_INFO'
export const REPORTS_DASHBOARD_DELETED_LAST_COMMENT =
  'REPORTS_DASHBOARD_DELETED_LAST_COMMENT'

export const REPORTS_DASHBOARD_UPDATE_TASK_STATUS =
  'REPORTS_DASHBOARD_UPDATE_TASK_STATUS'
export const REPORTS_DASHBOARD_UPDATE_TASK_STATUS_SUCCESS =
  'REPORTS_DASHBOARD_UPDATE_TASK_STATUS_SUCCESS'

import { takeEvery } from 'redux-saga/effects'
import * as actionCreators from '../../actionCreators/trackAndTrace'
import * as actions from '../../actions/trackAndTrace'
import {
  simpleGetAndResolve,
  simplePutAndResolve,
  simplePostAndResolve,
} from '../factories'

const getData = simpleGetAndResolve(
  (action) =>
    `/api/v1/shipments/${action.shipment_id}/track_trace_subscriptions`,
  actionCreators.trackAndTraceGetDataSuccess,
  (response) => response.data
)

const usePair = simplePutAndResolve(
  (action) =>
    `/api/v1/shipments/${action.shipment_id}/track_trace_subscriptions/${action.payload.trackId}/use_port_pair`,
  actionCreators.updateTrackAndTraceSuccess,
  (response) => response.data
)

const pause = simplePutAndResolve(
  (action) =>
    `/api/v1/shipments/${action.shipment_id}/track_trace_subscriptions/${action.payload.trackId}/pause`,
  actionCreators.updateTrackAndTraceSuccess,
  (response) => response.data
)

const resume = simplePutAndResolve(
  (action) =>
    `/api/v1/shipments/${action.shipment_id}/track_trace_subscriptions/${action.payload.trackId}/resume`,
  actionCreators.updateTrackAndTraceSuccess,
  (response) => response.data
)

const cancel = simplePutAndResolve(
  (action) =>
    `/api/v1/shipments/${action.shipment_id}/track_trace_subscriptions/${action.payload.trackId}/cancel_and_archive`,
  actionCreators.updateTrackAndTraceSuccess,
  (response) => response.data
)

const createSubscription = simplePostAndResolve(
  (action) =>
    `/api/v1/shipments/${action.shipment_id}/track_trace_subscriptions`,
  actionCreators.trackTraceSubscriptionCreateSuccess,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.TRACK_AND_TRACE_GET_DATA, getData)
  yield takeEvery(actions.TRACK_AND_TRACE_PAUSED, pause)
  yield takeEvery(actions.TRACK_AND_TRACE_RESUME, resume)
  yield takeEvery(actions.TRACK_AND_TRACE_CANCEL_AND_ARCHIVE, cancel)
  yield takeEvery(actions.TRACK_AND_TRACE_USE_PORT_PAIR, usePair)
  yield takeEvery(
    actions.TRACK_AND_TRACE_SUBSCRIPTION_CREATE,
    createSubscription
  )
}

import { useEffect, useState, useMemo } from 'react'
import { uniq } from 'lodash'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { shipmentTransactionItemsGetData } from '../../stores/actionCreators'
import { promisifyAction } from '../../utils'
import { getTransactionItemGroups } from './utils'
import { ShipmentCostsItemsTable } from './ShipmentCostsItemsTable'
import { ShipmentCostsTransactionItemsSkeleton } from './ShipmentCostsTransactionItemsSkeleton'
import { ShipmentCostsParty } from './ShipmentCostsParty'

interface ShipmentCostsTransactionItemsProps {
  id: number
}

export const ShipmentCostsTransactionItems: React.FC<ShipmentCostsTransactionItemsProps> = ({
  id,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState<boolean>(true)

  const getDataAsync = promisifyAction(
    dispatch,
    shipmentTransactionItemsGetData
  )

  const { transactionItems } = useSelector((state: IGlobalState) => ({
    transactionItems: state.shipmentPrice.transactionItems,
  }))

  const transactionItemGroups = getTransactionItemGroups(transactionItems)

  useEffect(() => {
    const getData = () => {
      getDataAsync(id).finally(() => {
        setLoading(false)
      })
    }
    getData()
  }, [])

  const isOnlyEur = useMemo(() => {
    const currencyArr = uniq(transactionItems.map((item) => item.currency))
    return currencyArr.length === 1 && currencyArr[0] === 'EUR'
  }, [transactionItems])

  return (
    <div>
      {loading && <ShipmentCostsTransactionItemsSkeleton />}
      {!loading && (
        <>
          {transactionItemGroups.map((group) => (
            <Stack
              spacing={2}
              sx={{ mb: 2 }}
              key={`${group.parties[0].id}_${group.parties[1].id}`}
            >
              <ShipmentCostsParty
                marginTop={1}
                name={group.parties[0].name}
                label={group.parties[0].label}
                className={group.parties[0].role_code}
              />
              <ShipmentCostsItemsTable
                loading={loading}
                rows={group.rows}
                totals={group.totals}
              />
            </Stack>
          ))}
          {!isOnlyEur && (
            <div className="alert-container">
              <Alert variant="filled" severity="warning">
                {t(
                  'shipment_costs.notifications.costs_are_subject_to_fluctuations',
                  'These costs are subject to exchange rate fluctuations'
                )}
              </Alert>
            </div>
          )}
        </>
      )}
    </div>
  )
}

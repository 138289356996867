import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const AutocompleteCommonToggleEndAdornment = ({
  hasValue,
  onClick,
  open,
}: {
  hasValue: boolean
  onClick: (event: { stopPropagation: () => void }) => void
  open: boolean
}) => {
  const InputIcon = open ? KeyboardArrowUpIcon : KeyboardArrowDownIcon
  return (
    <>
      {hasValue && (
        <IconButton size="small" onClick={onClick} className="reset">
          <CloseIcon sx={{ color: 'grey.600' }} />
        </IconButton>
      )}
      <InputIcon sx={{ color: 'grey.600' }} />
    </>
  )
}

export default AutocompleteCommonToggleEndAdornment

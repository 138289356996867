import { Grid, Skeleton } from '@mui/material'
import { Children } from 'react'

const defaultColumnSize = 2

export const ShipmentTemplateFormSkeleton = ({ children, isLoading }) => {
  return Children.map(children, (child, index) => {
    if (!child) return null
    const { columnSize = defaultColumnSize, key = index } = child.props

    const lgColumnSize = columnSize < 6 ? 4 : columnSize

    return (
      <Grid
        item
        key={key}
        xs={12}
        md={lgColumnSize}
        lg={lgColumnSize}
        xl={columnSize}
      >
        {isLoading && (
          <>
            <Skeleton
              animation="wave"
              variant="text"
              width="40%"
              sx={{ mb: 1 }}
            />
            <Skeleton
              variant="rectangular"
              width="100%"
              height={40}
              animation="wave"
              sx={{ borderRadius: 1 }}
            />
          </>
        )}
        {!isLoading && child}
      </Grid>
    )
  })
}

export default ShipmentTemplateFormSkeleton

import { FunctionComponent, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import { Table } from 'src/stories'
import { history } from 'src/shyppleStore'
import DashboardEmptyLine from 'src/components/DashboardOverview/DashboardEmptyLine'
import { promisifyAction } from '../../../utils'
import { convertDateToDateTime } from '../../../utils/helpers'
import { fetchDashboardRecentlyBooked } from '../../../stores/actionCreators'
import { dashboardLine } from '../DashboardShipmentLine/dashboardLine'
import columns from '../common'

const DashboardRecentlyBooked: FunctionComponent<{}> = () => {
  const dispatch = useDispatch()
  const [busy, setBusy] = useState<boolean>(true)

  const { records, onlyWatchedShipments } = useSelector(
    (state: IGlobalState) => ({
      records: state.dashboard.recentlyBooked.records || [],
      onlyWatchedShipments: state.shipmentsWatchedBy.onlyWatchedShipments,
    })
  )

  const fetchDashboardRecentlyBookedAsync = promisifyAction(
    dispatch,
    fetchDashboardRecentlyBooked
  )

  useEffect(() => {
    const fetchDataAsync = async () => {
      await fetchDashboardRecentlyBookedAsync({
        only_watched_shipments: onlyWatchedShipments,
      })
      setBusy(false)
    }
    fetchDataAsync()
  }, [onlyWatchedShipments])

  if (!busy && !records.length) {
    return <DashboardEmptyLine />
  }

  return (
    <Table
      columns={columns}
      loading={busy}
      maxHeight={320}
      onRowClick={(id) => {
        history.push(`/shipments/${id}`)
      }}
      showHeader={false}
      rows={
        !!records.length
          ? records.map((shipment: IDashboardShipment) => {
              const subtitle = `${
                shipment.booked_by
                  ? `On: ${convertDateToDateTime(shipment.booked_at || '')}`
                  : ''
              } ${
                shipment.status_group === 'supplier_initiated_booking'
                  ? ` - Supplier Initiated`
                  : ''
              }`
              const info = (
                <Typography variant="body1">
                  Booked by:{' '}
                  <span
                    style={{ color: shipment.booked_by_organization.color }}
                  >
                    {shipment.booked_by} |{' '}
                    <b>{shipment.booked_by_organization.name}</b>
                  </span>
                </Typography>
              )
              return dashboardLine(shipment, subtitle, info)
            })
          : []
      }
    />
  )
}

export default DashboardRecentlyBooked

const info = {
  50: '#F2E6F7',
  100: '#E1BCF2',
  200: '#CE8FEB',
  300: '#BA61E3',
  400: '#AB39DC',
  500: '#9B00D5',
  600: '#8A00CF',
  700: '#6E02B0',
  800: '#580599',
  900: '#410173',
}

export default info

import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  PricesResponse,
  FilterOptionsResponse,
  UpdateUserContainerTypeResponse,
  UpdateUserContainerTypeRequest,
} from 'src/@types/endpoints/prices'
import endpoints, { queryKeys } from '../endpoints'
import { apiClient } from '../http-common'
import { createQueryString } from '../utils'

export const useGetRates = (
  payload?: object,
  options?: UseQueryOptions<PricesResponse, AxiosError, PricesResponse>
) => {
  const queryParams = createQueryString(payload || {})
  const url = endpoints.prices + queryParams

  const { prices: key } = queryKeys

  const getPrices = () => apiClient.get<any, PricesResponse>(url)
  return useQuery<PricesResponse, AxiosError>(
    [key, queryParams],
    getPrices,
    options
  )
}

export const useGetRatesFilterOptions = (
  options?: UseQueryOptions<
    FilterOptionsResponse,
    AxiosError,
    FilterOptionsResponse
  >
) => {
  const url = endpoints.pricesFilterOptions

  const { pricesFilterOptions: key } = queryKeys

  const getFilterOptions = () => apiClient.get<any, FilterOptionsResponse>(url)
  return useQuery<FilterOptionsResponse, AxiosError>(
    [key],
    getFilterOptions,
    options
  )
}

export const useUpdateUserContainerType = (
  options?: UseMutationOptions<
    UpdateUserContainerTypeResponse,
    AxiosError,
    UpdateUserContainerTypeRequest
  >
) => {
  const updateShipmentTask = (payload: UpdateUserContainerTypeRequest) => {
    const url = endpoints.pricesSetUserContainerTypes

    return apiClient.put<any, UpdateUserContainerTypeResponse>(
      url,
      JSON.stringify(payload)
    )
  }

  return useMutation(updateShipmentTask, options)
}

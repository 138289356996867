import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { userLoadState, userUpdateUserData } from 'src/stores/actionCreators'
import { promisifyAction } from './utils'
import DateTime from './utils/DateTime'

const AuthWrapper = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  const getCurrentUser = promisifyAction(dispatch, userLoadState)
  const updateCurrentUser = promisifyAction(dispatch, userUpdateUserData)

  useEffect(() => {
    ;(async () => {
      const user = await getCurrentUser()
      const localTimeZone: string = DateTime.timeZone()
      if (!user.time_zone || user.time_zone !== localTimeZone) {
        updateCurrentUser({ time_zone: localTimeZone })
      }
      setLoading(false)
    })()
  }, [])

  if (loading) return null

  return children
}

export default AuthWrapper

import { FunctionComponent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { orderBy } from 'lodash'
import { promisifyAction, permissionTo } from 'src/utils'
import { convertDateToRelative } from 'src/utils/helpers'
import {
  Chip,
  Typography,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import AuthorAvatar from 'src/components/AuthorAvatar'
import { organizationUsersGetData } from '../../../stores/actionCreators'

import './styles.scss'

const Users: FunctionComponent<{}> = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const isUsersManage: boolean = permissionTo('users.manage')

  const organizationUsersGetDataAsync = promisifyAction(
    dispatch,
    organizationUsersGetData
  )

  const { users } = useSelector((state: IGlobalState) => ({
    users: state.organizationSettings.users,
  }))

  const { t } = useTranslation()

  useEffect(() => {
    const fetchData = async () => {
      await organizationUsersGetDataAsync()
    }

    fetchData()
  }, [])

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="short"></TableCell>
            <TableCell className="left-aligned medium">
              {t('address_book.connections.name', 'Name')}
            </TableCell>
            <TableCell className="left-aligned wide">
              {t('address_book.add_address.email', 'Email')}
            </TableCell>
            <TableCell className="left-aligned wide">
              {t('address_book.connections.last_active', 'Last active')}
            </TableCell>
            <TableCell className=""></TableCell>
            <TableCell className=""></TableCell>
          </TableRow>
        </TableHead>
        {orderBy(users, 'first_name').map((user: any, index) => {
          return (
            <TableRow
              key={index}
              hover={isUsersManage}
              onClick={() => {
                if (!isUsersManage) return
                history.push(`/shipment_settings/${user.id}`)
              }}
            >
              <TableCell className="short-70 user-avatar">
                <AuthorAvatar author={user} size="medium" />
              </TableCell>
              <TableCell className="left-aligned medium">
                {`${user.first_name} ${user.last_name}`}
              </TableCell>
              <TableCell className="left-aligned wide">
                <span className="text-wrapper">{user.email}</span>
              </TableCell>
              <TableCell className="left-aligned wide">
                {user.last_seen ? (
                  convertDateToRelative(user.last_seen)
                ) : (
                  <Chip
                    color="default"
                    label={
                      <Typography
                        variant="body1"
                        children={t(
                          'address_book.connections.invited',
                          'Invited'
                        )}
                      />
                    }
                    size="medium"
                    variant="outlined"
                  />
                )}
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          )
        })}
      </Table>
    </>
  )
}
export default Users

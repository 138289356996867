import { FunctionComponent, useState, useMemo } from 'react'
import { useHistory } from 'react-router'
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material'
import { pickBy } from 'lodash'
import { stringify } from 'query-string'
import Modal from 'src/components/Common/Modal'
import { SERVICES_TO_REQUEST } from './constants'

type Services = 'Destination port handling' | 'Import customs' | 'Delivery'

interface IServiceOption {
  id: number
  label: Services
  name: string
  enabled: boolean
}

const initialOptionsState = SERVICES_TO_REQUEST.map(
  (s) =>
    ({
      ...s,
      enabled: false,
    } as IServiceOption)
)

const ShipmentCostsRequestServiceModal: FunctionComponent<{
  isOpen: boolean
  shipmentId: number
}> = ({ isOpen, shipmentId }) => {
  const history = useHistory()
  const [options, setOptions] = useState<IServiceOption[]>(initialOptionsState)
  const [selectedServices, setSelectedServices] = useState<number[]>([])

  const closeModal = () => {
    history.push({
      search: stringify(pickBy({ modalOpen: false }), {
        arrayFormat: 'bracket',
      }),
    })
  }

  const handleSelectOption = (optionId: number): void => {
    const idx = options.findIndex((option) => optionId === option.id)
    setOptions((prev) => {
      return [
        ...prev.slice(0, idx),
        { ...prev[idx], enabled: !prev[idx].enabled },
        ...prev.slice(idx + 1),
      ]
    })
  }

  const saveChanges = () => {
    history.push(
      `/shipments/${shipmentId}/chats?scrollToChat=true&services=${selectedServices}`
    )
  }

  const isSavingDisabled: boolean = useMemo(() => {
    const enabledOptions = options.filter((o) => !!o.enabled).map((o) => o.id)
    setSelectedServices(enabledOptions)
    return !enabledOptions.length
  }, [options])

  return (
    <Modal.Window open={isOpen} onClose={closeModal}>
      <Modal.Title children="Please select a service" onClose={closeModal} />
      <Modal.Content
        sx={{
          width: '412px',
        }}
      >
        <Typography color="black" variant="body2">
          What can we help you with?
        </Typography>
        <FormGroup sx={{ marginTop: 3.25 }}>
          {options.map(({ id, label, enabled }) => (
            <FormControlLabel
              key={id}
              label={label}
              control={<Checkbox />}
              checked={enabled}
              onChange={(_) => handleSelectOption(id)}
            />
          ))}
        </FormGroup>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={saveChanges}
          disabled={isSavingDisabled}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal.Window>
  )
}

export default ShipmentCostsRequestServiceModal

import { Button } from '@mui/material'
import bind from 'autobind-decorator'
import { PureComponent } from 'react'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'

interface IDashboardTooltipProps {
  data: ISupplyChainOverviewItem
  currentGroup: StatusGroupType
  active: boolean
  onRevealShipments: (statusGroup: StatusGroupType) => void
}

class DashboardTooltip extends PureComponent<IDashboardTooltipProps> {
  public render(): React.ReactNode {
    return (
      <div>
        <div>
          {this.props.currentGroup === 'delivered'
            ? this.renderDeliveredShipments()
            : this.renderShipments()}
        </div>
        <Button
          variant="outlined"
          onClick={this.revealShipments}
          disabled={!this.props.active}
          endIcon={<KeyboardArrowDownRoundedIcon />}
        >
          View shipments
        </Button>
      </div>
    )
  }

  @bind
  private revealShipments(): void {
    this.props.onRevealShipments(this.props.currentGroup)
  }

  @bind
  private renderDeliveredShipments(): React.ReactNode {
    const { air, fcl, lcl, rail } = this.props.data.per_load_type
    return (
      <div className="shipment-block">
        {!!fcl.length && this.renderDeliveredShipmentsType('fcl', fcl)}
        {!!lcl.length && this.renderDeliveredShipmentsType('lcl', lcl)}
        {!!air.length && this.renderDeliveredShipmentsType('air', air)}
        {!!rail.length && this.renderDeliveredShipmentsType('rail', rail)}
      </div>
    )
  }

  @bind
  private renderShipments(): React.ReactNode {
    const {
      fcl_count,
      air_count,
      lcl_count,
      rail_count,
      per_load_type,
    } = this.props.data

    return (
      <div className="shipment-block">
        {!!fcl_count && (
          <div className="shipment-block-by-type">
            <div>
              <i className="icon standard-container-20ft" />
            </div>
            <div className="shipment-block-by-type--shipments">
              {per_load_type.fcl.map((info, index) => (
                <div
                  key={index}
                  className="shipment-block-by-type--shipments--item"
                >
                  {info.count}
                  <div className="shipment-block-by-type-cut-item">
                    {' x '}
                    {info.loading_port}
                  </div>
                  <div className="shipment-block-by-type-cut-item">
                    {' - '}
                    {info.discharge_port}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {!!lcl_count && (
          <div className="shipment-block-by-type">
            <div>
              <i className="icon lcl" />
            </div>
            <div className="shipment-block-by-type--shipments">
              {per_load_type.lcl.map((info, index) => (
                <div
                  key={index}
                  className="shipment-block-by-type--shipments--item"
                >
                  {info.count}
                  <div className="shipment-block-by-type-cut-item">
                    {' x '}
                    {info.loading_port}
                  </div>
                  <div className="shipment-block-by-type-cut-item">
                    {' - '}
                    {info.discharge_port}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {!!air_count && (
          <div className="shipment-block-by-type">
            <div>
              <i className="icon airport" />
            </div>
            <div className="shipment-block-by-type--shipments">
              {per_load_type.air.map((info, index) => (
                <div
                  key={index}
                  className="shipment-block-by-type--shipments--item"
                >
                  {info.count}
                  <div className="shipment-block-by-type-cut-item">
                    {' x '}
                    {info.loading_port}
                  </div>
                  <div className="shipment-block-by-type-cut-item">
                    {' - '}
                    {info.discharge_port}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {!!rail_count && (
          <div className="shipment-block-by-type">
            <div>
              <i className="icon train" />
            </div>
            <div className="shipment-block-by-type--shipments">
              {per_load_type.rail.map((info, index) => (
                <div
                  key={index}
                  className="shipment-block-by-type--shipments--item"
                >
                  {info.count}
                  <div className="shipment-block-by-type-cut-item">
                    {' x '}
                    {info.loading_port}
                  </div>
                  <div className="shipment-block-by-type-cut-item">
                    {' - '}
                    {info.discharge_port}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    )
  }

  private renderDeliveredShipmentsType(
    type: ShipmentsLoadTypes,
    perTypeInfo: any
  ): React.ReactNode {
    const icons = {
      air: 'airport',
      fcl: 'standard-container-20ft',
      lcl: 'lcl',
      rail: 'train',
    }

    return (
      <div className="shipment-block-by-type">
        <div>
          <i className={`icon ${icons[type]}`} />
        </div>
        <div className="shipment-block-by-type--shipments">
          {perTypeInfo.map((perMonth, index) => (
            <div
              key={index}
              className="shipment-block-by-type--shipments--item"
            >
              {perMonth.count}
              <div className="shipment-block-by-type-cut-item">
                {' x '}
                {perMonth.month}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default DashboardTooltip

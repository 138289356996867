import { FunctionComponent } from 'react'
import { useHistory } from 'react-router'
import { Button, Typography } from '@mui/material'
import Modal from 'src/components/Common/Modal'
import { useTranslation } from 'react-i18next'

const SetUpMyShipmentsModal: FunctionComponent<{
  open: boolean
  handleClose: () => void
}> = ({ open, handleClose }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const redirectToAccountSettings = (): void => {
    history.push('/account/my_shipments')
  }

  return (
    <Modal.Window open={open} onClose={handleClose}>
      <Modal.Title
        children={t(
          'shipments.watch_shipments_dialog.title',
          'Set up ‘My shipments’'
        )}
        onClose={handleClose}
      />
      <Modal.Content sx={{ width: '552px' }}>
        <Typography variant="body1">
          {t(
            'shipments.watch_shipments_dialog.description',
            "You haven't set up any shipment groups yet. By creating these groups in your account settings, you can easily filter the application with shipments that are only relevant for you."
          )}
        </Typography>
      </Modal.Content>
      <Modal.Actions>
        <Button size="large" variant="text" onClick={handleClose}>
          {t('common.buttons.cancel', 'Cancel')}
        </Button>
        <Button
          sx={{ ml: 1 }}
          size="large"
          variant="contained"
          onClick={redirectToAccountSettings}
        >
          {t(
            'shipments.watch_shipments_dialog.set_up_shipments',
            'Go to account settings'
          )}
        </Button>
      </Modal.Actions>
    </Modal.Window>
  )
}
export default SetUpMyShipmentsModal

import { Box, Divider, Stack, Typography } from '@mui/material'
import { useRef, useState } from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import { AxiosError } from 'axios'
import LinearProgress from '@mui/material/LinearProgress'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import Popover from '@mui/material/Popover'
import { apiClient } from 'src/services/http-common'
import { useQuery } from '@tanstack/react-query'
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded'

interface EtaHistory {
  id: number
  updated_eta: string
  created_by_user_name: string
  created_at: string
}

const PopoverEtaHistory = (props) => {
  const { data, error, isLoading } = useQuery<
    EtaHistory[],
    AxiosError,
    EtaHistory[]
  >(
    ['etaHistoryData', props.shipmentId],
    () =>
      apiClient.get<any, EtaHistory[]>(
        `/shipments/${props.shipmentId}/estimated_arrival_history`
      ),
    {
      staleTime: 30_000,
    }
  )

  return (
    <Popover
      id="mouse-over-popover"
      open
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      onClose={props.handlePopoverClose}
      disableRestoreFocus
      sx={{
        pointerEvents: 'none',
      }}
      PaperProps={{
        onMouseEnter: props.handlePopoverOpen,
        onMouseLeave: props.handlePopoverClose,
      }}
    >
      <Box sx={{ p: 2 }}>
        <Stack direction="row" alignItems="center" gap={1}>
          <HistoryRoundedIcon />
          <Typography variant="h5">ETA History</Typography>
        </Stack>
      </Box>
      <Divider />
      <Box sx={{ width: '100%', height: '4px' }}>
        {isLoading && <LinearProgress color="info" />}
      </Box>
      <Box
        sx={{
          pointerEvents: 'auto',
          overflow: 'auto',
          minWidth: '300px',
          maxHeight: '400px',
        }}
      >
        {!isLoading && data && data.length > 0 && (
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
              '& .MuiTimelineDot-root': {
                padding: '1px',
                boxShadow: 'none',
              },
              '& .MuiTimelineConnector-root': {
                width: '1px',
              },
              '& .MuiTimelineItem-root': {
                minHeight: '52px',
              },
            }}
          >
            {data.map((entry, index) => (
              <TimelineItem key={entry.id}>
                <TimelineSeparator>
                  <TimelineDot
                    color={index === 0 ? 'primary' : 'grey'}
                  ></TimelineDot>
                  {data.length - 1 !== index && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Typography component="span" variant="body1">
                    {index === 0 && <b>{entry.updated_eta}</b>}
                    {index > 0 && entry.updated_eta}
                  </Typography>
                  <Typography variant="subtitle1" color="grey.600">
                    Updated on {entry.created_at} by{' '}
                    {entry.created_by_user_name}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        )}
        {(error || (!isLoading && (!data || data.length === 0))) && (
          <Box p={2.5}>
            <Typography variant="body1">There is no history yet.</Typography>
          </Box>
        )}
      </Box>
    </Popover>
  )
}

const EtaHistoryPopover = ({
  delay,
  disabled = false,
  ...props
}: {
  delay: EstimatedArrivalDelay
  'data-testid'?: string
  children: React.ReactChild
  shipmentId: number
  disabled?: boolean
}) => {
  const ref = useRef(null)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(ref.current)
  }
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  if (disabled || !delay) return <span>{props.children}</span>
  return (
    <Box ref={ref}>
      <div
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
      >
        {props.children}
      </div>
      {open && (
        <PopoverEtaHistory
          handlePopoverOpen={handlePopoverOpen}
          handlePopoverClose={handlePopoverClose}
          shipmentId={props.shipmentId}
          anchorEl={anchorEl}
        />
      )}
    </Box>
  )
}

export default EtaHistoryPopover

import groupBy from 'lodash/groupBy'
import capitalize from 'lodash/capitalize'
import {
  OptionsHeader,
  OptionsItem,
  SelectableChipOptions,
} from 'src/components/SelectableChip'

export const getShipmentSelectableStatusOptions = (
  statues: IShipmentStatus[]
) => {
  const groupedStatuses = groupBy(
    statues,
    (shipmentStatus) => shipmentStatus.phase?.name
  )
  return Object.keys(groupedStatuses).reduce(
    (res: SelectableChipOptions, groupKey) => {
      const statuses = groupedStatuses[groupKey].map(
        (shipmentStatus): OptionsItem => ({
          ...shipmentStatus,
          type: 'item',
        })
      )

      if (groupKey === 'undefined') return [...res, ...statuses]
      return [
        ...res,
        { name: capitalize(groupKey), type: 'header' } as OptionsHeader,
        ...statuses,
      ]
    },
    []
  )
}

import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/chat'
import * as actionCreators from '../../actionCreators/chat'
import {
  simpleGetAndResolve,
  simplePostAndResolve,
  simplePatchAndResolve,
} from '../factories'

const getShipmentChats = simpleGetAndResolve(
  (action) => `/api/v1/shipments/${action.shipmentId}/chats`,
  actionCreators.shipmentGetChatsSuccess,
  (response) => response.data.data
)

const markAllChatsRead = simplePatchAndResolve(
  (action) => '/api/v1/chats/mark_all_read',
  actionCreators.chatsMarkAllReadSuccess,
  (response) => response.data
)

const getChat = simpleGetAndResolve(
  (action) => `/api/v1/chats/${action.chatId}/chat_comments`,
  actionCreators.chatGetInitialDataSuccess,
  (response) => ({
    chat_comments: response.data.data.chat_comments,
    csat: response.data.csat,
    total_pages: response.data.data.total_pages,
    page: response.data.data.page,
  })
)

const getChatUsers = simpleGetAndResolve(
  (action) => `/api/v1/chats/${action.chatId}/users_for_chat`,
  actionCreators.chatGetUsersSuccess,
  (response) => response.data.data
)

const attachFileToShipment = simplePostAndResolve(
  (action) =>
    `/api/v1/chats/${action.chatId}/attach_comment_attachment?attachment_id=${action.attachmentId}`,
  actionCreators.chatAttachFileSuccess,
  (response) => response.data.data
)

const pinComment = simplePatchAndResolve(
  (action) =>
    `/api/v1/chats/${action.chatId}/chat_comments/${action.commentId}/toggle_pin`,
  actionCreators.chatPinCommentSuccess,
  (response) => response.data.data
)

const saveMessage = simplePatchAndResolve(
  (action) =>
    `/api/v1/chats/${action.chatId}/chat_comments/${action.commentId}/toggle_save`,
  actionCreators.chatSaveMessageSuccess,
  (response) => response.data.data
)

const toggleFollow = simplePatchAndResolve(
  (action) =>
    `/api/v1/chats/${action.chatId}/toggle_chat_follow?user_id=${action.userId}`,
  actionCreators.toggleChatFollowSuccess,
  (response) => response.data.data
)

const submitComment = simplePostAndResolve(
  (action) => `/api/v1/chats/${action.chatId}/chat_comments`,
  actionCreators.submitChatCommentSuccess,
  (response) => response.data.data
)

const acceptApproval = simplePatchAndResolve(
  (action) => `/api/v1/approvals/${action.id}/accept`,
  actionCreators.acceptApprovalSuccess,
  (response) => response.data.data
)

const rejectApproval = simplePatchAndResolve(
  (action) => `/api/v1/approvals/${action.id}/reject`,
  actionCreators.rejectApprovalSuccess,
  (response) => response.data.data
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.SHIPMENT_GET_CHATS, getShipmentChats)
  yield takeEvery(actions.CHATS_MARK_ALL_READ, markAllChatsRead)
  yield takeEvery(actions.CHAT_GET_INITIAL_DATA, getChat)
  yield takeEvery(actions.CHAT_GET_USERS, getChatUsers)
  yield takeEvery(actions.CHAT_ATTACH_FILE, attachFileToShipment)
  yield takeEvery(actions.CHAT_PIN_COMMENT, pinComment)
  yield takeEvery(actions.CHAT_SAVE_MESSAGE, saveMessage)
  yield takeEvery(actions.CHAT_SUBMIT_COMMENT, submitComment)
  yield takeEvery(actions.CHAT_TOGGLE_FOLLOW, toggleFollow)
  yield takeEvery(actions.CHAT_ACCEPT_APPROVAL, acceptApproval)
  yield takeEvery(actions.CHAT_REJECT_APPROVAL, rejectApproval)
}

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showNotification } from 'src/stores/actionCreators/notifications'

export default function useCopyLink(link) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const copyLink = useCallback((): void => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        dispatch(
          showNotification({
            message: t(
              'common.notifications.sharable_link_copied',
              'Sharable link is copied to you clipboard.'
            ),
            severity: 'success',
          })
        )
      })
      .catch((error) => {
        dispatch(
          showNotification({
            message: 'Sharable link failed to be copied to you clipboard.',
            severity: 'error',
          })
        )
      })
  }, [link])

  return copyLink
}

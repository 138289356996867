import { Box, Paper, Typography } from '@mui/material'
import { useHistory } from 'react-router'
import { Breadcrumbs } from 'src/stories/Breadcrumbs'
import { useTranslation } from 'react-i18next'

const TemplatesLayout = ({ currentPage = '', content }) => {
  const history = useHistory()
  const breadCrumbItemLinks = [
    {
      label: 'Shipment templates',
      onClick: () => history.push('/templates'),
    },
  ]
  const { t } = useTranslation()

  return (
    <Box className="templates">
      <Box mb={2} ml={8}>
        <Breadcrumbs links={breadCrumbItemLinks} current={currentPage} />
      </Box>
      <Paper elevation={0} sx={{ py: 3, px: 4, mx: 8 }}>
        <Typography variant="h3" gutterBottom children={currentPage} />
        <Typography
          mb={2}
          variant="body1"
          children={t(
            'templates.instructions',
            'Fields with an asterisk (*) are required. The rest is optional.'
          )}
        />
        {content}
      </Paper>
    </Box>
  )
}

export default TemplatesLayout

import { AnyAction } from 'redux'
import * as actions from '../actions/modalities'

export const updateCurrentModality = (
  id: string | number,
  payload: any
): AnyAction => ({
  type: actions.UPDATE_CURRENT_MODALITY,
  id,
  payload,
})

export const updateCurrentModalitySuccess = (payload: any): AnyAction => ({
  type: actions.UPDATE_CURRENT_MODALITY_SUCCESS,
  payload,
})

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { promisifyAction } from 'src/utils'
import Box from '@mui/material/Box'
import { updatePickupsAndDeliveriesItem } from 'src/stores/actionCreators'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { useGetConnectionsAsync } from 'src/services/Api/common'
import { IConnectionsResponse } from 'src/@types/endpoints/common'
import InlandTransportRowTransportSearch from './InlandTransportRowTransportSearch'
import { AutocompleteOptionPropsOptionProps } from './InlandTransportRow.props'
import { getAutocompleteSelectOptions } from './InlandTransportRow.utils'

const InlandTransportRowTransportTransporter: React.FC<{
  transporter: IInlandTransportState['transporter'] | null
  updatable: boolean
  rowId: number | null
  inputWidth?: number
  inline?: boolean
  onSelectValue?: (value: number) => void
}> = ({ transporter, rowId, updatable, inputWidth, inline, onSelectValue }) => {
  const dispatch = useDispatch()

  const defaultValue:
    | AutocompleteOptionPropsOptionProps
    | undefined = !!transporter
    ? { value: transporter.id, label: transporter.name }
    : undefined

  const [defaultOptions, setDefaultOptions] = useState(
    defaultValue ? [defaultValue] : []
  )

  const [selected, setValue] = useState(defaultValue)
  const [options, setOptions] = useState(defaultOptions)

  useEffect(() => {
    if (transporter) {
      setValue({ value: transporter.id, label: transporter.name })
    }
  }, [transporter])

  const {
    isFetched,
    isFetching,
    fetchAsync: getTransporters,
  } = useGetConnectionsAsync()

  const updateTransportAddressAsync = promisifyAction(
    dispatch,
    updatePickupsAndDeliveriesItem
  )

  const handleGetTransportersSuccess = (
    res: IConnectionsResponse | undefined
  ) => {
    const fetchedOptions = res
      ? res.list.map((item) => ({
          value: item.organization_id,
          label: item.name,
        }))
      : null

    const options = getAutocompleteSelectOptions(
      selected,
      defaultOptions,
      fetchedOptions
    )

    if (options) {
      setOptions(options)
    }
  }

  const getTransportersAsync = async (search: string) => {
    const hasOption = options.find((option) => option.label === search)
    if (search.length === 0 || !!hasOption) {
      return
    }

    getTransporters({
      search,
      per_page: 1000,
      role: 'Transporter',
    }).then(handleGetTransportersSuccess)
  }

  const updateTransportAddress = (
    transporterOrganizationId: number,
    tempSelection: AutocompleteOptionPropsOptionProps | undefined
  ) => {
    updateTransportAddressAsync(rowId, {
      transporter_organization_id: transporterOrganizationId,
    })
      .then(() => {
        dispatch(
          showNotification({
            severity: 'success',
            message: 'Transporter updated',
          })
        )
      })
      .catch(() => {
        setValue(tempSelection)
      })
  }

  const handleSelectChange = (
    newSelection: AutocompleteOptionPropsOptionProps | null
  ) => {
    if (!updatable) {
      return
    }
    const tempSelection = selected
    setValue(newSelection === null ? undefined : newSelection)
    setDefaultOptions(newSelection === null ? [] : [newSelection])

    if (newSelection) {
      if (inline && onSelectValue) {
        onSelectValue(newSelection.value)
      } else {
        updateTransportAddress(newSelection.value, tempSelection)
      }
    }
  }

  return (
    <Box
      height="100%"
      display="flex"
      alignItems="center"
      data-testid="inland-transport-transporter"
    >
      {updatable && (
        <InlandTransportRowTransportSearch
          {...{
            options,
            selected,
            isFetched,
            isFetching,
            onChange: handleSelectChange,
            onInputChange: getTransportersAsync,
            inputPlaceholder: 'Search transporter',
            inputWidth: inputWidth,
            inline: inline,
          }}
        />
      )}
      {!updatable && <Box>{selected?.label ?? '-'}</Box>}
    </Box>
  )
}

export default InlandTransportRowTransportTransporter

import { fork } from 'redux-saga/effects'
import account from './account'
import user from './user'
import users from './users'
import dashboard from './dashboard'
import documents from './documents'
import countries from './countries'
import addresses from './addresses'
import addressBankDetails from './addressBankDetails'
import personalDetails from './personalDetails'
import pickUpsAndDeliveries from './pickupsAndDeliveries'
import companies from './companies'
import searches from './searches'
import searchPrevious from './searchPrevious'
import searchOverview from './searchOverview'
import searchBooking from './searchBooking'
import hsCodes from './hsCodes'
import reports from './reports'
import shipmentTrack from './shipmentTrack'
import shipmentLink from './shipmentLink'
import shipmentLayout from './shipmentLayout'
import shipmentPrice from './shipmentPrice'
import shipmentPurchaseOrders from './shipmentPurchaseOrders'
import quotes from './quotes'
import shipments from './shipments'
import connections from './connections'
import tasks from './tasks'
import shipmentOverview from './shipmentOverview'
import chat from './chat'
import shipmentDocs from './shipmentDocs'
import routing from './routing'
import organizations from './organizations'
import organizationSettings from './organizationSettings'
import messages from './messageCenter'
import shipmentPickupAndDelivery from './shipmentPickupAndDelivery'
import modalities from './modalities'
import bookings from './bookings'
import packageTypes from './packageTypes'
import purchaseOrders from './purchaseOrders'
import publicPO from './publicPO'
import shipmentQuotations from './shipmentQuotations'
import shipmentComments from './shipmentComments'
import ports from './ports'
import csat from './csat'
import reportsDashboard from './reportsDashboard'
import serviceItems from './serviceItems'
import trackAndTrace from './trackAndTrace'
import watchShipmentRules from './watchShipmentRules'
import rates from './rates'

export default function* (): Iterator<any> {
  yield fork(account)
  yield fork(user)
  yield fork(users)
  yield fork(countries)
  yield fork(addresses)
  yield fork(addressBankDetails)
  yield fork(personalDetails)
  yield fork(companies)
  yield fork(dashboard)
  yield fork(documents)
  yield fork(searches)
  yield fork(searchPrevious)
  yield fork(searchOverview)
  yield fork(searchBooking)
  yield fork(hsCodes)
  yield fork(reports)
  yield fork(shipments)
  yield fork(connections)
  yield fork(shipmentTrack)
  yield fork(shipmentLink)
  yield fork(shipmentPrice)
  yield fork(shipmentPurchaseOrders)
  yield fork(quotes)
  yield fork(tasks)
  yield fork(shipmentLayout)
  yield fork(shipmentOverview)
  yield fork(chat)
  yield fork(shipmentDocs)
  yield fork(routing)
  yield fork(organizations)
  yield fork(organizationSettings)
  yield fork(messages)
  yield fork(shipmentPickupAndDelivery)
  yield fork(modalities)
  yield fork(bookings)
  yield fork(packageTypes)
  yield fork(pickUpsAndDeliveries)
  yield fork(purchaseOrders)
  yield fork(publicPO)
  yield fork(shipmentQuotations)
  yield fork(shipmentComments)
  yield fork(ports)
  yield fork(csat)
  yield fork(reportsDashboard)
  yield fork(serviceItems)
  yield fork(trackAndTrace)
  yield fork(watchShipmentRules)
  yield fork(rates)
}

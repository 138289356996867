import { ReactNode, FunctionComponent } from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { makeStyles } from '@mui/styles'

interface IProps {
  children: ReactNode
  onClose?: () => void
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))

const Title: FunctionComponent<IProps> = (props) => {
  const classes = useStyles()
  return (
    <DialogTitle className={classes.root}>
      {props.children}
      <IconButton aria-label="Close" onClick={props.onClose} size="large">
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  )
}

export default Title

import { AnyAction } from 'redux'
import * as actions from '../actions/reportsDashboard'

interface DeletePayload {
  lastComment: IComment | null
  shipmentId: number
}

interface UpdateTaskStatusPayload {
  task_status_id: any
}

export const fetchReportsDashboardShipments = (
  payload: any,
  isBackgroundReload: boolean = false
): AnyAction => ({
  type: actions.REPORTS_DASHBOARD_FETCH_DATA,
  payload,
  isBackgroundReload,
})

export const fetchReportsDashboardShipmentsSuccess = (
  payload: any
): AnyAction => ({
  type: actions.REPORTS_DASHBOARD_FETCH_DATA_SUCCESS,
  payload,
})

export const updateReportsDashboardScope = (payload: any): AnyAction => ({
  type: actions.REPORTS_DASHBOARD_UPDATE_SCOPE,
  payload,
})

export const updateReportsDashboardFilters = (payload: any): AnyAction => ({
  type: actions.REPORTS_DASHBOARD_UPDATE_FILTERS,
  payload,
})

export const updateReportsDashboardAsyncFilters = (
  payload: any
): AnyAction => ({
  type: actions.REPORTS_DASHBOARD_UPDATE_ASYNC_FILTERS,
  payload,
})

export const fetchReportsDashboardFilterOptions = (): AnyAction => ({
  type: actions.REPORTS_DASHBOARD_FETCH_FILTER_OPTIONS,
})

export const fetchReportsDashboardFilterOptionsSuccess = (
  payload: any
): AnyAction => ({
  type: actions.REPORTS_DASHBOARD_FETCH_FILTER_OPTIONS_SUCCESS,
  payload,
})

export const reportsDashboardClearFilters = (): AnyAction => ({
  type: actions.REPORTS_DASHBOARD_CLEAR_FILTERS,
})

export const setEditCommentsModalInformation = (payload: any): AnyAction => ({
  type: actions.REPORTS_DASHBOARD_SET_EDIT_COMMENT_INFO,
  payload,
})

export const reportsDashboardDeletedLastComment = (
  payload: DeletePayload
): AnyAction => ({
  type: actions.REPORTS_DASHBOARD_DELETED_LAST_COMMENT,
  payload,
})

export const reportsDashboardUpdateTaskStatus = (
  shipmentId: number,
  taskId: number,
  payload: UpdateTaskStatusPayload
): AnyAction => ({
  type: actions.REPORTS_DASHBOARD_UPDATE_TASK_STATUS,
  shipmentId,
  taskId,
  payload,
})

export const reportsDashboardUpdateTaskStatusSuccess = (
  payload: any
): AnyAction => ({
  type: actions.REPORTS_DASHBOARD_UPDATE_TASK_STATUS_SUCCESS,
  payload,
})

import { makeStyles } from '@mui/styles'

export const searchHeight = 40
export const selectWidth = 136

export const useStyles = makeStyles((theme) => {
  return {
    navBarTooltip: {
      width: 260,
      '& ul': {
        margin: theme.spacing(0.5, 0),
        padding: theme.spacing(0, 3),
      },
    },
    navBarSearch: {
      height: searchHeight,
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      flexDirection: 'row',
      '& .MuiTextField-root': {
        top: 0,
        right: 0,
        position: 'absolute',
        zIndex: 1,
      },
      '&.inverse': {
        '& .MuiTextField-root': {
          right: 'auto',
          left: 0,
          backgroundColor: theme.palette.common.white,
        },
        '& .MuiButtonBase-root': {
          backgroundColor: theme.palette.common.white,
          left: -1,
        },
      },
    },
    searchBarInactive: {
      '& .MuiTextField-root': {
        width: 200,
      },
      '&.inverse': {
        '& .MuiTextField-root': {
          width: 234,
        },
      },
      '&:not(.inverse)': {
        '& .MuiOutlinedInput-root': {
          color: theme.palette.primary['A100'],
          '& fieldset': {
            borderColor: theme.palette.primary[200],
          },
          '& input': {
            '&::placeholder': {
              color: theme.palette.primary['A100'],
            },
          },
          '& svg': {
            color: theme.palette.primary[200],
          },
        },
      },
      '& .MuiOutlinedInput-root': {
        minHeight: searchHeight,
        '& input': {
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
        },
      },
    },
    searchBarActive: {
      '& .MuiTextField-root': {
        width: 300,
        transition: theme.transitions.create(['width'], {
          duration: theme.transitions.duration.shortest,
        }),
      },
      '&.inverse': {
        '& .MuiTextField-root': {
          width: '100%',
        },
        '& .MuiOutlinedInput-root': {
          width: '100%',
        },
      },
      '&:not(.inverse)': {
        color: theme.palette.primary.main,
        '& input': {
          '&::placeholder': {
            color: theme.palette.grey[500],
          },
        },
        '& fieldset': {
          borderColor: theme.palette.grey[50],
          backgroundColor: theme.palette.common.white,
        },
      },
      '& .MuiOutlinedInput-root': {
        paddingRight: 0,
        '& input': {
          cursor: 'text',
        },
        '& fieldset': {
          zIndex: -1,
        },
      },
    },
    menuToggler: {
      width: selectWidth,
      position: 'relative',
      paddingLeft: 0,
      paddingRight: 0,
      '&::before': {
        top: 5,
        left: 0,
        width: 1,
        height: 24,
        content: '""',
        position: 'absolute',
        display: 'inline-block',
        backgroundColor: theme.palette.grey[300],
      },
      '& svg': {
        fontSize: theme.spacing(3),
        marginLeft: theme.spacing(1),
        color: theme.palette.grey[600],
        transition: theme.transitions.create(['transform'], {
          duration: theme.transitions.duration.shortest,
        }),
      },
      '&:hover': {
        '& svg': {
          color: theme.palette.primary.main,
        },
        backgroundColor: 'transparent',
      },
    },
    menuOpened: {
      '& svg': {
        transform: 'rotate(180deg)',
      },
    },
    menuClosed: {
      '& svg': {
        transform: 'rotate(0)',
      },
    },
  }
})

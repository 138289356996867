import { IconButtonProps, MenuProps } from '@mui/material'
import TrafficIcon from '@mui/icons-material/Traffic'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import InboxIcon from '@mui/icons-material/Inbox'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import DirectionsBoatOutlinedIcon from '@mui/icons-material/DirectionsBoatOutlined'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined'
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined'
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined'
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined'
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined'
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined'

import SearchIcon from '@mui/icons-material/Search'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import {
  MenuItemLinkProps,
  CreateMenuItemProps,
  SearchSelectOptionProps,
} from './TopNavigation.props'

const pickupsAndDeliveriesPermissions = [
  'pickups_deliveries.limited_manage',
  'pickups_deliveries.full_manage',
  'pickups_deliveries.shypple_admin',
  'pickups_deliveries.view_only',
]

export const tasksPermissions = ['navigation_bar.open_tasks.all']

export const defaultMobileBreakpoint = 1200
export const largeMobileBreakpoint = 1440
export const drawerWidth = 392
export const toolbarHeight = 56

export const navItems = (t): MenuItemLinkProps[] => [
  {
    key: 'shipments',
    label: t('top_navigation.shipments.title', 'Shipments'),
    to: '/shipments',
    icon: <SwapHorizIcon />,
  },
  {
    key: 'transport',
    label: t('top_navigation.transports.title', 'Transports'),
    to: '/transports',
    permission: pickupsAndDeliveriesPermissions,
    icon: <LocalShippingOutlinedIcon />,
  },
  {
    key: 'inbox',
    label: t('top_navigation.inbox.title', 'Inbox'),
    to: '/message_centre',
    icon: <InboxIcon />,
  },
  {
    key: 'more',
    label: t('top_navigation.more.title', 'More'),
    children: [
      {
        key: 'documents',
        label: t('top_navigation.documents.title', 'Documents'),
        to: '/documents',
        permission: ['document.view'],
        icon: <FileCopyOutlinedIcon />,
      },
      {
        key: 'purchase-orders',
        label: t('top_navigation.purchase_orders.title', 'Order management'),
        to: '/order-management',
        permission: ['purchase_orders.view'],
        icon: <ReceiptLongOutlinedIcon />,
      },
      {
        key: 'quotes',
        label: t('top_navigation.quotes.title', 'Quotes'),
        to: '/quotes',
        permission: ['your_quotes.all'],
        icon: <RequestQuoteOutlinedIcon />,
      },
      {
        key: 'templates',
        label: t('top_navigation.templates.title', 'Templates'),
        to: '/templates',
        permission: ['shipment_templates.view'],
        icon: <LibraryAddOutlinedIcon />,
      },
      {
        key: 'rates',
        label: t('top_navigation.rates.title', 'Rates'),
        to: '/rates',
        permission: ['your_rates.all'],
        icon: <ManageSearchOutlinedIcon />,
      },
      {
        key: 'reports',
        label: t('top_navigation.reports.title', 'Reports'),
        to: '/reports',
        icon: <EqualizerOutlinedIcon />,
      },
      {
        key: 'shipments-map',
        label: t('top_navigation.map.title', 'Shipments map'),
        to: '/map_overviews',
        permission: ['track_and_trace.all'],
        icon: <MapOutlinedIcon />,
      },
      {
        key: 'vessels',
        label: t('top_navigation.vessels.title', 'Vessels'),
        to: '/vessels',
        icon: <DirectionsBoatOutlinedIcon />,
      },
    ],
  },
  {
    key: 'ops-dashboard',
    label: t('top_navigation.ops_dashboard.title', 'Ops dashboard'),
    to: '/operations-dashboard',
    permission: ['reports_dashboard.view'],
    icon: <TrafficIcon />,
  },
]

export const userMenuLinkItems = (t): MenuItemLinkProps[] => [
  {
    key: 'account-settings',
    label: t(
      'top_navigation.user_menu.account_settings.title',
      'Account settings'
    ),
    to: '/account',
    icon: <AccountCircleOutlinedIcon />,
  },
  {
    key: 'organization-settings',
    label: t(
      'top_navigation.user_menu.org_settings.title',
      'Organization settings'
    ),
    to: '/organization_settings',
    permission: ['organization_settings.view'],
    icon: <CorporateFareOutlinedIcon />,
  },
  {
    key: 'address-book',
    label: t('top_navigation.user_menu.address_book.title', 'Address book'),
    to: '/contacts',
    permission: ['address_book.view'],
    icon: <FormatListBulletedOutlinedIcon />,
  },
]

export const createMenuLinkItems = (t): CreateMenuItemProps[] => [
  {
    color: 'primary',
    key: 'search-and-book',
    label: t('top_navigation.create_menu.booking.title', 'Booking'),
    description: t(
      'top_navigation.create_menu.booking.description',
      'Book your shipment or request a quote via "Search & Book" or "Templates"'
    ),
    to: '/search',
    icon: <SearchIcon />,
    permission: ['search_and_book.all'],
    visibilityOnly: false,
  },
  {
    color: 'secondary',
    key: 'local-handling',
    label: t(
      'top_navigation.create_menu.handling_request.title',
      'Handling request'
    ),
    description: t(
      'top_navigation.create_menu.handling_request.description',
      'Book local services (terminal handling, customs or transportation) for your shipments'
    ),
    to: '/request-handling',
    icon: <LocalShippingIcon />,
    permission: ['search_and_book.request_handling.all'],
    visibilityOnly: false,
  },
  {
    color: 'info',
    key: 'add-shipments',
    label: t(
      'top_navigation.create_menu.tracking_only.title',
      'Tracking-only shipment'
    ),
    description: t(
      'top_navigation.create_menu.tracking_only.description',
      'Keep track of all your shipments in OneView'
    ),
    to: '/shipments/new',
    icon: <CloudUploadOutlinedIcon />,
    permission: ['search_and_book.all'],
    visibilityOnly: true,
  },
]

export const popupProps: Pick<
  MenuProps,
  'PaperProps' | 'anchorOrigin' | 'transformOrigin' | 'elevation'
> = {
  elevation: 4,
  PaperProps: { sx: { mt: 2 } },
  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
  transformOrigin: { vertical: 'top', horizontal: 'left' },
}

export const searchSelectOptions = (t): SearchSelectOptionProps[] => [
  {
    text: t('top_navigation.search_options.shipments.title', 'Shipments'),
    path: 'shipments?page=1&scope=all',
    permissions: '',
  },
  {
    text: t('top_navigation.search_options.containers.title', 'Containers'),
    path: 'transports?order_by=newest_first&page=1&per_page=50&scope=delivery',
    permissions: pickupsAndDeliveriesPermissions,
  },
  {
    text: t('top_navigation.search_options.documents.title', 'Documents'),
    path: 'documents?documents_per_page=20&page=1',
    permissions: ['document.view'],
  },
]

export const drawerIconButtonProps: IconButtonProps = {
  size: 'large',
  color: 'primary',
  sx: { '&.active': { backgroundColor: 'primary.light' } },
}

import { useTranslation } from 'react-i18next'
import { SetStateAction, useState } from 'react'
import omit from 'lodash/omit'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import { useGetDemurrageAndDetention } from 'src/services/Api/demurrageAndDetention'
import { getTabs } from './DemurrageAndDetentionContent.constants'
import { DemurrageAndDetentionContentProps } from './DemurrageAndDetentionContent.props'
import { StyledTab } from './DemurrageAndDetentionContent.styles'

const DemurrageAndDetentionContentTabs: React.FC<DemurrageAndDetentionContentProps> = (
  props
) => {
  const { t } = useTranslation()
  const [currentTab, setCurrentTab] = useState('default')
  const { data } = useGetDemurrageAndDetention()
  const carriers = data?.carriers || []
  const tabs = getTabs(carriers, { ...props, currentTab }, t)
  const handleTabChange = (
    _event: React.SyntheticEvent,
    newValue: SetStateAction<string>
  ) => {
    setCurrentTab(newValue)
  }

  return (
    <Box data-testid="demurrage-and-detention-tabs">
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
        <Tabs value={currentTab} onChange={handleTabChange}>
          {tabs.map((tab) => {
            return (
              <StyledTab
                iconPosition="start"
                {...omit(tab, ['component'])}
                value={tab.key}
              />
            )
          })}
        </Tabs>
      </Box>
      {tabs.map((tab) => (
        <Box key={tab.key} display={tab.key === currentTab ? 'block' : 'none'}>
          {tab.key === currentTab && tab.component}
        </Box>
      ))}
    </Box>
  )
}

export default DemurrageAndDetentionContentTabs

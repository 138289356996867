import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

export const AlignedStack = styled(Stack)(() => ({
  display: 'flex',
  alignItems: 'center',
}))

export const AlignedStackItem = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  color: theme.palette.primary.main,
}))

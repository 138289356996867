import { createReducer, Handlers, Reducer } from 'redux-create-reducer'
import { AnyAction } from 'redux'
import * as actions from '../actions/searchBooking'

declare global {
  interface IPlaceMatchedSubstring {
    length: number
    offset: number
  }

  interface IPlaceSuggestion {
    main_text: string
    secondary_text?: string
    place_id: string
    port_id: number
    matched_substrings: IPlaceMatchedSubstring[]
    default_address: boolean
    result_type: 'address' | 'place' | 'port'
  }

  interface ISearchBooking {
    id: number
    title: string
    quote: boolean
    type: string
    loading: boolean
    suggestions: IPlaceSuggestion[]
  }
}

export const initialSearchQuoteBookingState: ISearchBooking = {
  id: 0,
  title: '',
  quote: false,
  type: '',
  loading: false,
  suggestions: [],
}

const searchQuotesBooking: Reducer<ISearchBooking, AnyAction> = (
  state,
  { payload }
) => ({
  ...payload.data.data,
  loading: false,
})

const searchQuotesBookingStart: Reducer<ISearchBooking, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  loading: true,
})

const searchAddressSuccess: Reducer<ISearchBooking, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  suggestions: payload.places,
})

const searchQuotesBookingReset: Reducer<ISearchBooking, AnyAction> = (
  state,
  { payload }
) => initialSearchQuoteBookingState

const actionHandlers: Handlers<ISearchBooking> = {
  [actions.SEARCH_QUOTES_BOOKING_SUCCESS]: searchQuotesBooking,
  [actions.SEARCH_QUOTES_BOOKING]: searchQuotesBookingStart,
  [actions.SEARCH_QUOTES_BOOKING_RESET]: searchQuotesBookingReset,
  [actions.SEARCH_ADDRESS_SUCCESS]: searchAddressSuccess,
}

export default createReducer(initialSearchQuoteBookingState, actionHandlers)

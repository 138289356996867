import { memo } from 'react'
import { ActionCableConsumer } from 'react-actioncable-provider'

interface IProps {
  onReceived: (receivedMessage: any) => void
  forwardRef: (elem: ActionCableConsumer) => void
  channel: {
    channel: string
    chat_id?: number
  }
}

function ChatChannelConsumerWrapper(props: IProps) {
  return (
    <ActionCableConsumer
      ref={props.forwardRef}
      channel={props.channel}
      onReceived={props.onReceived}
    />
  )
}

export default memo(
  ChatChannelConsumerWrapper,
  (prev, next) => prev.channel.chat_id === next.channel.chat_id
)

import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat'
import TrainIcon from '@mui/icons-material/Train'
import LocalAirportIcon from '@mui/icons-material/LocalAirport'
import { ModalityEnum } from 'src/config/constants'

export const modalityIcons = {
  [ModalityEnum.Rail]: TrainIcon,
  [ModalityEnum.Air]: LocalAirportIcon,
  [ModalityEnum.Sea]: DirectionsBoatIcon,
}

type ModalityIconProps = {
  modality: string
}

export const ShipmentModalityIcon: React.FC<ModalityIconProps> = (props) => {
  const { modality, ...other } = props
  const DynamicIcon = modalityIcons[modality]
  if (!DynamicIcon) {
    return null
  }
  return <DynamicIcon {...other} />
}

import { AnyAction } from 'redux'
import * as actions from '../actions/tasks'

export const getYourOpenTasks = (signal: any = null): AnyAction => ({
  type: actions.GET_YOUR_OPEN_TASKS,
  signal,
})

export const getYourOpenTasksSuccess = (payload: any): AnyAction => ({
  type: actions.GET_YOUR_OPEN_TASKS_SUCCESS,
  payload,
})

export const getAllOpenTasks = (signal: any): AnyAction => ({
  type: actions.GET_ALL_OPEN_TASKS,
  signal,
})

export const getAllOpenTasksSuccess = (payload: any): AnyAction => ({
  type: actions.GET_ALL_OPEN_TASKS_SUCCESS,
  payload,
})

export const getTasksStatusTypes = (): AnyAction => ({
  type: actions.GET_TASKS_STATUS_TYPES,
})

export const getTasksStatusTypesSuccess = (payload: any): AnyAction => ({
  type: actions.GET_TASKS_STATUS_TYPES_SUCCESS,
  payload,
})

export const getShipmentTasksSuccess = (payload: any): AnyAction => ({
  type: actions.GET_SHIPMENT_TASKS_SUCCESS,
  payload,
})

export const getShipmentTasks = (id: number): AnyAction => ({
  type: actions.GET_SHIPMENT_TASKS,
  id,
})

export const cleanTasksState = (): AnyAction => ({
  type: actions.CLEAN_TASKS_STATE,
})

import uuid from 'uuid'

export const initialCargo = () => {
  return {
    id: uuid(),
    quantity: 1,
    package_type_id: 1,
    description: '',
    length: 0,
    width: 0,
    height: 0,
    total_volume_cbm: 0,
    weight: 0,
    stackable: null,
  }
}

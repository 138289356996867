import Box from '@mui/material/Box'
import { DateTime } from 'luxon'
import Button from '@mui/material/Button'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import { useTranslation } from 'react-i18next'
import { useGetDemurrageAndDetention } from 'src/services/Api/demurrageAndDetention'
import {
  downloadFile,
  getCsvUrlFromTwoDimensionalArray,
} from 'src/utils/helpers/content'
import { exportedFileColumns } from './DemurrageAndDetentionTopSection.constants'

const DemurrageAndDetentionTopSectionActions = () => {
  const { t } = useTranslation()
  const { data, isLoading } = useGetDemurrageAndDetention()
  const items = data?.items || []

  const columns = exportedFileColumns.map((item) => item.label)
  const keys = exportedFileColumns.map((item) => item.value)

  const selectedItems = items.map((item) => {
    return keys.map((key) => {
      return item[key]
    })
  })

  const csvData = [columns, ...selectedItems]

  const handleClickOnDownload = () => {
    downloadFile(
      getCsvUrlFromTwoDimensionalArray(csvData),
      `Demurrage-detention-${DateTime.now().toFormat('dd-MMM-yyyy')}.csv`
    )
  }

  return (
    <Box display="flex" justifyContent="end">
      <Button
        size="large"
        disabled={isLoading}
        variant="outlined"
        children={t('demurrage_and_detention.download', 'Download')}
        startIcon={<SaveAltIcon />}
        onClick={handleClickOnDownload}
        data-testid="demurrage-and-detention-download-button"
      />
    </Box>
  )
}

export default DemurrageAndDetentionTopSectionActions

import AutocompleteCommon from 'src/stories/Lab/Autocomplete/AutocompleteCommon'
import FormLabel from 'src/stories/Lab/FormLabel'
import { FormElementProps } from './FormInput.props'

export const FormMultipleAutocomplete = ({
  qs,
  name,
  label,
  onChange,
  options = [],
  ...props
}: FormElementProps) => {
  const value = (qs?.[name] ?? []) as (string | number)[]
  const valueObject = options.filter((option) => value.includes(option.id))
  return (
    <>
      <FormLabel label={label} count={valueObject.length} />
      <AutocompleteCommon
        value={valueObject}
        disablePortal={true}
        options={options}
        onChange={(updatedValue: any[]) => {
          return onChange({ [name]: updatedValue.map((val) => val.id) })
        }}
        onReset={() => {
          return onChange({ [name]: [] })
        }}
        data-testid={props['data-testid']}
        placeholder={props.placeholder}
      />
    </>
  )
}

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Stack } from '@mui/system'
import { promisifyAction } from 'src/utils'
import { OptionsItem } from 'src/components/SelectableChip'
import { DateTime } from 'luxon'
import {
  updateTaskStatus,
  updatePickupsAndDeliveriesItem,
} from 'src/stores/actionCreators'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { convertDateToOrderFormat } from 'src/utils/helpers/shipmentDate'
import DatePicker from 'src/stories/DatePicker'
import { inspectionStatusWidth } from '../constants'
import InlandTransportRowEditableCellText from './InlandTransportRowEditableCellText'
import InlandTransportRowInspectionStatus from './InlandTransportRowInspectionStatus'
import { getInspectionDateText } from './InlandTransportRow.utils'

import { InlandTransportRowInspectionProps } from './InlandTransportRow.props'

const InlandTransportRowInspection: React.FC<InlandTransportRowInspectionProps> = ({
  task,
  rowId,
  tasks,
  updatable,
  shipmentId,
  inspectionDateTime,
}) => {
  const dispatch = useDispatch()

  const updateTaskStatusAsync = promisifyAction(dispatch, updateTaskStatus)

  const updateDateTimeAsync = promisifyAction(
    dispatch,
    updatePickupsAndDeliveriesItem
  )

  const [open, setOpen] = useState(false)

  const [currentDate, setCurrentDate] = useState(
    inspectionDateTime ? DateTime.fromISO(inspectionDateTime) : null
  )

  const [currentTask, setCurrentTask] = useState<OptionsItem>({
    type: 'item',
    name: task?.task_status ?? '',
    id: task?.task_status_id ?? '',
  })

  useEffect(() => {
    if (task && task.task_status_id !== currentTask.id) {
      setCurrentTask({
        type: 'item',
        name: task?.task_status ?? '',
        id: task?.task_status_id ?? '',
      })
    }
  }, [task])

  useEffect(() => {
    if (inspectionDateTime) {
      setCurrentDate(DateTime.fromISO(inspectionDateTime))
    }
  }, [inspectionDateTime])

  const handleClick = (event) => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleClickOnContainer = (e: {
    stopPropagation: () => void
    preventDefault: () => void
  }) => {
    if (!updatable) {
      return
    }
    e.stopPropagation()
    e.preventDefault()
  }

  const handleUpdateSuccess = (message: string) => {
    dispatch(
      showNotification({
        severity: 'success',
        message,
      })
    )
  }

  const handleDateChange = (newDate: DateTime | null) => {
    const tempDate = currentDate

    setCurrentDate(newDate)
    handleClose()

    updateDateTimeAsync(rowId, {
      delivery_inspection_datetime: newDate,
    })
      .then(() => {
        handleUpdateSuccess('Inspection date & time updated')
      })
      .catch(() => {
        setCurrentDate(tempDate)
      })
  }

  const handleTaskStatusChange = (newTask: OptionsItem) => {
    const tempTask = currentTask
    setCurrentTask(newTask)
    updateTaskStatusAsync(shipmentId, task?.id, {
      task_status_id: newTask.id,
    })
      .then(() => {
        handleUpdateSuccess('Inspection task status updated')
      })
      .catch(() => {
        setCurrentTask(tempTask)
      })
  }

  const onDateChange = (newDate: DateTime | null) => {
    setCurrentDate(newDate)
  }

  const formattedDate = convertDateToOrderFormat(
    currentDate ? currentDate.toISO() : null,
    'dd MMM HH:mm'
  )

  const taskTypeCode: string =
    tasks.find((taskItem) => {
      return taskItem.id === currentTask.id
    })?.task_status_type?.code ?? ''

  const dateIsUpdatable = updatable && taskTypeCode === 'to_do'

  return (
    <Box onClick={handleClickOnContainer} height="100%">
      <Stack direction="row" height="100%" alignItems="center" spacing={0}>
        <InlandTransportRowInspectionStatus
          tasks={tasks}
          task={currentTask}
          updatable={updatable}
          onChange={handleTaskStatusChange}
        />
        {!open && dateIsUpdatable && (
          <InlandTransportRowEditableCellText
            text={formattedDate}
            onClick={handleClick}
            linkText="Add date & time"
          />
        )}
        {!open && !dateIsUpdatable && (
          <Box sx={{ cursor: 'default' }}>
            {getInspectionDateText(formattedDate, taskTypeCode)}
          </Box>
        )}
        {open && dateIsUpdatable && (
          <Box width={inspectionStatusWidth}>
            <DatePicker
              open
              data-testid="inland-transport-editable-date-picker"
              clearable={false}
              closeOnSelect={false}
              inputFormat="dd-MM-yyyy HH:mm"
              value={currentDate?.toISO() || null}
              onClose={handleDateChange}
              onChange={onDateChange}
            />
          </Box>
        )}
      </Stack>
    </Box>
  )
}

export default InlandTransportRowInspection

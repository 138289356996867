import { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import { compact } from 'lodash'
import { ToggleButtonProps } from '@mui/lab'
import { Box, SvgIcon, Typography } from '@mui/material'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import { ButtonGroup } from 'src/stories/ButtonGroup'

interface IProps {
  openWindow: (type: string) => void
}

const PickupsAndDeliveriesTableActions: FunctionComponent<IProps> = ({
  openWindow,
}) => {
  const { selectedRowsIds, scope } = useSelector((state: IGlobalState) => ({
    selectedRowsIds: state.pickUpsAndDeliveries.selectedInlandTransportRowsIds,
    scope: state.pickUpsAndDeliveries.filters.scope,
  }))

  const buttons = [
    {
      value: 'counter',
      children: (
        <>
          <Typography
            variant="body1"
            children={`${selectedRowsIds.length} selected`}
            data-testid="selected-rows-counter"
          />
        </>
      ),
    },
    {
      value: 'action',
      children: (
        <>
          <SvgIcon>
            <LocalShippingOutlinedIcon color="primary" />
          </SvgIcon>
          <Typography
            variant="body1"
            children={`Plan ${scope === 'pickup' ? 'pick-up' : scope}`}
          />
        </>
      ),
      onClick: () => openWindow(scope),
      'data-testid': 'open-window-button',
    },
  ]

  return (
    <Box
      m={0.5}
      sx={{
        position: 'absolute',
        backgroundColor: 'grey.50',
        zIndex: '1',
        borderRadius: 1,
      }}
    >
      <ButtonGroup buttons={compact(buttons) as ToggleButtonProps[]} />
    </Box>
  )
}

export default PickupsAndDeliveriesTableActions

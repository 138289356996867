import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import { useState } from 'react'
import { useCreateNewShipmentTemplate } from 'src/services/Api/shipmentTemplates'

import { showNotification } from 'src/stores/actionCreators/notifications'
import VerticalLinearStepper from 'src/stories/Stepper'
import { useFormContext } from '../../FormContext'
import { useGetStepsAdditionalInfo } from '../../Form.hooks'
import { getFormPayload } from '../../Payload.utils'
import TemplateFormInitialDetails from '../Steps/InitialDetails'
import { TemplateFormContextProps } from '../../Form.props'

const TemplatesFormCreate = ({ steps }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [step, setStep] = useState(0)
  const {
    formState,
    shipmentRoles,
  } = useFormContext() as TemplateFormContextProps
  const additionalInfo = useGetStepsAdditionalInfo()

  const {
    mutateAsync: createNewShipmentTemplate,
  } = useCreateNewShipmentTemplate()

  const { user } = useSelector((state: IGlobalState) => ({
    user: state.user,
  }))

  const nextButtonEnabled = steps[step]?.requiredFields?.every((field) => {
    const fieldValue = get(formState, field)
    return fieldValue !== null && fieldValue !== ''
  })

  const handleSaveSuccess = () => {
    dispatch(
      showNotification({
        message: `Template "${formState.name}" successfully created`,
        severity: 'success',
      })
    )
    history.push('/templates')
  }

  const handleSave = (activeStep) => {
    if (activeStep !== steps.length - 1) {
      return
    }
    const payload = getFormPayload({
      formState,
      shipmentRoles,
      userOrganizationId: user.organizationId,
    })
    createNewShipmentTemplate(payload).then(handleSaveSuccess)
  }

  return (
    <div data-testid="create-template-form">
      <TemplateFormInitialDetails />
      <VerticalLinearStepper
        steps={steps}
        stepProps={{}}
        loading={false}
        activeStep={step}
        endButtonText="Save"
        showFinalBlock={false}
        forwardBtnLabel="Next"
        handleGetActiveStep={(step) => setStep(step)}
        handleNext={handleSave}
        additionalInfo={additionalInfo}
        isForwardBtnDisabled={!nextButtonEnabled}
      />
    </div>
  )
}

export default TemplatesFormCreate

import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { noop } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Link, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import InlineNotification from 'src/components/Common/InlineNotification'
import Radio from 'src/components/Common/Radio'
import BlankState from 'src/components/Common/BlankState'
import { useTranslation, Trans } from 'react-i18next'

import { permissionTo, promisifyAction } from '../../utils'
import useCopyLink from '../../hooks/useCopyLink'
import { OrganizationWithAvatar } from '../../stories/OrganizationWithAvatar'
import { getAllConnections } from '../../stores/actionCreators'
import { Dialog, DialogContent } from '../../stories/Dialogs'

interface IProps {
  collaborators: ICollaborator[]
  onSaveAndFetch: (id) => void
  open: boolean
  onClose: () => void
  buttonLoading: boolean
  isBuyer?: boolean
  publicLink: string
}

const POShareModal: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const [selectedConnection, setSelectedConnection] = useState<number | null>(
    null
  )
  const dispatch = useDispatch()
  const copyLink = useCopyLink(props.publicLink)

  const getAllConnectionsAsync = promisifyAction(dispatch, getAllConnections)

  useEffect(() => {
    const fetchConnections = async () => {
      await getAllConnectionsAsync()
    }
    fetchConnections()
  }, [])

  const { connections } = useSelector((state: IGlobalState) => ({
    connections: state.connections.list,
  }))

  const onClose = useCallback(() => {
    setSelectedConnection(null)
    props.onClose()
  }, [props.onClose])

  const selectConnection = useCallback(
    (id: number, block: boolean): void => {
      if (!block) {
        setSelectedConnection(id)
      }
    },
    [selectedConnection]
  )

  const onSave = () => {
    props.onSaveAndFetch(selectedConnection)
  }

  const removeCollaborator = () => {
    props.onSaveAndFetch(null)
  }

  const collaboratorLine = useCallback(
    (party: ICollaborator): React.ReactNode => {
      return (
        <div key={party.id} className="shipment-collaborators-window--line">
          <OrganizationWithAvatar organization={party as any} bold={true} />
          <div className="collaborator-role">{party.role}</div>
          <div className="collaborator-remove">
            {party.manageable && (
              <i className="icon trash" onClick={removeCollaborator} />
            )}
          </div>
        </div>
      )
    },
    [props.collaborators]
  )

  const connectionLine = (connection: IShipmentConnection): React.ReactNode => {
    return (
      <div
        key={connection.organization_id}
        className="shipment-collaborators-window--line"
        onClick={() => {
          selectConnection(connection.organization_id, !connection.manageable)
        }}
      >
        <div className="collaborator-radio">
          <Radio
            checked={selectedConnection === connection.organization_id}
            onChange={noop}
            id={connection.organization_id}
            value={true}
            label={''}
          />
          <Box ml={1} />
          <OrganizationWithAvatar
            organization={connection as any}
            bold={true}
          />
        </div>
        <div className="collaborator-role">{connection.role}</div>
      </div>
    )
  }

  const blankStateInfo: React.ReactNode = (
    <>
      <Trans
        i18nKey="purchase_orders.modals.share.empty_state"
        defaults="<strong>It looks like you have no organizations in your network</strong><black>Please contact <link>customer support</link> to add your first organization</black>"
        components={{
          strong: <div className="paragraph__medium black strong" />,
          black: <div className="paragraph__small black" />,
          link: (
            <Link
              variant="body1"
              component="button"
              onClick={() => {
                window.location.href = 'mailto:CS@shypple.com'
              }}
            />
          ),
        }}
      />
    </>
  )

  const isSellerOrganizationAssigned = !!props.collaborators[1]

  const filteredConnections: IShipmentConnection[] = (connections || []).filter(
    (item) => item.manageable
  )

  return (
    <Dialog
      title={t(
        'purchase_orders.modals.share.title',
        'Share with collaborators'
      )}
      open={props.open}
      onClose={onClose}
      actions={
        <>
          {!!props.publicLink && (
            <Button
              variant="outlined"
              onClick={copyLink}
              startIcon={<LinkRoundedIcon />}
            >
              {t(
                'purchase_orders.modals.share.copy_public_link_button',
                'Copy public link'
              )}
            </Button>
          )}
          <Button variant="outlined" onClick={onClose}>
            {t('purchase_orders.modals.share.cancel_button', 'Cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            onClick={onSave}
            disabled={!selectedConnection}
            loading={props.buttonLoading}
          >
            {t('purchase_orders.modals.share.add_button', 'Add')}
          </LoadingButton>
        </>
      }
    >
      <DialogContent>
        <div className="shipment-collaborators-window--block">
          <div className="shipment-collaborators-window--block-name">
            {t(
              'purchase_orders.modals.share.current_collaborator',
              'Current collaborators'
            )}
          </div>
          <div className="shipment-collaborators-window--lines">
            {(props.collaborators || []).map((party) =>
              collaboratorLine(party)
            )}
          </div>
        </div>
        {permissionTo('shipments.shipment_collaborators.manage') && (
          <div className="shipment-collaborators-window--block">
            <div className="shipment-collaborators-window--block-name">
              {t(
                'purchase_orders.modals.share.invite_collaborator',
                'Invite new collaborators'
              )}
            </div>
            {!!filteredConnections.length ? (
              !isSellerOrganizationAssigned ? (
                <>
                  <div className="shipment-collaborators-window--lines">
                    {(
                      filteredConnections || []
                    ).map((item: IShipmentConnection) => connectionLine(item))}
                  </div>
                  <div className="support-message">
                    <Trans
                      i18nKey="purchase_orders.modals.share.cant_find_org"
                      defaults="Can't find the organization that you are looking for? Please
        contact <0>customer support</0>"
                      components={[
                        <Link
                          component="button"
                          variant="body1"
                          onClick={() => {
                            window.location.href = 'mailto:CS@shypple.com'
                          }}
                        />,
                      ]}
                    />
                  </div>
                </>
              ) : (
                <InlineNotification
                  color="warning"
                  show={true}
                  message={t(
                    'purchase_orders.modals.share.notifications.remove_collaborator',
                    'Please remove before adding a new collaborator.'
                  )}
                  actionName={'Remove'}
                  action={removeCollaborator}
                  showClose={false}
                />
              )
            ) : (
              <BlankState.Info
                type="link"
                theme="shareWindow"
                iconType="container"
                linkBlock={blankStateInfo}
              />
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default POShareModal

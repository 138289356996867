import { FunctionComponent, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { compact, values } from 'lodash'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { Button, Tooltip } from '@mui/material'
import CreateRoundedIcon from '@mui/icons-material/CreateRounded'
import { AvatarGroup } from 'src/stories/AvatarGroup'
import { getPartiesAvatarsArray } from 'src/components/helpers'
import POShareModal from 'src/components/POShareModal'
import ShipmentCollaboratorsPopper from 'src/components/ShipmentCollaboratorsPopper'

import { promisifyAction } from '../../../utils'
import { assignSellerOrganization } from '../../../stores/actionCreators'
import SharePurchaseOrder from './SharePurchaseOrder'

interface IProps {
  isCopyDisabled: boolean
  isSellerPresent: boolean
  editItem: () => void
  deleteItem: () => void
  copyItemPublicLink: () => void
  purchaseOrderId: number
  refreshOrdersData?: () => void
  isBuyer: boolean
  purchaseOrder: IPurchaseOrder
}

const RowDetails: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [openShare, setOpenShare] = useState<boolean>(false)
  const [openShareModal, setOpenShareModal] = useState<boolean>(false)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  const assignSellerOrganizationAsync = promisifyAction(
    dispatch,
    assignSellerOrganization
  )
  const showSuccess = (message: string): void => {
    dispatch(showNotification({ message, severity: 'success' }))
  }
  const showError = (message: string): void => {
    dispatch(showNotification({ message, severity: 'error' }))
  }

  const toggleShareWindow = useCallback(() => {
    if (props.isSellerPresent) {
      setOpenShare(!openShare)
    }
  }, [props.isSellerPresent, openShare])

  const onSaveAndFetch = async (id: number | null) => {
    try {
      if (id) {
        setButtonLoading(true)
      }
      await assignSellerOrganizationAsync(props.purchaseOrder.id, {
        party_id: id,
      })
      if (!id) {
        showSuccess(
          t(
            'purchase_orders.row_details.notifications.removed_seller_org',
            'You have removed seller organization.'
          )
        )
      } else {
        showSuccess(
          t(
            'purchase_orders.row_details.notifications.new_collaborator',
            'You have added one or more new collaborators.'
          )
        )
        setOpenShareModal(false)
      }
      if (props.refreshOrdersData) {
        props.refreshOrdersData()
      }
    } catch (error) {
      const unknownError: any = error
      const errorsArr: string[] = values(unknownError.response.data.errors).map(
        (item) => item || ''
      )
      showError(
        values(unknownError.response.data.errors).length > 1
          ? compact(errorsArr).join(', ')
          : errorsArr[0]
      )
      setButtonLoading(false)
    }
    setButtonLoading(false)
  }

  const poParties = compact([
    props.purchaseOrder.buyer,
    props.purchaseOrder.seller.organization,
  ])

  return (
    <>
      {props.isBuyer && (
        <div className="purchase-order__details-header-actions">
          <div className="shipment__header--share-content">
            <ShipmentCollaboratorsPopper parties={poParties} groupByValue="id">
              <AvatarGroup
                avatars={getPartiesAvatarsArray(poParties, 'id')}
                max={4}
                className="mr-15"
                onClick={setOpenShareModal.bind(null, true)}
              />
            </ShipmentCollaboratorsPopper>
            <Button
              variant="outlined"
              onClick={setOpenShareModal.bind(null, true)}
              startIcon={!props.isBuyer ? <CreateRoundedIcon /> : null}
              children={props.isBuyer ? 'Share' : 'Edit'}
            />
          </div>
          <div className="purchase-order__details-header-action-sep"></div>
          <div className="purchase-order__details-header-action-delete paragraph__medium lightblue">
            <Tooltip
              title={t(
                'purchase_orders.row_details.tooltips.delete_order',
                'Delete order'
              )}
              placement="bottom"
            >
              <i className="icon trash" onClick={props.deleteItem} />
            </Tooltip>
          </div>
          <div
            className={`purchase-order__details-header-action-copy paragraph__medium lightblue ${
              !props.isSellerPresent ? 'disabled' : ''
            }`}
          >
            <Tooltip
              title={t(
                'purchase_orders.row_details.tooltips.share_order',
                'Share order'
              )}
              placement="bottom"
            >
              <i className="icon network" onClick={toggleShareWindow} />
            </Tooltip>
            {openShare && (
              <SharePurchaseOrder
                close={setOpenShare.bind(null, false)}
                copyItemPublicLink={props.copyItemPublicLink}
                purchaseOrderId={props.purchaseOrderId}
                refreshOrdersData={props.refreshOrdersData}
                isCopyDisabled={props.isCopyDisabled}
              />
            )}
            {openShare && <div className="selected-share-po" />}
          </div>
          <div className="purchase-order__details-header-action-edit paragraph__medium lightblue">
            <Tooltip
              title={t(
                'purchase_orders.row_details.tooltips.edit_order',
                'Edit order'
              )}
              placement="bottom"
            >
              <i className="icon pencil" onClick={props.editItem} />
            </Tooltip>
          </div>
        </div>
      )}
      <POShareModal
        collaborators={poParties}
        open={openShareModal}
        onClose={setOpenShareModal.bind(null, false)}
        onSaveAndFetch={onSaveAndFetch}
        buttonLoading={buttonLoading}
        isBuyer={props.isBuyer}
        publicLink={props.purchaseOrder.public_po_link || ''}
      />
    </>
  )
}

export default RowDetails

import { AnyAction } from 'redux'
import * as actions from '../actions/pickupAndDeliveries'

export const pickupsAndDeliveriesLoading = (): AnyAction => ({
  type: actions.PICK_UPS_AND_DELIVERIES_LOADING,
})

export const pickupsAndDeliveriesGetData = (payload?: any): AnyAction => ({
  type: actions.PICK_UPS_AND_DELIVERIES_GET_DATA,
  payload,
})

export const pickupsAndDeliveriesSetFilterOptions = (
  payload?: any
): AnyAction => ({
  type: actions.GET_PICK_UPS_AND_DELIVERIES_FILTERS_OPTIONS_SUCCESS,
  payload,
})

export const pickupsAndDeliveriesGetDataSuccess = (
  payload: any[]
): AnyAction => ({
  type: actions.PICK_UPS_AND_DELIVERIES_GET_DATA_SUCCESS,
  payload,
})

export const pickupsAndDeliveriesUpdate = (
  id: number,
  payload?: any
): AnyAction => ({
  type: actions.PICK_UPS_AND_DELIVERIES_UPDATE_DATA,
  id,
  payload,
})

export const pickupsAndDeliveriesUpdateSuccess = (
  payload: any[]
): AnyAction => ({
  type: actions.PICK_UPS_AND_DELIVERIES_UPDATE_DATA_SUCCESS,
  payload,
})

export const bulkEditTransporter = (payload?: any): AnyAction => ({
  type: actions.BULK_EDIT_TRANSPORTER,
  payload,
})

export const bulkEditTransporterSuccess = (payload: any[]): AnyAction => ({
  type: actions.BULK_EDIT_TRANSPORTER_SUCCESS,
  payload,
})

export const pickupsAndDeliveriesGetOne = (id: number): AnyAction => ({
  type: actions.PICK_UPS_AND_DELIVERIES_GET_ONE,
  id,
})

export const pickupsAndDeliveriesGetOneSuccess = (payload: any): AnyAction => ({
  type: actions.PICK_UPS_AND_DELIVERIES_GET_ONE_SUCCESS,
  payload,
})

export const getPickupsAndDeliveriesUserColumns = (): AnyAction => ({
  type: actions.GET_PICK_UPS_AND_DELIVERIES_USER_COLUMNS,
})

export const getPickupsAndDeliveriesUserColumnsSuccess = (
  payload: any[]
): AnyAction => ({
  type: actions.GET_PICK_UPS_AND_DELIVERIES_USER_COLUMNS_SUCCESS,
  payload,
})

export const updatePickupsAndDeliveriesShipmentStatus = (
  id: string,
  payload: any
): AnyAction => ({
  type: actions.UPDATE_PICK_UPS_AND_DELIVERIES_SHIPMENT_STATUS,
  id,
  payload,
})

export const updatePickupsAndDeliveriesShipmentStatusSuccess = (
  payload: any
): AnyAction => ({
  type: actions.UPDATE_PICK_UPS_AND_DELIVERIES_SHIPMENT_STATUS_SUCCESS,
  payload,
})

export const updatePickupsAndDeliveriesItem = (
  id: number,
  payload?: any
): AnyAction => ({
  type: actions.PICK_UPS_AND_DELIVERIES_UPDATE_ITEM,
  id,
  payload: { ...payload, inline_edit: true },
})

export const updatePickupsAndDeliveriesItemSuccess = (
  payload: any[]
): AnyAction => ({
  type: actions.PICK_UPS_AND_DELIVERIES_UPDATE_ITEM_SUCCESS,
  payload,
})

export const updatePickupsAndDeliveriesUserColumns = (
  payload: any
): AnyAction => ({
  type: actions.UPDATE_PICK_UPS_AND_DELIVERIES_USER_COLUMNS,
  payload,
})

export const updatePickupsAndDeliveriesUserColumnsSuccess = (
  payload: any[]
): AnyAction => ({
  type: actions.UPDATE_PICK_UPS_AND_DELIVERIES_USER_COLUMNS_SUCCESS,
  payload,
})

export const pickupsAndDeliveriesGetFiltersOptions = (): AnyAction => ({
  type: actions.GET_PICK_UPS_AND_DELIVERIES_FILTERS_OPTIONS,
})

export const updateTaskStatus = (
  shipmentId: number,
  taskId: number,
  payload: any
): AnyAction => ({
  type: actions.UPDATE_TASK_STATUS,
  shipmentId,
  taskId,
  payload,
})

export const pickupsAndDeliveriesUpdateFilters = (payload: any): AnyAction => ({
  type: actions.PICK_UPS_AND_DELIVERIES_UPDATE_FILTERS,
  payload,
})

export const pickupsAndDeliveriesUpdateQuickFilter = (
  payload: any
): AnyAction => ({
  type: actions.PICK_UPS_AND_DELIVERIES_UPDATE_QUICK_FILTER,
  payload,
})

export const pickupsAndDeliveriesSetDrawer = (payload: any): AnyAction => ({
  type: actions.PICK_UPS_AND_DELIVERIES_SET_DRAWER,
  payload,
})

export const pickupsAndDeliveriesSetSelectedId = (
  id: number | null
): AnyAction => ({
  type: actions.PICK_UPS_AND_DELIVERIES_SET_SELECTED,
  id,
})

export const pickupsAndDeliveriesSelectedIds = (
  ids: number[] | null
): AnyAction => ({
  type: actions.PICK_UPS_AND_DELIVERIES_SELECTED_IDS,
  ids,
})

export const sendTransportEmail = (id: number, payload?: any): AnyAction => ({
  type: actions.SEND_TRANSPORT_EMAIL,
  id,
  payload,
})

export const sendTransportEmailSuccess = (payload: any[]): AnyAction => ({
  type: actions.SEND_TRANSPORT_EMAIL_SUCCESS,
  payload,
})

export const toggleTransportWindow = (payload: any): AnyAction => ({
  type: actions.PICK_UPS_AND_DELIVERIES_TOGGLE_TRANSPORT_WINDOW,
  payload,
})

export const toggleEditWindow = (payload: any): AnyAction => ({
  type: actions.PICK_UPS_AND_DELIVERIES_TOGGLE_EDIT_WINDOW,
  payload,
})

export const pickupsAndDeliveriesSetOpenAddressIndex = (
  index: number | null
): AnyAction => ({
  type: actions.PICK_UPS_AND_DELIVERIES_SET_OPEN_ADDRESS_INDEX,
  index,
})

export const createPickupsAndDeliveriesQuickFilter = (payload): AnyAction => ({
  type: actions.ADD_PICK_UPS_AND_QUICK_FILTER,
  payload: {
    ...payload,
    page: 'inland_transports',
  },
})

export const updatePickupsAndDeliveriesQuickFilter = (
  id: number,
  payload?: any
): AnyAction => ({
  type: actions.UPDATE_PICK_UPS_AND_QUICK_FILTER,
  id,
  payload: {
    ...payload,
    page: 'inland_transports',
  },
})

export const createPickupsAndDeliveriesQuickFilterSuccess = (
  payload: any[]
): AnyAction => ({
  type: actions.ADD_PICK_UPS_AND_QUICK_FILTER_SUCCESS,
  payload,
})

export const updatePickupsAndDeliveriesQuickFilterSuccess = (
  payload: any[]
): AnyAction => ({
  type: actions.UPDATE_PICK_UPS_AND_QUICK_FILTER_SUCCESS,
  payload,
})

export const deletePickupsAndDeliveriesQuickFilter = (
  id: string
): AnyAction => ({
  type: actions.DELETE_PICK_UPS_AND_QUICK_FILTER,
  id,
})

export const deletePickupsAndDeliveriesQuickFilterSuccess = (
  payload: any
): AnyAction => ({
  type: actions.DELETE_PICK_UPS_AND_QUICK_FILTER_SUCCESS,
  payload,
})

export const updatePickupsAndDeliveriesQuickFilters = (
  payload: any
): AnyAction => ({
  type: actions.UPDATE_PICK_UPS_AND_QUICK_FILTERS,
  payload,
})

export const updatePickupsAndDeliveriesQuickFiltersSuccess = (
  payload: any[]
): AnyAction => ({
  type: actions.UPDATE_PICK_UPS_AND_QUICK_FILTERS_SUCCESS,
  payload,
})

import { FormStateProps, VisibilityFormErrorProps } from './Form.props'

export const initialState: FormStateProps = {
  container_number: '',
  booking_number: '',
  bl_number: '',
  shared_reference: '',
  carrier: null,
  customer: null,
}

export const initialFormErrors: VisibilityFormErrorProps = {}

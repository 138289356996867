import { useState } from 'react'
import Stack from '@mui/material/Stack'
import TopNavigationBrand from './components/TopNavigationBrand'
import TopNavigationDrawer from './components/TopNavigationDrawer'
import TopNavigationDrawerToggler from './components/TopNavigationDrawer/TopNavigationDrawerToggler'
import TopNavigationUserMenu from './components/TopNavigationUserMenu'
import TopNavigationCreateDropdown from './components/TopNavigationCreateDropdown'
import LanguageSwitch from './LanguageSwitch'

const TopNavigationMobile = ({
  isUser,
  email,
  avatar,
  fullName,
  navItems,
  visibilityTrial,
}) => {
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        flexGrow={1}
        data-testid="top-navigation-mobile"
      >
        {isUser && (
          <TopNavigationDrawerToggler
            isOpen={mobileOpen}
            onClick={handleDrawerToggle}
          />
        )}
        <TopNavigationBrand />
        {isUser && (
          <TopNavigationCreateDropdown
            visibilityTrial={visibilityTrial}
            isMobile={true}
          />
        )}
      </Stack>
      <LanguageSwitch />
      {isUser && (
        <>
          <TopNavigationUserMenu
            name={fullName}
            email={email}
            avatar={avatar}
          />
          <TopNavigationDrawer
            open={mobileOpen}
            navItems={navItems}
            onClick={handleDrawerToggle}
          />
        </>
      )}
    </>
  )
}

export default TopNavigationMobile

import { useCallback } from 'react'
import { difference } from 'lodash'
import { useSelector } from 'react-redux'
import { permissionTo } from 'src/utils'
import { Avatar } from 'src/stories/Avatar'
import { Link } from '@mui/material'
import { useTranslation } from 'react-i18next'

const AddCostsRow = (props: {
  onClick: (buyer: IShipmentParty, items: ITransactionItem[]) => void
}) => {
  const { t } = useTranslation()
  const { transactionItems, currentUser, organizations } = useSelector(
    (state: IGlobalState) => ({
      transactionItems: state.shipmentPrice.transactionItems,
      organizations: state.shipmentOverview.collaborators,
      currentUser: state.user,
    })
  )

  const buyersIds = [
    ...new Set(
      transactionItems.map((transaction) => transaction.buyer_organization.id)
    ),
  ]

  const buyersToBeAdded = [
    ...new Set(
      difference(
        organizations
          .filter(
            (collaborator) =>
              collaborator.organization_id !== currentUser.organizationId
          )
          .map((party) => party.organization_id),
        buyersIds
      )
    ),
  ]

  const onClick = useCallback((buyer: IShipmentParty) => {
    props.onClick(buyer, [])
  }, [])

  if (!permissionTo('shipments.costs.manage')) return null

  if (buyersToBeAdded.length === 0) return null

  return (
    <>
      {buyersToBeAdded.map((buyerId) => {
        const buyer = organizations.find(
          (party) => party.organization_id === buyerId
        )
        if (!buyer) return null
        return (
          <div
            className="content-dropdown--block task-overview-block"
            key={buyerId}
            data-testid="add-cost"
          >
            <div
              className="content-dropdown--header"
              onClick={onClick.bind(null, buyer)}
            >
              <div className="costs__row-header" data-testid="costs-row-header">
                <div className="costs__row-header--organizations">
                  <div className="seller">
                    <Avatar
                      variant="circular"
                      alt={currentUser.organizationName}
                      src={currentUser.organizationLogo || 'no-logo'}
                      className={currentUser.organizationRole as any}
                    />

                    <div className="agent-name">
                      {currentUser.organizationName}
                    </div>
                  </div>
                  {buyer && (
                    <div className="buyer">
                      <Avatar
                        variant="circular"
                        alt={buyer.name}
                        src={buyer.logo || 'no-logo'}
                        className={buyer.organization_role_code as any}
                      />
                      <div className="agent-name">{buyer.name}</div>
                    </div>
                  )}
                </div>
                <div
                  className="costs__row-header--total"
                  data-testid="add-costs-btn"
                >
                  <Link
                    variant="body1"
                    component="button"
                    onClick={onClick.bind(null, buyer)}
                  >
                    {t('common.buttons.add_costs', 'Add costs')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default AddCostsRow

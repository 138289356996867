// @ts-nocheck
// FIXME

import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import * as actions from '../actions/reports'

declare global {
  interface IReportsShipmentsCount {
    reports: any
  }
  interface IReportsShipmentsCountState {
    reports: {
      dataset: any
      filtrationData: {
        pols: Array<Array<string | number>>
        pods: Array<Array<string | number>>
        carriers: Array<Array<string | number>>
        consignees: Array<Array<string | number>>
        shippers: Array<Array<string | number>>
        dates: string[]
        types: string[]
      }
    }
  }
}

export const initialReportsShipmentsCountState = {}

const receiveReportShipmentsCountData: Reducer<
  IReportsShipmentsCountState,
  AnyAction
> = (state, action) => ({
  ...state,
  dataset: action.payload.data.dataset,
  filtrationData: action.payload.data.filtration_data,
})

export default createReducer(initialReportsShipmentsCountState, {
  [actions.REPORTS_GET_COUNTS_OF_SHIPMENTS_SUCCESS]: receiveReportShipmentsCountData,
})

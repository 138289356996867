import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import { QUOTES_GET_DATA_SUCCESS } from '../actions/quotes'

declare global {
  interface IQuote {
    id: number
    title: string
    estimated_departure: string
    estimated_arrival: string
    status: string
    type: string
    token: string
    costs_total_usd: number
    costs_total_eur: number
    carrier_name: string
    quote_expiration_at: string | null
    carrier_logo?: string | null
    reference_number?: string | null
    valid_information: boolean
    flagged: boolean
    shipment_type: string
    total_quantity: number
    pickup_requested: boolean
    delivery_requested: boolean
    loading_port: string
    loading_port_code: string
    discharge_port: string
    discharge_port_code: string
    progress_percent: number
    created_at: string
    container_type: string
    humanized_status: string
    shipment_quotations: number
  }

  interface IQuotesState {
    quotes: IQuote[]
    proposedQuotes: IQuote[]
  }
}

export const initialQuotesState = {
  quotes: [],
  proposedQuotes: [],
}

const receiveList: Reducer<IQuotesState, AnyAction> = (state, action) => ({
  ...state,
  quotes: action.payload.quotes,
  proposedQuotes: action.payload.proposed_quotes,
})

export default createReducer(initialQuotesState, {
  [QUOTES_GET_DATA_SUCCESS]: receiveList,
})

import { FunctionComponent, useState, useEffect } from 'react'
import { toNumber } from 'lodash'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import getClassName from '../utils/styling/index'
import './quantityCounter.scss'

export interface IQuantityCounterProps {
  totalError: string
  value: number
  onChange: (value: number, name: string) => void
  min: string
  name: string
}

const QuantityCounter: FunctionComponent<IQuantityCounterProps> = (props) => {
  const [totalError, setTotalError] = useState<string>('')

  useEffect(() => {
    setTotalError(props.totalError)
  }, [props.totalError])

  const increment = (event: React.MouseEvent<HTMLButtonElement>): void => {
    props.onChange(toNumber(props.value) + 1, props.name)
  }

  const decrement = (event: React.MouseEvent<HTMLButtonElement>): void => {
    props.onChange(toNumber(props.value) - 1, props.name)
  }

  const onChange = (event: any): void => {
    const { value } = event.target
    props.onChange(value > 0 ? value : 0, props.name)
  }

  return (
    <div className="quantity-counter">
      <button
        className="quantity-counter--button quantity-counter--button-desc"
        name={props.name}
        onClick={decrement}
        disabled={props.min === '1' ? props.value < 2 : props.value < 1}
        data-testid={`decrement-${props.name}`}
      >
        <RemoveIcon />
      </button>
      <input
        className={getClassName('quantity-counter--input', {
          'error-field': totalError,
          'has-value': !!props.value,
        })}
        type="number"
        name={props.name}
        value={props.value}
        id={props.name}
        onChange={onChange}
        min={props.min}
        pattern="^[0-9]"
        data-testid={`container-select-${props.name}`}
      />
      <button
        className="quantity-counter--button quantity-counter--button-asc"
        name={props.name}
        onClick={increment}
        data-testid={`increment-${props.name}`}
      >
        <AddIcon />
      </button>
      <div
        className={getClassName('error-holder', {
          hidden: !totalError || totalError === 'red',
        })}
      >
        {totalError}
      </div>
    </div>
  )
}

export default QuantityCounter

import { AnyAction } from 'redux'
import * as actions from '../actions/hsCodes'

export const searchHsCodes = (payload: any): AnyAction => ({
  type: actions.SEARCH_HS_CODES,
  payload,
})

export const searchHsCodesSuccess = (payload: any): AnyAction => ({
  type: actions.SEARCH_HS_CODES_SUCCESS,
  payload,
})

export const addHsCode = (containerId: number, payload: any): AnyAction => ({
  type: actions.ADD_HS_CODE,
  containerId,
  payload,
})

export const addHsCodeSuccess = (payload: any): AnyAction => ({
  type: actions.ADD_HS_CODE_SUCCESS,
  payload,
})

export const editHsCode = (
  containerId: number,
  hsCodeId: number,
  payload: any
): AnyAction => ({
  type: actions.EDIT_HS_CODE,
  containerId,
  hsCodeId,
  payload,
})

export const editHsCodeSuccess = (payload: any): AnyAction => ({
  type: actions.EDIT_HS_CODE_SUCCESS,
  payload,
})

export const deleteHsCode = (
  containerId: number,
  hsCodeId: number
): AnyAction => ({
  type: actions.DELETE_HS_CODE,
  containerId,
  hsCodeId,
})

export const deleteHsCodeSuccess = (payload: any): AnyAction => ({
  type: actions.DELETE_HS_CODE_SUCCESS,
  payload,
})

export const hsCodesGetData = (payload: string): AnyAction => ({
  type: actions.HS_CODES_GET_DATA,
  payload,
})

export const hsCodesGetDataSuccess = (payload: any): AnyAction => ({
  type: actions.HS_CODES_GET_DATA_SUCCESS,
  payload,
})

export const organizationHsCodesGet = (payload: any): AnyAction => ({
  type: actions.ORGANIZATION_HS_CODES_GET,
  payload,
})

export const organizationHsCodesGetSuccess = (payload: any): AnyAction => ({
  type: actions.ORGANIZATION_HS_CODES_GET_SUCCESS,
  payload,
})
export const toggleHsModalOpen = (payload: any): AnyAction => ({
  type: actions.TOGGLE_HS_MODAL_OPEN,
  payload,
})

export const relevantHsCodesGetData = (
  containerId: number,
  payload: any
): AnyAction => ({
  type: actions.RELEVANT_HS_CODES_GET_DATA,
  containerId,
  payload,
})

export const relevantHsCodesGetDataSuccess = (payload: any): AnyAction => ({
  type: actions.RELEVANT_HS_CODES_GET_DATA_SUCCESS,
  payload,
})

export const containerHsCodesSubmitData = (
  containerId: number,
  payload: any
): AnyAction => ({
  type: actions.SUBMIT_CONTAINER_HS_CODES_DATA,
  containerId,
  payload,
})

export const containerhsCodesSubmitDataSuccess = (payload: any): AnyAction => ({
  type: actions.SUBMIT_CONTAINER_HS_CODES_DATA_SUCCESS,
  payload,
})

export const clearHsCodesData = (): AnyAction => ({
  type: actions.CLEAR_HS_CODES_DATA,
})

export const cacheHsCode = (payload: IHsCode): AnyAction => ({
  type: actions.CACHE_HS_CODE,
  payload,
})

import { MenuItemProps } from '../Menu.props'
import { MenuItemText } from './MenuItemText'
import { StyledMenuItem } from './MenuItem.utils'

export const MenuItem: React.FC<MenuItemProps> = (props) => {
  const { text, ...otherProps } = props
  return (
    <StyledMenuItem {...otherProps}>
      <MenuItemText text={text} />
    </StyledMenuItem>
  )
}

import { FunctionComponent, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Box, FormControlLabel, Button } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { toNumber } from 'lodash'
import {
  updatePickupsAndDeliveriesUserColumns,
  getPickupsAndDeliveriesUserColumns,
} from 'src/stores/actionCreators'
import { showNotification } from 'src/stores/actionCreators/notifications'

import { useTranslation } from 'react-i18next'
import { permissionTo } from '../../../utils'
import { adminColumnNames } from '../constants'

const ADMIN_OR_FULL_MANAGE_COLUMNS = ['Transporter']

interface IProps {
  onDrawerClose: () => void
}

const CustomiseColumns: FunctionComponent<IProps> = ({ onDrawerClose }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(getPickupsAndDeliveriesUserColumns())
    }
  }, [])

  const { tableConfigurations } = useSelector((state: IGlobalState) => ({
    tableConfigurations: state.pickUpsAndDeliveries.tableConfigurations,
  }))

  const [tableColumns, setTableColumns] = useState<ITableConfiguration[]>(
    tableConfigurations
  )
  const { t } = useTranslation()
  const onChangeConfiguration = (
    event: React.ChangeEvent,
    value: boolean
  ): void => {
    const updatedConfigurations = tableConfigurations.map((configuration) => {
      if (configuration.id === toNumber(event.target.id)) {
        configuration.enabled = value
      }
      return configuration
    })
    setTableColumns(updatedConfigurations)
  }

  const onSaveConfiguration = (): void => {
    dispatch(
      updatePickupsAndDeliveriesUserColumns({
        user_pickup_delivery_columns: tableColumns,
      })
    )
    onDrawerClose()
    dispatch(
      showNotification({
        message: 'Table configuration updated.',
        severity: 'success',
      })
    )
  }

  return (
    <>
      <Box sx={{ mt: 1.5, mb: 1.5, width: 275 }}>
        <Typography
          variant="h5"
          children={t('transports.customize_table.columns', 'Columns')}
        />
        <Typography
          variant="body1"
          children={t(
            'transports.customize_table.description',
            'Please select the column that you want to see in the table'
          )}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {tableColumns.map((configuration: ITableConfiguration) => {
          if (
            adminColumnNames.includes(configuration.name) &&
            !permissionTo('pickups_deliveries.shypple_admin')
          ) {
            return null
          }
          if (
            ADMIN_OR_FULL_MANAGE_COLUMNS.includes(configuration.name) &&
            !permissionTo([
              'pickups_deliveries.shypple_admin',
              'pickups_deliveries.full_manage',
            ])
          ) {
            return null
          }
          return (
            <FormControlLabel
              key={configuration.id}
              control={
                <Checkbox
                  id={configuration.id.toString()}
                  onChange={onChangeConfiguration}
                  checked={configuration.enabled}
                />
              }
              label={configuration.name}
              data-testid={configuration.name}
            />
          )
        })}
      </Box>
      <Box
        sx={{
          mt: 1.5,
          mb: 1.5,
          textAlign: 'left',
          position: 'sticky',
          bottom: 0,
          background: 'white',
          width: '100%',
          pt: 1.5,
          pb: 1.5,
        }}
      >
        <Button
          sx={{ mr: 1 }}
          size="large"
          variant="text"
          onClick={onDrawerClose}
          data-testid="transport-page-cancel-customize-button"
          children={t('common.cancel', 'Cancel')}
        />

        <Button
          size="large"
          variant="contained"
          onClick={onSaveConfiguration}
          data-testid="transport-page-save-customize-button"
          children={t('common.save', 'Save')}
        />
      </Box>
    </>
  )
}

export default CustomiseColumns

import { styled } from '@mui/system'

interface SizedBoxProps {
  columnSize?: number
}

const SizedBox = styled('div', {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => prop !== 'columnSize' && prop !== 'sx',
  name: 'SizedBox',
  slot: 'Root',
})<SizedBoxProps>(() => ({}))

export default SizedBox

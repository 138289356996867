import Avatar from '@mui/material/Avatar'
import { ModalityEnum, AIRLINE_LOGOS_URL } from 'src/config/constants'
import seaIcon from 'src/stories/assets/unknown-avatar-sea.png'
import { CarrierAvatarAvatarProps } from './CarrierAvatarAvatar.props'

export const CarrierAvatar: React.FC<CarrierAvatarAvatarProps> = ({
  name,
  size = 75,
  scac,
  modality,
  carrier,
}) => {
  const padding = 0
  const borderWidth = 1

  const modalityIcons = {
    [ModalityEnum.Air]: `${AIRLINE_LOGOS_URL}/${scac}.png`,
    [ModalityEnum.Sea]: carrier?.logo_url || seaIcon,
  }

  return (
    <Avatar
      alt={name}
      variant="circular"
      src={modalityIcons[modality]}
      sx={{
        width: size,
        height: size,
        borderStyle: 'solid',
        padding: `${padding}px`,
        borderColor: 'grey.200',
        borderWidth: `${borderWidth}px`,
      }}
    />
  )
}

import { FunctionComponent, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { toString, omit } from 'lodash'
import { Box, IconButton, Grid } from '@mui/material'
import { Delete, Refresh } from '@mui/icons-material'
import { lclFormGetState } from '../../../stores/actionCreators'
import { useStyle } from './GoodsLoadTypeLCL.styles'
import { GoodsLoadTypeLCLProps, ICargoItem } from './GoodsLoadTypeLCL.props'
import { schemaValidation } from './GoodsLoadTypeLCL.utils'
import { QuantityInput } from './components/QuantityInput'
import { PackageTypeSelect } from './components/PackageTypeSelect'
import { DimensionInput } from './components/DimensionInput'
import { VolumeInput } from './components/VolumeInput'
import { TotalWeightInput } from './components/TotalWeightInput'
import { DescriptionInput } from './components/DescriptionInput'
import { StackableSelect } from './components/StackableSelect'
import { FormHeaders } from './components/FormHeaders'
import {
  getDimensionValue,
  selectedType,
  countVolume,
} from './components/helpers'
import { initialCargo } from './constants'

export const GoodsLoadTypeLCL: FunctionComponent<GoodsLoadTypeLCLProps> = (
  props
) => {
  const dispatch = useDispatch()
  const classes = useStyle({})
  const { control, formState, resetField, handleSubmit, setValue } = useForm({
    resolver: joiResolver(schemaValidation),
    mode: 'onChange',
  })

  useEffect(() => {
    dispatch(lclFormGetState(formState?.isValid))
  }, [formState?.isValid])

  const isVolume = (cargo: ICargoItem) => {
    const isDimensions = cargo.height && cargo.length && cargo.width
    const isVolume = cargo.total_volume_cbm > 0
    if (isDimensions && isVolume) return false
    if (!isDimensions && isVolume) return true

    return false
  }

  const isDimensionsError = (cargo: ICargoItem) => {
    return cargo.length > 1000 || cargo.width > 255 || cargo.width > 1000
  }

  const handleChange = (
    id: string,
    name: string,
    value: string | number | boolean,
    index: number = 0
  ) => {
    props.onChange({ id, name, value })

    if (name === 'package_type_id') {
      const selectedPackageType = selectedType(value, props.packageTypes)
      const length = getDimensionValue(selectedPackageType, 'default_length_mm')
      const width = getDimensionValue(selectedPackageType, 'default_width_mm')
      const height = getDimensionValue(selectedPackageType, 'default_height_mm')
      const volume = countVolume(
        width,
        height,
        length,
        props.cargos[index].quantity
      )

      setValue(`items.${index}.length`, length)
      setValue(`items.${index}.width`, width)
      setValue(`items.${index}.height`, height)
      setValue(`items.${index}.total_volume_cbm`, volume)
    }
  }

  const handleTriggerReset = async () => {
    props.onClear?.()
    resetField(`items.0`, { defaultValue: omit(initialCargo(), 'id') })
  }

  const submitForm = () => {
    props.onSubmit?.()
  }

  return (
    <Grid>
      <FormHeaders required={props.isShowAterisk} />
      <form
        ref={props.formRef}
        onSubmit={handleSubmit?.(submitForm)}
        id="hook-form"
        data-testid="lcl-form"
      >
        {props.cargos.map((cargo: ICargoItem, index: number) => {
          return (
            <Grid container key={cargo.id}>
              <QuantityInput
                cargo={cargo}
                control={control}
                defaultValue={cargo.quantity}
                name={`items.${index}.quantity`}
                onChange={handleChange}
                isClearError={props.isClearErrors}
              />
              <PackageTypeSelect
                index={index}
                cargo={cargo}
                control={control}
                defaultValue={cargo.package_type_id}
                name={`items.${index}.package_type_id`}
                onChange={handleChange}
              />
              <Grid item xs={2} pr={1.5} pl={1.5}>
                <Box
                  className={`${classes.dimensions} ${
                    isVolume(cargo) ? classes.disabled : ''
                  } ${isDimensionsError(cargo) ? classes.error : ''}`}
                >
                  <DimensionInput
                    cargo={cargo}
                    control={control}
                    defaultValue={cargo.length}
                    name={`items.${index}.length`}
                    nameChange="length"
                    onChange={handleChange}
                  />
                  <DimensionInput
                    cargo={cargo}
                    control={control}
                    defaultValue={cargo.width}
                    name={`items.${index}.width`}
                    nameChange="width"
                    onChange={handleChange}
                  />
                  <DimensionInput
                    cargo={cargo}
                    control={control}
                    defaultValue={cargo.height}
                    name={`items.${index}.height`}
                    nameChange="height"
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
              <VolumeInput
                index={index}
                cargo={cargo}
                control={control}
                defaultValue={cargo.total_volume_cbm}
                name={`items.${index}.total_volume_cbm`}
                modality={props.modality}
                onChange={handleChange}
                isClearError={props.isClearErrors}
              />
              <TotalWeightInput
                cargo={cargo}
                control={control}
                defaultValue={cargo.weight}
                name={`items.${index}.weight`}
                onChange={handleChange}
                isClearError={props.isClearErrors}
              />
              <DescriptionInput
                cargo={cargo}
                control={control}
                defaultValue={toString(cargo.description)}
                name={`items.${index}.description`}
                onChange={handleChange}
                isClearError={props.isClearErrors}
              />
              <StackableSelect
                cargo={cargo}
                control={control}
                defaultValue={cargo.stackable}
                name={`items.${index}.stackable`}
                onChange={handleChange}
              />
              <Grid>
                {props.isShowRefresh && index === 0 ? (
                  <IconButton onClick={handleTriggerReset} size="large">
                    <Refresh color="primary" />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => props.onRemove?.(cargo.id)}
                    size="large"
                  >
                    <Delete color="primary" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          )
        })}
      </form>
    </Grid>
  )
}

import isNull from 'lodash/isNull'
import isArray from 'lodash/isArray'
import camelCase from 'lodash/camelCase'
import i18next from 'i18next'
import {
  deserialize,
  DocumentObject,
  ExistingResourceObject,
} from 'jsonapi-fractal'
import { getFormattedPrice } from 'src/utils/helpers/currency'

import {
  IDemurrageAndDetentionRow,
  DemurrageAndDetentionPrice,
  DemurrageAndDetentionRange,
  IDemurrageAndDetentionCarrier,
  DemurrageAndDetentionContainer,
  IDemurrageAndDetentionPort,
} from 'src/@types/endpoints/demurrageAndDetention'

const getPriceRangeNumberValue = (value: string | null) => {
  if (!!value) {
    return parseInt(value)
  }
  return ''
}

const getPriceRange = (range: DemurrageAndDetentionRange) => {
  const { lower, upper } = range
  const lowerRange = getPriceRangeNumberValue(lower)
  const upperRange = getPriceRangeNumberValue(upper)
  const lowerRangeText = upper
    ? `${lowerRange} - `
    : i18next.t('demurrage_and_detention.table.lower_range_and_more', {
        defaultValue: '{{lowerRange}} or more',
        lowerRange,
      })
  return `${lowerRangeText}${upperRange}`
}

export const getPriceValue = (
  price: DemurrageAndDetentionPrice,
  transformFreePrice: boolean = true
) => {
  const demurrageAndDetentionPriceNoteMap = {
    on_request: i18next.t(
      'demurrage_and_detention.table.price.on_request',
      'On request'
    ),
    free: i18next.t('demurrage_and_detention.table.price.free', 'Free'),
  }
  const { value, currency, note } = price
  if (!isNull(note)) {
    return demurrageAndDetentionPriceNoteMap?.[note] ?? ''
  }
  const numberValue = Number(value)
  if (numberValue === 0 && transformFreePrice) {
    return i18next.t('demurrage_and_detention.table.price.free', 'Free')
  }
  return getFormattedPrice(numberValue, currency)
}

const getPortValueText = (port: IDemurrageAndDetentionPort) => {
  return `${port.name} (${port.code})`
}

const getPortPairValue = (
  loadingPort: IDemurrageAndDetentionPort | undefined,
  dischargePort: IDemurrageAndDetentionPort | undefined
) => {
  if (!loadingPort || !dischargePort) {
    return ''
  }
  return `${getPortValueText(loadingPort)} - ${getPortValueText(dischargePort)}`
}

const getIncludedItems = (
  included: ExistingResourceObject[],
  includedType: string
) => {
  return included
    .filter(
      (includedItem: ExistingResourceObject) =>
        includedItem.type === includedType
    )
    .map((item) => ({
      id: item.id,
      ...item.attributes,
    }))
}

const getTableColumns = (containers: DemurrageAndDetentionContainer[]) => {
  return [
    {
      value: 'range',
      label: i18next.t('demurrage_and_detention.table.days', 'Days'),
    },
    ...containers.map((container) => ({
      value: camelCase(container.code),
      label: container.name,
    })),
  ]
}

export const getDemurrageAndDetentionTransformedData = (
  payload: DocumentObject
) => {
  const { included = [] } = payload
  const transformedPayload = deserialize(payload)
  const carriers = getIncludedItems(
    included,
    'carrier'
  ) as IDemurrageAndDetentionCarrier[]
  const containers = getIncludedItems(included, 'container_type')
  const columns = getTableColumns(
    containers as DemurrageAndDetentionContainer[]
  )
  const items: IDemurrageAndDetentionRow[] = isArray(transformedPayload)
    ? transformedPayload.map((item) => {
        const range = getPriceRange(item.range)
        const priceValue = getPriceValue(item.price)
        const portPair = getPortPairValue(
          item?.loading_port,
          item?.discharge_port
        )
        return {
          range,
          portPair,
          priceValue,
          carrier: item?.carrier?.code || 'default',
          containerCode: item?.container_type?.code || '',
          serviceItemCode: item?.service_item?.code || '',
          serviceItemName: item?.service_item?.name || '',
          [camelCase(item.container_type.code)]: priceValue,
          rangeCalculationMethod: item?.range?.calculation_method || '',
        }
      })
    : []
  return {
    items,
    columns,
    carriers,
  }
}

import { useEffect, useRef, useState, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import Modal from 'src/components/Common/Modal'
import { Box, Typography, Button, Link } from '@mui/material'
import { makeStyles, withStyles } from '@mui/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import Delete from '@mui/icons-material/Delete'
import { getServiceItems } from 'src/stores/actionCreators/serviceItems'
import { useTranslation } from 'react-i18next'
import { Avatar } from 'src/stories/Avatar'
import {
  modifyShipmentData,
  updateShipmentData,
} from 'src/stores/actionCreators/shipments'
import { LoadingButton } from '@mui/lab'
import { permissionTo, promisifyAction } from 'src/utils'
import { shipmentOverviewGetData } from 'src/stores/actionCreators'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { getComponent } from './ShipmentCostManagementComponents/utils'
import { IBuyer } from '.'

type Transaction = ITransactionItem & {
  _destroy: boolean
  _new: boolean
}

type Config = Record<
  string,
  {
    name: string
    data: Record<string, unknown>
    value?: string
    component?: string
    className?: string
  }
>

interface TableComponentProps {
  rows: Transaction[]
  setRows: React.Dispatch<React.SetStateAction<Transaction[]>>
  isDataValid: boolean
}

interface TableRowComponentProps {
  row: Transaction
  setRows: React.Dispatch<React.SetStateAction<Transaction[]>>
  config: Config
  isValid: boolean
}

const PROPOSED_QUOTE_STATUS_ID = 18

const emptyRow = {
  service_item: undefined,
  description: '',
  quantity: '',
  unit_price: '',
  currency: 'USD',
}

interface Props {
  onClose: () => void
  onSave: () => Promise<any>
  items: ITransactionItem[]
  buyer: IBuyer | null
}

const DialogComponent = memo((props: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [rows, setRows] = useState<Transaction[]>(
    props.items.length === 0
      ? ([{ ...emptyRow, id: Date.now(), _new: true }] as any)
      : (props.items as Transaction[])
  )
  const [isSaving, setIsSaving] = useState(false)
  const modifyShipmentDataAsync = promisifyAction(dispatch, modifyShipmentData)
  const updateShipment = promisifyAction(dispatch, updateShipmentData)
  const getShipmentOverview = promisifyAction(dispatch, shipmentOverviewGetData)

  const { shipmentOverview } = useSelector((state: IGlobalState) => ({
    shipmentOverview: state.shipmentOverview,
  }))

  const notEmptyOrFilledRows = rows.filter(
    (row) =>
      new Set([!!row.service_item, row.quantity !== '', row.unit_price !== ''])
        .size > 1
  )

  const isValid = notEmptyOrFilledRows.length === 0

  const updateTransactionItems = async () => {
    const rowsWithData = rows.filter(
      (row) =>
        !(!row.service_item && row.quantity === '' && row.unit_price === '')
    )

    await modifyShipmentDataAsync(shipmentOverview.id, {
      transaction_items_attributes: rowsWithData.map((row) => ({
        ...(row._new ? {} : { id: row.id }),
        ...(row._destroy ? { _destroy: row._destroy } : {}),
        buyer_organization_id: props.buyer?.id,
        service_item_id: row.service_item?.id,
        description: row.description,
        quantity: row.quantity,
        unit_price: row.unit_price,
        currency: row.currency,
      })),
    })
    await props.onSave()
    dispatch(
      showNotification({
        message: t(
          'shipment_costs.edit_costs.notifications.costs_saved',
          'Costs saved'
        ),
        severity: 'success',
      })
    )
  }

  const onSave = async () => {
    try {
      setIsSaving(true)
      await updateTransactionItems()
      props.onClose()
    } finally {
      setIsSaving(false)
    }
  }

  const onSaveAndProposeQuote = async () => {
    try {
      setIsSaving(true)
      await updateTransactionItems()
      await updateShipment(shipmentOverview.id.toString(), {
        status_id: PROPOSED_QUOTE_STATUS_ID,
      })
      dispatch(
        showNotification({
          message: 'Shipment status updated',
          severity: 'success',
        })
      )
      await getShipmentOverview(shipmentOverview.id.toString())
      props.onClose()
    } finally {
      setIsSaving(false)
    }
  }

  const canChangeShipmentStatus =
    shipmentOverview.status === 'QUO' && permissionTo('shipments.status.manage')

  return (
    <Modal.Window open onClose={props.onClose}>
      <Modal.Title
        children={t('shipment_costs.edit_costs.title', 'Edit costs')}
        onClose={props.onClose}
      />
      <Modal.Content>
        <div data-testid="edit-cost-modal" className="edit-cost-modal">
          {props.buyer && (
            <div className={classes.dialogContentBuyerBlock}>
              <div className={classes.dialogContentBuyerBlockTitle}>
                <Typography className="medium normal">
                  {t('shipment_costs.offered_to', 'Offered to')}
                </Typography>
              </div>
              <div
                className={classes.dialogContentBuyerBlockAvatar}
                key={props.buyer.id}
              >
                <div className={classes.dialogContentBuyerBlockAvatarWrapper}>
                  <Avatar
                    variant="circular"
                    alt={props.buyer.name}
                    src={props.buyer.logo || 'no-logo'}
                    className={props.buyer.role_code as any}
                  />
                </div>
              </div>
            </div>
          )}
          <TableComponent rows={rows} setRows={setRows} isDataValid={isValid} />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Box className={classes.dialogActions}>
          <Box>
            {canChangeShipmentStatus && props.buyer && (
              <Box>
                <Box mb={2}>
                  <Typography className="medium normal">
                    {t('shipment_costs.edit_costs.propose_quote_to', {
                      defaultValue:
                        'Is the cost overview complete? Propose as a quote to {{name}}',
                      name: props.buyer.name,
                    })}
                  </Typography>
                </Box>
                <Box>
                  <LoadingButton
                    loading={isSaving}
                    onClick={onSaveAndProposeQuote}
                    children={t(
                      'shipment_costs.edit_costs.save_and_propose',
                      'Save and propose quote'
                    )}
                    variant="contained"
                    disabled={!isValid || isSaving}
                  />
                </Box>
              </Box>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            <Button
              variant="text"
              color="primary"
              size="medium"
              onClick={props.onClose}
            >
              {t('common.buttons.cancel', 'Cancel')}
            </Button>
            <Box mr={2} />
            <LoadingButton
              loading={isSaving}
              onClick={onSave}
              children={t('common.buttons.save', 'Save')}
              variant="contained"
              disabled={!isValid || isSaving}
              data-testid="save-cost"
            />
          </Box>
        </Box>
      </Modal.Actions>
    </Modal.Window>
  )
})

const TablePaperComponent = (props) => (
  <Paper {...props} square elevation={0} style={{ maxHeight: '50vh' }} />
)

const TableComponent = memo((props: TableComponentProps) => {
  const { t } = useTranslation()
  const columnsSchema = (
    row: Transaction,
    data: { serviceItems: IServiceItem[] }
  ): Config => ({
    service_item: {
      name: 'Service Item',
      value: 'service_item',
      component: 'Autocomplete',
      data: {
        options: data.serviceItems || [],
        placeholder: t(
          'shipment_costs.edit_costs.fields.service_item.placeholder',
          'Service Item'
        ),
        groupBy: (option: IServiceItem) => option.service_name,
      },
      className: 'autocompleteField',
    },
    description: {
      value: 'description',
      name: 'Description (optional)',
      component: 'MuiInputBase',
      className: 'inputField',
      data: {
        placeholder: t(
          'shipment_costs.edit_costs.fields.description.placeholder',
          'Description (optional)'
        ),
      },
    },
    quantity: {
      value: 'quantity',
      name: 'Quantity',
      component: 'CustomInputBaseNumber',
      className: 'inputField',
      data: {
        placeholder: t(
          'shipment_costs.edit_costs.fields.quantity.placeholder',
          '1,000.00'
        ),
      },
    },
    price: {
      value: 'unit_price',
      name: 'Price',
      component: 'CustomInputBaseNumber',
      className: 'inputField',
      data: {
        placeholder: t(
          'shipment_costs.edit_costs.fields.unit_price.placeholder',
          '1,000.00'
        ),
      },
    },
    currency: {
      value: 'currency',
      name: 'Currency',
      component: 'CustomSelect',
      data: {
        options: [
          { id: 'USD', label: 'USD' },
          { id: 'EUR', label: 'EUR' },
        ],
        placeholder: t(
          'shipment_costs.edit_costs.fields.currency.placeholder',
          'Currency'
        ),
      },
      className: 'currencyField',
    },
    total: {
      name: 'Total',
      value: 'total',
      data: {
        value: isNaN(parseFloat(row.quantity) * parseFloat(row.unit_price))
          ? 0
          : (parseFloat(row.quantity) * parseFloat(row.unit_price)).toFixed(1),
      },
    },
  })

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getServiceItems())
  }, [])

  const { serviceItems } = useSelector((state: IGlobalState) => ({
    serviceItems: state.serviceItems.items,
  }))

  const columnsConfig = columnsSchema({} as Transaction, {
    serviceItems: [],
  })

  const addNewRow = () => {
    props.setRows([
      ...props.rows,
      { ...emptyRow, id: Date.now(), _new: true } as any,
    ])
  }

  return (
    <>
      <TableContainer component={TablePaperComponent}>
        <Table style={{ tableLayout: 'auto' }} stickyHeader>
          <TableHead>
            <TableRowStyled>
              {Object.keys(columnsConfig).map((columnKey) => (
                <TableCellStyled key={columnKey}>
                  {t(
                    `shipment_costs.edit_costs.fields.${columnsConfig[columnKey].value}`,
                    columnsConfig[columnKey].name
                  )}
                </TableCellStyled>
              ))}
              <TableCellStyled />
            </TableRowStyled>
          </TableHead>
          <TableBody>
            {serviceItems.length === 0 && (
              <>
                {Array.from({ length: props.rows.length }).map((_, index) => (
                  <TableRow key={index}>
                    {Object.keys(columnsConfig).map((key) => (
                      <TableCell key={key}>
                        <Box
                          height={32}
                          width="100%"
                          className="text-placeholder light-grey"
                        />
                      </TableCell>
                    ))}
                    <TableCell>
                      <Box
                        height={32}
                        width="100%"
                        className="text-placeholder light-grey"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
            {serviceItems.length > 0 && (
              <>
                {props.rows
                  .filter((row) => !row._destroy)
                  .map((row) => (
                    <TableRowComponent
                      key={row.id}
                      row={row}
                      setRows={props.setRows}
                      isValid={props.isDataValid}
                      config={columnsSchema(row, {
                        serviceItems,
                      })}
                    />
                  ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box ml={2} mt={2}>
        <Link
          variant="body1"
          component="button"
          onClick={addNewRow}
          data-testid="add-item-button"
        >
          <AddRoundedIcon fontSize="small" />
          {t('common.buttons.add_item', 'Add item')}
        </Link>
      </Box>
    </>
  )
})

const TableRowComponent = memo(
  ({ row, setRows, config, isValid }: TableRowComponentProps) => {
    const ref = useRef<HTMLTableRowElement>(null)
    const classes = useStyles()

    useEffect(() => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
    }, [])

    const deleteItem = () => {
      if (row._new) {
        setRows((prevData) => prevData.filter((item) => item.id !== row.id))
      } else {
        setRows((prevData) =>
          prevData.map((item) =>
            item.id === row.id ? { ...item, _destroy: true } : item
          )
        )
      }
    }

    return (
      <TableRowStyled ref={ref}>
        {Object.keys(config).map((columnKey) => {
          const columnConfig = config[columnKey]
          const fieldDataPath = columnConfig.value || columnKey
          const fieldValue = get(row, fieldDataPath, emptyRow[columnKey])
          const field = getComponent(columnConfig.component, {
            'data-testid': fieldDataPath,
            value: fieldValue,
            onChange: (newValue: any) => {
              setRows((prevData) =>
                prevData.map((item) =>
                  item.id === row.id
                    ? { ...item, [fieldDataPath]: newValue }
                    : item
                )
              )
            },
            ...(columnConfig.data || {}),
          })
          return (
            <TableCellStyled key={columnKey}>
              <Box className={classes[columnConfig.className || '']}>
                {field}
              </Box>
            </TableCellStyled>
          )
        })}
        <TableCellStyled>
          <Delete
            color="action"
            data-testid="delete-cost-row"
            className="delete-svgIcon"
            onClick={deleteItem}
          />
        </TableCellStyled>
      </TableRowStyled>
    )
  },
  (prev, next) =>
    JSON.stringify(prev.row) === JSON.stringify(next.row) &&
    prev.isValid === next.isValid
)

const useStyles = makeStyles((theme) => ({
  deleteItemButton: {
    color: theme.palette.grey[500],
    cursor: 'pointer',
  },
  dialogActions: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '100%',
  },
  dialogContentBuyerBlock: {
    display: 'flex',
    padding: `0 ${theme.spacing(4)} ${theme.spacing(2)}`,
    alignItems: 'center',
  },
  dialogContentBuyerBlockTitle: {
    marginRight: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContentBuyerBlockAvatar: {
    display: 'flex',
    alignItems: 'center',
  },
  dialogContentBuyerBlockAvatarWrapper: { marginRight: theme.spacing(1) },
  currencyField: {
    width: 100,
  },
  numberField: {
    maxWidth: 70,
  },
  inputField: {
    minWidth: 80,
  },
  autocompleteField: {
    minWidth: 250,
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
}))

const TableCellStyled = withStyles((theme) => ({
  root: {
    overflow: 'unset',
    width: 'auto',
    verticalAlign: 'middle',
    '&:first-child': {
      paddingLeft: theme.spacing(4),
    },
    '&:last-child': {
      paddingRight: theme.spacing(4),
    },
  },
}))(TableCell)

const TableRowStyled = withStyles({
  root: {
    cursor: 'unset',
  },
})(TableRow)

export default DialogComponent

// @ts-nocheck
// FIXME

import { FunctionComponent, ReactText, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { orderBy, filter, includes, map } from 'lodash'
import { Link, Typography } from '@mui/material'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import Table from 'src/components/Common/Table'
import Select from 'src/components/Common/Select'
import CustomSwitchButton from 'src/components/CustomSwitchButton'

import '../../styles.scss'

interface IProps {
  slaCheckpoint: ISLA
  addNewAddress: () => void
  destroyAddress: (index: number) => void
  onChangeAddressItem: (itemIndex: number, name: string, value: any) => void
}

const PartiesBlock: FunctionComponent<IProps> = (props) => {
  const { addresses } = useSelector((state: IGlobalState) => ({
    addresses: state.addresses.list,
  }))

  const handleChangeAddress = (index: number, data) => {
    props.onChangeAddressItem(index, 'address_id', data.id)
    props.onChangeAddressItem(index, 'address', data)
  }

  const handleChangeEmail = (
    index: number,
    event: React.ChangeEvent<any>
  ): void => {
    props.onChangeAddressItem(index, 'email', event.target.value)
  }

  const filteredSLAAssresses: ISLAAddress[] = useMemo(() => {
    return orderBy(props.slaCheckpoint.sla_checkpoint_addresses)
  }, [props.slaCheckpoint])

  const removeCondition: boolean = useMemo(() => {
    return filter(filteredSLAAssresses, (item) => !item._destroy).length > 1
  }, [props.slaCheckpoint])

  const filteredAssresses: IAddress[] = useMemo(() => {
    const selectedAddresses = filter(
      filteredSLAAssresses,
      (item) => !item._destroy
    )
    const values = selectedAddresses.map((item) =>
      item.address_id || item.address ? item.address.id : 0
    )
    return map(addresses, (item: any) => {
      item.disabled = includes(values, item.id)
      return item
    })
  }, [filteredSLAAssresses])

  const handleFieldChange = (index: number, field, value: boolean) => {
    props.onChangeAddressItem(index, field, value)
  }

  const { t } = useTranslation()

  return (
    <div className="sla-window--parties">
      <Typography variant="body1Strong">
        {t('address_book.connections.tab_header', 'Connections')}
      </Typography>
      <Typography my={0.5}>
        {t(
          'address_book.connections.description',
          'Please add connections to request information. You can enable/disable the requests per connection.'
        )}
      </Typography>
      <div className="sla-window--block-content">
        <Table.StripedTable theme="organizationSettings">
          <Table.StripedTableHeader
            cells={[
              {
                name: t('address_book.connections.name', 'Name'),
                required: true,
                sortable: false,
                sortValue: '',
                customClassName: 'left-aligned x-wide',
              },
              {
                name: t('address_book.add_address.email', 'Email'),
                required: true,
                sortable: false,
                sortValue: '',
                customClassName: 'left-aligned x-wide',
              },
              {
                name: t(
                  'address_book.connections.send_request',
                  'Send request'
                ),
                sortable: false,
                sortValue: '',
                customClassName: 'left-aligned',
              },
              {
                name: '',
                sortable: false,
                sortValue: '',
                customClassName: 'x-short',
              },
            ]}
          />
          <Table.StripedTableBody>
            {props.slaCheckpoint &&
              filteredSLAAssresses.map((item: ISLAAddress, index: number) => {
                if (item._destroy) {
                  return <></>
                } else {
                  return (
                    <Table.StripedTableRow index={index} className="window-row">
                      <Table.StripedTableCell className="left-aligned x-wide paragraph__medium black">
                        <Select.Single
                          placeholder={t(
                            'address_book.add_address.select',
                            'Select'
                          )}
                          options={filteredAssresses || []}
                          value={
                            item.address_id ||
                            (item.address ? item.address.id : 0)
                          }
                          onChange={handleChangeAddress.bind(null, index)}
                          theme="cargo"
                        />
                      </Table.StripedTableCell>
                      <Table.StripedTableCell className="left-aligned x-wide paragraph__medium black">
                        <input
                          className={`purchase-order-number-input`}
                          placeholder={t(
                            'address_book.add_address.email',
                            'Email'
                          )}
                          name="email"
                          value={
                            item.address_attributes &&
                            item.address_attributes.email
                              ? item.address_attributes.email
                              : item.address
                              ? item.address.email
                              : ''
                          }
                          onChange={handleChangeEmail.bind(null, index)}
                        />
                      </Table.StripedTableCell>
                      <Table.StripedTableCell className="left-aligned paragraph__medium black notify-settings-form__switch-field">
                        <CustomSwitchButton
                          field="automated_alert"
                          value={item.automated_alert}
                          fallbackValue={item.automated_alert}
                          onChange={(field: ReactText, value: boolean) =>
                            handleFieldChange(index, field, value)
                          }
                        />
                      </Table.StripedTableCell>
                      <Table.StripedTableCell className="x-short paragraph__medium lightblue">
                        {removeCondition && (
                          <i
                            className={`icon trash`}
                            onClick={props.destroyAddress.bind(null, index)}
                          />
                        )}
                      </Table.StripedTableCell>
                    </Table.StripedTableRow>
                  )
                }
              })}
          </Table.StripedTableBody>
        </Table.StripedTable>
      </div>
      <div className="products-item--add-line">
        <Link variant="body1" component="button" onClick={props.addNewAddress}>
          <AddRoundedIcon />
          {t('address_book.connections.add_new_address', 'Add new address')}
        </Link>
      </div>
    </div>
  )
}

export default PartiesBlock

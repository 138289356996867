import { FunctionComponent } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import { Dialog, DialogContent, DialogContentText } from '../../stories'

import './styles.scss'

type Theme = 'default'

interface IDialogProps {
  theme?: Theme
  title?: string
  message?: string
  isOpen?: boolean
  confirm?: IEventHandler
  reject?: IEventHandler
  onClose?: IEventHandler
  confirmButtonText?: string
  rejectButtonText?: string
  isConfirmOnly?: boolean
}

const ConfirmDialog: FunctionComponent<any> = (props: IDialogProps) => (
  <Dialog
    open={!!props.isOpen}
    keepMounted={true}
    onClose={props.onClose}
    aria-labelledby="alert-dialog-confirm-title"
    aria-describedby="alert-dialog-confirm-description"
    classes={{ paper: 'shypple-confirm-dialog', root: props.theme }}
    title={props.title}
    actions={
      <>
        <Button
          variant="contained"
          onClick={props.confirm}
          data-testid="confirm-dialog-confirm-button"
        >
          {props.confirmButtonText}
        </Button>
        {!props.isConfirmOnly && (
          <Button
            variant="outlined"
            onClick={props.reject}
            data-testid="confirm-dialog-reject-button"
          >
            {props.rejectButtonText}
          </Button>
        )}
      </>
    }
  >
    <DialogContent>
      <DialogContentText
        id="alert-dialog-confirm-description"
        classes={{
          root: 'dialog__message mui-override',
        }}
      >
        {props.message}
      </DialogContentText>
    </DialogContent>
  </Dialog>
)

ConfirmDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  confirmButtonText: PropTypes.string,
  rejectButtonText: PropTypes.string,
  isConfirmOnly: PropTypes.bool,
  isOpen: PropTypes.bool,
  confirm: PropTypes.func,
  reject: PropTypes.func,
  onClose: PropTypes.func,
}

ConfirmDialog.defaultProps = {
  title: '',
  message: 'Are you sure',
  confirmButtonText: 'Yes',
  rejectButtonText: 'No',
  isConfirmOnly: false,
  isOpen: false,
  confirm() {
    return
  },
  reject() {
    return
  },
  onClose() {
    return
  },
}

export default ConfirmDialog

import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const fixedTimelinePhaseCount = 3

const renderGridItem = (name: string, value: string) => (
  <Grid item sx={{ display: 'flex', paddingBottom: 0.5 }} key={name}>
    <Typography
      className="shipment-timeline-tooltip-item-title"
      variant="body1Strong"
      children={name}
    />
    <Typography
      className="shipment-timeline-tooltip-item-description"
      sx={{ paddingLeft: 1 }}
    >
      {value}
    </Typography>
  </Grid>
)

export const TooltipBody = () => {
  const { t } = useTranslation()
  const tooltipItems = [
    {
      name: t('shipment.timeline.tooltip_item.etd', 'ETD: '),
      value: t(
        'shipment.timeline.tooltip_item.etd_description',
        'Estimated Time of Departure'
      ),
    },
    {
      name: t('shipment.timeline.tooltip_item.atd', 'ATD: '),
      value: t(
        'shipment.timeline.tooltip_item.atd_description',
        'Actual Time of Departure'
      ),
    },
    {
      name: t('shipment.timeline.tooltip_item.eta', 'ETA: '),
      value: t(
        'shipment.timeline.tooltip_item.eta_description',
        'Estimated Time of Arrival'
      ),
    },
    {
      name: t('shipment.timeline.tooltip_item.ata', 'ATA: '),
      value: t(
        'shipment.timeline.tooltip_item.ata_description',
        'Actual Time of Arrival'
      ),
    },
  ]
  return (
    <Grid container>
      {tooltipItems.map(({ name, value }) => renderGridItem(name, value))}
    </Grid>
  )
}

export const getTimelineProgressClass = (
  progress: number,
  threshold: number
): string => {
  if (progress > threshold) {
    return 'completed'
  }
  return ''
}

export const getTotalPhaseCount = (legsCount: number) => {
  return fixedTimelinePhaseCount + legsCount - 1
}

export const getPercentagePerPhase = (totalPhaseCount: number) => {
  return Math.floor(100 / totalPhaseCount)
}

export const getTimelineMainPhases = (
  legsCount: number,
  percentagePerPhase: number
) => {
  return {
    start: 0,
    pol: percentagePerPhase,
    pod: percentagePerPhase * (legsCount + 1),
    end: 100,
  }
}

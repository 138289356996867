import Chip from '@mui/material/Chip'
import { capitalize } from 'lodash'
import {
  convertDateToTimeRange,
  convertDateToDateWithoutYear,
} from 'src/utils/helpers'

export const inspectionStatusWidth = 200

export const statusColors = {
  estimated: 'secondary',
  proposed: 'warning',
  requested: 'info',
  misc: 'success',
}

export const statusOptions = [
  { id: 'estimated', label: 'Estimated' },
  { id: 'requested', label: 'Requested' },
  { id: 'proposed', label: 'Proposed' },
  { id: 'confirmed', label: 'Confirmed' },
  { id: 'completed', label: 'Completed' },
]

export const modalityOptions = [
  { name: 'road', label: 'Road' },
  { name: 'rail', label: 'Rail' },
  { name: 'barge', label: 'Barge' },
]

export enum AddressBookTypeOptionsEnum {
  YourAddressBook = 'your_address_book',
  CustomersAddressBook = 'customers_address_book',
}

export const addressBookTypeOptions = [
  {
    id: AddressBookTypeOptionsEnum.YourAddressBook,
    label: 'Your address book',
  },
  {
    id: AddressBookTypeOptionsEnum.CustomersAddressBook,
    label: "Customer's address book",
  },
]

export const shipmentChipColor = {
  delivered: 'success',
  destination: 'primary',
  in_transit: 'secondary',
  origin: 'info',
  quote: 'warning',
  deleted: 'error',
  cancelled: 'error',
}

export const TABS = (t) => [
  {
    label: t('common.service_item.delivery.title', 'Deliveries'),
    value: 'delivery',
  },
  {
    label: t('common.service_item.pickup.title', 'Pick-ups'),
    value: 'pickup',
  },
]

export const sortingOptions = (t) => [
  {
    name: t('common.filter.eta_ascending', 'ETA (ascending)'),
    value: 'eta_asc',
    icon: 'sort-arrow',
  },
  {
    name: t('common.filter.eta_descending', 'ETA (descending)'),
    value: 'eta_desc',
    icon: 'sort-arrow down',
  },
  {
    name: t('common.filter.etd_ascending', 'ETD (ascending)'),
    value: 'etd_asc',
    icon: 'sort-arrow',
  },
  {
    name: t('common.filter.etd_descending', 'ETD (descending)'),
    value: 'etd_desc',
    icon: 'sort-arrow down',
  },
  {
    name: t('common.filter.newest_first', 'Newest first'),
    value: 'newest_first',
    icon: '',
  },
  {
    name: t('common.filter.delivery_ascending', 'Delivery (ascending)'),
    value: 'delivery_asc',
    icon: 'sort-arrow',
  },
  {
    name: t('common.filter.delivery_descending', 'Delivery (descending)'),
    value: 'delivery_desc',
    icon: 'sort-arrow down',
  },
]

export const containerTypes = {
  empty_gate_out: { color: 'default' },
  cargo_picked_up: { color: 'default' },
  vgm_submitted: { color: 'default' },
  full_gate_in: { color: 'default' },
  loaded_on_vessel: { color: 'default' },
  discharged_from_vessel: { color: 'default' },
  customs_document_present: { color: 'default' },
  full_gate_out: { color: 'default' },
  cargo_delivered: { color: 'default' },
  empty_gate_in: { color: 'default' },
}

export const podInspectionStatusColors = {
  notified: 'error',
  released: 'secondary',
}

export const columnsData = {
  'Container NR': { value: 'container.number', type: 'container_nr' },
  Seal: {
    value: 'shipment.seal_number',
    type: 'string',
  },
  Notes: {
    value: 'notes',
    type: 'notes',
  },
  Type: {
    value: 'shipment',
    type: 'container_type',
  },
  Customer: { value: 'customer.name', type: 'string' },
  Vessel: { value: 'shipment.vessel', type: 'string' },
  '(Pick-up and delivery) date': {
    value: 'pickup_delivery_time',
    type: 'pickup_delivery_time',
  },
  ETD: {
    value: 'shipment.estimated_departure',
    type: 'datetime',
  },
  'Shypple ETA': {
    value: 'shipment.estimated_arrival',
    type: 'datetime',
  },
  'Discharge Terminal': {
    value: 'shipment.discharge_terminal',
    type: 'string',
  },
  'Container status': {
    value: 'container.status',
    type: 'container_status',
  },
  POD: {
    value: 'shipment.discharge_port',
    type: 'string',
  },
  'POD ETD': {
    value: 'shipment.estimated_discharge',
    type: 'datetime',
  },
  'Terminal ETA': {
    value: 'shipment.terminal_estimated_arrival',
    type: 'datetime',
  },
  'Carrier ETA': {
    value: 'shipment.carrier_estimated_arrival',
    type: 'datetime',
  },
  'Container discharge': {
    value: 'container.actual_discharge',
    type: 'datetime',
  },
  'Container gate out': {
    value: 'container.actual_full_gate_out',
    type: 'datetime',
  },
  Inspection: {
    value: 'inspection',
    type: 'inspection',
  },
  '(Pick-up and delivery) address': {
    value: 'inland_transport_addresses',
    type: 'inland_transport_address',
  },
  '(Pick-up and delivery) status': {
    value: 'status',
    type: 'inland_transport_status',
  },
  'First day of demurrage': {
    value: [
      'container.actual_first_day_of_demurrage',
      'shipment.destination_demurrage_starting_from',
    ],
    type: 'first_day_of_demurrage',
  },
  'Shipment reference': { value: 'reference', type: 'reference' },
  'Shipment status': { value: 'shipment', type: 'status' },
  Transporter: { value: 'transporter.name', type: 'transporter' },
  Shipper: { value: 'shipment.shippers', type: 'array_names' },
  Shipment: { value: 'title', type: 'reference' },
  Carrier: { value: 'shipment.carrier.scac', type: 'carrier' },
  'Master BL/AWB': {
    value: 'shipment.master_bl_number',
    type: 'string',
  },
  'House BL/AWB': {
    value: 'shipment.house_bl_number',
    type: 'array_names',
  },
}

export const shipmentColumnsData = {
  shipment: {
    name: 'Shipment',
    component: (rowProps) => rowProps.row.title,
  },
  etd: {
    name: 'ETD',
    component: (rowProps) =>
      rowProps.row.estimated_departure
        ? convertDateToDateWithoutYear(rowProps.row.estimated_departure)
        : 'TBA',
  },
  port_of_loading: {
    name: 'Port of loading',
    component: (rowProps) => rowProps.row.port_pair?.loading_port.name,
  },
  eta: {
    name: 'Shypple ETA',
    component: (rowProps) =>
      rowProps.row.estimated_arrival
        ? convertDateToDateWithoutYear(rowProps.row.estimated_arrival)
        : 'TBA',
  },
  port_of_discharge: {
    name: 'Port of discharge',
    component: (rowProps) => rowProps.row.port_pair?.discharge_port.name,
  },
}

export const shipmentColumnsAdditionalData = {
  supplier: {
    name: 'Supplier',
    component: (rowProps) => '-',
  },
  vessel: {
    name: 'Vessel',
    component: (rowProps) => rowProps.row.vessel,
  },
  mbl_number: {
    name: 'MBL number',
    component: (rowProps) => rowProps.row.bl_number,
  },
}

export const containerColumnsData = {
  type: {
    name: 'Type',
    component: (rowProps) => rowProps.row?.container_type.name,
  },
  'Container number': {
    name: 'Container number',
    component: (rowProps) => rowProps.row.container_number,
  },
  'Seal number': {
    name: 'Seal number',
    component: (rowProps) => rowProps.row.seal_number,
  },
}

export const inlandTransportColumnsData = {
  transporter: {
    name: 'Transporter',
    component: (rowProps) => rowProps.row?.transporter?.name,
  },
  'transporter email': {
    name: 'Transporter email',
    component: (rowProps) => '-',
  },
  Modality: {
    name: 'Modality',
    component: (rowProps) => capitalize(rowProps.row.modality),
  },
  'Delivery available from': {
    name: 'Delivery available from',
    component: (rowProps) => '-',
  },
}

export const addressesColumnsData = {
  type: {
    name: 'Type',
    component: (rowProps) => capitalize(rowProps.row.address_type),
  },
  address: {
    name: 'Address',
    component: (rowProps) => rowProps.row.name,
  },
  reference: {
    name: 'Reference',
    component: (rowProps) => rowProps.row.reference,
  },
  time: {
    name: 'Time',
    component: (rowProps) => (
      <>
        {convertDateToTimeRange(
          rowProps.row.datetime_from,
          rowProps.row.datetime_to
        )}
        {!!rowProps.row.datetime_status ? (
          <Chip
            size="small"
            label={capitalize(rowProps.row.datetime_status)}
            color={statusColors[rowProps.row.datetime_status]}
            sx={{ ml: 1.5 }}
          />
        ) : (
          ''
        )}
      </>
    ),
  },
}

export const reeferSettingsColumnsData = {
  temporter: {
    name: 'Temp',
    className: 'short-50',
    component: (rowProps) =>
      rowProps.row?.temperature ? `${rowProps.row?.temperature} °C` : '-',
  },
  humidity: {
    name: 'Humidity',
    className: 's-medium',
    component: (rowProps) =>
      rowProps.row?.humidity ? `${rowProps.row?.humidity} %` : '-',
  },
  ventilation: {
    name: 'Ventilation',
    className: 's-medium',
    component: (rowProps) =>
      rowProps.row?.ventilation ? `${rowProps.row?.ventilation} CBM/HR` : '-',
  },
  vents: {
    name: 'Vents',
    className: 's-medium',
    component: (rowProps) =>
      rowProps.row?.vents_open
        ? 'OPEN'
        : rowProps.row?.vents_open === null
        ? 'UNSPECIFIED'
        : 'CLOSED',
  },
  drains: {
    name: 'Drains',
    className: 's-medium',
    component: (rowProps) =>
      rowProps.row?.drains_open
        ? 'OPEN'
        : rowProps.row?.vents_open === null
        ? 'UNSPECIFIED'
        : 'CLOSED',
  },
  co2_regulation: {
    name: 'CO₂',
    className: 'short-50',
    component: (rowProps) =>
      rowProps.row?.co2_regulation ? `${rowProps.row?.co2_regulation} %` : '-',
  },
}

export const statuses = [
  ['Estimated', 'estimated'],
  ['Requested', 'requested'],
  ['Proposed', 'proposed'],
  ['Confirmed', 'confirmed'],
  ['Completed', 'completed'],
]

export const binaries = [
  ['Yes', 'true'],
  ['No', 'false'],
]

export const deliveryPlannedOutsideFreeDaysMsg =
  'The delivery date is set on or after the first day of demurrage.\nCosts may arise when a container is in demurrage.'

export const adminColumnNames = ['Tasks', 'Air Tasks']

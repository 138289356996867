import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { InfoTooltip } from 'src/stories/MUI'
import { FormLabelProps } from './FormLabel.props'

const FormLabel: React.FC<FormLabelProps> = ({ label, count = 0, tooltip }) => {
  return (
    <Stack spacing={0.5} mb={0.5} direction="row">
      <Typography
        noWrap
        children={label}
        variant="body1Strong"
        data-testid="form-label-text"
      />
      {count > 0 && (
        <Typography
          variant="body1Strong"
          children={`(${count})`}
          data-testid="form-label-counter"
        />
      )}
      {tooltip && (
        <InfoTooltip fluid={true} placement="right-start" title={tooltip} />
      )}
    </Stack>
  )
}

export default FormLabel

import { FunctionComponent, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Tabs from 'src/stories/Tabs'
import { Box, Paper } from '@mui/material'
import { LinearProgress } from 'src/stories'
import Typography from '@mui/material/Typography'
import LoadableContainer from 'src/components/LoadableContainer'
import BlankState from 'src/components/Common/BlankState'
import WatchShipmentsToggler from 'src/components/Common/WatchShipmentsToggler'
import { promisifyAction, permissionTo } from '../../utils'

import { quotesGetData } from '../../stores/actionCreators'
import QuoteLine from './QuoteLine'

const initTabList: ITabProps[] = []

const Quotes: FunctionComponent<{ match: IMatch }> = ({ match }) => {
  const dispatch = useDispatch()
  const [busy, setBusy] = useState<boolean>(true)
  const [tab, setTab] = useState<string>('proposed_quote')
  const [tabList, setTabList] = useState<ITabProps[]>(initTabList)
  const [onlyWatchedShipments, setOnlyWatchedShipments] = useState<boolean>(
    window.localStorage.getItem('quotesMyShipments') === 'true'
  )

  const { quotes, proposedQuotes } = useSelector((state: IGlobalState) => ({
    quotes: state.quotes.quotes,
    proposedQuotes: state.quotes.proposedQuotes,
  }))

  const quotesGetDataAsync = promisifyAction(dispatch, quotesGetData)
  const isAcceptQuote: boolean = permissionTo('shipments.accept_quote.all')

  useEffect(() => {
    const fetchDataAsync = async () => {
      setBusy(true)
      await quotesGetDataAsync({ my_shipments: onlyWatchedShipments })
      setBusy(false)
    }
    fetchDataAsync()
  }, [])

  const { t } = useTranslation()

  useEffect(() => {
    const splittedQuotes: any = {
      proposed_quote: proposedQuotes,
      quote: quotes,
    }
    const localTabList: ITabProps[] = [
      {
        label: t('quotes.proposed_quotes.tab', 'Proposed quotes'),
        value: 'proposed_quote',
        permissions: [],
      },
      {
        label: t('quotes.quotes.tab', 'Quotes'),
        value: 'quote',
        permissions: [],
      },
    ].map((tab: ITabProps) => {
      tab.label = `${tab.label} (${splittedQuotes[tab.value].length || 0})`
      return tab
    })
    setTabList(localTabList)
  }, [quotes, proposedQuotes])

  const requestQuote = (): void => {
    window.location.href = '/search'
  }

  const splittedQuotes: any = {
    proposed_quote: {
      label: t('quotes.proposed_quotes.tab', 'Proposed quotes'),
      data: proposedQuotes,
    },
    quote: {
      label: t('quotes.quotes.tab', 'Quotes'),
      data: quotes,
    },
  }

  const handleWatchedShipments = async (newValue: boolean): Promise<void> => {
    setBusy(true)
    setOnlyWatchedShipments(newValue)
    localStorage.setItem('quotesMyShipments', JSON.stringify(newValue))
    await quotesGetDataAsync({ my_shipments: newValue })
    setBusy(false)
  }

  return (
    <article>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          mb={2}
          variant="h2"
          children={t('quotes.quotes.tab', 'Quotes')}
        />
        <WatchShipmentsToggler
          value={onlyWatchedShipments}
          action={handleWatchedShipments}
        />
      </Box>
      <Paper variant="elevation" elevation={1}>
        <Tabs value={tab} onChange={setTab} tabs={tabList} />
        <Box sx={{ minHeight: '480px' }}>
          <LoadableContainer
            className="quotes-page__loader-container"
            placeholder={<LinearProgress />}
            loading={busy}
          >
            {splittedQuotes[tab].data.length ? (
              splittedQuotes[tab].data.map((quote: IQuote) => (
                <QuoteLine
                  key={quote.id}
                  quote={quote}
                  fromPath={match?.path}
                  isAcceptQuote={isAcceptQuote}
                />
              ))
            ) : (
              <BlankState.Info
                type="button"
                iconType="container"
                descMainText={t(
                  'quotes.main_text',
                  'It looks like that you havent requested any quotes'
                )}
                descSubText={t(
                  'quotes.sub_text',
                  'Do you want to start shipping? Request a quote!'
                )}
                buttonText={t('quotes.request_quote', 'Request quote')}
                buttonAction={requestQuote}
              />
            )}
          </LoadableContainer>
        </Box>
      </Paper>
    </article>
  )
}

export default Quotes

import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { find, compact, startCase, capitalize } from 'lodash'
import {
  Box,
  Button,
  Divider,
  Link,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import { ModalityEnum } from 'src/config/constants'
import { showNotification } from 'src/stores/actionCreators/notifications'
import ContainerIcon from 'src/components/Common/ContainerInfo/ContainerIcon'
import Chip from '@mui/material/Chip'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Counter } from '../../../stories/Counter'

import { containerTypeDescription } from '../../../utils/helpers'

import {
  acceptInlandTransportStatus,
  copyContainerAttributes,
} from '../../../stores/actionCreators'
import { permissionTo, promisifyAction } from '../../../utils/'

import './styles.scss'

const reeferIcons = {
  '40_hc_rf': (
    <>
      <i className="icon HC-reefer-container1">
        <span className="path1"></span>
        <span className="path2"></span>
        <span className="path3"></span>
        <span className="path4"></span>
        <span className="path5"></span>
        <span className="path6"></span>
        <span className="path7"></span>
        <span className="path8"></span>
        <span className="path9"></span>
        <span className="path10"></span>
        <span className="path11"></span>
        <span className="path12"></span>
        <span className="path13"></span>
        <span className="path14"></span>
        <span className="path15"></span>
        <span className="path16"></span>
        <span className="path17"></span>
      </i>
    </>
  ),
  '20_rf': (
    <>
      <i className="icon reefer-container1">
        <span className="path1"></span>
        <span className="path2"></span>
        <span className="path3"></span>
        <span className="path4"></span>
        <span className="path5"></span>
        <span className="path6"></span>
        <span className="path7"></span>
        <span className="path8"></span>
        <span className="path9"></span>
        <span className="path10"></span>
        <span className="path11"></span>
        <span className="path12"></span>
        <span className="path13"></span>
        <span className="path14"></span>
        <span className="path15"></span>
        <span className="path16"></span>
        <span className="path17"></span>
      </i>
    </>
  ),
  '40_rf': (
    <>
      <i className="icon reefer-container">
        <span className="path1"></span>
        <span className="path2"></span>
        <span className="path3"></span>
        <span className="path4"></span>
        <span className="path5"></span>
        <span className="path6"></span>
        <span className="path7"></span>
        <span className="path8"></span>
        <span className="path9"></span>
        <span className="path10"></span>
        <span className="path11"></span>
        <span className="path12"></span>
        <span className="path13"></span>
        <span className="path14"></span>
        <span className="path15"></span>
        <span className="path16"></span>
        <span className="path17"></span>
        <span className="path18"></span>
        <span className="path19"></span>
        <span className="path20"></span>
        <span className="path21"></span>
        <span className="path22"></span>
        <span className="path23"></span>
        <span className="path24"></span>
        <span className="path25"></span>
        <span className="path26"></span>
        <span className="path27"></span>
      </i>
    </>
  ),
}

interface IProps {
  shipmentData: IShipmentMainData
  containerData: IShipmentContainer
  isLclOrAir: boolean
  isSailingTBA: boolean
  collapsed?: boolean
  openDateTimeModal: (transport: IInlandTransport, containerId: number) => void
  openAddressModal: (
    address: IInlandTransportAddress | null,
    service: InlandTransportService,
    containerId: number
  ) => void
  openContainerWindow: (id: number) => void
  openDuplicateContainerWindow: (id: number) => void
  confirmDeletion: (id: number) => void
  fetchData: () => void
  openCargoDetails: (id: number) => void
  isSingleContainer: boolean
  shipmentModality: ModalityEnum
}

const ContainerOverviewHeaderCollapsed: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { cargo, inland_transports } = props.containerData
  const { shipment_phase } = props.shipmentData
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const containerStatus = props.containerData.status
  const handleActionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleActionsClose = () => {
    setAnchorEl(null)
  }

  const acceptInlandTransportStatusAsync = promisifyAction(
    dispatch,
    acceptInlandTransportStatus
  )

  const copyContainerAttributesAsync = promisifyAction(
    dispatch,
    copyContainerAttributes
  )

  const service: InlandTransportService =
    shipment_phase === 'origin' ? 'pickup' : 'delivery'

  const isOriginPhase: boolean = shipment_phase === 'origin'

  const viewPermission: boolean = permissionTo(
    `shipments.containers_|_cargo.${service}.view`
  )
  const managePermission: boolean = permissionTo(
    `shipments.containers_|_cargo.${service}.manage`
  )
  const containerManagePermission: boolean = permissionTo(
    `shipments.containers.manage`
  )
  const cargoLinesView: boolean = permissionTo(
    'shipments.containers_|_cargo.cargo_lines.view'
  )
  const cargoLinesManage: boolean = permissionTo(
    'shipments.containers_|_cargo.cargo_lines.manage'
  )

  const pickupTransport = find(inland_transports, {
    service: 'pickup',
  })
  const deliveryTransport = find(inland_transports, {
    service: 'delivery',
  })

  const transport = (isOriginPhase
    ? pickupTransport
    : deliveryTransport) as IInlandTransport

  const transportAddress = transport?.inland_transport_addresses?.length
    ? transport.inland_transport_addresses[0]
    : null

  const cargoDescription = (): string => {
    if (cargo && cargo.length) {
      const descriptionsArr: string[] = cargo.map((item) => {
        return item.goods_description || '-'
      })
      const goodsDescription: string =
        cargo.length > 1
          ? compact(descriptionsArr).join(', ')
          : descriptionsArr[0]
      if (goodsDescription.length > 50) {
        return goodsDescription.slice(0, 50) + '...'
      }
      return goodsDescription
    }
    return '-'
  }

  const acceptDateAndTime = async (): Promise<any> => {
    if (transportAddress?.address_id) {
      await acceptInlandTransportStatusAsync(props.containerData.id, {
        service_type: service,
      })
      props.fetchData()
    } else {
      requestAddress()
      showErrorMessage()
    }
  }

  const requestTime = (): void => {
    if (transportAddress?.address_id) {
      props.openDateTimeModal(transport, props.containerData.id)
    } else {
      requestAddress()
      showErrorMessage()
    }
  }

  const requestAddress = useCallback(() => {
    props.openAddressModal(null, service, props.containerData.id)
  }, [props.containerData.id, service])

  const openContainerChange = useCallback(() => {
    props.openContainerWindow(props.containerData.id)
  }, [props.containerData.id, props.containerData.id])

  const openCargoDetails = (e): void => {
    if (!props.collapsed) {
      e.stopPropagation()
    }
    props.openCargoDetails(props.containerData.id)
  }

  const isEstimated: boolean = transport?.status === 'estimated'

  const dateTimeRange = t('common.date_time_range_med', {
    defaultValue: '{{value, DATETIME_RANGE_MED}}',
    value: [transport?.pickup_delivery_time, transport?.closing_time],
  })

  const dateTimeBlock: React.ReactNode = (
    <>
      {transport?.pickup_delivery_time_editable && managePermission ? (
        <Link variant="body1" component="button" onClick={requestTime}>
          {dateTimeRange}
        </Link>
      ) : (
        <div>{dateTimeRange}</div>
      )}
    </>
  )

  const addCargoBlock: React.ReactNode = (
    <>
      <div>
        {cargoLinesManage ? (
          <Link
            component="button"
            variant="body1"
            onClick={(e) => openCargoDetails(e)}
          >
            {t('common.buttons.add', 'Add')}
          </Link>
        ) : (
          '-'
        )}
      </div>
    </>
  )

  const showErrorMessage = () => {
    dispatch(
      showNotification({
        message: t('shipment_containers.notifications.enter_service_address', {
          defaultValue: 'Please enter your {{service}} address first.',
          service,
        }),
        severity: 'error',
      })
    )
  }

  const openTasksCount = useMemo(() => {
    return props.containerData.milestones.filter(
      (x) => !x.reached && x.status !== 'upcoming'
    ).length
  }, [props.containerData.milestones])

  const isExeptionType: string | undefined = useMemo(() => {
    if (props.containerData.container_type.code) {
      const names: string[] = Object.keys(reeferIcons)
      const name: string | undefined = names.find(
        (i: string) => i === props.containerData.container_type.code
      )
      return name
    }
    return undefined
  }, [props.containerData.container_type.code])

  const onContainerModalOpen = useCallback((): void => {
    props.openContainerWindow(props.containerData.id)
  }, [props.openContainerWindow, props.containerData.id])

  const onDupliclateContainerModalOpen = useCallback((): void => {
    props.openDuplicateContainerWindow(props.containerData.id)
  }, [props.openDuplicateContainerWindow, props.containerData.id])

  const copyToAllContainers = async () => {
    await copyContainerAttributesAsync({
      shipment_id: props.shipmentData.id,
      container_id: props.containerData.id,
    })
    dispatch(
      showNotification({
        message: t(
          'shipment_containers.notifications.container_data_copied',
          'Container data copied successfully.'
        ),
        severity: 'success',
      })
    )
  }

  return (
    <Box
      sx={{
        backgroundColor: !props.collapsed ? 'grey.100' : 'white',
        border: 'grey.100',
      }}
      className="container-overview-header--collapsed"
    >
      <div className="container-size">
        <div className="container-size--title">
          {isExeptionType ? (
            reeferIcons[isExeptionType]
          ) : (
            <ContainerIcon
              containerName={props.containerData.container_type.code || null}
              shipmentType={props.shipmentData.type}
              sx={{ mt: -1 }}
            />
          )}
          {props.containerData.container_event_exceptions.length ? (
            <i className="icon attention" />
          ) : null}
          {!props.isLclOrAir && (
            <Typography
              variant="body1"
              data-testid="shipment-container-type-value"
            >
              {containerTypeDescription(
                props.shipmentData.type,
                props.containerData.container_type.name || null
              )}
            </Typography>
          )}
        </div>

        {containerStatus && (
          <div>
            <Chip
              label={
                containerStatus === 'vgm_submitted'
                  ? 'VGM submitted'
                  : capitalize(startCase(containerStatus))
              }
              size="small"
              variant="outlined"
              color="secondary"
              sx={{ margin: '10px 0' }}
            />
          </div>
        )}
      </div>
      <Divider orientation="vertical" flexItem />
      <div className="container-info">
        <div className="container-info--title">
          <Typography
            variant="body1Strong"
            children={t(
              'shipment_containers.container_number',
              'Container number'
            )}
          />
        </div>
        <div>
          <Typography
            variant="body1"
            data-testid="shipment-container-number-value"
          >
            {props.containerData.number || (
              <>
                {containerManagePermission ? (
                  <Link
                    component="button"
                    variant="body1"
                    onClick={openContainerChange}
                  >
                    {t('common.buttons.add', 'Add')}
                  </Link>
                ) : (
                  '-'
                )}
              </>
            )}
          </Typography>
        </div>
      </div>
      {cargoLinesView && (
        <div className="container-info cargo">
          <div className="container-info--title">
            <Typography
              variant="body1Strong"
              children={t('shipment_containers.cargo', 'Cargo')}
            />
          </div>
          <div>
            {cargo && cargo.length ? (
              <Box>
                <Typography
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                  variant="body1"
                  children={cargoDescription()}
                />
              </Box>
            ) : (
              addCargoBlock
            )}
          </div>
        </div>
      )}
      {viewPermission && (
        <div className="container-info">
          <div className="container-info--title paragraph__small grey">
            <Typography
              variant="body1Strong"
              children={`${
                isOriginPhase
                  ? t(
                      'shipment_containers.pickup_date_and_time',
                      'Pick-up date & time'
                    )
                  : t(
                      'shipment_containers.delivery_date_and_time',
                      'Delivery date & time'
                    )
              }`}
            />
          </div>
          {!transportAddress && <div>-</div>}
          {transportAddress && props.isSailingTBA ? (
            <Typography
              variant="body1Strong"
              children={t(
                'shipment_containers.to_be_announced',
                'To be announced'
              )}
            />
          ) : (
            <>
              {isEstimated && transport?.pickup_delivery_time_editable && (
                <div
                  className={`${
                    managePermission ? 'blue-text' : ''
                  } manageable`}
                >
                  {managePermission ? (
                    <Link
                      component="button"
                      variant="body1"
                      onClick={requestTime}
                    >
                      {t('common.buttons.request', 'Request')}
                    </Link>
                  ) : (
                    '-'
                  )}
                </div>
              )}
              {transport?.status === 'proposed' && (
                <div>
                  <div>{dateTimeBlock}</div>
                  {managePermission && (
                    <div className="button-block">
                      <Button
                        startIcon={<CheckRoundedIcon />}
                        variant="outlined"
                        children={t('common.buttons.accept', 'Accept')}
                        onClick={acceptDateAndTime}
                      />
                      <Button variant="contained" onClick={requestTime}>
                        {t('common.buttons.change', 'Change')}
                      </Button>
                    </div>
                  )}
                </div>
              )}
              {transport?.status === 'requested' && (
                <div>
                  {dateTimeBlock}
                  <Typography
                    variant="body1"
                    children={t(
                      'shipment_containers.to_be_confirmed_by_forwarder',
                      'To be confirmed by the forwarder'
                    )}
                  />
                </div>
              )}
              {transport?.status === 'confirmed' && (
                <div>
                  <div>{dateTimeRange}</div>
                  <Typography
                    variant="body1"
                    children={t(
                      'shipment_containers.transport_confirmed',
                      'Confirmed'
                    )}
                  />
                </div>
              )}
              {isEstimated &&
                transport?.pickup_delivery_time &&
                !transport?.pickup_delivery_time_editable && (
                  <div>
                    <Typography variant="body1">
                      {t('shipment_containers.estimated_on', {
                        defaultValue: 'Estimated on {{date, DATE_MED}}',
                        date: transport.pickup_delivery_time,
                      })}
                    </Typography>
                  </div>
                )}
            </>
          )}
        </div>
      )}
      <div className="container-info number">
        <div className="container-info--title">
          <Typography
            variant="body1Strong"
            children={t('shipment_containers.tasks', 'Tasks')}
          />
        </div>
        {openTasksCount > 0 && (
          <Counter value={openTasksCount} className={'red'} />
        )}
        {!openTasksCount && (
          <Typography
            variant="body1"
            children={t('shipment_containers.none', 'None')}
          />
        )}
      </div>
      {permissionTo('shipments.containers.manage') &&
        props.shipmentModality !== ModalityEnum.Air && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              id="actions-toggler"
              data-testid="actions-toggler"
              aria-controls={open ? 'actions-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleActionsClick}
              startIcon={<MoreVertIcon />}
            >
              {t('common.buttons.more', 'More')}
            </Button>
            <Menu
              id="actions-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleActionsClose}
              MenuListProps={{
                'aria-labelledby': 'actions-toggler',
              }}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              className="blocked-open"
            >
              <MenuItem
                onClick={() => {
                  onContainerModalOpen()
                  handleActionsClose()
                }}
                className="blocked-open"
              >
                <Typography
                  variant="body1"
                  children={t(
                    'common.buttons.edit_container',
                    'Edit container'
                  )}
                  className="blocked-open"
                />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  props.confirmDeletion(props.containerData.id)
                  handleActionsClose()
                }}
                className="blocked-open"
              >
                <Typography
                  variant="body1"
                  children={t(
                    'common.buttons.remove_container',
                    'Remove container'
                  )}
                  className="blocked-open"
                />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onDupliclateContainerModalOpen()
                  handleActionsClose()
                }}
                className="blocked-open"
              >
                <Typography
                  variant="body1"
                  children={t(
                    'common.buttons.duplicate_container',
                    'Duplicate container'
                  )}
                  className="blocked-open"
                />
              </MenuItem>
              {!props.isSingleContainer && (
                <MenuItem
                  onClick={() => {
                    handleActionsClose()
                    copyToAllContainers()
                  }}
                  className="blocked-open"
                >
                  <Typography
                    variant="body1"
                    children={t(
                      'common.buttons.copy_to_all_containers',
                      'Copy to all containers'
                    )}
                    className="blocked-open"
                  />
                </MenuItem>
              )}
            </Menu>
          </Box>
        )}
      <div className="block-arrow">
        <i className={`icon chevron ${props.collapsed ? 'collapsed' : ''}`} />
      </div>
    </Box>
  )
}

export default ContainerOverviewHeaderCollapsed

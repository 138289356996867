import { ChipProps } from '@mui/material/Chip'

export const palette = [
  'primary',
  'secondary',
  'info',
  'success',
  'warning',
  'error',
]

export const shipmentPhasePalette = {
  origin: 'info',
  deleted: 'error',
  quote: 'warning',
  cancelled: 'error',
  delivered: 'success',
  destination: 'primary',
  in_transit: 'secondary',
}

export const getShipmentStatusChipColorBasedOnShipmentPhase = (
  phase: string
) => {
  return shipmentPhasePalette?.[phase] ?? ('secondary' as ChipProps['color'])
}

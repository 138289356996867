import DemurrageAndDetentionContent from 'src/components/DemurrageAndDetention/DemurrageAndDetentionContent'
import DemurrageAndDetentionTopSection from 'src/components/DemurrageAndDetention/DemurrageAndDetentionTopSection'

const DemurrageAndDetention = () => {
  return (
    <>
      <DemurrageAndDetentionTopSection />
      <DemurrageAndDetentionContent />
    </>
  )
}

export default DemurrageAndDetention

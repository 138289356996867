import { convertDateToTimeRange } from 'src/utils/helpers'

export enum EstimatedPickupOrDeliveryStatusEnum {
  Requested = 'requested',
  Confirmed = 'confirmed',
  Proposed = 'proposed',
}

export const getIsEstimated = (
  transport: IInlandTransport | undefined
): boolean => transport?.status === 'estimated'

export const getDateTimeRange = (
  transport: IInlandTransport | undefined
): string =>
  convertDateToTimeRange(
    transport?.pickup_delivery_time,
    transport?.closing_time
  )

import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Box, InputLabel, Tooltip, Link } from '@mui/material'
import { useTranslation, Trans } from 'react-i18next'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import MuiMultipleInput from 'src/components/Common/Input/MuiMultipleInput'
import Input from 'src/components/Common/Input/MuiInput'
import { Multiselect } from 'src/stories/Multiselect'
import { TextArea } from 'src/stories/TextArea'
import getClassName from 'src/utils/styling/index'
import { promisifyAction } from 'src/utils'
import { shipmentDocumentsGetData } from 'src/stores/actionCreators'
import EmailAddressesComponent from './EmailAddressesComponent'
import { IShareData } from './'

const messageMaxCharacters = 400

interface IProps {
  shipmentId: string
  onChangeData: (type, value) => void
  data: IShareData
  shareLink: string
  ccError: string
  userEmailError: string
  onEmailsChange: (value: Record<string, any>) => void
}

const ShareEmailBlock: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const [messageError, setMessageError] = useState<string | null>(null)
  const dispatch = useDispatch()

  const shipmentDocumentsGetDataAsync = promisifyAction(
    dispatch,
    shipmentDocumentsGetData
  )

  const { shipmentDocuments } = useSelector((state: IGlobalState) => ({
    shipmentDocuments:
      state.shipmentDocs.shipmentDocumentsData.shipment_documents,
  }))

  useEffect(() => {
    shipmentDocumentsGetDataAsync(props.shipmentId)
  }, [])

  const emailInputChange = (
    values: string[] | number[] | { [key: string]: any }[],
    name
  ) => {
    props.onChangeData(name, values)
  }

  const handleChangeArea = (event): void => {
    props.onChangeData('message', event.target.value)
    setMessageError(
      event.target.value && event.target.value.length >= messageMaxCharacters
        ? t('shipment_share.validations.message_too_long', {
            defaultValue: 'Message cannot be longer than {{count}} characters',
            count: messageMaxCharacters,
          })
        : null
    )
  }

  const handleDocsSelect = (values): void => {
    return props.onChangeData('document_ids', values as number[])
  }

  const docsOptions = useMemo(() => {
    return (shipmentDocuments || []).map((doc) => {
      doc.label = doc.original_filename
      return doc
    })
  }, [shipmentDocuments])

  const onSubjectChange = (value) => {
    props.onChangeData('subject', value || '')
  }

  const userEmailChange = (value) => {
    props.onChangeData('reply_to', value || '')
  }

  const tooltipContent = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography
        variant="body1"
        children={t(
          'shipment_share.tooltips.shared_content_info.title',
          'The following information will be shared:'
        )}
        mb={2}
      />
      <Typography
        ml={0.5}
        variant="body1"
        children={t(
          'shipment_share.tooltips.shared_content_info.etd',
          'Estimated Time of Departure (ETD)'
        )}
      />
      <Typography
        ml={0.5}
        variant="body1"
        children={t(
          'shipment_share.tooltips.shared_content_info.eta',
          'Estimated Time of Arrival (ETA)'
        )}
      />
      <Typography
        ml={0.5}
        variant="body1"
        children={t(
          'shipment_share.tooltips.shared_content_info.cargo_closing_datetime',
          'Cargo closing'
        )}
      />
      <Typography
        ml={0.5}
        variant="body1"
        children={t(
          'shipment_share.tooltips.shared_content_info.shipment_status',
          'Shipment status'
        )}
      />
      <Typography
        ml={0.5}
        variant="body1"
        children={t(
          'shipment_share.tooltips.shared_content_info.containers',
          'Containers'
        )}
      />
      <Typography
        ml={0.5}
        variant="body1"
        children={t(
          'shipment_share.tooltips.shared_content_info.carrier',
          'Carrier'
        )}
      />
      <Typography
        ml={0.5}
        variant="body1"
        children={t(
          'shipment_share.tooltips.shared_content_info.vessel',
          'Vessel'
        )}
      />
      <Typography
        ml={0.5}
        variant="body1"
        children={t(
          'shipment_share.tooltips.shared_content_info.co2_emissions',
          'CO2 emissions'
        )}
      />
      <Typography mt={2} component="div" variant="body1">
        <Trans
          i18nKey="shipment_share.tooltips.shared_content_info.check_preview"
          defaults="Check out a preview of the page <0>here</0>"
          components={[
            <Link
              ml={0.5}
              sx={{ ':hover': { cursor: 'pointer' } }}
              href={props.shareLink}
              target="_blank"
            />,
          ]}
        />
      </Typography>
    </Box>
  )

  const customOption = (optionProps, option, selected): React.ReactNode => {
    return (
      <li {...optionProps}>
        <Typography
          variant="body1Strong"
          sx={{
            mr: 1.5,
            width: 200,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {option.label}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            width: 250,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {option.types?.map((type) => type.name).join(', ')}
        </Typography>
      </li>
    )
  }

  return (
    <Box mt={2} sx={{ position: 'relative' }}>
      <EmailAddressesComponent
        values={props.data.to_email_addresses}
        options={props.data.options}
        onChange={props.onEmailsChange}
      />
      <Box mt={2} sx={{ display: 'flex' }}>
        <Box sx={{ width: '50%' }}>
          <InputLabel htmlFor="customer">
            {t('shipment_share.email.cc.label', 'Cc')}
          </InputLabel>
          <MuiMultipleInput
            name="cc"
            label=""
            placeholder={t(
              'shipment_share.email.cc.placeholder',
              'Enter email address(es)'
            )}
            onChange={emailInputChange}
            error={!!props.ccError && !!props.data.cc.length}
            helperText={props.ccError}
            values={props.data.cc}
            saveOnBlur={true}
          />
        </Box>
        <Box pl={2} sx={{ width: '50%' }}>
          <InputLabel htmlFor="customer">
            {t('shipment_share.email.reply_to.label', 'Reply to address')}
          </InputLabel>
          <Input
            required
            data-testid="reply_to"
            name="email"
            placeholder={t(
              'shipment_share.email.reply_to.placeholder',
              'Enter email address'
            )}
            onChange={userEmailChange}
            value={props.data.reply_to}
            error={!!props.userEmailError && !!props.data.reply_to}
            helperText={props.userEmailError}
          />
        </Box>
      </Box>
      <Box mt={2}>
        <Input
          data-testid="subject"
          label={t('shipment_share.email.subject.label', 'Subject*')}
          className="input-field"
          value={props.data.subject}
          onChange={onSubjectChange}
        />
      </Box>
      <Box mt={2}>
        <TextArea
          className={`shipment-collaborators-window--sharing-email--area  ${
            messageError ? 'value-error' : ''
          }`}
          id="textarea-field"
          maxLength={400}
          name="cancelshipment"
          onChange={handleChangeArea}
          value={props.data.message}
          placeholder={t(
            'shipment_share.email.message.placeholder',
            'Type to add message'
          )}
          label={t('shipment_share.email.message.label', 'Message')}
          minRows={4}
        />
        {!!messageError && (
          <div
            className={getClassName('error-message', {
              hidden: !messageError,
            })}
          >
            {messageError}
          </div>
        )}
      </Box>
      <Box mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '45%' }}>
          <InputLabel htmlFor="customer">
            {t(
              'shipment_share.email.attach_document.label',
              'Attach documents'
            )}
          </InputLabel>
          <Multiselect
            name="document_ids"
            optionLabel="label"
            options={docsOptions.length ? docsOptions : []}
            values={props.data.document_ids || []}
            placeholder={t(
              'shipment_share.email.attach_document.placeholder',
              'Select document(s)'
            )}
            onChange={handleDocsSelect}
            customOption={customOption}
          />
        </Box>
        <Tooltip title={tooltipContent()} placement="right-start">
          <Box
            className="shipment-collaborators-window--info-message"
            sx={{ marginTop: '28px', marginLeft: '12px' }}
          >
            <InfoOutlinedIcon sx={{ fontSize: 20 }} />
            <Typography
              variant="body1Strong"
              ml={0.5}
              children={t(
                'shipment_share.tooltips.shared_content_info.trigger',
                'What is shown on the public tracking page?'
              )}
            />
          </Box>
        </Tooltip>
      </Box>
    </Box>
  )
}

export default ShareEmailBlock

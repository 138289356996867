import Chip from '@mui/material/Chip'
import { getTransitTimeText } from './SABSearchItem.utils'
import { SABSearchItemTimelineTransitChipProps } from './SABSearchItem.props'

const SABSearchItemTimelineTransitChip: React.FC<SABSearchItemTimelineTransitChipProps> = ({
  transitTime,
}) => {
  return (
    <Chip
      size="small"
      color="primary"
      variant="outlined"
      label={getTransitTimeText(transitTime)}
    />
  )
}

export default SABSearchItemTimelineTransitChip

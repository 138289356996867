// Inland transport services encompass both pickup and delivery of goods, and
// each type can have different associated addresses. For instance, in the case
// of an inland transport delivery, the address could refer to the location of
// the depot where the goods are being held or to an additional stop along the
// delivery route
const getPickupOrDeliveryServiceAddress = (
  container: IShipmentContainer,
  service: string
) => {
  if (!container) {
    return null
  }
  const inlandTransportFromType = container?.inland_transports.find(
    (item: IInlandTransport) => item.service === service
  )

  const inlandTransportAddressFromType = inlandTransportFromType?.inland_transport_addresses.find(
    (item: IInlandTransportAddress) => item.address_type === service
  )

  return inlandTransportAddressFromType?.address_id ?? null
}

export const getTemplateDataFromShipmentData = (
  organizationId: number,
  overview: IOverviewShipment,
  bookings: IBooking[],
  containers: IShipmentContainer[]
) => {
  const { services } = overview
  const booking = bookings[0] ?? null
  const firstContainer = containers[0] ?? null
  const pickupAddressId = services.pickup
    ? getPickupOrDeliveryServiceAddress(firstContainer, 'pickup')
    : null
  const deliveryAddressId = services.delivery
    ? getPickupOrDeliveryServiceAddress(firstContainer, 'delivery')
    : null

  const firstContainerCargo = firstContainer?.cargo[0] ?? null
  const cargo = firstContainerCargo
    ? {
        width: firstContainerCargo.width_mm
          ? firstContainerCargo.width_mm / 10
          : null,
        height: firstContainerCargo.height_mm
          ? firstContainerCargo.height_mm / 10
          : null,
        length: firstContainerCargo.length_mm
          ? firstContainerCargo.length_mm / 10
          : null,
        quantity: firstContainerCargo.quantity,
        weight: firstContainerCargo.total_weight_kg,
        description: firstContainerCargo.goods_description,
        total_volume_cbm: firstContainerCargo.volume_cbm,
        package_type_id: firstContainerCargo?.package_type?.id ?? null,
        stackable: firstContainerCargo.stackable ?? null,
      }
    : null

  const container = firstContainer
    ? {
        container_type_enum: firstContainer.container_type.code,
        amount: containers?.length || 0,
        non_operating_reefer: firstContainer.non_operating_reefer,
        temperature: firstContainer.temperature,
        humidity: firstContainer.humidity,
        ventilation: firstContainer.ventilation,
        vents_open: firstContainer.vents_open,
        drains_open: firstContainer.drains_open,
        co2_regulation: firstContainer.co2_regulation,
        comments: firstContainer.comments,
      }
    : null

  const dangerousGoods = overview?.dangerous_goods ?? null
  const temperature_setting = overview?.temperature_setting ?? null

  return {
    addresses: {
      pickup: pickupAddressId,
      delivery: deliveryAddressId,
      shipper: booking?.shipper?.shipment_address?.address_id ?? null,
      consignee: booking?.consignee?.shipment_address?.address_id ?? null,
    },
    customer_organization_id: organizationId,
    modality: overview.modality,
    load_type: overview.load_type,
    incoterm: overview.incoterm?.code || null,
    origin_id: overview.loading_port_id,
    origin_type: 'port',
    destination_id: overview.discharge_port_id,
    destination_type: 'port',
    cif_value: overview.cif_value,
    services: overview.services,
    cargo,
    container,
    collaborators: overview.collaborators.map((collaborator) => ({
      organization_id: collaborator.organization_id,
      role_ids: collaborator.roles.map((role) => role.id),
    })),
    hs_code: {
      hs_code: firstContainer?.hs_codes[0]?.goods_code ?? null,
    },
    references: {
      shared_reference: null,
      internal_reference: null,
    },
    tags: {
      dangerous_goods: dangerousGoods,
    },
    temperature_setting,
  }
}

import { Dispatch, FunctionComponent, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Filters } from 'src/components/Filters'
import { IRatesFiltersType } from 'src/pages/Rates'
import { useGetRatesFilterOptions } from 'src/services/Api/prices'

const RatesFilters: FunctionComponent<{
  setFilters: Dispatch<SetStateAction<IRatesFiltersType>>
}> = ({ setFilters }) => {
  const { t } = useTranslation()
  const { data: filterOptions, isLoading } = useGetRatesFilterOptions()

  const convertOptions = <T extends { id: string | number; name: string }[]>(
    data?: T
  ) => data?.map(({ id, name }) => ({ label: name, id: `${id}` })) || []

  const updateFilters = (value) => {
    setFilters({ ...value })
  }

  return (
    <Filters
      page="rates"
      open={true}
      onFiltersUpdate={updateFilters}
      defaultFiltersKeys={[]}
      isLoading={isLoading}
      filterOptions={{ saved_filters: [], modalities: [] }}
      filterOptionsQueryKey="pricesFilterOptions"
      initialFilters={{}}
      hasQuickFilters={false}
    >
      <Filters.TextInput
        name="search"
        label={t('rates.filters.search', 'Search')}
        placeholder={t('rates.filters.search', 'Search')}
        data-testid="rates-search"
        columnSize={4}
      />
      <Filters.MultipleAutocomplete
        name="origin_port_ids"
        label={t('rates.filters.pol', 'POL')}
        placeholder={t('rates.filters.pol', 'POL')}
        options={convertOptions(filterOptions?.origin_ports)}
        data-testid="rates-origin-ports"
      />
      <Filters.MultipleAutocomplete
        options={convertOptions(filterOptions?.destination_ports)}
        label={t('rates.filters.pod', 'POD')}
        name="destination_port_ids"
        data-testid="rates-destination-ports"
      />
      <Filters.FormDatePicker
        placeholder="DD-MM-YYYY"
        inputFormat="dd-MM-yyyy"
        label={t('rates.filters.historic_rates', 'Historic rates')}
        name={{ startDateId: 'valid_from', endDateId: 'valid_to' }}
        data-testid="rates-historic-rates"
      />
      <Filters.MultipleAutocomplete
        options={convertOptions(filterOptions?.origin_countries)}
        label={t('rates.filters.origin_country', 'Origin country')}
        placeholder={t('rates.filters.destination_country', 'Origin country')}
        name="origin_country_ids"
        data-testid="rates-origin-countries"
      />
      <Filters.MultipleAutocomplete
        options={convertOptions(filterOptions?.destination_countries)}
        label={t('rates.filters.destination_country', 'Destination country')}
        placeholder={t(
          'rates.filters.destination_country',
          'Destination country'
        )}
        name="destination_country_ids"
        data-testid="rates-destination-countries"
      />
      <Filters.MultipleAutocomplete
        options={convertOptions(filterOptions?.tradelanes)}
        label={t('rates.filters.trade_lanes', 'Trade lanes')}
        placeholder={t('rates.filters.trade_lanes', 'Trade lanes')}
        name="tradelane_ids"
        data-testid="rates-trade-lines"
      />
    </Filters>
  )
}

export default RatesFilters

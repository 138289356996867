import { LoadTypeEnum, ModalityEnum } from 'src/config/constants'
import camelcaseKeys from 'camelcase-keys'
import { flatMap, snakeCase, uniqBy, has, get, set } from 'lodash'
import { FCLIcon, LCLIcon } from 'src/svgIcons'
import { TFunction } from 'i18next'
import {
  defaultCargo,
  bookingPartyKeys,
  defaultContainer,
  defaultServiceValues,
} from './Form.constants'
import {
  FormStateProps,
  CollaboratorOptionProps,
  TemplateFormContextProps,
} from './Form.props'
import TemplateFormDetails from './components/Steps/Details'
import TemplateFormLoadDetails from './components/Steps/LoadDetails'
import TemplateFormCollaborators from './components/Parts/Collaborators/Collaborators'

export const getEditAndCreateSteps = (
  t: TFunction<'translation', undefined>,
  isUserOrganizationCustomer: boolean
) => {
  const customerRequiredFields = !isUserOrganizationCustomer ? ['customer'] : []

  return [
    {
      key: 'details',
      label: t(
        'templates.shipment_details.shipment_details',
        'Shipment services'
      ),
      component: () => <TemplateFormDetails />,
      requiredFields: [
        'name',
        'modality',
        'loadType',
        'loadingPort',
        'dischargePort',
        ...customerRequiredFields,
      ],
    },
    {
      key: 'loadDetails',
      label: t('templates.cargo_details.cargo_details', 'Cargo details'),
      component: () => <TemplateFormLoadDetails />,
      requiredFields: [],
    },
    {
      key: 'collaborators',
      label: t('templates.collaborators.heading', 'Collaborators'),
      component: () => <TemplateFormCollaborators />,
      requiredFields: [],
    },
  ]
}

const getMappedValuesFromResponsePayload = ({
  field,
  defaultData,
  templateData,
}) => {
  const data = { ...defaultData }
  const value = get(templateData, `template.${field}`, null)
  if (value) {
    const camelized = camelcaseKeys(value)
    for (let key in data) {
      if (has(camelized, key)) {
        data[key] = camelized[key]
      }
    }
  }
  return data
}

const getHsCode = ({ templateData }) => {
  const hsCode = get(templateData, 'template.hs_code.hs_code', null)

  if (hsCode === '0') {
    return null
  }
  return hsCode
}

const getContainer = ({ templateData }) => {
  return getMappedValuesFromResponsePayload({
    templateData,
    field: 'container',
    defaultData: defaultContainer,
  })
}

const getCargo = ({ templateData }) => {
  return getMappedValuesFromResponsePayload({
    templateData,
    field: 'cargo',
    defaultData: defaultCargo,
  })
}

const getServiceAddress = ({ templateData, service }) => {
  const address = get(templateData, `template.addresses.${service}`, null)
  if (address) {
    return {
      label: address.name,
      value: address.id,
    }
  }
  return null
}

const getPort = ({ templateData, portType }) => {
  const port = get(templateData, `template.${portType}_id`, null)
  if (port) {
    return {
      label: get(templateData, `template.${portType}_name`, ''),
      value: port,
    }
  }
  return null
}

const getCustomer = ({ templateData }) => {
  const label = get(templateData, 'template.customer_organization_name', null)
  const value = get(templateData, 'template.customer_organization_id', null)
  return value && label
    ? {
        value,
        label,
      }
    : null
}

const getOtherCollaborators = ({ templateData, shipmentRoles }) => {
  const collaborators = get(templateData, 'template.collaborators', [])
  return collaborators
    .filter((collaborator) => {
      const roles = collaborator.roles.map((role) => role.id)
      return (
        !roles.includes(shipmentRoles.shipper) &&
        !roles.includes(shipmentRoles.consignee)
      )
    })
    .map((filtered) => ({
      id: filtered.organization_id,
      name: filtered.name,
      shipmentRoleOptions: filtered.roles.map((role) => ({
        id: role.id,
        name: role.role,
      })),
      shipmentRoleIds: filtered.roles.map((role) => role.id),
    }))
}

const getCollaborator = ({ templateData, shipmentRoles, party }) => {
  const collaborator = get(
    templateData,
    `template.collaborators`,
    []
  ).find((collaborator) =>
    collaborator.roles.map((role) => role.id).includes(shipmentRoles[party])
  )

  if (collaborator) {
    return {
      value: collaborator.organization_id,
      label: collaborator.name ?? '',
    }
  }
  return null
}

const getAddress = ({ templateData, party }) => {
  const address = get(templateData, `template.addresses.${party}`, null)

  if (address) {
    return {
      value: address.id,
      label: address.name ?? '',
    }
  }
  return null
}

export const getPreferredBookingPartyIsMissing = ({
  bookingParties,
  userPreferredShipmentRoleCode,
}) => {
  return (
    bookingPartyKeys.includes(userPreferredShipmentRoleCode) &&
    !bookingParties[userPreferredShipmentRoleCode].collaborator &&
    !bookingParties[userPreferredShipmentRoleCode].address
  )
}

const getBookingParties = ({
  templateData,
  shipmentRoles,
  defaultUserOrganization,
  userPreferredShipmentRoleCode,
}) => {
  const shipperCollaborator = getCollaborator({
    templateData,
    shipmentRoles,
    party: 'shipper',
  })

  const consigneeCollaborator = getCollaborator({
    templateData,
    shipmentRoles,
    party: 'consignee',
  })

  const shipperAddress = getAddress({ templateData, party: 'shipper' })

  const consigneeAddress = getAddress({ templateData, party: 'consignee' })

  const bookingParties = {
    shipper: {
      collaborator: shipperCollaborator,
      address: shipperAddress,
    },
    consignee: {
      collaborator: consigneeCollaborator,
      address: consigneeAddress,
    },
  }

  // Set default collaborator if no collaborator or address is set
  // Based on the preferred shipment role code

  const preferredBookingPartyMissing = getPreferredBookingPartyIsMissing({
    bookingParties,
    userPreferredShipmentRoleCode,
  })

  if (preferredBookingPartyMissing) {
    bookingParties[
      userPreferredShipmentRoleCode
    ].collaborator = defaultUserOrganization
  }
  return bookingParties
}

export const mapTemplateResponsePayloadToFormState = ({
  templateData,
  shipmentRoles,
  defaultUserOrganization,
  userPreferredShipmentRole,
  userPreferredShipmentRoleCode,
}) => {
  const newFormState = {} as FormStateProps

  set(newFormState, 'name', get(templateData, 'name', null))

  set(
    newFormState,
    'shipmentRole',
    get(templateData, 'template.shipment_role_id', null) ??
      userPreferredShipmentRole
  )

  set(
    newFormState,
    'bookingParties',
    getBookingParties({
      templateData,
      shipmentRoles,
      defaultUserOrganization,
      userPreferredShipmentRoleCode,
    })
  )

  set(
    newFormState,
    'modality',
    get(templateData, 'template.modality', ModalityEnum.Sea)
  )

  set(
    newFormState,
    'loadType',
    get(templateData, 'template.load_type', LoadTypeEnum.fcl)
  )

  set(newFormState, 'customer', getCustomer({ templateData }))

  set(
    newFormState,
    'loadingPort',
    getPort({ templateData, portType: 'origin' })
  )
  set(
    newFormState,
    'dischargePort',
    getPort({ templateData, portType: 'destination' })
  )

  set(newFormState, 'incoterm', get(templateData, 'template.incoterm', null))

  set(
    newFormState,
    'services',
    get(templateData, 'template.services', defaultServiceValues)
  )

  set(newFormState, 'cifValue', get(templateData, 'template.cif_value', null))

  set(
    newFormState,
    'temperatureSetting',
    get(templateData, 'template.temperature_setting', null)
  )

  set(
    newFormState,
    'sellerOrganization',
    get(templateData, 'template.seller_organization', null)
  )

  set(newFormState, 'hsCode.hsCode', getHsCode({ templateData }))

  set(newFormState, 'booking.cargoReadyDate', null)

  set(
    newFormState,
    'references.sharedReference',
    get(templateData, 'template.references.shared_reference', null)
  )

  set(
    newFormState,
    'references.internalReference',
    get(templateData, 'template.references.internal_reference', null)
  )

  set(
    newFormState,
    'tags.dangerousGoods',
    get(templateData, 'template.tags.dangerous_goods', false) ?? false
  )

  set(
    newFormState,
    'pickupAddress',
    getServiceAddress({
      templateData,
      service: 'pickup',
    })
  )

  set(
    newFormState,
    'deliveryAddress',
    getServiceAddress({
      templateData,
      service: 'delivery',
    })
  )

  set(newFormState, 'container', getContainer({ templateData }))

  set(newFormState, 'cargo', getCargo({ templateData }))

  set(
    newFormState,
    'collaborators',
    getOtherCollaborators({
      templateData,
      shipmentRoles,
    })
  )

  return newFormState
}

export const getShipmentRoles = (organizationShipmentRoles) => {
  return flatMap(organizationShipmentRoles, 'shipment_roles').reduce(
    (roleMap: TemplateFormContextProps['shipmentRoles'][], role) => {
      const name = snakeCase(role.name)
      if (!roleMap[name]) {
        roleMap[name] = role.id
      }
      return roleMap
    },
    {}
  )
}

export const getTransformedPartyOptions = (orgData, addressData) => {
  const orgSuggestions =
    orgData?.map((org) => ({
      label: org.name,
      value: org.id,
      type: 'collaborator',
    })) ?? []

  const addressSuggestions =
    addressData?.data?.map((address) => ({
      label: address.name,
      value: address.id,
      type: 'address',
    })) ?? []

  return [...orgSuggestions, ...addressSuggestions]
}

export const getAutocompleteSelectOptions = (selected, fetchedOptions) => {
  if (!fetchedOptions) {
    return null
  }

  return uniqBy([...selected, ...fetchedOptions], 'value')
}

export const getBookingPartiesAreEqual = (formState) => {
  const { bookingParties } = formState

  const { shipper, consignee } = bookingParties

  if (shipper.collaborator && consignee.collaborator) {
    return shipper.collaborator.value === consignee.collaborator.value
  }

  if (shipper.address && consignee.address) {
    return shipper.address.value === consignee.address.value
  }

  return false
}

export const getIncotermServices = ({ incoterms, incoterm, shipmentRole }) => {
  const selectedIncoterm = incoterms?.find(
    (incotermOption) => incotermOption.code === incoterm
  )

  const roleServices = selectedIncoterm?.role_services.find(
    (service) => service.id === shipmentRole
  )

  const services = Object.assign({}, defaultServiceValues)

  if (!roleServices) {
    return services
  }

  const roleServiceTypes = roleServices.services.map(
    (roleService) => roleService.service_type
  )

  for (const service in services) {
    services[service] = roleServiceTypes.includes(service)
  }

  return services
}

export const getLoadTypeToggleOptions = (modality: ModalityEnum) => {
  return [
    {
      label: LoadTypeEnum.fcl.toUpperCase(),
      id: LoadTypeEnum.fcl,
      disabled: modality !== ModalityEnum.Sea,
      icon: <FCLIcon />,
    },
    {
      label: LoadTypeEnum.lcl.toUpperCase(),
      id: LoadTypeEnum.lcl,
      disabled: false,
      icon: <LCLIcon />,
    },
  ]
}

export const getCargoDetailsRequiredFields = (
  loadType: LoadTypeEnum,
  modality: ModalityEnum,
  mode: string
) => {
  const cargoRequiredFields = Object.keys(defaultCargo).map(
    (key) => `cargo.${key}`
  )

  const containerRequiredFields = [
    'container.containerTypeEnum',
    'container.amount',
  ]

  const rebookRequiredFields = [...containerRequiredFields]

  if (mode === 'rebook') {
    if (loadType === LoadTypeEnum.lcl) {
      if (modality === ModalityEnum.Air) {
        return [...cargoRequiredFields, 'temperatureSetting']
      }
      return cargoRequiredFields
    }
    return rebookRequiredFields
  }

  if (loadType === LoadTypeEnum.fcl) {
    return containerRequiredFields
  }

  return []
}

export const getCollaboratorOptions = ({
  organizations,
  organizationShipmentRoles,
  bookingPartyRoles,
  collaborators,
}) => {
  const options: CollaboratorOptionProps[] =
    organizations?.map((organization) => {
      const roleName = organization.role
      const shipmentRoles =
        organizationShipmentRoles?.find(
          (shipmentRole) => shipmentRole.name === roleName
        )?.shipment_roles ?? []

      return {
        roleName,
        id: organization.id,
        shipmentRoleIds: [],
        name: organization.name,
        roleCode: organization.role_code,
        shipmentRoleOptions: shipmentRoles.filter(
          (shipmentRole) => !bookingPartyRoles.includes(shipmentRole.id)
        ),
      }
    }) ?? []

  return options.filter(
    (option) =>
      option.shipmentRoleOptions.length > 0 &&
      !collaborators.map((collaborator) => collaborator.id).includes(option.id)
  )
}

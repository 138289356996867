import { FunctionComponent, useState, useEffect, useRef } from 'react'
import './styles.scss'

interface IProps {
  className?: string
  placeholder?: string
  disabled?: boolean
  label?: string
  value?: string
  name?: string
  showError?: boolean
  errorMessage?: string | null
  onChange?: (fieldName: string | undefined, value: string) => void
  onFocus?: (event) => void
  onBlur?: () => void
  forceFocus?: boolean
}

const Text: FunctionComponent<IProps> = (props) => {
  const [showError, setShowError] = useState<boolean>(true)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (props.onChange) {
      props.onChange(props.name, event.target.value)
    }
  }

  const handleFocus = (event): void => {
    setShowError(false)
    if (props.onFocus) {
      props.onFocus(event)
    }
  }

  const handleBlur = (): void => {
    setShowError(true)
    if (props.onBlur) {
      props.onBlur()
    }
  }

  useEffect(() => {
    setShowError(true)
  }, [props.value])

  useEffect(() => {
    if (props.forceFocus && inputRef && inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  return (
    <div
      className={`text-input-form ${props.className} ${
        props.disabled ? 'disabled' : ''
      } ${props.errorMessage && showError ? 'invalid' : ''}`}
    >
      <div className="input-group">
        <input
          className="text-input-field"
          type="text"
          name={props.name}
          onChange={handleChange}
          disabled={props.disabled}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder=" "
          value={props.value}
          required={true}
          ref={inputRef}
        />
        <span className="highlight" />
        <span className="bar" />
        <label className="text-input-label">{props.label}</label>
      </div>
      <div className="text-input-error">{showError && props.errorMessage}</div>
    </div>
  )
}

Text.defaultProps = {
  disabled: false,
  showError: false,
  className: '',
  onChange: (e) => undefined,
}

export default Text

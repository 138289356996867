// @ts-nocheck
// FIXME

import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { permissionTo } from '../../utils'

import ReportsShipmentsCount from '../../components/ReportsShipmentsCount'
import ReportsShipmentsCosts from '../../components/ReportsShipmentsCosts'

import './styles.scss'

const Reports: FunctionComponent<{}> = () => {
  const { t } = useTranslation()
  return (
    <article className="reports-page">
      <div className="reports-page__header dashboard-header--top">
        <Typography
          variant="h2"
          children={t('reports.main_header', 'Reporting')}
        />
      </div>
      {permissionTo('your_reports.booked_shipments') && (
        <Paper
          classes={{
            root: 'reports-page__content mui-override',
          }}
        >
          <ReportsShipmentsCount />
        </Paper>
      )}
      {permissionTo('your_reports.shipment_costs') && (
        <Paper
          classes={{
            root: 'reports-page__content mui-override',
          }}
        >
          <ReportsShipmentsCosts />
        </Paper>
      )}
    </article>
  )
}
export default Reports

import StripedTable from './StripedTable'
import StripedTableHeader from './StripedTableHeader'
import StripedTableRow from './StripedTableRow'
import StripedTableBody from './StripedTableBody'
import StripedTableCell from './StripedTableCell'
import DropDownRow from './DropDownRow'
import SortingIconsBlock from './SortingIconsBlock'
import './styles.scss'

const Table = {
  StripedTable,
  StripedTableHeader,
  StripedTableRow,
  StripedTableBody,
  StripedTableCell,
  DropDownRow,
  SortingIconsBlock,
}

export default Table

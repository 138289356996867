import { useState, useEffect } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import DraggableListItem from './DraggableListItem'

export type DraggableListProps = {
  items: any[]
  movedItem: (data) => void
  'data-testid'?: string
}

const DraggableList: React.FC<DraggableListProps> = ({
  items,
  movedItem,
  ...props
}) => {
  const [itemsArr, setItemsArr] = useState<any[]>(items)

  useEffect(() => {
    setItemsArr(items)
  }, [items])

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return

    const newItems = reorder(itemsArr, source.index, destination.index)

    setItemsArr(newItems)
    movedItem({ destination, source })
  }

  return (
    <DragDropContext onDragEnd={onDragEnd} data-testid={props['data-testid']}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {itemsArr.map((item, index) => (
              <DraggableListItem
                index={index}
                key={`item-${index}`}
                children={item}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DraggableList

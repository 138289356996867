import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import CustomizedAxisTick from 'src/components/CustomizedAxisTick'
import { includes, pull } from 'lodash'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'
import { CustomTooltip } from './CustomTooltip'
import { CustomLegend } from './CustomLegend'
import { IReportsState } from '.'

interface IRenderChartProps {
  reportsState: IReportsState
  search: (stateChanges?: any) => Promise<void>
  reports: IReportsShipmentsCostsState
}

const toogleClasses = {
  root: 'reports-toggle-group',
}

const toogleButtonClasses = {
  selected: 'reports-toggle-button-selected',
  root: 'reports-toggle-button-root',
}

export const CustomChart: React.FC<IRenderChartProps> = (props) => {
  const { reportsState, search, reports } = props

  const servicesProps = {
    destination_port: { name: 'Destination port', color: '#1bbc9a' },
    duty_tax: {
      name: 'Duties and Tax',
      color: '#00bcd4',
    },
    import_customs: { name: 'Import declaration', color: '#17a085' },
    freight: { name: 'Freight', color: '#1da6ee' },
    delivery: { name: 'Delivery', color: '#e77e23' },
    origin_port: { name: 'Origin port', color: '#c1392c' },
    other: { name: 'Other', color: '#d25300' },
    insurance: { name: 'Insurance', color: '#2e3e4f' },
    export_customs: { name: 'Export declaration', color: '#f39c0f' },
    pickup: { name: 'Pickup', color: '#2b80b9' },
  }

  let keys: string[] = []
  const { legendDimensions } = reportsState
  if (reports.dataset?.length) {
    keys = Object.keys(reports.dataset[0])
    pull(keys, 'name')
  }

  const setSorting = async (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    sortBy: string
  ): Promise<any> => {
    if (includes(['eta', 'etd'], sortBy)) {
      await search({
        filters: {
          ...reportsState.filters,
          orderBy: sortBy,
        },
      })
    }
  }

  const setCurrency = async (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    currency: string
  ): Promise<any> => {
    if (includes(['eur', 'usd'], currency)) {
      await search({
        filters: {
          ...reportsState.filters,
          currency,
        },
      })
    }
  }

  const tickFormatter = (x: string): string => {
    return x.substring(0, 3)
  }

  const isFloat = (n: number | string): boolean => {
    return Number(n) === n && n % 1 !== 0
  }

  const yTickFormatter = (x: number): string => {
    const locale = reportsState.filters.currency === 'usd' ? 'us-US' : 'es-ES'
    return isFloat(x) ? '' : x.toLocaleString(locale)
  }

  return (
    <div data-testId="custom-chart">
      <div className="toggle-sort-head">
        <ToggleButtonGroup
          value={reportsState.filters.currency}
          exclusive={true}
          onChange={setCurrency}
          classes={toogleClasses}
        >
          <ToggleButton value="eur" classes={toogleButtonClasses}>
            €
          </ToggleButton>
          <ToggleButton value="usd" classes={toogleButtonClasses}>
            $
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <BarChart
        width={reportsState.chartDimensions.width}
        height={reportsState.chartDimensions.height}
        data={reports.dataset}
        margin={{ top: 20, right: 30, bottom: 5, left: 10 }}
      >
        <CartesianGrid stroke="#f5f5f5" vertical={false} />
        <XAxis
          stroke="#fff"
          dataKey="name"
          tick={<CustomizedAxisTick />}
          tickFormatter={tickFormatter}
        />
        <YAxis
          stroke="#fff"
          tick={{ fill: '#98a0a5' }}
          tickFormatter={yTickFormatter}
        />
        <Tooltip
          content={(props) => (
            <CustomTooltip
              {...props}
              currency={reportsState.filters.currency}
            />
          )}
          cursor={{ fill: '#e8f7fd' }}
        />
        <Legend
          layout={legendDimensions.layout}
          align={legendDimensions.align}
          verticalAlign={legendDimensions.verticalAlign}
          content={(props) => <CustomLegend {...props} />}
        />
        {keys.map((key: string, index: number) =>
          servicesProps[key] ? (
            <Bar
              key={key}
              dataKey={key}
              isAnimationActive={true}
              fill={servicesProps[key].color}
              name={servicesProps[key].name}
              stackId="a"
            />
          ) : (
            ''
          )
        )}
      </BarChart>
      <div
        className="toggle-sort"
        style={{ width: reportsState.chartDimensions.width }}
      >
        <ToggleButtonGroup
          value={reportsState.filters.orderBy}
          exclusive={true}
          onChange={setSorting}
          classes={toogleClasses}
        >
          <ToggleButton value="etd" classes={toogleButtonClasses}>
            ETD
          </ToggleButton>
          <ToggleButton value="eta" classes={toogleButtonClasses}>
            ETA
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  )
}

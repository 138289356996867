import urlJoin from 'url-join'
import {
  createContext,
  Dispatch,
  SetStateAction,
  Suspense,
  useEffect,
  useState,
} from 'react'
import { useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import { ActionCableProvider } from 'react-actioncable-provider'
import Notification from 'react-web-notification'
import TopNavBar from 'src/components/TopNavigation'
import Footer from 'src/components/Footer'
import ShipmentHeader from 'src/components/ShipmentHeader'
import FilterOrganizationIndicator from 'src/components/FilterOrganizationIndicator'
import PasswordExpirationNotice from 'src/components/PasswordExpirationNotice'
import SelfMentionStyle from 'src/components/Common/SelfMentionStyle'
import Intercom from 'src/components/Intercom'
import Routes from 'src/Routes'
import UserChannelSubscription from 'src/components/SocketHandlers/UserChannelSubscription'
import useHeap from 'src/hooks/useHeap'
import useUserFlow from 'src/hooks/useUserFlow'
import { promisifyAction, Logger } from 'src/utils'
import { userSubscribeToBrowserNotifications } from 'src/stores/actionCreators'
import Loader from './Loader'

import './styles.scss'
import 'src/assets/icons/style.scss'

export const FullScreenContext = createContext<{
  isFullScreen: boolean
  setFullScreen: Dispatch<SetStateAction<boolean>>
}>({ isFullScreen: false, setFullScreen: () => {} })

const Layout = () => {
  const [isFullScreen, setFullScreen] = useState(false)
  const dispatch = useDispatch()
  const location = useLocation()
  const { cable, currentUser } = useSelector((globalState: IGlobalState) => ({
    cable: globalState.user.cable,
    currentUser: globalState.user,
  }))

  const subscribeToBrowserNotifications = promisifyAction(
    dispatch,
    userSubscribeToBrowserNotifications
  )

  const callDelighted = (): void => {
    if (
      currentUser.createdAt &&
      DateTime.local().minus({ days: 90 }) >
        DateTime.fromISO(currentUser.createdAt)
    ) {
      const script = document.createElement('script')
      script.onload = () => {
        window.delighted.survey({
          email: currentUser.email,
          name: `${currentUser.firstName} ${currentUser.lastName}`,
          createdAt: currentUser.createdAt,
          properties: {
            company: currentUser.organizationName,
            user_id: currentUser.id,
          },
        })
      }
      script.src = urlJoin(
        process.env.BASE_URL || '',
        '/analytics/delighted.js'
      )
      document.body.appendChild(script)
    }
  }

  useHeap()
  useUserFlow()

  useEffect(() => {
    callDelighted()
  }, [currentUser.id])

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('update')
    }
  }, [location])

  const shipmentDetailPagePattern = /\/shipments\/\d+/g

  const { pathname } = window.location

  const isSafari = navigator.userAgent.match(/Version\/[\d.]+.*Safari/)

  const isShipmentDetailPage = shipmentDetailPagePattern.test(pathname)

  const enableServiceWorker = (): void => {
    if (!('serviceWorker' in navigator)) {
      return
    }

    Logger.log('Starting register worker')
    navigator.serviceWorker
      .register('/sw.js')
      .then((sw) => {
        Logger.log('Starting subscribing worker')
        sw.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlB64ToUint8Array(
              'BKXFfRP-1cvDT8G7uZpmAoG8WyE9-4t6j7SKlrcy5Iljh7TNX63os-vMSe1eBGfJF-NprTawv3gvMKibUrEnzJE='
            ),
          })
          .then((subscription) => subscribe(subscription))
          .catch((error) => {
            Logger.log('ServiceWorker did not subscribed!', error)
            sw.pushManager.getSubscription().then((subscription) => {
              if (subscription) {
                subscription.unsubscribe()
                enableServiceWorker()
              }
            })
          })
      })
      .catch((error) => Logger.log('ServiceWorker did not registered!', error))
  }

  const subscribe = async (subscription: any): Promise<any> => {
    try {
      await subscribeToBrowserNotifications({ subscription })
    } catch (error) {
      Logger.log(error)
    }
  }

  return (
    <ActionCableProvider cable={cable}>
      <Box className="page-layout__container">
        {' '}
        <Intercom />
        <SelfMentionStyle />
        <TopNavBar />
        <FilterOrganizationIndicator />
        <PasswordExpirationNotice />
        {!isSafari && (
          <Notification
            ignore={true}
            onPermissionGranted={enableServiceWorker}
            title="Shypple!"
          />
        )}
        {isShipmentDetailPage && <ShipmentHeader />}
        <Box
          className={`page-layout__viewport ${
            isFullScreen ? 'page-layout__viewport--fullscreen' : ''
          }`}
        >
          <FullScreenContext.Provider value={{ isFullScreen, setFullScreen }}>
            <Suspense fallback={<Loader />}>
              <section
                className={`page-layout__content ${
                  isShipmentDetailPage ? 'shipment-page' : ''
                }`}
              >
                <Routes />
              </section>
            </Suspense>
          </FullScreenContext.Provider>
        </Box>
        <Footer />
        <UserChannelSubscription userId={currentUser.id} />
      </Box>
    </ActionCableProvider>
  )
}

const urlB64ToUint8Array = (base64String: string): any => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export default Layout

import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/shipmentLayout'
import * as actionCreators from '../../actionCreators/shipmentLayout'
import {
  simpleDeleteAndResolve,
  simpleGetAndResolve,
  simplePatchAndResolve,
} from '../factories'
import { shipmentOverviewSerializer } from '../../../utils/serializers'

const cancelShipment = simpleDeleteAndResolve(
  (action) => `/api/v1/shipments/${action.id}/cancel`,
  actionCreators.shipmentLayoutCancelShipmentSuccess,
  (response) => response.data
)

const acceptQuote = simplePatchAndResolve(
  (action) => `/api/v1/shipments/${action.id}/accept_quote`,
  actionCreators.shipmentLayoutAcceptQuoteSuccess,
  shipmentOverviewSerializer
)

const updateBooking = simplePatchAndResolve(
  (action) =>
    `/api/v1/shipments/${action.id}/toggle_sib_status?status=${action.status}`,
  actionCreators.shipmentLayoutUpdateBookingSuccess,
  shipmentOverviewSerializer
)

const getRemarks = simpleGetAndResolve(
  (action) => `/api/v1/common/cancel_shipment_remarks`,
  actionCreators.shipmentLayoutGetRemarksSuccess,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.SHIPMENT_LAYOUT_CANCEL_SHIPMENT, cancelShipment)
  yield takeEvery(actions.SHIPMENT_LAYOUT_ACCEPT_QUOTE, acceptQuote)
  yield takeEvery(actions.SHIPMENT_LAYOUT_UPDATE_BOOKING, updateBooking)
  yield takeEvery(actions.SHIPMENT_LAYOUT_GET_REMARKS, getRemarks)
}

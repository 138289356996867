import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/bookings'
import * as actionCreators from '../../actionCreators/bookings'
import {
  simpleGetAndResolve,
  simplePutAndResolve,
  simplePostAndResolve,
  simplePatchAndResolve,
} from '../factories'

const getBookings = simpleGetAndResolve(
  (action) => `api/v1/shipments/${action.id}/bookings`,
  actionCreators.bookingsGetDataSuccess,
  (response) => response.data
)

const getCarrierBooking = simpleGetAndResolve(
  (action) => `/api/v1/shipments/${action.id}/carrier_booking`,
  actionCreators.bookingsCarrierBookingGetDataSuccess,
  (response) => response.data
)

const updateBooking = simplePutAndResolve(
  (action) => `api/v1/bookings/${action.id}`,
  actionCreators.updateBookingSuccess,
  (response) => response.data
)

const createBooking = simplePostAndResolve(
  (action) => `api/v1/bookings`,
  actionCreators.createBookingSuccess,
  (response) => response.data
)

const getIncoterms = simpleGetAndResolve(
  () => 'api/v1/common/incoterms/',
  actionCreators.incotermsGetDataSuccess,
  (response) => response.data
)

const assignAddress = simplePatchAndResolve(
  (action) => `/api/v1/bookings/${action.id}/assign_address`,
  actionCreators.bookingAssignSuccess,
  (response) => response.data
)

const assignContact = simplePatchAndResolve(
  (action) => `/api/v1/bookings/${action.id}/assign_contact`,
  actionCreators.bookingAssignSuccess,
  (response) => response.data
)

const updateNotifyPartyAsConsignee = simplePatchAndResolve(
  (action) => `/api/v1/bookings/${action.id}/consignee_as_notify`,
  actionCreators.bookingAssignSuccess,
  (response) => response.data
)

const assignShipperAsCollaborator = simplePostAndResolve(
  (action) => `/api/v1/bookings/${action.id}/assign_shipper_as_collaborator`,
  (response) => response.data
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.BOOKINGS_GET_DATA, getBookings)
  yield takeEvery(actions.BOOKINGS_CARRIER_BOOKING_GET_DATA, getCarrierBooking)
  yield takeEvery(actions.INCOTERMS_GET_DATA, getIncoterms)
  yield takeEvery(actions.UPDATE_BOOKING, updateBooking)
  yield takeEvery(actions.CREATE_BOOKING, createBooking)
  yield takeEvery(actions.BOOKINGS_ASSIGN_ADDRESS, assignAddress)
  yield takeEvery(actions.BOOKINGS_ASSIGN_CONTACT, assignContact)
  yield takeEvery(
    actions.BOOKINGS_UPDATE_NOTIFY_AS_CONSIGNEE,
    updateNotifyPartyAsConsignee
  )
  yield takeEvery(
    actions.ASSIGN_SHIPPER_AS_COLLABORATOR,
    assignShipperAsCollaborator
  )
}

import {
  convertDateToOrderFormat,
  formattedDateNow,
} from 'src/utils/helpers/shipmentDate'
import WarningIconWithTooltip from '../WarningIconWithTooltip'

interface DeliveriesWarningIconTooltipProps {
  shipment: IDeliveriesShipment
}

const DeliveriesWarningIconTooltip: React.FC<DeliveriesWarningIconTooltipProps> = ({
  shipment,
}) => {
  const formattedActualDayOfDemurrage =
    shipment.container_events.actual_first_day_of_demurrage &&
    convertDateToOrderFormat(
      shipment.container_events.actual_first_day_of_demurrage,
      'yyyy-MM-dd'
    )
  const formattedDeliveryDate =
    shipment.delivery_date &&
    convertDateToOrderFormat(shipment.delivery_date, 'yyyy-MM-dd')
  const formattedDestinationDemurrageStartingFrom =
    shipment.destination_demurrage_starting_from &&
    convertDateToOrderFormat(
      shipment.destination_demurrage_starting_from,
      'yyyy-MM-dd'
    )

  const inlandTransportStatus = shipment.inland_transport_status
  const formattedDayInFuture = (day: number) =>
    convertDateToOrderFormat(
      new Date(new Date().setDate(new Date().getDate() + day)).toISOString(),
      'yyyy-MM-dd'
    )

  const formattedCurrentDate = convertDateToOrderFormat(
    formattedDateNow(),
    'yyyy-MM-dd'
  )

  const dateOfDemurrage =
    formattedActualDayOfDemurrage || formattedDestinationDemurrageStartingFrom

  const component = () => {
    if (
      (dateOfDemurrage === formattedDayInFuture(2) &&
        inlandTransportStatus === 'estimated') ||
      (dateOfDemurrage === formattedDayInFuture(1) &&
        inlandTransportStatus === 'estimated')
    ) {
      return (
        <WarningIconWithTooltip
          title="Demurrage costs may arise"
          color="error.dark"
        />
      )
    }

    if (
      dateOfDemurrage &&
      dateOfDemurrage <= formattedCurrentDate &&
      inlandTransportStatus === 'estimated'
    ) {
      return (
        <WarningIconWithTooltip title="Demurrage costs" color="error.dark" />
      )
    }

    if (
      dateOfDemurrage &&
      dateOfDemurrage <= formattedDeliveryDate &&
      inlandTransportStatus === 'confirmed'
    ) {
      return (
        <WarningIconWithTooltip
          title="Delivery planned outside of free days"
          color="warning.700"
        />
      )
    }

    return null
  }
  return component()
}

export default DeliveriesWarningIconTooltip

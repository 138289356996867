import Joi from 'joi'

export const filteredError = (props, name, cargoErrors) => {
  const error = cargoErrors[props.row.id]
  return error
    ? {
        message: error[name],
        id: props.row.id,
      }
    : undefined
}

export const schemaValidation = Joi.object({
  items: Joi.array().items({
    quantity: Joi.number().integer().positive().min(1).required().messages({
      'number.positive': 'Must be greater than 0',
      'number.base': 'Must be greater than 0',
    }),
    package_type_id: Joi.number().integer().positive(),
    length: Joi.number().min(0).max(1000).allow('').messages({
      'number.max': 'The maximum dimensions are 1000 x 255 x 1000',
    }),
    width: Joi.number().min(0).max(255).allow('').messages({
      'number.max': 'The maximum dimensions are 1000 x 255 x 1000',
    }),
    height: Joi.number().min(0).max(1000).allow('').messages({
      'number.max': 'The maximum dimensions are 1000 x 255 x 1000',
    }),
    total_volume_cbm: Joi.number().min(0.00001).required().messages({
      'number.min': 'Must be greater than 0',
      'number.base': 'Must be greater than 0',
      'any.required': 'Must be greater than 0',
    }),
    weight: Joi.number().min(0.00001).required().messages({
      'number.min': 'Must be greater than 0',
      'number.base': 'Must be greater than 0',
      'any.required': 'Cannot be empty',
    }),
    description: Joi.string().min(1).required().messages({
      'string.empty': "Goods description can't be blank",
      'any.required': "Goods description can't be blank",
    }),
    stackable: Joi.boolean().required().messages({
      'any.required': 'Please select stackable',
      'string.base': 'Please select stackable',
      'boolean.base': 'Please select stackable',
    }),
  }),
})

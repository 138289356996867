// @ts-nocheck
// FIXME
import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import bind from 'autobind-decorator'
import { includes } from 'lodash'
import MenuButton from 'src/components/MenuButton'
import Checkbox from 'src/components/Checkbox'

import './styles.scss'

interface IMultiSelectorProps {
  fieldName?: string
  options?: (string | number)[][] | (string | number)[]
  pickedOptionIds?: string[]
  className?: string
  placement?: string
  buttonColor?: string
  buttonColorActive?: string
  title?: string
  onPickOption?: (option: string, fieldName?: string) => void
  onCancel?: (fieldName: string) => void
  onClose?: () => void
  search: boolean
}

interface IMultiSelectorState {
  search: string
}

class MultiSelector extends PureComponent<
  IMultiSelectorProps,
  IMultiSelectorState
> {
  public static propTypes = {
    fieldName: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string.isRequired,
          PropTypes.number.isRequired,
        ])
      ).isRequired
    ),
    pickedOptionIds: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
      ])
    ),
    className: PropTypes.string,
    buttonColor: PropTypes.string,
    buttonColorActive: PropTypes.string,
    onPickOption: PropTypes.func,
    onCancel: PropTypes.func,
    search: PropTypes.bool,
    placeholder: PropTypes.string,
  }

  public static defaultProps = {
    fieldName: '',
    options: [],
    pickedOptionIds: [],
    className: '',
    buttonColor: 'grey-outline',
    buttonColorActive: 'blue-active',
    onPickOption: null,
    onCancel: null,
    search: true,
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      search: '',
    }
  }

  public render() {
    const { buttonColor, buttonColorActive, className } = this.props
    const options = (this.props.options || []).filter(this.matchSearch)
    const pickedOptionIds = this.props.pickedOptionIds || []
    const isActive = pickedOptionIds.length > 0
    return (
      <MenuButton
        className={className}
        buttonColor={buttonColor}
        buttonColorActive={buttonColorActive}
        isActive={isActive}
        title={`${this.props.title} ${
          isActive ? `(${pickedOptionIds.length})` : ''
        }`}
        placement={this.props.placement}
        onCancel={this.cancel}
        onClose={this.close}
      >
        <div>
          <div className="multi-selector__search-container">
            {this.props.search && (
              <input
                className="multi-selector__search"
                placeholder={this.props.placeholder}
                value={this.state.search}
                onChange={this.changeSearch}
              />
            )}
          </div>
          <div className="multi-selector__options">
            {options.map(([title, id]: string[]): any => (
              <div
                className="multi-selector__option"
                key={id}
                onClick={this.pickOption.bind(this, id)}
              >
                <Checkbox
                  className="multi-selector__option-checkbox"
                  checked={includes(pickedOptionIds, id)}
                  color="blue"
                />
                <div className="multi-selector__option-name">{title}</div>
              </div>
            ))}
          </div>
        </div>
      </MenuButton>
    )
  }

  private pickOption(option: string): void {
    if (this.props.onPickOption) {
      this.props.onPickOption(option, this.props.fieldName)
    }
  }

  @bind
  private matchSearch(option: string[]): boolean {
    return (
      !this.state.search ||
      option[0].toLowerCase().includes(this.state.search.toLowerCase())
    )
  }

  @bind
  private changeSearch(event: any): void {
    this.setState({ search: event.currentTarget.value })
  }

  @bind
  private cancel(): void {
    const { fieldName, onCancel } = this.props
    if (onCancel && fieldName) {
      onCancel(fieldName)
    }
  }

  @bind
  private close(): void {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }
}

export default MultiSelector

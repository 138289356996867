import { call, put } from 'redux-saga/effects'
import { Method } from 'axios'
import { apiClient } from 'src/services/http-common'
import { showNotification } from 'src/stores/actionCreators/notifications'
import createActionCable from '../../../actionCable'
import { requester, Logger, shyppleGa } from '../../../utils'
import { userWithCredsSerializer } from '../../../utils/serializers'
import { userSignInSuccess } from '../../actionCreators'
import { rejectAction, resolveAction, storeCredentials } from '../wrappers'

export default function* (action: IPromisifiedAction): Iterator<any> {
  const { email, password } = action.payload
  try {
    const response = yield call([requester, 'request'], {
      method: 'POST' as Method,
      url: 'api/auth/sign_in',
      data: { email, password },
    })
    const user = userWithCredsSerializer(response)
    user.cable = createActionCable(user, response)

    try {
      const { headers } = response || { headers: {} }
      apiClient.defaults.headers['uid'] = headers?.['uid']
      apiClient.defaults.headers['access-token'] = headers['access-token']
      apiClient.defaults.headers['client'] = headers['client']
    } catch (error) {}

    yield put(userSignInSuccess(user))
    yield put(
      showNotification({
        message: 'Signed in successfully.',
        severity: 'success',
      })
    )
    yield storeCredentials(user.uid, user.accessToken, user.clientToken)
    shyppleGa.setUserData(user)
    yield resolveAction(action, user)
  } catch (error) {
    const unknownError: any = error
    yield Logger.sagaError(unknownError, 'store::user::signIn')
    yield rejectAction(action, error)
  }
}

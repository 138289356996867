import get from 'lodash/get'
import set from 'lodash/set'
import Typography from '@mui/material/Typography'
import MuiToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { InputLabel } from '@mui/material'
import { useFormContext } from '../../FormContext'

const ToggleButton = (props) => {
  const {
    fieldPath,
    label,
    required,
    options,
    onFieldChange,
    ...otherProps
  } = props
  const { formState, onChange } = useFormContext()
  const value = get(formState, fieldPath, '')

  const onToggleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    onChange(set(formState, fieldPath, newValue))
    if (onFieldChange) {
      onFieldChange(newValue)
    }
  }

  return (
    <>
      <InputLabel required={required}>{label}</InputLabel>
      <ToggleButtonGroup
        exclusive
        aria-label={label}
        value={value}
        fullWidth
        onChange={onToggleChange}
        {...otherProps}
      >
        {options.map((option) => (
          <MuiToggleButton
            key={option.id}
            value={option.id}
            aria-label={option.label}
            disabled={option.disabled}
          >
            {option.icon}
            <Typography variant="body2" children={option.label} />
          </MuiToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  )
}

export default ToggleButton

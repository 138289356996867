import { OptionsItem } from 'src/stories/DashboardSelect'

export interface OptionsItemWithIconCode extends OptionsItem {
  icon: string
  shortcut?: string
}

export type Mappings = {
  [key in DeliveryTaskType | 'delivery']: OptionsItemWithIconCode[]
}

export const deliveryStatuses: OptionsItemWithIconCode[] = [
  {
    id: 'estimated',
    icon: 'remove',
    color: 'grey',
    name: 'Estimated',
  },
  {
    id: 'requested',
    icon: 'help',
    color: 'orange',
    name: 'Requested',
  },
  {
    id: 'proposed',

    icon: 'help',
    color: 'blue',
    name: 'Proposed',
  },
  {
    id: 'confirmed',
    icon: 'check',
    color: 'green',
    name: 'Confirmed',
  },
]

export const TOP_MARGIN_1 = { xs: 3, sm: 3, md: 3, lg: 0, xl: 0 }
export const TOP_MARGIN_2 = { xs: 5, sm: 5, md: 0, lg: 0, xl: 0 }
export const LEFT_PADDING_1 = { xs: 1, sm: 1, md: 1, lg: 7.5, xl: 8 }
export const LEFT_PADDING_2 = { xs: 1, sm: 1, md: 6, lg: 5, xl: 5 }
export const TOP_PADDING = { xs: 3, sm: 3, md: 3.5, lg: 3.5, xl: 3.5 }
export const DATE_TOOLTIP_TEXT = {
  cargoClosingDate: {
    abbriviation: 'ECC',
    description: 'Estimated Cargo Closing',
  },
  departureDate: {
    abbriviation: 'ETD',
    description: 'Estimated Time of Departure',
  },
  arrivalDate: {
    abbriviation: 'ETA',
    description: 'Estimated Time of Arrival',
  },
}

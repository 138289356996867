import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import * as actions from '../actions/hsCodes'

export const initialHsCodesState = {
  hsCodes: [],
  hsCodesForSearch: [],
  organizationHsCodes: [],
  validatedHsCode: {},
  cachedHsCodes: [],
  hsCodesModal: {
    open: false,
    containerId: null,
    containerType: null,
  },
}

declare global {
  interface IHsCode {
    id: number
    goods_code: string
    description: string
  }

  interface IContainerHsCode {
    id?: number
    goods_code: string
    description: string
    hs_code_id?: number
    notes: string
    _deleted?: boolean
    container_id?: number | null
  }

  interface IHsCodesState {
    hsCodesForSearch: IHsCode[]
    hsCodes: any[]
    organizationHsCodes: IHsCode[]
    validatedHsCode: any
    cachedHsCodes: IHsCode[]
    hsCodesModal: {
      open: boolean
      containerId: number | null
      containerType: string | null
    }
  }

  interface IHsCodeToSubmit {
    id?: number
    notes: string
    hs_code_id: number
  }
}

const hsCodesSerializer = (hsCodes: any[]) => {
  const hsCodesData: any[] = []

  if (hsCodes != null) {
    hsCodes.forEach((parent) => {
      hsCodesData.push({
        type: 'parent',
        text: parent.text,
      })

      if (parent.children != null) {
        parent.children.forEach((children) => {
          hsCodesData.push({
            type: 'children',
            id: children.id,
            text: children.text,
          })
        })
      }
    })
  }

  return hsCodesData
}

const receivedSearchHsCodes: Reducer<IHsCodesState, AnyAction> = (
  state,
  action
) => {
  return {
    ...state,
    hsCodesForSearch: action.payload,
  }
}

const receivedContainerHsCode: Reducer<IHsCodesState, AnyAction> = (
  state,
  action
) => {
  return state
}

const receiveOrganizationHsCodes: Reducer<IHsCodesState, AnyAction> = (
  state,
  action
) => {
  const organizationHsCodes = action.payload
  return {
    ...state,
    organizationHsCodes,
  }
}

const receiveHsCodes: Reducer<IHsCodesState, AnyAction> = (state, action) => {
  let hsCodes: any[] = []

  if (action.payload != null && action.payload.results != null) {
    hsCodes = hsCodesSerializer(action.payload.results)
  }

  return {
    ...state,
    hsCodes,
  }
}

const receiveRelevantHsCodes: Reducer<IHsCodesState, AnyAction> = (
  state,
  action
) => {
  let hsCodes: any[] = []

  if (action.payload != null && action.payload.results != null) {
    hsCodes = hsCodesSerializer(action.payload.results)
  }

  return {
    ...state,
    hsCodes,
  }
}

const clearHsCodesData: Reducer<IHsCodesState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  hsCodes: [],
})

const cacheHsCode: Reducer<IHsCodesState, AnyAction> = (state, action) => ({
  ...state,
  cachedHsCodes: [...state.cachedHsCodes, action.payload],
})
const toggleHsCodeModalOpen: Reducer<IHsCodesState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  hsCodesModal: action.payload,
})

export default createReducer(initialHsCodesState, {
  [actions.SEARCH_HS_CODES_SUCCESS]: receivedSearchHsCodes,
  [actions.ADD_HS_CODE_SUCCESS]: receivedContainerHsCode,
  [actions.EDIT_HS_CODE_SUCCESS]: receivedContainerHsCode,
  [actions.DELETE_HS_CODE_SUCCESS]: receivedContainerHsCode,
  [actions.HS_CODES_GET_DATA_SUCCESS]: receiveHsCodes,
  [actions.ORGANIZATION_HS_CODES_GET_SUCCESS]: receiveOrganizationHsCodes,
  [actions.RELEVANT_HS_CODES_GET_DATA_SUCCESS]: receiveRelevantHsCodes,
  [actions.CLEAR_HS_CODES_DATA]: clearHsCodesData,
  [actions.CACHE_HS_CODE]: cacheHsCode,
  [actions.TOGGLE_HS_MODAL_OPEN]: toggleHsCodeModalOpen,
})

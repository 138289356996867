import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { Link as MuiLink } from '@mui/material'
import { convertDatePickerToUniversalFormat } from '../../../../../utils/helpers'

import './styles.scss'

interface IProps {
  templateName: string
  exceptional: boolean
  templateVariables: IPurchaseOrderTemplateVariables
}

const PurchaseOrderEventTemplate: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const fromPart: string = props.templateVariables.previous_value
    ? ` from ${props.templateVariables.previous_value}`
    : ''

  const renderDateEvent = (eventName: string) => {
    const cargoFromPart: string = props.templateVariables.previous_value
      ? ` from ${convertDatePickerToUniversalFormat(
          props.templateVariables.previous_value
        )}`
      : ''
    return (
      <>
        {`${eventName} updated`}
        {cargoFromPart} to{' '}
        {convertDatePickerToUniversalFormat(
          props.templateVariables.updated_value
        )}
      </>
    )
  }

  const renderExceptionalLabel = (): React.ReactNode => {
    if (!props.exceptional) return null

    return (
      <span className="purchase-order__template__exception">
        <i className="icon attention" />
        {t('purchase_orders.event_template.exception', 'exception')}
      </span>
    )
  }

  const renderTemplate = (): React.ReactNode => {
    switch (props.templateName) {
      case 'purchase_order_created':
        return t(
          'purchase_orders.event_template.order_created',
          'Order created'
        )
      case 'order_date_updated':
        return renderDateEvent(
          t('purchase_orders.event_template.order_date', 'Order date')
        )
      case 'ex_factory_date_updated':
        return renderDateEvent(
          t('purchase_orders.event_template.ex_factory_date', 'EX factory date')
        )
      case 'cargo_ready_date_updated':
        return renderDateEvent(
          t(
            'purchase_orders.event_template.ex_factory_date',
            'Cargo ready date'
          )
        )
      case 'seller_updated':
        return (
          <Trans
            i18nKey="purchase_orders.event_template.seller_updated"
            defaults="Seller updated {{from}} to <0>{{text}}</0>"
            values={{
              text: props.templateVariables.updated_value,
              from: fromPart,
            }}
            components={[
              <MuiLink
                variant="body1"
                component={Link}
                to={`/addresses/${props.templateVariables.seller_address_id}/edit`}
              />,
            ]}
          />
        )
      case 'collaborator_added':
        return (
          <Trans
            i18nKey="purchase_orders.event_template.order_shared"
            defaults="Order shared with collaborator <0>{{text}}</0>"
            values={{ text: props.templateVariables.updated_value }}
            components={[
              <MuiLink
                variant="body1"
                component={Link}
                to={`/contacts/connections/${props.templateVariables.connection_id}`}
              />,
            ]}
          />
        )
      case 'total_purchase_order_volume_updated':
        return (
          <>
            {t(
              'purchase_orders.event_template.volume_updated',
              'Total order volume updated {{from}} to {{to}}',
              { from: fromPart, to: props.templateVariables.updated_value }
            )}
          </>
        )
      case 'total_purchase_order_weight_updated':
        return (
          <>
            {t(
              'purchase_orders.event_template.weight_updated',
              'Total order weight updated {{from}} to {{to}}',
              { from: fromPart, to: props.templateVariables.updated_value }
            )}
          </>
        )
      case 'cargo_ready_date_confirmed':
        return (
          <>
            {t(
              'purchase_orders.event_template.cargo_ready_date_confirmed',
              'Cargo ready date: {{date}} confirmed',
              {
                date: convertDatePickerToUniversalFormat(
                  props.templateVariables.updated_value
                ),
              }
            )}
          </>
        )
      case 'supplier_email_sent_manual':
        return (
          <>
            {t(
              'purchase_orders.event_template.email_triggered',
              'Email triggered by {{by}} to {{to}}',
              {
                by: props.templateVariables.user_full_name,
                to: props.templateVariables.to_email_address,
              }
            )}
          </>
        )
      case 'supplier_email_sent_auto':
        return (
          <>
            <Trans
              i18nKey="purchase_orders.event_template.email_triggered_sla"
              defaults="Email triggered by SLA checkpoint <0>{{text}}</0> to {{to}}"
              values={{
                text: props.templateVariables.updated_value,
                to: props.templateVariables.to_email_address,
              }}
              components={[
                <MuiLink
                  variant="body1"
                  component={Link}
                  to={`/organization_settings/service_level_agreements?open_item=${props.templateVariables.sla_checkpoint_id}`}
                />,
              ]}
            />
          </>
        )
      case 'supplier_email_reminder_sent':
        return (
          <>
            <Trans
              i18nKey="purchase_orders.event_template.reminder_triggered_sla"
              defaults="Reminder triggered by SLA checkpoint <0>{{text}}</0> to {{to}}"
              values={{
                text: props.templateVariables.sla_checkpoint_name,
                to: props.templateVariables.to_email_address,
              }}
              components={[
                <MuiLink
                  variant="body1"
                  component={Link}
                  to={`/organization_settings/service_level_agreements?open_item=${props.templateVariables.sla_checkpoint_id}`}
                />,
              ]}
            />
          </>
        )
      case 'supplier_email_no_response':
        return (
          <>
            <Trans
              i18nKey="purchase_orders.event_template.no_response_sla"
              defaults="No response on SLA checkpoint <0>{{text}}</0> from {{to}}"
              values={{
                text: props.templateVariables.sla_checkpoint_name,
                to: props.templateVariables.to_email_address,
              }}
              components={[
                <MuiLink
                  variant="body1"
                  component={Link}
                  to={`/organization_settings/service_level_agreements?open_item=${props.templateVariables.sla_checkpoint_id}`}
                />,
              ]}
            />
          </>
        )
      case 'sla_checkpoint_reset':
        return (
          <>
            {t(
              'purchase_orders.event_template.checkpoint_reset',
              'Checkpoint for {{name}} has been reset',
              { name: props.templateVariables.sla_checkpoint_name }
            )}
          </>
        )
      default:
        return null
    }
  }

  return (
    <div className="purchase-order__template">
      {renderTemplate()}
      {renderExceptionalLabel()}
    </div>
  )
}

export default PurchaseOrderEventTemplate

import { AnyAction } from 'redux'
import * as actions from '../actions/organizations'

export const organizationsGetData = (payload): AnyAction => ({
  type: actions.ORGANIZATIONS_GET_DATA,
  payload,
})

export const organizationsGetDataSuccess = (
  payload: IOrganization[]
): AnyAction => ({
  type: actions.ORGANIZATIONS_GET_DATA_SUCCESS,
  payload,
})

export const clearOrganizations = (): AnyAction => ({
  type: actions.ORGANIZATIONS_CLEAR_LIST,
})

export const clearPreloadedOrganizations = (): AnyAction => ({
  type: actions.ORGANIZATIONS_CLEAR_PRELOADED,
})

export const organizationGetData = (): AnyAction => ({
  type: actions.ORGANIZATION_GET_DATA,
})

export const organizationGetDataSuccess = (
  payload: IOrganization[]
): AnyAction => ({
  type: actions.ORGANIZATION_GET_DATA_SUCCESS,
  payload,
})

export const organizationGetPreloaded = (payload): AnyAction => ({
  type: actions.ORGANIZATIONS_PRELOAD,
  payload,
})

export const organizationGetPreloadedSuccess = (
  payload: IOrganization[]
): AnyAction => ({
  type: actions.ORGANIZATIONS_PRELOAD_SUCCESS,
  payload,
})

export const organizationUploadLogo = (logo: any): AnyAction => ({
  type: actions.ORGANIZATION_UPLOAD_LOGO,
  logo,
})

export const updateInvoiceAddress = (payload: any): AnyAction => ({
  type: actions.ORGANIZATION_UPDATE_INVOICE_ADDRESS,
  payload,
})

export const updatePreferredForwarder = (payload: any): AnyAction => ({
  type: actions.UPDATE_PREFERRED_FORWARDER,
  payload,
})

export const updatePreferredShipmentRole = (payload: any): AnyAction => ({
  type: actions.UPDATE_PREFERRED_SHIPMENT_ROLE,
  payload,
})

export const organizationGetExportedRatesData = (): AnyAction => ({
  type: actions.ORGANIZATION_GET_EXPORTED_RATES_DATA,
})

export const organizationGetExportedRatesDataSuccess = (
  payload: any
): AnyAction => ({
  type: actions.ORGANIZATION_GET_EXPORTED_RATES_DATA_SUCCESS,
  payload,
})

export const organizationDeleteExportedRatesData = (): AnyAction => ({
  type: actions.ORGANIZATION_DELETE_EXPORTED_RATES_DATA,
})

import { FunctionComponent, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

import { Button, Link } from '@mui/material'
import InlineNotification from 'src/components/Common/InlineNotification'
import ConfirmDialog from '../../ConfirmDialog/index'

import { convertDateToUniversalFormat } from '../../../utils/helpers'

import './styles.scss'

interface IProps {
  quote: ISearchQuote
  currentUserId: number
  collaboratorsRolesSet: boolean
  collaboratorsWarningAction: () => {}
  bookingSubmit: () => {}
}

type DocTypeNames =
  | 'Forwarding Conditions'
  | 'Terms and conditions'
  | 'Visibility terms and conditions'
  | 'Warehousing Condition'

const noDocTypeText = {
  'Forwarding Conditions': 'Dutch Forwarding Conditions by FENEX',
  'Visibility terms and conditions': 'General Terms and Conditions',
  'Terms and conditions': 'General Terms & Conditions',
  'Warehousing Condition': '',
}

const SABTermsConditions: FunctionComponent<IProps> = (props) => {
  const [termsChecked, setTermsChecked] = useState<boolean>(false)
  const [dialogOpened, setDialogOpened] = useState<boolean>(false)

  const allPriceUnknown: boolean = props.quote.estimated_prices
  const allPriceIncomplete: boolean = props.quote.incomplete_prices
  const createdBySupplier: boolean = props.quote.user_id !== props.currentUserId
  const validTo = props.quote.price_details.valid_to
  const { t } = useTranslation()
  const oceanFreightService:
    | IPriceServices
    | undefined = props.quote.price_details.services.find(
    (x) => x.service_name === 'freight'
  )
  const displayLatestEtd: boolean = !!(
    oceanFreightService && !oceanFreightService.price_on_request
  )

  let buttonText: string = ''
  if (createdBySupplier) {
    buttonText = t('search_and_book.place_booking', 'Place booking')
  } else if (
    !allPriceUnknown &&
    !allPriceIncomplete &&
    props.quote.price_details.services.length
  ) {
    buttonText = t('search_and_book.request_booking', 'Request booking')
  } else {
    buttonText = t('search_and_book.request_quote', 'Request quote')
  }

  const requestText = (t) =>
    createdBySupplier
      ? t(
          'search_and_book.initiate_booking',
          'Initiate booking for your customer.'
        )
      : t(
          'search_and_book.request_quote_complete',
          'Request quote and receive the complete quotation.'
        )

  const submit = useCallback(() => {
    if (termsChecked) {
      props.bookingSubmit()
    } else {
      setDialogOpened(true)
    }
  }, [props.bookingSubmit, termsChecked])

  const publicDocuments: IPublicDocument[] = props.quote.seller_organization
    ? props.quote.seller_organization.public_documents
    : []

  const sellerOrganizationName: string = props.quote.seller_organization
    ? props.quote.seller_organization.name
    : 'Shypple'

  const renderPublicDocument = (type: DocTypeNames): React.ReactNode => {
    const document =
      publicDocuments &&
      publicDocuments.length &&
      publicDocuments.find(
        (document: IPublicDocument) => document.document_type.name === type
      )

    if (!document) {
      return <>{noDocTypeText[type]}</>
    }

    if (type === 'Warehousing Condition') {
      return (
        <>
          <span>The </span>
          <Link
            href={document.file_url}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Typography
              display="inline"
              variant="body1"
              className="hyperlink bold"
            >
              Dutch Warehousing Conditions
            </Typography>
          </Link>
          <span>
            , latest version, are applicable to all orders which exclusively
            concern the warehousing of property.
          </span>
        </>
      )
    }

    return (
      <Link href={document.file_url} rel="noopener noreferrer" target="_blank">
        <Typography display="inline" variant="body1" className="hyperlink bold">
          {type === 'Terms and conditions'
            ? `${sellerOrganizationName} ${document.name}`
            : document.name}
        </Typography>
      </Link>
    )
  }

  const conditionsText = () => (
    <>
      I hereby acknowledge and accept that <>{sellerOrganizationName}</> is
      acting as a digital freight forwarder and that for all freight forwarding
      services provided by <>{sellerOrganizationName}</>,{' '}
      <>{renderPublicDocument('Forwarding Conditions')}</>, latest version, and
      the <>{renderPublicDocument('Terms and conditions')}</> shall exclusively
      apply. <>{renderPublicDocument('Warehousing Condition')}</> All
      subcontractors and carriers appointed by Shypple B.V. have limited
      liability. Shypple B.V. recommends that goods are insured.
    </>
  )

  return (
    <Paper className="quote__footer" data-testid="search-book-terms-conditions">
      <div className="quote__footer__notification">
        <InlineNotification
          color="warning"
          show={!props.collaboratorsRolesSet}
          actionName={t(
            'search_and_book.collaborators.add_manage_collaborators',
            'Add/manage collaborators'
          )}
          action={props.collaboratorsWarningAction}
          message="You can't request the booking yet. Please indicate the role of all the shipment collaborators first."
        />
      </div>
      <div className="quote__footer__desc">
        <div className="footer__empty" />
        <div className="footer__accept">
          <div className="footer__accept--container">
            <Typography variant="body1">{conditionsText()}</Typography>
          </div>
        </div>
        <div className="footer__results-booking">
          <div className="footer__booking">
            <FormControlLabel
              className="outline"
              control={
                <Checkbox
                  checked={termsChecked}
                  onChange={setTermsChecked.bind(null, (prev) => !prev)}
                  name="accept-terms"
                  data-testid="terms"
                  color="primary"
                />
              }
              label={
                <Typography variant="body1Strong" className="pr-20">
                  {t('templates.booking_request.accept_terms', 'Accept terms')}
                </Typography>
              }
            />
            <Button
              size="medium"
              color="primary"
              variant="contained"
              data-testid="request-quote-price"
              onClick={submit}
              disabled={!props.collaboratorsRolesSet || !termsChecked}
            >
              {buttonText}
            </Button>
          </div>
          <div className="footer__total-results">
            <div className="total-results__warning">
              <Typography
                variant="subtitle1"
                children={
                  props.quote.schedule.id
                    ? t(
                        'common.notifications.subject_to_space_and_availability',
                        'Schedule is subject to space and availability'
                      )
                    : t(
                        'search_and_book.schedule_is_to_be_advised',
                        'Schedule is to advised'
                      )
                }
              />
            </div>
            <div className="total-results__warning latest-etd">
              {validTo && !allPriceUnknown && displayLatestEtd ? (
                <>
                  <Typography variant="subtitle1">
                    Latest ETD for this rate:{' '}
                    <b>
                      {convertDateToUniversalFormat(
                        props.quote.price_details.valid_to
                      )}
                    </b>
                  </Typography>
                </>
              ) : (
                <Typography variant="subtitle1" children={requestText(t)} />
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        message="Please accept our terms and conditions before you continue."
        isOpen={dialogOpened}
        confirm={setDialogOpened.bind(null, false)}
        onClose={setDialogOpened.bind(null, false)}
        isConfirmOnly={true}
        confirmButtonText="OK"
      />
    </Paper>
  )
}

export default SABTermsConditions

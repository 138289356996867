import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { ToggleButtonGroupProps, ToggleButtonProps } from '@mui/lab'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: theme.palette.grey[200],
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent !important',
      cursor: 'default',
    },
  },
  button: {
    borderColor: theme.palette.grey[200],
    textTransform: 'none',
  },
}))

export interface ToggleButtonsProps extends ToggleButtonGroupProps {
  buttons: ToggleButtonProps[]
  disabled?: boolean
}

export const ButtonGroup: React.FC<ToggleButtonsProps> = ({
  buttons,
  disabled,
  ...props
}) => {
  const classes = useStyles()

  return (
    <>
      <ToggleButtonGroup {...props}>
        {buttons.map((button: ToggleButtonProps, index: number) => (
          <ToggleButton
            key={index}
            value={button.value}
            children={button.children}
            disabled={button.disabled}
            onClick={button.onClick}
            data-testid={button['data-testid']}
            classes={{ root: index === 0 ? classes.root : classes.button }}
          />
        ))}
      </ToggleButtonGroup>
    </>
  )
}

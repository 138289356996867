import { FunctionComponent, memo } from 'react'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Table from '@mui/material/Table'
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { times } from 'lodash'

import TextPlaceholder from 'src/components/Common/TextPlaceholder'

const BlankTable: FunctionComponent<{}> = () => {
  const theme = useTheme()
  const isScreenSmall = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <TableContainer
      className="no-scroll"
      component={(props) => <Paper {...props} square elevation={0} />}
    >
      <Table size={isScreenSmall ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            {times(5, (index) => (
              <TableCell key={index} className="wide">
                <TextPlaceholder size={7} color="light-grey" />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {times(10, (index) => (
            <TableRow key={index}>
              {times(5, (number) => (
                <TableCell key={`${index}_${number}`} className="wide">
                  <TextPlaceholder size={7} color="light-grey" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default memo(BlankTable)

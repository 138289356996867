import { useState, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { stringify } from 'query-string'
import { Trans, useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import SearchIcon from '@mui/icons-material/Search'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { permissionTo } from 'src/utils'
import { delay } from 'lodash'
import { SearchSelectOptionProps } from 'src/components/TopNavigation/TopNavigation.props'
import { searchSelectOptions } from 'src/components/TopNavigation/TopNavigation.constants'
import { useStyles, selectWidth } from './TopNavSelectSearch.styles'

interface TopNavSelectSearchProps {
  inverse?: boolean
  onSearchDone?: () => void
}

const TopNavSelectSearch: React.FC<TopNavSelectSearchProps> = (props) => {
  const { t } = useTranslation()
  const { inverse = false } = props
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const inputRef = useRef<HTMLDivElement>()
  const options = searchSelectOptions(t).filter(
    (option: SearchSelectOptionProps) => {
      const { permissions } = option
      return !permissions || permissionTo(permissions)
    }
  )
  const [search, setSearch] = useState<string>('')
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)
  const [searchBarActive, setSearchBarActive] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState<SearchSelectOptionProps>(
    options[0]
  )
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handlePageSelectClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePageSelectClose = () => {
    setAnchorEl(null)
  }

  const redirectToSearchedPage = (path: string) => {
    const shouldReload: boolean = path.includes(
      location.pathname.replace(/[^a-zA-Z]/g, '')
    )
    history.push(`/${path}&${stringify({ search })}`)
    if (shouldReload) {
      history.go(0)
    } else {
      setSearch('')
      delay(() => {
        setSearchBarActive(false)
      }, 500)

      if (props.onSearchDone) {
        props.onSearchDone()
      }
    }
  }

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (['Enter'].includes(event.key)) {
      redirectToSearchedPage(selectedOption.path)
      if (inputRef && inputRef.current) {
        inputRef.current.blur()
      }
    }
  }

  const handleClickOnSearchOption = (option: SearchSelectOptionProps) => {
    setSelectedOption(option)
    if (!!search) {
      return redirectToSearchedPage(option.path)
    }
    delay(() => {
      if (inputRef && inputRef.current) {
        inputRef.current.focus()
      }
    }, 100)
  }

  const handleOnTooltipOpen = () => {
    if (searchBarActive) {
      return
    }
    setTooltipOpen(true)
  }

  const tooltipTitle = (
    <Box className={classes.navBarTooltip}>
      <Trans
        i18nKey="top_navigation.search.tooltip"
        defaults="Search by: <ul><li>Shipment references</li><li>BL, booking or container numbers</li><li>Pick-up/delivery address</li><li>Port of loading/discharge</li><li>PCarrier or vessel</li></ul>"
        components={{ ul: <ul />, li: <li /> }}
      />
    </Box>
  )

  return (
    <ClickAwayListener
      onClickAway={() => {
        setSearchBarActive(false)
      }}
    >
      <Box
        onClick={() => {
          setSearchBarActive(true)
          setTooltipOpen(false)
        }}
        className={`${classes.navBarSearch} ${
          searchBarActive ? classes.searchBarActive : classes.searchBarInactive
        } ${inverse ? 'inverse ' : ''}`}
        data-testid="top-nav-select-search-box"
      >
        <Tooltip
          enterDelay={500}
          open={tooltipOpen}
          title={tooltipTitle}
          placement="left-start"
          onClose={() => {
            setTooltipOpen(false)
          }}
          onOpen={handleOnTooltipOpen}
          className={classes.navBarSearch}
        >
          <TextField
            value={search}
            color="secondary"
            variant="outlined"
            inputRef={inputRef}
            onChange={(event) => {
              setSearch(event.target.value)
            }}
            placeholder={t('top_navigation.search.placeholder', 'Search')}
            onKeyDown={handleInputKeyDown}
            inputProps={{
              'data-testid': 'top-nav-select-search-input',
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!searchBarActive && <SearchIcon />}
                  {searchBarActive && (
                    <>
                      <Button
                        variant="text"
                        disableRipple={true}
                        aria-haspopup="true"
                        id="page-select-toggler"
                        onClick={handlePageSelectClick}
                        aria-expanded={open ? 'true' : undefined}
                        data-testid="top-nav-select-search-button"
                        aria-controls={open ? 'page-select-menu' : undefined}
                        className={`${classes.menuToggler} ${
                          open ? classes.menuOpened : classes.menuClosed
                        }`}
                      >
                        <Typography children={selectedOption.text} />
                        <KeyboardArrowDownIcon />
                      </Button>
                      <Menu
                        id="page-select-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handlePageSelectClose}
                        MenuListProps={{
                          'aria-labelledby': 'page-select-toggler',
                        }}
                      >
                        {options.map((option) => (
                          <MenuItem
                            key={option.text}
                            sx={{ width: selectWidth }}
                            selected={option.text === selectedOption.text}
                            data-testid={`top-nav-select-search-option-${option.text.toLocaleLowerCase()}`}
                            onClick={() => {
                              handleClickOnSearchOption(option)
                              handlePageSelectClose()
                            }}
                          >
                            {option.text}
                          </MenuItem>
                        ))}
                      </Menu>
                    </>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Tooltip>
      </Box>
    </ClickAwayListener>
  )
}

export default TopNavSelectSearch

import Search from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'
import { EmptyState } from 'src/stories'

const AddressBookEmptySearchResults = () => {
  const { t } = useTranslation()
  return (
    <EmptyState
      title={t('address_book.empty_search_results.title', 'No results found')}
      description={t(
        'address_book.empty_search_results.description',
        'It looks like that you do not have any results that match your search criteria'
      )}
      badgeIcon={Search}
      badgeColor="primary"
    />
  )
}

export default AddressBookEmptySearchResults

import { FunctionComponent, useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { History, LocationState } from 'history'
import TopNavBar from 'src/components/TopNavigation'
import InlineNotification from 'src/components/Common/InlineNotification'
import { useHistory } from 'react-router'
import POTabs from '../../components/PurchaseOrders/POTabs'
import CargoTasks from '../../components/PurchaseOrders/CargoTasks'

import {
  publicPurchaseOrdersGetData,
  publicPurchaseOrdersUpdate,
} from '../../stores/actionCreators'
import {
  convertDatePickerToUniversalFormat,
  convertDateForComparison,
} from '../../utils/helpers'
import { promisifyAction } from '../../utils'
import PublicPOLinesTable from './PublicPOLinesTable'
import PublicPOTable from './PublicPOTable'

import './styles.scss'

interface IProps {
  match: IMatch
  history: History<LocationState>
  location: Location
}

const PublicPOPage: FunctionComponent<IProps> = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [busy, setBusy] = useState<boolean>(false)
  const [ready, setReady] = useState<boolean>(false)
  const [showInlineAlert, setShowInlineAlert] = useState<boolean>(false)
  const [cargoReadyDate, setCargoReadyDate] = useState<string>('')
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [isCargoConfirmed, setIsCargoConfirmed] = useState<boolean>(true)

  const publicPurchaseOrdersGetDataAsync = promisifyAction(
    dispatch,
    publicPurchaseOrdersGetData
  )

  const updatePublicPurchaseOrderAsync = promisifyAction(
    dispatch,
    publicPurchaseOrdersUpdate
  )

  const publicPurchaseOrdersUpdateAsync = promisifyAction(
    dispatch,
    publicPurchaseOrdersUpdate
  )

  const { purchaseOrder } = useSelector((state: IGlobalState) => ({
    purchaseOrder: state.publicPO.purchaseOrder,
  }))

  useEffect(() => {
    const fetchOrderAsync = async () => {
      try {
        await publicPurchaseOrdersGetDataAsync(
          props.match.params.token,
          props.location.search
        )
        setReady(true)
      } catch {
        history.push('/404')
      }
    }
    fetchOrderAsync()
  }, [])

  useEffect(() => {
    setCargoReadyDate(purchaseOrder.cargo_ready_date || '')
  }, [purchaseOrder])

  const POHeader = useMemo(() => {
    return ready
      ? `${purchaseOrder.buyer && purchaseOrder.buyer.name} | PO Number: ${
          purchaseOrder.purchase_order_number
        }`
      : ''
  }, [ready])

  const confirmCargoReadyDate = async (): Promise<any> => {
    setBusy(true)
    const isConfirm: boolean = purchaseOrder.cargo_ready_date === cargoReadyDate
    await publicPurchaseOrdersUpdateAsync(
      props.match.params.token,
      props.location.search,
      {
        cargo_ready_date: cargoReadyDate,
        confirm_cargo_ready_date: isConfirm,
      }
    )
    setIsCargoConfirmed(isConfirm)
    await publicPurchaseOrdersGetDataAsync(
      props.match.params.token,
      props.location.search
    )
    setAlertMessage(
      `Cargo ready date ${
        isCargoConfirmed
          ? 'confirmed'
          : `updated to ${convertDatePickerToUniversalFormat(cargoReadyDate)}`
      }`
    )
    setBusy(false)
    setShowInlineAlert(true)
  }

  const modalWindowFetchData = async (poData: any): Promise<any> => {
    const fetchMessage: string =
      convertDateForComparison(poData.cargo_ready_date) ===
      purchaseOrder.cargo_ready_date
        ? 'You have successfully updated volume & weight'
        : `Cargo ready date updated to ${convertDatePickerToUniversalFormat(
            poData.cargo_ready_date
          )}`

    await publicPurchaseOrdersGetDataAsync(
      props.match.params.token,
      props.location.search
    )
    setAlertMessage(fetchMessage)
    setShowInlineAlert(true)
  }

  const updatePOData = async (poData: any): Promise<any> => {
    await updatePublicPurchaseOrderAsync(
      props.match.params.token,
      props.location.search,
      poData
    )
  }

  return (
    <div>
      <TopNavBar />
      <div className="public-po-page">
        <div className="public-po-page--header">{POHeader}</div>
        <div className="public-po-page--cargo-tasks">
          <InlineNotification
            color={'success'}
            show={showInlineAlert}
            showClose={true}
            message={alertMessage}
            onClose={() => {
              setShowInlineAlert(false)
            }}
          />
          <CargoTasks
            purchaseOrder={purchaseOrder}
            onConfirmCargoReady={confirmCargoReadyDate}
            onModalWindowFetch={modalWindowFetchData}
            purchaseOrdersUpdate={updatePOData}
            editAvailable={true}
            busy={busy}
            ready={ready}
          />
        </div>
        {ready && <PublicPOTable purchaseOrder={purchaseOrder} />}
        {ready && <PublicPOLinesTable purchaseOrder={purchaseOrder} />}
        <div className="public-po-page--events">
          <POTabs purchaseOrder={purchaseOrder} publicPage={true} />
        </div>
      </div>
    </div>
  )
}

export default PublicPOPage

export const SHIPMENT_OVERVIEW_GET_DATA = 'SHIPMENT_OVERVIEW_GET_DATA'
export const SHIPMENT_OVERVIEW_GET_DATA_SUCCESS =
  'SHIPMENT_OVERVIEW_GET_DATA_SUCCESS'
export const UPDATE_SHIPMENT_REFERENCE = 'UPDATE_SHIPMENT_REFERENCE'
export const UPDATE_SHIPMENT_REFERENCE_SUCCESS =
  'SUPDATE_SHIPMENT_REFERENCE_DATA_SUCCESS'
export const SHIPMENT_OVERVIEW_UNPIN_MESSAGE = 'SHIPMENT_OVERVIEW_UNPIN_MESSAGE'
export const SHIPMENT_OVERVIEW_UNPIN_MESSAGE_SUCCESS =
  'SHIPMENT_OVERVIEW_UNPIN_MESSAGE_SUCCESS'
export const SHIPMENTS_TOGGLE_FLAG = 'SHIPMENTS_TOGGLE_FLAG'
export const SHIPMENTS_TOGGLE_FLAG_SUCCESS = 'SHIPMENTS_TOGGLE_FLAG_SUCCESS'
export const SHIPMENTS_TOGGLE_WATCH = 'SHIPMENTS_TOGGLE_WATCH'
export const SHIPMENTS_TOGGLE_WATCH_SUCCESS = 'SHIPMENTS_TOGGLE_WATCH_SUCCESS'

export const SHIPMENTS_OVERVIEW_GET_PDF_FILES =
  'SHIPMENTS_OVERVIEW_GET_PDF_FILES'
export const SHIPMENTS_OVERVIEW_GET_PDF_FILES_SUCCESS =
  'SHIPMENTS_OVERVIEW_GET_PDF_FILES_SUCCESS'

export const CARRIER_BOOKING_GET_DATA = 'CARRIER_BOOKING_GET_DATA'
export const CARRIER_BOOKING_GET_DATA_SUCCESS =
  'CARRIER_BOOKING_GET_DATA_SUCCESS'
export const CARRIER_BOOKING_CREATE_DATA = 'CARRIER_BOOKING_CREATE_DATA'
export const CARRIER_BOOKING_CREATE_DATA_SUCCESS =
  'CARRIER_BOOKING_CREATE_DATA_SUCCESS'
export const CARRIER_BOOKING_UPDATE_DATA = 'CARRIER_BOOKING_UPDATE_DATA'

export const CARRIER_BOOKING_REQUEST_DATA = 'CARRIER_BOOKING_REQUEST_DATA'
export const CARRIER_BOOKING_REQUEST_DATA_SUCCESS =
  'CARRIER_BOOKING_REQUEST_DATA_SUCCESS'

export const SHIPMENT_OVERVIEW_SET_DRAWER = 'SHIPMENT_OVERVIEW_SET_DRAWER'
export const CLEAR_SHIPMENT_OVERVIEW_STATE = 'CLEAR_SHIPMENT_OVERVIEW_STATE'
export const SHIPMENT_RE_QUOTE = 'SHIPMENT_RE_QUOTE'
export const SET_EDIT_SHIPMENT_MODAL_OPEN = 'SET_EDIT_SHIPMENT_MODAL_OPEN'

import { FunctionComponent, ReactNode, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Typography, ButtonBase } from '@mui/material'
import { DateTime } from 'luxon'
import { compact } from 'lodash'
import ProgressWithDays from 'src/components/ProgressWithDays'

import './styles.scss'

const progressColors = {
  in_transit: ['dblue', 'lblue'],
  delivered: ['green', 'green'],
  confirmed_bookings: ['lblue', 'lblue'],
  not_confirmed_bookings: ['grey', 'grey'],
  supplier_initiated_bookings: ['grey', 'grey'],
}

interface IDashboardShipmentLineProps {
  shipment: any
  children?: ReactNode
  link: string
  grey?: boolean
}

const DashboardShipmentLine: FunctionComponent<any> = (
  props: IDashboardShipmentLineProps
) => {
  const { shipment } = props
  const dateEnd = shipment.estimated_arrival
    ? DateTime.fromISO(shipment.estimated_arrival).toFormat('dd MMM')
    : ''
  const dateStart = shipment.estimated_departure
    ? DateTime.fromISO(shipment.estimated_departure).toFormat('dd MMM')
    : ''
  const group = progressColors[shipment.status_group] || ['grey', 'grey']

  const departureIcon = useMemo(() => {
    if (shipment.modality === 'air') {
      return 'arrive '
    } else if (shipment.modality === 'rail') {
      return shipment.modality
    } else {
      return 'loading '
    }
  }, [shipment.modality])

  const destinationIcon = useMemo(() => {
    if (shipment.modality === 'air') {
      return 'depart '
    } else if (shipment.modality === 'rail') {
      return shipment.modality
    } else {
      return 'loading '
    }
  }, [shipment.modality])

  return (
    <div className="dashboard-page__shipment-line">
      <ButtonBase component={Link} to={props.link} sx={{ width: '100%' }}>
        <div className="dashboard-page__shipment-line__info">
          <div className="dashboard-page__shipment-line__info__main">
            <div className="dashboard-page__shipment-line__info__main--title">
              <Typography
                variant="h3"
                children={compact([
                  shipment.reference_number,
                  shipment.shared_reference,
                ]).join(' | ')}
              />
            </div>
            <div>
              <Typography variant="h5" children={shipment.title} />
            </div>
          </div>

          <div className="dashboard-page__shipment-line--ports">
            <div className="dashboard-page__shipment-line--ports--start">
              <div>
                <Typography variant="h3" children={dateStart} />
              </div>
              <div className="dashboard-page__shipment-line--ports-port">
                <Typography variant="body1" children={shipment.loading_port} />
              </div>
            </div>
            <div className="dashboard-page__shipment-line--ports-progress">
              <ProgressWithDays
                departureIcon={departureIcon}
                destinationIcon={destinationIcon}
                primaryColor={group[0]}
                secondaryColor={group[1]}
                progress={shipment.progress_percent}
              />
            </div>
            <div className="dashboard-page__shipment-line--ports--end">
              <div>
                <Typography variant="h3" children={dateEnd} />
              </div>
              <div className="dashboard-page__shipment-line--ports-port">
                <Typography
                  variant="body1"
                  children={shipment.discharge_port}
                />
              </div>
            </div>
          </div>

          <div className="dashboard-page__shipment-line--divider">
            <div className="dashboard-page__shipment-line--divider-line" />
            <div className="dashboard-page__shipment-line--divider-icon">
              <i className="icon info" />
            </div>
            <div className="dashboard-page__shipment-line--divider-line" />
          </div>
        </div>

        <div className="dashboard-page__shipment-line--extras">
          <Typography variant="body1" children={props.children} />
        </div>
        <div className="dashboard-page__shipment-line--icon-container mr-15">
          {shipment.flagged ? <i className="icon flag red" /> : ''}
        </div>
      </ButtonBase>
    </div>
  )
}

export default DashboardShipmentLine

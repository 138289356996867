import { FunctionComponent, useCallback } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'

import Pagination from 'src/components/Common/Pagination'
import { updateReportsDashboardFilters } from 'src/stores/actionCreators'

const ReportsDashboardPagination: FunctionComponent<{}> = () => {
  const dispatch = useDispatch()

  const onChangePage = useCallback((page: number) => {
    dispatch(updateReportsDashboardFilters({ page }))
  }, [])

  const { totalShipmentsCount, perPage, page } = useSelector(
    (state: IGlobalState) => ({
      totalShipmentsCount: state.reportsDashboard.totalShipmentsCount,
      perPage: state.reportsDashboard.filters.per_page,
      page: state.reportsDashboard.filters.page,
    }),
    shallowEqual
  )

  return (
    <Pagination
      onChangePage={onChangePage}
      totalItemsNumber={totalShipmentsCount}
      itemsPerPage={perPage}
      currentPage={page}
    />
  )
}

export default ReportsDashboardPagination

import { Avatar } from 'src/stories/Avatar'
import { useTranslation } from 'react-i18next'
import { SABSearchItemOffererProps } from './SABSearchItem.props'
import SABSearchItemAvatarWithText from './SABSearchItemAvatarWithText'

const SABSearchItemOfferer: React.FC<SABSearchItemOffererProps> = ({
  organizationName,
  organizationRoleCode,
}) => {
  const { t } = useTranslation()
  return (
    <SABSearchItemAvatarWithText
      title={t('common.offered_by', 'Offered by')}
      content={organizationName}
    >
      <Avatar
        {...{
          src: 'no-logo',
          variant: 'circular',
          alt: organizationName,
          className: organizationRoleCode,
        }}
      />
    </SABSearchItemAvatarWithText>
  )
}

export default SABSearchItemOfferer

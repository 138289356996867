import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  IShipmentTemplateResponse,
  IShipmentTemplatesResponse,
  IShipmentTemplatesRequest,
  IDeleteTemplateResponse,
} from 'src/@types/endpoints/shipmentTemplates'
import { queryClient, apiClient } from 'src/services/http-common'
import endpoints, { queryKeys } from '../endpoints'

export const useUpdateShipmentTemplates = (
  templateId: number | string,
  options?: UseMutationOptions<
    IShipmentTemplatesResponse,
    AxiosError,
    IShipmentTemplatesRequest
  >
) => {
  const updateSavedTemplate = (payload: IShipmentTemplatesRequest) => {
    const url = `${endpoints.shipmentTemplates}/${templateId}`

    return apiClient.put<any, IShipmentTemplatesResponse>(
      url,
      JSON.stringify(payload)
    )
  }

  return useMutation(updateSavedTemplate, options)
}

export const useCreateNewShipmentTemplate = (
  options?: UseMutationOptions<
    IShipmentTemplateResponse,
    AxiosError,
    IShipmentTemplatesRequest
  >
) => {
  const createNewShipmentTemplate = (payload: IShipmentTemplatesRequest) => {
    const url = endpoints.shipmentTemplates

    return apiClient.post<any, IShipmentTemplateResponse>(
      url,
      JSON.stringify(payload)
    )
  }

  return useMutation(createNewShipmentTemplate, options)
}

export const useCreateNewShipmentFromTemplate = (
  options?: UseMutationOptions<
    IShipmentTemplateResponse,
    AxiosError,
    IShipmentTemplatesRequest
  >
) => {
  const createNewShipment = (payload: IShipmentTemplatesRequest) => {
    const url = endpoints.shipmentTemplateCreateShipment

    return apiClient.post<any, IShipmentTemplateResponse>(
      url,
      JSON.stringify(payload)
    )
  }

  return useMutation(createNewShipment, options)
}

export const useGetShipmentTemplate = (
  payload: { templateId: number },
  options?: UseQueryOptions<
    IShipmentTemplateResponse,
    AxiosError,
    IShipmentTemplateResponse
  >
) => {
  const url = `${endpoints.shipmentTemplates}/${payload.templateId}`
  const { shipmentTemplates: key } = queryKeys

  const getShipmentTemplate = () =>
    apiClient.get<any, IShipmentTemplateResponse>(url)

  return useQuery<IShipmentTemplateResponse, AxiosError>(
    [key, payload.templateId],
    getShipmentTemplate,
    options
  )
}

export const useGetShipmentTemplates = (
  payload: { limit?: number },
  options?: UseQueryOptions<
    IShipmentTemplatesResponse,
    AxiosError,
    IShipmentTemplatesResponse
  >
) => {
  const limitArg = payload.limit ? `?limit=${payload.limit}` : ''
  const url = `${endpoints.shipmentTemplates}${limitArg}`
  const { shipmentTemplates: key } = queryKeys

  const getShipmentTemplates = () =>
    apiClient.get<any, IShipmentTemplatesResponse>(url)

  return useQuery<IShipmentTemplatesResponse, AxiosError>(
    [key, payload.limit],
    getShipmentTemplates,
    options
  )
}

export const useDeleteShipmentTemplate = (
  options?: UseMutationOptions<
    IDeleteTemplateResponse,
    AxiosError,
    number | null
  >
) => {
  const deleteTemplate = (templateId: number) => {
    const url = `${endpoints.shipmentTemplates}/${templateId}`
    return apiClient.delete<any, IDeleteTemplateResponse>(url)
  }

  return useMutation(deleteTemplate, {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.shipmentTemplates])
    },
  })
}

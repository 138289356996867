import {
  FunctionComponent,
  useState,
  useEffect,
  useMemo,
  Suspense,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, Redirect, useHistory } from 'react-router'
import { Location } from 'history'
import { get, find, compact } from 'lodash'
import joinUrl from 'url-join'
import Tabs from 'src/stories/Tabs'
import {
  scrollToTab,
  isQuotationsAvailable,
} from 'src/pages/ShipmentQuotations/helpers'
import { costs as costsRoute } from 'src/pages/ShipmentLayout/tabRoutes'
import Box from '@mui/material/Box'
import { Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ModalityEnum } from 'src/config/constants'
import UserChannelClient from 'src/components/SocketHandlers/UserChannelClient'
import ShipmentBookings from 'src/pages/ShipmentBookings'
import ShipmentDocuments from 'src/pages/ShipmentDocuments'
import ShipmentPickupAndDelivery from 'src/pages/ShipmentPickupAndDelivery'
import ShipmentPurchaseOrders from 'src/pages/ShipmentPurchaseOrders'
import ShipmentCosts from 'src/pages/ShipmentLayout/ShipmentCosts/index'
import Chats from '../PrivateChat/Chats'
import ShipmentQuotations from '../ShipmentQuotations'
import ShipmentOverview from '../ShipmentOverview'
import InlineNotification from '../../components/Common/InlineNotification'
import ShipmentTimelineSection from '../../components/ShipmentTimeline/ShipmentTimelineSection'
import { promisifyAction, Logger, permissionTo } from '../../utils'
import {
  shipmentOverviewGetData,
  userChannelPerform,
  incotermsGetData,
  shipmentTransactionItemsGetData,
} from '../../stores/actionCreators'
import { getShipmentQuotations } from '../../stores/actionCreators/shipmentQuotations'
import ShipmentTasks from './ShipmentTasks'
import routeToTabName from './routeToTabName'
import { matchDummy, tabMap, initialState } from './constants'
import { getShipmentCostsComponentKey } from './shipmentLayout.utils'
import './styles.scss'

const shipmentCostsComponents = {
  quotations: ShipmentQuotations,
  costs: ShipmentCosts,
}

interface IShipmentLayoutProps {
  match?: IMatch | null
  location: Location
}

interface IChatUnreadData {
  chat_id: number
  chat_type: ChatType
  unread_count: number
}

const ShipmentLayout: FunctionComponent<IShipmentLayoutProps> = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [state, setState] = useState<any>(initialState)
  const [busy, setBusy] = useState<boolean>(true)
  const [tempTabValue, setTempTabValue] = useState<null | string>(null)
  const { t } = useTranslation()

  const {
    counters,
    quotations,
    status,
    tabClicks,
    tabBadges,
    containerTabLabel,
    shipmentModality,
    userOrganizationRole,
    tenantOrganization,
    transactionItems,
  } = useSelector((state: IGlobalState) => ({
    counters: state.shipmentOverview.counters,
    quotations: state.shipmentQuotations.items,
    status: state.shipmentOverview.status,
    containerTabLabel:
      state.shipmentOverview.modality === 'air'
        ? t('common.modality_air.container_plural', 'Cargo')
        : t('common.modality_sea.container_plural', 'Containers'),
    tabClicks: state.shipments.tabClicks,
    tabBadges: state.shipmentOverview.tab_badges,
    shipmentModality: state.shipmentOverview.modality,
    userOrganizationRole: state.user.organizationRole,
    tenantOrganization: state.user.tenantOrganization,
    transactionItems: state.shipmentPrice.transactionItems,
  }))

  const shipmentCostsComponentKey = getShipmentCostsComponentKey({
    modality: shipmentModality,
    organizationRole: userOrganizationRole,
    tenantOrganization,
    status,
    transactionItems,
    isQuotationsAvailable: isQuotationsAvailable(quotations),
  })

  const ShipmentCostsComponent =
    shipmentCostsComponents[shipmentCostsComponentKey]

  const shipmentOverviewGetDataAsync = promisifyAction(
    dispatch,
    shipmentOverviewGetData
  )
  const getIncotermsAsync = promisifyAction(dispatch, incotermsGetData)
  const getShipmentQuotationsAsync = promisifyAction(
    dispatch,
    getShipmentQuotations
  )
  const performUserChannelCommandAsync = promisifyAction(
    dispatch,
    userChannelPerform
  )

  const getShipmentTransactionItemsAsync = promisifyAction(
    dispatch,
    shipmentTransactionItemsGetData
  )

  let inlineNotificationText =
    quotations.length > 1
      ? `You have ${quotations.length} pending quotes.`
      : 'You have a pending quote for this shipment.'

  if (status === 'QUO') {
    inlineNotificationText =
      'We are gathering prices to complete your quotation request.'
  }

  if (status === 'SIB') {
    inlineNotificationText = 'Please confirm you supplier initiated booking.'
  }

  const inlineActionText =
    status === 'QUO' && shipmentModality === ModalityEnum.Sea
      ? ''
      : 'Review quotes'

  useEffect(() => {
    setTempTabValue('tasks')
    setTimeout(() => {
      setTempTabValue(null)
    }, 200)
  }, [tabClicks])

  useEffect(() => {
    const fetchDataAsync = async () => {
      setBusy(true)
      try {
        await shipmentOverviewGetDataAsync(props.match!.params.id)
        if (permissionTo('shipments.accept_quote.all')) {
          await getShipmentQuotationsAsync({
            shipment: { id: props.match!.params.id },
          })
        }
        getIncotermsAsync()
      } catch (error) {
        const statusCode = get(error, 'response.status')
        if (statusCode === 404) {
          history.push({
            pathname: '/dashboard',
          })
        }
        Logger.error(error)
      }
      setBusy(false)
      performUserChannelCommandAsync({
        name: 'get_shipment_comments_count',
        params: {
          shipment_id: props.match!.params.id,
        },
      })
      if (permissionTo('transaction_items.view')) {
        getShipmentTransactionItemsAsync(props.match!.params.id)
      }
    }

    fetchDataAsync()
  }, [props.match!.params.id])

  const match = props.match || matchDummy

  const path = useMemo(() => {
    const hash = window.location.hash.replace('#', '')
    let path = 'bookings'
    switch (hash) {
      case 'shipping-bookings':
        path = 'bookings'
        break
      case 'shipping-instructions':
        path = 'instructions'
        break
      case 'shipment-documentation':
        path = 'documents'
        break
      case 'shipment-price-structure':
        path = 'price-structure'
        break
      case 'shipment-containers':
        path = 'containers'
        break
      case 'conversation':
        path = 'chats'
        break
      case 'orders':
        path = 'orders'
        break
      default:
        path = 'bookings'
        break
    }
    return path
  }, [window.location.hash])

  const handleReceivedNotifications = (response: any): void => {
    Logger.log('UserChannel:', response.message_type, response.message)

    if (
      response.message_type === 'unread_count' &&
      +response.shipment_id === +props.match!.params.id
    ) {
      const privateChat: IChatUnreadData = find(response.message, {
        chat_type: 'private_chat',
      })
      const publicChat: IChatUnreadData = find(response.message, {
        chat_type: 'public_chat',
      })
      const agentsChat: IChatUnreadData = find(response.message, {
        chat_type: 'agents_chat',
      })
      const counters: IShipmentLayoutCounters = {
        ...state.counters,
        chatCounter: 0,
      }

      if (!!privateChat) {
        counters.chatCounter += privateChat.unread_count
      }

      if (!!publicChat) {
        counters.chatCounter += publicChat.unread_count
      }

      if (!!agentsChat) {
        counters.chatCounter += agentsChat.unread_count
      }
      setState({ ...state, counters })
    } else if (
      response.message_type === 'unread_notifications' &&
      !response.message.data.comments.length
    ) {
      setState({
        ...state,
        counters: {
          ...counters,
          privateChat: 0,
        },
      })
    } else if (response.message_type === 'task_resolved') {
      shipmentOverviewGetDataAsync(props.match!.params.id)
    }
  }

  const renderCountBadge = (
    label: string,
    field: string
  ): React.ReactNode | string => {
    const { counters: localCounters } = state
    if (get(localCounters, field) || get(counters, field)) {
      return (
        <span>
          {label}
          <span className="shipment-layout__tab-text__badge">
            {localCounters[field] || counters[field]}
          </span>
        </span>
      )
    } else {
      return label
    }
  }

  const openTasksLabel = (label: string): React.ReactNode => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {label}
        <Box
          sx={{ marginTop: '-2px', backgroundColor: 'info.main' }}
          width={8}
          height={8}
          borderRadius="10px"
        />
      </Box>
    )
  }

  const renderTab = ({
    label,
    value,
    permission,
    extendedPermission,
    badgeFieldName = '',
    badgeFieldPermission = '',
  }) => {
    if (label === 'Containers' && !tabBadges.containers) {
      return { label: containerTabLabel, value }
    }
    if (label === 'Containers' && tabBadges.containers) {
      return { label: openTasksLabel(containerTabLabel), value }
    }
    if (
      (label === 'Bookings' && tabBadges.bookings) ||
      (label === 'Chats' && state.counters.chatCounter)
    ) {
      return { label: openTasksLabel(label), value }
    }

    if (
      !permission ||
      (permission && permissionTo(permission)) ||
      (extendedPermission && permissionTo(extendedPermission))
    ) {
      const canShowBadge: boolean = badgeFieldPermission
        ? permissionTo(badgeFieldPermission)
        : true
      return {
        label:
          badgeFieldName && canShowBadge
            ? renderCountBadge(label, badgeFieldName)
            : label,
        value,
      }
    }
    return null
  }

  const redirectRules = () => {
    switch (true) {
      case permissionTo('shipments.tasks.view'):
        return 'tasks'
      case permissionTo('shipments.bookings.view'):
        return 'bookings'
      default:
        return 'containers'
    }
  }

  const isShipmentAir = shipmentModality === ModalityEnum.Air
  return busy ? null : (
    <>
      <article
        className="shipment-layout timeline-active"
        data-testid="shipment-layout"
      >
        {!isShipmentAir &&
          (isQuotationsAvailable(quotations) ||
            (['QUO', 'PQUO', 'SIB'].includes(status) && (
              <div className="shipment-layout__notifications">
                <InlineNotification
                  show
                  showClose={false}
                  color="info"
                  message={inlineNotificationText}
                  actionName={inlineActionText}
                  action={(event: React.MouseEvent) => {
                    event.preventDefault()
                    scrollToTab(costsRoute)
                  }}
                />
              </div>
            )))}
        <UserChannelClient onReceived={handleReceivedNotifications} />
        <ShipmentOverview shipmentId={props.match!.params.id} />
        <Paper variant="elevation">
          <header className="shipment-layout__navigation">
            <Tabs
              onChange={(path) => {
                const id: string = get(props.match, 'params.id', '0')
                history.push(joinUrl(joinUrl('/shipments/', id), path))
              }}
              value={tempTabValue || routeToTabName(props.location.pathname)}
              tabs={compact(tabMap(t).map(renderTab))}
              variant="scrollable"
            />
          </header>
          <section className="shipment-layout__content">
            <Suspense fallback="">
              <Switch>
                <Route path="/shipments/:id/tasks" component={ShipmentTasks} />
                {permissionTo('shipments.bookings.view') && (
                  <Route
                    path="/shipments/:id/bookings"
                    component={ShipmentBookings}
                  />
                )}
                {permissionTo('shipments.documents.view') && (
                  <Route
                    path="/shipments/:id/documentation"
                    component={ShipmentDocuments}
                  />
                )}
                {permissionTo('shipment.purchase_orders.view') && (
                  <Route
                    path={[
                      '/shipments/:id/orders',
                      '/shipments/:id/purchase-orders',
                    ]}
                    component={ShipmentPurchaseOrders}
                  />
                )}
                {permissionTo('shipments.costs.all') && (
                  <Route
                    path="/shipments/:id/price-structure"
                    component={ShipmentCostsComponent}
                  />
                )}
                <Route
                  path="/shipments/:id/containers"
                  component={ShipmentPickupAndDelivery}
                />
                {permissionTo([
                  'shipments.private_chat.view',
                  'shipments.public_chat.view',
                  'shipments.agents_chat.view',
                ]) && <Route path="/shipments/:id/chats/" component={Chats} />}
                <Redirect
                  from="/shipments/:id/conversations"
                  to={`/shipments/:id/chats`}
                />
                <Redirect
                  from="/shipments/:id/edit"
                  to={`/shipments/:id/${path}`}
                />
                <Redirect to={`${match.url}/${redirectRules()}`} />
              </Switch>
            </Suspense>
          </section>
        </Paper>
      </article>
      <ShipmentTimelineSection shipmentId={props.match!.params.id} />
    </>
  )
}

export default ShipmentLayout

import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import { ShipmentLegs } from './components/ShipmentLegs'
import { ShipmentTitle } from './components/ShipmentTitle'
import { ShipmentStatus } from './components/ShipmentStatus'
import { ShipmentContainers } from './components/ShipmentContainers'
import { ShipmentTwoLineInfo } from './components/ShipmentTwoLineInfo'
import { ShipmentContentProps } from './shipmentContentProps'

const getLayoutObject = (
  orientation: string,
  infoWidth: string,
  maxWidth: string,
  legsWith: string
) => {
  return { orientation, infoWidth, maxWidth, legsWith }
}

const getLayout = (isDirectionRows: boolean) => {
  if (isDirectionRows) {
    return getLayoutObject('vertical', '40%', '100%', '60%')
  }
  return getLayoutObject('horizontal', '100%', '300px', '100%')
}

export const ShipmentContent: React.FC<ShipmentContentProps> = (props) => {
  const {
    id,
    title,
    status,
    statuses,
    modality,
    containers,
    shipment_legs: legs = [],
    shared_reference: shared,
    internal_reference: internal,
  } = props.data
  const {
    updating,
    showLegs = true,
    align = 'center',
    direction = 'row',
    showTitle = true,
    onStatusChange,
    showStatus = true,
    showContainers = true,
    showStatusMenu = false,
    showSharedReference = true,
    showInternalReference = true,
  } = props
  const isDirectionRows = direction === 'row'
  const orientation = isDirectionRows ? 'vertical' : 'horizontal'
  const layout = getLayout(isDirectionRows)
  const statusVisible = status && showStatus
  const statusMenuVisible = !!statuses && showStatusMenu
  const statusVisibleInRow = statusVisible && isDirectionRows
  const titleVisible = title && showTitle
  const containersVisible = containers && showContainers
  const internalRefVisible = internal && showInternalReference
  const sharedRefVisible = shared && showSharedReference
  const { t, i18n } = useTranslation()

  return (
    <Stack
      mx="auto"
      spacing={2}
      direction={direction}
      maxWidth={layout.maxWidth}
      divider={<Divider flexItem orientation={orientation} />}
    >
      {statusVisible && !isDirectionRows && (
        <ShipmentStatus
          align={align}
          status={status}
          statuses={statuses}
          updating={updating}
          onStatusChange={onStatusChange}
          statusMenuVisible={statusMenuVisible}
        />
      )}
      {(titleVisible ||
        containersVisible ||
        internalRefVisible ||
        sharedRefVisible) && (
        <Stack width={layout.infoWidth} spacing={2}>
          {titleVisible && (
            <ShipmentTitle
              id={id}
              align="start"
              title={title}
              modality={modality}
            />
          )}
          {containersVisible && <ShipmentContainers containers={containers} />}
          {internalRefVisible && (
            <ShipmentTwoLineInfo
              content={internal}
              title={
                i18n.exists('shipment_card.internal_reference')
                  ? t('shipment_card.internal_reference')
                  : 'Internal Reference'
              }
            />
          )}
          {sharedRefVisible && (
            <ShipmentTwoLineInfo
              content={shared}
              title={
                i18n.exists('shipment_card.shared_reference')
                  ? t('shipment_card.shared_reference')
                  : 'Shared Reference'
              }
            />
          )}
        </Stack>
      )}
      {(statusVisibleInRow || showLegs) && (
        <Stack spacing={2} overflow="hidden" width={layout.legsWith}>
          {statusVisible && isDirectionRows && (
            <ShipmentStatus
              align={align}
              status={status}
              updating={updating}
              statuses={statuses}
              onStatusChange={onStatusChange}
              statusMenuVisible={statusMenuVisible}
            />
          )}
          {showLegs && <ShipmentLegs align={align} legs={legs} />}
        </Stack>
      )}
    </Stack>
  )
}

import simplur from 'simplur'
import { isNull, lowerCase } from 'lodash'
import { stringify } from 'query-string'
import { ISchedule } from 'src/stores/reducers/schedule'
import { getFormattedPrice } from 'src/utils/helpers/currency'
import { SABSearchItemServiceProps } from './SABSearchItem.props'

export const getTransitTimeText = (
  transitTime: ISchedule['transit_time_days']
) => {
  if (isNull(transitTime)) {
    return '-'
  }
  if (transitTime === 0) {
    return 'Less than a day'
  }
  return simplur`${transitTime} da[y|ys]`
}

export const getQueryString = (
  searchQuery: ISearchQuery,
  quote: ISearchQuote
): string => {
  const { id: scheduleId, schedule_hash: scheduleHash } = quote.schedule
  const sellerOrganizationId = quote.seller_organization?.id
  if (!!scheduleId) {
    searchQuery['schedule_id'] = `${scheduleId}`
  }
  if (!!scheduleHash) {
    searchQuery['schedule_hash'] = scheduleHash
  }
  if (!!sellerOrganizationId) {
    searchQuery['seller_organization_id'] = sellerOrganizationId
  }

  return stringify(searchQuery, {
    arrayFormat: 'bracket',
  })
}

export const getServiceItemPrice = ({
  currency,
  service,
}: SABSearchItemServiceProps) => {
  const subtotal = service[`subtotal_${lowerCase(currency)}`]
  return subtotal ? getFormattedPrice(subtotal, currency) : 'On request'
}

export const getTotalItemPrice = (quote: ISearchQuote, currency: string) => {
  const totalPrice: number =
    quote[`total_price_${currency.toLocaleLowerCase()}`]

  return !quote.incomplete_prices
    ? getFormattedPrice(totalPrice, currency)
    : '-'
}

export const getTimelineNotificationMessage = (t, schedule: ISchedule) => {
  if (schedule.predicted_schedule && schedule.id && !schedule.direct_route) {
    return t(
      'search_and_book.sailing_not_known',
      'The exact sailing is not known yet. These dates are an estimate. We will confirm the ETD & ETA as soon as possible.'
    )
  }
  if (schedule.id) {
    return t(
      'search_and_book.schedule_subject_to',
      'Schedule is subject to space and availability.'
    )
  }
  return t(
    'search_and_book.schedule_is_to_be_advised',
    'Schedule is to be advised.'
  )
}

import { FunctionComponent, useState, useCallback } from 'react'
import Popover from '@mui/material/Popover'
import Box from '@mui/material/Box'
import { useDispatch } from 'react-redux'
import { popupProps } from 'src/components/TopNavigation/TopNavigation.constants'
import UserChannelClient from 'src/components/SocketHandlers/UserChannelClient'
import { getYourOpenTasks } from '../../stores/actionCreators'
import { promisifyAction } from '../../utils'
import TasksContent from './TasksContent'
import TasksButton from './TasksButton'
import { contentWidth } from './constants'

const Tasks: FunctionComponent<{}> = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const isOpen = Boolean(anchorEl)
  const dispatch = useDispatch()

  const getYourOpenTasksAsync = promisifyAction(dispatch, getYourOpenTasks)

  const onReceived = async (data: ISocketMessage) => {
    if (data.message_type === 'task_resolved') {
      await getYourOpenTasksAsync()
    }
  }

  const closeTasksDrawer = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <>
      <UserChannelClient onReceived={onReceived} />
      <TasksButton
        onClick={(event) => {
          setAnchorEl(event.currentTarget as HTMLElement)
        }}
      />
      {isOpen && (
        <Popover
          open
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          PaperProps={{ sx: { overflow: 'hidden' } }}
          {...popupProps}
        >
          <Box height="70vh" display="flex" flexDirection="column">
            <TasksContent
              isOpen={isOpen}
              onClickTask={closeTasksDrawer}
              contentWidth={contentWidth}
            />
          </Box>
        </Popover>
      )}
    </>
  )
}

export default Tasks

import { FunctionComponent, useEffect, useState } from 'react'
import { History, LocationState } from 'history'
import { Link } from 'react-router-dom'
import { Link as MuiLink } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { stringify, parse } from 'query-string'
import { isEmpty, toNumber } from 'lodash'
import BlankState from 'src/components/Common/BlankState'
import { promisifyAction } from '../../utils'
import { ModalityEnum } from '../../config/constants'
import PurchaseOrdersTable from '../../components/PurchaseOrders/PurchaseOrdersTable'
import {
  shipmentPurchaseOrdersGetData,
  purchaseOrdersOpenItem,
} from '../../stores/actionCreators'

import './styles.scss'

interface IProps {
  match: IMatch | null
  history: History<LocationState>
  location: Location
}

const ShipmentBookings: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const match = props.match || { params: { id: 0 } }
  const [busy, setBusy] = useState<boolean>(true)
  const shipmentPurchaseOrdersGetDataAsync = promisifyAction(
    dispatch,
    shipmentPurchaseOrdersGetData
  )

  const {
    openedPurchaseOrderId,
    shipmentPurchaseOrders,
    isBuyer,
    shipmentModality,
  } = useSelector((state: IGlobalState) => ({
    openedPurchaseOrderId: state.purchaseOrders.openItem,
    shipmentPurchaseOrders: state.shipmentPurchaseOrders.list,
    isBuyer: state.user.can_buy,
    shipmentModality: state.shipmentOverview.modality,
  }))

  useEffect(() => {
    const locationSearch = parseLocationSearch()
    if (!isEmpty(locationSearch)) {
      if (locationSearch.open_item) {
        dispatch(purchaseOrdersOpenItem(parseInt(locationSearch.open_item)))
      }
    }
    const fetchOrdersAsync = async () => {
      setBusy(true)
      await shipmentPurchaseOrdersGetDataAsync(match.params.id)
      setBusy(false)
    }
    fetchOrdersAsync()

    return () => {
      dispatch(purchaseOrdersOpenItem(null))
    }
  }, [])

  useEffect(() => {
    if (openedPurchaseOrderId) {
      props.history.push({
        search: stringify(
          { open_item: openedPurchaseOrderId },
          { arrayFormat: 'bracket' }
        ),
      })
    }
  }, [openedPurchaseOrderId])

  const parseLocationSearch = (): any => {
    return parse(props.location.search, {
      arrayFormat: 'bracket',
    })
  }

  const setCurrentPurchaseOrder = async (
    purchaseOrder: IPurchaseOrder
  ): Promise<any> => {}

  const deletePurchaseOrder = () => {}

  const blankStateInfo: React.ReactNode = (
    <>
      <div className="paragraph__medium black strong">
        {t(
          'purchase_orders.shipment.bookings.empty_state',
          'There are no orders assigned to this shipment yet'
        )}
      </div>
      <div className="paragraph__small black">
        <Trans
          i18nKey="purchase_orders.shipment.bookings.link_to_orders"
          components={[
            <MuiLink variant="body1" component={Link} to="/order-management" />,
          ]}
          defaults="Go to your <0>order management</0> to add to this shipment"
        />
      </div>
    </>
  )

  const blankStateIcons = [
    { icon: 'file' },
    { icon: shipmentModality === ModalityEnum.Air ? 'airport' : 'transport-1' },
  ]

  return (
    <div className="shipment-purchase-orders">
      <style
        dangerouslySetInnerHTML={{
          __html: `.shipment-layout__content {
                      padding: 0;
                     }`,
        }}
      />
      <PurchaseOrdersTable
        busy={busy}
        forShipment={true}
        shipmentId={toNumber(match.params.id)}
        setCurrentPurchaseOrder={setCurrentPurchaseOrder}
        deleteOpenedPurchaseOrder={deletePurchaseOrder}
        isBuyer={isBuyer}
      />
      {!shipmentPurchaseOrders.length && (
        <BlankState.Action
          theme="shipmentPO"
          type="link"
          linkBlock={blankStateInfo}
          iconsArray={blankStateIcons}
        />
      )}
    </div>
  )
}

export default ShipmentBookings

import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { ShipmentCostsParty } from './ShipmentCostsParty'

export const ShipmentCostsTransactionItemsSkeleton = () => {
  return (
    <>
      <ShipmentCostsParty name={null} marginTop={1} />
      <Box mt={2} sx={{ height: '100px' }}>
        {[...Array(2)].map((_item, index) => (
          <Skeleton width="100%" key={index}>
            <Typography>.</Typography>
          </Skeleton>
        ))}
      </Box>
    </>
  )
}

import { FunctionComponent, ChangeEvent, useState, useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Typography, Box, Button } from '@mui/material'
import { Dialog, DialogContent } from 'src/stories'
import { TextArea } from 'src/stories/TextArea'
import { Multiselect } from 'src/stories/Multiselect'
import { showNotification } from 'src/stores/actionCreators/notifications'
import Input from 'src/components/Common/Input/MuiInput'
import { promisifyAction } from 'src/utils'
import isEmail from 'validator/lib/isEmail'
import {
  sendTransportEmail,
  toggleTransportWindow,
} from 'src/stores/actionCreators'

const TransportOrderEmailWindow: FunctionComponent<{}> = () => {
  const dispatch = useDispatch()
  const [additionalMessage, setAdditionalMessage] = useState('')
  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true)
  const [documentIds, setDocumentIds] = useState<number[]>([])

  const sendTransportEmailAcync = promisifyAction(dispatch, sendTransportEmail)

  const { singleInlandTransport, isTransportOrderWindowOpen } = useSelector(
    (state: IGlobalState) => ({
      singleInlandTransport: state.pickUpsAndDeliveries.singleInlandTransport,
      isTransportOrderWindowOpen:
        state.pickUpsAndDeliveries.isTransportOrderWindowOpen,
    }),
    shallowEqual
  )

  useEffect(() => {
    return () => {
      setAdditionalMessage('')
      setEmail('')
      setIsEmailValid(true)
      setDocumentIds([])
    }
  }, [isTransportOrderWindowOpen])

  const onSave = async () => {
    try {
      const sendData = {
        id: singleInlandTransport?.id,
        comment: additionalMessage,
        document_ids: documentIds,
        reply_to: email,
      }
      await sendTransportEmailAcync(singleInlandTransport?.id, sendData)
      dispatch(
        showNotification({
          message: 'Transport order was sent.',
          severity: 'success',
        })
      )
      handleClose()
    } catch {}
  }

  const handleClose = () => {
    dispatch(toggleTransportWindow(false))
    setAdditionalMessage('')
    setDocumentIds([])
  }

  const handleChangeArea = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setAdditionalMessage(event.target.value)
  }

  const customOption = (optionProps, option, selected): React.ReactNode => {
    return (
      <li {...optionProps}>
        <Typography
          variant="h3"
          sx={{
            mr: 1.5,
            width: 200,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {option.name}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            width: 250,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {option.document_types.map((type, index) => {
            return ` ${type.name}${
              option.document_types.length - 1 !== index ? ',' : ''
            }`
          })}
        </Typography>
      </li>
    )
  }

  const customChip = (tag): React.ReactNode => {
    return (
      <>
        {tag.name} -{' '}
        {tag.document_types.map((type, index) => {
          return ` ${type.name}${
            tag.document_types.length - 1 !== index ? ',' : ''
          }`
        })}
      </>
    )
  }

  const onEmailBlur = (value) => {
    setIsEmailValid(value ? isEmail(value) : true)
  }

  const onEmailChange = (value: string) => {
    setEmail(value)
  }

  return (
    <Dialog
      open={isTransportOrderWindowOpen}
      onClose={handleClose}
      title="Email transport order"
      actions={
        <>
          <Button variant="text" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" disabled={!isEmailValid} onClick={onSave}>
            Send transport order
          </Button>
        </>
      }
    >
      <DialogContent sx={{ minWidth: 520 }}>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="body1Strong" sx={{ mb: 1 }}>
            This email will be shared with:
          </Typography>
          <Typography variant="body1">
            {singleInlandTransport?.transporter?.inland_transport_email || '-'}
          </Typography>
        </Box>
        <Box sx={{ mt: 3, mb: 2 }}>
          <Typography variant="body1Strong" sx={{ mb: 1 }}>
            Additional message
          </Typography>
          <TextArea
            minRows={10}
            name="cancelshipment"
            onChange={handleChangeArea}
            value={additionalMessage}
            placeholder="You can add an additional message here which will be included in the email."
          />
        </Box>
        <Box sx={{ mt: 3, mb: 2 }}>
          <Typography variant="body1Strong" sx={{ mb: 1 }}>
            Add documents
          </Typography>
          <Multiselect
            name="documents"
            onChange={(options) => {
              setDocumentIds(options as number[])
            }}
            optionLabel="name"
            values={documentIds || []}
            options={singleInlandTransport?.container.documents || []}
            placeholder=""
            disabled={false}
            customOption={customOption}
            customChip={customChip}
          />
        </Box>
        <Box sx={{ mt: 3, mb: 2 }}>
          <Input
            label="Reply-to email address (Optional)"
            value={email}
            error={!isEmailValid}
            helperText={!isEmailValid ? 'invalid email address' : ''}
            onChange={onEmailChange}
            onBlur={onEmailBlur}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default TransportOrderEmailWindow

import { FunctionComponent } from 'react'
import PropTypes from 'prop-types'

import ReactQuoteLoader from '../ReactQuoteLoader'

interface IPhraseLoaderProps {
  type: string
  loading: boolean
}

const makeLoadContent = (sentences: string[]) => {
  const allPhrases: string[] = []
  sentences.forEach((sub: string) => {
    allPhrases.push(sub, `${sub}.`, `${sub}..`)
  })
  return allPhrases
}

const phrases = {
  rates: [
    'Collecting rates.',
    'Extracting best shipping options.',
    'Calculating transit times.',
  ],
  search: [
    'Analyzing dataset.',
    'Collecting available routes.',
    'Scanning database for best rates.',
    'Constructing shipping options.',
  ],
}
const PhraseLoader: FunctionComponent<any> = (props: IPhraseLoaderProps) => {
  if (!props.loading) {
    return <div />
  }
  return (
    <div className="sub__loader">
      <ReactQuoteLoader
        loading={true}
        backgroundColorLoader="transparent"
        backgroundColorQuotes="transparent"
        speed={0.5}
        fontSizeTitle="1.5rem"
        fontFamilyTitle="'Inter', sans-serif"
        quotes={makeLoadContent(phrases[props.type] || [])}
      />
    </div>
  )
}

PhraseLoader.propTypes = {
  type: PropTypes.string,
  loading: PropTypes.bool,
}

export default PhraseLoader

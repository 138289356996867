import { useTranslation } from 'react-i18next'
import { useFormFieldChangeSideEffects } from '../../Form.hooks'
import { modalityToggleOptions } from '../../Form.constants'
import ToggleButton from '../FormElements/ToggleButton'

const Modality = (props) => {
  const { onModalityChange } = useFormFieldChangeSideEffects()

  const { t } = useTranslation()

  return (
    <ToggleButton
      fieldPath="modality"
      label={t('templates.shipment_details.modality', 'Modality')}
      required={true}
      options={modalityToggleOptions}
      onFieldChange={onModalityChange}
      data-testid="shipment-template-modality"
    />
  )
}

export default Modality

import Box from '@mui/material/Box'
import IconField from '../components/IconField'
import CommentField from '../components/CommentField'
import ShipmentField from '../components/ShipmentField'
import PortField from '../components/PortField'
import StatusField from '../components/StatusField'
import GenericDateField from '../components/GenericDateField'

export const bookingsPlacedSchema = {
  icon: {
    field: (shipment: IDashboardBookingsPlacedShipment) => (
      <IconField shipment={shipment} />
    ),
    name: '',
    sortable: false,
    sortValue: '',
    customClassName: 'short-50',
  },
  shipment: {
    field: (shipment: IDashboardBookingsPlacedShipment) => (
      <ShipmentField shipment={shipment} />
    ),
    name: 'SHIPMENT',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned x-medium',
  },
  status: {
    field: (shipment: IDashboardBookingsPlacedShipment) => (
      <StatusField shipment={shipment} />
    ),
    name: 'STATUS',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned x-medium',
  },
  from: {
    field: (shipment: IDashboardBookingsPlacedShipment) => (
      <PortField
        port={shipment.loading_port}
        date={shipment.estimated_departure}
      />
    ),
    name: 'POL',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  to: {
    field: (shipment: IDashboardBookingsPlacedShipment) => (
      <PortField
        port={shipment.discharge_port}
        date={shipment.estimated_arrival}
      />
    ),
    name: 'POD',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned',
  },
  volume: {
    field: (shipment: IDashboardBookingsPlacedShipment) => (
      <Box>{shipment.volume}</Box>
    ),
    name: 'VOLUME',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned s-medium',
  },
  comment: {
    field: (shipment: IDashboardBookingsPlacedShipment) => (
      <CommentField comment={shipment.comment} />
    ),
    name: 'COMMENT',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned x-medium',
  },
  cargoReady: {
    field: (shipment: IDashboardBookingsPlacedShipment) => (
      <GenericDateField date={shipment.cargo_ready_date} />
    ),
    name: 'CARGO READY',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned s-medium',
  },
  created: {
    field: (shipment: IDashboardBookingsPlacedShipment) => (
      <GenericDateField date={shipment.created_at} />
    ),
    name: 'CREATED',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned s-medium',
  },
}

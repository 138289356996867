import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  borderRadius: 8,
  '&.disabled': {
    background: theme.palette.grey[50],
  },
}))

export const StyledBox = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  maxWidth: '50%',
  [theme.breakpoints.down('sm')]: {
    maxWidth: 'auto',
  },
}))

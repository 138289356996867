import { FunctionComponent, useCallback } from 'react'
import { Box, Typography } from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { AutoCompleteSelect } from 'src/stories'
import { useGetCustomersAsync } from 'src/services/Api/organizations'
import { useFormContext } from 'src/components/Templates/Form/FormContext'
import { ReferenceTextArea } from '../ShipmentReferencesModal'
import { permissionTo } from '../../utils'

const ShipmentInfoFields: FunctionComponent = () => {
  const {
    formState,
    formErrors,
    onChange,
    onFormErrorChange,
  } = useFormContext()

  const { t } = useTranslation()

  const { fetchAsync: getCustomers } = useGetCustomersAsync()

  const customerPermission = permissionTo(
    'search_and_book.organization_select.all'
  )

  const onChangeSharedReference = useCallback(
    (event) => {
      const value = event.target.value
      onChange({ shared_reference: value })
      if (value.length > 500) {
        onFormErrorChange({
          shared_reference: t(
            'common.forms.fields.shipment_shared_reference.validation_error.max_characters',
            {
              maxCharacters: 500,
              defaultValue:
                'Your reference cannot have more than {{maxCharacters}} characters',
            }
          ),
        })
      }
    },
    [onChange, onFormErrorChange]
  )

  return (
    <>
      <Box>
        <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
          {t(
            'common.forms.required_fields.helper_text',
            'Fields with an asterisk (*) are required. The rest is optional.'
          )}
        </Typography>
      </Box>
      <Box mt={3}>
        <Box mt={3} sx={{ display: 'flex', alignItems: 'center' }}>
          <Box width={350}>
            <ReferenceTextArea
              isLoading={false}
              name="shared_reference"
              value={formState.shared_reference}
              onChange={onChangeSharedReference}
              placeholder={t(
                'common.forms.fields.shipment_shared_reference.placeholder',
                'Shared reference'
              )}
              label={t(
                'common.forms.fields.shipment_shared_reference.label',
                'Shared reference'
              )}
              error={formErrors['shared_reference']}
              collaborators={[]}
            />
          </Box>
          {customerPermission && (
            <Box width={350} ml={2}>
              <AutoCompleteSelect
                id="customer"
                label={t('common.forms.fields.customer.label', 'Customer')}
                required
                getData={(search) => getCustomers({ search }) as Promise<any>}
                onChange={(newValue) => {
                  onChange({ customer: newValue })
                }}
                value={formState.customer}
                placeholder={t(
                  'common.forms.fields.customer.placeholder',
                  'Customer'
                )}
                popupIcon={<KeyboardArrowDown />}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}
export default ShipmentInfoFields

import { round } from 'lodash'

export const getDimensionValue = (
  packageType: IPackageType | undefined,
  key: string
) => {
  if (!packageType || !packageType[key]) {
    return 0
  }
  return packageType[key] / 10
}

export const selectedType = (value: any, packageTypes: IPackageType[]) =>
  packageTypes?.find((type) => {
    return type.id === value
  })

export const countVolume = (width, height, length, quantity) =>
  round((width * height * length * quantity) / 1000000, 2)

export const tabList: ITabProps[] = [
  {
    label: 'Booked',
    value: 'booked',
    permissions: [],
  },
  {
    label: 'In transit',
    value: 'in_transit',
    permissions: [],
  },
  {
    label: 'Delivered',
    value: 'delivered',
    permissions: [],
  },
  {
    label: 'All shipments',
    value: 'all',
    permissions: [],
  },
  {
    label: 'Flagged',
    value: 'flagged',
    permissions: [],
  },
  {
    label: 'Cancelled',
    value: 'cancelled',
    permissions: [],
  },
]

export const sortingOptions = [
  { value: 'eta_asc', icon: 'sort-arrow' },
  { value: 'eta_desc', icon: 'sort-arrow down' },
  { value: 'etd_asc', icon: 'sort-arrow' },
  { value: 'etd_desc', icon: 'sort-arrow down' },
  { value: 'newest', icon: '' },
]

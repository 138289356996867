import { ReactNode, FunctionComponent } from 'react'
import './styles.scss'

interface IProps {
  children: ReactNode
}

const FullWidth: FunctionComponent<IProps> = (props) => (
  <div className="form-fullWidth">{props.children}</div>
)

export default FullWidth

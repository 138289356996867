import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  ICareerRequest,
  ICareerResponse,
  IAddressesResponse,
  IAddressesRequest,
  IIncotermsResponse,
  IProductGroupsResponse,
  IConnectionsResponse,
  IConnectionsRequest,
  IPlaceSuggestionResponse,
  IStatusTypesResponse,
  IOrganizationShipmentRolesResponse,
  IPackageTypesResponse,
  IContainerTypesResponse,
  IShipmentRolesSuggestionResponse,
  IShipmentRolesSuggestionRequest,
  IShipmentStatusResponse,
} from 'src/@types/endpoints/common'
import { endpoints, queryKeys } from 'src/services/endpoints'
import { apiClient } from 'src/services/http-common'
import { useQueryAsync } from '../hooks'
import { createQueryString } from '../utils'

export const useGetContainerTypes = (
  options: UseQueryOptions<
    IContainerTypesResponse,
    AxiosError,
    IContainerTypesResponse
  > = {}
) => {
  const url = endpoints.commonContainerTypes
  const { commonContainerTypes: key } = queryKeys

  const getContainerTypes = () =>
    apiClient.get<any, IContainerTypesResponse>(url)

  return useQuery<IContainerTypesResponse, AxiosError>(
    [key],
    getContainerTypes,
    options
  )
}

export const useGetPackageTypes = (
  options: UseQueryOptions<
    IPackageTypesResponse,
    AxiosError,
    IPackageTypesResponse
  > = {}
) => {
  const url = endpoints.commonPackageTypes
  const { commonPackageTypes: key } = queryKeys

  const getPackageTypes = () => apiClient.get<any, IPackageTypesResponse>(url)

  return useQuery<IPackageTypesResponse, AxiosError>(
    [key],
    getPackageTypes,
    options
  )
}

export const useGetStatusTypes = (
  options: UseQueryOptions<
    IStatusTypesResponse,
    AxiosError,
    IStatusTypesResponse
  > = {}
) => {
  const url = endpoints.commonTaskStatusTypes
  const { commonTaskStatusTypes: key } = queryKeys

  const getStatusTypes = () => apiClient.get<any, IStatusTypesResponse>(url)

  return useQuery<IStatusTypesResponse, AxiosError>(
    [key],
    getStatusTypes,
    options
  )
}

export const useGetPorts = (
  payload: object = {},
  options: UseQueryOptions<
    IPlaceSuggestionResponse,
    AxiosError,
    IPlaceSuggestionResponse
  > = {}
) => {
  const queryString = createQueryString(
    { ...payload, only_ports: true },
    { skipEmptyString: false }
  )
  const url = endpoints.commonAutoComplete + queryString
  const { commonAutoComplete: key } = queryKeys

  const getPorts = () => apiClient.get<any, IPlaceSuggestionResponse>(url)

  return useQuery<IPlaceSuggestionResponse, AxiosError>(
    [key, queryString],
    getPorts,
    options
  )
}

export const useGetCarriersAsync = (
  options?: UseQueryOptions<ICareerResponse, AxiosError, ICareerResponse>
) => {
  const { commonCarriers: key } = queryKeys

  const getCarriers = (payload: ICareerRequest) => {
    const queryString = createQueryString(payload)
    const url = endpoints.commonCarriers + queryString

    return apiClient.get<any, ICareerResponse>(url)
  }

  return useQueryAsync([key], getCarriers, options)
}

export const useGetAutocompleteAsync = (
  options?: UseQueryOptions<
    IPlaceSuggestionResponse,
    AxiosError,
    IPlaceSuggestionResponse
  >
) => {
  const { commonAutoComplete: key } = queryKeys

  const getAutoComplete = (payload: object = {}) => {
    const queryString = createQueryString(payload)
    const url = endpoints.commonAutoComplete + queryString

    return apiClient.get<any, IPlaceSuggestionResponse>(url)
  }

  return useQueryAsync([key], getAutoComplete, options)
}

export const useGetAddressesAsync = (
  options?: UseQueryOptions<IAddressesResponse, AxiosError, IAddressesResponse>
) => {
  const { commonAddresses: key } = queryKeys

  const getAddresses = (payload: IAddressesRequest) => {
    const queryString = createQueryString(payload)
    const url = endpoints.commonAddresses + queryString

    return apiClient.get<any, IAddressesResponse>(url)
  }

  return useQueryAsync([key], getAddresses, options)
}

export const useGetConnectionsAsync = (
  options?: UseQueryOptions<
    IConnectionsResponse,
    AxiosError,
    IConnectionsResponse
  >
) => {
  const { commonConnections: key } = queryKeys

  const getConnections = (payload: IConnectionsRequest) => {
    const queryString = createQueryString(payload)
    const url = endpoints.commonConnections + queryString

    return apiClient.get<any, IConnectionsResponse>(url)
  }

  return useQueryAsync([key], getConnections, options)
}

export const useGetOrganizationShipmentRoles = (
  options?: UseQueryOptions<
    IOrganizationShipmentRolesResponse,
    AxiosError,
    IOrganizationShipmentRolesResponse
  >
) => {
  const { commonOrganizationShipmentRoles: key } = queryKeys

  const getOrganizationShipmentRoles = () => {
    const url = endpoints.commonOrganizationShipmentRoles

    return apiClient.get<any, IOrganizationShipmentRolesResponse>(url)
  }

  return useQuery<IOrganizationShipmentRolesResponse, AxiosError>(
    [key],
    getOrganizationShipmentRoles,
    options
  )
}

export const useGetIncoterms = (
  options?: UseQueryOptions<IIncotermsResponse, AxiosError, IIncotermsResponse>
) => {
  const { commonIncoterms: key } = queryKeys

  const getIncoterms = () => {
    const url = endpoints.commonIncoterms

    return apiClient.get<any, IIncotermsResponse>(url)
  }

  return useQuery<IIncotermsResponse, AxiosError>([key], getIncoterms, options)
}

export const useGetProductGroups = (
  options?: UseQueryOptions<
    IProductGroupsResponse,
    AxiosError,
    IProductGroupsResponse
  >
) => {
  const { commonProductGroups: key } = queryKeys

  const getProductGroups = () => {
    const url = endpoints.commonProductGroups

    return apiClient.get<any, IProductGroupsResponse>(url)
  }

  return useQuery<IProductGroupsResponse, AxiosError>(
    [key],
    getProductGroups,
    options
  )
}

export const useGetShipmentStatuses = (
  options?: UseQueryOptions<
    IShipmentStatusResponse[],
    AxiosError,
    IShipmentStatusResponse[]
  >
) => {
  const { commonShipmentStatuses: key } = queryKeys

  const getShipmentStatuses = () => {
    const url = endpoints.commonShipmentStatuses

    return apiClient.get<any, IShipmentStatusResponse[]>(url)
  }

  return useQuery<IShipmentStatusResponse[], AxiosError>(
    [key],
    getShipmentStatuses,
    options
  )
}

export const useGetShipmentRolesSuggestion = (
  options?: UseQueryOptions<
    IShipmentRolesSuggestionResponse,
    AxiosError,
    IShipmentRolesSuggestionResponse
  >
) => {
  const { commonShipmentRolesSuggestion: key } = queryKeys

  const getShipmentRolesSuggestion = (
    payload: IShipmentRolesSuggestionRequest
  ) => {
    const queryString = createQueryString(payload)
    const url = endpoints.commonShipmentRolesSuggestion + queryString

    return apiClient.get<any, IShipmentRolesSuggestionResponse>(url)
  }

  return useQueryAsync([key], getShipmentRolesSuggestion, options)
}

import { FunctionComponent, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import CreateRoundedIcon from '@mui/icons-material/CreateRounded'
import { Button } from '@mui/material'
import PublicPOModal from '../../../pages/PublicPOPage/PublicPOModal'
import { convertDatePickerToUniversalFormat } from '../../../utils/helpers'

import './styles.scss'

interface IProps {
  purchaseOrder: IPurchaseOrder
  onConfirmCargoReady: () => void
  onModalWindowFetch: (poData: any) => void
  purchaseOrdersUpdate: (poData: any) => void
  editAvailable: boolean
  busy?: boolean
  ready?: boolean
}

const CargoTasks: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const [cargoReadyDate, setCargoReadyDate] = useState<string>('')
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  useEffect(() => {
    setCargoReadyDate(props.purchaseOrder.cargo_ready_date || '')
  }, [props.purchaseOrder])

  return (
    <>
      <div className="cargo-tasks">
        <div className="cargo-tasks--tasks-block">
          <div className="block-name">Tasks</div>
          <div className="cargo-tasks--cargo-ready-date-block top">
            <div className="cargo-tasks--cargo-ready-date-block--welcome">
              <i className="icon big-check" />
              {t(
                'purchase_orders.table.cargo_tasks.cargo_ready_date',
                'Update or confirm the cargo ready date:'
              )}
            </div>
            <div className="field-block">
              {convertDatePickerToUniversalFormat(cargoReadyDate) || '-'}
            </div>
            {props.editAvailable && (
              <>
                <Button
                  onClick={() => {
                    setIsModalOpen(true)
                  }}
                  startIcon={<CreateRoundedIcon />}
                  children={t(
                    'purchase_orders.table.cargo_tasks.edit_button',
                    'Edit'
                  )}
                />
                <Button
                  startIcon={<CheckRoundedIcon />}
                  onClick={props.onConfirmCargoReady}
                  disabled={props.busy || !cargoReadyDate}
                  children={t(
                    'purchase_orders.table.cargo_tasks.confirm_button',
                    'Confirm'
                  )}
                />
              </>
            )}
          </div>

          <div className="cargo-tasks--cargo-ready-date-block">
            <div className="cargo-tasks--cargo-ready-date-block--welcome">
              <i className="icon big-check" />
              {t(
                'purchase_orders.table.cargo_tasks.cargo_ready_date',
                'Update volume & weight:'
              )}
            </div>
            <div className="field-block">
              <div className="totals">
                {parseFloat(props.purchaseOrder.total_volume_cbm)
                  ? `${props.purchaseOrder.total_volume_cbm}m3`
                  : '-'}
              </div>
              <div className="totals">
                {parseFloat(props.purchaseOrder.total_weight_kg)
                  ? `${props.purchaseOrder.total_weight_kg}kg`
                  : '-'}
              </div>
            </div>
            {props.editAvailable && (
              <Button
                onClick={() => {
                  setIsModalOpen(true)
                }}
                startIcon={<CreateRoundedIcon />}
                children={t(
                  'purchase_orders.table.cargo_tasks.edit_button',
                  'Edit'
                )}
              />
            )}
          </div>
        </div>
      </div>
      {props.ready && (
        <PublicPOModal
          open={isModalOpen}
          close={() => {
            setIsModalOpen(false)
          }}
          fetchData={props.onModalWindowFetch}
          purchaseOrdersUpdate={props.purchaseOrdersUpdate}
          purchaseOrder={props.purchaseOrder}
        />
      )}
    </>
  )
}

export default CargoTasks

import { permissionTo } from 'src/utils'
import Box from '@mui/material/Box'
import TasksButton from 'src/components/Tasks/TasksButton'
import TopNavSelectSearch from 'src/components/TopNavBar/TopNavSelectSearch'
import NotificationsButton from 'src/components/Notifications/NotificationsButton'
import { StyledDrawerSearchAction } from 'src/components/TopNavigation/TopNavigation.styles'
import {
  drawerIconButtonProps,
  tasksPermissions,
} from 'src/components/TopNavigation/TopNavigation.constants'
import TopNavigationKnowledgedBaseLink from '../TopNavigationKnowledgedBaseLink'

const TopNavigationDrawerSearch = ({
  tasksOpen,
  onSearchDone,
  onTasksClick,
  notificationsOpen,
  onNotificationsClick,
}) => {
  return (
    <Box pt={1.5} px={1.5}>
      <Box sx={{ position: 'relative' }}>
        <StyledDrawerSearchAction spacing={0.25} direction="row">
          {permissionTo(tasksPermissions) && (
            <TasksButton
              onClick={onTasksClick}
              buttonProps={{
                ...drawerIconButtonProps,
                className: tasksOpen ? 'active' : '',
              }}
              iconProps={{ fontSize: 'small' }}
            />
          )}
          <NotificationsButton
            onClick={onNotificationsClick}
            buttonProps={{
              ...drawerIconButtonProps,
              className: notificationsOpen ? 'active' : '',
            }}
            iconProps={{ fontSize: 'small' }}
          />
          <TopNavigationKnowledgedBaseLink
            iconSize="small"
            buttonSize="large"
            iconColor="primary.main"
          />
        </StyledDrawerSearchAction>
      </Box>
      <TopNavSelectSearch inverse onSearchDone={onSearchDone} />
    </Box>
  )
}

export default TopNavigationDrawerSearch

import { useTranslation } from 'react-i18next'
import { FunctionComponent, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import MuiInput from 'src/components/Common/Input/MuiInput'
import { promisifyAction } from 'src/utils'
import { initialFilters } from 'src/stores/reducers/documents'
import SearchIcon from '@mui/icons-material/Search'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import { makeStyles } from '@mui/styles'
import { isEqual, isEmpty } from 'lodash'

import { Paper, Box, Button, InputLabel, Stack, Divider } from '@mui/material'

import { documentsUpdateFilters } from 'src/stores/actionCreators'
import { FormMultipleAutocomplete } from 'src/components/Filters/FormElements/FormMultipleAutocomplete'
import FormLabel from 'src/stories/Lab/FormLabel'

const useStyles = makeStyles((theme) => ({
  searchIcon: {
    color: theme.palette.grey[400],
  },
  searchBox: {
    paddingBottom: '1rem',
    '@media screen and (min-width: 768px)': {
      paddingRight: '1rem',
    },
  },
}))

const invoicesDocTypes = (t) => [
  t('documents.filters.commertial_invoice', 'Commercial invoice'),
  t(
    'documents.filters.commertial_invoice_proforma',
    'Commercial invoice (Proforma)'
  ),
  t('documents.filters.invoice_duties_vat', 'Financial invoice duties vat'),
  t(
    'documents.filters.invoice_transportation',
    'Financial invoice transportation'
  ),
]

const DocumentFilters: FunctionComponent = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const documentsUpdateFiltersAsync = promisifyAction(
    dispatch,
    documentsUpdateFilters
  )

  const { documentTypes, uploadedBy, filters } = useSelector(
    (state: IGlobalState) => ({
      documentTypes: state.documents.filters_data.document_types,
      uploadedBy: state.documents.filters_data.uploaded_by,
      filters: state.documents.filters,
    })
  )

  const invoicesIds = useMemo(
    () =>
      documentTypes
        .filter((docType) => invoicesDocTypes(t).includes(docType.name))
        .map((docType) => `${docType.id}`),
    [documentTypes]
  )

  const invoicesOnly = useMemo(() => {
    return (
      filters.document_types_ids.length &&
      invoicesIds.length &&
      invoicesIds.every((id) => filters.document_types_ids.includes(id))
    )
  }, [filters, invoicesIds])

  const resetFilters = () => {
    documentsUpdateFiltersAsync(initialFilters)
  }

  const handleSearchChange = (value: string) => {
    documentsUpdateFiltersAsync({ search: value })
  }

  const updateFilters = (newFilters: IDocuments['filters']) => {
    documentsUpdateFiltersAsync({
      ...filters,
      ...newFilters,
    })
  }

  const activeFiltersCounter = (): string => {
    let counter: number =
      Number(!!filters.search) +
      Number(!isEmpty(filters.document_types_ids)) +
      Number(!isEmpty(filters.uploaded_by_ids))

    return counter ? ` (${counter})` : ''
  }

  const showInvoicesOnly = () => {
    documentsUpdateFiltersAsync({
      document_types_ids: invoicesOnly ? [] : invoicesIds,
    })
  }

  const documentTypesOptions = documentTypes.map((item) => {
    return {
      id: `${item.id}`,
      label: item.name as string,
    }
  })

  const uploadedByOptions = uploadedBy.map((item) => {
    return { label: item.name, id: item.id }
  })

  return (
    <Paper variant="elevation" sx={{ marginTop: 1 }}>
      <Stack
        mx="auto"
        direction="row"
        spacing={2}
        divider={<Divider flexItem orientation="vertical" />}
        sx={{
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            marginBottom: 2,
            '@media screen and (min-width: 1050px)': {
              display: 'flex',
              alignItems: 'center',
            },
            padding: '16px 16px 0px 16px',
            width: '100%',
          }}
        >
          <Box className={classes.searchBox} sx={{ width: '300px' }}>
            <FormLabel
              label={t('documents.filters.search.label', 'Search documents')}
            />
            <MuiInput
              name="search"
              endAdornment={<SearchIcon className={classes.searchIcon} />}
              value={filters.search}
              onChange={handleSearchChange}
              placeholder={t(
                'documents.filters.search.placeholder',
                'Search by SF number, reference...'
              )}
              debouncevalue={600}
              data-testid="documents-search-input"
            />
          </Box>
          <Box className={classes.searchBox}>
            <FormMultipleAutocomplete
              options={documentTypesOptions}
              qs={filters}
              name="document_types_ids"
              onChange={updateFilters}
              placeholder={t(
                'documents.filters.type.placeholder',
                'Filter by typ'
              )}
              label={t('documents.filters.type.label', 'Document type')}
              data-testid="documents-filters-document-types"
            />
          </Box>
          <Box className={classes.searchBox}>
            <FormMultipleAutocomplete
              options={uploadedByOptions}
              qs={filters}
              name="uploaded_by_ids"
              onChange={updateFilters}
              placeholder={t(
                'documents.filters.org.placeholder',
                'Filter by organization'
              )}
              label={t('documents.filters.org.label', 'Uploaded by')}
              data-testid="documents-filters-uploaded-by"
            />
          </Box>
          <Button
            variant="text"
            onClick={resetFilters}
            disabled={isEqual(filters, initialFilters)}
            startIcon={<CloseRoundedIcon />}
            sx={{ my: 1.5 }}
          >
            {t('documents.filters.clear_button', 'Clear filters {{counter}}', {
              counter: activeFiltersCounter(),
            })}
          </Button>
        </Box>
        <Box
          sx={{
            minWidth: 175,
            width: 175,
            marginLeft: '0 !important',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              pr: 4,
              pl: 4,
            }}
          >
            <InputLabel>
              {t('documents.filters.quick_filters.title', 'QUICK FILTERS')}
            </InputLabel>
            <Button
              size="large"
              variant={invoicesOnly ? 'contained' : 'outlined'}
              onClick={showInvoicesOnly}
            >
              {t('documents.filters.quick_filters.invoices', 'Invoices')}
            </Button>
          </Box>
        </Box>
      </Stack>
    </Paper>
  )
}

export default DocumentFilters

import { FormDatePicker as MuiFormDatePicker } from '../FormElements/FormDatePicker'
import { useFilter } from '../hooks'
import { FilterInputsCommonPropsType } from '../types'

type FormDatePickerPropTypes = Omit<FilterInputsCommonPropsType, 'name'> & {
  name: { startDateId: string; endDateId: string }
  inputFormat?: string
}

export const FormDatePicker = ({
  name,
  label,
  placeholder = '',
  inputFormat,
  ...props
}: FormDatePickerPropTypes) => {
  const { onInputChange, filters } = useFilter()

  const onChange = (date) => {
    const { startDateId, endDateId } = name
    onInputChange(
      [name.startDateId, name.endDateId],
      [date[startDateId], date[endDateId]]
    )
  }

  return (
    <>
      <MuiFormDatePicker
        label={label}
        name={[name.startDateId, name.endDateId]}
        onChange={onChange}
        placeholder={placeholder}
        qs={filters}
        inputFormat={inputFormat}
        data-testid={props['data-testid']}
      />
    </>
  )
}

import { takeLatest } from 'redux-saga/effects'
import * as actions from 'src/stores/actions/shipmentComments'
import * as actionCreators from '../../actionCreators/shipmentComments'

import {
  simpleGetAndResolve,
  simplePostAndResolve,
  simpleDeleteAndResolve,
} from '../factories'

const getShipmentComments = simpleGetAndResolve(
  (action) => `api/v1/shipments/${action.shipmentId}/shipment_comments`,
  actionCreators.fetchShipmentCommentsSuccess,
  (response) => response.data
)

const createShipmentComment = simplePostAndResolve(
  (action) => `api/v1/shipments/${action.shipmentId}/shipment_comments`,
  actionCreators.createShipmentCommentSuccess,
  (response) => response.data
)

const deleteShipmentComment = simpleDeleteAndResolve(
  (action) =>
    `api/v1/shipments/${action.shipmentId}/shipment_comments/${action.id}`,
  actionCreators.deleteShipmentCommentSuccess,
  (response, action) => action
)

export default function* (): Iterator<any> {
  yield takeLatest(actions.SHIPMENT_COMMENTS_FETCH, getShipmentComments)
  yield takeLatest(actions.SHIPMENT_COMMENTS_CREATE, createShipmentComment)
  yield takeLatest(actions.SHIPMENT_COMMENTS_DESTROY, deleteShipmentComment)
}

import { FunctionComponent } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Avatar } from '../../../stories/Avatar'

export interface IOrganizationWithAvatarProps {
  organization: IOrganizationShort
  bold?: boolean
}

const OrganizationWithAvatar: FunctionComponent<IOrganizationWithAvatarProps> = ({
  organization,
  bold,
}) => (
  <Box display="flex" alignItems="center" className="seller-organization">
    <Avatar
      alt={organization.name}
      src={organization.logo || 'no-logo'}
      className={organization.organization_role_code || organization.role_code}
    />

    <span className="ml-10">
      <Typography
        className={`medium ${bold ? 'bold' : ''}`}
        children={organization.name}
      />
    </span>
  </Box>
)

export default OrganizationWithAvatar

export const exportedFileColumns = [
  {
    label: 'service_item',
    value: 'serviceItemCode',
  },
  {
    label: 'container_type',
    value: 'containerCode',
  },
  {
    label: 'range',
    value: 'range',
  },
  {
    label: 'range_calculation_method',
    value: 'rangeCalculationMethod',
  },
  {
    label: 'price',
    value: 'priceValue',
  },
]

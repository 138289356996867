import { FunctionComponent } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { updateUrl } from 'src/components/Filters/utils'

import TablePagination from '@mui/material/TablePagination'

import {
  pickupsAndDeliveriesGetData,
  pickupsAndDeliveriesUpdateFilters,
  pickupsAndDeliveriesSelectedIds,
} from 'src/stores/actionCreators'

const PickupDeliveriesPagination: FunctionComponent = () => {
  const dispatch = useDispatch()

  const { perPage, total, filters, page } = useSelector(
    (state: IGlobalState) => ({
      perPage: Number(state.pickUpsAndDeliveries.filters.per_page),
      total: state.pickUpsAndDeliveries.total,
      filters: state.pickUpsAndDeliveries.filters,
      page: state.pickUpsAndDeliveries.filters.page,
    }),
    shallowEqual
  )

  const updateFilters = (newFilters) => {
    dispatch(pickupsAndDeliveriesGetData({ ...filters, ...newFilters }))
    dispatch(pickupsAndDeliveriesUpdateFilters({ ...filters, ...newFilters }))
  }

  const onPageChange = (event, updatedPage: number) => {
    dispatch(pickupsAndDeliveriesSelectedIds([]))
    updateFilters({ page: updatedPage + 1 })
    updateUrl('inland_transports', { ...filters, page: updatedPage + 1 })
  }

  return (
    <TablePagination
      className="table-section__pagination"
      sx={{
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: 'grey.400',
        py: 1,
      }}
      rowsPerPageOptions={[]}
      component="div"
      data-testid="pickup-deliveries-pagination"
      rowsPerPage={perPage}
      count={total}
      page={total > 0 ? page - 1 : 0}
      onPageChange={onPageChange}
    />
  )
}

export default PickupDeliveriesPagination

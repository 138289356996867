import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import EditIcon from '@mui/icons-material/Edit'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Typography } from '@mui/material'
import { permissionTo } from '../../utils'
import DefaultLabel from './DefaultLabel'
import { routes } from './index'

const CompaniesTable = ({
  addresses,
  className,
}: {
  addresses: IAddress[]
  className: string
}) => {
  const history = useHistory()
  const theme = useTheme()

  const isScreenSmall = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()

  return (
    <TableContainer
      classes={{ root: className }}
      component={(props) => <Paper {...props} square elevation={0} />}
    >
      <Table size={isScreenSmall ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            <TableCell className="x-short" />
            <TableCell>{t('address_book.addresses.name', 'Name')}</TableCell>
            <TableCell>
              {t('address_book.addresses.address', 'Address')}
            </TableCell>
            <TableCell>{t('address_book.addresses.email', 'Email')}</TableCell>
            <TableCell>{t('address_book.addresses.phone', 'Phone')}</TableCell>
            <TableCell />
            <TableCell className="x-short" />
          </TableRow>
        </TableHead>
        <TableBody>
          {addresses.map((item: IAddress) => (
            <TableRow
              key={item.id}
              hover={permissionTo('address_book.manage')}
              onClick={() => {
                if (!permissionTo('address_book.manage')) return
                history.push(
                  routes.companiesEdit.replace(':id', item.id.toString())
                )
              }}
            >
              <TableCell component="th" scope="row" className="x-short">
                <i
                  style={{ fontSize: '16px', display: 'flex' }}
                  className="icon company"
                />
              </TableCell>
              <TableCell>
                <Typography variant="body1Strong">{item.name}</Typography>
              </TableCell>
              <TableCell>{item.address}</TableCell>
              <TableCell>{item.email}</TableCell>
              <TableCell>{item.phone}</TableCell>
              <TableCell>
                {item.default && (
                  <DefaultLabel
                    text={t('address_book.addresses.default', 'Default')}
                  />
                )}
              </TableCell>
              <TableCell className="x-short no-overflow">
                {permissionTo('address_book.manage') && (
                  <IconButton size="large">
                    <EditIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CompaniesTable

import { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Collapse, Paper, useMediaQuery, useTheme } from '@mui/material'
import { includes } from 'lodash'
import Box from '@mui/material/Box'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { makeStyles } from '@mui/styles'
import { LOCAL_STORAGE_MAP_STATE } from 'src/config/constants'
import InlineNotification from 'src/components/Common/InlineNotification'
import { useTranslation } from 'react-i18next'
import {
  shipmentTrackGetData,
  shipmentOverviewGetData,
  clearShipmentOverviewState,
  setShipmentEditModalOpen,
} from '../../stores/actionCreators'
import EditShipmentWindow from '../../components/EditShipmentWindow'
import ShipmentOverviewHeading from './ShipmentOverviewHeading'
import ShipmentOverviewBody from './ShipmentOverviewBody'
import ShipmentOverviewMap from './ShipmentOverviewMap'

import './styles.scss'

interface IProps {
  shipmentId: string
}

const useStyles = makeStyles((theme) => ({
  mapButton: {
    background: 'none',
    color: theme.palette.grey[700],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px',
    '&:hover': {
      background: theme.palette.grey[50],
      cursor: 'pointer',
    },
  },
}))

const ShipmentOverview: FunctionComponent<IProps> = ({ shipmentId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles()
  const isScreenSizeLg = useMediaQuery(theme.breakpoints.between('sm', 'lg'))
  const [shown, setShown] = useState<boolean>(true)

  const { shipment } = useSelector((state: IGlobalState) => ({
    shipment: state.shipmentOverview,
  }))

  useEffect(() => {
    const isMapShown: string | null = localStorage.getItem(
      LOCAL_STORAGE_MAP_STATE
    )
    setShown(isMapShown ? JSON.parse(isMapShown) : true)
  }, [])

  useEffect(() => {
    dispatch(shipmentTrackGetData(shipmentId))

    window.scrollTo(0, 0)
    return () => {
      dispatch(clearShipmentOverviewState())
    }
  }, [shipmentId])

  const handleChange = (): void => {
    setShown(!shown)
    localStorage.setItem(LOCAL_STORAGE_MAP_STATE, JSON.stringify(!shown))
  }

  const onOpenEditShipment = (): void => {
    dispatch(setShipmentEditModalOpen(true))
  }

  const onCloseEditShipment = (): void => {
    dispatch(shipmentOverviewGetData(shipmentId))
    dispatch(setShipmentEditModalOpen(false))
  }

  const onSaveAndFetch = (): void => {
    dispatch(shipmentOverviewGetData(shipmentId))
  }

  return (
    <>
      <InlineNotification
        color="info"
        message={t(
          'common.notifications.subject_to_space_and_availability',
          'Schedule is subject to space and availability'
        )}
        showClose={false}
        show={
          !shipment.visibility_only &&
          includes(shipment.status_groups, 'subject_to_space_and_availability')
        }
      />
      <Paper variant="elevation">
        <div className="shipment-overview-top__wrapper">
          <div className="shipment-overview-top__main">
            <ShipmentOverviewHeading
              shipment={shipment}
              onOpenEditShipment={onOpenEditShipment}
            />
            <ShipmentOverviewBody
              shipment={shipment}
              onOpenEditShipment={onOpenEditShipment}
            />
          </div>
          <div className={`shipment-overview-top__map__wrapper`}>
            <Box
              className={`${classes.mapButton} ${
                isScreenSizeLg && 'collapse-button'
              }`}
              onClick={handleChange}
            >
              <ArrowForwardIosIcon
                className={`shipment-overview-top__main__arrow-icon
                ${!shown && 'left'}
                ${isScreenSizeLg && shown && 'up'}
                ${isScreenSizeLg && !shown && 'down'}
                `}
              />
            </Box>
            <Collapse
              orientation={isScreenSizeLg ? 'vertical' : 'horizontal'}
              in={shown}
            >
              <ShipmentOverviewMap />
            </Collapse>
          </div>
        </div>
      </Paper>
      {shipment.editModalOpen && (
        <EditShipmentWindow
          shipment={shipment}
          open
          onClose={onCloseEditShipment}
          fetchData={onSaveAndFetch}
        />
      )}
    </>
  )
}

export default ShipmentOverview

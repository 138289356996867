import { AnyAction } from 'redux'
import * as actions from '../actions/organizationSettings'

export const slaGetData = (payload?: any): AnyAction => ({
  type: actions.SLA_GET_DATA,
  payload,
})

export const slaGetDataSuccess = (payload: any): AnyAction => ({
  type: actions.SLA_GET_DATA_SUCCESS,
  payload,
})

export const slaOpenItem = (payload: number | null): AnyAction => ({
  type: actions.SLA_OPEN_ITEM,
  payload,
})

export const updateSLAAlerts = (
  sla_id: string,
  address_id: string
): AnyAction => ({
  type: actions.UPDATE_SLA_ALERTS,
  sla_id,
  address_id,
})

export const createSLA = (payload: any): AnyAction => ({
  type: actions.CREATE_SLA,
  payload,
})

export const createSLASuccess = (payload: any): AnyAction => ({
  type: actions.CREATE_SLA_SUCCESS,
  payload,
})

export const updateSLA = (id: string, payload: any): AnyAction => ({
  type: actions.UPDATE_SLA,
  id,
  payload,
})

export const updateSLASuccess = (payload: any): AnyAction => ({
  type: actions.UPDATE_SLA_SUCCESS,
  payload,
})

export const deleteSLA = (id: number): AnyAction => ({
  type: actions.DELETE_SLA,
  id,
})

export const deleteSLASuccess = (payload: any): AnyAction => ({
  type: actions.DELETE_SLA_SUCCESS,
  payload,
})

export const organizationUsersGetData = (): AnyAction => ({
  type: actions.ORGANIZATION_USERS_GET_DATA,
})

export const organizationUsersGetDataSuccess = (payload: any): AnyAction => ({
  type: actions.ORGANIZATION_USERS_GET_DATA_SUCCESS,
  payload,
})

export const getOrganizationApiToken = (): AnyAction => ({
  type: actions.GET_ORGANIATION_API_TOKEN,
})

export const getOrganizationApiTokenSuccess = (payload: any): AnyAction => ({
  type: actions.GET_ORGANIATION_API_TOKEN_SUCCESS,
  payload,
})

export const postOrganizationApiToken = (): AnyAction => ({
  type: actions.POST_ORGANIATION_API_TOKEN,
})

export const deleteOrganizationApiToken = (): AnyAction => ({
  type: actions.DELETE_ORGANIATION_API_TOKEN,
})

export const deleteOrganizationApiTokenSuccess = (): AnyAction => ({
  type: actions.DELETE_ORGANIATION_API_TOKEN_SUCCESS,
})

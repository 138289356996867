import { AnyAction } from 'redux'
import { call, put } from 'redux-saga/effects'
import { Method } from 'axios'
import { datadogRum } from '@datadog/browser-rum'
import { LOCAL_STORAGE_CREDENTIAL_KEY } from 'src/config/constants'
import { redirectToLogin } from 'src/utils/navigation'
import createActionCable from '../../../actionCable'
import {
  retrieveCredentials,
  storeCredentials,
  resolveAction,
  rejectAction,
} from '../wrappers'
import { ICredentials } from '../wrappers/interfaces'
import { shyppleGa } from '../../../utils'
import requester from '../../../utils/requester'
import Logger from '../../../utils/Logger'
import { userSerializer } from '../../../utils/serializers'
import {
  userLoadStateSuccess,
  userLoadStateFailure,
} from '../../actionCreators'

export default function* (action: AnyAction): Iterator<any> {
  const storedCredentials = localStorage.getItem(LOCAL_STORAGE_CREDENTIAL_KEY)
  if (!storedCredentials) {
    return redirectToLogin()
  }

  try {
    let credentials: any = yield retrieveCredentials()

    if ((credentials as ICredentials).impersonator) {
      // Preventing legacy impersonation => logging user out.

      credentials = {
        uid: '',
        accessToken: '',
        clientToken: '',
      }
      yield storeCredentials(
        credentials.uid,
        credentials.accessToken,
        credentials.clientToken
      )
    }
    const headers = {
      uid: credentials.uid,
      'access-token': credentials.accessToken,
      client: credentials.clientToken,
    }

    const response: any = yield call([requester, 'request'], {
      headers,
      method: 'GET' as Method,
      url: '/api/v1/account',
    })

    try {
      const user = response?.data?.data

      datadogRum.setUser({
        email: user.email,
        fullName: `${user.first_name} ${user.last_name}`,
        companyName: user.organization_name,
        phone: user.phone,
      })
    } catch (e) {}

    const user: IUser = {
      ...userSerializer(response),
      ...credentials,
      cable: createActionCable(credentials, response),
    }
    yield put(userLoadStateSuccess(user))
    shyppleGa.setUserData(user)
    shyppleGa.pageview(window.location.pathname)
    yield resolveAction(action, user)
  } catch (error) {
    const unknownError: any = error
    yield Logger.sagaError(unknownError, 'store::user::loadState')
    yield put(userLoadStateFailure())
    yield rejectAction(action, error)
  }
}

import Favico from 'favico.js'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { showNotification } from 'src/stores/actionCreators/notifications'
import {
  setNavigationNotificationList,
  setNavigationNotificationCount,
} from 'src/stores/actionCreators/navigationNotifications'
import UserChannelClient from 'src/components/SocketHandlers/UserChannelClient'
import { getYourOpenTasks, userGetUserData } from 'src/stores/actionCreators'
import { promisifyAction, permissionTo } from 'src/utils'
import { tasksPermissions } from '../TopNavigation.constants'

const favicon = new Favico({ animation: 'popFade' })

const TopNavigationUserChannel = () => {
  const dispatch = useDispatch()

  const showNotificationAsync = promisifyAction(dispatch, showNotification)

  const getAccountDataAsync = promisifyAction(dispatch, userGetUserData)

  const getYourOpenTasksAsync = promisifyAction(dispatch, getYourOpenTasks)

  useEffect(() => {
    if (permissionTo(tasksPermissions)) {
      getYourOpenTasksAsync()
    }
  }, [])

  const handleReceivedNotifications = (response: any) => {
    if (response.message_type === 'unread_notifications') {
      const {
        comments,
        count,
      }: {
        comments: INavigationNotification[]
        count: number
      } = response.message.data
      dispatch(setNavigationNotificationList(comments))
      dispatch(setNavigationNotificationCount(count))
      favicon.badge(count)
    } else if (response.message_type === 'sync_shipment_groups') {
      showNotificationAsync({
        message:
          'Existing shipments have been synced with your selected watch settings.',
        severity: 'success',
      })
      getYourOpenTasksAsync()
      getAccountDataAsync()
    }
  }

  return <UserChannelClient onReceived={handleReceivedNotifications} />
}

export default TopNavigationUserChannel

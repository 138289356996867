import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { OrganizationRoleEnum } from 'src/config/constants'
import { useFormContext } from '../../FormContext'
import Input from '../FormElements/Input'
import Skeleton from '../Skeleton'
import BookingParties from '../Parts/BookingParties/BookingParties'
import ShipmentRole from '../Parts/ShipmentRole'
import Customer from '../Parts/Customer'

const TemplatesFormInitialDetails = () => {
  const { isLoading } = useFormContext()
  const { t } = useTranslation()

  const { user } = useSelector((state: IGlobalState) => ({
    user: state.user,
  }))

  const isUserOrganizationCustomer =
    user.organizationRole === OrganizationRoleEnum.Customer

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Skeleton isLoading={isLoading}>
        <Input
          fieldPath="name"
          required={true}
          label={t('templates.name', 'Name')}
          columnSize={4}
          placeholder={t('templates.name_placeholder', 'Enter name')}
          inputProps={{ 'data-testid': 'shipment-template-name-input' }}
        />
        <ShipmentRole
          fieldPath="shipmentRole"
          label={t('templates.shipment_role', 'Shipment role')}
          columnSize={2}
        />
        {!isUserOrganizationCustomer && (
          <Customer
            fieldPath="customer"
            columnSize={2}
            required={true}
            label={t('templates.customer', 'Customer')}
          />
        )}
        <BookingParties columnSize={4} />
      </Skeleton>
    </Grid>
  )
}

export default TemplatesFormInitialDetails

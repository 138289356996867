import { AnyAction } from 'redux'
import * as actions from '../actions/shipments'

export const modalDisplayChange = (payload: any): AnyAction => ({
  type: actions.MODAL_DISPLAY_CHANGE,
  payload,
})

export const getShipmentStatuses = (): AnyAction => ({
  type: actions.GET_SHIPMENT_STATUSES,
})

export const getShipmentStatusesSuccess = (payload?: any): AnyAction => ({
  type: actions.GET_SHIPMENT_STATUSES_SUCCESS,
  payload,
})

/*
 @param payload : carries query-params of the GET request for shipments and filters
*/
export const shipmentsGetData = (payload?: any): AnyAction => ({
  type: actions.SHIPMENTS_GET_DATA,
  payload,
})

/* @param payload : {
 shipments: list of shipments,
 filter_options: object with various filter options
}*/
export const shipmentsGetDataSuccess = (payload: any): AnyAction => ({
  type: actions.SHIPMENTS_GET_DATA_SUCCESS,
  payload,
})

/*
 @param payload : carries query-params of the GET request for shipments and filters
*/
export const shipmentsGetParties = (id: string): AnyAction => ({
  type: actions.SHIPMENTS_GET_PARTIES,
  id,
})

/* @param payload : {
 shipments: list of shipments,
 filter_options: object with various filter options
}*/
export const shipmentsGetPartiesSuccess = (payload: any): AnyAction => ({
  type: actions.SHIPMENTS_GET_PARTIES_SUCCESS,
  payload,
})

/*
 @param payload : carries query-params of the GET request for shipments and filters
*/
export const shipmentsGetTimeline = (id: string): AnyAction => ({
  type: actions.SHIPMENTS_GET_TIMELINE,
  id,
})

/* @param payload : {
 shipments: list of shipments,
 filter_options: object with various filter options
}*/
export const shipmentsGetTimelineSuccess = (payload: any): AnyAction => ({
  type: actions.SHIPMENTS_GET_TIMELINE_SUCCESS,
  payload,
})

export const shipmentsClearTimeline = (payload?: any): AnyAction => ({
  type: actions.SHIPMENTS_CLEAR_TIMELINE,
})

/*
 @param payload : carries query-params of the GET request for shipments filters - required to
 feth correct metadata
*/
export const shipmentsGetMetaData = (payload?: any): AnyAction => ({
  type: actions.SHIPMENTS_GET_META_DATA,
  payload,
})

export const shipmentsGetMetaDataSuccess = (payload: any): AnyAction => ({
  type: actions.SHIPMENTS_GET_META_DATA_SUCCESS,
  payload,
})

export const requestExcel = (payload: any): AnyAction => ({
  type: actions.SHIPMENTS_REQUEST_EXCEL,
  payload,
})

export const requestExcelSuccess = (payload: any): AnyAction => ({
  type: actions.SHIPMENTS_REQUEST_EXCEL_SUCCESS,
  payload,
})

export const scopeParamsChange = (payload: any): AnyAction => ({
  type: actions.SCOPE_PARAMS_CHANGE,
  payload,
})

export const setExportFile = (payload: any): AnyAction => ({
  type: actions.SET_EXPORT_FILE,
  payload,
})

/*
 @param payload : carries query-params of the GET request for shipments and filters
*/
export const shipmentsToAssignPO = (payload?: any): AnyAction => ({
  type: actions.SHIPMENTS_TO_ASSIGN_PO_GET,
  payload,
})

export const shipmentsToAssignPOClean = (payload?: any): AnyAction => ({
  type: actions.SHIPMENTS_TO_ASSIGN_PO_CLEAN,
})

/* @param payload : {
 shipments: list of shipments,
 filter_options: object with various filter options
}*/
export const shipmentsToAssignPOSuccess = (payload: any): AnyAction => ({
  type: actions.SHIPMENTS_TO_ASSIGN_PO_GET_SUCCESS,
  payload,
})

export const shipmentsForceTabClick = (payload?: any): AnyAction => ({
  type: actions.SHIPMENTS_FORCE_CLICK_TAB,
})

export const updateConnections = (id: string, payload: any): AnyAction => ({
  type: actions.SHIPMENTS_UPDATE_CONNECTIONS,
  id,
  payload,
})

export const updateShipmentData = (id: string, payload: any): AnyAction => ({
  type: actions.SHIPMENT_UPDATE_DATA,
  id,
  payload,
})

export const modifyShipmentData = (id: number, payload: any): AnyAction => ({
  type: actions.SHIPMENT_MODIFY_DATA,
  id,
  payload,
})

export const updateConnectionsSuccess = (payload: any): AnyAction => ({
  type: actions.SHIPMENTS_UPDATE_CONNECTIONS_SUCCESS,
  payload,
})

export const shipmentsGetOrganizationRoles = (): AnyAction => ({
  type: actions.SHIPMENTS_GET_ORGANIZATION_ROLES,
})

export const shipmentsGetOrganizationRolesSuccess = (
  payload?: any
): AnyAction => ({
  type: actions.SHIPMENTS_GET_ORGANIZATION_ROLES_SUCCESS,
  payload,
})

export const createVisibilityShipment = (payload): AnyAction => ({
  type: actions.CREATE_VISIBILITY_SHIPMENT,
  payload,
})

export const setVisibilityShipment = (payload): AnyAction => ({
  type: actions.SET_VISIBILITY_SHIPMENT,
  payload,
})

export const createVisibilityShipmentSuccess = (payload): AnyAction => ({
  type: actions.CREATE_VISIBILITY_SHIPMENT_SUCCESS,
  payload,
})

export const shipmentsUpdateFilters = (payload: any): AnyAction => ({
  type: actions.SHIPMENTS_UPDATE_FILTERS,
  payload,
})

export const shipmentsSetFilterInitial = (): AnyAction => ({
  type: actions.SHIPMENTS_SET_FILTERS_INITIAL,
})

export const shipmentsUpdateShipmentForm = (payload: any): AnyAction => ({
  type: actions.SHIPMENTS_UPDATE_SHIPMENT_FORM,
  payload,
})

export const shipmentsUpdateShipmentFormErrors = (payload: any): AnyAction => ({
  type: actions.SHIPMENTS_UPDATE_SHIPMENT_FORM_ERRORS,
  payload,
})

export const getVisibilityShipmentCarriers = (payload): AnyAction => ({
  type: actions.GET_VISIBILITY_SHIPMENT_CARRIERS,
  payload,
})

export const getVisibilityShipmentCarriersSuccess = (payload): AnyAction => ({
  type: actions.GET_VISIBILITY_SHIPMENT_CARRIERS_SUCCESS,
  payload,
})

export const getUniqueTrackingKey = (payload): AnyAction => ({
  type: actions.SHIPMENTS_GET_UNIQUE_TRACKING_KEY,
  payload,
})

export const getUniqueTrackingKeySuccess = (payload): AnyAction => ({
  type: actions.SHIPMENTS_GET_UNIQUE_TRACKING_KEY_SUCCESS,
  payload,
})

export const createShipmentsQuickFilter = (payload: {
  name: string
  filter: IShipmentFilters
}): AnyAction => ({
  type: actions.ADD_QUICK_FILTER,
  payload: {
    ...payload,
    page: 'shipments',
  },
})

export const updateShipmentsQuickFilter = (
  id: number,
  payload: { name: string; filter: IShipmentFilters }
): AnyAction => ({
  type: actions.PUT_QUICK_FILTER,
  id,
  payload: {
    ...payload,
    page: 'shipments',
  },
})

export const deleteShipmentsQuickFilter = (id: number): AnyAction => ({
  type: actions.DELETE_QUICK_FILTER,
  id,
})

export const manageQuickFilters = (
  payload: { id: number; name?: string; _destroy?: boolean }[]
): AnyAction => ({
  type: actions.SHIPMENTS_MANAGE_QUICK_FILTERS,
  payload: { saved_filters_attributes: payload },
})

export const shareShipmentViaEmail = (id: string, payload: any): AnyAction => ({
  type: actions.SHIPMENTS_SHARE_EMAIL,
  id,
  payload,
})

export const shareShipmentViaEmailSuccess = (payload: any): AnyAction => ({
  type: actions.SHIPMENTS_SHARE_EMAIL_SUCCESS,
  payload,
})

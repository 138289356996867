import { FunctionComponent, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { History, LocationState } from 'history'
import { Button, Chip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CreateRoundedIcon from '@mui/icons-material/CreateRounded'
import { Box } from '@mui/system'
import { permissionTo, promisifyAction } from 'src/utils'
import { LoadTypeEnum, ModalityEnum } from 'src/config/constants'
import { carrierBookingCreateData } from 'src/stores/actionCreators'
import { capitalize } from 'lodash'
import { carrierBookingStatuses } from 'src/pages/ShipmentLayout/constants'
import { Trans, useTranslation } from 'react-i18next'

interface ICarrierBookingsProps {
  history: History<LocationState>
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))

const CarrierBookings: FunctionComponent<ICarrierBookingsProps> = ({
  history,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    inttraCompanyId,
    shipmentId,
    contactId,
    carrierBooking,
    loadType,
    modality,
  } = useSelector((state: IGlobalState) => ({
    inttraCompanyId: state.user.inttraCompanyId,
    contactId: state.user.id,
    carrierBooking: state.bookings.carrierBooking,
    shipmentId: state.shipmentOverview.id,
    loadType: state.shipmentOverview.load_type,
    modality: state.shipmentOverview.modality,
  }))

  const showActionButton =
    permissionTo('carrier_bookings.create') &&
    permissionTo('carrier_bookings.update') &&
    loadType === LoadTypeEnum.fcl &&
    modality === ModalityEnum.Sea &&
    inttraCompanyId

  const redirect = () =>
    history.push(`/shipments/${shipmentId}/carrier-booking`)

  const createCarrierBookingsAsync = promisifyAction(
    dispatch,
    carrierBookingCreateData
  )

  const handleRequest = async () => {
    await createCarrierBookingsAsync(shipmentId, {
      sender_id: inttraCompanyId,
      contact_user_id: contactId,
    })
    redirect()
  }

  const status = useMemo(() => {
    return carrierBooking?.booking_state
      ? carrierBookingStatuses.find(
          (s) => s.label === carrierBooking?.booking_state
        )
      : null
  }, [carrierBooking?.booking_state])

  const statusText = useMemo(() => {
    let text
    switch (true) {
      case !carrierBooking:
        text = (
          <Trans
            i18nKey="shipments.bookings.no_booking_requested"
            defaults="No carrier booking requested. Click the <strong>Request carrier booking</strong> button to start the process"
            components={{ strong: <strong /> }}
          />
        )
        break
      case carrierBooking && status?.label === 'created':
        text = (
          <Trans
            i18nKey="shipments.bookings.booking_created"
            defaults="Review and complete your carrier booking before sending it to the carrier. Click the <strong>Edit carrier booking</strong> button to continue the process."
            components={{ strong: <strong /> }}
          />
        )
        break
      default:
        text = status?.text
    }

    return text
  }, [carrierBooking, status])

  return (
    <Box m={2.5}>
      <Box className={classes.container}>
        <Box className={classes.wrapper}>
          <Typography variant="h3">
            {t('shipments.bookings.title', 'Carrier bookings')}
          </Typography>
          {carrierBooking && (
            <Box ml={2}>
              <Chip
                color={status?.color || 'default'}
                label={capitalize(status?.label) || ''}
                size="medium"
                variant="outlined"
              />
            </Box>
          )}
        </Box>

        <Box className={classes.content} mt={2}>
          <Typography variant="body2" maxWidth={'75%'}>
            {statusText}
          </Typography>
          <Button
            variant="outlined"
            disabled={!showActionButton}
            startIcon={carrierBooking ? <CreateRoundedIcon /> : undefined}
            onClick={carrierBooking ? redirect : handleRequest}
          >
            {carrierBooking
              ? t(
                  'shipments.bookings.edit_carrier_booking',
                  'Edit carrier booking'
                )
              : t(
                  'shipments.bookings.request_carrier_booking',
                  'Request carrier booking'
                )}
          </Button>
        </Box>
      </Box>
      <Box mt={2} />
      <Typography variant="h5">
        {t('shipments.bookings.supplier_bookings', 'Supplier bookings')}
      </Typography>
    </Box>
  )
}

export default CarrierBookings

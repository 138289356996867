import { FunctionComponent, useState, useCallback, useEffect } from 'react'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import Modal from 'src/components/Common/Modal'
import Table from 'src/components/Common/Table'
import DatePicker from 'src/stories/DatePicker'
import { convertDatePickerToUniversalFormat } from '../../../utils/helpers'

import { productHeaders, singleLineHeaders } from './tableHeaders'

import './styles.scss'

interface IProps {
  open: boolean
  close: () => void
  fetchData: (poData: any) => void
  purchaseOrdersUpdate: (poData: any) => void
  purchaseOrder: IPurchaseOrder
}

export const cells = (t): any[] => [
  {
    name: '',
    customClassName: 'short-50',
  },
  {
    name: t(
      'purchase_orders.modals.public_po.table.product_name',
      'Product Name'
    ),
    customClassName: 'left-aligned x-medium',
  },
  {
    name: t(
      'purchase_orders.modals.public_po.table.product_code',
      'Product Code'
    ),
    customClassName: 'left-aligned x-medium',
  },
  {
    name: t('purchase_orders.modals.public_po.table.volume', 'Volume'),
    customClassName: 'left-aligned x-medium',
  },
  {
    name: t('purchase_orders.modals.public_po.table.weight', 'Weight'),
    customClassName: 'left-aligned x-medium',
  },
]

const PublicPOModal: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const [loadingButton, setLoadingButton] = useState<boolean>(false)
  const [purchaseOrder, setPurchaseOrder] = useState<any>(props.purchaseOrder)

  useEffect(() => {
    setPurchaseOrder(props.purchaseOrder)
  }, [props.purchaseOrder])

  const onSave = async (): Promise<any> => {
    const poData = {
      cargo_ready_date: purchaseOrder.cargo_ready_date,
      purchase_order_lines_attributes: purchaseOrder.purchase_order_lines.map(
        (line: IPurchaseOrderLine) => {
          return {
            id: line.id,
            booking_lines_attributes: line.booking_lines.map(
              (booking_line: IBookingLine) => {
                return {
                  id: booking_line.id,
                  weight_kg: booking_line.weight_kg,
                  volume_cbm: booking_line.volume_cbm,
                }
              }
            ),
          }
        }
      ),
    }

    await props.purchaseOrdersUpdate(poData)
    await props.fetchData(poData)
    props.close()
    setLoadingButton(false)
  }

  const onClose = useCallback((): void => {
    props.close()
  }, [])

  const setDate = useCallback(
    (date: string, fieldName: string): void => {
      setPurchaseOrder({ ...purchaseOrder, [fieldName]: date })
    },
    [purchaseOrder]
  )

  const handleChangeWeight = useCallback(
    (values: NumberFormatValues, field: string, index: number): void => {
      const lines = [...purchaseOrder.purchase_order_lines]
      lines[index].booking_lines[0][field] = values.floatValue
      setPurchaseOrder({ ...purchaseOrder, purchase_order_lines: lines })
    },
    [purchaseOrder]
  )

  const singleLineEdit = useCallback((): React.ReactElement => {
    const bookingLine = purchaseOrder.purchase_order_lines[0].booking_lines[0]
    return (
      <Table.StripedTable theme="rates">
        <Table.StripedTableHeader cells={singleLineHeaders(t)} />
        <Table.StripedTableBody>
          <div className="products-item--table-body" key={0}>
            <Table.StripedTableRow index={0}>
              <Table.StripedTableCell className="left-aligned x-medium">
                <div className="block medium">
                  <NumberFormat
                    className={`cargo-input right-aligned--weight medium`}
                    name="weight_kg"
                    decimalScale={2}
                    thousandSeparator={true}
                    value={bookingLine.volume_cbm}
                    required={true}
                    onValueChange={(value) => {
                      handleChangeWeight(value, 'volume_cbm', 0)
                    }}
                    allowNegative={false}
                  />
                  <div className="input-desc">m3</div>
                </div>
              </Table.StripedTableCell>
              <Table.StripedTableCell className="left-aligned x-medium">
                <div className="block medium">
                  <NumberFormat
                    className={`cargo-input right-aligned--weight medium`}
                    name="weight_kg"
                    decimalScale={2}
                    thousandSeparator={true}
                    value={bookingLine.weight_kg}
                    required={true}
                    onValueChange={(value) => {
                      handleChangeWeight(value, 'weight_kg', 0)
                    }}
                    allowNegative={false}
                  />
                  <div className="input-desc">kg</div>
                </div>
              </Table.StripedTableCell>
              <Table.StripedTableCell></Table.StripedTableCell>
              <Table.StripedTableCell></Table.StripedTableCell>
              <Table.StripedTableCell></Table.StripedTableCell>
              <Table.StripedTableCell></Table.StripedTableCell>
              <Table.StripedTableCell></Table.StripedTableCell>
            </Table.StripedTableRow>
          </div>
        </Table.StripedTableBody>
      </Table.StripedTable>
    )
  }, [purchaseOrder])

  const poLinesTable = useCallback((): React.ReactElement => {
    return (
      <Table.StripedTable theme="rates">
        <Table.StripedTableHeader cells={productHeaders(t)} />
        <Table.StripedTableBody>
          {purchaseOrder.purchase_order_lines.map((line, index: number) => (
            <div className="products-item--table-body" key={line.id}>
              <Table.StripedTableRow index={index}>
                <Table.StripedTableCell className="short-50">
                  {`${line.units_ordered} x`}
                </Table.StripedTableCell>
                <Table.StripedTableCell className="left-aligned x-medium">
                  {line.product_name}
                </Table.StripedTableCell>
                <Table.StripedTableCell className="left-aligned x-medium">
                  {line.product_code}
                </Table.StripedTableCell>
                <Table.StripedTableCell className="left-aligned x-medium">
                  <div className="block medium">
                    <NumberFormat
                      className={`cargo-input right-aligned--weight medium`}
                      name="weight_kg"
                      decimalScale={2}
                      thousandSeparator={true}
                      value={
                        !!line.booking_lines.length
                          ? line.booking_lines[0].volume_cbm
                          : ''
                      }
                      required={true}
                      onValueChange={(value) => {
                        handleChangeWeight(value, 'volume_cbm', index)
                      }}
                      allowNegative={false}
                    />
                    <div className="input-desc">m3</div>
                  </div>
                </Table.StripedTableCell>
                <Table.StripedTableCell className="left-aligned x-medium">
                  <div className="block medium">
                    <NumberFormat
                      className={`cargo-input right-aligned--weight medium`}
                      name="weight_kg"
                      decimalScale={2}
                      thousandSeparator={true}
                      value={
                        !!line.booking_lines.length
                          ? line.booking_lines[0].weight_kg
                          : ''
                      }
                      required={true}
                      onValueChange={(value) => {
                        handleChangeWeight(value, 'weight_kg', index)
                      }}
                      allowNegative={false}
                    />
                    <div className="input-desc">kg</div>
                  </div>
                </Table.StripedTableCell>
              </Table.StripedTableRow>
            </div>
          ))}
        </Table.StripedTableBody>
      </Table.StripedTable>
    )
  }, [purchaseOrder])

  return (
    <Modal.Window open={props.open} onClose={onClose}>
      <Modal.Title
        children={t('purchase_orders.modals.details.title', 'Order details')}
        onClose={onClose}
      />
      <Modal.Content>
        <div className="purchase-orders-window--block-content">
          <div className="flex">
            <div className="field-block long">
              <div className="field-title paragraph__small grey">
                {t(
                  'purchase_orders.modals.details.order_number',
                  'Order number'
                )}
              </div>
              <div>{purchaseOrder.purchase_order_number}</div>
            </div>
            <div className="field-block long">
              <div className="field-title paragraph__small grey">
                {t('purchase_orders.modals.details.shipper', 'Shipper')}
              </div>
              <div>
                {(purchaseOrder.seller && purchaseOrder.seller.name) || '-'}
              </div>
            </div>
          </div>

          <div className="flex new-line">
            <div className="field-block medium">
              <div className="field-title paragraph__small grey">
                {t('purchase_orders.modals.details.incoterm', 'Incoterm')}
              </div>
              <div>
                {(purchaseOrder.incoterm && purchaseOrder.incoterm.name) || '-'}
              </div>
            </div>
            <div className="field-block long">
              <div className="field-title paragraph__small grey">
                {t('purchase_orders.modals.details.pol', 'Port of Loading')}
              </div>
              <div>
                {(purchaseOrder.loading_port &&
                  purchaseOrder.loading_port.name) ||
                  '-'}
              </div>
            </div>
            <div className="field-block medium">
              <div className="field-title paragraph__small grey">
                {t('purchase_orders.modals.details.order_date', 'Order date')}
              </div>
              <div>
                {convertDatePickerToUniversalFormat(purchaseOrder.order_date) ||
                  '-'}
              </div>
            </div>
            <div className="field-block medium">
              <div className="field-title paragraph__small grey">
                {t('purchase_orders.modals.details.ex_factory', 'Ex factory')}
              </div>
              <div>
                {convertDatePickerToUniversalFormat(
                  purchaseOrder.ex_factory_date
                ) || '-'}
              </div>
            </div>
            <div className="field-block medium">
              <div className="field-title paragraph__small grey">
                {t(
                  'purchase_orders.modals.details.cargo_ready_date',
                  'Cargo ready date'
                )}
              </div>
              <DatePicker
                onChange={(date) =>
                  setDate(date?.toISO() || '', 'cargo_ready_date')
                }
                value={purchaseOrder.cargo_ready_date}
                clearable={false}
              />
            </div>
          </div>

          <div className="products-table">
            {purchaseOrder.purchase_order_lines.length === 1 &&
            purchaseOrder.purchase_order_lines[0].units_ordered === 0
              ? singleLineEdit()
              : poLinesTable()}
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <LoadingButton
          variant="contained"
          onClick={onSave}
          loading={loadingButton}
        >
          {t('purchase_orders.modals.details.save_button', 'Save')}
        </LoadingButton>
      </Modal.Actions>
    </Modal.Window>
  )
}

export default PublicPOModal

import Box from '@mui/material/Box'
import { NavLink } from 'react-router-dom'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import { CreateMenuItemProps } from 'src/components/TopNavigation/TopNavigation.props'
import { StyledCreateMenuItem } from 'src/components/TopNavigation/TopNavigation.styles'

interface TopNavigationCreateDropdownItemProps {
  item: CreateMenuItemProps
  onClick: () => void
}

const TopNavigationCreateDropdownItem: React.FC<TopNavigationCreateDropdownItemProps> = ({
  item,
  onClick,
}) => {
  const { icon, label, color, to, description } = item
  return (
    <StyledCreateMenuItem
      to={to}
      spacing={2}
      direction="row"
      component={NavLink}
      onClick={onClick}
      activeClassName="active"
      data-testid={`top-nav-${item.key}-menu-link-item`}
    >
      <Avatar
        sx={{ backgroundColor: `${color}.light`, color: `${color}.main` }}
        children={icon}
      />
      <Box>
        <Typography children={label} variant="body1Strong" />
        <Typography children={description} variant="subtitle1" mt={1} />
      </Box>
    </StyledCreateMenuItem>
  )
}

export default TopNavigationCreateDropdownItem

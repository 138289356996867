import { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'

const SelfMentionStyle: FunctionComponent<{}> = () => {
  const { currentUserId } = useSelector((state: IGlobalState) => ({
    currentUserId: state.user.id,
  }))

  const style = `
    .atwho-custom[data-atwho-id='${currentUserId}'] {
      font-weight: 600;
    }
    `

  return <style>{style}</style>
}

export default SelfMentionStyle

import { FunctionComponent, useState, useMemo, useCallback } from 'react'
import { RatesListTypeEnum } from 'src/@types/endpoints/prices'
import { IColumnHeader } from 'src/components/Common/Table/StripedTableHeader'
import { useTranslation } from 'react-i18next'
import Table from 'src/components/Common/Table'
import { spacerTableHeader, organizationTableHeader } from '../constants'
import RatesLine from '../RatesLine'
import { getSortedRatesBasedOnListType } from '../helpers'

interface IProps {
  scope: RatesListTypeEnum
  rates: IRate[] | null
  container_types: IContainerType[]
  user_container_types: IContainerType[]
}

const RatesTable: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const { container_types, user_container_types } = props
  const [sortValue, setSortValue] = useState<string>('valid_from')
  const [sortOrder, setSortOrder] = useState<boolean>(true)

  const commonRatesHeaders: IColumnHeader[] = [
    organizationTableHeader,
    {
      name: t('rates.table_columns.pol', 'POL'),
      sortable: true,
      sortValue: 'loading_port.name',
      customClassName: 'x-medium left-aligned',
    },
    {
      name: t('rates.table_columns.pod', 'POD'),
      sortable: true,
      sortValue: 'discharge_port.name',
      customClassName: 'x-medium left-aligned',
    },
    {
      name: t('rates.table_columns.carrier', 'Carrier'),
      sortable: true,
      sortValue: 'carrier.name',
      customClassName: 'x-medium left-aligned',
    },
    {
      name: t('rates.table_columns.validity', 'Validity'),
      sortable: true,
      sortValue: 'valid_from',
      customClassName: 'wide left-aligned',
    },
    {
      name: t('rates.table_columns.currency', 'Currency'),
      sortable: false,
      sortValue: '',
      customClassName: 'short-70 left-aligned',
    },
  ]

  const tableLclHeaders: IColumnHeader[] = [
    ...commonRatesHeaders,
    {
      name: t('rates.table_columns.w_m_ocean_freight', 'W/M ocean freight'),
      sortable: true,
      sortValue: 'value',
      customClassName: 'x-medium left-aligned',
    },
    spacerTableHeader,
  ]

  const tableAirHeaders: IColumnHeader[] = [
    organizationTableHeader,
    {
      name: t('rates.table_columns.aod', 'AOD'),
      sortable: true,
      sortValue: 'loading_port.display_name',
      customClassName: 'wide left-aligned',
    },
    {
      name: t('rates.table_columns.aoa', 'AOA'),
      sortable: true,
      sortValue: 'discharge_port.display_name',
      customClassName: 'wide left-aligned',
    },
    {
      name: t('rates.table_columns.airline', 'Airline'),
      sortable: true,
      sortValue: 'carrier.name',
      customClassName: 'wide left-aligned',
    },
    {
      name: t('rates.table_columns.validity', 'Validity'),
      sortable: true,
      sortValue: 'valid_from',
      customClassName: 'wide left-aligned',
    },
    {
      name: t('rates.table_columns.currency', 'Currency'),
      sortable: false,
      sortValue: '',
      customClassName: 'short-70 left-aligned',
    },
    {
      name: t('rates.table_columns.p_kg.air_freight', 'P/KG air freight'),
      sortable: true,
      sortValue: 'value',
      customClassName: 'x-medium left-aligned',
    },
    spacerTableHeader,
  ]

  const sortRatesTable = useCallback(
    (value: string): void => {
      setSortValue(value)
      setSortOrder(sortValue !== value || !sortOrder)
    },
    [sortValue, setSortValue, setSortOrder, sortOrder]
  )

  const sortedRates: IRate[] = useMemo(() => {
    return getSortedRatesBasedOnListType(
      props.scope,
      sortOrder,
      sortValue,
      props.rates
    )
  }, [props.rates, sortValue, sortOrder])

  const tableFclHeaders = [
    ...commonRatesHeaders,
    ...user_container_types.map((containerType) => {
      return {
        name: containerType.name.toUpperCase(),
        sortable: false,
        sortValue: '',
        customClassName: 'center-aligned medium',
      }
    }),
    spacerTableHeader,
  ]

  const headers = {
    [RatesListTypeEnum.fcl]: tableFclHeaders,
    [RatesListTypeEnum.lcl]: tableLclHeaders,
    [RatesListTypeEnum.air]: tableAirHeaders,
  }

  return (
    <Table.StripedTable theme="rates">
      <Table.StripedTableHeader
        cells={headers[props.scope]}
        clickOnSort={sortRatesTable}
        defaultSortValue={sortValue}
        defaultSortDirection="asc"
      />
      <Table.StripedTableBody>
        {sortedRates.map((item: IRate, index) => (
          <RatesLine
            key={index + item.list_type}
            item={item}
            index={index}
            codes={container_types.map((i) => i.code)}
            containerCodes={user_container_types.map((i) => i.code)}
          />
        ))}
      </Table.StripedTableBody>
    </Table.StripedTable>
  )
}

export default RatesTable

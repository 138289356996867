import Menu from '@mui/material/Menu'
import { useLocation } from 'react-router-dom'
import { useState, MouseEvent } from 'react'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import { popupProps } from 'src/components/TopNavigation/TopNavigation.constants'
import { MenuItemLinkProps } from 'src/components/TopNavigation/TopNavigation.props'
import { getPermittedMenuItems } from 'src/components/TopNavigation/TopNavigation.utils'
import { StyledMenuButton } from 'src/components/TopNavigation/TopNavigation.styles'
import TopNavigationMenuItem from 'src/components/TopNavigation/components/TopNavigationMenuItem'

interface TopNavigationLinkDropdownProps {
  menuItem: MenuItemLinkProps
}

const TopNavigationLinkDropdown: React.FC<TopNavigationLinkDropdownProps> = ({
  menuItem,
}) => {
  const id = 'top-nav-more'
  const location = useLocation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const filteredNavItems = getPermittedMenuItems(menuItem?.children ?? [])

  const matchedLink = filteredNavItems.find((filteredNavItem) => {
    return location.pathname.includes(filteredNavItem?.to ?? '')
  })

  if (filteredNavItems.length === 0) {
    return null
  }

  return (
    <>
      <StyledMenuButton
        size="large"
        sx={{ ml: 2 }}
        id={`${id}-button`}
        aria-haspopup="true"
        onClick={handleClick}
        aria-expanded={open ? 'true' : undefined}
        endIcon={<KeyboardArrowDownOutlinedIcon />}
        className={`${matchedLink ? 'active' : ''} ${open ? 'open' : ''}`}
        aria-controls={open ? `${id}-button` : undefined}
        data-testid={`top-nav-${menuItem.key}-dropdown`}
      >
        {menuItem.label}
      </StyledMenuButton>
      <Menu
        open={open}
        id={`${id}-menu`}
        anchorEl={anchorEl}
        onClose={handleClose}
        aria-labelledby={`${id}-button`}
        MenuListProps={{ sx: { width: 200 } }}
        {...popupProps}
      >
        {filteredNavItems.map((subMenuItem) => {
          return (
            <TopNavigationMenuItem
              key={subMenuItem.key}
              item={subMenuItem}
              onClick={handleClose}
            />
          )
        })}
      </Menu>
    </>
  )
}

export default TopNavigationLinkDropdown

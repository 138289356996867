import { FunctionComponent, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from 'src/stories'
import { history } from 'src/shyppleStore'
import { promisifyAction } from '../../../utils'
import { fetchDashboardArriving } from '../../../stores/actionCreators'
import { dashboardLine } from '../DashboardShipmentLine/dashboardLine'
import DashboardEmptyLine from '../DashboardEmptyLine'
import columns from '../common'

const DashboardArriving: FunctionComponent<{}> = () => {
  const dispatch = useDispatch()
  const [busy, setBusy] = useState<boolean>(true)

  const { records, onlyWatchedShipments } = useSelector(
    (state: IGlobalState) => ({
      records: state.dashboard.arriving.records || [],
      onlyWatchedShipments: state.shipmentsWatchedBy.onlyWatchedShipments,
    })
  )

  const fetchDashboardArrivingAsync = promisifyAction(
    dispatch,
    fetchDashboardArriving
  )

  useEffect(() => {
    const fetchDataAsync = async () => {
      await fetchDashboardArrivingAsync({
        only_watched_shipments: onlyWatchedShipments,
      })
      setBusy(false)
    }
    fetchDataAsync()
  }, [onlyWatchedShipments])

  if (!busy && !records.length) {
    return <DashboardEmptyLine />
  }

  return (
    <Table
      columns={columns}
      loading={busy}
      maxHeight={320}
      onRowClick={(id) => {
        history.push(`/shipments/${id}`)
      }}
      showHeader={false}
      rows={
        !!records.length
          ? records.map((shipment: IDashboardShipment) => {
              const subtitle = shipment.delivery_requested
                ? shipment.delivery_address_name
                : 'Delivery not requested'
              return dashboardLine(shipment, subtitle)
            })
          : []
      }
    />
  )
}

export default DashboardArriving

import { SpotRateProps } from './SABSearchResultAirSpotRates.props'

export const initialSpotRates: SpotRateProps[] = [
  {
    label: 'Direct (fast)',
    checked: false,
    color: 'secondary',
    name: 'fast',
  },
  {
    label: 'Indirect (regular)',
    checked: true,
    color: 'default',
    name: 'regular',
  },
  {
    label: 'Express (door-to-door)',
    checked: false,
    color: 'info',
    name: 'courier',
  },
]

import { FunctionComponent, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import { Link } from '@mui/material'
import Typography from '@mui/material/Typography'
import Delete from '@mui/icons-material/Delete'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { find, groupBy, keys, toInteger, sortBy, includes, map } from 'lodash'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { AvatarGroup } from 'src/stories/AvatarGroup'
import { Avatar } from 'src/stories/Avatar'
import DefaultAutocomplete from 'src/stories/SearchBar'
import { permissionTo, promisifyAction } from '../../../utils'
import { toggleChatFollow, chatGetUsers } from '../../../stores/actionCreators'

interface IProps {
  chatId: number
}

const ChatOrganizations: FunctionComponent<IProps> = ({ chatId }) => {
  const dispatch = useDispatch()

  const toggleChatFollowAsync = promisifyAction(dispatch, toggleChatFollow)
  const chatGetUsersAsync = promisifyAction(dispatch, chatGetUsers)

  const {
    organizations,
    relatedUsers,
    followers,
    currentUserId,
    currentOrganizationId,
  } = useSelector((state: IGlobalState) => ({
    organizations: state.chat.organizations,
    relatedUsers: state.chat.related_users,
    followers: state.chat.followers,
    currentUserId: state.user.id,
    currentOrganizationId: state.user.organizationId,
  }))

  const [open, setOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | undefined>()

  const manageFollowersPermission: boolean = useMemo(
    () => permissionTo('chats.participants.update'),
    []
  )

  const togglePopover = useCallback(
    (event) => {
      !open &&
        anchorEl !== event.currentTarget &&
        setAnchorEl(event.currentTarget)
      setOpen(!open)
    },
    [open, anchorEl]
  )

  const isFollower = useCallback(
    (user: IChatUser): boolean => {
      return !!followers.filter(
        (follower: IFollower) =>
          follower.id === user.id && follower.type === user.user_type
      ).length
    },
    [followers]
  )

  const onPickOption = async (userId: number | string): Promise<void> => {
    const userIdInteger: number = toInteger(userId)

    const user: IChatUser | undefined = relatedUsers.find(
      (chatUser: IChatUser) => chatUser.id === userIdInteger
    )

    if (!user) {
      return
    }

    let userFullName: string = `${user.first_name} ${user.last_name} has`

    if (userIdInteger === currentUserId) {
      userFullName = 'You have'
    }

    const wasFollower: boolean = isFollower(user)

    await toggleChatFollowAsync(chatId, userId)
      .then((response) => {
        if (wasFollower) {
          dispatch(
            showNotification({
              message: `${userFullName} been removed from chat followers.`,
              severity: 'error',
            })
          )
        } else {
          dispatch(
            showNotification({
              message: `${userFullName} been added to this chat as a follower.`,
              severity: 'success',
            })
          )
        }
      })
      .catch(() => {
        dispatch(
          showNotification({
            message: 'Not allowed',
            severity: 'error',
          })
        )
      })
    chatGetUsersAsync(chatId)
  }

  const renderOrgGroups = (manageFollowersPermission: boolean) => {
    const followersIds = map(followers, (followers) => followers.id)

    const filteredRelatedUsers = relatedUsers.filter((user) =>
      includes(followersIds, user.id)
    )

    const getOrganization = (id: number): IChatOrganization => {
      return find(organizations, ['id', id]) || ({} as IChatOrganization)
    }

    const userGroups = groupBy(
      filteredRelatedUsers,
      (user: IChatUser) => user.organization.id
    )
    const groupsArr: number[] = sortBy(
      keys(userGroups).map((key: string) => toInteger(key)),
      [
        (x) => {
          return x !== currentOrganizationId
        },
      ]
    )

    const sortUsers = (users: IChatUser[]): IChatUser[] => {
      return sortBy(users, [
        'full_name',
        (x) => {
          return x.id !== currentUserId
        },
      ])
    }

    return (
      <div className="chat-organizations-container">
        {groupsArr.map((orgId: number) => (
          <div key={orgId} className="organization__block">
            <div
              className="organization__block--title"
              style={{
                color: getOrganization(orgId).color,
              }}
            >
              {getOrganization(orgId).name || 'Other'}
            </div>
            <div className="organization__block--names">
              {sortUsers(userGroups[orgId]).map((user: IChatUser) => (
                <div
                  key={user.id}
                  className="organization__block--wrapper mt-5"
                >
                  <Typography variant="body1">
                    <div>
                      {user.full_name}{' '}
                      {user.id === currentUserId ? (
                        <span className="you-marker">(you)</span>
                      ) : (
                        ''
                      )}
                    </div>
                  </Typography>
                  <Delete
                    color="action"
                    onClick={() => {
                      onPickOption(`${user.id}`)
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    )
  }

  const selectUser = (user: IChatUser | null) => {
    if (!user) return
    if (!isFollower(user)) {
      onPickOption(user.id)
    }
  }

  const userLine = (text: React.ReactNode, option: IChatUser) => {
    return (
      <div className="shipment-collaborators-window--option-line">
        <div className="avatar-block">
          <div className="avatar-block--wrapper">
            <Avatar
              alt={option.full_name}
              src={option.avatar_thumb || 'no-logo'}
              className={option.organization.role_code}
            />
          </div>
          <div className="name-block">
            <Typography variant="body1Strong" children={option.full_name} />
          </div>
        </div>
        <div className="description">
          <Typography variant="body1" children={option.organization.name} />
        </div>
        <div className="note-message">
          {includes(
            map(followers, (followers) => followers.id),
            option.id
          ) && <div className="status-message">Already added to chat</div>}
        </div>
      </div>
    )
  }

  return (
    <div className="chat-prolog">
      <div className="heading__3">Visible to:</div>
      <div className="paragraph__medium pl-5">
        <AvatarGroup
          avatars={organizations.map((organization: IChatOrganization) => {
            return {
              id: organization.id.toString(),
              alt: organization.name || '',
              className: organization.role_code,
              src: organization.logo || '',
            }
          })}
        />
      </div>
      <div className="paragraph__medium normalblue pl-10">
        <Link variant="body1" component="button" onClick={togglePopover}>
          Manage chat watchers
        </Link>
        {open && (
          <Popper
            id="shipment-share-link-popper"
            open
            anchorEl={anchorEl}
            placement="bottom-start"
            className=" mui-override"
          >
            <ClickAwayListener onClickAway={togglePopover}>
              <Paper className="chat-watchers">
                <div className="chat-watchers__title">
                  <Typography variant="h5" children="Manage chat watchers" />
                  <div
                    className="chat-watchers__title__actions"
                    onClick={togglePopover}
                  >
                    <i className="icon close" />
                  </div>
                </div>
                <div className="chat-watchers__info">
                  <Typography
                    variant="body1"
                    children="Chat watchers receive email and in-app notifications and can be
                notified with @user. Users not watching a chat can still access
                it."
                  />
                </div>
                <DefaultAutocomplete
                  key={followers.length}
                  onChange={selectUser}
                  value={null}
                  children={userLine}
                  placeholder="Search in users"
                  options={relatedUsers || []}
                />
                {renderOrgGroups(manageFollowersPermission)}
              </Paper>
            </ClickAwayListener>
          </Popper>
        )}
      </div>
    </div>
  )
}

export default ChatOrganizations

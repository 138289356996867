import Text from './Text'
import TextBordered from './TextBordered'
import Currency from './Currency'
import Multiselect from './Multiselect'
import Selector from './Selector'
import DropDown from './DropDown'
import SearchBar from './SearchBar'
import OptionsPicker from './OptionsPicker'
import OptionsPickerAsync from './OptionsPickerAsync'
import SinglePicker from './SinglePicker'

const Input = {
  Text,
  TextBordered,
  Currency,
  Multiselect,
  Selector,
  DropDown,
  SearchBar,
  OptionsPicker,
  OptionsPickerAsync,
  SinglePicker,
}

export default Input

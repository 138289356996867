export const estimatedArrivalDelayColor = (category: string) => {
  switch (category) {
    case 'critical_delay':
      return 'error'
    case 'delay':
      return 'warning'
    case 'early_arrival':
      return 'primary'
    default:
      return 'success'
  }
}

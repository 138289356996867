import { AnyAction } from 'redux'
import * as actions from '../actions/documents'

export const getDocumentsData = (payload?: any): AnyAction => ({
  type: actions.DOCUMENTS_GET_DATA,
  payload,
})

export const getDocumentsDataSuccess = (payload: any): AnyAction => ({
  type: actions.DOCUMENTS_GET_DATA_SUCCESS,
  payload,
})

export const getDocumentsFiltersData = (payload?: any): AnyAction => ({
  type: actions.DOCUMENTS_FILTERS_GET_DATA,
  payload,
})

export const getDocumentsFiltersDataSuccess = (payload: any): AnyAction => ({
  type: actions.DOCUMENTS_FILTERS_GET_DATA_SUCCESS,
  payload,
})

export const documentsUpdateFilters = (payload: any): AnyAction => ({
  type: actions.DOCUMENTS_UPDATE_FILTERS,
  payload,
})

export const PICK_UPS_AND_DELIVERIES_LOADING = 'PICK_UPS_AND_DELIVERIES_LOADING'

export const PICK_UPS_AND_DELIVERIES_GET_DATA =
  'PICK_UPS_AND_DELIVERIES_GET_DATA'
export const PICK_UPS_AND_DELIVERIES_GET_DATA_SUCCESS =
  'PICK_UPS_AND_DELIVERIES_GET_DATA_SUCCESS'

export const PICK_UPS_AND_DELIVERIES_UPDATE_DATA =
  'PICK_UPS_AND_DELIVERIES_UPDATE_DATA'
export const PICK_UPS_AND_DELIVERIES_UPDATE_DATA_SUCCESS =
  'PICK_UPS_AND_DELIVERIES_UPDATE_DATA_SUCCESS'

export const PICK_UPS_AND_DELIVERIES_UPDATE_ITEM =
  'PICK_UPS_AND_DELIVERIES_UPDATE_ITEM'

export const PICK_UPS_AND_DELIVERIES_UPDATE_ITEM_SUCCESS =
  'PICK_UPS_AND_DELIVERIES_UPDATE_ITEM_SUCCESS'

export const BULK_EDIT_TRANSPORTER = 'BULK_EDIT_TRANSPORTER'

export const BULK_EDIT_TRANSPORTER_SUCCESS = 'BULK_EDIT_TRANSPORTER_SUCCESS'

export const PICK_UPS_AND_DELIVERIES_SELECTED_IDS =
  'PICK_UPS_AND_DELIVERIES_SELECTED_IDS'

export const PICK_UPS_AND_DELIVERIES_GET_ONE = 'PICK_UPS_AND_DELIVERIES_GET_ONE'
export const PICK_UPS_AND_DELIVERIES_GET_ONE_SUCCESS =
  'PICK_UPS_AND_DELIVERIES_GET_ONE_SUCCESS'

export const GET_PICK_UPS_AND_DELIVERIES_USER_COLUMNS =
  'GET_PICK_UPS_AND_DELIVERIES_USER_COLUMNS'
export const GET_PICK_UPS_AND_DELIVERIES_USER_COLUMNS_SUCCESS =
  'GET_PICK_UPS_AND_DELIVERIES_USER_COLUMNS_SUCCESS'
export const PICK_UPS_AND_DELIVERIES_USER_COLUMNS_UPDATE =
  'PICK_UPS_AND_DELIVERIES_USER_COLUMNS_UPDATE'
export const PICK_UPS_AND_DELIVERIES_USER_COLUMNS_UPDATE_SUCCESS =
  'PICK_UPS_AND_DELIVERIES_USER_COLUMNS_UPDATE_SUCCESS'

export const UPDATE_PICK_UPS_AND_DELIVERIES_USER_COLUMNS =
  'UPDATE_PICK_UPS_AND_DELIVERIES_USER_COLUMNS'
export const UPDATE_PICK_UPS_AND_DELIVERIES_USER_COLUMNS_SUCCESS =
  'UPDATE_PICK_UPS_AND_DELIVERIES_USER_COLUMNS_SUCCESS'

export const GET_PICK_UPS_AND_DELIVERIES_FILTERS_OPTIONS =
  'GET_PICK_UPS_AND_DELIVERIES_FILTERS_OPTIONS'
export const GET_PICK_UPS_AND_DELIVERIES_FILTERS_OPTIONS_SUCCESS =
  'GET_PICK_UPS_AND_DELIVERIES_FILTERS_OPTIONS_SUCCESS'
export const UPDATE_TASK_STATUS = 'UPDATE_TASK_STATUS'
export const PICK_UPS_AND_DELIVERIES_UPDATE_FILTERS =
  'PICK_UPS_AND_DELIVERIES_UPDATE_FILTERS'
export const PICK_UPS_AND_DELIVERIES_UPDATE_QUICK_FILTER =
  'PICK_UPS_AND_DELIVERIES_UPDATE_QUICK_FILTER'
export const PICK_UPS_AND_DELIVERIES_SET_DRAWER =
  'PICK_UPS_AND_DELIVERIES_SET_DRAWER'
export const PICK_UPS_AND_DELIVERIES_SET_SELECTED =
  'PICK_UPS_AND_DELIVERIES_SET_SELECTED'

export const SEND_TRANSPORT_EMAIL = 'SEND_TRANSPORT_EMAIL'
export const SEND_TRANSPORT_EMAIL_SUCCESS = 'SEND_TRANSPORT_EMAIL_SUCCESS'
export const PICK_UPS_AND_DELIVERIES_TOGGLE_TRANSPORT_WINDOW =
  'PICK_UPS_AND_DELIVERIES_TOGGLE_TRANSPORT_WINDOW'
export const PICK_UPS_AND_DELIVERIES_TOGGLE_EDIT_WINDOW =
  'PICK_UPS_AND_DELIVERIES_TOGGLE_EDIT_WINDOW'
export const PICK_UPS_AND_DELIVERIES_SET_OPEN_ADDRESS_INDEX =
  'PICK_UPS_AND_DELIVERIES_SET_OPEN_ADDRESS_INDEX'
export const UPDATE_PICK_UPS_AND_DELIVERIES_SHIPMENT_STATUS =
  'UPDATE_PICK_UPS_AND_DELIVERIES_SHIPMENT_STATUS'
export const UPDATE_PICK_UPS_AND_DELIVERIES_SHIPMENT_STATUS_SUCCESS =
  'UPDATE_PICK_UPS_AND_DELIVERIES_SHIPMENT_STATUS_SUCCESS'
export const UPDATE_PICK_UPS_AND_QUICK_FILTER =
  'UPDATE_PICK_UPS_AND_QUICK_FILTER'
export const ADD_PICK_UPS_AND_QUICK_FILTER = 'ADD_PICK_UPS_AND_QUICK_FILTER'
export const UPDATE_PICK_UPS_AND_QUICK_FILTER_SUCCESS =
  'UPDATE_PICK_UPS_AND_QUICK_FILTER_SUCCESS'
export const ADD_PICK_UPS_AND_QUICK_FILTER_SUCCESS =
  'ADD_PICK_UPS_AND_QUICK_FILTER_SUCCESS'
export const UPDATE_PICK_UPS_AND_QUICK_FILTERS =
  'UPDATE_PICK_UPS_AND_QUICK_FILTERS'
export const DELETE_PICK_UPS_AND_QUICK_FILTER =
  'DELETE_PICK_UPS_AND_QUICK_FILTER'
export const UPDATE_PICK_UPS_AND_QUICK_FILTERS_SUCCESS =
  'UPDATE_PICK_UPS_AND_QUICK_FILTERS_SUCCESS'
export const DELETE_PICK_UPS_AND_QUICK_FILTER_SUCCESS =
  'DELETE_PICK_UPS_AND_QUICK_FILTER_SUCCESS'
export const PICK_UPS_AND_DELIVERIES_SET_QUICK_FILTERS_DRAWER =
  'PICK_UPS_AND_DELIVERIES_SET_QUICK_FILTERS_DRAWER'

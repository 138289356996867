import { set } from 'lodash'
import {
  Divider,
  Grid,
  Stack,
  Chip,
  Button,
  Box,
  Typography,
} from '@mui/material'
import { Delete } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Multiselect } from 'src/stories/Multiselect'
import { useFormContext } from '../../../FormContext'
import { TemplateFormContextProps } from '../../../Form.props'

const CollaboratorsCollaborator = ({ collaborator }) => {
  const { formState, onChange } = useFormContext() as TemplateFormContextProps

  const { collaborators } = formState
  const { value: id } = collaborator
  const { shipmentRoleIds, locked, shipmentRoleOptions } = collaborator

  const onRolesChange = (updatedValue) => {
    const newCollaborators =
      collaborators?.map((c) => {
        if (c.id === id) {
          return {
            ...c,
            shipmentRoleIds: updatedValue,
          }
        }
        return c
      }) ?? []

    onChange(set(formState, 'collaborators', newCollaborators))
  }

  const shipmentRolesList = shipmentRoleOptions.filter((role) =>
    shipmentRoleIds.includes(role.id)
  )

  const onDeleteClick = (id) => {
    const newCollaborators = collaborators?.filter((c) => c.id !== id) ?? []
    onChange(set(formState, 'collaborators', newCollaborators))
  }

  /* istanbul ignore next */
  const detectDisabledOption = (option: any): boolean => {
    if (!shipmentRoleIds.length) return false
    if (shipmentRoleIds.includes(1030)) return option.id !== 1030 // 1030 is visibility provider
    if (!shipmentRoleIds.includes(1030)) return option.id === 1030
    return false
  }

  const { t } = useTranslation()

  return (
    <>
      <Grid item xs={6} display="flex" alignItems="center">
        <Typography variant="body1Strong" children={collaborator.label} />
      </Grid>
      <Grid item xs={6}>
        <Stack spacing={1} height="100%" direction="row" alignItems="center">
          {locked &&
            shipmentRolesList.map((role) => (
              <Chip color="primary" key={role.id} label={role.name} />
            ))}
          {!locked && (
            <Stack spacing={2} width="100%" direction="row" alignItems="center">
              <Box flexGrow={1}>
                {shipmentRoleOptions.length === 0 && (
                  <Typography variant="body2">
                    No roles available for this organization
                  </Typography>
                )}
                {shipmentRoleOptions.length > 0 && (
                  <Box data-testid="shipment-template-collaborator-role-select">
                    <Multiselect
                      name=""
                      onChange={onRolesChange}
                      optionLabel="name"
                      options={shipmentRoleOptions}
                      disabledOption={detectDisabledOption}
                      values={shipmentRoleIds}
                      placeholder={t('common.select', 'Select')}
                    />
                  </Box>
                )}
              </Box>
              <Button
                size="medium"
                data-testid="shipment-template-collaborator-remove-button"
                startIcon={<Delete />}
                onClick={() => {
                  onDeleteClick(id)
                }}
              >
                Remove
              </Button>
            </Stack>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </>
  )
}

export default CollaboratorsCollaborator

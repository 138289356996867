import Box from '@mui/material/Box'
import MUIAvatar from '@mui/material/Avatar'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { Avatar } from 'src/stories/Avatar'

interface ShipmentCostsPartyProps {
  name?: string | null
  label?: string
  marginTop?: number
  className?: string
}

export const ShipmentCostsParty: React.FC<ShipmentCostsPartyProps> = ({
  name,
  label = '',
  marginTop = 0,
  className = '',
}) => {
  return (
    <Box mt={marginTop} display="flex" sx={{ alignItems: 'center' }}>
      {!name && (
        <>
          <Skeleton width="82px" sx={{ mr: 1 }}>
            <Typography variant="h4">.</Typography>
          </Skeleton>
          <Skeleton variant="circular">
            <MUIAvatar />
          </Skeleton>
          <Skeleton width="82px" sx={{ ml: 1 }}>
            <Typography variant="h4">.</Typography>
          </Skeleton>
        </>
      )}
      {name && (
        <>
          <Typography mr={1} variant="body1Strong" children={label} />
          <Avatar
            {...{
              alt: name,
              className,
              src: 'no-logo',
              variant: 'circular',
            }}
          />
          <Typography ml={1} variant="body1" children={name} />
        </>
      )}
    </Box>
  )
}

import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import {
  IDemurrageAndDetentionRow,
  IDemurrageAndDetentionColumn,
  IDemurrageAndDetentionSection,
} from 'src/@types/endpoints/demurrageAndDetention'
import DemurrageAndDetentionContentRatesTable from './DemurrageAndDetentionContentRatesTable'
import {
  getDemurrageAndDetentionServiceItemSections,
  sortedDemurrageAndDetentionServiceItems,
} from './DemurrageAndDetentionContent.utils'
import DemurrageAndDetentionContentRatesSectionHeader from './DemurrageAndDetentionContentRatesSectionHeader'

const DemurrageAndDetentionContentRatesSections: React.FC<{
  items: IDemurrageAndDetentionRow[]
  columns: IDemurrageAndDetentionColumn[]
  showSectionSubtitle: boolean
}> = ({ items, columns, showSectionSubtitle }) => {
  const { t } = useTranslation()
  const sections: IDemurrageAndDetentionSection[] = getDemurrageAndDetentionServiceItemSections(
    items
  )
  const sortedSection: IDemurrageAndDetentionSection[] = sortedDemurrageAndDetentionServiceItems(
    sections,
    'group'
  )

  const subtitle = showSectionSubtitle
    ? t(
        'demurrage_and_detention.section_subtitle',
        'POD: Rotterdam and Antwerp'
      )
    : null

  return (
    <>
      {sortedSection.map(
        (section: IDemurrageAndDetentionSection, sectionIndex: number) => (
          <Box
            key={`section${sectionIndex}`}
            data-testid="demurrage-and-detention-rates-section"
          >
            <DemurrageAndDetentionContentRatesSectionHeader
              title={section.group}
              subtitle={subtitle}
            />
            <DemurrageAndDetentionContentRatesTable
              section={section}
              columns={columns}
            />
          </Box>
        )
      )}
    </>
  )
}

export default DemurrageAndDetentionContentRatesSections

import { reverse } from 'lodash'
import { LegendProps } from 'recharts'

export const CustomLegend: React.FC<LegendProps> = (props) => {
  const labels = reverse(props.payload || [])
  return (
    <div className="shypple-custom-legend-costs">
      {labels.map((entry, index) => (
        <div key={index}>
          <div
            className="shypple-custom-legend__color"
            style={{ borderColor: entry.color }}
          />
          <span className="shypple-custom-legend__text"> {entry.value} </span>
        </div>
      ))}
    </div>
  )
}

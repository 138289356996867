import { showNotification } from 'src/stores/actionCreators/notifications'
import { SERVER_ERRORS } from 'src/config/constants'
import { put } from 'redux-saga/effects'

export default function* getErrors(error) {
  if (error.message === 'canceled') {
    return
  }
  let msg: string = ''
  switch (true) {
    case error.message === 'Network Error':
      msg = SERVER_ERRORS['network_error']
      break
    case error.code === 'ECONNABORTED':
      msg = SERVER_ERRORS['connection_aborted']
      break
    case error.response && error.response.status === 500:
      msg = SERVER_ERRORS['technical_error']
      break
    default:
      msg = error.response.data.message
      break
  }
  if (msg) {
    yield put(
      showNotification({
        message: msg,
        severity: 'error',
      })
    )
  }
}

import uniqBy from 'lodash/uniqBy'
import set from 'lodash/set'
import { useEffect, useState } from 'react'
import { useGetAutocompleteAsync } from 'src/services/Api/common'
import { getAutocompleteSelectOptions } from '../../Form.utils'
import { useFormContext } from '../../FormContext'
import AsyncAutocomplete from '../FormElements/AsyncAutocomplete'

const Port = (props) => {
  const {
    fetchAsync: getPorts,
    isFetching: portsFetching,
  } = useGetAutocompleteAsync()

  const { fieldPath, label, required = false } = props
  const { formState, onChange } = useFormContext()

  const { modality } = formState

  const selected = formState[fieldPath] ?? null

  const defaultOptions = selected ? [{ ...selected, type: modality }] : []

  const [fetchedOptions, setFetchedOptions] = useState(defaultOptions)

  const options = uniqBy([...fetchedOptions, ...defaultOptions], 'value')

  const onSelectChange = (newSelection) => {
    onChange(set(formState, fieldPath, newSelection))
  }

  const onInputChange = async (input: string) => {
    const hasOption = options.find((option) => option.label === input)
    if (input.length === 0 || !!hasOption) {
      return
    }
    const ports = await getPorts({
      input,
      only_ports: true,
      with_ports: true,
      shipment_type: formState.modality,
    })

    const newlyFetchedOptions =
      ports?.data?.places.map((port) => ({
        label: port?.main_text ?? '',
        value: port?.port_id ?? port?.place_id ?? '',
        type: modality,
      })) ?? []

    const newOptions =
      getAutocompleteSelectOptions(defaultOptions, newlyFetchedOptions) ?? []

    setFetchedOptions(newOptions)
  }

  useEffect(() => {
    if (selected === null) {
      setFetchedOptions([])
    }
  }, [selected])

  return (
    <>
      <AsyncAutocomplete
        label={label}
        isFetched={true}
        options={options}
        required={required}
        selected={selected}
        onChange={onSelectChange}
        onInputChange={onInputChange}
        isFetching={portsFetching}
        data-testid={`port-autocomplete-${fieldPath}`}
      />
    </>
  )
}

export default Port

const primary = {
  50: '#E6E6F0',
  100: '#C1C1DB',
  200: '#999AC2',
  300: '#7273AA',
  400: '#585699',
  500: '#3E3A88',
  600: '#383380',
  700: '#312A75',
  800: '#292169',
  900: '#1C1053',
  A100: '#D4D4E6',
}

export default primary

import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@mui/material'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import { FullScreenContext } from 'src/pages/Layout'
import Tooltip from '@mui/material/Tooltip'

const FullScreenToggleButton = () => {
  const { t } = useTranslation()
  const { isFullScreen, setFullScreen } = useContext(FullScreenContext)

  const onFullscreenButtonClick = () => {
    setFullScreen(!isFullScreen)
  }

  return (
    <Tooltip
      title={t('common.tooltips.toggle_fullscreen', 'Toggle fullscreen')}
      arrow
    >
      <IconButton aria-label="fullscreen" onClick={onFullscreenButtonClick}>
        {isFullScreen && <FullscreenExitIcon />}
        {!isFullScreen && <FullscreenIcon />}
      </IconButton>
    </Tooltip>
  )
}

export default FullScreenToggleButton

import { createReducer, Reducer } from 'redux-create-reducer'
import { AnyAction } from 'redux'
import * as actions from '../actions/ports'

declare global {
  type PortType = 'seaport' | 'airport'

  type PortStorageType = 'pol' | 'pod'

  interface IPortShort {
    id: number
    name: string
    code: string
  }

  interface IPort extends IPortShort {
    display_name: string
    type: PortType
    country: ICountry
    time_zone: string
  }

  interface IPortPair {
    id: number
    loading_port: IPort
    discharge_port: IPort
  }

  interface IPortsReducerState {
    isFetching: boolean
    items: IPort[]
    pol: {
      isFetching: boolean
      items: IPort[]
    }
    pod: {
      isFetching: boolean
      items: IPort[]
    }
  }
}

const receivePorts: Reducer<IPortsReducerState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  items: action.payload,
  isFetching: false,
})

const requestPols: Reducer<IPortsReducerState, AnyAction> = (state) => ({
  ...state,
  pol: {
    ...state.pol,
    isFetching: true,
  },
})

const receivePreloadedPols: Reducer<IPortsReducerState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  pol: {
    items: action.payload,
    isFetching: false,
  },
})

const requestPods: Reducer<IPortsReducerState, AnyAction> = (state) => ({
  ...state,
  pod: {
    ...state.pod,
    isFetching: true,
  },
})

const receivePreloadedPods: Reducer<IPortsReducerState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  pod: {
    items: action.payload,
    isFetching: false,
  },
})

const clearList: Reducer<IPortsReducerState, AnyAction> = (state, action) => {
  if (action.storageType) {
    return {
      ...state,
      [action.storageType]: {
        ...state[action.storageType],
        items: [],
      },
    }
  } else {
    return {
      ...state,
      items: [],
    }
  }
}

export const initialPortsState = {
  isFetching: false,
  items: [] as IPort[],
  pol: {
    isFetching: false,
    items: [] as IPort[],
  },
  pod: {
    isFetching: false,
    items: [] as IPort[],
  },
}

export default createReducer(initialPortsState, {
  [actions.PORTS_GET_DATA_SUCCESS]: receivePorts,
  [actions.PORTS_PRELOAD_POLS]: requestPols,
  [actions.PORTS_PRELOAD_POLS_SUCCESS]: receivePreloadedPols,
  [actions.PORTS_PRELOAD_POLS]: requestPods,
  [actions.PORTS_PRELOAD_PODS_SUCCESS]: receivePreloadedPods,
  [actions.PORTS_CLEAR_DATA]: clearList,
})

import { FunctionComponent, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { find } from 'lodash'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { Box, Divider, Link, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Tabs, { TabComponentProps } from 'src/stories/Tabs'
import { ModalityEnum, chipColor } from 'src/config/constants'
import { showNotification } from 'src/stores/actionCreators/notifications'
import UserChannelClient from 'src/components/SocketHandlers/UserChannelClient'
import TabPanel from 'src/components/Common/TabPanel'
import ModalitySelect from 'src/components/Common/ModalitySelect'
import SelectableChip from 'src/components/SelectableChip'

import { getTranslationForModality } from '../../../utils/helpers'

import { MilestoneTable } from '../../../stories/MilestoneTable'
import AddressInfoBlock from '../AddressInfoBlock'
import HsCodesBlock from '../HsCodesBlock'
import ContainerEventsBlock from '../ContainerEventsBlock'
import ContainerDemurrageAndDetention from '../ContainerDemurrageAndDetention'

import { CargoBlock } from '../CargoBlock/'
import { permissionTo, promisifyAction } from '../../../utils/'

import {
  acceptInlandTransportStatus,
  updateCurrentModality,
  toggleHsModalOpen,
} from '../../../stores/actionCreators'
import ReeferSettingsOverview from './ReeferSettingsOverview'
import SpecialRequirements from './SpecialRequirements'

import './styles.scss'
import { EstimatedPickupOrDeliveryDate } from './EstimatedPickupOrDelivery'
import { getIsEstimated } from './constants'

interface IProps {
  data: IShipmentContainer
  fetchData: () => void
  addNewDocument: () => void
  openDateTimeModal: (transport: IInlandTransport, containerId: number) => void
  isOriginPhase: boolean
  isLclOrAir: boolean
  isSailingTBA: boolean
  shipmentModality: ModalityEnum
  tradeDirection: string
  freightServiceRequested: boolean
  destinationDemurrageStartingFrom: string | null
  openAddressModal: (
    address: IInlandTransportAddress | null,
    service: InlandTransportService,
    containerId: number
  ) => void
}

const ContainerOverviewExpanded: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [tabValue, setTabValue] = useState<string>('pickup_delivery')

  const {
    cargo,
    inland_transports,
    fresh_inspection_task,
    delivery_inspection_datetime,
  } = props.data

  const acceptInlandTransportStatusAsync = promisifyAction(
    dispatch,
    acceptInlandTransportStatus
  )

  const updateCurrentModalityAsync = promisifyAction(
    dispatch,
    updateCurrentModality
  )

  const pickUpView: boolean = permissionTo(
    'shipments.containers_|_cargo.pickup.view'
  )

  const pickUpManage: boolean = permissionTo(
    'shipments.containers_|_cargo.pickup.manage'
  )

  const deliveryView: boolean = permissionTo(
    'shipments.containers_|_cargo.delivery.view'
  )

  const deliveryManage: boolean = permissionTo(
    'shipments.containers_|_cargo.delivery.manage'
  )

  const permissionToViewHsCodes: boolean = permissionTo(
    'shipments.containers.hs_codes.view'
  )

  const permissionToViewDemurrageAndDetention: boolean = permissionTo(
    'demurrage_and_detention_prices.view'
  )

  const tabs: TabComponentProps[] = [
    {
      label: t('shipment_containers.tabs.services', 'Pick-up & delivery'),
      value: 'pickup_delivery',
      iconDirection: 'vertical',
      icon: (
        <LocalShippingIcon style={{ fontSize: '20px', margin: '4px 0 0 0' }} />
      ),
    },
    {
      label: t('shipment_containers.tabs.goods', 'Goods'),
      value: 'goods',
      iconDirection: 'vertical',
      icon: <i className="icon box" style={{ fontSize: '20px', height: 20 }} />,
    },
    {
      label: t('shipment_containers.tabs.activity_logs', 'Activity log'),
      value: 'events',
      iconDirection: 'vertical',
      icon: (
        <div className="icon-container">
          <DateRangeIcon style={{ fontSize: '20px', margin: '4px 0 0 0' }} />
          {props.data.container_event_exceptions.length ? (
            <i className="icon attention" />
          ) : null}
        </div>
      ),
    },
  ]

  if (permissionToViewHsCodes) {
    tabs.splice(2, 0, {
      label: t('shipment_containers.tabs.hs_codes', 'HS codes'),
      value: 'hs_codes',
      iconDirection: 'vertical',
      icon: (
        <i
          className="icon hs-code"
          style={{
            height: 22,
            fontSize: '16px',
            margin: '4px 0 0 0',
          }}
        />
      ),
    })
  }

  const pickupTransport = find(inland_transports, { service: 'pickup' })
  const deliveryTransport = find(inland_transports, { service: 'delivery' })

  const pickupAddress =
    pickupTransport?.inland_transport_addresses.find(
      (a) => a.pickup_delivery
    ) || null
  const deliveryAddress =
    deliveryTransport?.inland_transport_addresses.find(
      (a) => a.pickup_delivery
    ) || null

  const dateTimeBlock = (transport: IInlandTransport): React.ReactNode => {
    const isPickup = transport.service === 'pickup'
    const dateRange = t('common.date_time_range_med', {
      defaultValue: '{{value, DATETIME_RANGE_MED}}',
      value: [transport?.pickup_delivery_time, transport?.closing_time],
    })
    return (
      <>
        {transport.pickup_delivery_time_editable &&
        (isPickup ? pickUpManage : deliveryManage) ? (
          <Link
            variant="body1"
            component="button"
            onClick={isPickup ? requestPickupTime : requestDeliveryTime}
          >
            {dateRange}
          </Link>
        ) : (
          <div className="blue-text">{dateRange}</div>
        )}
      </>
    )
  }

  const openAddressDetails = (service: InlandTransportService) => {
    props.openAddressModal(
      service === 'pickup' ? pickupAddress : deliveryAddress,
      service,
      props.data.id
    )
  }

  const openAddressModalWithError = (service: InlandTransportService): void => {
    props.openAddressModal(null, service, props.data.id)
    showErrorMessage(service)
  }

  const requestPickupTime = (): void => {
    if (pickupTransport) {
      props.openDateTimeModal(pickupTransport, props.data.id)
    } else {
      openAddressModalWithError('pickup')
    }
  }

  const requestDeliveryTime = () => {
    if (deliveryTransport) {
      props.openDateTimeModal(deliveryTransport, props.data.id)
    } else {
      openAddressModalWithError('delivery')
    }
  }

  const onReceived = (data: ISocketMessage): void => {
    if (data.message_type === 'task_resolved') {
      props.fetchData()
    }
  }

  const showErrorMessage = (service: InlandTransportService) => {
    dispatch(
      showNotification({
        message: t('shipment_containers.notifications.enter_service_address', {
          defaultValue: 'Please enter your {{service}} address first.',
          service,
        }),
        severity: 'error',
      })
    )
  }

  const acceptPickupDateAndTime = async (): Promise<any> => {
    if (pickupAddress?.address_id) {
      await acceptInlandTransportStatusAsync(props.data.id, {
        service_type: 'pickup',
      })
      props.fetchData()
    } else {
      openAddressModalWithError('pickup')
    }
  }

  const acceptDeliveryDateAndTime = async (): Promise<any> => {
    if (deliveryAddress?.address_id) {
      await acceptInlandTransportStatusAsync(props.data.id, {
        service_type: 'delivery',
      })
      props.fetchData()
    } else {
      openAddressModalWithError('delivery')
    }
  }

  const handleModalitySelect = (modality): void => {
    if (deliveryTransport?.id) {
      updateModality(deliveryTransport?.id, modality)
    }
  }

  const updateModality = async (
    deliveryTransportId,
    modalityId
  ): Promise<void> => {
    await updateCurrentModalityAsync(deliveryTransportId, {
      modality: modalityId,
    })
    props.fetchData()
  }

  const onTabsChange = (newValue: string) => {
    setTabValue(newValue)
  }

  const onClickMilestone = useCallback(
    (task: IMilestoneTask) => {
      switch (task.task_type.code) {
        case 'add_pickup_address':
          openAddressDetails('pickup')
          break
        case 'request_pickup_date':
          if (
            !(
              pickupAddress &&
              getIsEstimated(pickupTransport) &&
              pickupTransport?.pickup_delivery_time_editable &&
              pickUpManage
            )
          ) {
            const message: string = t(
              'shipment_containers.notifications.plan_date_time_after_cargo_closing',
              'Plan date & time once after the cargo closing date is confirmed.'
            )

            dispatch(showNotification({ message, severity: 'error' }))
          } else {
            requestPickupTime()
          }
          break
        case 'add_delivery_address':
          openAddressDetails('delivery')
          break
        case 'request_delivery_date':
          if (
            !(
              getIsEstimated(deliveryTransport) &&
              deliveryTransport?.pickup_delivery_time_editable &&
              deliveryManage
            )
          ) {
            const message: string = t(
              'shipment_containers.notifications.plan_date_time_after_delivery_available_from',
              'Plan date & time after the delivery available from date is indicated.'
            )

            dispatch(showNotification({ message, severity: 'error' }))
          } else {
            requestDeliveryTime()
          }
          break
        case 'add_import_hs_code':
        case 'add_export_hs_code':
          setTabValue('hs_codes')
          dispatch(
            toggleHsModalOpen({
              open: true,
              containerId: props.data.id,
              containerType: props.data.container_type.code,
            })
          )
          break
        default:
          props.addNewDocument()
          break
      }
    },
    [pickupAddress, deliveryAddress, pickUpManage, deliveryManage]
  )

  const estimatedPickupProps = {
    title: t('shipment_containers.pickup_date_and_time', 'Pick-up date & time'),
    isSailingTBA: props.isSailingTBA,
    transport: pickupTransport,
    canManage: pickUpManage,
    requestPickupOrDeliveryTime: requestPickupTime,
    dateTimeBlock,
    acceptPickupOrDeliveryDateAndTime: acceptPickupDateAndTime,
    deliveryInspectionDatetime: null,
  }

  const estimatedDeliveryProps = {
    title: t(
      'shipment_containers.delivery_date_and_time',
      'Delivery date & time'
    ),
    isSailingTBA: props.isSailingTBA,
    transport: deliveryTransport,
    canManage: deliveryManage,
    requestPickupOrDeliveryTime: requestDeliveryTime,
    dateTimeBlock,
    acceptPickupOrDeliveryDateAndTime: acceptDeliveryDateAndTime,
    deliveryInspectionDatetime: props.data.delivery_inspection_datetime,
  }

  const getEstimatedPickupOrDelivery = ({
    transportType,
    transportProps,
  }: {
    transportType: IInlandTransport | undefined
    transportProps: typeof estimatedPickupProps | typeof estimatedDeliveryProps
  }) => {
    const isModalityAir = props.shipmentModality === ModalityEnum.Air
    if ((isModalityAir && !getIsEstimated(transportType)) || !isModalityAir) {
      return (
        <EstimatedPickupOrDeliveryDate
          transportType={transportType?.service}
          {...transportProps}
        />
      )
    }
    return null
  }

  const inspectionStatus = () => {
    const { task_status } = fresh_inspection_task
    return (
      <Box className="modality-block">
        <Box className="date-time-info--title">
          <Typography
            variant="body1Strong"
            children={t('shipment_containers.inspection', 'Inspection')}
          />
        </Box>
        <Box display="flex" alignItems="center">
          {delivery_inspection_datetime && (
            <Box mr={1}>
              {t('common.date_medium', {
                defaultValue: '{{date, DATE_MED}}',
                date: delivery_inspection_datetime,
              })}
            </Box>
          )}
          <SelectableChip
            variant="filled"
            disabled={true}
            value={{
              color: task_status.color
                ? chipColor[task_status.color]
                : 'default',
              name: task_status.name,
            }}
          />
        </Box>
      </Box>
    )
  }

  const actualDayOfDemurrage = props.data.actual_first_day_of_demurrage
  const destinationDemurrage = props.destinationDemurrageStartingFrom

  return (
    <Box px={2} className="container-overview-expanded">
      <UserChannelClient onReceived={onReceived} />
      <MilestoneTable
        milestones={props.data.milestones}
        onClick={onClickMilestone}
        resolvedTasksMessage={t('shipment_containers.no_tasks_for_container', {
          defaultValue: 'You have no open tasks for this {{type}}.',
          type: getTranslationForModality(props.shipmentModality, 'container'),
        })}
      />
      <Divider className="mt-16 mb-16" />
      {props.data.seal_number && (
        <>
          <Typography
            variant="h5"
            children={t(
              'shipment_containers.container_details',
              'Container details'
            )}
            pb={2}
          />
          <Box>
            <Typography
              variant="body1Strong"
              children={t('shipment_containers.seal_number', 'Seal number')}
            />
            <Typography
              data-testid="shipment-container-seal-number-value"
              variant="body1"
              children={props.data.seal_number}
            />
          </Box>
          <Divider className="mt-16 mb-16" />
        </>
      )}
      {props.data.equipment_controlled_atmosphere && (
        <>
          <ReeferSettingsOverview data={props.data} />
          <Divider className="mt-16 mb-16" />
        </>
      )}
      {permissionToViewDemurrageAndDetention &&
        props.data.demurrage_and_detention &&
        props.data.demurrage_and_detention.length > 0 && (
          <ContainerDemurrageAndDetention
            demurrageAndDetentionItems={props.data.demurrage_and_detention}
            demurrageDates={{
              actualDayOfDemurrage,
              destinationDemurrage,
            }}
            freightServiceRequested={props.freightServiceRequested}
          />
        )}
      <SpecialRequirements container={props.data} />
      <div className="container-overview-expanded--tabs">
        <Tabs value={tabValue} onChange={onTabsChange} tabs={tabs} />
        <TabPanel value={tabValue} index="pickup_delivery">
          <div className="container-overview-expanded--description">
            {pickUpView && (
              <div className="right-block" data-testid="pickup">
                <div className="container-overview-expanded--title">
                  <Typography
                    variant="h5"
                    children={t('shipment_containers.pickup', 'Pick-up')}
                  />
                </div>
                <AddressInfoBlock
                  title={t(
                    'shipment_containers.pickup_address',
                    'Pick-up address'
                  )}
                  service={'pickup'}
                  data={pickupAddress}
                  openAddressModal={openAddressDetails}
                  containerAddress={true}
                />
                {getEstimatedPickupOrDelivery({
                  transportType: pickupTransport,
                  transportProps: estimatedPickupProps,
                })}
                <div className="modality-block">
                  <ModalitySelect
                    inlandOnly={true}
                    defaultValue={
                      (pickupTransport?.modality as ModalityEnum) ?? ''
                    }
                    disabled={true}
                    onChangeModality={handleModalitySelect}
                  />
                </div>
              </div>
            )}
            {deliveryView && (
              <div className="left-block" data-testid="delivery">
                <div className="container-overview-expanded--title">
                  <Typography
                    variant="h5"
                    children={t('shipment_containers.delivery', 'Delivery')}
                  />
                </div>
                <AddressInfoBlock
                  title={t(
                    'shipment_containers.delivery_address',
                    'Delivery address'
                  )}
                  service={'delivery'}
                  data={deliveryAddress}
                  openAddressModal={openAddressDetails}
                  containerAddress={true}
                />
                {getEstimatedPickupOrDelivery({
                  transportType: deliveryTransport,
                  transportProps: estimatedDeliveryProps,
                })}
                {fresh_inspection_task && inspectionStatus()}
                <div className="modality-block">
                  <ModalitySelect
                    inlandOnly={true}
                    defaultValue={
                      (deliveryTransport?.modality as ModalityEnum) ?? ''
                    }
                    disabled={props.isLclOrAir}
                    onChangeModality={handleModalitySelect}
                  />
                </div>
              </div>
            )}
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index="goods">
          <CargoBlock
            isEditable
            shipmentModality={props.shipmentModality}
            cargo={cargo}
            containerId={props.data.id}
            fetchContainerData={props.fetchData}
            totalWeightKg={props.data.total_cargo_weight_kg}
            totalVolumeCBM={props.data.total_volume_cbm}
          />
        </TabPanel>
        {permissionToViewHsCodes && (
          <TabPanel value={tabValue} index="hs_codes">
            <HsCodesBlock
              hsCodes={props.data.hs_codes}
              containerId={props.data.id}
              containerType={props.data.container_type.code}
            />
          </TabPanel>
        )}
        <TabPanel value={tabValue} index="events">
          <ContainerEventsBlock
            container_events={props.data.container_events}
          />
        </TabPanel>
      </div>
    </Box>
  )
}

export default ContainerOverviewExpanded

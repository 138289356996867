export const getStateFromQueryString = (
  state: IReportsDashboardFilters,
  formatters
): IReportsDashboardFilters => {
  return Object.keys(state).reduce((res, fieldName) => {
    const fieldValue = state[fieldName]
    const formatter = formatters[fieldName]
    if (formatter) return { ...res, [fieldName]: formatter(fieldValue) }
    return { ...res, [fieldName]: fieldValue }
  }, {} as IReportsDashboardFilters)
}

import { FunctionComponent, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { last } from 'lodash'

interface IProps {
  onReceived: (message: ISocketMessage) => void
}

const UserChannelClient: FunctionComponent<IProps> = (props) => {
  const { userChannelMessages } = useSelector((state: IGlobalState) => ({
    userChannelMessages: state.socketMessages.userChannelMessages,
  }))

  useEffect(() => {
    if (!userChannelMessages.length) {
      return
    }
    props.onReceived(last(userChannelMessages) as ISocketMessage)
  }, [userChannelMessages])

  return null
}

export default UserChannelClient

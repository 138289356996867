import { AnyAction } from 'redux'
import { put } from 'redux-saga/effects'
import { authorizedRequest } from '../wrappers'
import { Logger } from '../../../utils'
import { serverErrors } from '../../../utils/helpers'
import * as actionCreators from '../../actionCreators/searches'

export default function* (action: AnyAction): Iterator<any> {
  try {
    const response = yield authorizedRequest({
      method: 'GET',
      url: 'api/v1/request_quotes/search',
      params: action.payload,
    })
    yield put(actionCreators.searchQuotesSuccess(response))
  } catch (error) {
    const unknownError: any = error
    yield* serverErrors(unknownError)
    yield put(actionCreators.searchQuotesError())
    yield Logger.sagaError(unknownError, 'store::user::loadState')
  }
}

import get from 'lodash/get'
import set from 'lodash/set'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ReferenceTextArea } from 'src/components/ShipmentReferencesModal'
import { useFormContext } from '../../FormContext'

const TemplateFormReference = ({ fieldPath, label, placeholder }) => {
  const { formState, onChange } = useFormContext()

  const onReferenceChange = (event) => {
    onChange(set(formState, fieldPath, event?.target.value))
  }

  const { t } = useTranslation()

  return (
    <Box>
      <ReferenceTextArea
        isLoading={false}
        name={fieldPath}
        value={get(formState, fieldPath, '') ?? ''}
        onChange={onReferenceChange}
        placeholder={placeholder}
        label={label}
        subLabel={t(
          'templates.collaborators.shared_reference_instruction',
          'Visible to and editable by all collaborators'
        )}
        collaborators={[]}
        error={''}
      />
    </Box>
  )
}

export default TemplateFormReference

import { FunctionComponent } from 'react'
import { capitalize } from 'lodash'
import Box from '@mui/material/Box'
import SelectableChip from 'src/components/SelectableChip'
import getColor from './helpers'

const TrackTraceStatusField: FunctionComponent<{
  trackTraceStatus: string
}> = ({ trackTraceStatus }) => {
  return (
    <Box>
      <SelectableChip
        data-testid="trackTraceStatus"
        disabled
        sx={{
          marginLeft: 2,
          '@media (min-width: 600px) and (max-width: 1424px)': {
            marginLeft: 0,
            marginTop: 1,
          },
        }}
        size="small"
        variant="filled"
        value={{
          name: capitalize(trackTraceStatus.replace('_', ' ')),
          color: getColor(trackTraceStatus),
        }}
      />
    </Box>
  )
}

export default TrackTraceStatusField

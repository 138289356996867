import { useTranslation } from 'react-i18next'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import { LoadTypeEnum, ModalityEnum } from 'src/config/constants'
import DeliveryInfoItem from './DeliveryInfoItem'
import { DeliveryInfoProps } from './DeliveryInfo.props'
import DeliveryInfoItemFirstDayOfDemurrage from './DeliveryInfoItemFirstDayOfDemurrage'

const DeliveryInfo: React.FC<DeliveryInfoProps> = ({
  loadType,
  modality,
  deliveryAvailableFrom,
  actualFirstDayOfDemurrage,
  destinationDemurrageStartingFrom,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <Divider sx={{ my: 3 }} />
      <Stack direction="row">
        <DeliveryInfoItem
          title={t(
            'transports.drawer.delivery_available_from',
            'Delivery available from'
          )}
          date={deliveryAvailableFrom}
        />
        {modality === ModalityEnum.Sea && loadType === LoadTypeEnum.fcl && (
          <DeliveryInfoItemFirstDayOfDemurrage
            {...{ actualFirstDayOfDemurrage, destinationDemurrageStartingFrom }}
          />
        )}
      </Stack>
    </>
  )
}

export default DeliveryInfo

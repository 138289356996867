import { useTranslation } from 'react-i18next'
import { getLoadTypeToggleOptions } from '../../Form.utils'
import { useFormContext } from '../../FormContext'
import ToggleButton from '../FormElements/ToggleButton'

const LoadType = (props) => {
  const { formState } = useFormContext()
  const { modality } = formState
  const options = getLoadTypeToggleOptions(modality)
  const { t } = useTranslation()

  return (
    <ToggleButton
      fieldPath="loadType"
      label={t('templates.shipment_details.load_type', 'Load Type')}
      data-testid="shipment-template-load-type"
      required={true}
      options={options}
    />
  )
}

export default LoadType

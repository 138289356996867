import { useState, ChangeEvent } from 'react'
import Box from '@mui/material/Box'
import Pagination from '@mui/material/Pagination'
import { ShipmentContent } from 'src/stories/MUI/ShipmentContent'
import { TooltipContentProps } from './MapTooltip.props'

const getPaginationProps = (size: number) => {
  if (size < 7) {
    return {
      hidePrevButton: true,
      hideNextButton: true,
    }
  }
  return {
    siblingCount: 0,
    boundaryCount: 0,
  }
}

interface MapTooltipContentProps {
  shipments: TooltipContentProps[]
}

const MapTooltipContent: React.FC<MapTooltipContentProps> = ({ shipments }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const pageCount = shipments.length
  return (
    <Box>
      {shipments.map((shipment: TooltipContentProps, index: number) => {
        const {
          shared_reference: sharedRef,
          organization_reference: internalRef,
        } = shipment
        return (
          <Box
            key={shipment.id}
            sx={{ display: index === activeIndex ? 'block' : 'none' }}
          >
            <ShipmentContent
              {...{
                showLegs: false,
                direction: 'column',
                data: {
                  id: `${shipment.id}`,
                  title: shipment.title,
                  modality: shipment.modality,
                  shared_reference: !!sharedRef ? sharedRef : '-',
                  internal_reference: !!internalRef ? internalRef : '-',
                  visibility_only: shipment.visibility_only,
                },
              }}
            />
          </Box>
        )
      })}
      {pageCount > 1 && (
        <Pagination
          color="primary"
          count={pageCount}
          sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}
          onChange={(_event: ChangeEvent<unknown>, page: number) => {
            setActiveIndex(page - 1)
          }}
          {...getPaginationProps(pageCount)}
        />
      )}
    </Box>
  )
}

export default MapTooltipContent

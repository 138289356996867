import { FunctionComponent } from 'react'
import Box from '@mui/material/Box'

const mapModalityToIcon = {
  air: 'airport',
  rail: 'train',
  sea: 'sea',
}

const IconField: FunctionComponent<{
  shipment: IReportsShipment
}> = ({ shipment }) => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <i className={`icon ${mapModalityToIcon[shipment.modality]}`}></i>
    <Box style={{ textTransform: 'uppercase' }}>{shipment.load_type}</Box>
  </Box>
)

export default IconField

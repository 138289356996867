import { Divider, Grid, Typography, Stack } from '@mui/material'
import { uniq } from 'lodash'
import Alert from '@mui/material/Alert'
import { useTranslation } from 'react-i18next'
import { useFormContext } from '../../../FormContext'
import { TemplateFormContextProps } from '../../../Form.props'
import SizedBox from '../Box'
import Skeleton from '../../Skeleton'
import TemplateFormReference from '../Reference'
import Collaborator from './CollaboratorsCollaborator'
import CollaboratorsSelect from './CollaboratorsSelect'

const TemplateFormCollaborators = () => {
  const {
    isLoading,
    formState,
    mode,
    shipmentRoles,
  } = useFormContext() as TemplateFormContextProps
  const { bookingParties, collaborators } = formState
  const { consignee, shipper } = bookingParties
  const shipperCollaborator = shipper.collaborator
    ? [
        {
          ...shipper.collaborator,
          shipmentRoleOptions: [{ id: shipmentRoles.shipper, name: 'Shipper' }],
          shipmentRoleIds: [shipmentRoles.shipper],
          locked: true,
        },
      ]
    : []
  const consigneeCollaborator = consignee.collaborator
    ? [
        {
          ...consignee.collaborator,
          shipmentRoleOptions: [
            { id: shipmentRoles.consignee, name: 'Consignee' },
          ],
          shipmentRoleIds: [shipmentRoles.consignee],
          locked: true,
        },
      ]
    : []

  const otherCollaborators =
    collaborators?.map((collaborator) => {
      return {
        ...collaborator,
        locked: false,
        value: collaborator.id,
        label: collaborator.name,
      }
    }) ?? []

  const list = [
    ...shipperCollaborator,
    ...consigneeCollaborator,
    ...otherCollaborators,
  ]

  const bookingPartyRoles = [shipmentRoles.consignee, shipmentRoles.shipper]
  const forwarderRoles = [
    shipmentRoles.origin_forwarder,
    shipmentRoles.destination_forwarder,
  ]

  const shipmentRoleIds = collaborators
    .map((collaborator) => collaborator.shipmentRoleIds)
    .flat()

  const duplicatedRolesDetected =
    uniq(shipmentRoleIds).length !== shipmentRoleIds.length

  const { t } = useTranslation()

  return (
    <>
      <Grid container spacing={2}>
        <Skeleton isLoading={isLoading}>
          <SizedBox columnSize={9}>
            <CollaboratorsSelect
              bookingPartyRoles={bookingPartyRoles}
              forwarderRoles={forwarderRoles}
            />
          </SizedBox>
          <SizedBox columnSize={9}>
            <Grid container columnSpacing={2} rowSpacing={1}>
              {duplicatedRolesDetected && (
                <Grid item xs={12}>
                  <Alert severity="warning" variant="filled">
                    One of the roles was already assigned to another
                    organization in this shipment.
                  </Alert>
                </Grid>
              )}

              <Grid item xs={6}>
                <Typography variant="overline">
                  {t('templates.collaborators.organization', 'Organization')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="overline">
                  {t('templates.collaborators.shipment_role', 'Shipment role')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {list.map((item, index) => (
                <Collaborator
                  key={`${item.value}-${index}`}
                  collaborator={item}
                />
              ))}
              {mode === 'rebook' && (
                <>
                  <Grid item xs={6}>
                    <Stack spacing={2} mt={2}>
                      <TemplateFormReference
                        label={t(
                          'templates.collaborators.shared_reference',
                          'Shared reference'
                        )}
                        fieldPath="references.sharedReference"
                        placeholder={t(
                          'templates.collaborators.shared_reference_placeholder',
                          'Shared reference placeholder'
                        )}
                      />
                      <TemplateFormReference
                        label={t(
                          'templates.collaborators.internal_reference',
                          'Internal reference'
                        )}
                        fieldPath="references.internalReference"
                        placeholder={t(
                          'templates.collaborators.internal_reference_placeholder',
                          'Internal reference placeholder'
                        )}
                      />
                    </Stack>
                  </Grid>
                </>
              )}
            </Grid>
          </SizedBox>
        </Skeleton>
      </Grid>
    </>
  )
}

export default TemplateFormCollaborators

import startCase from 'lodash/startCase'
import Box from '@mui/material/Box'
import TableRow from '@mui/material/TableRow'
import { useTranslation } from 'react-i18next'
import TableBody from '@mui/material/TableBody'
import Typography from '@mui/material/Typography'
import { OrganizationWithAvatar } from 'src/stories/OrganizationWithAvatar'
import { getDateRange } from '../helpers'
import { TableCustom, TableCustomCell } from './RatesLineDetails.styles'

const RatesLineDetailsAirAdditionalInformation: React.FC<{
  rate: IRate
  status: string
}> = ({ status, rate }) => {
  const { t } = useTranslation()
  return (
    <>
      <Typography
        variant="h5"
        children={t(
          'rates.table_content.headings.additional_information',
          'Additional information'
        )}
        data-testid="rates-additional-information"
      />
      <TableCustom size="small">
        <TableBody>
          <TableRow>
            <TableCustomCell>{t('common.status', 'Status')}</TableCustomCell>
            <TableCustomCell>
              <Box typography="body1" className="status-flex">
                <Box className="rates__validity-dates">
                  {getDateRange(rate.valid_from, rate.valid_to)}
                </Box>
                <Box className={`rates__status-element ${status}`}>
                  {startCase(status)}
                </Box>
              </Box>
            </TableCustomCell>
          </TableRow>
          <TableRow>
            <TableCustomCell>
              {t('common.offered_by', 'Offered by')}
            </TableCustomCell>
            <TableCustomCell>
              <OrganizationWithAvatar organization={rate.seller_organization} />
            </TableCustomCell>
          </TableRow>
        </TableBody>
      </TableCustom>
    </>
  )
}

export default RatesLineDetailsAirAdditionalInformation

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export default function usePreAlert() {
  const { organizationId, collaborators } = useSelector(
    (state: IGlobalState) => ({
      collaborators: state.shipmentOverview.collaborators,
      organizationId: state.user.organizationId,
    })
  )

  const showPreAlert = useMemo(() => {
    const collaborator = collaborators.find((item) => {
      return item.organization_id === organizationId
    })
    const roles = (collaborator ? collaborator.roles : []).filter((x) =>
      [15, 10, 17].includes(x.id)
    )
    return !!roles.length
  }, [collaborators, organizationId])

  return showPreAlert
}

import { useState, Fragment, useRef, useEffect, SyntheticEvent } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete'
import { Box, Divider, Paper } from '@mui/material'
import InputBase from '@mui/material/InputBase'
import { MenuItemWithCheckbox } from 'src/stories/Lab/Menu/components/MenuItemWithCheckbox'
import { StyledAutocompletePopper } from 'src/stories/Lab/Autocomplete/AutocompleteCommon.styles'
import { PopperComponentProps } from 'src/stories/Lab/Autocomplete/Autocomplete.props'
import { FilterOptionValue, AutocompleteBaseProps } from '../types'
import { StyledBaseAutocompletePopper } from '../../styled'
import RecentFiltersManager from '../RecentSearches'
import DropdownTrigger from './DropdownTrigger'

const PopperComponent = (props: PopperComponentProps) => {
  const { disablePortal, anchorEl, open, ...other } = props
  return <StyledAutocompletePopper {...other} />
}

const AutocompleteBase = ({
  asynchronous = false,
  token,
  options,
  loading,
  onDelete,
  onInputChange,
  noOptionsText,
}: AutocompleteBaseProps) => {
  const recentSearches = new RecentFiltersManager()
  const { control, setValue: setFilterValue, getValues } = useFormContext()

  const defaultValue = getValues(token.value) ?? []
  const anchorRef = useRef(null)
  const [selectAll, setSelectAll] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [localValue, setLocalValue] = useState<FilterOptionValue[]>(
    defaultValue
  )

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setFilterValue('open', true)
  }

  const setRecentSearch = (newValue: FilterOptionValue[]) => {
    recentSearches.addRecentFilter({
      key: token.value,
      data: newValue,
      label: token.label,
      text: newValue.map((item) => item.label).join(', '),
    })
  }

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
    setFilterValue('open', false)
    if (localValue.length > 0) {
      setRecentSearch(localValue)
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'autocomplete-button-label' : undefined
  const searchInputPlaceholder = token?.shortLabel ?? token.label

  const autocompleteProps = asynchronous
    ? {
        filterOptions: (x: FilterOptionValue[]) => x,
      }
    : {}

  const getRenderOption = (props, option, { selected }) => {
    const { className, ...rest } = props
    return (
      <MenuItemWithCheckbox {...rest} selected={selected} text={option.label} />
    )
  }

  const getRenderInput = (params: AutocompleteRenderInputParams) => (
    <InputBase
      ref={params.InputProps.ref}
      inputProps={params.inputProps}
      autoFocus
      fullWidth
      className="search"
      placeholder={`Search in ${searchInputPlaceholder.toLowerCase()}`}
      sx={{
        borderBottom: '1px solid',
        borderColor: 'grey.200',
        px: 2,
        py: 1,
      }}
    />
  )

  const handleOnInputChange = (
    _event: SyntheticEvent<Element, Event>,
    search: string
  ) => {
    if (onInputChange) {
      onInputChange(search)
    }
  }

  const handleDelete = () => {
    setFilterValue(token.value, [])
    onDelete(token.value)
  }

  const onSelectAll = () => {
    setSelectAll((prev) => {
      if (!prev) {
        setLocalValue(options)
        setFilterValue(token.value, options)
      } else {
        setLocalValue([])
        setFilterValue(token.value, [])
      }
      return !prev
    })
  }

  useEffect(() => {
    if (anchorRef?.current && defaultValue.length === 0) {
      setAnchorEl(anchorRef.current)
      setFilterValue('open', true)
    }
  }, [anchorRef])

  return (
    <Fragment>
      <Controller
        control={control}
        name={token.value}
        render={({ field: { value } }) => (
          <DropdownTrigger
            open={open}
            anchorRef={anchorRef}
            partOne={token?.shortLabel ?? token.label}
            onClick={handleClick}
            onDelete={handleDelete}
            partTwo={
              !asynchronous && options.length === value.length
                ? 'All selected'
                : value.map((item) => item.label).join(', ')
            }
          />
        )}
      />
      <StyledBaseAutocompletePopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        data-testid="autocomplete-popper"
      >
        <Controller
          control={control}
          name={token.value}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              open
              multiple
              fullWidth
              autoComplete={true}
              onChange={(_event, item) => {
                onChange(item)
                setLocalValue(item)
              }}
              value={value || null}
              loading={loading}
              disableCloseOnSelect
              onClose={handleClose}
              disablePortal
              disableListWrap={true}
              renderTags={() => null}
              onInputChange={handleOnInputChange}
              PopperComponent={PopperComponent}
              noOptionsText={noOptionsText}
              componentsProps={{
                paper: { elevation: 0, square: true, sx: { pb: 1 } },
              }}
              renderOption={getRenderOption}
              options={options}
              getOptionLabel={(option) => option.label}
              renderInput={getRenderInput}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              PaperComponent={(paperProps) => {
                const { children, ...restPaperProps } = paperProps
                return (
                  <Paper {...restPaperProps}>
                    <Box onMouseDown={(e) => e.preventDefault()}>
                      <MenuItemWithCheckbox
                        onClick={onSelectAll}
                        selected={selectAll}
                        text="Select all"
                      />
                    </Box>
                    <Divider />
                    {children}
                  </Paper>
                )
              }}
              {...autocompleteProps}
            />
          )}
        />
      </StyledBaseAutocompletePopper>
    </Fragment>
  )
}

export default AutocompleteBase

import get from 'lodash/get'
import set from 'lodash/set'
import findKey from 'lodash/findKey'
import { useGetPackageTypes, useGetIncoterms } from 'src/services/Api/common'
import capitalize from 'lodash/capitalize'
import { ModalityEnum } from 'src/config/constants'
import { useFormContext } from './FormContext'
import {
  defaultDimensionValidation,
  defaultLoadTypeForModality,
  defaultBookingParties,
  bookingPartyKeys,
} from './Form.constants'
import { FormStateProps, TemplateFormContextProps } from './Form.props'
import { getIncotermServices } from './Form.utils'

const getJoinedText = (list: (string | null)[], separator = ', ') => {
  return list
    .filter((item: string | null) => item && item.length > 0)
    .join(separator)
}

export const useFormFieldChangeSideEffects = () => {
  const { data: packageTypes } = useGetPackageTypes({
    refetchOnMount: false,
  })

  const { data: incoterms } = useGetIncoterms({
    refetchOnMount: false,
  })

  const {
    formState,
    shipmentRoles,
    onChange,
  } = useFormContext() as TemplateFormContextProps

  const onModalityChange = (newValue: ModalityEnum) => {
    onChange(set(formState, 'loadingPort', null))
    onChange(set(formState, 'dischargePort', null))
    onChange(set(formState, 'loadType', defaultLoadTypeForModality[newValue]))
  }

  const onPackageTypeChange = (packageTypeId) => {
    const dimensionValues = {}
    const dimensionValidations = { ...defaultDimensionValidation }

    const selectedPackageType =
      packageTypes?.find((packageType) => packageType.id === packageTypeId) ??
      null
    if (selectedPackageType) {
      for (const dimensionKey of Object.keys(defaultDimensionValidation)) {
        const dimensionValue =
          (selectedPackageType[`default_${dimensionKey}_mm`] ?? 0) / 10

        dimensionValues[dimensionKey] = dimensionValue
      }
    }

    return { dimensionValues, dimensionValidations }
  }

  const onShipmentRoleChange = (newRoleId: number | string, user: IUser) => {
    const roleKey =
      findKey(shipmentRoles, (v) => {
        return v === newRoleId
      }) ?? ''
    if (bookingPartyKeys.includes(roleKey)) {
      const updatedBookingParties = {
        ...defaultBookingParties,
        [roleKey]: {
          collaborator: {
            value: user.organizationId,
            label: user.organizationName,
          },
          address: null,
        },
      }

      onChange(set(formState, 'bookingParties', updatedBookingParties))
    }
  }

  const onIncotermOrShipmentRoleChange = () => {
    let role = formState.shipmentRole
    if (role === shipmentRoles.origin_forwarder) {
      role = shipmentRoles.shipper
    }
    if (role === shipmentRoles.destination_forwarder) {
      role = shipmentRoles.consignee
    }
    if (formState.incoterm) {
      const incotermServices = getIncotermServices({
        incoterms,
        incoterm: formState.incoterm,
        shipmentRole: role,
      })
      if (incotermServices) {
        onChange(set(formState, 'services', incotermServices))
      }
    }
  }

  return {
    onModalityChange,
    onPackageTypeChange,
    onShipmentRoleChange,
    onIncotermOrShipmentRoleChange,
  }
}

const getPortLabel = (formState: FormStateProps, port) => {
  const label = get(formState, `${port}.label`)
  if (!label) {
    return ''
  }
  const splittedLabel = label.split(' - ')
  return splittedLabel[splittedLabel.length - 1].trim()
}

const getStepOneAdditionalInfo = (formState: FormStateProps) => {
  const modality = capitalize(get(formState, 'modality', ''))
  const loadType = get(formState, 'loadType', '').toUpperCase()
  const origin = getPortLabel(formState, 'loadingPort')
  const destination = getPortLabel(formState, 'dischargePort')
  const incoterm = get(formState, 'incoterm', '')
  const ports = getJoinedText([origin, destination], ' - ')
  return getJoinedText([modality, loadType, ports, incoterm])
}

const getContainerReeferText = (formState: FormStateProps) => {
  const temperature = get(formState, 'container.temperature')
  const ventilation = get(formState, 'container.ventilation')
  const humidity = get(formState, 'container.humidity')
  const co2Regulation = get(formState, 'container.co2Regulation')
  const temperatureText = temperature ? temperature + ' C°' : ''
  const ventilationText = ventilation ? ventilation + ' CBM/HR' : ''
  const humidityText = humidity ? humidity + '%' : ''
  const co2RegulationText = co2Regulation ? co2Regulation + '%' : ''
  const reeferText = getJoinedText([
    temperatureText,
    ventilationText,
    humidityText,
    co2RegulationText,
  ])

  return reeferText.length ? `(${reeferText})` : ''
}

const getStepTwoAdditionalInfo = (
  formState: FormStateProps,
  selectedContainerType
) => {
  const container = selectedContainerType?.name ?? ''
  const amount = get(formState, 'container.amount', '')
  const containerText = getJoinedText([amount, container], ' x ')
  const reeferText = !get(formState, 'container.nonOperatingReefer')
    ? getContainerReeferText(formState)
    : ''
  const cargoDescription = get(formState, 'cargo.description', '')
  const hsCode = get(formState, 'hsCode.hsCode', '')
  return getJoinedText([containerText, reeferText, cargoDescription, hsCode])
}

const getStepThreeAdditionalInfo = (formState: FormStateProps) => {
  const shipperCollaborator = get(
    formState,
    'bookingParties.shipper.collaborator.label',
    ''
  )
  const consigneeCollaborator = get(
    formState,
    'bookingParties.consignee.collaborator.label',
    ''
  )
  const otherCollaborators = get(formState, 'collaborators', []).map(
    (collaborator) => collaborator.name
  )

  return getJoinedText([
    shipperCollaborator,
    consigneeCollaborator,
    ...otherCollaborators,
  ])
}

export const useGetStepsAdditionalInfo = () => {
  const {
    formState,
    selectedContainerType,
  } = useFormContext() as TemplateFormContextProps

  const stepOneAdditionalInfo = getStepOneAdditionalInfo(formState)
  const stepTwoAdditionalInfo = getStepTwoAdditionalInfo(
    formState,
    selectedContainerType
  )
  const stepThreeAdditionalInfo = getStepThreeAdditionalInfo(formState)

  return [stepOneAdditionalInfo, stepTwoAdditionalInfo, stepThreeAdditionalInfo]
}

export const useResetCollaborators = () => {
  const {
    formState,
    shipmentRoles,
    onChange,
  } = useFormContext() as TemplateFormContextProps
  const { collaborators } = formState

  const forwarderRoles = [
    shipmentRoles.lead_forwarder,
    shipmentRoles.origin_forwarder,
    shipmentRoles.destination_forwarder,
  ]

  const nonForwarderCollaborators = collaborators.filter(
    (collaborator) =>
      collaborator.shipmentRoleOptions.every(
        (role) => !forwarderRoles.includes(role.id)
      ) && collaborator.shipmentRoleOptions.length > 0
  )
  onChange(set(formState, 'collaborators', nonForwarderCollaborators))
}

import { Squash as Hamburger } from 'hamburger-react'
import Box from '@mui/material/Box'

const TopNavigationDrawerToggler = ({ onClick, isOpen }) => {
  return (
    <Box onClick={onClick} data-testid="top-navigation-mobile-drawer-toggler">
      <Hamburger toggled={isOpen} size={18} />
    </Box>
  )
}

export default TopNavigationDrawerToggler

import { FunctionComponent } from 'react'
import { ModalityEnum } from 'src/config/constants'
import ContentDropDown from 'src/components/Common/ContentDropDown'
import { RatesListTypeEnum } from 'src/@types/endpoints/prices'
import RatesLineDetails from '../RatesLineDetails'
import RatesLineHeader from '../RatesLineHeader'
import { RatesLineDetailsAir } from '../RatesLineDetails/RatesLineDetailsAir'
import { isFutureRate, isHistoricRate, stringifyParams } from '../helpers'

interface IProps {
  index: number
  codes: string[]
  item: IRate
  containerCodes: string[]
}

const getBookingUrl = (item: IRate, isAir: boolean) => {
  const query = {
    modality: isAir ? ModalityEnum.Air : ModalityEnum.Sea,
    load_type: item.load_type,
    start: item.loading_port.display_name,
    origin_id: item.loading_port.id,
    origin_type: 'port',
    end: item.discharge_port.display_name,
    destination_id: item.discharge_port.id,
    destination_type: 'port',
  }

  return `/search?${stringifyParams(query as any)}`
}

const rateStatus = (item: IRate, isAir: boolean) => {
  if (!item.hasOceanFreightPrices && !isAir) return 'on_request'
  if (isFutureRate(item.valid_from)) return 'future'
  if (isHistoricRate(item.valid_to)) return 'historic'
  return 'valid'
}

const RatesLine: FunctionComponent<IProps> = (props) => {
  const { item, index, codes, containerCodes } = props
  const isAir = item.list_type === RatesListTypeEnum.air
  const bookUrl = getBookingUrl(item, isAir)
  const status = rateStatus(item, isAir)
  const body = isAir ? (
    <RatesLineDetailsAir
      status={status}
      bookUrl={bookUrl}
      rate={item as IAirRate}
    />
  ) : (
    <RatesLineDetails status={status} bookUrl={bookUrl} rate={item} />
  )

  return (
    <ContentDropDown
      className="container-overview-block"
      header={
        <RatesLineHeader
          status={status}
          item={item}
          index={index}
          codes={codes}
          containerCodes={containerCodes}
          opened
        />
      }
      headerCollapsed={
        <RatesLineHeader
          status={status}
          item={item}
          index={index}
          codes={codes}
          containerCodes={containerCodes}
          opened={false}
        />
      }
      body={body}
      disableCollapse={false}
    />
  )
}

export default RatesLine

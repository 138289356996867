import { FunctionComponent, useState, useRef, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import 'canvas-toBlob/canvas-toBlob.js'
import { Button } from '@mui/material'
import { AnyAction } from 'redux'
import { useTranslation } from 'react-i18next'
import Modal from 'src/components/Common/Modal'
import { promisifyAction } from '../../utils/'

import './styles.scss'

interface IProps {
  logo: any
  handleClose: () => void
  update: (logo: any) => AnyAction
  fetch: () => AnyAction
}

const CropAvatarModal: FunctionComponent<IProps> = ({
  logo,
  handleClose,
  update,
  fetch,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [previewLogo, setPreviewLogo] = useState<any>(null)
  const [newLogo, setNewLogo] = useState<any>(null)

  const updateLogoAsync = promisifyAction(dispatch, update)
  const fetchDataAsync = promisifyAction(dispatch, fetch)

  const cropperRef = useRef<HTMLImageElement>(null)

  const onCrop = useCallback((): void => {
    const imageElement: any = cropperRef?.current
    const cropper: any = imageElement?.cropper
    cropper.getCroppedCanvas().toBlob((blob) => {
      setNewLogo(blob)
      setPreviewLogo(cropper.getCroppedCanvas().toDataURL())
    })
  }, [cropperRef])

  const onUpload = useCallback(async () => {
    await updateLogoAsync(newLogo)
    await fetchDataAsync()
    handleClose()
  }, [newLogo])

  return (
    <Modal.Window open={!!logo} onClose={handleClose}>
      <Modal.Title
        children={t('account.side_bar.crop_avatar_modal.title')}
        onClose={handleClose}
      />
      <Modal.Content>
        <div className="crop-avatar-modal">
          <div className="crop-avatar-modal__preview">
            {t('account.side_bar.crop_avatar_modal.avatar_preview')}
            <img
              src={previewLogo || logo}
              alt="avatar"
              className="crop-avatar-modal__preview__image"
            />
          </div>
          <Cropper
            src={(logo && logo.preview) || logo}
            ref={cropperRef}
            aspectRatio={1}
            className="account-avatar-form__cropper"
            guides={false}
            crop={onCrop}
          />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="outlined" onClick={handleClose}>
          {t('account.side_bar.crop_avatar_modal.cancel_button')}
        </Button>
        <Button variant="contained" onClick={onUpload}>
          {t('account.side_bar.crop_avatar_modal.upload_button')}
        </Button>
      </Modal.Actions>
    </Modal.Window>
  )
}
export default CropAvatarModal

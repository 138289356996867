import { FunctionComponent, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LinearProgress } from 'src/stories'
import LoadableContainer from 'src/components/LoadableContainer'
import MessageCenterSingleMessage from 'src/components/MessageCenter/MessageCenterSingleMessage'
import { useTranslation } from 'react-i18next'
import { promisifyAction } from '../../../utils'
import { mentionsGetData } from '../../../stores/actionCreators'

import './styles.scss'

const MessageCenterMentions: FunctionComponent<{}> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [busy, setBusy] = useState<boolean>(true)

  const { comments } = useSelector((state: IGlobalState) => ({
    comments: state.messages.mentions.comments || [],
  }))

  const mentionsGetDataAsync = promisifyAction(dispatch, mentionsGetData)

  useEffect(() => {
    const fetchDataAsync = async () => {
      await mentionsGetDataAsync()
      setBusy(false)
    }
    fetchDataAsync()
  }, [])

  return (
    <LoadableContainer loading={busy} placeholder={<LinearProgress />}>
      <div className="message-center-messages">
        {!!comments.length ? (
          comments.map((comment: IComment) => (
            <MessageCenterSingleMessage comment={comment} key={comment.id} />
          ))
        ) : (
          <div className="message-center-emptyTab">
            <i className="icon big-check" />
            {t('message_center.mentions.empty_placeholder')}
          </div>
        )}
      </div>
    </LoadableContainer>
  )
}

export default MessageCenterMentions

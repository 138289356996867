import { UseFormRegister } from 'react-hook-form'
import FormLabel from 'src/stories/Lab/FormLabel'
import TextField from '@mui/material/TextField'
import { ShipmentTemplateDialogContentFormProps } from './ShipmentTemplate.props'

const ShipmentTemplateDialogContentForm: React.FC<{
  register: UseFormRegister<ShipmentTemplateDialogContentFormProps>
}> = ({ register }) => {
  return (
    <>
      <FormLabel label="Name of template*" />
      <TextField
        fullWidth
        placeholder="Type a unique descriptive name"
        inputProps={{
          'data-testid': 'shipment-template-dialog-form-name',
        }}
        {...register('name', { required: true })}
      />
    </>
  )
}

export default ShipmentTemplateDialogContentForm

import { BadgeProps, Typography } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import AppBar from '@mui/material/AppBar'
import { StyledToolbar } from 'src/components/TopNavigation/TopNavigation.styles'
import TopNavigationBrand from 'src/components/TopNavigation/components/TopNavigationBrand'

interface CenteredLayoutProps {
  icon?: React.ElementType
  children?: React.ReactNode
  variant?: BadgeProps['color']
  title: string
  description: string
  isLoading?: boolean
}

const CenteredLayout = (props: CenteredLayoutProps) => {
  const Icon = props.icon
  const iconColor = props?.variant ?? 'error'
  const isLoading = props.isLoading ?? false
  const { title, description } = props
  return (
    <Box
      className="wrapper"
      sx={{ height: '100vh', display: 'flex', width: '100%' }}
    >
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar component="nav" elevation={0} position="fixed">
          <StyledToolbar
            variant="dense"
            sx={{ minHeight: 56, justifyContent: 'space-between' }}
          >
            <Stack direction="row" alignItems="center" flexGrow={1}>
              <TopNavigationBrand />
            </Stack>
          </StyledToolbar>
        </AppBar>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Paper
          sx={{
            width: '100%',
            p: 3,
            maxWidth: 480,
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            whiteSpace: 'pre-line',
            textAlign: 'center',
          }}
        >
          {Icon && (
            <Box
              sx={{
                height: 52,
                width: 52,
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                color: `${iconColor}.400`,
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: `${iconColor}.50`,
                fontSize: '2rem',
              }}
            >
              <Icon fontSize="inherit" />
            </Box>
          )}
          {title.length > 0 && (
            <Typography
              variant="h2"
              children={title}
              mb={2}
              width="100%"
              data-testid="title"
            />
          )}
          {description.length > 0 && (
            <Typography
              mb={2}
              data-testid="description"
              variant="body1"
              width="100%"
              children={description}
            />
          )}
          {isLoading && (
            <Box width="100%" data-testid="loading-indicator">
              {[...Array(4)].map((_e, i) => (
                <Typography
                  key={i}
                  width="100%"
                  variant="body1"
                  children={<Skeleton />}
                />
              ))}
            </Box>
          )}
          {props.children && props.children}
        </Paper>
      </Box>
    </Box>
  )
}

export default CenteredLayout

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { SABSearchItemServiceProps } from './SABSearchItem.props'
import { getServiceItemPrice } from './SABSearchItem.utils'

const SABSearchItemService: React.FC<SABSearchItemServiceProps> = ({
  currency,
  service,
}) => {
  return (
    <Stack
      mt={0.25}
      spacing={2}
      display="flex"
      direction="row"
      justifyContent="space-between"
    >
      <Typography children={service.service_name} />
      {service.service_name === 'Freight' && currency !== 'EUR' && (
        <Typography
          variant="body1Strong"
          children={getServiceItemPrice({ currency, service })}
        />
      )}
      <Typography
        variant="body1Strong"
        children={getServiceItemPrice({ currency: 'EUR', service })}
      />
    </Stack>
  )
}

export default SABSearchItemService

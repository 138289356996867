import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { apiClient } from 'src/services/http-common'
import { endpoints, queryKeys } from 'src/services/endpoints'
import { useQueryAsync } from '../../hooks'
import { createQueryString } from '../../utils'
import { OverviewResponse, OverviewRequest } from './types'

export const useGetOverview = (
  payload: OverviewRequest,
  options?: UseQueryOptions<OverviewResponse, AxiosError, OverviewResponse>
) => {
  const queryString = createQueryString(payload.params, {
    skipEmptyString: false,
  })

  const url = endpoints.containersOverview + queryString
  const { containersOverview: key } = queryKeys

  const getOverview = () =>
    apiClient.get<OverviewRequest, OverviewResponse>(url)

  return useQuery<OverviewResponse, AxiosError>(
    [key, ...payload.keys],
    getOverview,
    options
  )
}

export const useGetOverviewVesselContainersAsync = (
  options?: UseQueryOptions<OverviewResponse, AxiosError, OverviewResponse>
) => {
  const { vesselsOverview: key } = queryKeys

  const getVesselContainers = (payload) => {
    const { id, ...requestPayload } = payload
    const queryString = createQueryString(requestPayload)
    const url = `${endpoints.vesselsOverview}/${id}/containers${queryString}`
    return apiClient.get<any, OverviewResponse>(url)
  }

  return useQueryAsync([key], getVesselContainers, options)
}

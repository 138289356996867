import AddIcon from '@mui/icons-material/Add'
import { Button } from '@mui/material'
import { omit } from 'lodash'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useCreateNewFilter } from 'src/services/Api/savedFilters'
import { useTranslation } from 'react-i18next'
import { queryClient } from 'src/services/http-common'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { useActionButtonIsActive, useFilter } from '../hooks'
import { ActionButtonsModal } from './ActionButtonsModal'

export const SaveNewFilterButton = () => {
  const {
    filters,
    page,
    setFilterId,
    defaultFiltersKeys,
    filterOptionsQueryKey,
  } = useFilter()
  const { t } = useTranslation()
  const { mutateAsync: createNewFilter } = useCreateNewFilter()
  const isActive = useActionButtonIsActive('save')
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)

  const onSave = async (filterName: string) => {
    createNewFilter({
      name: filterName,
      page,
      filter: omit(filters, defaultFiltersKeys),
    }).then((response) => {
      setFilterId(response.id, response)

      queryClient.setQueriesData([filterOptionsQueryKey], (oldParams: any) => {
        if (!oldParams) return oldParams

        const saved_filters = oldParams.saved_filters || []
        return { ...oldParams, saved_filters: [response, ...saved_filters] }
      })

      dispatch(
        showNotification({
          message: t('common.notifications.filter_saved', { filterName }),
          severity: 'success',
        })
      )
    })
  }

  return (
    <>
      <Button
        variant="text"
        size="large"
        onClick={() => setOpen(true)}
        startIcon={<AddIcon />}
        disabled={!isActive}
      >
        {t('common.buttons.save_new_filter', 'Save new filter')}
      </Button>
      <ActionButtonsModal
        onSave={onSave}
        onClose={() => setOpen(false)}
        filterName=""
        open={open}
        type="add"
      />
    </>
  )
}

import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded'
import { InfoTooltip } from 'src/stories'
import { ShipmentStatusSequence } from 'src/config/statusSequence'
import { ModalityEnum } from 'src/config/constants'
import { TooltipBody } from '../helpers'

interface IProps {
  label: string
  date: string | null
  modality: ModalityEnum
  statusSequence: number
  showInfoTooltip?: boolean
  showEtaCheck?: boolean
  shipment: IOverviewShipment
  showExactDate?: boolean
}

const useStyles = makeStyles(() => ({
  lastCheckedTooltipIcon: {
    padding: 0,
    marginRight: 5,
  },
}))

export const TimelinePortDate: React.FC<IProps> = ({
  date,
  label,
  modality,
  statusSequence,
  showInfoTooltip = true,
  showEtaCheck = false,
  showExactDate = false,
  shipment,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const lastCheckedTitle = t('shipment.timeline.last_checked_at', {
    defaultValue: 'Checked at {{val, DATETIME_MED}}',
    val: shipment.eta_last_checked_at,
  })

  const dateIsToBeAnnounced =
    !date ||
    (modality === ModalityEnum.Air &&
      statusSequence < ShipmentStatusSequence.ScheduleToBeAnnounced &&
      !showExactDate)

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          mr={0.5}
          variant="body1Strong"
          data-testid={`timeline-port-header-date-${label}`}
        >
          {label}:{' '}
        </Typography>
        <div data-testid={`timeline-${label}-date-value`}>
          {dateIsToBeAnnounced && (
            <Typography
              variant="body1"
              children={t(
                'shipment.timeline.date_to_be_announced',
                'To be announced'
              )}
            />
          )}
          {!dateIsToBeAnnounced && (
            <Typography
              variant="body1"
              children={t('shipment.timeline.date', {
                defaultValue: '{{val, DATETIME_MED}}',
                val: date,
              })}
            />
          )}
        </div>
        {showInfoTooltip && <InfoTooltip title={<TooltipBody />} />}
      </Box>
      {showEtaCheck &&
      shipment.status_groups.includes('your_shipments_in_transit') &&
      shipment.eta_last_checked_at ? (
        <>
          <Tooltip placement="bottom-start" title={lastCheckedTitle}>
            <Box sx={{ textWrap: 'balance' }}>
              <IconButton
                size="small"
                component="span"
                classes={{ root: classes.lastCheckedTooltipIcon }}
              >
                <AccessTimeRoundedIcon
                  sx={{ fontSize: '18px' }}
                ></AccessTimeRoundedIcon>
              </IconButton>
              <Typography
                sx={{
                  display: 'inline',
                  fontSize: 12,
                  color: 'text.secondary',
                }}
              >
                {t('shipment.timeline.relative_last_checked_at', {
                  defaultValue: 'Last checked {{val, DATE_RELATIVE}}',
                  val: shipment.eta_last_checked_at,
                })}
              </Typography>
            </Box>
          </Tooltip>
        </>
      ) : (
        ''
      )}
    </>
  )
}

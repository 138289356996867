import { AnyAction } from 'redux'
import * as actions from '../actions/watchShipmentRules'

export const watchShipmentRuleCreate = (payload: any): AnyAction => ({
  type: actions.WATCH_SHIPMENT_RULE_CREATE,
  payload,
})

export const watchShipmentRuleSync = (payload: any): AnyAction => ({
  type: actions.WATCH_SHIPMENT_RULE_SYNC,
  payload,
})

export const watchShipmentRuleUpdate = (id, payload: any): AnyAction => ({
  type: actions.WATCH_SHIPMENT_RULE_UPDATE,
  id,
  payload,
})

export const watchShipmentRuleDelete = (id: any): AnyAction => ({
  type: actions.WATCH_SHIPMENT_RULE_DELETE,
  id,
})

export const watchShipmentRulesToggleModal = (
  isModalOpen: boolean
): AnyAction => ({
  type: actions.WATCH_SHIPMENT_RULE_TOGGLE_MODAL,
  isModalOpen,
})

export const watchShipmentRulesSetCurrent = (
  currentRule: IWatchShipmentRule
): AnyAction => ({
  type: actions.WATCH_SHIPMENT_RULE_SET_CURRENT_RULE,
  currentRule,
})

export const watchShipmentRulesGetShipper = (payload: any): AnyAction => ({
  type: actions.WATCH_SHIPMENT_RULE_GET_SHIPPERS,
  payload,
})

export const watchShipmentRulesGetShipperSuccess = (
  payload: any
): AnyAction => ({
  type: actions.WATCH_SHIPMENT_RULE_GET_SHIPPERS_SUCCESS,
  payload,
})

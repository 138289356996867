import { useEffect, forwardRef, ReactNode, useState } from 'react'

const OPTIONS = {
  root: null,
  rootMargin: '0px',
  threshold: 1.0,
}

const InfiniteScroll = forwardRef(
  (
    {
      children,
      onScrollTop,
      className,
      loading,
      id,
    }: {
      onScrollTop?: () => Promise<any>
      children: ReactNode
      className: string
      loading: boolean
      id: string
    },
    ref: any
  ) => {
    const [wasRendered, setWasRendered] = useState(false)
    useEffect(() => {
      if (loading) return
      const topObserver = new IntersectionObserver(async ([firstItem]) => {
        if (!onScrollTop || !firstItem.isIntersecting) return
        topObserver.unobserve(firstItem.target)
        await onScrollTop()
        if (!ref.current) return
        topObserver.observe(ref.current.firstElementChild)
      }, OPTIONS)
      if (ref.current.firstElementChild && !wasRendered) {
        topObserver.observe(ref.current.firstElementChild)
        setWasRendered(true)
      }
    }, [loading])
    return (
      <div className={className} id={id} ref={ref}>
        {children}
      </div>
    )
  }
)

export default InfiniteScroll

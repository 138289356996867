import { FunctionComponent } from 'react'
import { Dialog, DialogContent } from 'src/stories/Dialogs'
import { Typography, Button, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ExportShipmentsConfirmWindow: FunctionComponent<any> = ({
  open,
  onClose,
  onConfirm,
  title,
}) => {
  const { t } = useTranslation()
  const onCloseWindow = () => {
    onConfirm(false)
    onClose()
  }

  const confirm = () => {
    onConfirm(true)
    onClose()
  }

  return (
    <Dialog
      sx={{
        '.MuiDialog-paper': {
          width: '100%',
          maxWidth: 630,
        },
        '.MuiDialog-paper .MuiDialogTitle-root': {
          paddingBottom: 1,
        },
        '.MuiDialogTitle-root': {
          fontWeight: 600,
        },
      }}
      open={open}
      onClose={onClose}
      title={title}
      actions={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Box>
            <Button
              variant="outlined"
              onClick={onCloseWindow}
              size="large"
              data-testid="no-button"
              sx={{ mr: 2 }}
            >
              {t(
                'shipments.download.prompt.apply_filters.reject',
                'No, export all shipments'
              )}
            </Button>
            <Button
              data-testid="yes-button"
              variant="contained"
              onClick={confirm}
              size="large"
            >
              {t(
                'shipments.download.prompt.apply_filters.confirm',
                'Yes, apply filters'
              )}
            </Button>
          </Box>
        </Box>
      }
    >
      <DialogContent>
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="body1"
            children={t(
              'shipments.download.prompt.apply_filters.description',
              'You have selected one or multiple filters on this page. Do you want to apply those filters to the export file?'
            )}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}
export default ExportShipmentsConfirmWindow

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { PickupsDeliveriesFilterOptionsResponse } from 'src/@types/endpoints/pickupsDeliveries'
import endpoints, { queryKeys } from '../endpoints'
import { apiClient } from '../http-common'

export const useGetPickupsDeliveriesFilterOptions = (
  options?: UseQueryOptions<
    PickupsDeliveriesFilterOptionsResponse,
    AxiosError,
    PickupsDeliveriesFilterOptionsResponse
  >
) => {
  const url = endpoints.pickupsDeliveriesFilterOptions
  const { pickupsDeliveriesFilterOptions: key } = queryKeys

  const getPrices = () =>
    apiClient.get<any, PickupsDeliveriesFilterOptionsResponse>(url)
  return useQuery<PickupsDeliveriesFilterOptionsResponse, AxiosError>(
    [key],
    getPrices,
    options
  )
}

import { FunctionComponent, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadableContainer from 'src/components/LoadableContainer'
import {
  clearShipmentComments,
  fetchShipmentComments,
  reportsDashboardDeletedLastComment,
} from 'src/stores/actionCreators'
import ShipmentComment from './ShipmentComment'
import EditCommentField from './EditCommentField'

interface ICommentsBlockProps {
  shipmentId: number
}

const CommentsBlock: FunctionComponent<ICommentsBlockProps> = ({
  shipmentId,
}) => {
  const dispatch = useDispatch()

  const { isFetching, comments } = useSelector((state: IGlobalState) => ({
    isFetching: state.shipmentComments.isFetching,
    comments: state.shipmentComments.comments,
  }))

  useEffect(() => {
    if (shipmentId) {
      dispatch(fetchShipmentComments(shipmentId))
    } else {
      dispatch(clearShipmentComments())
    }
  }, [shipmentId])

  const onDeleteComment = useCallback(
    (commentId: number) => {
      if (comments[0].id !== commentId) {
        return
      }

      const lastComment: IComment | null = comments[1] || null

      dispatch(
        reportsDashboardDeletedLastComment({
          lastComment,
          shipmentId,
        })
      )
    },
    [comments, shipmentId]
  )

  return (
    <>
      <EditCommentField shipmentId={shipmentId} />
      <div className="edit-comment__comments">
        <LoadableContainer loading={isFetching} className="top-50">
          {comments.map((comment: IComment) => (
            <ShipmentComment
              comment={comment}
              key={comment.id}
              onDelete={onDeleteComment}
            />
          ))}
        </LoadableContainer>
      </div>
    </>
  )
}

export default CommentsBlock

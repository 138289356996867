export const ADDRESSES_GET_DATA = 'ADDRESSES_GET_DATA'
export const ADDRESSES_GET_DATA_SUCCESS = 'ADDRESSES_GET_DATA_SUCCESS'
export const ADDRESSES_GET_ONE_ADDRESS = 'ADDRESSES_GET_ONE_ADDRESS'
export const ADDRESSES_GET_ONE_ADDRESS_SUCCESS =
  'ADDRESSES_GET_ONE_ADDRESS_SUCCESS'
export const ADDRESSES_PUT_ADDRESS = 'ADDRESSES_PUT_ADDRESS'
export const ADDRESSES_PUT_ADDRESS_SUCCESS = 'ADDRESSES_PUT_ADDRESS_SUCCESS'
export const ADDRESSES_ADD_ADDRESS = 'ADDRESSES_ADD_ADDRESS'
export const ADDRESSES_ADD_ADDRESS_SUCCESS = 'ADDRESSES_ADD_ADDRESS_SUCCESS'
export const ADDRESSES_DELETE_ADDRESS = 'ADDRESSES_DELETE_ADDRESS'
export const ADDRESSES_DELETE_ADDRESS_SUCCESS =
  'ADDRESSES_DELETE_ADDRESS_SUCCESS'
export const SET_DEFAULT_ADDRESS = 'SET_DEFAULT_ADDRESS'
export const SET_DEFAULT_ADDRESS_SUCCESS = 'SET_DEFAULT_ADDRESS_SUCCESS'
export const GET_ORGANIZATION_ADDRESSES = 'GET_ORGANIZATION_ADDRESSES'
export const GET_ORGANIZATION_ADDRESSES_SUCCESS =
  'GET_ORGANIZATION_ADDRESSES_SUCCESS'

import { AnyAction } from 'redux'
import { put } from 'redux-saga/effects'
import { authorizedRequest } from '../wrappers'
import { Logger } from '../../../utils'
import { minimumShippingDate, serverErrors } from '../../../utils/helpers'

import * as actionCreators from '../../actionCreators/searchBooking'

export default function* (action: AnyAction): Iterator<any> {
  try {
    const params = action.payload
    params.departure_date = minimumShippingDate(
      params.modality,
      params.departure_date
    )
    const response = yield authorizedRequest({
      method: 'POST',
      url: 'api/v1/request_quotes/complete',
      data: params,
    })
    yield put(actionCreators.searchQuotesBookingSuccess(response))
  } catch (error) {
    const unknownError: any = error
    yield* serverErrors(unknownError)

    yield put(actionCreators.searchQuotesBookingReset())
    yield Logger.sagaError(unknownError, 'store::user::loadState')
  }
}

import { useState } from 'react'
import { useDispatch } from 'react-redux'
import capitalize from 'lodash/capitalize'
import { useTranslation } from 'react-i18next'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Button from '@mui/material/Button'
import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { bookShipmentQuotation } from 'src/stores/actionCreators/shipmentQuotations'
import { CircularProgress } from 'src/stories'
import { ModalityEnum, ShipmentQuotationStatusEnum } from 'src/config/constants'
import { getBookButtonVisible } from './utils'
import ProposedRevenues from './ProposedRevenues'

const ACCORDION_TRANSITION_DURATION = 500

const useStyles = makeStyles((theme) => ({
  accordionDetails: {
    padding: 0,
  },
  cheapestPrice: {
    color: theme.palette.success.main,
  },
  bookingWithLoaderIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -9,
    marginLeft: -9,
  },
  currency: {
    display: 'inline-block',
  },
  price: {
    fontSize: 18,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
  accordionRoot: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '& .MuiAccordionSummary-expandIcon': {
      transitionDuration: ACCORDION_TRANSITION_DURATION,
    },
  },
  carrierImage: {
    width: 70,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('lg')]: {
      width: 35,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  typography: {
    fontSize: 16,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
}))

const ButtonWithLoader = ({ isLoading, variant, onClick, children }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isScreenSmall = useMediaQuery(theme.breakpoints.down('md'))

  const onButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    onClick()
  }

  return (
    <Box m={1} position="relative">
      <Button
        disabled={isLoading}
        disableElevation
        variant={variant}
        size={isScreenSmall ? 'small' : 'medium'}
        color="primary"
        onClick={onButtonClick}
      >
        {children}
      </Button>
      {isLoading && (
        <CircularProgress size={18} className={classes.bookingWithLoaderIcon} />
      )}
    </Box>
  )
}

const ShipmentQuotation = ({
  quotation,
  isBooking,
  modality,
}: {
  modality: ModalityEnum
  quotation: IShipmentQuotation
  isBooking: boolean
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [expanded, setExpanded] = useState<number | false>(false)
  const theme = useTheme()
  const isScreenSmall = useMediaQuery(theme.breakpoints.down('md'))
  const bookButtonVisible = getBookButtonVisible(
    modality,
    quotation.status as ShipmentQuotationStatusEnum,
    quotation.quote_expiration_at
  )

  const handleChange = (panel: number) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false)
  }

  const onBookClick = (quotation: IShipmentQuotation) => {
    if (!bookButtonVisible) {
      return
    }
    dispatch(
      bookShipmentQuotation({
        quotation: { id: quotation.id },
      })
    )
  }

  return (
    <Accordion
      key={quotation.id}
      square
      expanded={expanded === quotation.id}
      onChange={handleChange(quotation.id)}
      className={classes.accordionRoot}
      TransitionProps={{
        timeout: ACCORDION_TRANSITION_DURATION,
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container spacing={2}>
          {!isScreenSmall && (
            <Grid item xs={2}>
              <img
                className={classes.carrierImage}
                title={quotation.carrier.name}
                src={`https://www.gstatic.com/flights/airline_logos/70px/${quotation.carrier.scac}.png`}
                alt={quotation.carrier.name}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null
                  currentTarget.src =
                    'https://www.gstatic.com/flights/airline_logos/70px/5E.png'
                }}
              />
            </Grid>
          )}
          <Grid item xs>
            <Typography className={classes.typography}>
              {quotation.transit_time}
            </Typography>
            <Typography className={classes.typography}>
              {capitalize(quotation.transit_type)}
            </Typography>
            <Typography
              className={classes.typography}
              variant="subtitle1"
              color="textSecondary"
              title={quotation.carrier.name}
            >
              {quotation.carrier.scac}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.typography}>
              {quotation.loading_port.name} ({quotation.loading_port.code}) -{' '}
              {quotation.discharge_port.name} ({quotation.discharge_port.code})
            </Typography>
            <Typography
              className={classes.typography}
              variant="subtitle1"
              color="textSecondary"
            >
              {t('shipment_costs.table_row.departure_date', {
                defaultValue: 'Departures on: {{days}}',
                days: quotation.departure_days.map(capitalize).join(', '),
              })}
            </Typography>
            <Typography
              className={classes.typography}
              variant="subtitle1"
              color="textSecondary"
            >
              {quotation.transit_remarks}
            </Typography>
          </Grid>
          <Grid item xs>
            <b
              className={`${classes.price} ${
                quotation.cheapest ? classes.cheapestPrice : ''
              }`}
            >
              {quotation.costs_total_eur}{' '}
              <Box className={classes.currency}>
                {t('common.currencies.eur', 'EUR')}
              </Box>
            </b>
            <Typography
              className={classes.typography}
              variant="subtitle1"
              color="textSecondary"
            >
              {t('shipment.timeline.quotation.valid_until', {
                defaultValue: 'Valid until: {{val, DATE_MED}}',
                val: quotation.quote_expiration_at,
              })}
            </Typography>
          </Grid>
          <Grid item xs>
            {bookButtonVisible && (
              <ButtonWithLoader
                isLoading={isBooking}
                variant={expanded === quotation.id ? 'contained' : 'outlined'}
                onClick={() => onBookClick(quotation)}
              >
                {t('common.buttons.book', 'Book')}
              </ButtonWithLoader>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <ProposedRevenues quotation={quotation} />
      </AccordionDetails>
    </Accordion>
  )
}

export default ShipmentQuotation

import { useEffect } from 'react'
import { useSelector } from 'react-redux'

export default function useHeap(): void {
  const { currentUser } = useSelector((globalState: IGlobalState) => ({
    currentUser: globalState.user,
  }))

  const heap = window.heap

  const isProduction: boolean = window.location.href.includes('app.shypple.com')

  useEffect(() => {
    if (!isProduction) return
    if (!heap) return
    if (currentUser.uid && !currentUser.isDummy) {
      heap.identify(currentUser.email)
      heap.addUserProperties({
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        organizationId: currentUser.organizationId,
        userId: currentUser.id,
        userRole: currentUser.userRole,
        organizationRole: currentUser.organizationRole,
        organizationName: currentUser.organizationName,
        createdAt: currentUser.createdAt,
      })
    } else if (heap.identity) {
      // logout cleanup
      heap.resetIdentity()
    }
  }, [currentUser.uid])
}

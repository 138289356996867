import { FunctionComponent } from 'react'
import TextPlaceholder from 'src/components/Common/TextPlaceholder'

const BlankSelectableShipmentLine: FunctionComponent<{}> = () => {
  return (
    <div className="selectable-shipment-line">
      <div className="selectable-shipment-line__selectable"></div>
      <div className="selectable-shipment-line__name">
        <TextPlaceholder color="light-grey" size={6} />
      </div>
      <div className="selectable-shipment-line__departure">
        <TextPlaceholder color="light-grey" size={4} />
      </div>
      <div className="selectable-shipment-line__arrival">
        <TextPlaceholder color="light-grey" size={4} />
      </div>
    </div>
  )
}

export default BlankSelectableShipmentLine

import { useState, Fragment, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { isArray } from 'lodash'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import { useFormContext, Controller } from 'react-hook-form'
import FormControlLabel from '@mui/material/FormControlLabel'
import { FormDatePicker } from 'src/components/Filters/FormElements/FormDatePicker'
import {
  getDateOptionRangeForWeek,
  convertDateToUnixTimestamp,
  convertDateFromUnixTimestamp,
  getDateOptionRange,
} from 'src/components/Overview/utils'

import { FORMAT_SHORT_DATE } from 'src/utils/helpers/shipmentDate'
import { datePickerDefaultValue } from '../constants'
import { DateSelectorOptionProps } from '../types'
import RecentFiltersManager from '../RecentSearches'
import DropdownTrigger from './DropdownTrigger'

const getChipText = (value: number[]) => {
  if (!value || !isArray(value) || value.length < 2) {
    return ''
  }

  const start = convertDateFromUnixTimestamp(value[0], FORMAT_SHORT_DATE)
  const end = convertDateFromUnixTimestamp(value[1], FORMAT_SHORT_DATE)

  if (start === end) {
    return start
  }

  return `${start} - ${end}`
}

const getDatePickerValueFromFilterValue = (value: number[]) => {
  return value && isArray(value) && value.length === 2
    ? {
        start: convertDateFromUnixTimestamp(value[0]),
        end: convertDateFromUnixTimestamp(value[1]),
      }
    : { ...datePickerDefaultValue }
}

const DateRangePicker = ({ token, onDelete }) => {
  const { t } = useTranslation()

  const recentSearches = new RecentFiltersManager()
  const options = getDateOptionRangeForWeek(t)

  const { control, setValue: setFilterValue, getValues } = useFormContext()

  const defaultValue = getValues(token.value) ?? []

  const anchorRef = useRef<HTMLDivElement>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedIndex, setSelectedIndex] = useState(-1)

  const open = Boolean(anchorEl)
  const id = open ? 'date-range-picker' : undefined

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setFilterValue('open', true)
  }

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
    setFilterValue('open', false)
  }

  const updateLocalStorage = (newValue: number[]) => {
    recentSearches.addRecentFilter({
      key: token.value,
      data: newValue,
      label: token.label,
      text: getChipText(newValue),
    })
  }

  const onMenuItemClick = (option: DateSelectorOptionProps, index: number) => {
    if (!option) {
      return
    }
    const [start, end] = getDateOptionRange(option)
    updateLocalStorage([start, end])
    setFilterValue(token.value, [start, end])
    setSelectedIndex(index)
    handleClose()
  }

  const handleDelete = () => {
    setFilterValue(token.value, [])
    onDelete(token.value)
  }

  useEffect(() => {
    if (anchorRef?.current && defaultValue?.length === 0) {
      setAnchorEl(anchorRef.current)
      setFilterValue('open', true)
    }
  }, [anchorRef])

  return (
    <Fragment>
      <Controller
        control={control}
        name={token.value}
        render={({ field: { value } }) => (
          <DropdownTrigger
            open={open}
            anchorRef={anchorRef}
            partOne={token.label}
            onClick={handleClick}
            onDelete={handleDelete}
            partTwo={getChipText(value)}
          />
        )}
      />

      <Menu
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          role: 'listbox',
        }}
        sx={(theme) => {
          return {
            '& .MuiMenu-paper': {
              border: `1px solid ${theme.palette.grey[200]}`,
              boxShadow: theme.shadows[2],
            },
          }
        }}
      >
        {options.map((option: DateSelectorOptionProps, index: number) => (
          <MenuItem
            key={option.value}
            children={
              <FormControlLabel
                control={<Radio />}
                label={option.label}
                checked={index === selectedIndex}
              />
            }
            selected={index === selectedIndex}
            onClick={() => onMenuItemClick(option, index)}
          />
        ))}
        <MenuItem>
          <Box sx={{ width: 250 }}>
            <Controller
              control={control}
              name={token.value}
              render={({ field: { onChange, value } }) => (
                <FormDatePicker
                  qs={getDatePickerValueFromFilterValue(value)}
                  onChange={(newValue) => {
                    const filterValue = [
                      convertDateToUnixTimestamp(newValue?.start ?? ''),
                      convertDateToUnixTimestamp(newValue?.end ?? '', true),
                    ]
                    updateLocalStorage(filterValue)
                    onChange(filterValue)
                    handleClose()
                    setSelectedIndex(options.length + 1)
                  }}
                  placeholder="DD-MM"
                  inputFormat="dd-MM"
                  name={['start', 'end']}
                  label="Select date range"
                  data-testid="date-range-picker"
                />
              )}
            />
          </Box>
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default DateRangePicker

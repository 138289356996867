import { useState, useRef, useCallback } from 'react'
import { makeStyles } from '@mui/styles'
import { Chip, Theme, Typography } from '@mui/material'
import ListSubheader from '@mui/material/ListSubheader'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { SxProps } from '@mui/system'

const useStyles = makeStyles((theme) => ({
  item: {
    borderRadius: 3,
    display: 'flex',
    color: theme.palette.primary.main,
    padding: `0 ${theme.spacing(1)}`,
    alignItems: 'center',
    width: '100%',
    height: 18,
  },
  icon: {
    display: 'flex',
    '& .MuiSvgIcon-root': {
      width: 12,
      height: 12,
    },
    '& .icon': {
      width: 12,
      height: 12,
      fontSize: 9,
      textAlign: 'center',
    },
  },
  menuItem: {
    color: theme.palette.primary.main,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    minHeight: 'auto',
    '&.Mui-disabled': {
      opacity: 1,
      pointerEvents: 'auto',
    },
  },
  listSubheader: {
    color: theme.palette.primary.main,
    lineHeight: 'normal',
    padding: `${theme.spacing(1.5)} ${theme.spacing(1.25)} 0 ${theme.spacing(
      1.25
    )}`,
    margin: 0,
    backgroundColor: 'white',
  },
  arrowIcon: {
    width: 24,
    height: 24,
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.complex,
    }),
    transform: ({ open }: { open: boolean }) =>
      open ? 'rotate(180deg)' : 'rotate(0)',
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  container: {},
  fixedWidth: {
    width: '180px',
    justifyContent: 'flex-start',
  },
}))

export type ISelectChipColor =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error'

export interface OptionsItem {
  id?: string | number
  name?: string
  icon?: React.ReactElement
  type?: 'header' | 'item'
  color?: ISelectChipColor
  disabled?: string
  phase?: IShipmentStatus['phase'] | null
}

export interface OptionsHeader {
  id?: string | number
  type: 'header'
  name: string
}

export type SelectableChipOptions = (OptionsItem | OptionsHeader)[]

export interface Props {
  value: OptionsItem
  noLabel?: boolean
  options?: SelectableChipOptions
  variant?: 'outlined' | 'filled'
  color?: ISelectChipColor
  size?: 'small' | 'medium'
  disabled?: boolean
  loading?: boolean
  onChange?: (id: OptionsItem) => void
  children?: any
  fixedWidth?: boolean
  sx?: SxProps<Theme>
}

const SelectableChip: React.FC<Props> = (props) => {
  const { loading = false } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const ref = useRef(null)
  const open = Boolean(anchorEl)

  const classes = useStyles({
    open,
  })

  const handleMenuItemClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const option: OptionsItem | undefined =
      props.options &&
      (props.options
        .filter((j) => j.type !== 'header')
        .find((i) => i.name === event.currentTarget.id) as OptionsItem)

    if (!option) return

    if (props.onChange) {
      props.onChange(option)
    }

    setAnchorEl(null)
  }

  const handleClose = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
  }, [])

  const handleButtonClick = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(ref?.current)
  }, [])

  return (
    <>
      <Chip
        ref={ref}
        disabled={loading}
        aria-describedby="chip-select"
        icon={props.value?.icon}
        className={`${classes.container} ${
          props.fixedWidth ? classes.fixedWidth : ''
        } ${props.noLabel && 'no-label'}`}
        sx={props.sx}
        variant={props.variant || 'outlined'}
        size={props.size || 'medium'}
        label={!props.noLabel && props.value?.name}
        color={props.value?.color}
        onClick={props.disabled ? undefined : handleButtonClick}
        onDelete={props.disabled ? undefined : handleButtonClick}
        deleteIcon={
          props.disabled ? undefined : (
            <ExpandMoreIcon className={classes.arrowIcon} />
          )
        }
        data-testid="selectable-chip"
      />
      {!props.disabled && open && (
        <Menu
          id="chip-select"
          sx={{
            maxHeight: 'calc(100vh*2/3)',
          }}
          anchorEl={anchorEl}
          keepMounted
          open
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          elevation={5}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          PopoverClasses={{
            root: 'dashboard-select__list',
          }}
        >
          {!!props.options?.length &&
            props.options.map((option) => {
              if (option.type === 'header')
                return (
                  <ListSubheader
                    key={option.id || option.name}
                    className={classes.listSubheader}
                    disableGutters
                  >
                    <Typography variant="body1Strong">{option.name}</Typography>
                  </ListSubheader>
                )
              return (
                <MenuItem
                  disableGutters
                  className={classes.menuItem}
                  key={option.id || option.name}
                  id={option.name}
                  data-testid="selectable-chip-menu-item"
                  selected={option.name === props.value?.name}
                  onClick={handleMenuItemClick}
                >
                  <Box typography="body1">{option.name}</Box>
                </MenuItem>
              )
            })}
        </Menu>
      )}
    </>
  )
}

export default SelectableChip

import { Box } from '@mui/material'
import { t } from 'i18next'
import { uniqBy } from 'lodash'
import { ShyppleAvatarProps } from 'src/stories/Avatar'

export const renderTooltipReference = (
  shared_reference,
  organizationReference,
  title
): React.ReactElement => (
  <div className="shipment__header--tooltip-data">
    {!shared_reference && !organizationReference}
    {shared_reference && (
      <>
        <div className="shipment__header__tooltip--title">
          {t(
            'common.forms.fields.shipment_shared_reference.label',
            'Shared reference'
          )}
        </div>
        <div>{shared_reference}</div>
        <Box mb={1} />
      </>
    )}
    {organizationReference && (
      <>
        <div className="shipment__header__tooltip--title">
          {t(
            'common.forms.fields.shipment_internal_reference.label',
            'Internal reference'
          )}
        </div>
        <div>{organizationReference}</div>
        <Box mb={1} />
      </>
    )}
    {title && (
      <>
        <div className="shipment__header__tooltip--title">
          {t('common.forms.fields.shipment_number.label', 'Shipment number')}
        </div>
        <div>{title}</div>
      </>
    )}
  </div>
)

export const getPartiesAvatarsArray = (
  parties: Array<ICollaborator | IShipmentParty>,
  groupByValue: string
) => {
  const filteredParties = uniqBy(parties, groupByValue)
  return filteredParties.map(
    (party: ICollaborator | IShipmentParty) =>
      ({
        id: party.organization_id?.toString() || '',
        alt: party.name,
        src: party.logo || 'no-logo',
        className: party.organization_role_code || party.role_code,
      } as ShyppleAvatarProps & { id?: string })
  )
}

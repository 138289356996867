import { Button } from '@mui/material'
import { FunctionComponent, useState } from 'react'
import Modal from 'src/components/Common/Modal'
import { useTranslation } from 'react-i18next'
import { canUpdatePassword, PasswordModalState } from './validations'
import PasswordContent from './PasswordContent'

interface IProps {
  onSave: (data: PasswordModalState) => void
  handleClose: () => void
}

const initialFormData: PasswordModalState = {
  password: '',
  confirm: '',
  current: '',
}

const PasswordModal: FunctionComponent<IProps> = ({ handleClose, onSave }) => {
  const [formData, setFormData] = useState<PasswordModalState>(initialFormData)
  const { t } = useTranslation()

  const onChange = (value: string | number, field: string) => {
    setFormData({ ...formData, [field]: value })
  }

  return (
    <Modal.Window
      open
      onClose={handleClose}
      onKeyUp={(e) => {
        if (e.code === 'Enter') {
          if (canUpdatePassword(formData)) {
            onSave(formData)
          }
        }
      }}
    >
      <Modal.Title
        children={t('change_password.title', 'Change Password')}
        onClose={handleClose}
      />
      <Modal.Content>
        {<PasswordContent onChange={onChange} formData={formData} />}
      </Modal.Content>
      <Modal.Actions>
        <Button variant="outlined" onClick={handleClose}>
          {t('change_password.cancel_button', 'Cancel')}
        </Button>
        <Button
          data-testid="button-reset-password"
          variant="contained"
          disabled={!canUpdatePassword(formData)}
          onClick={() => onSave(formData)}
        >
          {t('change_password.reset_password_button', 'Reset Password')}
        </Button>
      </Modal.Actions>
    </Modal.Window>
  )
}
export default PasswordModal

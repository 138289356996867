import { useTranslation } from 'react-i18next'
import { FunctionComponent } from 'react'
import { Typography } from '@mui/material'
import DocumentFilters from './DocumentFilters'
import DocumentTable from './DocumentsTable'

const DocumentsManager: FunctionComponent = () => {
  const { t } = useTranslation()
  return (
    <div className="documents-manager">
      <Typography variant="h2" children={t('documents.title', 'Documents')} />
      <DocumentFilters />
      <DocumentTable />
    </div>
  )
}

export default DocumentsManager

import { t } from 'i18next'

const descriptions = (t) => ({
  shipper: {
    from: t(
      'search_and_book.shipper_from',
      'When shipping from a business location/warehouse, please fill in its address. When shipping from the port without arranging the pick-up, please select the relevant port.'
    ),
    to: t(
      'search_and_book.shipper_to',
      'If you do not know the delivery address, please select the relevant port. Only select an address from the address book if agreed upon with the consignee'
    ),
  },
  consignee: {
    from: t(
      'search_and_book.consignee_from',
      'When shipping from the shipper’s location/warehouse, please fill in its address and add it to your address book when prompted. When shipping from the port without arranging the pick-up, please select the relevant port.'
    ),
    to: t(
      'search_and_book.consignee_to',
      'When shipping to a business location/warehouse, please fill in its address. When shipping to the port without arranging the delivery, please select the relevant port.'
    ),
  },
})

export const getSearchTooltipDescription = (role: IRoleShort | null) => {
  if (!role) return

  const { name } = role
  const roleName = name.toLowerCase()
  const roleDescriptions = descriptions(t)

  return {
    from: roleDescriptions[roleName]?.from,
    to: roleDescriptions[roleName]?.to,
  }
}

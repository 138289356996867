import { Typography, Stack } from '@mui/material'

const FieldValueText = (props) => {
  const { text } = props
  return (
    <Stack spacing={1} direction="row" alignItems="center">
      {props.icon}
      <Typography
        noWrap
        component="div"
        children={text ?? '-'}
        sx={{ height: 40, alignItems: 'center', display: 'flex' }}
      />
    </Stack>
  )
}

export default FieldValueText

import { createReducer, Handlers, Reducer } from 'redux-create-reducer'
import { AnyAction } from 'redux'
import { has } from 'lodash'
import * as actions from '../actions/searchPrevious'
import { shippingDate, convertDateForComparison } from '../../utils/helpers'

declare global {
  // returned by /api/v1/request_quotes/previous_searches
  interface ISearchPrevious {
    load_type: 'fcl' | 'lcl'
    shipment_type: string
    container_types: Record<string, number>
    departure_date: string
    start: string
    end: string
    lengths: number[] | null
    heights: number[] | null
    widths: number[] | null
    weights: number[] | null
    quantities: number[]
    total_quantity: number
    delivery: boolean
    pickup: boolean
    import_customs: boolean
    export_customs: boolean
    freight: boolean
    origin_port: boolean
    destination_port: boolean
    insurance: boolean
    cif_value?: number
  }
  interface ISearchPreviousResult {
    searches: ISearchPrevious[]
    loading: boolean
  }
}

export const initialSearchPreviousState: ISearchPreviousResult = {
  searches: [],
  loading: false,
}

const searchPrevious: Reducer<ISearchPreviousResult, AnyAction> = (
  state,
  { payload }
) => {
  const searches = payload.data.data.searches.map((search) => {
    const date = convertDateForComparison(shippingDate(search.modality))
    if (has(search, 'departure_date')) {
      return { ...search }
    } else {
      return { ...search, departure_date: date }
    }
  })
  return {
    searches: searches,
    loading: false,
  }
}

const searchPreviousStart: Reducer<ISearchPreviousResult, AnyAction> = (
  state,
  { payload }
) => ({
  ...state,
  loading: true,
})

const actionHandlers: Handlers<ISearchPreviousResult> = {
  [actions.SEARCH_PREVIOUS_SUCCESS]: searchPrevious,
  [actions.SEARCH_PREVIOUS]: searchPreviousStart,
}

export default createReducer(initialSearchPreviousState, actionHandlers)

import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import { ModalityEnum } from 'src/config/constants'
import * as actions from '../actions/shipmentTrack'

declare global {
  interface IMapCenter {
    lat: number
    lng: number
  }

  interface IShipmentTrack {
    id: number
    name: string
    route: IMapCenter[]
    current_position: { lat: number; lng: number }
    latitude: string
    longitude: string
    shipment_code: string
    shipment_content: any
    type: ModalityEnum
    isOpen: boolean | null
  }

  interface IShipmentTrackState {
    list: IShipmentTrack[]
    displayMapModal: boolean
  }
}
export const initialShipmentTrackState = {
  list: [],
  displayMapModal: false,
}
const receiveList: Reducer<IShipmentTrackState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  list: action.payload,
})

const changeDisplayMapModal: Reducer<IShipmentTrackState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  displayMapModal: action.payload,
})

export default createReducer(initialShipmentTrackState, {
  [actions.SHIPMENT_TRACK_GET_DATA_SUCCESS]: receiveList,
  [actions.MAP_MODAL_DISPLAY_CHANGE]: changeDisplayMapModal,
})

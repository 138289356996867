import { useState } from 'react'
import Radio from '@mui/material/Radio'
import Button from '@mui/material/Button'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Dialog, DialogContent } from 'src/stories'
import { useTranslation } from 'react-i18next'

interface UseAsSharedReferenceConfirmationProps {
  open: boolean
  onClose: () => void
  containerNumber: string
  sharedReference: string
  onSave: (sharedReference: string) => void
}

interface UseAsSharedReferenceConfirmationOptionsProps {
  key: string
  value: string
  label: string
}

const UseAsSharedReferenceConfirmation: React.FC<UseAsSharedReferenceConfirmationProps> = ({
  open,
  onSave,
  onClose,
  containerNumber,
  sharedReference,
}) => {
  const { t } = useTranslation()
  const options: UseAsSharedReferenceConfirmationOptionsProps[] = [
    {
      key: 'combine',
      value: `${sharedReference} ${containerNumber} `,
      label: t(
        'shipment_containers.reefer_settings.add_container_number_to_reference',
        'Add container number to current shared shipment reference'
      ),
    },
    {
      key: 'replace',
      value: containerNumber,
      label: t(
        'shipment_containers.reefer_settings.replace_reference_with_container_number',
        'Replace shared shipment reference with container number'
      ),
    },
  ]

  const [value, setValue] = useState(options[0].value)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }
  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={onClose}
      title={t(
        'shipment_containers.reefer_settings.shared_shipment_reference',
        'Shared shipment reference'
      )}
      actions={
        <>
          <Button
            children={t('common.buttons.cancel')}
            variant="text"
            onClick={onClose}
          />
          <Button
            children={t('common.buttons.save')}
            variant="contained"
            onClick={() => {
              onSave(value)
            }}
          />
        </>
      }
    >
      <DialogContent sx={{ width: 600 }}>
        <Typography
          children={t(
            'shipment_containers.reefer_settings.shared_reference_info',
            {
              defaultValue:
                'Currently, there is a shared reference present: {{sharedReference}}',
              sharedReference,
            }
          )}
        />
        <Typography
          mt={3}
          mb={2}
          children={t(
            'shipment_containers.reefer_settings.do_you_want_to',
            'Do you want to:'
          )}
        />
        <FormControl>
          <RadioGroup
            value={value}
            onChange={handleChange}
            name="reference-options-group"
            aria-labelledby="reference-options"
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.key}
                control={<Radio />}
                value={option.value}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
    </Dialog>
  )
}

export default UseAsSharedReferenceConfirmation

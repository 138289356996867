import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ContainerDemurrageAndDetentionItemDetails from './ContainerDemurrageAndDetentionItemDetails'
import {
  getOverdueDays,
  getProgressPercentage,
} from './ContainerDemurrageAndDetention.utils'
import {
  StyledBox,
  BorderLinearProgress,
} from './ContainerDemurrageAndDetention.styles'

const ContainerDemurrageAndDetentionItem: React.FC<{
  demurrageAndDetentionItem: IShipmentContainerDemurrageAndDetention
}> = ({ demurrageAndDetentionItem }) => {
  const {
    costs,
    free_range: freeRange,
    service_item: serviceItem,
  } = demurrageAndDetentionItem
  const { used: usedDays, total: totalDays } = freeRange
  const { name: serviceItemName } = serviceItem
  const overdueDays = getOverdueDays(usedDays, totalDays)
  const progressValue = getProgressPercentage(usedDays, totalDays)

  return (
    <StyledBox data-testid="container-demurrage-and-detention-item">
      <Typography
        mb={1}
        component="div"
        variant="body1Strong"
        children={serviceItemName}
      />
      <Grid container columnSpacing={2} alignItems="center">
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <BorderLinearProgress
            data-testid="container-demurrage-and-detention-item-progress"
            variant="determinate"
            value={progressValue}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ContainerDemurrageAndDetentionItemDetails
            {...{
              usedDays,
              totalDays,
              overdueDays,
              overduePrices: costs,
            }}
          />
        </Grid>
      </Grid>
    </StyledBox>
  )
}

export default ContainerDemurrageAndDetentionItem

import isEmail from 'validator/lib/isEmail'
import { keys, omitBy, map, isNull, compact, toString } from 'lodash'

export const validationErrorsFor = (
  formData: IAddress | IPersonalDetail | IUserState | any,
  validations: Record<string, { (value: string): string | null }[]>,
  force?: boolean
): any => {
  const validators = keys(force ? validations : formData)
  const groupedErrors = map(validators, (validator) => {
    const errors = map(validations[validator], (check) => {
      if (toString(check) === toString(shouldMatchConfirm)) {
        const areEqual =
          formData[validator] === formData['confirm'] && validator !== 'confirm'
        return check(toString(areEqual))
      }

      return check(formData[validator])
    })

    return { [validator]: compact(errors)[0] || null }
  })
  return omitBy(Object.assign({}, ...groupedErrors), isNull)
}

export const isEmpty = (value: string): boolean => !value || value.length === 0

export const cannotBeBlank = (value: string) =>
  isEmpty(value) ? "Can't be blank" : null

export const fieldCannotBeBlank = (value: string) =>
  isEmpty(value) ? 'Field cannot be blank' : null

export const shouldBeEmail = (value: string) =>
  value && !isEmail(value.toString()) ? 'Invalid email' : null

export const shouldMatchConfirm = (value: string) =>
  value === 'false' ? "Passwords don't match" : null

export const shouldHasProperLength = (value: string) =>
  (value || '').length < 6 ? 'Should has at least 6 characters' : null

import { FunctionComponent } from 'react'
import Table from '@mui/material/Table'
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { taskStatusesSchema as schema } from './schema'

interface IProps {
  shipment: AnyReportsShipment
}

const TaskStatusesTable: FunctionComponent<IProps> = ({ shipment }) => {
  if (!Object.values(schema).some((x) => x.shouldRender(shipment))) {
    return null
  }

  return (
    <>
      <div className="title">Tasks statuses</div>
      <div>
        <TableContainer
          className="no-scroll"
          component={(props) => <Paper {...props} square elevation={0} />}
        >
          <Table>
            <TableHead>
              <TableRow>
                {Object.keys(schema).map((key) =>
                  schema[key].shouldRender(shipment) ? (
                    <TableCell
                      key={key}
                      className={schema[key].customClassName}
                    >
                      {schema[key].name}
                    </TableCell>
                  ) : null
                )}
                <TableCell className="full-width" />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {Object.keys(schema).map((key) =>
                  schema[key].shouldRender(shipment) ? (
                    <TableCell
                      key={key}
                      className={schema[key].customClassName}
                    >
                      {schema[key].field(shipment)}
                    </TableCell>
                  ) : null
                )}
                <TableCell className="full-width" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  )
}

export default TaskStatusesTable

import set from 'lodash/set'
import omit from 'lodash/omit'
import uniqBy from 'lodash/uniqBy'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import InputLabel from '@mui/material/InputLabel'
import {
  useGetAddressesAsync,
  useGetOrganizationShipmentRoles,
} from 'src/services/Api/common'
import { useGetOrganizationsAsync } from 'src/services/Api/organizations'
import {
  getAutocompleteSelectOptions,
  getTransformedPartyOptions,
} from '../../../Form.utils'
import { useFormContext } from '../../../FormContext'
import ShipmentTemplateFormAsyncAutocomplete from '../../FormElements/AsyncAutocomplete'
import FieldValueText from '../FieldValueText'
import { TemplateFormContextProps } from '../../../Form.props'

const BookingParty = (props) => {
  const { fieldPath, label, required = false, editable } = props
  const {
    formState,
    onChange,
    shipmentRoles,
  } = useFormContext() as TemplateFormContextProps

  const { shipmentRole, bookingParties } = formState

  const {
    fetchAsync: getAddresses,
    isFetching: addressesFetching,
  } = useGetAddressesAsync()

  const {
    fetchAsync: getOrganizations,
    isFetching: organizationsFetching,
  } = useGetOrganizationsAsync()

  const { user } = useSelector((state: IGlobalState) => ({
    user: state.user,
  }))

  const { data: organizationShipmentRoles } = useGetOrganizationShipmentRoles({
    refetchOnMount: false,
  })

  const currentBookingPartyRoleId = shipmentRoles?.[fieldPath] ?? null

  // Find organization roles that have shipment roles shipper or consignee
  const shipperOrConsigneeOrganizations =
    organizationShipmentRoles?.reduce((filtered: string[], role) => {
      if (
        role.shipment_roles
          .map((shipmentRole) => shipmentRole.id)
          .includes(currentBookingPartyRoleId)
      ) {
        filtered.push(role.name)
      }
      return filtered
    }, []) ?? []

  const selected =
    bookingParties[fieldPath]['collaborator'] ||
    bookingParties[fieldPath]['address']

  const shouldPopulateParty = shipmentRole === currentBookingPartyRoleId

  const selectedPartyType = bookingParties[fieldPath]['collaborator']
    ? 'collaborator'
    : 'address'

  const userOrganizationAsCollaborator = {
    value: user.organizationId,
    label: user.organizationName,
    type: 'collaborator',
  }

  const defaultOptionsBasedOnOrganizationRole = shouldPopulateParty
    ? [userOrganizationAsCollaborator]
    : []

  const defaultOptions = selected
    ? uniqBy(
        [
          userOrganizationAsCollaborator,
          { ...selected, type: selectedPartyType },
        ],
        'value'
      )
    : defaultOptionsBasedOnOrganizationRole

  const [fetchedOptions, setFetchedOptions] = useState(defaultOptions)

  const options = uniqBy([...fetchedOptions, ...defaultOptions], 'value')

  const onSelectChange = (newSelection) => {
    // First reset the form state for shipper or consignee
    const resetFormState = set(
      { ...formState },
      `bookingParties.${fieldPath}`,
      {
        collaborator: null,
        address: null,
      }
    )

    if (!newSelection) {
      onChange(resetFormState)
      setFetchedOptions([])
      return
    }

    // Then set the new selection as shipper or consignee
    const newFormState = set(
      { ...resetFormState },
      `bookingParties.${fieldPath}.${newSelection.type}`,
      omit(newSelection, 'type')
    )

    onChange(newFormState)
  }

  const onInputChange = async (search) => {
    const hasOption = options.find((option) => option.label === search)
    if (search.length === 0 || !!hasOption) {
      return
    }
    const orgData = await getOrganizations({ search })
    const addressData = await getAddresses({
      search,
      organization_id: user.organizationId,
      page_size: 100,
    })

    // Not every organization can be a shipper or consignee
    // We filter the organizations that can be shipper or consignee based on the shipment role
    const filteredOrgData =
      orgData?.filter((org) =>
        shipperOrConsigneeOrganizations.includes(org?.role ?? '')
      ) ?? []

    const newlyFetchedOptions = getTransformedPartyOptions(
      filteredOrgData,
      addressData
    )

    const newOptions =
      getAutocompleteSelectOptions(defaultOptions, newlyFetchedOptions) ?? []

    setFetchedOptions(newOptions)
  }

  const autoCompleteDisabled =
    shouldPopulateParty && !bookingParties[fieldPath]['address']

  useEffect(() => {
    if (!editable) {
      return
    }
    if (!shouldPopulateParty) {
      setFetchedOptions([])
    }
  }, [shipmentRole])

  return (
    <>
      <InputLabel children={label} required={required} />
      {editable && (
        <ShipmentTemplateFormAsyncAutocomplete
          isFetched={true}
          options={options}
          required={required}
          selected={selected}
          onChange={onSelectChange}
          onInputChange={onInputChange}
          disabled={autoCompleteDisabled}
          isFetching={addressesFetching || organizationsFetching}
          data-testid={`booking-party-autocomplete-${fieldPath}`}
        />
      )}
      {!editable && <FieldValueText text={selected?.label} />}
    </>
  )
}

export default BookingParty

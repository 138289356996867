import { useState, useCallback, SyntheticEvent } from 'react'
import {
  Box,
  Paper,
  TextField,
  PaperProps,
  Autocomplete,
  MenuItemProps,
} from '@mui/material'
import { debounce, delay } from 'lodash'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { MenuItem } from 'src/stories/Lab/Menu/components/MenuItem'
import {
  AutocompleteOptionPropsOptionProps,
  InlandTransportRowTransportSearchProps,
} from './InlandTransportRow.props'
import InlandTransportRowEditableCellText from './InlandTransportRowEditableCellText'

const CustomPaper = (props: PaperProps) => {
  return <Paper elevation={8} {...props} />
}

const getRenderedOption = (
  props: MenuItemProps,
  option: AutocompleteOptionPropsOptionProps,
  { selected }
) => {
  const { className, ...otherProps } = props
  return (
    <MenuItem
      {...otherProps}
      selected={selected}
      data-testid="inland-transport-search-menu-item"
      text={option.label as string}
    />
  )
}

const InlandTransportRowTransportSearch: React.FC<InlandTransportRowTransportSearchProps> = ({
  selected,
  options,
  onChange,
  isFetched,
  isFetching,
  onInputChange,
  inputPlaceholder,
  inputWidth,
  inline,
  disabled,
}) => {
  const [editing, setEditing] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const noOptionsText =
    isFetched && inputValue.length > 0 ? 'No options' : 'Start typing...'

  const handleFocus = (event: { target: { select: () => void } }) => {
    delay(() => {
      event.target.select()
    }, 50)
  }

  const closeEdit = () => {
    setEditing(false)
  }

  const handleOnChange = useCallback(
    (
      _event: SyntheticEvent<Element, Event>,
      newValue: AutocompleteOptionPropsOptionProps | null
    ) => {
      onChange(newValue)
      closeEdit()
    },
    [onChange]
  )

  const handleDebounceFn = (search: string) => {
    onInputChange(search)
    setInputValue(search)
  }

  const handleInputChangeWithDebounce = useCallback(
    debounce(handleDebounceFn, 500),
    [onChange]
  )

  const handleOnInputChange = (
    _event: SyntheticEvent<Element, Event>,
    search: string
  ) => {
    handleInputChangeWithDebounce(search)
  }

  return (
    <Box
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      width={inputWidth || 200}
      height="100%"
      display="flex"
      alignItems="center"
      data-testid="inland-transport-search"
    >
      {!editing && !inline && (
        <InlandTransportRowEditableCellText
          text={selected?.label ?? null}
          onClick={() => {
            setEditing(true)
          }}
        />
      )}
      {(editing || inline) && (
        <>
          <Autocomplete
            fullWidth
            value={selected}
            openOnFocus={true}
            disabled={disabled}
            options={options}
            loading={isFetching}
            disableClearable={true}
            onChange={handleOnChange}
            PaperComponent={CustomPaper}
            noOptionsText={noOptionsText}
            renderOption={getRenderedOption}
            onInputChange={handleOnInputChange}
            popupIcon={<KeyboardArrowDownIcon />}
            data-testid="inland-transport-autocomplete"
            isOptionEqualToValue={(option, selected) => {
              return option.value === selected.value
            }}
            renderInput={(params) => (
              <TextField
                autoFocus={!inline}
                {...params}
                inputProps={{
                  ...params.inputProps,
                  'data-testid': 'inland-transport-autocomplete-input',
                }}
                onBlur={closeEdit}
                onFocus={handleFocus}
                placeholder={inputPlaceholder}
              />
            )}
          />
        </>
      )}
    </Box>
  )
}

export default InlandTransportRowTransportSearch

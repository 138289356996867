import { searchParams } from 'src/components/Overview/Search/constants'
import {
  modalityOptions,
  loadTypeOptions,
  pickupOrDeliveryStatusOptions,
} from '../constants'
import { FilterOptionValue } from '../types'
import AutocompleteBase from './AutocompleteBase'

const AutocompleteMulti = ({ token, onDelete }) => {
  const valuePickerOptions = {
    [searchParams.modality]: modalityOptions,
    [searchParams.loadType]: loadTypeOptions,
    [searchParams.pickupStatus]: pickupOrDeliveryStatusOptions,
    [searchParams.deliveryStatus]: pickupOrDeliveryStatusOptions,
  }

  const defaultOptions: FilterOptionValue[] =
    valuePickerOptions?.[token.value] ?? []

  const handleDelete = () => {
    onDelete(token.value)
  }

  const noOptionsText = 'No options'

  return (
    <AutocompleteBase
      options={defaultOptions}
      noOptionsText={noOptionsText}
      token={token}
      loading={false}
      onDelete={handleDelete}
    />
  )
}

export default AutocompleteMulti

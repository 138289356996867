import { DialogActions } from '@mui/material'
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog'
import MuiDialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import './styles.scss'

export interface DialogProps extends MuiDialogProps {
  /**
   * Dialog title
   */
  title?: string
  /**
   * Dialog actions
   */
  actions?: React.ReactNode
  /**
   * Optionally display close icon
   */
  showCloseIcon?: boolean
}

export const Dialog = (props: DialogProps) => {
  const {
    onClose,
    open,
    id,
    title,
    children,
    actions,
    showCloseIcon = true,
    ...rest
  } = props

  const handleClose = () => {
    onClose?.({}, 'backdropClick')
  }

  return (
    <MuiDialog id={id} open={open} onClose={onClose} {...rest}>
      <MuiDialogTitle component="h3" id={id}>
        {title}
        {showCloseIcon && onClose ? (
          <IconButton
            aria-label="close"
            sx={{ p: 0.5 }}
            onClick={handleClose}
            size="large"
            data-testid="close-window"
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
      {children}
      {actions && <DialogActions>{actions}</DialogActions>}
    </MuiDialog>
  )
}

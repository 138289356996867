import { FunctionComponent, memo } from 'react'
import Paper from '@mui/material/Paper'
import useLocalStorage from 'src/hooks/useLocalStorage'
import ShipmentsHeader from './ShipmentsHeader'
import ShipmentsFilters from './ShipmentsFilters/ShipmentsFilters'
import ShipmentsTabs from './ShipmentsTabs'
import ShipmentsPagination from './ShipmentsPagination'
import ShipmentsBody from './ShipmentsBody'
import './styles.scss'

const Shipments: FunctionComponent = () => {
  const [showAllFilters, setShowAllFilters] = useLocalStorage(
    'showShipmentsFilters',
    true
  )

  const filtersToggle = () => {
    setShowAllFilters(!showAllFilters)
  }

  return (
    <article
      className={`shipments-page ${
        showAllFilters ? 'shipments-page--show-filters' : ''
      }`}
    >
      <ShipmentsHeader
        onFiltersVisibilityToggle={filtersToggle}
        showAllFilters={showAllFilters}
      />
      <ShipmentsFilters showAllFilters={showAllFilters} />
      <Paper
        variant="elevation"
        sx={{ mt: 2 }}
        className="shipments-page__shipments-wrapper"
      >
        <div className="shipments-page__content">
          <ShipmentsTabs />
          <ShipmentsBody />
          <ShipmentsPagination />
        </div>
      </Paper>
    </article>
  )
}

export default memo(Shipments, () => true)

import { useState } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Chip from '@mui/material/Chip'
import { useTranslation } from 'react-i18next'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { getDateIsExpired } from 'src/utils/helpers/shipmentDate'
import {
  ShipmentQuotationStatusEnum,
  ShipmentQuotationStatus,
} from 'src/config/constants'
import { capitalize } from 'lodash'
import { ButtonProps } from '@mui/material'
import { CarrierAvatar } from 'src/stories/MUI/CarrierAvatar'
import { useStyles } from './constants'
import { getDateDisplayProps, getRevenueItemRows } from './utils'
import { ShipmentCostsItemsTable } from './ShipmentCostsItemsTable'
import { ShipmentCostsLoadingButton } from './ShipmentCostsLoadingButton'

interface ShipmentCostsProposedQuoteStatusTableRowProps {
  size: number
  fastest: boolean
  longest: boolean
  loading: boolean
  cheapest: boolean
  row: IShipmentQuotation
  onBookClicked: (quotation: IShipmentQuotation) => void
}

export const ShipmentCostsAirQuotationsTableRow: React.FC<ShipmentCostsProposedQuoteStatusTableRowProps> = ({
  row,
  size,
  loading,
  fastest,
  longest,
  cheapest,
  onBookClicked,
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const { quote_expiration_at: quoteExpiration, status } = row
  const classes = useStyles()
  const quoteExpired = getDateIsExpired(quoteExpiration)
  const dateDisplayProps = getDateDisplayProps(
    quoteExpired,
    status as ShipmentQuotationStatusEnum
  )
  const variant = open ? 'contained' : 'outlined'
  const buttonProps = {
    fullWidth: true,
    size: 'large' as ButtonProps['size'],
    variant: variant as ButtonProps['variant'],
    sx: {
      height: '40px',
    },
  }

  const quoteIsOpen = status === ShipmentQuotationStatusEnum.Open
  const quoteIsAlreadyAccepted = !!row.shipment_booked_at
  const statusDisplay = ShipmentQuotationStatus[status]

  const carrierScac: string = row.carrier?.scac ?? '5E'

  return (
    <>
      <TableRow className="shipment-quotation-outer-table-row">
        <TableCell sx={{ width: '90px' }}>
          <Tooltip title={row.carrier.name} placement="right">
            <CarrierAvatar
              name={row.carrier.name}
              scac={carrierScac}
              modality="air"
            />
          </Tooltip>
        </TableCell>
        <TableCell sx={{ width: '100px' }}>
          <Typography
            component="h5"
            variant="body1Strong"
            children={row.transit_time}
          />
          {capitalize(row.transit_type)}
          <Typography noWrap children={row.transit_remarks} />
        </TableCell>
        <TableCell
          sx={{
            py: 1,
            width: 'auto',
            maxWidth: '400px',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            textOverflow: 'inherit',
          }}
        >
          <Typography component="h5" variant="body1Strong" children="Route" />
          {row.loading_port.name} - {row.discharge_port.name}
          {row.departure_days.length > 0 && (
            <Typography mt={0.25} variant="subtitle1" color="text.secondary">
              {t('shipment_costs.table_row.departure_date', {
                defaultValue: 'Departures on: {{days}}',
                days: row.departure_days.map(capitalize).join(', '),
              })}
            </Typography>
          )}
        </TableCell>
        <TableCell sx={{ width: 'auto' }}>
          <Stack spacing={0.5} sx={{ alignItems: 'start' }}>
            {quoteIsOpen && (
              <>
                {!quoteIsAlreadyAccepted && size > 1 && (
                  <>
                    {cheapest && (
                      <Chip
                        label={t(
                          'shipment_costs.air.table_row.cheapest',
                          'Cheapest'
                        )}
                        size="small"
                        color="success"
                      />
                    )}
                    {fastest && (
                      <Chip
                        label={t(
                          'shipment_costs.air.table_row.fastest',
                          'Fastest'
                        )}
                        size="small"
                        color="secondary"
                      />
                    )}
                    {longest && !fastest && (
                      <Chip
                        label={t(
                          'shipment_costs.air.table_row.longest',
                          'Longest'
                        )}
                        size="small"
                        color="warning"
                      />
                    )}
                  </>
                )}
                {quoteIsAlreadyAccepted && (
                  <Chip
                    label={t('shipment_costs.air.table_row.new', 'New')}
                    size="small"
                    color="info"
                  />
                )}
              </>
            )}
            {!quoteIsOpen && (
              <Chip
                size="small"
                label={statusDisplay.text}
                color={statusDisplay.color}
              />
            )}
          </Stack>
        </TableCell>
        <TableCell align="right" sx={{ width: '150px' }}>
          <Typography variant="body1Strong">
            {row.costs_total_eur} {t('common.currencies.eur', 'EUR')}
          </Typography>
          <Typography variant="subtitle1" color={dateDisplayProps.color}>
            {dateDisplayProps.text}
            {status !== ShipmentQuotationStatusEnum.Canceled && (
              <>
                {': '}
                {t('common.date_medium', {
                  defaultValue: 'date, DATE_MED',
                  date: row[dateDisplayProps.prop],
                })}
              </>
            )}
          </Typography>
        </TableCell>
        {size > 0 && (
          <TableCell align="right" sx={{ width: '100px' }}>
            {quoteIsOpen && !quoteExpired && (
              <ShipmentCostsLoadingButton
                text={t('common.buttons.book', 'Book')}
                {...buttonProps}
                loading={loading}
                onClick={() => {
                  onBookClicked(row)
                }}
              />
            )}
          </TableCell>
        )}

        <TableCell align="right" sx={{ width: '40px' }}>
          <IconButton
            size="small"
            aria-label="expand row"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow className="shipment-quotation-inner-table-wrapper">
        <TableCell
          colSpan={size > 0 ? 7 : 6}
          classes={{
            root: open
              ? classes.expandedExpandableCell
              : classes.collapsedExpandableCell,
          }}
          sx={{ paddingBottom: 0, paddingTop: 0, borderBottom: 0 }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ my: 1, overflowX: 'auto' }}>
              <ShipmentCostsItemsTable
                rows={getRevenueItemRows(row.proposed_revenues)}
                totals={{
                  usd: row.costs_total_usd,
                  eur: row.costs_total_eur,
                }}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

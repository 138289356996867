import { FunctionComponent, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { Link } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'
import { permissionTo, promisifyAction } from '../../utils'
import useLocalStorage from '../../hooks/useLocalStorage'
import useScopedOrganizationFromUrl from '../../hooks/useScopedOrganizationFromUrl'

import { toggleApplicationFilterModal } from '../../stores/actionCreators'
import './styles.scss'

const FilterOrganizationIndicator: FunctionComponent<{}> = () => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const [organization, setOrganization] = useLocalStorage<IOrganization | null>(
    'scopedOrganization',
    null
  )

  useScopedOrganizationFromUrl(setOrganization)

  const toggleApplicationFilterModalAsync = promisifyAction(
    dispatch,
    toggleApplicationFilterModal
  )

  const openApplicationFilterModal = useCallback(() => {
    toggleApplicationFilterModalAsync(true)
  }, [])

  const removeFiltering = useCallback(() => {
    setOrganization(null)
    window.location.reload()
  }, [])

  function OrganizationFilter() {
    return (
      <>
        <div className="impersonation-block--impersonator">
          <div className="icon-block">
            <WarningIcon />
            {t(
              'top_navigation.filter_applicaiton.notification.title',
              'Filtering'
            )}
          </div>
          <div>
            <Trans
              i18nKey="top_navigation.filter_applicaiton.notification.description"
              defaults="You are filtering the application by <orgLink>{{orgName}}</orgLink>"
              values={{
                orgName: organization ? organization.name : '',
              }}
              components={{
                orgLink: (
                  <Link
                    variant="body1"
                    component="button"
                    onClick={openApplicationFilterModal}
                  >
                    {organization ? organization.name : ''}
                  </Link>
                ),
              }}
            />
          </div>
          <Link variant="body1" component="button" onClick={removeFiltering}>
            {t(
              'top_navigation.filter_applicaiton.notification.remove_filtering_button',
              'Remove filtering'
            )}
          </Link>
        </div>
      </>
    )
  }

  return !!organization ? (
    <div className="impersonation-block">
      {organization && permissionTo('menu.account.application_filter') && (
        <OrganizationFilter />
      )}
    </div>
  ) : null
}

export default FilterOrganizationIndicator

import { FunctionComponent } from 'react'

import './styles.scss'

export interface ColorBadgeProps {
  /**
   * Content (text or text with icon) to show within the component
   */
  content: React.ReactNode
  /**
   * Showing certain colors of colorbadge
   */
  className?:
    | 'green'
    | 'red'
    | 'yellow'
    | 'grey'
    | 'blue'
    | 'blue-outlined'
    | 'yellow-outlined'
    | 'light-grey'
    | 'light-orange'
    | 'purple'
    | 'light-blue'
    | 'dark-blue'
    | 'light-green'
    | 'light-red'
    | 'purple-outlined'
    | ''
  /**
   * Change size for display counters
   */
  size?: 'short' | ''
}

const ColorBadge: FunctionComponent<ColorBadgeProps> = (props) => {
  const className: string = props.className || ''

  return (
    <span className={`color-badge ${className} ${props.size}`}>
      {props.content}
    </span>
  )
}

export default ColorBadge

import { useState } from 'react'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import TableSkeleton from 'src/stories/MUI/TableSkeleton'
import { IDemurrageAndDetentionRow } from 'src/@types/endpoints/demurrageAndDetention'
import {
  DemurrageAndDetentionContentProps,
  DemurrageAndDetentionPortPairProps,
} from './DemurrageAndDetentionContent.props'
import DemurrageAndDetentionContentRatesEmptyState from './DemurrageAndDetentionContentRatesEmptyState'
import DemurrageAndDetentionContentRatesSectionHeader from './DemurrageAndDetentionContentRatesSectionHeader'
import DemurrageAndDetentionContentRatesSections from './DemurrageAndDetentionContentRatesSections'
import DemurrageAndDetentionContentRatesPortPairs from './DemurrageAndDetentionContentRatesPortPairs'
import { StyledBox } from './DemurrageAndDetentionContent.styles'

const DemurrageAndDetentionContentRates: React.FC<DemurrageAndDetentionContentProps> = ({
  items,
  columns,
  isLoading,
  currentTab,
}) => {
  const [currentPortPair, setCurrentPortPair] = useState('')
  const currentTabItems = currentTab
    ? items.filter(
        (item: IDemurrageAndDetentionRow) => item.carrier === currentTab
      )
    : items

  const itemsWithPortPairs = currentTabItems.filter(
    (currentTabItem) => currentTabItem.portPair.length > 0
  )

  const portPairGroups = [
    ...new Set(itemsWithPortPairs.map((item) => item.portPair)),
  ]

  const portPairs: DemurrageAndDetentionPortPairProps[] = portPairGroups.map(
    (portPair) => ({
      id: portPair,
      label: portPair,
    })
  )

  const hasPortPairs = currentPortPair.length > 0

  const filteredItems = hasPortPairs
    ? currentTabItems.filter((item) => item.portPair === currentPortPair)
    : currentTabItems

  const onPortPairChange = (newPortPair: string) => {
    setCurrentPortPair(newPortPair)
  }

  return (
    <Grid
      px={3}
      container
      spacing={2}
      data-testid="demurrage-and-detention-rates"
    >
      <Grid item xs={12} md={12}>
        {isLoading && (
          <>
            <DemurrageAndDetentionContentRatesSectionHeader
              title={<Skeleton width={100} />}
            />
            <TableSkeleton rows={8} columns={4} component={StyledBox} />
          </>
        )}
        {!isLoading && (
          <>
            {filteredItems.length > 0 && (
              <>
                <DemurrageAndDetentionContentRatesPortPairs
                  portPairs={portPairs}
                  currentTab={currentTab}
                  onPortPairChange={onPortPairChange}
                />
                <DemurrageAndDetentionContentRatesSections
                  columns={columns}
                  items={filteredItems}
                  showSectionSubtitle={!hasPortPairs}
                />
              </>
            )}
            {filteredItems.length === 0 && (
              <DemurrageAndDetentionContentRatesEmptyState />
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default DemurrageAndDetentionContentRates

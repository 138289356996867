import {
  FunctionComponent,
  useState,
  useCallback,
  useEffect,
  ReactNode,
  forwardRef,
} from 'react'
import debounce from 'lodash/debounce'
import omit from 'lodash/omit'
import Typography from '@mui/material/Typography'
import InputBase, { InputBaseProps } from '@mui/material/InputBase'
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import NumberFormat from 'react-number-format'
import { createStyles, makeStyles } from '@mui/styles'

export interface CustomInputProps
  extends Omit<InputBaseProps, 'onChange' | 'onBlur'> {
  label?: ReactNode
  helperText?: string
  onChange?: (value: string, name: string) => void
  onBlur?: (value: string, name: string) => void
  'data-testid'?: string
  debouncevalue?: number
}

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

export const MuiInputLabel: FunctionComponent<InputLabelProps> = (props) => {
  return <InputLabel shrink style={{ transform: 'scale(1)' }} {...props} />
}

export const MuiInputBase: FunctionComponent<CustomInputProps> = (props) => {
  const { onChange, onBlur, value = '', debouncevalue } = props
  const [inputValue, setInputValue] = useState(value)
  const classes = useStyles()

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const onChangeFunc = (event) => {
    return onChange && onChange(event.target.value, event.target.name)
  }

  const debounceFunction = useCallback(
    debounce(onChangeFunc, debouncevalue || 0),
    [props]
  )

  const onInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInputValue(event.target.value)
    if (debouncevalue) {
      return debounceFunction(event)
    } else {
      return onChangeFunc(event)
    }
  }

  const onInputBlur = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (onBlur) {
      onBlur(event.target.value, event.target.name)
    }
  }

  return (
    <InputBase
      {...omit(props, 'data-testid')}
      inputProps={{
        className: props.className,
        'data-testid': props['data-testid'],
      }}
      value={inputValue}
      className={classes.root}
      onChange={onInputChange}
      onBlur={onInputBlur}
    />
  )
}

export const NumberFormatCustom = forwardRef(
  (props: NumberFormatCustomProps, ref) => {
    const { onChange, ...other } = props

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }}
        thousandSeparator
        isNumericString
      />
    )
  }
)

const CustomInput = ({
  label,
  helperText,
  onChange,
  onBlur,
  ...props
}: CustomInputProps) => {
  return (
    <>
      {label && (
        <MuiInputLabel
          htmlFor={props.id}
          disabled={props.disabled}
          required={props.required}
        >
          {label}
        </MuiInputLabel>
      )}
      <MuiInputBase onChange={onChange} onBlur={onBlur} {...props} />
      {helperText && (
        <FormHelperText error={props.error}>
          <Typography variant="subtitle2" color="error" children={helperText} />
        </FormHelperText>
      )}
    </>
  )
}

export default CustomInput

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      transition: 'none',
      padding: '0 12px',
      height: 40,
      width: '100%',
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: theme.shape.borderRadius,
      fontSize: 15,
      '&::placeholder': {
        color: theme.palette.grey[600],
        opacity: 1,
      },
      '&:hover': {
        borderColor: theme.palette.grey[600],
      },
      '&.Mui-focused': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-error': {
        border: `1px solid ${theme.palette.error.main}`,
      },
      '&.Mui-disabled': {
        border: `1px solid ${theme.palette.grey[300]}`,
      },
    },
  })
)

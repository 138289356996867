import { FunctionComponent, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { showNotification } from 'src/stores/actionCreators/notifications'
import {
  capitalize,
  isEmpty,
  find,
  each,
  isEqual,
  values,
  every,
  mapValues,
  isString,
} from 'lodash'
import { Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import Typography from '@mui/material/Typography'
import Checkbox from 'src/components/Checkbox'
import { useTranslation } from 'react-i18next'
import { promisifyAction, permissionTo, Logger } from '../../../../utils'
import { InfoTooltip, Dialog, DialogContent } from '../../../../stories/'

import {
  addressesAddAddress,
  addressesPutAddress,
  personalDetailsAddPersonalDetail,
  personalDetailsPutPersonalDetail,
  assignAddress,
  assignContact,
  shipmentOverviewGetData,
  countriesGetCountries,
  assignShipperAsCollaborator,
} from '../../../../stores/actionCreators'

import AddressForm from '../AddressForm'
import ContactForm, { IContactFormState } from '../ContactForm'
import contactValidators from './contactValidators'
import companyValidators from './companyValidators'

import './styles.scss'

interface IProps {
  open: boolean
  type: string
  booking: IBooking
  handleClose: () => void
  permission: boolean
  disableTextInputs: boolean
  addressBookPermission: boolean
  shipmentId: number
  bookingId: number
  fetchData: () => void
}

type FormValue = string | number | null

interface IDataValidation {
  valid: boolean
  errors: IContactFormState
}

interface IInstructionsModalState {
  open: boolean
  companyData: IContactFormState
  contactData: IContactFormState
  companyErrors: any
  contactErrors: any
  showValidationCompany: boolean
  showValidationContact: boolean
  dialogCompanyDescription: string
  dialogContactDescription: string
  newCompany: boolean
  changeCompany: boolean
  disableSave: boolean
  isContactEmailValid: boolean
  isCompanyNameValid: boolean
}

const initialState: IInstructionsModalState = {
  open: false,
  companyData: {},
  contactData: {},
  companyErrors: {},
  contactErrors: {},
  showValidationCompany: false,
  showValidationContact: false,
  dialogCompanyDescription: '',
  dialogContactDescription: '',
  newCompany: false,
  changeCompany: false,
  disableSave: true,
  isContactEmailValid: true,
  isCompanyNameValid: true,
}

const filterCompany = (contact: IContactFormState, isInitial: boolean) => ({
  address_id: isInitial ? contact.address_id : contact.id,
  company_name: contact.name || '',
  address: contact.address || '',
  city: contact.city || '',
  postal_code: contact.postal_code || '',
  country_id: contact.country.id || null,
  vat_number: contact.vat_number || '',
  eori_number: contact.eori_number || '',
  comment: contact.comment || '',
})

const filterContact = (contact) => ({
  name: contact.name || '',
  email: contact.email || '',
  phone: contact.phone || '',
  comment: contact.comment || '',
  contact_id: contact.id || '',
})

const filterAddress = (address) => ({
  name: address.company_name || '',
  address: address.address || '',
  city: address.city || '',
  postal_code: address.postal_code || '',
  country_id: address.country_id || null,
  vat_number: address.vat_number || '',
  eori_number: address.eori_number || '',
  comment: address.comment || '',
})

const BookingPartiesModal: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const [state, setState] = useState<IInstructionsModalState>(initialState)
  const [assignConnection, setAssignConnection] = useState<boolean>(false)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  const store = useStore()
  const dispatch = useDispatch()

  const saveCompany = promisifyAction(dispatch, addressesAddAddress)
  const saveContact = promisifyAction(
    dispatch,
    personalDetailsAddPersonalDetail
  )
  const assignConnectionAsCollaborator = promisifyAction(
    dispatch,
    assignShipperAsCollaborator
  )
  const updateCompany = promisifyAction(dispatch, addressesPutAddress)
  const updateContact = promisifyAction(
    dispatch,
    personalDetailsPutPersonalDetail
  )
  const submitCompany = promisifyAction(dispatch, assignAddress)
  const submitContact = promisifyAction(dispatch, assignContact)
  const fetchOverview = promisifyAction(dispatch, shipmentOverviewGetData)
  const showSuccess = (message: string, duration?: number) => {
    dispatch(showNotification({ message, severity: 'success' }))
  }
  const showError = (message: string, duration?: number) => {
    dispatch(showNotification({ message, severity: 'error' }))
  }

  const { addresses, contacts } = useSelector((state: IGlobalState) => ({
    addresses: state.addresses.list,
    contacts: state.personalDetails.list,
  }))

  useEffect(() => {
    dispatch(countriesGetCountries())
  }, [])

  useEffect(() => {
    if (
      !!props.booking[props.type] &&
      props.booking[props.type].shipment_address
    ) {
      setState((currentState) => {
        return {
          ...currentState,
          companyData: filterCompany(
            props.booking[props.type].shipment_address,
            true
          ),
        }
      })
    }
  }, [props.type])

  const handleClose = () => {
    props.handleClose()
    setState(initialState)
  }

  const handleCompanyChange = (data: IContactFormState) => {
    const { companyData } = state
    const changeCompany: boolean =
      !isEmpty(companyData) &&
      !!data.address_id &&
      data.address_id !== companyData.address_id

    setState((currentState) => {
      return {
        ...currentState,
        companyData: data,
        newCompany: !data.address_id,
        companyErrors: changeCompany ? {} : state.companyErrors,
        contactErrors: changeCompany ? {} : state.contactErrors,
        changeCompany,
      }
    })
  }

  const renderSubmit = (): React.ReactNode => {
    const { disableSave } = state
    return (
      <>
        <Button variant="outlined" onClick={handleClose}>
          {t('common.buttons.cancel', 'Cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          disabled={disableSave || buttonLoading}
          onClick={submit}
          loading={buttonLoading}
        >
          {t('common.buttons.save', 'Save')}
        </LoadingButton>
      </>
    )
  }

  const onAssignConnectionChange = () => {
    setAssignConnection(!assignConnection)
    setState((currentState) => {
      return {
        ...currentState,
        disableSave: false,
      }
    })
  }

  const assignConnectionCondition = useMemo(() => {
    const fullConnection = find(addresses, { id: state.companyData.address_id })
    return !!fullConnection && !!fullConnection.connection
  }, [state.companyData])

  const renderConnections = (): React.ReactNode => {
    return (
      <div className="instructions__modal__connection-block">
        <Typography
          sx={{ px: 0.75, mb: 0.25, display: 'flex', alignItems: 'center' }}
          variant="body1Strong"
        >
          {t('shipments.bookings.connection', 'Connection')}
          <InfoTooltip
            title={t(
              'shipments.bookings.connection_description',
              'By linking an address to an connection you are enabled to automatically add this organization as a collaborator to shipments and orders.'
            )}
          />
        </Typography>
        <div>
          <label
            className={`instructions__modal__connection-block--checkbox-group ${
              !assignConnectionCondition ? 'disabled' : ''
            }`}
          >
            <Checkbox
              checked={assignConnection}
              onChange={onAssignConnectionChange}
              isDisabled={!assignConnectionCondition}
              isIndeterminate={!assignConnectionCondition}
            />
            {t(
              'shipments.bookings.add_connection_input',
              'Add connection as collaborator'
            )}
          </label>
        </div>
      </div>
    )
  }

  const checkCompanyName = (name: string): void => {
    if (name) {
      if (addresses.find((x) => x.name === name)) {
        const companyErrors = {
          ...state.companyErrors,
          company_name: t(
            'shipments.bookings.errors.address_exists',
            'Address already exists'
          ),
        }
        setState((currentState) => {
          return {
            ...currentState,
            isCompanyNameValid: false,
            companyErrors,
          }
        })
      } else {
        const companyErrors = {
          ...state.companyErrors,
          company_name: null,
        }
        setState((currentState) => {
          return {
            ...currentState,
            companyErrors,
            isCompanyNameValid: true,
          }
        })
      }
    }
  }

  const trimValues = (object) => {
    return mapValues(object, (val) => (isString(val) ? val.trim() : val))
  }

  const checkEmptyContact = (contactData: IContactFormState): boolean => {
    const contactFormValues: FormValue[] = values(contactData)
    return every(contactFormValues, (value) => value === '')
  }

  const checkValidation = (data: IContactFormState, isCompany: boolean) => {
    let isValid: boolean = true
    const errorNotes: any = {}
    const validators: IContactFormState = isCompany
      ? companyValidators
      : contactValidators
    each(validators, ({ field, validate }: IFieldValidator): void => {
      const errorMessage: string = validate(data[field])
      if (errorMessage) {
        errorNotes[field] = errorMessage
        isValid = false
      }
    })
    if (isCompany ? !state.isCompanyNameValid : !state.isContactEmailValid) {
      isValid = false
      if (isCompany) {
        if (!errorNotes.company_name) {
          errorNotes.company_name = t(
            'shipments.bookings.errors.address_exists',
            'Address already exists'
          )
        }
      } else {
        if (!errorNotes.email) {
          errorNotes.email = t(
            'shipments.bookings.errors.email_exists',
            'Contact email already exists'
          )
        }
      }
    }
    return { valid: isValid, errors: errorNotes }
  }

  const checkFormChanges = (
    data: IContactFormState,
    isCompany: boolean
  ): boolean => {
    if (isCompany) {
      const company: IAddress | undefined = find(addresses, {
        id: data.address_id,
      })
      const filteredCompany = company ? filterCompany(company, false) : {}
      const updateExist: boolean = isEqual(data, filteredCompany)
      return updateExist
    } else {
      const contact: IPersonalDetail | undefined = find(contacts, {
        id: data.contact_id,
      })
      const filteredContact = contact ? filterContact(contact) : {}
      const updateExist: boolean = isEqual(data, filteredContact)
      return updateExist
    }
  }

  const checkAssignContact = async (): Promise<void> => {
    const { companyData, contactData } = state
    const fullContact: IPersonalDetail | undefined = find(contacts, {
      id: contactData ? contactData.contact_id : null,
    })
    if (
      !isEmpty(companyData) &&
      !!fullContact &&
      !fullContact.company_address_id
    ) {
      const updateData = {
        company_address_id: companyData ? companyData.address_id : null,
      }
      if (contactData) {
        try {
          await updateContact(contactData.contact_id, updateData)
        } catch (error) {
          Logger.log(error)
        }
      }
    }
  }

  const setSaveDisable = (status: boolean): void => {
    setState((currentState) => {
      return {
        ...currentState,
        disableSave: status,
      }
    })
  }

  const handleContactChange = (data: IContactFormState | null): void => {
    if (data === null) return
    setState({
      ...state,
      contactData: data,
    })
  }

  const submit = async (): Promise<void> => {
    setButtonLoading(true)
    const { type, booking } = props
    const companyData = trimValues(state.companyData)
    const contactData = trimValues(state.contactData)
    const bookingId: number | null = (booking && booking.id) || null
    const companyValidation: IDataValidation = checkValidation(
      companyData,
      true
    )
    const contactValidation: IDataValidation = checkValidation(
      contactData,
      false
    )
    const validationCondition = checkEmptyContact(contactData)
      ? companyValidation.valid
      : companyValidation.valid && contactValidation.valid
    const errorMessage: string = t(
      'shipments.bookings.errors.cannot_save_form',
      'Your {{type}} forms could not be saved. Please see the errors messages.',
      { type: capitalize(type) }
    )
    if (!isEmpty(companyData) && !!companyData.address_id) {
      setState((currentState) => {
        return {
          ...currentState,
          newCompany: false,
          changeCompany: false,
        }
      })
    }
    if (validationCondition) {
      try {
        if (companyData.address_id) {
          if (checkFormChanges(companyData, true)) {
            await submitCompany(
              { address_id: companyData.address_id, booking_party: type },
              bookingId
            )
          } else {
            try {
              if (permissionTo('address_book.manage')) {
                await updateCompany(companyData.address_id, companyData)
              }
              await submitCompany(
                { address_id: companyData.address_id, booking_party: type },
                bookingId
              )
              showSuccess(
                t(
                  'shipments.bookings.notification.address_book_updated',
                  'Address book was updated'
                ),
                4000
              )
            } catch (error) {
              Logger.log(error)
            }
          }
        } else {
          await saveCompany(filterAddress(companyData))
          const singleCreatedAddress = store.getState().addresses.singleAddress
          if (singleCreatedAddress) {
            const newData = companyData
            newData.address_id = singleCreatedAddress.id
            await submitCompany(
              { address_id: newData.address_id, booking_party: type },
              bookingId
            )
          }
        }
        if (contactData.contact_id) {
          if (checkFormChanges(contactData, false)) {
            await submitContact(
              {
                contact_id: contactData.contact_id,
                booking_party: type,
                comment: contactData.comment || '',
              },
              bookingId
            )
          } else {
            try {
              if (permissionTo('address_book.manage')) {
                await updateContact(contactData.contact_id, contactData)
              }
              await submitContact(
                {
                  contact_id: contactData.contact_id,
                  booking_party: type,
                  comment: contactData.comment || '',
                },
                bookingId
              )
              checkAssignContact()
              showSuccess(
                t(
                  'shipments.bookings.notification.address_book_updated',
                  'Address book was updated'
                ),
                4000
              )
              handleClose()
            } catch (error) {
              Logger.log(error)
            }
          }
        } else {
          if (checkEmptyContact(contactData)) {
            const emptyConatctData: IContactFormState = contactData
            emptyConatctData.contact_id = null
            await submitContact(
              { contact_id: emptyConatctData.contact_id, booking_party: type },
              bookingId
            )
          } else {
            await saveContact(contactData)
            const singleCreatedPersonalDetail = store.getState().personalDetails
              .singlePersonalDetail
            if (singleCreatedPersonalDetail) {
              const newContactData = contactData
              newContactData.contact_id = singleCreatedPersonalDetail.id
              await submitContact(
                {
                  contact_id: newContactData.contact_id,
                  booking_party: type,
                  comment: contactData.comment || '',
                },
                bookingId
              )
            }
            const singleCreatedAddress = store.getState().addresses
              .singleAddress
            const updateData = {
              company_address_id: companyData
                ? companyData.address_id
                : singleCreatedAddress && singleCreatedAddress.id,
            }
            await updateContact(
              singleCreatedPersonalDetail && singleCreatedPersonalDetail.id,
              updateData
            )
            showSuccess(
              t(
                'shipments.bookings.notification.address_book_updated',
                'Address book was updated'
              ),
              4000
            )
          }
        }

        if (assignConnection && assignConnectionCondition) {
          await assignConnectionAsCollaborator(props.bookingId)
        }
        await fetchOverview(props.shipmentId)
        setButtonLoading(false)
        handleClose()
      } catch (error) {
        const unknownError: any = error
        if (unknownError.response && unknownError.response.data) {
          showError(unknownError.response.data.message)
        } else {
          showError(errorMessage)
        }
        setButtonLoading(false)
        Logger.log(error)
      }
    } else {
      setState((currentState) => {
        return {
          ...currentState,
          companyErrors: companyValidation.errors,
          contactErrors: contactValidation.errors,
        }
      })
    }
    props.fetchData()
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      className="instructions__modal"
      title={t(
        'shipments.bookings.parties_modal.title',
        'Add {{type}} information',
        { type: props.type }
      )}
      maxWidth="lg"
      actions={renderSubmit()}
    >
      <DialogContent>
        {props.type === 'shipper' && (
          <div className="flex instructions__modal__body">
            <AddressForm
              addressObj={props.booking.shipper.shipment_address}
              permission={props.permission}
              disableTextInputs={props.disableTextInputs}
              addressBookPermission={props.addressBookPermission}
              shipmentId={props.shipmentId}
              handleCompanyChange={handleCompanyChange}
              checkCompanyName={checkCompanyName}
              validationErrors={state.companyErrors}
              setSaveDisable={setSaveDisable}
              label={t('shipments.bookings.parties_modal.address', 'Address')}
              companyType="shipperCompany"
            />
            <div className="instructions__modal__contact-block">
              <div>
                <ContactForm
                  contactObj={props.booking.shipper.shipment_contacts}
                  disableTextInputs={props.disableTextInputs}
                  permission={props.permission}
                  addressBookPermission={props.addressBookPermission}
                  shipmentId={props.shipmentId}
                  handleContactChange={handleContactChange}
                  companyAddressId={
                    state.companyData ? state.companyData.address_id : null
                  }
                  companyObj={state.companyData}
                  validationErrors={state.contactErrors}
                  newCompany={state.newCompany}
                  changeCompany={state.changeCompany}
                  setSaveDisable={setSaveDisable}
                  label={t(
                    'shipments.bookings.parties_modal.contact',
                    'Contact (optional)'
                  )}
                  companyType="shipperCompany"
                  contactType="shipperContact"
                />
                {permissionTo('shipments.shipment_collaborators.manage') &&
                  renderConnections()}
              </div>
            </div>
          </div>
        )}

        {props.type === 'consignee' && (
          <div className="flex instructions__modal__body">
            <AddressForm
              addressObj={props.booking.consignee.shipment_address}
              disableTextInputs={props.disableTextInputs}
              permission={props.permission}
              addressBookPermission={props.addressBookPermission}
              shipmentId={props.shipmentId}
              checkCompanyName={checkCompanyName}
              handleCompanyChange={handleCompanyChange}
              validationErrors={state.companyErrors}
              setSaveDisable={setSaveDisable}
              label={t('shipments.bookings.parties_modal.address', 'Address')}
              companyType="consigneeCompany"
            />
            <div className="instructions__modal__contact-block">
              <ContactForm
                contactObj={props.booking.consignee.shipment_contacts}
                disableTextInputs={props.disableTextInputs}
                permission={props.permission}
                addressBookPermission={props.addressBookPermission}
                shipmentId={props.shipmentId}
                handleContactChange={handleContactChange}
                companyAddressId={
                  state.companyData ? state.companyData.address_id : null
                }
                companyObj={state.companyData}
                validationErrors={state.contactErrors}
                newCompany={state.newCompany}
                changeCompany={state.changeCompany}
                setSaveDisable={setSaveDisable}
                label={t(
                  'shipments.bookings.parties_modal.contact',
                  'Contact (optional)'
                )}
                companyType="consigneeCompany"
                contactType="consigneeContact"
              />
            </div>
          </div>
        )}

        {props.type === 'notify_party' && (
          <div className="flex instructions__modal__body">
            <AddressForm
              addressObj={props.booking.notify_party.shipment_address}
              disableTextInputs={props.disableTextInputs}
              permission={props.permission}
              addressBookPermission={props.addressBookPermission}
              shipmentId={props.shipmentId}
              checkCompanyName={checkCompanyName}
              handleCompanyChange={handleCompanyChange}
              validationErrors={state.companyErrors}
              setSaveDisable={setSaveDisable}
              label={t('shipments.bookings.parties_modal.address', 'Address')}
              companyType="notifyPartyCompany"
            />
            <div className="instructions__modal__contact-block">
              <ContactForm
                contactObj={props.booking.notify_party.shipment_contacts}
                disableTextInputs={props.disableTextInputs}
                permission={props.permission}
                addressBookPermission={props.addressBookPermission}
                shipmentId={props.shipmentId}
                handleContactChange={handleContactChange}
                companyAddressId={
                  state.companyData ? state.companyData.address_id : null
                }
                companyObj={state.companyData}
                validationErrors={state.contactErrors}
                newCompany={state.newCompany}
                changeCompany={state.changeCompany}
                setSaveDisable={setSaveDisable}
                label={t(
                  'shipments.bookings.parties_modal.contact',
                  'Contact (optional)'
                )}
                companyType="notifyPartyCompany"
                contactType="notifyPartyContact"
              />
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}
export default BookingPartiesModal

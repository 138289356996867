import { AnyAction } from 'redux'
import * as actions from '../actions/flags'

export const flagsUpdateApp = (payload: boolean): AnyAction => ({
  type: actions.FLAGS_UPDATE_APP,
  payload,
})

export const flagsWindowActive = (payload: boolean): AnyAction => ({
  type: actions.FLAGS_WINDOW_ACTIVE,
  payload,
})

export const toggleApplicationFilterModal = (payload: boolean): AnyAction => ({
  type: actions.APPLICATION_FILTER_MODAL,
  payload,
})

export const toggleShipmentShareModal = (
  status: boolean,
  docId: number | null
): AnyAction => ({
  type: actions.SHIPMENT_SHARE_MODAL,
  status,
  docId,
})

export const BOOKINGS_GET_DATA = 'BOOKINGS_GET_DATA'
export const BOOKINGS_GET_DATA_SUCCESS = 'BOOKINGS_GET_DATA_SUCCESS'
export const INCOTERMS_GET_DATA = 'INCOTERMS_GET_DATA'
export const INCOTERMS_GET_DATA_SUCCESS = 'INCOTERMS_GET_DATA_SUCCESS'
export const UPDATE_BOOKING = 'UPDATE_BOOKING'
export const UPDATE_BOOKING_SUCCESS = 'UPDATE_BOOKING_SUCCESS'
export const CREATE_BOOKING = 'CREATE_BOOKING'
export const CREATE_BOOKING_SUCCESS = 'CREATE_BOOKING_SUCCESS'

export const BOOKINGS_ASSIGN_ADDRESS = 'BOOKINGS_ASSIGN_ADDRESS'
export const BOOKINGS_ASSIGN_CONTACT = 'BOOKINGS_ASSIGN_CONTACT'
export const BOOKINGS_ASSIGN_SUCCESS = 'BOOKINGS_ASSIGN_SUCCESS'
export const BOOKINGS_UPDATE_NOTIFY_AS_CONSIGNEE =
  'BOOKINGS_UPDATE_NOTIFY_AS_CONSIGNEE'
export const BOOKINGS_UPDATE_NOTIFY_AS_CONSIGNEE_SUCCESS =
  'BOOKINGS_UPDATE_NOTIFY_AS_CONSIGNEE_SUCCESS'

export const BOOKINGS_CARRIER_BOOKING_GET_DATA =
  'BOOKINGS_CARRIER_BOOKING_GET_DATA'
export const BOOKINGS_CARRIER_BOOKING_GET_DATA_SUCCESS =
  'BOOKINGS_CARRIER_BOOKING_GET_DATA_SUCCESS'

export const CLEAR_BOOKINGS_STATE = 'CLEAR_BOOKINGS_STATE'

export const ASSIGN_SHIPPER_AS_COLLABORATOR = 'ASSIGN_SHIPPER_AS_COLLABORATOR'

import {
  FunctionComponent,
  useCallback,
  useReducer,
  useState,
  useEffect,
  useMemo,
} from 'react'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import Modal from 'src/components/Common/Modal'
import { promisifyAction } from '../../../utils/'
import {
  triggerFetchingOrders,
  shipmentsToAssignPOClean,
} from '../../../stores/actionCreators'
import AssignToShipmentProgress from './AssignToShipmentProgress'
import SelectShipmentStep from './SelectShipmentStep'
import ConfirmationStep from './ConfirmationStep'

import './styles.scss'

interface IProps {
  open: boolean
  onClose: () => void
  onConfirm: (shipmentId, formData) => void
  purchaseOrders: IPurchaseOrder[]
}

interface IModalStep {
  name: string
  proceed: string
  back: string
}

interface IReducerAction {
  type: 'next' | 'prev' | 'start'
}

const getModalSteps = (t): IModalStep[] => [
  {
    name: 'blank',
    proceed: t(
      'purchase_orders.modals.assign_po_to_shipment.next_button',
      'Next'
    ),
    back: t(
      'purchase_orders.modals.assign_po_to_shipment.cancel_button',
      'Cancel'
    ),
  },
  {
    name: 'select_shipment',
    proceed: t(
      'purchase_orders.modals.assign_po_to_shipment.next_button',
      'Next'
    ),
    back: t(
      'purchase_orders.modals.assign_po_to_shipment.cancel_button',
      'Cancel'
    ),
  },
  {
    name: 'confirmation',
    proceed: t(
      'purchase_orders.modals.assign_po_to_shipment.submit_button',
      'Submit'
    ),
    back: t('purchase_orders.modals.assign_po_to_shipment.back_button', 'Back'),
  },
]

const initializePoData = (orders: IPurchaseOrder[]) => {
  const obj = {}
  orders.forEach((order) => {
    obj[order.id] = { booking_reference: '', cargo_ready_date: '' }
  })

  return obj
}

const AssignOrderToShipmentModal: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()

  const [loading, setLoading] = useState<boolean>(false)
  const [confirmed, setConfirmed] = useState<boolean>(false)
  const [poData, setPoData] = useState<any>(
    initializePoData(props.purchaseOrders)
  )

  const modalSteps: IModalStep[] = useMemo(() => {
    return getModalSteps(t)
  }, [t])

  const [
    selectedShipment,
    setSelectedShipment,
  ] = useState<IDetailedShipment | null>(null)

  const cleanShipmentsToAssign = promisifyAction(
    dispatch,
    shipmentsToAssignPOClean
  )
  const fetchOrders = promisifyAction(dispatch, triggerFetchingOrders)

  const onClose = useCallback(() => {
    setPoData({})
    setSelectedShipment(null)
    setConfirmed(false)
    cleanShipmentsToAssign()

    props.onClose()
  }, [])

  const confirm = useCallback(async () => {
    if (!selectedShipment) {
      return
    }
    setLoading(true)
    await props.onConfirm(selectedShipment.id, poData)
    setLoading(false)
    onClose()
    fetchOrders()
  }, [selectedShipment, poData])

  const reducer = function (state: IModalStep, action: IReducerAction) {
    if (action.type === 'next') {
      if (state.name === 'confirmation') {
        setConfirmed(true)
        return state
      }

      if (state.name === 'select_shipment') {
        cleanShipmentsToAssign()
      }

      return modalSteps[modalSteps.indexOf(state) + 1]
    }

    if (action.type === 'prev') {
      if (state.name === 'select_shipment') {
        onClose()
        return modalSteps[0]
      }

      return modalSteps[modalSteps.indexOf(state) - 1]
    }

    if (action.type === 'start') {
      return modalSteps[0]
    }

    return state
  }

  useEffect(() => {
    setPoData(initializePoData(props.purchaseOrders))
  }, [props.open])

  useEffect(() => {
    if (confirmed) {
      confirm()
    }
  }, [confirmed])

  const [modalStep, dispatchModalStep] = useReducer(reducer, modalSteps[0])

  useEffect(() => {
    if (!props.open) {
      dispatchModalStep({ type: 'start' })
    } else {
      dispatchModalStep({ type: 'next' })
    }
  }, [props.open])

  return (
    <Modal.Window open={props.open} onClose={onClose}>
      <Modal.Title
        children={t(
          'purchase_orders.modals.assign_po_to_shipment.title',
          'Assign to shipment'
        )}
        onClose={onClose}
      />
      <Modal.Content
        className={modalStep.name === 'select_shipment' ? 'no-scroll' : ''}
      >
        <AssignToShipmentProgress activeStep={modalSteps.indexOf(modalStep)} />
        {modalStep.name === 'select_shipment' && (
          <SelectShipmentStep
            onSelectShipment={setSelectedShipment}
            selectedShipment={selectedShipment}
          />
        )}

        {modalStep.name === 'confirmation' && !!selectedShipment && (
          <ConfirmationStep
            shipment={selectedShipment}
            purchaseOrders={props.purchaseOrders}
            formData={poData}
            onFormDataChange={(newData) => setPoData(newData)}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          variant="outlined"
          onClick={() => dispatchModalStep({ type: 'prev' })}
        >
          {modalStep.back}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() => dispatchModalStep({ type: 'next' })}
          disabled={modalStep.name === 'select_shipment' && !selectedShipment}
          loading={loading}
        >
          {modalStep.proceed}
        </LoadingButton>
      </Modal.Actions>
    </Modal.Window>
  )
}

export default AssignOrderToShipmentModal

import { Paper, Stack, Typography, Box, Grid } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { ModalityEnum } from 'src/config/constants'
import i18n from 'i18next'
import { Add } from '@mui/icons-material'
import { ShipmentModalityIcon } from 'src/stories/MUI/ShipmentContent/components/ShipmentModalityIcon'
import TemplateRowRoleText from 'src/pages/ShipmentTemplates/TemplateRowRoleText'
import { ShipmentProgressWithPorts } from 'src/stories/MUI/ShipmentContent/components/ShipmentProgressWithPorts'
import TemplatesListItemCollaborator from './TemplatesListItemCollaborator'
import TemplatesListItemActions from './TemplatesListItemActions'

const getAddress = (template, field) => {
  return template?.template?.addresses[field]?.full_text ?? 'Not specified'
}

const getTimeline = (template) => {
  if (!template.id) {
    return [
      ...Array(4)
        .fill(0)
        .map((x, index) => ({
          title: 0,
          description: 0,
          key: `${index}`,
        })),
    ]
  }
  return [
    {
      key: 'pickup',
      title: i18n.t('common.service_item.pickup.title', 'Pickup'),
      description: getAddress(template, 'pickup'),
    },
    { key: 'origin', title: template?.template?.origin_name ?? '' },
    {
      key: 'destination',
      title: template?.template?.destination_name ?? '',
    },
    {
      key: 'delivery',
      title: i18n.t('common.service_item.delivery.title', 'Delivery'),
      description: getAddress(template, 'delivery'),
    },
  ]
}
interface TemplatesListItemProps {
  template: Partial<IBookingTemplate>
}

const TemplatesListItem: React.FC<TemplatesListItemProps> = ({ template }) => {
  const collaborators = template?.template?.collaborators ?? []
  const timeline = getTimeline(template)

  const isTemplate = template.id !== undefined

  const link = template.id
    ? `/templates/${template.id}/rebook`
    : 'templates/create'

  return (
    <Paper
      sx={{ p: 2, '& a': { color: 'primary.main', textDecoration: 'none' } }}
      variant="outlined"
    >
      <NavLink to={link}>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={3}>
            <Stack spacing={1} direction="row">
              {isTemplate && (
                <ShipmentModalityIcon
                  modality={template.template?.modality ?? ModalityEnum.Sea}
                />
              )}
              {!isTemplate && <Add />}
              <Box maxWidth="100%">
                <Typography variant="h5" component="div">
                  {template.name}
                </Typography>
                {isTemplate && (
                  <TemplateRowRoleText
                    template={template as IBookingTemplate}
                  />
                )}
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              {timeline.map((item, index) => (
                <Grid item xs={3} key={item.key}>
                  <ShipmentProgressWithPorts
                    title={item.title}
                    description={item.description ?? ''}
                    hideProgressBar={index === timeline.length - 1}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={2} alignItems="end">
            <Box display="flex" alignItems="end" flexDirection="column">
              <TemplatesListItemCollaborator collaborators={collaborators} />
              <Typography variant="body1Strong" sx={{ mb: 0.5, mt: 1 }}>
                {template?.template?.load_type_name}
              </Typography>
              <Typography variant="body1">
                {template?.template?.container.type_name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={1}>
            {isTemplate && (
              <TemplatesListItemActions id={template.id} name={template.name} />
            )}
          </Grid>
        </Grid>
      </NavLink>
    </Paper>
  )
}

export default TemplatesListItem

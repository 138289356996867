import { NavLink } from 'react-router-dom'
import { LogoTypeIcon } from 'src/svgIcons'

const TopNavigationBrand = () => {
  return (
    <NavLink to="/" style={{ display: 'flex', alignItems: 'center' }}>
      <LogoTypeIcon
        sx={{
          mr: 1,
          width: 88,
          color: 'common.white',
          display: 'flex',
        }}
      />
    </NavLink>
  )
}

export default TopNavigationBrand

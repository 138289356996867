// @ts-nocheck
// FIXME

import { groupBy, keys, toLower, without } from 'lodash'
import { ReactNode, useRef, useState } from 'react'

import MenuButton, { IMenuOriginRender } from '../MenuButton'
import './styles.scss'

interface ISelectorButtonProps {
  value?: string
  title?: string
  contactType?: string
  options?: Array<Array<string | number>>
  buttonColor?: string
  buttonColorActive?: string
  className?: string
  renderOrigin?: IMenuOriginRender
  onChange?: (optionId: string) => void
  clearContact?: () => void
  selectedNew?: () => void
  defaultValue?: string
  isSearchable?: boolean
  isShippingInstuctions?: boolean
  hasLimitedHeight?: boolean
  showTitle?: boolean
  popperClassName?: string
  longTitles?: boolean
  disabled?: boolean
  removeHighlight?: boolean
  children?: ReactNode
}

interface ISelectorButtonState {
  isOpen: boolean
  search: string
  lowerCaseSearch: string
}

interface IPerson {
  person: Array<string | number>
}

function SelectorButton(props: ISelectorButtonProps) {
  const [state, setState] = useState<ISelectorButtonState>({
    isOpen: false,
    search: '',
    lowerCaseSearch: '',
  })

  const testElement = useRef<HTMLInputElement | null>(null)

  const {
    buttonColor,
    buttonColorActive,
    className,
    renderOrigin,
    isSearchable,
    isShippingInstuctions,
    hasLimitedHeight,
    removeHighlight,
  } = props

  let options = props.options || []

  if (state.search) {
    options = options.filter(matchSearch)
  }
  setTimeout(() => {
    focusField()
  }, 0)

  const isActive = props.value !== props.defaultValue

  function focusField(): void {
    if (testElement.current) {
      testElement.current.focus()
    }
  }

  function renderPersonOptions(options: any[]) {
    const persons = options.map((person: IPerson) => {
      return {
        company: person[2] || 'Not linked',
        name: person[0],
        id: person[1],
      }
    })
    const personList = groupBy(persons, 'company') || {}
    const personListKeys = makeKeys(keys(personList)) || []
    return personListKeys.map((key: string, index: number): any => (
      <div key={index}>
        <div className="selector-button__company">{key || 'Not Linked'}</div>
        {personList[key].map(({ name, id }: any): any => (
          <div
            className={`selector-button__option ${
              props.value === id ? 'selector-button__option_selected' : ''
            }`}
            key={id}
            onClick={pickOption.bind(this, id)}
          >
            <div className="selector-button__option-name">{name}</div>
          </div>
        ))}
      </div>
    ))
  }

  function renderOptions(options: any[]) {
    return options.map(([title, id]: string[]): any => (
      <div
        className={`selector-button__option ${
          props.value === id ? 'selector-button__option_selected' : ''
        }`}
        key={id}
        onClick={pickOption.bind(this, id)}
      >
        <div className="selector-button__option-name">{title}</div>
      </div>
    ))
  }

  function makeKeys(data: any) {
    if (data.includes('Not linked')) {
      data = without(data, 'Not linked').sort()
      data.push('Not linked')
      return data
    }
    return data.sort()
  }

  function getOptionTitle() {
    const option = (props.options || []).find(
      ([title, id]: string[]): boolean => id === props.value
    )
    return option ? option[0] : ''
  }

  function pickOption(option: string): void {
    if (props.onChange) {
      props.onChange(option)
    }
    close()
  }

  function pickNew(): void {
    if (props.selectedNew) {
      props.selectedNew()
    }
    close()
  }

  function matchSearch(option: string[]): boolean {
    const { search } = state
    return !search || toLower(option[0]).includes(state.lowerCaseSearch)
  }

  function changeSearch(event: any): void {
    setState({
      search: event.currentTarget.value,
      lowerCaseSearch: toLower(event.currentTarget.value),
    })
  }

  function cancel(): void {
    const { onChange, defaultValue } = props
    if (onChange) {
      onChange(defaultValue || '')
    }
  }

  function open(): void {
    setState({ isOpen: true, search: '' })
  }

  function close(): void {
    setState({ isOpen: false })
  }

  return (
    <MenuButton
      renderOrigin={renderOrigin}
      isOpen={state.isOpen}
      className={className}
      buttonColor={buttonColor}
      buttonColorActive={buttonColorActive}
      isActive={isActive && !removeHighlight}
      title={`${props.showTitle ? props.title : ''} ${getOptionTitle()}`}
      onCancel={cancel}
      onOpen={open}
      onClose={close}
      popperClassName={props.popperClassName}
      longTitles={props.longTitles}
      disabled={props.disabled}
    >
      {isSearchable && (
        <div className="selector-button__search-container">
          <input
            className="selector-button__search"
            placeholder="Type to search"
            value={state.search}
            onChange={changeSearch}
            ref={testElement}
          />
        </div>
      )}
      {isShippingInstuctions && (
        <div className={`selector-button__option`} onClick={pickNew}>
          <div className="selector-button__option-name blue">
            Add new{' '}
            {props.contactType && props.contactType === 'person'
              ? 'contact person'
              : 'company'}
          </div>
        </div>
      )}
      <div
        className={`selector-button__options ${
          hasLimitedHeight ? 'selector-button__options_limited' : ''
        }`}
      >
        {props.contactType && props.contactType === 'person'
          ? renderPersonOptions(options)
          : renderOptions(options)}
      </div>
    </MenuButton>
  )
}

export default SelectorButton

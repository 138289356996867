import { AnyAction } from 'redux'
import uuid from 'uuid/v4'
import * as actions from '../actions/notifications'

export const showNotification = ({
  id = uuid(),
  ...notification
}: INotification): AnyAction => ({
  type: actions.NOTIFICATIONS_SHOW_MESSAGE,
  payload: { id, ...notification },
})

export const removeNotification = (id?: string): AnyAction => ({
  type: actions.NOTIFICATIONS_REMOVE_MESSAGE,
  payload: { id },
})

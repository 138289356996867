export const pickerWords = {
  months: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  from: 'Start:',
  to: 'End:',
}

export function monthYearToString(monthYear: {
  month: number
  year: number
}): string {
  return monthYear && monthYear.year && monthYear.month
    ? `${pickerWords.months[+monthYear.month - 1]}. ${monthYear.year}`
    : '?'
}

import { FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Link as MuiLink } from '@mui/material'
import Restore from '@mui/icons-material/Restore'
import Table from 'src/components/Common/Table'
import BlankState from 'src/components/Common/BlankState'

import { convertDatePickerToUniversalFormat } from '../../../../utils/helpers'
import { resetInfoRequests } from '../../../../stores/actionCreators'
import './styles.scss'

interface IProps {
  slaCheckpoints: ISLA[]
  editItem?: () => void
}

const tableHeaders = (t): any[] => [
  {
    name: '',
    sortable: false,
    sortValue: '',
    customClassName: 'x-short',
  },
  {
    name: t('purchase_orders.tabs.sla.table_headers.type', 'TYPE'),
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned wide',
  },
  {
    name: t('purchase_orders.tabs.sla.table_headers.name', 'NAME'),
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned wide',
  },
  {
    name: t('purchase_orders.tabs.sla.table_headers.timing', 'TIMING'),
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned wide',
  },
  {
    name: t('purchase_orders.tabs.sla.table_headers.due_date', 'DUE DATE'),
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned wide',
  },
  {
    name: '',
    sortable: false,
    sortValue: '',
    customClassName: 'left-aligned wide',
  },
  {
    name: '',
    sortable: false,
    sortValue: '',
    customClassName: 'right-aligned wide',
  },
]

const PurchaseOrderSLA: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { openItem } = useSelector((state: IGlobalState) => ({
    openItem: state.purchaseOrders.openItem,
  }))

  const formattedStatus = (status: string): string => {
    return status.replace(/_/g, ' ')
  }

  const dueDateCell = (item: ISLA): React.ReactNode => {
    return item.due_date_message_status.due_date ? (
      convertDatePickerToUniversalFormat(
        item.due_date_message_status.due_date || ''
      )
    ) : (
      <MuiLink variant="body1" component="button" onClick={props.editItem}>
        {t(
          'purchase_orders.tabs.sla.due_date_cell.add_date_button',
          'Add date'
        )}
      </MuiLink>
    )
  }

  const resetCheckpoint = (slaCheckpoint: ISLA) => {
    dispatch(
      resetInfoRequests(openItem || 0, { checkpoint_id: slaCheckpoint.id })
    )
  }

  const slaCheckpointsRows = (): React.ReactNode => {
    return props.slaCheckpoints.map((item: ISLA, index: number) => {
      return (
        <Table.StripedTableRow index={index} key={item.id}>
          <Table.StripedTableCell className="x-short paragraph__medium black">
            <i className="icon supplier-initiated" />
          </Table.StripedTableCell>
          <Table.StripedTableCell className="left-aligned wide paragraph__medium black">
            {item.sla_checkpoint_type ? item.sla_checkpoint_type.name : '-'}
          </Table.StripedTableCell>
          <Table.StripedTableCell className="left-aligned wide paragraph__medium black">
            {item.name ? (
              <MuiLink
                variant="body1"
                component={Link}
                to={`/organization_settings/service_level_agreements?open_item=${item.id}`}
              >
                {item.name}
              </MuiLink>
            ) : (
              '-'
            )}
          </Table.StripedTableCell>
          <Table.StripedTableCell className="left-aligned wide paragraph__medium black">
            {item.sla_checkpoint_value
              ? item.sla_checkpoint_value.description
              : '-'}{' '}
            {item.dt_days && item.dt_days > 0 ? '+ ' : ''}
            {item.dt_days} days
          </Table.StripedTableCell>
          <Table.StripedTableCell className="left-aligned wide paragraph__medium black">
            {dueDateCell(item)}
          </Table.StripedTableCell>
          <Table.StripedTableCell className="left-aligned wide paragraph__medium black">
            <div
              className={`status-message ${item.due_date_message_status.message_status}`}
            >
              {formattedStatus(item.due_date_message_status.message_status)}
            </div>
          </Table.StripedTableCell>
          <Table.StripedTableCell className="right-aligned wide paragraph__medium black">
            {item.has_info_requests && (
              <div
                className="reset-checkpoint"
                onClick={() => {
                  resetCheckpoint(item)
                }}
              >
                <Restore color="primary" />
                {t(
                  'purchase_orders.tabs.sla.checkpoints.reset_checkpoint_button',
                  'Reset checkpoint'
                )}
              </div>
            )}
          </Table.StripedTableCell>
        </Table.StripedTableRow>
      )
    })
  }

  const blankStateInfo: React.ReactNode = (
    <>
      <Trans
        i18nKey="purchase_orders.tabs.sla.empty_state"
        defaults="<0>Set up automated alerts</0> to receive updates from your suppliers"
        components={[
          <MuiLink
            variant="body1"
            component={Link}
            to="/organization_settings"
          />,
        ]}
      />
    </>
  )

  const blankStateIcons = [
    { icon: 'pencil', text: '' },
    { icon: 'file', text: '' },
  ]

  return (
    <div className="purchase-order-sla-block">
      <Table.StripedTable theme="organizationSettings PO-tool">
        <Table.StripedTableHeader cells={tableHeaders(t)} />
        <Table.StripedTableBody>
          {props.slaCheckpoints && !!props.slaCheckpoints.length ? (
            slaCheckpointsRows()
          ) : (
            <BlankState.Action
              theme="slaPO"
              type="link"
              linkBlock={blankStateInfo}
              iconsArray={blankStateIcons}
            />
          )}
        </Table.StripedTableBody>
      </Table.StripedTable>
    </div>
  )
}

export default PurchaseOrderSLA

import EtaHistoryPopover from 'src/components/ShipmentEstimatedArrivalDelay'
import { useSelector } from 'react-redux'
import ShipmentTimelineEstimatedArrivalDelay from 'src/components/ShipmentEstimatedArrivalDelay/ShipmentTimeline'
import { TimelinePortDate } from '../TimelinePortDate'
import { TimelineFlightNumber } from '../TimelineFlightNumber'

interface IProps {
  flightNumber: string | null
  dateKey: string
  dateLabel: string
  milestones: IMilestone[]
  shipment: IOverviewShipment
  opened: boolean
  showEtaCheck?: boolean
  showChevron: boolean
}

export const TimelinePortHeader: React.FC<IProps> = ({
  flightNumber,
  dateKey,
  dateLabel,
  milestones,
  shipment,
  opened,
  showEtaCheck = false,
  showChevron,
}) => {
  const { currentUserId } = useSelector((state: IGlobalState) => ({
    currentUserId: state.user?.id,
  }))
  const { modality, status_sequence: statusSequence } = shipment
  const iconClass = opened ? '' : 'collapsed'

  return (
    <div
      data-testid="timeline-port-header"
      className={`dropdown-header-content ${opened ? 'opened' : ''}`}
    >
      <div className="shipment-timeline--phases-header-wrapper">
        <div>
          <TimelinePortDate
            label={dateLabel}
            modality={modality}
            date={shipment[dateKey]}
            statusSequence={statusSequence}
            shipment={shipment}
            showEtaCheck={showEtaCheck}
          />
          <TimelineFlightNumber
            modality={modality}
            flightNumber={flightNumber}
          />
        </div>
        {dateKey === 'estimated_arrival' && (
          <EtaHistoryPopover
            delay={shipment.estimated_arrival_delay}
            shipmentId={shipment.id}
            disabled={!currentUserId}
          >
            <ShipmentTimelineEstimatedArrivalDelay
              delay={shipment.estimated_arrival_delay}
              data-testid="estimated-arrival-delay"
            />
          </EtaHistoryPopover>
        )}
        {showChevron ? <i className={`icon chevron ${iconClass}`} /> : ''}
      </div>
    </div>
  )
}

import { useController } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import { cargoRequiredStackableOptions as options } from 'src/config/constants'
import { SelectChangeEvent } from '@mui/material'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import { ItemProps } from '../GoodsLoadTypeLCL.props'

export const StackableSelect = (props: ItemProps) => {
  const { cargo, onChange } = props
  const { field, fieldState } = useController(props)

  const stackableValue =
    options.find((option) => option.value === props.cargo.stackable)?.id || ''

  const onStackableSelectChange = (event: SelectChangeEvent<unknown>) => {
    const updatedValue =
      options.find((option) => option.id === event.target.value)?.value || false

    onChange(cargo.id, 'stackable', updatedValue)
    field.onChange(updatedValue)
  }

  const { ref, ...fieldProps } = field
  const { t } = useTranslation()

  return (
    <Grid item xs={1.2} pr={1.5} pl={1.5}>
      <SingleSelect
        {...fieldProps}
        options={options}
        placeholder={t('common.select', 'Select')}
        inputProps={{ ref }}
        value={stackableValue}
        data-testid="stackable-field"
        error={Boolean(fieldState.error)}
        onChange={onStackableSelectChange}
      />
    </Grid>
  )
}

import { useTranslation } from 'react-i18next'
import Stack from '@mui/material/Stack'
import { Alert } from 'src/stories/Alert'
import { ModalityEnum } from 'src/config/constants'
import { SABSearchItemTimelineProps } from './SABSearchItem.props'
import { getTimelineNotificationMessage } from './SABSearchItem.utils'
import { StyledTimelineGrid, StyledGridItem } from './SABSearchItem.styles'
import SABSearchItemTimelineAddress from './SABSearchItemTimelineAddress'
import SABSearchItemTimelineVoyageInfo from './SABSearchItemTimelineVoyageInfo'
import SABSearchItemTimelineCarrierAndTransit from './SABSearchItemTimelineCarrierAndTransit'
import SABSearchItemTimelineDateInfo from './SABSearchItemTimelineDateInfo'

const SABSearchItemTimeline: React.FC<SABSearchItemTimelineProps> = ({
  schedule,
  modality,
  showAlert = false,
  pickupAddress = '',
  showCarrier = false,
  deliveryAddress = '',
  cargoClosingDate,
}) => {
  const {
    transit_time_days: transitTime,
    estimated_arrival_date: estimatedArrival,
    estimated_departure_date: estimatedDeparture,
  } = schedule

  const {
    loading_port: origin,
    discharge_port: destination,
  } = schedule.port_pair

  const carrier = schedule.carrier?.name ?? 'Unknown'

  const { t } = useTranslation()

  return (
    <>
      <StyledTimelineGrid
        container
        wrap="nowrap"
        spacing={0}
        data-testid="search-and-book-item-timeline"
      >
        {!!pickupAddress && (
          <StyledGridItem item xs>
            <SABSearchItemTimelineAddress
              port={origin}
              title={t('common.origin', 'Origin')}
              textAlign="left"
              address={pickupAddress}
            />
          </StyledGridItem>
        )}
        <StyledGridItem item xs>
          <SABSearchItemTimelineVoyageInfo
            port={origin}
            textAlign="left"
            modality={modality}
            date={estimatedDeparture}
          />
          {modality === ModalityEnum.Sea && (
            <SABSearchItemTimelineDateInfo
              textAlign="left"
              cargoClosingDate={cargoClosingDate}
              departureDate={estimatedDeparture}
              modality={modality}
            />
          )}
        </StyledGridItem>
        <StyledGridItem item xs>
          <SABSearchItemTimelineCarrierAndTransit
            carrier={carrier}
            showCarrier={showCarrier}
            transitTime={transitTime}
          />
        </StyledGridItem>
        <StyledGridItem item xs>
          <SABSearchItemTimelineVoyageInfo
            textAlign="right"
            port={destination}
            modality={modality}
            date={estimatedArrival}
          />
          {modality === ModalityEnum.Sea && (
            <SABSearchItemTimelineDateInfo
              textAlign="right"
              arrivalDate={estimatedArrival}
              modality={modality}
            />
          )}
        </StyledGridItem>
        {!!deliveryAddress && (
          <StyledGridItem item xs>
            <SABSearchItemTimelineAddress
              textAlign="right"
              port={destination}
              title={t('common.destination', 'Destination')}
              address={deliveryAddress}
            />
          </StyledGridItem>
        )}
      </StyledTimelineGrid>
      {showAlert && (
        <Stack
          mt={4}
          alignItems="start"
          data-testid="search-and-book-item-timeline-alert"
        >
          <Alert
            color="secondary"
            message={getTimelineNotificationMessage(t, schedule)}
          />
        </Stack>
      )}
    </>
  )
}

export default SABSearchItemTimeline

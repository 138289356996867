import { put } from 'redux-saga/effects'

import { resolveAction, rejectAction, authorizedRequest } from '../wrappers'
import { Logger } from '../../../utils'
import * as actionCreators from '../../actionCreators'

export default function* (action: IPromisifiedAction): Iterator<any> {
  const { shipmentId, payload } = action

  try {
    const formData = new FormData()
    formData.append('file', payload.file)
    payload.document_type_ids.forEach((x) => {
      formData.append('document_type_ids[]', x)
    })
    formData.append('booking_id', payload.booking_id || '')
    payload.container_ids.forEach((x) => {
      if (x !== '0') {
        formData.append('container_ids[]', x)
      }
    })
    if (payload.organization_ids) {
      payload.organization_ids.forEach((x) => {
        formData.append('organization_ids[]', x)
      })
    }
    const response: any = yield authorizedRequest({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'POST',
      url: `/api/v1/shipments/${shipmentId}/shipment_documents`,
      data: formData,
    })
    yield put(actionCreators.createShipmentDocumentSuccess(response.data.data))
    yield resolveAction(action)
  } catch (error) {
    const unknownError: any = error
    yield Logger.sagaError(unknownError, 'store::user::getUserData')
    yield rejectAction(action, error)
  }
}

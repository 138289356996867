import { useCallback } from 'react'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { DocumentObject } from 'jsonapi-fractal'
import { AxiosError } from 'axios'
import { IDemurrageAndDetention } from 'src/@types/endpoints/demurrageAndDetention'
import endpoints, { queryKeys } from '../../endpoints'
import { apiClient } from '../../http-common'
import { getDemurrageAndDetentionTransformedData } from './utils'

export const useGetDemurrageAndDetention = (
  options?: UseQueryOptions<DocumentObject, AxiosError, IDemurrageAndDetention>
) => {
  const url = endpoints.demurrageAndDetention
  const { demurrageAndDetention: key } = queryKeys

  const getDemurrageAndDetention = () => apiClient.get<any, DocumentObject>(url)

  return useQuery<DocumentObject, AxiosError, IDemurrageAndDetention>(
    [key],
    getDemurrageAndDetention,
    {
      ...options,
      select: useCallback(getDemurrageAndDetentionTransformedData, []),
    }
  )
}

export const SHIPMENT_DOCUMENTS_GET_DATA = 'SHIPMENT_DOCUMENTS_GET_DATA'
export const SHIPMENT_DOCUMENTS_CLEAN_DATA = 'SHIPMENT_DOCUMENTS_CLEAN_DATA'
export const SHIPMENT_DOCUMENTS_GET_DATA_SUCCESS =
  'SHIPMENT_DOCUMENTS_GET_DATA_SUCCESS'
export const SUBMIT_SHIPMENT_DOCUMENT_DATA = 'SUBMIT_SHIPMENT_DOCUMENT_DATA'
export const SUBMIT_SHIPMENT_DOCUMENT_DATA_SUCCESS =
  'SUBMIT_SHIPMENT_DOCUMENT_DATA_SUCCESS'
export const DELETE_SHIPMENT_DOCUMENT = 'DELETE_SHIPMENT_DOCUMENT'
export const DELETE_SHIPMENT_DOCUMENT_SUCCESS =
  'DELETE_SHIPMENT_DOCUMENT_SUCCESS'
export const CREATE_SHIPMENT_DOCUMENT = 'CREATE_SHIPMENT_DOCUMENT'
export const CREATE_SHIPMENT_DOCUMENT_SUCCESS =
  'CREATE_SHIPMENT_DOCUMENT_SUCCESS'
export const MOVE_SHIPMENT_DOCUMENT = 'MOVE_SHIPMENT_DOCUMENT'
export const MOVE_SHIPMENT_DOCUMENT_SUCCESS = 'MOVE_SHIPMENT_DOCUMENT_SUCCESS'
export const DOCUMENT_TYPES_GET_DATA = 'DOCUMENT_TYPES_GET_DATA'
export const DOCUMENT_TYPES_GET_DATA_SUCCESS = 'DOCUMENT_TYPES_GET_DATA_SUCCESS'

export const SHIPMENT_DOCUMENTS_OPEN_ITEM = 'SHIPMENT_DOCUMENTS_OPEN_ITEM'

import { Grid, Skeleton } from '@mui/material'
import { Children } from 'react'
import { permissionTo } from 'src/utils'
import { useFilter } from '../hooks'

const BASE_COLUMN_SIZE = 2

export const FiltersSkeleton = ({ children }) => {
  const { isLoading } = useFilter()

  return Children.map(children, (child, index) => {
    const {
      columnSize = BASE_COLUMN_SIZE,
      key = index,
      permissions,
    } = child.props

    if (permissions && !permissionTo(permissions ?? [])) return <></>

    return (
      <Grid item key={key} xs={12} md={6} lg={columnSize} xl={columnSize}>
        {!isLoading ? (
          child
        ) : (
          <>
            <Skeleton
              animation="wave"
              variant="text"
              width="40%"
              sx={{ mb: 1 }}
            />
            <Skeleton
              variant="rectangular"
              width="100%"
              height={40}
              animation="wave"
              sx={{ borderRadius: 1 }}
            />
          </>
        )}
      </Grid>
    )
  })
}

import { AnyAction } from 'redux'
import * as actions from '../actions/packageTypes'

export const packageTypesGetData = (): AnyAction => ({
  type: actions.PACKAGE_TYPES_GET_DATA,
})

export const packageTypesGetDataSuccess = (payload: any): AnyAction => ({
  type: actions.PACKAGE_TYPES_GET_DATA_SUCCESS,
  payload,
})

export const containerTypesGetData = (): AnyAction => ({
  type: actions.CONTAINER_TYPES_GET_DATA,
})

export const containerTypesGetDataSuccess = (payload: any): AnyAction => ({
  type: actions.CONTAINER_TYPES_GET_DATA_SUCCESS,
  payload,
})

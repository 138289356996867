import { takeLatest } from 'redux-saga/effects'

import * as actions from 'src/stores/actions/serviceItems'
import * as actionCreators from '../../actionCreators/serviceItems'
import { simpleGetAndResolve } from '../factories'

const getServiceItems = simpleGetAndResolve(
  () => '/api/v1/service_items',
  actionCreators.getServiceItemsSuccess,
  (response) => response.data.data
)

export default function* (): Iterator<any> {
  yield takeLatest(actions.GET_SERVICE_ITEMS, getServiceItems)
}

export const SLA_GET_DATA = 'SLA_GET_DATA'
export const SLA_GET_DATA_SUCCESS = 'SLA_GET_DATA_SUCCESS'
export const SLA_OPEN_ITEM = 'SLA_OPEN_ITEM'
export const UPDATE_SLA_ALERTS = 'UPDATE_SLA_ALERTS'
export const CREATE_SLA = 'CREATE_SLA'
export const CREATE_SLA_SUCCESS = 'CREATE_SLA_SUCCESS'
export const UPDATE_SLA = 'UPDATE_SLA'
export const UPDATE_SLA_SUCCESS = 'UPDATE_SLA_SUCCESS'
export const DELETE_SLA = 'DELETE_SLA'
export const DELETE_SLA_SUCCESS = 'DELETE_SLA_SUCCESS'
export const ORGANIZATION_USERS_GET_DATA = 'ORGANIZATION_USERS_GET_DATA'
export const ORGANIZATION_USERS_GET_DATA_SUCCESS =
  'ORGANIZATION_USERS_GET_DATA_SUCCESS'
export const GET_ORGANIATION_API_TOKEN = 'GET_ORGANIATION_API_TOKEN'
export const GET_ORGANIATION_API_TOKEN_SUCCESS =
  'GET_ORGANIATION_API_TOKEN_SUCCESS'
export const POST_ORGANIATION_API_TOKEN = 'POST_ORGANIATION_API_TOKEN'
export const DELETE_ORGANIATION_API_TOKEN = 'DELETE_ORGANIATION_API_TOKEN'
export const DELETE_ORGANIATION_API_TOKEN_SUCCESS =
  'DELETE_ORGANIATION_API_TOKEN_SUCCESS'

import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { DemurrageAndDetentionPrice } from 'src/@types/endpoints/demurrageAndDetention'
import { InfoTooltip } from 'src/stories/MUI/Tooltip/InfoTooltip'
import ContainerDemurrageAndDetentionItemPrice from './ContainerDemurrageAndDetentionItemPrice'

const ContainerDemurrageAndDetentionTotalPrice: React.FC<{
  totalPricesItem: DemurrageAndDetentionPrice
}> = ({ totalPricesItem }) => {
  const { t } = useTranslation()
  const tooltipTitle = totalPricesItem?.note ?? 'estimated'
  return (
    <Box
      mt={2.25}
      display="flex"
      data-testid="container-demurrage-and-detention-total-price"
    >
      <Typography
        mr={0.5}
        component="div"
        variant="body1Strong"
        children={t(
          'shipment_containers.demurrage_and_detention.total_cost',
          'Total cost:'
        )}
      />
      <Typography
        component="div"
        variant="body1Strong"
        children={
          <ContainerDemurrageAndDetentionItemPrice
            linkText=""
            addSuffix={true}
            price={totalPricesItem}
          />
        }
      />
      <InfoTooltip
        placement="right-start"
        title={t(
          `shipment_containers.demurrage_and_detention.costs_tooltip.${tooltipTitle}`
        )}
      />
    </Box>
  )
}

export default ContainerDemurrageAndDetentionTotalPrice

import { ModalityEnum, OrganizationRoleEnum } from 'src/config/constants'

interface IProps {
  modality: ModalityEnum
  organizationRole: IUserState['organizationRole']
  tenantOrganization: IUserState['tenantOrganization']
  status: string
  transactionItems: ITransactionItem[]
  isQuotationsAvailable: boolean
}

export const getShipmentCostsComponentKey = ({
  modality,
  organizationRole,
  tenantOrganization,
  status,
  transactionItems,
  isQuotationsAvailable,
}: IProps) => {
  const tenantOrganizationId = tenantOrganization?.id ?? ''
  const tenantOrganizationForShypple = 1
  if (
    modality === ModalityEnum.Air &&
    organizationRole === OrganizationRoleEnum.Customer &&
    tenantOrganizationId === tenantOrganizationForShypple
  ) {
    if (
      !isQuotationsAvailable &&
      transactionItems.length > 0 &&
      status === 'PQUO'
    ) {
      return 'quotations'
    }
    return 'costs'
  }
  return 'quotations'
}

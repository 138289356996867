import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import * as actions from '../actions/serviceItems'

declare global {
  interface IServiceItem {
    additional: boolean
    code: string
    id: number
    name: string
    priority: number
    service_code: string
    service_name: string
    service_priority: number
    tiered_pricing: boolean
  }
  interface IServiceItemsState {
    items: IServiceItem[]
  }
}

export const initialState = {
  items: [],
}

const receiveServiceItems: Reducer<IServiceItemsState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  items: action.payload,
})

export default createReducer(initialState, {
  [actions.GET_SERVICE_ITEMS_SUCCESS]: receiveServiceItems,
})

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import { getAddAgreementSections } from './DemurrageAndDetentionContent.constants'
import DemurrageAndDetentionContentAddAgreementSection from './DemurrageAndDetentionContentAddAgreementSection'

const DemurrageAndDetentionContentAddAgreement = () => {
  const { t } = useTranslation()
  const addAgreementSections = getAddAgreementSections(t)
  return (
    <Box sx={{ flexGrow: 1, px: 3, pt: 6, mb: 35 }}>
      <Grid container spacing={{ xs: 3, sm: 3, md: 5, lg: 9, xl: 9 }}>
        {addAgreementSections.map((section) => (
          <DemurrageAndDetentionContentAddAgreementSection
            {...section}
            key={section.step}
          />
        ))}
      </Grid>
    </Box>
  )
}

export default DemurrageAndDetentionContentAddAgreement

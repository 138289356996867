import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { TruncatedTextWithTooltip } from 'src/stories'
import { ShipmentTwoLineInfoProps } from '../shipmentContentProps'

export const ShipmentTwoLineInfo: React.FC<ShipmentTwoLineInfoProps> = (
  props
) => {
  const { content } = props
  return (
    <Box>
      <Typography variant="overline" children={props.title} />
      <TruncatedTextWithTooltip text={content} />
    </Box>
  )
}

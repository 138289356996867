import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'

import { getPrice } from '../helpers'

const RatesLineDetailsChargesTablePriceBlock: React.FC<{
  price: IRatesPriceItem
}> = ({ price }) => {
  if (price.type === 'included_price')
    return (
      <Box
        color="white"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CheckCircleRoundedIcon
          color="success"
          sx={{
            fontSize: '18px',
          }}
        />
      </Box>
    )
  return (
    <Typography
      variant="body1Strong"
      children={getPrice(price.value)}
      className="rates-price-block-test"
    />
  )
}

export default RatesLineDetailsChargesTablePriceBlock

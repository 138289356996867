import { FunctionComponent } from 'react'
import Box from '@mui/material/Box'

import './styles.scss'

interface IProps {
  status: RollingStatus
  reason: string
}

type RollingStatusObject = {
  name: string
  customClassName: string
}

type RollingStatusEnum = {
  [key in RollingStatus]: RollingStatusObject
}

const rollingStatuses: RollingStatusEnum = {
  to_be_checked: {
    name: 'TO BE CHECKED',
    customClassName: 'yellow',
  },
  confirmed: {
    name: 'CONFIRMED',
    customClassName: 'green',
  },
  declined: {
    name: 'DECLINED',
    customClassName: 'red',
  },
}

const RollingStatusField: FunctionComponent<IProps> = ({ status, reason }) => {
  const rollingStatus: RollingStatusObject = rollingStatuses[status]

  return (
    <Box>
      <Box className="status-field block">{reason}</Box>
      <Box className={`status-field block ${rollingStatus.customClassName}`}>
        {rollingStatus.name}
      </Box>
    </Box>
  )
}

export default RollingStatusField

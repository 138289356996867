export const organizationTableHeader = {
  name: '',
  sortable: false,
  sortValue: '',
  customClassName: 'short-50 center-aligned',
}

export const spacerTableHeader = {
  name: '',
  sortable: false,
  sortValue: '',
  customClassName: 'fill-width',
}

export const rangeItemSeparator = '-'

export const rangeCalculationMethods = {
  chargeable_weight_kg: 'CW kg',
}

export const localChargesName = 'Local charges'

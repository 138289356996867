import { useSelector, useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import { useForm } from 'react-hook-form'
import {
  useGetShipmentOverview,
  useGetShipmentBookings,
  useGetShipmentContainers,
} from 'src/services/Api/shipments'
import { useHistory } from 'react-router-dom'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { useCreateNewShipmentTemplate } from 'src/services/Api/shipmentTemplates'
import ShipmentTemplateDialogSkeleton from './ShipmentTemplateDialogSkeleton'
import ShipmentTemplateDialogContentForm from './ShipmentTemplateDialogContentForm'
import { ShipmentTemplateDialogContentFormProps } from './ShipmentTemplate.props'
import { getTemplateDataFromShipmentData } from './ShipmentTemplate.utils'

const defaultValues: ShipmentTemplateDialogContentFormProps = {
  name: '',
}

const ShipmentTemplateDialogContent = ({ shipmentId, onClose }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    reset,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues,
  })

  const { organizationId } = useSelector((state: IGlobalState) => ({
    organizationId: state.user.organizationId,
  }))

  const {
    isFetching: isShipmentOverviewLoading,
    data: shipmentOverview,
  } = useGetShipmentOverview({ shipmentId })

  const {
    isFetching: isShipmentBookingsLoading,
    data: shipmentBookings,
  } = useGetShipmentBookings({ shipmentId })

  const {
    isFetching: isShipmentContainersLoading,
    data: shipmentContainers,
  } = useGetShipmentContainers({ shipmentId })

  const isLoading =
    isShipmentOverviewLoading ||
    isShipmentBookingsLoading ||
    isShipmentContainersLoading

  const {
    mutateAsync: createBookingTemplateAsync,
  } = useCreateNewShipmentTemplate()

  const showSuccessMessage = (name: string, id: number) => {
    dispatch(
      showNotification({
        message: `‘${name}’ has been saved to your templates.`,
        actionText: 'View template',
        actionHandler: () => {
          history.push(`/templates/${id}/edit`)
        },
        severity: 'success',
      })
    )
  }

  const onSubmit = async (formData) => {
    const templateData = getTemplateDataFromShipmentData(
      organizationId,
      shipmentOverview.data,
      shipmentBookings.bookings,
      shipmentContainers
    )
    const payload = { ...formData, template: templateData }
    createBookingTemplateAsync(payload)
      .then((res) => {
        showSuccessMessage(res.name, res.id)
      })
      .finally(onClose)
  }

  const onCancel = () => {
    reset()
    onClose()
  }

  return (
    <>
      <Box px={3} data-testid="save-template-from-shipment-dialog-content">
        {isLoading && <ShipmentTemplateDialogSkeleton />}
        {!isLoading && (
          <ShipmentTemplateDialogContentForm register={register} />
        )}
      </Box>
      <DialogActions
        sx={{ mt: 2 }}
        data-testid="save-template-from-shipment-dialog-actions"
      >
        <Button
          size="large"
          onClick={onCancel}
          data-testid="save-template-from-shipment-dialog-actions-cancel"
        >
          Cancel
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading || !isValid}
          data-testid="save-template-from-shipment-dialog-actions-confirm"
        >
          Save
        </Button>
      </DialogActions>
    </>
  )
}

export default ShipmentTemplateDialogContent

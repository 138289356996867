// @ts-nocheck
// FIXME

import { FunctionComponent, useEffect, useState, useCallback } from 'react'

import { useTranslation, Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button } from '@mui/material'
import Input from 'src/components/Common/Input'
import Modal from 'src/components/Common/Modal'

import { promisifyAction } from '../../../utils'

import { editHsCode } from '../../../stores/actionCreators'

interface IProps {
  containerId: number
  hsCode: IContainerHsCode
  open: boolean
  onClose: () => void
}

const EditHsCodeModal: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [notes, setNotes] = useState<string>(props.hsCode.notes)

  useEffect(() => {
    setNotes(props.hsCode.notes)
  }, [props.hsCode])

  const onChange = useCallback((fieldName: string, value: string): void => {
    setNotes(value)
  }, [])

  const updateHsCodeAsync = promisifyAction(dispatch, editHsCode)

  const onSave = async (): Promise<void> => {
    updateHsCodeAsync(props.containerId, props.hsCode.id, { notes: notes })
  }

  const onClose = useCallback(() => {
    props.onClose()
  }, [props.onClose])

  const onSaveAndClose = useCallback(async () => {
    await onSave()
    props.onClose()
  }, [props.onClose, props.containerId, props.hsCode, notes])

  const modalTitle: React.ReactNode = (
    <Trans
      i18nKey="shipment_containers.hs_code_tabs.edit_hs_code"
      defaults="Edit HS code <0>{{hsCode}}</0>"
      values={{ hsCode: props.hsCode.goods_code }}
      components={[<strong />]}
    />
  )
  return (
    <Modal.Window open={props.open} onClose={onClose}>
      <Modal.Title children={modalTitle} onClose={onClose} />
      <Modal.Content>
        <Input.Text
          children={''}
          label={t('shipment_containers.hs_code_tabs.notes', 'Notes')}
          name="notes"
          value={notes}
          disabled={false}
          onChange={onChange}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button variant="outlined" onClick={onClose}>
          {t('common.buttons.cancel', 'Cancel')}
        </Button>
        <Button variant="contained" onClick={onSaveAndClose}>
          {t('common.buttons.save', 'Save')}
        </Button>
      </Modal.Actions>
    </Modal.Window>
  )
}

export default EditHsCodeModal

import { shipmentChipColor } from 'src/pages/PickupsAndDeliveries/constants'
import OverviewTableText from './OverviewTableText'
import OverviewTableShipmentColoredCell from './OverviewTableShipmentColoredCell'

const OverviewTableShipmentStatusCell = ({ row, field }) => {
  if (!row.original?.[field]) return <OverviewTableText text="-" />

  const statusPhase = row.original?.shipment_status_phase ?? 'in_transit'

  const statusColor = shipmentChipColor[statusPhase]

  return (
    <OverviewTableShipmentColoredCell
      color={statusColor}
      text={row.original[field]}
    />
  )
}

export default OverviewTableShipmentStatusCell

import { FunctionComponent, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { flatMap, uniqBy } from 'lodash'

import './styles.scss'

import ShipmentDocumentHeader from './ShipmentDocumentHeader'

interface IProps {
  document: IShipmentDocument
  shipmentId: number | string
  disabled?: boolean
  isModalityAir?: boolean
  downloadable?: boolean
  withBooking?: boolean
  containersCount?: number
  onEdit: (id: number) => void
  onDelete: (id: number) => void
  showPublicLink: boolean
}

const ShipmentDocument: FunctionComponent<IProps> = (props) => {
  const onEdit = useCallback((): void => {
    if (!props.disabled) {
      props.onEdit(props.document.id)
    }
  }, [props.document, props.disabled])

  const onDelete = useCallback((): void => {
    if (!props.disabled) {
      props.onDelete(props.document.id)
    }
  }, [props.document, props.disabled])

  const { organizationRoles } = useSelector((state: IGlobalState) => ({
    organizationRoles: state.shipments.organizationRoles,
  }))

  const shipmentRoles: IRoleShort[] = useMemo(() => {
    return uniqBy(flatMap(organizationRoles, 'shipment_roles'), 'id')
  }, [organizationRoles])

  return (
    <ShipmentDocumentHeader
      isModalityAir={props.isModalityAir}
      document={props.document}
      shipmentId={props.shipmentId}
      downloadable={props.downloadable}
      withBooking={props.withBooking}
      containersCount={props.containersCount}
      opened={true}
      remove={onDelete}
      edit={onEdit}
      disabled={!!props.disabled}
      shipmentRoles={shipmentRoles}
      showPublicLink={props.showPublicLink}
    />
  )
}

export default ShipmentDocument

ShipmentDocument.defaultProps = {
  disabled: false,
  withBooking: false,
  downloadable: true,
  containersCount: 0,
}

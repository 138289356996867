import { FunctionComponent, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from 'src/stories'
import { history } from 'src/shyppleStore'
import DashboardEmptyLine from 'src/components/DashboardOverview/DashboardEmptyLine'
import { promisifyAction } from '../../../utils'
import { fetchDashboardDeparting } from '../../../stores/actionCreators'
import { dashboardLine } from '../DashboardShipmentLine/dashboardLine'
import columns from '../common'

const DashboardDeparting: FunctionComponent<{}> = () => {
  const dispatch = useDispatch()
  const [busy, setBusy] = useState<boolean>(true)

  const { records, onlyWatchedShipments } = useSelector(
    (state: IGlobalState) => ({
      records: state.dashboard.departing.records || [],
      onlyWatchedShipments: state.shipmentsWatchedBy.onlyWatchedShipments,
    })
  )

  const fetchDashboardDepartingAsync = promisifyAction(
    dispatch,
    fetchDashboardDeparting
  )

  useEffect(() => {
    const fetchDataAsync = async () => {
      await fetchDashboardDepartingAsync({
        only_watched_shipments: onlyWatchedShipments,
      })
      setBusy(false)
    }
    fetchDataAsync()
  }, [onlyWatchedShipments])

  if (!busy && !records.length) {
    return <DashboardEmptyLine />
  }

  return (
    <Table
      columns={columns}
      loading={busy}
      maxHeight={320}
      onRowClick={(id) => {
        history.push(`/shipments/${id}`)
      }}
      showHeader={false}
      rows={
        !!records.length
          ? records.map((shipment: IDashboardShipment) => {
              const subtitle = shipment.pickup_requested
                ? shipment.pickup_address_name
                : 'Pickup not requested'
              return dashboardLine(shipment, subtitle)
            })
          : []
      }
    />
  )
}

export default DashboardDeparting

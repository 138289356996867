export const PERSONAL_DETAILS_GET_DATA = 'PERSONAL_DETAILS_GET_DATA'
export const PERSONAL_DETAILS_GET_DATA_SUCCESS =
  'PERSONAL_DETAILS_GET_DATA_SUCCESS'
export const PERSONAL_DETAILS_GET_ONE_PERSONAL_DETAIL =
  'PERSONAL_DETAILS_GET_ONE_PERSONAL_DETAIL'
export const PERSONAL_DETAILS_GET_ONE_PERSONAL_DETAIL_SUCCESS =
  'PERSONAL_DETAILS_GET_ONE_PERSONAL_DETAIL_SUCCESS'
export const PERSONAL_DETAILS_PUT_PERSONAL_DETAIL =
  'PERSONAL_DETAILS_PUT_PERSONAL_DETAIL'
export const PERSONAL_DETAILS_PUT_PERSONAL_DETAIL_SUCCESS =
  'PERSONAL_DETAILS_PUT_PERSONAL_DETAIL_SUCCESS'
export const PERSONAL_DETAILS_ADD_PERSONAL_DETAIL =
  'PERSONAL_DETAILS_ADD_PERSONAL_DETAIL'
export const PERSONAL_DETAILS_ADD_PERSONAL_DETAIL_SUCCESS =
  'PERSONAL_DETAILS_ADD_PERSONAL_DETAIL_SUCCESS'
export const PERSONAL_DETAILS_DELETE_PERSONAL_DETAIL =
  'PERSONAL_DETAILS_DELETE_PERSONAL_DETAIL'
export const PERSONAL_DETAILS_DELETE_PERSONAL_DETAIL_SUCCESS =
  'PERSONAL_DETAILS_DELETE_PERSONAL_DETAIL_SUCCESS'

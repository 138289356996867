import { SelectChangeEvent } from '@mui/material'
import { isArray } from 'lodash'
import { MultiSelect } from 'src/stories/Lab/Select/MultiSelect'

type SABSearchResultMultiSelectOption = { name: string }

interface SABSearchResultMultiSelectProps {
  name: string
  label: string
  value: SABSearchResultMultiSelectOption[]
  selectOptions: SABSearchResultMultiSelectOption[]
  onChange: (newValue: SABSearchResultMultiSelectOption[], name: string) => void
}

const SABSearchResultMultiSelect: React.FC<SABSearchResultMultiSelectProps> = ({
  value,
  label,
  name,
  onChange,
  selectOptions,
}) => {
  const selected = value.map(
    (item: SABSearchResultMultiSelectOption) => item.name
  )

  const options = selectOptions.map(
    (option: SABSearchResultMultiSelectOption) => ({
      id: option.name,
      label: option.name,
    })
  )

  const onMultiSelectChange = (event: SelectChangeEvent<unknown>) => {
    const selectedOptions = event.target.value
    if (isArray(selectedOptions)) {
      onChange(
        selectedOptions.map((selected) => ({ name: selected })),
        name
      )
    }
  }

  const onMultiSelectReset = () => {
    onChange([], name)
  }

  return (
    <>
      <MultiSelect
        label={label}
        value={selected}
        options={options}
        placeholder="Select"
        onReset={onMultiSelectReset}
        onChange={onMultiSelectChange}
        MenuProps={{ sx: { maxWidth: '200px' } }}
        data-testid="search-and-book-search-results-multi-select"
      />
    </>
  )
}

export default SABSearchResultMultiSelect

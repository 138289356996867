import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import SABSearchItemDateWithTooltip from './SABSearchItemDateWithTooltip'
import { SABSearchItemDateInfoProps } from './SABSearchItem.props'
import { DATE_TOOLTIP_TEXT } from './constants'

const SABSearchItemTimelineDateInfo: React.FC<SABSearchItemDateInfoProps> = ({
  textAlign,
  cargoClosingDate,
  departureDate,
  arrivalDate,
}) => {
  const isTBA = !departureDate && !arrivalDate
  return (
    <Box
      px={1}
      textAlign={textAlign}
      flexDirection="column"
      data-testid="search-and-book-item-date-info"
    >
      {!isTBA && cargoClosingDate && (
        <SABSearchItemDateWithTooltip
          abbriviation={DATE_TOOLTIP_TEXT['cargoClosingDate'].abbriviation}
          text={DATE_TOOLTIP_TEXT['cargoClosingDate'].description}
          date={cargoClosingDate}
          textAlign={textAlign}
        />
      )}
      {departureDate && (
        <SABSearchItemDateWithTooltip
          abbriviation={DATE_TOOLTIP_TEXT['departureDate'].abbriviation}
          text={DATE_TOOLTIP_TEXT['departureDate'].description}
          date={departureDate}
          textAlign={textAlign}
        />
      )}
      {arrivalDate && (
        <SABSearchItemDateWithTooltip
          abbriviation={DATE_TOOLTIP_TEXT['arrivalDate'].abbriviation}
          text={DATE_TOOLTIP_TEXT['arrivalDate'].description}
          date={arrivalDate}
          textAlign={textAlign}
        />
      )}
      {isTBA && (
        <Typography
          variant="body1Strong"
          textAlign={textAlign}
          children="TBA"
        />
      )}
    </Box>
  )
}

export default SABSearchItemTimelineDateInfo

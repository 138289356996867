import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {
  MRT_ShowHideColumnsMenu as ShowHideColumnsMenu,
  MRT_Column,
} from 'material-react-table'

const ShowHideColumnsButton = ({ table }) => {
  const hiddenColumnsCount = table
    .getAllColumns()
    .filter((column: MRT_Column<{}>) => !column.getIsVisible()).length

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'show-hide-button' : undefined
  const { t } = useTranslation()

  return (
    <Box>
      <Button
        id={id}
        size="large"
        data-testid="show-hide-button"
        aria-controls={open ? 'show-hide-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={<VisibilityOffIcon />}
        sx={{
          mr: 1,
          backgroundColor:
            hiddenColumnsCount === 0 ? 'transparent' : 'primary.A100',
        }}
      >
        {hiddenColumnsCount === 0 && t('vessels_page.hide_fieds')}
        {hiddenColumnsCount > 0 &&
          `${hiddenColumnsCount} ${t('vessels_page.hidden_fields')}`}
      </Button>
      <ShowHideColumnsMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        table={table}
      />
    </Box>
  )
}

export default ShowHideColumnsButton

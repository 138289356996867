import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import { DemurrageAndDetentionPrice } from 'src/@types/endpoints/demurrageAndDetention'
import ContainerDemurrageAndDetentionItem from './ContainerDemurrageAndDetentionItem'
import ContainerDemurrageAndDetentionTopSection from './ContainerDemurrageAndDetentionTopSection'
import ContainerDemurrageAndDetentionTotalPrice from './ContainerDemurrageAndDetentionTotalPrice'
import ContainerDemurrageAndDetentionEmptyState from './ContainerDemurrageAndDetentionEmptyState'
import { getItemForSumOfPrices } from './ContainerDemurrageAndDetention.utils'

export interface IContainerDemurrageAndDetention {
  demurrageAndDetentionItems: IShipmentContainerDemurrageAndDetention[]
  freightServiceRequested: boolean
  demurrageDates: {
    destinationDemurrage: string | null
    actualDayOfDemurrage?: string
  }
}
const ContainerDemurrageAndDetention: React.FC<IContainerDemurrageAndDetention> = ({
  demurrageAndDetentionItems,
  freightServiceRequested,
  demurrageDates,
}) => {
  const filteredItems = demurrageAndDetentionItems.filter(
    (demurrageAndDetentionItem) => !!demurrageAndDetentionItem.free_range.total
  )

  const prices = filteredItems.reduce(
    (filtered: DemurrageAndDetentionPrice[], demurrageAndDetentionItem) => {
      if (demurrageAndDetentionItem.costs !== null) {
        filtered.push(demurrageAndDetentionItem.costs)
      }
      return filtered
    },
    []
  )

  const totalPricesItem = getItemForSumOfPrices(prices)

  const hasAgreement = filteredItems.length > 0

  return (
    <>
      <ContainerDemurrageAndDetentionTopSection
        demurrageDates={demurrageDates}
        hasAgreement={hasAgreement}
      />
      {hasAgreement && (
        <>
          <Stack
            spacing={2}
            direction={{
              xs: 'column',
              sm: 'column',
              md: 'row',
              lg: 'row',
              xl: 'row',
            }}
            divider={<Divider orientation="vertical" flexItem />}
          >
            {filteredItems.map(
              (
                demurrageAndDetentionItem: IShipmentContainerDemurrageAndDetention
              ) => (
                <ContainerDemurrageAndDetentionItem
                  key={demurrageAndDetentionItem.service_item.id}
                  demurrageAndDetentionItem={demurrageAndDetentionItem}
                />
              )
            )}
          </Stack>
          {totalPricesItem &&
            totalPricesItem.value !== null &&
            (totalPricesItem.note === 'on_request' ||
              totalPricesItem.value > 0) && (
              <ContainerDemurrageAndDetentionTotalPrice
                totalPricesItem={totalPricesItem}
              />
            )}
        </>
      )}
      {!hasAgreement && (
        <ContainerDemurrageAndDetentionEmptyState
          freightServiceRequested={freightServiceRequested}
        />
      )}
      <Divider sx={{ my: 2 }} />
    </>
  )
}

export default ContainerDemurrageAndDetention

import { put } from 'redux-saga/effects'
import { ActionCreator, AnyAction } from 'redux'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { serverErrors } from '../../../utils/helpers'
import { authorizedRequest, rejectAction, resolveAction } from '../wrappers'
import { Logger } from '../../../utils'
import { reportTimeout } from './'

// Factory for standartized POST request. Action should have payload which is compatible with
// server API. Resolves with updated data, which can be serialized
export default (
  makeUrl: (action: AnyAction) => string,
  actionCreator: ActionCreator<AnyAction>,
  serializer?: IResponseSerializer,
  emitMessage?: boolean,
  displayServerError?: boolean
): ISaga1<IPromisifiedAction> =>
  function* (action: IPromisifiedAction): Iterator<any> {
    try {
      const response: any = yield authorizedRequest({
        method: 'POST',
        data: action.payload,
        url: makeUrl(action),
      })
      yield put(actionCreator(serializer ? serializer(response) : response))
      yield resolveAction(action, response.data)
      if (emitMessage) {
        yield put(showNotification({ message: response, severity: 'success' }))
      }
    } catch (error) {
      const unknownError: any = error
      if (unknownError.code === 'ECONNABORTED') {
        yield reportTimeout(makeUrl, action, 'POST')
      }
      yield* serverErrors(unknownError)
      yield Logger.sagaError(unknownError, 'store::user::simplePostAndResolve')
      if (displayServerError && unknownError.response) {
        yield put(
          showNotification({
            message: unknownError.response.data.message,
            severity: 'error',
          })
        )
      }

      if (emitMessage) {
        yield put(
          showNotification({
            message: unknownError.response.data.message,
            severity: 'error',
          })
        )
      }
      yield rejectAction(action, error)
    }
  }

import { ReactNode, FunctionComponent } from 'react'
import { SxProps, Theme } from '@mui/system'
import DialogContent from '@mui/material/DialogContent'

interface IProps {
  children: ReactNode
  className?: string
  sx?: SxProps<Theme>
}

const Content: FunctionComponent<IProps> = (props) => (
  <DialogContent
    sx={{ ...props.sx, paddingBottom: '0px !important' }}
    className={props.className}
  >
    {props.children}
  </DialogContent>
)

export default Content

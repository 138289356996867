import { Grid, Typography } from '@mui/material'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

const styles = {
  textTransform: 'uppercase',
}

interface IFormHead {
  required?: boolean
}

export const FormHeaders: FunctionComponent<IFormHead> = ({ required }) => {
  const isRequired = required ? <sup>*</sup> : ''
  const { t } = useTranslation()
  return (
    <Grid container mt={1.5} mb={1.5}>
      <Grid item xs={1} pr={1.5} pl={1.5}>
        <Typography variant="overline" sx={styles}>
          {t('shipment_containers.cargo_window.quantity', 'quantity')}
          {isRequired}
        </Typography>
      </Grid>
      <Grid item xs={2} pr={1.5} pl={1.5}>
        <Typography variant="overline" sx={styles}>
          {t('shipment_containers.cargo_window.package_type', 'package type')}
          {isRequired}
        </Typography>
      </Grid>
      <Grid item xs={2} pr={1.5} pl={1.5}>
        <Typography variant="overline" sx={styles}>
          {t('shipment_containers.cargo_window.dimensions', 'dimensions')}
        </Typography>
      </Grid>
      <Grid item xs={1.5} pr={1.5} pl={1.5}>
        <Typography variant="overline" sx={styles}>
          {t('shipment_containers.cargo_window.volume', 'volume')}
          {isRequired}
        </Typography>
      </Grid>
      <Grid item xs={1.5} pr={1.5} pl={1.5}>
        <Typography variant="overline" sx={styles}>
          {t('shipment_containers.cargo_window.weight', 'weight')}
          {isRequired}
        </Typography>
      </Grid>
      <Grid item xs={2} pr={1.5} pl={1.5}>
        <Typography variant="overline" sx={styles}>
          {t('shipment_containers.cargo_window.description', 'description')}
          {isRequired}
        </Typography>
      </Grid>
      <Grid item xs={1.5} pr={1.5} pl={1.5}>
        <Typography variant="overline" sx={styles}>
          {t('shipment_containers.cargo_window.stackable', 'stackable')}
          {isRequired}
        </Typography>
      </Grid>
    </Grid>
  )
}

import { useState } from 'react'
import SelectableChip, {
  ISelectChipColor,
  OptionsItem,
} from 'src/components/SelectableChip'
import { useUpdateShipmentTask } from 'src/services/Api/shipments'
import { permissionTo } from 'src/utils'

interface Props {
  shipmentId: number
  row: IMilestoneTask
  onChange?: () => void
}

const statusToColor: { [key: string]: ISelectChipColor } = {
  await: 'warning',
  resolved: 'success',
  in_progress: 'secondary',
  to_do: 'default',
  issue: 'error',
}

export default function TaskStatusCell(props: Props) {
  const { mutate: updateTask } = useUpdateShipmentTask({
    onSuccess: props.onChange,
  })

  const findTaskStatusColor = (taskId?: string | number) => {
    const taskStatuses = props.row.possible_task_statuses
    const selectedStatus = taskStatuses.find(({ id }) => id === taskId)

    if (!selectedStatus) return 'default'
    return statusToColor[selectedStatus.task_status_type.code]
  }

  const [status, setStatus] = useState({
    id: props.row.task_status?.id,
    name: props.row.task_status?.name,
    color: findTaskStatusColor(props.row.task_status?.id),
  })

  const onChange = async (option: OptionsItem) => {
    const taskStatuses = props.row.possible_task_statuses
    const taskStatus = taskStatuses.find(({ id }) => id === option.id)

    if (!taskStatus) return

    const { id, name } = taskStatus
    const { shipmentId, row } = props

    setStatus({ id, name, color: findTaskStatusColor(id) })
    updateTask({ task_status_id: id, shipment_id: shipmentId, task_id: row.id })
  }

  const selectOptions = props.row.possible_task_statuses.map(
    ({ name, id }) => ({ name, id })
  )

  return (
    <SelectableChip
      onChange={onChange}
      size="small"
      value={status}
      options={selectOptions}
      disabled={!permissionTo('shipments.status.manage')}
    />
  )
}

import { get, snakeCase } from 'lodash'
import snakecaseKeys from 'snakecase-keys'

const readyFields = [
  'tags',
  'cargo',
  'hsCode',
  'cifValue',
  'incoterm',
  'loadType',
  'modality',
  'services',
  'container',
  'booking',
  'references',
  'temperatureSetting',
]

export const getPayloadPorts = (port, field) => {
  return {
    [`${field}_id`]: port?.value ?? null,
    [`${field}_type`]: 'port',
  }
}

export const getPayloadAddresses = (formState) => {
  const pickupAddress = get(formState, 'pickupAddress.value', null)
  const deliveryAddress = get(formState, 'deliveryAddress.value', null)
  const delivery =
    deliveryAddress && formState.services?.delivery ? deliveryAddress : null
  const pickup =
    pickupAddress && formState.services?.pickup ? pickupAddress : null
  const shipper = formState.bookingParties.shipper.address?.value ?? null
  const consignee = formState.bookingParties.consignee.address?.value ?? null
  return { delivery, pickup, shipper, consignee }
}

export const getBookingPartyCollaborator = (
  formState,
  shipmentRoles,
  field
) => {
  const collaborator = formState.bookingParties[field].collaborator
  return collaborator
    ? [
        {
          organization_id: collaborator.value,
          role_ids: [shipmentRoles[field]],
        },
      ]
    : []
}

export const getPayloadBookingPartyCollaborators = (
  formState,
  shipmentRoles
) => {
  const shipperCollaborator = getBookingPartyCollaborator(
    formState,
    shipmentRoles,
    'shipper'
  )
  const consigneeCollaborator = getBookingPartyCollaborator(
    formState,
    shipmentRoles,
    'consignee'
  )

  return [...shipperCollaborator, ...consigneeCollaborator]
}

export const getFormPayload = ({
  id = '',
  formState,
  shipmentRoles,
  userOrganizationId,
}) => {
  const bookingPartyCollaborators = getPayloadBookingPartyCollaborators(
    formState,
    shipmentRoles
  )
  const otherCollaborators = formState.collaborators
    .map((collaborator) => {
      return {
        organization_id: collaborator.id,
        role_ids: collaborator.shipmentRoleIds,
      }
    })
    .filter((collaborator) => collaborator.role_ids.length > 0)

  const addresses = getPayloadAddresses(formState)
  const originPort = getPayloadPorts(formState.loadingPort, 'origin')
  const destinationPort = getPayloadPorts(
    formState.dischargePort,
    'destination'
  )
  const template = {
    ...originPort,
    ...destinationPort,
    addresses,
    shipment_role_id: formState.shipmentRole,
    collaborators: [...bookingPartyCollaborators, ...otherCollaborators],
    customer_organization_id: formState.customer?.value ?? userOrganizationId,
  }

  readyFields.forEach((field) => {
    const value = get(formState, field)
    const key = snakeCase(field)
    if (value && typeof value === 'object') {
      template[key] = snakecaseKeys(value)
    } else {
      template[key] = value
    }
  })

  return { id, name: formState.name, template } as Partial<
    IShipmentBookingTemplate
  >
}

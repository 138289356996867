import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { Trans, useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

const ShipmentCostsAirQuotationsStatus = ({ shipmentID, status }) => {
  const { t } = useTranslation()
  const statusHeadings = {
    rquo: {
      title: t('shipment_costs.air.status.rquo.title', 'Requote in progress'),
      content: t(
        'shipment_costs.air.status.rquo.description',
        'There has been a change in prices from our suppliers and/or you have requested a requote.\nYou will be notified when our offer is complete.'
      ),
    },
    quo: {
      title: t('shipment_costs.air.status.quo.title', 'Requote in progress'),
      content: t(
        'shipment_costs.air.status.quo.description',
        'There has been a change in prices from our suppliers and/or you have requested a requote.\nYou will be notified when our offer is complete.'
      ),
    },
    pquo: {
      title: t('shipment_costs.air.status.pquo.title', 'Proposed quote'),
      content: t(
        'shipment_costs.air.status.pquo.description',
        'Please review our proposed quote(s).'
      ),
    },
    spb: {
      title: t('shipment_costs.air.status.spb.title', 'Quote accepted'),
      content: t(
        'shipment_costs.air.status.spb.description',
        'You have accepted our proposed quote(s).'
      ),
    },
  }

  const statusText = statusHeadings?.[status] ?? statusHeadings.spb

  return (
    <>
      <Typography
        variant="h3"
        children={statusText.title}
        data-testid="shipment-quotation-status-title"
      />

      <Typography
        data-testid="shipment-quotation-status-description"
        variant="body1"
        sx={{ whiteSpace: 'pre-line' }}
      >
        {statusText.content}{' '}
        <Trans
          i18nKey="shipment_costs.air.status.helper_text"
          defaults="If you have any questions you can <0>chat</0> with us."
          components={[
            <Link
              component={RouterLink}
              to={`/shipments/${shipmentID}/chats`}
            />,
          ]}
        />
      </Typography>
    </>
  )
}

export default ShipmentCostsAirQuotationsStatus

import { AnyAction } from 'redux'
import * as actions from '../actions/messageCenter'

export const approvalsGetData = (): AnyAction => ({
  type: actions.APPROVALS_GET_DATA,
})

export const approvalsGetDataSuccess = (payload: any): AnyAction => ({
  type: actions.APPROVALS_GET_DATA_SUCCESS,
  payload,
})

export const mentionsGetData = (): AnyAction => ({
  type: actions.MENTIONS_GET_DATA,
})

export const mentionsGetDataSuccess = (payload: any): AnyAction => ({
  type: actions.MENTIONS_GET_DATA_SUCCESS,
  payload,
})

export const getSavedMessages = (): AnyAction => ({
  type: actions.GET_SAVED_MESSAGES,
})

export const getSavedMessagesSuccess = (payload: any): AnyAction => ({
  type: actions.GET_SAVED_MESSAGES_SUCCESS,
  payload,
})

export const unreadGetData = (payload: any): AnyAction => ({
  type: actions.UNREAD_GET_DATA,
  payload,
})

export const unreadGetDataSuccess = (payload: any): AnyAction => ({
  type: actions.UNREAD_GET_DATA_SUCCESS,
  payload,
})

export const countersGetData = (): AnyAction => ({
  type: actions.COUNTERS_GET_DATA,
})

export const countersGetDataSuccess = (payload: any): AnyAction => ({
  type: actions.COUNTERS_GET_DATA_SUCCESS,
  payload,
})

export const setMessagesCounters = (payload: any): AnyAction => ({
  type: actions.SET_MESSAGES_COUNTERS,
  payload,
})

import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import { permissionTo } from 'src/utils'
import { useSelector, shallowEqual } from 'react-redux'
import CssBaseline from '@mui/material/CssBaseline'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'
import usePageTitleHook from 'src/components/TopNavigation/hooks/usePageTitleHook'
import {
  navItems,
  largeMobileBreakpoint,
  defaultMobileBreakpoint,
} from './TopNavigation.constants'
import TopNavigationUserChannel from './components/TopNavigationUserChannel'
import TopNavigationMobile from './TopNavigationMobile'
import TopNavigationDesktop from './TopNavigationDesktop'
import { getUserFullName, getPermittedMenuItems } from './TopNavigation.utils'
import { StyledToolbar } from './TopNavigation.styles'

const TopNavigation = () => {
  const { t } = useTranslation()
  usePageTitleHook(t)
  const opsDashboardIsVisible = permissionTo('reports_dashboard.view')

  const breakpoint = opsDashboardIsVisible
    ? largeMobileBreakpoint
    : defaultMobileBreakpoint

  const isMobile = useMediaQuery(`(max-width:${breakpoint}px)`)

  const filteredNavItems = getPermittedMenuItems(navItems(t))

  const { user } = useSelector(
    (state: IGlobalState) => ({
      user: state.user,
    }),
    shallowEqual
  )

  const fullName = getUserFullName(user)

  const isUser = !!user.uid

  const props = {
    isUser,
    fullName,
    email: user.email,
    avatar: user.avatar ?? '',
    navItems: filteredNavItems,
    visibilityTrial: user.organization_visibility_trial,
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav" elevation={0} position="fixed">
        <StyledToolbar
          variant="dense"
          sx={{ minHeight: 56, justifyContent: 'space-between' }}
        >
          {isUser && <TopNavigationUserChannel />}
          {isMobile && <TopNavigationMobile {...props} />}
          {!isMobile && <TopNavigationDesktop {...props} />}
        </StyledToolbar>
      </AppBar>
    </Box>
  )
}

export default TopNavigation
